import { Button } from "primereact/button";
import React, { useState } from "react";
import StepZilla from "react-stepzilla";
import { GroupCompanyService } from "../../service/GroupCompanyService";
import { CompanyCreate } from "../company/Company";
import { GroupCompanyCreate } from "../groupcompany/GroupCompany";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { Question1 } from "./Question1";

function Onboarding(props: RouteComponentProps) {
  const { SetSuccess } = React.useContext(ToastStateContext);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>("");
  const [numberOfCompany, setNumberOfCompany] = useState<number>(0);

  let commonMethods = new CommonMethods();

  const gcs = new GroupCompanyService();

  const noOfCompCallback = (noOfCompany: number) => {
    setNumberOfCompany(noOfCompany);
  };

  const Completed = (
    <>
      <div className="row">
        <div className="col-12 col-xl-6">
          <div className="card h5">
            <p>
              Congratulations on getting your company set up! Head over to Admin
              Dashboard to complete other configurations.
            </p>

            <p className="mb-5">
              If you need help, click on the information icon{" "}
              <span
                className="layout-topbar-icon pi pi-question"
                style={{ fontSize: "1.0em", opacity: 0.8 }}
              />{" "}
              on your application bar!
            </p>

            <div className="p-grid p-justify-center">
            <Button
              label="Go to Admin Dashboard"
              onClick={() => {
                gcs
                  .updateOrientationStatus(props.userProfile.groupCompanyID)
                  .then(() => {
                    SetSuccess("Orientation completed!");
                    window.location.href = "/admin";
                  })
                  .catch(e => {
                    let errMsg = commonMethods.getErrorMessage(e);
                    setIsError(true);
                    setErrorMessage(errMsg);
                  });
              }}
            />
          </div>
          </div>
      
        </div>
      </div>
    </>
  );
  const steps = [
    {
      name: "1. Start by telling us about your parent company",
      component: <GroupCompanyCreate {...props} others="onboarding" />
    },
    {
      name: "2. Now, tell us about one of your companies",
      component: <CompanyCreate {...props} others="onboarding" />
    },

    { name: "3. Completed", component: Completed }
  ];

  const steps1Company = [
    {
      name: "1. Start by telling us about your company",
      component: <CompanyCreate {...props} others="onboarding" />
    },
    { name: "2. Completed", component: Completed }
  ];

  let currentdisplay;
  if (numberOfCompany === 0) {
    currentdisplay = (
      <div className="pt-3">
        <Question1 {...props} others={noOfCompCallback} />
      </div>
    );
  } else {
    currentdisplay = (
      <StepZilla
        steps={numberOfCompany > 1 ? steps : steps1Company}
        showSteps={true}
        stepsNavigation={false}
        nextButtonCls="p-button p-component p-button-text-only p-2 mx-1"
        backButtonCls="p-button p-component p-button-text-only p-2"
        nextTextOnFinalActionStep="Complete"
        prevBtnOnLastStep={false}
      />
    );
  }

  let page = (
    <div className="step-progress">
      <h1>Welcome to NeuHR!</h1>
      {currentdisplay}
    </div>
  );
  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else {
    display = page;
  }

  return <>{display}</>;
}

export default Onboarding;
