import { HttpClient } from "./HttpClient";
import moment from "moment";
import { CommonMethods } from "../components/resources/CommonMethods";
export class ExternalCostService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getExternalCostsByCompanyId(companyid: string) {
    const res = await this.httpClient.get(
      "/api/externalcost/company/" + companyid
    );
    return res;
  }

  async getExternalCostsById(id: string) {
    const res = await this.httpClient.get("/api/externalcost/" + id);
    return res;
  }
  // Not in use
  // async getMonthlyByExternalCostTypebyCompany(
  //   companyID: string,
  //   month: number
  // ) {
  //   const res = await this.httpClient.get(
  //     "/api/externalcost/company/" + companyID + "/monthly/" + month
  //   );
  //   return res;
  // }

  // In use - Boss Dashboard - Pie chart monthly operational cost
  async getPieChartCostByCurrency(groupID: string, currency: string) {
    const res = await this.httpClient.get(
      "/api/externalcost/group/" + groupID + "/" + currency
    );
    return res;
  }

  // In use - Boss Dashboard Bar Chart
  async getBarChartMonthlyCostByCurrency(groupID: string, currency: string) {
    const res = await this.httpClient.get(
      "/api/externalcost/group/" + groupID + "/monthly/" + currency + "/all"
    );
    return res;
  }

  // Not in use
  // async getMonthlyTotalExternalCostbyCompany(companyID: string) {
  //   const res = await this.httpClient.get(
  //     "/api/externalcost/company/" + companyID + "/monthly/all"
  //   );
  //   return res;
  // }

  // Not in use
  // async getMonthlyTotalCostbyGroup(groupID: string) {
  //   const res = await this.httpClient.get(
  //     "/api/externalcost/group/" + groupID + "/monthly/all"
  //   );
  //   return res;
  // }

  async createExternalCost(obj: any) {
    var form_data = new FormData();
    for (var key in obj) {
      if (key === "paymentDate" || key === "invoiceDate") {
        // let utcDate = obj[key].toLocaleString();
        let utcDate = moment(obj[key]).format(CommonMethods.DateForBackendStringFormat);
        form_data.append(key, utcDate);
      } else {
        form_data.append(key, obj[key]);
      }
    }

    const res = await this.httpClient.post(
      "/api/externalcost/create",
      form_data
    );
    return res;
  }

  async updateExternalCost(obj: any) {
    var form_data = new FormData();

    for (var key in obj) {
      if (key === "paymentDate" || key === "invoiceDate") {
        // let utcDate = obj[key].toLocaleString();
        let utcDate = moment(obj[key]).format(CommonMethods.DateForBackendStringFormat);
        form_data.append(key, utcDate);
      } else {
        form_data.append(key, obj[key]);
      }
    }

    const res = await this.httpClient.put(
      "/api/externalcost/update",
      form_data
    );
    return res;
  }

  async updateExternalCostStatus(obj: any) {
    const res = await this.httpClient.put("/api/externalcost/status", obj);
    return res;
  }

  async deleteExternalCost(id: string) {
    const res = await this.httpClient.delete("/api/externalcost/" + id);
    return res;
  }
}
