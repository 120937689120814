import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState } from "react";
import {
  StatusDocumentService,
  StatusRecordService
} from "../../service/StatusRecordService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { ApproveLeavePage } from "./ApproveLeavePage";
import StatusRecordGCView from "./StatusRecordGCView";

export function AuditLeave(props: RouteComponentProps) {
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);
  const statusRecordService = new StatusRecordService();
  const commonMethods = new CommonMethods();
  const statusDocumentService = new StatusDocumentService();
  const [unauditedLeaves, setUnauditedLeaves] = useState<any[]>([]);
  const [selectedRecords, SetSelectedRecords] = useState<any[]>([]);
  const [dialogStr, setDialogStr] = useState(<> </>);
  const [confirmStr, setConfirmStr] = useState("");
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [infoDialogVisibility, setInfoDialogVisibility] = useState(false);
  const [verifiedRemarks, setVerifiedRemarks] = useState("");
  const [documentID, setDocumentID] = useState("");
  const [isError, setIsError] = useState(false);
  const [statusRecordID, setStatusRecordID] = useState("");

  useEffect(() => {
    getRecordsToVerify();
  }, []);

  useEffect(() => {}, [selectedRecords]);

  const getRecordsToVerify = () => {
    statusRecordService
      .getUnverifiedRecords(localStorage.currentAccessingCompany)
      .then((res) => {
        setUnauditedLeaves(res);
      })
      .catch(() => {
        SetError("Could not get unverified records.");
        setIsError(true);
      });
  };

  const actionTemplate = (rowData: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip={"Application Details"}
          tooltipOptions={{ position: "top" }}
          type="button"
          // style={{ backgroundColor: "#0099CC" }}
          icon="pi pi-eye"
          className="p-button-primary"
          onClick={() => {
            showInfoDialog(rowData);
          }}
        />

        <Button
          tooltip={"Verify Document"}
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-check"
          className="p-button-success mx-1"
          onClick={() => {
            handleVerifyClick(rowData);
          }}
        />

        <Button
          tooltip="Reject"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            handleRejectionClick(rowData);
          }}
        />
        <Button
          tooltip="View Document"
          tooltipOptions={{ position: "top" }}
          disabled={rowData.uploadPath == null}
          type="button"
          icon="pi pi-folder"
          className="button-document mx-1"
          onClick={() => {
            window.open(rowData.uploadPath, "_blank");
          }}
        />
      </div>
    );
  };

  const tableHeader = () => {
    if (selectedRecords.length !== 0) {
      return (
        <span>
          <Button
            tooltip="Verify Selected Documents"
            label="Verify Selected"
            tooltipOptions={{ position: "top" }}
            hidden={selectedRecords.length === 0}
            type="button"
            icon="pi pi-check"
            className="p-button-success mr-2"
            onClick={() => {
              setConfirmStr(`Verify multiple documents`);
              setDialogStr(
                <p>
                  Are you sure you want to verify {selectedRecords.length}{" "}
                  record(s)?
                </p>
              );
              setDialogVisibility(true);
            }}
          />
          <Button
            tooltip="Reject Selected Documents"
            label="Reject Selected"
            tooltipOptions={{ position: "top" }}
            hidden={selectedRecords.length === 0}
            type="button"
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => {
              handleRejectSelectedClick();
            }}
          />
        </span>
      );
    }
  };

  const showInfoDialog = (rowData: any) => {
    setConfirmStr(rowData.statusTypeName);
    let html = (
      <>
        <table className="leave-info-dialog table">
          <tbody>
            {/* <tr>
              <td className="font-weight-bold">ID:</td>
              <td>{rowData.statusRecordID}</td>
            </tr> */}
            <tr>
              <td className="font-weight-bold">Status Type:</td>
              <td>{rowData.statusTypeName}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Status:</td>
              <td>{rowData.approvalStatus}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Approved By:</td>
              <td>
                {rowData.approvers} on{" "}
                {commonMethods.displayDate(rowData.lastUpdated)}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">Applied By:</td>
              <td>
                {rowData.fullDisplayName} on{" "}
                {commonMethods.displayDate(new Date(rowData.createdTime + "Z"))}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">Start Date:</td>
              <td>
                {" "}
                {commonMethods.convertStartEndDateForDayDateDurationDisplay(
                  rowData.statusTypeName,
                  rowData.startDate,
                  rowData.endDate,
                  rowData.duration,
                  "start"
                )}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">End Date:</td>
              <td>
                {commonMethods.convertStartEndDateForDayDateDurationDisplay(
                  rowData.statusTypeName,
                  rowData.startDate,
                  rowData.endDate,
                  rowData.duration,
                  "end"
                )}
              </td>
            </tr>

            <tr>
              <td className="font-weight-bold">Value:</td>
              <td>{rowData.creditDebitValue}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Remarks:</td>
              <td>{rowData.remarks}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
    setDialogStr(html);
    setInfoDialogVisibility(true);
  };

  //    ___       ___  _  _  _        _  _        _  _  ___   _          ___  ___
  //   | __|__ __| __|| \| || |_     | || | __ _ | \| ||   \ | |    ___ | _ \/ __|
  //   | _| \ V /| _| | .` ||  _|    | __ |/ _` || .` || |) || |__ / -_)|   /\__ \
  //   |___| \_/ |___||_|\_| \__|    |_||_|\__,_||_|\_||___/ |____|\___||_|_\|___/
  //

  const handleRejectionClick = (rowData: any) => {
    setDialogVisibility(true);
    setDocumentID(rowData.id);
    setStatusRecordID(rowData.statusRecordID);
    setDialogStr(
      <>
        Are you sure you want to reject this document?
        <p>
          <strong>Reason:</strong>
        </p>
        <InputTextarea
          placeholder="Required"
          rows={5}
          cols={30}
          onChange={(e) => {
            setVerifiedRemarks(e.currentTarget.value);
          }}
        />
      </>
    );
    setConfirmStr("Confirm Rejection");
  };

  const handleRejectSelectedClick = () => {
    setConfirmStr(`Reject multiple documents`);
    setDialogStr(
      <>
        <p>
          Are you sure you want to reject {selectedRecords.length} record(s)?
        </p>
        <p>
          <strong>Reason:</strong>
        </p>
        <InputTextarea
          rows={5}
          cols={30}
          onChange={(e) => {
            setVerifiedRemarks(e.currentTarget.value);
          }}
          required
        />
      </>
    );
    setDialogVisibility(true);
  };

  const confirmRejectDocument = (documentID: string) => {
    statusDocumentService
      .rejectStatusDocument({
        id: documentID,
        VerifiedRemarks: verifiedRemarks,
        StatusRecordID: statusRecordID,
      })
      .then((res) => {
        setVerifiedRemarks("");
        setDialogVisibility(false);
        getRecordsToVerify();
        SetSuccess("Document has been rejected.");
      })
      .catch((err) => {
        const error = commonMethods.getErrorMessage(err);
        SetError(error);
        setDialogVisibility(false);
      });
  };

  const confirmVerifyDocument = (documentID: string) => {
    statusDocumentService
      .verifyStatusDocument({ id: documentID })
      .then((res) => {
        setDialogVisibility(false);
        getRecordsToVerify();
        SetSuccess("Document has been verified.");
      })
      .catch((err) => {
        const error = commonMethods.getErrorMessage(err);
        SetError(error);
        setDialogVisibility(false);
      });
  };

  const handleVerifyClick = (rowData: any) => {
    setDocumentID(rowData.id);
    setDialogVisibility(true);
    setDialogStr(<>Are you sure you want to verify this application?</>);
    setConfirmStr("Verify Document");
  };

  const batchRejectRecords = () => {
    selectedRecords.forEach((element) => {
      element.verifiedRemarks = verifiedRemarks;
    });

    statusDocumentService
      .bulkRejectStatusDocuments(selectedRecords)
      .then((res) => {
        SetSuccess(`${selectedRecords.length} documents have been reject.`);
      })
      .catch((err) => {
        SetError("Error rejecting records.");
      })
      .finally(() => {
        setDialogVisibility(false);
        getRecordsToVerify();
      });
  };
  const batchVerifyRecords = () => {
    statusDocumentService
      .bulkVerifyStatusDocuments(selectedRecords)
      .then((res) => {
        SetSuccess(`${selectedRecords.length} documents have been verified.`);
      })
      .catch((err) => {
        SetError("Error verifying records.");
      })
      .finally(() => {
        setDialogVisibility(false);
        getRecordsToVerify();
      });
  };

  let display;
  if (isError) {
    display = <CustomError message={"An error has occurred"} />;
  } else {
    display = (
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          emptyMessage="No records found."
          //   globalFilter={this.state.globalFilter}
          value={unauditedLeaves}
          paginator={true}
          rows={15}
          autoLayout={true}
          alwaysShowPaginator={false}
          selection={selectedRecords}
          onSelectionChange={(e) => {
            SetSelectedRecords(e.value);
          }}
        >
          <Column
            selectionMode="multiple"
            className="p-col-1 text-center"
            style={{ width: "3em" }}
          />
          <Column field="fullDisplayName" header="User" />
          <Column field="departmentName" header="Department" />
          <Column
            field="statusTypeName"
            header="Leave Type"
            className="p-col-2"
          />
          <Column
            field="createdTime"
            header="Application Date"
            className="d-none"
          />
          <Column field="remarks" header="Remarks" />
          <Column body={actionTemplate} header="Action" />
        </DataTable>
      </div>
    );
  }

  let verifydocument = (
    <div>
      <Dialog
        //dismissableMask={true}
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        onHide={() => {
          setDialogVisibility(false);
          setVerifiedRemarks("");
          setDialogStr(<></>);
        }}
        footer={
          <div>
            <Button
              disabled={
                confirmStr === "Confirm Rejection" && verifiedRemarks === ""
              }
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                switch (confirmStr) {
                  case "Verify Document":
                    confirmVerifyDocument(documentID);
                    break;
                  case "Confirm Rejection":
                    confirmRejectDocument(documentID);
                    break;
                  case "Reject multiple documents":
                    batchRejectRecords();
                    break;
                  case "Verify multiple documents":
                    batchVerifyRecords();
                    break;

                  default:
                    break;
                }
              }}
            />

            <Button
              label="No"
              type="button"
              icon="pi pi-times"
              className="p-button-danger "
              onClick={() => {
                setVerifiedRemarks("");
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>
      <Dialog
        //dismissableMask={true}
        header={confirmStr}
        visible={infoDialogVisibility}
        modal={true}
        onHide={() => {
          setInfoDialogVisibility(false);
          setVerifiedRemarks("");
        }}
      >
        {dialogStr}
      </Dialog>

      <div className="row">
        <div className="col-12 col-xl-10">
          <div className="card">
            <h1>
              Verify Application Document
              <span className="mx-2">{tableHeader()}</span>
            </h1>
            <p>
              Verify the documents that has been uploaded by employees - useful
              for auditing!
            </p>

            {display}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {verifydocument}
      <ApproveLeavePage {...props} />
      <div className="row">
        <div className="col-12 col-xl-10">
          <div className="card">
            <Accordion>
              <AccordionTab header="Group Overview">
              <p>View the Leaves of employees across all companies.</p>

                <StatusRecordGCView
                  {...props}
                  others={{ admin: true }}
                />
              </AccordionTab>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuditLeave;
