import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/components/dropdown/Dropdown";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import { StatusRecordService } from "../../service/StatusRecordService";
import {
  CommonMethods,
  DropdownModel,
  RouteComponentProps
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { ReportsModel, ReportType } from "./Reports";
import { InputSwitch } from "primereact/inputswitch";

function LeaveReport(props: RouteComponentProps<any>) {
  const { SetError } = React.useContext(ToastStateContext);

  const commonMethods = new CommonMethods();
  const statusRecordService = new StatusRecordService();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [companyDropdown, setCompanyDropdown] = useState<DropdownModel[]>([]);

  const [userDropdown, setUserDropdown] = useState<DropdownModel[]>([]);
  const [inactiveUserDropdown, setInactiveUserDropdown] = useState<DropdownModel[]>([]);
  const [switchButton, setSwitchButton] = useState(false);

  const [reportObj, setReportObj] = useState<ReportsModel>(new ReportsModel());
  const [reportData, setReportData] = useState<any[]>([]);
  const [exportFileName, setExportFileName] = useState<string>(
    "LeaveReport_" + commonMethods.displayDate_FileName(new Date()) + ".csv"
  );
  let test: DataTable | null = null;

  const [reportType, setReportType] = useState<ReportType>({
    groupCompany: false,
    company: false,
  });

  const leaveHeaders = [
    { label: "Full Name", key: "fullDisplayName" },
    { label: "Leave Type", key: "leaveType" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" },
    { label: "Reason", key: "reason" },
    { label: "Requested Days", key: "requestedDays" },
    { label: "Status", key: "approvalStatus" },
    { label: "Requested Date", key: "requestedDate" },
    { label: "Approved Date", key: "approvedDate" },
    { label: "Approved By", key: "approvedBy" },
    { label: "Account Status", key: "accStatus"}
  ];

  useEffect(() => {
    getCompanyDropdownOptions();
  }, []);

  useEffect(() => {
    if (test !== null) {
      test.exportCSV();
    }
  }, [reportData]);

  useEffect(() => {
    if (reportObj.companyID !== CommonMethods.EmptyGuid) {
      setReportObj({ 
        ...reportObj, 
        employeeListStrArr: [],
        inactiveEmployeeListStrArr: []
      });
      setSwitchButton(false);
      commonMethods
        .getUserDropdownByCompany(reportObj.companyID)
        .then((res) => {
          setUserDropdown(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
      commonMethods
        .getInactiveUsersDropdownByCompany(reportObj.companyID)
        .then((res) => {
          setInactiveUserDropdown(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
    }
  }, [reportObj.companyID]);

  useEffect(() => {
    setUserDropdown([]);
    setInactiveUserDropdown([]);
    setReportObj({ 
      ...reportObj, 
      employeeListStrArr: [],
      inactiveEmployeeListStrArr: []
    });
    setSwitchButton(false);
    if (reportType.groupCompany) {
      setReportObj({ 
        ...reportObj, 
        companyID: CommonMethods.EmptyGuid, 
        employeeListStrArr: [],
        inactiveEmployeeListStrArr:[]
      });
      commonMethods
        .getUserDropdownForGroupCompany()
        .then((res) => {
          setUserDropdown(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
      commonMethods
        .getInactiveUserDropdownByGroupCompany()
        .then((res) => {
          setInactiveUserDropdown(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
    }
  }, [reportType]);

  const getCompanyDropdownOptions = () => {
    commonMethods
      .getCompanyDropdownListByGcid()
      .then((res) => {
        setCompanyDropdown(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
        setErrorMsg(commonMethods.getErrorMessage(err));
      });
  };  

  function generateLeaveReport() {
    if (reportObj.employeeListStrArr.length === 0 && reportObj.inactiveEmployeeListStrArr.length === 0) {
      SetError("Please select employees.");
      return;
    }
    setExportFileName(
      "LeaveReport_" +
        commonMethods.displayDate_FileName(reportObj.startDate) +
        "_" +
        commonMethods.displayDate_FileName(reportObj.endDate)
    );
    statusRecordService
      .getLeaveReportDetails(reportObj)
      .then((res) => {
        res.forEach((report: any) => {
          if (report.startDate){
            report.startDate = (report.startDate.split("T"))[0];
          }
          if (report.endDate){
            report.endDate = (report.endDate.split("T"))[0];
          }
          if (report.requestedDate){
            report.requestedDate = (report.requestedDate.split("T"))[0];
          }
          if (report.approvedDate){
            report.approvedDate = (report.approvedDate.split("T"))[0];
          }
        });
        setReportData(res);
      })
      .catch((err) => {
        SetError("Unable to Export Leave Report");
      });
  }


  const companyDropdownDisplay = () => {
    if (reportType.company) {
      return (
        <div className="p-col-12">
          <span className="boldLabel">Company</span>
          <Dropdown
            value={reportObj.companyID}
            options={companyDropdown}
            onChange={(e) => {
              setReportObj({
                ...reportObj,
                companyID: e.value,
              });
            }}
            placeholder="Please Select"
            required
          />
        </div>
      );
    }
  };

  const displayLeaveReportPart = () => {
    return (
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="card">
            <h5 className="pb-4">Leave Report</h5>
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <span className="boldLabel">
                  Type <span style={{ color: "red" }}>*</span>
                </span>
                <div className="p-grid p-fluid pt-1">
                  <div className="p-col-12 p-field-radiobutton">
                    <RadioButton
                      inputId="GroupCompany"
                      value={true}
                      name="GroupCompany"
                      onChange={(e) => {
                        setReportType({
                          ...reportType,
                          groupCompany: true,
                          company: false,
                        });
                      }}
                      checked={reportType.groupCompany === true}
                    />
                    <label
                      htmlFor="GroupCompany"
                      className="ml-1"
                    >
                      Group Company
                    </label>

                    <RadioButton
                      inputId="Company"
                      value={true}
                      className="ml-3"
                      name="Company"
                      onChange={(e) => {
                        setReportType({
                          ...reportType,
                          groupCompany: false,
                          company: true,
                        });
                      }}
                      checked={reportType.company === true}
                    />
                    <label htmlFor="Company" className="ml-1">
                      Company
                    </label>
                  </div>
                </div>
              </div>
              {companyDropdownDisplay()}
              <div className="p-col-12">
                <span className="boldLabel">Employees</span>
                <MultiSelect
                  value={reportObj.employeeListStrArr}
                  options={userDropdown}
                  onChange={(e) => {
                    setReportObj({
                      ...reportObj,
                      employeeListStrArr: e.value,
                    });
                  }}
                  filter={true}
                  placeholder="Please Select"
                  disabled={userDropdown.length === 0}
                />
              </div>
              
              <div className="p-col-12">
                <span className="boldLabel">Include deactivated employees?</span>
                <div className="field-checkbox">
                    <InputSwitch 
                      checked={switchButton} 
                      onChange={(e) => {
                        setSwitchButton(e.value)
                        setReportObj({
                          ...reportObj,
                          inactiveEmployeeListStrArr: new Array<string>(),
                        });
                      }}
                      disabled={userDropdown.length ===0}
                    />
                </div>
              </div>
              
              {switchButton &&
                <div className="p-col-12">
                  <span className="boldLabel">Deactivated Employees</span>
                  <MultiSelect
                    value={reportObj.inactiveEmployeeListStrArr}
                    options={inactiveUserDropdown}
                    onChange={(e) => {
                      setReportObj({
                        ...reportObj,
                        inactiveEmployeeListStrArr: e.value,
                      });
                    }}
                    filter={true}
                    placeholder="Please Select"
                    disabled={inactiveUserDropdown.length === 0}
                  />
                </div>
              }

              <div className="p-col-12">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <span className="boldLabel">Start Date</span>
                    <Calendar
                      name="startDate"
                      dateFormat="dd/mm/yy"
                      value={new Date(reportObj.startDate)}
                      onChange={(e) => {
                        let start = commonMethods.setEndOfDayOfDate(
                          new Date(e.value.toString())
                        );

                        setReportObj({
                          ...reportObj,
                          startDate: start,
                        });
                      }}
                      required
                      disabled={userDropdown.length === 0}
                    ></Calendar>
                  </div>
                  <div className="col-12 col-lg-6">
                    <span className="boldLabel">End Date</span>
                    <Calendar
                      name="endDate"
                      dateFormat="dd/mm/yy"
                      value={new Date(reportObj.endDate)}
                      onChange={(e) => {
                        let end = commonMethods.setEndOfDayOfDate(
                          new Date(e.value.toString())
                        );

                        setReportObj({
                          ...reportObj,
                          endDate: end,
                        });
                      }}
                      required
                      disabled={userDropdown.length === 0}
                    ></Calendar>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12 pt-4">
              <div className="p-grid p-justify-center">
                <DataTable
                  style={{ display: "none" }}
                  value={reportData}
                  ref={(el) => {
                    test = el;
                  }}
                  exportFilename={exportFileName}
                >
                  {leaveHeaders.map((col, i) => {
                    return (
                      <Column
                        key={col.key}
                        field={col.key}
                        header={col.label}
                      />
                    );
                  })}
                </DataTable>
                <Button
                  className="p-button"
                  label="Download Report"
                  onClick={(e) => {
                    e.preventDefault();
                    generateLeaveReport();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  let page = <>{displayLeaveReportPart()}</>;

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return <>{display}</>;
}

export default LeaveReport;
