import _ from "lodash";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { ProgressSpinner } from "primereact/progressspinner";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Redirect, useParams } from "react-router";
import CampaignService from "../../service/CampaignService";
import ClientCompanyAndContactCreate from "../client/ClientCompanyAndContactCreate";
import {
  CommonMethods,
  RouteComponentProps,
  DropdownModel,
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { CampaignModel } from "./Campaign";
import ClientContactCreate from "../client/ClientContactCreate";
import { TaskList } from "../resources/ListOfTasks";

function CampaignCreate(props: RouteComponentProps<any>) {
  const campaignService = new CampaignService();
  let commonMethods = new CommonMethods();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [action, setAction] = useState("Create");
  const [campaignObj, setCampaignObj] = useState<CampaignModel>(
    new CampaignModel()
  );

  const [currencies, setCurrencies] = useState([]);
  const [clientDropdown, setClientDropdown] = useState<[]>([]);
  const [clientContactDropdown, setClientContactsDropdown] = useState();
  const [agentContactDropdown, setAgentContactsDropdown] = useState<[]>([]);
  const [campaignTypeDropdown, setCampaignTypeDropdown] = useState<[]>([]);
  const [companyDropdown, setCompanyDropdown] = useState<[]>([]);
  const [usersDropdown, setUsersDropdown] = useState<[]>([]);
  const [publisherDropdown, setPublisherDropdown] = useState<any[]>([]);
  const [campaignStatusDropdown, setCampaignStatusDropdown] = useState<
    DropdownModel[]
  >([]);

  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);

  const [hasSalesAccess] = useState(
    props.userProfile.taskList.includes(TaskList.salesaccess)
  );

  const [hasClientAccess] = useState(
    props.userProfile.taskList.includes(TaskList.clientaccess)
  );

  const [insightsrnaccess] = useState(
    props.userProfile.taskList.includes(TaskList.insightsrnaccess)
  );

  const [clientDialogVisibility, setClientDialogVisibility] = useState<boolean>(
    false
  );
  const [
    clientContactDialogVisibility,
    setClientContactDialogVisibility,
  ] = useState<boolean>(false);
  const [clientDialogHeader, setClientDialogHeader] = useState<string>("");
  const [clientContactCreationBox, setClientContactCreationBox] = useState(
    <></>
  );
  const [creationType, setCreationType] = useState<string>("client");

  const [isAdvertorial, setIsAdvertorial] = useState<boolean>(false);
  let { id } = useParams<{id:string}>();

  useEffect(() => {
    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") ||
      props.userProfile.companyID;

    if (id !== undefined) {
      // update
      campaignService
        .getCampaignByCampaignID(id)
        .then((res) => {
          setAction("Update");
          setCampaignObj(res);
          updateContactDropdown();
          // setIsLoading(false);
          getDropdown("update");
        })
        .catch((err) => {
          setErrorMessage(commonMethods.getErrorMessage(err));
          setIsError(true);
          setIsLoading(false);
        });
    } else {
      // create
      setCampaignObj({
        ...campaignObj,
        companyID: currentAccessingCompanyId,
      });
      getDropdown("create");
      // setIsLoading(false);
    }

    setRedirectPath("/campaign/summary/" + id);
  }, []);

  useEffect(() => {
    if (campaignTypeDropdown !== undefined && campaignTypeDropdown !== []) {
      let organicValue: any = _.find(campaignTypeDropdown, [
        "label",
        "Organic",
      ]);
      let adValue: any = _.find(campaignTypeDropdown, ["label", "Advertorial"]);

      // If organic, hide the agent client fields
      if (
        organicValue !== undefined &&
        organicValue.value === campaignObj.campaignTypeID
      ) {
        setIsAdvertorial(false);
        setCampaignObj({
          ...campaignObj,
          hasAgent: false,
          hasClient: false,
        });
      }

      if (
        adValue !== undefined &&
        adValue.value === campaignObj.campaignTypeID
      ) {
        setIsAdvertorial(true);
        if (campaignObj.hasAgent) {
          setCampaignObj({
            ...campaignObj,
            hasAgent: true,
            hasClient: true,
          });
        } else {
          setCampaignObj({
            ...campaignObj,
            hasAgent: false,
            hasClient: true,
          });
        }
      }
    }
  }, [campaignObj.campaignTypeID, campaignTypeDropdown]);

  useEffect(() => {
    updateContactDropdown();
  }, [campaignObj.hasAgent, campaignObj.hasClient]);

  const updateContactDropdown = () => {
    if (campaignObj.hasAgent) {
      getAgentContactDropdown(campaignObj.agentCompanyID || "");
    }
    if (campaignObj.hasClient) {
      getClientContactDropdown(campaignObj.clientCompanyID || "");
    }
  };

  // With None option
  const getClientContactDropdown = (clientCompanyID: string) => {
    if (clientCompanyID !== "") {
      commonMethods
        .getClientContactDropdownWithNone(clientCompanyID)
        .then((res) => {
          setClientContactsDropdown(res);
        });
    }
  };
  // AgencyContact has no None option because if have agency means will have agency contact but maybe no client contact.
  const getAgentContactDropdown = (agentCompanyID: string) => {
    if (agentCompanyID !== "") {
      commonMethods.getClientContactDropdown(agentCompanyID).then((res) => {
        setAgentContactsDropdown(res);
      });
    }
  };

  const getDropdown = async (actionType: string) => {
    try {
      await commonMethods.getClientDropdown().then((res) => {
        setClientDropdown(res);
      });

      await commonMethods.getCurrencyDropdown().then((res) => {
        res = commonMethods.addCommonCurrencies(res);
        setCurrencies(res);
      });

      await commonMethods.getCampaignTypeDropdown().then((res) => {
        if (actionType === "create") {
          var Aditem = _.find(res, ["label", "Advertorial"]);
          let salesPersonArr = [props.userProfile.userID];
          setCampaignObj({
            ...campaignObj,
            campaignTypeID: Aditem?.value,
            hasClient: true,
            currency: "SGD",
            salesPersonsIDStrArr: salesPersonArr,
          });
        }

        setCampaignTypeDropdown(res);
      });

      await commonMethods.getUserDropdownForGroupCompany().then((res) => {
        setUsersDropdown(res);
      });

      setCampaignStatusDropdown(commonMethods.getCampaignStatusDropdown());

      await commonMethods.getCompanyDropdownListByGcid().then((res) => {
        setCompanyDropdown(res);
      });

      await commonMethods.getPublisherDropdown().then((res) => {
        setPublisherDropdown(res);
      });

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(commonMethods.getErrorMessage(e));
      setIsError(true);
    }
  };

  const haveSalesAccessForCreation = () => {
    if (!(hasSalesAccess || hasClientAccess)) {
      SetError("Client creation access is required.");
      return false;
    }
    return true;
  };

  const clientSelectedActions = (clientcompanyid: string, type: string) => {
    if (type === "agency") {
      setCampaignObj({
        ...campaignObj,
        agentCompanyID: clientcompanyid,
      });
      getAgentContactDropdown(clientcompanyid);
      setClientContactCreationBox(
        <ClientContactCreate
          {...props}
          others={{
            clientCompanyID: clientcompanyid,
            clientContactCallback: agentContactCallback,
          }}
        />
      );
    } else {
      setCampaignObj({
        ...campaignObj,
        clientCompanyID: clientcompanyid,
      });
      getClientContactDropdown(clientcompanyid);
      setClientContactCreationBox(
        <ClientContactCreate
          {...props}
          others={{
            clientCompanyID: clientcompanyid,
            clientContactCallback: clientContactCallback,
          }}
        />
      );
    }
  };

  // Callbacks from client company/contact creation shortcut.
  const clientCallback = (companyid: string) => {
    if (companyid !== null) {
      SetSuccess("Client Company added to selection list.");
      // Set as selected
      clientSelectedActions(companyid, creationType);

      // Refresh the dropdown
      commonMethods
        .getClientDropdown()
        .then((res) => {
          setClientDropdown(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMessage(commonMethods.getErrorMessage(err));
        });
    } else {
      SetError("Failed to create Client Company");
    }
    setClientDialogVisibility(false);
  };

  const clientContactCallback = (isSuccess: boolean, id: string) => {
    if (isSuccess) {
      SetSuccess("Client Contact added to selection list.");
      // Refresh the dropdown
      getClientContactDropdown(id);
    } else {
      SetError("Failed to create Client Contact");
    }
    setClientContactDialogVisibility(false);
    setClientContactCreationBox(<></>);
  };

  const agentContactCallback = (isSuccess: boolean, id: string) => {
    if (isSuccess) {
      SetSuccess("Agency Contact added to selection list.");
      // Refresh the dropdown
      getAgentContactDropdown(id);
    } else {
      SetError("Failed to create Agency Contact");
    }
    setClientContactDialogVisibility(false);
    setClientContactCreationBox(<></>);
  };

  const cleanupForm = (tempCampObj: CampaignModel) => {
    if (!campaignObj.hasAgent) {
      tempCampObj.agentCompanyID = null;
      tempCampObj.agentContactsID = null;
    }

    if (!campaignObj.hasClient) {
      tempCampObj.clientCompanyID = null;
      tempCampObj.clientContactsID = null;
    }
    if (!isAdvertorial) {
      tempCampObj.salesPersonsIDStrArr = [];
    }
    return tempCampObj;
  };

  const formValidation = () => {
    if (isAdvertorial && !campaignObj.hasAgent && !campaignObj.hasClient) {
      SetError(
        "There must be an agent/client if the campaign type is Advertorial."
      );
      return false;
    }
    // if (campaignObj.campaignProjectManagersIDStrArr.length === 0) {
    //   SetError("Please select a Project Manager");
    //   return false;
    // }
    if (campaignObj.salesPersonsIDStrArr.length === 0 && isAdvertorial) {
      SetError("Please select a Sales Person");
      return false;
    }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // process dates and field validations
    let tempCampObj = campaignObj;
    if (!formValidation()) {
      return;
    }

    tempCampObj = cleanupForm(tempCampObj);

    if (action === "Update") {
      campaignService
        .updatecampaign(tempCampObj)
        .then((res) => {
          SetSuccess(campaignObj.campaignName + " has been updated.");
          setRedirectPath("/campaign/summary/" + tempCampObj.campaignID);
          setRedirectOut(true);
        })
        .catch((err) => {
          if (commonMethods.isWarning(err)) {
            // failed to create at irn
            const error = commonMethods.getErrorMessage(err);
            SetError(error);
            setRedirectPath(
              "/campaign/summary/" + err.response.data.campaignID
            );
            setRedirectOut(true);
          } else {
            const error = commonMethods.getErrorMessage(err);
            SetError(error);
          }
        });
    } else if (action === "Create") {
      campaignService
        .createcampaign(tempCampObj)
        .then((res) => {
          SetSuccess(campaignObj.campaignName + " has been created.");
          setRedirectPath("/campaign/summary/" + res.campaignID);
          setRedirectOut(true);
        })
        .catch((err) => {
          if (commonMethods.isWarning(err)) {
            // failed to create at irn
            const error = commonMethods.getErrorMessage(err);
            SetError(error);
            setRedirectPath(
              "/campaign/summary/" + err.response.data.campaignID
            );
            setRedirectOut(true);
          } else {
            const error = commonMethods.getErrorMessage(err);
            SetError(error);
          }
        });
    }
  };

  let salesFields = (
    <>
      <div className="p-col-12">
        <span className="boldLabel">
          Campaign Currency <span style={{ color: "red" }}>*</span>
        </span>
        <Dropdown
          required
          options={currencies}
          placeholder="Please Select"
          value={campaignObj.currency}
          onChange={(e) => {
            setCampaignObj({
              ...campaignObj,
              currency: e.value,
            });
          }}
        />
      </div>
      <div className="p-col-12">
        <span className="boldLabel">Campaign Amount</span>
        <NumberFormat
          className="p-inputtext"
          value={campaignObj.campaignAmount}
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          onValueChange={(values) => {
            setCampaignObj({
              ...campaignObj,
              campaignAmount: values.floatValue!,
            });
          }}
        />
      </div>

      {/* <div className="p-col-12">
        <span className="boldLabel">Tax Value</span>
        <NumberFormat
          className="p-inputtext"
          value={campaignObj.gstValue}
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          onValueChange={values => {
            setCampaignObj({
              ...campaignObj,
              gstValue: values.floatValue
            });
          }}
        />
      </div> */}
    </>
  );

  let agentSalesView = (
    <div className="p-col-12">
      <span className="boldLabel">Has Agent</span>

      <div className="p-grid p-col-12 p-1">
        <div className="p-col-4 pb-1 p-field-radiobutton">
          <RadioButton
            inputId="YesAgent"
            value={true}
            name="Yes"
            onChange={(e) => {
              // updateContactDropdown();
              setCampaignObj({
                ...campaignObj,
                hasAgent: e.value,
                hasClient: e.value,
              });
            }}
            checked={campaignObj.hasAgent === true}
          />
          <label htmlFor="Yes" className="ml-1">
            Yes
          </label>
        </div>
        <div className="p-col-4 pb-1 p-field-radiobutton">
          <RadioButton
            inputId="NoAgent"
            value={false}
            name="No"
            onChange={(e) => {
              // updateContactDropdown();
              setCampaignObj({
                ...campaignObj,
                hasAgent: e.value,
              });
            }}
            checked={campaignObj.hasAgent === false}
          />
          <label htmlFor="No" className="ml-1">
            No
          </label>
        </div>
      </div>
    </div>
  );

  let clientSalesView = (
    <div className="p-col-12">
      <span className="boldLabel">Has Client</span>

      <div className="p-grid p-col-12 p-1">
        <div className="p-col-4 pb-1 p-field-radiobutton">
          <RadioButton
            inputId="Yes"
            value={true}
            name="Yes"
            onChange={(e) => {
              // updateContactDropdown();
              setCampaignObj({
                ...campaignObj,
                hasClient: e.value,
              });
            }}
            checked={campaignObj.hasClient === true}
          />
          <label htmlFor="Yes" className="ml-1">
            Yes
          </label>
        </div>
        <div className="p-col-4 pb-1 p-field-radiobutton">
          <RadioButton
            disabled={campaignObj.hasAgent}
            inputId="No"
            value={false}
            name="No"
            onChange={(e) => {
              // updateContactDropdown();
              setCampaignObj({
                ...campaignObj,
                hasClient: e.value,
              });
            }}
            checked={campaignObj.hasClient === false}
          />
          <label htmlFor="No" className="ml-1">
            No
          </label>
        </div>
      </div>
    </div>
  );

  let agentFields = (
    <>
      <div className="p-col-8 p-sm-10">
        <span className="boldLabel">
          Agency Company <span style={{ color: "red" }}>*</span>
        </span>
        <Dropdown
          filter={true}
          filterPlaceholder="Search"
          filterBy="label,value"
          // showClear={true}
          value={campaignObj.agentCompanyID}
          options={clientDropdown}
          onChange={(e) => {
            clientSelectedActions(e.value, "agency");

            // getAgentContactDropdown(e.value);
            // setCampaignObj({
            //   ...campaignObj,
            //   agentCompanyID: e.value,
            // });
            // setClientContactCreationBox(
            //   <ClientContactCreate
            //     {...props}
            //     others={{
            //       clientCompanyID: e.value,
            //       clientContactCallback: agentContactCallback,
            //     }}
            //   />
            // );
          }}
          placeholder="Please Select"
          required
          scrollHeight="350px"
        />
      </div>
      <div className="p-col-4 p-sm-2">
        <span className="boldLabel">Add New</span>
        <Button
          type="button"
          iconPos="right"
          label="Add"
          className="p-button-danger"
          onClick={() => {
            if (haveSalesAccessForCreation()) {
              setClientDialogHeader("Create Agency");
              setCreationType("agency");
              setClientDialogVisibility(true);
            }
          }}
        />
      </div>

      <div className="p-col-8 p-sm-10 ">
        <span className="boldLabel">
          Agency Contact <span style={{ color: "red" }}>*</span>
        </span>
        <Dropdown
          value={campaignObj.agentContactsID}
          options={agentContactDropdown}
          onChange={(e) =>
            setCampaignObj({
              ...campaignObj,
              agentContactsID: e.value,
            })
          }
          required
          placeholder="Please Select"
        />
      </div>

      <div className="p-col-4 p-sm-2">
        <span className="boldLabel">Add New</span>
        <Button
          type="button"
          iconPos="right"
          label="Add"
          className="p-button-danger"
          onClick={() => {
            if (haveSalesAccessForCreation()) {
              setClientContactDialogVisibility(true);
            }
          }}
          disabled={campaignObj.agentCompanyID === null}
        />
      </div>
    </>
  );

  let clientFields = (
    <>
      <div className="p-col-8 p-sm-10 ">
        <span className="boldLabel">
          Client Company <span style={{ color: "red" }}>*</span>
          {insightsrnaccess && <span className="text-info">* </span>}
        </span>
        <Dropdown
          filter={true}
          filterPlaceholder="Search"
          filterBy="label,value"
          // showClear={true}
          value={campaignObj.clientCompanyID}
          options={clientDropdown}
          onChange={(e) => {
            clientSelectedActions(e.value, "client");

            // getClientContactDropdown(e.value);
            // setCampaignObj({
            //   ...campaignObj,
            //   clientCompanyID: e.value,
            // });
            // setClientContactCreationBox(
            //   <ClientContactCreate
            //     {...props}
            //     others={{
            //       clientCompanyID: e.value,
            //       clientContactCallback: clientContactCallback,
            //     }}
            //   />
            // );
          }}
          placeholder="Please Select"
          required
          scrollHeight="350px"
        />
      </div>
      <div className="p-col-4 p-sm-2">
        <span className="boldLabel">Add New</span>
        <Button
          type="button"
          iconPos="right"
          label="Add"
          className="p-button-danger"
          onClick={() => {
            if (haveSalesAccessForCreation()) {
              setClientDialogHeader("Create Client");
              setCreationType("client");
              setClientDialogVisibility(true);
            }
          }}
        />
      </div>
      <div className="p-col-8 p-sm-10 ">
        <span className="boldLabel">
          Client Contact <span style={{ color: "red" }}>*</span>
        </span>
        <Dropdown
          value={campaignObj.clientContactsID}
          options={clientContactDropdown}
          onChange={(e) =>
            setCampaignObj({
              ...campaignObj,
              clientContactsID: e.value,
            })
          }
          placeholder="Please Select"
          required
        />
      </div>
      <div className="p-col-4 p-sm-2">
        <span className="boldLabel">Add New</span>
        <Button
          type="button"
          iconPos="right"
          label="Add"
          className="p-button-danger"
          onClick={() => {
            if (haveSalesAccessForCreation()) {
              setClientContactDialogVisibility(true);
            }
          }}
          disabled={campaignObj.clientCompanyID === null}
        />
      </div>
    </>
  );
  let closedByDetails = (
    <>
      <div className="p-col-12">
        <span className="boldLabel">Closed Date</span>
        <Calendar
          dateFormat="dd/mm/yy"
          value={new Date(campaignObj.closedDate)}
          onChange={(e) => {
            setCampaignObj({
              ...campaignObj,
              closedDate: new Date(e.value.toString()),
            });
          }}
          showButtonBar={true}
          showIcon={true}
          monthNavigator={true}
          yearNavigator={true}
          yearRange="1900:2030"
          // maxDate={this.state.maxDate}
          showTime={false}
          readOnlyInput={true}

        />
      </div>

      <div className="p-col-12">
        <span className="boldLabel">Closed By</span>
        <Dropdown
          value={campaignObj.closedBy}
          options={usersDropdown}
          onChange={(e) =>
            setCampaignObj({
              ...campaignObj,
              closedBy: e.value,
            })
          }
          placeholder="Please Select"
        />
      </div>
    </>
  );

  let irnPage = (
    <form onSubmit={handleSubmit}>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <span className="boldLabel">
            Company <span style={{ color: "red" }}>*</span>
          </span>
          <Dropdown
            value={campaignObj.companyID}
            options={companyDropdown}
            onChange={(e) =>
              setCampaignObj({
                ...campaignObj,
                companyID: e.value,
              })
            }
            placeholder="Please Select"
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Campaign Name <span style={{ color: "red" }}>*</span>
            <span className="text-info">* </span>
          </span>
          <InputText
            value={campaignObj.campaignName}
            onChange={(e) =>
              setCampaignObj({
                ...campaignObj,
                campaignName: e.currentTarget.value,
              })
            }
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Campaign Objective</span>{" "}
          <span className="text-info">* </span>
          <InputText
            value={campaignObj.campaignObjective}
            onChange={(e) =>
              setCampaignObj({
                ...campaignObj,
                campaignObjective: e.currentTarget.value,
              })
            }
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Campaign Type <span style={{ color: "red" }}>*</span>
          </span>
          <Dropdown
            value={campaignObj.campaignTypeID}
            options={campaignTypeDropdown}
            onChange={(e) =>
              setCampaignObj({
                ...campaignObj,
                campaignTypeID: e.value,
              })
            }
            placeholder="Please Select"
            required
          />
        </div>

        {isAdvertorial ? agentSalesView : ""}
        {isAdvertorial && campaignObj.hasAgent ? agentFields : ""}

        {isAdvertorial ? clientSalesView : ""}
        {isAdvertorial && campaignObj.hasClient ? clientFields : ""}

        {isAdvertorial && hasSalesAccess ? salesFields : ""}

        {isAdvertorial && campaignObj.hasClient && (
          <div className="p-col-12">
            <span className="boldLabel">Sales Person</span>
            <span className="text-info">* </span>
            <span style={{ color: "red" }}>*</span>

            <MultiSelect
              value={campaignObj.salesPersonsIDStrArr}
              options={usersDropdown}
              onChange={(e) => {
                setCampaignObj({
                  ...campaignObj,
                  salesPersonsIDStrArr: e.value,
                });
              }}
              filter={true}
              placeholder="Please Select"
              scrollHeight="350px"
            />
          </div>
        )}

        <div className="p-col-12">
          <span className="boldLabel">
            Publisher <span className="text-info">* </span>
          </span>
          <Dropdown
            filter={true}
            filterPlaceholder="Search Publisher"
            filterBy="label,value"
            value={campaignObj.publisherID}
            options={publisherDropdown}
            onChange={(e) => {
              var pubItem:
                | { label: string; value: string }
                | undefined = _.find(publisherDropdown, ["value", e.value]);
              setCampaignObj({
                ...campaignObj,
                publisherID: e.value,
                publisherName: pubItem!.label,
              });
            }}
            placeholder="Please Select"
            // required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Campaign Status <span style={{ color: "red" }}>*</span>
          </span>
          <Dropdown
            value={campaignObj.campaignStatus}
            options={campaignStatusDropdown}
            onChange={(e) => {
              setCampaignObj({
                ...campaignObj,
                campaignStatus: e.value,
              });
              if (e.value === "Published") {
                setCampaignObj({
                  ...campaignObj,
                  closedDate: new Date(),
                  campaignStatus: e.value,
                });
              }
            }}
            placeholder="Please Select"
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Start Date <span style={{ color: "red" }}>*</span>
          </span>
          <Calendar
            dateFormat="dd/mm/yy"
            value={new Date(campaignObj.startDate)}
            onChange={(e) => {
              setCampaignObj({
                ...campaignObj,
                startDate: new Date(e.value.toString()),
              });
            }}
            showButtonBar={true}
            showIcon={true}
            monthNavigator={true}
            yearNavigator={true}
            yearRange="1900:2030"
            // maxDate={this.state.maxDate}
            showTime={false}
            readOnlyInput={true}
            required

          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">End Date </span>
          <Calendar
            dateFormat="dd/mm/yy"
            value={new Date(campaignObj.endDate)}
            onChange={(e) => {
              setCampaignObj({
                ...campaignObj,
                endDate: new Date(e.value.toString()),
              });
            }}
            showButtonBar={true}
            showIcon={true}
            monthNavigator={true}
            yearNavigator={true}
            yearRange="1900:2030"
            // maxDate={this.state.maxDate}
            showTime={false}
            readOnlyInput={true}

          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Project Managers
          </span>
          <MultiSelect
            value={campaignObj.campaignProjectManagersIDStrArr}
            options={usersDropdown}
            onChange={(e) =>
              setCampaignObj({
                ...campaignObj,
                campaignProjectManagersIDStrArr: e.value,
              })
            }
            filter={true}
            placeholder="Please Select"
            scrollHeight="350px"
          />
        </div>

        {campaignObj.campaignStatus === "Published" ? closedByDetails : ""}
      </div>

      <div className="p-grid p-justify-center pt-2">
        <Button type="submit" label={action} />
      </div>
    </form>
  );

  let normalPage = (
    <form onSubmit={handleSubmit}>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <span className="boldLabel">
            Company <span style={{ color: "red" }}>*</span>
          </span>
          <Dropdown
            value={campaignObj.companyID}
            options={companyDropdown}
            onChange={(e) =>
              setCampaignObj({
                ...campaignObj,
                companyID: e.value,
              })
            }
            placeholder="Please Select"
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Campaign Name <span style={{ color: "red" }}>*</span>
          </span>
          <InputText
            value={campaignObj.campaignName}
            onChange={(e) =>
              setCampaignObj({
                ...campaignObj,
                campaignName: e.currentTarget.value,
              })
            }
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Campaign Type <span style={{ color: "red" }}>*</span>
          </span>
          <Dropdown
            value={campaignObj.campaignTypeID}
            options={campaignTypeDropdown}
            onChange={(e) =>
              setCampaignObj({
                ...campaignObj,
                campaignTypeID: e.value,
              })
            }
            placeholder="Please Select"
            required
          />
        </div>

        {isAdvertorial ? agentSalesView : ""}
        {isAdvertorial && campaignObj.hasAgent ? agentFields : ""}

        {isAdvertorial ? clientSalesView : ""}
        {isAdvertorial && campaignObj.hasClient ? clientFields : ""}

        {isAdvertorial && hasSalesAccess && campaignObj.hasClient
          ? salesFields
          : ""}

        {isAdvertorial && campaignObj.hasClient && (
          <div className="p-col-12">
            <span className="boldLabel">Sales Person</span>

            <MultiSelect
              value={campaignObj.salesPersonsIDStrArr}
              options={usersDropdown}
              onChange={(e) => {
                setCampaignObj({
                  ...campaignObj,
                  salesPersonsIDStrArr: e.value,
                });
              }}
              filter={true}
              placeholder="Please Select"
              scrollHeight="350px"
            />
          </div>
        )}

        <div className="p-col-12">
          <span className="boldLabel">
            Campaign Status <span style={{ color: "red" }}>*</span>
          </span>
          <Dropdown
            value={campaignObj.campaignStatus}
            options={campaignStatusDropdown}
            onChange={(e) => {
              setCampaignObj({
                ...campaignObj,
                campaignStatus: e.value,
              });
              if (e.value === "Published") {
                setCampaignObj({
                  ...campaignObj,
                  closedDate: new Date(),
                  campaignStatus: e.value,
                });
              }
            }}
            placeholder="Please Select"
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Start Date <span style={{ color: "red" }}>*</span>
          </span>
          <Calendar
            dateFormat="dd/mm/yy"
            value={new Date(campaignObj.startDate)}
            onChange={(e) => {
              setCampaignObj({
                ...campaignObj,
                startDate: new Date(e.value.toString()),
              });
            }}
            showButtonBar={true}
            showIcon={true}
            monthNavigator={true}
            yearNavigator={true}
            yearRange="1900:2030"
            // maxDate={this.state.maxDate}
            showTime={false}
            readOnlyInput={true}
            required

          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">End Date </span>
          <Calendar
            dateFormat="dd/mm/yy"
            value={new Date(campaignObj.endDate)}
            onChange={(e) => {
              setCampaignObj({
                ...campaignObj,
                endDate: new Date(e.value.toString()),
              });
            }}
            showButtonBar={true}
            showIcon={true}
            monthNavigator={true}
            yearNavigator={true}
            yearRange="1900:2030"
            // maxDate={this.state.maxDate}
            showTime={false}
            readOnlyInput={true}

          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Project Managers <span style={{ color: "red" }}>*</span>
          </span>
          <MultiSelect
            value={campaignObj.campaignProjectManagersIDStrArr}
            options={usersDropdown}
            onChange={(e) =>
              setCampaignObj({
                ...campaignObj,
                campaignProjectManagersIDStrArr: e.value,
              })
            }
            filter={true}
            placeholder="Please Select"
            scrollHeight="350px"
          />
        </div>

        {campaignObj.campaignStatus === "Published" ? closedByDetails : ""}
      </div>

      <div className="p-grid p-justify-center pt-2">
        <Button type="submit" label={action} />
      </div>
    </form>
  );
  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    if (insightsrnaccess && isAdvertorial) {
      display = (
        <>
          <div className="pb-2">
            <span className="requiredRed">* Required</span>{" "}
            <span className="text-info px-2">
              * Required for iNSIGHTSRN Report
            </span>
          </div>
          <div className="row p-justify-center">
            <div className="col-12">{irnPage}</div>
          </div>
        </>
      );
    } else {
      display = (
        <>
          <div className="pb-2">
            <span className="requiredRed">* Required</span>{" "}
          </div>
          <div className="row p-justify-center">
            <div className="col-12">{normalPage}</div>
          </div>
        </>
      );
    }
  }

  return (
    <>
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      <div className="row">
        <div className="col-12 col-md-12 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{action} Campaign</h1>

            {display}
          </div>
        </div>
      </div>

      <Dialog
        header={clientDialogHeader}
        visible={clientDialogVisibility}
        modal={true}
        //dismissableMask={true}
        onHide={() => setClientDialogVisibility(false)}
      >
        {
          <ClientCompanyAndContactCreate
            {...props}
            others={{
              display: "minimal",
              clientCallback: clientCallback,
            }}
          />
        }
      </Dialog>

      <Dialog
        header={"Create Contact"}
        visible={clientContactDialogVisibility}
        modal={true}
        //dismissableMask={true}
        onHide={() => {
          setClientContactDialogVisibility(false);
          setClientContactCreationBox(<></>);
        }}
      >
        {clientContactCreationBox}
      </Dialog>
    </>
  );
}

export default CampaignCreate;
