import React, { useEffect, useState } from "react";
import { RouteComponentProps, CommonMethods, ParamIDTypes } from "../resources/CommonMethods";
import LinkPerformanceDeptView from "./linkperformance/LinkPerformanceDeptView";
import { useParams } from "react-router";
import { DepartmentService } from "../../service/DepartmentService";
import { CompanyDepartmentModel } from "../settings/MultiDeptSetting";
import CustomError from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";

function LinkPerformanceBossView(props: RouteComponentProps<any>) {
  const { id } = useParams<ParamIDTypes>();
  const [deptCompanyDetails, setDeptCompanyDetails] = useState<
    CompanyDepartmentModel
  >(new CompanyDepartmentModel());
  const deptService = new DepartmentService();
  const commonMethods = new CommonMethods();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrorMsg] = useState<string>("An error has occurred");

  useEffect(() => {
    if (id !== undefined && id !== "") {
      getDeptDetails(id);
    }
  }, []);

  const getDeptDetails = (deptid: string) => {
    deptService
      .getDeptAndCompanyNames(deptid)
      .then((res) => {
        setDeptCompanyDetails(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
        setErrorMsg(commonMethods.getErrorMessage(err));
      });
  };

  let display;
  if (isError) {
    display = <CustomError message={errMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = (
      <LinkPerformanceDeptView
        {...props}
        others={deptCompanyDetails.departmentID}
      />
    );
  }
  
  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">
            Link Performance [{deptCompanyDetails.companyName}]{" "}
            {deptCompanyDetails.departmentName}
          </h1>
          {display}
        </div>
      </div>
    </div>
  );
}

export default LinkPerformanceBossView;
