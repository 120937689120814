import { InputTextarea } from "primereact/inputtextarea";
import { CommentModel } from "./CommentModel";

export class PostModel {
    postId: string = '';
    userId: string | null = null;
    postText: string | null = '';
    imagePath: string | null = null;
    imagePathArray: Array<string> | null = null;
    usersTagged: string | null = null;
    usersTaggedArray: Array<string> | null = null;
    isEdited: boolean = false;
    createdTime: Date = new Date();
    lastUpdated: Date | null = null;
    firstName: string = '';
    lastName: string = '';
    displayPicture: string | null = null;
    usersWhoLiked: string | null = null;
    usersWhoLikedArray: Array<string> | null = null;
    comments: CommentModel[] | null = null;
    numberOfComments: number = 0;
    type: string = "Feed"
    // birthdays: BirthdayModel[] = []
    // birthdayPersonsDetails: UserDetails[] = []
    rawEditorContentJsonStr: string | null = null
    isLikedByUser: boolean = false
    previouslyLikedByUser: boolean = false
}

export interface PostResponseModel {
    totalPosts: number;
    posts: PostModel[];
}

export class ImageFile extends File {
    objectURL?: string = "";
}

export class UserPreview {
    userId: string = '';
    firstName: string = ""
    fullName: string = ""
    lastName: string = ""
    displayPicture: string | null = ""
    email: string = ""
    jobTitle: string = ""
    userRankName: string = ""
    companyName: string = ""
}

export class PreviewProps {
    cachePreview: UserPreview[] = []
    isElementAboveMiddleVH: boolean = true
    // used to get position where to load ProfilePreview
    previewPosition: DOMRect | null = null
    loadPreviewIdentifier: string = ""
}

export class UserProfile {
    companyName: string = ""
    companyLogo: string = ""
    dateOfBirth: Date | null = null
    departmentName: string = ""
    displayPicture: string | null = null
    email: string = ""
    firstName: string = ""
    fullName: string = ""
    jobTitle: string = ""
    lastName: string = ""
    userId: string = ""
    userRankName: string = ""
    race: string | null = null
    religion: string | null = null
    nationality: string | null = null
    aboutMe: string | null = null
    interestsHobbies: string | null = null
    hasCreatedPublicProfile: boolean = false
    isEditable: boolean = false
    telegramUsername: string | null = null
    publicProfilePermission: boolean = false
}

export class UserDetails {
    userId: string = ""
    firstName: string = ""
    lastName: string = ""
    displayPicture: string | null = null
    hasCompanyFeedAdminAccess: boolean = false
}

export class BirthdayModel {
    birthdayId: string = '';
    postId: string = '';
    userId: string = ''
    birthdayDate: Date = new Date()
    createdTime: Date = new Date();
    lastUpdated: Date | null = null;
}

export interface PrimeInputTextarea extends InputTextarea {
    cachedScrollHeight: number
    element: HTMLTextAreaElement
}