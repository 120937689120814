import { Button } from 'primereact/button'
import React from 'react'
import { useHistory } from 'react-router';
import errorImg from "../../../../assets/images/404_image.jpg"

export default function CompanyFeedErrorPage() {
    const history = useHistory();
    let errorMsg = "This Content Isn't Available Right Now"

    function goBackToFeed() {
        history.push(`/companyfeed`)
    }

    return (
        <div className="card p-4">
            <div className="text-center">
                <img src={errorImg} width="50%" alt="404 ERROR" className="mb-4" />
                <h4>
                    <b>Oops! Something went wrong</b>
                </h4>
                <h5>{errorMsg}</h5>
                <p>
                    The page you are looking for might have been moved, removed, renamed
                    or never existed.
                </p>
                <p>
                    If that's not the case, refresh the page or re-login to try again.
                </p>
                <Button
                    type="button"
                    label="Go to Company Feed"
                    className="error-back-button"
                    onClick={goBackToFeed}
                />
                <br />
            </div>
        </div>
    )
}