import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { StatusRecordService } from "../../service/StatusRecordService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { TaskList } from "../resources/ListOfTasks";
import {
  StatusRecordDisplayObj,
  StatusRecordDocumentObj
} from "./LeaveApplicationForm";

type ApprovalAppState = {
  statusRecords: Array<StatusRecordDocumentObj>;
  visible: boolean;
  recordID: string;
  recipientUserID: string;
  dialogstr: any;
  confirmstr: string;
  selectedRecords: Array<StatusRecordDocumentObj>;
  departmentDropdownList: any[];
  department: string | null;
  globalFilter: string;
  deptFilter: any;
  dateFilter: string | Date;
  isLoading: boolean;
  isError: boolean;
  errorMsg: string;
  hideProgressBar: boolean;
  approvingRemarks: string;

  infoDialogHeader: string;
  infoDialogDisplay: boolean;
  infoDialogStr: any;
  dt: any;
};

export class ApproveOrRejectStatusRecord extends Component<
  RouteComponentProps<any>,
  ApprovalAppState
> {
  _isMounted = false;
  static contextType = ToastStateContext;
  statusRecordService: StatusRecordService;
  commonMethods: CommonMethods;
  dt: any;
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      statusRecords: new Array<StatusRecordDocumentObj>(),
      visible: false,
      recordID: "",
      recipientUserID: "",
      dialogstr: <></>,
      confirmstr: "",
      selectedRecords: new Array<StatusRecordDocumentObj>(),
      departmentDropdownList: [{ label: "Tech", value: "Tech" }],
      department: null,
      globalFilter: "",
      deptFilter: null,
      dateFilter: new Date(),
      isLoading: true,
      hideProgressBar: true,
      isError: false,
      errorMsg: "",
      approvingRemarks: "",
      infoDialogHeader: "",
      infoDialogDisplay: false,
      infoDialogStr: <></>,
      dt: React.createRef(),
    };
    this.statusRecordService = new StatusRecordService();
    this.commonMethods = new CommonMethods();
    this.createdDateTemplate = this.createdDateTemplate.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.statusTemplate = this.statusTemplate.bind(this);

    this.handleMultiApprovalClick = this.handleMultiApprovalClick.bind(this);
    this.handleMultiRejectionClick = this.handleMultiRejectionClick.bind(this);
    this.handleMultiRecordApproval = this.handleMultiRecordApproval.bind(this);
    this.handleMultiRecordRejection = this.handleMultiRecordRejection.bind(
      this
    );

    this.getPendingRecords = this.getPendingRecords.bind(this);

    this.handleApprovalClick = this.handleApprovalClick.bind(this);
    this.handleRejectionClick = this.handleRejectionClick.bind(this);
    this.handleConfirmApproval = this.handleConfirmApproval.bind(this);
    this.handleConfirmRejection = this.handleConfirmRejection.bind(this);
    this.rejectStatusRecord = this.rejectStatusRecord.bind(this);
    this.approveStatusRecord = this.approveStatusRecord.bind(this);
    this.isAdmin = this.isAdmin.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;

    if (this.isAdmin()) {
      this.commonMethods
        .getDepartmentDropdownListForFiltering(
          localStorage.getItem("currentAccessingCompany") || ""
        )
        .then((res) => {
          if (this._isMounted) {
            this.setState({
              departmentDropdownList: res
            });
          }
        })
        .catch((error) => {
          if (this._isMounted) {
            let errorMsg = this.commonMethods.getErrorMessage(error);
            this.setState({
              isError: true,
              isLoading: false,
              errorMsg: errorMsg,
            });
            return errorMsg;
          }
        });

      if (this._isMounted) {
        this.statusRecordService
          .getCompanyWidePendingRecords(localStorage.currentAccessingCompany)
          .then((res) => {
            if (this._isMounted) {
              this.setState({ statusRecords: res }, () => {
                this.setState({ isLoading: false });
              });
            }
          })
          .catch((error) => {
            if (this._isMounted) {
              let errorMsg = this.commonMethods.getErrorMessage(error);
              this.setState({
                isError: true,
                isLoading: false,
                errorMsg: errorMsg,
              });
              return errorMsg;
            }
          });
      }
    } else {
      if (this._isMounted) {
        this.statusRecordService
          .getStatusRecordByApprover()
          .then((res) => {
            if (this._isMounted) {
              this.setState({ statusRecords: res }, () => {
                this.setState({ isLoading: false });
              });
            }
          })
          .catch((error) => {
            if (this._isMounted) {
              let errorMsg = this.commonMethods.getErrorMessage(error);
              this.setState({
                isError: true,
                isLoading: false,
                errorMsg: errorMsg,
              });
              return errorMsg;
            }
          });
      }
    }
  }

  //     ___  _____  _  _  ___  ___      ___  _   _  _  _   ___  _____  ___  ___   _  _  ___
  //    / _ \|_   _|| || || __|| _ \    | __|| | | || \| | / __||_   _||_ _|/ _ \ | \| |/ __|
  //   | (_) | | |  | __ || _| |   /    | _| | |_| || .` || (__   | |   | || (_) || .` |\__ \
  //    \___/  |_|  |_||_||___||_|_\    |_|   \___/ |_|\_| \___|  |_|  |___|\___/ |_|\_||___/
  //

  isAdmin() {
    return (
      this.props.userProfile.taskList.includes(TaskList.leaveadminaccess) &&
      this.props.location.pathname === "/admin/leave_admin"
    );
  }

  //    ___ __   __ ___  _  _  _____    _  _    _    _  _  ___   _     ___  ___    ___  _   _  _  _   ___  _____  ___  ___   _  _  ___
  //   | __|\ \ / /| __|| \| ||_   _|  | || |  /_\  | \| ||   \ | |   | __|| _ \  | __|| | | || \| | / __||_   _||_ _|/ _ \ | \| |/ __|
  //   | _|  \ V / | _| | .` |  | |    | __ | / _ \ | .` || |) || |__ | _| |   /  | _| | |_| || .` || (__   | |   | || (_) || .` |\__ \
  //   |___|  \_/  |___||_|\_|  |_|    |_||_|/_/ \_\|_|\_||___/ |____||___||_|_\  |_|   \___/ |_|\_| \___|  |_|  |___|\___/ |_|\_||___/
  //

  handleApprovalClick(StatusRecordID: string, UserID: string) {
    this.setState({
      dialogstr: "Are you sure you want to approve this application?",
      confirmstr: "Confirm Approval",
      visible: true,
      recordID: StatusRecordID,
      recipientUserID: UserID,
    });
  }

  handleRejectionClick(StatusRecordID: string) {
    this.setState({
      dialogstr: (
        <>
          Are you sure you want to reject this application?
          <p>
            <strong>Reason:</strong>
          </p>
          <InputTextarea
            rows={5}
            cols={30}
            onChange={(e) => {
              this.setState({ approvingRemarks: e.currentTarget.value });
            }}
          />
        </>
      ),
      confirmstr: "Confirm Rejection",
    });
    this.setState({ visible: true, recordID: StatusRecordID });
  }

  handleMultiApprovalClick() {
    let invalidRecordCheck: boolean = false;

    for (let index in this.state.selectedRecords) {
      if (this.requireDocument(this.state.selectedRecords[index])) {
        invalidRecordCheck = true;
        break;
      } else {
        invalidRecordCheck = false;
      }
    }

    if (invalidRecordCheck) {
      this.context.SetError(
        "Please unselect applications that still require documents for approval."
      );
    } else {
      this.setState({
        dialogstr: `Are you sure you want to approve ${this.state.selectedRecords.length} application(s)?`,
        confirmstr: "Approve Selected Records",
      });
      this.setState({ visible: true });
    }
  }
  handleMultiRejectionClick() {
    this.setState({
      dialogstr: (
        <>
          {`Are you sure you want to approve ${this.state.selectedRecords.length} application(s)?`}
          <p>
            <strong>Reason:</strong>
          </p>
          <InputTextarea
            rows={5}
            cols={30}
            onChange={(e) => {
              this.setState({ approvingRemarks: e.currentTarget.value });
            }}
          />
        </>
      ),
      confirmstr: "Reject Selected Records",
    });
    this.setState({ visible: true });
  }

  async handleConfirmApproval() {
    await this.approveStatusRecord({
      StatusRecordID: this.state.recordID,
      ApprovingRemarks: this.state.approvingRemarks,
    })
      .then((res) => {
        this.context.SetSuccess("Application has been approved");
        this.setState({ visible: false });
        if (this.props.others !== undefined && this.props.others !== null) {
          this.props.others.tableDataCallback();
        }
      })
      .catch((err) => {
        const error = this.commonMethods.getErrorMessage(err);
        this.context.SetError(error);
        this.setState({ visible: false });
      });
    await this.getPendingRecords();
  }

  async handleConfirmRejection() {
    await this.rejectStatusRecord({
      StatusRecordID: this.state.recordID,
      ApprovingRemarks: this.state.approvingRemarks,
    })
      .then((res) => {
        this.context.SetSuccess("Application has been rejected");
        this.setState({ visible: false });
        if (this.props.others !== undefined && this.props.others !== null) {
          this.props.others.tableDataCallback();
        }
        // this.props.others.tableDataCallback();
      })
      .catch((err) => {
        const error = this.commonMethods.getErrorMessage(err);
        this.context.SetError(error);
        this.setState({ visible: false });
      });
    await this.getPendingRecords();
  }

  async handleMultiRecordApproval() {
    this.setState({ hideProgressBar: false });

    await this.statusRecordService
      .bulkActionLeaveApplication(this.state.selectedRecords)
      .then((res) => {
        this.setState({ visible: false });
        this.context.SetSuccess(
          `${this.state.selectedRecords.length} applications have been approved.`
        );
        this.setState({ hideProgressBar: true, selectedRecords: [] });
      })
      .catch((err) => {
        this.context.SetError("Unable to approve the selected applications");

        this.setState({
          hideProgressBar: true,
          visible: false,
        });
      });

    this.getPendingRecords();
  }

  async handleMultiRecordRejection() {
    this.setState({ hideProgressBar: false });
    this.state.selectedRecords.forEach((element: StatusRecordDocumentObj) => {
      element.approvingRemarks = this.state.approvingRemarks;
    });
    await this.statusRecordService
      .bulkActionLeaveApplication(this.state.selectedRecords)
      .then((res) => {
        this.setState({ visible: false });
        this.context.SetSuccess(
          `${this.state.selectedRecords.length} applications have been rejected`
        );
        this.setState({ hideProgressBar: true, selectedRecords: [] });
      })
      .catch((err) => {
        this.context.SetError("Unable to reject the selected applications");

        this.setState({
          hideProgressBar: true,
          visible: false,
        });
      });

    this.getPendingRecords();
  }

  async approveStatusRecord(statusObj: any) {
    await this.statusRecordService.approveStatusRecord(statusObj);
  }

  async getPendingRecords() {
    if (this.isAdmin()) {
      this.statusRecordService
        .getCompanyWidePendingRecords(localStorage.currentAccessingCompany)
        .then((res) => {
          this.setState({ statusRecords: res }, () => {
            this.setState({ isLoading: false });
          });
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
          return errorMsg;
        });
    } else {
      await this.statusRecordService
        .getStatusRecordByApprover()
        .then((res) => {
          this.setState({ statusRecords: res });
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
          return errorMsg;
        });
    }
  }

  async rejectStatusRecord(statusObj: any) {
    await this.statusRecordService.rejectStatusRecord(statusObj);
  }

  // HM 05/05/2020 - Add check for only pending status. [For leave applied on behalf by admin, no document is needed to be approved even tho status requires document.....]

  requireDocument = (rowdata: StatusRecordDocumentObj) => {
    return (
      rowdata.statusTypeSupportDoc &&
      rowdata.approvalStatus === "Pending" &&
      (rowdata.uploadPath === "" || rowdata.uploadPath === null)
    );
  };

  //    ___    _  _____  _      _____  _    ___  _     ___    _____  ___  __  __  ___  _       _  _____  ___  ___
  //   |   \  /_\|_   _|/_\    |_   _|/_\  | _ )| |   | __|  |_   _|| __||  \/  || _ \| |     /_\|_   _|| __|/ __|
  //   | |) |/ _ \ | | / _ \     | | / _ \ | _ \| |__ | _|     | |  | _| | |\/| ||  _/| |__  / _ \ | |  | _| \__ \
  //   |___//_/ \_\|_|/_/ \_\    |_|/_/ \_\|___/|____||___|    |_|  |___||_|  |_||_|  |____|/_/ \_\|_|  |___||___/
  //

  durationTemplate = (rowData: StatusRecordDisplayObj) => {
    let display = this.commonMethods.convertStartEndDateForStartEndDayDateDurationDisplay(
      rowData.statusTypeName,
      rowData.startDate,
      rowData.endDate,
      rowData.duration
    );

    return <>{display}</>;
  };

  statusTemplate = (rowData: any) => {
    if (rowData.approvalStatus === "Pending") {
      return (
        <span
          className="boldLabel text-info"
          data-tip=""
          data-for="pendingcancellation"
        >
          <ReactTooltip place="top" id="pending">
            Pending your approval for the leave applied.
          </ReactTooltip>
          Pending
        </span>
      );
    } else if (rowData.approvalStatus === "Approved") {
      return <span className="boldLabel text-success">Approved</span>;
    } else if (rowData.approvalStatus === "Rejected") {
      return <span className="boldLabel text-danger">Rejected</span>;
    } else if (rowData.approvalStatus === "Cancelled") {
      return <span className="boldLabel">Cancelled</span>;
    } else if (rowData.approvalStatus === "Pending Cancellation") {
      return (
        <span
          className="boldLabel"
          style={{ color: "#EE3B3B" }}
          data-tip=""
          data-for="pendingcancellation"
        >
          <ReactTooltip place="top" id="pendingcancellation">
            Pending your approval for the cancellation of an approved leave.
          </ReactTooltip>
          Pending Cancellation
        </span>
      );
    }
  };

  createdDateTemplate(rowData: { createdTime: Date }) {
    return this.commonMethods.displayDate(rowData.createdTime);
  }

  showInfoDialog(e: any) {
    this.setState({ infoDialogHeader: "Leave Details" });

    let html = (
      <>
        <table className="leave-info-dialog table">
          <tbody>
            <tr>
              <td className="font-weight-bold">Leave Type:</td>
              <td>{e.statusTypeName}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Status:</td>
              <td>{e.approvalStatus}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Applied By:</td>
              <td>
                {e.fullDisplayName} on{" "}
                {this.commonMethods.displayDate(new Date(e.createdTime + "Z"))}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">Start Date:</td>
              <td>
                {this.commonMethods.convertStartEndDateForDayDateDurationDisplay(
                  e.statusTypeName,
                  e.startDate,
                  e.endDate,
                  e.duration,
                  "start"
                )}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">End Date:</td>
              <td>
                {" "}
                {this.commonMethods.convertStartEndDateForDayDateDurationDisplay(
                  e.statusTypeName,
                  e.startDate,
                  e.endDate,
                  e.duration,
                  "end"
                )}
              </td>
            </tr>

            <tr>
              <td className="font-weight-bold">Value:</td>
              <td>{e.creditDebitValue}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Remarks:</td>
              <td>{e.remarks}</td>
            </tr>
          </tbody>
        </table>
      </>
    );

    this.setState({ infoDialogStr: html });
    this.setState({ infoDialogDisplay: true });
  }

  approveAllButton() {
    if (this.state.statusRecords.length < 1) {
      return <> </>;
    }
    return (
      <span className="editButtonFloatRight">
        <Button
          label="Approve All"
          onClick={() => {
            this.setState({ selectedRecords: this.state.statusRecords }, () => {
              this.handleMultiApprovalClick();
            });
          }}
        />
      </span>
    );
  }

  actionTemplate(rowData: any) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="View Details"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-eye"
          className="p-button-info mx-1"
          onClick={() => {
            this.showInfoDialog(rowData);
          }}
        />

        <Button
          tooltip={
            this.requireDocument(rowData)
              ? "Document required before approval."
              : "Approve"
          }
          tooltipOptions={{ position: "top" }}
          type="button"
          icon={
            this.requireDocument(rowData)
              ? "pi pi-exclamation-triangle"
              : "pi pi-check"
          }
          className={
            this.requireDocument(rowData)
              ? "p-button-warning"
              : "p-button-success"
          }
          onClick={() => {
            if (!this.requireDocument(rowData)) {
              this.handleApprovalClick(rowData.statusRecordID, rowData.userID);
            }
          }}
        />

        <Button
          tooltip="Reject"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-times"
          className="mx-1 p-button-danger"
          onClick={() => {
            this.handleRejectionClick(rowData.statusRecordID);
          }}
        />
        <Button
          tooltip="View Document"
          tooltipOptions={{ position: "top" }}
          hidden={rowData.uploadPath == null}
          type="button"
          icon="pi pi-folder"
          className="button-document mr-1"
          onClick={() => {
            window.open(rowData.uploadPath, "_blank");
          }}
        />
      </div>
    );
  }

  departmentNameFilter(){
    return (
      <Dropdown
        showClear
        className={this.isAdmin() ? "this.ismb-1 mx-1" : "d-none"}
        value={this.state.deptFilter}
        options={this.state.departmentDropdownList}
        placeholder="Filter by Department"
        onChange={(e) => {
          this.setState({ deptFilter: e.value });
          this.onDepartmentNameChange(e);
        }}
      />
    );
  }

  onDepartmentNameChange(e: any) {
    let dt = this.state.dt;
    dt.current.filter(e.value, 'departmentName', 'equals');
  }

  render() {
    console.log(this.state.statusRecords);
    let header = (
      <>
        <div style={{ textAlign: "left" }}>
          {/* <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i> */}
          <span>

            {/* The bottom commented out is the old version, the one within the span is the new version */}
            {/* <InputText
              onInput={(e) =>
                this.setState({ globalFilter: e.currentTarget.value })
              }
              placeholder="Search"
              className="pi pi-search mx-1 mb-1"
            /> */}
            <span className="p-input-icon-left mr-1 mb-1">
              <i className="pi pi-search" />
              <InputText
                placeholder="Search"
                onInput={(e) => this.setState({ globalFilter: e.currentTarget.value })}
              />
            </span>
            {this.departmentNameFilter()}
            <Calendar
              showButtonBar
              todayButtonClassName="d-none"
              tooltip="Filter by Applied Date"
              tooltipOptions={{ position: "top" }}
              yearNavigator
              yearRange="2020:2023"
              id="MonthCalendar"
              view="month"
              style={{ width: "187px" }}
              readOnlyInput={true}
              dateFormat="M-yy"
              maxDate={new Date()}
              value={new Date(this.state.dateFilter.toString())}

              onChange={(e) => {
                if (e.value === null) {
                  this.setState({
                    globalFilter: "",
                    dateFilter: new Date(),
                  });
                } else {
                this.setState({
                  globalFilter: new Date(
                    new Date(e.value.toString()).getTime() -
                      new Date().getTimezoneOffset() * 60 * 1000
                  )
                    .toISOString()
                    .substr(0, 7)
                    .replace("T", " "),
                  dateFilter: new Date(e.value.toString()),
                });
              }}}
            />
          </span>
          {this.approveAllButton()}
        </div>
      </>
    );

    if (this.state.selectedRecords.length > 0) {
      header = (
        <div style={{ textAlign: "left" }}>
          <Button
            label="Approve"
            type="button"
            iconPos="left"
            icon="pi pi-check"
            className="p-button-success mx-1 "
            onClick={() => {
              this.handleMultiApprovalClick();
            }}
          />

          <Button
            label="Reject"
            type="button"
            iconPos="left"
            icon="pi pi-times"
            className="p-button-danger mx-1"
            onClick={() => {
              this.handleMultiRejectionClick();
            }}
          />
          <span style={{ float: "right" }}>
            <Button
              label="Approve All"
              onClick={() => {
                this.setState(
                  { selectedRecords: this.state.statusRecords },
                  () => {
                    this.handleMultiApprovalClick();
                  }
                );
              }}
            />
          </span>
        </div>
      );
    }

    let page = (
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          ref={this.state.dt}
          header={header}
          emptyMessage="No records found."
          globalFilter={this.state.globalFilter}
          value={this.state.statusRecords}
          paginator={true}
          rows={15}
          autoLayout={true}
          alwaysShowPaginator={false}
          selection={this.state.selectedRecords}
          onSelectionChange={(e) => {
            this.setState({
              selectedRecords: e.value,
            });
          }}
        >
          <Column
            selectionMode="multiple"
            className="p-col-1 text-center"
            style={{ width: "3em" }}
          />
          <Column field="fullDisplayName" header="User" className="p-col-2 text-center" />
          <Column field="departmentName" header="Department" className="text-center" filterElement={this.departmentNameFilter()}/>
          <Column field="statusTypeName" header="Leave Type" className="text-center" />

          <Column
            field="createdTime"
            header="Application Date"
            className="d-none"
          />

          <Column
            className="p-col-2 text-center"
            body={this.durationTemplate}
            header="Duration"
          />

          <Column field="remarks" header="Remarks" className="p-col-2 text-center" />

          {/* <Column
        body={this.createdDateTemplate}
        header="Applied On"
        excludeGlobalFilter={false}
      /> */}
          {/* <Column field="remarks" header="Remarks" /> */}
          <Column body={this.statusTemplate} header="Status" className="text-center" />
          <Column body={this.actionTemplate} header="Action" className="text-center" />
        </DataTable>
      </div>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />; // or some growl/message that stays
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = page;
    }

    let approveLeavePanel = (
      <div
        className="card"
        data-tour-id="approve-leave"
        data-tour="Approve or reject your subordinates' leave here using the action buttons."
      >
        <Dialog
          //dismissableMask={true}
          className="popupDialog"
          header={this.state.confirmstr}
          visible={this.state.visible}
          modal={true}
          onHide={() =>
            this.setState({
              visible: false,
              approvingRemarks: "",
              dialogstr: "",
            })
          }
          footer={
            <div>
              <Button
                label="Yes"
                type="button"
                disabled={
                  (this.state.confirmstr === "Confirm Rejection" ||
                    this.state.confirmstr === "Reject Selected Records") &&
                  this.state.approvingRemarks === ""
                }
                iconPos="left"
                icon="pi pi-check"
                className="p-button-success"
                onClick={() => {
                  if (this.state.confirmstr === "Confirm Rejection") {
                    this.handleConfirmRejection();
                  } else if (this.state.confirmstr === "Confirm Approval") {
                    this.handleConfirmApproval();
                  } else if (
                    this.state.confirmstr === "Approve Selected Records"
                  ) {
                    this.handleMultiRecordApproval();
                  } else if (
                    this.state.confirmstr === "Reject Selected Records"
                  ) {
                    this.handleMultiRecordRejection();
                  }
                }}
              />

              <Button
                label="No"
                type="button"
                icon="pi pi-times"
                className="p-button-danger "
                onClick={() => {
                  this.setState({ visible: false, approvingRemarks: "" });
                }}
              />
            </div>
          }
        >
          {this.state.dialogstr}
        </Dialog>

        <Dialog
          //dismissableMask={true}
          header={this.state.infoDialogHeader}
          visible={this.state.infoDialogDisplay}
          modal={true}
          onHide={() => {
            this.setState({ infoDialogDisplay: false });
          }}
        >
          {this.state.infoDialogStr}
        </Dialog>

        <h1>Approve Leave </h1>
        {display}
        {/* <span hidden={this.state.hideProgressBar}>
          <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
        </span> */}
      </div>
    );

    return <div className="">{approveLeavePanel}</div>;
  }
}
