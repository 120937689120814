import { HttpClient } from "./HttpClient";
import moment from "moment";
import { CommonMethods } from "../components/resources/CommonMethods";
export class ScoreIndicatorService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getAllScoreIndicators() {
    return await this.httpClient.get("/api/scoreindicator");
  }

  // async getCompanyScoreIndicators(companyId: string) {
  //   const res = await this.httpClient.get(
  //     "/api/scoreindicator/companyscoreindicators/" + companyId
  //   );
  //   return res;
  // }

  // async getDepartmentScoreIndicators(userid: string) {
  //   const res = await this.httpClient.get(
  //     "/api/scoreindicator/departmentscoreindicators/" + userid
  //   );
  //   return res;
  // }

  async getDepartmentScoreIndicators(deptId: string) {
    const res = await this.httpClient.get(
      "/api/scoreindicator/departmentscoreindicators/" + deptId
    );
    return res;
  }

  async createScoreIndicator(scoreindicator: any) {
    return await this.httpClient.post(
      "/api/scoreindicator/create",
      scoreindicator
    );
  }

  async bulkCreateScores(scoreindicator: any) {
    return await this.httpClient.post(
      "/api/scoreindicator/bulkcreate",
      scoreindicator
    );
  }

  async updateScoreIndicator(scoreindicator: any) {
    return await this.httpClient.put(
      "/api/scoreindicator/update",
      scoreindicator
    );
  }

  async deleteScoreByID(id: any) {
    return await this.httpClient.delete("/api/scoreindicator/" + id);
  }

  async addEntry(task: any) {
    var form_data = new FormData();

    for (var key in task) {
      if (key === "createdTime") {
        let utcDate = moment(task[key]).format(
          CommonMethods.DateForBackendStringFormat
        );
        // let utcDate = statusRecordObj[key].toLocaleString();
        form_data.append(key, utcDate);
      } else {
        form_data.append(key, task[key]);
      }
    }
    return await this.httpClient.post("/api/userindicator/createmultiple", form_data);
  }

  async updateEntry(task: any) {
    console.log("KPIEntry",task);
    // sorry for the lousy fix
    task.createdTime = moment(task.createdTime).format(
      CommonMethods.DateForBackendStringFormat
    );
    return await this.httpClient.put("/api/userindicator/update", task);
  }

  async getEntryByID(id: any) {
    return await this.httpClient.get("/api/userindicator/" + id);
  }

  async getMyKPIEntries() {
    return await this.httpClient.get("/api/userindicator/me");
  }

  async getMyKPIEntriesByMonthYear(
    userid: string,
    month: number,
    year: number
  ) {
    return await this.httpClient.get(
      "/api/userindicator/me/" + userid + "/month/" + month + "/year/" + year
    );
  }

  async getMyDeptKPIEntries() {
    return await this.httpClient.get("/api/userindicator/dept");
  }

  async getDeptKPIEntries(deptid: string) {
    return await this.httpClient.get("/api/userindicator/dept/" + deptid);
  }

  // async getMyCompanyKPIEntries() {
  //   return await this.httpClient.get("/api/userindicator/company");
  // }

  async removeMyKPI(task: any) {
    return await this.httpClient.delete("/api/userindicator/" + task);
  }

  async getCompanyKPIList(companyid: string, month: number, year: number) {
    return await this.httpClient.get(
      "/api/userindicator/group/" + companyid + "/" + month + "/" + year
    );
  }

  async prorateTarget(targetModel: any) {
    const res = await this.httpClient.post(
      "/api/userindicator/target",
      targetModel
    );
    // const res = await this.httpClient.get(
    //   "/api/userindicator/target/" +
    //     userid +
    //     "/month/" +
    //     month +
    //     "/year/" +
    //     year
    // );
    return res;
  }

  async prorateTargetManual(targetModel: any) {
    const res = await this.httpClient.post(
      "/api/userindicator/target/manual",
      targetModel
    );
    return res;
  }

  async getLinkStatsPerformance(userid: string, month: number, year: number) {
    const res = await this.httpClient.get(
      "/api/linkstats/performance/" +
        userid +
        "/month/" +
        month +
        "/year/" +
        year
    );
    return res;
  }

  async getDeptLinkStatsPerformance(
    deptid: string,
    month: number,
    year: number
  ) {
    const res = await this.httpClient.get(
      "/api/linkstats/performance/dept/combined/" +
        deptid +
        "/" +
        month +
        "/" +
        year
    );
    return res;
  }

  async getIRNDashboardDetails() {
    const res = await this.httpClient.get("/api/linkstats/dashboard");
    return res;
  }

  async getLinkStatsTrackingResults() {
    const res = await this.httpClient.get("/api/linkstats/tracking");
    return res;
  }

  async manualSendLinksToIrn(month: number, year: number) {
    const res = await this.httpClient.get(
      "/api/linkstats/send/" + month + "/" + year
    );
    return res;
  }
}
