import * as React from "react";
import { Redirect } from "react-router";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { UserService, UserDocumentService } from "../../service/UserService";
import { InputText } from "primereact/inputtext";

import CustomError from "../resources/Error";

import { RadioButton } from "primereact/radiobutton";
import {
    CommonMethods,
    DropdownModel,
    RouteComponentProps,
} from "../resources/CommonMethods";

import { Dialog } from "primereact/dialog";
import { UserModel } from "./User";
import { Calendar } from "primereact/calendar";
import { ProgressSpinner } from "primereact/progressspinner";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UserDocumentModel } from "./UserDocument";
import { DocumentTypeService } from "../../service/DocumentTypeService";
import { Message } from "primereact/message";
import { ToastStateContext } from "../resources/ToastContext";
import Axios from "axios";
import {
    CommonDocumentMethods,
    ProcessedImage,
} from "../resources/CommonDocumentMethods";
import {Checkbox } from "primereact/checkbox"

interface AppStateUpdate {
    allUsers: Array<UserModel>;
    userObj: UserModel;
    initialUserObj: UserModel;
    redirectOut: boolean;
    redirectPath: string;
    isLoading: boolean;

    action: string;
    formHeader: string;
    maxDate: Date;

    isError: boolean;
    errorMsg: string;
    dialogStr: string;
    dialogHeaderStr: string;
    visible: boolean;
    dialogIcon: string;
    dialogShowError: boolean;
    previewImage: any;

    isUpdate: boolean;
    isDocUpdate: boolean;

    userDocumentList: Array<UserDocumentModel>;
    userDocObj: UserDocumentModel;
    documentTypeDropdownList: Array<DropdownModel>;
    nationalityDropdownList: Array<DropdownModel>;
    workPassTypeDropdownList: Array<DropdownModel>;
    workLocationDropdownList: Array<DropdownModel>;
    selectedDoc: UserDocumentModel;

    swiftList: { label: string; value: string }[];

    ppEmailError: string;
    genderError: string;

    isTSL: boolean;

	mobileNumber: string;
	dialCode: string;
}

export class StaffView extends React.Component<
    RouteComponentProps<any>,
    AppStateUpdate
> {
    static contextType = ToastStateContext;
    martialStatusDropdownList: any;

    genderDropdownList: any;
    applicableDropdownList: any;
    salutationDropdownList: any;
    legalStatusDropdownList: any;
    userRankDropdownList: any;
    userService: UserService;
    userdocumentService: UserDocumentService;
    documentTypeService: DocumentTypeService;

    commonMethods: CommonMethods;

    constructor(props: RouteComponentProps<any>) {
        super(props);
        this.state = {
            allUsers: new Array<UserModel>(),
            userObj: new UserModel(),
            initialUserObj: new UserModel(),
            redirectOut: false,
            redirectPath: "",
            isLoading: true,

            action: "",
            formHeader: "",
            maxDate: new Date(),

            documentTypeDropdownList: new Array<DropdownModel>(),
            nationalityDropdownList: new Array<DropdownModel>(),
            workPassTypeDropdownList: new Array<DropdownModel>(),
            workLocationDropdownList: new Array<DropdownModel>(),
            isError: false,
            errorMsg: "",
            dialogStr: "",
            dialogHeaderStr: "",
            visible: false,
            dialogIcon: "",
            dialogShowError: false,

            previewImage: "",

            isUpdate: false,
            isDocUpdate: false,
            userDocumentList: [],
            userDocObj: new UserDocumentModel(),

            selectedDoc: new UserDocumentModel(),

            swiftList: [],

            ppEmailError: "",
            genderError: "",

            isTSL: false,
			mobileNumber: "",
			dialCode: "",
        };

        this.genderDropdownList = [];
        this.applicableDropdownList = [];
        this.salutationDropdownList = [];
        this.legalStatusDropdownList = [];
        this.userService = new UserService();
        this.userdocumentService = new UserDocumentService();
        this.documentTypeService = new DocumentTypeService();
        this.submitForm = this.submitForm.bind(this);
        this.getDropdownLists = this.getDropdownLists.bind(this);
        this.commonMethods = new CommonMethods();
        this.convertDatesForSubmission = this.convertDatesForSubmission.bind(this);

        this.hideDialog = this.hideDialog.bind(this);
        this.initDateFields = this.initDateFields.bind(this);

        this.submitDocument = this.submitDocument.bind(this);
        this.uploadDateTemplate = this.uploadDateTemplate.bind(this);
        this.formValidationCheck = this.formValidationCheck.bind(this);
		this.combineDialCode = this.combineDialCode.bind(this);
    }
    componentDidMount() {
        this.initUser();

        this.commonMethods
            .isGroupCompanyTSL(this.props.userProfile.groupCompanyID)
            .then((res) => {
                this.setState({ isTSL: res });
            })
            .catch(() => {
                this.setState({ isTSL: false });
            });
    }

    initUser() {
        this.userService
            .getMyDisplay()
            .then((res:UserModel) => {
				let stringSplit = res.pP_MobileNumber.split(" ");
				let dialCodeTemp = "";
				let mobileNumberTemp = "";
				if (stringSplit.length > 1){
					dialCodeTemp = stringSplit[0];
					mobileNumberTemp = stringSplit[1];
				} else {
					mobileNumberTemp = res.pP_MobileNumber
				}
                // User Doc
                this.userdocumentService.getMyUserDocuments().then((docRes) => {
                    this.setState({
                        userDocumentList: docRes,
                    });
                });
                // User
                this.setState(
                    {
                        initialUserObj: res,
                        userObj: res,
                        formHeader: "Profile",
						dialCode: dialCodeTemp,
						mobileNumber: mobileNumberTemp
                    },
                    () => {
                        this.initDateFields(this.state.userObj);
                        let maxDate = this.commonMethods.createMaxDate();
                        this.setState({
                            maxDate: maxDate,
                            previewImage: this.state.userObj.displayPicture,
                            isUpdate: false,
                            isDocUpdate: false,
                        });
                        this.getDropdownLists();
                    }
                );
            })
            .catch((error) => {
                let errorMsg = this.commonMethods.getErrorMessage(error);
                this.setState({
                    isError: true,
                    isLoading: false,
                    errorMsg: errorMsg,
                });
                return errorMsg;
            });
    }

    // convert dates to display in correct format inside the calendar fields
    initDateFields(res: UserModel) {
        let data = res;
        data.dateOfBirth = new Date(res.dateOfBirth);
        data.wP_WorkPermitExpiry = new Date(res.wP_WorkPermitExpiry);
        data.prObtainedDate = new Date(res.prObtainedDate);

        if (this.commonMethods.isDateNull(this.state.userObj.dateOfBirth)) {
            this.updateProperty("dateOfBirth", "");
        }
    }

    // --- INIT FORM METHODS ---

    getDropdownLists() {
        // document types
        Axios.all([
            this.documentTypeService.getDocumentTypeDropdown(),
            this.commonMethods.getBankCodeDropdown(),
            this.commonMethods.getNationalityDropdown(),
            this.commonMethods.getWorkPassTypeDropdown(),
            this.commonMethods.getWorkLocationDropdown(),
        ])
            .then(
                Axios.spread((first, second, third, fourth, fifth) => {
                    this.setState({
                        documentTypeDropdownList: first,
                        swiftList: second,
                        nationalityDropdownList: third,
                        workPassTypeDropdownList: fourth,
                        workLocationDropdownList: fifth,
                        isLoading: false,
                    });
                })
            )
            .catch((error) => {
                let errorMsg = this.commonMethods.getErrorMessage(error);
                this.setState({
                    isError: true,
                    isLoading: false,
                    errorMsg: errorMsg,
                });
                return errorMsg;
            });

        // country
        // this.commonMethods
        //   .getNationalityDropdown()
        //   .then((res) => {
        //     this.nationalityDropdownList = res;
        //   })
        //   .catch((error) => {
        //     let errorMsg = this.commonMethods.getErrorMessage(error);
        //     this.setState({
        //       isError: true,
        //       isLoading: false,
        //       errorMsg: errorMsg,
        //     });
        //     return errorMsg;
        //   });
        this.genderDropdownList = this.commonMethods.getGenderDropdownList();
        this.applicableDropdownList = this.commonMethods.getYesNoDropdownList();
        this.salutationDropdownList = this.commonMethods.getSalutationDropdownList();
        this.legalStatusDropdownList = this.commonMethods.getLegalStatusDropdownList();
        this.martialStatusDropdownList = this.commonMethods.getMaritalStatusDropdownList();
    }

    updateProperty(property: string, value: any) {
        let tempUserObj = this.state.userObj;
        (tempUserObj as any)[property] = value;
        this.setState({
            userObj: tempUserObj,
        });
    }

    updateDocumentProperty(property: string, value: any) {
        let tempUserObj = this.state.userDocObj;
        (tempUserObj as any)[property] = value;
        this.setState({
            userDocObj: tempUserObj,
        });
    }

    hideDialog() {
        if (this.state.dialogShowError) {
            this.setState({ visible: false });
        } else {
            this.setState({
                redirectOut: true,
                redirectPath: "/user/view/" + this.state.userObj.userID,
            });
        }
    }

    // --- FORM SUBMISSION METHODS ---
    submitForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault(); // to prevent page from refreshing!
        this.setState({ isLoading: true }, () => {
            if (this.formValidationCheck()) {
                this.setState({ isLoading: false });
            } else {
                this.convertDatesForSubmission();
                this.cleanUpFieldsForSubmission();
				this.combineDialCode();
                this.userService
                    .staffUpdate(this.state.userObj)
                    .then((res) => {
                        this.context.SetSuccess("Profile updated.");
                        this.initUser();
                    })
                    .catch((error) => {
                        let errorMsg = this.commonMethods.getErrorMessage(error);
                        this.initDateFields(this.state.userObj);
                        this.context.SetError(errorMsg);
                        this.setState({
                            isLoading: false,
                        });
                    });
            }
        });
    }

    submitDocument() {
        this.setState(
            {
                isLoading: true,
                userDocObj: {
                    ...this.state.userDocObj,
                    userID: this.state.userObj.userID,
                },
            },
            () => {
                this.userdocumentService
                    .createUserDocument(this.state.userDocObj)
                    .then((res) => {
                        this.context.SetSuccess("Document uploaded.");
                        this.initUser();
                        this.clearDocument();
                    })
                    .catch((error) => {
                        let errorMsg = this.commonMethods.getErrorMessage(error);
                        this.setState({
                            isLoading: false,
                            dialogStr: errorMsg,
                            dialogHeaderStr: "Unable to upload document",
                            visible: true,
                            dialogIcon: "pi pi-times",
                            dialogShowError: true,
                        });
                        this.clearDocument();
                    });
            }
        );
    }

    // ---< DOCUMENT TABLE TEMPLATE ---

    uploadDateTemplate(rowData: UserDocumentModel) {
        let datePart = this.commonMethods.displayDate(new Date(rowData.uploadDate));
        let timePart = this.commonMethods.displayTime12Hour(
            new Date(rowData.uploadDate)
        );
        return datePart + " " + timePart;
    }

    // --- DOCUMENT TABLE TEMPLATE />---

    cleanUpFieldsForSubmission() {
        if (this.state.userObj.legalStatus === "FR" && this.state.userObj.wP_WorkLocation === "Remote") {
            this.updateProperty("prObtainedDate", null);
            this.updateProperty("wP_WorkPass", null);
            this.updateProperty("wP_WorkPermitExpiry", null);
            this.updateProperty("wP_DependantPass", "");
        } else if (this.state.userObj.legalStatus === "FR" && this.state.userObj.wP_WorkLocation === "SG") {
            this.updateProperty("prObtainedDate", null);
            this.updateProperty("wP_PassportNumber", null);
        } else if (this.state.userObj.legalStatus === "PR") {
            this.updateProperty("wP_WorkPass", null);
            this.updateProperty("wP_WorkPermitExpiry", null);
            this.updateProperty("wP_DependantPass", "");
            this.updateProperty("wP_WorkLocation", null);
            this.updateProperty("wP_PassportNumber", null);

        } else if (this.state.userObj.legalStatus === "SC") {
            this.updateProperty("prObtainedDate", null);
            this.updateProperty("wP_WorkPass", null);
            this.updateProperty("wP_WorkPermitExpiry", null);
            this.updateProperty("wP_DependantPass", "");
            this.updateProperty("wP_WorkLocation", null);
            this.updateProperty("wP_PassportNumber", null);
        }

        if (this.state.userObj.bank_Code !== "") {
            var index = this.state.swiftList.findIndex(
                (obj) => obj.value === this.state.userObj.bank_Code
            );
            this.setState({
                userObj: {
                    ...this.state.userObj,
                    bank_Name: this.state.swiftList[index].label,
                },
            });
            // this.state.userObj.bank_Name = this.state.swiftList[index].label;
        }
    }

    formValidationCheck() {
        let isError = false;
        this.setState({ ppEmailError: "", genderError: "" });

        if (!this.commonMethods.validateDOB(this.state.userObj.dateOfBirth)) {
            this.context.SetError("Date of Birth cannot be greater than today");
            return true;
        }

        if (
            this.state.userObj.pP_PersonalEmail !== "" &&
            !this.commonMethods.validateEmail(this.state.userObj.pP_PersonalEmail)
        ) {
            this.setState({ ppEmailError: "p-error" });
            isError = true;
        }

        if (
            !(
                this.state.userObj.gender === "Male" ||
                this.state.userObj.gender === "Female"
            )
        ) {
            this.setState({ genderError: "p-error" });
            isError = true;
        }
        if (isError) {
            this.context.SetError("Invalid fields");
            return true;
        }
        return false;
    }

    // Convert date to ISOString format to write to db because formdata has no date type
    convertDatesForSubmission() {
        if (!this.commonMethods.isDateNull(this.state.userObj.dateOfBirth)) {
            let dob = this.state.userObj.dateOfBirth.toISOString();
            this.updateProperty("dateOfBirth", dob);
        }

        if (
            !this.commonMethods.isDateNull(this.state.userObj.wP_WorkPermitExpiry)
        ) {
            let wP_WorkPermitExpiry = this.state.userObj.wP_WorkPermitExpiry.toISOString();
            this.updateProperty("wP_WorkPermitExpiry", wP_WorkPermitExpiry);
        }

        if (!this.commonMethods.isDateNull(this.state.userObj.prObtainedDate)) {
            let prObtainedDate = this.state.userObj.prObtainedDate.toISOString();
            this.updateProperty("prObtainedDate", prObtainedDate);
        }
    }

	combineDialCode() {
		let fullNumber = ""
		if (this.state.dialCode != ""){
			fullNumber = this.state.dialCode + " " + this.state.mobileNumber;
		} else {
			fullNumber = this.state.mobileNumber;
		}
		this.updateProperty("pP_MobileNumber", fullNumber)
	}

    setDisplayPicture(e: React.ChangeEvent<HTMLInputElement>) {

        if (
            e !== null &&
            e.currentTarget.files !== null &&
            e.currentTarget.files[0] !== undefined
        ) {
            const uploadedFile = e.currentTarget.files[0];
            CommonDocumentMethods.uploadImage(uploadedFile, 300, 300, "profile")
                .then((res) => {
                    this.setState({
                        previewImage: res.base64,
                        userObj: {
                            ...this.state.userObj,
                            displayPicture: uploadedFile.name,
                            displayPictureFile: res.file,
                        },
                    });
                })
                .catch((err: ProcessedImage) => {
                    this.context.SetError(err.errorMsg);
                });
        } else {
            this.setState({
                userObj: {
                    ...this.state.userObj,
                    displayPicture: "",
                    displayPictureFile: "",
                },
            });
        }
    }

    uploadDocument(e: React.ChangeEvent<HTMLInputElement>) {
        if (
            e !== null &&
            e.currentTarget.files !== null &&
            e.currentTarget.files[0] !== undefined
        ) {
            if (e.currentTarget.files[0].size > 2000000) {
                this.context.setError(
                    "File too big! Has to be below 2MB. Please compress your file before uploading."
                );
            } else {
                this.updateDocumentProperty(
                    "uploadPath",
                    e.currentTarget.files[0].name
                );
                this.updateDocumentProperty("uploadFile", e.currentTarget.files[0]);
            }
        } else {
            this.updateDocumentProperty("uploadPath", "No file chosen");
            this.updateDocumentProperty("uploadFile", null);
        }
    }

    clearDocument() {
        this.updateDocumentProperty("uploadPath", "No file chosen");
        this.updateDocumentProperty("uploadFile", null);
    }

    openDocument(e: { originalEvent: Event; value: any }) {
        // this.setState({selectedDoc: e.value})

        window.open(e.value.uploadPath, "_blank");
    }

    isEquipmentPart() {
        if (this.state.isTSL) {
            return (
                <>
                    <div className="p-col-4">
                        <strong>Equipment ID: </strong>
                    </div>
                    <div className="p-col-8">
                        {Buffer.from(
                            this.state.userObj.userID.replace(/-/g, ""),
                            "hex"
                        ).toString("base64")}
                    </div>
                </>
            );
        }
    }

    render() {
		console.log(this.state.dialCode);
		console.log(this.state.mobileNumber);
        let prDate;
        if (this.state.userObj.legalStatus === "PR") {
            prDate = (
                <div className="p-col-12 pr-0 pl-3">
                    <span className="boldLabel">PR Date Obtained</span>
                    <Calendar
                        readOnlyInput={true}
                        disabled={!this.state.isUpdate}
                        dateFormat="dd/mm/yy"
                        value={this.state.userObj.prObtainedDate}
                        onChange={(e) => this.updateProperty("prObtainedDate", e.value)}
                        showButtonBar={true}

                        showIcon={true}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1900:2030"
                        showTime={false}
                    />
                </div>
            );
        } else {
            prDate = "";
        }

        let workpass;
        let worklocation;
        if (this.state.userObj.legalStatus === "FR") {
            worklocation = (
                <>
                    <div className="p-col-12 pr-0 pl-3">
                        <span className="boldLabel">Work Location</span>
                        <Dropdown
                            disabled={!this.state.isUpdate}
                            value={this.state.userObj.wP_WorkLocation}
                            options={this.state.workLocationDropdownList}
                            onChange={(e) => {
                                this.setState({
                                    userObj: {
                                        ...this.state.userObj,
                                        wP_WorkLocation: e.value,
                                    },
                                });
                            }}
                            placeholder="Please Select"
                        />
                    </div>
                </>
            );
        }

        if (this.state.userObj.legalStatus === "FR" && this.state.userObj.wP_WorkLocation === "Remote") {
            workpass = (
                <>
                    <div className="p-col-12 pr-0 pl-3">
                        <span className="boldLabel">Passport Number</span>
                        <InputText
                            disabled={!this.state.isUpdate}
                            value={this.state.userObj.wP_PassportNumber}
                            onChange={(e) =>
                                this.setState({
                                    userObj: {
                                        ...this.state.userObj,
                                        wP_PassportNumber: e.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </div>
                </>
            )
        } else if (this.state.userObj.legalStatus === "FR" && this.state.userObj.wP_WorkLocation === "SG") {
            workpass = (
                <>
                    <div className="p-col-12 pr-0 pl-3">
                        <span className="boldLabel">Work Pass Type</span>
                        <Dropdown
                            disabled={!this.state.isUpdate}
                            value={this.state.userObj.wP_WorkPass}
                            options={this.state.workPassTypeDropdownList}
                            onChange={(e) => {
                                this.setState({
                                    userObj: {
                                        ...this.state.userObj,
                                        wP_WorkPass: e.value,
                                    },
                                });
                            }}
                            placeholder="Please Select"
                        />
                    </div>
                    <div className="p-col-12 pr-0 pl-3">
                        <span className="boldLabel">Work Pass Number</span>
                        <InputText
                            disabled={!this.state.isUpdate}
                            value={this.state.userObj.wP_DependantPass}
                            onChange={(e) =>
                                this.setState({
                                    userObj: {
                                        ...this.state.userObj,
                                        wP_DependantPass: e.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </div>
                    <div className="p-col-12 pr-0 pl-3">
                        <span className="boldLabel">Work Permit Expiry Date</span>
                        <Calendar
                            dateFormat="dd/mm/yy"
                            value={this.state.userObj.wP_WorkPermitExpiry}
                            onChange={(e) =>
                                this.updateProperty("wP_WorkPermitExpiry", e.value)
                            }
                            showButtonBar={true}

                            showIcon={true}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1900:2030"
                            showTime={false}
                            disabled={!this.state.isUpdate}
                        />
                    </div>
                </>
            );
        }

        // FOR REDIRECT
        if (this.state.redirectOut) {
            return (
                <Redirect
                    push
                    to={{
                        pathname: this.state.redirectPath,
                    }}
                />
            );
        }
        let buttons;
        if (this.state.isUpdate) {
            buttons = "";
        } else {
            buttons = (
                <Button
                    className="editButtonFloatRight"
                    label="Edit"
                    onClick={(event) => {
                        event.preventDefault();
                        this.setState({ isUpdate: true });
                    }}
                />
            );
        }

        let docbuttons;
        if (this.state.isDocUpdate) {
            docbuttons = "";
        } else {
            docbuttons = (
                <Button
                    className="editButtonFloatRight"
                    label="Upload"
                    onClick={(event) => {
                        event.preventDefault();
                        this.setState({
                            isDocUpdate: true,
                        });
                    }}
                />
            );
        }

        let datatable;

        datatable = (
            <div className="datatable-centerHeader datatable-centerContent">
                <DataTable
                    emptyMessage="No results found."
                    value={this.state.userDocumentList}
                    autoLayout={true}
                    selectionMode="single"
                    paginator={true}
                    rows={15}
                    alwaysShowPaginator={false}
                    selection={this.state.selectedDoc}
                    onSelectionChange={(e) => {
                        this.setState({ selectedDoc: e.value });
                        this.openDocument(e);
                    }}
                >
                    <Column
                        className="p-col-2"
                        field="documentTypeName"
                        header="Document Type"
                    />
                    <Column className="p-col-3" field="documentRemarks" header="Remarks" />

                    <Column
                        className="p-col-2"
                        body={this.uploadDateTemplate}
                        header="Date of Upload"
                    />
                </DataTable>
            </div>
        );

        let page = (
            <>
                <form onSubmit={this.submitForm} id="employeeForm">
                    <div className="p-col-12 py-2">
                        <h5>Employee Details</h5>
                    </div>
                    <div className="p-col-12">
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6">
                                <div className="p-grid">
                                    <div className="p-col-4">
                                        <strong>First Name: </strong>
                                    </div>
                                    <div className="p-col-8">{this.state.userObj.firstName}</div>
                                    <div className="p-col-4">
                                        <strong>Last Name: </strong>
                                    </div>
                                    <div className="p-col-8">{this.state.userObj.lastName}</div>
                                    <div className="p-col-4">
                                        <strong>Full Name (as per NRIC): </strong>
                                    </div>
                                    <div className="p-col-8">{this.state.userObj.fullName}</div>
                                    <div className="p-col-4">
                                        <strong>Email: </strong>
                                    </div>
                                    <div className="p-col-8">{this.state.userObj.email}</div>
                                    <div className="p-col-4">
                                        <strong>Company: </strong>
                                    </div>
                                    <div className="p-col-8">
                                        {this.state.userObj.companyName}
                                    </div>
                                    <div className="p-col-4">
                                        <strong>Department: </strong>
                                    </div>
                                    <div className="p-col-8">
                                        {this.state.userObj.departmentName}
                                    </div>
                                    <div className="p-col-4">
                                        <strong>Job Title: </strong>
                                    </div>
                                    <div className="p-col-8">{this.state.userObj.jobTitle}</div>
                                </div>
                            </div>
                            <div className="p-col-12 p-lg-6">
                                <div className="p-grid">
                                    <div className="p-col-4">
                                        <strong>User Rank: </strong>
                                    </div>
                                    <div className="p-col-8">
                                        {this.state.userObj.userRankName}
                                    </div>
                                    <div className="p-col-4">
                                        <strong>Role: </strong>
                                    </div>
                                    <div className="p-col-8">{this.state.userObj.roleName}</div>
                                    <div className="p-col-4">
                                        <strong>Supervisors: </strong>
                                    </div>
                                    <div className="p-col-8">
                                        {this.state.userObj.approverList.map(
                                            (item: string, index: number) => {
                                                if (
                                                    index ===
                                                    this.state.userObj.approverList.length - 1
                                                ) {
                                                    return item;
                                                } else {
                                                    return item + ", ";
                                                }
                                            }
                                        )}
                                    </div>
                                    <div className="p-col-4">
                                        <strong>Subordinates: </strong>
                                    </div>
                                    <div className="p-col-8">
                                        {this.state.userObj.subordinateList.map(
                                            (item: string, index: number) => {
                                                if (
                                                    index ===
                                                    this.state.userObj.subordinateList.length - 1
                                                ) {
                                                    return item;
                                                } else {
                                                    return item + ", ";
                                                }
                                            }
                                        )}
                                    </div>
                                    {this.isEquipmentPart()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={{ height: 5, margin: 10 }} />
                    <div className="p-col-12">
                        <h5>
                            Personal Particulars
              {buttons}
                        </h5>
                    </div>
                    <div className="p-col-12">
                        <div className="p-grid p-justify-center">
                            <div className="imagecropper">
                                <img src={this.state.previewImage} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-justify-center">
                        <div className="p-col-12 p-lg-8">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <span className="boldLabel">Display Picture</span>

                                    <div className="pt-2">
                                        <input
                                            ref="file"
                                            style={{ color: "transparent", width: "110px" }}
                                            type="file"
                                            name="displayPicture"
                                            accept=".jpg, .jpeg, .png"
                                            onChange={(e) => this.setDisplayPicture(e)}
                                            disabled={!this.state.isUpdate}
                                        />
                                    </div>
                                </div>

                                <div className="p-col-12">
                                    <span className="boldLabel">Nationality</span>
                                    <Dropdown
                                        disabled={!this.state.isUpdate}
                                        value={this.state.userObj.nationality}
                                        options={this.state.nationalityDropdownList}
                                        onChange={(e) => {
                                            this.setState({
                                                userObj: {
                                                    ...this.state.userObj,
                                                    nationality: e.value,
                                                },
                                            });
                                        }}
                                        placeholder="Please Select"
                                    />
                                </div>
                                <div className="p-col-12">
                                    <span className="boldLabel">Citizenship</span>
                                    <Dropdown
                                        className="mb-2"
                                        disabled={!this.state.isUpdate}
                                        value={this.state.userObj.legalStatus}
                                        options={this.legalStatusDropdownList}
                                        onChange={(e) =>
                                            this.setState({
                                                userObj: {
                                                    ...this.state.userObj,
                                                    legalStatus: e.value,
                                                },
                                            })
                                        }
                                        placeholder="Please Select"
                                    />
                                    <div className="p-col-12 pr-0 pl-3">
                                        <span className="boldLabel">NRIC/FIN/ID</span>
                                        <InputText
                                            disabled={!this.state.isUpdate}
                                            value={this.state.userObj.identificationNumber}
                                            onChange={(e) =>
                                                this.setState({
                                                    userObj: {
                                                        ...this.state.userObj,
                                                        identificationNumber: e.currentTarget.value,
                                                    },
                                                })
                                            }
                                        />
                                    </div>

                                    {worklocation}

                                    {workpass}

                                    {prDate}
                                </div>

                                {/* <div className="p-col-12 p-lg-6">
                <span className="boldLabel">NRIC</span>
                <InputText
                  value={this.state.userObj.nric}
                  onChange={e =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        nric: e.currentTarget.value
                      }
                    })
                  }
                  required
                />
              </div> */}

                                <div className="p-col-12">
                                    <span className="boldLabel">Date of Birth</span>
                                    <Calendar
                                        readOnlyInput={true}
                                        dateFormat="dd/mm/yy"
                                        value={this.state.userObj.dateOfBirth}
                                        onChange={(e) => {
                                            this.updateProperty("dateOfBirth", e.value);
                                        }}
                                        showButtonBar={true}

                                        showIcon={true}
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        yearRange="1900:2020"
                                        // maxDate={this.state.maxDate}
                                        showTime={false}
                                        disabled={!this.state.isUpdate}
                                    />
                                </div>

                                <div className="p-col-12">
                                    <span className="boldLabel">Gender</span>
                                    <div
                                        hidden={this.state.genderError === "" ? true : false}
                                        className="my-1"
                                    >
                                        <Message severity="error" text="Invalid Input" />
                                    </div>
                                    <div className="p-grid p-col-12 p-1">
                                        <div className="p-col-4 pb-1 p-field-radiobutton">
                                            <RadioButton
                                                disabled={!this.state.isUpdate}
                                                inputId="Male"
                                                value="Male"
                                                name="Male"
                                                onChange={(e) =>
                                                    this.setState({
                                                        userObj: {
                                                            ...this.state.userObj,
                                                            gender: e.value,
                                                        },
                                                    })
                                                }
                                                checked={this.state.userObj.gender === "Male"}
                                            />
                                            <label htmlFor="Male" className="ml-1">
                                                Male
                      </label>
                                        </div>
                                        <div className="p-col-4 pb-1  p-field-radiobutton">
                                            <RadioButton
                                                disabled={!this.state.isUpdate}
                                                inputId="Female"
                                                value="Female"
                                                name="Female"
                                                onChange={(e) =>
                                                    this.setState({
                                                        userObj: {
                                                            ...this.state.userObj,
                                                            gender: e.value,
                                                        },
                                                    })
                                                }
                                                checked={this.state.userObj.gender === "Female"}
                                            />
                                            <label htmlFor="Female" className="ml-1">
                                                Female
                      </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12">
                                    <span className="boldLabel">Salutation</span>
                                    <Dropdown
                                        disabled={!this.state.isUpdate}
                                        value={this.state.userObj.salutation}
                                        options={this.salutationDropdownList}
                                        onChange={(e) =>
                                            this.setState({
                                                userObj: {
                                                    ...this.state.userObj,
                                                    salutation: e.value,
                                                },
                                            })
                                        }
                                        placeholder="Please Select"
                                    />
                                </div>

                                <div className="p-col-12">
                                    <span className="boldLabel">Marital Status</span>
                                    <Dropdown
                                        disabled={!this.state.isUpdate}
                                        value={this.state.userObj.maritalStatus}
                                        options={this.martialStatusDropdownList}
                                        onChange={(e) =>
                                            this.setState({
                                                userObj: {
                                                    ...this.state.userObj,
                                                    maritalStatus: e.value,
                                                },
                                            })
                                        }
                                        placeholder="Please Select"
                                    />
                                </div>

                                <div className="p-col-12">
                                    <span className="boldLabel">Race</span>
                                    <InputText
                                        keyfilter="alpha"
                                        disabled={!this.state.isUpdate}
                                        value={this.state.userObj.race}
                                        onChange={(e) =>
                                            this.setState({
                                                userObj: {
                                                    ...this.state.userObj,
                                                    race: e.currentTarget.value,
                                                },
                                            })
                                        }
                                    />
                                </div>
                                <div className="p-col-12">
                                    <span className="boldLabel">Religion</span>
                                    <InputText
                                        keyfilter="alpha"
                                        disabled={!this.state.isUpdate}
                                        value={this.state.userObj.religion}
                                        onChange={(e) =>
                                            this.setState({
                                                userObj: {
                                                    ...this.state.userObj,
                                                    religion: e.currentTarget.value,
                                                },
                                            })
                                        }
                                    />
                                </div>

                                <div className="p-col-12">
                                    <span className="boldLabel">Personal Email</span>
                                    <div
                                        hidden={this.state.ppEmailError === "" ? true : false}
                                        className="my-1"
                                    >
                                        <Message severity="error" text="Invalid Input" />
                                    </div>
                                    <InputText
                                        keyfilter="email"
                                        disabled={!this.state.isUpdate}
                                        value={this.state.userObj.pP_PersonalEmail}
                                        onChange={(e) =>
                                            this.setState({
                                                userObj: {
                                                    ...this.state.userObj,
                                                    pP_PersonalEmail: e.currentTarget.value,
                                                },
                                            })
                                        }
                                    />
                                </div>
                                <div className="p-col-12">
                                    <span className="boldLabel">Residential Address</span>
                                    <InputText
                                        disabled={!this.state.isUpdate}
                                        value={this.state.userObj.pP_ResidentialAddress}
                                        onChange={(e) =>
                                            this.setState({
                                                userObj: {
                                                    ...this.state.userObj,
                                                    pP_ResidentialAddress: e.currentTarget.value,
                                                },
                                            })
                                        }
                                    />
                                </div>
                                <div className="p-col-12">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 pt-2">
                                            <span className="boldLabel">Mobile Number</span>
											<div>
											<InputText
												className="col-3 mr-1"
												disabled={!this.state.isUpdate}
                                                value={this.state.dialCode}
												onChange={(e) =>
                                                    this.setState({dialCode: e.currentTarget.value})
                                                }
												tooltip="Dial Code"
												tooltipOptions={{ position: "top" }}
											/>
                                            <InputText
												className="col-8"
                                                keyfilter="int"
                                                disabled={!this.state.isUpdate}
                                                value={this.state.mobileNumber}
                                                onChange={(e) =>
                                                    this.setState({mobileNumber: e.currentTarget.value})
                                                }
												tooltip="Mobile Number"
												tooltipOptions={{ position: "top" }}
                                            />
											</div>
                                        </div>
                                        <div className="col-12 col-lg-6 pt-2">
                                            <span className="boldLabel">Home Number</span>
                                            <InputText
                                                keyfilter="int"
                                                disabled={!this.state.isUpdate}
                                                value={this.state.userObj.pP_HomeNumber}
                                                onChange={(e) =>
                                                    this.setState({
                                                        userObj: {
                                                            ...this.state.userObj,
                                                            pP_HomeNumber: e.currentTarget.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </div>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={{ height: 5, margin: 10 }} />
                    <div className="p-col-12 py-2">
                        <h5>Emergency Contact</h5>
                    </div>
                    <div className="p-grid p-justify-center">
                        <div className="p-col-12 p-lg-8">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <span className="boldLabel">Name</span>
                                    <InputText
                                        value={this.state.userObj.emergency_Name}
                                        disabled={!this.state.isUpdate}
                                        onChange={(e) =>
                                            this.setState({
                                                userObj: {
                                                    ...this.state.userObj,
                                                    emergency_Name: e.currentTarget.value,
                                                },
                                            })
                                        }
                                    />
                                </div>

                                <div className="p-col-12">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 pt-2">
                                            <span className="boldLabel">Contact Number</span>
                                            <InputText
                                                keyfilter="int"
                                                value={this.state.userObj.emergency_Contact}
                                                disabled={!this.state.isUpdate}
                                                onChange={(e) =>
                                                    this.setState({
                                                        userObj: {
                                                            ...this.state.userObj,
                                                            emergency_Contact: e.currentTarget.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-12 col-lg-6 pt-2">
                                            <span className="boldLabel">Relationship</span>
                                            <InputText
                                                value={this.state.userObj.emergency_Relationship}
                                                disabled={!this.state.isUpdate}
                                                onChange={(e) =>
                                                    this.setState({
                                                        userObj: {
                                                            ...this.state.userObj,
                                                            emergency_Relationship: e.currentTarget.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </div>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={{ height: 5, margin: 10 }} />
                    <div className="p-col-12 py-2">
                        <h5>Bank & Statutory Details</h5>
                    </div>
                    <div className="p-grid p-justify-center">
                        <div className="p-col-12 p-lg-8">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <span className="boldLabel">Bank Name</span>
                                    <Dropdown
                                        value={this.state.userObj.bank_Code}
                                        options={this.state.swiftList}
                                        onChange={(e) => {
                                            this.setState({
                                                userObj: {
                                                    ...this.state.userObj,
                                                    bank_Code: e.value,
                                                    bank_Name: this.state.swiftList[
                                                        this.state.swiftList.findIndex(
                                                            (obj) => obj.value === e.value
                                                        )
                                                    ].label,
                                                },
                                            });
                                        }}
                                        disabled={!this.state.isUpdate}
                                        placeholder="Please Select"
                                    />
                                </div>
                                <div className="p-col-12">
                                    <span className="boldLabel">
                                        Account Type (Savings/Current)
                                    </span>
                                    <InputText
                                        value={this.state.userObj.bank_AccountType}
                                        disabled={!this.state.isUpdate}
                                        onChange={(e) =>
                                            this.setState({
                                                userObj: {
                                                    ...this.state.userObj,
                                                    bank_AccountType: e.currentTarget.value,
                                                },
                                            })
                                        }
                                    />
                                </div>
                                <div className="p-col-12">
                                    <span className="boldLabel">Name of Account Holder</span>
                                    <InputText
                                        value={this.state.userObj.bank_PayeeName}
                                        disabled={!this.state.isUpdate}
                                        onChange={(e) =>
                                            this.setState({
                                                userObj: {
                                                    ...this.state.userObj,
                                                    bank_PayeeName: e.currentTarget.value,
                                                },
                                            })
                                        }
                                    />
                                </div>
                                <div className="p-col-12">
                                    <span className="boldLabel">Account Number (No Dashes)</span>
                                    <InputText
                                        keyfilter="money"
                                        value={this.state.userObj.bank_Number}
                                        disabled={!this.state.isUpdate}
                                        onChange={(e) =>
                                            this.setState({
                                                userObj: {
                                                    ...this.state.userObj,
                                                    bank_Number: e.currentTarget.value,
                                                },
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={{ height: 5, margin: 10 }} />
                    <div className="p-col-12 py-2">
                        <h5>Public Profile Permission</h5>
                    </div>
                    <div className="p-grid p-justify-center">
                        <div className="p-col-12 p-lg-8">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
									<div className="field-checkbox">
										<Checkbox
											className="mr-2"
											disabled={!this.state.isUpdate}
											inputId="publicprofilepermission"
											checked={this.state.userObj.publicProfilePermission}
											onChange={e => {
												this.setState({
													userObj: {
														...this.state.userObj,
														publicProfilePermission: e.checked
													}
												})
											}} />
										<label className="mb-0"htmlFor="publicprofilepermission">
											I agree to have my information (Birthdays) displayed on the Company Feed and in my Profile
										</label>
									</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br></br>


                    <div className="p-grid p-justify-center">
                        {this.state.isUpdate && (
                            <>
                                <Button
                                    label="Save"
                                    className="mr-2"
                                    onClick={() => this.submitForm}
                                />
                                <Button
                                    label="Cancel"
                                    type=""
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.setState({
                                            isUpdate: false,
                                            userObj: this.state.initialUserObj,
                                        });
                                    }}
                                />
                            </>
                        )}
                    </div>
                </form>
                <hr style={{ height: 5, margin: 10 }} />
                <div className="p-col-12 py-2">
                    <h5>Documents {docbuttons}</h5>
                </div>
                <div className="p-col-12">
                    <div className="p-grid p-justify-center">
                        <div className="p-col-12">{datatable}</div>
                    </div>
                </div>
                <form id="documentForm" onSubmit={this.submitDocument}>
                    <div className="p-grid p-justify-center">
                        <div className="p-col-12 p-md-6 p-lg-8">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <span className="boldLabel">Document Type:</span>
                                    <Dropdown
                                        value={this.state.userDocObj.documentTypeID}
                                        options={this.state.documentTypeDropdownList}
                                        onChange={(e) =>
                                            this.setState({
                                                userDocObj: {
                                                    ...this.state.userDocObj,
                                                    documentTypeID: e.value,
                                                },
                                            })
                                        }
                                        placeholder="Please Select"
                                        disabled={!this.state.isDocUpdate}
                                        required
                                    />
                                </div>

                                <div className="p-col-12">
                                    <p className="boldLabel">Upload File</p>
                                    <input
                                        style={{ color: "transparent", width: "110px" }}
                                        type="file"
                                        name="uploadFile"
                                        onChange={(e) => {
                                            this.uploadDocument(e);
                                        }}
                                        disabled={!this.state.isDocUpdate}
                                        required
                                    />
                                    {this.state.userDocObj.uploadPath}
                                </div>

                                <div className="p-col-12">
                                    <span className="boldLabel">Remarks:</span>
                                    <InputText
                                        id="documentRemarks"
                                        onChange={(e) =>
                                            this.setState({
                                                userDocObj: {
                                                    ...this.state.userDocObj,
                                                    documentRemarks: e.currentTarget.value,
                                                },
                                            })
                                        }
                                        disabled={!this.state.isDocUpdate}
                                        value={this.state.userDocObj.documentRemarks}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-justify-center">
                        {this.state.isDocUpdate ? (
                            <>
                                <Button
                                    type="submit"
                                    label="Save"
                                    className="mr-2"
                                // onClick={this.submitDocument}
                                />
                                <Button
                                    label="Cancel"
                                    type=""
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.setState({
                                            isDocUpdate: false,
                                            userDocObj: new UserDocumentModel(),
                                        });
                                    }}
                                />
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </form>
            </>
        );

        let display;
        if (this.state.isError) {
            display = <CustomError message={this.state.errorMsg} />;
        } else if (this.state.isLoading) {
            display = <ProgressSpinner />;
        } else {
            display = page;
        }

        return (
            <div className="row">
                <div className="col-12 col-xl-8">
                    <div className="card">
                        <h1 className="pb-2">{this.state.formHeader}</h1>

                        {display}
                    </div>
                </div>

                <Dialog
                    className="popupDialog"
                    header={this.state.dialogHeaderStr}
                    visible={this.state.visible}
                    modal={true}
                    onHide={this.hideDialog}
                    footer={
                        <div>
                            <Button
                                label="Close"
                                type="button"
                                iconPos="left"
                                icon={this.state.dialogIcon}
                                className={
                                    this.state.dialogShowError
                                        ? "p-button-danger"
                                        : "p-button-success"
                                }
                                onClick={this.hideDialog}
                            />
                        </div>
                    }
                >
                    {this.state.dialogStr}
                </Dialog>
            </div>
        );
    }
}
