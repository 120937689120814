import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import AccountingCodeService from "../../service/AccountingCodeService";
import {
  ActionTypes, CommonMethods,
  ParamIDTypes,
  RouteComponentProps
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { AccountingCodeModel } from "./AccountingCodeModel";


function AccountingCodeCreate(props: RouteComponentProps<any>) {
  const accountingCodeService = new AccountingCodeService();
  const commonMethods = new CommonMethods();
  const { id } = useParams<ParamIDTypes>();
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const [accountingCodeData, setAccountingCodeData] = useState<
    AccountingCodeModel
  >(new AccountingCodeModel());
  const [action, setAction] = useState<string>(ActionTypes.create);

  const [isError, setisError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [toHome, setToHome] = useState(false);
  
  
  useEffect(() => {
    if (id !== undefined) {
      // update
      accountingCodeService
        .getAccountingCodeByID(id)
        .then((res) => {
          setAccountingCodeData(res);
          setIsLoading(false);
      })
      .catch((err) => {
        setisError(true);
        setErrorMessage(commonMethods.getErrorMessage(err));
      });
    
    setAction(ActionTypes.update);
  } else {
    // create
    setIsLoading(false);
  }

},[]);

  const handleSubmit = (event:any) => {
    event.preventDefault();
    if (action === ActionTypes.create) {
      accountingCodeService
        .createAccountingCode(accountingCodeData)
        .then((res) => {
          SetSuccess("New Accounting Code created!");
          setToHome(true);
        })
        .catch((err) => {
          SetError(commonMethods.getErrorMessage(err));
          // SetError("Could not create new accounting code.");
        });
    } else if (action === ActionTypes.update){
        accountingCodeService
          .updateAccountingCode(accountingCodeData)
          .then((res) => {
            SetSuccess("Acounting Code updated!");
            setToHome(true);
          })
          .catch((err) => {
            SetError(commonMethods.getErrorMessage(err));
            // SetError("Could not update accounting type.");
          });
    }
  };


  const form = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="p-grid p-fluid">
        < div className="p-col-12">
            <span className="boldLabel">
              Accounting Code <span className="requiredRed">*</span>
            </span>
            <InputText
              value={accountingCodeData.accountingCode}
              onChange={(event) =>
                setAccountingCodeData({
                  ...accountingCodeData,
                  accountingCode: event.currentTarget.value,
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Accounting Code Name <span className="requiredRed">*</span>
            </span>
            <InputText
              value={accountingCodeData.accountingCodeName}
              onChange={(event) =>
                setAccountingCodeData({
                  ...accountingCodeData,
                  accountingCodeName: event.currentTarget.value,
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Accounting Code Type{" "}
              <span className="requiredRed">*</span>
            </span>
            <InputText
              value={accountingCodeData.accountingCodeType}
              onChange={(event) =>
                setAccountingCodeData({
                  ...accountingCodeData,
                  accountingCodeType: event.currentTarget.value,
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Accounting Code Description{" "}
          
            </span>
            <InputText
              value={accountingCodeData.accountingCodeDescription}
              onChange={(event) =>
                setAccountingCodeData({
                  ...accountingCodeData,
                  accountingCodeDescription: event.currentTarget.value,
                })
              }
              
            />
          </div>
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button type="submit" label={action} />
        </div>
      </form>
    );
  };

  let display;
  if (isLoading) {
    display = <ProgressSpinner />;
  } else if (isError) {
    display = <CustomError message={errorMsg} />;
  } else {
    display = form();
  }

  return (
    <div className="row">
      {toHome ? <Redirect push to="/finance/accounting_code" /> : null}
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
        <div className="card">
          <h1 className="pb-2">{action} Accounting Code</h1>
          <div className="requiredRed">
            <p>* Required</p>
          </div>
          {display}
        </div>
      </div>
    </div>
  );
}
 
export default AccountingCodeCreate;