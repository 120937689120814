import React from "react";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import LeaveReport from "./LeaveReport";
import ProfileReport from "./ProfileReport";
import ClaimsReport from "./ClaimsReport";
import ContractReport from "./ContractReport";

export class ReportsModel {
  public employeeList: Array<string> = new Array<string>();
  public employeeListStrArr: Array<string> = new Array<string>();
  public inactiveEmployeeListStrArr: Array<string> = new Array<string>();
  public startDate: Date = new Date(new Date().getFullYear(), 0, 1);
  public endDate: Date = new Date(new Date().getFullYear(), 11, 31, 23, 59, 0);
  public companyID: string = CommonMethods.EmptyGuid;
}

export class ProfileReportModel {
  public employeeList: Array<string> = new Array<string>();
  public employeeListStrArr: Array<string> = new Array<string>();
  public inactiveEmployeeListStrArr: Array<string> = new Array<string>();
  public startDate: Date = new Date(new Date().getFullYear(), 0, 1);
  public endDate: Date = new Date(new Date().getFullYear(), 11, 31, 23, 59, 0);
  public companyID: string = CommonMethods.EmptyGuid;
  public selectedUserFilter: Array<string> = new Array<string>();
  public selectedContractFilter: Array<string> = new Array<string>();
}


export interface ReportType {
  groupCompany: boolean;
  company: boolean;
}
export class ReportLeaveObjectModel {
  public fullDisplayName: string = "";
  public leaveType: string = "";
  public startDate: string = "";
  public endDate: string = "";
  public reason: string = "";
  public requestedDays: number = 0;
  public approvalStatus: string = "";
  public requestedDate: string = "";
  public approvedDate: string = "";
  public approvedBy: string = "";
}

function Reports(props: RouteComponentProps<any>) {
  let page = (
    <>
      <LeaveReport {...props} />
      <ProfileReport {...props} />
      <ContractReport {...props} />
      <ClaimsReport />
    </>
  );

  return (
    <div className="row">
      <div className="col-12 col-xl-6">
        <div className="card">
          <h1 className="pb-2">Reports</h1>
          <p>
            Make your own custom report as needed. Sort, select, download. It's
            that simple.
          </p>
          {page}
        </div>
      </div>
    </div>
  );
}

export default Reports;
