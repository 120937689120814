import { ProgressSpinner } from "primereact/progressspinner";
import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import CustomError from "./resources/Error";
import { MenuList } from "./resources/MenuList";

interface AppState {
  defaultMenu: any;
  isFetching: boolean;
  isError: boolean;
  errorMsg: string;

  redirectOut: boolean;
  redirectPath: string;
}

export class Sitemap extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  menuList: any;
  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      defaultMenu: {},

      isFetching: false,
      isError: false,
      errorMsg: "",

      redirectOut: false,
      redirectPath: ""
    };
    this.menuList = new MenuList();

    this.addDefaultMenu = this.addDefaultMenu.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
  }

  componentDidMount() {
    this.addDefaultMenu();
  }
  
  // DEFAULT MENU
  addDefaultMenu() {
    let finalMenu = this.state.defaultMenu;
    let mainMenu: any = [];

    this.menuList.DefaultList.items.forEach((defaultModuleName: string) => {
      let moduleToAdd = this.menuList.Menu[defaultModuleName];
      if ("to" in moduleToAdd || "command" in moduleToAdd) {
        mainMenu.push(moduleToAdd);
      }
    });
    finalMenu = Object.assign(finalMenu, {
      [this.menuList.DefaultList.label]: mainMenu
    });

    this.menuList.SubMenuList.forEach((defaultModule: string) => {
      let singleSubMenu: Array<object> = [];
      this.menuList[defaultModule].items.forEach(
        (defaultModuleName: string) => {
          let moduleToAdd = this.menuList.Menu[defaultModuleName];
          singleSubMenu.push(moduleToAdd);
        }
      );
      finalMenu = Object.assign(finalMenu, {
        [this.menuList[defaultModule].label]: singleSubMenu
      });
    });

    this.setState({ defaultMenu: finalMenu });
  }

  renderMenu() {
    const { defaultMenu } = this.state;
    const display: Array<JSX.Element> = [];

    Object.keys(defaultMenu).forEach((key: string) => {
        const displayLinks: Array<JSX.Element> = [];
        defaultMenu[key].forEach((moduleItem: any) => {
          displayLinks.push(
            <li className="list-group-item">
              <Link to={moduleItem.to}>{moduleItem.label}</Link>
            </li>
          );
        });

        display.push(
          <div className="p-col-3 card p-0">
            <h5 className="card-header">{key}</h5>
            <div className="card-body p-0">
              <ul className="list-group list-group-flush">{displayLinks}</ul>
            </div>
          </div>
        );
      });

    return <>{display}</>;
  }


  // RENDER
  render() {
    const { isError, isFetching, errorMsg } = this.state;
    let page = (
      <div className="p-flex">
        <div className="p-grid p-justify-center pt-3 pb-3 mb-3">
          <this.renderMenu />
        </div>
      </div>
    );

    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath
          }}
        />
      );
    }

    let display;
    if (isError) {
      display = <CustomError message={errorMsg} />;
    } else if (isFetching) {
      display = <ProgressSpinner />;
    } else {
      display = page;
    }

    return (
      <div className="row">
        <div className="col-12 col-xl-8">
          <div className="card">
            <h1 className="pb-2"> Sitemap </h1>
            {display}
          </div>
        </div>
      </div>
    );
  }
}
