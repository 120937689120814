// import { InputTextarea } from 'primereact/inputtextarea'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { CommentModel } from '../../../Model/CommentModel'
import CompanyFeedService from '../../../Service/CompanyFeedService'
import CommentDraftJsEditor from '../../DraftJsEditors/CommentDraftJsEditor'
import Editor from '@draft-js-plugins/editor';
import { NotificationsService } from '../../../../../service/NotificationsService'
import { getContext, toTitleCase } from '../../../CompanyFeedFunctions'
import { ACTIONS } from '../../../DispatchActions';

// check if there is parentComment
export default function EditComment({
    comment,
    useEditMode,
    contextName,
    parentComponent,
}: {
    comment: CommentModel;
    useEditMode: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    contextName: string
    parentComponent: string | undefined
}) {
    const { state, dispatch } = useContext(getContext(contextName));
    const [editCommentText, setEditCommentText] = useState('')
    const [isEditMode, setIsEditMode] = useEditMode
    const editCommentSubmitRef = useRef<HTMLButtonElement>(null)
    const editCommentCancelRef = useRef<HTMLButtonElement>(null)
    // const { dispatch } = useContext(CompanyFeedContext);
    const [isEditorFocused, setIsEditorFocused] = useState(false)
    const [mentionSuggestionsOpened, setMentionSuggestionsOpened] = useState(false)

    const [rawEditorContentJsonStr, setRawEditorContentJsonStr] = useState<string | null>(null)
    const [usersTaggedArr, setUsersTaggedArr] = useState<null | string[]>(null)
    const editorRef = useRef<Editor>(null);
    const [hasText, setHasText] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const notifSvc = new NotificationsService();
    const [previouslyTaggedUsers, setPreviouslyTaggedUsers] = useState<string[]>([])

    useEffect(() => {
        if (comment.usersTagged) setPreviouslyTaggedUsers(JSON.parse(comment.usersTagged))
    }, [])

    useEffect(() => {
        console.log('previouslyTaggedUsers', previouslyTaggedUsers)
    }, [previouslyTaggedUsers])


    useEffect(() => {
        console.log('usersTaggedArr', usersTaggedArr)
    }, [usersTaggedArr])

    useEffect(() => {
        // console.log('isEditMode', isEditMode)
        setIsEditorFocused(true)
    }, [isEditMode])

    useEffect(() => {
        setRawEditorContentJsonStr(comment.rawEditorContentJsonStr)
        if (comment.commentText) setEditCommentText(comment.commentText)
        // if (comment.usersTagged) setUsersTaggedArr(JSON.parse(comment.usersTagged))
        // console.log('edit comment rawdraft', comment.rawEditorContentJsonStr)
    }, [comment])

    useEffect(() => {
        // console.log('focus', focus)
        // console.log('mentionSuggestionsOpened', mentionSuggestionsOpened)
        const listener = (event: KeyboardEvent) => {
            if ((event.code === "Enter" && !event.shiftKey) || event.code === "NumpadEnter") {
                event.preventDefault();
                if (editCommentSubmitRef.current) {
                    editCommentSubmitRef.current.click()
                }
            }
            if (event.code === "Escape") {
                event.preventDefault();
                if (editCommentCancelRef.current) {
                    editCommentCancelRef.current.click()
                }
            }
        };
        if (mentionSuggestionsOpened) document.removeEventListener("keydown", listener);
        if (isEditorFocused) document.addEventListener("keydown", listener);
        if (!isEditorFocused) document.removeEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [isEditorFocused, mentionSuggestionsOpened]);

    async function handleEditCommentSubmit(evt: React.FormEvent<HTMLFormElement>) {
        evt.preventDefault();

        const companyFeedService = new CompanyFeedService();
        try {
            let commentObject = comment
            commentObject.commentText = null
            // commentObject.editedCommentText = editCommentText
            commentObject.rawEditorContentJsonStr = rawEditorContentJsonStr
            if (usersTaggedArr) commentObject.usersTagged = JSON.stringify(usersTaggedArr)
            const result = await companyFeedService.editComment(commentObject)
            const editedComment = result
            // console.log('editedComment result,', editedComment)

            if (editedComment.parentCommentId === null) {
                if (parentComponent === "TodayBirthdayPost") dispatch({ type: ACTIONS.BIRTHDAY_POST.EDIT_COMMENT, payload: { editedComment } })
                else dispatch({ type: ACTIONS.EDIT_COMMENT, payload: { editedComment } })
                // notifications
                if (usersTaggedArr) {
                    let notifObjArr = []
                    // exclude users previously tagged in comment.usersTagged
                    let usersToNotify = usersTaggedArr.filter((el) => !previouslyTaggedUsers.includes(el));
                    // don't notify sender if they tag themself
                    usersToNotify = usersToNotify.filter((el) => (el !== state.currentUser.userId))

                    for (let i = 0; i < usersToNotify.length; i++) {
                        let notifObj = {
                            RecipientUserID: usersToNotify[i],
                            SenderUserID: state.currentUser.userId,
                            Url: `/companyfeed/post/${comment.postId}?commentId=${comment.commentId}`,
                            MsgHeader: "Tagged In Comment",
                            Message: `${toTitleCase(state.currentUser.firstName) + " " + toTitleCase(state.currentUser.lastName)} has tagged you in a comment`
                        }
                        notifObjArr.push(notifObj)
                        // console.log('notifObj create', notifObj)
                    }
                    notifSvc.createNotification(notifObjArr).then()
                }
            } else {
                if (parentComponent === "TodayBirthdayPost") dispatch({ type: ACTIONS.BIRTHDAY_POST.EDIT_CHILD_COMMENT, payload: { editedComment } })
                else dispatch({ type: ACTIONS.EDIT_CHILD_COMMENT, payload: { editedComment } })
                // notifications
                if (usersTaggedArr) {
                    let notifObjArr = []
                    // exclude users previously tagged in comment.usersTagged
                    let usersToNotify = usersTaggedArr.filter((el) => !previouslyTaggedUsers.includes(el));
                    // don't notify sender if they tag themself
                    usersToNotify = usersToNotify.filter((el) => (el !== state.currentUser.userId))

                    console.log('usersTaggedArr', usersTaggedArr)
                    console.log('previouslyTaggedUsers', previouslyTaggedUsers)
                    console.log('usersToNotify', usersToNotify)

                    for (let i = 0; i < usersToNotify.length; i++) {
                        let notifObj = {
                            RecipientUserID: usersToNotify[i],
                            SenderUserID: state.currentUser.userId,
                            Url: `/companyfeed/post/${comment.postId}?commentId=${comment.parentCommentId}&replyCommentId=${comment.commentId}`,
                            MsgHeader: "Tagged In Comment",
                            Message: `${toTitleCase(state.currentUser.firstName) + " " + toTitleCase(state.currentUser.lastName)} has tagged you in a comment`
                        }
                        notifObjArr.push(notifObj)
                        // console.log('notifObj create', notifObj)
                    }
                    notifSvc.createNotification(notifObjArr).then()
                }
            }
        } catch (e) {
            console.log('error', e)
        } finally {
            resetEditComment()
        }
    }

    function resetEditComment() {
        setEditCommentText('')
        setRawEditorContentJsonStr(null)
        setUsersTaggedArr(null)
        setIsEditMode(false)
    }

    return (
        <div className="edit-comment-container">
            <form onSubmit={handleEditCommentSubmit}>
                <div className="edit-comment">
                    <div className="edit-comment-input">
                        <CommentDraftJsEditor
                            rawEditorContentJsonStr={rawEditorContentJsonStr}
                            setRawEditorContentJsonStr={setRawEditorContentJsonStr}
                            setUsersTaggedArr={setUsersTaggedArr}
                            editorRef={editorRef}
                            setHasText={setHasText}
                            commentText={comment.commentText}
                            isSubmitted={isSubmitted}
                            setIsSubmitted={setIsSubmitted}
                            inputPlaceholder="Write a comment"
                            parentComponent="EditComment"
                            setIsEditorFocused={setIsEditorFocused}
                            setMentionSuggestionsOpened={setMentionSuggestionsOpened}
                            contextName={contextName}
                        />
                    </div>
                    <div className="edit-comment-buttons">
                        <button
                            ref={editCommentSubmitRef}
                            type="submit"
                            disabled={!hasText}
                            className="edit-comment-submit"
                        >
                            <span className="material-icons">check</span>
                        </button>
                        <button
                            type="button"
                            className="edit-comment-cancel"
                            onClick={resetEditComment}
                            ref={editCommentCancelRef}
                        >
                            <span className="material-icons">close</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}