import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import ContactUs from "./ContactUs";

function Priceplans(props: {
  header: string;
  description: string;
  hidebasic: boolean;
}) {
  const [header, setHeader] = useState(props.header);
  const [description, setDescription] = useState(props.description);
  const [hidebasic, setHideBasic] = useState(props.hidebasic);
  const [contactUsModal, setContactUsModal] = useState<boolean>(false);
  // From expired or
  useEffect(() => {
    if (props.header === undefined || props.header === "") {
      setHeader("Gain access to Premium Content now!");
      setDescription("");
      setHideBasic(true);
    }
  }, []);
  return (
    <div>
 
      <Dialog
        header="Contact Us"
        visible={contactUsModal}
        modal={true}
        //dismissableMask={true}
        onHide={() => setContactUsModal(false)}
      >
        <ContactUs/>
      </Dialog>
      <section
        className="fdb-block"
        data-block-type="call_to_action"
        data-id="2"
      >
        <div className="container lead">
          <div className="row">
            <div className="col-12 text-center">
              <h1>{header}</h1>
              <p className="h3">{description}</p>
            </div>
          </div>

          <div className="row align-items-center justify-content-center pt-2 pt-lg-5">
            <div className="col-10">
              <div className="row justify-content-center">
                <div
                  className="col-12 col-xl-5 col-md-5 border border-freecolour rounded pt-4 mx-2 pl-4 pb-4"
                  hidden={hidebasic}
                >
                  <h2>Basic</h2>
                  <p className="lead">
                    90 days free trial with a maximum of 20 employees.
                  </p>

                  <h3 className="pb-4">S$0</h3>

                  <Button
                    className="btn btn-primary btn-login"
                    onClick={() => (window.location.href = "/sme")}
                    label="Try for free"
                  />

                  <p className="blackfont pt-4">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    HR platform
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Payroll (Partial)
                  </p>

                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Leave
                  </p>

                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Claims
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Facilities Booking
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Permissions Control
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                   Reports Generation
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Company Noticeboard
                  </p>
                  <p >
                    <i
                      className="fa fa-times greycross px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Project Management
                  </p>

                  <p>
                    <i
                      className="fa fa-times greycross px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Boss Dashboard (Partial)
                  </p>
                  <p>
                    <i
                      className="fa fa-times greycross px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    KPI Tracking
                  </p>
                  {/* <p>
                    <i className="fa fa-times greycross px-2" aria-hidden="true"></i>{" "}
                    Equipment Tracking
                  </p> */}
                </div>
                <div className="col-12 col-xl-5 col-md-5 premiumbg border border-premiumcolour rounded pt-4 mx-2 pl-4 pb-4">
                  <h2>Premium</h2>
                  <p className="lead">
                    Our All-In-One HR platform for maximum automation.
                  </p>

                  <h3 className="pb-4">S$15 per employee</h3>

                  <Button
                    className="btn btn-primary btn-login"
                    onClick={() => {
                      hidebasic
                        ? setContactUsModal(true)
                        : (window.location.href = "/contactus");
                    }}
                    label="Contact Us"
                  />

                  <p className="blackfont pt-4">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    HR platform
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Payroll (Full)
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Leave
                  </p>

                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Claims
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Facilities Booking
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Permissions Control
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                   Reports Generation
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Company Noticeboard
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Project Management
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Boss Dashboard (Full)
                  </p>
                  <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    KPI tracking
                  </p>
                  {/* <p className="blackfont">
                    <i
                      className="fa fa-check greentick px-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Equipment Tracking
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Priceplans;
