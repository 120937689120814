import { BreadCrumb } from "primereact/breadcrumb";
import React from "react";

export function AdminDashboardBreadCrumbs() {
  const items: { label: string; url?: string }[] = [];
  let home = {
    icon: "pi pi-home",
    label: "Admin Dashboard",
    url: "/admin",
  };
  let companyName: string | null = "";

  // COMPANY NAME FOR ADMIN PAGES
  if (
    window.location.pathname.includes("admin/") ||
    window.location.pathname.includes("finance")
  ) {
    companyName = localStorage.getItem("currentAccessingCompanyName");
  }

  let companyNamePart: JSX.Element | undefined;
  if (companyName !== null && companyName !== "") {
    companyNamePart = <h5 className="settingsFont">{companyName}</h5>;
  }

  // BREADCRUMBS
  let pageNameParts = window.location.pathname.split("/");
  pageNameParts.shift(); // first is empty

  pageNameParts.forEach((part: string, index: number) => {
    var alphaRegx = /^[A-Za-z_]+$/;

    if (part.match(alphaRegx) && !part.match(/^admin$/)) {
      // skip the admin index 0
      // caps first letter of each word
      part = part.replace(/_/g, " ");
      part = part
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");

      if (index < 2) {
        if (part !== "Finance") {
          let returnurl = "/";
          returnurl += pageNameParts.slice(0, index + 1).join("/");
          items.push({ label: part, url: returnurl });
        }
      }
      if (index == 2) {
        items.push({ label: part });
      }
      // Because there are some like /admin/operational_cost_supplier/create/supplier and the last one doesnt need to show so we stop at 2
    }
  });
  let breadcrumbs;

  if (companyNamePart && items.length > 0) {
    breadcrumbs = (
      <BreadCrumb
        model={items}
        home={home}
        className="adminDashboardBreadcrumb"
      />
    );
  }

  return (
    <>
     {companyNamePart} {breadcrumbs}
    </>
  );
}
