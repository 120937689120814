import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { Redirect } from "react-router";
import ReactTooltip from "react-tooltip";
import { ClaimsService } from "../../service/ClaimsService";
import { CommonDocumentMethods } from "../resources/CommonDocumentMethods";
import { CommonMethods, DropdownModel } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { ClaimsModel } from "./ClaimsModel";
import { InputSwitch } from "primereact/inputswitch";
import { CreateAnnouncement } from "../notifications/AnnouncementCreate";
import { create } from "lodash";

function CreateClaims(props: any) {

  const [createClaimData, setCreateClaimData] = useState<
  ClaimsModel>(new ClaimsModel());

  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const [claimType, setClaimType] = useState([]);
  const [campaigns, setCampaigns] = useState<DropdownModel[]>(
    new Array<DropdownModel>()
  );
  const [companyDropdown, setCompanyDropdown] = useState<DropdownModel[]>(
    new Array<DropdownModel>()
  );
  const [campaignTypes, setCampaignTypes] = useState([]);
  const [displayCamTypes, setDisplayCamTypes] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState([]);
  const [receiptPath, setReceiptPath] = useState<string>("");
  const [receiptPathFile, setReceiptPathFile] = useState<(File | null)[]>(); // file
  const [previewReceipt, setPreviewReceipt] = useState<any>([]); // base64 string
  const [GSTincluded, setGSTincluded] = useState<boolean>(false);
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const [pastGST, setPastGST] = useState<boolean>(false)
  // const [errorMsg, setErrorMessage] = useState<string>("");

  const [isPremiumPlan, setIsPremiumPlan] = useState<boolean>(false);
  const commonMethods = new CommonMethods();
  let claimsService = new ClaimsService();

  let uploader: FileUpload | null;

  useEffect(() => {
    async function setDefaults() {
      commonMethods.getCostTypeDropdown().then((res) => {
        setClaimType(res);
      });

      commonMethods
        .getCampaignsUserDropdown()
        .then((res) => {
          setCampaigns([{ label: "None", value: null }, ...res]);
        })
        .catch((err) => setIsError(true));

      commonMethods.getCurrencyDropdown().then((res) => {
        res = commonMethods.addCommonCurrencies(res);
        setCurrencies(res);
      });

      commonMethods.getCampaignTypeDropdown().then((res) => {
        setCampaignTypes(res);
      });

      commonMethods.hasPremiumPlanAccess().then((res) => {
        setIsPremiumPlan(res);
      });

      commonMethods.getCompanyDropdownListByGcid().then((res) => {
        setCompanyDropdown(res);
      });
    }
    setDefaults().then(() => {
      setIsLoading(false);
      setCreateClaimData({...createClaimData,
        claimID: CommonMethods.EmptyGuid,
        campaignType: CommonMethods.EmptyGuid,
        companyID: CommonMethods.EmptyGuid,
        submittedBy: CommonMethods.EmptyGuid});
    });
  }, []);

  useEffect(() => {
    setDisplayCamTypes(false);
    if (createClaimData.campaignID === null) {
      setDisplayCamTypes(true);
    }
  }, [createClaimData.campaignID, isPremiumPlan]);

  useEffect(() => {
    setCreateClaimData({...createClaimData, receiptPath: receiptPath});
    setCreateClaimData({...createClaimData, receiptPathFile: receiptPathFile});
  }, [receiptPath, receiptPathFile]);

  const validation = (data: any) => {
    if (data.amount === undefined || data.amount === "" || data.amount === 0) {
      SetError("Please enter a claim amount");
      return false;
    } else if (
      data.receiptPathFile === undefined ||
      data.receiptPathFile === [] || data.receiptPathFile === null
    ) {
      SetError("Please upload your receipts");
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (GSTincluded) {
      // calculate tax
	  if (pastGST){
		console.log("pastGST selected, tax amount should be 7/107");
		setCreateClaimData({...createClaimData, taxAmount: createClaimData.amount * (7 / 107)});
	  } else {
		console.log("pastGST NOT selected, tax amount should be 8/108");
		setCreateClaimData({...createClaimData, taxAmount: createClaimData.amount * (8 / 108)});
	  }
    } else {
      setCreateClaimData({...createClaimData, taxAmount: 0});
    }
  }, [GSTincluded, pastGST]);

  // 18/08/2020 - if save directly to setValue("remarks"), it doesn't allow copy-paste and will randomly get stuck.
  useEffect(() => {
    setCreateClaimData({...createClaimData, remarks: createClaimData.remarks});
  }, [createClaimData.remarks]);

  console.log(createClaimData.taxAmount);

  const handleSubmit = (event:any) => {
    event.preventDefault();

    if (!validation(createClaimData)) {
      return;
    }

    if (props.submit) {
      claimsService
        .createClaim(createClaimData)
        .then((res) => {
          SetSuccess("Claim has been made");
          setRedirectPath("/claims/view");
          setRedirectOut(true);
        })
        .catch((err) => SetError(commonMethods.getErrorMessage(err)));
    } else if (!props.submit) {
      claimsService
        .createClaim(createClaimData)
        .then((res) => {
          SetSuccess("Claim has been updated");
        })
        .catch((err) => SetError(commonMethods.getErrorMessage(err)));
    }
  };

  const campaignTypeIfNone = () => {
    if (displayCamTypes) {
      return (
        <>
          <div className="p-grid">
            <div className="p-col-12 p-fluid">
              <span className="boldLabel">
                Campaign Type <span className="requiredRed">*</span>
              </span>

              <Dropdown
                options={campaignTypes}
                value={createClaimData.campaignType}
                required
                placeholder="Please Select"
                scrollHeight="350px"
                onChange={(e) => {
                  setCreateClaimData({...createClaimData, campaignType: e.value});
                }}
              />
            </div>
          </div>

          <div className="p-grid">
            <div className="p-col-12 p-fluid">
              <span className="boldLabel">
                Company <span className="requiredRed">*</span>
              </span>

              <Dropdown
                options={companyDropdown}
                value={createClaimData.companyID}
                required
                placeholder="Please Select"
                scrollHeight="350px"
                onChange={(e) => {
                  setCreateClaimData({...createClaimData, companyID: e.value});
                }}
              />
            </div>
          </div>
        </>
      );
    }
  };

  const setReceiptFile = (e: any) => {
    var filePathList: (File | null)[] = [];
    e.map(async (element: File) => {
      const image = await CommonDocumentMethods.uploadImageOrDocument(element);
      if (image.errorMsg !== "") {
        SetError(image.errorMsg);
      } else {
        filePathList.push(image.file);
        return image.base64;
      }
      setReceiptPath(element.name);
    });
    // Promise.all(promises).then(res => {
    //   setPreviewReceipt(res);
    // });
    setReceiptPathFile(filePathList);
    setCreateClaimData({...createClaimData, receiptPathFile: filePathList});
    SetSuccess("Uploaded");
  };

  console.log(pastGST);

  if (isLoading) {
    return <ProgressSpinner />;
  } else if (isError) {
    return <CustomError message="error" />;
  } else if (redirectOut) {
    return <Redirect push to={redirectPath} />;
  } else {
    return (
      <div>
        <div className="requiredRed">* Required</div>
        <form onSubmit={handleSubmit}>
          <div className="p-grid">
            <div className="p-col-12 p-fluid">
              <span className="boldLabel">
                Claim Type <span className="requiredRed">*</span>
              </span>

              <Dropdown
                options={claimType}
                required
                placeholder="Please Select"
                onChange={(e) => {
                  setCreateClaimData({...createClaimData, claimTypeID: e.value});
                }}
                value={createClaimData.claimTypeID}
              />
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col-12 p-fluid">
              <span className="boldLabel">
                Campaign <span className="requiredRed">*</span>{" "}
                {!isPremiumPlan && (
                  <span data-for="claimcampaign" data-tip="">
                    <i className="fas fa-info-circle informationStyle"></i>
                  </span>
                )}
              </span>
              {!isPremiumPlan && (
                <ReactTooltip
                  id="claimcampaign"
                  aria-haspopup="true"
                  className="tooltipFont"
                >
                  <p className="text-center mb-0">
                    You can tag claims under campaigns for easier
                    classification. This is a premium plan module.
                  </p>
                </ReactTooltip>
              )}

              <Dropdown
                options={campaigns}
                required
                placeholder="Please Select"
                filter={true}
                filterPlaceholder="Search"
                scrollHeight="350px"
                onChange={(e) => {
                  setCreateClaimData({...createClaimData, campaignID: e.value});
                }}
                value={createClaimData.campaignID}
              />
            </div>
          </div>
          {campaignTypeIfNone()}
          <div className="p-grid">
            <div className="p-col-12 p-fluid">
              <span className="boldLabel">
                Currency <span className="requiredRed">*</span>
              </span>

              <Dropdown
                options={currencies}
                required
                placeholder="Please Select"
                onChange={(e) => {
                  setCreateClaimData({...createClaimData, currency: e.value});
                }}
                value={createClaimData.currency}
              />
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col-12 p-fluid">
              <span className="boldLabel">
                Total Amount <span className="requiredRed">*</span>
              </span>

              <NumberFormat
                className="p-inputtext"
                thousandSeparator={true}
                decimalScale={2}
                value={createClaimData.amount}
                fixedDecimalScale={true}
                required
                onValueChange={(e) => {
                  setCreateClaimData({...createClaimData, amount: e.floatValue!});
                }}
              />
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col-12 p-fluid">
              <span className="boldLabel">
                Tax Included? <span className="requiredRed">*</span>
              </span>
              <div className="p-grid p-col-12 p-1">
                <div className="p-col-4 pb-1 p-field-radiobutton">
                  <RadioButton
                    inputId="YesAgent"
                    value={true}
                    name="Yes"
                    onChange={(e) => {
                      setGSTincluded(e.value);
                    }}
                    checked={GSTincluded === true}
                  />
                  <label htmlFor="Yes" className="ml-1">
                    Yes
                  </label>
                </div>
                <div className="p-col-4 pb-1 p-field-radiobutton">
                  <RadioButton
                    inputId="NoAgent"
                    value={false}
                    name="No"
                    onChange={(e) => {
                      setGSTincluded(e.value);
					  setPastGST(false);
                    }}
                    checked={GSTincluded === false}
                  />
                  <label htmlFor="No" className="ml-1">
                    No
                  </label>
                </div>
              </div>
            </div>
			{GSTincluded &&
				<div className="p-col-12">
					<p className="boldLabel mb-0 pb-0">GST Before 2023{" "}
						<i
							className="fas fa-info-circle informationStyle"
							data-tip=""
							data-for="pastgst"
						></i>
					</p>
					<ReactTooltip
						id="pastgst"
						aria-haspopup="true"

						className="tooltipFont"
						>
						<p>Select this for receipts that reflect purchases/services charged at 7% GST before 1 Jan 2023</p>
						</ReactTooltip>
					<InputSwitch
						checked={pastGST}
						onChange={(e) =>
							setPastGST(e.value)
						}
					/>
				</div>
			}
          </div>
          {/* <div className="p-grid">
            <div className="p-col-12 p-fluid">
              <span className="boldLabel">Tax Amount </span>
              <RHFInput
                as={
                  <NumberFormat
                    className="p-inputtext"
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    placeholder="0.00"
                  />
                }
                register={register}
                setValue={setValue}
                name="taxAmount"
              />
            </div>
          </div> */}
          <div className="p-grid">
            <div className="p-col-12 p-fluid">
              <span className="boldLabel">
                Receipt Date <span className="requiredRed">*</span>
              </span>
              <Calendar
                name="receiptDate"
                dateFormat="dd/mm/yy"
                monthNavigator={true}
                yearNavigator={true}
                readOnlyInput={true}
                yearRange="1900:2030"
                value={createClaimData.receiptDate}
                onChange={(e) => {
                  setCreateClaimData({...createClaimData, receiptDate: new Date(e.target.value.toString())});
                }}
                placeholder="DD/MM/YYYY"
                required
              ></Calendar>
            </div>
          </div>
          <div className="p-grid">
            <div
              className="p-col-12 p-fluid"
              data-tour-id="claim-receipt-upload"
              data-tour="It is essential that you upload your claim receipts!"
            >
              <span className="boldLabel">
                Receipt Upload <span className="requiredRed">*</span> (Please
                press Upload after choosing your documents)
              </span>
              <FileUpload
                ref={(el) => (uploader = el)}
                uploadHandler={(event) => {
                  setReceiptFile(event.files);
                }}
                customUpload={true}
                multiple={true}
                // auto={true}
                accept=".pdf,image/*"
                maxFileSize={5000000}
                onClear={() => {
                  setReceiptPathFile(undefined);
                  setReceiptPath("");
                }}
              />
              {/* <input
                style={{ color: "transparent", width: "110px" }}
                type="file"
                name="receiptPath"
                accept=".jpg, .jpeg, .png, .pdf"
                onChange={setReceiptFile}
                required
                multiple
              /> */}
            </div>
          </div>

          <div className="p-grid">
            {previewReceipt.map(
              (element: string | undefined, index: number) => {
                return (
                  <div
                    className="p-col-12 p-fluid d-flex justify-content-center"
                    key={index}
                  >
                    <div
                      className="companyImageCropper text-center"
                      style={{ height: "50vh", width: "50vw" }}
                    >
                      <object
                        data={element}
                        // type="application/pdf"
                        width="100%"
                        height="100%"
                      ></object>
                    </div>
                  </div>
                );
              }
            )}
          </div>

          <div className="p-grid">
            <div
              className="p-col-12 p-fluid"
              data-tour-id="claim-remarks"
              data-tour="Be very specific with what you are claiming for or your claim will be rejected! You have been warned."
            >
              <span className="boldLabel">
                Remarks <span className="requiredRed">*</span>{" "}
              </span>
              {isPremiumPlan && (
                <span className="text-danger">
                  (Please state all details specifically e.g. Shoot at ABC cafe
                  for dishes X, Y, and Z)
                </span>
              )}
              <InputTextarea
                rows={5}
                cols={30}
                required
                value={createClaimData.remarks}
                onChange={(e) => {
                  setCreateClaimData({...createClaimData, remarks: e.currentTarget.value});
                }}
              />
            </div>
          </div>
          <div className="p-grid p-justify-center pt-4">
            <Button
              type="submit"
              label={props.submit ? "Submit" : "Update"}
              // disabled={receiptPathFile?.length<1?true:false}
              //simple fix for picky ppl who dont prepare their claims before hand
            />
          </div>
        </form>
      </div>
    );
  }
}

export default CreateClaims;
