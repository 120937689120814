import React, { useEffect, useState, ReactNode } from "react";
import { Toast } from 'primereact/toast';
import Tour from "reactour";
export const ToastStateContext = React.createContext<any | null>(null);

interface IProps {
  children: ReactNode;
}
export default function ToastStateProvider({ children }: IProps) {
  const [success, SetSuccess] = useState();
  const [info, SetInfo] = useState();
  const [warn, SetWarn] = useState();
  const [error, SetError] = useState();
  const [steps, SetSteps] = useState<any>();
  const [runTour, SetTour] = useState(false);
  const [startStep, SetStartStep] = useState(0);

  const state = {
    SetSuccess: SetSuccess,
    SetInfo: SetInfo,
    SetWarn: SetWarn,
    SetError: SetError,
    SetSteps: SetSteps,
    SetTour: SetTour,
  };

  let toast: any;
  const life = 5000;

  useEffect(() => {
    if (success !== undefined) {
      toast.show({
        life: life,
        severity: "success",
        summary: "Success!",
        detail: success
      });
      SetSuccess(undefined);
    }
  }, [success]);

  useEffect(() => {
    if (info !== undefined) {
      toast.show({
        life: life,
        severity: "info",
        summary: "Information!",
        detail: info
      });
      SetInfo(undefined);
    }
  }, [info]);

  useEffect(() => {
    if (warn !== undefined) {
      toast.show({
        life: life,
        severity: "warn",
        summary: "Warning!",
        detail: warn
      });
      SetWarn(undefined);
    }
  }, [warn]);

  useEffect(() => {
    if (error !== undefined) {
      toast.show({
        life: life,
        severity: "error",
        summary: "Error!",
        detail: error
      });
      SetError(undefined);
    }
  }, [error]);

  useEffect(() => setGroupCompanyTour(), [runTour]);
      

  const setGroupCompanyTour = () => {
    const steps = [];
    var tourMatches = document.querySelectorAll("[data-tour]");
    for (let i = 0; i < tourMatches.length; i++) {
      var selectorText =  (`[data-tour-id="${tourMatches[i].getAttribute("data-tour-id")}"]`);
      // var contentText = (`"${tourMatches[i].getAttribute("data-tour")}"`)
      var contentText = tourMatches[i].getAttribute("data-tour");
      steps.push({selector: selectorText, content: contentText});
    }
    if (tourMatches.length > 3) {
      SetStartStep(3);
    }
    SetSteps(steps);
  };

  return (
    <ToastStateContext.Provider value={state}>
      <Toast ref={(el: any) => (toast = el)} />
      {steps !== null ? 
      <Tour
          steps={steps}
          isOpen={runTour}
          onRequestClose={() => {
            SetTour(false);
          }}
          closeWithMask={false}
          startAt={startStep}
        />
        : null}

      {children}
    </ToastStateContext.Provider>
  );
}
