import * as React from "react";
import { Redirect } from "react-router";
import { LoginService } from "./service/LoginService";
import { UserService } from "./service/UserService";
import { CompanyService } from "./service/CompanyService";

// export const MainContext = React.createContext();

interface AppProps {}

interface AppState {
  user: any;
  companyLogo: string;
  expanded: boolean;
  redirectOut: boolean;
  redirectPath: string;
}

export class AppProfile extends React.Component<AppProps, AppState> {
  userService: UserService;
  loginService: LoginService;
  companyService :CompanyService;
  constructor(props: AppProps) {
    super(props);
    this.state = {
      user: {},
      expanded: false,
      companyLogo: "",
      redirectOut: false,
      redirectPath: ""
    };
    this.onClick = this.onClick.bind(this);
    this.passwordSetting = this.passwordSetting.bind(this);
    this.logout = this.logout.bind(this);
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.loginService = new LoginService();
  }

  componentDidMount() {
    this.userService.getUserDisplayDetails().then(res => {
      this.setState({ user: res });
    });
    this.companyService.getCompanyLogoFromUser().then(res => {
      this.setState({ companyLogo: res });
    });
  }

  onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({ expanded: !this.state.expanded });
    event.preventDefault();
  }

  logout(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.loginService.logout().then(() => {
      window.location.replace("/");
    });
  }

  passwordSetting() {
    this.setState({
      redirectOut: true,
      redirectPath: "/settings/changepassword"
    });
  }

  render() {
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath
          }}
        />
      );
    }

    return (
      <div>
        <div className="layout-profile">
          <div>
            <img
              src={this.state.user.displayPicture}
              alt=""
              className="display"
            />
          </div>
          <img src={this.state.companyLogo} alt="" className="mb-1"/>
          <br />
          <span className="username">
            {this.state.user.firstName} {this.state.user.lastName}
          </span>
{/* 
          <ul
            className={classNames({
              "layout-profile-expanded": this.state.expanded
            })}
          >
            <li>
              <button className="p-link" onClick={this.passwordSetting}>
                <i className="pi pi-fw pi-user" />
                <span>Account</span>
              </button>
            </li>
            <li>
              <button className="p-link">
                <i className="pi pi-fw pi-inbox" />
                <span>Notifications</span>
                <span className="menuitem-badge">2</span>
              </button>
            </li>
            <li>
              <button className="p-link" onClick={this.logout}>
                <i className="pi pi-fw pi-power-off" />
                <span>Logout</span>
              </button>
            </li>
          </ul> */}
        </div>
      </div>
    );
  }
}
