import React, { Component } from 'react';

export class AppFooter extends Component {

    render() {
        return  (
            // <div className="layout-footer shadow-lg">
            //     <span className="footer-text" style={{'marginRight': '5px'}}>TSLOS 2019</span>
            // </div>
            <></>
        );
    }
}