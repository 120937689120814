import React, { useState, useEffect, useRef } from "react";
import { RouteComponentProps, CommonMethods } from "../resources/CommonMethods";
import { ClientCompanyService } from "../../service/ClientCompanyService";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { ToastStateContext } from "../resources/ToastContext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Redirect } from "react-router";
import { InputSwitch } from "primereact/inputswitch";
import CustomError from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import moment from "moment";



export class ClientCompanyModel {
  clientCompanyID: string = CommonMethods.EmptyGuid;
  clientCompany: string = "";
  clientLogoImagePath: string = "";
  clientCompanyUEN: string = "";
  clientEmail: string = "";
  clientAddress: string = "";
  lastUpdated?: string = "";
  lastUpdatedBy?: string = "";
  isSupplier: boolean = false;
  clientStatus: string = "";
  clientLogoImage: any = null;
  industry: string = "";

  //Sales person
  name: string = "";
  startDate: Date | string = new Date();
  remarks: string = "";
  postalCode: string = "";
}

function AllClientCompanies(props: RouteComponentProps) {
  const clientCompanyService = new ClientCompanyService();
  let commonMethods = new CommonMethods();

  const [clientCompanyData, setClientCompanyData] = useState<
    ClientCompanyModel
  >(new ClientCompanyModel());
  const [clientCompanies, setClientCompanies] = useState<ClientCompanyModel[]>(
    []
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);

  const [dialogStr, setDialogStr] = useState<string>("");
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [tableDataChanged, setTableDataChanged] = useState(false);
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [isSupplier, setIsSupplier] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [headerName, setHeaderName] = useState<string>("Client Companies");
  const [industryFilterValues, setIndustryFilterValues] = useState([
    {
      label: "",
      value: "",
    },
  ]);
  const [startDateFilter, setStartDateFilter] = useState<Date>();
  const [endDateFilter, setEndDateFilter] = useState<Date>();

  const [descriptionTitle, setDescriptionTitle] = useState<string>("Here are all the Client Companies that you have contacts with.");
  const dt = useRef<any>(null);
  const [industryFilterValue, setIndustryFilterValue] = useState(null);


  const getClientCompanyData = () => {
    if (
      props.others !== undefined &&
      props.others !== null &&
      props.others === "fromexternalcost"
    ) {
      setIsSupplier("supplier");
      setHeaderName("Supplier Companies");
      setDescriptionTitle("Keep track of Suppliers that your company deals with for Operational Costs here.")
      clientCompanyService
        .getClientCompaniesIfIsSupplier()
        .then((res) => {
          setClientCompanies(res);
          setIsLoading(false);
        })
        .catch((err) => {
          let errorMsg = commonMethods.getErrorMessage(err);
          setIsError(true);
          setIsLoading(false);
          setErrorMessage(errorMsg);
        });
    } else {
      setIsSupplier("client");
      clientCompanyService
        .getClientCompaniesIfNotSupplier()
        .then((res) => {
          setClientCompanies(res);
          setIsLoading(false);
        })
        .catch((err) => {
          let errorMsg = commonMethods.getErrorMessage(err);
          setIsError(true);
          setIsLoading(false);
          setErrorMessage(errorMsg);
        });
      getIndustryFilterValues();
    }
  };

  useEffect(() => {
    getClientCompanyData();
  }, [tableDataChanged]);

  const updateClientCompanyStatus = async (rowData: ClientCompanyModel) => {
    let clientCompanyObj = new ClientCompanyModel();
    clientCompanyObj = rowData;
    clientCompanyObj.clientStatus =
      clientCompanyObj.clientStatus === "Active" ? "Deactivated" : "Active";
    clientCompanyService.updateClientCompanyStatus(clientCompanyObj);
  };

  const clientCompanyDetailsButton = (rowData: ClientCompanyModel) => {
    if (!(props.others === "fromexternalcost")) {
      return (
        <Button
          icon="pi pi-eye"
          tooltip="View Client Details"
          tooltipOptions={{ position: "top" }}
          className="p-button-info"
          onClick={() => {
            setRedirectPath("/sales/clientcontacts/" + rowData.clientCompanyID);
            setRedirectOut(true);
          }}
        />
      );
    }
  };
  const statusTemplate = (rowData: ClientCompanyModel) => {
    return (
      <>
        <InputSwitch
          className="mt-2"
          tooltip={rowData.clientStatus === "Active" ? "Active" : "Deactivated"}
          tooltipOptions={{ position: "top" }}
          checked={rowData.clientStatus === "Active"}
          onChange={(e) => {
            updateClientCompanyStatus(rowData);
            SetSuccess(
              rowData.clientCompany + " is now " + rowData.clientStatus + "."
            );
          }}
        />
      </>
    );
  };

  const meetingDate = (rowData: ClientCompanyModel) => {
    if (rowData.startDate === "0001-01-01T00:00:00") {
      return "-";
    } else {
      return (
        <>{commonMethods.displayDate(new Date(rowData.startDate))}</>
      );
    }
  };

  const meetingRemarks = (rowData: ClientCompanyModel) => {
    if (rowData.remarks === null) {
      return "-";
    } else {
      return rowData.remarks;
    }
  };

  const getIndustryFilterValues = () => {
    commonMethods.getClientCompanyIndustryDropdown().then((res) => {
      setIndustryFilterValues(res);
    });
  };

  const filterByDate = () => {
    if (
      startDateFilter !== null &&
      endDateFilter !== null &&
      startDateFilter !== undefined &&
      endDateFilter !== undefined &&
      moment(startDateFilter).isBefore(moment(endDateFilter))
    ) {
      clientCompanyService
        .filterClientCompaniesByDate(startDateFilter, endDateFilter)
        .then(res => {
          setClientCompanies(res);
          setIsLoading(false);
        })
        .catch(err => {
          let errorMsg = commonMethods.getErrorMessage(err);
          setIsError(true);
          setIsLoading(false);
          setErrorMessage(errorMsg);
        });
    } else {
      SetError("Start date must be before end date!");
    }
  };

  const industryFilter = (
    <Dropdown
      showClear
      value={industryFilterValue}
      options={industryFilterValues}
      onChange={(e) => {
        setIndustryFilterValue(e.value);
        onIndustryChange(e);
      }}
      placeholder="Filter by Industry"
    />
  );

  const onIndustryChange = ((e: any) => {
    dt.current.filter(e.value, 'industry', 'equals');
  });

  const tableheader = (
    <div className="row mb-2">
      <div className="col text-md-left">
        <span className="p-input-icon-left mr-1 mb-1">
          <i className="pi pi-search" />
          <InputText
            placeholder="Global Search"
            onInput={(e: any) => setSearchValue(e.currentTarget.value)}
          />
        </span>
        {industryFilter}
      </div>
      <div className="col text-md-right">
        <Calendar
          className="mb-1 mx-1"
          placeholder="From Date"
          value={startDateFilter}
          dateFormat="dd/mm/yy"
          onChange={(e) => e.value!== null &&  setStartDateFilter(new Date(e.value.toString()))}
        />
        <Calendar
          className="mb-1 mx-1"
          placeholder="To Date"
          value={endDateFilter}
          dateFormat="dd/mm/yy"
          onChange={(e) => e.value!== null && setEndDateFilter(new Date(e.value.toString()))}
        />
        <Button
          label="Filter"
          onClick={() => {
            filterByDate();
          }}
          className="mx-1"
        />
        <Button
          label="Clear"
          onClick={() => {
            getClientCompanyData();
            setStartDateFilter(undefined);
            setEndDateFilter(undefined);
          }}
          className="mx-1 mt-1"
        />
      </div>
    </div>
  );

  const tableDisplay = () => {
    return (
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          ref={dt}
          value={clientCompanies}
          paginator={true}
          rows={15}
          autoLayout={true}
          alwaysShowPaginator={false}
          emptyMessage="No results found."
          // header={tableheader}
          globalFilter={searchValue}
        >
          {/* <Column field="clientCompanyUEN" header="UEN Number" /> */}
          {/* <Column field="clientEmail" header="Email" />
          <Column field="clientAddress" header="Address" className="p-col-2" /> */}

          <Column field="clientCompany" header="Name" sortable={true} />
          <Column field="industry" header="Industry" className="p-col-2 d-none" filterElement = {industryFilter}  />
          <Column
            field="name"
            header="Latest Sales Person"
            className="p-col-2"
            sortable={true}
          />
          <Column body={meetingDate} header="Latest Meeting" />
          <Column
            body={meetingRemarks}
            header="Meeting Remarks"
            className="p-col-2"
          />
          <Column field="clientStatus" header="Status" />
          <Column body={statusTemplate} header="" className="p-0" />
          <Column body={actionTemplate} header="Action" className="p-col-2" />
        </DataTable>
      </div>
    );
  };

  console.log(clientCompanies);

  const setDialog = (header: string, content: any) => {
    setConfirmStr(header);
    setDialogStr(content);
  };

  const actionTemplate = (rowData: ClientCompanyModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {clientCompanyDetailsButton(rowData)}
        <Button
          icon="pi pi-pencil"
          className="p-button-warning mx-1"
          tooltip="Edit Client Details"
          tooltipOptions={{ position: "top" }}
          onClick={() => {
            setClientCompanyData(rowData);

            if (isSupplier === "supplier") {
              setRedirectPath(
                "/finance/operational_cost/update_supplier/" +
                  rowData.clientCompanyID +
                  "/" +
                  isSupplier
              );
            } else {
              setRedirectPath(
                "/sales/clientcompany/update/" + rowData.clientCompanyID
              );
            }
            setRedirectOut(true);
          }}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          tooltip="Delete Client"
          tooltipOptions={{ position: "top" }}
          onClick={() => {
            setClientCompanyData(rowData);

            setDialog(
              `Delete ${rowData.clientCompany}`,
              `Are you sure you want to delete this client company?`
            );
            setDialogVisibility(true);
          }}
        />
      </div>
    );
  };

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = (
      <div
        data-tour-id="all-client-companies"
        data-tour="View and edit the companies you collaborate with here by using the action buttons on the right. Use the status toggle button to activate or deactivate the companies."
      >
        {tableheader}
        {tableDisplay()}
      </div>
    );
  }
  return (
    <div className="row">
      {redirectOut ? (
        <Redirect
          push
          to={{
            pathname: redirectPath,
          }}
        />
      ) : null}

      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        //dismissableMask={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                if (confirmStr.includes("Delete")) {
                  clientCompanyService
                    .deleteClientCompanyByID(clientCompanyData.clientCompanyID)
                    .then((res) => {
                      SetSuccess("Client Company Deleted");
                      setDialogVisibility(false);
                      setTableDataChanged(!tableDataChanged);
                    })
                    .catch((err) =>
                      SetError("Could not delete Client Company.")
                    );
                }
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>
      <div className="col-12 col-xl-10">
        <div className="card">
          <h1 className="pb-2">
            {headerName}
            <span
              className="pl-3"
              data-tour-id="add-client-company"
              data-tour="Add new clients here."
            >
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={() => {
                  if (isSupplier === "supplier") {
                    setRedirectPath(
                      "/finance/operational_cost/create_supplier" +
                        "/" +
                        isSupplier
                    );
                  } else {
                    setRedirectPath("/sales/clientcompany/create");
                  }

                  setRedirectOut(true);
                }}
              />
            </span>
          </h1>
          <p>
         {descriptionTitle}
          </p>
          {display}
        </div>
      </div>
    </div>
  );
}

export default AllClientCompanies;
