import moment from "moment";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { ClaimsService } from "../../service/ClaimsService";
import DeliverableService from "../../service/DeliverableService";
import { ScoreIndicatorService } from "../../service/ScoreIndicatorService";
import { StatusRecordService } from "../../service/StatusRecordService";
import { StatusService } from "../../service/StatusService";
import { UserService } from "../../service/UserService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import KPIProgressBar from "../scoreindicators/kpipoints/KPIProgressBar";
import { ApproveOrRejectStatusRecord } from "../statusrecord/ApproveRejectLeave";
import StatusCalendar from "../statusrecord/StatusCalendar";
import HeaderCards from "./HeaderCards";
import CompanyFeed from "../companyfeed/CompanyFeed";
import { TabView, TabPanel } from 'primereact/tabview';
import TopPost from "../dashboard/TopPost";

export class CompanyIDToNumberModel {
  public companyID: string = "";
  public noOfUsers: number = 0;
  public noOfDays: number = 0;
  public companyName: string = "";
  public statusTypeName: string = "";
}
export class DashboardAttendanceModel {
  public totalNoInEachCompany: Array<CompanyIDToNumberModel> = new Array<
    CompanyIDToNumberModel
  >();
  public totalUnavailInEachCompany: Array<CompanyIDToNumberModel> = new Array<
    CompanyIDToNumberModel
  >();
  public totalStatusTypesPerCompany: Array<CompanyIDToNumberModel> = new Array<
    CompanyIDToNumberModel
  >();
}
function Dashboard(props: RouteComponentProps<any>) {
  const [isSupervisorView] = useState(
    props.userProfile.taskList.includes("approveleaveaccess")
  );
  // const [hasApproveClaimView, setHasApproveClaimView] = useState(props.userProfile.taskList.includes("approveclaimsaccess"));
  const [hasSalesAccess] = useState(
    props.userProfile.taskList.includes("salesaccess")
  );

  // const [outStandingApproval, setOutStandingApproval] = useState<any>(0);
  const [annualLeaveBalance, setAnnualLeaveBalance] = useState<any>(0);
  const [offLeaveBalance, setOffLeaveBalance] = useState<any>(0);
  const [deptAttendance, setDeptAttendance] = useState("");
  // const [deliverablesView] = useState(
  //   props.userProfile.taskList.includes("campaignaccess")
  // );

  const [deliverableCount, setDeliverableCount] = useState<any>(0);
  const [deliverableCountLoading, setDeliverableCountLoading] = useState(true);

  const [campaignCount, setCampaignCount] = useState<any>(0);
  const [campaignCountLoading, setCampaignCountLoading] = useState(true);

  const [deptAttendanceLoading, setDeptAttendanceLoading] = useState(true);
  // const [outstandingApprovalLoading, setOutstandingApprovalLoading] = useState(true);
  const [leaveCountLoading, setLeaveCountLoading] = useState(true);

  const [pendingClaimsCount, setPendingClaimsCount] = useState(0);
  const [pendingClaimsCountLoading, setPendingClaimsCountLoading] = useState(
    true
  );

  const [KPIPoints, setKPIPoints] = useState([]);
  const [displayKPI] = useState<boolean>(
    props.userProfile.taskList.includes("userindicatoraccess")
  );

  const [isPremiumPlan, setIsPremiumPlan] = useState<boolean>(false);

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");

  const commonMethods = new CommonMethods();
  const srs = new StatusRecordService();
  const us = new UserService();
  const ds = new DeliverableService();
  const cs = new ClaimsService();
  const statusService = new StatusService();
  const sis = new ScoreIndicatorService();

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();
    let abortSignal = myAbortController.signal.aborted;

    // if (commonMethods.isPropsLoaded(props)) {
    //   localStorage.setItem(
    //     "currentAccessingCompany",
    //     props.userProfile.companyID
    //   );
    // }


    commonMethods.hasPremiumPlanAccess().then((res) => {
      if (abortSignal) {
        return;
      }
      setIsPremiumPlan(res);
    });

    async function getDeptAttendance() {
      getPercentageDept();
    }

    function getKPIPoints() {
      sis.getMyKPIEntries().then((res) => {
        res.userIndicatorList = res.userIndicatorList.map((element: any) => {
          return {
            ...element,
            groupTime: moment(element.createdTime + "Z").format("MMMM Y"),
          };
        });
        if (abortSignal) {
          return;
        }
        setKPIPoints(res);
      });
    }

    function getLeaveBalance() {
      statusService
        .getStatusBalanceByStatusName("Annual Leave")
        .then((res) => {
          if (abortSignal) {
            return;
          }
          setAnnualLeaveBalance(res);
        })
        .catch(() => {
          if (abortSignal) {
            return;
          }
          setAnnualLeaveBalance("Error");
        })
        .finally(() => {
          if (abortSignal) {
            return;
          }
          setLeaveCountLoading(false);
        });
      statusService
        .getStatusBalanceByStatusName("Off")
        .then((res) => {
          if (abortSignal) {
            return;
          }
          setOffLeaveBalance(res);
        })
        .catch(() => {
          if (abortSignal) {
            return;
          }
          setOffLeaveBalance("Error");
        })
        .finally(() => {
          if (abortSignal) {
            return;
          }
          setLeaveCountLoading(false);
        });
    }
    // function getOutstanding() {
    //   srs
    //     .getStatusRecordByApprover(userID)
    //     .then((res) => {
    //       if (abortSignal) {
    //         return;
    //       }
    //       setOutStandingApproval(res.length);
    //     })
    //     .catch(() => {
    //       if (abortSignal) {
    //         return;
    //       }
    //       setOutStandingApproval("Error");
    //     })
    //     .finally(() => {
    //       if (abortSignal) {
    //         return;
    //       }
    //       setOutstandingApprovalLoading(false);
    //     });
    // }

    function getDeliverablesForUser() {
      ds.getAllDeliverablesForUser()
        .then((res) => {
          if (abortSignal) {
            return;
          }
          setDeliverableCount(res.length);
        })
        .catch(() => {
          if (abortSignal) {
            return;
          }
          setDeliverableCount("Error");
        })
        .finally(() => {
          if (abortSignal) {
            return;
          }
          setDeliverableCountLoading(false);
        });
    }

    function getSalesPersonCampaignCount() {
      ds.getSalesPersonCampaignCountForTheMonth()
        .then((res) => {
          if (abortSignal) {
            return;
          }
          setCampaignCount(res);
        })
        .catch(() => {
          if (abortSignal) {
            return;
          }
          setCampaignCount("Error");
        })
        .finally(() => {
          if (abortSignal) {
            return;
          }
          setCampaignCountLoading(false);
        });
    }

    function getMyPendingClaimsCount() {
      cs.getMyPendingClaimsCount()
        .then((res) => {
          if (abortSignal) {
            return;
          }
          setPendingClaimsCount(res);
        })
        .finally(() => {
          if (abortSignal) {
            return;
          }
          setPendingClaimsCountLoading(false);
        });
    }

    getKPIPoints();
    getDeptAttendance();
    // getOutstanding();
    getLeaveBalance();
    getMyPendingClaimsCount();

    if (hasSalesAccess) {
      getSalesPersonCampaignCount();
    } else {
      getDeliverablesForUser();
    }

    return () => {
      myAbortController.abort();
    };
  }, []);

  const getPercentageDept = () => {
    let fullStrength = 0;
    let currentStrength = 0;
    let usersInSameDept = us.userIDsForUsersInSameDept();
    usersInSameDept.then((res1) => {
      fullStrength = res1.length;
      srs
        .getNumberOfRecordsToday(res1)
        .then((res) => {
          currentStrength = fullStrength - res;
        })
        .then(() => {
          if (currentStrength === 0) {
            setDeptAttendance("0%");
          } else {
            setDeptAttendance(
              Math.round((currentStrength / fullStrength) * 100) + "%"
            );
          }
        })
        .catch(() => setDeptAttendance("Error"))
        .finally(() => setDeptAttendanceLoading(false));
    });
  };

  // const supervisorHeaderCards = () => {
  //   if (isSupervisorView) {
  //     return (
  //       <HeaderCards
  //         redirect={"/status/approve"}
  //         hidden={!isSupervisorView}
  //         id="Outstanding Approvals"
  //         title="Outstanding Approvals"
  //         content=""
  //         number={
  //           outstandingApprovalLoading ? (
  //             <i className="pi pi-spin pi-spinner" />
  //           ) : (
  //             outStandingApproval
  //           )
  //         }
  //         dataTourId="outstanding-approvals"
  //         dataTour="Here are your outstanding approvals. Click to go to the approval page."
  //       />
  //     );
  //   }
  // };

  const departmentCalendar = () => {
    return (
      <div
        className="card card pointer border-0"
        id="statusCalendar"
        onClick={goToCalendar}
      >
        <StatusCalendar {...props} />
      </div>
    );
  };
  const departmentAttCard = () => {
    return (
      <HeaderCards
        title="Department Attendance"
        content=""
        number={
          deptAttendanceLoading ? (
            <i className="pi pi-spin pi-spinner" />
          ) : (
            deptAttendance
          )
        }
        dataTourId="dept-attendance"
        dataTour="This is your department's attendance for the day, calculated by their approved leave statuses."
      />
    );
  };

  const KPICard = () => {
    if (displayKPI) {
      return (
        <div className="card">
          <h1> My KPI Progress ({commonMethods.displayMonth_Full(new  Date())})</h1>

          <KPIProgressBar {...props} others={KPIPoints} />
        </div>
      );
    }
  };

  const approveLeaveCard = () => {
    if (isSupervisorView) {
      return (
        <div id="leaveBalance">
          <ApproveOrRejectStatusRecord {...props} />
        </div>
      );
    }
  };

  // const endorseClaimsCard = () => {
  //   if (hasApproveClaimView) {
  //     return (
  //       <div id="leaveBalance">
  //          <div
  //           className="card"
  //           data-tour-id="endorse-claim"
  //           data-tour="These are the claims waiting to be endorsed. Use the buttons at the right to endorse or reject the accompanying documents."
  //         >
  //           <h1>Endorse Claims</h1>
  //           <ViewClaims endorsing="true" />
  //         </div>
  //       </div>
  //     );
  //   }
  // };

  const leaveHeaderCards = () => {
    return (
      <HeaderCards
        id="Annual Leave"
        title="Annual Leave"
        content=""
        redirect={"/status/summary"}
        number={
          leaveCountLoading ? (
            <i className="pi pi-spin pi-spinner" />
          ) : (
            annualLeaveBalance
          )
        }
        dataTourId="annual-leave-balance"
        dataTour="Here is your Annual Leave balance."
      />
    );
  };

  const offHeaderCards = () => {
    if (offLeaveBalance > 0) {
      return (
        <HeaderCards
          id="Offs"
          title="Offs"
          content=""
          number={
            leaveCountLoading ? (
              <i className="pi pi-spin pi-spinner" />
            ) : (
              offLeaveBalance
            )
          }
          dataTourId="off-balance"
          dataTour="Here is your Off balance."
        />
      );
    } else {
      return "";
    }
  };

  const claimsPendingCard = () => {
    return (
      <HeaderCards
        id="Pending Claims"
        title="Pending Claims"
        content=""
        redirect={"/claims/view"}
        number={
          pendingClaimsCountLoading ? (
            <i className="pi pi-spin pi-spinner" />
          ) : (
            pendingClaimsCount
          )
        }
        dataTourId="pending-claims"
        dataTour="Here is the number of claims pending endorsement."
      />
    );
  };

  const salesHeaderCards = () => {
    if (isPremiumPlan) {
      if (hasSalesAccess) {
        return (
          <HeaderCards
            hidden={!hasSalesAccess}
            id="Campaign Count"
            title="Campaign Count"
            content=""
            number={
              campaignCountLoading ? (
                <i className="pi pi-spin pi-spinner" />
              ) : (
                campaignCount
              )
            }
            dataTourId="campaign-count"
            dataTour="This is the number of campaigns you are currently working on."
          />
        );
      } else {
        return (
          <HeaderCards
            title="Outstanding Deliverables"
            number={
              deliverableCountLoading ? (
                <i className="pi pi-spin pi-spinner" />
              ) : (
                deliverableCount
              )
            }
            dataTourId="to-do-list"
            dataTour="These are your outstanding deliverables."
          />
        );
      }
    }
  };

  const goToCalendar = () => {
    setRedirectOut(true);
    setRedirectPath("/status/calendar");
  };

  // const myOutstandingDeliverables = () => {
  //   if (deliverablesView) {
  //     return <DeliverablesOutstandingView {...props} />;
  //   }
  // };

  return (
    <div>
      {redirectOut ? (
        <Redirect
          push
          to={{
            pathname: redirectPath,
          }}
        />
      ) : null}

      <>
        <div className="row">
          <div className="col-12">
            <div className="card-group">
              {/* {supervisorHeaderCards()} */}
              {departmentAttCard()}
              {salesHeaderCards()}
              {leaveHeaderCards()}
              {offHeaderCards()}
              {claimsPendingCard()}
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-lg-7">
            {KPICard()}
            {approveLeaveCard()}
            {/* {endorseClaimsCard()} */}

            {/* {myOutstandingDeliverables()} */}
            {/* <NotificationsTable {...props}></NotificationsTable> */}
          </div>
          <div className="col-12 col-lg-5">
          {/* <div className="feedcalendarwidget overflow-auto" style={{"width":"40%", "height":"770px", "paddingRight":"5px", "paddingLeft":"0px", "paddingBottom": "0px"}}> */}
            <TabView>
              <TabPanel
                header="Company Feed"
                contentClassName="overflow-auto"
                contentStyle={{maxHeight: "700px"}}
              >
                <CompanyFeed {...props} others={{CompanyFeed, fromDashboard: true}} />
              </TabPanel>
              <TabPanel header="Calendar">
                {departmentCalendar()}
              </TabPanel>
              {/* <TabPanel header="Best Post">
                <TopPost {...props}/>
              </TabPanel> */}
            </TabView>
          </div>
        </div>
      </>
    </div>
  );
}

export default Dashboard;
