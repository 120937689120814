import { RouteComponentProps, useParams, Redirect } from "react-router";

import { QuicklinksDashboardService } from "../../service/QuicklinksDashboardService";
import { QuicklinksDashboardModel } from "./QuicklinksDashboardModel";

import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ToastStateContext } from "../resources/ToastContext";
import CustomError from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";
import { CommonMethods, ActionTypes, ParamIDTypes } from "../resources/CommonMethods";
import ReactTooltip from "react-tooltip";

export default function QuicklinksDashboardCreate(props: RouteComponentProps) {
  const quicklinksDashboardService = new QuicklinksDashboardService();
  const { id } = useParams<ParamIDTypes>();
  const action = id === undefined ? ActionTypes.create : ActionTypes.update;
  let confirmationMessage = id === undefined ? "created" : "updated";

  const commonMethods = new CommonMethods();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [toHome, setToHome] = useState(false);

  const [linkData, setLinkData] = useState<QuicklinksDashboardModel>(
    new QuicklinksDashboardModel()
  );

  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);

  useEffect(() => {
    if (id !== undefined) {
      quicklinksDashboardService.getLinkByID(id).then((res) => {
        setLinkData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsError(true);
        setErrorMessage(commonMethods.getErrorMessage(err));
      }); 
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // TODO: can probably check if valid link like https:// before submitting.
    const tmpLink = linkData;
    if (action === ActionTypes.create) {
      quicklinksDashboardService
        .createLink(tmpLink)
        .then((res) => {
          SetSuccess(
            tmpLink.linkTitle + " has been " + confirmationMessage + "."
          );
          setToHome(true);
        })
        .catch((err) => {
          SetError(commonMethods.getErrorMessage(err));
        });
    } else {
      quicklinksDashboardService
        .updateLinkByID(tmpLink)
        .then((res) => {
          SetSuccess(tmpLink.linkTitle + " has been " + confirmationMessage + ".");
          setToHome(true);
        })
        .catch((err) => {
          SetError(commonMethods.getErrorMessage(err));
        });
    }
  };

  let page = (
    <form onSubmit={handleSubmit}>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <span className="boldLabel">
            Link Title <span style={{ color: "red" }}>*</span>
          </span>
          <InputText
            value={linkData.linkTitle}
            onChange={(event) =>
              setLinkData({
                ...linkData,
                linkTitle: event.currentTarget.value,
              })
            }
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Link <span style={{ color: "red" }}> * </span>
            <i
              className="fas fa-info-circle informationStyle"
              data-tip=""
              data-for="linkdetails"
            ></i>
          </span>
          <ReactTooltip
            id="linkdetails"
            area-haspopup="true"
            className="tooltipFont"
          >
            <p>Type in the full address (include https:// at the front) </p>
          </ReactTooltip>
          <InputText
            value={linkData.link}
            onChange={(event) =>
              setLinkData({
                ...linkData,
                link: event.currentTarget.value,
              })
            }
            required
          />
        </div>
      </div>

      <div className="p-grid p-justify-center pt-2">
        <Button type="submit" label={action} />
      </div>
    </form>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <>
      {toHome ? <Redirect push to="/admin/quick_links" /> : null}

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{action} Link </h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {display}
          </div>
        </div>
      </div>
    </>
  );
}
