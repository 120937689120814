import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { TaxFilingService } from "../../service/TaxFilingService";
import {
  CommonMethods,
  ParamIDTypes,
  RouteComponentProps
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import SubmissionDashboard from "./SubmissionDashboard";
import TaxBodyDetails from "./TaxBodyDetails";
import { TaxFilingHeader, TaxHeaderStatus } from "./TaxFilingModel";
import TaxHeaderDetails from "./TaxHeaderDetails";
import TaxTrailerDetails from "./TaxTrailerDetails";

export default function TaxFiling(props: RouteComponentProps<any>) {
  const commonMethods = new CommonMethods();
  const taxFilingService = new TaxFilingService();
  const { SetWarn } = React.useContext(ToastStateContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(
    "An unidentified error has occurred"
  );

  const [taxHeaderId, setTaxHeaderId] = useState<string>("");
  const [taxFilingHeader, setTaxFilingHeader] = useState(new TaxFilingHeader());

  const { id } = useParams<ParamIDTypes>();

  useEffect(() => {
    init();
    setTaxHeaderId(id);
  }, []);

  const init = () => {
    taxFilingService
      .getHeaderDetails(id)
      .then((res) => {
        setTaxFilingHeader(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMsg(commonMethods.getErrorMessage(err));
        setIsError(true);
        setIsLoading(false);
      });
  };


  const submissionDisplay = () => {
    return <SubmissionDashboard {...props} others={taxHeaderId} />;
  };
  
  const headerDisplay = () => {
    return (
      // <Accordion activeIndex={0}>
      //   <AccordionTab header="Header">
      <TaxHeaderDetails {...props} others={{ taxHeaderId: taxHeaderId }} />
      //   </AccordionTab>
      // </Accordion>
    );
  };

  const bodyDisplay = () => {
    return <TaxBodyDetails {...props} others={{ taxHeaderId: taxHeaderId }} />;
  };

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = (
      <div className="row">
        <div className="col-12">
          <div className="row justify-content-around">
            <div className="col-12 col-md-4"></div>
            <div className="col-12 col-md-4 text-center">
              <span className="boldLabel">Income Year</span>
              <p>{taxFilingHeader.incomeYear}</p>
              <span className="boldLabel">Company</span>
              <p>{taxFilingHeader.organizationName}</p>
              <span className="boldLabel">Status</span>
              <p>{taxFilingHeader.status}</p>
            </div>
            <div className="col-12 col-md-4"><div className="card">{submissionDisplay()}</div></div>
          </div>
        </div>

        {/* <div className="col-12">{submissionDisplay()}</div> */}
        <div className="col-12">  {headerDisplay()}</div>
        {/* <div className="col-4"><div className="card">{submissionDisplay()}</div></div> */}
        {/* <div className="col-12">{headerDisplay()}</div> */}
        <hr></hr>
        <div className="col-12">{bodyDisplay()}</div>
      </div>
    );
  }
  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">Tax filing</h1>
          {display}
        </div>
      </div>
    </div>
  );
}
