import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "../resources/CommonMethods";
import {
    EquipmentsModel,
    SingleForecastingModel,
    EquipmentForecastingModel
} from "../resources/ExportClass";
import { ChronosService } from "../../service/ChronosService";
import { ToastStateContext } from "../resources/ToastContext";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { UserService } from "../../service/UserService";
import { UserModel } from "../user/User";
import { InputSwitch } from "primereact/inputswitch";
import DeliverableService from "../../service/DeliverableService";
import { DeliverableModel } from "../deliverable/DeliverablesInCampaign";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

const userSvc = new UserService();
const chronosSvc = new ChronosService();
const deliverableSvc = new DeliverableService();
const todayDate = moment();

/**
 * Display CHRONOS Page
 * @param props
 */
function ChronosOverview(props: RouteComponentProps<any>) {
    const { SetSuccess, SetError } = React.useContext(ToastStateContext);

    const [isLoading, setLoadingStatus] = useState<boolean>(true);
    const [isForecasting, setForecasting] = useState<boolean>(false);
    const [isDeliverable, setIfDeliverable] = useState<boolean>(true);

    const [displayYear, setDisplayYear] = useState<number>(0);
    const [displayMonth, setDisplayMonth] = useState<number>(0);
    const [confirmDialog, setConfirmDialogStatus] = useState<boolean>(false);

    // RETRIEVED FROM BACKEND
    const [userInfo, setUserInfo] = useState<UserModel>(new UserModel());
    const [deliverableList, setDeliverableList] = useState<Array<DeliverableModel>>([]);
    const [companyEquipList, setCompanyEquipList] = useState<Array<EquipmentsModel>>([]);
    const [existingForecastedList, setExistingForecastedList] = useState<Array<EquipmentForecastingModel>>([]);

    // USER SELECTED TO FORECAST
    const [forecastObj, setForecastObj] = useState<SingleForecastingModel>(
        new SingleForecastingModel()
    );

    // ComponentDidMount
    useEffect(() => {
        if (props.userProfile.userID !== "") {
            getUserInfo();
            getUserDeliverables();
        }
        getChronosEquipment();

        setDisplayYear(todayDate.year());
        setDisplayMonth(todayDate.month());

        return () => { };
    }, []);

    useEffect(() => {
        setLoadingStatus(false);
    }, [companyEquipList]);

    // Functions
    const getChronosEquipment = () => {
        chronosSvc.getChronosEquipment().then(res => {
            setCompanyEquipList(res.equipments);
            setExistingForecastedList(res.forecasted);
        });
    };

    const getUserInfo = () => {
        userSvc.getUserById(props.userProfile.userID).then(res => {
            setUserInfo(res);
        });
    };

    const getUserDeliverables = () => {
        deliverableSvc.getAllDeliverablesForUser().then(res => {
            setDeliverableList(res);
        });
    };

    const forecastEquipments = () => {
        // deliverableSvc.getAllDeliverablesForUser().then(res => {
        //   setDeliverableList(res);
        // });
        //
    };

    // Row Template
    const rowGroupingHeaderTemplate = (rowData: EquipmentsModel) => {
        return (
            <>
                <span className="boldLabel">
                    {rowData.companyName.toUpperCase()} INVENTORY
        </span>
            </>
        );
    };
    const rowGroupingFooterTemplate = (rowData: EquipmentsModel) => {
        return <></>;
    };

    // CONSTRUCTING
    const fixedHeaderGroup = (
        <ColumnGroup>
            <Row>
                <Column header="INSTRUCTIONS BLAHBLAH" colSpan={3} rowSpan={1} />
            </Row>
            <Row>
                <Column header="EQUIPMENT" colSpan={3} rowSpan={1} />
            </Row>
            <Row>
                <Column header="PART" colSpan={1} rowSpan={1} />
                <Column header="ITEM" colSpan={1} rowSpan={1} />
                <Column header="SERIAL" colSpan={1} rowSpan={1} />
            </Row>
        </ColumnGroup>
    );
    const days: number = todayDate.daysInMonth();
    let day_of_week: any = [];
    let date: any = [];
    let am_pm: any = [];
    if (
        day_of_week.length !== days ||
        date.length !== days ||
        am_pm.length !== days * 2
    ) {
        for (let i = 1; i <= days; i++) {
            day_of_week.push(
                <Column
                    header={moment()
                        .year(displayYear)
                        .month(displayMonth)
                        .date(i)
                        .format("ddd")}
                    colSpan={2}
                    key={`1_${i}`}
                />
            );
            date.push(
                <Column
                    header={moment()
                        .year(displayYear)
                        .month(displayMonth)
                        .date(i)
                        .format("DD / MM")}
                    colSpan={2}
                    key={`2_${i}`}
                />
            );
            am_pm.push(<Column header="AM" key={`3_1_${i}`} />);
            am_pm.push(<Column header="PM" key={`3_2_${i}`} />);
        }
    }

    const scrollableHeaderGroup = (
        <ColumnGroup>
            <Row>{day_of_week}</Row>
            <Row>{date}</Row>
            <Row>{am_pm}</Row>
        </ColumnGroup>
    );

    const prepareForecast = (rowData: EquipmentsModel, column: any) => {
        if (!isForecasting) {
            return;
        }

        // 1. ColumnKey get Index
        const date: number = Math.floor(column.columnKey / 2) + 1;

        //2. ColumnKey get AM or PM
        const isPM: number = column.columnKey % 2;

        var toUpdate = forecastObj;
        if (forecastObj.startDate === "" && forecastObj.startTime === "") {
            toUpdate.startDate = new Date(
                moment()
                    .year(displayYear)
                    .month(displayMonth)
                    .date(date)
                    .toString()
            );
            toUpdate.startTime = isPM ? "PM" : "AM";
        } else if (
            forecastObj.endDate === "" &&
            forecastObj.endTime === "" &&
            forecastObj.startDate !== "" &&
            forecastObj.startTime !== ""
        ) {
            if (
                moment(forecastObj.startDate).diff(
                    moment()
                        .year(displayYear)
                        .month(displayMonth)
                        .date(date)
                ) > -1000
            ) {
                SetError(
                    "Please select a date or time greater than your starting period"
                );
                return;
            } else {
                // end date cannot be before start date!!
                toUpdate.endDate = new Date(
                    moment()
                        .year(displayYear)
                        .month(displayMonth)
                        .date(date)
                        .toString()
                );
                toUpdate.endTime = isPM ? "PM" : "AM";
            }
        }
        if (
            toUpdate.logisticsIDList.findIndex(
                (i: string) => i === rowData.logisticsID
            ) >= 0
        ) {
            SetError("Equipment is already selected!");
            return;
        }
        toUpdate.logisticsIDList.push(rowData.logisticsID);

        setForecastObj(toUpdate);
    };
    const cellBtnCover = (rowData: any, column: any) => {
        // Check against already selected 
        var exists_index: number = forecastObj.logisticsIDList.findIndex(
            (i: string) => i === rowData.logisticsID
        );
        var isStartPM: number = forecastObj.startTime === "PM" ? -1 : -2;
        var start_day: number = moment(forecastObj.startDate).date();
        var isEndPM: number = forecastObj.endTime === "PM" ? -1 : -2;
        var end_day: number = moment(forecastObj.endDate).date();

        var this_Column = Number(column.columnKey);
        var startColumn = (start_day * 2) + isStartPM;
        var endColumn = (end_day * 2) + isEndPM;

        if (exists_index >= 0 && (((this_Column === startColumn) || (this_Column === endColumn)) || ((this_Column >= startColumn) && (this_Column <= endColumn)))) {

            // If user has selected that time period & item
            return (
                <span
                    style={{
                        backgroundColor: "#7EAAD1",
                        width: "100%",
                        height: "100%",
                        display: "block",
                        color: "#7EAAD1"
                    }}
                >+</span>
            );
        } else {
            return (
                <button
                    onClick={e => prepareForecast(rowData, column)}
                    className="chronosTableCell"
                >+</button>
            );
        }
    };

    // Forecast Date slots
    let forecast_slot = [];
    for (let index = 0; index < days * 2; index++) {
        forecast_slot.push(
            <Column
                field="inOut"
                header="STATUS"
                className="timeslot"
                style={{ width: "5em" }}
                columnKey={index.toString()}
                body={cellBtnCover}
                key={index}
            />
        );
    }

    const dialogFooter = (
        <div>
            <Button
                label="Forecast!"
                icon="pi pi-check"
                onClick={() => {
                    setConfirmDialogStatus(false);
                    setForecasting(true);
                }}
            />
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-secondary"
                onClick={() => setConfirmDialogStatus(false)}
            />
        </div>
    );

    // Selected for Forecast List
    let equipmentsSelected: JSX.Element[] = [];
    if (forecastObj.logisticsIDList.length > 0) {
        forecastObj.logisticsIDList.forEach((equip: string, index: number) => {
            var equipment: EquipmentsModel =
                companyEquipList[
                companyEquipList.findIndex(
                    (logs: EquipmentsModel) => equip === logs.logisticsID
                )
                ];
            equipmentsSelected.push(
                <p className="mb-0">
                    {equipment.equipmentName} [{equipment.equipmentSerialNo}]
          <span
                        onClick={() => {
                            forecastObj.logisticsIDList.splice(index, 1);
                        }}
                    >
                        <i className="pi pi-fw pi-times" />
                    </span>
                </p>
            );
        });
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-xl-12">
                    {isLoading ? (
                        <div className="card">
                            <ProgressSpinner />
                        </div>
                    ) : (
                        <>
                            <Dialog
                                header="Forecast this Equipment"
                                footer={dialogFooter}
                                visible={confirmDialog}
                                style={{ width: "50vw", textAlign: "center" }}
                                modal={true}
                                onHide={() => setConfirmDialogStatus(false)}
                            >
                                <div className="row mb-2">
                                    <div className="col">
                                        <h4>
                                            Hello, {userInfo.firstName} {userInfo.lastName}
                                        </h4>
                                        <span className="text-muted mx-2">
                                            Not you? (aka scan barcode)
                      </span>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 align-middle mb-2">
                                        <span className="boldLabel">Campaign</span>
                                        <InputSwitch
                                            checked={isDeliverable}
                                            onChange={e => setIfDeliverable(!isDeliverable)}
                                        />
                                        <span className="boldLabel">Organic</span>
                                    </div>
                                    <div className="col-12 align-middle mb-2">
                                        {isDeliverable ? (
                                            <InputText
                                                style={{ width: "60%" }}
                                                placeholder="Enter Forecast Purpose"
                                                value={forecastObj.deliverableID}
                                                onChange={e => {
                                                    setForecastObj({
                                                        ...forecastObj,
                                                        deliverableID: e.currentTarget.value,
                                                        campaignID: "organic"
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <Dropdown
                                                optionLabel="deliverableName"
                                                value={
                                                    deliverableList[
                                                    deliverableList.findIndex(
                                                        (i: DeliverableModel) =>
                                                            forecastObj.deliverableID === i.deliverableID
                                                    )
                                                    ]
                                                }
                                                options={deliverableList}
                                                onChange={e => {
                                                    setForecastObj({
                                                        ...forecastObj,
                                                        deliverableID: e.value.deliverableID,
                                                        campaignID: e.value.campaignID
                                                    });
                                                }}
                                                placeholder="Select a Deliverable"
                                            />
                                        )}
                                    </div>
                                </div>
                            </Dialog>
                            <div className="card" data-tour-id="chronos-table" data-tour="Here is a calendar view of the company's equipment and their forecasted dates. Please check that your forecast does not clash with those made previously.">
                                <div className="row">
                                    <div className="col">
                                        <DataTable
                                            className="chronosTable"
                                            value={companyEquipList}
                                            style={{
                                                fontSize: "0.8em",
                                                width: "100%"
                                                // height: "100%"
                                            }}
                                            groupField="companyID"
                                            rowGroupMode="subheader"
                                            rowGroupHeaderTemplate={rowGroupingHeaderTemplate}
                                            rowGroupFooterTemplate={rowGroupingFooterTemplate}
                                            scrollable={true}
                                            scrollHeight="100vw"
                                            frozenWidth="25%"
                                            headerColumnGroup={scrollableHeaderGroup}
                                            frozenHeaderColumnGroup={fixedHeaderGroup}
                                        >
                                            <Column
                                                field="productCategory"
                                                style={{ width: "0.5em", textAlign: "center" }}
                                                frozen={true}
                                            />
                                            <Column
                                                field="equipmentName"
                                                style={{ width: "1em", textAlign: "center" }}
                                                frozen={true}
                                            />
                                            <Column
                                                field="equipmentSerialNo"
                                                style={{ width: "0.5em", textAlign: "center" }}
                                                frozen={true}
                                            />
                                            {forecast_slot}
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="row">
                                    <div className="col-1">
                                        {isForecasting ? (
                                            <>
                                                <Button
                                                    style={{ width: "100%" }}
                                                    className="p-button"
                                                    label="Confirm"
                                                    onClick={() => { }}
                                                />
                                                <Button
                                                    style={{ width: "100%" }}
                                                    className="p-button-secondary"
                                                    label="Cancel"
                                                    onClick={() => {
                                                        setForecasting(false);
                                                        setForecastObj(new SingleForecastingModel());
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <div data-tour-id="forecast-button" data-tour="Click here to begin forecasting equipment.">
                                                <Button
                                                    style={{ width: "100%" }}
                                                    className="p-button"
                                                    label="Forecast"
                                                    onClick={() => setConfirmDialogStatus(true)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-3">
                                        <p>
                                            <b>Today:</b> {todayDate.toString()}
                                            <br />
                                        </p>
                                    </div>
                                    <div className="col">
                                        {isForecasting ? (
                                            <>
                                                <div className="row">
                                                    <div className="col">
                                                        <span className="boldLabel">Start Period: </span>
                                                        {typeof forecastObj.startDate === "string" ? (
                                                            <i>Please select start period</i>
                                                        ) : (
                                                            <>
                                                                {moment(forecastObj.startDate).format("DD/MM")}{" "}
                                                                {forecastObj.startTime}{" "}
                                                                <span
                                                                    onClick={() => {
                                                                        setForecastObj({
                                                                            ...forecastObj,
                                                                            startDate: "",
                                                                            startTime: ""
                                                                        });
                                                                    }}
                                                                >
                                                                    <i className="pi pi-fw pi-replay" /> Change?
                                </span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <span className="boldLabel">End Period: </span>
                                                        {typeof forecastObj.endDate === "string" ? (
                                                            <i>Please select end period</i>
                                                        ) : (
                                                            <>
                                                                {moment(forecastObj.endDate).format("DD/MM")}{" "}
                                                                {forecastObj.endTime}{" "}
                                                                <span
                                                                    onClick={() => {
                                                                        setForecastObj({
                                                                            ...forecastObj,
                                                                            endDate: "",
                                                                            endTime: ""
                                                                        });
                                                                    }}
                                                                >
                                                                    <i className="pi pi-fw pi-replay" /> Change?
                                </span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col">
                                                        <span className="boldLabel">
                                                            Equipments Selected
                            </span>
                                                        {forecastObj.logisticsIDList.length > 0 ? (
                                                            <>{equipmentsSelected}</>
                                                        ) : (
                                                            <p>Add Equipment</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
export default ChronosOverview;