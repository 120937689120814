import { TaskList } from "./ListOfTasks";

export interface MenuItem {
  [key: string]: any;

  label: string;
  icon?: string | null;
  command?: Function | null;
  to?: string;
  items?: Array<MenuItem> | Array<string> | any;
  rank?: number | null;
  permission?: Array<string>; // only for standalone or menu items, does not apply to parent grouping
}

export interface SubMenuList {
  [key: string]: any;

  label: string;
  group?: string;
  items?: Array<MenuItem> | Array<string> | any;
}

/**
 * Contains list of navigational items as part of the Menu
 *
 * -----------------------------------------------------
 * 2019 PCLQ
 */
export class MenuList {
  [moduleKey: string]:
  | MenuItem
  | SubMenuList
  | Array<string>
  | { [moduleKey: string]: MenuItem }
  | any;
  /**
   * ADD TO MENU
   * ------------------------------------------------------
   *
   *      1. Add to Menu Object following the format of MenuItem
   *          - If it is standalone, ensure 'to: xxx' is inside
   *          - If it is part of a submenu, remember to add a parent grouping
   *      2. Adding a parent grouping
   *          - Create a ParentList (e.g. OrganizationSettingList) with key-values 'label', 'group' and 'items'
   *          - Each item in the 'items' array contains the key name (Case-Sensitive) of the items in the Menu object
   *          - Add the Listing name (Case-Sensitive) to the SubMenuList
   *      3. Adding a Menu Object which is default (i.e. Global viewing)
   *          - Add the Menu Object key name (Case-Sensitive) to the Default List
   *
   *      NOTE:
   *        - Rank 1-19 is for outer menu items -> the numbers are used in AppMenu
   *
   * ------------------------------------------------------
   *  Important Lists: DefaultList, SubMenuList, Menu
   */
  DefaultList: SubMenuList = {
    label: "Global",
    // small letters only (Case-Sensitive)
    items: [
      "companyfeed",
      "bossdashboard",
      "dashboard",
      "noticeboard",
      "quicklinks",
      "roombooking",
      // "birdseye",
      "admindashboard",
      //"chronos"
    ],
  };

  // Lists of all SubMenus objects
  SubMenuList: Array<string> = [
    "LeaveList",
    "PersonalList",
    "KPIList",
    "ClaimsList",
    "SalesList",
    "CampaignList",
    "EquipmentList",
  ];

  AdminSettingsList: Array<string> = [
    "OrganizationSettingList",
    "CompanySettingList",
    "AdminList",
    "FinanceList",
  ];

  Menu: { [moduleKey: string]: MenuItem } = {
    dashboard: {
      label: "Dashboard",
      icon: "pi pi-fw pi-home",
      to: "/",
      rank: 1,
      permission: [TaskList.basicaccess],
    },

    companyfeed: {
      label: "Company Feed",
      icon: "pi pi-fw pi-comments",
      to: "/companyfeed",
      rank: 0,
      permission: [TaskList.basicaccess, TaskList.companyfeedadmin],
    },
    companyfeedpost: {
      label: "Company Feed - Post",
      to: "/companyfeed/post/:postId",
      rank: 15,
      permission: [TaskList.basicaccess, TaskList.companyfeedadmin],
    },
    companyfeeduserprofile: {
      label: "Public Profile",
      to: "/publicprofile/:userId",
      rank: 15,
      permission: [TaskList.basicaccess],
    },

    bossdashboard: {
      label: "Boss Dashboard",
      icon: "pi pi-fw pi-home",
      to: "/bossview",
      rank: 0,
      permission: [TaskList.bossaccess],
    },

    noticeboard: {
      label: "Noticeboard",
      icon: "pi pi-fw pi-th-large",
      to: "/noticeboard",
      rank: 2,
      permission: [TaskList.basicaccess],
    },

    quicklinks: {
      label: "Quick Links",
      icon: "pi pi-fw  pi-info-circle",
      to: "/home",
      rank: 3,
      permission: [TaskList.basicaccess],
    },

    admindashboard: {
      label: "Admin Dashboard",
      icon: "pi pi-fw pi-cog",
      to: "/admin",
      rank: 19,
      permission: [TaskList.adminmenuaccess],
    },

    announcement: {
      label: "Announcements",
      icon: "pi pi-fw pi-globe",
      to: "/announcements",
      rank: 30,
      permission: [TaskList.basicaccess],
    },
    makeannouncement: {
      label: "Announcements",
      icon: "pi pi-fw pi-globe",
      to: "/announcements/update/:id",
      rank: 30,
      permission: [
        TaskList.deptannouncement,
        TaskList.groupannouncement,
        TaskList.companyannouncement,
      ],
    },
    viewcirculardocument: {
      label: "Circular",
      icon: "pi pi-fw pi-globe",
      to: "/circular/document/:id",
      rank: 30,
      permission: [TaskList.basicaccess, TaskList.quicklinksaccess],
    },
    login: {
      label: "Login",
      icon: "pi pi-fw pi-home",
      to: "/login",
      rank: 30,
      permission: [TaskList.basicaccess],
    },

    calendar: {
      label: "Calendar",
      // icon: "pi pi-fw pi-calendar",
      to: "/status/calendar",
      rank: 3,
      permission: [TaskList.basicaccess],
    },

    //    ___
    //   | _ \ ___  ___  _ __
    //   |   // _ \/ _ \| '  \
    //   |_|_\\___/\___/|_|_|_|
    //

    roombooking: {
      label: "Room Booking",
      icon: "pi pi-fw pi-calendar",
      to: "/room/book",
      rank: 5,
      permission: [TaskList.basicaccess],
    },
    viewroombooking: {
      label: "View Room Booking",
      icon: "pi pi-fw pi-calendar-plus",
      to: "/room/book/:id",
      rank: 30,
      permission: [TaskList.basicaccess],
    },
    viewmyroombookings: {
      label: "My Room Booking",
      icon: "pi pi-fw pi-calendar-plus",
      to: "/room/mybookings",
      rank: 30,
      permission: [TaskList.basicaccess],
    },
    //      _       _         _         ___       _    _    _
    //     /_\   __| | _ __  (_) _ _   / __| ___ | |_ | |_ (_) _ _   __ _
    //    / _ \ / _` || '  \ | || ' \  \__ \/ -_)|  _||  _|| || ' \ / _` |
    //   /_/ \_\\__,_||_|_|_||_||_||_| |___/\___| \__| \__||_||_||_|\__, |
    //                                                              |___/
    adminsetting: {
      label: "Admin Settings",
      icon: "pi pi-fw pi-cog",
      items: [],
      rank: 9,
    },

    user: {
      label: "Employees",
      to: "/admin/employees",
      rank: 30,
      // These are the permissions that can see user table
      permission: [
        TaskList.adminchangepw,
        TaskList.userroleaccess,
        TaskList.useraccess,
        TaskList.userdocaccess,
        TaskList.leaveadminaccess,
        TaskList.contractaccess,
      ],
    },

    userstatus: {
      label: "User Stuff",
      to: "/admin/employees/:status/:name/:id",
      rank: 30,
      permission: [
        TaskList.adminchangepw,
        TaskList.userroleaccess,
        TaskList.useraccess,
        TaskList.userdocaccess,
        TaskList.leaveadminaccess,
        TaskList.contractaccess,
        TaskList.roleaccess,
        TaskList.jobtitleaccess,
        TaskList.departmentaccess,
      ],
    },
    usercreate: {
      label: "Create Employee",
      to: "/admin/employees/create",
      rank: 30,
      permission: [TaskList.useraccess],
    },
    useronboarding: {
      label: "User Onboarding",
      to: "/admin/employees/onboarding/:id",
      rank: 30,
      permission: [TaskList.useraccess],
    },
    userupdate: {
      label: "Update Employee",
      to: "/admin/employees/update/:id",
      rank: 30,
      permission: [TaskList.useraccess],
    },
    userview: {
      label: "View Employee",
      // icon: "pi pi-fw pi-users",
      to: "/admin/employees/view/:id",
      rank: 30,
      permission: [TaskList.useraccess],
    },
    useradminsetting: {
      label: "User Role Settings",
      // icon: "pi pi-fw pi-users",
      to: "/admin/employees/user_role/:id",
      rank: 30,
      permission: [TaskList.userroleaccess, TaskList.customkpiaccess],
    },
    changepasswordadmin: {
      label: "Password Change Admin",
      // icon: "pi pi-fw pi-users",
      to: "/admin/employees/password/:id",
      rank: 30,
      permission: [TaskList.adminchangepw],
    },
    userdocument: {
      label: "User Document",
      to: "/admin/employees/document/:id",
      rank: 38,
      permission: [TaskList.userdocaccess],
    },
    createuserdocument: {
      label: "Create User Document",
      to: "/admin/employees/document_create/:id",
      rank: 38,
      permission: [TaskList.userdocaccess],
    },
    updateuserdocument: {
      label: "Update User Document",
      to: "/admin/employees/document_update/:id",
      rank: 38,
      permission: [TaskList.userdocaccess],
    },

    //     ___            _                   _
    //    / __| ___  _ _ | |_  _ _  __ _  __ | |_
    //   | (__ / _ \| ' \|  _|| '_|/ _` |/ _||  _|
    //    \___|\___/|_||_|\__||_|  \__,_|\__| \__|
    //

    contract: {
      label: "Contracts",
      to: "/admin/contract",
      rank: 31,
      permission: [TaskList.contractaccess],
    },
    viewcontract: {
      label: "View Single Contract",
      to: "/admin/employees/contract_view/:id",
      rank: 31,
      permission: [TaskList.contractaccess],
    },
    createnewcontract: {
      label: "Create New Contract",
      to: "/admin/contract/create",
      rank: 31,
      permission: [TaskList.contractaccess],
    },
    createusercontract: {
      label: "Create Contract For User",
      to: "/admin/contract/create/:id",
      rank: 31,
      permission: [TaskList.contractaccess],
    },
    updatecontract: {
      label: "Update Contract For User",
      to: "/admin/contract/update/:id/:user",
      rank: 31,
      permission: [TaskList.contractaccess],
    },
    viewusercontract: {
      label: "View User's Contract",
      to: "/admin/employees/contract_of_user/:id",
      rank: 31,
      permission: [TaskList.contractaccess],
    },
    viewusercontractfromcontract: {
      label: "View User Contract",
      to: "/admin/contract/view/:id",
      rank: 31,
      permission: [TaskList.contractaccess],
    },

    //     ___                                      ___       _    _    _
    //    / __| ___  _ __   _ __  __ _  _ _  _  _  / __| ___ | |_ | |_ (_) _ _   __ _
    //   | (__ / _ \| '  \ | '_ \/ _` || ' \| || | \__ \/ -_)|  _||  _|| || ' \ / _` |
    //    \___|\___/|_|_|_|| .__/\__,_||_||_|\_, | |___/\___| \__| \__||_||_||_|\__, |
    //                     |_|               |__/                               |___/

    companysetting: {
      label: "Company Settings",
      items: [],
      rank: 8,
      permission: [TaskList.basicaccess],
    },
    groupcompanyupdate: {
      label: "Update Group Company Details",
      to: "/groupcompany/update/:id",
      rank: 30,
      permission: [TaskList.companyaccess],
    },
    company: {
      label: "Company",
      to: "/admin/company",
      rank: 30,
      permission: [TaskList.companyaccess],
    },
    createcompany: {
      label: "Create Company",
      to: "/admin/company/create",
      rank: 30,
      permission: [TaskList.companyaccess],
    },
    updatecompany: {
      label: "Update Company",
      to: "/admin/company/update/:id",
      rank: 30,
      permission: [TaskList.companyaccess],
    },

    //    ___                         _                     _
    //   |   \  ___  _ __  __ _  _ _ | |_  _ __   ___  _ _ | |_
    //   | |) |/ -_)| '_ \/ _` || '_||  _|| '  \ / -_)| ' \|  _|
    //   |___/ \___|| .__/\__,_||_|   \__||_|_|_|\___||_||_|\__|
    //              |_|

    department: {
      label: "Department",
      to: "/admin/department",
      rank: 31,
      permission: [TaskList.departmentaccess],
    },
    createdepartment: {
      label: "Create Department",
      to: "/admin/department/create",
      rank: 31,
      permission: [TaskList.departmentaccess],
    },
    updatedepartment: {
      label: "Update Department",
      to: "/admin/department/update/:id",
      rank: 31,
      permission: [TaskList.departmentaccess],
    },

    //     ___                          _            _    _              ___       _    _    _
    //    / _ \  _ _  __ _  __ _  _ _  (_) ___ __ _ | |_ (_) ___  _ _   / __| ___ | |_ | |_ (_) _ _   __ _  ___
    //   | (_) || '_|/ _` |/ _` || ' \ | ||_ // _` ||  _|| |/ _ \| ' \  \__ \/ -_)|  _||  _|| || ' \ / _` |(_-<
    //    \___/ |_|  \__, |\__,_||_||_||_|/__|\__,_| \__||_|\___/|_||_| |___/\___| \__| \__||_||_||_|\__, |/__/
    //               |___/                                                                           |___/

    organizationsetting: {
      label: "Organization Settings",
      items: [],
      rank: 7,
      permission: [TaskList.basicaccess],
    },

    documenttype: {
      label: "Document Types",
      to: "/admin/document_type",
      rank: 31,
      permission: [TaskList.documenttypeaccess],
    },
    createdocumenttype: {
      label: "Create Document Type",
      to: "/admin/document_type/create",
      rank: 31,
      permission: [TaskList.documenttypeaccess],
    },
    updatedocumenttype: {
      label: "Update Document Type",
      to: "/admin/document_type/update/:id",
      rank: 31,
      permission: [TaskList.documenttypeaccess],
    },

    statustype: {
      label: "Leave Types",
      to: "/admin/leave_types",
      rank: 32,
      permission: [TaskList.statustypeaccess],
    },
    createstatustype: {
      label: "Leave Types",
      to: "/admin/leave_types/create",
      rank: 32,
      permission: [TaskList.statustypeaccess],
    },
    updatestatustype: {
      label: "Leave Types",
      to: "/admin/leave_types/update/:id",
      rank: 32,
      permission: [TaskList.statustypeaccess],
    },

    holiday: {
      label: "Holidays",
      to: "/admin/holidays",
      rank: 30,
      permission: [TaskList.holidayaccess],
    },
    createholiday: {
      label: "Create Holiday",
      to: "/admin/holidays/create",
      rank: 30,
      permission: [TaskList.holidayaccess],
    },
    updateholiday: {
      label: "Holidays",
      to: "/admin/holidays/update/:id",
      rank: 30,
      permission: [TaskList.holidayaccess],
    },

    jobtitle: {
      label: "Job Title",
      to: "/admin/job_title",
      rank: 33,
      permission: [TaskList.jobtitleaccess],
    },
    createjobtitle: {
      label: "Create Job Title",
      to: "/admin/job_title/create",
      rank: 33,
      permission: [TaskList.jobtitleaccess],
    },
    updatejobtitle: {
      label: "Update Job Title",
      to: "/admin/job_title/update/:id",
      rank: 33,
      permission: [TaskList.jobtitleaccess],
    },

    role: {
      label: "Role",
      to: "/admin/role",
      rank: 34,
      permission: [TaskList.roleaccess],
    },
    createrole: {
      label: "Create Role",
      to: "/admin/role/create",
      rank: 34,
      permission: [TaskList.roleaccess],
    },
    updaterole: {
      label: "Update Role",
      to: "/admin/role/update/:id",
      rank: 34,
      permission: [TaskList.roleaccess],
    },

    room: {
      label: "Room",
      to: "/admin/room",
      rank: 35,
      permission: [TaskList.roomaccess],
    },
    createroom: {
      label: "Create Room",
      to: "/admin/room/create",
      rank: 35,
      permission: [TaskList.roomaccess],
    },
    updateroom: {
      label: "Update Room",
      to: "/admin/room/update/:id",
      rank: 35,
      permission: [TaskList.roomaccess],
    },

    costtype: {
      label: "Cost Types",
      to: "/admin/cost_type",
      rank: 36,
      permission: [TaskList.costtypeaccess],
    },
    createcosttype: {
      label: "Create Cost Type",
      to: "/admin/cost_type/create",
      rank: 36,
      permission: [TaskList.costtypeaccess],
    },
    updatecosttype: {
      label: "Update Cost Type",
      to: "/admin/cost_type/update/:id",
      rank: 36,
      permission: [TaskList.costtypeaccess],
    },

    campaigntype: {
      label: "Campaign Types",
      to: "/admin/campaign_type",
      rank: 37,
      permission: [TaskList.campaigntypeaccess],
    },
    createcampaigntype: {
      label: "Create Campaign Type",
      to: "/admin/campaign_type/create",
      rank: 37,
      permission: [TaskList.campaigntypeaccess],
    },
    updatecampaigntype: {
      label: "Update Campaign Type",
      to: "/admin/campaign_type/update/:id",
      rank: 37,
      permission: [TaskList.campaigntypeaccess],
    },
    paymentterms: {
      label: "Payment Terms",
      to: "/admin/payment_terms",
      rank: 37,
      permission: [TaskList.paymenttermsaccess],
    },
    paymenttermscreate: {
      label: "Payment Terms",
      to: "/admin/payment_terms/create",
      rank: 37,
      permission: [TaskList.paymenttermsaccess],
    },
    paymenttermsupdate: {
      label: "Create Payment Terms",
      to: "/admin/payment_terms/update/:id",
      rank: 37,
      permission: [TaskList.paymenttermsaccess],
    },
    deliverabletype: {
      label: "Deliverable Types",
      to: "/admin/deliverable_type",
      rank: 38,
      permission: [TaskList.deliverabletypeaccess],
    },
    createdeliverabletype: {
      label: "Create Deliverable Type",
      to: "/admin/deliverable_type/create",
      rank: 38,
      permission: [TaskList.deliverabletypeaccess],
    },
    updatedeliverabletype: {
      label: "Update Deliverable Type",
      to: "/admin/deliverable_type/update/:id",
      rank: 38,
      permission: [TaskList.deliverabletypeaccess],
    },
    weeklyduties: {
      label: "Weekly Duties",
      to: "/admin/weekly_duties",
      rank: 39,
      permission: [TaskList.weeklydutiesaccess],
    },
    createweeklyduties: {
      label: "Create Weekly Duty",
      to: "/admin/weekly_duties/create",
      rank: 39,
      permission: [TaskList.weeklydutiesaccess],
    },
    updateweeklyduties: {
      label: "Update Weekly Duty",
      to: "/admin/weekly_duties/update/:id",
      rank: 39,
      permission: [TaskList.weeklydutiesaccess],
    },
    publisher: {
      label: "Publishers",
      to: "/admin/publishers",
      rank: 40,
      permission: [TaskList.publisheraccess],
    },
    createpublisher: {
      label: "Create Publisher",
      to: "/admin/publishers/create",
      rank: 40,
      permission: [TaskList.publisheraccess],
    },
    updatepublisher: {
      label: "Update Publisher",
      to: "/admin/publishers/update/:id",
      rank: 40,
      permission: [TaskList.publisheraccess],
    },
    quicklinksdashboard: {
      label: "Quicklinks Dashboard",
      to: "/admin/quick_links",
      rank: 41,
      permission: [TaskList.quicklinksaccess],
    },
    createquicklinks: {
      label: "Create Quicklink",
      to: "/admin/quick_links/create",
      rank: 41,
      permission: [TaskList.quicklinksaccess],
    },
    updatequicklinks: {
      label: "Update Quicklink",
      to: "/admin/quick_links/update/:id",
      rank: 41,
      permission: [TaskList.quicklinksaccess],
    },
    createcirculars: {
      label: "Create Circular",
      to: "/admin/circulars/create",
      rank: 42,
      permission: [TaskList.quicklinksaccess],
    },
    updatecirculars: {
      label: "Update Circular",
      to: "/admin/circulars/update/:id",
      rank: 42,
      permission: [TaskList.quicklinksaccess],
    },
    //    _                           __        ___    __   __
    //   | |    ___  __ _ __ __ ___  / _|___   / _ \  / _| / _|
    //   | |__ / -_)/ _` |\ V // -_) > _|_ _| | (_) ||  _||  _|
    //   |____|\___|\__,_| \_/ \___| \_____|   \___/ |_|  |_|
    //

    leavesetting: {
      label: "Leave",
      icon: "pi pi-fw pi-clock",
      items: [],
      rank: 6,
      permission: [TaskList.basicaccess],
    },

    creditstatus: {
      label: "Leave Credit Status",
      to: "/admin/employees/credit_leave",
      rank: 30,
      permission: [TaskList.leaveadminaccess],
    },
    viewcreditstatus: {
      label: "Leave Credit Status",
      to: "/admin/employees/credit_leave/:id",
      rank: 30,
      permission: [TaskList.leaveadminaccess],
    },
    viewuserleavebalancehistory: {
      label: "Balance & History of User",
      to: "/admin/employees/leave_summary/:id",
      rank: 31,
      permission: [TaskList.leaveadminaccess],
    },
    approveleave: {
      label: "Approve Leave",
      to: "/status/approve",
      rank: 32,
      permission: [TaskList.approveleaveaccess],
    },
    departmentview: {
      label: "Department View",
      to: "/status/departmentview",
      rank: 33,
      permission: [TaskList.departmentleaveaccess, TaskList.approveleaveaccess],
    },
    applyleaveonbehalfsupervisor: {
      label: "Apply Leave On Behalf",
      to: "/status/departmentview/apply/:userid",
      rank: 30,
      permission: [TaskList.approveleaveaccess],
    },
    leavebalancehistory: {
      label: "Balance & History",
      to: "/status/summary",
      rank: 31,
      permission: [TaskList.basicaccess],
    },
    applyleave: {
      label: "Apply Leave",
      to: "/status/apply",
      rank: 30,
      permission: [TaskList.basicaccess],
    },
    updateleave: {
      label: "Update Leave",
      to: "/status/update/:id",
      rank: 30,
      permission: [TaskList.basicaccess],
    },
    adminaccess: {
      label: "Leave Overview",
      to: "/admin/leave_admin",
      rank: 34,
      permission: [TaskList.leaveadminaccess],
    },

    //     ___  _        _
    //    / __|| | __ _ (_) _ __   ___
    //   | (__ | |/ _` || || '  \ (_-<
    //    \___||_|\__,_||_||_|_|_|/__/
    //

    claims: {
      label: "Claims",
      icon: "pi pi-fw pi-dollar",
      items: [],
      rank: 7,
    },
    createclaims: {
      label: "Make a Claim",
      to: "/claims/create",
      rank: 31,
      permission: [TaskList.basicaccess],
    },
    // updateclaims: {
    //   label: "Make a Claim",
    //   to: "/claims/update/:id",
    //   rank: 31,
    //   permission: [TaskList.basicaccess],
    // },
    viewclaims: {
      label: "My Claims",
      to: "/claims/view",
      rank: 32,
      permission: [TaskList.basicaccess],
    },
    viewthisclaim: {
      label: "My Claims",
      to: "/claims/view/:id",
      rank: 32,
      permission: [TaskList.basicaccess],
    },
    endorseclaims: {
      label: "Endorse Claims",
      to: "/claims/endorse",
      rank: 33,
      permission: [TaskList.endorseclaimaccess],
    },
    approveclaims: {
      label: "Claims",
      to: "/finance/claims",
      rank: 34,
      permission: [TaskList.approveclaimsaccess, TaskList.viewclaimsaccess],
    },
    admineditclaims: {
      label: "Claims",
      to: "/claims/adminedit/:id",
      rank: 34,
      permission: [TaskList.approveclaimsaccess],
    },

    //    ___  _
    //   | __|(_) _ _   __ _  _ _   __  ___
    //   | _| | || ' \ / _` || ' \ / _|/ -_)
    //   |_|  |_||_||_|\__,_||_||_|\__|\___|
    //

    finance: {
      label: "Finance",
      icon: "pi pi-fw pi-cog",
      items: [],
      rank: 10,
    },

    financecompanydetails: {
      label: "Finance Details",
      to: "/finance/company_details",
      rank: 31,
      permission: [TaskList.financeaccess],
    },
    // payroll: {
    //   label: "Run Payroll",
    //   to: "/finance/payroll",
    //   rank: 32,
    //   permission: [TaskList.payrollaccess],
    // },
    payrollview: {
      // View payroll for month
      label: "View Payroll",
      to: "/finance/payroll/:id",
      rank: 32,
      permission: [TaskList.payrollaccess],
    },
    payslipupdate: {
      label: "Update Payslip",
      to: "/finance/payroll/payslip/:id/update",
      rank: 32,
      permission: [TaskList.payrollaccess],
    },
    payslipexport: {
      label: "Export Payslip",
      to: "/finance/payroll/user/:id/export",
      rank: 32,
      permission: [TaskList.payrollaccess],
    },
    payrollmanual: {
      label: "Payroll - Add",
      to: "/finance/payroll/add_payslip/:id",
      rank: 32,
      permission: [TaskList.payrollaccess],
    },
    payrollhistory: {
      // 25/06/2020 -> this is now All Payrolls Page
      label: "Payrolls",
      to: "/finance/payroll",
      rank: 33,
      permission: [TaskList.payrollaccess],
    },
    // payrollhistorysingle: {
    //   label: "Payroll History",
    //   to: "/finance/payroll_history/:date",
    //   rank: 33,
    //   permission: [TaskList.payrollaccess],
    // },
    payitemtype: {
      label: "Pay Item Types",
      to: "/finance/pay_item_type",
      rank: 35,
      permission: [TaskList.payrollaccess],
    },
    payitemtypecreate: {
      label: "Create Pay Item Type",
      to: "/finance/pay_item_type/create",
      rank: 35,
      permission: [TaskList.payrollaccess],
    },
    payitemtypeupdate: {
      label: "Update Pay Item Type",
      to: "/finance/pay_item_type/update/:id",
      rank: 35,
      permission: [TaskList.payrollaccess],
    },
    externalcost: {
      label: "Operational Costs",
      to: "/finance/operational_cost",
      rank: 33,
      permission: [TaskList.operationalcostaccess],
    },
    externalcostcreate: {
      label: "Create External Costs",
      to: "/finance/operational_cost/create",
      rank: 33,
      permission: [TaskList.operationalcostaccess],
    },
    externalcostupdate: {
      label: "Update External Costs",
      to: "/finance/operational_cost/update/:id",
      rank: 33,
      permission: [TaskList.operationalcostaccess],
    },
    accountingcodecreate: {
      label: "Create Accounting Code",
      to: "/finance/accounting_code/create",
      rank: 38,
      permission: [TaskList.approveclaimsaccess],
    },
    accountingcodeupdate: {
      label: "Update Accounting Code",
      to: "/finance/accounting_code/update/:id",
      rank: 38,
      permission: [TaskList.approveclaimsaccess],
    },
    accountingcode: {
      label: "Accounting Code",
      to: "/finance/accounting_code",
      rank: 38,
      permission: [TaskList.approveclaimsaccess],
    },

    //    ___                                 _
    //   | _ \ ___  _ _  ___ ___  _ _   __ _ | |
    //   |  _// -_)| '_|(_-</ _ \| ' \ / _` || |
    //   |_|  \___||_|  /__/\___/|_||_|\__,_||_|
    //

    personal: {
      label: "Personal",
      icon: "pi pi-fw pi-user",
      items: [],
      rank: 6,
    },

    changepassword: {
      label: "Change Password",
      to: "/settings/changepassword",
      rank: 31,
      permission: [TaskList.basicaccess],
    },

    payslip: {
      label: "Payslip",
      to: "/payslip/viewall",
      rank: 32,
      permission: [TaskList.basicaccess],
    },

    profile: {
      label: "Profile",
      to: "/profile",
      rank: 30,
      permission: [TaskList.basicaccess],
    },

	businesscarduser: {
		label: "My Business Card",
		to: "/card",
		rank: 31,
		permission: [TaskList.basicaccess]
	},

    emailsignature: {
      label: "Email Signature",
	  to: "/email-signature",
      rank: 31,
      permission: [TaskList.basicaccess],
    },

    //    ___  _          _
    //   | _ )(_) _ _  __| | ___ ___  _  _  ___
    //   | _ \| || '_|/ _` |(_-</ -_)| || |/ -_)
    //   |___/|_||_|  \__,_|/__/\___| \_, |\___|
    //                                |__/

    birdseye: {
      label: "Company Overview",
      to: "/birdseye",
      rank: 4,
      permission: [TaskList.basicaccess],
    },

    //    _____           _
    //   |_   _|__ _  ___| |__
    //     | | / _` |(_-<| / /
    //     |_| \__,_|/__/|_\_\
    //

    tasksettings: {
      label: "Task Settings",
      to: "/tasksettings",
      rank: 110,
      permission: [TaskList.techaccess],
    },

    assigntaskrole: {
      label: "Assign Task to Role",
      to: "/admin/role/task/:id",
      rank: 110,
      permission: [TaskList.roleaccess],
    },

    //    ___        _
    //   / __| __ _ | | ___  ___
    //   \__ \/ _` || |/ -_)(_-<
    //   |___/\__,_||_|\___|/__/
    //

    sales: {
      label: "Sales",
      icon: "pi pi-fw pi-money-bill",
      items: [],
      rank: 11,
    },

    clientcompany: {
      label: "Clients",
      to: "/sales/clientcompany",
      rank: 30,
      permission: [TaskList.salesaccess, TaskList.clientaccess],
    },
    createclientcompany: {
      label: "Clients",
      to: "/sales/clientcompany/create",
      rank: 30,
      permission: [TaskList.salesaccess, TaskList.clientaccess],
    },
    updateclientcompany: {
      label: "Clients",
      to: "/sales/clientcompany/update/:id",
      rank: 30,
      permission: [TaskList.salesaccess, TaskList.clientaccess],
    },

    createsuppliercompany: {
      label: "Suppliers",
      to: "/finance/operational_cost/create_supplier/:supplier",
      rank: 30,
      permission: [TaskList.salesaccess, TaskList.operationalcostaccess],
    },
    updatesuppliercompany: {
      label: "Suppliers",
      to: "/finance/operational_cost/update_supplier/:id/:supplier",
      rank: 30,
      permission: [TaskList.salesaccess, TaskList.operationalcostaccess],
    },
    clientcontacts: {
      label: "Clients",
      to: "/sales/clientcontacts/:id",
      rank: 30,
      permission: [TaskList.salesaccess, TaskList.clientaccess],
    },
    createclientcontacts: {
      label: "Clients",
      to: "/sales/clientcontacts/create/:companyid",
      rank: 30,
      permission: [TaskList.salesaccess, TaskList.clientaccess],
    },
    updateclientcontacts: {
      label: "Clients",
      to: "/sales/clientcontacts/update/:contactid",
      rank: 30,
      permission: [TaskList.salesaccess, TaskList.clientaccess],
    },

    //    ___    _    _     ___  ___    ___  _     ___  ___  _  _  _____  ___
    //   / __|  /_\  | |   | __|/ __|  / __|| |   |_ _|| __|| \| ||_   _|/ __|
    //   \__ \ / _ \ | |__ | _| \__ \ | (__ | |__  | | | _| | .` |  | |  \__ \
    //   |___//_/ \_\|____||___||___/  \___||____||___||___||_|\_|  |_|  |___/
    //

    viewassignedclients: {
      label: "My Clients",
      to: "/sales/view",
      rank: 30,
      permission: [TaskList.salesaccess, TaskList.clientaccess],
    },
    createsalesclientbyclient: {
      label: "Create Client",
      to: "/sales/add/:company/:contact",
      rank: 30,
      permission: [TaskList.salesaccess, TaskList.clientaccess],
    },
    createsalesclient: {
      label: "Create Client",
      to: "/sales/add",
      rank: 30,
      permission: [TaskList.salesaccess, TaskList.clientaccess],
    },
    updateclient: {
      label: "Update Client",
      to: "/client/:id/edit/:contactid",
      rank: 30,
      permission: [TaskList.salesaccess, TaskList.clientaccess],
    },

    //     ___                           _
    //    / __| __ _  _ __   _ __  __ _ (_) __ _  _ _   ___
    //   | (__ / _` || '  \ | '_ \/ _` || |/ _` || ' \ (_-<
    //    \___|\__,_||_|_|_|| .__/\__,_||_|\__, ||_||_|/__/
    //                      |_|            |___/

    campaignsanddeliverables: {
      label: "Campaigns",
      icon: "pi pi-fw pi-tags",
      items: [],
      rank: 12,
    },

    campaigns: {
      label: "Campaigns Masterlist",
      to: "/campaigns/masterlist",
      rank: 40,
      permission: [TaskList.campaignadminaccess],
    },
    createcampaign: {
      label: "Create Campaign",
      to: "/campaign/:masterlist/create",
      rank: 30,
      permission: [TaskList.campaignaccess, TaskList.campaignadminaccess],
    },
    updatecampaign: {
      label: "Update Campaign",
      to: "/campaign/:masterlist/update/:id",
      rank: 30,
      permission: [TaskList.campaignaccess, TaskList.campaignadminaccess],
    },
    mycampaigns: {
      label: "My Campaigns",
      to: "/campaigns",
      rank: 30,
      permission: [TaskList.campaignaccess, TaskList.campaignadminaccess],
    },
    campaignsummary: {
      label: "Campaign Summary",
      to: "/campaign/summary/:id",
      rank: 30,
      permission: [TaskList.campaignaccess, TaskList.campaignadminaccess],
    },
    campaigndocuments: {
      label: "Campaign Document",
      to: "/campaign/documents/:id",
      rank: 30,
      permission: [TaskList.campaignaccess, TaskList.campaignadminaccess],
    },
    createcampaigndocuments: {
      label: "Create Campaign Document",
      to: "/campaign/document/:campaignid/create",
      rank: 30,
      permission: [TaskList.campaignaccess, TaskList.campaignadminaccess],
    },
    updatecampaigndocuments: {
      label: "Update Campaign Document",
      to: "/campaign/document/:campaignid/update/:docid",
      rank: 30,
      permission: [TaskList.campaignadminaccess, TaskList.campaignaccess],
    },
    campaigninvoice: {
      label: "Campaign Invoice",
      to: "/campaign/invoice/:id",
      rank: 1,
      permission: [TaskList.campaignaccess, TaskList.campaignadminaccess],
    },
    campaigninvoiceexport: {
      label: "Campaign Invoice",
      to: "/campaign/invoice/:id/export",
      rank: 1,
      permission: [TaskList.campaignadminaccess, TaskList.campaignaccess],
    },
    myoutstandingdeliverables: {
      label: "My Outstanding Deliverables",
      to: "/deliverables/outstanding",
      rank: 32,
      permission: [TaskList.campaignaccess, TaskList.campaignadminaccess],
    },
    workloaddistribution: {
      label: "Team's Deliverables",
      to: "/deliverables/distribution",
      rank: 33,
      permission: [TaskList.campaignaccess, TaskList.campaignadminaccess],
    },
    createdeliverable: {
      label: "Create Deliverables",
      to: "/deliverable/:campaignid/create",
      rank: 30,
      permission: [TaskList.campaignaccess, TaskList.campaignadminaccess],
    },
    updatedeliverable: {
      label: "Update Deliverable",
      to: "/deliverable/:campaignid/update/:deliverableid",
      rank: 30,
      permission: [TaskList.campaignaccess, TaskList.campaignadminaccess],
    },

    reports: {
      label: "Reports Export",
      to: "/admin/reports",
      rank: 31,
      permission: [TaskList.reportaccess],
    },

    //    ___              _                         _
    //   | __| __ _  _  _ (_) _ __  _ __   ___  _ _ | |_
    //   | _| / _` || || || || '_ \| '  \ / -_)| ' \|  _|
    //   |___|\__, | \_,_||_|| .__/|_|_|_|\___||_||_|\__|
    //           |_|         |_|
    //    aka Logistics

    equipments: {
      label: "Equipments",
      icon: "pi pi-fw pi-video",
      items: [],
      rank: 13,
    },
    logsstatuscreate: {
      label: "Create Logistics Status",
      to: "/logs/status/create",
      rank: 13,
      permission: [TaskList.equipmentadminaccess],
    },
    logisticscreate: {
      label: "Create Logistics",
      to: "/logs/create",
      rank: 13,
      permission: [TaskList.equipmentadminaccess],
    },
    logisticsupdate: {
      label: "Update Logistics Info",
      to: "/logs/update/:logisticsID",
      rank: 13,
      permission: [TaskList.equipmentadminaccess],
    },
    equipmentview: {
      label: "Manage Logistics",
      to: "/equipment",
      rank: 13,
      permission: [TaskList.equipmentadminaccess],
    },
    equipmentdetails: {
      label: "View Equipment Details",
      to: "/equipment/:equipmentSerial",
      rank: 13,
      permission: [TaskList.equipmentadminaccess],
    },
    equipmentwriteoff: {
      label: "Write Off Equipment",
      to: "/writeoff",
      rank: 13,
      permission: [TaskList.equipmentadminaccess],
    },

    //     ___  _  _  ___   ___   _  _   ___   ___
    //    / __|| || || _ \ / _ \ | \| | / _ \ / __|
    //   | (__ | __ ||   /| (_) || .` || (_) |\__ \
    //    \___||_||_||_|_\ \___/ |_|\_| \___/ |___/
    //

    chronos: {
      label: "CHRONOS",
      to: "/chronos",
      icon: "pi pi-fw pi-camera",
      rank: 5,
      permission: [TaskList.techaccess],
    },
    equipmentwithdraw: {
      label: "Equipment Movement",
      to: "/inout",
      rank: 1,
      permission: [TaskList.equipmentadminaccess],
    },

    // KPI
    kpi: {
      label: "KPI",
      icon: "pi pi-fw pi-star",
      items: [],
      rank: 7,
    },
    userindicator: {
      label: "My KPI",
      to: "/indicators/mykpi",
      rank: 8,
      permission: [TaskList.userindicatoraccess],
    },
    departmentscoreindicator: {
      label: "Department Scoring Sheet",
      to: "/indicators/scoring",
      rank: 8,
      permission: [TaskList.userindicatoraccess],
    },
    departmentuserindicator: {
      label: "Supervisor Overview",
      to: "/indicators/mydept",
      rank: 8,
      permission: [TaskList.departmentuserindicatoraccess],
    },
    viewscoreindicator: {
      label: "KPI",
      to: "/admin/score_indicator",
      rank: 5,
      permission: [TaskList.scoreindicatoraccess],
    },
    bossuserindicatorview: {
      label: "Group Company KPI",
      to: "/indicators/groupcompany",
      rank: 8,
      permission: [TaskList.userindicatorsbossaccess],
    },
    bossdeptstatsview: {
      label: "Link Performance",
      to: "/indicators/stats/:id",
      rank: 8,
      permission: [TaskList.userindicatorsbossaccess],
    },
    linkperformanceview: {
      label: "Link Performance",
      to: "/linkperformance",
      rank: 8,
      permission: [TaskList.userindicatorsbossaccess],
    },
    irnlinkstatsdashboard: {
      label: "IRN Dashboard",
      to: "/irndashboard",
      rank: 8,
      permission: [TaskList.techaccess],
    },

    onboarding: {
      label: "Onboarding",
      to: "/onboarding",
      rank: 30,
      permission: [TaskList.basicaccess],
    },
    sitemap: {
      label: "Sitemap",
      to: "/sitemap",
      rank: 30,
      permission: [TaskList.basicaccess],
    },
    error: {
      label: "Error",
      to: "/error",
      rank: 30,
      permission: [TaskList.basicaccess],
    },
    // errorRouteNotFound: {
    //   label: "Error",
    //   to: "/*",
    //   rank: 30,
    //   permission: [TaskList.basicaccess],
    // },

    safeentrydashboard: {
      label: "Entry/Exit Dashboard",
      to: "/entry_exit_dashboard",
      rank: 1,
      permission: [TaskList.safeentrydashboardaccess],
    },

    // Pages available before login
    priceplans: {
      label: "Price Plans",
      to: "/priceplans",
      rank: 1,
      permission: [TaskList.basicaccess],
    },

    signupform: {
      label: "Contact Us",
      to: "/contactus",
      rank: 1,
      permission: [TaskList.basicaccess],
    },

    register: {
      label: "Sign Up",
      to: "/sme",
      rank: 1,
      permission: [TaskList.basicaccess],
    },

    taxfilingdashboard: {
      label: "Tax filing",
      to: "/finance/tax_filing",
      rank: 100,
      permission: [TaskList.payrollaccess],
    },
    taxfiling: {
      label: "Tax filing",
      to: "/finance/tax_filing/:id",
      rank: 100,
      permission: [TaskList.payrollaccess],
    },
    taxfilingupdate: {
      label: "Update Tax Body",
      to: "/finance/tax_filing/update/:taxbodyid",
      rank: 100,
      permission: [TaskList.payrollaccess],
    },
    irassubmissionloading: {
      label: "Submission of Employment Income Records",
      to: "/finance/tax_filing/submit/loading",
      rank: 100,
      permission: [TaskList.basicaccess],
    },
    irassubmissionsuccess: {
      label: "Submission Complete",
      to: "/tax_filing/submit/success",
      rank: 100,
      permission: [TaskList.basicaccess],
    },
    irassubmissionerror: {
      label: "Submission Error",
      to: "/tax_filing/submit/error",
      rank: 100,
      permission: [TaskList.basicaccess],
    },
  };

  //    ___        _     __  __                   _     _      _
  //   / __| _  _ | |__ |  \/  | ___  _ _  _  _  | |   (_) ___| |_
  //   \__ \| || || '_ \| |\/| |/ -_)| ' \| || | | |__ | |(_-<|  _|
  //   |___/ \_,_||_.__/|_|  |_|\___||_||_|\_,_| |____||_|/__/ \__|
  //

  OrganizationSettingList: SubMenuList = {
    label: "Organisation Settings",
    group: "organizationsetting",
    items: [
      "costtype",
      "campaigntype",
      "deliverabletype",
      "documenttype",
      // "paymentterms",
      "role",
      "room",
      "publisher",
      "statustype",
      "weeklyduties",
      "safeentrydashboard",
      "quicklinksdashboard",
    ],
  };
  ClaimsList: SubMenuList = {
    label: "Claims",
    group: "claims",
    items: ["createclaims", "viewclaims", "endorseclaims"],
  };
  KPIList: SubMenuList = {
    label: "KPI",
    group: "kpi",
    items: ["userindicator", "departmentuserindicator"],
  };

  LeaveList: SubMenuList = {
    label: "Leave",
    group: "leavesetting",
    items: [
      "applyleave",
      "leavebalancehistory",
      "approveleave",
      "departmentview",
      // "calendar",
      // "adminaccess"
    ],
  };

  PersonalList: SubMenuList = {
    label: "Personal",
    group: "personal",
    items: ["profile", "payslip", "businesscarduser", "emailsignature", "changepassword"],
  };

  CompanySettingList: SubMenuList = {
    label: "Company Settings",
    group: "companysetting",
    items: [
      "company",
      "department",
      "jobtitle",
      "holiday",
      "viewscoreindicator",
    ],
  };

  AdminList: SubMenuList = {
    label: "Admin Menu",
    group: "adminsetting",
    items: ["contract", "user", "adminaccess", "reports"],
  };

  FinanceList: SubMenuList = {
    label: "Finance",
    group: "finance",
    items: [
      "accountingcode",
      "approveclaims",
      "financecompanydetails",
      "externalcost",
      // "payroll",
      "payrollhistory",
      "payitemtype",
      "taxfilingdashboard",
    ],
  };

  SalesList: SubMenuList = {
    label: "Sales",
    group: "sales",
    items: ["clientcompany", "viewassignedclients"],
  };

  CampaignList: SubMenuList = {
    label: "Campaigns & Deliverables",
    group: "campaignsanddeliverables",
    items: [
      "campaigns",
      "mycampaigns",
      "myoutstandingdeliverables",
      "workloaddistribution",
      "campaigninvoice",
    ],
  };

  EquipmentList: SubMenuList = {
    label: "Equipments",
    group: "equipments",
    items: [
      "equipmentwithdraw",
      "logsstatuscreate",
      "logisticscreate",
      "logisticsupdate",
      "equipmentview",
    ],
  };
}
