import React, { useEffect, useState } from "react";
import { MultiDeptService } from "../../../service/MultiDeptService";
import {
  CommonMethods,
  RouteComponentProps
} from "../../resources/CommonMethods";
import CustomError from "../../resources/Error";
import { TaskList } from "../../resources/ListOfTasks";
import { CompanyDepartmentModel } from "../../settings/MultiDeptSetting";
import OneDeptScoreIndicator from "./OneDeptScoreIndicator";

function ViewCustomDeptScoreIndicators(props: RouteComponentProps<any>) {
  const multiDeptService = new MultiDeptService();
  const commonMethods = new CommonMethods();

  const [deptList, setDeptList] = useState<Array<CompanyDepartmentModel>>(
    new Array<CompanyDepartmentModel>()
  );
  const [userRanks, setUserRanks] = useState<any>([]);
  const [hasMultiDeptView] = useState<boolean>(
    props.userProfile.taskList.includes(TaskList.customkpiaccess)
  );
  const [myDeptId, setMyDeptId] = useState<string>("");
  const [viewOnly] = useState<boolean>(
    !props.userProfile.taskList.includes(TaskList.departmentuserindicatoraccess)
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  useEffect(() => {
    init();
  }, []);

  const getUserRank = async () => {
    await commonMethods
      .getUserRankDropdown()
      .then((res) => {
        setUserRanks(res);
      })
      .catch(() => {
        setUserRanks([]);
      });
  };

  const init = async () => {
    await getUserRank();

    if (hasMultiDeptView) {
      multiDeptService
        .getCustomMultiDeptSetting(props.userProfile.userID, "KPI")
        .then((res) => {
          // list of depts.
          setDeptList(res);
        })
        .catch(() => {
          getMyDept();
        });
    } 
    getMyDept();
  };

  const getMyDept = () => {
    commonMethods
      .getDepartmentIDFromUserID()
      .then((res) => {
        setMyDeptId(res);
      })
      .catch((err) => {
        setIsError(true);
        setErrorMsg(commonMethods.getErrorMessage(err));
      });
  };

  const displayArrOfDepts = () => {
    let displayArr: any[] = [];
    deptList.forEach((dept) => {
      let oneDept = (
        <div className="row" key={dept.departmentID}>
          <div className="col-12 col-xl-12">
            <OneDeptScoreIndicator
              {...props}
              others={{
                departmentID: dept.departmentID,
                viewOnly: viewOnly,
                userRanks: userRanks,
                customView: true,
              }}
            />
          </div>
        </div>
      );
      displayArr.push(oneDept);
    });
    return displayArr;
  };

  let display;

  if (isError) {
    display = <CustomError message={errorMsg} />;

  } else if (hasMultiDeptView && deptList.length > 0) {
    display = displayArrOfDepts();
  } else {
    display = (
      <div className="row">
        <div className="col-12 col-xl-12">
          <OneDeptScoreIndicator
            {...props}
            others={{
              departmentID: myDeptId,
              // callbackRefresh: callbackRefresh,
              viewOnly: viewOnly,
              userRanks: userRanks,
              customView: false,
            }}
          />
        </div>
      </div>
    );
  }


  return <>{display}</>;
}

export default ViewCustomDeptScoreIndicators;
