import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { StatusService } from "../../service/StatusService";
import { RouteComponentProps, CommonMethods } from "../resources/CommonMethods";
import CustomError from "../resources/Error";

export function LeaveAppliedOneDeptView(props: RouteComponentProps<any>) {
  const statusService = new StatusService();
  const commonMethods = new CommonMethods();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>("");

  const [deptAppliedStatus, setDeptAppliedStatus] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState<any>(new Date());

  useEffect(() => {
    if (props.others !== undefined) {
      if (props.others?.admin) {
        getGroupCompanyAppliedStatus();
      } else if (props.others?.mydept) {
        getMyDeptAppliedStatus();
      } else {
        getDeptAppliedStatus(props.others.deptid);
      }
    }
  }, []);

  useEffect(() => {
    if (props.others !== undefined) {
      if (props.others?.admin) {
        getGroupCompanyAppliedStatus();
      } else if (props.others?.mydept) {
        getMyDeptAppliedStatus();
      } else {
        getDeptAppliedStatus(props.others.deptid);
      }
    }
  }, [selectedMonth]);

  const getDeptAppliedStatus = (deptid: string) => {
    statusService
      .getLeaveAppliedByDeptId(
        deptid,
        selectedMonth.getFullYear().toString(),
        (selectedMonth.getMonth() + 1).toString()
      )
      .then((res) => {
        setDeptAppliedStatus(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrMsg(commonMethods.getErrorMessage(err));
        setIsError(true);
      });
  };

  const getMyDeptAppliedStatus = () => {
    statusService
      .getDepartmentAppliedStatus(
        selectedMonth.getFullYear().toString(),
        (selectedMonth.getMonth() + 1).toString()
      )
      .then((res) => {
        setDeptAppliedStatus(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrMsg(commonMethods.getErrorMessage(err));
        setIsError(true);
      });
  };

  const getGroupCompanyAppliedStatus = () => {
    statusService
      .getGroupCompanyAppliedStatus(
        selectedMonth.getFullYear().toString(),
        (selectedMonth.getMonth() + 1).toString()
      )
      .then((res) => {
        setDeptAppliedStatus(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrMsg(commonMethods.getErrorMessage(err));
        setIsError(true);
      });
  };

  const tableHeader = () => {
    return (
      <div style={{ textAlign: "left" }}>
        <Calendar
          id="MonthCalendar"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.value)}
          view="month"
          dateFormat="M-yy"
          className="text-left"
        />
      </div>
    );
  };

  const displayApplied = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errMsg} />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            rowGroupMode="rowspan"
            groupField="fullName"
            sortField="fullName"
            sortOrder={1}
            value={deptAppliedStatus}
            autoLayout={true}
            paginator={true}
            alwaysShowPaginator={false}
            rows={20}
            emptyMessage="No results found."
            header={tableHeader()}
          >
            <Column className="p-col" field="fullName" header="Employee" />
            <Column
              className="p-col"
              field="statusTypeName"
              header="Leave Type"
            />
            <Column
              className="p-col"
              field="creditDebitValue"
              header="No. of Days"
            />
          </DataTable>
        </div>
      );
    }
  };

  return <>{displayApplied()}</>;
}
