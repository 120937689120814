import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer
      // className="fdb-block footer-large bg-dark "
      className="bg-dark"
      data-block-type="footers"
      data-id="9"
    >
      <div className="container footertext">
        <div className="row align-items-top text-center text-md-left">
          <div className="col-12 col-md-4">
            {/* <h3 className="footerwordsh3">
              <strong>Company Info</strong>
            </h3> */}

            <p>
              <i className="fa fa-map-marker"></i> 219 Kallang Bahru, #04-00{" "}
              <br /> Singapore 339348
            </p>
          </div>

          <div className="col-12 col-md-4"></div>

          <div className="col-12 col-md-4 mt-md-0 text-center text-md-right">
            <h3 className="footerwordsh3">
              <strong>NeuHR</strong>
            </h3>

            <p>
              <i className="fa fa-envelope mx-1" aria-hidden="true"></i>
              support@neuhr.com <br />
              {/* <a href="/">
                <i className="fa fa-facebook-square mx-2"></i>
              </a>
              <a href="/">
                <i className="fa fa-linkedin-square"></i>
              </a> */}
              {/* <i className="fab fa-linkedin fa-1x"></i> */}
            </p>
            {/* <h3>
              <strong>About Us</strong>
            </h3>
            <p>
              In a small corner of the TheSmartLocal Office, a team of Spoiled
              Rabbits and Smile Persons (that just wanted no trouble) lived in
              harmony. <br />
              Then, everything changed when the Pepe Nation attacked. Only the
              Yellow Duck, Master of all coding languages, could stop them.{" "}
              <br />
              But when the team needed him most, <br /> he vanished.
            </p> */}
          </div>
        </div>
        <hr style={{ backgroundColor: "white" }}></hr>
        <div className="row footerwords12">
          <div className="col-12 col-xl-4 text-center text-md-left">
            © 2020 NeuHR. All Rights Reserved
          </div>
          <div className="col-12 col-xl-2 text-center"> </div>

          <div className="col-12 col-xl-6 text-center text-xl-right">
            <Link to="/privacypolicy" target="_blank">
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link to="/terms" target="_blank">
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
