import _ from "lodash";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Redirect, useParams } from "react-router";
import DeliverableService from "../../service/DeliverableService";
import { CampaignModel } from "../campaign/Campaign";
import Board from "./Board";
import { CommonMethods, ParamIDTypes, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { TaskList } from "../resources/ListOfTasks";
import { InsightsrnMethods } from "../resources/InsightsrnMethods";

export class DeliverableModel {
  public campaignID: string = "";
  public campaignSerialNo: string = "";
  public deliverableID: string = "";
  public deliverableSerialNo: string = "";
  public deliverableName: string = "";
  public requirements: string = "";
  public deliverableStatus: string = "In Progress";
  public deliverableValue: number = 0;
  public publicationDate: Date = new Date();
  public remarks: string = "";
  public deliverableLink: string = "";
  public deliverableLinkArr: string[] = [];
  public createdTime: Date | string = "";
  public createdBy: string = CommonMethods.EmptyGuid;
  public closedDate: Date = new Date();
  public closedBy: string = CommonMethods.EmptyGuid;
  public deliverableTypeID: string = CommonMethods.EmptyGuid;
  public deliverableMembersIDStrArr: string[] = [];
  public deliverableMembersForDisplay: string[] = [];
  public currency: string = "";
  public billed: boolean = false;
  public publisherID: string = "";
  // display
  public deliverableTypeName: string = "";
  public createdByName: string = "";
  public closedByName: string = "";
  public campaignName: string = "";
  public deliverablePoint: number = 0;
  public quantity: number = 1;
  public publisherName: string = "";

  // irn
  public facebookLink: string = "";
  public link: string = "";
  public reportOrder: number = 0;
  public irnDelId: string = CommonMethods.EmptyGuid;
}

function DeliverablesInCampaign(props: RouteComponentProps<any>) {
  const deliverableService = new DeliverableService();
  const commonMethods = new CommonMethods();
  const insightsMethods = new InsightsrnMethods();
  //Growls
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  // Loading, Error, Redirect
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [redirectState, setRedirectState] = useState<any>("");
  // Dialogs
  const [dialogStr, setDialogStr] = useState(<></>);
  const [dialogHeaderStr, setDialogHeaderStr] = useState<string>("");
  const [dialogAction, setDialogAction] = useState();
  const [dialogVisibility, setDialogVisibility] = useState(false);

  const [pmoDialogStr, setPMODialogStr] = useState(<></>);
  const [pmoDialogHeaderStr, setPMODialogHeaderStr] = useState<string>("");
  const [pmoDialogVisibility, setPMODialogVisibility] = useState(false);

  const [infoVisible, setInfoVisible] = useState<boolean>(false);
  const [selectedInfoVisible, setSelectedInfoVisible] = useState<boolean>(
    false
  );

  const [selectedDeliverables, setSelectedDeliverables] = useState([]);
  const [statusToBeUpdated, setStatusToBeUpdated] = useState<string>("");
  const [deliverableList, setDeliverableList] = useState<
    Array<DeliverableModel>
  >(new Array<DeliverableModel>());
  const [deliverableObj, setDeliverableObj] = useState<DeliverableModel>(
    new DeliverableModel()
  );

  const [
    deliverableStatusDropdownOptions,
    setDeliverableStatusDropdownOptions,
  ] = useState<any[]>([]);

  const [
    deliverableStatusViewDropdownOptions,
    setDeliverableStatusViewDropdownOptions,
  ] = useState<any[]>([]);

  const [searchValue, setSearchValue] = useState<string>("");
  const [campaignID, setCampaignID] = useState<string>("");
  const [campaignData, setCampaignData] = useState(new CampaignModel());
  const [componentUpdated, setComponentUpdated] = useState(false);
  const [isFinanceView, setIsFinanceView] = useState(false);
  const [campaigninvoiceaccess] = useState(
    props.userProfile.taskList.includes(TaskList.campaigninvoiceaccess)
  );
  const [insightsrnaccess] = useState(
    props.userProfile.taskList.includes(TaskList.insightsrnaccess)
  );
  const [displayIrn, setDisplayIrn] = useState<boolean>(false);

  const [isTSL, setIsTSL] = useState(false);
  const { id } = useParams<ParamIDTypes>();

  useEffect(() => {
    if (props.others === undefined) {
      setIsError(true);
      setIsLoading(false);
      setErrorMessage("Unable to retrieve campaign details.");
      return;
    }

    commonMethods
      .isGroupCompanyTSL(props.userProfile.groupCompanyID)
      .then((res) => {
        setIsTSL(res);
      })
      .catch((err) => {
        setIsTSL(false);
      });

    getFromDB();
    setCampaignID(id || "");
    setDeliverableStatusDropdownOptions(
      commonMethods.getDeliverableStatusDropdown()
    );
    setDeliverableStatusViewDropdownOptions(
      commonMethods.getDeliverableStatusViewDropdown()
    );
  }, []);

  useEffect(() => {
    if (!componentUpdated) {
      if (commonMethods.isPropsLoaded(props)) {
        if (props.userProfile.taskList.includes("salesaccess")) {
          setIsFinanceView(true);
        }
        setComponentUpdated(true);
      }
    }
  });

  useEffect(() => {
    setDisplayIrn(insightsrnaccess && campaignData.irnCamId ? true : false);
  }, [insightsrnaccess, campaignData]);

  const getFromDB = () => {
    let campaignDataFromProps = props.others;
    setCampaignData(campaignDataFromProps);

    if (!campaignDataFromProps.hasClient && !campaignDataFromProps.hasAgent) {
      setIsFinanceView(false);
    }
    deliverableService
      .getAllDeliverablesInCampaign(campaignDataFromProps.campaignID)
      .then((res) => {
        setDeliverableList(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(commonMethods.getErrorMessage(err));
      });
  };

  const setDialog = (header: string, content: any, action: any) => {
    setDialogHeaderStr(header);
    setDialogStr(content);
    setDialogAction(action);
    setDialogVisibility(true);
  };

  const setPMODialog = (header: string, content: any) => {
	setPMODialogHeaderStr(header);
	setPMODialogStr(content);
	setPMODialogVisibility(true);
  }

  const updateDeliverableStatus = () => {
    let deliverableObjUpdated = deliverableObj;
    deliverableObjUpdated.deliverableStatus = statusToBeUpdated;
    deliverableService
      .updateDeliverableStatus(deliverableObjUpdated)
      .then((res) => {
        SetSuccess(
          deliverableObj.deliverableName +
            " is now " +
            deliverableObj.deliverableStatus
        );
        setDialogVisibility(false);
        setIsLoading(false);
        getFromDB();
      })
      .catch((err) => {
        const error = commonMethods.getErrorMessage(err);
        setIsLoading(false);
        SetError(error);
      });
  };

  const confirmDelete = () => {
    deliverableService
      .deleteDeliverableByID(deliverableObj.deliverableID)
      .then((res) => {
        SetSuccess(deliverableObj.deliverableName + " has been deleted.");
        setDialogVisibility(false);
        setIsLoading(false);
        getFromDB();
      })
      .catch((err) => {
        const error = commonMethods.getErrorMessage(err);
        setIsLoading(false);
        SetError(error);
      });
  };

  const updateDeliverableBilled = async (data: DeliverableModel) => {
    let del = data;
    del.billed = del.billed === true ? false : true;
    deliverableService
      .updateDeliverableBilledStatus(del)
      .then((res) =>
        SetSuccess(
          `${del.deliverableSerialNo}'s billed status marked as ${del.billed}`
        )
      )
      .catch((err) => setIsError(true));
  };

  const showSelectedDeliverablesDialog = (selectedDels: DeliverableModel[]) => {
    setDialogHeaderStr("Selected Deliverables");
    let jsxArray: any[] = [];
    selectedDels.forEach((element, index) => {
      jsxArray.push(
        <tr>
          <td className="font-weight-bold">{index + 1}</td>
          <td>{element.deliverableSerialNo}</td>
        </tr>
      );

      let table = (
        <>
          <table className="leave-info-dialog table">
            <tbody>{jsxArray}</tbody>
          </table>
        </>
      );

      setDialogStr(table);
      setSelectedInfoVisible(true);
    });
  };

  // const handleChange = (content: string, delta: Delta, source: Sources) => {
  //   setCampaignData({ ...campaignData, campaignContent: content });
  //   // setCampaignInvoiceData({
  //   //   ...campaignInvoiceData,
  //   //   terms: content
  //   // });
  // };

  const showInfoDialog = (deliverable: DeliverableModel) => {
    setDialogHeaderStr("Deliverable Details");

    let displayLinks: any[] = [];
    if (deliverable.deliverableLink !== "") {
      let delLinkArr = deliverable.deliverableLink.split(";");
      delLinkArr.forEach((link) => {
        displayLinks.push(
          <p className="p-0 m-0">
            <a href={link} target="_blank" rel="noopener noreferrer">
              {_.truncate(link)}
            </a>
          </p>
        );
      });
    }
    let insightsfields;
    if (displayIrn) {
      insightsfields = (
        <tr>
          <td className="font-weight-bold">Report Order:</td>
          <td>{deliverable.reportOrder}</td>
        </tr>
      );
    }
    let closedFields = (
      <>
        {/* <tr>
          <td className="font-weight-bold">Closed By:</td>
          <td>{deliverable.closedByName}</td>
        </tr> */}
        <tr>
          <td className="font-weight-bold">Published Date:</td>
          <td>
            {commonMethods.isDateStringNull(deliverable.closedDate)
              ? ""
              : commonMethods.displayDate(new Date(deliverable.closedDate))}
          </td>
        </tr>
      </>
    );

    let valueField = (
      <tr>
        <td className="font-weight-bold">Value:</td>
        <td>
          {campaignData.currency + " "}
          <NumberFormat
            displayType={"text"}
            value={deliverable.deliverableValue}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </td>
      </tr>
    );
    let html = (
      <>
        <table className="leave-info-dialog table">
          <tbody>
            <tr>
              <td className="font-weight-bold">Serial:</td>
              <td>{deliverable.deliverableSerialNo}</td>
            </tr>
            <tr>
              <td className="font-weight-bold"> Name:</td>
              <td>{deliverable.deliverableName}</td>
            </tr>
            <tr>
              <td className="font-weight-bold"> Publisher:</td>
              <td>{deliverable.publisherName}</td>
            </tr>
            <tr>
              <td className="font-weight-bold"> Type:</td>
              <td>{deliverable.deliverableTypeName}</td>
            </tr>
            <tr>
              <td className="font-weight-bold"> Status</td>
              <td>{deliverable.deliverableStatus}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Requirements:</td>
              <td>{deliverable.requirements}</td>
            </tr>
            {/* <tr>
              <td className="font-weight-bold">Remarks:</td>
              <td>{deliverable.remarks}</td>
            </tr> */}

            <tr>
              <td className="font-weight-bold">Created By:</td>
              <td>{deliverable.createdByName}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Created On:</td>
              <td>
                {commonMethods.displayDate(new Date(deliverable.createdTime))}
              </td>
            </tr>
            {deliverable.deliverableStatus === "Published" && closedFields}
            {isFinanceView && valueField}
            {/* <tr>
              <td className="font-weight-bold">Publication Date:</td>
              <td> {commonMethods.displayDate(deliverable.publicationDate)}</td>
            </tr> */}
            <tr>
              <td className="font-weight-bold">
                Deliverable Link (discontinued):
              </td>
              <td>{displayLinks}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Link:</td>
              <td>{deliverable.link}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Facebook Link:</td>
              <td>{deliverable.facebookLink}</td>
            </tr>
            {insightsfields}
            <tr>
              <td className="font-weight-bold">Members:</td>
              <td>{pmoTemplate(deliverable)}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
    setDialogStr(html);
    setInfoVisible(true);
  };

  const addToIRN = () => {
    setIsLoading(true);
    insightsMethods
      .createDeliverable(selectedDeliverables)
      .then((res) => {
        if (typeof res === "string") {
          SetSuccess("Successfully add deliverables to IRN Report.");
        } else {
          // For deliverables with no link
          if (res.error !== undefined) {
            SetError(res.error);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (typeof err === "string") {
          SetSuccess("Successfully add deliverables to IRN Report.");
        } else {
          const error = commonMethods.getErrorMessage(err);
          SetError(error);
        }
        setIsLoading(false);
      });
  };
  //    ___   _            _
  //   |   \ (_) ___ _ __ | | __ _  _  _
  //   | |) || |(_-<| '_ \| |/ _` || || |
  //   |___/ |_|/__/| .__/|_|\__,_| \_, |
  //                |_|             |__/

  const isInIrn = (rowData: DeliverableModel) => {
    if (rowData.irnDelId !== null) {
      return "Yes";
    } else {
      return "No";
    }
  };

  const pmoTemplate = (rowData: DeliverableModel) => {
    return rowData.deliverableMembersForDisplay.map(
      (item: string, index: number) => {
        if (index === rowData.deliverableMembersForDisplay.length - 1) {
          return item;
        } else {
          return item + ", ";
        }
      }
    );
  };

  const toggleBilledState = (rowData: DeliverableModel) => {
    return (
      <>
        <InputSwitch
          className="mt-2"
          tooltip={rowData.billed === true ? "True" : "False"}
          tooltipOptions={{ position: "top" }}
          checked={rowData.billed === true}
          onChange={(e) => {
            updateDeliverableBilled(rowData);
          }}
        />
      </>
    );
  };

  const generateIRNDeliverablesButton = () => {
    if (
      selectedDeliverables.length > 0 &&
      displayIrn
    ) {
      return (
        <Button
          type="button"
          icon="pi pi-chart-bar"
          iconPos="right"
          label="Add to IRN Report"
          className="irn-button ml-2"
          onClick={() => {
            // SetInfo("Coming soon! ");
            // // TODO: IRN - ADD BACK WHEN READY FOR DELIVERABLES
            addToIRN();
          }}
        />
      );
    }
  };

  const invoicePreviewButton = () => {
    if (
      isTSL &&
      campaignData.campaignTypeName === "Advertorial" &&
      selectedDeliverables.length > 0 &&
      campaigninvoiceaccess
    ) {
      return (
        <Button
          type="button"
          icon="pi pi-file"
          iconPos="right"
          label="Preview Invoice"
          className="button-document ml-2"
          onClick={() => {
            if (
              selectedDeliverables.filter((x: DeliverableModel) => {
                return x.billed === true;
              }).length > 0
            ) {
              SetError("Cannot invoice billed deliverable.");
            } else {
              showSelectedDeliverablesDialog(selectedDeliverables);
              setSelectedInfoVisible(true);
            }
          }}
        />
      );
    }
  };

  const actionTemplate = (rowData: DeliverableModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="View Deliverable"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-eye"
          className="p-button-primary mx-1"
          onClick={() => showInfoDialog(rowData)}
        />
        <Button
          tooltip="Edit Deliverable"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            setRedirectPath(
              "/deliverable/" + campaignID + "/update/" + rowData.deliverableID
            );
            setRedirectOut(true);
          }}
        />
        <Button
          tooltip="Delete Deliverable"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setDeliverableObj(rowData);
            setDialog(
              `Delete ${rowData.deliverableName}`,
              `Are you sure you want to delete this deliverable?`,
              "delete"
            );
          }}
        />
      </div>
    );
  };

  const billedTemplate = (rowData: DeliverableModel) => {
    if (rowData.billed === true) {
      return "True";
    } else if (rowData.billed === false) {
      return "False";
    } else {
      return "Error State";
    }
  };

  const statusTemplate = (rowData: DeliverableModel) => {
    return (
      <>
        <Dropdown
          tooltip="Change Deliverable Status"
          tooltipOptions={{ position: "top" }}
          value={rowData.deliverableStatus}
          appendTo={document.body}
          options={deliverableStatusDropdownOptions}
          onChange={(e) => {
            setDeliverableObj(rowData);
            setStatusToBeUpdated(e.value);
			{(e.value == "Published" && campaignData.campaignProjectManagersForDisplay.length == 0) ?
				setPMODialog(
					"No Project Managers",
					"Deliverable cannot be published without project managers assigned to the campaign"
				)
				:
				setDialog(
					"Update Status",
					`Update deliverable status to ` + e.value + "?",
					"update"
				);
			}
          }}
          placeholder="Please Select"
          // overlaypanel={true}
        />
      </>
    );
  };

  let tableheader = (
    <div style={{ textAlign: "left" }}>
      <Dropdown
        className="mb-1 mx-1 "
        value={searchValue}
        options={deliverableStatusViewDropdownOptions}
        onChange={(e) => setSearchValue(e.value)}
        placeholder="All"
      />
    </div>
  );

  let page = (
    <div className="datatable-centerHeader datatable-centerContent">
      <DataTable
        value={deliverableList}
        header={tableheader}
        globalFilter={searchValue}
        paginator={true}
        rows={15}
        autoLayout={true}
        alwaysShowPaginator={false}
        emptyMessage="No deliverables found."
        selection={selectedDeliverables}
        onSelectionChange={(e) => {
          setSelectedDeliverables(e.value);
        }}
      >
        <Column
          selectionMode="multiple"
          className={
            isFinanceView || displayIrn ? "p-col-1 text-center" : "d-none"
          }
          style={{ width: "3em" }}
        />

        <Column field="deliverableSerialNo" header="Serial" />
        <Column field="deliverableName" header="Name" />
        <Column field="publisherName" header="Publisher" />
        <Column field="deliverableTypeName" header="Type" />
        {/* <Column field="reportOrder" header="Report Order" /> */}
        {displayIrn && <Column body={isInIrn} header="In IRN" />}
        <Column body={pmoTemplate} header="Members" className="p-col-3" />
        <Column body={actionTemplate} header="Actions" />
        {isFinanceView && (
          <Column body={billedTemplate} header="Billed" className="pr-0" />
        )}
        {isFinanceView && (
          <Column body={toggleBilledState} header="" className="pl-0" />
        )}
        <Column field="deliverableStatus" className="d-none" />
        <Column body={statusTemplate} header="Status" />
      </DataTable>
    </div>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  // const onDragEnd = (result: any) => {
  //   const { source, destination, draggableId } = result;

  //   // Do nothing if item is dropped outside the list
  //   if (!destination) {
  //     return;
  //   }
  //   // Do nothing if the item is dropped into the same place
  //   if (
  //     destination.droppableId === source.droppableId &&
  //     destination.index === source.index
  //   ) {
  //     return;
  //   }
  // };

  return (
    <div className="row">
      {redirectOut ? (
        <Redirect
          push
          to={{
            pathname: redirectPath,
            state: redirectState,
          }}
        />
      ) : null}

      <Dialog
        header={dialogHeaderStr}
        visible={infoVisible}
        modal={true}
        //dismissableMask={true}
        onHide={() => setInfoVisible(false)}
      >
        {dialogStr}
      </Dialog>
      <Dialog
        className="popupDialog"
        header={dialogHeaderStr}
        visible={selectedInfoVisible}
        modal={true}
        //dismissableMask={true}
        onHide={() => setSelectedInfoVisible(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                if (campaignData.clientCompanyID !== null) {
                  setIsLoading(true);
                  setRedirectPath("/campaign/invoice/" + id);
                  setRedirectState(selectedDeliverables);
                  setRedirectOut(true);
                } else {
                  SetError("Campaign is not associated with any clients");
                }
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setSelectedInfoVisible(false);
              }}
            />
          </div>
        }
      >
        <ScrollPanel style={{ height: "300px" }}>
          <div>{dialogStr}</div>
        </ScrollPanel>
      </Dialog>

      <Dialog
        className="popupDialog"
        header={dialogHeaderStr}
        visible={dialogVisibility}
        modal={true}
        //dismissableMask={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                setIsLoading(true);
                dialogAction === "delete" && confirmDelete();
                dialogAction === "update" && updateDeliverableStatus();
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>

	  <Dialog
        className="popupDialog"
        header={pmoDialogHeaderStr}
        visible={pmoDialogVisibility}
        modal={true}
        //dismissableMask={true}
        onHide={() => setPMODialogVisibility(false)}
      >
        {pmoDialogStr}
      </Dialog>


      {/***
       *      _____       _     _             _
       *     |_   _|__ _ | |__ | | ___  __ __(_) ___ __ __ __
       *       | | / _` || '_ \| |/ -_) \ V /| |/ -_)\ V  V /
       *       |_| \__,_||_.__/|_|\___|  \_/ |_|\___| \_/\_/
       *
       */}
      <div
        className="col-12"
        data-tour-id="campaign-deliverables"
        data-tour="These are the deliverables in this campaign."
      >
        <div className="card">
          <h1 className="pb-2">
            Deliverables
            <span className="pl-3">
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={() => {
                  setRedirectPath("/deliverable/" + campaignID + "/create");
                  setRedirectOut(true);
                }}
              />
              {invoicePreviewButton()}
              {generateIRNDeliverablesButton()}
            </span>
          </h1>
          {display}
        </div>
      </div>

      {/***
       *      _____           _  _        _                        _
       *     |_   _|_ _  ___ | || | ___  | |__  ___  __ _  _ _  __| |
       *       | | | '_|/ -_)| || |/ _ \ | '_ \/ _ \/ _` || '_|/ _` |
       *       |_| |_|  \___||_||_|\___/ |_.__/\___/\__,_||_|  \__,_|
       *
       */}

      <div
        className="col-12"
        data-tour-id="campaign-board"
        data-tour="Clear overview of the deliverable and their statuses."
      >
        <div className="card pb-2">
          <h1 className="pb-2">
            Deliverables - Card view
            {/* <span className="pl-3">
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={() => {
                  setRedirectPath("/deliverable/" + campaignID + "/create");
                  setRedirectOut(true);
                }}
              />
            </span> */}
          </h1>

          <Board {...props} others={deliverableList} />
        </div>
      </div>
    </div>
  );
}

export default DeliverablesInCampaign;
