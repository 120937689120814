import { PDFExport } from "@progress/kendo-react-pdf";
import _ from "lodash";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Redirect, useLocation } from "react-router";
import CampaignInvoiceService from "../../service/CampaignInvoiceService";
import { DeliverableModel } from "../deliverable/DeliverablesInCampaign";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { CampaignInvoiceModel } from "./CampaignInvoice";

let commonMethods = new CommonMethods();
let campaignInvoiceService = new CampaignInvoiceService();
let pdfComponent: PDFExport | null = null;
let pdfComponentForUser: PDFExport | null = null;

function CampaignInvoiceExport(props: RouteComponentProps<any>) {
  // let params: { id?: string } = useParams();
  let location = useLocation<{ state: any }>();

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { SetSuccess } = React.useContext(ToastStateContext);

  // const [campaignData, setCampaignData] = useState(new CampaignModel())

  const [campaignInvoiceData, setCampaignInvoiceData] = useState<
    CampaignInvoiceModel
  >(new CampaignInvoiceModel());

  const [terms, setTerms] = useState<JSX.Element>();

  const [deliverables, setDeliverables] = useState<Array<DeliverableModel>>([]);
  const [sortedDeliverables, setSortedDeliverables] = useState(<></>);

  useEffect(() => {
    if (commonMethods.checkLocState(props)) {
      var data: CampaignInvoiceModel | any = location.state;
      setCampaignInvoiceData(data);
      setDeliverables(data.deliverablesArray);
      setIsLoading(false);
      setTerms(
        <ReactQuill
          value={data.terms}
          modules={{ toolbar: false }}
          readOnly={true}
          theme="bubble"
        />
      );
    }
  }, []);

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMsg} />;
    } else {
      return generalDetails();
    }
  };

  const prepareExport = () => {
    campaignInvoiceService
      .logInvoiceDocument(campaignInvoiceData)
      .then(res => {
        // This copy is to save to server
        if (pdfComponent !== null) {
          pdfComponent.save(() => {
            SetSuccess("Success!!!!");
            // Below doesn't work, dont know why. Put here for future use
            // Using growl instead
            // return <Redirect push to={{ pathname: "/finance/payroll" }} />;
          });
        }
        // This copy is to download to client
        if (pdfComponentForUser !== null) {
          pdfComponentForUser.save();
        }
      })
      .catch(err => {
        let errMsg = commonMethods.getErrorMessage(err);
        setErrorMessage(errMsg);
        setIsError(true);
      });
  };

  const displaySerialNumbers = () => {
    return campaignInvoiceData.invoiceSerialNo.map(num => (
      <>
        <span> {num}</span> <br></br>{" "}
      </>
    ));
  };

  useEffect(() => {
    var groupedById = _.groupBy(deliverables, "deliverableTypeID");
    let delTable: any[] = [];
    let index = 1;
    for (var key in groupedById) {
      let delList = groupedById[key];
      let firstItem: DeliverableModel = delList[0];

      let row = (
        <tr key={key} className="invoicedeltable">
          <td className="text-left">
            {index}) {firstItem.deliverableTypeName}
          </td>
          <td>{delList.length}</td>
        </tr>
      );
      delTable.push(row);
      index++;
    }

    let delTableDisplay = (
      <>
        <table id="tabledel" key="tabledel">
          <tbody>
            <tr className="text-center">
              <td>
                <strong>Deliverable</strong>
              </td>
              <td>
                <strong>Quantity</strong>
              </td>
            </tr>
            {delTable}
          </tbody>
        </table>
      </>
    );
    setSortedDeliverables(delTableDisplay);
  }, [deliverables]);

  const generalDetails = () => {
    return (
      <>
        <div className="row p-4">
          <div className="col-12">
            <div className="row pb-3">
              <div className="col-4 justify-content-start">
                <img
                  src={campaignInvoiceData.companyLogo}
                  alt={campaignInvoiceData.companyLogo}
                  style={{ maxWidth: "5rem" }}
                />
              </div>
              <div className="col-4"></div>
              <div className="col-4">
                {campaignInvoiceData.companyName} <br></br>
                {campaignInvoiceData.companyAddress}
                {"  S"}
                {campaignInvoiceData.postalCode}
              </div>
            </div>
          </div>

          <div className="col-7">
            <div className="row pb-3">
              <div className="col-12">
                <strong>
                  <u>Official Quotation</u>
                </strong>
              </div>
              <div className="col-5">
                {/* <strong>Company:</strong> */}
                Company:
              </div>
              <div className="col-7">
                {" "}
                {campaignInvoiceData.clientCompanyName}
              </div>
              <div className="col-5">
                UEN:
                {/* <strong>UEN:</strong> */}
              </div>
              <div className="col-7">{campaignInvoiceData.companyRegNum}</div>
              <div className="col-5">
                Servicing Person:
                {/* <strong>Servicing Person:</strong> */}
              </div>
              <div className="col-7">{campaignInvoiceData.salesPerson}</div>
              <div className="col-5">
                Date:
                {/* <strong>Date:</strong> */}
              </div>
              <div className="col-7">
                {commonMethods.displayDate(
                  new Date(campaignInvoiceData.invoiceDate)
                )}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-5">
                Attention (Advertiser):
                {/* <strong>Attention (Advertiser):</strong> */}
              </div>
              <div className="col-7">
                {campaignInvoiceData.clientCompanyName}
              </div>

              <div className="col-5">
                Client's Address:
                {/* <strong>Client's Address:</strong>{" "} */}
              </div>
              <div className="col-7">
                {campaignInvoiceData.clientBillingAddress}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-7">
                Customer's billing entity is overseas:
                {/* <strong>Customer's billing entity is overseas:</strong>{" "} */}
              </div>
              <div className="col-5">
                {campaignInvoiceData.isOverseasBilling ? "Yes" : "No"}
              </div>
              <div className="col-5">
                Client Refs (if any):
                {/* <strong>Client Refs (if any):</strong>{" "} */}
              </div>
              <div className="col-7">{campaignInvoiceData.clientRef}</div>
            </div>
          </div>

          <div className="col-5">
            <div className="col-12 text-center">{displaySerialNumbers()}</div>
          </div>
          <div className="col-12">
            <div className="col-12">
              <hr />
            </div>

            <div className="row pb-3">
              <div className="col-3">
                <strong>Campaign Name:</strong>
              </div>

              <div className="col-9">{campaignInvoiceData.campaignName}</div>
            </div>
            <div className="row pb-4">
              <div className="col-3">
                <strong>Media Buy Details</strong>
              </div>

              <div className="col-9">{campaignInvoiceData.mediaBuyDetails}</div>
              <div className="col-3">
                <strong>Deliverables:</strong>
              </div>
              <div className="col-12 d-flex  justify-content-center">
                {sortedDeliverables}
              </div>
            </div>

            <div className="row pb-3">
              <div className="col-3">Total Investment:</div>
              <div className="col-9">
                {campaignInvoiceData.currency} $
                {campaignInvoiceData.subtotal.toFixed(2)}
              </div>
              {/* <div className="col-7">
                <strong>
                  TAX (
                  {(
                    campaignInvoiceData.gstValue / campaignInvoiceData.subtotal
                  ).toFixed(2)}
                  %):
                </strong>
              </div>
              <div className="col-7">
                {campaignInvoiceData.currency} {campaignInvoiceData.gstValue}
              </div> */}

              <div className="col-3">Amount w/ TAX:</div>
              <div className="col-9">
                {campaignInvoiceData.currency} $
                {(
                  campaignInvoiceData.subtotal + campaignInvoiceData.gstValue
                ).toFixed(2)}
              </div>
            </div>

            <div className="row pb-3">
              <div className="col-3">Payment Terms:</div>

              <div className="col-9">
                {campaignInvoiceData.paymentTermsName}
              </div>
              <div className="col-3"></div>
              <div className="col-9">
                {campaignInvoiceData.paymentTermsDescription}
              </div>
              <div className="col-12">
                <p>
                  {" "}
                  Should there be any variation, amendment or postponing to the
                  advertising commitment, the terms of payment will nevertheless
                  remain in full force.
                </p>

                <p>
                  I agree to the terms of this contract and have read and
                  understood the Terms and Conditions on Page 2
                </p>
              </div>
            </div>

            <div className="row pt-4 pb-4">
              <div className="col-4">
                <span style={{ verticalAlign: "bottom" }}>
                  <hr></hr>
                  Signature, Name, Position Held
                </span>
              </div>
              <div className="col-4"></div>{" "}
              <div className="col-4">
                Prepared by: <br></br>
                <strong>{campaignInvoiceData.salesPerson}</strong> <br></br>
                {campaignInvoiceData.salesPersonJobTitle} <br></br>
                {campaignInvoiceData.companyName} <br></br>
                {campaignInvoiceData.companyAddress}
                {"  S"}
                {campaignInvoiceData.postalCode}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <div>
          {redirectOut ? (
            <Redirect push to={{ pathname: redirectPath }} />
          ) : null}
          <div className="p-grid p-2">
            <Button
              label="Export"
              className="mx-1"
              onClick={() => {
                prepareExport();
              }}
            />
            <Button
              label="Back"
              className="mx-1 p-button-secondary"
              onClick={() => {
                setRedirectPath(
                  "/campaign/summary/" + campaignInvoiceData.campaignID
                );
                setRedirectOut(true);
              }}
            />
          </div>
          <PDFExport
            paperSize={"A4"}
            title=""
            subject=""
            keywords=""
            forcePageBreak=".page-break"
            // forceProxy={true}
            // proxyURL="/api/SavePDF/CampaignInvoice"
            ref={r => (pdfComponentForUser = r)}
            fileName={campaignInvoiceData.campaignID}
          >
            <div
              style={{
                height: 842,
                width: 595,

                backgroundColor: "white",
                boxShadow: "5px 5px 5px black",
                margin: "auto",
                // overflowX: "hidden",
                // overflowY: "hidden",
               fontSize: "xx-small",
                fontFamily: "Nunito"
              }}
            >
              {display()}
            </div>
            <br />
            <div
              style={{
                height: 842,
                width: 595,
                padding: "10px",
                backgroundColor: "white",
                boxShadow: "5px 5px 5px black",
                margin: "auto",
                // overflowX: "hidden",
                // overflowY: "hidden",
               fontSize: "xx-small",
                fontFamily: "Nunito"
              }}
            >
              <div className="termsandcond">{terms}</div>
            </div>
            <br />
            <div
              style={{
                height: 842,
                width: 595,
                padding: "10px",
                backgroundColor: "white",
                boxShadow: "5px 5px 5px black",
                margin: "auto",
                // overflowX: "hidden",
                // overflowY: "hidden",
               fontSize: "xx-small",
                fontFamily: "Nunito"
              }}
            ></div>
          </PDFExport>

          <div style={{ position: "absolute", left: "-1000px", top: 0 }}>
            <PDFExport
              paperSize={"A4"}
              title=""
              subject=""
              keywords=""
              forcePageBreak=".page-break"
              forceProxy={true}
              proxyURL="/api/SavePDF/CampaignInvoice"
              proxyTarget="_blank"
              ref={r => (pdfComponent = r)}
              fileName={campaignInvoiceData.campaignID}
            >
              <div
                style={{
                  height: 842,
                  width: 595,
                  padding: "10px",
                  backgroundColor: "white",
                  boxShadow: "5px 5px 5px black",
                  margin: "auto",
                  // overflowX: "hidden",
                  // overflowY: "hidden",
                 fontSize: "xx-small",
                  fontFamily: "Nunito"
                }}
              >
                {display()}
              </div>
              <br />
              <div
                style={{
                  height: 842,
                  width: 595,
                  padding: "10px",
                  backgroundColor: "white",
                  boxShadow: "5px 5px 5px black",
                  margin: "auto",
                  // overflowX: "hidden",
                  // overflowY: "hidden",
                 fontSize: "xx-small",
                  fontFamily: "Nunito"
                }}
              >
                <div className="termsandcond">{terms}</div>
              </div>
            </PDFExport>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignInvoiceExport;
