import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import CustomError from "../../resources/Error";
import ReactTooltip from "react-tooltip";
import { UserTargetModel } from "./KPIModels";

export default function ProrateUserTarget(props: any) {
  const [isError, setIsError] = useState(false);
  const [errorMsg] = useState<string>("An error has occurred");
  const [isLoading, setIsLoading] = useState(true);
  const [userTargetModel, setUserTargetModel] = useState<UserTargetModel>(
    new UserTargetModel()
  );

  useEffect(() => {
    if (props.others === undefined) {
      setIsError(true);
    } else {
      if (props.others.userTargetModel !== undefined && props.others.userTargetModel.userID !== "") {
        setUserTargetModel(props.others.userTargetModel);
      }
      setIsLoading(false);
    }
  }, [props.others]);

  let display = (
    <>
      <div data-for="calculation-kpi" data-tip="">
        <b>Target calculation:</b>
        <br></br>
        <span>
          Leave applied: {userTargetModel.leaveDays} day(s){" "}
        </span>
        <br></br>
        <span> Public holidays: {userTargetModel.holidayDays} day(s)</span>
        <br></br>
        <span>
          {" "}
          New target: {userTargetModel.targetPoints}
        </span>
        <br></br>
      </div>
      <ReactTooltip
          id="calculation-kpi"
          aria-haspopup="true"
          
          className="tooltipFont"
        >
          <p className="text-center mb-0">
            Doesn't look right? Click on Prorate Target to recalculate.
          </p>
        </ReactTooltip>
    </>
  );
  let page;
  if (isError) {
    page = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    page = <ProgressSpinner />;
  } else {
    page = display;
  }

  return page;
}
