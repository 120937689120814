import { MentionData } from '@draft-js-plugins/mention'
import React, { useEffect, useState } from 'react'
import { toTitleCase } from '../../CompanyFeedFunctions'
const placeholder = require('../../Assets/profileplaceholder.jpg')

export default function MentionProfilePreview({ mention, className }: { mention: MentionData; className?: string }) {
    function handleError(e: React.SyntheticEvent<HTMLImageElement, Event>) {
        e.currentTarget.src = placeholder
    }

    function Picture({ mention }: { mention: MentionData }) {
        return (
            <img
                className="profile-preview-picture"
                src={mention?.displayPicture ?? placeholder}
                alt=""
                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
            />
        )
    }

    function User({ mention }: { mention: MentionData }) {
        return (
            <div className="text-bold user-description">
                <div>
                    {toTitleCase(mention?.firstName) + ' ' + toTitleCase(mention?.lastName)}
                </div>
                <div className="user-jobtitle">
                    {mention?.jobTitle + ' · ' + mention?.userRankName}
                </div>
                <div className="user-companyname">
                    {mention?.companyName}
                </div>
            </div>
        )
    }
    
    return (
        <div className={className}>
            <div
                className="mention-profile-preview-container"
            >
                <div className="profile-preview">
                    <div><Picture mention={mention} /></div>
                    <div><User mention={mention} /></div>
                </div>
            </div>
        </div>
    )
}
