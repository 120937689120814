import React, { useState } from "react";
import { RouteComponentProps } from "../resources/CommonMethods";
import ClientCompanySummary from "./ClientCompanySummary";
import ClientContacts from "./ClientContacts";
import SalesClientsDetails from "../sales/SalesClientsDetails";
import ClientCampaigns from "../client/ClientCampaigns";

function ClientContactInCompany(props: RouteComponentProps<any>) {
  const [hasCampaignAccess] = useState<boolean>(
    props.userProfile.taskList.includes("campaignaccess") ||
      props.userProfile.taskList.includes("campaignadminaccess")
  );

  return (
    <>
      <ClientCompanySummary {...props}></ClientCompanySummary>

      <ClientContacts {...props}></ClientContacts>

      <SalesClientsDetails {...props}></SalesClientsDetails>

      {hasCampaignAccess && <ClientCampaigns {...props} />}
    </>
  );
}

export default ClientContactInCompany;
