import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { StatusRecordDisplayObj } from "./LeaveApplicationForm";

function ApproverHistory(props: RouteComponentProps) {
  const commonMethods = new CommonMethods();
  const [deptDropdown, setDeptDropdown] = useState<any[]>([]);
  const [infoVisible, setInfoVisible] = useState<boolean>(false);
  const [dialogStr, setDialogStr] = useState(<></>);
  const [headerStr, setHeaderStr] = useState<string>("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [deptFilter, setDeptFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState<string | Date>(new Date());
  const [isError, setIsError] = useState(false);
  const dt = useRef<any>(null);

  useEffect(() => {
    getDepartmentDropdown();
  }, []);

  const getDepartmentDropdown = () => {
    commonMethods
      .getDepartmentDropdownListForFiltering(props.userProfile.companyID || "")
      .then((res) => {
        setDeptDropdown(res);
      })
      .catch((err) => {
        // let errorMsg = commonMethods.getErrorMessage(err);
        setIsError(true);
      });
  };

  const appliedDateTemplate = (rowData: any) => {
    return commonMethods.displayDate(new Date(rowData.createdTime + "Z"));
  };

  const approvedDateTemplate = (rowData: any) => {
    return commonMethods.displayDate(rowData.lastUpdated);
  };

  const durationTemplate = (rowData: StatusRecordDisplayObj) => {
    let display = commonMethods.convertStartEndDateForStartEndDayDateDurationDisplay(
      rowData.statusTypeName,
      rowData.startDate,
      rowData.endDate,
      rowData.duration
    );

    return <>{display}</>;
  };

  const viewStatusTemplate = (rowData: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Leave Details"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-eye"
          type="button"
          className="p-button-info mx-1"
          onClick={() => {
            showInfoDialog(rowData);
          }}
        />
        <Button
          tooltip="View Documents"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-folder"
          type="button"
          disabled={rowData.uploadPath === null}
          className="button-document"
          onClick={() => {
            window.open(rowData.uploadPath, "_blank");
          }}
        />
      </div>
    );
  };

  const departmentFilter = (
    <Dropdown
      showClear
      className="mb-1 mr-1"
      value={deptFilter}
      options={deptDropdown}
      placeholder="Sort By Department"
      onChange={(e) => {
        setDeptFilter(e.value);
        onDepartmentChange(e);
      }}
    />
  );

  const onDepartmentChange = ((e:any) => {
    dt.current.filter(e.value, 'departmentName', 'equals')
  });

  const calendarFilter = (
    <Calendar
      yearNavigator
      yearRange="2020:2023"
      id="MonthCalendar"
      tooltip="Filter by Approved Date"
      tooltipOptions={{ position: "top" }}
      view="month"
      style={{ width: 200 }}
      readOnlyInput={true}
      dateFormat="M-yy"
      // maxDate={new Date()}
      showButtonBar={true}
      todayButtonClassName="d-none"
      value={new Date(dateFilter.toString())}
      onChange={(e) => {
        if (e.value === null) {
          setGlobalFilter("");
          setDateFilter(new Date());
        } else {
          // setGlobalFilter(
          //   new Date(
          //     new Date(e.value.toString()).getTime() -
          //       new Date().getTimezoneOffset() * 60 * 1000
          //   )
          //     .toISOString()
          //     .substr(0, 7)
          //     .replace("T", " ")
          // );
          console.log(moment(e.value.toString()).format("YYYY-MM"))
          setGlobalFilter(moment(e.value.toString()).format("YYYY-MM"));
          setDateFilter(new Date(e.value.toString()));
        }
      }}
    />
  );

  const header = () => {
    return (
      <div style={{ textAlign: "left" }}>
        <span className="p-input-icon-left mr-1 mb-1">
          <i className="pi pi-search" />
          <InputText
            placeholder="Global Search"
            onInput={(e) => {
              setGlobalFilter(e.currentTarget.value);
            }}
          />
        </span>
        {departmentFilter}
        {calendarFilter}
      </div>
    );
  };

  const showInfoDialog = (e: any) => {
    setHeaderStr("Leave Details");
    let html = (
      <>
        <table className="leave-info-dialog table">
          <tbody>
            <tr>
              <td className="font-weight-bold">Leave Type:</td>
              <td>{e.statusTypeName}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Status:</td>
              <td>
                {e.approvalStatus} on {approvedDateTemplate(e)} <br></br>by{" "}
                {e.updaterName}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">Applied By:</td>
              <td>
                {e.approvingRemarks === "Applied on behalf"
                  ? e.updaterName
                  : e.fullDisplayName}{" "}
                on {appliedDateTemplate(e)}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">Start Date:</td>
              <td>
                {commonMethods.convertStartEndDateForDayDateDurationDisplay(
                  e.statusTypeName,
                  e.startDate,
                  e.endDate,
                  e.duration,
                  "start"
                )}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">End Date:</td>
              <td>
                {commonMethods.convertStartEndDateForDayDateDurationDisplay(
                  e.statusTypeName,
                  e.startDate,
                  e.endDate,
                  e.duration,
                  "end"
                )}
              </td>
            </tr>

            <tr>
              <td className="font-weight-bold">Value:</td>
              <td>{e.creditDebitValue}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Remarks:</td>
              <td>{e.remarks}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Approval Remarks:</td>
              <td>{e.approvingRemarks}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
    setDialogStr(html);
    setInfoVisible(true);
  };

  const statusTemplate = (rowData: any) => {
    if (rowData.approvalStatus === "Rejected") {
      return (
        <div className="text-danger boldLabel">{rowData.approvalStatus}</div>
      );
    } else if (rowData.approvalStatus === "Approved") {
      return (
        <div className="text-success boldLabel">{rowData.approvalStatus}</div>
      );
    } else if (rowData.approvalStatus.includes("Pending") || rowData.approvalStatus.includes("Pending Cancellation")) {
      return (
        <div className="text-info boldLabel">{rowData.approvalStatus}</div>
      );
    } else {
      return <div className="boldLabel">{rowData.approvalStatus}</div>;
    }
  };
  if (props.others.isError || isError) {
    return <CustomError message={"An error has occurred"} />;
  } else {
    return (
      <div>
        <Dialog
          //dismissableMask={true}
          header={headerStr}
          visible={infoVisible}
          modal={true}
          onHide={() => setInfoVisible(false)}
        >
          {dialogStr}
        </Dialog>
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            ref={dt}
            header={header()}
            value={props.others.tableData}
            paginator={true}
            alwaysShowPaginator={false}
            globalFilter={globalFilter}
            rows={10}
            autoLayout={true}
            emptyMessage="No records found."
          >
            <Column field="fullDisplayName" header="User" />
            <Column field="departmentName" header="Department" filterElement={departmentFilter} />
            <Column field="statusTypeName" header="Leave Type" />
            <Column body={statusTemplate} header="Status" />

            <Column
              body={durationTemplate}
              field="startDate"
              header="Duration"
              sortable
            />
            {/* <Column body={approvedDateTemplate} header="Approved" /> */}
            <Column field="updaterName" header="Approver" />
            <Column field="lastUpdated" header="Approved" className="d-none" />
            <Column body={viewStatusTemplate} header="Action" />
          </DataTable>
        </div>
      </div>
    );
  }
}

export default ApproverHistory;
