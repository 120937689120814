export enum ClaimStatuses {
  Pending = "Pending",
  Endorsed = "Endorsed",
  Approved = "Approved",
  Processed = "Processed",
  Rejected = "Rejected",
  Cancelled = "Cancelled"
}

export enum ClaimViewType {
    MyPending,
    MyHistory,
    Endorsing,
    EndorsingHistory,
    Approving,
    ApprovingHistory,
    AllHistory
}

export enum ClaimActionType {
  Approve = "Approve",
  Endorse = "Endorse",
  Reject = "Reject",
  Cancel = "Cancel"
}

export class ClaimsModel {
  public claimID: string | null = null;
  public claimSerialNo: string = "";
  public claimURL: string = "";
  public currency: string = "";
  public claimStatus: string = "";
  public amount: number = 0;
  public taxAmount: number = 0;
  public receiptPath: string = "";
  public receiptPathFile: (File | null)[] | undefined | null = null;
  public receiptDate: Date = new Date();

  public createdDate: Date = new Date();
  public costTypeName: string = "";
  public campaignName: string = "";
  public submittedByName: string = "";
  public approvedBy: string = "";

  public approvedByName: string = "";
  public approvedDate: Date = new Date();
  public approvingRemarks: string = "";
  public endorsedBy: string = "";
  public endorsedByName: string = "";
  public endorsedDate: Date = new Date();
  public endorsedRemarks: string = "";
  public processedBy: string = "";
  public processedByName: string = "";
  public processedDate: Date = new Date();
  public processedRemarks: string = "";
  public cancelledDate: Date = new Date();
  public cancelledRemarks: string = "";
  public remarks: string = "";
  public paymentReference: string = "";
  public accountingCodeID: string = "";
  public submittedBy: string = "";

  public campaignTypeName: string = "";
  public campaignType: string | null = null;
  public claimTypeID: string = "";
  public campaignID: string = "";
  public companyID: string = "";
  public companyName: string = "";

  public lastUpdatedDate: Date = new Date();
}

export class ClaimsDisplayModel {
    public claimID: string | null = null;
    public claimSerialNo: string = "";
    public claimURL: string = "";
    public currency: string = "";
    public claimStatus: string = "";
    public amount: number = 0;
    public taxAmount: number = 0;
    public receiptPath: string = "";
    public receiptPathFile: (File | null)[] | undefined | null = null;
    public receiptDate: Date = new Date();

    public createdDate: Date = new Date();
    public costTypeName: string = "";
    public campaignName: string = "";
    public submittedByName: string = "";
    public approvedBy: string = "";

    public approvedByName: string = "";
    public approvedDate: Date = new Date();
    public approvingRemarks: string = "";
    public endorsedBy: string = "";
    public endorsedByName: string = "";
    public endorsedDate: Date = new Date();
    public endorsedRemarks: string = "";
    public processedBy: string = "";
    public processedByName: string = "";
    public processedDate: Date = new Date();
    public processedRemarks: string = "";
    public cancelledDate: Date = new Date();
    public cancelledRemarks: string = "";
    public remarks: string = "";
    public paymentReference: string = "";
    public accountingCodeID: string = "";
    public submittedBy: string = "";

    public campaignTypeName: string = "";
    public campaignType: string | null = null;
    public claimTypeID: string = "";
    public campaignID: string = "";
    public companyID: string = "";
    public companyName: string = "";
	public budgetingGroup: string = "";

    public lastUpdatedDate: Date = new Date();


    public bank_PayeeName: string = "";
    public bank_Number: string = "";
    public swiftCode: string = "";

  }
