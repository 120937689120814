import React, { useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import { ImageFile } from '../../../Model/PostModel'
import placeholder from '../../../Assets/missing-image.jpg'

export default function DragAndDropUpload({
    images,
    setImages,
    imagePathArray,
    setImagePathArray,
    setIsShowDialog,
    setIsShowEditDialog,
    setIsShowImagesDialog,
}: {
    images: File[] | null;
    setImages: React.Dispatch<React.SetStateAction<File[] | null>>;
    imagePathArray: string[] | null;
    setImagePathArray: React.Dispatch<React.SetStateAction<string[] | null>>;
    setIsShowDialog?: React.Dispatch<React.SetStateAction<boolean>>;
    setIsShowEditDialog?: React.Dispatch<React.SetStateAction<boolean>>;
    setIsShowImagesDialog?: React.Dispatch<React.SetStateAction<boolean>>;

}) {
    const dropZoneRef = useRef(null)
    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        onDrop,
        accept: 'image/*',
        noClick: true
    })

    // useEffect(() => {
    //     console.log('imagePathArray', imagePathArray)
    // }, [imagePathArray])

    function onDrop(acceptedFiles: File[]) {
        let imageArray: ImageFile[] = []
        let imagePaths: string[] = []
        for (let i = 0; i < acceptedFiles.length; i++) {
            const image: ImageFile = acceptedFiles[i]
            image.objectURL = URL.createObjectURL(image)
            imageArray.push(image)
            imagePaths.push(image.objectURL);
        }
        if (images && imagePathArray) {
            console.log('if images && imagePathArray')
            setImages([...images, ...imageArray])
            setImagePathArray([...imagePathArray, ...imagePaths])
        } else if (imagePathArray) {
            console.log('else if imagePathArray')
            setImages(imageArray)
            setImagePathArray([...imagePathArray, ...imagePaths])
        } else {
            console.log('else')
            setImages(imageArray)
            setImagePathArray(imagePaths)
        }
    }

    function clearImages(e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) {
        e.stopPropagation()
        setImages(null)
        setImagePathArray(null)
    }

    function viewImages(e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) {
        console.log('clicked viewImages')
        e.stopPropagation()
        e.preventDefault()
        if (setIsShowDialog) setIsShowDialog(false)
        if (setIsShowEditDialog) setIsShowEditDialog(false)
        if (setIsShowImagesDialog) setIsShowImagesDialog(true)
    }

    return (
        <div className="drag-drop-upload">
            <div
                ref={dropZoneRef}
                className="width-height-inherit outline-none"
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <div className="width-height-inherit position-relative outline-none">
                    {imagePathArray && imagePathArray.length > 0 ?
                        <>
                            <button
                                className="image-upload-button clear-images"
                                onClick={(e) => clearImages(e)}
                            >
                                <span className="material-icons material-icon-align">delete</span>
                                <span className="image-upload-button-text">Clear</span>
                            </button>
                            <button
                                className="image-upload-button view-all-images"
                                onClick={(e) => viewImages(e)}
                            >
                                <span className="material-icons material-icon-align">edit</span>
                                <span className="image-upload-button-text">Edit All</span>
                            </button>
                        </>
                        : null
                    }
                    {imagePathArray && imagePathArray?.length > 0 && isDragActive ?
                        <>
                            <ImagesDropped imagePathArray={imagePathArray} />
                            <div className="dropzone-with-image">
                                <div className="drag-active">
                                    <span>Drop images here</span>
                                </div>
                            </div>
                        </>
                        :
                        <ImagesDropped imagePathArray={imagePathArray} />
                    }
                    <div className="dropzone">
                        {isDragActive && imagePathArray === null ?
                            <div className="drag-active">
                                <span>Drop images here</span>
                            </div>
                            :
                            <>{imagePathArray
                                ? null
                                :
                                <div className="drag-inactive">
                                    <span>Drag and drop images here</span>
                                </div>
                            }</>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function ImagesDropped({ imagePathArray }: { imagePathArray: string[] | null, style?: React.CSSProperties }) {
    if (imagePathArray) {
        if (imagePathArray.length === 1) {
            return (
                <div className="images-dropped">
                    <img
                        className="img-1"
                        src={imagePathArray[0]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                </div>
            )
        } else if (imagePathArray.length === 2) {
            return (
                <div className="dropzone-image-grid images-dropped">
                    <img
                        className="img-2 img-2-1"
                        src={imagePathArray[0]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                    <img
                        className="img-2 img-2-2"
                        src={imagePathArray[1]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                </div>
            )
        } else if (imagePathArray.length === 3) {
            return (
                <div className="dropzone-image-grid images-dropped">
                    <img
                        className="img-3 img-3-1"
                        src={imagePathArray[0]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                    <img
                        className="img-3 img-3-2"
                        src={imagePathArray[1]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                    <img
                        className="img-3 img-3-3"
                        src={imagePathArray[2]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                </div>
            )
        } else if (imagePathArray.length === 4) {
            return (
                <div className="dropzone-image-grid images-dropped">
                    <img
                        className="img-4 img-4-1"
                        src={imagePathArray[0]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                    <img
                        className="img-4 img-4-2"
                        src={imagePathArray[1]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                    <img
                        className="img-4 img-4-3"
                        src={imagePathArray[2]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                    <img
                        className="img-4 img-4-4"
                        src={imagePathArray[3]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                </div>
            )
        } else if (imagePathArray.length > 4) {
            return (
                <div className="dropzone-image-grid images-dropped">
                    <img
                        className="img-4 img-4-1"
                        src={imagePathArray[0]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                    <img
                        className="img-4 img-4-2"
                        src={imagePathArray[1]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                    <img
                        className="img-4 img-4-3"
                        src={imagePathArray[2]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                    <img
                        className="img-4 img-4-4"
                        src={imagePathArray[3]}
                        alt="uploaded file"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                    />
                    <div className="images-dropped-overlay">
                        + {imagePathArray.length - 4}
                    </div>
                </div>
            )
        }
    }
    return null
}

function handleError(e: React.SyntheticEvent<HTMLImageElement, Event>) {
    e.currentTarget.src = placeholder
}