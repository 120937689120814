import axios from 'axios'
import { HttpClient } from '../../../service/HttpClient'


export default class PublicProfileService {
    httpClient: HttpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    async getUserProfile(userId: string | null) {
        const results = await axios({
            method: 'GET',
            url: '/api/publicprofile/userprofile',
            params: { userId }
        })
        return results
    }

    async insertAboutMe(userId: string, aboutMe: string) {
        const results = await axios({
            method: 'POST',
            url: '/api/publicprofile/aboutme',
            params: { userId, aboutMe }
        })
        return results
    }

    async insertInterestsHobbies(userId: string, interestsHobbies: string) {
        const results = await axios({
            method: 'POST',
            url: '/api/publicprofile/interestshobbies',
            params: { userId, interestsHobbies }
        })
        return results
    }

    async insertTelegramUsername(userId: string, telegramUsername: string) {
        const results = await axios({
            method: 'POST',
            url: '/api/publicprofile/telegram',
            params: { userId, telegramUsername }
        })
        return results
    }

    async editAboutMe(userId: string, aboutMe: string) {
        const results = await axios({
            method: 'PUT',
            url: '/api/publicprofile/aboutme',
            params: { userId, aboutMe }
        })
        return results
    }

    async editInterestsHobbies(userId: string, interestsHobbies: string) {
        const results = await axios({
            method: 'PUT',
            url: '/api/publicprofile/interestshobbies',
            params: { userId, interestsHobbies }
        })
        return results
    }

    async editTelegramUsername(userId: string, telegramUsername: string) {
        const results = await axios({
            method: 'PUT',
            url: '/api/publicprofile/telegram',
            params: { userId, telegramUsername }
        })
        return results
    }
}



