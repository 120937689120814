import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useState } from "react";
import { UserService } from "../../service/UserService";
import { CommonMethods } from "../resources/CommonMethods";

function ContactUs() {
  const [errorMsg, setErrorMsg] = useState(
    " Unable to register. Please contact us for more information."
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [successMsg] = useState(
    "Thank you for expressing interest. Our team will contact you shortly."
  );

  // fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userEmailError, setUserEmailError] = useState("");
  const [contactNumber, setContactNumber] = useState("");

  const commonMethods = new CommonMethods();
  const userService = new UserService();

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    if (!commonMethods.validateEmail(email)) {
      setUserEmailError("Invalid email address");
      setIsLoading(false);
      return false;
    }

    let potentialEmailModel = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      contactNumber: contactNumber,
    };

    userService
      .contactus(potentialEmailModel)
      .then((res) => {
        setIsLoading(false);
        setIsSuccess(true);
      })
      .catch((err) => {
        setIsLoading(false);
        let errorMsg = commonMethods.getErrorMessage(err);
        setIsError(true);
        setErrorMsg(errorMsg);
      });
  };

  const emailErrMsg = (
    <div className="p-col-12 text-danger">{userEmailError}</div>
  );

  let contactUsForm = (
    <form onSubmit={submitForm} className="col-12">
      <div className="p-grid p-fluid p-justify-center pt-3">
        <div className="p-col-12">
          <span className="boldLabel">First Name</span>
          <InputText
            value={firstName}
            onChange={(e) => setFirstName(e.currentTarget.value)}
            required
          />
        </div>
        <div className="p-col-12">
          <span className="boldLabel">Last Name</span>
          <InputText
            value={lastName}
            onChange={(e) => setLastName(e.currentTarget.value)}
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Email</span>
          <InputText
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            required
          />
          {userEmailError ? emailErrMsg : ""}
        </div>
        <div className="p-col-12">
          <span className="boldLabel">Contact Number</span>
          <InputText
            value={contactNumber}
            onChange={(e) => setContactNumber(e.currentTarget.value)}
            required
          />
        </div>
      </div>
      <div className="p-grid p-justify-center pt-4">
        <Button type="submit" label="Submit" />
      </div>
    </form>
  );
  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <div className="col-12 text-danger text-center">{errorMsg}</div>;
    } else if (isSuccess) {
      return <div className="col-12 text-center">{successMsg}</div>;
    } else {
      return contactUsForm;
    }
  };

  return (
    <section className="fdb-block" data-block-type="call_to_action" data-id="5">
      <div className="container">
        <div className="row align-items-center justify-content-center pt-2 pt-lg-5">
          <div className="col-12 col-md-8  text-center">
            <h1>Request a demo</h1>
            <p className="h3">
              Ready to see more of NeuHR? We will reach out to you shortly.
            </p>
          </div>
          <div className="col-md-8 pt-4"> {display()}</div>
        </div>
      </div>
      <div className="py-5"></div>
    </section>
  );
}

export default ContactUs;
