import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { GroupCompanyService } from "../../service/GroupCompanyService";
import { GroupCompanyModel } from "../groupcompany/GroupCompany";
import { CommonMethods } from "../resources/CommonMethods";
import { ToastStateContext } from "../resources/ToastContext";

function PremiumPlanConversion() {
  const [errorMsg, setErrorMsg] = useState(
    " Unable to register. Please contact us for more information."
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [groupCompanies, setGroupCompanies] = useState<GroupCompanyModel[]>();

  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);

  const [searchValue, setSearchValue] = useState<string>("");

  const groupCompanyService = new GroupCompanyService();
  const commonMethods = new CommonMethods();

  let planDropdown = [
    { label: "All", value: "" },
    { label: "Free", value: "true" },
    { label: "Premium", value: "false" },
  ];

  useEffect(() => {
    getAllGC();
  }, []);

  const getAllGC = () => {
    groupCompanyService
      .getAllGroupCompanies()
      .then((res) => {
        setGroupCompanies(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        let errorMsg = commonMethods.getErrorMessage(err);
        setIsError(true);
        setErrorMsg(errorMsg);
      });
  };

  const changePlan = (rowData: GroupCompanyModel) => {
    if (!rowData.defaultPlan) {
      SetError(
        "You cannot do this because you need to manually remove the premium tasks from all the roles............"
      );
      return;
    }
    setIsLoading(true);
    groupCompanyService
      .updatePlanOfGroupCompany(rowData)
      .then((res) => {
        setIsLoading(false);
        let msg = rowData.groupCompanyName + " changed ";
        if (res.defaultPlan) {
          msg += "to Free plan";
        } else {
          msg += "to Premium plan";
        }
        SetSuccess(msg);
        getAllGC();
      })
      .catch((err) => {
        setIsLoading(false);
        let errorMsg = commonMethods.getErrorMessage(err);
        SetError(errorMsg);
      });
  };

  const actionButtons = (rowData: GroupCompanyModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="btn-login mx-1"
          onClick={(e) => changePlan(rowData)}
          tooltip={
            rowData.defaultPlan
              ? "Upgrade to Premium Plan"
              : "Downgrade to Free Plan"
          }
          tooltipOptions={{ position: "top" }}
        />
      </div>
    );
  };

  const defaultDisplay = (rowData: GroupCompanyModel) => {
    if (rowData.defaultPlan) {
      return <span>Free</span>;
    } else {
      return <span className="neuhrTextColor">Premium</span>;
    }
  };

  const createdTimeDisplay = (rowData: GroupCompanyModel) => {
    return commonMethods.displayDate(rowData.createdTime);
  };

  let tableheader = (
    <div style={{ textAlign: "left" }}>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e: any) => setSearchValue(e.currentTarget.value)}
        />
      </span>
      <Dropdown
        className="mb-1 mx-1"
        value={searchValue}
        options={planDropdown}
        onChange={(e) => setSearchValue(e.value)}
        placeholder="All"
      />
    </div>
  );

  let contactUsForm = (
    <div className="datatable-centerHeader datatable-centerContent">
      <DataTable
        value={groupCompanies}
        globalFilter={searchValue}
        header={tableheader}
        paginator={true}
        rows={15}
        autoLayout={true}
        alwaysShowPaginator={false}
        emptyMessage="No Group Companies found."
      >
        <Column field="groupCompanyID" header="ID" />
        <Column field="groupCompanyName" header="Group Company Name" />
        <Column field="groupCompanyStatus" header="Status" />
        <Column field="defaultPlan" className="d-none" />
        <Column body={createdTimeDisplay} header="Created On" />
        <Column body={defaultDisplay} header="Plan" />
        <Column body={actionButtons} header="Action" />
      </DataTable>
    </div>
  );
  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <div className="col-12 text-danger text-center">{errorMsg}</div>;
    } else {
      return contactUsForm;
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <h1>Convert to Premium Plan</h1>
          {display()}
        </div>
      </div>
    </div>
  );
}

export default PremiumPlanConversion;
