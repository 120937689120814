import React, { useState } from 'react'
import { CommentModel } from '../../Model/CommentModel'
import CreateComment from './Forms/CreateComment'

export default function ReplyInput({
    comment,
    replyCommentIdentifier,
    setViewMoreThreshold,
    commentReplyCounter,
    contextName,
    parentComponent
}: {
    comment: CommentModel,
    replyCommentIdentifier: string;
    setViewMoreThreshold: React.Dispatch<React.SetStateAction<number>>
    commentReplyCounter: number
    contextName: string
    parentComponent?: string | undefined
}) {
    if (comment.commentId === replyCommentIdentifier) {
        return <CreateComment
            inputPlaceholder="Write a reply"
            comment={comment}
            setViewMoreThreshold={setViewMoreThreshold}
            commentReplyCounter={commentReplyCounter}
            contextName={contextName}
            parentComponent={parentComponent}
        />
    }
    return null
}