import React, { useState, useEffect } from "react";
import { RouteComponentProps, CommonMethods } from "../resources/CommonMethods";
import { Button } from "primereact/button";
import { Redirect } from "react-router";
import { PaymentTermsService } from "../../service/PaymentTermsService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { ToastStateContext } from "../resources/ToastContext";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import CustomError from "../resources/Error";

let paymentTermsService = new PaymentTermsService();
export class PaymentTermsModel {
  paymentTermsID: string = CommonMethods.EmptyGuid;
  paymentTermsName: string = "";
  paymentTermsDescription: string = "";
  paymentTermsStatus: string = "";
}

function PaymentTerms(props: RouteComponentProps) {
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);
  const [redirectPath, setRedirectPath] = useState("");
  const [redirectOut, setRedirectOut] = useState(false);
  const [tableDataChanged, setTableDataChanged] = useState(false);
  const [tableData, setTableData] = useState<PaymentTermsModel[]>([]);
  const [paymentTermsData, setPaymentTermsData] = useState<PaymentTermsModel>(
    new PaymentTermsModel()
  );
  const [dialogStr, setDialogStr] = useState<string>("");
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    paymentTermsService
      .getAllPaymentTermsByGCID()
      .then(res => setTableData(res))
      .catch(err => {
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    paymentTermsService
      .getAllPaymentTermsByGCID()
      .then(res => setTableData(res))
      .catch(err => {
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, [tableDataChanged]);

  const setDialog = (header: string, content: any) => {
    setConfirmStr(header);
    setDialogStr(content);
  };

  const updatePaymentTermsStatus = async (rowData: PaymentTermsModel) => {
    let paymentTermsObj = new PaymentTermsModel();
    paymentTermsObj = rowData;
    paymentTermsObj.paymentTermsStatus =
      paymentTermsObj.paymentTermsStatus === "Active"
        ? "Deactivated"
        : "Active";

    paymentTermsService
      .updatePaymentTermsStatus(paymentTermsObj)
      .then(() => {
        SetSuccess(
          rowData.paymentTermsName +
            " is now " +
            rowData.paymentTermsStatus +
            "."
        );
      })
      .catch(() => {
        SetError("Could not update status.");
      });
  };

  const statusTemplate = (rowData: PaymentTermsModel) => {
    return (
      <>
        <InputSwitch
          className="mt-2"
          tooltip={
            rowData.paymentTermsStatus === "Active" ? "Active" : "Deactivated"
          }
          tooltipOptions={{ position: "top" }}
          checked={rowData.paymentTermsStatus === "Active"}
          onChange={e => {
            updatePaymentTermsStatus(rowData);
          }}
        />
      </>
    );
  };

  const actionTemplate = (rowData: PaymentTermsModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            setPaymentTermsData(rowData);
            setRedirectPath(
              "/admin/payment_terms/update/" + rowData.paymentTermsID
            );
            setRedirectOut(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setPaymentTermsData(rowData);

            setDialog(
              "Delete Payment Terms",
              `Are you sure you want to delete : ${rowData.paymentTermsName} `
            );
            setDialogVisibility(true);
          }}
        />
      </div>
    );
  };

  const display = () => {
    if (isLoading) {
      return (
        <>
          <ProgressSpinner />
        </>
      );
    } else if (isError) {
      return (
        <>
          <CustomError message="An error has occurred." />
        </>
      );
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={tableData}
            paginator={true}
            rows={15}
            autoLayout={true}
            alwaysShowPaginator={false}
            emptyMessage="No results found."
          >
            <Column field="paymentTermsName" header="Name" />
            <Column field="paymentTermsDescription" header="Description" />
            <Column field="paymentTermsStatus" header="Status" />
            <Column body={statusTemplate} />
            <Column body={actionTemplate} header="Description" />
          </DataTable>
        </div>
      );
    }
  };

  return (
    <div className="row">
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                if (confirmStr === "Delete Payment Terms") {
                  paymentTermsService
                    .deletePaymentTerms(paymentTermsData.paymentTermsID)
                    .then(() => {
                      setTableDataChanged(!tableDataChanged);
                      setDialogVisibility(false);
                      SetSuccess(
                        ` ${paymentTermsData.paymentTermsName} has been deleted.`
                      );
                    })
                    .catch(() => {
                      setDialogVisibility(false);
                      SetError(
                        `An error occurred when trying to delete ${paymentTermsData.paymentTermsName}`
                      );
                    });
                }
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>
      <div className="col-12 col-xl-10">
        <div className="card">
          <h1 className="pb-2">
            Payment Terms
            <span className="pl-3">
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={() => {
                  setRedirectPath("/admin/payment_terms/create");
                  setRedirectOut(true);
                }}
              />
            </span>
          </h1>
          <p>These are for campaign invoicing.</p>
          {display()}
        </div>
      </div>
    </div>
  );
}

export default PaymentTerms;
