import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { MultiDeptService } from "../../../service/MultiDeptService";
import {
  CommonMethods, DropdownModel,
  RouteComponentProps
} from "../../resources/CommonMethods";
import CustomError from "../../resources/Error";
import { TaskList } from "../../resources/ListOfTasks";
import OneDeptCombinedView from "./OneDeptCombinedView";

function SupervisorView(props: RouteComponentProps<any>) {
  const multiDeptService = new MultiDeptService();
  const commonMethods = new CommonMethods();

  const [deptList, setDeptList] = useState<Array<DropdownModel>>(
    new Array<DropdownModel>()
  );
  const [hasMultiDeptView] = useState<boolean>(
    props.userProfile.taskList.includes(TaskList.customkpiaccess)
  );
  const [deptSelected, setDeptSelected] = useState<string>("");
  const [name, setName] = useState<string>("");

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  useEffect(() => {
    if (hasMultiDeptView) {
      multiDeptService
        .getCustomMultiDeptDropdown(props.userProfile.userID, "KPI")
        .then((res) => {
          // list of depts.
          setDeptList(res);
        })
        .catch((err) => {
          setIsError(true);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
    }
    // load default if no depts are set in customkpiaccess
    commonMethods
      .getDepartmentIDFromUserID()
      .then((deptid) => {
        setDeptSelected(deptid);
      })
      .catch((err) => {
        setIsError(true);
        setErrorMsg(commonMethods.getErrorMessage(err));
      });
  }, []);

  const multipleDeptView = () => {
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <h1>Supervisor Overview</h1>

            <div className="p-grid p-fluid pb-4">
              <div className="p-col-12 p-lg-6">
                <span className="boldLabel">Now Viewing: </span>

                <Dropdown
                  options={deptList}
                  placeholder="Please Select"
                  scrollHeight="350px"
                  value={deptSelected}
                  required
                  onChange={(e) => {
                    setDeptSelected(e.value);
                    const label = _.find(deptList, ["value", e.value])!.label;
                    setName(label);
                  }}
                />
              </div>
            </div>

            <OneDeptCombinedView
              {...props}
              others={{ departmentID: deptSelected, departmentName: name }}
            />
          </div>
        </div>
      </div>
    );
  };

  //   const displayArrOfDepts = () => {
  //     let displayArr: any[] = [];
  //     deptList.forEach((dept) => {
  //       const header = dept.departmentName + " [" + dept.companyName + "]";
  //       const oneDept = (
  //         <div
  //           className="card pointer"
  //           onClick={(e) => {
  //             console.log("selected");
  //             setDeptSelected(dept.departmentID);
  //           }}
  //         >
  //           {header}
  //         </div>
  //       );
  //       displayArr.push(oneDept);
  //     });

  //     setArrToDisplay(displayArr);
  //   };

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (hasMultiDeptView && deptList.length > 0) {
    display = multipleDeptView();
  } else {
    display = (
      <OneDeptCombinedView
        {...props}
        others={{ departmentID: deptSelected, departmentName: name }}
      />
    );
  }
  return <>{display}</>;
}

export default SupervisorView;
