import * as React from "react";
import { Redirect } from "react-router";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { UserService } from "../../service/UserService";
import { InputText } from "primereact/inputtext";
import { CompanyService } from "../../service/CompanyService";
import { DepartmentService } from "../../service/DepartmentService";
import { UserRankService } from "../../service/UserRankService";
import { JobTitleService } from "../../service/JobTitleService";
import { RoleService } from "../../service/RoleService";
import { UserModel } from "./User";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import { Message } from "primereact/message";
import CustomError from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";
import { ToastStateContext } from "../resources/ToastContext";
import { AppService } from "../../service/AppService";

interface AppStateCreate {
  userObj: UserModel;

  redirectOut: boolean;
  redirectPath: string;
  isLoading: boolean;

  action: string;
  formHeader: string;
  roleDropdownList: [];
  approverDropdownList: [];
  contractDropdownList: [];
  departmentDropdownList: [];
  userrankDropdownList: [];
  jobTitleDropdownList: [];

  isError: boolean;
  errorMsg: string;

  isOnboarding: boolean;

  emailError: string;
  pwError: string;
  setupCreate: boolean;
}

export class UserCreate extends React.Component<
  RouteComponentProps<any>,
  AppStateCreate
> {
  static contextType = ToastStateContext;

  accStatusDropdownList: any;
  genderDropdownList: any;
  userRankDropdownList: any;
  userService: UserService;
  companyService: CompanyService;
  departmentService: DepartmentService;
  userRankService: UserRankService;
  jobTitleService: JobTitleService;
  roleService: RoleService;
  appService: AppService;

  commonMethods: CommonMethods;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      userObj: new UserModel(),
      redirectOut: false,
      redirectPath: "",
      isLoading: true,

      action: "",
      formHeader: "",
      roleDropdownList: [],
      approverDropdownList: [],
      departmentDropdownList: [],
      userrankDropdownList: [],
      jobTitleDropdownList: [],
      contractDropdownList: [],

      isError: false,
      errorMsg: "",

      isOnboarding: false,

      emailError: "",
      pwError: "",
      setupCreate: false,
    };

    this.accStatusDropdownList = [];

    this.genderDropdownList = [];
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.departmentService = new DepartmentService();
    this.userRankService = new UserRankService();
    this.jobTitleService = new JobTitleService();
    this.roleService = new RoleService();
    this.appService = new AppService();
    this.submitForm = this.submitForm.bind(this);
    this.goToView = this.goToView.bind(this);
    this.getDropdownLists = this.getDropdownLists.bind(this);
    this.commonMethods = new CommonMethods();
    this.copyPassword = this.copyPassword.bind(this);
    this.updateDetailsFromContract = this.updateDetailsFromContract.bind(this);
  }

  componentDidMount() {
    this.setupProgress();
    if (this.props.match.path === "/admin/employees/onboarding/:id") {
      if (
        //  --- UPDATE ONBOARDING ---
        this.props.match.params !== {} &&
        this.props.match.params.id !== undefined
      ) {
        this.setState({
          formHeader: "Activate Onboarding Employee",
          action: "",
          isOnboarding: true,
        });
        this.updateDetailsFromContract(this.props.match.params.id);
      }
    } else {
      //  --- CREATE ---
      this.setState({
        formHeader: "Create New Employee",
        action: "create",
      });
      this.updateProperty("accStatus", "Active");

      // initialize all date fields to null so that display is blank
      this.setDatesToNull();
      this.setRadioButtonsToNull();
    }

    this.getDropdownLists();
  }

  // --- INIT FORM METHODS ---
  setupProgress() {
    this.appService
      .getAppTopBar()
      .then((res: any) => {
        if (!res.setup.includes("User")) {
          this.setState({setupCreate: true});
        }
      })
      .catch ((err) => {
        this.context.SetError(this.commonMethods.getErrorMessage(err));
      })
  }


  setDatesToNull() {
    this.updateProperty("dateOfBirth", null);
    this.updateProperty("cD_JobStartDate", null);
    this.updateProperty("cD_JobEndDate", null);
    this.updateProperty("prObtainedDate", null);
    this.updateProperty("wP_WorkPermitExpiry", null);
  }

  setRadioButtonsToNull() {
    this.updateProperty("cpF_Eligible", null);
    this.updateProperty("cpF_PRApplicable", null);
    this.updateProperty("doN_Eligible", null);
  }

  getDropdownLists() {
    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";

    this.commonMethods
      .getRoleDropdownListByCid(currentAccessingCompanyId)
      .then((res) => {
        this.setState({ roleDropdownList: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });

    this.commonMethods.getUserDropdownForGroupCompany().then((res) => {
      this.setState({ approverDropdownList: res });
    });

    this.commonMethods
      .getDepartmentDropdownList(
        localStorage.getItem("currentAccessingCompany") || ""
      )
      .then((res) => {
        this.setState({ departmentDropdownList: res });
      });

    this.commonMethods.getUserRankDropdown().then((res) => {
      this.setState({ userrankDropdownList: res });
    });

    this.commonMethods.getContractSignedDropdown().then((res) => {
      this.setState({ contractDropdownList: res });
    });

    this.accStatusDropdownList = this.commonMethods.getViewUserStatusDropdownList();
    this.genderDropdownList = this.commonMethods.getGenderDropdownList();

    this.setState({ isLoading: false });
  }

  updateProperty(property: string, value: any) {
    let tempUserObj = this.state.userObj;
    (tempUserObj as any)[property] = value;
    this.setState({
      userObj: tempUserObj,
    });
  }

  // --- FORM SUBMISSION METHODS ---
  submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // to prevent page from refreshing!

    this.setState({ isLoading: true });

    if (!this.formValidationCheck()) {
      if (this.state.isOnboarding) {
        this.userService
          .updateOnboardingUser(this.state.userObj)
          .then((res) => {
            let msg = res.firstName + "'s account has been activated";
            this.context.SetSuccess(msg);
            this.setState({ userObj: res }, () => {
              this.goToView();
            });
          })
          .catch((error) => {
            let errorMsg = this.commonMethods.getErrorMessage(error);

            this.setState({
              isLoading: false,
            });

            this.context.SetError(errorMsg);
          });
      } else {
        this.userService
          .createUser(this.state.userObj)
          .then((res) => {
            if (!this.state.setupCreate) {
              let msg = res.firstName + "'s account has been created";
              this.context.SetSuccess(msg);

              this.setState({ userObj: res }, () => {
                this.goToView();
              });
            } else {
              this.setState({ userObj: res }, () => {
                this.goToView();
              });
              window.location.reload();
            }
          })
          .catch((error) => {
            let errorMsg = this.commonMethods.getErrorMessage(error);

            this.setState({
              isLoading: false,
            });

            this.context.SetError(errorMsg);
          });
      }
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  formValidationCheck() {
    this.setState({
      emailError: "",
      pwError: "",
    });
    if (!this.commonMethods.validateEmail(this.state.userObj.email)) {
      this.context.SetError("Invalid field");
      this.setState({ emailError: "p-error" });
      return true;
      // Removed because the pw generator does not pass the validation.
    } else if (
      !this.commonMethods.pwValidation(this.state.userObj.passwordHash)
    ) {
      this.context.SetError("Invalid field");
      this.setState({ pwError: "p-error" });
      return true;
    } else {
      return false;
    }
  }
  goToView() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/employees/view/" + this.state.userObj.userID,
    });
  }

  copyPassword() {
    let copyText = this.state.userObj.passwordHash;

    let textArea = document.createElement("textarea");
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }

  updateDetailsFromContract(userid: string) {
    this.userService
      .getUserDetailsFromContract(userid)
      .then((res) => {
        this.setState({ userObj: res }, () => {
          if (!this.state.userObj.email.includes("@")) {
            this.setState({
              userObj: {
                ...this.state.userObj,
                email: "",
                accStatus: "Active",
              },
            });
          }
          this.displayJobTitleByCompanySelected(
            this.state.userObj.departmentID
          );
        });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  displayJobTitleByCompanySelected(departmentid: string) {
    this.commonMethods
      .getDeptJobTitleDropdown(departmentid)
      .then((res) => {
        this.setState({ jobTitleDropdownList: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
          }}
        />
      );
    }

    let page = (
      <form onSubmit={this.submitForm}>
        <h5>Employee Details</h5>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="row">
              <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">
                  First Name <span style={{ color: "red" }}>*</span>
                </span>
                <InputText
                  value={this.state.userObj.firstName}
                  onChange={(e) =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        firstName: e.currentTarget.value,
                      },
                    })
                  }
                  required
                />
              </div>
              <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">
                  Last Name <span style={{ color: "red" }}>*</span>
                </span>
                <InputText
                  value={this.state.userObj.lastName}
                  onChange={(e) =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        lastName: e.currentTarget.value,
                      },
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Full Name (as per NRIC) <span style={{ color: "red" }}>*</span>
            </span>

            <InputText
              value={this.state.userObj.fullName}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    fullName: e.currentTarget.value.toUpperCase(),
                  },
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Identification Number <span style={{ color: "red" }}>*</span>
            </span>

            <InputText
              value={this.state.userObj.identificationNumber}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    identificationNumber: e.currentTarget.value.toUpperCase(),
                  },
                })
              }
              required
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Email <span style={{ color: "red" }}>*</span>
            </span>
            <div
              hidden={this.state.emailError === "" ? true : false}
              className="my-1"
            >
              <Message severity="error" text="Invalid Input" />
            </div>
            <InputText
              className={this.state.emailError}
              value={this.state.userObj.email}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    email: e.currentTarget.value,
                  },
                })
              }
              required
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Account Status</span>
            <Dropdown
              value={this.state.userObj.accStatus}
              options={this.accStatusDropdownList}
              // onChange={e =>
              //   this.setState({
              //     userObj: {
              //       ...this.state.userObj,
              //       accStatus: e.value
              //     }
              //   })
              // }

              disabled
              placeholder="Please Select"
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Password <span style={{ color: "red" }}>*</span>
            </span>

            <ul>
              <li> 8 characters long</li>
              <li> contain one number </li>
              <li> include both lower and upper case characters </li>
            </ul>
            <div
              hidden={this.state.pwError === "" ? true : false}
              className="my-1"
            >
              <Message severity="error" text="Invalid Input" />
            </div>
            <div className="p-inputgroup">
              <InputText
                value={this.state.userObj.passwordHash}
                onChange={(e) =>
                  this.setState({
                    userObj: {
                      ...this.state.userObj,
                      passwordHash: e.currentTarget.value,
                    },
                  })
                }
                required
              />

              <Button
                tooltip="Generate Random Password"
                tooltipOptions={{ position: "top" }}
                type="button"
                className=" p-button-warning"
                onClick={() => {
                  this.setState({
                    userObj: {
                      ...this.state.userObj,
                      passwordHash: this.commonMethods.generatePassword(),
                    },
                  });
                }}
                icon="pi pi-replay"
              />
              <Button
                tooltip="Copy Password"
                tooltipOptions={{ position: "top" }}
                type="button"
                id="passwordGenerated"
                onClick={this.copyPassword}
                icon="pi pi-copy"
              />
            </div>
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Role <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.userObj.roleID}
              options={this.state.roleDropdownList}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    roleID: e.value,
                  },
                })
              }
              required
              placeholder="Please Select"
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Supervisors</span>

            <MultiSelect
              value={this.state.userObj.approverList}
              options={this.state.approverDropdownList}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    approverList: e.value,
                  },
                })
              }
              filter={true}
              placeholder="Please Select"
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Department <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.userObj.departmentID}
              options={this.state.departmentDropdownList}
              onChange={(e) => {
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    departmentID: e.value,
                  },
                });
                this.displayJobTitleByCompanySelected(e.value);
              }}
              placeholder="Please Select"
              required
            />{" "}
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Job Title <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.userObj.jobTitleID}
              options={this.state.jobTitleDropdownList}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    jobTitleID: e.value,
                  },
                })
              }
              placeholder="Please Select"
              required
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              User Rank <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.userObj.userRankID}
              options={this.state.userrankDropdownList}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    userRankID: e.value,
                  },
                })
              }
              placeholder="Please Select"
              required
            />
          </div>
        </div>

        <div className="p-grid p-justify-center pt-4">
          <Button type="submit" label="Submit" />
        </div>
      </form>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = page;
    }
    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{this.state.formHeader}</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {display}
          </div>
        </div>
      </div>
    );
  }
}
