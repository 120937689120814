import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ScrollToTop from "./ScrollToTop";
import { BrowserRouter } from "react-router-dom";

// For IE9/11 Support
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

// OS Title
export const tslosTitle = 'TSLOS';

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <App/>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();

// serviceWorker.register({
//   onUpdate: (registration:any) => {
//     const waitingServiceWorker = registration.waiting

//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener("statechange", (event:any) => {
//         if (event.target.state === "activated") {
//           console.log("actiated")
//           window.location.reload()
//         }
//       });
//       waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//     }
//   }
// });

serviceWorker.unregister();

Notification.requestPermission().then(function(result) {
  if (result === "denied") {
    return;
  }
  if (result === "default") {
    return;
  }
  // Do something with the granted permission.
});