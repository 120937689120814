import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import NumberFormat from "react-number-format";
import { Redirect } from "react-router";
import ReactTooltip from "react-tooltip";
import { PayslipService } from "../../service/PayslipService";
import {
  CommonMethods,
  DropdownModel,
  RouteComponentProps
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { PayrollTableModel } from "../resources/ExportClass";
import { ToastStateContext } from "../resources/ToastContext";

export function AllPayrolls(props: RouteComponentProps<any>) {
  const { SetSuccess, SetError, SetWarn } = React.useContext(ToastStateContext);
  const payslipService = new PayslipService();
  const commonMethods = new CommonMethods();

  const [allPayrollsForYear, setAllPayrollsForYear] = useState<Array<PayrollTableModel>>([]);
  const [selectedYear, setSelectedYear] = useState<string>(new Date().getFullYear().toString());
  const [payslipPeriod, setPayslipPeriod] = useState<Array<DropdownModel>>([]);
  const [addPayrollDialogVisible, setAddPayrollDialogVisible] = useState<boolean>(false);
  const [deletePayrollDialogVisible, setDeletePayrollDialogVisible] = useState<boolean>(false);
  const [payrollToDelete, setPayrollToDelete] = useState<PayrollTableModel>(new PayrollTableModel());
  const [payrollObj, setPayrollObj] = useState<PayrollTableModel>(new PayrollTableModel());

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );

// useEffect(()=>{
//   getPayrollHistoryObject();
// },[]);

useEffect(()=>{
  getPayrollHistoryObject();
},[selectedYear]);

const getPayrollHistoryObject = () => {
  let currentAccessingCompanyId =
    localStorage.getItem("currentAccessingCompany") || "";
  payslipService
    .getPayrollHistoryYearDropdown(currentAccessingCompanyId)
    .then((res) => {
      setPayslipPeriod(res);
    });

  payslipService
    .getAllPayrollForCompany(
      currentAccessingCompanyId,
      selectedYear
    )
    .then((res) => {
      setAllPayrollsForYear(res);
      setIsLoading(false);
    })
    .catch((error) => {
      let errorMsg = commonMethods.getErrorMessage(error);
      setIsError(true);
      setIsLoading(false);
      setErrorMessage(errorMessage);
      return errorMsg;
    });
};

const goToMonthPage = (rowData: PayrollTableModel) => {
  setRedirectOut(true);
  setRedirectPath("/finance/payroll/" + rowData.payrollID);
}

const deletePayroll = () => {
  payslipService
    .deletePayroll(payrollToDelete.payrollID)
    .then((res) => {
      setDeletePayrollDialogVisible(false);
      SetSuccess("Payroll deleted");
      getPayrollHistoryObject();
    })
    .catch((error) => {
      let errorMsg = commonMethods.getErrorMessage(error);
      SetError(errorMsg);
    });
}

const prepareCreatePayroll = () => {
  let currentAccessingCompanyId =
    localStorage.getItem("currentAccessingCompany") || "";
    setAddPayrollDialogVisible(true);
    setPayrollObj({...payrollObj, companyID:currentAccessingCompanyId});
}

const createPayroll = () => {
  payslipService
    .createPayroll(payrollObj)
    .then((res) => {
      setAddPayrollDialogVisible(false);
      // Redirect to that payroll page
      setRedirectPath("/finance/payroll/" + res.payrollID);
      setRedirectOut(true);
    })
    .catch((error) => {
      let errorMsg = commonMethods.getErrorMessage(error);
      if (commonMethods.isWarning(error)) {
        // if fail
        SetWarn(errorMsg);
      } else {
        SetError(errorMsg);

      }
    });
}

const monthFull = (dataRow: any) => {
  return commonMethods.displayMonth_Full(dataRow.paymentDate);
}
const displayCreatedTime = (dataRow: PayrollTableModel) => {
  return commonMethods.displayDate(dataRow.createdTime);
}

const financeFormat = (rowData: any, column: any) => {
  return (
    <NumberFormat
      displayType="text"
      value={rowData[column.field]}
      thousandSeparator={true}
      prefix={"$"}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
}

const displayStatus = (rowData: PayrollTableModel) => {
  if (rowData.payrollStatus === "Incomplete") {
    return (
      <span
        className=" text-info boldLabel"
        data-tip=""
        data-for="incomplete"
      >
        {rowData.payrollStatus}{" "}
        <ReactTooltip place="top" id="incomplete">
          Payroll not published
        </ReactTooltip>
      </span>
    );
  }
  if (rowData.payrollStatus === "Completed") {
    return (
      <span className="text-success boldLabel">{rowData.payrollStatus}</span>
    );
  }
  return rowData.payrollStatus;
}

const actionTemplate = (rowData: PayrollTableModel) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        tooltip="View Payroll"
        tooltipOptions={{ position: "top" }}
        icon="pi pi-eye"
        className="p-button mx-1"
        onClick={(e) => goToMonthPage(rowData)}
      />
      <Button
        tooltip="Delete Payroll"
        tooltipOptions={{ position: "top" }}
        icon="pi pi-trash"
        className="p-button-danger mx-1"
        onClick={() => {
          setPayrollToDelete(rowData);
          setDeletePayrollDialogVisible(true);
          // this.setState({
          //   payrollToDelete: rowData,
          //   deletePayrollDialogVisible: true,
          // });
        }}
      />
    </div>
  );
};


if (redirectOut) {
  return (
    <Redirect
      push
      to={{
        pathname: redirectPath,
      }}
    />
  );
}

let renderPayrollTable = (
  <div className="p-col-12">
    <DataTable
      emptyMessage="No results found."
      className="text-center"
      value={allPayrollsForYear}
      autoLayout={true}
    >
      <Column body={monthFull} header="Month" />
      <Column
        field="payrollStatus"
        body={displayStatus}
        header="Status"
        className="p-col-1"
      />

      <Column
        field="totalNetSalary"
        header="Net Pay"
        body={financeFormat}
      />
      <Column
        field="totalCPFContributions"
        header="CPF"
        body={financeFormat}
      />
      <Column
        field="totalDonation"
        header="SHGS"
        body={financeFormat}
      />
      <Column field="totalSDL" header="SDL" body={financeFormat} />

      <Column
        body={displayCreatedTime}
        header="Created on"
        className="p-col-1"
      />
      <Column
        header="Action"
        body={actionTemplate}
        className="p-col-1"
      />
    </DataTable>
  </div>
);
  let display;
  if (isError) {
    display = <CustomError message={errorMessage} />;
  } else if(isLoading) {
    display = <ProgressSpinner/>;
  } else {
    display =(
      <div className="p-grid p-justify-center text-center">
        {renderPayrollTable}
      </div>
    );    
  }

  return (<>
    <div className="row">
      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">
            All Payrolls{" "}
            <span className="pl-3">
              <Button
                label="Add"
                type="button"
                iconPos="right"
                icon="pi pi-plus"
                onClick={prepareCreatePayroll}
                className="p-button-danger"
              />
            </span>
          </h1>
          <div className="p-grid p-fluid ">
            <div className="p-col-2 pb-4">
              <span className="boldLabel">Year</span>
              <Dropdown
                value={selectedYear}
                options={payslipPeriod}
                placeholder="Payroll Year"
                onChange={(e) => setSelectedYear(e.value)}
              />
            </div>
          </div>
          {display}
        </div>
      </div>
    </div>
    <Dialog
      visible={addPayrollDialogVisible}
      className="popupDialog"
      modal={true}
      header={"Create new payroll"}
      onHide={() => {
        setAddPayrollDialogVisible(false);
      }}
    >
      <div className="p-grid p-fluid text-left">
        <div className="p-col-12">
          <span className="boldLabel">Payment Period</span>
          <Calendar
            id="MonthCalendar"
            value={payrollObj.paymentDate}
            onChange={(e) => {
              setPayrollObj({...payrollObj, paymentDate: e.value});
            }}
            view="month"
            dateFormat="mm/yy"
            yearNavigator={true}
            yearRange="2020:2030"
          />
        </div>
      </div>
      <div className="p-grid p-justify-center pt-4">
        <Button
          label="Create"
          type="button"
          className="p-button"
          onClick={() => {
            createPayroll();
          }}
        />
      </div>
    </Dialog>
    <Dialog
      visible={deletePayrollDialogVisible}
      className="popupDialog"
      modal={true}
      header={"Delete payroll"}
      onHide={() => {
        setDeletePayrollDialogVisible(false);
      }}
    >
      <div className="p-grid p-fluid text-left">
        <div className="p-col-12">
          Confirm delete payroll for {commonMethods.displayMonth_Short(payrollToDelete.paymentDate)}{selectedYear}?
        </div>
      </div>
      <div className="p-grid p-justify-center pt-4">
        <Button
          label="Delete"
          type="button"
          className="p-button"
          onClick={() => {
            deletePayroll();
          }}
        />
      </div>
    </Dialog>
  </>
  );
}
