import React, { useState, useEffect, useRef } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useParams, Redirect } from "react-router";
import { Button } from "primereact/button";
import { StatusRecordService } from "../../service/StatusRecordService";
import { CommonMethods, ParamIDTypes, RouteComponentProps } from "../resources/CommonMethods";

import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { StatusService } from "../../service/StatusService";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { ProgressSpinner } from "primereact/progressspinner";
import { ToastStateContext } from "../resources/ToastContext";
import LeaveBalancePanelHorizontal from "./LeaveBalancePanelHorizontal";

import CustomError from "../resources/Error";
import { StatusRecordDisplayObj } from "./LeaveApplicationForm";
import ReactTooltip from "react-tooltip";

export function BalanceAndHistory(props: RouteComponentProps) {
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const statusRecordService = new StatusRecordService();
  const statusService = new StatusService();

  const commonMethods = new CommonMethods();

  // let params: { id?: string } = useParams();
  const { id } = useParams<ParamIDTypes>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [creditHistory, setCreditHistory] = useState<any[]>([]);
  const [statusRecords, setStatusRecords] = useState<any[]>([]);
  // const [statusBalance, setStatusBalance] = useState<any[]>([]);
  const [dialogStr, setDialogStr] = useState(<></>);
  const [headerStr, setHeaderStr] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [infoVisible, setInfoVisible] = useState<boolean>(false);
  const [recordID, setRecordID] = useState<string>("");
  const [toUpdate, setToUpdate] = useState(false);
  // const [toApply, setToApply] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [dateFilter, setDateFilter] = useState<string | Date>(new Date());
  const [statusTypeFilter, setStatusTypeFilter] = useState(null);
  const [statusTypeDropdown, setStatusTypeDropdown] = useState<any[]>([]);
  const [leaveStatusDropdown, setLeaveStatusDropdown] = useState<any>([]);
  const [leaveStatusFilter, setLeaveStatusFilter] = useState(null);
  const [page, setPage] = useState(0);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState<string>("An error has occurred");
  const [isCreditError] = useState(false);
  // const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
  const [changeInRelatedData, setChangeInRelatedData] = useState<boolean>(
    false
  );
  const dt = useRef<any>(null);

  useEffect(() => {
    initiateDropdownLists();
  }, []);

  useEffect(() => {
    getStatusRecordHistory();
  }, [changeInRelatedData]);

  // useEffect(() => {
  //   if (id !== undefined) {
  //     setUserID(id || "");
  //     statusRecordService
  //       .getStatusRecordsByUserId(userID)
  //       .then((res) => {
  //         setStatusRecords(res);
  //         getStatusCreditHistory(userID);
  //         // getStatusBalance(userID);
  //         setIsLoading(false);
  //       })
  //       .catch(() => {
  //         setIsLoading(false);
  //         setIsError(true);
  //       });
  //   } else {
  //     setUserID(commonMethods.getUserIDFromToken());
  //     statusRecordService
  //       .getMyStatusRecords(userID)
  //       .then((res) => {
  //         setStatusRecords(res);
  //         getStatusCreditHistory(userID);
  //         // getStatusBalance(userID);
  //         setIsLoading(false);
  //       })
  //       .catch(() => {
  //         setIsLoading(false);
  //         setIsError(true);
  //       });
  //   }
  // }, [userID]);

  const getStatusRecordHistory = async () => {
    if (id !== undefined) {
      try {
        await statusService.getCreditHistoryByUserID(id).then((res) => {
          setCreditHistory(res);
        });
        await statusRecordService.getStatusRecordsByUserId(id).then((res) => {
          setStatusRecords(res);
          setIsLoading(false);
        });
      } catch (err) {
        setIsLoading(false);
        setIsError(true);
        setErrMsg(commonMethods.getErrorMessage(err));
      }
    } else {
      try {
        await statusService.getMyCreditHistory().then((res) => {
          setCreditHistory(res);
        });
        await statusRecordService.getMyStatusRecords().then((res) => {
          setStatusRecords(res);
          setIsLoading(false);
        });
      } catch (err) {
        setIsLoading(false);
        setIsError(true);
        setErrMsg(commonMethods.getErrorMessage(err));
      }
    }
  };

  //    ___  _          _                _  _  _      _                      ___  _           __   __
  //   / __|| |_  __ _ | |_  _  _  ___  | || |(_) ___| |_  ___  _ _  _  _   / __|| |_  _  _  / _| / _|
  //   \__ \|  _|/ _` ||  _|| || |(_-<  | __ || |(_-<|  _|/ _ \| '_|| || |  \__ \|  _|| || ||  _||  _|
  //   |___/ \__|\__,_| \__| \_,_|/__/  |_||_||_|/__/ \__|\___/|_|   \_, |  |___/ \__| \_,_||_|  |_|
  //                                                                 |__/

  useEffect(() => {
    setStatusRecords(statusRecords);
  }, [statusRecords]);

  const initiateDropdownLists = () => {
    statusService
      .getActiveStatusTypeDropdown()
      .then((res) => {
        setStatusTypeDropdown(res);
      })
      .catch(() => {
        setIsError(true);
      });

    commonMethods
      .getLeaveStatusDropdownForFiltering()
      .then((res) =>
        setLeaveStatusDropdown(res)
      )
      .catch(() => {
        setIsError(true);
      });
  };

  const durationTemplate = (rowData: StatusRecordDisplayObj) => {
    let display = "";

    display = commonMethods.convertStartEndDateForStartEndDayDateDurationDisplay(
      rowData.statusTypeName,
      rowData.startDate,
      rowData.endDate,
      rowData.duration
    );

    return <>{display}</>;
  };

  const createdTimeDisplay = (rowData: any) => {
    return commonMethods.displayDate(rowData.createdTime);
  };
  const cancelPrompt = (data: any) => {
    setDialogStr(<>Are you sure you want to cancel your application?</>);
    setHeaderStr("Cancel Application");
    setVisible(true);
    setRecordID(data);
  };

  const confirmCancel = () => {
    setIsLoading(true);
    statusRecordService
      .cancelStatusRecord({ StatusRecordID: recordID })
      .then(() => {
        SetSuccess("Status Application Cancelled");
        setChangeInRelatedData(!changeInRelatedData);
      })
      .catch((err) => {
        SetError(commonMethods.getErrorMessage(err));
      })
      .finally(() => {
        setVisible(false);
        setIsLoading(false);
      });
  };

  const immediateCancelPrompt = (data: any) => {
    setDialogStr(<>Are you sure you want to cancel this application?</>);
    setHeaderStr("Cancel Application (Admin)");
    setVisible(true);
    setRecordID(data.statusRecordID);
  };

  const confirmImmediateCancel = () => {
    statusRecordService
      .adminCancelStatusRecord({ StatusRecordID: recordID })
      .then(() => {
        SetSuccess("Status Application Cancelled");
        setVisible(false);
        setChangeInRelatedData(!changeInRelatedData);
      })
      .catch((err) => {
        SetError(commonMethods.getErrorMessage(err));
        setVisible(false);
      });
  };

  // const statusTemplate = (rowData: any) => {
  //   if (rowData.approvalStatus === "Pending") {
  //     return <span style={{ color: "#0099CC" }}>Pending</span>;
  //   } else if (rowData.approvalStatus === "Approved") {
  //     return <span style={{ color: "#00C78C" }}>Approved</span>;
  //   } else if (rowData.approvalStatus === "Rejected") {
  //     return <span style={{ color: "#EE3B3B" }}>Rejected</span>;
  //   } else if (rowData.approvalStatus === "Cancelled") {
  //     return <span>Cancelled</span>;
  //   } else if (rowData.approvalStatus === "Pending Cancellation") {
  //     return <span style={{ color: "#EE3B3B" }}>Pending Cancellation</span>;
  //   } else if (rowData.approvalStatus === "Cancellation Approved") {
  //     return <span style={{ color: "#00C78C" }}>Cancellation Approved</span>;
  //   } else if (rowData.approvalStatus === "Cancellation Rejected") {
  //     return <span style={{ color: "#EE3B3B" }}>Cancellation Rejected</span>;
  //   }
  // };

  const showInfoDialog = (e: any) => {
    setHeaderStr("Leave Details");
    let html = (
      <>
        <table className="leave-info-dialog table">
          <tbody>
            <tr>
              <td className="font-weight-bold">Leave Type:</td>
              <td>{e.statusTypeName}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Status:</td>
              <td>{e.approvalStatus}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Updated By:</td>
              <td>{e.approvers}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Start Date:</td>
              <td>
                {commonMethods.convertStartEndDateForDayDateDurationDisplay(
                  e.statusTypeName,
                  e.startDate,
                  e.endDate,
                  e.duration,
                  "start"
                )}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">End Date:</td>
              <td>
                {commonMethods.convertStartEndDateForDayDateDurationDisplay(
                  e.statusTypeName,
                  e.startDate,
                  e.endDate,
                  e.duration,
                  "end"
                )}
              </td>
            </tr>

            <tr>
              <td className="font-weight-bold">Days Deducted:</td>
              <td>{e.creditDebitValue}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Remarks:</td>
              <td>{e.remarks}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
    setDialogStr(html);
    setInfoVisible(true);
  };

  const actionTemplate = (rowData: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Leave Details"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-eye"
          type="button"
          className="p-button-info mx-1"
          onClick={() => {
            showInfoDialog(rowData);
          }}
        />

        <Button
          tooltip="Edit Leave"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          type="button"
          className="p-button-warning mr-1"
          onClick={() => {
            setToUpdate(true);
            setRecordID(rowData.statusRecordID);
          }}
          disabled={
            (!(rowData.approvalStatus === "Pending") && !(props.match.path === "/admin/employees/leave_summary/:id"))
          }
        />

        <Button
          tooltip="Cancel Leave"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-times"
          className="p-button-danger mr-1"
          onClick={() => {
            if (
              props.userProfile.taskList.includes("leaveadminaccess") &&
              rowData.userID !== props.userProfile.userID
            ) {
              immediateCancelPrompt(rowData);
            } else {
              cancelPrompt(rowData.statusRecordID);
            }
          }}
          disabled={
            props.userProfile.taskList.includes("leaveadminaccess") ||
            (rowData.approvalStatus === "Pending" ||
            (rowData.approvalStatus === "Approved" &&
            new Date(rowData.endDate) >= new Date()))
              ? false
              : true
          }
        />

        <Button
          tooltip="View Documents"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-folder"
          className="button-document mr-1"
          onClick={() => {
            window.open(rowData.documentPath, "_blank");
          }}
          disabled={rowData.documentPath == null}
        />
      </div>
    );
  };

  const statusTypeNameFilter = (
    <Dropdown
      showClear
      className="mb-1 mr-1"
      value={statusTypeFilter}
      options={statusTypeDropdown}
      placeholder="Filter by Leave Type"
      onChange={(e) => {
        setStatusTypeFilter(e.value);
        setPage(0);
        onStatusTypeNameChange(e);
      }}
    />
  );

  const onStatusTypeNameChange = ((e: any) => {
    dt.current.filter(e.value, 'statusTypeName', 'equals');
  });

  const statusTypeStatusFilter = (
    <Dropdown
      showClear
      className="mb-1 mr-1"
      value={leaveStatusFilter}
      options={leaveStatusDropdown}
      placeholder="Filter by Status"
      onChange={(e) => {
        setLeaveStatusFilter(e.value);
        setPage(0);
        onStatusTypeStatusChange(e);
      }}
    />
  );

  const onStatusTypeStatusChange = ((e: any) => {
    dt.current.filter(e.value, 'approvalStatus', 'equals');
  });

  const calendarDateFilter = (
    <Calendar
      showButtonBar={true}
      todayButtonClassName="d-none"
      tooltip="Filter by Date"
      tooltipOptions={{ position: "top" }}
      yearNavigator
      yearRange="2020:2023"
      id="MonthCalendar"
      readOnlyInput={true}
      view="month"
      dateFormat="M-yy"
      maxDate={new Date()}
      value={new Date(dateFilter.toString())}
      onChange={(e) => {
        if (e.value === null) {
          setGlobalFilter("");
          setDateFilter(new Date());
        } else {
          setGlobalFilter(
            new Date(
              new Date(e.value.toString()).getTime() -
                new Date().getTimezoneOffset() * 60 * 1000
            )
              .toISOString()
              .substr(0, 7)
              .replace("T", " ")
          );
          setDateFilter(new Date(e.value.toString()));
          setPage(0);
        }
      }}
    />
  );

  let historyHeader = (
    <div style={{ textAlign: "left" }}>
      {/* <i className="pi pi-search" style={{ margin: "2px 4px 0 0" }}></i> */}
      <span className="p-input-icon-left mr-1 mb-1">
          <i className="pi pi-search" />
          <InputText
            placeholder="Global Search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
          />
      </span>
      {statusTypeNameFilter}
      {statusTypeStatusFilter}
      {calendarDateFilter}
    </div>
  );

  //    ___  _          _                ___        _                         ___  _           __   __
  //   / __|| |_  __ _ | |_  _  _  ___  | _ ) __ _ | | __ _  _ _   __  ___   / __|| |_  _  _  / _| / _|
  //   \__ \|  _|/ _` ||  _|| || |(_-<  | _ \/ _` || |/ _` || ' \ / _|/ -_)  \__ \|  _|| || ||  _||  _|
  //   |___/ \__|\__,_| \__| \_,_|/__/  |___/\__,_||_|\__,_||_||_|\__|\___|  |___/ \__| \_,_||_|  |_|
  //

  // const getStatusBalance = (ID: string) => {
  //   statusService.getDeductibleAndActiveStatusBalance(userID).then(res => {
  //     for (let i = 0; i < res.length; i++) {
  //       res[i].expiryDate = commonMethods.convertDateForDisplay(
  //         res[i].expiryDate
  //       );
  //       res[i].createdTime = commonMethods.convertDateForDisplay(
  //         res[i].createdTime
  //       );
  //     }
  //     setStatusBalance(res);
  //   })
  //     .catch(() => {
  //       setIsError(true);
  //     });
  // };

  //    ___  _          _                 ___               _  _  _     ___        _                         ___  _           __   __
  //   / __|| |_  __ _ | |_  _  _  ___   / __| _ _  ___  __| |(_)| |_  | _ ) __ _ | | __ _  _ _   __  ___   / __|| |_  _  _  / _| / _|
  //   \__ \|  _|/ _` ||  _|| || |(_-<  | (__ | '_|/ -_)/ _` || ||  _| | _ \/ _` || |/ _` || ' \ / _|/ -_)  \__ \|  _|| || ||  _||  _|
  //   |___/ \__|\__,_| \__| \_,_|/__/   \___||_|  \___|\__,_||_| \__| |___/\__,_||_|\__,_||_||_|\__|\___|  |___/ \__| \_,_||_|  |_|
  //

  const creditHistoryTable = () => {
    if (isCreditError) {
      return <CustomError message={"An error has occurred"} />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={creditHistory}
            autoLayout={true}
            paginator={true}
            alwaysShowPaginator={false}
            rows={20}
            emptyMessage="No results found."
          >
            <Column field="statusType" header="Type" className="p-col-3" />
            <Column
              field="actionType"
              header="Credit/Debit"
              className="p-col-1"
            />
            <Column field="statusValue" header="Value" className="p-col-1" />
            <Column field="remarks" header="Remarks" className="p-col-3" />
            <Column field="creditor" header="Updated By" className="p-col-3" />
            <Column
              body={createdTimeDisplay}
              header="Created On"
              className="p-col-1"
            />
          </DataTable>
        </div>
      );
    }
  };

  const statusTemplate = (rowData: any) => {
    if (rowData.approvalStatus === "Rejected") {
      return (
        <div className="text-danger boldLabel" data-tip="" data-for="rejected">
          <ReactTooltip place="top" id="rejected">
            Rejected by Supervisor.
          </ReactTooltip>
          {rowData.approvalStatus}
        </div>
      );
    } else if (rowData.approvalStatus === "Approved") {
      return (
        <div className="text-success boldLabel">{rowData.approvalStatus}</div>
      );
    } else if (rowData.approvalStatus.includes("Pending")) {
      return (
        <div className="text-info boldLabel" data-tip="" data-for="pending">
          <ReactTooltip place="top" id="pending">
            Pending approval by Supervisor.
          </ReactTooltip>
          {rowData.approvalStatus}
        </div>
      );
    } else if (rowData.approvalStatus.includes("Pending Cancellation")) {
      return (
        <div
          className="text-info boldLabel"
          data-tip=""
          data-for="pendingcancellation"
        >
          <ReactTooltip place="top" id="pendingcancellation">
            Awaiting approval by Supervisor for your leave cancellation.
          </ReactTooltip>
          {rowData.approvalStatus}
        </div>
      );
    } else {
      return <div className="boldLabel">{rowData.approvalStatus}</div>;
    }
  };

  const historyTable = () => {
    if (isError) {
      return <CustomError message={errMsg} />;
    } else {
      return (
        <>
          {historyHeader}
          <div className="datatable-centerHeader datatable-centerContent">
            <DataTable
              ref={dt}
              value={statusRecords}
              paginator={true}
              alwaysShowPaginator={false}
              rows={10}
              autoLayout={true}
              first={page}
              onPage={(e) => setPage(e.first)}
              // header={historyHeader}
              emptyMessage="No records found."
              globalFilter={globalFilter}
            >
              <Column field="statusTypeName" header="Type" filterElement={statusTypeNameFilter}/>
              <Column body={durationTemplate} field="startDate" header="Duration" sortable />
              <Column field="creditDebitValue" header="Value" />
              <Column field="approvalStatus" header="status" className="d-none" />
              <Column body={createdTimeDisplay} field="createdTime" header="Applied On" sortable />
              <Column field="approvalStatus" body={statusTemplate} header="Status" filterElement={statusTypeStatusFilter}/>
              <Column body={actionTemplate} header="Action" />
            </DataTable>
          </div>
        </>
      );
    }
  };

  const display = () => {
    if (isLoading) {
      return (
        <div className="text-center">
          <ProgressSpinner />
          {/* <i className="pi pi-spin pi-spinner"></i> */}
        </div>
      );
    } else {
      return (
        <>
          <div>
            {toUpdate ? (
              <Redirect push to={"/status/update/" + recordID} />
            ) : null}
            <Dialog
              className="popupDialog"
              header={headerStr}
              visible={visible}
              modal={true}
              //dismissableMask={true}
              onHide={() => setVisible(false)}
              footer={
                <div>
                  <Button
                    label="Yes"
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={() => {
                      if (headerStr === "Cancel Application") {
                        confirmCancel();
                      } else if (
                        headerStr === "Cancel Application (Admin)" &&
                        props.userProfile.taskList.includes("leaveadminaccess")
                      ) {
                        confirmImmediateCancel();
                      }
                    }}
                  />
                  <Button
                    label="No"
                    type="button"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => {
                      setVisible(false);
                    }}
                  />
                </div>
              }
            >
              {dialogStr}
            </Dialog>
            <Dialog
              // className="popupDialog"
              //dismissableMask={true}
              header={headerStr}
              visible={infoVisible}
              modal={true}
              onHide={() => setInfoVisible(false)}
            >
              {dialogStr}
            </Dialog>
            <div className="card">
              <LeaveBalancePanelHorizontal
                {...props}
                others="BalanceAndHistory"
              />
            </div>
            <div
              className="card"
              data-tour-id="leave-history-tab"
              data-tour="Click to view your all your past leave applications."
            >
              <Accordion>
                <AccordionTab header="Leave History">
                  {historyTable()}
                </AccordionTab>
              </Accordion>
            </div>
          </div>

          <div>
            <div
              className="card"
              data-tour-id="leave-credit-tab"
              data-tour="Click this to view the leave you've been credited in the past."
            >
              <Accordion>
                <AccordionTab header="Leave Credit History">
                  {creditHistoryTable()}
                </AccordionTab>
              </Accordion>
            </div>
          </div>
        </>
      );
    }
  };

  //    ___  ___  _  _  ___   ___  ___
  //   | _ \| __|| \| ||   \ | __|| _ \
  //   |   /| _| | .` || |) || _| |   /
  //   |_|_\|___||_|\_||___/ |___||_|_\
  //

  return (
    <div className="row">
      <div className="col-12 col-xl-10">{display()}</div>
    </div>
  );
}

export default BalanceAndHistory;
