import React, { useEffect, useReducer, useState } from 'react'
import { RouteComponentProps } from "../resources/CommonMethods";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import PostsContainer from './Component/Post/PostsContainer';
import './CompanyFeed.css';
import './Birthday.css'
import CreatePost from './Component/Post/Forms/CreatePost';
import { UserDetails, PostModel } from './Model/PostModel';
import CompanyFeedService from './Service/CompanyFeedService';
import { MentionData } from '@draft-js-plugins/mention';
import { toTitleCase } from './CompanyFeedFunctions';
import { ContentState, convertToRaw, EditorState, RawDraftEntityRange } from 'draft-js';
import TodayBirthdayPost from './Component/BirthdayPost/TodayBirthdayPost';
import { TaskList } from '../resources/ListOfTasks';
import { ACTIONS } from './DispatchActions';

export class CompanyFeedState {
    currentUser: UserDetails = new UserDetails()
    posts: PostModel[] = []
    page: number = 1
    hasMore: boolean = false
    allMentionSuggestions: MentionData[] = []
    birthdayPost: PostModel | null = null
}

function reducer(
    state: {
        currentUser: UserDetails;
        posts: PostModel[];
        page: number;
        hasMore: boolean;
        allMentionSuggestions: MentionData[]
        birthdayPost: PostModel | null
    },
    action: { type: any; payload: any; }) {
    switch (action.type) {
        case ACTIONS.LOAD_POSTS: {
            const posts = action.payload
            // console.log('LOAD POST posts:', posts)
            const prevPosts = state.posts
            return { ...state, posts: [...prevPosts, ...posts] }
        }
        case ACTIONS.NEXT_PAGE: {
            // console.log("ACTIONS.NEXT_PAGE")
            return { ...state, page: state.page + 1 }
        }
        case ACTIONS.ON_HASMORE: {
            // console.log("ACTIONS.ON_HASMORE")
            return { ...state, hasMore: true }
        }
        case ACTIONS.OFF_HASMORE: {
            // console.log("ACTIONS.OFF_HASMORE")
            return { ...state, hasMore: false }
        }
        case ACTIONS.ADD_POST: {
            const newPost = action.payload
            // console.log('add_post state', state)
            const postsObject = state.posts
            postsObject.unshift(newPost)
            // console.log("newPost", newPost)
            // console.log("postsObject", postsObject)
            return { ...state, posts: [...postsObject] };
        }
        case ACTIONS.EDIT_POST: {
            // console.log('COMPANYFEED EDIT_POST')
            const editedPost = action.payload
            // console.log('editedPost', editedPost)
            const postsObjectEdit = state.posts
            postsObjectEdit.map(post => {
                if (post.postId === editedPost.postId) {
                    post.postText = editedPost.postText
                    post.rawEditorContentJsonStr = editedPost.rawEditorContentJsonStr
                    post.usersTagged = editedPost.usersTagged
                    post.usersTaggedArray = editedPost.usersTaggedArray
                    post.imagePath = editedPost.imagePath
                    post.imagePathArray = editedPost.imagePathArray
                    post.isEdited = editedPost.isEdited
                    post.lastUpdated = editedPost.lastUpdated
                }
                return post
            })
            return { ...state, posts: [...postsObjectEdit] };
        }
        case ACTIONS.DELETE_POST: {
            const postId = action.payload.postId
            const postsObject = state.posts
            const filteredPosts = postsObject.filter(post => post.postId !== postId)
            return { ...state, posts: [...filteredPosts] };
        }
        case ACTIONS.LOAD_COMMENTS: {
            const commentsToAdd = action.payload.comments
            const postId = action.payload.postId
            const postsObject = state.posts
            postsObject.map(post => post.postId === postId ? post.comments = commentsToAdd : post)
            return { ...state, posts: [...postsObject] };
        }
        case ACTIONS.ADD_COMMENT: {
            const commentToAdd = action.payload
            const postsObject = state.posts
            postsObject.map(post => {
                if (post.postId === commentToAdd.postId) {
                    // post.comments!.push(commentToAdd)
                    post.comments!.unshift(commentToAdd)
                    post.numberOfComments++
                }
                return post
            })
            return { ...state, posts: [...postsObject] };
        }
        case ACTIONS.EDIT_COMMENT: {
            const editedComment = action.payload.editedComment
            const postsObject = state.posts

            postsObject.map(post => {
                if (post.postId === editedComment.postId) {
                    post.comments!.map(comment => {
                        if (comment.commentId === editedComment.commentId) {
                            comment.commentText = editedComment.commentText
                            comment.isEdited = editedComment.isEdited
                            comment.lastUpdated = editedComment.lastUpdated
                            comment.rawEditorContentJsonStr = editedComment.rawEditorContentJsonStr
                            comment.usersTagged = editedComment.usersTagged
                            comment.usersTaggedArray = editedComment.usersTaggedArray
                        }
                        return comment
                    })
                }
                return post
            })
            return { ...state, posts: [...postsObject] };
        }
        case ACTIONS.DELETE_COMMENT: {
            const postId = action.payload.postId
            const commentId = action.payload.commentId
            const postsObject = state.posts
            postsObject.map(post => {
                if (post.postId === postId) {
                    post.comments = post.comments!.filter(comment => comment.commentId !== commentId)
                    post.numberOfComments = post.numberOfComments - action.payload.numberOfCommentsDeleted
                }
                return post
            })
            return { ...state, posts: [...postsObject] };
        }
        case ACTIONS.LOAD_CHILD_COMMENTS: {
            // console.log('loading child comments...')
            const childComments = action.payload.childComments
            const postId = action.payload.postId
            const parentCommentId = action.payload.parentCommentId
            const postsObject = state.posts

            postsObject.map(post => {
                if (post.postId === postId) {
                    post.comments!.map(comment => {
                        if (comment.commentId === parentCommentId) {
                            comment.childComments = childComments
                            comment.loadedReplies = true
                        }
                        return comment
                    })
                }
                return post
            })
            return { ...state, posts: [...postsObject] };
        }
        case ACTIONS.ADD_CHILD_COMMENT: {
            const childToAdd = action.payload
            const postsObject = state.posts

            postsObject.map(post => {
                if (post.postId === childToAdd.postId) {
                    post.comments!.map(comment => {
                        if (comment.commentId === childToAdd.parentCommentId) {
                            comment.numberOfChildComments++
                            post.numberOfComments++
                            if (comment.childComments!?.length > 0) {
                                comment.childComments!.push(childToAdd)
                                // comment.childComments!.unshift(childToAdd)
                            } else {
                                // comment.isShowReplies = true
                                comment.childComments = [childToAdd]
                            }
                        }
                        return comment
                    })
                }
                return post
            })
            return { ...state, posts: [...postsObject] };
        }
        case ACTIONS.EDIT_CHILD_COMMENT: {
            // console.log('EDIT_CHILD_COMMENT')
            const editedComment = action.payload.editedComment
            const postsObject = state.posts

            postsObject.map(post => {
                if (post.postId === editedComment.postId) {
                    post.comments!.map(parent => {
                        if (parent.commentId === editedComment.parentCommentId) {
                            parent.childComments!.map(child => {
                                if (child.commentId === editedComment.commentId) {
                                    child.commentText = editedComment.commentText
                                    child.isEdited = editedComment.isEdited
                                    child.lastUpdated = editedComment.lastUpdated
                                    child.rawEditorContentJsonStr = editedComment.rawEditorContentJsonStr
                                    child.usersTagged = editedComment.usersTagged
                                    child.usersTaggedArray = editedComment.usersTaggedArray

                                }
                                return child
                            })
                        }
                        return parent
                    })
                }
                return post
            })
            return { ...state, posts: [...postsObject] };
        }
        case ACTIONS.DELETE_CHILD_COMMENT: {
            const postId = action.payload.postId
            const commentId = action.payload.commentId
            const parentCommentId = action.payload.parentCommentId
            const postsObject = state.posts
            postsObject.map(post => {
                post.numberOfComments--
                if (post.postId === postId) {
                    post.comments!.map(parent => {
                        if (parent.commentId === parentCommentId) {
                            parent.childComments = parent.childComments!.filter(child => child.commentId !== commentId)
                        }
                        return parent
                    })
                }
                return post
            })
            return { ...state, posts: [...postsObject] };
        }
        case ACTIONS.ADD_LIKE: {
            const postId = action.payload.postId
            const userId = action.payload.userId
            // console.log('ADD_LIKE', userId)
            const postsObject = state.posts
            postsObject.map(post => {
                if (post.postId === postId) {
                    if (post.usersWhoLikedArray) post.usersWhoLikedArray.push(userId)
                    if (post.usersWhoLikedArray == null) {
                        post.usersWhoLikedArray = [userId]
                    }
                }
                return post
            })
            return { ...state, posts: [...postsObject] }
        }
        case ACTIONS.REMOVE_LIKE: {
            const postId = action.payload.postId
            const userId = action.payload.userId
            // console.log('REMOVE-LIKE', userId)
            const postsObject = state.posts
            postsObject.map(post => {
                if (post.postId === postId && post.usersWhoLikedArray) {
                    post.usersWhoLikedArray = post.usersWhoLikedArray.filter(item => item !== userId)
                }
                return post
            })
            return { ...state, posts: [...postsObject] }
        }
        case ACTIONS.SAVE_CURRENT_USER: {
            // console.log('SAVE_CURRENT_USER')
            return { ...state, currentUser: { ...state.currentUser, ...action.payload } }
        }
        case ACTIONS.SET_CURRENT_USER_COMPANYFEED_ADMIN: {
            // console.log('SET_CURRENT_USER_COMPANYFEED_ADMIN', action.payload)
            return { ...state, currentUser: { ...state.currentUser, hasCompanyFeedAdminAccess: action.payload } }
        }
        case ACTIONS.SET_ALL_MENTION_SUGGESTIONS: {
            // console.log('SET_ALL_MENTION_SUGGESTIONS')
            return { ...state, allMentionSuggestions: action.payload }
        }
        case ACTIONS.BIRTHDAY_POST.LOAD_POST: {
            const birthdayPost = action.payload
            // console.log('BIRTHDAY_POST.LOAD_POST', birthdayPost)
            return { ...state, birthdayPost };
        }
        case ACTIONS.BIRTHDAY_POST.EDIT_POST: {
            const editedPost = action.payload
            // console.log('BIRTHDAY_POST.EDIT_POST', editedPost)
            let postObjectEdit = {
                ...state.birthdayPost,
                postText: editedPost.postText,
                rawEditorContentJsonStr: editedPost.rawEditorContentJsonStr,
                usersTagged: editedPost.usersTagged,
                usersTaggedArray: editedPost.usersTaggedArray,
                imagePath: editedPost.imagePath,
                imagePathArray: editedPost.imagePathArray,
                isEdited: editedPost.isEdited,
                lastUpdated: editedPost.lastUpdated,
            }
            return { ...state, birthdayPost: postObjectEdit };
        }
        case ACTIONS.BIRTHDAY_POST.DELETE_POST: {
            // console.log('BIRTHDAY_POST.DELETE_POST')
            return { ...state, birthdayPost: null };
        }
        case ACTIONS.BIRTHDAY_POST.LOAD_COMMENTS: {
            // console.log('BIRTHDAY_POST.LOAD_COMMENTS')
            const postObject = state.birthdayPost
            if (postObject) postObject.comments = action.payload.comments
            return { ...state, birthdayPost: postObject };
        }
        case ACTIONS.BIRTHDAY_POST.ADD_COMMENT: {
            // console.log('BIRTHDAY_POST.ADD_COMMENT')
            const commentToAdd = action.payload
            const postObject = state.birthdayPost
            if (postObject) {
                if (postObject.postId === commentToAdd.postId) {
                    // postObject.comments?.push(commentToAdd)
                    postObject.comments?.unshift(commentToAdd)
                    postObject.numberOfComments++
                }
            }
            return { ...state, birthdayPost: postObject };
        }
        case ACTIONS.BIRTHDAY_POST.EDIT_COMMENT: {
            // console.log('BIRTHDAY_POST.EDIT_COMMENT')
            const editedComment = action.payload.editedComment
            const postObject = state.birthdayPost
            if (postObject) {
                if (postObject.postId === editedComment.postId) {
                    postObject.comments?.map(comment => {
                        if (comment.commentId === editedComment.commentId) {
                            comment.commentText = editedComment.commentText
                            comment.isEdited = editedComment.isEdited
                            comment.lastUpdated = editedComment.lastUpdated
                            comment.rawEditorContentJsonStr = editedComment.rawEditorContentJsonStr
                            comment.usersTagged = editedComment.usersTagged
                            comment.usersTaggedArray = editedComment.usersTaggedArray
                        }
                        return comment
                    })
                }
            }
            return { ...state, birthdayPost: postObject };
        }
        case ACTIONS.BIRTHDAY_POST.DELETE_COMMENT: {
            // console.log('BIRTHDAY_POST.DELETE_COMMENT')
            const postId = action.payload.postId
            const commentId = action.payload.commentId
            const postObject = state.birthdayPost
            if (postObject) {
                if (postObject.postId === postId) {
                    postObject.comments = postObject.comments!.filter(comment => comment.commentId !== commentId)
                    postObject.numberOfComments = postObject.numberOfComments - action.payload.numberOfCommentsDeleted
                }
            }
            return { ...state, birthdayPost: postObject };
        }
        case ACTIONS.BIRTHDAY_POST.LOAD_CHILD_COMMENTS: {
            // console.log('BIRTHDAY_POST.LOAD_CHILD_COMMENTS')
            const childComments = action.payload.childComments
            const parentCommentId = action.payload.parentCommentId
            const postObject = state.birthdayPost
            if (postObject) {
                postObject.comments!.map(comment => {
                    if (comment.commentId === parentCommentId) {
                        comment.childComments = childComments
                        comment.loadedReplies = true
                    }
                    return comment
                })
            }
            return { ...state, birthdayPost: postObject };
        }
        case ACTIONS.BIRTHDAY_POST.ADD_CHILD_COMMENT: {
            // console.log('BIRTHDAY_POST.ADD_CHILD_COMMENT')
            const childToAdd = action.payload
            const postObject = state.birthdayPost
            if (postObject) {
                if (postObject.postId === childToAdd.postId) {
                    postObject.comments?.map(comment => {
                        if (comment.commentId === childToAdd.parentCommentId) {
                            comment.numberOfChildComments++
                            postObject.numberOfComments++
                            if (comment.childComments!?.length > 0) comment.childComments!.push(childToAdd)
                            // if (comment.childComments!?.length > 0) comment.childComments!.unshift(childToAdd)
                            else comment.childComments = [childToAdd]
                        }
                        return comment
                    })
                }
            }
            return { ...state, birthdayPost: postObject };
        }
        case ACTIONS.BIRTHDAY_POST.EDIT_CHILD_COMMENT: {
            // console.log('BIRTHDAY_POST.EDIT_CHILD_COMMENT')
            const editedComment = action.payload.editedComment
            const postObject = state.birthdayPost
            if (postObject) {
                if (postObject.postId === editedComment.postId) {
                    postObject.comments?.map(parent => {
                        if (parent.commentId === editedComment.parentCommentId) {
                            parent.childComments?.map(child => {
                                if (child.commentId === editedComment.commentId) {
                                    child.commentText = editedComment.commentText
                                    child.isEdited = editedComment.isEdited
                                    child.lastUpdated = editedComment.lastUpdated
                                    child.rawEditorContentJsonStr = editedComment.rawEditorContentJsonStr
                                    child.usersTagged = editedComment.usersTagged
                                    child.usersTaggedArray = editedComment.usersTaggedArray
                                }
                                return child
                            })
                        }
                        return parent
                    })
                }
            }
            return { ...state, birthdayPost: postObject };
        }
        case ACTIONS.BIRTHDAY_POST.DELETE_CHILD_COMMENT: {
            // console.log('BIRTHDAY_POST.DELETE_CHILD_COMMENT')
            const postId = action.payload.postId
            const commentId = action.payload.commentId
            const parentCommentId = action.payload.parentCommentId
            const postObject = state.birthdayPost
            if (postObject) {
                if (postObject.postId === postId) {
                    postObject.comments!.map(parent => {
                        if (parent.commentId === parentCommentId) {
                            parent.childComments = parent.childComments!.filter(child => child.commentId !== commentId)
                            postObject.numberOfComments--
                        }
                        return parent
                    })
                    postObject.comments = postObject.comments!.filter(comment => comment.commentId !== commentId)
                }
            }
            return { ...state, birthdayPost: postObject };
        }
        case ACTIONS.BIRTHDAY_POST.ADD_LIKE: {
            const postId = action.payload.postId
            const userId = action.payload.userId
            // console.log('BIRTHDAY_POST.ADD_LIKE', userId)
            const postObject = state.birthdayPost
            if (postObject) {
                if (postObject.postId === postId) {
                    if (postObject.usersWhoLikedArray) postObject.usersWhoLikedArray.push(userId)
                    if (postObject.usersWhoLikedArray == null) postObject.usersWhoLikedArray = [userId]
                }
            }
            return { ...state, birthdayPost: postObject }
        }
        case ACTIONS.BIRTHDAY_POST.REMOVE_LIKE: {
            const postId = action.payload.postId
            const userId = action.payload.userId
            // console.log('BIRTHDAY_POST.REMOVE_LIKE', userId)
            const postObject = state.birthdayPost
            if (postObject) {
                if (postObject.postId === postId && postObject.usersWhoLikedArray) {
                    postObject.usersWhoLikedArray = postObject.usersWhoLikedArray.filter(item => item !== userId)
                }
            }
            return { ...state, birthdayPost: postObject }
        }
        default:
            throw new Error("reducer error");
    }
}

export const CompanyFeedContext = React.createContext<{
    state: CompanyFeedState
    dispatch: React.Dispatch<{
        type: any;
        payload: any;
    }>
}>({
    state: new CompanyFeedState(),
    dispatch: () => undefined,
});

export default function CompanyFeed(props: RouteComponentProps<any>) {
    const [state, dispatch] = useReducer(reducer, new CompanyFeedState());
    const companyFeedSvc = new CompanyFeedService();
    const [hasCompanyFeedAdminAccess] = useState(
        props.userProfile.taskList.includes(TaskList.companyfeedadmin)
    );

    useEffect(() => {
        dispatch({ type: ACTIONS.SET_CURRENT_USER_COMPANYFEED_ADMIN, payload: hasCompanyFeedAdminAccess })
    }, [hasCompanyFeedAdminAccess])

    useEffect(() => {
        async function getCurrUserDetailsAndDispatch(userId: string) {
            const results = await companyFeedSvc.getCurrentUserDetails(userId)
            // console.log('GET CURRENT USER DETAILS', results.data)
            const { firstName, lastName, displayPicture } = results.data
            dispatch({
                type: ACTIONS.SAVE_CURRENT_USER, payload: {
                    userId, firstName, lastName, displayPicture
                }
            })
        }
        getCurrUserDetailsAndDispatch(props.userProfile.userID)
    }, [props.userProfile.userID])

    useEffect(() => {
        async function getAllMentionSuggestions() {
            // TODO:
            // instead of loading this everytime CompanyFeed is rendered,
            // save this locally or on server as a json file?
            // and change the json file when UserTable changes
            const results = await companyFeedSvc.getAllMentionSuggestions()
            for (let i = 0; i < results.length; i++) {
                results[i].id = results[i].userId
                results[i].name = toTitleCase(results[i].firstName.concat(' ', results[i].lastName))
                results[i].avatar = results[i].displayPicture
                // results[i].link = `http://localhost:3000/publicprofile/${results[i].userId}`
            }
            // console.log('all mention suggestions', results)
            dispatch({ type: ACTIONS.SET_ALL_MENTION_SUGGESTIONS, payload: results })
        }
        getAllMentionSuggestions()
    }, [])

    // Automate Birthday Post creation
    // ISSUE: This only runs when any TSLOS user enters /CompanyFeed, meaning if no one enters /CompanyFeed on the day of someone's Bday, the Post will not be created
    // TODO: Perhaps can fix this by creating Birthday Posts earlier in advance.

    // 1. Check if there's anyone's birthday today
    // 2. Check if Birthday Post has been created for them
    // If No, insert Birthday Post
    // If Yes, retrieve Birthday Post
    useEffect(() => {
        async function getBirthdayUsers() {
            const birthdayUsers = await companyFeedSvc.getBirthdayUsers()
            console.log('CompanyFeed.tsx getBirthdayUsers', birthdayUsers)
            return birthdayUsers
        }
        async function insertBirthdayPost(birthdayUsers: UserDetails[]) {
            let birthdayText = "Happy Birthday to "
            let createdEntityRanges: RawDraftEntityRange[] = []
            let createdEntityMap: any = {}
            let key = 0

            if (birthdayUsers.length === 1) {
                let mentionName = toTitleCase("@" + birthdayUsers[0].firstName + " " + birthdayUsers[0].lastName)
                createdEntityRanges.push({ "offset": birthdayText.length, "length": mentionName.length, "key": key })
                birthdayText = birthdayText + mentionName
                createdEntityMap[key.toString()] = {
                    type: "mention",
                    mutability: "SEGMENTED",
                    data: {
                        mention: {
                            ...birthdayUsers[0],
                            name: toTitleCase(birthdayUsers[0].firstName + " " + birthdayUsers[0].lastName),
                            avatar: birthdayUsers[0].displayPicture,
                        }
                    }
                }

            }
            if (birthdayUsers.length === 2) {
                let mentionName = toTitleCase("@" + birthdayUsers[0].firstName + " " + birthdayUsers[0].lastName)
                createdEntityRanges.push({ "offset": birthdayText.length, "length": mentionName.length, "key": key })
                createdEntityMap[key.toString()] = {
                    type: "mention",
                    mutability: "SEGMENTED",
                    data: {
                        mention: {
                            ...birthdayUsers[0],
                            name: toTitleCase(birthdayUsers[0].firstName + " " + birthdayUsers[0].lastName),
                            avatar: birthdayUsers[0].displayPicture,
                        }
                    }
                }
                birthdayText = birthdayText + mentionName + " and "
                key += 1

                mentionName = toTitleCase("@" + birthdayUsers[1].firstName + " " + birthdayUsers[1].lastName)
                createdEntityRanges.push({ "offset": birthdayText.length, "length": mentionName.length, "key": key })
                createdEntityMap[key.toString()] = {
                    type: "mention",
                    mutability: "SEGMENTED",
                    data: {
                        mention: {
                            ...birthdayUsers[1],
                            name: toTitleCase(birthdayUsers[1].firstName + " " + birthdayUsers[1].lastName),
                            avatar: birthdayUsers[1].displayPicture,
                        }
                    }
                }
                birthdayText = birthdayText + mentionName

            }
            if (birthdayUsers.length > 2) {
                for (let i = 0; i < birthdayUsers.length; i++) {
                    let mentionName: string

                    createdEntityMap[key.toString()] = {
                        type: "mention",
                        mutability: "SEGMENTED",
                        data: {
                            mention: {
                                ...birthdayUsers[i],
                                name: toTitleCase(birthdayUsers[i].firstName + " " + birthdayUsers[i].lastName),
                                avatar: birthdayUsers[i].displayPicture,
                            }
                        }
                    }

                    if (i < birthdayUsers.length - 1) {
                        mentionName = toTitleCase("@" + birthdayUsers[i].firstName + " " + birthdayUsers[i].lastName)
                        createdEntityRanges.push({ "offset": birthdayText.length, "length": mentionName.length, "key": key })
                        birthdayText = birthdayText + mentionName + ", "
                        key += 1
                    }

                    // last
                    if (i === birthdayUsers.length - 1) {
                        birthdayText = birthdayText + "and "
                        mentionName = toTitleCase("@" + birthdayUsers[i].firstName + " " + birthdayUsers[i].lastName)
                        createdEntityRanges.push({ "offset": birthdayText.length, "length": mentionName.length, "key": key })
                        birthdayText = birthdayText + mentionName
                    }
                }
            }

            // console.log('birthdayText', birthdayText)
            // console.log('createdEntityRanges', createdEntityRanges)
            // console.log('createdEntityMap', createdEntityMap)

            let editorState = EditorState.createWithContent(ContentState.createFromText(`${birthdayText}!`))
            let contentStateJson = convertToRaw(editorState.getCurrentContent())
            contentStateJson.blocks[0].entityRanges = [...createdEntityRanges]
            contentStateJson.entityMap = createdEntityMap
            // console.log('contentStateJson', contentStateJson)

            let usersTaggedArr = []
            for (let i = 0; i < birthdayUsers.length; i++) {
                usersTaggedArr.push(birthdayUsers[i].userId)
            }
            let usersTaggedJsonStr: string | null = null
            if (usersTaggedArr.length > 0) usersTaggedJsonStr = JSON.stringify(usersTaggedArr)

            const birthdayPost = {
                rawEditorContentJsonStr: JSON.stringify(contentStateJson),
                usersTagged: usersTaggedJsonStr,
                type: "Birthday",
            }
            const result = await companyFeedSvc.insertBirthdayPost(birthdayPost)
            return result
        }

        async function getTodayBirthdayPost() {
            const result = await companyFeedSvc.getTodayBirthdayPost()
            return result
        }

        getBirthdayUsers().then(birthdayUsers => {
            if (birthdayUsers.length > 0) {
                getTodayBirthdayPost().then(result => {
                    if (result !== "") {
                        dispatch({ type: ACTIONS.BIRTHDAY_POST.LOAD_POST, payload: result })
                        return
                    }
                    if (result === "") {
                        insertBirthdayPost(birthdayUsers).then(result => {
                            dispatch({ type: ACTIONS.BIRTHDAY_POST.LOAD_POST, payload: result })
                        })
                        return
                    }
                })
            }
        })
    }, [])

    return (
        <CompanyFeedContext.Provider value={{ state, dispatch }}>
            <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet" />
            <div className="grid-container background-grey">
                <CreatePost />
                <TodayBirthdayPost post={state.birthdayPost} />
                <div>
                    <PostsContainer
                        posts={state?.posts}
                        page={state?.page}
                        hasMore={state?.hasMore}
                        fromDashboard={props.others ? props.others.fromDashboard : false}
                    />
                </div>
            </div>
        </CompanyFeedContext.Provider>
    )
}
