import { Button } from "primereact/button";
import { Checkbox } from "primereact/components/checkbox/Checkbox";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import useForm from "react-hook-form";
import { useParams } from "react-router";
import ReactTooltip from "react-tooltip";
import { AnnouncementService } from "../../service/AnnouncementService";
import {
  CommonMethods,
  DropdownModel,
  ParamIDTypes,
  RouteComponentProps
} from "../resources/CommonMethods";
import { ToastStateContext } from "../resources/ToastContext";
import { IAnnouncementDocumentModel } from "./Announcement";
import { TaskList } from "../resources/ListOfTasks";

export function CreateAnnouncement(props: RouteComponentProps<any>) {
  const notificationService = new AnnouncementService();
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    reset,
  } = useForm();
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const commonMethods = new CommonMethods();
  const [formType, setFormType] = useState("create");
  const [appliedLevel, setAppliedLevel] = useState<DropdownModel[]>();

  const { id } = useParams<ParamIDTypes>();
  useEffect(() => {
    getAnnouncement();
    checkPerms();
  }, []);

  async function getAnnouncement() {
    if (id !== undefined) {
      await notificationService
        .getAnnouncementById(id)
        .then((res: IAnnouncementDocumentModel) => {
          // setAnnouncement(res);
          setFormType("update");

          register({ name: "announcementID", type: "string" });
          register({ name: "active", type: "boolean" });
          register({
            name: "summary",
            type: "string",
            required: true,
            max: 50,
            maxLength: {
              value: 50,
              message: "Header cannot be longer than 15 characters!",
            },
          });
          register({
            name: "detail",
            type: "string",
            required: true,
            max: 15,
            maxLength: {
              value: 180,
              message: "Content cannot be longer than 180 characters!",
            },
          });
          register({ name: "severity", type: "string" });
          register({ name: "appliedLevel", type: "string" });

          setValue("announcementID", res.announcementID);
          setValue("summary", res.summary);
          setValue("detail", res.detail);
          setValue("severity", res.severity);
          setValue("appliedLevel", res.appliedLevel);
          setValue("active", res.active);
        });
    } else {
      register({ name: "active", type: "custom" });
      setValue("active", true);
    }
  }

  const checkPerms = () => {
    let dropdown: DropdownModel[] = [];
    if (commonMethods.isPropsLoaded(props)) {
      if (props.userProfile.taskList !== undefined) {
        if (
          commonMethods.isArrayPresentInArray(props.userProfile.taskList, [
            TaskList.groupannouncement,
          ])
        ) {
          dropdown.push({ label: "Group Wide", value: "group" });
        }
        if (
          commonMethods.isArrayPresentInArray(props.userProfile.taskList, [
            TaskList.companyannouncement,
          ])
        ) {
          dropdown.push({ label: "Company Wide", value: "company" });
        }
        if (
          commonMethods.isArrayPresentInArray(props.userProfile.taskList, [
            TaskList.deptannouncement,
          ])
        ) {
          dropdown.push({
            label: "Department Wide",
            value: "department",
          });
        }
      }
    }
    setAppliedLevel(dropdown);
  };
  const onSubmit = (data: any) => {
    if (formType === "create") {
      notificationService
        .createAnnouncement(data)
        .then(() => {
          reset();
          showSuccess();
          SetSuccess("Announcement has been created");
          // cheat
          setTimeout(function () {
            window.location.reload(false);
          }, 2000);
        })
        .catch(() => SetError("Unable to make announcement"));
    } else if (formType === "update") {
      notificationService
        .updateAnnouncement(data)
        .then(() => {
          SetSuccess("Announcement has been updated");
          window.location.replace("/announcements");
        })
        .catch(() => SetError("Unable to update announcement"));
    }
  };

  const severityOptions = [
    { label: "Green", value: "success" },
    { label: "Blue", value: "info" },
    { label: "Yellow", value: "warn" },
    { label: "Red", value: "error" },
  ];

  const handleSeverityChange = (e: any) => {
    register({ name: "severity", type: "custom" }, e.target.value);
    setValue("severity", e.target.value);
  };

  const handleAppliedLevelChange = (e: any) => {
    register({ name: "appliedLevel", type: "custom" }, e.target.value);
    setValue("appliedLevel", e.target.value);
  };

  const handleActiveChange = (e: any) => {
    setValue("active", e.checked);
  };

  const showSuccess = () => {
    SetSuccess("Your announcement has been made!");
  };

  return (
    <>
      {/* {redirect ? <Redirect push to={url} /> : null} */}
      <h1 style={{ marginTop: 0 }}>Make An Announcement</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-grid">
          <div className="p-col-12 p-fluid">
            <span className="boldLabel">Header </span> <span className="requiredRed">*</span>
            <input
              name="summary"
              className="p-inputtext"
              maxLength={50}
              ref={register}
              required
            />
            <span className="text-danger">
              {errors.summary && errors.summary.message}
            </span>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-12 p-fluid">
            <span className="boldLabel">Content </span><span className="requiredRed">*</span>
            <textarea
              name="detail"
              maxLength={180}
              ref={register}
              className="p-inputtextarea"
              rows={5}
              cols={30}
              required
            />
            <span className="text-danger">
              {errors.detail && errors.detail.message}
            </span>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-12 p-fluid">
            <span className="boldLabel">Applied Level </span><span className="requiredRed">*</span>
            <Dropdown
              value={getValues()["appliedLevel"]}
              options={appliedLevel}
              onChange={(e) => {
                handleAppliedLevelChange(e);
              }}
              placeholder="Select applied level"
              required
            />
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-12 p-fluid">
            <span className="boldLabel">Background Colour </span><span className="requiredRed">*</span>
            <Dropdown
              value={getValues()["severity"]}
              options={severityOptions}
              onChange={(e) => {
                handleSeverityChange(e);
              }}
              placeholder="Select background colour"
              required
            />
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-12 p-fluid">
            <span className="boldLabel">
              Active{" "}
              <i
                className="fas fa-info-circle informationStyle"
                data-tip=""
                data-for="activeCheck"
              ></i>{" "}
            </span>
            <ReactTooltip
              id="activeCheck"
              aria-haspopup="true"
              className="tooltipFont"
            >
              <p className="text-center">
                If checked, an announcement banner will be created.
              </p>
            </ReactTooltip>
            <div>
              <Checkbox
                name="Active"
                onChange={(e) => handleActiveChange(e)}
                checked={getValues()["active"]}
              ></Checkbox>
            </div>
          </div>
        </div>

        <div className="p-grid p-justify-center pt-4">
          <Button
            type="submit"
            label={formType === "create" ? "Submit" : "Update"}
          />
        </div>
      </form>
    </>
  );
}

export function AnnouncementEditPage(props: any) {

  useEffect(() => {
 
  }, []);

  return (
    <div className="row">
      <div className="col-12 col-md-8 col-lg-6 col-xl-4">
        <div className="card">
          <CreateAnnouncement {...props}></CreateAnnouncement>
        </div>
      </div>
    </div>
  );
}
