import React from "react";
import { RouteComponentProps } from "../resources/CommonMethods";
import { LeaveAppliedDeptView } from "./LeaveAppliedDeptView";
import { LeaveBalanceDeptView } from "./LeaveBalanceDeptView";

export function LeaveOverviewDepts(props: RouteComponentProps<any>) {
  return (
    <>
      <LeaveBalanceDeptView {...props} />
      <LeaveAppliedDeptView {...props} />
    </>
  );
}
