import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router";
import { PayslipService } from "../../service/PayslipService";
import {
  CommonMethods,
  DropdownModel,
  RouteComponentProps,
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { PayItemModel } from "../resources/ExportClass";
import { ToastStateContext } from "../resources/ToastContext";

function PayItemType(props: RouteComponentProps<any>) {
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const payslipService = new PayslipService();
  const commonMethods = new CommonMethods();

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [payItemTypes, setPayItemTypes] = useState([]);
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [dialogStr, setDialogStr] = useState<string>("");
  const [globalFilter, setGlobalFilter] = useState<string>();

  const [tableDataChanged, setTableDataChanged] = useState(false);
  const [payItemTypeData, setPayItemTypeData] = useState(new PayItemModel());
  const [cpfTypesDropdown, setCPFTypesDropdown] = useState<
    Array<DropdownModel>
  >([]);
  const [categoriesDropdown, setCategoriesDropdown] = useState<
    Array<DropdownModel>
  >([]);
  const [ir8aDropdown, setIR8ADropdown] = useState<Array<DropdownModel>>([]);
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const dt = useRef<any>(null);
  const [ir8aFilterValue, setIr8aFilterValue] = useState(null);
  const [cpfTypeFilterValue, setCpfTypeFilterValue] = useState(null);
  const [categoriesFilterValue, setCategoriesFilterValue] = useState(null);
   

  useEffect(() => {
    payslipService
      .getAllPayItemTypes()
      .then((res) => {
        setPayItemTypes(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsLoading(false);
        setIsError(true);
      });
    getDropdown();
  }, [tableDataChanged]);

  const setDialog = (header: string, content: string) => {
    setConfirmStr(header);
    setDialogStr(content);
  };

  const deletePayItemType = () => {
    payslipService
      .deletePayItemType(payItemTypeData.payItemID)
      .then(() => {
        setTableDataChanged(!tableDataChanged);
        setDialogVisibility(false);
        SetSuccess(
          `Pay Item Type ${payItemTypeData.payItemName} has been deleted.`
        );
      })
      .catch((err) => {
        setDialogVisibility(false);
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  const getDropdown = () => {
    try {
      payslipService.getCPFTypesDropdownOptions().then((res) => {
        setCPFTypesDropdown(res);
      });

      payslipService.getPayItemCategoryDropdownOptions().then((res) => {
        setCategoriesDropdown(res);
      });

      payslipService.getIR8ADropdownOptions().then((res) => {
        setIR8ADropdown(res);
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(commonMethods.getErrorMessage(e));
      setIsError(true);
    }
  };

  const globalSearchBar = (
    <>
      <span className="p-input-icon-left mr-1 mb-1">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e) => setGlobalFilter(e.currentTarget.value)}
        />
      </span>
    </>
  );

  const ir8aFilter = (
    <Dropdown
      showClear
      className="mb-1 mr-1"
      placeholder="Filter by IR8A Code"
      value={ir8aFilterValue}
      options={ir8aDropdown}
      onChange={(e) => {
        setIr8aFilterValue(e.value);
        onIr8aChange(e);
      }}
    />
  );

  const onIr8aChange = ((e: any) => {
    dt.current.filter(e.value, 'iR8ACode', 'equals');
  });

  const cpfTypeFilter = (
    <Dropdown
      showClear
      className="mb-1 mr-1"
      placeholder="Filter by CPF Type"
      value={cpfTypeFilterValue}
      options={cpfTypesDropdown}
      onChange={(e) => {
        setCpfTypeFilterValue(e.value);
        onCpfTypeChange(e);
      }}
    />
  );

  const onCpfTypeChange = ((e: any) => {
    dt.current.filter(e.value, 'cpfType', 'equals');
  });

  const categoriesFilter = (
    <Dropdown
      showClear
      placeholder="Filter by Category"
      value={categoriesFilterValue}
      options={categoriesDropdown}
      onChange={(e) => {
        setCategoriesFilterValue(e.value);
        onCategoriesChange(e);
      }}
    />
  );

  const onCategoriesChange = ((e: any) => {
    dt.current.filter(e.value, 'category', 'equals');
  });

  const adminActionTemplate = (rowData: PayItemModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            setPayItemTypeData(rowData);
            setRedirectOut(true);
            setRedirectPath(
              "/finance/pay_item_type/update/" + rowData.payItemID
            );
          }}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setPayItemTypeData(rowData);
            setDialog(
              `Delete ${rowData.payItemName}`,
              `Are you sure you want to delete this pay item type? `
            );
            setDialogVisibility(true);
          }}
        />
      </div>
    );
  };
  const isCPFPayableTemplate = (rowData: PayItemModel) => {
    if (rowData.isCPFPayable) {
      return (
        <i className="pi pi-check p-mr-2"></i>
      );
    } else {
      return(<i className="pi pi-times p-mr-2"></i>);
    }
  };

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMessage} />;
    } else {
      return (
        <>
          <div className="row mb-2">
            <div className="col">
              {globalSearchBar}
              {ir8aFilter}
              {cpfTypeFilter}
              {categoriesFilter}
            </div>
          </div>
          <span className="datatable-centerHeader datatable-centerContent">
            <DataTable
              ref={dt}
              value={payItemTypes}
              emptyMessage="No results found."
              autoLayout={true}
              globalFilter={globalFilter}
            >
              <Column field="payItemName" header="Name" />
              <Column field="iR8ACode" header="IR8A Code" filterElement={ir8aFilter} />
              <Column field="cpfType" header="CPF Type" filterElement={cpfTypeFilter} />
              <Column field="category" header="Category" filterElement={categoriesFilter}/>
              <Column body={isCPFPayableTemplate} header="Is CPF Payable?" />
              {/* <Column
                      field="payItemStatus"
                      header="Status"
                      className="p-col-1"
                    /> */}
              <Column body={adminActionTemplate} header="Actions" />
            </DataTable>
          </span>
        </>
      );
    }
  };

  return (
    <div className="row">
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        //dismissableMask={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                deletePayItemType();
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>

      <div
        className="col-12 col-xl-10"
        data-tour-id="pay-items"
        data-tour="Pay Items are used for additions and deductions in payslips. Tie them to the respective IR8A codes."
      >
        <div className="card">
          <div className="pb-2">
            <h1 className="pb-2">
              Pay Item Types{" "}
              <span className="pl-3">
                <Button
                  label="Add"
                  icon="pi pi-plus"
                  className="p-button-danger"
                  onClick={() => {
                    setRedirectOut(true);
                    setRedirectPath("/finance/pay_item_type/create");
                  }}
                />
              </span>
            </h1>
            <p>
              Customise and track the items on your employee's payslips according to IRAS IR8A form items. (E.g.
              Insurance, Transport Allowance, etc.)
            </p>
            {display()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayItemType;
