import { HttpClient } from "./HttpClient";
import { CommonMethods } from "../components/resources/CommonMethods";
export class LoginService {
  httpClient: HttpClient;
  commonMethods: CommonMethods;

  constructor() {
    this.httpClient = new HttpClient();
    this.commonMethods = new CommonMethods();
  }

  async login(loginobj: any) {
    const res = await this.httpClient.loginpost(
      "/api/authentication/authenticate",
      loginobj
    );
    return res;
  }

  async forgetpassword(email: any) {
    const res = await this.httpClient.loginpost(
      "/api/authentication/forgetpassword",
      email
    );
    return res;
  }

  async checkIsLoggedIn() {
    return this.httpClient.get("/api/authentication");
  }

  async logout() {
    localStorage.removeItem("currentAccessingCompany");
    localStorage.removeItem("currentAccessingCompanyName");
    return this.httpClient.get("/api/authentication/logout");
  }

  async changePassword(passwordModel: any) {
    const res = await this.httpClient.put("/api/user/pw", passwordModel);
    return res;
  }
}
