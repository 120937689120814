import { convertObjToFormData } from "../components/resources/CommonMethodsServices";
import { TaxBodyModel } from "../components/taxfiling/TaxFilingModel";
import { HttpClient } from "./HttpClient";
export class TaxFilingService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  // Dashboard
  async getFormTypeDropdown() {
    const res = await this.httpClient.get("/api/taxfiling/header/formtypes");
    return res;
  }

  async getFileTypeDropdown() {
    const res = await this.httpClient.get("/api/taxfiling/header/filetypes");
    return res;
  }

  async getAllTaxHeaders(companyid: string) {
    const res = await this.httpClient.get(
      "/api/taxfiling/company/" + companyid
    );
    return res;
  }

  async createTax(tax: any) {
    const res = await this.httpClient.post("/api/taxfiling/header", tax);
    return res;
  }

  async deleteTax(taxid: string) {
    const res = await this.httpClient.delete("/api/taxfiling/header/" + taxid);
    return res;
  }

  // Header
  async getHeaderDetails(taxheaderid: string) {
    const res = await this.httpClient.get(
      "/api/taxfiling/header/" + taxheaderid
    );
    return res;
  }

  async updateHeaderDetails(header: any) {
    const res = await this.httpClient.patch("/api/taxfiling/header", header);
    return res;
  }

  // Body
  async getPayrollCheck(year: number, companyid: string) {
    const res = await this.httpClient.get(
      "/api/taxfiling/checkpayrolls/" + year + "/" + companyid
    );
    return res;
  }

  async getEmployeeList(taxheaderid: string) {
    const res = await this.httpClient.get(
      "/api/dropdown/user/company/tax/" + taxheaderid
    );
    return res;
  }

  async createBody(taxBody: any) {
    const res = await this.httpClient.post("/api/taxfiling/body", taxBody);
    return res;
  }

  async getAllBodyDetails(taxHeaderId: string) {
    const res = await this.httpClient.get("/api/taxfiling/body/" + taxHeaderId);
    return res;
  }

  async getOneBodyDetails(taxbodyid: string) {
    const res = await this.httpClient.get(
      "/api/taxfiling/body/user/" + taxbodyid
    );
    return res;
  }

  async updateOneBodyDetails(taxBody: TaxBodyModel) {
    const res = await this.httpClient.put(
      "/api/taxfiling/body",
      convertObjToFormData(taxBody)
    );
    return res;
  }

  async calculateAndValidateOneBody(taxBody: TaxBodyModel) {
    const res = await this.httpClient.put(
      "/api/taxfiling/body/calculate",
      convertObjToFormData(taxBody)
    );
    return res;
  }

  async resetBody(taxHeaderId: string) {
    const res = await this.httpClient.delete(
      "/api/taxfiling/reset/" + taxHeaderId
    );
    return res;
  }

  // async deletePerson(taxBodyId: string) {
  //   const res = await this.httpClient.delete(
  //     "/api/taxfiling/delete/" + taxBodyId
  //   );
  //   return res;
  // }

  async GetCorppassRedirectUrl(taxHeaderId: string) {
    const res = await this.httpClient.get("/api/IR8ASubmission/" + taxHeaderId);
    return res;
  }

  // async test(taxHeaderId: string) {
  //   const res = await this.httpClient.get("/api/IR8ASubmission/test/" + taxHeaderId);
  //   return res;
  // }

  async submitBody(stateid: string) {
    const res = await this.httpClient.post(
      "/api/IR8ASubmission/submit",
      convertObjToFormData({
        state: stateid,
      })
    );
    return res;
  }

  async getTrailer(taxheaderid: string) {
    const res = await this.httpClient.get(
      "/api/taxfiling/trailer/" + taxheaderid
    );
    return res;
  }

  async getSubmissionDetails(submissionid: string) {
    const res = await this.httpClient.get(
      "/api/taxfiling/submission/" + submissionid
    );
    return res;
  }

  async getAllSubmissionDetails(taxheaderid: string) {
    const res = await this.httpClient.get(
      "/api/taxfiling/submission/all/" + taxheaderid
    );
    return res;
  }

}
