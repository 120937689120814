import { HttpClient } from "./HttpClient";
import { MultipleEquipmentLoanModel } from "../components/resources/ExportClass";
export class ChronosService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  /**
   * [GET REQUEST] Get All the Status Type
   * @return Array<object> LogisticsStatusTypeModel
   */
  async getChronosEquipment() {
    const res = await this.httpClient.get("/api/chronos");
    return res;
  }

    /**
   * [GET REQUEST] Get List of Users Withdraw Equipment
   * @return Array<EquipmentsModel> EquipmentsModel
   */
  async getListOfUsersWithdrawnEquipment(UserID:string) {
    const res = await this.httpClient.get(`api/chronos/withdrawn/${UserID}`);
    return res;
  }

  /**
   * [GET REQUEST] Get Equipment Avaiability
   * @return Array<EquipmentsModel> EquipmentsModel
   */
  async getEquipmentAvailability(ActionType:string, SerialNumber:string) {
    const res = await this.httpClient.get(`/api/chronos/${ActionType}/equip/${SerialNumber}`);
    return res;
  }

   /**
   * [POST REQUEST] Post Equipment Loan
   * @param MultipleEquipmentLoanModel multipleObj
   * @return Array<object> LogisticsStatusTypeModel
   */
  async postEquipmentLoan(multipleObj:MultipleEquipmentLoanModel) {
    const res = await this.httpClient.post("/api/chronos/move",multipleObj);
    return res;
  }

  
}