import moment from "moment";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { OverlayPanel } from "primereact/overlaypanel";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState, Fragment } from "react";
import {
  RoomBookingModel,
  RoomBookingService,
} from "../../service/RoomBookingService";
import { StatusRecordService } from "../../service/StatusRecordService";
import { StatusService } from "../../service/StatusService";
import WeeklyDutiesService from "../../service/WeeklyDutiesService";
import { UserService } from "../../service/UserService";
import { CommonMethods, DropdownModel } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { NoticeboardSettingModel } from "../resources/ExportClass";
import { StatusRecordDisplayObj } from "../statusrecord/LeaveApplicationForm";
import { WeeklyDutiesModel } from "../weeklyduties/WeeklyDuties";
import "./../../layout/sass/_parallax.scss";
import { Dropdown } from "primereact/dropdown";
import ReactTooltip from "react-tooltip";


function Noticeboard() {
  useEffect(() => {
    document.body.classList.add("noticeboard");
    var topbar = document.getElementById("nav-topbar");
    if (topbar !== null) {
      topbar.style.background = "#000000";
    }
  }, []);
  useEffect(
    () => () => {
      document.body.classList.remove("noticeboard");
      var topbar = document.getElementById("nav-topbar");
      if (topbar !== null) {
        topbar.style.removeProperty("background");
      }
    },
    []
  );

  let op: OverlayPanel | null;
  const [time, setTime] = useState(<></>);
  const [displayDate, setDisplayDate] = useState(<> </>);
  const [displayDay, setDisplayDay] = useState(<> </>);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    "Unable to display noticeboard."
  );
  const [listOfRooms, setListOfRooms] = useState<Array<DropdownModel>>(
    new Array<DropdownModel>()
  );

  const [listOfRoomBookings, setListOfRoomBookings] = useState<
    Array<RoomBookingModel>
  >(new Array<RoomBookingModel>());

  const [listOfLeaveRecords, setListOfLeaveRecords] = useState<
    Array<StatusRecordDisplayObj>
  >(new Array<StatusRecordDisplayObj>());

  const [listOfLeaveTypes, setListOfLeaveTypes] = useState<
    Array<DropdownModel>
  >(new Array<DropdownModel>());

  const [listOfWeeklyDuties, setListOfWeeklyDuties] = useState<
    Array<WeeklyDutiesModel>
  >(new Array<WeeklyDutiesModel>());

  const [displayOtherLeaves, setDisplayOtherLeaves] = useState<JSX.Element[]>(
    []
  );

  const [displayOOOrecords, setDisplayOOOrecords] = useState<JSX.Element[]>([]);

  // const [displayMCrecords, setDisplayMCrecords] = useState();

  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const [companyListDropdown, setCompanyListDropdown] = useState<
    Array<DropdownModel>
  >(new Array<DropdownModel>());
  const [shownCompanySettings, setShownCompanySettings] = useState<
    NoticeboardSettingModel
  >(new NoticeboardSettingModel());

  const [settingsChanged, setSettingsChanged] = useState<boolean>(false);

  const roomBookingService = new RoomBookingService();
  const statusRecordService = new StatusRecordService();
  const statusService = new StatusService();
  const commonMethods = new CommonMethods();
  let weeklyDutyService = new WeeklyDutiesService();

  const [selectedAward, setSelectedAward] = useState<number>(0);
  let allAwards = commonMethods.getAllAwards();
  let awardDropdown = commonMethods.getAwardDropdown();

  // get all for the day
  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    getAllForTheDay(myAbortController.signal.aborted);

    return () => {
      myAbortController.abort();
    };
  }, [currentDate]);

  // reload page after settings changed
  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    getAllForTheDay(myAbortController.signal.aborted);
    setSettingsChanged(false);
    return () => {
      myAbortController.abort();
    };
  }, [settingsChanged]);

  // runs every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    organizeLeaveTypes(myAbortController.signal.aborted);

    return () => {
      myAbortController.abort();
    };
  }, [listOfLeaveTypes, listOfLeaveRecords]);

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    getTime(myAbortController.signal.aborted);

    return () => {
      myAbortController.abort();
    };
  }, []);

  const getWeeklyDutyToday = (dateToLoad: Date, abortSignal: boolean) => {
    weeklyDutyService
      .getWeeklyDutyToday(dateToLoad)
      .then((res) => {
        if (abortSignal) {
          return;
        }
        setListOfWeeklyDuties(res);
      })
      .catch((error) => {
        if (abortSignal) {
          return;
        }
        let errorMsg = commonMethods.getErrorMessage(error);
        setErrorMessage(errorMsg);
        setIsError(true);
      });
  };

  const getRoomBooking = (dateToLoad: Date, abortSignal: boolean) => {
    roomBookingService
      .getAllBookingsForDate(dateToLoad)
      .then(async (res) => {
        if (abortSignal) {
          return;
        }
        setListOfRoomBookings(res);
      })
      .catch((error) => {
        if (abortSignal) {
          return;
        }
        let errorMsg = commonMethods.getErrorMessage(error);
        setErrorMessage(errorMsg);
        setIsError(true);
      });
  };

  const getRoomDropdown = (abortSignal: boolean) => {
    roomBookingService
      .getRoomDropdownByGCID()
      .then((res) => {
        if (abortSignal) {
          return;
        }
        setListOfRooms(res);
      })
      .catch((error) => {
        if (abortSignal) {
          return;
        }
        let errorMsg = commonMethods.getErrorMessage(error);
        setErrorMessage(errorMsg);
        setIsError(true);
      });
  };

  const getPendingActiveLeaveOfEveryoneToday = (
    dateToLoad: Date,
    abortSignal: boolean
  ) => {
    statusRecordService
      .getAllPendingActiveLeaveToday(dateToLoad)
      .then((res) => {
        if (abortSignal) {
          return;
        }
        setListOfLeaveRecords(res);
      })
      .catch((error) => {
        if (abortSignal) {
          return;
        }
        let errorMsg = commonMethods.getErrorMessage(error);
        setErrorMessage(errorMsg);
        setIsError(true);
      });
  };

  const getLeaveTypesDropdown = (abortSignal: boolean) => {
    statusService
      .getStatusTypeDropdownAll()
      .then((res) => {
        if (abortSignal) {
          return;
        }
        setListOfLeaveTypes(res);
      })
      .catch((error) => {
        if (abortSignal) {
          return;
        }
        let errorMsg = commonMethods.getErrorMessage(error);
        setErrorMessage(errorMsg);
        setIsError(true);
      });
  };

  const getCompanyListByGCIDDropdown = (abortSignal: boolean) => {
    commonMethods
      .getCompanyDropdownListByGcid()
      .then((res) => {
        if (abortSignal) {
          return;
        }
        setCompanyListDropdown(res);
      })
      .catch((error) => {
        if (abortSignal) {
          return;
        }
        let errorMsg = commonMethods.getErrorMessage(error);
        setErrorMessage(errorMsg);
        setIsError(true);
      });
  };

  const getUserNoticeboardSettings = (abortSignal: boolean) => {
    commonMethods
      .getUserSettings("noticeboardSettings")
      .then((res) => {
        if (abortSignal) {
          return;
        }
        setShownCompanySettings(res);
      })
      .catch((error) => {
        if (abortSignal) {
          return;
        }
        let errorMsg = commonMethods.getErrorMessage(error);
        setErrorMessage(errorMsg);
        setIsError(true);
      });
  };

  const getAllForTheDay = (abortSignal: boolean) => {
    let startOfDayDate = commonMethods.setStartOfDayOfDate(currentDate);
    // let endOfDay = commonMethods.setEndOfDayOfDate(currentDate.toISOString());
    if (abortSignal) {
      return;
    }
    // setDisplayDate(<>{moment(startOfDayDate).format("Do MMMM YYYY")}</>);
    // setDisplayDay(<>{moment(startOfDayDate).format("dddd")}</>);

    setDisplayDate(<>{ commonMethods.displayDate_Words(startOfDayDate) }</>);
    setDisplayDay(<>{commonMethods.displayDay(startOfDayDate)}</>);

    getCompanyListByGCIDDropdown(abortSignal);
    getUserNoticeboardSettings(abortSignal);

    // setIsLoading(true);
    getRoomDropdown(abortSignal);
    getRoomBooking(startOfDayDate, abortSignal);
    getPendingActiveLeaveOfEveryoneToday(startOfDayDate, abortSignal);
    getLeaveTypesDropdown(abortSignal);
    getWeeklyDutyToday(startOfDayDate, abortSignal);
  };

  //    _
  //   | |    ___  __ _ __ __ ___
  //   | |__ / -_)/ _` |\ V // -_)
  //   |____|\___|\__,_| \_/ \___|
  //
  const organizeLeaveTypes = (abortSignal: boolean) => {
    let otherLeaves: JSX.Element[] = [];
    let leaveName = "";
    let oooRecords: JSX.Element[] = [];
    // let mcRecords: JSX.Element[] = [];

    listOfLeaveTypes.forEach((leaveType, index) => {
      let combined: JSX.Element = <></>;
      let eachLeaveTypeRecords;
      leaveName = leaveType.label;
      eachLeaveTypeRecords = listOfLeaveRecords.map(
        (leaveRecord: StatusRecordDisplayObj, index: number) => {
          return eachLeaveRecord(leaveName, leaveRecord, leaveType.value);
        }
      );

      if (
        eachLeaveTypeRecords[0] === undefined ||
        eachLeaveTypeRecords.every((item: any) => {
          return item === null;
        })
      ) {
        eachLeaveTypeRecords = (
          // <tr key={leaveName + index}>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
        );
      } else {
        // let leaveNameDisplay = (
        //   <tr key={leaveName}>
        //     <td className="py-2 text-center">
        //       <h5>{leaveName}</h5>
        //     </td>
        //   </tr>
        // );
        combined = (
          <table key={leaveType.label}>
            <tbody key={leaveType.label}>
              {leaveType.label !== "Out Of Office" &&
                leaveType.label !== "Outpatient Leave"}
                {/* && leaveNameDisplay */}
              {eachLeaveTypeRecords}
            </tbody>
          </table>
        );
      }

      if (leaveType.label === "Out Of Office") {
        oooRecords.push(combined);
      } else if (leaveType.label === "Overtime") {
        // dont care about overtime
      } else {
        otherLeaves.push(combined);
      }
    });

    if (abortSignal) {
      return;
    }

    setDisplayOOOrecords(oooRecords);
    setDisplayOtherLeaves(otherLeaves);
    setIsLoading(false);
  };

  const eachLeaveRecord = (
    leaveName: string,
    leaveRecord: StatusRecordDisplayObj,
    leaveId: string
  ) => {
    if (leaveId === leaveRecord.statusTypeID) {
      let displayDate = commonMethods.convertDatesForDisplayNoticeBoard(
        leaveName,
        leaveRecord.startDate,
        leaveRecord.endDate,
        leaveRecord.duration
      );

      let pendingRecord = false;

      if (leaveRecord.approvalStatus === "Pending") {
        pendingRecord = true;
      }

      return (
        <tr key={leaveRecord.statusRecordID}>
          <td>
            <p className="mb-1">
              <span className="boldLabel d-inline">
                {leaveRecord.fullDisplayName}{" "}
              </span>
              <span
                hidden={!pendingRecord}
                className="boldLabel mx-1 pendingText"
              >
                {"PENDING"}
              </span>
              <span style={{ float: "right" }}> {displayDate}</span>
            </p>
            <p className="noticeboardRemarks mb-0">{leaveRecord.remarks}</p>
          </td>
        </tr>
      );
    }
    return null;
  };

  const outOfOfficeBlock = () => {
    if (displayOOOrecords.length === 0) {
      return <> </>;
    }
    return (
      <div className="col-lg-4 col-12 p-2">
        <div className="card noticeboardCard">
          <h1> Out Of Office</h1>
          {displayOOO()}
        </div>
      </div>
    );
  };

  //    ___         _
  //   |   \  _  _ | |_  _  _
  //   | |) || || ||  _|| || |
  //   |___/  \_,_| \__| \_, |
  //                     |__/
  const weeklyDutyDisplay = () => {
    let arrToDisplay: JSX.Element[] = [];
    let dutyName = "";
    listOfWeeklyDuties.forEach((oneduty, index) => {
      let eachDutyDisplay;
      dutyName = oneduty.dutyName;
      let nameDisplay: JSX.Element[] = [];
      oneduty.employeesOnDutyForDisplay.forEach((person, index) => {
        nameDisplay.push(
          <Fragment key={person + index}>
            <span>
              {person}
              <br />
            </span>
          </Fragment>
        );
      });

      if (listOfWeeklyDuties.length === 0) {
        eachDutyDisplay = (
          <tr key="0">
            <td>
              <p></p>
            </td>
          </tr>
        );
      }
      let combined = (
        <tbody key={oneduty.dutyID}>
          <tr key={oneduty.dutyID + index}>
            <td>
              <h5 className="py-2 text-center">{dutyName}</h5>
              <p>{nameDisplay}</p>
            </td>
          </tr>
          {eachDutyDisplay}
        </tbody>
      );

      arrToDisplay.push(combined);
    });

    return <table>{arrToDisplay}</table>;
  };

  const weeklyDutyBlock = () => {
    if (listOfWeeklyDuties.length === 0) {
      return <></>;
    }

    return (
      <div className="col-12 p-2">
        <div className="card noticeboardCard">
          <h1> Weekly Duties </h1>
          {weeklyDutyDisplay()}
        </div>
      </div>
    );
  };

  //    ___
  //   | _ \ ___  ___  _ __
  //   |   // _ \/ _ \| '  \
  //   |_|_\\___/\___/|_|_|_|
  //
  const eachRoomBooking = (
    roomBookingItem: RoomBookingModel,
    roomId: string
  ) => {
    if (roomId === roomBookingItem.roomID) {
      return (
        <tr key={roomBookingItem.roomBookingID} className="p-col">
          <td>
            <p className="mb-0">
              {commonMethods.displayTime12Hour(roomBookingItem.startDateTime)}
              {" - "}
              {commonMethods.displayTime12Hour(roomBookingItem.endDateTime)}

              <span className="boldLabel mx-1">
                {roomBookingItem.firstName} {roomBookingItem.lastName}
              </span>
            </p>
            <p className="mb-0">
              <span className="noticeboardRemarks">
                {roomBookingItem.purpose}
              </span>
            </p>
          </td>
        </tr>
      );
    }
  };
  const roomDisplay = () => {
    let arrToDisplay: JSX.Element[] = [];
    let roomName = "";
    listOfRooms.forEach((roomItem, index) => {
      let eachRoomBookings;
      roomName = roomItem.label;

      eachRoomBookings = listOfRoomBookings.map(
        (roomBookingItem: RoomBookingModel) => {
          return eachRoomBooking(roomBookingItem, roomItem.value);
        }
      );

      if (eachRoomBookings.length === 0) {
        eachRoomBookings = (
          <tr key={roomItem.value + index} style={{ textAlign: "left" }}>
            <td>
              <p></p>
            </td>
          </tr>
        );
      }
      let combined = (
        <tbody key={index + roomItem.value}>
          <tr key={roomItem.value}>
            <td className="py-2 text-center">
              <h5>{roomName}</h5>
            </td>
          </tr>
          {eachRoomBookings}
        </tbody>
      );

      arrToDisplay.push(combined);
    });

    let allcombined = <table>{arrToDisplay}</table>;
    return <>{allcombined}</>;
  };

  const minusOneDay = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let current;
    current = moment(currentDate).subtract(1, "day");
    setCurrentDate(current.toDate());
  };

  const plusOneDay = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let current;
    current = moment(currentDate).add(1, "day");
    setCurrentDate(current.toDate());
    // setDisplayDate(<>{moment(current).format("MMMM Do YYYY")}</>);
    // setDisplayDay(<>{moment(current).format("dddd")}</>);
  };

  const todayDay = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    let current;
    current = moment(new Date());
    setCurrentDate(current.toDate());
    // setDisplayDate(<>{moment(current).format("MMMM Do YYYY")}</>);
    // setDisplayDay(<>{moment(current).format("dddd")}</>);
  };

  const displayOOO = () => {
    return <>{displayOOOrecords}</>;
  };

  const getTime = (abortSignal: boolean) => {
    if (abortSignal) {
      return;
    }
    setInterval(() => setTime(<>{moment().format("h:mm:ss A")}</>), 1000);
  };



  // Award Winners
  //
  //
  //
  //

  const displayWinners = (listOfWinners: any) => {
	let winnerDisplay: JSX.Element[] = [];
	listOfWinners.forEach((winner: any, index: number) => {
		if (index != (listOfWinners.length -1)) {
			winnerDisplay.push(
				<>
					<span
						className="noticeboardRemarks mb-0"
						data-tip=""
						data-for={winner.name + ", " + winner.type}
					>
						{winner.name}, {" "}
					</span>
					<ReactTooltip
						id={winner.name + ", " + winner.type}
						aria-haspopup="true"
						place="top"
						backgroundColor="white"
						className="opacity-100"
						textColor="black"
					>
					{winner.type}
					</ReactTooltip>
				</>
			)
		} else {
			winnerDisplay.push(
				<>
					<span
						className="noticeboardRemarks mb-0"
						data-tip=""
						data-for={winner.name + ", " + winner.type}
					>
						{winner.name}
					</span>
					<ReactTooltip
						id={winner.name + ", " + winner.type}
						aria-haspopup="true"
						place="top"
						backgroundColor="white"
						className="opacity-100"
						textColor="black"
					>
					{winner.type}
					</ReactTooltip>
				</>
			)
		}
	})

	// let winnerDisplay = "";
	// listOfWinners.forEach((winner: any, index: number)  => {
	// 	winnerDisplay += winner.name + ", ";
	// });
	// return winnerDisplay.slice(0, -2);
	return winnerDisplay;
  }

  const displayAwardWinners = (selectedAward: number) => {
	let display: JSX.Element[] = [];
	let award = allAwards[selectedAward]
	award.department.forEach((dept, index) => {
		display.push(
			<tr>
				<td>
					<p className="mb-1">
						<span className="boldLabel d-inline">
							{dept.name}{" "}
						</span>
					</p>
					{/* {displayWinners(department.winners)} */}
					<div>
						{displayWinners(dept.winners)}
					</div>
				</td>
			</tr>
		)
	});
	return display
  }


  //    ___ ___ _____ _____ ___ _  _  ___
  //   / __| __|_   _|_   _|_ _| \| |/ __|
  //   \__ \ _|  | |   | |  | || .` | (_ |
  //   |___/___| |_|   |_| |___|_|\_|\___|
  //

  const displayCompanyCB = () => {
    var display: Array<JSX.Element> = [];
    shownCompanySettings.settingValue.length >= 0 &&
      companyListDropdown.forEach((company, index) => {
        display.push(
          <Fragment key={"cb" + index + company}>
            <div className="p-col-12">
              <Checkbox
                inputId={index + "cb"}
                onChange={onCompanySelectSetting}
                checked={shownCompanySettings.settingValue.includes(
                  company.value
                )}
                value={company.value}
              />
              <label htmlFor={index + "cb"}  className="ml-1 mb-0">
                {company.label}
              </label>
            </div>
          </Fragment>
        );
      });
    return display;
  };

  const onCompanySelectSetting = (e: any) => {
    let selectedCompanies = shownCompanySettings.settingValue;
    if (e.checked) selectedCompanies.push(e.value);
    else selectedCompanies.splice(selectedCompanies.indexOf(e.value), 1);

    setShownCompanySettings((prevValue) => ({
      ...prevValue,
      settingValue: selectedCompanies,
    }));
  };

  const saveNBSettings = (e: any) => {
    var object: NoticeboardSettingModel = shownCompanySettings;
    object.settingKey = "noticeboardSettings";

    commonMethods
      .saveUserSettings("noticeboardSettings", object)
      .then((res) => {
        setShownCompanySettings(res);
      })
      .finally(() => setSettingsChanged(true));
  };

  //    ___ ___ ___ ___ _      ___   __
  //   |   \_ _/ __| _ \ |    /_\ \ / /
  //   | |) | |\__ \  _/ |__ / _ \ V /
  //   |___/___|___/_| |____/_/ \_\_|
  //

  const display = () => {
    return (
      <>
        <div className="col-12">
          <div className="row">
            <div className="col-lg-4 col-12 p-2">
              <div className="card noticeboardCard">
                <h1> Room Bookings</h1>
                {roomDisplay()}
              </div>
            </div>
            {outOfOfficeBlock()}
            <div className="col-lg-4 col-12 p-2">
              <div className="card noticeboardCard">
                <h1> On Leave</h1>
                {displayOtherLeaves}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const finaldisplay = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMessage} />;
    } else {
      return (
        <div
          data-tour-id="overall-noticeboard"
          data-tour="This provides a daily view of the whole company's movements, including room bookings."
        >
          {display()}
        </div>
      );
    }
  };

  return (
    <>
      <OverlayPanel
        ref={(el) => (op = el)}
        // appendTo={document.getElementById("body")}
        style={{ width: "20em" }}
      >
        <div className="p-grid">
          <div className="p-col-12">
            <b>Show Companies:</b>
          </div>
          {displayCompanyCB()}
          <div className="p-col-12">
            <Button
              type="button"
              label="Save"
              onClick={(e) => {
                saveNBSettings(e);
              }}
            />
          </div>
        </div>
      </OverlayPanel>
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />
      <div className="row">
        <div className="col-lg-3 col-12">
          <div className="col-12 text-center py-2 px-1">
            <div className="card noticeboardCard">
              <div
                id="datesButtonGroup"
                data-tour-id="chevron"
                data-tour="You can change the day if you wish."
              >
                <Button
                  type="button"
                  icon="pi pi-chevron-left"
                  tooltip="Previous Day"
                  style={{ backgroundColor: "inherit", border: "none", position: "absolute", marginRight: "130px" }}
                  tooltipOptions={{ position: "top" }}
                  onClick={minusOneDay}
                />
                <Button
                  type="button"
                  id="calendarIconTodayBtn"
                  className="mx-1"
                  icon="pi pi-calendar"
                  tooltip="Today"
                  tooltipOptions={{ position: "top" }}
                  style={{ margin:"0px" }}
                  onClick={todayDay}
                />
                <Button
                  type="button"
                  icon="pi pi-chevron-right"
                  tooltip="Next Day"
                  style={{ backgroundColor: "inherit", border: "none", position: "absolute", marginLeft: "130px" }}
                  tooltipOptions={{ position: "top" }}
                  onClick={plusOneDay}
                />
              </div>
              <div style={{ display: "block" }}>
                <p className="noticeboardTypeHeader my-2">
                  {displayDay}
                  <span style={{ display: "block", lineHeight: "0.75em" }}>
                    {displayDate}
                  </span>
                </p>
                <p
                  className="noticeboardTypeHeader mb-0"
                  style={{
                    fontSize: "2em",
                    fontWeight: 800,
                    letterSpacing: "0.15em",
                  }}
                >
                  {time}
                </p>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="button"
                  icon="pi pi-cog"
                  id="companySettingsGear"
                  data-tour-id="setting-gear"
                  data-tour="Select the companies you want to view here."
                  style={{ backgroundColor: "none" }}
                  onClick={(e) => {
                    op !== null && op.toggle(e);
                  }}s
                />
              </div>
            </div>
          </div>

			<div className="col-12 py-2 px-1">
				<div className="card noticeboardCard">
					<h1>🏆 Hall of Fame</h1>
					<div className="mb-2">
						<Dropdown
							className="bg-transparent border-0 w-100"
							id="noticeboardDropdown"
							options={awardDropdown}
							value={selectedAward}
							onChange={(e) => {
								setSelectedAward(e.value)
							}}
						/>
					</div>
					{displayAwardWinners(selectedAward)}
				</div>
			</div>

          {weeklyDutyBlock()}
        </div>
        <div className="col-lg-9 col-12">{finaldisplay()}</div>
      </div>
    </>
  );
}

export default Noticeboard;
