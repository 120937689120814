import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";

import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

import {
  CommonMethods,
  RouteComponentProps,
  StatusTypes,
} from "../resources/CommonMethods";
import { RoomService } from "../../service/RoomService";
import { RoomModel } from "./RoomModel";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { InputText } from "primereact/inputtext";

function Room(props: RouteComponentProps<any>) {
  const commonMethods = new CommonMethods();
  const roomService = new RoomService();

  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");

  const [searchValue, setSearchValue] = useState<string>("");
  const [tableDataChanged, setTableDataChanged] = useState(false);
  const [tableData, setTableData] = useState<RoomModel[]>([]);
  const [roomData, setRoomData] = useState(new RoomModel());

  const [dialogStr, setDialogStr] = useState<string>("");
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [tableDataChanged]);

  const init = () => {
    roomService
      .getAllActiveAndInactiveRooms()
      .then((res) => setTableData(res))
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const setDialog = (header: string, content: string) => {
    setConfirmStr(header);
    setDialogStr(content);
  };

  const deleteRoom = () => {
    roomService
      .deleteRoom(roomData.roomID)
      .then(() => {
        setTableDataChanged(!tableDataChanged);
        setDialogVisibility(false);
        SetSuccess(` ${roomData.roomName} has been deleted.`);
      })
      .catch((err) => {
        setDialogVisibility(false);
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  const updateRoomStatus = (rowData: RoomModel, status: string) => {
    rowData.roomStatus = status;
    roomService
      .updateRoomStatus(rowData)
      .then(() => {
        SetSuccess(rowData.roomName + " is now " + rowData.roomStatus + ".");
      })
      .catch((err) => {
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  const statusTemplate = (rowData: RoomModel) => {
    return (
      <InputSwitch
        className="mt-2"
        tooltip={
          rowData.roomStatus === StatusTypes.active
            ? StatusTypes.active
            : StatusTypes.deactivated
        }
        tooltipOptions={{ position: "top" }}
        checked={rowData.roomStatus === StatusTypes.active}
        onChange={(e) => {
          updateRoomStatus(
            rowData,
            e.value ? StatusTypes.active : StatusTypes.deactivated
          );
        }}
      />
    );
  };

  const actionTemplate = (rowData: RoomModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Edit Room"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            setRoomData(rowData);
            setRedirectOut(true);
            setRedirectPath("/admin/room/update/" + rowData.roomID);
          }}
        />
        <Button
          tooltip="Delete Room"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setRoomData(rowData);
            setDialog(
              `Delete ${rowData.roomName}`,
              `Are you sure you want to delete this room? `
            );
            setDialogVisibility(true);
          }}
        />
      </div>
    );
  };

  let tableheader = (
    <div style={{ textAlign: "left" }}>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e: any) => setSearchValue(e.currentTarget.value)}
        />
      </span>
    </div>
  );  

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMessage} />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={tableData}
            paginator={true}
            rows={15}
            autoLayout={true}
            header={tableheader}
            globalFilter={searchValue}
            alwaysShowPaginator={false}
            emptyMessage="No results found."
          >
            <Column field="roomName" header="Room" />
            <Column field="roomOrder" header="Order" />
            <Column field="roomDescription" header="Description" />
            <Column field="roomStatus" header="Status" className="p-col-2" />
            <Column body={statusTemplate} className="p-col-0" />
            <Column body={actionTemplate} header="Actions" className="p-col-2" />
          </DataTable>
        </div>
      );
    }
  };

  return (
    <div className="row">
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                deleteRoom();
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>
      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">
            Rooms
            <span className="pl-3">
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={() => {
                  setRedirectOut(true);
                  setRedirectPath("/admin/room/create");
                }}
              />
            </span>
          </h1>
          <p>
            These are available for booking on the Room Booking page (E.g.,
            Meeting Room)
          </p>
          {display()}
        </div>
      </div>
    </div>
  );
}

export default Room;
