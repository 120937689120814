import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import * as React from "react";
import { Redirect } from "react-router";
import { ExternalCostService } from "../../service/ExternalCostService";
import AllClientCompanies from "../client/AllClientCompanies";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { throws } from "assert";

interface AppProps {}

interface AppState {
  externalCostList: [];
  externalCostModel: ExternalCostModel;

  redirectOut: boolean;
  redirectParam: string;
  redirectPath: string;

  isLoading: boolean;

  isViewFile: boolean;
  isDialogVisible: boolean;
  formHeader: string;
  action: string;

  isError: boolean;
  errorMsg: string;

  dialogStr: string;
  dialogHeaderStr: string;
  visible: boolean;

  infoDialogHeader: string;
  infoDialogDisplay: boolean;
  infoDialogStr: any;
}

export class ExternalCostModel {
  public externalCostID: string = CommonMethods.EmptyGuid;
  public clientCompanyID: string = CommonMethods.EmptyGuid;
  public companyID: string = CommonMethods.EmptyGuid;
  public costTypeID: string = CommonMethods.EmptyGuid;
  public companyName: string = "";
  public clientCompany: string = "";
  public costTypeName: string = "";
  public isRecurring: boolean = false;
  public externalCostAmount: number = 0;
  public externalCostRemarks: string = "";
  public paymentDate: Date = new Date();
  public invoiceDate: Date = new Date();
  public documentPath: string = "";
  public documentFile: any = null;
  public currency: string = "";
}

export class ExternalCost extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  commonMethods: CommonMethods;
  externalCostService: ExternalCostService;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      externalCostList: [],
      externalCostModel: new ExternalCostModel(),

      redirectOut: false,
      redirectParam: "",
      redirectPath: "",
      isLoading: true,

      isViewFile: false,
      isDialogVisible: false,
      formHeader: "",
      action: "",

      isError: false,
      errorMsg: "",
      dialogStr: "",
      dialogHeaderStr: "",
      visible: false,

      infoDialogHeader: "",
      infoDialogDisplay: false,
      infoDialogStr: <></>,
    };
    this.actionButton = this.actionButton.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.amountTemplate = this.amountTemplate.bind(this);
    this.displayPaymentDate = this.displayPaymentDate.bind(this);
    this.commonMethods = new CommonMethods();
    this.externalCostService = new ExternalCostService();
  }

  componentDidMount() {
    this.getFromDB();
  }

  getFromDB() {
    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";

    this.externalCostService
      .getExternalCostsByCompanyId(currentAccessingCompanyId)
      .then((res) => {
        this.setState({ externalCostList: res }, () => {
          this.setState({ isLoading: false });
        });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  showInfoDialog(rowData: ExternalCostModel) {
    this.setState({ infoDialogHeader: "Cost Details" });

    let html = (
      <>
        <table className="leave-info-dialog table">
          <tbody>
            <tr>
              <td className="font-weight-bold">Company:</td>
              <td>{rowData.companyName}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Payable To:</td>
              <td>{rowData.clientCompany}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Cost Type:</td>
              <td>{rowData.costTypeName}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Recurring:</td>
              <td>{this.recurringTemplate(rowData)}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Amount:</td>
              <td>
                {" "}
                {rowData.currency} {rowData.externalCostAmount}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">Remarks:</td>
              <td>{rowData.externalCostRemarks}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Payment Date:</td>
              <td>
                {this.commonMethods.displayDate(new Date(rowData.paymentDate))}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">Invoice Date:</td>
              <td>
                {this.commonMethods.displayDate(new Date(rowData.invoiceDate))}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );

    this.setState({ infoDialogStr: html });
    this.setState({ infoDialogDisplay: true });
  }

  recurringTemplate(rowData: ExternalCostModel) {
    if (rowData.isRecurring === true) {
      return "True";
    } else if (rowData.isRecurring === false) {
      return "False";
    }
  }

  amountTemplate(rowData: ExternalCostModel) {
    return `${rowData.currency} ${rowData.externalCostAmount}`;
  }

  actionButton(rowData: ExternalCostModel) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="View Details"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-eye"
          className="p-button-info"
          onClick={() => {
            this.showInfoDialog(rowData);
          }}
        />
        <Button
          disabled={
            rowData.documentPath === null || rowData.documentPath === ""
          }
          type="button"
          icon="pi pi-external-link"
          className="p-button-primary mx-1 button-document"
          tooltip="View Document"
          tooltipOptions={{ position: "top" }}
          onClick={() => {
            this.openDocument(rowData);
          }}
        />

        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          tooltip="Edit Cost"
          tooltipOptions={{ position: "top" }}
          onClick={() => {
            this.updateItem(rowData);
          }}
        />

        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          tooltip="Delete Cost"
          tooltipOptions={{ position: "top" }}
          onClick={() => {
            this.deleteItem(rowData);
          }}
        />
      </div>
    );
  }

  displayPaymentDate(rowData: ExternalCostModel) {
    return this.commonMethods.displayMonthYear(rowData.paymentDate);
  }

  openDocument(rowData: ExternalCostModel) {
    this.setState({
      // isViewFile: true,
      externalCostModel: rowData,
    });

    window.open(rowData.documentPath, "_blank");
  }

  updateItem(rowData: ExternalCostModel) {
    this.setState({
      redirectOut: true,
      redirectPath:
        "/finance/operational_cost/update/" + rowData.externalCostID,
    });
  }

  addItem() {
    this.setState({
      redirectOut: true,
      redirectPath: "/finance/operational_cost/create",
    });
  }

  deleteItem(rowData: ExternalCostModel) {
    this.setState({
      isDialogVisible: true,
      externalCostModel: rowData,
    });
  }

  confirmDelete() {
    let name = this.state.externalCostModel.costTypeName;
    this.setState({ isLoading: true }, () => {
      this.externalCostService
        .deleteExternalCost(this.state.externalCostModel.externalCostID)
        .then((res) => {
          this.context.SetSuccess(name + " has been deleted.");
          this.setState({
            isLoading: false,
            isDialogVisible: false,
          });
          this.getFromDB();
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.setState({
            isLoading: false,
            isDialogVisible: false,
          });
          this.context.SetError("Unable to delete " + name);
          return errorMsg;
        });
    });
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectPath,
            search: this.state.redirectParam,
          }}
          push
        />
      );
    }

    let data = (
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          value={this.state.externalCostList}
          paginator={true}
          rows={15}
          autoLayout={true}
          alwaysShowPaginator={false}
          emptyMessage="No results found."
        >
          <Column field="companyName" header="Company" />
          <Column field="costTypeName" header="Cost Type" />
          <Column body={this.amountTemplate} header="Amount" />
          <Column field="clientCompany" header="Payable To" />
          <Column body={this.displayPaymentDate} header="Month" />
          <Column className="p-col-2" body={this.actionButton} header="Action" />
        </DataTable>
      </div>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = data;
    }

    let externalCostDisplay = (
      <div className="row">
        <div
          className="col-12 col-xl-10"
          data-tour-id="add-operational-cost"
          data-tour="Operational costs added will appear on Boss Dashboard for easy tracking"
        >
          <div className="card">
            <h1 className="pb-2">
              Operational Costs
              <span className="pl-3">
                <Button
                  type="button"
                  icon="pi pi-plus"
                  iconPos="right"
                  label="Add"
                  className="p-button-danger"
                  onClick={() => {
                    this.addItem();
                  }}
                ></Button>
              </span>
            </h1>

            <p>
              Keep track of Operational Costs over time here (E.g. Office Space
              Rental, Maintenance Cost)
            </p>

            {display}

            <Dialog
              className="popupDialog"
              header={"Delete " + this.state.externalCostModel.costTypeName}
              visible={this.state.isDialogVisible}
              modal={true}
              onHide={() => this.setState({ isDialogVisible: false })}
              footer={
                <div>
                  <Button
                    label="Yes"
                    icon="pi pi-check"
                    onClick={() => this.confirmDelete()}
                  />
                  <Button
                    label="No"
                    icon="pi pi-times"
                    onClick={() => this.setState({ isDialogVisible: false })}
                  />
                </div>
              }
            >
              Are you sure you want to delete this item?
            </Dialog>
            <Dialog
              //dismissableMask={true}
              header={this.state.infoDialogHeader}
              visible={this.state.infoDialogDisplay}
              modal={true}
              onHide={() => {
                this.setState({ infoDialogDisplay: false });
              }}
            >
              {this.state.infoDialogStr}
            </Dialog>
          </div>
        </div>
      </div>
    );

    return (
      <>
        {externalCostDisplay}
        <AllClientCompanies
          {...this.props}
          others="fromexternalcost"
        ></AllClientCompanies>
      </>
    );
  }
}
