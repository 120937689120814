import { CommonMethods } from "../components/resources/CommonMethods";
import { HttpClient } from "./HttpClient";
export class MultiDeptService {
  httpClient: HttpClient;
  commonMethods: CommonMethods;
  constructor() {
    this.httpClient = new HttpClient();
    this.commonMethods = new CommonMethods();
  }

  async setCustomMultiDeptSetting(obj: any) {
    const res = await this.httpClient.post(
      "/api/usermultideptsetting/create",
      obj
    );
    return res;
  }
  async getCustomMultiDeptSetting(userid: string, type: string) {
    const res = await this.httpClient.get(
      "/api/usermultideptsetting/" + type + "/" + userid
    );
    return res;
  }
  async getCustomMultiDeptDropdown(userid: string, type: string) {
    const res = await this.httpClient.get(
      "/api/usermultideptsetting/dropdown/" + type + "/" + userid
    );
    return res;
  }
  async getLeaveDepartmentIds() {
    const res = await this.httpClient.get(
      "/api/usermultideptsetting/leave/deptid"
    );
    return res;
  }
}
