import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { DepartmentService } from "../../../service/DepartmentService";
import { DepartmentModel } from "../../department/Department";
import {
  CommonMethods,
  RouteComponentProps,
} from "../../resources/CommonMethods";
import CustomError from "../../resources/Error";
import OneDeptScoreIndicator from "./OneDeptScoreIndicator";

function ViewIndicators(props: RouteComponentProps) {
  const commonMethods = new CommonMethods();
  const deptService = new DepartmentService();

  const [displayDeptArr, setDisplayDeptArr] = useState();
  // const [userRanks, setUserRanks] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );

  useEffect(() => {
    getData();
  }, []);

  const getUserRank = async () => {
    let ranks;
    await commonMethods.getUserRankDropdown().then((res) => {
      ranks = res;
    });
    return ranks;
  };

  const getData = async () => {

    var currentAccessingCompany =
      localStorage.getItem("currentAccessingCompany") || "";

    const ranks = await getUserRank();

    deptService
      .getAllDeptInCompany(currentAccessingCompany)
      .then((res: Array<DepartmentModel>) => {
        let displayArr: any = [];
        res.forEach((dept) => {
          let display1 = (
            <div className="row" key={dept.departmentID}>
              <div className="col-12 col-xl-12">
                <OneDeptScoreIndicator
                  {...props}
                  others={{
                    departmentID: dept.departmentID,
                    // callbackRefresh: callbackRefresh,
                    viewOnly: false,
                    userRanks: ranks,
                    customView: false,
                  }}
                />
              </div>
            </div>
          );
          displayArr.push(display1);
        });
        setDisplayDeptArr(displayArr);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
        setIsLoading(false);
      });
  };

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = (
      <div className="text-center">
        <ProgressSpinner />
      </div>
    );
  } else {
    display = displayDeptArr;
  }

  return (
    <div
      data-tour-id="kpi-scores"
      data-tour="View and enter every department's KPI scores here.."
    >
      {display}
    </div>
  );
}

export default ViewIndicators;
