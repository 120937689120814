import axios from 'axios'
import { HttpClient } from '../../../service/HttpClient'
import { CommentModel } from '../Model/CommentModel';

export default class CompanyFeedService {
    httpClient: HttpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    async getPosts(page: number) {
        const results = await axios({
            method: 'GET',
            url: '/api/post',
            params: { page }
        })
        // console.log('results: ', results.data)
        return results.data
    }

    async getPostById(postId: string) {
        try {
            const results = await axios({
                method: 'GET',
                url: `/api/post/${postId}`,
            })
            console.log('getPostById results: ', results)
            return results
        } catch (error) {
            if (error.response) {
                // Request made and server responded
                // console.log(error.response.data);
                console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            }
            return error
        }

    }

    async insertPost(formData: FormData) {
        const results = await axios({
            method: 'POST',
            url: '/api/post',
            data: formData
        })
        // console.log('insertPost results: ', results)
        return results
    }

    async editPost(formData: FormData) {
        const results = await axios({
            method: 'PUT',
            url: '/api/post',
            data: formData
        })
        // console.log('results: ', results)
        return results
    }

    async deletePost(postId: string) {
        const results = await axios({
            method: 'PUT',
            url: '/api/post/delete',
            params: { postId }
        })
        console.log('deletePost results: ', results)
        return results
    }

    async likePost(postId: string) {
        const results = await axios({
            method: 'PUT',
            url: '/api/post/like',
            params: { postId }
        })
        return results
    }

    async unlikePost(postId: string) {
        const results = await axios({
            method: 'PUT',
            url: '/api/post/unlike',
            params: { postId }
        })
        return results
    }

    async getComments(postId: string) {
        const results = await axios({
            method: 'GET',
            url: '/api/post/comment',
            params: { postId }
        })
        // console.log('get comments: ', results)
        return results.data
    }

    async getChildComments(parentCommentId: string) {
        const results = await axios({
            method: 'GET',
            url: '/api/post/comment/child',
            params: { parentCommentId }
        })
        // console.log('get child comments: ', results)
        return results.data
    }

    async insertComment(
        postId: string,
        parentCommentId: string | null,
        commentText: string | null,
        rawEditorContentJsonStr: string | null,
        usersTagged: string | null,
    ) {
        const results = await axios({
            method: 'POST',
            url: '/api/post/comment',
            params: {
                postId,
                parentCommentId: parentCommentId ?? null,
                commentText,
                rawEditorContentJsonStr,
                usersTagged,
            }
        })

        return results
    }

    async editComment(comment: CommentModel) {
        const results = await this.httpClient.put('/api/post/comment', comment)
        return results
    }

    async deleteComment(commentId: string) {
        const results = await axios({
            method: 'PUT',
            url: '/api/post/comment/delete',
            params: { commentId }
        })
        return results
    }

    async getUserPreview(userId: string | null) {
        const results = await axios({
            method: 'GET',
            url: '/api/post/userpreview',
            params: { userId }
        })
        return results
    }

    async getCurrentUserDetails(userId: string) {
        const results = await axios({
            method: 'GET',
            url: '/api/post/curruserdetails',
            params: { userId }
        })
        return results
    }

    async getLikedUsers(likedUsers: string[]) {
        const results = await this.httpClient.post('/api/post/liked/users', likedUsers)
        return results
    }

    async getAllMentionSuggestions() {
        const results = await this.httpClient.get('/api/post/mention-suggestions')
        return results
    }

    async getBirthdayPost() {
        const results = await this.httpClient.get('/api/post/birthday')
        // console.log('getBirthdayPost', results)
        return results
    }

    async insertBirthdayPost(birthdayPost: {
        rawEditorContentJsonStr: string | null;
        usersTagged: string | null;
        type: string;
    }) {
        const results = await this.httpClient.post('/api/post/birthday', birthdayPost)
        // console.log('insertBirthdayPost', results)
        return results
    }

    async getBirthdayUsers() {
        const results = await this.httpClient.get('/api/post/birthdayusers')
        // console.log('getBirthdayUsers', results)
        return results
    }

    async getTodayBirthdayPost() {
        const results = await this.httpClient.get('/api/post/birthday')
        // console.log('getTodayBirthdayPost', results)
        // null result becomes an empty string for some reason
        // console.log({
        //     res: results
        // })
        return results
    }
}



