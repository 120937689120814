import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { CircularsDashboardModel } from "./CircularsDashboardModel";
import CircularsDashboardService from "../../service/CircularsDashboardService";
import { UserModel } from "../user/User";
import { UserService } from "../../service/UserService";

export default function CircularsDashboard(props: RouteComponentProps<any>) {
  const commonMethods = new CommonMethods();
  const circularsDashboardService = new CircularsDashboardService();
  const userService = new UserService();

  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");

  const [searchValue, setSearchValue] = useState<string>("");
  const [tableDataChanged, setTableDataChanged] = useState(false);
  const [tableData, setTableData] = useState<CircularsDashboardModel[]>([]);
  const [circularData, setCircularData] = useState(new CircularsDashboardModel());

  const [readDialog, setReadDialog] = useState<boolean>(false);
  const [dialogStr, setDialogStr] = useState<any>("");
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
  "An unidentified error has occurred"
  );

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [tableDataChanged]);

  const init = () => {
    circularsDashboardService
      .getAllCirculars()
      .then((res) => setTableData(res))
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const setDialog = (header: string, content: string) => {
    setConfirmStr(header);
    setDialogStr(content);
  };

  const deleteCircular = () => {
    circularsDashboardService
      .deleteCircularByID(circularData.announcementID)
      .then (() => {
        setTableDataChanged(!tableDataChanged);
        setDialogVisibility(false);
        SetSuccess( `${circularData.summary} has been deleted.`);
      })
      .catch((err) => {
        setDialogVisibility(false);
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  let tableheader = (
    <div style={{ textAlign: "left" }}>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e: any) => setSearchValue(e.currentTarget.value)}
        />
      </span>
    </div>
  );

  const actionTemplate = (rowData: CircularsDashboardModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Edit Circular"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            setCircularData(rowData);
            setRedirectOut(true);
            setRedirectPath("/admin/circulars/update/" + rowData.announcementID);
          }}
        />
        <Button
          tooltip="Delete Circular"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setCircularData(rowData);
            setDialog(
              `Delete ${rowData.summary}`,
              `Are you sure you want to delete this circular?`
            );
            setDialogVisibility(true);
          }}
        />
      </div>
    );
  };

  const titleLabel = (rowData: CircularsDashboardModel) => {
    return <>{rowData.summary} {rowData.userRankName === "" || rowData.userRankName === null ? <></> :<span className="p-tag p-tag-rounded p-tag-neutral">{rowData.userRankName}</span>}</>;
  }

  const dateFormat = (rowData: CircularsDashboardModel) => {
    return commonMethods.displayDate(rowData.lastUpdatedDate);
  }

  const viewDocument = (rowData: CircularsDashboardModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="View Document"

          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-eye"
          className="p-button-primary mr-1"
          onClick={() => {
            setRedirectOut(true);
            setRedirectPath("/circular/document/" + rowData.announcementID)
          }}
        />
      </div>
    );
  };

  const getNameList = (userList: Array<UserModel>) => {
    let nameList: any;
    let arrToDisplay: any[] = [];
    userList.forEach((item: UserModel, index: any) => {
      nameList = (
        <tr>
          <td className="font-weight-bold">{index + 1}</td>
          <td>{item.firstName} {item.lastName}</td>
        </tr>
      );
      arrToDisplay.push(nameList);
    });
    return arrToDisplay;
  };

  const readByTemplate = (rowData: CircularsDashboardModel) => {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-eye"
          className="p-button-info"
          onClick={() => {
            circularsDashboardService
            .getAcknowledgedBy(rowData.announcementID)
            .then((res) => {
              return getNameList(res);
            })
            .then((res) => {
              let arrToDisplay = res;
              setConfirmStr(`${rowData.summary} - Total: ${arrToDisplay.length}`);
              
              let dialogstr = (
                <table className="leave-info-dialog table">
                  <tbody>{arrToDisplay}</tbody>
                </table>
              );
            
              setDialogStr(dialogstr);
              setReadDialog(true);
            });
          }}
        ></Button>
      </div>
    );
  };


  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMessage} />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={tableData}
            paginator={true}
            rows={15}
            autoLayout={true}
            header={tableheader}
            globalFilter={searchValue}
            alwaysShowPaginator={false}
            emptyMessage="No results found."
            sortField="summary"
            sortOrder={1}
          >
            <Column body={titleLabel} header="Title" />
            {/* <Column field="summary" header="Title"/> */}
            <Column field="fullName" header="Created By"/>
            <Column body={dateFormat} header="Last Updated"/>
            <Column body={viewDocument} header="View" />
            <Column body={readByTemplate} header="Read By" className="p-col-2"/>
            <Column body={actionTemplate} header="Action" className="p-col-2"/>
          </DataTable>
        </div>
      );
    }
  }

  return (
    <div className="row">
      {redirectOut ? <Redirect push to={redirectPath} /> : null}
      
      {/* Dialog for readby button */}
      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={readDialog}
        modal={true}
        onHide={() => setReadDialog(false)}
      >
        <ScrollPanel style={{ height: "500px" }}>
          <div>{dialogStr}</div>
        </ScrollPanel>
      </Dialog>

      {/* Dialog for delete button*/}
      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                deleteCircular();
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>
      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">
            Circulars
            <span className="pl-3">
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={() => {
                  setRedirectOut(true);
                  setRedirectPath("/admin/circulars/create")
                }}
              />
            </span>
          </h1>
          <p>
            These circulars will show up on the Quick Links page
          </p>
          {display()}
        </div>
      </div>
    </div>
  );
}