import React, { useState, useEffect } from "react";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CompanyDropdown from "../resources/CompanyDropdown";
import { MenuList, MenuItem } from "../resources/MenuList";
import { Link } from "react-router-dom";
import CustomError from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";

const commonMethods = new CommonMethods();
const menuList = new MenuList();

function AdminDashboard(props: RouteComponentProps<any>) {
  // declare
  const [companyID, setCompanyID] = useState(
    // localStorage.getItem("currentAccessingCompany") ||
    props.userProfile.companyID
  );

  const [companyName, setCompanyName] = useState<string>("");

  const [currentModulesList, setModulesList] = useState([""]);
  const [thisMenuList, setMenuList] = useState(new Array());

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>("");

  const [displays, setDisplays] = useState<any[]>();

  // when companyID changes, get the new taskList
  useEffect(() => {
    setIsLoading(true);
    commonMethods
      .getMenuOfViewingUserOfCompany(companyID)
      .then((res) => {
        setModulesList(res);
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(commonMethods.getErrorMessage(err));
      });
  }, [companyID]);

  // when taskList changes, get new menu List
  useEffect(() => {
  
    let finalMenu = new Array();

    // Push role defined menus
    menuList.AdminSettingsList.forEach((adminListItem: string) => {
      let parentMenu: MenuItem = {
        label: menuList.Menu[menuList[adminListItem].group].label,
        items: [],
      };

      menuList[adminListItem].items.forEach((moduleName: string) => {
        if (currentModulesList.includes(moduleName)) {
          //2.1 Get the parent group
          var index = finalMenu.findIndex(
            (menuItem: MenuItem) => menuItem.label === parentMenu.label
          );

          // 2.2 Add the child into parent group if it exists
          if (index < 0) {
            parentMenu.items.push(menuList.Menu[moduleName]);
            finalMenu.push(parentMenu);
          } else {
            parentMenu = finalMenu[index];
            parentMenu.items.push(menuList.Menu[moduleName]);
            finalMenu[index] = parentMenu;
          }
        }
      });
    });

    setMenuList(finalMenu);
  }, [currentModulesList]);

  // Usable Functions
  const getCurrentAccessingCompany = (
    currentCompanyID: string,
    currentCompanyName: string
  ) => {
    setCompanyID(currentCompanyID);
    setCompanyName(currentCompanyName);
  };

  useEffect(() => {
    setDisplays(
      thisMenuList.map((menuGroup, index) => {
        return (
          <div className="p-col-12 p-lg-3 pb-2" key={index}>
            <div className="card" style={{ height: "100%" }}>
              <h1 className="pb-2">{menuGroup.label}</h1>
              <table>
                <tbody>
                  {menuGroup.label === "Organization Settings" ? (
                    orgSetting
                  ) : (
                    <tr>
                      <td> </td>
                    </tr>
                  )}

                  {menuGroup.items.map((menuItem: any, menuIndex: number) => (
                    <tr className="p-col" key={menuIndex}>
                      <td>
                        <Link className="settingsFont" to={menuItem.to}>
                          {menuItem.label}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      })
    );
    setIsLoading(false);
  }, [thisMenuList]);

  let orgSetting = (
    <tr>
      <td>
        <p className="noticeboardRemarks">
          These are Group Company wide applicable.
        </p>
      </td>
    </tr>
  );

  let nothingtodisplay = (
    <div className="p-col-12 text-center">
      You do not have any admin access for this company.
    </div>
  );
  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = (
      <div className="p-grid">
        {displays !== undefined && displays.length > 0
          ? displays
          : nothingtodisplay}
      </div>
    );
  }

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <h1
            className="pb-2"
            data-tour-id="admin-dashboard-title"
            data-tour="This is the place for admin to access everything behind the scenes. Wow!"
          >
            Admin Dashboard - {companyName}
          </h1>
          <CompanyDropdown {...props} others={getCurrentAccessingCompany} />
          {display}
        </div>
      </div>
    </div>
  );
}
export default AdminDashboard;
