import React, { useEffect, useState } from 'react'
import { PostModel, PreviewProps, UserPreview } from '../../Model/PostModel';
import { checkElementAboveMiddleVH } from '../../CompanyFeedFunctions';
import EditPost from '../Post/Forms/EditPost';
import Post from '../Post/Post';
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner';

export default function SinglePost({
    post,
    contextName,
    parentComponent,
    isLoaded,
}: {
    post: PostModel;
    contextName: string
    parentComponent: string | undefined
    isLoaded: boolean
}) {
    const [postToEdit, setPostToEdit] = useState<PostModel>(new PostModel())
    const [isEditPost, setIsEditPost] = useState(false)
    const [isShowEditDialog, setIsShowEditDialog] = useState(false)
    const [previewData, setPreviewData] = useState<UserPreview | null>(null)
    const [previewProps, setPreviewProps] = useState(new PreviewProps())

    useEffect(() => {
        if (previewProps.previewPosition) setPreviewProps(prevState => (
            {
                ...prevState,
                isElementAboveMiddleVH: checkElementAboveMiddleVH(previewProps.previewPosition)
            }
        ))
    }, [previewProps.previewPosition])

    if (post && (isLoaded))
        return (
            <div className="grid-container">
                <div key={post.postId} className={parentComponent === "TodayBirthdayPost" ? "each-post margin-bottom-0px" : "each-post"}>
                    <Post
                        post={post}
                        setPostToEdit={setPostToEdit}
                        setIsEditPost={setIsEditPost}
                        setIsShowEditDialog={setIsShowEditDialog}
                        previewData={previewData}
                        setPreviewData={setPreviewData}
                        previewProps={previewProps}
                        setPreviewProps={setPreviewProps}
                        contextName={contextName}
                        singlePostLoaded={isLoaded}
                        parentComponent={parentComponent}
                    />
                </div>
                {isEditPost ?
                    <EditPost
                        setIsEditPost={setIsEditPost}
                        isShowEditDialog={isShowEditDialog}
                        setIsShowEditDialog={setIsShowEditDialog}
                        post={postToEdit}
                        contextName={contextName}
                        parentComponent={parentComponent}
                    />
                    : null}
            </div>
        )
    if (post && !isLoaded) return <ProgressSpinner className="loader-centered loader-size" />
    return null
}