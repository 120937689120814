import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import CampaignService from "../../service/CampaignService";
import DeliverablesInCampaign from "../deliverable/DeliverablesInCampaign";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import { TaskList } from "../resources/ListOfTasks";
import { CampaignModel } from "./Campaign";
import CampaignInvoiceTable from "./CampaignInvoiceTable";
import CampaignOtherDetails from "./CampaignOtherDetails";
import CampaignSummary from "./CampaignSummary";

function CampaignDeliverableView(props: RouteComponentProps<any>) {
  let campaignService = new CampaignService();
  let commonMethods = new CommonMethods();

  const [financePermissions] = useState(
    props.userProfile.taskList.includes(TaskList.financeaccess)
  );
  const [displayInvoice, setDisplayInvoice] = useState<boolean>(false);
  const [campaignData, setCampaignData] = useState<CampaignModel>();

  const hasClientCallback = (hasClient: boolean) => {
    setDisplayInvoice(hasClient);
  };

  let { id } = useParams<{id:string}>();

  const getCampaign = () => {
    if (id === undefined) {
      return;
    }
    campaignService
      .getCampaignByCampaignID(id)
      .then((res) => {
        setCampaignData(res);
      })
      .catch((err) => {
        // Will catch inside the components
      });
  };

  useEffect(() => {
    getCampaign();
  }, []);
  return (
    <>
      <CampaignSummary {...props} others={hasClientCallback}></CampaignSummary>

      {campaignData && (
        <DeliverablesInCampaign
          {...props}
          others={campaignData}
        ></DeliverablesInCampaign>
      )}
      {campaignData && (
        <CampaignOtherDetails
          {...props}
          others={campaignData}
        ></CampaignOtherDetails>
      )}
      {financePermissions && displayInvoice && (
        <CampaignInvoiceTable {...props}></CampaignInvoiceTable>
      )}
    </>
  );
}

export default CampaignDeliverableView;
