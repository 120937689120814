import { Button } from "primereact/button";
import { Delta, Sources } from "quill";
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import CampaignService from "../../service/CampaignService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import { ToastStateContext } from "../resources/ToastContext";
import CustomError from "../resources/Error";
import { CampaignModel } from "./Campaign";

function CampaignOtherDetails(props: RouteComponentProps<any>) {
  let campaignService = new CampaignService();
  let commonMethods = new CommonMethods();
  const [campaignData, setCampaignData] = useState(new CampaignModel());
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // [{size:[]}],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
      ],
      ["link", "image", "video"],
      ["clean"]
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  };

  useEffect(() => {
    if (props.others !== undefined) {
      setCampaignData(props.others);
    } else {
      setIsError(true);
      setErrorMessage("Unable to retrieve campaign details.");
    }
  }, []);
  const handleChange = (content: string, delta: Delta, source: Sources) => {
    setCampaignData({ ...campaignData, campaignContent: content });
  };

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else {
    display = (
      <>
        {" "}
        <ReactQuill
          value={campaignData.campaignContent}
          onChange={handleChange}
          modules={modules}
          theme="snow"
        />
        <div className="p-grid p-justify-center pt-4">
          <Button
            type="button"
            iconPos="right"
            label="Save"
            className="p-button-primary"
            onClick={() => {
              campaignService
                .updatecampaigncontent(campaignData)
                .then(res => {
                  SetSuccess("Remarks saved");
                })
                .catch(err => {
                  const error = commonMethods.getErrorMessage(err);
                  SetError(error);
                });
            }}
          />
        </div>
      </>
    );
  }
  return (
    <div className="row">
      <div
        className="col-12"
        data-tour-id="campaign-details"
        data-tour="Share additional details here for everyone involved to see. Remember to save after making any changes!"
      >
        <div className="card">
          <h1 className="pb-2">Other Details</h1>
          {display}
        </div>
      </div>
    </div>
  );
}
export default CampaignOtherDetails;
