import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "../resources/CommonMethods";
import { Button } from "primereact/button";
import { CostTypeModel } from "./CostType";
import { InputText } from "primereact/inputtext";
import { CostTypeService } from "../../service/CostTypeService";
import { ProgressSpinner } from "primereact/progressspinner";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { Redirect, useParams } from "react-router";

let costTypeService = new CostTypeService();
function CostTypeCreate(props: RouteComponentProps) {
  let params: { id?: string } = useParams();

  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);

  const [costTypeData, setCostTypeData] = useState<CostTypeModel>(
    new CostTypeModel()
  );
  const [buttonLabel, setButtonLabel] = useState<string>("Create");
  const [isError, setisError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [toHome, setToHome] = useState(false);

  useEffect(() => {
    if (params.id !== undefined) {
      costTypeService
        .getCostTypeByID(params.id)
        .then((res) => setCostTypeData(res))
        .catch((err) => {
          setisError(true);
          SetError("An Error has occurred.");
        });

      setButtonLabel("Update");
    }
    setIsLoading(false);
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (props.match.path === "/admin/cost_type/create")
      costTypeService
        .createCostType(costTypeData)
        .then((res) => {
          SetSuccess("New Cost Type Created!");
          setToHome(true);
        })
        .catch((err) => {
          SetError("Could not create new cost type.");
        });
    else if (props.match.path === "/admin/cost_type/update/:id") {
      costTypeService
        .updateCostType(costTypeData)
        .then((res) => {
          SetSuccess("Cost Type Updated!");
          setToHome(true);
        })
        .catch((err) => {
          SetError("Could not update cost type.");
        });
    }
  };

  const form = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">
              Cost Type Name <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={costTypeData!.costTypeName}
              placeholder=""
              onChange={(event) =>
                setCostTypeData({
                  ...costTypeData!,
                  costTypeName: event.currentTarget.value,
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Cost Type Description <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={costTypeData!.costTypeDescription}
              placeholder=""
              onChange={(event) =>
                setCostTypeData({
                  ...costTypeData!,
                  costTypeDescription: event.currentTarget.value,
                })
              }
              required
            />
          </div>
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button type="submit" label={buttonLabel} />
        </div>
      </form>
    );
  };

  if (isLoading) {
    return <ProgressSpinner />;
  } else if (isError) {
    return <CustomError message={"An Error Has occurred."} />;
  } else {
    return (
      <div className="row">
        {toHome ? <Redirect push to="/admin/cost_type" /> : null}
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <span className="pb-2">
              {" "}
              <h1> {buttonLabel} Cost Type </h1>
            </span>

            <div className="requiredRed">
              <p>* Required</p>
            </div>

            {form()}
          </div>
        </div>
      </div>
    );
  }
}

export default CostTypeCreate;
