import React, { useContext } from 'react'
import CompanyLogo from './CompanyLogo'
import { PublicProfileContext } from './PublicProfile';
import moment from 'moment';
import { Button } from 'primereact/button';
import Telegram from './Telegram';

export default function UserInfo() {
    const { state } = useContext(PublicProfileContext);
    let profile = state.userProfile

    function getBirthdayFromDOB(dob: Date) {
        return moment(dob).format('Do') + " " + moment(dob).format('MMMM')
    }

    function sentenceCase(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    function personalInfo(){
        return (
            <div className="flex-row profile-personal-info">
                <span className="material-icons-outlined md-40 material-icon-align">person</span>
                <table className="public-profile-table">
                    <tbody>
                        {profile.dateOfBirth
							?
                            <tr>
                                <th>Birthday: </th>
                                <td>{getBirthdayFromDOB(profile.dateOfBirth)}</td>
                            </tr>
                            :
							null
						}
                    </tbody>
                </table>
            </div>
        )
    }

    // TODO: delete user-info-section class css and related
    return (
        <div className="public-profile-container">
            <div className="public-profile-info">
                {profile.publicProfilePermission && personalInfo()}
                <div className="flex-row profile-work-info ">
                    {profile?.companyName ?
                        <CompanyLogo companyLogo={profile?.companyLogo} />
                        : <span className="material-icons-outlined md-40 material-icon-align">work_outline</span>}
                    <table className="public-profile-table">
                        <tbody>
                            <tr>
                                <td>{profile?.companyName}</td>
                            </tr>
                            <tr>
                                <td>{profile?.departmentName} Department</td>
                            </tr>
                            <tr>
                                <td>{profile?.userRankName}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="contact-buttons flex-row">
                <a className="email-link" href={"mailto:" + profile?.email} target="_blank" rel="noopener noreferrer">
                    <Button className="p-button-raised p-button-rounded" ><span className="material-icons-outlined">email</span>&nbsp;Email</Button>
                </a>
                <Telegram />
            </div>
        </div>

    )
}
