import moment from 'moment'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router';
import { CommentModel } from '../Model/CommentModel'
// import { PostModel } from '../Model/PostModel'

export default function TimeStampFromNow({
    comment,
}: {
    comment: CommentModel
}) {
    const history = useHistory();
    // Add 8 hrs because CreatedTime saved in DB is GMT +0
    const longTimeStamp = moment(comment.createdTime).add(8, 'hours').format('dddd, MMMM DD, YYYY [at] h:mm A')
    const fromNow = moment(comment.createdTime).add(8, 'hours').fromNow()

    function enterComment(comment: CommentModel) {
        // if child comment
        if (comment.parentCommentId) {
            history.push({
                pathname: `/companyfeed/post/${comment.postId}`,
                search: `?commentId=${comment.parentCommentId}&replyCommentId=${comment.commentId}`,
                state: {
                    postId: comment.postId,
                    parentCommentId: comment.parentCommentId,
                    commentId: comment.commentId,
                }
            })
            return
        }

        history.push({
            pathname: `/companyfeed/post/${comment.postId}`,
            search: `?commentId=${comment.commentId}`,
            state: {
                postId: comment.postId,
                commentId: comment.commentId,
            }
        })
    }

    return (
        <div className="timestamp cursor-underline position-relative" onClick={() => enterComment(comment)}>
            {fromNow}
            <span className="timestamp-tooltip">{longTimeStamp}</span>
        </div>
    )
}

// moment.updateLocale('en', {
    //     relativeTime: {
    //         past: "%s",
    //         // s: "s",
    //         m: "1min",
    //         mm: "%dmin",
    //         h: "1h",
    //         hh: "%dh",
    //         d: "1d",
    //         dd: "%dd",
    //         M: "1mth",
    //         MM: "%dmth",
    //         y: "1y",
    //         yy: "%dy"
    //     }
    // });