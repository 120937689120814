import { HttpClient } from "./HttpClient";
import moment from "moment";
export class AttendanceService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async guestCheckIn(obj: any) {
    const res = await this.httpClient.post("/api/attendance/check/in", obj);
    return res;
  }
  async staffCheckIn(obj: any) {
    const res = await this.httpClient.post("/api/attendance/check/staff", obj);
    return res;
  }
  // async getGuestList(type:string, id:string) {
  //   const res = await this.httpClient.get("/api/attendance/guests/"+type+"/"+id);
  //   return res;
  // }
  async guestCheckOut(obj: any) {
    const res = await this.httpClient.post("/api/attendance/check/out", obj);
    return res;
  }
  async getLocation(type: string, id: string) {
    const res = await this.httpClient.get(
      "/api/attendance/location/" + type + "/" + id
    );
    return res;
  }
  async getEntryExitUrl() {
    const res = await this.httpClient.get("/api/attendance/url");
    return res;
  }
  async getCompanyDropdown() {
    const res = await this.httpClient.get("/api/attendance/gcompanydropdown");
    return res;
  }
  async getLogs(id: string) {
    const res = await this.httpClient.get("/api/attendance/logs/" + id);
    return res;
  }
  async getLogsDateRange(id: string, startDate: Date, endDate: Date) {
    const res = await this.httpClient.get(
      "/api/attendance/logs/" + id + "/" + moment(startDate).format("YYYY-MM-DD") + "/" + moment(endDate).format("YYYY-MM-DD")
    );
    return res;
  }
}
