import React from "react";
import CreateClaims from "./CreateClaims";

function CreateUpdateClaimsPage(props: any) {
  return (
    <div className="row">
      <div className="col-12 col-xl-6">
        <div className="card" data-tour-id="claim-create-update" data-tour="Fill in your claim details here and upload the necessary documents.">
            <h1>Make a Claim</h1>
            
          <CreateClaims submit="true" />
        </div>
      </div>
    </div>
  );
}

// export function UpdateClaimsPage(props: any) {
//   return (
//     <div className="card">
//       <CreateClaims submit="false" {...props}/>
//     </div>
//   );
// }

export default CreateUpdateClaimsPage;
