import { HttpClient } from "./HttpClient";
export class RoleService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  // Role.tsx
  async getAllRoles() {
    const res = await this.httpClient.get("/api/role/gcid");
    return res;
  }

  // Role.tsx , RoleTaskSetting.tsx (getRoleName)
  async getRoleByID(id: string) {
    const res = await this.httpClient.get("/api/role/" + id);
    return res;
  }

  // Role.tsx
  async createRole(obj: any) {
    const res = await this.httpClient.post("/api/role/create", obj);
    return res;
  }

  // Role.tsx
  async updateRole(obj: any) {
    const res = await this.httpClient.put("/api/role/update", obj);
    return res;
  }

  // Role.tsx
  async deleteRole(id: string) {
    const res = await this.httpClient.delete("/api/role/" + id);
    return res;
  }

  // RoleTaskSetting.tsx (SetTasksWhenRoleSelected)
  // async getTasksByRoleID(id: string) {
  //   try {
  //     const res = await this.httpClient.get(
  //       "/api/role/taskListAndRemarks/" + id
  //     );
  //     return res;
  //   } catch (err) {
  //     return err;
  //   }
  // }

  async getRoleTaskList(roleID: string) {
    const res = await this.httpClient.get("/api/role/tasklist/" + roleID);
    return res;
  }

  // RoleTaskSetting.tsx (submitForm) , RoleTaskSetting2.tsx (updateRolePermissions)
  async updateTasksForRole(obj: any) {
    const res = await this.httpClient.put("/api/role/update/taskList", obj);
    return res;
  }

  // UNUSED
  async getCompanyListByUserID(id: string) {
    try {
      const res = await this.httpClient.get("/api/role/companyList/" + id);
      return res;
    } catch (err) {
      return err;
    }
  }

  // RoleTaskSetting.tsx ,
  async copyRoleSettings(obj: any) {
    const res = await this.httpClient.post("/api/role/copyRole", obj);
    return res;
  }
}
