import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { PublisherService } from "../../service/PublisherService";
import { CommonMethods } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { PublisherModel } from "./PublisherModel";

function Publishers() {
  const commonMethods = new CommonMethods();
  const publisherService = new PublisherService();

  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );

  const [searchValue, setSearchValue] = useState<string>("");
  const [tableDataChanged, setTableDataChanged] = useState(false);
  const [tableData, setTableData] = useState<PublisherModel[]>([]);
  const [publisherData, setPublisherData] = useState<PublisherModel>(
    new PublisherModel()
  );

  const [dialogStr, setDialogStr] = useState<string>("");
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [dialogVisibility, setDialogVisibility] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [tableDataChanged]);

  const init = () => {
    publisherService
      .getAllPublishersByGCID()
      .then((res) => setTableData(res))
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const setDialog = (header: string, content: any) => {
    setConfirmStr(header);
    setDialogStr(content);
  };

  const deletePublisher = () => {
    publisherService
      .deletePublisher(publisherData.publisherID)
      .then(() => {
        setTableDataChanged(!tableDataChanged);
        setDialogVisibility(false);
        SetSuccess(` ${publisherData.publisherName} has been deleted.`);
      })
      .catch((err) => {
        setDialogVisibility(false);
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  const koiTemplate = (rowData: PublisherModel) => {
    if (rowData.isKOL) {
      return "Yes";
    } else {
      return "No";
    }
  };

  const actionTemplate = (rowData: PublisherModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Edit Publisher"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            setPublisherData(rowData);
            setRedirectOut(true);
            setRedirectPath("/admin/publishers/update/" + rowData.publisherID);
          }}
        />
        <Button
          tooltip="Delete Publisher"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setPublisherData(rowData);

            setDialog(
              `Delete ${rowData.publisherName}`,
              `Are you sure you want to delete this publisher?`
            );
            setDialogVisibility(true);
          }}
        />
      </div>
    );
  };

  let tableheader = (
    <div style={{ textAlign: "left" }}>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e: any) => setSearchValue(e.currentTarget.value)}
        />
      </span>
    </div>
  );

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMsg} />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={tableData}
            paginator={true}
            rows={15}
            autoLayout={true}
            header={tableheader}
            globalFilter={searchValue}
            alwaysShowPaginator={false}
            emptyMessage="No results found."
          >
            <Column field="publisherName" header="Name" />
            <Column body={koiTemplate} header="KOL" />
            {/* <Column field="publisherStatus" header="Status" className="p-col-2" /> */}
            <Column body={actionTemplate} header="Actions" className="p-col-2" />
          </DataTable>
        </div>
      );
    }
  };

  return (
    <div className="row">
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                deletePublisher();
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>

      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">
            Publishers{" "}
            <span className="pl-3">
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={() => {
                  setRedirectOut(true);
                  setRedirectPath("/admin/publishers/create");
                }}
              />
            </span>
          </h1>
          <p>
            These are publishers used to classify Campaigns and Deliverables for
            InsightsRN Report generation. Always ensure they are in sync with
            InsightsRN.
          </p>
          {display()}
        </div>
      </div>
    </div>
  );
}

export default Publishers;
