import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { AnnouncementService } from "../../service/AnnouncementService";
import { CommonMethods } from "../resources/CommonMethods";
import { ToastStateContext } from "../resources/ToastContext";
import { UserModel } from "../user/User";
import { CreateAnnouncement } from "./AnnouncementCreate";
import { IAnnouncementDocumentModel } from "./Announcement";
import { TaskList } from "../resources/ListOfTasks";

export function AnnouncementPage(props: any) {
  const commonMethods = new CommonMethods();

  const [form, setForm] = useState(<></>);
  const [makeAnnouncements, setMakeAnnouncements] = useState(false);

  useEffect(() => {
    function checkPerms() {
      if (commonMethods.isPropsLoaded(props)) {
        if (props.userProfile.taskList !== undefined) {
          if (
            commonMethods.isArrayPresentInArray(props.userProfile.taskList, [
              TaskList.groupannouncement,
              TaskList.companyannouncement,
              TaskList.deptannouncement,
            ])
          ) {
            setMakeAnnouncements(true);
            setForm(
              <div className="col-12 col-lg-4">
                <div className="card">
                  <CreateAnnouncement {...props} />{" "}
                </div>
              </div>
            );
          }
        }
      }
    }
    checkPerms();
  }, []);

  return (
    <div className="row">
      <div className="col-12 col-lg-8">
        <div className="card">
          <ViewAnnouncements
            makeAnnouncements={makeAnnouncements}
          ></ViewAnnouncements>
        </div>
      </div>

      {form}
    </div>
  );
}

export function ViewAnnouncements(props: any) {
  const commonMethods = new CommonMethods();
  const announcementService = new AnnouncementService();
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const [url, setUrl] = useState("");
  const [redirect, setRedirect] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [actionDialogVisibility, setactionDialogVisibility] = useState<boolean>(
    false
  );
  const [dialogHeader, setDialogHeader] = useState<string | JSX.Element>("");
  const [dialogContent, setDialogContent] = useState<any>("");
  const [announcements, setAnnouncements] = useState<
    IAnnouncementDocumentModel[]
  >();
  const [announcementData, setAnnouncementData] = useState<
    IAnnouncementDocumentModel
  >();

  useEffect(() => {
    async function getAnnouncements() {
      announcementService.getAllAnnouncement().then((res) => {
        setAnnouncements(res);
      });
    }
    getAnnouncements();
  }, []);

  const actionTemplate = (rowData: IAnnouncementDocumentModel) => {
    if (rowData.createdByMe) {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="button"
            icon="pi pi-pencil"
            className="p-button-warning mr-1"
            onClick={() => {
              updateItem(rowData);
            }}
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => {
              setAnnouncementData(rowData);
              setDialogContent(
                "Are you sure you want to delete this announcement?"
              );
              setDialogHeader("Confirm Delete");
              setactionDialogVisibility(true);
            }}
          ></Button>
        </div>
      );
    }
  };

  const getNameList = (userList: Array<UserModel>) => {
    let namelist: any;
    let arrToDisplay: any[] = [];
    userList.forEach((item: UserModel, index: any) => {
      namelist = (
        <tr>
          <td className="font-weight-bold">{index + 1}</td>
          <td>
            {item.firstName} {item.lastName}
          </td>
        </tr>
      );
      arrToDisplay.push(namelist);
    });
    return arrToDisplay;
  };

  const readByTemplate = (rowData: IAnnouncementDocumentModel) => {
    if (rowData.createdByMe) {
      return (
        <div>
          <Button
            type="button"
            icon="pi pi-eye"
            className="p-button-info"
            onClick={() => {
              announcementService
                .getReadListById(rowData.announcementID)
                .then((res) => {
                  return getNameList(res);
                })
                .then((res) => {
                  let arrToDisplay = res;
                  setDialogHeader(
                    `${rowData.summary} - Total: ${arrToDisplay.length}`
                  );

                  let dialogstr = (
                    <table className="leave-info-dialog table">
                      <tbody>{arrToDisplay}</tbody>
                    </table>
                  );

                  setDialogContent(dialogstr);
                  setShowDialog(true);
                });
            }}
          ></Button>
        </div>
      );
    }
  };

  const updateItem = (rowData: IAnnouncementDocumentModel) => {
    setUrl(`/announcements/update/${rowData.announcementID}`);
    setRedirect(true);
  };

  const displayDate = (rowData: IAnnouncementDocumentModel) => {
    return commonMethods.displayDate(rowData.createdTime!);
  };

  return (
    <>
      {redirectOut && (
        <Redirect
          push
          to={{
            pathname: redirectPath,
          }}
        />
      )}
      <Dialog
        header={dialogHeader}
        visible={showDialog}
        modal={true}
        //dismissableMask={true}
        className="popupDialog"
        onHide={() => {
          setShowDialog(false);
        }}
      >
        <ScrollPanel style={{ height: "500px" }}>
          <div>{dialogContent}</div>
        </ScrollPanel>
      </Dialog>

      <Dialog
        header={dialogHeader}
        visible={actionDialogVisibility}
        modal={true}
        className="popupDialog"
        //dismissableMask={true}
        onHide={() => {
          setactionDialogVisibility(false);
        }}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                if (dialogHeader === "Confirm Delete") {
                  announcementService
                    .deleteAnnouncement(announcementData?.announcementID || "")
                    .then(() => {
                      SetSuccess("Announcement Deleted.");
                      setactionDialogVisibility(false);
                      window.location.reload(false);
                    })
                    .catch(() => {
                      SetError("Could not delete announcement.");
                    });
                }
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setactionDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogContent}
      </Dialog>
      {redirect ? <Redirect push to={url} /> : null}
      <h1 style={{ marginTop: 0 }}>View All Announcements</h1>
      <div className="table-responsive">
        <div className="table">
          <div className="datatable-centerHeader datatable-centerContent">
            <DataTable
              value={announcements}
              paginator={true}
              alwaysShowPaginator={false}
              rows={10}
              autoLayout={true}
              emptyMessage="No records found."
            >
              <Column field="summary" header="Header" style={{ width: "12em" }} />
              <Column field="detail" header="Content" />
              <Column
                field="appliedLevel"
                header="Level"
                style={{ width: "10em" }}
              />
              <Column body={displayDate} header="Date" />
              {props.makeAnnouncements ? (
                <Column
                  body={readByTemplate}
                  header="Read By"
                  style={{ width: "8em" }}
                />
              ) : null}
              {props.makeAnnouncements ? (
                <Column
                  body={actionTemplate}
                  header="Actions"
                  style={{ width: "4em" }}
                />
              ) : null}
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
}
