import Axios from "axios";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { LogisticsService } from "../../service/LogisticsService";
import { CommonMethods, DropdownModel, RouteComponentProps } from "../resources/CommonMethods";
import { LogisticsModel, LogisticsStatusTypeModel } from "../resources/ExportClass";
import { ToastStateContext } from "../resources/ToastContext";
import { TaskList } from "../resources/ListOfTasks";

let adminPermission = false;
const commonMethods = new CommonMethods();
const logsService = new LogisticsService();
class fields {
  public fieldName: string = "";
  public fieldValue: DropdownModel | LogisticsStatusTypeModel | any;
}
/**
 * Display the page for Creation of Logistics (aka Equipments)
 * @param props
 */
function LogisticsList(props: RouteComponentProps<any>) {
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [isLoading, setLoadingStatus] = useState<boolean>(true);
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");

  const [confirmDialog, setConfirmDialogStatus] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<LogisticsModel>(
    new LogisticsModel()
  );

  const [fieldsetClose, setFieldsetClose] = useState<boolean>(true);
  const [fieldsetContent, setFieldsetContent] = useState<Array<any>>([]);
  const [updateFields, setUpdateFields] = useState<fields>({
    fieldName: "",
    fieldValue: ""
  });
  const [fieldValueSuggestion, setFieldValueSuggestion] = useState<Array<any>>(
    []
  );

  const [equipList, setEquipmentList] = useState<Array<LogisticsModel>>([]);
  const [userList, setUserList] = useState<Array<DropdownModel>>([]);
  // const [companyList, setCompanyList] = useState<Array<DropdownModel>>([]);
  // const [deptList, setDeptList] = useState<Array<DropdownModel>>([]);
  const [statusList, setStatusList] = useState<Array<DropdownModel>>([]);
  const [typeList, setTypeList] = useState<Array<DropdownModel>>([]);

  let refDT: DataTable | null = null;
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [statusFilterSelected, setStatusFilterSelected] = useState<
    string | null
  >(null);
  const [typeFilterSelected, setTypeFilterSelected] = useState<string | null>(
    null
  );

  const [equipmentSelected, setEquipmentSelected] = useState<
    Array<LogisticsModel>
  >([]);

  // ComponentDidMount
  useEffect(() => {
    getUserListForDropdown();
    // getCompanyListForDropdown();
    getFiltersDropdown();

    if (
      props.userProfile.taskList !== undefined &&
      commonMethods.isArrayPresentInArray(props.userProfile.taskList, [
        TaskList.equipmentadminaccess
      ])
    ) {
      adminPermission = true;
    }
  }, []);

  useEffect(() => {
    if (userList.length > 0 && statusList.length > 0) {
      getAllEquipmentList();
    } else {
      setLoadingStatus(false);
    }
  }, [userList, statusList]);

  useEffect(() => {
    if (equipList.length > 0 && refDT !== null) {
      refDT.filter(statusFilterSelected, "logisticsStatusTypeID", "equals");

      setLoadingStatus(false);
    }
  }, [equipList.length]);
  useEffect(() => {
    if (equipmentSelected.length > 0) {
    } else {
      setFieldsetClose(true);
    }

    let display: any = [];
    if (equipmentSelected.length > 0) {
      equipmentSelected.forEach((value: LogisticsModel, i: number) => {
        display.push(
          <>
            <p className="mb-1">
              <span className="boldLabel">{value.equipmentSerialNo}</span>
              <span className="mx-2">{value.equipmentName}</span>
              <span
                style={{ verticalAlign: "sub" }}
                onClick={() => {
                  equipmentSelected.splice(i, 1);
                }}
              >
                <i className="pi pi-fw pi-times" />
              </span>
            </p>
          </>
        );
      });
    }
    setFieldsetContent(display);
  }, [equipmentSelected.length]);

  // Functions
  const getFiltersDropdown = () => {
    Axios.all([
      commonMethods.getLogsStatusDropdown(),
      commonMethods.getLogsTypeDropdown()
    ]).then(
      Axios.spread((status, type) => {
        status.unshift({ label: "All Status", value: null });
        type.unshift({ label: "All Types", value: null });

        // STATUS
        setStatusList(status);
        var index = status.findIndex((el: any) => el.label === "Active");
        setStatusFilterSelected(status[index].value); // Set to 'ACTIVE'

        // TYPE
        setTypeList(type);
      })
    );
  };

  const getUserListForDropdown = () => {
    commonMethods.getUserDropdownForGroupCompany().then(res => {
      setUserList(res);
    });
  };

  // const getCompanyListForDropdown = () => {
  //   commonMethods.getCompanyDropdownListByGcid().then(res => {
  //     setCompanyList(res);
  //   });

  //   commonMethods
  //     .getDepartmentDropdownListByGcid(props.userProfile.groupCompanyID)
  //     .then(res => {
  //       setDeptList(res);
  //     });
  // };

  const getAllEquipmentList = () => {
    logsService.getAllEquipmentList().then(res => {
      setEquipmentList(res);
    });
  };

  // This function is only created in case of double entry or wrong equipment added
  // Only Admins i.e. Tech or CQ are allowed to interact with this function
  const deleteEquipment = (rowData: LogisticsModel) => {
    if (adminPermission) {
      logsService
        .deleteEquipmentByLogsId(rowData.logisticsID)
        .then(res => {
          SetSuccess("Equipment Has Been Deleted");
        })
        .catch(err => {
          SetError("Issue encounted when deleting equipment");
        })
        .finally(() => {
          // Reset All
          getAllEquipmentList();
        });
    } else {
      SetError("Invalid Access!");
    }
  };

  const batchUpdate = () => {
    var logsIDList: Array<string> = [];
    equipmentSelected.forEach((eq: LogisticsModel) => {
      logsIDList.push(eq.logisticsID);
    });
    logsService
      .batchUpdateEquipment(updateFields.fieldName, {
        fieldValue: updateFields.fieldValue,
        logisticIDList: logsIDList
      })
      .then(res => {
        SetSuccess("Equipments Updated");
      })
      .catch(err => {
        SetError("Equipments Unable To Be Updated");
      })
      .finally(() => {
        // Reset All
        getAllEquipmentList();
        setEquipmentSelected([]);
        setUpdateFields({
          fieldName: "",
          fieldValue: ""
        });
      });
  };
  const displayStatus = (rowData: LogisticsModel) => {
    var index = statusList.findIndex(
      (el: DropdownModel) => rowData.logisticsStatusTypeID === el.value
    );
    if (statusList[index] === undefined) {
      return <span>STATUS?</span>;
    }
    return <span>{statusList[index].label}</span>;
  };
  const displayDate = (rowData: any, column: any) => {
    return commonMethods.displayDate_FileName(rowData[column.field])
  };

  const dtActionButtons = (rowData: LogisticsModel) => {
    return (
      <>
        <Button
          icon="pi pi-info-circle"
          onClick={() => {
            setRedirectOut(true);
            setRedirectPath("/equipment/" + rowData.equipmentSerialNo);
          }}
          className="p-button"
        />
        {adminPermission && (
          <>
            <Button
              icon="pi pi-pencil"
              onClick={() => {
                setRedirectOut(true);
                setRedirectPath("/logs/update/" + rowData.logisticsID);
              }}
              className="p-button-warning mx-1"
            />
            <Button
              icon="pi pi-trash"
              className="p-button-danger"
              onClick={() => {
                setDialogContent(rowData);
                setConfirmDialogStatus(true);
              }}
            />
          </>
        )}
      </>
    );
  };

  const suggestValues = (e: { originalEvent: Event; query: string }) => {
    let results: Array<any> = [];

    if (updateFields.fieldName !== "" && updateFields.fieldName === "status") {
      results = statusList.filter(status => {
        return status.label.toLowerCase().startsWith(e.query.toLowerCase());
      });
    }
    if (updateFields.fieldName !== "" && updateFields.fieldName === "user") {
      results = userList.filter(user => {
        return user.label.toLowerCase().startsWith(e.query.toLowerCase());
      });
    }

    setFieldValueSuggestion(results);
  };

  const suggestionTemplate = (value: any) => {
    if (updateFields.fieldName !== "" && updateFields.fieldName === "status") {
      return <>{value.logisticsStatusTypeName}</>;
    } else if (
      updateFields.fieldName !== "" &&
      updateFields.fieldName === "user"
    ) {
      return <>{value.label}</>;
    }
  };

  // Filter
  var header = (
    <div style={{ textAlign: "left" }}>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={e => setGlobalFilter(e.currentTarget.value)}
        />
      </span>
    </div>
  );
  let statusFilter = (
    <Dropdown
      value={statusFilterSelected}
      options={statusList}
      onChange={e => onStatusFilterChange(e)}
    />
  );

  const onStatusFilterChange = (event: any) => {
    if (refDT !== null) {
      refDT.filter(event.value, "logisticsStatusTypeID", "equals");
      setStatusFilterSelected(event.value);
    }
  };

  let typeFilter = (
    <Dropdown
      value={typeFilterSelected}
      options={typeList}
      onChange={e => onTypeFilterChange(e)}
    />
  );

  const onTypeFilterChange = (event: any) => {
    if (refDT !== null) {
      refDT.filter(event.value, "productCategory", "equals");
      setTypeFilterSelected(event.value);
    }
  };

  const dialogFooter = (
    <div>
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => {
          setConfirmDialogStatus(false);
          deleteEquipment(dialogContent);
        }}
      />
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setConfirmDialogStatus(false)}
      />
    </div>
  );

  return (
    <>
      {redirectOut ? (
        <Redirect
          push
          to={{
            pathname: redirectPath
          }}
        />
      ) : null}

      <div className="row">
        <Dialog
          header="Confirm delete?"
          footer={dialogFooter}
          visible={confirmDialog}
          style={{ width: "50vw" }}
          modal={true}
          onHide={() => setConfirmDialogStatus(false)}
        >
          <p>Are you sure you want to delete this information?</p>
          <p className="m-0">
            <span className="boldLabel">{dialogContent.equipmentName}:</span>{" "}
            {dialogContent.equipmentSerialNo}
          </p>
        </Dialog>
        <div className="col-12 col-xl-12">
          <div
            className="card"
            data-tour-id="logistics-list"
            data-tour="All Equipment under all company are listed here."
          >
            <h1>Logistics List</h1>
            {isLoading ? (
              <ProgressSpinner />
            ) : (
              <div className="datatable-centerHeader datatable-centerContent">
                <DataTable
                  ref={el => (refDT = el)}
                  value={equipList}
                  className="equipmentTable"
                  emptyMessage="No results found."
                  // responsive={true}
                  autoLayout={true}
                  paginator={true}
                  rows={15}
                  alwaysShowPaginator={false}
                  header={header}
                  globalFilter={globalFilter}
                  selection={equipmentSelected}
                  onSelectionChange={e => {
                    if (e.value.issuedToUser === null) {
                      return;
                    }
                    setEquipmentSelected(e.value);
                  }}
                >
                  <Column selectionMode="multiple" style={{ width: "2em" }} />
                  <Column
                    field="productCategory"
                    filter={true}
                    filterElement={typeFilter}
                  />
                  <Column field="equipmentName" header="Equipment" />
                  <Column
                    field="equipmentSerialNo"
                    filter={true}
                    filterPlaceholder={"Serial Number"}
                    style={{ width: "15em"}}
                  />
                  <Column
                    field="issuedToName"
                    filter={true}
                    filterPlaceholder={"Issued"}
                  />
                  <Column
                    field="logisticsStatusTypeID"
                    body={displayStatus}
                    filter={true}
                    filterElement={statusFilter}
                  />
                  <Column
                    field="accountedByName"
                    header="Accounted By"
                  />
                  <Column
                    field="issuedDate"
                    body={displayDate}
                    header="Issued Date"
                  />
                  <Column body={dtActionButtons} header="Action" />
                </DataTable>
              </div>
            )}
          </div>
        </div>
        <Fieldset
          legend="Selected"
          toggleable={true}
          collapsed={fieldsetClose}
          onToggle={e => setFieldsetClose(e.value)}
          className="col-3 equipment-selected-list"
          style={{ position: "fixed", bottom: 0, right: 0 }}
        >
          <div className="col-12 mb-3">{fieldsetContent}</div>
          <div className="col-12">
            <Dropdown
              value={updateFields.fieldName}
              options={[
                { label: "Issued To", value: "user" },
                { label: "Status", value: "status" }
              ]}
              onChange={e => {
                setUpdateFields({ ...updateFields, fieldName: e.value });
              }}
              placeholder="Update Field"
            />
            <AutoComplete
              value={
                updateFields.fieldName !== "" &&
                updateFields.fieldName === "user"
                  ? updateFields.fieldValue.label
                  : updateFields.fieldName === "status"
                  ? updateFields.fieldValue.logisticsStatusTypeName
                  : updateFields.fieldValue
              }
              onChange={(e: any) => {
                setUpdateFields({ ...updateFields, fieldValue: e.value });
              }}
              suggestions={fieldValueSuggestion}
              completeMethod={suggestValues}
              itemTemplate={suggestionTemplate}
            />
            {updateFields.fieldName !== "" &&
            updateFields.fieldName === "user" ? (
              <p style={{ color: "red" }} className="mb-0">
                Note: You are transfering ownership to a user
              </p>
            ) : (
              <></>
            )}

            <Button
              label="Batch Update"
              onClick={batchUpdate}
              className="mt-2"
            />
          </div>
        </Fieldset>
      </div>
    </>
  );
}
export default LogisticsList;
