import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "../resources/CommonMethods";
import { LogisticsStatusTypeModel } from "../resources/ExportClass";
import { LogisticsService } from "../../service/LogisticsService";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ToastStateContext } from "../resources/ToastContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

const logsService = new LogisticsService();
let statusType: LogisticsStatusTypeModel = new LogisticsStatusTypeModel();
let thisUpdateStatusType: LogisticsStatusTypeModel = new LogisticsStatusTypeModel();
/**
 * Display the page for Creation of Logistics Status Type
 * e.g. Serialised, Reserved, Return, Faulty, Written Off, NA
 * @param props
 */
function LogisticsStatusCreate(props: RouteComponentProps<any>) {
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const [visible, setVisibility] = useState<boolean>(false);
  const [statusObjects, setStatusObjects] = useState<
    Array<LogisticsStatusTypeModel>
  >([]);
  const [logCategoryList, setLogCategoryList] = useState();
  const [statusTypeName, setStatusTypeName] = useState<string>("");
  const [updateStatusTypeName, setUpdateStatusTypeName] = useState<string>("");

  useEffect(() => {
    getAllLogisticsStatusType();
    getAllLogisticsCategoryType();
  }, []);

  useEffect(() => {
    statusType.logisticsStatusTypeName = statusTypeName;
  }, [statusTypeName]);

  const getAllLogisticsStatusType = () => {
    logsService.getAllLogisticsStatusType().then((res) => {
      setStatusObjects(res);
    });
  };

  const getAllLogisticsCategoryType = () => {
    logsService.getAllLogisticsCategories().then(res => {
      setLogCategoryList(res);
    });
  }

  const addNewStatusType = () => {
    if (statusType.logisticsStatusTypeName.length === 0) {
      return;
    }
    logsService
      .addLogisticsStatusType(statusType)
      .then((res) => {
        SetSuccess("Equipment Status Added!");
      })
      .catch((err) => {
        SetError("Issue with adding new status");
      })
      .finally(() => {
        getAllLogisticsStatusType();
        setStatusTypeName("");
      });
  };

  const addNewCategory= () => {
    window.alert("not done yet ha.")
  }

  const updateStatusType = (rowData: LogisticsStatusTypeModel) => {
    logsService
      .UpdateLogisticsStatusType(rowData)
      .then((res) => {
        SetSuccess("Equipment Status Updated!");
      })
      .catch((err) => {
        SetError("Issue with updating status");
      })
      .finally(() => {
        getAllLogisticsStatusType();
        setStatusTypeName("");
      });
  };

  const deleteStatusType = (rowData: LogisticsStatusTypeModel) => {
    logsService
      .DeleteLogisticsStatusType(rowData.logisticsStatusTypeID)
      .then((res) => {
        SetSuccess("Equipment Status Delete!");
      })
      .catch((err) => {
        SetError("Issue with deleting status");
      })
      .finally(() => {
        getAllLogisticsStatusType();
        setStatusTypeName("");
      });
  };

  const prepareStatusToEdit = (rowData: LogisticsStatusTypeModel) => {
    setUpdateStatusTypeName(rowData.logisticsStatusTypeName);
    thisUpdateStatusType = rowData;
    setVisibility(true);
  };

  useEffect(() => {
    thisUpdateStatusType.logisticsStatusTypeName = updateStatusTypeName;
  }, [updateStatusTypeName]);

  const dtStatusTypeActionButtons = (rowData: LogisticsStatusTypeModel) => {
    return (
      <>
        {" "}
        <Button
          icon="pi pi-pencil"
          onClick={() => {
            // prepareStatusToEdit(rowData);
          }}
          className="p-button-warning"
        />{" "}
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            // deleteStatusType(rowData);
          }}
        />
      </>
    );
  };

  const dtCategoryActionButtons = (rowData: LogisticsStatusTypeModel) => {
    return (
      <>
        {" "}
        <Button
          icon="pi pi-pencil"
          onClick={() => {
            prepareStatusToEdit(rowData);
          }}
          className="p-button-warning"
        />{" "}
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            deleteStatusType(rowData);
          }}
        />
      </>
    );
  };

  const footer = (
    <div>
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => {
          updateStatusType(thisUpdateStatusType);
          setVisibility(false);
        }}
      />
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setVisibility(false)}
      />
    </div>
  );
  return (
    <>
      <Dialog
        header="Edit Status"
        footer={footer}
        visible={visible}
        style={{ width: "50vw" }}
        modal={true}
        onHide={() => setVisibility(false)}
      >
        <InputText
          value={updateStatusTypeName}
          onChange={(e) => setUpdateStatusTypeName(e.currentTarget.value)}
        />
      </Dialog>
      <div className="row">
        <div className="col-12 col-xl-6">
          <div
            className="card"
            data-tour-id="equipment-status-type"
            data-tour="Something about equipment status. Description coming soon."
          >
            <h1>Logisics Status Types</h1>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <span className="boldLabel">New Status Name</span>
                  <br />
                  <InputText
                    value={statusTypeName}
                    onChange={(e) => setStatusTypeName(e.currentTarget.value)}
                    className="mr-2"
                  />
                  <Button
                    label="Add"
                    onClick={addNewStatusType}
                    disabled={statusTypeName.length > 0 ? false : true}
                    className="mt-2"
                  />
                </div>
              </div>
            </div>
            <div className="datatable-centerHeader datatable-centerContent">
              <DataTable
                autoLayout={true}
                value={statusObjects}
                // editMode="row"
              >
                {/* <Column field="logisticsStatusTypeID" header="ID" /> */}
                <Column
                  field="logisticsStatusTypeName"
                  header="Status Type Name"
                />
                <Column body={dtStatusTypeActionButtons} header="Action" />
              </DataTable>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div
            className="card"
            data-tour-id="equipment-status-type"
            data-tour="Something about equipment status. Description coming soon."
          >
            <h1>Logisics Categories</h1>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <span className="boldLabel">New Category Name</span>
                  <br />
                  <InputText
                    value={statusTypeName}
                    onChange={(e) => setStatusTypeName(e.currentTarget.value)}
                    className="mr-2"
                  />
                  <Button
                    label="Add"
                    className="mt-2"
                    onClick={addNewCategory}
                    disabled={statusTypeName.length > 0 ? false : true}
                  />
                </div>
              </div>
            </div>
            <div className="datatable-centerHeader datatable-centerContent">
              <DataTable
                autoLayout={true}
                value={logCategoryList}
              >
                <Column
                  field="logisticsCategoryName"
                  header="Category Name"
                />
                <Column body={dtCategoryActionButtons} header="Action" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default LogisticsStatusCreate;
