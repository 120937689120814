import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { Component } from "react";
import { Redirect } from "react-router";
import { CompanyService } from "../../service/CompanyService";
import { ContractService } from "../../service/ContractService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError  from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { Link } from "react-router-dom";

export class ContractModel {
  public contractID: string = CommonMethods.EmptyGuid;
  public contractStatus: string = "Pending";
  public contractType: string = "New";
  public userID: string = CommonMethods.EmptyGuid;
  public identificationNumber: string = "";
  public jobStartDate: Date = new Date();
  public jobEndDate: Date = new Date();

  public jobTitleID: string = CommonMethods.EmptyGuid;
  public userRankID: string = CommonMethods.EmptyGuid;
  public contractDuration: number = 1;
  public workLocation: string = "";
  public workHours: number = 8;
  public workingDays: string = "";
  public workingDaysArr: string[] = [];
  public weeklyWorkDays: number = 5;
  public salaryPeriod: string = "Monthly";
  public payDay: number = 28;
  public basicSalary: number = 0;
  public allowance: number = 0;
  public yearlyAnnualLeave: number = 7;
  public yearlyOutpatientLeave: number = 14;
  public yearlyHospitalisationLeave: number = 60;
  public otherLeaves: string = "";
  public probationLength: number = 0;
  public noticePeriod: number = 3;
  public confidentiality: string = "";
  public nonCompete: string = "";
  public damageCompensation: string = "";
  public copyright: string = "";
  public createdTime: Date = new Date();
  public contractCreatedTime: Date = new Date();
  public currency: string = "";

  public cpfEligible: boolean | null = null;
  public contractEffectiveDate: Date | null = null;
  public jobGrade: string = "";

  // User Model
  public firstName: string = "";
  public lastName: string = "";
  public fullName: string = "";
  public departmentID: string = CommonMethods.EmptyGuid;
  public accStatus: string = "";

  public companyID: string = CommonMethods.EmptyGuid;
}

export interface ContractState {
  contractObj: ContractModel;
  contracts: ContractModel[];
  displayContracts: any[];
  redirectOut: boolean;
  redirectPath: string;
  redirectParam: string;

  globalFilter: string;

  dialogStr: string;
  visible: boolean;
  confirmStr: string;
  contractID: string;
  userView: boolean;
  userViewRedirect: string;
  isDeleteDialog: boolean;
  statusToBeUpdated: string;

  statusFilterSelected: string;
  statusFilterOptions: any[];

  isError: boolean;
  isLoading: boolean;
  errorMsg: string;

  userid: string;
}

class Contract extends Component<RouteComponentProps<any>, ContractState> {
  static contextType = ToastStateContext;

  commonMethods: CommonMethods;
  contractService: ContractService;
  companyService: CompanyService;
  op: any;
  dropdownOptions: any[];
  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      contractObj: new ContractModel(),
      contracts: [],
      displayContracts: [],
      redirectOut: false,
      redirectPath: "",
      redirectParam: "",
      globalFilter: "",
      dialogStr: "",
      visible: false,
      confirmStr: "",
      contractID: "",
      userView: false,
      userViewRedirect: "contract",
      isDeleteDialog: false,

      statusToBeUpdated: "",
      isError: false,

      errorMsg: "",
      isLoading: true,

      statusFilterSelected: "",
      statusFilterOptions: [],
      userid: "",
    };

    this.commonMethods = new CommonMethods();
    this.contractService = new ContractService();
    this.companyService = new CompanyService();
    this.nameTemplate = this.nameTemplate.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.dateTemplate = this.dateTemplate.bind(this);
    this.dropdownOptions = this.commonMethods.getContractEditStatusOptions();
    this.initViewFromUser = this.initViewFromUser.bind(this);
    this.initViewFromContracts = this.initViewFromContracts.bind(this);
    this.handleDeleteConfirmation = this.handleDeleteConfirmation.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    if (
      // From user view
      this.props.match.params !== {} &&
      this.props.match.params.id !== undefined
    ) {
      this.initViewFromUser(this.props.match.params.id);
    } else {
      this.initViewFromContracts();
    }
    let options = this.commonMethods.getContractAllStatusOptions();
    this.setState({ statusFilterOptions: options });
  }

  initViewFromUser(userIDfromQuery: string) {
    this.contractService
      .getContractsByUserID(userIDfromQuery)
      .then((res) => {
        this.setState({
          contracts: res,
          displayContracts: res,
          userView: true,
          userViewRedirect: "user",
          isLoading: false,
          userid: userIDfromQuery,
        });
        if (res[0].accStatus !== "Onboarding") {
          this.dropdownOptions = this.commonMethods.getContractEditExistingUserStatusOptions();
        }
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  initViewFromContracts() {
    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";

    this.contractService
      .getContractsByCompany(currentAccessingCompanyId)
      .then((res) => {
        this.setState({
          contracts: res,
          displayContracts: res,
          isLoading: false,
        });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }
  nameTemplate(rowData: ContractModel) {
    return (
      <span>
        {rowData.firstName} {rowData.lastName}
      </span>
    );
  }

  handleDeleteButtonClick(rowData: ContractModel) {
    this.setState({
      dialogStr: "Are you sure you want to delete this contract?",
      confirmStr: "Delete Contract",
      visible: true,
      contractID: rowData.contractID,
      isDeleteDialog: true,
      contractObj: rowData,
    });
  }

  handleDeleteConfirmation() {
    this.setState({ visible: false, isLoading: true }, () => {
      this.contractService
        .deleteContract(this.state.contractID)
        .then((res) => {
          let msg =
            this.state.contractObj.firstName + "'s contract has been deleted";
          this.context.SetSuccess(msg);

          if (this.state.userView) {
            this.initViewFromUser(this.state.userid);
          } else {
            this.initViewFromContracts();
          }
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.context.SetError(errorMsg);
          this.setState({
            isLoading: false,
          });
        });
    });
  }

  dateTemplate(rowData: ContractModel) {
    return this.commonMethods.displayDate(rowData.contractCreatedTime);
  }

  updateTemplate(rowData: ContractModel) {
    let disableChangeStatusButton = true;
    if (
      rowData.contractStatus === "Pending" ||
      (this.state.userView &&
        rowData.contractStatus === "Signed" &&
        rowData.accStatus === "Active")
    ) {
      disableChangeStatusButton = false;
    }
    let disabledDeleteButton = true;
    if (rowData.contractStatus === "Nullified") {
      disabledDeleteButton = false;
    }
    let disabledEditButton = true;
    if (
      rowData.contractStatus === "Pending" ||
      rowData.contractStatus === "Incomplete"
    ) {
      disabledEditButton = false;
    }

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          disabled={disabledEditButton}
          icon="pi pi-pencil"
          id="editButton"
          className="p-button-warning"
          onClick={() => {
            this.setState({
              redirectPath:
                "/admin/contract/update/" +
                rowData.contractID +
                "/" +
                this.state.userViewRedirect,
              redirectOut: true,
            });
          }}
        />
        <Button
          className="p-button-info mx-1"
          icon="pi pi-eye"
          onClick={() => {
            let path = "/admin/contract/view/" + rowData.contractID;
            if (this.state.userView) {
              path = "/admin/employees/contract_view/" + rowData.contractID;
            }
            this.setState({
              redirectPath: path,
              redirectOut: true,
            });
          }}
        />
        {/* <Button
          disabled={disabledDeleteButton}
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => this.handleDeleteButtonClick(rowData)}
        /> */}

        <Button
          disabled={disableChangeStatusButton}
          icon="pi pi-ellipsis-h"
          className="p-button-secondary"
          id="changestatusbutton"
          onClick={(e) => {
            this.setState({
              statusToBeUpdated: rowData.contractStatus,
              contractObj: rowData,
            });
            this.op.toggle(e);
          }}
        />
        <OverlayPanel
          ref={(el) => (this.op = el)}
          showCloseIcon={true}
          dismissable={true}
          appendTo={document.body}
        >
          <div className="p-grid p-fluid p-2">
            <span className="boldLabel pt-1 mr-2">Contract Status</span>
            <Dropdown
              value={this.state.statusToBeUpdated}
              options={this.dropdownOptions}
              onChange={(e) => {
                this.setState(
                  {
                    statusToBeUpdated: e.value,
                  },
                  () => this.confirmUpdateDialog(e)
                );
              }}
              placeholder="Please Select"
            />
          </div>
        </OverlayPanel>
      </div>
    );
  }

  confirmUpdateDialog(e: { originalEvent: Event; value: any }) {
    this.setState({
      dialogStr: "Update the contract status to " + e.value + "?",
      confirmStr: "Update Status",
      visible: true,
      isDeleteDialog: false,
    });
  }

  redirectToOnboardingUsers() {
    this.setState({
      redirectOut: true,
      redirectParam: "",
      redirectPath: "/admin/employees/onboarding/all/all",
    });
  }

  updateStatusConfirmation() {
    this.setState(
      {
        visible: false,
        isLoading: true,
        contractObj: {
          ...this.state.contractObj,
          contractStatus: this.state.statusToBeUpdated,
        },
      },
      () => {
        let name = this.state.contractObj.firstName;

        this.contractService
          .updateContractStatus(this.state.contractObj)
          .then((res) => {
            this.setState({ contractObj: res });
            if (res.contractStatus === "Signed") {
              if (!this.state.userView) {
                let msg =
                  "Contract has been signed and can be viewed at Onboarding Users on the User page.";
                this.context.SetSuccess(msg);

                this.redirectToOnboardingUsers();
              }
            } else {
              let msg =
                name +
                "'s contract has been updated to " +
                res.contractStatus +
                ".";
              this.context.SetSuccess(msg);
            }
            if (this.state.userView) {
              this.initViewFromUser(this.state.userid);
            } else {
              this.initViewFromContracts();
            }
          })
          .catch((error) => {
            let errorMsg = this.commonMethods.getErrorMessage(error);
            this.context.SetError(errorMsg);
            this.setState({
              isLoading: false,
            });
          });
      }
    );
  }

  goBack() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/employees",
    });
  }

  render() {
    let heading = (
      <div style={{ textAlign: "left" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            onInput={(e) =>
              this.setState({ globalFilter: e.currentTarget.value })
            }
          />
        </span>
      </div>
    );

    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
            search: this.state.redirectParam,
          }}
        />
      );
    }

    let displayHeader;
    if (this.state.userView) {
      displayHeader = (
        <h1 className="pb-2">
          Contracts of {this.state.contracts[0].firstName}{" "}
          {this.state.contracts[0].lastName}
          <span className="pl-3">
            <Button
              type="button"
              icon="pi pi-plus"
              iconPos="right"
              className="p-button-danger"
              label="Add"
              onClick={() => {
                this.state.userView
                  ? this.setState({
                      redirectPath:
                        "/admin/contract/create/" + this.props.match.params.id,
                      redirectOut: true,
                    })
                  : this.setState({
                      redirectPath: "/admin/contract/create/",
                      redirectOut: true,
                    });
              }}
              disabled={
                this.state.contracts.length === 1 &&
                this.state.contracts[0].contractStatus === "Incomplete"
              }
            />
          </span>
        </h1>
      );
    } else {
      displayHeader = (
        <>
          <h1 className="pb-2">
            Contracts
            <span className="pl-3">
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                className="p-button-danger"
                label="Add"
                onClick={() => {
                  this.setState({
                    redirectPath: "/admin/contract/create",
                    redirectOut: true,
                  });
                }}
              />
            </span>
          </h1>
          <p>
            Here are contracts for brand new hires. To create new contracts for
            existing/deactivated users, head to{" "}
            <Link to="/admin/employees">Employees</Link> menu.
          </p>
        </>
      );
    }

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />; // or some growl/message that stays
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={this.state.contracts}
            header={heading}
            globalFilter={this.state.globalFilter}
            autoLayout={true}
            rows={10}
            paginator={true}
            emptyMessage="No pending contracts found."
          >
            <Column body={this.nameTemplate} header="User" />
            {/* <Column field="firstName" header="firstname" className="d-none" />
            <Column field="lastName" header="lastname" className="d-none" /> */}
            {/* First name last name columns are for search bar */}
            {/* <Column field="companyName" header="Company" /> */}
            <Column field="departmentName" header="Department" />
            <Column field="contractType" header="Type" />
            <Column field="contractStatus" header="Status" sortable={true} />
            {/* <Column field="accStatus" header="User Account" /> */}
            <Column body={this.dateTemplate} header="Created On" />
            <Column
              body={this.updateTemplate}
              header="Actions"
              className="p-col-3"
            />
          </DataTable>
        </div>
      );
    }

    let backButton;
    if (this.state.userView) {
      backButton = (
        <Button
          label="Back to Users"
          onClick={this.goBack}
          icon="pi pi-angle-left"
          iconPos="left"
          className="p-button-primary my-2"
        />
      );
    }

    return (
      <div className="row">
        <div className="col-12 col-xl-10">
          {backButton}
          <div className="card">
            {displayHeader}

            <Dialog
              className="popupDialog"
              header={this.state.confirmStr}
              visible={this.state.visible}
              modal={true}
              onHide={() => this.setState({ visible: false })}
              footer={
                <div>
                  <Button
                    label="Yes"
                    type="button"
                    iconPos="left"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={() => {
                      this.state.isDeleteDialog
                        ? this.handleDeleteConfirmation()
                        : this.updateStatusConfirmation();
                    }}
                  />
                </div>
              }
            >
              {this.state.dialogStr}
            </Dialog>

            {display}
          </div>
        </div>
      </div>
    );
  }
}

export default Contract;
