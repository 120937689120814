import { CommonMethods } from "../resources/CommonMethods";

export class AccountingCodeModel {
    public accountingCodeID: string = CommonMethods.EmptyGuid;
    
    public accountingCode: string = "";
    public accountingCodeName: string = "";
    public accountingCodeType: string = "";
    public accountingCodeDescription: string = "";
    
    public accountingCodeStatus: string = ""; // Here onwards needed?
    public createdTime: Date = new Date();
    public groupCompanyID: string = CommonMethods.EmptyGuid;
}