import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import CampaignService from "../../service/CampaignService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { CampaignModel } from "./Campaign";
import { InsightsrnMethods } from "../resources/InsightsrnMethods";
import { ToastStateContext } from "../resources/ToastContext";
import { TaskList } from "../resources/ListOfTasks";
import { useParams } from "react-router";

let campaignService = new CampaignService();
let commonMethods = new CommonMethods();
let insightsMethods = new InsightsrnMethods();
function CampaignSummary(props: RouteComponentProps<any>) {
  //Growls
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );

  const { SetError } = React.useContext(ToastStateContext);
  const { SetSuccess } = React.useContext(ToastStateContext);

  // const [redirectOut, setRedirectOut] = useState<boolean>(false);
  // const [redirectPath, setRedirectPath] = useState<string>("");

  const [campaignObj, setCampaignObj] = useState<CampaignModel>(
    new CampaignModel()
  );
  const [componentUpdated, setComponentUpdated] = useState(false);

  const [isSalesView, setIsSalesView] = useState(
    props.userProfile.taskList.includes(TaskList.salesaccess)
  );
  // const [financePermissions] = useState(
  //   props.userProfile.taskList.includes(TaskList.financeaccess)
  // );

  const [insightsRNAccess] = useState(
    props.userProfile.taskList.includes(TaskList.insightsrnaccess)
  );

  const [highlightValues, setHighlightValues] = useState<string>("");

  let { id } = useParams<{id:string}>();

  useEffect(() => {
    getCampaignDetails();
  }, []);

  useEffect(() => {
    if (!componentUpdated) {
      if (commonMethods.isPropsLoaded(props)) {
        if (props.userProfile.taskList.includes(TaskList.salesaccess)) {
          setIsSalesView(true);
        }
        setComponentUpdated(true);
      }
    }
  }, []);

  const getCampaignDetails = () => {
    if (id !== undefined) {
      campaignService
        .getCampaignByCampaignID(id)
        .then((res) => {
          setCampaignObj(res);
          if (res.hasClient || res.hasAgent) {
            props.others(true);
          } else {
            props.others(false);
          }
        })
        .catch((err) => {
          setErrorMessage(commonMethods.getErrorMessage(err));
          setIsError(true);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    matchCampaignDeliverableAmount();
  }, [campaignObj]);

  const matchCampaignDeliverableAmount = () => {
    if (campaignObj.totalDeliverablesAmount !== campaignObj.campaignAmount) {
      setHighlightValues("text-danger");
    }
  };

  const createIrnCampaign = () => {
    setIsLoading(true);
    insightsMethods
      .createCampaign(campaignObj)
      .then((res) => {
        setIsLoading(false);
        SetSuccess("IRN Report Generated");
        window.location.reload();
      })
      .catch((err) => {
        setIsLoading(false);
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  // const closeCampaignButtons = () => {
  //   if (!financePermissions === false && isLoading === false) {
  //     if (campaignObj.campaignStatus !== "Active") {
  //       return (
  //         <>
  //           <Button
  //             className="editButtonFloatRight"
  //             label="Re-Open Campaign"
  //             onClick={e => {
  //               let campObj: CampaignModel = campaignObj;
  //               campObj.campaignStatus = "Active";
  //               campaignService
  //                 .updateCampaignStatus(campObj)
  //                 .catch(err => setIsError(true));
  //             }}
  //           />
  //         </>
  //       );
  //     } else {
  //       return (
  //         <>
  //           <Button
  //             className="editButtonFloatRight"
  //             label="Close Campaign"
  //             onClick={e => {
  //               let campObj: CampaignModel = campaignObj;
  //               campObj.campaignStatus = "Closed";
  //               campaignService
  //                 .updateCampaignStatus(campObj)
  //                 .then((res: CampaignModel) => {})
  //                 .catch(err => setIsError(true));
  //             }}
  //           />
  //         </>
  //       );
  //     }
  //   }
  // };

  const salesPersonTemplate = () => {
    if (campaignObj.salesPersonsForDisplay.length === 0) {
      return "-";
    }
    return campaignObj.salesPersonsForDisplay.map(
      (item: string, index: number) => {
        if (index === campaignObj.salesPersonsForDisplay.length - 1) {
          return item;
        } else {
          return item + ", ";
        }
      }
    );
  };

  const pmoTemplate = () => {
    if (campaignObj.campaignProjectManagersForDisplay.length === 0) {
      return "-";
    }
    return campaignObj.campaignProjectManagersForDisplay.map(
      (item: string, index: number) => {
        if (
          index ===
          campaignObj.campaignProjectManagersForDisplay.length - 1
        ) {
          return item;
        } else {
          return item + ", ";
        }
      }
    );
  };

  const clientFields = () => {
    if (campaignObj.hasClient) {
      return (
        <>
          <div className="p-col-4">
            <strong>Client Company: </strong>
          </div>
          <div className="p-col-8">{campaignObj.clientCompanyName}</div>

          <div className="p-col-4">
            <strong>Client Contact: </strong>
          </div>
          <div className="p-col-8">{campaignObj.clientContactName}</div>
        </>
      );
    }
  };

  const insightsFields = () => {
    if (insightsRNAccess && campaignObj.campaignTypeName === "Advertorial") {
      return (
        <>
          {" "}
          <div className="p-col-4">
            <strong>Publisher: </strong>
          </div>
          <div className="p-col-8">{campaignObj.publisherName}</div>
          <div className="p-col-4">
            <strong>Created on iNSIGHTSRN: </strong>
          </div>
          <div className="p-col-8">
            {campaignObj.irnCamId ? (
              "Yes"
            ) : (
              <>
                No{" "}
                {insightsRNAccess ? (
                  <>
                    {" - "}{" "}
                    <span
                      className="text-info pointer"
                      onClick={createIrnCampaign}
                    >
                      Generate now
                    </span>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          <div className="p-col-4">
            <strong>iNSIGHTSRN Link: </strong>
          </div>
          <div className="p-col-8">
            {campaignObj.irnCamId ? (
              <a
                className="text-info"
                href={
                  "https://insightsrn.com/report/" + campaignObj.irnUrlRoute
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Report
              </a>
            ) : (
              "-"
            )}
          </div>
        </>
      );
    }
  };
  const salesFields = () => {
    if (isSalesView && campaignObj.hasClient) {
      return (
        <>
          <div className={"p-col-4 " + highlightValues}>
            <strong> Campaign Amount: </strong>
          </div>
          <div className={"p-col-8 " + highlightValues}>
            {campaignObj.currency}{" "}
            <NumberFormat
              displayType={"text"}
              value={campaignObj.campaignAmount}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
          <div className={"p-col-4 " + highlightValues}>
            <strong> Total Amount from Deliverables: </strong>
          </div>
          <div className={"p-col-8 " + highlightValues}>
            {campaignObj.currency}{" "}
            <NumberFormat
              displayType={"text"}
              value={campaignObj.totalDeliverablesAmount}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
          {/* <div className="p-col-4">
            <strong>Tax Value: </strong>
          </div>
          <div className="p-col-8">
            {campaignObj.currency}{" "}
            <NumberFormat
              displayType={"text"}
              value={campaignObj.gstValue}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div> */}
          <div className="p-col-4">
            <strong>Outstanding Payment: </strong>
          </div>
          <div className="p-col-8">
            {campaignObj.currency}{" "}
            <NumberFormat
              displayType={"text"}
              value={campaignObj.outstandingPayment}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
        </>
      );
    }
  };

  const agentFields = () => {
    if (campaignObj.hasAgent) {
      return (
        <>
          <div className="p-col-4">
            <strong>Agency Company: </strong>
          </div>
          <div className="p-col-8">{campaignObj.agentCompanyName}</div>

          <div className="p-col-4">
            <strong>Agency Contact: </strong>
          </div>
          <div className="p-col-8">{campaignObj.agentContactName}</div>
        </>
      );
    }
  };

  let page = (
    <>
      <div className="p-col-12">
        <div className="p-grid">
          <div className="p-col-12 p-lg-6">
            <div className="p-grid">
              <div className="p-col-4">
                <strong> Serial: </strong>
              </div>
              <div className="p-col-8">{campaignObj.campaignSerialNo}</div>
              <div className="p-col-4">
                <strong> Type: </strong>
              </div>
              <div className="p-col-8">{campaignObj.campaignTypeName}</div>

              <div className="p-col-4">
                <strong>Company: </strong>
              </div>
              <div className="p-col-8">{campaignObj.companyName}</div>
              <div className="p-col-4">
                <strong>Status: </strong>
              </div>
              <div className="p-col-8">{campaignObj.campaignStatus}</div>
              {clientFields()}
              {agentFields()}
              <div className="p-col-4">
                <strong>Start Date: </strong>
              </div>
              <div className="p-col-8">
                {commonMethods.displayDate(new Date(campaignObj.startDate))}
              </div>
              <div className="p-col-4">
                <strong>End Date: </strong>
              </div>
              <div className="p-col-8">
                {commonMethods.displayDate(new Date(campaignObj.endDate))}
              </div>
              {insightsFields()}
            </div>
          </div>
          <div className="p-col-12 p-lg-6">
            <div className="p-grid">
              {salesFields()}

              <div className="p-col-4">
                <strong>Sales Persons: </strong>
              </div>
              <div className="p-col-8">{salesPersonTemplate()}</div>

              <div className="p-col-4">
                <strong>Project Managers: </strong>
              </div>
              <div className="p-col-8">{pmoTemplate()}</div>
              <div className="p-col-4">
                <strong>Created By: </strong>
              </div>
              <div className="p-col-8">{campaignObj.createdByName}</div>
              <div className="p-col-4">
                <strong>Created On: </strong>
              </div>
              <div className="p-col-8">
                {commonMethods.displayDate(new Date(campaignObj.createdTime!))}
              </div>
              {campaignObj.campaignStatus === "Published" && (
                <>
                  <div className="p-col-4">
                    <strong>Closed By: </strong>
                  </div>
                  <div className="p-col-8">{campaignObj.closedByName}</div>
                  <div className="p-col-4">
                    <strong>Closed On: </strong>
                  </div>
                  <div className="p-col-8">
                    {commonMethods.displayDate(
                      new Date(campaignObj.closedDate)
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <div className="row">
      {/* {redirectOut ? (
        <Redirect
          push
          to={{
            pathname: redirectPath
          }}
        />
      ) : null} */}
      <div
        className="col-12"
        data-tour-id="campaign-summary"
        data-tour="Information of the campaign."
      >
        <div className="card">
          <h1 className="pb-2">
            Campaign Summary - {campaignObj.campaignName}
            {/* {actionTemplate()} */}
          </h1>
          {display}
        </div>
      </div>
    </div>
  );
}

export default CampaignSummary;
