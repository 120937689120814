import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useContext, useRef, useState } from 'react'
import PublicProfileService from '../../Service/PublicProfileService';
import { PP_ACTIONS, PublicProfileContext } from './PublicProfile';

export default function AddTelegramDialog({
    showAddDialog,
    setShowAddDialog,
}: {
    showAddDialog: boolean;
    setShowAddDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { state, dispatch } = useContext(PublicProfileContext);
    const [content, setContent] = useState("")

    const headerElement = (
        <div>
            <h5>Add Telegram</h5>
        </div>
    )

    // TODO: added validation for Telegram Username
    // only allow a-z, 0-9, underscores, minimum length 5 chars
    async function submitAddedTelegram(evt: React.FormEvent<HTMLFormElement>) {
        evt.preventDefault();
        const publicProfileService = new PublicProfileService();
        if (!state.userProfile.hasCreatedPublicProfile) {
            console.log('inserting telegram')
            const result = await publicProfileService.insertTelegramUsername(state.userProfile.userId, content)
            dispatch({ type: PP_ACTIONS.ADD_TELEGRAM, payload: result.data.telegramUsername })
        }
        if (state.userProfile.hasCreatedPublicProfile) {
            console.log('updating telegram')
            const result = await publicProfileService.editTelegramUsername(state.userProfile.userId, content)
            dispatch({ type: PP_ACTIONS.EDIT_TELEGRAM, payload: result.data.telegramUsername })
        }
        setShowAddDialog(false)
    }

    function hideDialog() {
        setShowAddDialog(false)
    }

    return (
        <div>
            <Dialog
                visible={showAddDialog}
                onHide={hideDialog}
                className="rounded-border telegram-dialog"
                showHeader={true}
                header={headerElement}
                blockScroll
                dismissableMask={true}
            >
                <form onSubmit={submitAddedTelegram}>
                    <div className="form-flex-container">
                        <span className="p-float-label p-input-icon-left position-relative width-100">
                            <span className="material-icons input-at-icon">alternate_email</span>
                            <InputText autoFocus id="addTelegramInput" value={content} onChange={(e) => setContent(e.currentTarget.value)} />
                            <label htmlFor="addTelegramInput">Telegram Username</label>
                        </span>
                        <div className="form-flex-item">
                        </div>
                        <div className="postform-buttons form-flex-item">
                            <Button
                                type="submit"
                                label="Submit"
                                disabled={!content || content.length < 5}
                                className="postform-submit"
                            />
                        </div>
                    </div>
                </form>
            </Dialog >
        </div>
    )
}
