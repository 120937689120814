import { Chart } from "primereact/chart";
import randomColor from "randomcolor";
import React, { useEffect, useState } from "react";
import { ExternalCostService } from "../../service/ExternalCostService";
import { RouteComponentProps, CommonMethods } from "../resources/CommonMethods";
import {
  BarChartModel,
  StackBarChartDatasets,
  StackBarChartModel,
} from "../resources/ExportClass";
import CustomError from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";

const MonthlyTotalCostModel = {
  paymentMonth: 0,
  totalExternalCostAmount: 0,
  totalPayroll: 0,
  totalClaimsPayout: 0,
};

function MonthlyCostsWidget(props: RouteComponentProps<any>) {
  const externalCostsSvc = new ExternalCostService();
  const commonMethods = new CommonMethods();

  // Loading, Error, Redirect
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [isNoResults, setNoResults] = useState<boolean>(true);
  const [errorMsg, setErrorMessage] = useState<string>("");
  const [monthlyExternalCosts, setMonthlyExternalCosts] = useState([
    { ...MonthlyTotalCostModel },
  ]);
  const [monthlyExternalCostsData, setMonthlyExternalCostsData] = useState<
    BarChartModel
  >(new BarChartModel());
  const [selectedCurrency, setSelectedCurrency] = useState("SGD");

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    if (props.others !== undefined) {
      if (myAbortController.signal.aborted) {
        return;
      }
      setSelectedCurrency(props.others);
    }
    return () => {
      myAbortController.abort();
    };
  }, [props.others]);

  // Get Bar Chart For Monthly Operational Cost
  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    externalCostsSvc
      .getBarChartMonthlyCostByCurrency(
        props.userProfile.groupCompanyID,
        selectedCurrency
      )
      .then((res) => {
        if (myAbortController.signal.aborted) {
          return;
        }
        setNoResults(false);
        setMonthlyExternalCosts(res);
        setIsLoading(false);
      })
      .catch((err) => {
        if (myAbortController.signal.aborted) {
          return;
        }
        if (commonMethods.isWarning(err)) {
          setNoResults(true);
          setIsLoading(false);
        } else {
          let errorMsg = commonMethods.getErrorMessage(err);
          setIsError(true);
          setErrorMessage(errorMsg);
          setIsLoading(false);
        }
      });

      return () => {
        myAbortController.abort();
      };
  }, [selectedCurrency]);

  useEffect(() => {

    const myAbortController: AbortController = new AbortController();


    let stackedData: StackBarChartModel = new StackBarChartModel();
    var bgColorArray = randomColor({
      count: 3,
      luminosity: "light",
      hue: "random",
    });

    let extCost: StackBarChartDatasets = new StackBarChartDatasets(
      "Operational Costs",
      bgColorArray[0]
    );
    let payroll: StackBarChartDatasets = new StackBarChartDatasets(
      "Payroll",
      bgColorArray[1]
    );
    let claims: StackBarChartDatasets = new StackBarChartDatasets(
      "Claims",
      bgColorArray[2]
    );

    var months = [
      "",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    monthlyExternalCosts.forEach((costType) => {
      // Push month into StackedData
      stackedData.labels.push(months[costType.paymentMonth]);

      // Push Values into data
      extCost.data.push(costType.totalExternalCostAmount);
      payroll.data.push(costType.totalPayroll);
      claims.data.push(costType.totalClaimsPayout);
    });

    stackedData.datasets = [extCost, payroll, claims];

    if (myAbortController.signal.aborted) {
      return;
    }
    setMonthlyExternalCostsData(stackedData);


    return () => {
      myAbortController.abort();
    };

  }, [monthlyExternalCosts]);

  const stackedOptions = {
    tooltips: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  };

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else if (isNoResults) {
    display = <div className="text-center">Nothing to display</div>;
  } else {
    display = (
      <Chart
        type="bar"
        data={monthlyExternalCostsData}
        options={stackedOptions}
      />
    );
  }

  return display;
}

export default MonthlyCostsWidget;
