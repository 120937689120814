import { CommonMethods } from "../../resources/CommonMethods";

export class ScoreIndicatorModel {
  public indicatorID: string = CommonMethods.EmptyGuid;
  public indicatorName: string = "";
  public departmentID: string = CommonMethods.EmptyGuid;
  public userRankID: string = CommonMethods.EmptyGuid;
  public points: number = 0;
  public customPoint: boolean = false;
}

export class DepartmentScoreIndicatorModel {
  public scoreIndicatorList: Array<ScoreIndicatorModel> = new Array<
    ScoreIndicatorModel
  >();
  public companyDepartmentModel: any;
}
