import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { RoomBookingService } from "../../service/RoomBookingService";
import { CommonMethods } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";

function MyRoomBookings(props: any) {
  let commonMethods = new CommonMethods();
  let roomBookingService = new RoomBookingService();
  
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );

  const [bookings, setBookings] = useState<any[]>([]);
  const [bookingsHistory, setBookingsHistory] = useState<any[]>([]);
  const [bookingID, setBookingID] = useState<string>("");

  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [dialogStr, setDialogStr] = useState<string>("");
  const [confirmStr, setConfirmStr] = useState<string>("");

  useEffect(() => {
    getBookingsForUser();
  }, []);

  const setDialog = (header: string, content: any) => {
    setConfirmStr(header);
    setDialogStr(content);
  };

  const getBookingsForUser = async () => {
    roomBookingService
      .getMyRoomBookings()
      .then(res => {
        setBookings(res);
      })
      .catch(err => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
      });
    roomBookingService
      .getMyRoomBookingsHistory()
      .then(res => {
        setBookingsHistory(res);
      })
      .catch(err => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
      });
    setIsLoading(false);
  };

  const deleteRoomBooking  = () => {
    roomBookingService
      .deleteRoomBooking(bookingID)
      .then(async res => {
        SetSuccess("Success!", "Booking has been deleted.");
        setDialogVisibility(false);
      })
      .catch(async err => {
        SetError(commonMethods.getErrorMessage(err));
        setDialogVisibility(false);
      })
      .finally(() => {
        getBookingsForUser();
      });
  };

  const concatDuration = (start: Date, end: Date) => {
    const startTime = commonMethods.displayTime12Hour(start);
    const endTime = commonMethods.displayTime12Hour(end);
    // let options = {
    //   hour: "numeric",
    //   minute: "2-digit"
    // };
    return startTime +" - " + endTime
    // return `${startTime.toLocaleTimeString(
    //   "en-US",
    //   options
    // )} - ${endTime.toLocaleTimeString("en-US", options)}`;
  };

  const getDateDisplay = (date: string) => {
    return commonMethods.displayDate(new Date(date));
  };

  const timeTemplate = (rowData: any) => {
    let duration = concatDuration(rowData.startDateTime, rowData.endDateTime);
    return duration;
  };
  const dateTemplate = (rowData: any) => {
    let dateToDisplay = commonMethods.displayDate(rowData.bookedDate);
    return dateToDisplay;
  };

  let header = <div style={{ textAlign: "left" }}></div>;

  const actionTemplate = (rowData: any) => {
    let table = (
      <>
        <p>Are you sure you want to delete your booking?</p>
        <table className="leave-info-dialog table">
          <tbody>
            <tr>
              <td className="font-weight-bold">Room Name:</td>
              <td>{rowData.roomName}</td>
            </tr>

            <tr>
              <td className="font-weight-bold">Duration:</td>
              <td>
                {concatDuration(rowData.startDateTime, rowData.endDateTime)}
              </td>
            </tr>

            <tr>
              <td className="font-weight-bold">Date:</td>
              <td>{getDateDisplay(rowData.bookedDate)}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
    
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Edit Room Booking"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            setBookingID(rowData.roomBookingID);
            setRedirectOut(true);
            setRedirectPath("book/" + rowData.roomBookingID);
          }}
        />
        <Button
          tooltip="Delete Room Booking"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setBookingID(rowData.roomBookingID);
            setDialog("Delete Booking", table);
            setDialogVisibility(true);
          }}
        />
      </div>
    );
  };

  const display = (history: boolean) => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMessage} />;
    } else {
      return (
        <div className="row">
          <div className="col-12">
            <div className={history ? "" : "card"}>
              {history ? null : (
                <div>
                  <h1 className="pb-2">
                    My Bookings
                    <span className="pl-3">
                      <Button
                        label="Make Booking"
                        onClick={() => {
                          setRedirectOut(true);
                          setRedirectPath("book/");
                        }}
                      />
                    </span>
                  </h1>
                </div>
              )}
              <div
                data-tour-id="personal-bookings"
                data-tour="Your past and scheduled bookings are here. Use the action buttons to interact with them. You can edit or delete scheduled bookings!"
              >
                <div className="datatable-centerHeader datatable-centerContent">
                  <DataTable
                    header={header}
                    value={history ? bookingsHistory : bookings}
                    emptyMessage="No bookings to show."
                    paginator={true}
                    alwaysShowPaginator={false}
                    rows={15}
                    autoLayout={true}
                  >
                    <Column header="Room" field="roomName" />
                    <Column header="Purpose" field="purpose" />
                    <Column header="Remarks" field="remarks" />
                    <Column header="Duration" body={timeTemplate} />
                    <Column header="Date" body={dateTemplate} />
                    {history ? null : (
                      <Column header="Actions" body={actionTemplate} />
                    )}
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Okay"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                deleteRoomBooking();
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>
      {display(false)}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <Accordion>
              <AccordionTab header="History">{display(true)}</AccordionTab>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyRoomBookings;
