import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import classNames from "classnames";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { ProgressSpinner } from "primereact/progressspinner";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova/theme.css";
import * as React from "react";
// GA
import ReactGA from "react-ga";
import { Route } from "react-router";
import "./App.scss";
import AppMain from "./AppMain";
import SafeEntry from "./components/attendance/SafeEntry";
import Homepage from "./components/landingpages/Homepage";
import Register from "./components/landingpages/Register";
import { Login } from "./components/Login";
import ToastStateProvider, { ToastStateContext } from "./components/resources/ToastContext";
import { MenuList } from "./components/resources/MenuList";
import "./layout/layout.scss";
import { LoginService } from "./service/LoginService";
import BusinessCard from "./components/landingpages/BusinessCard";

ReactGA.initialize("UA-9826438-9");
ReactGA.pageview(window.location.pathname + window.location.search);

interface AppProps {}

interface AppState {
  layoutMode: string;
  layoutColorMode: string;
  staticMenuInactive: boolean;
  overlayMenuActive: boolean;
  mobileMenuActive: boolean;
  isLogin: boolean;
  loaded: boolean;
  businessCard: boolean;
  errorMsg: string;
}

export default class App extends React.Component<AppProps, AppState> {
  loginService: LoginService;
  menuClick: any;
  sidebar: any;
  menuList: any;
  static contextType = ToastStateContext;
  constructor(props: AppProps) {
    super(props);
    this.state = {
      layoutMode: "static",
      layoutColorMode: "light",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      isLogin: false,
      errorMsg: "",
      loaded: false,
	  businessCard: false
    };

    this.loginCallback = this.loginCallback.bind(this);
    this.loginService = new LoginService();
    this.disableReactDevTools = this.disableReactDevTools.bind(this);
    this.menuList = new MenuList();
  }

  componentDidMount() {
    // enable the line below before production build
    this.disableReactDevTools();

	// check if business card
	this.businessCardCheck();

    // check if already login
    this.loginService
      .checkIsLoggedIn()
      .then((res) => {
        this.setState({ isLogin: true, loaded: true });
      })
      .catch((err) => {
        this.setState({ isLogin: false, loaded: true });
      });
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  }

  disableReactDevTools() {
    const noop = (): void => undefined;
    const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

    if (typeof DEV_TOOLS === "object") {
      for (const [key, value] of Object.entries(DEV_TOOLS)) {
        DEV_TOOLS[key] = typeof value === "function" ? noop : null;
      }
    }
  }

  businessCardCheck() {
	if (window.location.pathname.includes("/businesscard")) {
		this.setState({ businessCard: true });
	}
  }

  //    __  __                   __  __       _    _             _
  //   |  \/  | ___  _ _  _  _  |  \/  | ___ | |_ | |_   ___  __| | ___
  //   | |\/| |/ -_)| ' \| || | | |\/| |/ -_)|  _|| ' \ / _ \/ _` |(_-<
  //   |_|  |_|\___||_||_|\_,_| |_|  |_|\___| \__||_||_|\___/\__,_|/__/
  //

  addClass(element: any, className: string) {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  }

  removeClass(element: any, className: string) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  loginCallback(returnMsg: string) {
    if (returnMsg === "success") {
      this.setState({ isLogin: true }, () => {
        window.location.replace("/");
      });
      // window.location.replace("/");
    }
  }

  render() {
    // const logo = this.state.layoutColorMode === "dark"
    //     ? "/assets/layout/images/logo-white.svg"
    //     : "/assets/layout/images/logo.svg";

    const wrapperClass = classNames("layout-wrapper", {
      "layout-overlay": this.state.layoutMode === "overlay",
      "layout-static": this.state.layoutMode === "static",
      "layout-static-sidebar-inactive":
        this.state.staticMenuInactive && this.state.layoutMode === "static",
      "layout-overlay-sidebar-active":
        this.state.overlayMenuActive && this.state.layoutMode === "overlay",
      "layout-mobile-sidebar-active": this.state.mobileMenuActive,
    });

    if (window.location.href.includes("/entryexit")) {
      document.title = "Entry/Exit";
      return (
        <div id="wrapper" className={wrapperClass + " whitebg"}>
          <ToastStateProvider>
            <Route path="/entryexit/:id" exact component={SafeEntry} />
          </ToastStateProvider>
        </div>
      );
    }


	if (this.state.businessCard) {
		return (
			<Route path = "/businesscard/:id" exact component={BusinessCard}/>
	  	);
	} else {
		if (!this.state.isLogin && this.state.loaded) {
			// Not login, no error
			return (
				<div id="wrapper" className={wrapperClass}>
					<Homepage
						login={<Login loginCallback={this.loginCallback} />}
						register={<Register loginCallback={this.loginCallback} />}
					/>
				</div>
			);
		} else if (this.state.loaded && this.state.isLogin) {
			// Login
			return <AppMain isLogin={this.state.isLogin}></AppMain>;
		} else {
			return (
				<div id="wrapper" className={wrapperClass}>
				<div className="text-center"><ProgressSpinner /></div>
				</div>
			);
		}
	}

	// if (!this.state.isLogin && this.state.loaded) {
	// 	// Not login, no error
	// 	return (
	// 		<div id="wrapper" className={wrapperClass}>
	// 			<Homepage
	// 				login={<Login loginCallback={this.loginCallback} />}
	// 				register={<Register loginCallback={this.loginCallback} />}
	// 			/>
	// 		</div>
	// 	);
	// } else if (this.state.loaded && this.state.isLogin) {
	// 	// Login
	// 	return <AppMain isLogin={this.state.isLogin}></AppMain>;
	// } else {
	// 	return (
	// 		<div id="wrapper" className={wrapperClass}>
	// 		<div className="text-center"><ProgressSpinner /></div>
	// 		</div>
	// 	);
	// }
  }
}
