import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import ReactTooltip from "react-tooltip";
import { CampaignService } from "../../service/CampaignService";
import Board from "../deliverable/Board";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { TaskList } from "../resources/ListOfTasks";
import { DeliverableModel } from "../deliverable/DeliverablesInCampaign";
import DeliverableService from "../../service/DeliverableService";
import _ from "lodash";

export class CampaignModel {
  public campaignID: string | null = null;
  public campaignSerialNo: string = "";
  public companyID: string | null = null;
  public campaignName: string = "";
  public hasAgent: boolean = false;
  public agentCompanyID: string | null = null;
  public agentContactsID: string | null = null;
  public hasClient: boolean = false;
  public clientCompanyID: string | null = null;
  public clientContactsID: string | null = null;
  public campaignContent: string = "";
  public campaignAmount: number = 0;
  public gstValue: number = 0;
  public campaignStatus: string = "";
  public createdTime: Date | null = null;
  public createdBy: string | null = null;
  public closedDate: Date = new Date();
  public closedBy: string | null = null;
  public startDate: Date = new Date();
  public endDate: Date = new Date();
  public campaignTypeID: string | null = null;
  public campaignProjectManagersForDisplay: Array<string> = new Array<string>();
  public campaignProjectManagersIDStrArr: string[] = [];
  public salesPersonsForDisplay: Array<string> = new Array<string>();
  public salesPersonsIDStrArr: string[] = [];
  public currency: string = "";
  // others for display
  public campaignTypeName: string = "";
  public companyName: string = "";
  public deliverableCompletedCount: number = 0;
  public deliverableTotalCount: number = 0;

  public agentCompanyName: string = "";
  public agentContactName: string = "";
  public clientCompanyName: string = "";
  public clientContactName: string = "";
  public createdByName: string = "";
  public closedByName: string = "";

  public outstandingPayment: string = "";

  public hasMissingMembers: boolean = false;
  public hasMissingInvoice: boolean = false;
  public deliverablesInCampaign: Array<DeliverableModel> = new Array<
    DeliverableModel
  >();
  public totalDeliverablesAmount: number = 0;

  // irn
  public campaignObjective: string = "";
  public publisherID: string = "";
  public irnCamId: string = "";
  public irnUrlRoute: string = "";
  public publisherName: string = "";
}

const campaignService = new CampaignService();
const commonMethods = new CommonMethods();

function Campaign(props: RouteComponentProps) {
  //Growls
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);
  // Loading, Error, Redirect
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [dialogStr, setDialogStr] = useState<string>("");
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [dialogAction, setDialogAction] = useState();
  const [dialogVisibility, setDialogVisibility] = useState(false);

  const [statusToBeUpdated, setStatusToBeUpdated] = useState<string>("");

  const [campaignList, setCampaignList] = useState<Array<CampaignModel>>(
    new Array<CampaignModel>()
  );
  const [campaignObj, setCampaignObj] = useState<CampaignModel>(
    new CampaignModel()
  );
  const [
    campaignStatusDropdownOptions,
    setCampaignStatusDropdownOptions,
  ] = useState<any[]>([]);
  const [
    campaignStatusViewDropdownOptions,
    setCampaignStatusViewDropdownOptions,
  ] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pagetitle, setPageTitle] = useState<string>("Campaign");
  const [campaignHelpdeskText, setCampaignHelpdeskText] = useState<string>("");
  const [masterListRedirectPath, setMasterListRedirectPath] = useState<string>(
    "mycampaigns"
  );

  const [expandedRows, setExpandedRows] = useState<CampaignModel[]>();
  const [expandAllRows, setExpandAllRows] = useState<boolean>(false);
  const [financePermissions] = useState(
    props.userProfile.taskList.includes(TaskList.financeaccess)
  );
  const [delIsLoading, setDelIsLoading] = useState<boolean>(true);
  const [campaignIDForDels, setCampaignIDForDels] = useState<string>("");
  const [yearDropdown, setYearDropdown] = useState<any[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>(new Date().getFullYear().toString());

  useEffect(() => {
    configureYearDropdown();
  }, []);


  useEffect(() => {
    if (commonMethods.isPropsLoaded(props)) {
      if (
        props.userProfile.taskList.includes(TaskList.campaignadminaccess) &&
        props.location.pathname === "/campaigns/masterlist"
      ) {
        setMasterListRedirectPath("masterlist");
        loadMasterListView();
      } else {
        loadMyOwnView();
      }
    }

    setCampaignStatusDropdownOptions(commonMethods.getCampaignStatusDropdown());
    setCampaignStatusViewDropdownOptions(
      commonMethods.getCampaignStatusViewDropdown()
    );
  }, [selectedYear]);

  useEffect(() => {
    setDelIsLoading(true);
    if (campaignIDForDels === null || campaignIDForDels === "") {
      return;
    }
    getDeliverablesInCampaign(campaignIDForDels);
  }, [campaignIDForDels]);

  const configureYearDropdown = () => {
    var currentYear = new Date().getFullYear();
    // We manually set the oldest year to 2020 instead of retrieving it from the oldest campaign date. This is a specific TSL module so we can do it like this.
    const difference = currentYear - 2020;
    const tempArray = [];
    for (let i = 0; i <= difference; i++){
      var tempValue = currentYear - i;
      const tempObject = { label: tempValue.toString(), value: tempValue.toString()};
      tempArray.push(tempObject);
    }
    setYearDropdown(tempArray);
  }

  const loadMasterListView = () => {
    setPageTitle("Campaigns Masterlist");
    setCampaignHelpdeskText(
      "View all the campaigns your company has been involved in here. Use the action buttons on the right to interact with the campaign."
    );
    campaignService
      .getAllCampaignsInGroupCompanyByYear(selectedYear)
      .then((res) => {
        setCampaignList(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
        setIsLoading(false);
      });
  };

  const loadMyOwnView = () => {
    setPageTitle("My Campaigns");
    setCampaignHelpdeskText(
      "View all the campaigns you have been involved in here. Use the action buttons on the right to interact with the campaign."
    );
    campaignService
      .getAllMyCampaigns()
      .then((res) => {
        setCampaignList(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
        setIsLoading(false);
      });
  };

  const setDialog = (header: string, content: any, action: any) => {
    setConfirmStr(header);
    setDialogStr(content);
    setDialogAction(action);
    setDialogVisibility(true);
  };

  const updateCampaignStatus = () => {
    let campaignObjUpdated = campaignObj;
    campaignObjUpdated.campaignStatus = statusToBeUpdated;
    campaignService
      .updateCampaignStatus(campaignObjUpdated)
      .then((res) => {
        SetSuccess(
          campaignObj.campaignName + " is now " + campaignObj.campaignStatus
        );
        setDialogVisibility(false);
        setIsLoading(false);
        loadMasterListView();
      })
      .catch((err) => {
        const error = commonMethods.getErrorMessage(err);
        setIsLoading(false);
        SetError(error);
      });
  };

  const confirmDelete = () => {
    campaignService
      .deletecampaignByID(campaignObj.campaignID || "")
      .then((res) => {
        SetSuccess(campaignObj.campaignName + " has been deleted.");
        setDialogVisibility(false);
        setIsLoading(false);
        loadMasterListView();
      })
      .catch((err) => {
        const error = commonMethods.getErrorMessage(err);
        setIsLoading(false);
        SetError(error);
      });
  };

  //    ___   _            _
  //   |   \ (_) ___ _ __ | | __ _  _  _
  //   | |) || |(_-<| '_ \| |/ _` || || |
  //   |___/ |_|/__/| .__/|_|\__,_| \_, |
  //                |_|             |__/

  const startDateTemplate = (rowData: CampaignModel) => {
    return commonMethods.displayDate(new Date(rowData.startDate));
  };

  const endDateTemplate = (rowData: CampaignModel) => {
    return commonMethods.displayDate(new Date(rowData.endDate));
  };

  const pmoTemplate = (rowData: CampaignModel) => {
    return rowData.campaignProjectManagersForDisplay.map(
      (item: string, index: number) => {
        if (index === rowData.campaignProjectManagersForDisplay.length - 1) {
          return item;
        } else {
          return item + ", ";
        }
      }
    );
  };

  const completionTemplate = (rowData: CampaignModel) => {
    let amount =
      rowData.deliverableCompletedCount / rowData.deliverableTotalCount || 0;
    let percentage = amount * 100;

    return (
      <ProgressBar
        value={Math.round(percentage * 100) / 100}
        showValue={true}
      ></ProgressBar>
    );
  };

  const actionTemplate = (rowData: CampaignModel) => {
    let docButton;
    if (financePermissions) {
      docButton = (
        <Button
          tooltip="Documents"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-folder"
          className="button-document"
          onClick={() => {
            setCampaignObj(rowData);
            setRedirectPath("/campaign/documents/" + rowData.campaignID);
            setRedirectOut(true);
          }}
        />
      );
    }
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Campaign Summary"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-eye"
          className="p-button-primary mx-1"
          onClick={() => {
            setRedirectOut(true);
            setRedirectPath("/campaign/summary/" + rowData.campaignID);
          }}
        />
        <Button
          tooltip="Edit Campaign"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            setRedirectPath(
              "/campaign/" +
                masterListRedirectPath +
                "/update/" +
                rowData.campaignID
            );
            setRedirectOut(true);
          }}
        />
        <Button
          tooltip="Delete Campaign"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setCampaignObj(rowData);
            setDialog(
              `Delete Campaign ${rowData.campaignName}`,
              `Are you sure you want to delete this campaign?`,
              "delete"
            );
          }}
        />
        {docButton}
      </div>
    );
  };

  const statusTemplate = (rowData: CampaignModel) => {
    return (
      <>
        <Dropdown
          tooltip="Change Campaign Status"
          tooltipOptions={{ position: "top" }}
          value={rowData.campaignStatus}
          options={campaignStatusDropdownOptions}
          style={{width: "130px"}}
          appendTo={document.body}
          onChange={(e) => {
            setCampaignObj(rowData);
            setStatusToBeUpdated(e.value);
            setDialog(
              "Update Status",
              `Update campaign status to ` + e.value + "?",
              "update"
            );
          }}
          placeholder="Please Select"
        />
      </>
    );
  };

  let tableheader = (
    <div style={{ textAlign: "left" }}>
      <span className="p-input-icon-left mr-1 mb-1">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e: any) => setSearchValue(e.currentTarget.value)}
        />
      </span>
      <span
        className="mr-1 mb-1"
        data-tour-id="campaign-status-dropdown"
        data-tour="Use the status bar to view campaigns of different statuses."
      >
        <Dropdown
          value={searchValue}
          options={campaignStatusViewDropdownOptions}
          onChange={(e) => setSearchValue(e.value)}
          placeholder="All"
        />
      </span>
      <span>
        <Dropdown
          value={selectedYear}
          placeholder={selectedYear}
          options={yearDropdown}
          onChange={(e) => {
            setSelectedYear(e.value);
          }}
        />
      </span>

    </div>
  );

  const exclamationMarkDisplay = (rowData: CampaignModel) => {
    let icon;
    if (rowData.hasMissingMembers) {
      icon = (
        <>
          <span data-tip="" data-for="exclamationmarkMissingMember">
            <i className="fas fa-userArray<DeliverableModel> = new Array<
    DeliverableModel
  >();-slash exclamationStyle"></i>
          </span>

          <ReactTooltip
            id="exclamationmarkMissingMember"
            aria-haspopup="true"
            className="tooltipFont"
          >
            <p className="text-center">
              Some deliverables have no member assigned.
            </p>
          </ReactTooltip>
        </>
      );
    }

    // else if (rowData.hasMissingInvoice) {
    //   icon = (
    //     <>
    //       <span data-tip="" data-for="exclamationmarkMissingInvoice">
    //         <i className="fas fa-file-invoice-dollar exclamationStyle"></i>
    //       </span>

    //       <ReactTooltip
    //         id="exclamationmarkMissingInvoice"
    //         aria-haspopup="true"
    //
    //         className="tooltipFont"
    //       >
    //         <p className="text-center">This campaign has no invoice.</p>
    //       </ReactTooltip>
    //     </>
    //   );
    // }
    return icon;
  };

  const getDeliverablesInCampaign = (campaignid: string) => {
    const delService = new DeliverableService();
    delService
      .getAllDeliverablesInCampaign(campaignid)
      .then((res) => {
        let campaignListTemp = campaignList;
        const indexOfCam = _.findIndex(campaignList, [
          "campaignID",
          campaignid,
        ]);
        if (indexOfCam !== -1) {
          campaignListTemp[indexOfCam].deliverablesInCampaign = res;
          setCampaignList(campaignListTemp);
        }
      })
      .catch((err) => {})
      .finally(() => setDelIsLoading(false));
  };

  const rowExpansionTemplate = (data: CampaignModel) => {
    setCampaignIDForDels(data.campaignID!);
    let displayDeliverables;

    if (
      data.deliverablesInCampaign === null ||
      data.deliverablesInCampaign.length === 0
    ) {
      displayDeliverables = (
        <div className="row p-2 pl-4">
          <div className="col-12 text-left">
            <p>No deliverables found.</p>
          </div>
        </div>
      );
    } else {
      displayDeliverables = (
        <Board {...props} others={data.deliverablesInCampaign} />
      );
    }

    //TODO: individual loading. current: when one is loading, all also display isloading lol.
    return (
      <div className="text-left">
        <div className="row p-2 pl-4">
          <h2>
            Deliverables{" "}
            {data.deliverablesInCampaign?.length > 0 && (
              <>({data.deliverablesInCampaign.length}) </>
            )}
          </h2>
        </div>
        {delIsLoading ? <ProgressSpinner /> : displayDeliverables}
      </div>
    );
  };

  const expandAll = () => {
    if (expandAllRows) {
      setExpandAllRows(false);
      setExpandedRows([]);
    } else {
      setExpandAllRows(true);
      setExpandedRows(campaignList);
    }
  };
  let page = (
  
    <div data-tour-id="campaigns-masterlist" data-tour={campaignHelpdeskText}>
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          value={campaignList}
          header={tableheader}
          globalFilter={searchValue}
          paginator={true}
          rows={15}
          autoLayout={true}
          alwaysShowPaginator={false}
          emptyMessage="No campaigns found."
          expandedRows={expandedRows}
          onRowToggle={(e) => {
            setExpandedRows(e.data);
          }}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column
            expander={true}
            style={{ width: "2em" }}
            header={
              <Button
                label="All"
                type="button"
                iconPos="left"
                icon="fas fa-angle-double-down"
                className="p-button-primary"
                onClick={expandAll}
              />
              // <span onClick={expandAll}>
              //   Expand
              //   <i className="fas fa-angle-double-down"></i>
              // </span>
            }
          />
          <Column field="campaignSerialNo" header="Serial" />
          <Column
            style={{ padding: "0px" }}
            body={exclamationMarkDisplay}
            header=""
          />
          <Column field="campaignName" header="Name" className="p-col-3 p-0" />
          <Column field="campaignTypeName" header="Type" />
          <Column field="companyName" header="Company" className="d-none" />
          <Column
            field="campaignProjectManagersForDisplay"
            header="PMOS"
            className="d-none"
          />

          <Column body={pmoTemplate} header="Project Managers" />
          <Column body={completionTemplate} header="Completion" className="p-1" />
          <Column body={startDateTemplate} header="Start Date" className="pr-1" />
          <Column body={endDateTemplate} header="End Date" className="pl-1" />
          <Column field="campaignStatus" className="d-none" />
          <Column body={actionTemplate} header="Actions" className="p-1" />
          <Column body={statusTemplate} header="Status" />
        </DataTable>
      </div>
    </div>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <div className="row">
      {redirectOut ? (
        <Redirect
          push
          to={{
            pathname: redirectPath,
          }}
        />
      ) : null}

      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        //dismissableMask={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                setIsLoading(true);
                setDialogVisibility(false);
                dialogAction === "delete" && confirmDelete();
                dialogAction === "update" && updateCampaignStatus();
                setIsLoading(true);
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>

      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">
            {pagetitle}
            <span
              className="pl-3"
              data-tour-id="add-campaign"
              data-tour="Add a new campaign with this button."
            >
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={() => {
                  setRedirectPath(
                    "/campaign/" + masterListRedirectPath + "/create"
                  );
                  setRedirectOut(true);
                }}
              />
            </span>
          </h1>
          {/* {!isMasterListView && mypoints} */}
          {display}
        </div>
      </div>
    </div>
  );
}

export default Campaign;
