import { Avatar } from 'primereact/avatar'
import React, { useRef, useState } from 'react'
import { nameFirstLetter } from '../../CompanyFeedFunctions'
import { CommentModel } from '../../Model/CommentModel'
// const placeholder = require('../Profile/assets/profileplaceholder.jpg')
const profileplaceholder = require('../../Assets/profileplaceholder.jpg')


export default function CommentAuthorPicture({
    item,
}: {
    item: CommentModel
}) {

    return (<img
        className="p-avatar-circle-comment"
        src={item.displayPicture ?? profileplaceholder}
        alt=""
        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => e.currentTarget.src = profileplaceholder}
    />)

    // Use FirstLetterOfName for Users without displaypics
    // let Picture
    // if (item.displayPicture !== '/images/default-dp.png') {
    //     Picture = (
    //         <img
    //             className="p-avatar-circle-comment cursor-pointer hover-greyedout"
    //             src={item.displayPicture ?? profileplaceholder}
    //             alt=""
    //             onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => e.currentTarget.src = profileplaceholder}
    //         />
    //     )
    // } else {
    //     Picture = (
    //         <Avatar
    //             className="p-avatar-circle-comment cursor-pointer hover-greyedout"
    //             label={nameFirstLetter(item.firstName)}
    //             shape="circle"
    //         />
    //     )
    // }
    // return (
    //     <div >
    //         {Picture}
    //     </div>
    // )
}