import { useParams, Redirect } from "react-router";

import { CircularsDashboardModel } from "./CircularsDashboardModel";
import CircularsDashboardService from "../../service/CircularsDashboardService";

import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ToastStateContext } from "../resources/ToastContext";
import CustomError from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";
import { CommonMethods, ActionTypes, ParamIDTypes, RouteComponentProps, DropdownModel } from "../resources/CommonMethods";
import { CommonDocumentMethods } from "../resources/CommonDocumentMethods";
import { Dropdown } from "primereact/dropdown";
import { UserRankService } from "../../service/UserRankService";

export default function CircularsDashboardCreate(props:  RouteComponentProps<any>) {
  const circularDashboardService = new CircularsDashboardService();
  const userRankService = new UserRankService();
  const { id } = useParams<ParamIDTypes>();
  const action = id === undefined ? ActionTypes.create : ActionTypes.update;
  let confirmationMessage = id === undefined ? "created" : "updated";

  const commonMethods = new CommonMethods();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [toHome, setToHome] = useState(false);

  const [circularData, setCircularData] = useState<CircularsDashboardModel>(
    new CircularsDashboardModel()
  );

  const [listOfUserRanks, setListOfUserRanks] = useState<DropdownModel[]>([{label:"All", value: ""}]);

  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);

  useEffect(() => {
    if (id !== undefined) {
      circularDashboardService
        .getCircularByID(id)
        .then((res) => {
          setCircularData(res);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsError(true);
          setErrorMessage(commonMethods.getErrorMessage(err));
        });
    } else {
      // 2020-16-12 PCLQ: HR Request to filter visibility to Full Timers 
      userRankService.getUserRankDropdownList().then((res)=>{
        setListOfUserRanks(listOfUserRanks.concat(res));
        setIsLoading(false);
      }).catch((err) => {
        setIsError(true);
        setErrorMessage(commonMethods.getErrorMessage(err));
      });
    }
  }, []);

  const uploadDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e !== null &&
      e.currentTarget.files !== null &&
      e.currentTarget.files[0] !== undefined
    ) {
      
      const currentTargetImage = e.currentTarget.files[0];
      CommonDocumentMethods.uploadImageOrDocument(currentTargetImage).then((image) => {
        if (image.errorMsg !== "") {
          SetError(image.errorMsg);
        } else {
          setCircularData({
            ...circularData,
            documentPath: currentTargetImage.name,
            uploadFile: currentTargetImage,
          });
        }
      });
    } else {
      setCircularData({
        ...circularData,
        documentPath: "No file chosen:",
        uploadFile: null,
      });
    }
  };

  const fileName = () => {
    if (
      circularData.documentPath !== undefined &&
      circularData.documentPath !== null
    ) {
      return circularData.documentPath;
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const tmpCircular = circularData;
    if (action === ActionTypes.create) {
      circularDashboardService
        .createCircular(tmpCircular)
        .then(() => {
          SetSuccess(
            tmpCircular.summary + " has been " + confirmationMessage + "."
          );
          setToHome(true);
        })
        .catch((err) => {
          SetError(commonMethods.getErrorMessage(err));
        });
    } else {
      circularDashboardService
        .updateCircular(tmpCircular)
        .then(() => {
          SetSuccess(
            tmpCircular.summary + " has been " + confirmationMessage + "."
          );
          setToHome(true);
        })
        .catch((err) => {
          SetError(commonMethods.getErrorMessage(err));
        });
    }
  };

  let page = (
    <form onSubmit={handleSubmit}>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <span className="boldLabel">
            Title <span style={{ color: "red" }}>*</span>
          </span>
          <InputText
            maxLength={50}
            value={circularData.summary}
            onChange={(event) =>
              setCircularData({
                ...circularData,
                summary: event.currentTarget.value,
              })
            }
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Description <span style={{ color: "red" }}>*</span>
          </span>
          <InputText
            maxLength={100}
            value={circularData.detail}
            onChange={(event) =>
              setCircularData({
                ...circularData,
                detail: event.currentTarget.value,
              })
            }
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Visible to
          </span>
          <Dropdown
          options={listOfUserRanks}
          value={circularData.visibleTo}
          onChange={(event) =>
            setCircularData({
              ...circularData,
              visibleTo: event.value,
            })
          }
        />
        </div>


        <div className="p-col-12">
          <span className="boldLabel">
            Document <span style={{ color: "red" }}>*</span> (only PDF accepted)
          </span>
          <div>
            <input
              style={{ color: "transparent", width: "110px" }}
              type="file"
              name="uploadFile"
              accept=".pdf"
              onChange={(e) => {
                uploadDocument(e);
              }}
            />
            {fileName()}
          </div>
        </div>
      </div>

      <div className="p-grid p-justify-center pt-2">
        <Button type="submit" label={action} />
      </div>
    </form>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <>
      {toHome ? <Redirect push to="/admin/quick_links" /> : null}

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{action} Circular </h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {display}
          </div>
        </div>
      </div>
    </>
  );
}
