import { Redirect, RouteComponentProps } from "react-router";
import { ExternalCostService } from "../../service/ExternalCostService";
import { ExternalCostModel } from "./ExternalCost";
import { CommonMethods } from "../resources/CommonMethods";
import { useState, useEffect } from "react";
import React from "react";
import { ToastStateContext } from "../resources/ToastContext";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import CustomError from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";
import { Calendar } from "primereact/calendar";
import NumberFormat from "react-number-format";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";

function ExternalCostCreate(props: RouteComponentProps<any>) {
  let externalCostService = new ExternalCostService();

  let commonMethods = new CommonMethods();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [toHome, setToHome] = useState(false);
  const [action, setAction] = useState("Create");
  const [externalCostObj, setExternalCostObj] = useState<ExternalCostModel>(
    new ExternalCostModel()
  );

  const [currencyDropdown, setCurrenctyDropdown] = useState<any[]>([]);

  const [clientDropdown, setClientDropdown] = useState([]);
  const [costTypeDropdown, setCostTypeDropdown] = useState([]);
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);

  useEffect(() => {
    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";
    if (
      //  --- UPDATE---
      props.match.params !== {} &&
      props.match.params.id !== undefined
    ) {
      // update
      externalCostService
        .getExternalCostsById(props.match.params.id)
        .then(res => {
          setAction("Update");
          setExternalCostObj(res);
          setIsLoading(false);
        })
        .catch(err => {
          let errorMsg = commonMethods.getErrorMessage(err);
          setIsError(true);
          setIsLoading(false);
          setErrorMessage(errorMsg);
        });
    } else {
      // create
      setIsLoading(false);
      setExternalCostObj({
        ...externalCostObj,
        companyID: currentAccessingCompanyId
      });
    }

    getDropdown();
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // convert date to ISO string

    setExternalCostObj({
      ...externalCostObj,
      paymentDate: commonMethods.setStartOfDayOfDate(
        externalCostObj.paymentDate
      )
    });

    if (action === "Update") {
      externalCostService
        .updateExternalCost(externalCostObj)
        .then(res => {
          var found = costTypeDropdown.find(function(item: {
            label: string;
            value: string;
          }) {
            return item.value === externalCostObj.costTypeID;
          }) || { label: "", value: "" };

          SetSuccess(found.label + " has been updated.");
          setToHome(true);
        })
        .catch(err => {
          const error = commonMethods.getErrorMessage(err);
          SetError(error);
        });
    } else if (action === "Create") {
      externalCostService
        .createExternalCost(externalCostObj)
        .then(res => {
          var found = costTypeDropdown.find(function(item: {
            label: string;
            value: string;
          }) {
            return item.value === externalCostObj.costTypeID;
          }) || { label: "", value: "" };

          SetSuccess(found.label + " has been created.");
          setToHome(true);
        })
        .catch(err => {
          const error = commonMethods.getErrorMessage(err);
          SetError(error);
        });
    }
  };

  const getDropdown = () => {
    commonMethods
      .getExternalCostClientDropdown()
      .then(res => {
        setClientDropdown(res);
      })
      .catch(err => {
        setIsError(true);
        setIsLoading(false);
      });

    commonMethods
      .getCurrencyDropdown()
      .then(res => {
        setCurrenctyDropdown(commonMethods.addCommonCurrencies(res));
      })
      .catch(error => {
        setIsError(true);
        setIsLoading(false);
      });

    commonMethods
      .getCostTypeDropdown()
      .then(res => {
        setCostTypeDropdown(res);
      })
      .catch(err => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  const uploadDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e !== null &&
      e.currentTarget.files !== null &&
      e.currentTarget.files[0] !== undefined
    ) {
      setExternalCostObj({
        ...externalCostObj,
        documentPath: e.currentTarget.files[0].name,
        documentFile: e.currentTarget.files[0]
      });
    } else {
      setExternalCostObj({
        ...externalCostObj,
        documentPath: "No file chosen:",
        documentFile: null
      });
    }
  };

  const fileName = () => {
    if (
      externalCostObj.documentPath !== undefined &&
      externalCostObj.documentPath !== null
    ) {
      let arr = externalCostObj.documentPath.split("/");
      if (action === "Update") {
        return arr[arr.length - 1];
      } else {
        return externalCostObj.documentPath;
      }
    }
  };

  let page = (
    <form onSubmit={handleSubmit}>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <span className="boldLabel">Cost Type <span style={{color: "red"}}>*</span></span>
          <Dropdown
            value={externalCostObj.costTypeID}
            options={costTypeDropdown}
            onChange={e =>
              setExternalCostObj({
                ...externalCostObj,
                costTypeID: e.value
              })
            }
            required
            placeholder="Please Select"
          />
        </div>
        <div className="p-col-12">
          <span className="boldLabel">Supplier Company <span style={{color: "red"}}>*</span></span>
          <Dropdown
            value={externalCostObj.clientCompanyID}
            options={clientDropdown}
            onChange={e =>
              setExternalCostObj({
                ...externalCostObj,
                clientCompanyID: e.value
              })
            }
            placeholder="Please Select"
          />
        </div>
        <div className="p-col-12">
          <span className="boldLabel">Amount <span style={{color: "red"}}>*</span></span>
          <NumberFormat
            className="p-inputtext"
            value={externalCostObj.externalCostAmount}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            onValueChange={values => {
              setExternalCostObj({
                ...externalCostObj,
                externalCostAmount: values.floatValue!
              });
            }}
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Currency <span style={{color: "red"}}>*</span></span>

          <Dropdown
            value={externalCostObj.currency}
            options={currencyDropdown}
            onChange={e => {
              setExternalCostObj({
                ...externalCostObj,
                currency: e.value
              });
            }}
            placeholder="Please Select"
            required
          />
        </div>
        {/* <div className="p-col-12">
          <span className="boldLabel">Recurring <span style={{color: "red"}}>*</span></span>
          <div>
            <span>
              <RadioButton
                value={true}
                checked={externalCostObj.isRecurring === true}
                onChange={e => {
                  setExternalCostObj({
                    ...externalCostObj,
                    isRecurring: e.value
                  });
                }}
              />
              <label htmlFor="rb1" className="p-radiobutton-label">
                Yes
              </label>
            </span>
            <span className="mx-3">
              <RadioButton
                checked={externalCostObj.isRecurring === false}
                value={false}
                onChange={e => {
                  setExternalCostObj({
                    ...externalCostObj,
                    isRecurring: e.value
                  });
                }}
              />
              <label htmlFor="rb1" className="p-radiobutton-label">
                No
              </label>
            </span>
          </div>
        </div> */}
        <div className="p-col-12">
          <span className="boldLabel">Upload Document</span>
          <div className="pt-2">
            <input
              style={{ color: "transparent", width: "110px" }}
              type="file"
              name="uploadFile"
              onChange={e => {
                uploadDocument(e);
              }}
            />
            {fileName()}
          </div>
        </div>
        <div className="p-col-12">
          <span className="boldLabel">Payment Date <span style={{color: "red"}}>*</span></span>
          <Calendar
            dateFormat="dd/mm/yy"
            value={new Date(externalCostObj.paymentDate)}
            onChange={e => {
              setExternalCostObj({
                ...externalCostObj,
                paymentDate: new Date(e.value.toString())
              });
            }}
            showButtonBar={true}
            showIcon={true}
            monthNavigator={true}
            yearNavigator={true}
            yearRange="1900:2030"
            // maxDate={this.state.maxDate}
            showTime={false}
            readOnlyInput={true}
            
          />
        </div>
        <div className="p-col-12">
          <span className="boldLabel">Invoice Date <span style={{color: "red"}}>*</span></span>
          <Calendar
            dateFormat="dd/mm/yy"
            value={new Date(externalCostObj.invoiceDate)}
            onChange={e => {
              setExternalCostObj({
                ...externalCostObj,
                invoiceDate: new Date(e.value.toString())
              });
            }}
            showButtonBar={true}
            showIcon={true}
            monthNavigator={true}
            yearNavigator={true}
            yearRange="1900:2030"
            // maxDate={this.state.maxDate}
            showTime={false}
            readOnlyInput={true}
            
          />
        </div>
        <div className="p-col-12">
          <span className="boldLabel">Remarks <span style={{color: "red"}}>*</span></span>
          <InputText
            value={externalCostObj.externalCostRemarks}
            onChange={e =>
              setExternalCostObj({
                ...externalCostObj,
                externalCostRemarks: e.currentTarget.value
              })
            }
            required
          />
        </div>
      </div>

      <div className="p-grid p-justify-center pt-2">
        <Button type="submit" label={action} />
      </div>
    </form>
  );
  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <>
      {toHome ? <Redirect push to="/finance/operational_cost" /> : null}

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{action} New Cost</h1>
            {display}
          </div>
        </div>
      </div>
    </>
  );
}

export default ExternalCostCreate;
