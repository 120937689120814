import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import * as React from "react";
import { Redirect } from "react-router";
import { CompanyService } from "../../service/CompanyService";
import { RoleService } from "../../service/RoleService";
import { UserService } from "../../service/UserService";
import { CommonMethods, RouteComponentProps, ActionTypes } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";

interface AppProps {}

interface AppState {
  groupCompanyID: string;
  roleObj: RoleModel;
  roleList: Array<RoleModel>;
  isLoading: boolean;
  redirectOut: boolean;
  redirectPath: string;
  redirectParam: string;
  isDialogVisible: boolean;
  isRedirectDialogVisible: boolean;

  isError: boolean;
  errorMsg: string;
  dialogStr: string;
  dialogHeaderStr: string;
  visible: boolean;
  dialogIcon: string;
  dialogShowError: boolean;

  searchValue: string;
}

export class RoleModel {
  public roleID: string = CommonMethods.EmptyGuid;
  public roleName: string = "";
  public isAdmin: boolean = false;
  public roleRemarks: string = "";
  public roleStatus: string = "";
  public groupCompanyID: string = CommonMethods.EmptyGuid;
  public companyID: string = "";
  public taskList: string = "";
  public roleTaskRemarks: string = "";
}

export class Role extends React.Component<AppProps, AppState> {
  static contextType = ToastStateContext;
  roleService: RoleService;
  userService: UserService;
  commonMethods: CommonMethods;
  constructor(props: AppProps) {
    super(props);
    this.state = {
      groupCompanyID: "",
      isLoading: true,
      roleObj: new RoleModel(),
      roleList: new Array<RoleModel>(),
      redirectOut: false,
      redirectPath: "",
      redirectParam: "",
      isDialogVisible: false,
      isRedirectDialogVisible: false,
      isError: false,
      errorMsg: "",
      dialogStr: "",
      dialogHeaderStr: "",
      visible: false,
      dialogIcon: "",
      dialogShowError: false,
      searchValue: "",
    };

    this.roleService = new RoleService();
    this.userService = new UserService();
    this.commonMethods = new CommonMethods();
    this.actionButton = this.actionButton.bind(this);
    this.employeeListButton = this.employeeListButton.bind(this);
    this.permissionButton = this.permissionButton.bind(this);
    this.redirectToUsers = this.redirectToUsers.bind(this);
  }

  componentDidMount() {
    this.getRolesFromDB();
  }

  async getRolesFromDB() {
    await this.roleService
      .getAllRoles()
      .then((res) => {
        this.setState({ roleList: res, isLoading: false });
      })
      .catch((err) => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({
          isError: true, // display error page
          isLoading: false,
          errorMsg: errorMsg,
        });
      });
  }

  actionButton(rowData: RoleModel) {
    let disablebutton = false;
    if (rowData.isAdmin) {
      disablebutton = true;
    }
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          disabled={disablebutton}
          tooltip="Edit Role Details"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning mx-1"
          onClick={() => {
            this.updateItem(rowData);
          }}
        />

        <Button
          disabled={disablebutton}
          tooltip="Delete Role"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            this.deleteItem(rowData);
          }}
        />
      </div>
    );
  }

  employeeListButton(rowData: RoleModel) {
    return (
      <>
        <Button
          tooltip="View Employees"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-users"
          className="p-button-info mx-1"
          onClick={() => {
            this.viewEmployees(rowData);
          }}
        />

        <Button
          // disabled={disablebutton}
          tooltip="Edit Role Permissions"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            this.viewPermissions(rowData);
          }}
        />
      </>
    );
  }

  viewEmployees(rowData: RoleModel) {
    this.setState({
      roleObj: rowData,
      redirectOut: true,
      redirectParam: "",
      redirectPath: "/admin/employees/all/Role/" + rowData.roleID,
    });
  }

  permissionButton(rowData: RoleModel) {
    return (
      <Button
        // disabled={disablebutton}
        tooltip="View Role Permissions"
        tooltipOptions={{ position: "top" }}
        type="button"
        icon="pi pi-pencil"
        className="p-button-warning"
        onClick={() => {
          this.viewPermissions(rowData);
        }}
      />
    );
  }

  viewPermissions(rowData: RoleModel) {
    this.setState({
      roleObj: rowData ,
      redirectOut: true,
      redirectParam: "",
      redirectPath: "/admin/role/task/" + rowData.roleID,
    });
  }

  updateItem(rowData: RoleModel) {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/role/update/" + rowData.roleID,
    });
  }

  deleteItem(rowData: RoleModel) {
    this.setState({
      isDialogVisible: true,
      roleObj: rowData,
    });
  }

  addItem() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/role/create",
    });
  }

  confirmDelete() {
    this.setState(
      {
        isLoading: true,
        isDialogVisible: false,
      },
      () => {
        this.roleService
          .deleteRole(this.state.roleObj.roleID)
          .then((res) => {
            let msg = this.state.roleObj.roleName + " has been deleted.";
            this.context.SetSuccess(msg);
            this.getRolesFromDB();
          })
          .catch((error) => {
            let errorMsg = this.commonMethods.getErrorMessage(error);
            this.context.SetError(errorMsg);
            this.setState({
              isLoading: false,
            });
          });
      }
    );
  }

  redirectToUsers() {
    this.setState({
      redirectOut: true,
      redirectParam: "",
      redirectPath: "/admin/employees/all/Role/" + this.state.roleObj.roleID,
    });
  }

  tableheader() {
    return (
      <div style={{ textAlign: "left" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            onInput={(e: any) => {
              this.setState({ searchValue: e.currentTarget.value });
            }}
          />
        </span>
      </div>
    );
  }

  render() {
    let page = (
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          value={this.state.roleList}
          autoLayout={true}
          emptyMessage="No results found."
          paginator={true}
          rows={40}
          alwaysShowPaginator={false}
          header={this.tableheader()}
          globalFilter={this.state.searchValue}
          groupField="companyName"
          rowGroupMode="rowspan"
          sortField="companyName"
          sortOrder={1}
        >
          <Column className="p-col-2" field="companyName" header="Company" />
          <Column className="p-col-2" field="roleName" header="Name" />

          <Column className="p-col" field="roleRemarks" header="Remarks" />
          <Column
            className="p-col-2"
            body={this.employeeListButton}
            header="Employees & Permissions"
          />
          {/* <Column
            className="p-col-2"
            body={this.permissionButton}
            header="Permissions"
          /> */}
          <Column className="p-col-2" body={this.actionButton} header="Action" />
        </DataTable>
      </div>
    );
    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />; // or some growl/message that stays
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = page;
    }

    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectPath,
            search: this.state.redirectParam,
          }}
          push
        />
      );
    }

    return (
      <div className="row">
        <div className="col-12 col-xl-10">
          <div className="card">
            <h1 className="pb-2">
              Roles
              <span className="pl-3">
                <Button
                  type="button"
                  icon="pi pi-plus"
                  iconPos="right"
                  label="Add"
                  className="p-button-danger"
                  onClick={() => {
                    this.addItem();
                  }}
                />
              </span>
            </h1>
            <p>
              Roles determine the type of access that employees can have. An
              employee can be assigned multiple roles from the Employee menu.
            </p>
            {display}

            <Dialog
              className="popupDialog"
              header={"Delete " + this.state.roleObj.roleName}
              visible={this.state.isDialogVisible}
              modal={true}
              //dismissableMask={true}
              onHide={() => this.setState({ isDialogVisible: false })}
              footer={
                <div>
                  <Button
                    label="Yes"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={() => this.confirmDelete()}
                  />
                  <Button
                    label="No"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => this.setState({ isDialogVisible: false })}
                  />
                </div>
              }
            >
              Are you sure you want to delete this role?
            </Dialog>

            <Dialog
              className="popupDialog"
              header={"Unable to delete " + this.state.roleObj.roleName}
              visible={this.state.isRedirectDialogVisible}
              modal={true}
              onHide={() => this.setState({ isRedirectDialogVisible: false })}
              footer={
                <div>
                  <Button
                    label="Proceed to Users"
                    icon="pi pi-check"
                    onClick={() => this.redirectToUsers()}
                  />
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    onClick={() =>
                      this.setState({ isRedirectDialogVisible: false })
                    }
                  />
                </div>
              }
            >
              {" "}
              {this.state.dialogStr} You can view them on Employee List.
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

interface AppStateCreate {
  groupCompanyID: string;
  roleObj: RoleModel;
  isLoading: boolean;
  redirectOut: boolean;
  redirectPath: string;
  isDialogVisible: boolean;
  isError: boolean;
  errorMsg: string;
  action: string;
  formHeader: string;
  companyDropdownList: [];
  dialogStr: string;
  dialogHeaderStr: string;
  visible: boolean;
  dialogIcon: string;
  dialogShowError: boolean;
}

export class RoleCreate extends React.Component<
  RouteComponentProps<any>,
  AppStateCreate
> {
  static contextType = ToastStateContext;

  roleService: RoleService;
  companyService: CompanyService;
  commonMethods: CommonMethods;
  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      groupCompanyID: "",
      isLoading: false,
      isError: false,
      errorMsg: "",
      roleObj: new RoleModel(),
      redirectOut: false,
      redirectPath: "",
      isDialogVisible: false,
      action: "",
      formHeader: "",
      companyDropdownList: [],
      dialogStr: "",
      dialogHeaderStr: "",
      visible: false,
      dialogIcon: "",
      dialogShowError: false,
    };
    this.commonMethods = new CommonMethods();
    this.roleService = new RoleService();
    this.companyService = new CompanyService();
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";
    this.getDropdownList();
    if (
      this.props.match.params !== {} &&
      this.props.match.params.id !== undefined
    ) {
      var IDfromQuery = this.props.match.params.id;

      // id is roleID
      this.setState({
        action: ActionTypes.update,
        formHeader: "Update Role",
      });
      this.getRoleFromDB(IDfromQuery);
    } else {
      this.setState({
        formHeader: "Create a new Role",
        action: ActionTypes.create,
        isLoading: false,
        roleObj: {
          ...this.state.roleObj,
          companyID: currentAccessingCompanyId,
        },
      });
    }
  }
  getDropdownList() {
    this.commonMethods
      .getCompanyDropdownListByGcid()
      .then((res) => {
        this.setState({ companyDropdownList: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
      });
  }

  getRoleFromDB(IDfromQuery: string) {
    this.roleService
      .getRoleByID(IDfromQuery)
      .then((res) => {
        this.setState({ roleObj: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
      });
  }

  updateProperty(property: string, value: any) {
    let tempObj = this.state.roleObj;
    (tempObj as any)[property] = value;
    this.setState({
      roleObj: tempObj,
    });
  }

  goBack() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/role",
    });
  }

  submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // to prevent page from refreshing!
    if (this.state.action === ActionTypes.create) {
      this.roleService
        .createRole(this.state.roleObj)
        .then((res) => {
          let msg = res.roleName + " has been created.";
          this.context.SetSuccess(msg);
          this.goBack();
        })
        .catch((error) => {
          // error dialog
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.context.SetError(errorMsg);
      
        });
    } else if (this.state.action === ActionTypes.update) {
      this.roleService
        .updateRole(this.state.roleObj)
        .then((res) => {
          let msg = res.roleName + " has been updated.";
          this.context.SetSuccess(msg);
          this.goBack();
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.context.SetError(errorMsg);
       
        });
    }
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
          }}
        />
      );
    }

    let form = (
      <form onSubmit={this.submitForm}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">
              Company <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.roleObj.companyID}
              options={this.state.companyDropdownList}
              onChange={(e) =>
                this.setState({
                  roleObj: {
                    ...this.state.roleObj,
                    companyID: e.value,
                  },
                })
              }
              placeholder="Please Select"
              required
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Role Name <span style={{ color: "red" }}>*</span>
            </span>

            <InputText
              id="roleName"
              value={this.state.roleObj.roleName}
              onChange={(e) => {
                this.updateProperty("roleName", e.currentTarget.value);
              }}
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Remarks <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              id="roleRemarks"
              onChange={(e) => {
                this.updateProperty("roleRemarks", e.currentTarget.value);
              }}
              value={this.state.roleObj.roleRemarks}
              required
            />
          </div>
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button
            type="submit"
            label={this.state.action === ActionTypes.create ? "Submit" : ActionTypes.update}
          />
        </div>
      </form>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = form;
    }

    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{this.state.formHeader}</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {display}
          </div>
        </div>
      </div>
    );
  }
}
