import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import * as React from "react";
import { Redirect } from "react-router";
import { SalesService } from "../../service/SalesService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { SalesClientsCompanyModel, SalesClientsModel } from "../resources/ExportClass";
import { ToastStateContext } from "../resources/ToastContext";
import { UserModel } from "../user/User";

interface AppState {
  salesProfile: UserModel;
  salesClientsList: Array<SalesClientsCompanyModel>;

  isLoading: boolean;

  isError: boolean;
  errorMsg: string;

  redirectOut: boolean;
  redirectPath: string;

  searchValue: string;
  industryList: [{}];

  meetingDateStart: Date;
  meetingDateEnd: Date,
  dt: any
  leadStatusFilterValue: any,
  industryFilterValue: string
}

export class MySalesClients extends React.Component<
  RouteComponentProps<any>,
  AppState
  > {
  static contextType = ToastStateContext;
  commonMethods: CommonMethods;
  salesService: SalesService;
  leadStatusList: any[] = [];

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      dt: React.createRef(),
      leadStatusFilterValue: null,
      industryFilterValue: "",
      salesProfile: new UserModel(),
      salesClientsList: [],

      isLoading: true,

      isError: false,
      errorMsg: "",

      redirectOut: false,
      redirectPath: "",
      searchValue: "",
      industryList: [{ label: "", value: "" }],
      meetingDateStart: new Date(""),
      meetingDateEnd: new Date(""),

    };
    this.commonMethods = new CommonMethods();
    this.salesService = new SalesService();
    this.leadStatusList = this.commonMethods.getLeadStatusDropdown();
    this.viewClientDetails = this.viewClientDetails.bind(this);
    this.updateClientDetails = this.updateClientDetails.bind(this);
    this.meetingDate = this.meetingDate.bind(this);
    this.goToClientDetails = this.goToClientDetails.bind(this);
  }

  componentDidMount() {
    if (this.commonMethods.isPropsLoaded(this.props)) {
      this.getSalesClientsByUser(this.props.userProfile.userID);
    }
  }

  componentDidUpdate() { }

  getSalesClientsByUser(userID: string) {
    this.salesService
      .getAllSalesClientsByUserID(userID)
      .then(res => {
        this.setState({
          salesClientsList: res,
          isLoading: false
        });
      })
      .catch(err => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({ isError: true, isLoading: false, errorMsg: errorMsg });
      });
    this.leadStatusList = this.commonMethods.getLeadStatusDropdown();
    this.commonMethods.getClientContactIndustryDropdown()
      .then(res => {
        this.setState({
          industryList: res
        })
      })
      .catch(err => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({ isError: true, isLoading: false, errorMsg: errorMsg });
      });
  }

  viewClientDetails(rowData: SalesClientsModel) {
    //UNUSED UNTIL DECIDED
    return (
      <Button
        icon="pi pi-external-link"
        tooltip="View Client Details"
        onClick={() => {
          this.setState({
            redirectOut: true,
            redirectPath: "/client/" + rowData.id
          });
        }}
      />
    );
  }

  updateClientDetails(rowData: SalesClientsModel) {
    return (
      <Button
        icon="pi pi-pencil"
        tooltip="Update Client Details"
        className="p-button-warning"
        onClick={() => {
          this.setState({
            redirectOut: true,
            redirectPath: "/client/" + rowData.id + "/edit"
          });
        }}
      />
    );
  }

  goToClientDetails(rowData: any) {
    this.setState({
      redirectOut: true,
      redirectPath: "/client/" + rowData.id + "/edit/" + rowData.clientContactsID
    });
    // this.setState({
    //   redirectPath: "/sales/clientcontacts/" + rowData.clientCompanyID,
    //   redirectOut: true
    // })
  }

  remarks(rowData: SalesClientsModel) {
    if (rowData.remarks == null) {
      return (
        "-"
      )
    }
    else {
      return (
        <>
          {rowData.remarks}
        </>
      )
    }
  }

  meetingDate(rowData: SalesClientsModel) {
    return (
      <>
        {" "}
        {this.commonMethods.displayDate(new Date(rowData.startDate))}
      </>
    );
  }

  filterByDate = () => {
    if (this.state.meetingDateStart !== null && this.state.meetingDateEnd !== null && moment(this.state.meetingDateStart).isBefore(moment(this.state.meetingDateEnd))) {
      this.salesService.filterSalesClientByDate(this.props.userProfile.userID, this.state.meetingDateStart, this.state.meetingDateEnd)
        .then(res => {
          this.setState({
            salesClientsList: res,
            isLoading: false
          });
        })
        .catch(err => {
          let errorMsg = this.commonMethods.getErrorMessage(err);
          this.setState({ isError: true, isLoading: false, errorMsg: errorMsg });
        });
    }
    else {
      this.context.SetError("Start date must be before end date!");
    }
  };

  leadStatusFilter(){
    return (
      <Dropdown
        showClear
        className="mb-1 mr-1"
        placeholder="Filter by Lead Status"
        value={this.state.leadStatusFilterValue}
        options={this.leadStatusList}
        onChange={e => {
          this.setState({ leadStatusFilterValue: e.value });
          this.onLeadStatusChange(e);
        }}
      />
    );
  }

  onLeadStatusChange(e: any) {
    let dt = this.state.dt;
    dt.current.filter(e.value, 'leadsStatus', 'equals');
  }

  industryFilter(){
    return(
      <Dropdown
        showClear
        className="mb-1"
        placeholder="Filter by Industry"
        options={this.state.industryList}
        value={this.state.industryFilterValue}
        onChange={e => {
          this.setState({ industryFilterValue: e.value });
          this.onIndustryChange(e);
        }}
      />
    );
  }

  onIndustryChange(e:any){
    let dt = this.state.dt;
    dt.current.filter(e.value, 'industry', 'equals');
  }

  tableheader() {
    return (
      <div className="row mb-2">
        <div className="col text-md-left">
          <span className="p-input-icon-left mr-1 mb-1">
            <i className="pi pi-search" />
            <InputText
              placeholder="Global Search"
              onInput={(e: any) => {
                this.setState({
                  searchValue: e.currentTarget.value
                });
              }}
              
            />
          </span>
          {this.leadStatusFilter()}
          {/* {this.industryFilter()} */}
        </div>
        <div className="col text-md-right">
          <Calendar
            className="mb-1 mx-1"
            placeholder="From Date"
            value={this.state.meetingDateStart}
            dateFormat="dd/mm/yy"
            onChange={e => {
              this.setState({
                meetingDateStart: new Date(e.value.toString())
              });
            }}
          />
          <Calendar
            className="mb-1 mx-1"
            placeholder="To Date"
            value={this.state.meetingDateEnd}
            dateFormat="dd/mm/yy"
            onChange={e => {
              this.setState({
                meetingDateEnd: new Date(e.value.toString())
              });
            }}
          />
          <Button
            label="Filter"
            onClick={() => {
              this.filterByDate();
            }}
            className="mx-1"
          />
          <Button
            label="Clear"
            onClick={() => {
              this.getSalesClientsByUser(this.props.userProfile.userID);
              this.setState({
                meetingDateStart: new Date(""),
                meetingDateEnd: new Date("")
              })
            }}
            className="mx-1 mt-1"
          />
        </div>
      </div>
    )

  }
  

  actionTemplate = (rowData: any) => {

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          className="p-button-warning"
          icon="pi pi-pencil"
          tooltip="Edit"
          onClick={() => {
            this.goToClientDetails(rowData)
          }}
          />
      </div>
    )
  }

  render() {
    function mailTo(rowData: any, column: any) {
      // return `<a href="mailto:${rowData[column.field]}>${rowData[column.field]}</a>`;
      return rowData[column.field];
    }

    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
          }}
         
        />
      );
    }

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />; // or some growl/message that stays
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = (
        <>
          {this.tableheader()}
          <div className="datatable-centerHeader datatable-centerContent">
            <DataTable
              // onRowClick={(event) => {
                
              //   this.goToClientDetails(event.data)
              // }}
              ref={this.state.dt}
              value={this.state.salesClientsList}
              paginator={true}
              rows={15}
              autoLayout={true}
              alwaysShowPaginator={false}
              emptyMessage="No results found."
              globalFilter={this.state.searchValue}
            >
              <Column field="clientCompany" header="Company" />
              <Column field="contactName" header="Client Name" sortable={true} />
              <Column field="contactNumber" header="Phone" />
              <Column field="contactEmail" header="Email" body={mailTo} />
              <Column header="Latest Meeting" body={this.meetingDate} />
              <Column field="leadsStatus" header="Lead Status" sortable={true} filterElement={this.leadStatusFilter()} />
              <Column field="remarks" body={this.remarks} header="Latest Remarks" />
              <Column body={this.actionTemplate} header="Action"  />
              {/* <Column field="industry" header="Industry" filterElement={this.industryFilter()} /> */}
            </DataTable>
          </div>
        </>
      );
    }
    console.log(this.state.salesClientsList)

    // FOR REDIRECT
    return (
      <>
        <div className="row">
          <div className="col-12 col-xl-10">
            <div className="card" data-tour-id="view-sales-clients" data-tour="You can easily track which client has been approached by whom here.">
              <h1 className="pb-2">
                My Current Clients
                <span data-tour-id="add-sales-lead" data-tour="Add in a new lead by connecting your sales team to an existing client contact.">
                  <Button
                    icon="pi pi-plus"
                    iconPos="right"
                    label="Add"
                    className="p-button-danger ml-3"
                    onClick={() => {
                      this.setState({
                        redirectOut: true,
                        redirectPath: "/sales/add"
                      });
                    }}
                  />
                </span>
              </h1>
              {display}
            </div>
          </div>
        </div>
      </>
    );
  }
}
