import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { FullCalendar } from "primereact/fullcalendar";
import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { HolidayService } from "../../service/HolidayService";
import { StatusRecordService } from "../../service/StatusRecordService";
import { StatusService } from "../../service/StatusService";
import { UserService } from "../../service/UserService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { HolidayObj } from "../settings/HolidaySetting";
import moment from "moment";

interface dataObj {
  [key: string]: number | string;
}
export class EventObj {
  public title: string = "";
  public start: Date = new Date();
  public end: Date = new Date();
  public remarks: string = "";
  public allDay: boolean = false;
  public name: string = "";
  public leaveType: string = "";
  public duration: string = "";
  public backgroundColor: string | number = "";
}

type AppState = {
  statusRecords: [];
  status: string;
  events: Array<EventObj>;
  options: {};
  tooltipData: any[];
  isError: boolean;
  colorArray: string[];
};
export class StatusCalendar extends Component<
  RouteComponentProps<any>,
  AppState
> {
  _isMounted = false;
  static contextType = ToastStateContext;
  statusRecordService: StatusRecordService;
  statusService: StatusService;
  commonMethods: CommonMethods;
  userService: UserService;
  holidayService: HolidayService;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      colorArray: [
        "#f9ebea", //beige
        "#ff83b5", //light pink
        "#5499c7", //sea blue
        "#d4ece6", //light hint of mint
        "#aaaaaa", //light grey
        "#89eadd", //pleasant mint green
        "#f3e4d8", //cream
        "#feda75", //sunflower yellow
        "#f0d6e4", //pastelest pink
        "#b0d6e4", //pastel seafoam
        "#c79dd7", //calm lavender
        "#89adec", //good cerulean blue
      ],
      tooltipData: [],
      status: "Active",
      statusRecords: [],
      events: Array<EventObj>(),
      isError: false,
      options: {
        columnHeaderText: (date: Date) => {
          switch (date.getDay()) {
            case 0:
              return "Su";
            case 1:
              return "M";
            case 2:
              return "T";
            case 3:
              return "W";
            case 4:
              return "T";
            case 5:
              return "F";
            case 6:
              return "Sa";
            default:
              break;
          }
        },
        contentHeight: 600,
        eventLimit: true,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        defaultView: "dayGridMonth",
        defaultDate: Date.now(),
        header: {
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek",
        },
        editable: false,
        titleFormat: { year: "numeric", month: "short" },
        timeFormat: { hour: "numeric", minute: "2-digit", meridiem: false },
        displayEventTime: false,
        eventMouseEnter: (info: any) => {
          if (this._isMounted) {
            this.setState({
              tooltipData: [
                info.event.extendedProps.name,
                info.event.extendedProps.leaveType,
                info.event.extendedProps.remarks,
                info.event.extendedProps.duration,
              ],
            });
          }
          info.el.setAttribute("data-tip", ``);
          ReactTooltip.rebuild();
        },
      },
    };
    this.statusRecordService = new StatusRecordService();
    this.statusService = new StatusService();
    this.commonMethods = new CommonMethods();
    this.userService = new UserService();
    this.holidayService = new HolidayService();
    this.tooltipDisplay = this.tooltipDisplay.bind(this);
    this.getCalendarEvents = this.getCalendarEvents.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.setState({ colorArray: this.commonMethods.getColourArr() }, () => {
        this.getCalendarEvents();
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  getCalendarEvents = async () => {
    let eventList = new Array<EventObj>();
    let uniqueNames: dataObj[] = [];
    await this.statusRecordService
      .getDepartmentLeaveCalendar()
      .then((res) => {
        res.forEach((status: dataObj) => {
          if (
            !uniqueNames.some(
              (uniqueName) => uniqueName.name === status.fullDisplayName
            )
          ) {
            uniqueNames.push({
              name: status.fullDisplayName,
              color: this.state.colorArray.pop()! + "!important",
            });
          }

          const eventObj = this.createStatusEventObj(status);

          eventList.push(eventObj);
        });
      })
      .catch((err) => {
        if (this._isMounted) {
          this.context.SetError("Error getting leaves");
          this.setState({ isError: true });
        }
      });

    await this.holidayService
      .getHolidayForUser()
      .then((res) => {
        let holidayres = new Array<HolidayObj>();
        holidayres = res;

        holidayres.forEach((item) => {
          let eventObj = new EventObj();
          eventObj.allDay = true;
          eventObj.end = new Date((item.holidayDate + "Z").toLocaleString());
          eventObj.start = new Date((item.holidayDate + "Z").toLocaleString());
          eventObj.leaveType = "Public Holiday";
          eventObj.name = item.holidayName;
          eventObj.title = item.holidayName;
          eventObj.backgroundColor = "#7E8CF1 !important";
          eventObj.remarks = item.holidayDescription;
          eventObj.duration = item.duration;
          eventList.push(eventObj);
        });
      })
      .catch((err) => {
        if (this._isMounted) {
          this.context.SetError("Error getting leaves");
          this.setState({ isError: true });
        }
      });

    eventList.forEach((element) => {
      if (uniqueNames.some((e) => e.name === element.name)) {
        let color = uniqueNames
          .filter((obj) => obj.name === element.name)
          .map((obj) => obj.color)[0];
        element.backgroundColor = color;
      }
    });
    if (!this._isMounted) {
      return;
    }
    this.setState({ events: eventList });
  };

  createStatusEventObj = (status: any) => {
    let eventObj = new EventObj();

    if (this.commonMethods.timingBasedLeaveTypes(status.statusTypeName)) {
      eventObj.name = status.fullDisplayName;
      eventObj.title = status.fullDisplayName + " | " + status.statusTypeName;
      eventObj.leaveType = status.statusTypeName;
      eventObj.remarks = status.remarks;
      eventObj.start = moment(status.startDate).toDate();
      eventObj.end = moment(status.endDate).toDate();
      eventObj.duration = this.commonMethods.convertStartEndDateForStartEndDayDateDurationDisplay(
        status.statusTypeName,
        status.startDate,
        status.endDate,
        status.duration
      );
    } else {
      eventObj.allDay = false;
      eventObj.name = status.fullDisplayName;
      eventObj.leaveType = status.statusTypeName;
      eventObj.remarks = status.remarks;
      eventObj.start = moment(status.startDate).toDate();
      eventObj.end = moment(status.endDate).toDate();
      eventObj.title = status.fullDisplayName + " | " + status.statusTypeName;
      eventObj.duration = this.commonMethods.convertStartEndDateForStartEndDayDateDurationDisplay(
        status.statusTypeName,
        status.startDate,
        status.endDate,
        status.duration
      );
    }

    return eventObj;
  };

  tooltipDisplay = () => {
    return (
      <>
        <p>Name: {this.state.tooltipData[0]}</p>
        <p>Leave Type: {this.state.tooltipData[1]}</p>
        <p>Remarks: {this.state.tooltipData[2]}</p>
        <p>Duration: {this.state.tooltipData[3]}</p>
      </>
    );
  };

  durationTemplate = (rowData: {
    remarks: string;
    endDate: Date;
    startDate: Date;
    duration: string;
    statusTypeName: string;
  }) => {
    let appendStartDuration = "";
    let splitArray = [];

    if (rowData.duration === "FULL DAY")
      return this.commonMethods
        .displayDate(rowData.startDate)
        .toString();
    else {
      splitArray = rowData.duration.split(" - ");

      appendStartDuration = splitArray[0];

      let startDateStr =
        this.commonMethods.displayDate(rowData.startDate).toString() +
        " (" +
        appendStartDuration +
        ")";

      let appendEndDuration = "";
      if (splitArray[1] === "") {
        appendEndDuration = "FULL DAY";
      }
      appendEndDuration = splitArray[1];

      let endDateStr =
        this.commonMethods.displayDate(rowData.endDate).toString() +
        " (" +
        appendEndDuration +
        ")";

      return startDateStr + " - " + endDateStr;
    }
  };

  render() {
    if (this.state.isError) {
      return <CustomError message="An error has occurred" />;
    } else {
      return (
        <>
          <div
            data-tour-id="calendar"
            data-tour="Check your department's activities here. Hover over a status to view more details. Use the blue buttons to toggle to your preferred view."
          >
            <ReactTooltip
              effect="solid"
              place="top"
              delayHide={600}
              clickable={true}
            >
              {this.tooltipDisplay()}
            </ReactTooltip>

            <FullCalendar
              events={this.state.events}
              options={this.state.options}
            />
          </div>
        </>
      );
    }
  }
}

export class StatusCalendarPage extends Component<RouteComponentProps<any>> {
  render() {
    return (
      <div className="row">
        <div className="col-12 col-xl-10">
          <div className="card">
            <h1>Department Calendar</h1>
            <StatusCalendar {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

export default StatusCalendar;
