import React, { useEffect, useState } from 'react'
import { toTitleCase } from '../../CompanyFeedFunctions'
import { PreviewProps, UserPreview } from '../../Model/PostModel'
const placeholder = require('../../Assets/profileplaceholder.jpg')

export default function ProfilePreview({
    parentId,
    previewData,
    previewProps,
}: {
    parentId: string;
    previewData: UserPreview | null
    previewProps: PreviewProps
}) {
    const [match, setMatch] = useState(false)

    useEffect(() => {
        setMatch(isMatch(parentId, previewProps.loadPreviewIdentifier))
    }, [parentId, previewProps.loadPreviewIdentifier])

    function handleError(e: React.SyntheticEvent<HTMLImageElement, Event>) {
        e.currentTarget.src = placeholder
    }

    function Picture() {
        return (
            <img
                className="profile-preview-picture"
                src={previewData?.displayPicture ?? placeholder}
                alt=""
                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
            />
        )
    }

    function User() {
        return (
            <div className="text-bold user-description">
                <div>
                    {toTitleCase(previewData?.firstName) + ' ' + toTitleCase(previewData?.lastName)}
                </div>
                <div className="user-jobtitle">
                    {previewData?.jobTitle + ' · ' + previewData?.userRankName}
                </div>
                <div className="user-companyname">
                    {previewData?.companyName}
                </div>
            </div>
        )
    }

    // function CompanyLogo() {
    //     return <img className="preview-companylogo" src={eatbooklogo} alt="" />
    // }

    function profilePreviewContainerClass() {
        if (previewProps.isElementAboveMiddleVH) return "profile-preview-container preview-appear-bottom"
        if (!previewProps.isElementAboveMiddleVH) return "profile-preview-container preview-appear-top"
    }

    // match the Preview element for the post/comment with the post/comment being hovered over
    function isMatch(parentId: string, loadPreviewIdentifier: string) {
        if (parentId === loadPreviewIdentifier) return true
        else return false
    }

    return (
        <>
            {(match && previewData) ? 
            <div className={profilePreviewContainerClass()}>
                <div className="profile-preview">
                    <div><Picture /></div>
                    <div><User /></div>
                    {/* <div><CompanyLogo /></div> */}
                </div>
            </div> 
            : null}
        </>
    )
}
