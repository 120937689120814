import { CommonMethods } from "../resources/CommonMethods";

export class QuicklinksDashboardModel {
    public linkID: string = CommonMethods.EmptyGuid;
    public link: string = "";
    public linkTitle: string = "";
    // public createdTime: Date = new Date();
    // public createdBy: string = CommonMethods.EmptyGuid;
    // public companyID: string = "";
    // public groupCompanyID: string = CommonMethods.EmptyGuid;
    public fullName: string = "";
}