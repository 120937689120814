import React, {
    ReactElement,
    MouseEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
    defaultSuggestionsFilter, MentionData, MentionPluginTheme,
} from '@draft-js-plugins/mention';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import editorStyles from './PostDraftJsEditor.module.css';
import mentionsStyles from './MentionsStyles.module.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import { getContext, toTitleCase } from '../../CompanyFeedFunctions';
import EmojiPicker from '../Comment/Forms/EmojiPicker';
import Tippy from '@tippyjs/react';
const placeholder = require('../../Assets/profileplaceholder.jpg')

export interface EntryComponentProps {
    className?: string;
    onMouseDown(event: MouseEvent): void;
    onMouseUp(event: MouseEvent): void;
    onMouseEnter(event: MouseEvent): void;
    role: string;
    id: string;
    'aria-selected'?: boolean | 'false' | 'true';
    theme?: MentionPluginTheme;
    mention: MentionData;
    isFocused: boolean;
    searchValue?: string;
}

export default function PostDraftJsEditor({
    rawEditorContentJsonStr,
    postText,
    setRawEditorContentJsonStr,
    setUsersTaggedArr,
    editorRef,
    setHasText,
    contextName,
}: {
    rawEditorContentJsonStr?: string | null
    postText?: string | null
    setRawEditorContentJsonStr: React.Dispatch<React.SetStateAction<string | null>>
    setUsersTaggedArr: React.Dispatch<React.SetStateAction<string[] | null>>
    editorRef: React.RefObject<Editor>
    setHasText: React.Dispatch<React.SetStateAction<boolean>>
    contextName: string
}): ReactElement {
    const { state } = useContext(getContext(contextName));
    const [editorState, setEditorState] = useState(() => {
        console.log('init postdraft editor rawEditorContentJsonStr: ', rawEditorContentJsonStr)
        if (rawEditorContentJsonStr) return EditorState.createWithContent(convertFromRaw(JSON.parse(rawEditorContentJsonStr)))
        if (postText) return EditorState.createWithContent(ContentState.createFromText(postText))
        return EditorState.createEmpty()
    });
    const [open, setOpen] = useState(false);
    const [mentions, setMentions] = useState<MentionData[]>(state.allMentionSuggestions)
    const [suggestions, setSuggestions] = useState(state.allMentionSuggestions);
    const [loadEmojiPicker, setLoadEmojiPicker] = useState(false)
    const { plugins, MentionSuggestions } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            theme: mentionsStyles,
            mentionPrefix: '@',
            supportWhitespace: true,
        });
        const linkifyPlugin = createLinkifyPlugin({
            rel: "noopener noreferrer",
            target: "_blank"
        });
        const emojiPlugin = createEmojiPlugin({
            // selectButtonContent: <span className="draftjs-emoji-button-span">☺</span>
        });
        // const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
        const { MentionSuggestions } = mentionPlugin;
        const plugins = [mentionPlugin, linkifyPlugin, emojiPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const onOpenChange = useCallback((_open: boolean) => {
        setOpen(_open);
    }, []);
    const onSearchChange = useCallback(({ value }: { value: string }) => {
        setSuggestions(defaultSuggestionsFilter(value, mentions));
    }, []);

    useEffect(() => {
        setMentions(state.allMentionSuggestions)
    }, [state.allMentionSuggestions])

    useEffect(() => {
        setHasText(!!editorState.getCurrentContent().getPlainText().trim())
        setRawEditorContentJsonStr(editorStateToJsonStr(editorState))
        setUsersTaggedArr(getMentions(editorState))
    }, [editorState, setHasText, setRawEditorContentJsonStr, setUsersTaggedArr])

    function editorStateToJsonStr(editorState: EditorState) {
        return JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    }

    function getMentions(editorState: EditorState) {
        var rawJson = convertToRaw(editorState.getCurrentContent())
        let mentionedUsers: string[] = []
        for (const key in rawJson.entityMap) {
            if (Object.prototype.hasOwnProperty.call(rawJson.entityMap, key)) {
                const element = rawJson.entityMap[key];
                if (element.type === "mention" && !mentionedUsers.includes(element.data.mention.userId)) {
                    mentionedUsers.push(element.data.mention.userId)
                }
            }
        }
        // to save usersTagged as null into database if empty array
        if (mentionedUsers.length === 0) return null
        return mentionedUsers
    }

    function clickEmojiPicker(e: any) {
        setLoadEmojiPicker(prev => !prev)
    }

    return (
        <div>
            <div
                className={editorStyles.editor}
                onClick={() => {
                    editorRef.current!.focus();
                }}
            >
                <Editor
                    editorState={editorState}
                    onChange={setEditorState}
                    plugins={plugins}
                    ref={editorRef}
                    placeholder="What's happening?"
                />
                <MentionSuggestions
                    open={open}
                    onOpenChange={onOpenChange}
                    suggestions={suggestions}
                    onSearchChange={onSearchChange}
                    onAddMention={(mention) => { editorRef.current!.focus() }}
                    entryComponent={Entry}
                />
            </div>
            <div className="position-relative">
                <Tippy
                    content={
                        <EmojiPicker
                            editorState={editorState}
                            setEditorState={setEditorState}
                            loadEmojiPicker={loadEmojiPicker}
                            setLoadEmojiPicker={setLoadEmojiPicker}
                        />
                    }
                    interactive={true}
                    appendTo={document.body}
                    placement="auto"
                    offset={[2, 2]}
                    zIndex={1050}
                    visible={loadEmojiPicker}
                    onClickOutside={() => setLoadEmojiPicker(false)}
                >
                    <span
                        className="material-icons-outlined emoji-picker-button"
                        onClick={e => clickEmojiPicker(e)}
                    >sentiment_satisfied_alt</span>
                </Tippy>
            </div>
        </div>
    );
}

function Entry(props: EntryComponentProps): ReactElement {
    const {
        mention,
        theme,
        searchValue,
        isFocused,
        ...parentProps
    } = props;

    return (
        <div {...parentProps}>
            <div className={theme?.mentionSuggestionsEntryContainer}>
                <div className={theme?.mentionSuggestionsEntryContainerLeft}>
                    <img
                        src={mention.avatar}
                        className={theme?.mentionSuggestionsEntryAvatar}
                        role="presentation"
                        alt=""
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => e.currentTarget.src = placeholder}
                    />
                </div>

                <div className={theme?.mentionSuggestionsEntryContainerRight}>
                    <div className={theme?.mentionSuggestionsEntryText}>
                        {toTitleCase(mention.name)}
                    </div>

                    <div className={theme?.mentionSuggestionsEntryTitle}>
                        {toTitleCase(mention.jobTitle)} · {toTitleCase(mention.companyName)}
                    </div>
                </div>
            </div>
        </div>
    );
}