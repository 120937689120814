import axios, { Canceler } from "axios";

export class HttpClient {
  CancelToken = axios.CancelToken;
  cancel!: Canceler;
  source = this.CancelToken.source();

  constructor() {
    axios.create();
  }

  async get(
    url: string,
    cancel = new this.CancelToken((c) => (this.cancel = c))
  ) {
    try {
      const res = await axios.get(url, {
        cancelToken: cancel,
        // headers: {
        //   "Content-Type": "application/json",
        //   "Cache-Control": "no-cache"
        // }
      });
      return res.data;
    } catch (err) {
      if (axios.isCancel(err)) {
      } else {
        throw err;
      }
    }
  }

  async post(
    url: string,
    data: string | FormData | any | object,
    cancel = new this.CancelToken((c) => (this.cancel = c))
  ) {
    try {
      const res = await axios.post(url, data, { cancelToken: cancel });
      return res.data;
    } catch (err) {
      if (axios.isCancel(err)) {
      } else {
        throw err;
      }
    }
  }

  async postModifiedResponse(
	url: string,
	data: string | FormData | any | object,
	cancel = new this.CancelToken((c) => {this.cancel = c})
  ) {
	try {
		const res = await axios.post(url, data, { cancelToken: cancel, responseType: 'arraybuffer' });
		return res;
	} catch (err) {
		if (axios.isCancel(err)) {
		} else {
			throw err;
		}
	}
  }

  async patch(
    url: string,
    data: string | FormData | any | object,
    cancel = new this.CancelToken((c) => (this.cancel = c))
  ) {
    try {
      const res = await axios.patch(url, data, { cancelToken: cancel });
      return res.data;
    } catch (err) {
      if (axios.isCancel(err)) {
      } else {
        throw err;
      }
    }
  }

  async put(
    url: string,
    data: string | FormData | any | object,
    cancel = new this.CancelToken((c) => (this.cancel = c))
  ) {
    const res = await axios.put(url, data, { cancelToken: cancel });
    return res.data;
  }

  async delete(
    url: string,
    cancel = new this.CancelToken((c) => (this.cancel = c))
  ) {
    const res = await axios.delete(url, { cancelToken: cancel });
    return res.data;
  }

  async loginpost(
    url: string,
    data: any,
    cancel = new this.CancelToken((c) => (this.cancel = c))
  ) {
    const res = await axios.post(url, data, { cancelToken: cancel });
    return res.data;
  }

  async cancelMethod() {
    this.source.cancel("cancelled!!");
  }
}

const instance = new HttpClient();
export default instance;
