import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { confirmDialog } from 'primereact/confirmdialog'
import { Menu } from 'primereact/menu'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { PostModel, PreviewProps, UserPreview } from '../../Model/PostModel'
import CompanyFeedService from '../../Service/CompanyFeedService'
import Comments from '../Comment/Comments'
import CreateComment from '../Comment/Forms/CreateComment'
import LikeButton from '../LikeButton/LikeButton'
import NumberOfComments from './NumberOfComments'
import NumberOfLikes from './NumberOfLikes'
import PostImagesContainer from './PostImagesContainer'
import PostUserAvatar from './PostUserAvatar'
import '@draft-js-plugins/mention/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import PostDraftJsEditorReadOnly from '../DraftJsEditors/PostDraftJsEditorReadOnly';
import { useHistory } from 'react-router'
import { getContext } from '../../CompanyFeedFunctions'
import BirthdayPostHeader from '../BirthdayPost/BirthdayPostHeader'
import { ACTIONS } from '../../DispatchActions'

export default function Post({
    post,
    setPostToEdit,
    setIsEditPost,
    setIsShowEditDialog,
    previewData,
    setPreviewData,
    previewProps,
    setPreviewProps,
    contextName,
    singlePostLoaded,
    parentComponent,
}: {
    post: PostModel
    setPostToEdit: React.Dispatch<React.SetStateAction<PostModel>>
    setIsEditPost: React.Dispatch<React.SetStateAction<boolean>>
    setIsShowEditDialog: React.Dispatch<React.SetStateAction<boolean>>
    previewData: UserPreview | null
    setPreviewData: React.Dispatch<React.SetStateAction<UserPreview | null>>
    previewProps: PreviewProps
    setPreviewProps: React.Dispatch<React.SetStateAction<PreviewProps>>
    contextName: string
    singlePostLoaded?: boolean
    parentComponent?: string | undefined
}) {
    const [postCommentIdentifier, setPostCommentIdentifier] = useState('')
    const { state, dispatch } = useContext(getContext(contextName));
    const [viewMoreComments, setViewMoreComments] = useState(false)
    // used to correctly update number of comments shown when adding/deleting comments before viewMoreComments button is pressed
    const [viewMoreThreshold, setViewMoreThreshold] = useState(3)
    const history = useHistory();

    // if Comments are toggled programmatically, @mentions stop working for the first CreateComment input
    // unsure why, maybe there are some issues with the PostDraftJsEditorReadOnly loading at the same time as the CommentDraftJsEditor 
    // added setTimeout here as a temp fix, but unreliable if user's internet is slow
    useEffect(() => {
        if (contextName === "SinglePost" && singlePostLoaded) {
            setTimeout(() => {
                handleCommentToggle(post)
            }, 300);
        }
    }, [])

    function handleEditPost(post: PostModel) {
        console.log('handleEditPost')
        setIsEditPost(true)
        setIsShowEditDialog(true)
        setPostToEdit(post)
    }

    function handleDeletePost(post: PostModel) {
        const companyFeedService = new CompanyFeedService();
        companyFeedService.deletePost(post.postId)
            .then((res) => {
                if (contextName === "SinglePost" && res.status === 200) {
                    // delete success while viewing SinglePost, redirect to companyfeed
                    history.push(`/companyfeed`)
                }
                // deleting post in CompanyFeed removes it from array of posts
                if (contextName === "CompanyFeed" && res.status === 200) {
                    if (parentComponent === "TodayBirthdayPost") dispatch({ type: ACTIONS.BIRTHDAY_POST.DELETE_POST, payload: { postId: res.data } })
                    else dispatch({ type: ACTIONS.DELETE_POST, payload: { postId: res.data } })
                }
            })
    }

    function handleCommentToggle(data: any) {
        // console.log('commenting on post', data.postId)
        setPostCommentIdentifier(data.postId)
        // toggle button if already selected
        if (data.postId === postCommentIdentifier) {
            setPostCommentIdentifier('')
        }
    }

    function handleCopyLinkPost(post: PostModel) {
        const linkToPost = `${window.location.origin}/companyfeed/post/${post.postId}`
        navigator.clipboard.writeText(linkToPost).then(function () {
            console.log('Copying to clipboard was successful!');
        }, function (err) {
            console.error('Could not copy text: ', err);
        });
    }


    function MenuButton({ item, currentUserId }: { item: PostModel; currentUserId: string }) {
        const menu: any = useRef(null);
        let menuItems = [
            {
                label: 'Copy link to Post',
                icon: 'pi pi-link',
                command: () => {
                    handleCopyLinkPost(item)
                }
            },
        ];

        if (item.userId === currentUserId || state.currentUser.hasCompanyFeedAdminAccess) {
            menuItems.push(
                {
                    label: 'Edit',
                    icon: 'pi pi-pencil',
                    command: () => {
                        handleEditPost(item)
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: () => {
                        confirmDelete(item)
                    }
                },
            )
        }

        return (
            <div>
                <Menu model={menuItems} popup ref={menu} id="post-ellipsis-menu" appendTo={document.body}/>
                <Button
                    icon="pi pi-ellipsis-v"
                    className="p-button-secondary p-button-text"
                    onClick={(event) => menu.current.toggle(event)}
                />
            </div>
        )
    }

    const confirmDelete = (item: PostModel) => {
        confirmDialog({
            message: 'Do you want to delete this post?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => handleDeletePost(item),
            className: "confirm-delete-dialog",
        });
    }

    return (
        <Card className="posts-rounded-border">
            <div className="posts-grid-container">
                <div>
                    <div className="posts-header-flex">
                        {post.type === "Birthday" ? <BirthdayPostHeader birthdayDate={post.createdTime} /> : null}
                        {post.type === "Feed" ? <div>
                            <PostUserAvatar
                                item={post}
                                previewData={previewData}
                                setPreviewData={setPreviewData}
                                previewProps={previewProps}
                                setPreviewProps={setPreviewProps}
                            />
                        </div> : null}
                        <div>
                            <MenuButton item={post} currentUserId={state.currentUser.userId} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="padding-all">
                        <PostDraftJsEditorReadOnly rawEditorContentJsonStr={post.rawEditorContentJsonStr} postText={post.postText} />
                    </div>
                </div>
                <div>
                    <PostImagesContainer imagePathArray={post.imagePathArray} />
                </div>
                <div>
                    <div className="flex-container-lc-buttons">
                        <div className="flex-row">
                            <LikeButton
                                item={post}
                                currentUser={state.currentUser.userId}
                                dispatch={dispatch}
                                parentComponent={parentComponent}
                            />
                            <NumberOfLikes item={post} />
                        </div>
                        <span onClick={() => handleCommentToggle(post)}>
                            {/* Not using item.comments.length because haven't retrieved comments from backend yet */}
                            <NumberOfComments numberOfComments={post.numberOfComments} />
                        </span>
                    </div>
                </div>
                {postCommentIdentifier === post.postId
                    ?
                    <div>
                        <div>
                            <div>
                                <CreateComment
                                    inputPlaceholder="Write a comment"
                                    post={post}
                                    setViewMoreThreshold={setViewMoreThreshold}
                                    contextName={contextName}
                                    parentComponent={parentComponent}
                                />
                            </div>
                            <Comments
                                postId={post.postId}
                                postCommentIdentifier={postCommentIdentifier}
                                comments={post.comments}
                                previewData={previewData}
                                setPreviewData={setPreviewData}
                                previewProps={previewProps}
                                setPreviewProps={setPreviewProps}
                                viewMoreComments={viewMoreComments}
                                setViewMoreComments={setViewMoreComments}
                                viewMoreThreshold={viewMoreThreshold}
                                setViewMoreThreshold={setViewMoreThreshold}
                                contextName={contextName}
                                parentComponent={parentComponent}
                            />
                        </div>
                    </div>
                    : null
                }
            </div>
        </Card>
    )
}

