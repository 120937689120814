import React from 'react'
import cake1 from '../../Assets/cake1.jpg'
import cake2 from '../../Assets/cake2.jpg'
import cake3 from '../../Assets/cake3.jpg'
import cake4 from '../../Assets/cake4.png'
import moment from 'moment'

export default function BirthdayPostHeader({
    birthdayDate,
}: {
    birthdayDate: Date
}) {

    function differentCake(birthdayDate: Date) {
        var dayOfBirthdayStr = moment(birthdayDate).add(8, 'hours').format("D")
        var dayOfBirthday = parseInt(dayOfBirthdayStr)
        var remainder = (dayOfBirthday % 4)
        if (remainder === 0) return cake1
        if (remainder === 1) return cake2
        if (remainder === 2) return cake3
        else return cake4
    }

    function formatBirthdayDate(birthdayDate: Date) {
        var formattedDate = moment(birthdayDate).add(8, 'hours').format("MMMM D")
        return formattedDate
    }

    return (
        <div className="flex-row">
            {/* <img className="birthday-header-banner" src={happybirthday} alt="" /> */}
            <img className="birthday-header-icon" src={differentCake(birthdayDate)} alt="" />
            <div className="flex-column birthday-header-date">
                <span className="birthday-header-date-span1">BIRTHDAY:</span>
                <span className="birthday-header-date-span2">{formatBirthdayDate(birthdayDate)}</span>
                {/* <span className="birthday-header-date-span2">JUNE 24</span> */}
            </div>
        </div>
    )
}