import { HttpClient } from "./HttpClient";
import { PublisherModel } from "../components/publisher/PublisherModel";


export class PublisherService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async getAllPublishersByGCID() {
    const res = await this.httpClient.get("/api/publisher");
    return res;
  }

  async createPublisher(publisherObj: PublisherModel) {
    const res = await this.httpClient.post(
      "/api/publisher",
      publisherObj
    );
    return res;
  }

  async updatePublisher(publisherObj: PublisherModel) {
    const res = await this.httpClient.put(
      "/api/publisher",
      publisherObj
    );
    return res;
  }

  async deletePublisher(ID: string) {
    const res = this.httpClient.delete("/api/publisher/" + ID);
    return res;
  }
  async getPublisherByID(id: string) {
    const res = await this.httpClient.get("/api/publisher/" + id);
    return res;
  }

  async updatePublisherStatus(publisherObj: PublisherModel) {
    const res = this.httpClient.put(
      "/api/publisher/updatestatus",
      publisherObj
    );
    return res;
  }

  // May not need below
  async deletePublisherByID(id: string) {
    const res = await this.httpClient.delete("/api/publisher/id" + id);
    return res;
  }
  async deactivatePublisher(ID: string) {
    const res = this.httpClient.get("/api/publisher/deactivate/" + ID);
    return res;
  }
  async activatePublisher(ID: string) {
    const res = this.httpClient.get("/api/publisher/activate" + ID);
    return res;
  }
}

export default PublisherService;