import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
// import { GrowlStateContext } from "../resources/GrowlContext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import CampaignInvoiceService from "../../service/CampaignInvoiceService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { CampaignInvoiceModel } from "./CampaignInvoice";


let campaignInvoiceService = new CampaignInvoiceService();
let commonMethods = new CommonMethods();
function CampaignInvoiceTable(props: RouteComponentProps<any>) {
  let params: { id?: string } = useParams();


  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
 

  const [invoiceTableData, setInvoiceTableData] = useState<
    CampaignInvoiceModel[]
  >([]);


  useEffect(() => {
    if (params.id !== undefined) {
      campaignInvoiceService
        .getInvoicesByCampaignID(params.id)
        .then(res => {
          setInvoiceTableData(res);
          setIsLoading(false);
        })
        .catch(err => {
          let errMsg = commonMethods.getErrorMessage(err);
          setErrorMessage(errMsg)
          setIsError(true);
        });
    }
  }, []);


  const actionsTemplate = (rowData: CampaignInvoiceModel) => {


    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
       
        <Button
          tooltip="View Invoice"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-eye"
          className=" p-button-primary"
          onClick={() => {
            window.open(rowData.uploadPath, "_blank");
          }}
        />
      </div>
    );
  };

  const createdDateTemplate = (rowData: CampaignInvoiceModel) => {
    return commonMethods.displayDateTime(new Date(rowData.createdTime + "Z"))    
  }

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMsg} />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            //   header={tableheader}
            paginator={true}
            rows={15}
            autoLayout={true}
            alwaysShowPaginator={false}
            emptyMessage="No invoices found."
            value={invoiceTableData}
          >
            {/* <Column field="campaignSerialNo" header="ID" /> */}
            <Column field="invoiceSerialNo" header="Serial Number" />
            <Column body={createdDateTemplate} header="Created Date/Time" />
            <Column body={actionsTemplate} header="Actions" />
          </DataTable>
        </div>
      );
    }
  };

  return (
    <div className="row">
      <div className="col-12" data-tour-id="campaign-invoices"
        data-tour="These are invoices generated for this campaign.">
        <div className="card">
          <h1 className="pb-2">Invoices</h1>
          {display()}
        </div>
      </div>
    </div>
  );
}

export default CampaignInvoiceTable;
