import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useRef } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { CompanyService } from "../../service/CompanyService";
import { HolidayService } from "../../service/HolidayService";
import { UserService } from "../../service/UserService";
import { CommonMethods } from "../resources/CommonMethods";
import  CustomError  from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { SelectButton } from "primereact/selectbutton";
import moment from "moment";

type AppState = {
  redirectOut: boolean;
  redirectPath: string;
  redirectParam: string;
  holidays: HolidayObj[];
  holidayobj: HolidayObj;
  confirmDeleteVisible: boolean;
  bulkImportVisible: boolean;
  icsFile: File | any;
  coyID: string;
  groupCoyID: string;
  globalFilter: any;
  companyFilter: string;
  dateFilter: Date;
  isError: boolean;
  isLoading: boolean;
  errorMsg: string;
  companyDropdown: any[];
  dt: any
};

export class HolidayObj {
  holidayID: string = CommonMethods.EmptyGuid;
  companyName: string = "";
  groupCompanyID: string = "";
  companyID: string = "";
  appliedLevel: string = "";
  holidayName: string = "";
  holidayDescription: string = "";
  holidayDate: Date = new Date();
  creditValue: number = 1;
  duration: string = "";
}

export class HolidaySettings extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  commonMethods: CommonMethods;
  holidayService: HolidayService;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      dt: React.createRef(),
      redirectOut: false,
      redirectPath: "",
      redirectParam: "",
      holidays: [],
      holidayobj: new HolidayObj(),
      confirmDeleteVisible: false,
      bulkImportVisible: false,
      icsFile: "",
      coyID: "",
      groupCoyID: "",
      globalFilter: "",
      companyFilter: "",
      dateFilter: new Date(),
      isError: false,
      isLoading: true,
      errorMsg: "",
      companyDropdown: [],
    };
    this.holidayService = new HolidayService();
    this.commonMethods = new CommonMethods();
    this.actionsTemplate = this.actionsTemplate.bind(this);
  }

  componentDidMount() {
    this.getHolidays();
    this.getDropdowns();
    this.getIDsForImport();
  }

  getIDsForImport() {
    try {
      this.commonMethods.getGroupCompanyIDFromUserID().then((res) => {
        this.setState({
          groupCoyID: res,
        });
      });
      this.commonMethods.getCompanyIDFromUserID().then((res) => {
        this.setState({
          coyID: res,
        });
      });
    } catch (error) {
      this.setState({
        isError: true,
        errorMsg: this.commonMethods.getErrorMessage(error),
      });
    }
  }

  getDropdowns() {
    this.commonMethods
      .getCompanyDropdownListByGcid()
      .then((res) => {
        this.setState({ companyDropdown: res }, () => {
          let arr = this.state.companyDropdown;
        });
      })
      .catch((error) => {
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: this.commonMethods.getErrorMessage(error),
        });
      });
  }

  getHolidays() {
    this.holidayService
      .getAllHolidayValues()
      .then((res) => {
        res.forEach((element: any) => {
          element.holidayDate = this.commonMethods.displayDate(
            element.holidayDate
          );
        });
        this.setState({
          holidays: res,
          isLoading: false,
          globalFilter: null,
        });
      })
      .catch((err) => {
        this.setState({
          isError: true, // display error page
          isLoading: false,
          errorMsg: this.commonMethods.getErrorMessage(err),
        });
      });
  }

  createHoliday() {
    this.setState({ redirectPath: "/admin/holidays/create" }, () =>
      this.setState({ redirectOut: true })
    );
  }

  bulkImport() {
    this.setState({ bulkImportVisible: true });
  }

  readICSContents() {
    if (this.state.icsFile === null || this.state.icsFile === "") {
      return;
    }

    if (this.state.icsFile.name !== null && this.state.icsFile.name !== "") {
      let fileName = this.state.icsFile.name.split(".");
      if (fileName[1] !== undefined && fileName[1] !== "ics") {
        this.context.SetError(
          "Only .ics files from MOM's website are accepted."
        );
        return;
      }
    }

    this.setState(
      {
        isLoading: true,
      },
      () => {
        //
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(this.state.icsFile);
        fileReader.onloadend = () => {
          this.setState({ icsFile: fileReader.result }, () => {
            let holidays: any[] = [];
            let array = this.state.icsFile.split("BEGIN:VEVENT");
            array.shift();
            array.forEach((element: string) => {
              let holidayDateString = element.substr(
                element.indexOf("DTSTART;VALUE=DATE:") + 19,
                8
              );
              let holidayName = element.substr(
                element.indexOf("SUMMARY:") + 8,
                element.indexOf("\n", element.indexOf("SUMMARY:")) -
                  element.indexOf("SUMMARY:") -
                  8
              );
              let year = Number.parseInt(holidayDateString.substr(0, 4));
              let month = Number.parseInt(holidayDateString.substr(4, 2)) - 1;
              let day = Number.parseInt(holidayDateString.substr(6, 2));
              let holidayDate = new Date(year, month, day);

              holidayDate.setHours(
                holidayDate.getHours() +
                  moment(holidayDate).parseZone().utcOffset() / 60
              );

              holidays.push({
                holidayDate: holidayDate,
                holidayName: holidayName,
                holidayDescription: holidayName,
                groupCompanyID: this.state.groupCoyID,
                companyID: this.state.coyID,
                appliedLevel: "groupcompany",
                creditValue: 1,
                duration: "FULL DAY",
              });
            });
            this.setState({ icsFile: holidays }, () => {
              this.holidayService
                .createHolidaysBulk(this.state.icsFile)
                .then((res) => {
                  this.setState({ bulkImportVisible: false });
                  this.context.SetSuccess(
                    res
                    // "Holidays imported! Employees who have applied leave on this date have been refunded."
                  );
                  this.setState({
                    globalFilter: null,
                    icsFile: "",
                    isLoading: false,
                  });
                  this.getHolidays();
                })
                .catch((error) => {
                  let errorMsg = this.commonMethods.getErrorMessage(error);
                  if (this.commonMethods.isWarning(error)) {
                    this.context.SetWarn(errorMsg);
                    this.context.SetSuccess(
                      "Holidays imported! Employees who have applied leave on this date have been refunded."
                    );
                  } else {
                    this.context.SetError(errorMsg);
                  }

                  this.setState({
                    isLoading: false,
                    bulkImportVisible: false,
                  });
                });
            });
          });
        };
      }
    );
  }

  updateItem(id: string) {
    this.setState({
      redirectPath: "/admin/holidays/update/" + id,
      redirectOut: true,
    });
  }

  deleteItem(holiday: HolidayObj) {
    this.setState({ holidayobj: holiday, confirmDeleteVisible: true });
  }

  confirmDelete() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.holidayService
          .deleteHolidayValues(this.state.holidayobj.holidayID)
          .then((res) => {
            let msg = res.holidayName + " is now deleted";
            this.context.SetSuccess(msg);
            this.getHolidays();
          })
          .catch((error) => {
            this.context.SetError(this.commonMethods.getErrorMessage(error));
            this.setState({
              isLoading: false,
            });
          })
          .finally(() => {
            this.setState({ confirmDeleteVisible: false });
          });
      }
    );
  }

  actionsTemplate(rowData: HolidayObj) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning mx-1"
          onClick={() => {
            this.updateItem(rowData.holidayID);
          }}
        />
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            this.deleteItem(rowData);
          }}
        />
      </div>
    );
  }

  companyNameFilter(){
    return (
      <Dropdown
        showClear
        className="mr-1 mb-1"
        value={this.state.companyFilter}
        options={this.state.companyDropdown}
        placeholder="Filter by Company Name"
        onChange={(e) => {
          this.setState({ companyFilter: e.value });
          this.onCompanyNameChange(e);
        }}
      />
    );
  }

  onCompanyNameChange(e: any){
    let dt = this.state.dt;
    let companyName = this.state.companyDropdown.filter(label => label.value === e.value).map(item => item.label)[0];
    dt.current.filter(companyName, 'companyName', 'equals');
  }

  render() {
    let header = (
      <div style={{ textAlign: "left" }}>
        <span className="p-input-icon-left mr-1 mb-1">
          <i className="pi pi-search" />
          <InputText
            placeholder="Global Search"
            onChange={(e) => {
              this.setState({ globalFilter: e.currentTarget.value });
            }}
          />
        </span>

        {this.companyNameFilter()}

        <Calendar
          showButtonBar={true}
          todayButtonClassName="d-none"
          tooltip="Filter by Holiday Date"
          tooltipOptions={{ position: "top" }}
          yearNavigator
          yearRange="2020:2023"
          id="MonthCalendar"
          readOnlyInput={true}
          view="month"
          dateFormat="M-yy"
          value={new Date(this.state.dateFilter.toString())}
          onChange={(e) => {
            if (e.value === null) {
              this.setState({
                globalFilter: "",
                dateFilter: new Date(),
              });
            } else {
            this.setState({
              globalFilter: moment(
                new Date(
                  new Date(e.value.toString()).getTime() -
                    new Date().getTimezoneOffset() * 60 * 1000
                )
              ).format("MM/YYYY"),
              dateFilter: new Date(e.value.toString()),
            });
          }}}
        />
      </div>
    );

    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
            search: this.state.redirectParam,
          }}
        />
      );
    }
    let form = (
      <div>
        <Dialog
          className="popupDialog"
          header={"Delete " + this.state.holidayobj.holidayName}
          visible={this.state.confirmDeleteVisible}
          //dismissableMask={true}
          modal={true}
          onHide={() => this.setState({ confirmDeleteVisible: false })}
          footer={
            <div>
              <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-success"
                onClick={() => this.confirmDelete()}
              />
              <Button
                label="No"
                icon="pi pi-times"
                className="p-button-danger"
                onClick={() => this.setState({ confirmDeleteVisible: false })}
              />
            </div>
          }
        >
          Are you sure you want to delete this holiday?
        </Dialog>
        <Dialog
          className="popupDialog"
          header={"Bulk Import"}
          visible={this.state.bulkImportVisible}
          //dismissableMask={true}
          modal={true}
          onHide={() => this.setState({ bulkImportVisible: false })}
          footer={
            <div>
              <Button
                label="Import"
                icon="pi pi-check"
                className="p-button-success"
                onClick={() => this.readICSContents()}
              />
              <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-danger"
                onClick={() => this.setState({ bulkImportVisible: false })}
              />
            </div>
          }
        >
          <input
            type="file"
            name="icsFile"
            accept=".ics"
            onChange={(e) => {
              this.setState({
                icsFile: e.currentTarget.files![0],
              });
            }}
          />
        </Dialog>
        <p>
          Leaves applied on Holidays will not be deducted from leave balance.
          You can import the Holidays from MOM's website{" "}
          <a href="https://www.mom.gov.sg/employment-practices/public-holidays">
            here.
          </a>
        </p>
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            ref={this.state.dt}
            value={this.state.holidays}
            header={header}
            autoLayout={true}
            globalFilter={this.state.globalFilter}
            emptyMessage="No results found."
            paginator={true}
            rows={15}
            alwaysShowPaginator={false}
          >
            <Column field="holidayName" header="Name" />
            <Column field="holidayDescription" header="Description" />
            <Column field="appliedLevel" header="Applicable" />
            <Column field="companyName" header="Company" filterElement={this.companyNameFilter()}/>
            <Column field="holidayDate" header="Date" />
            <Column field="duration" header="Duration" />
            {/* <Column field="creditValue" header="Value" /> */}
            <Column body={this.actionsTemplate} header="Actions" />
            <Column field="companyID" header="" className="d-none" />
          </DataTable>
        </div>
      </div>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = form;
    }

    return (
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="card">
            <h1>
              Holidays
              <span className="pl-3">
                <Button
                  type="button"
                  icon="pi pi-plus"
                  iconPos="right"
                  label="Add"
                  className="p-button-danger mt-2"
                  onClick={() => {
                    this.createHoliday();
                  }}
                />
              </span>
              <span className="pl-2 mb-4">
                <Button
                  type="button"
                  icon="pi pi-plus"
                  iconPos="right"
                  label="Import"
                  className="p-button-primary mt-2"
                  onClick={() => {
                    this.bulkImport();
                  }}
                />
              </span>
            </h1>
            {display}
          </div>
        </div>
      </div>
    );
  }
}

type CreateAppState = {
  redirectOut: boolean;
  redirectPath: string;
  redirectParam: string;
  holidayobj: HolidayObj;
  companyDropdown: [];
  submitType: string;
  isLoading: boolean;
  dialogStr: string;
  dialogHeaderStr: string;
  visible: boolean;
  dialogIcon: string;
  dialogShowError: boolean;
  isError: boolean;
  errorMsg: string;
  durationOptions: any[];
};

export class HolidaySettingsCreate extends React.Component<
  RouteComponentProps<any>,
  CreateAppState
> {
  static contextType = ToastStateContext;

  holidayService: HolidayService;
  userService: UserService;
  companyService: CompanyService;
  commonMethods: CommonMethods;
  appliedLevel: { label: string; value: string }[];
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      redirectOut: false,
      redirectPath: "",
      redirectParam: "",
      holidayobj: new HolidayObj(),
      companyDropdown: [],
      submitType: "Create",
      isLoading: true,
      dialogStr: "",
      dialogHeaderStr: "",
      visible: false,
      dialogIcon: "",
      dialogShowError: false,
      isError: false,
      errorMsg: "",
      durationOptions: [],
    };

    this.appliedLevel = [
      { label: "Group Company Wide", value: "groupcompany" },
      { label: "Company Wide", value: "company" },
    ];

    this.holidayService = new HolidayService();
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.commonMethods = new CommonMethods();
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    var companyId = localStorage.getItem("currentAccessingCompany") || "";
    this.commonMethods
      .getGroupCompanyIDFromUserID()
      .then((res) => {
        this.setState(
          {
            holidayobj: {
              ...this.state.holidayobj,
              groupCompanyID: res,
              duration: "FULL DAY",
              companyID: companyId,
            },
          },
          () => {
            this.commonMethods
              .getCompanyDropdownListByGcid()
              .then((res) => {
                this.setState({ companyDropdown: res, isLoading: false });
              })
              .catch((error) => {
                let errorMsg = this.commonMethods.getErrorMessage(error);
                this.setState({
                  isError: true,
                  isLoading: false,
                  errorMsg: errorMsg,
                });
                return errorMsg;
              });
          }
        );
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
    // update
    if (
      this.props.match.params !== {} &&
      this.props.match.params.id !== undefined
    ) {
      let idFromQuery = this.props.match.params.id;
      this.holidayService
        .getOneHolidayValue(idFromQuery)
        .then((res: HolidayObj) => {
          this.setState({
            holidayobj: res,
            submitType: "Update",
            isLoading: false,
          });
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
          return errorMsg;
        });
    }

    this.setState({ durationOptions: this.commonMethods.getDurationOptions() });
  }

  goBack() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/holidays",
    });
  }

  submitForm(e: React.FormEvent) {
    e.preventDefault();
    if (this.state.submitType === "Create") {
      this.holidayService
        .createHolidayValues(this.state.holidayobj)
        .then((res) => {
          this.context.SetSuccess(res);
          this.goBack();
        })
        .catch((error) => {
          // error dialog
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.context.SetError(errorMsg);
          this.setState({
            isLoading: false,
          });
        });
    } else if (this.state.submitType === "Update") {
      this.holidayService
        .updateHolidayValues(this.state.holidayobj)
        .then((res) => {
          let msg = res.holidayName + " has been updated.";
          this.context.SetSuccess(msg);
          this.goBack();
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.context.SetError(errorMsg);
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  setDurationAndValue(e: { originalEvent: Event; value: any }) {
    let value = 1;
    if (e.value === "PM" || e.value === "AM") {
      value = 0.5;
    }
    this.setState({
      holidayobj: {
        ...this.state.holidayobj,
        duration: e.value,
        creditValue: value,
      },
    });
  }
  render() {
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
            search: this.state.redirectParam,
          }}
        />
      );
    }

    let createNote =
      "Leave credit will be refunded to employees who have leave applied on this date. This is applicable only if the holiday date has not passed.";
    // "Employees who have applied for leave on this date will have their leave credit refunded if the holiday has not passed.";

    let page = (
      <form onSubmit={this.submitForm}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="noticeboardRemarks">
              {this.state.submitType === "Create" && createNote}
            </span>

            {/* <span className="boldLabel">Company </span>
            <Dropdown
              value={this.state.holidayobj.companyID}
              options={this.state.companyDropdown}
              onChange={e => {
                this.setState({
                  holidayobj: {
                    ...this.state.holidayobj,
                    companyID: e.value
                  }
                });
              }}
              placeholder="Select a company"
              required
            /> */}
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Applied Level <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.holidayobj.appliedLevel}
              options={this.appliedLevel}
              onChange={(e) => {
                this.setState({
                  holidayobj: {
                    ...this.state.holidayobj,
                    appliedLevel: e.value,
                  },
                });
              }}
              placeholder="Please Select"
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Holiday Name <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={this.state.holidayobj.holidayName}
              onChange={(e) => {
                this.setState({
                  holidayobj: {
                    ...this.state.holidayobj,
                    holidayName: e.currentTarget.value,
                  },
                });
              }}
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Description <span style={{ color: "red" }}>*</span>
            </span>
            <InputTextarea
              rows={5}
              cols={30}
              value={this.state.holidayobj.holidayDescription}
              onChange={(e) =>
                this.setState({
                  holidayobj: {
                    ...this.state.holidayobj,
                    holidayDescription: e.currentTarget.value,
                  },
                })
              }
              required
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Duration <span style={{ color: "red" }}>*</span>
            </span>
            <SelectButton
              value={this.state.holidayobj.duration}
              options={this.state.durationOptions}
              onChange={(e) => {
                this.setDurationAndValue(e);
              }}
              disabled={this.state.submitType === "Update"}
            ></SelectButton>
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Holiday Date <span style={{ color: "red" }}>*</span>
            </span>

            <Calendar
              readOnlyInput={true}
              dateFormat="dd/mm/yy"
              value={new Date(this.state.holidayobj.holidayDate.toString())}
              onChange={(e) =>
                this.setState({
                  holidayobj: {
                    ...this.state.holidayobj,
                    holidayDate: new Date(e.value.toString()),
                  },
                })
              }
              showIcon={true}
              required
              disabled={this.state.submitType === "Update"}
            />
          </div>
          {/* <div className="p-col-12">
            <span className="boldLabel">Holiday Value </span>
            <Spinner
              value={this.state.holidayobj.creditValue}
              onChange={e => {
                if (!isNaN(Number(e.value))) {
                  this.setState({
                    holidayobj: {
                      ...this.state.holidayobj,
                      creditValue: e.value
                    }
                  });
                }
              }}
              formatInput={false}
              decimalSeparator={"."}
              thousandSeparator={","}
              step={0.5}
            />
          </div> */}
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button type="submit" label={this.state.submitType} />
        </div>
      </form>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = page;
    }

    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{this.state.submitType + " Holiday"}</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {display}
          </div>
        </div>
      </div>
    );
  }
}
