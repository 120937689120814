import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";
import { Redirect } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { CompanyService } from "../../service/CompanyService";
import { GroupCompanyService } from "../../service/GroupCompanyService";
import { CommonDocumentMethods, ProcessedImage } from "../resources/CommonDocumentMethods";
import {
  CommonMethods,
  RouteComponentProps
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";

export class CompanyObj {
  companyID: string = "";
  groupCompanyID: string = "";
  companyName: string = "";
  companyRegNum: string = "";
  gstRegNum: string = "";
  companyAddress: string = "";
  country: string = "SG";
  timeZone: string = "8";
  industry: string = "";
  companyLogo: string = "";
  payslipLogo: string = "";
  companyTSLOSlink: string = "";
  companyStatus: string = "Active";
  companyLogoFile: any = null;
  payslipLogoFile: any = null;
  phoneNumber: string = "";
  createdTime: string = "";
  isParentCompany: boolean = false;
  timezone: string = "";
  cpfSubmissionNo: string = "";
  irasTaxNo: string = "";
  payDayDate: number = 0;
  invoiceBankAccNo: string = "";
  postalCode: string = "";
}

export class GroupCompanyModel {
  public groupCompanyID: string = CommonMethods.EmptyGuid;
  public groupCompanyName: string = "";
  public groupCompanyDescription: string = "";
  public groupCompanyStatus: string = "";
}

type AppState = {
  redirectOut: boolean;
  redirectPath: string;
  companies: Array<any>;
  isLoading: boolean;
  status: string;
  companyObj: CompanyObj;
  countrySuggestions: Array<string>;
  submitType: string;

  groupCompanyObj: GroupCompanyModel;
  groupCompanies: [];

  previewLogoImage: any;
  previewPayslipImage: any;
  statusToBeUpdated: string;
  headerStr: string;
  // error
  isError: boolean;
  errorMsg: string;
  //dialog
  visible: boolean;
  dialogStr: any;
  dialogHeaderStr: string;
  dialogIcon: string;
  dialogShowError: boolean;
  myString: string;

  displayButton: boolean;
};

export class Company extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  status: { label: string; value: string }[];
  dropdownOptions: any[];
  companyService: CompanyService;
  groupCompanyService: GroupCompanyService;
  countryList: any;
  op: any;
  commonMethods: CommonMethods;
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      companyObj: new CompanyObj(),
      groupCompanyObj: new GroupCompanyModel(),
      groupCompanies: [],
      redirectOut: false,
      redirectPath: "",
      companies: [],
      isLoading: true,
      status: "Active",
      countrySuggestions: [],
      submitType: "Create",
      statusToBeUpdated: "",
      headerStr: "",
      previewLogoImage: "",
      previewPayslipImage: "",
      isError: false,
      errorMsg: "",

      visible: false,
      dialogStr: "",
      dialogHeaderStr: "",
      dialogIcon: "",
      dialogShowError: false,

      myString: "",

      displayButton: false,
    };
    this.commonMethods = new CommonMethods();
    this.status = this.commonMethods.getViewStatusDropdownList();
    this.dropdownOptions = this.commonMethods.getStatusSelectionDropdownList();

    this.companyService = new CompanyService();
    this.groupCompanyService = new GroupCompanyService();
    this.actionsTemplate = this.actionsTemplate.bind(this);
    this.displayStatus = this.displayStatus.bind(this);
    this.gcactionbuttons = this.gcactionbuttons.bind(this);

    // this.clearProps = this.clearProps.bind(this);
  }

  componentDidMount() {
    this.setState({ status: "Active", isLoading: true }, () => {
      this.getGroupCompanies();
      this.getCompanies("Active");
    });
  }

  getCompanies(status: string) {
    this.companyService
      .getCompanies(status)
      .then((res) => {
        this.setState({ companies: res, isLoading: false });
      })
      .catch((err) => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
      });
  }

  getGroupCompanies() {
    this.groupCompanyService
      .getGroupCompanies()
      .then((res) => {
        this.setState({
          groupCompanies: res,
          isLoading: false,
          groupCompanyObj: res[0],
        });
      })
      .catch((err) => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
      });
  }

  gcactionbuttons(gc: any) {
    return (
      <>
        <Button
          id="editGroupCompany"
          tooltip="Edit Description"
          tooltipOptions={{ position: "bottom" }}
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            this.updateGCItem();
          }}
        />
      </>
    );
  }

  updateGCItem() {
    this.setState({
      redirectOut: true,
      redirectPath:
        "/groupcompany/update/" + this.state.groupCompanyObj.groupCompanyID,
    });
  }

  logoTemplate(rowData: any) {
    return (
      <div className="text-center">
        <img
          src={rowData.companyLogo}
          alt={rowData.CompanyLogo}
          style={{ maxWidth: "5rem", margin: "0.5rem" }}
        />
        {/* <img
          src={rowData.payslipLogo}
          alt={rowData.PayslipLogo}
          style={{ maxWidth: "5rem", margin: "0.5rem" }}
        /> */}
      </div>
    );
  }

  actionsTemplate(rowData: any) {
    let button = this.statusButton(rowData);
    return button;
  }

  confirmUpdateDialog(e: { originalEvent: Event; value: any }) {
    let activatestr = "Activate " + this.state.companyObj.companyName + "?";
    let deactivatestr = "Deactivate " + this.state.companyObj.companyName + "?";
    this.setState({
      dialogStr: e.value === "Active" ? activatestr : deactivatestr,
      headerStr: "Update Status",
      visible: true,
    });
  }

  displayStatus(rowData: CompanyObj) {
    let statusDisplay;
    if (rowData.companyStatus === "Active") {
      statusDisplay = "Active";
    } else if (rowData.companyStatus === "Deactivated") {
      statusDisplay = "Deactivated";
    }

    return statusDisplay;
  }

  statusButton(rowData: CompanyObj) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          id="editCompanyDetails"
          tooltip="Edit Company Details"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning mx-1"
          onClick={() => {
            this.updateItem(rowData);
          }}
        />
        <Dropdown
          tooltip="Edit Company Status"
          tooltipOptions={{ position: "top" }}
          value={rowData.companyStatus}
          options={this.dropdownOptions}
          onChange={(e) => {
            this.setState(
              {
                companyObj: rowData,
                statusToBeUpdated: e.value,
              },
              () => this.confirmUpdateDialog(e)
            );
          }}
          placeholder="Please Select"
        />
      </div>
    );
  }

  suggestCountries(e: { query: { toLowerCase: () => void } }) {
    let results = this.countryList.filter(
      (countryList: {
        toLowerCase: () => { startsWith: (arg0: any) => void };
      }) => {
        return countryList.toLowerCase().startsWith(e.query.toLowerCase());
      }
    );

    this.setState({ countrySuggestions: results });
  }

  addCompany() {
    this.setState({ redirectPath: "/admin/company/create" });
    this.setState({ redirectOut: true });
  }

  updateItem(updateCompanyObj: { companyID: string }) {
    this.setState({
      redirectPath: "/admin/company/update/" + updateCompanyObj.companyID,
    });
    this.setState({ redirectOut: true });
  }

  updateCompanyStatus() {
    this.setState(
      {
        visible: false,
        companyObj: {
          ...this.state.companyObj,
          companyStatus: this.state.statusToBeUpdated,
        },
      },
      () => {
        this.companyService
          .updateCompanyStatus(this.state.companyObj)
          .then((res) => {
            this.setState({ companyObj: res });
            let msg = res.companyName + " is now " + res.companyStatus;
            this.context.SetSuccess(msg);

            this.setState({ status: "Active" }, () =>
              this.getCompanies("Active")
            );
          })
          .catch((error) => {
            // let errorMsg = this.commonMethods.getErrorMessage(error);
            let status =
              this.state.statusToBeUpdated === "Active"
                ? "activate"
                : "deactivate";
            this.context.SetError(
              "Unable to " + status + " " + this.state.companyObj.companyName
            );
          });
      }
    );
  }

  render() {
    let data;

    if (this.state.groupCompanies.length > 0) {
      data = (
        <div>
          <span className="boldLabel"> Name: </span>
          {this.state.groupCompanyObj.groupCompanyName} <br></br>
          <span className="boldLabel"> Description: </span>
          {this.state.groupCompanyObj.groupCompanyDescription}
        </div>
      );
      // data = (
      //   <DataTable
      //     className="text-center"
      //     value={this.state.groupCompanies}
      //     responsive={true}
      //   >
      //     <Column
      //       className="p-col-4"
      //       field="groupCompanyName"
      //       header="Group Company Name"
      //     />
      //     <Column
      //       className="p-col-5"
      //       field="groupCompanyDescription"
      //       header="Description"
      //     />

      //     <Column
      //       className="p-col-2"
      //       body={this.gcactionbuttons}
      //       header="Action"
      //     />
      //   </DataTable>
      // );
    } else {
      data = <div>No results found.</div>;
    }

    let form = (
      <>
        <div className="row">
          <div className="col-12 col-xl-10">
            <div className="card">
              <h1>
                Group Company <this.gcactionbuttons />{" "}
              </h1>
              {data}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-10">
            <div className="card">
              <h1>
                Company
                <span className="pl-3 mb-4">
                  <Button
                    id="createNewCompany"
                    tooltip="Create New Company"
                    tooltipOptions={{ position: "top" }}
                    type="button"
                    icon="pi pi-plus"
                    iconPos="right"
                    label="Add"
                    className="p-button-danger"
                    onClick={() => {
                      this.addCompany();
                    }}
                  />
                </span>
              </h1>
              <div className="p-grid">
                <div className="p-col-2 p-fluid">
                  <span className="boldLabel">View </span>
                  <Dropdown
                    options={this.status}
                    value={this.state.status}
                    onChange={(e: any) => {
                      this.setState({
                        status: e.target.value,
                        isLoading: true,
                      });
                      this.getCompanies(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="datatable-centerHeader datatable-centerContent">
                <DataTable
                  value={this.state.companies}
                  paginator={true}
                  rows={15}
                  autoLayout={true}
                  alwaysShowPaginator={false}
                  emptyMessage="No results found."
                >
                  {/* <Column body={this.logoTemplate} header="Logo" /> */}
                  <Column field="companyName" header="Name" />
                  <Column field="companyRegNum" header="Registration Number" />
                  {/* <Column  body={this.displayStatus} header="Status" /> */}
                  <Column
                    className="p-col-2"
                    body={this.actionsTemplate}
                    header="Actions"
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = form;
    }

    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
          }}
        />
      );
    }
    return (
      <>
        {display}
        <Dialog
          className="popupDialog"
          header={this.state.headerStr}
          visible={this.state.visible}
          modal={true}
          onHide={() => this.setState({ visible: false })}
          footer={
            <div>
              <Button
                label="Yes"
                type="button"
                iconPos="left"
                icon="pi pi-check"
                className="p-button-success"
                onClick={() => {
                  this.updateCompanyStatus();
                }}
              />
            </div>
          }
        >
          {this.state.dialogStr}
        </Dialog>
      </>
    );
  }
}

export class CompanyCreate extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  countryList: { label: string; value: string }[];
  industryList: { label: string; value: string }[];
  companyStatusList: any[];
  timezoneList: { label: string; value: string }[];
  companyService: CompanyService;
  groupCompanyService: GroupCompanyService;
  commonMethods: CommonMethods;
  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      groupCompanyObj: new GroupCompanyModel(),
      groupCompanies: [],
      companyObj: new CompanyObj(),
      redirectOut: false,
      redirectPath: "",
      companies: [],
      isLoading: true,

      status: "Active",
      countrySuggestions: [],
      submitType: "Create",

      statusToBeUpdated: "",
      headerStr: "",
      isError: false,
      errorMsg: "",

      visible: false,
      dialogStr: "",
      dialogHeaderStr: "",
      dialogIcon: "",
      dialogShowError: false,

      previewLogoImage: "",
      previewPayslipImage: "",

      myString: "",
      displayButton: false,
    };

    this.countryList = [];

    this.companyStatusList = [];

    this.timezoneList = [];

    this.companyService = new CompanyService();
    this.groupCompanyService = new GroupCompanyService();
    this.submitForm = this.submitForm.bind(this);
    this.commonMethods = new CommonMethods();
    this.industryList = this.commonMethods.getIndustryDropdownOptions();
    this.companyStatusList = this.commonMethods.getStatusSelectionDropdownList();
    this.timezoneList = this.commonMethods.getTimeZoneList();
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this.commonMethods
      .getCountryDropdown()
      .then((res) => (this.countryList = res))
      .then(() => this.forceUpdate());
    if (
      this.props.match.params !== {} &&
      this.props.match.params.id !== undefined
    ) {
      let idFromQuery = this.props.match.params.id;
      this.companyService
        .getCompanyById(idFromQuery)
        .then((res: CompanyObj) => {
          this.setState({
            companyObj: res,
            submitType: "Update",
            isLoading: false,
            previewLogoImage: res.companyLogo,
            previewPayslipImage: res.payslipLogo,
            displayButton: true,
          });
        })
        .catch((err) => {
          let errorMsg = this.commonMethods.getErrorMessage(err);
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
        });
    } else if (this.props.others === "onboarding") {
      this.companyService
        .getCompanyById(this.props.userProfile.companyID)
        .then((res: CompanyObj) => {
          this.setState(
            {
              companyObj: res,
              submitType: "Create",
              isLoading: false,
              previewLogoImage: res.companyLogo,
              previewPayslipImage: res.payslipLogo,
              displayButton: false,
            },
            () => {
              this.setDefaultTimeZone();
            }
          );
        });
    } else {
      this.setState({ isLoading: false, displayButton: true }, () => {
        this.setDefaultTimeZone();
      });
    }
  }

  setDefaultTimeZone() {
    this.setState({
      companyObj: {
        ...this.state.companyObj,
        timeZone: "8",
      },
    });
  }

  isValidated() {
    let registerNo = this.state.companyObj.companyRegNum;
    // let gstRegister = this.state.companyObj.gstRegNum;
    let address = this.state.companyObj.companyAddress;
    let phone = this.state.companyObj.phoneNumber;
    if (registerNo === "" || registerNo === undefined) {
      this.context.SetError("Company registration number cannot be empty");
      return false;
      // } else if (gstRegister === "" || gstRegister === undefined) {
      //   this.context.SetError("Company GST number cannot be empty");
      //   return false;
    } else if (address === "" || address === undefined) {
      this.context.SetError("Company address cannot be empty");
      return false;
    } else if (phone === "" || phone === undefined) {
      this.context.SetError("Company phone number cannot be empty");
      return false;
    } else {
      this.companyService
        .updateCompany(this.state.companyObj)
        .then((res: any) => {
          let msg = res.companyName + " has been created.";
          this.context.SetSuccess(msg);
          return true;
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.context.SetError(errorMsg);
          return false;
        });
    }
  }

  goBack() {
    this.setState({
      redirectPath: "/admin/company",
    });

    this.setState({ redirectOut: true });
  }

  submitForm(event: React.FormEvent) {
    event.preventDefault();

    if (this.state.submitType === "Create") {
      this.companyService
        .createCompany(this.state.companyObj)
        .then((res: CompanyObj) => {
          let msg = res.companyName + " has been created.";
          this.context.SetSuccess(msg);

          this.goBack();
        })
        .catch((error) => {
          // error dialog
          let errorMsg = this.commonMethods.getErrorMessage(error);

          this.context.SetError(errorMsg);

          this.setState({
            isLoading: false,
          });
        });
    } else if (this.state.submitType === "Update") {
      this.companyService
        .updateCompany(this.state.companyObj)
        .then((res: any) => {
          let msg = res.companyName + " has been updated.";
          this.context.SetSuccess(msg);
          // if updated company id = localstorage company id:
          if (
            this.state.companyObj.companyID ===
            localStorage.getItem("currentAccessingCompany")
          ) {
            this.commonMethods
              .getCompanyNameFromID(this.state.companyObj.companyID)
              .then((res) => {
                localStorage.setItem("currentAccessingCompanyName", res);
                this.goBack();
              });
          } else {
            this.goBack();
          }
        })
        .catch((error) => {
          // error dialog
          let errorMsg = this.commonMethods.getErrorMessage(error);

          this.context.SetError(errorMsg);

          this.setState({
            isLoading: false,
          });
        });
    }
  }

  setCompanyLogo(e: React.ChangeEvent<HTMLInputElement>) {
    if (
      e !== null &&
      e.currentTarget.files !== null &&
      e.currentTarget.files[0] !== undefined
    ) {
      const uploadedFile = e.currentTarget.files[0];
      CommonDocumentMethods.uploadImage(uploadedFile, 150, 30, "company")
        .then((res) => {
          this.setState({
            previewLogoImage: res.base64,
            companyObj: {
              ...this.state.companyObj,
              companyLogo: uploadedFile.name,
              companyLogoFile: res.file,
            },
          });
        })
        .catch((err: ProcessedImage) => {
          this.context.SetError(err.errorMsg);
        });
    } else {
      this.setState({
        companyObj: {
          ...this.state.companyObj,
          companyLogo: "",
          companyLogoFile: "",
        },
      });
    }
  }

  setPayslipImage(e: React.ChangeEvent<HTMLInputElement>) {
    if (
      e !== null &&
      e.currentTarget.files !== null &&
      e.currentTarget.files[0] !== undefined
    ) {
      const uploadedFile = e.currentTarget.files[0];
      CommonDocumentMethods.uploadImage(uploadedFile, 500, 500, "payslip")
        .then((res) => {
          this.setState({
            previewPayslipImage: res.base64,
            companyObj: {
              ...this.state.companyObj,
              payslipLogo: uploadedFile.name,
              payslipLogoFile: res.file,
            },
          });
        })
        .catch((err: ProcessedImage) => {
          this.context.SetError(err.errorMsg);
        });
    } else {
      this.setState({
        companyObj: {
          ...this.state.companyObj,
          payslipLogo: "",
          payslipLogoFile: "",
        },
      });
    }
  }

  render() {
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
          }}
        />
      );
    }

    let displayButton;
    if (this.state.displayButton) {
      displayButton = <Button type="submit" label={this.state.submitType} />;
    }

    let form = (
      <form onSubmit={this.submitForm}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">
              Company Name <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={this.state.companyObj.companyName}
              onChange={(e) =>
                this.setState({
                  companyObj: {
                    ...this.state.companyObj,
                    companyName: e.currentTarget.value,
                  },
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Company Status <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.companyObj.companyStatus}
              options={this.companyStatusList}
              onChange={(e) =>
                this.setState({
                  companyObj: {
                    ...this.state.companyObj,
                    companyStatus: e.value,
                  },
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Registration Number <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={this.state.companyObj.companyRegNum}
              onChange={(e) =>
                this.setState({
                  companyObj: {
                    ...this.state.companyObj,
                    companyRegNum: e.currentTarget.value,
                  },
                })
              }
              required
            />
          </div>
          {/* <div className="p-col-12">
            <span className="boldLabel">GST Registration Number</span>
            <InputText
              value={this.state.companyObj.gstRegNum}
              onChange={e =>
                this.setState({
                  companyObj: {
                    ...this.state.companyObj,
                    gstRegNum: e.currentTarget.value
                  }
                })
              }
              required
            />
          </div> */}
          <div className="p-col-12">
            <span className="boldLabel">
              Company Address <span style={{ color: "red" }}>*</span>
            </span>
            <InputTextarea
              rows={2}
              cols={30}
              value={this.state.companyObj.companyAddress}
              onChange={(e) =>
                this.setState({
                  companyObj: {
                    ...this.state.companyObj,
                    companyAddress: e.currentTarget.value,
                  },
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Postal Code <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={this.state.companyObj.postalCode}
              onChange={(e) =>
                this.setState({
                  companyObj: {
                    ...this.state.companyObj,
                    postalCode: e.currentTarget.value,
                  },
                })
              }
              required
              keyfilter="int"
              maxLength={6}
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Office Phone Number <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              keyfilter="int"
              value={
                this.state.companyObj.phoneNumber === null
                  ? ""
                  : this.state.companyObj.phoneNumber
              }
              onChange={(e) =>
                this.setState({
                  companyObj: {
                    ...this.state.companyObj,
                    phoneNumber: e.currentTarget.value,
                  },
                })
              }
              required
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Country <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.companyObj.country}
              options={this.countryList}
              onChange={(e) =>
                this.setState({
                  companyObj: {
                    ...this.state.companyObj,
                    country: e.value,
                  },
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Timezone <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.companyObj.timeZone}
              options={this.timezoneList}
              onChange={(e) =>
                this.setState({
                  companyObj: {
                    ...this.state.companyObj,
                    timeZone: e.value,
                  },
                })
              }
              placeholder="Please Select"
              required
            />
          </div>
        </div>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">Industry </span>
            <Dropdown
              value={this.state.companyObj.industry}
              options={this.industryList}
              placeholder="Please Select"
              onChange={(e) =>
                this.setState({
                  companyObj: {
                    ...this.state.companyObj,
                    industry: e.value,
                  },
                })
              }
            />
          </div>
          {/* <div className="p-col-12">
            <span className="boldLabel">Subdomain Name</span>
            <InputText
              value={this.state.companyObj.companyTSLOSlink}
              onChange={e =>
                this.setState({
                  companyObj: {
                    ...this.state.companyObj,
                    companyTSLOSlink: e.currentTarget.value
                  }
                })
              }
              required
            />
          </div> */}
          <div className="p-col-12">
            <p className="boldLabel">
              Company Logo (150x30){" "}
              <i
                className="fas fa-info-circle informationStyle"
                data-tip=""
                data-for="companylogo"
              ></i>{" "}
            </p>
            <ReactTooltip
              id="companylogo"
              aria-haspopup="true"
              className="tooltipFont"
            >
              <p>
                This is displayed under the employee's profile picture on the
                left side menu bar.{" "}
              </p>
            </ReactTooltip>
            {this.state.companyObj.companyLogo !== null &&
              this.state.companyObj.companyLogo !== "" && (
                <div
                  className="companyImageCropper"
                  hidden={
                    this.state.previewLogoImage === null ||
                    this.state.previewLogoImage === ""
                  }
                >
                  <img src={this.state.previewLogoImage} alt="" />
                </div>
              )}

            <input
              ref="file"
              style={{ color: "transparent", width: "110px" }}
              type="file"
              name="displayPicture"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => this.setCompanyLogo(e)}
            />
          </div>
          <div className="p-col-12">
            <p className="boldLabel">Payslip Logo </p>
            {this.state.companyObj.payslipLogo !== null &&
              this.state.companyObj.payslipLogo !== "" && (
                <div
                  className="companyImageCropper"
                  hidden={
                    this.state.previewPayslipImage === null ||
                    this.state.previewPayslipImage === ""
                  }
                >
                  <img src={this.state.previewPayslipImage} alt="" />
                </div>
              )}
            <input
              ref="file"
              style={{ color: "transparent", width: "110px" }}
              type="file"
              name="paysliplogo"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => this.setPayslipImage(e)}
            />
          </div>
        </div>

        <div className="p-grid p-justify-center">{displayButton}</div>
      </form>
    );
    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = (
        <div className="row p-justify-center">
          <div className="col-12">{form}</div>
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{this.state.submitType + " Company"}</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {display}
          </div>
        </div>
      </div>
    );
  }
}
