import React, { useState, useEffect } from "react";
import { RouteComponentProps, DropdownModel } from "../resources/CommonMethods";
import {
  LogisticsStatusTypeModel,
  LogisticsModel,
  LogisticsUpdateLogModel,
  LogisticsDocumentModel
} from "../resources/ExportClass";
import { LogisticsService } from "../../service/LogisticsService";
import { UserService } from "../../service/UserService";
import { ToastStateContext } from "../resources/ToastContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { Link } from "react-router-dom";

const userService = new UserService();
const logsService = new LogisticsService();
/**
 * Display the page for Creation of Logistics Status Type
 * e.g. Serialised, Reserved, Return, Faulty, Written Off, NA
 * @param props
 */
function EquipmentDetails(props: RouteComponentProps<any>) {
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  const [statusTypeList, setStatusTypeList] = useState<
    Array<LogisticsStatusTypeModel>
  >([]);
  const [userList, setUserList] = useState<Array<DropdownModel>>([]);
  const [equipmentObject, setEquipmentObject] = useState<LogisticsModel>(
    new LogisticsModel()
  );
  const [documentList, setDocumentList] = useState<
    Array<LogisticsDocumentModel>
  >([]);
  const [equipmentLog, setEquipmentLog] = useState<
    Array<LogisticsUpdateLogModel>
  >([]);

  // ComponentDidMount
  useEffect(() => {
    getUserList();
    getAllLogisticsStatusType();

  }, []);

  useEffect(()=>{
    if (userList.length > 0 && statusTypeList.length > 0) {
      getEquipmentBySerial(props.match.params.equipmentSerial);
    }
  },[userList, statusTypeList]);


  const getUserList = () => {
    userService.getUsersDropdown().then(res => {
      setUserList(res);
    });
  };

  const getAllLogisticsStatusType = () => {
    logsService.getAllLogisticsStatusType().then(res => {
      setStatusTypeList(res);
    });
  };

  const getEquipmentBySerial = (serialNo: string) => {
    logsService.getEquipmentBySerial(serialNo).then(res => {
      setEquipmentObject(res.equipInfo);
      setDocumentList(res.invoiceInfoList);
      setEquipmentLog(res.updateLog);

      setLoadingStatus(false);
    });
  };

  const getName = (equipmentObject: any, field: any) => {
    if (userList.length <= 0) {
      return;
    }
    var index = userList.findIndex((el: DropdownModel) => equipmentObject[field] === el.value);
    if (index >= 0) {
      return (
        <span> {userList[index].label}</span>
      );
    }
  };

  const displayName = (rowData: any, column: any) => {
    if (userList.length <= 0) {
      return;
    }
    var index = userList.findIndex((el: DropdownModel) => rowData[column.field] === el.value);
    if (index >= 0) {
      return (
        <span> {userList[index].label}</span>
      );
    }
  };

  const displayDate = (rowData: any, column: any) => {
    return new Date(rowData[column.field]).toString();
  };

  const displayLink = (rowData: any, column: any) => {
    return <Link to={rowData.uploadPath}>{rowData[column.field]}</Link>;
  };


  return (
    <>
    <div className="row">
      <div className="col-12">
      <Link to="/equipment" style={{ color: "black" }}>
              <i className="pi pi-angle-left" />
              Back
            </Link>
      </div>
    </div>
      <div className="row">
        <div className="col-12 col-xl-6">
          {isLoading ? (
            <ProgressSpinner />
          ) : (
            <>
              <div className="card">
                <h1>Equipment Information</h1>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5>{equipmentObject.equipmentName}</h5>
                      <p>{equipmentObject.equipmentSerialNo}</p>
                    </div>
                    <div className="col">
                      <h5>
                        <span className="boldLabel">Issued To: </span>
                        {getName(equipmentObject, "issuedTo")}
                      </h5>
                      <p>
                        <span className="boldLabel">Status: </span>
                        {
                          statusTypeList[
                            statusTypeList.findIndex(
                              (el: LogisticsStatusTypeModel) =>
                                equipmentObject.logisticsStatusTypeID ===
                                el.logisticsStatusTypeID
                            )
                          ].logisticsStatusTypeName
                        }
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <h5 className="col">Product Info</h5>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="m-0">
                        <span className="boldLabel">Brand: </span>
                        {equipmentObject.productBrand}
                      </p>
                      <p className="m-0">
                        <span className="boldLabel">Model: </span>
                        {equipmentObject.productModelNumber}
                      </p>
                      <p className="m-0">
                        <span className="boldLabel">Category: </span>
                        {equipmentObject.productCategory}
                      </p>
                      <p className="m-0">
                        <span className="boldLabel">Details: </span>
                        {equipmentObject.productDetail}
                      </p>
                    </div>
                    <div className="col">
                      <p className="m-0">
                        <span className="boldLabel">Unit Price: </span>
                        {"$"}
                        {equipmentObject.purchasedUnitPrice}
                      </p>
                      <p className="m-0">
                        <span className="boldLabel">Purchased Date: </span>
                        {new Date(equipmentObject.purchasedDate).toDateString()}
                      </p>
                      <p className="m-0">
                        <span className="boldLabel">Warranty Till: </span>
                        {new Date(
                          equipmentObject.warrantyExpiryDate
                        ).toDateString()}
                      </p>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-12 datatable-centerHeader datatable-centerContent">
                      <DataTable
                        value={documentList}
                        autoLayout={true}
                        paginator={true}
                        rows={5}
                        alwaysShowPaginator={false}
                      >
                        <Column field="uploadDate" header="Upload Date" />
                        <Column
                          field="remarks"
                          body={displayLink}
                          header="Invoice Name"
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <h3>Update Log</h3>
                <div className="card-body">
                  <div className="row datatable-centerHeader datatable-centerContent">
                    <DataTable
                      value={equipmentLog}
                      editMode="row"
                    >
                      <Column field="actionType" header="Action" />
                      <Column field="updatedContent" header="Updated Content" />
                      <Column
                        field="updatedBy"
                        body={displayName}
                        header="Updated By"
                      />
                      <Column
                        field="updatedOn"
                        body={displayDate}
                        header="Updated On"
                      />
                    </DataTable>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default EquipmentDetails;
