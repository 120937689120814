import { CommonMethods } from "../resources/CommonMethods";

export class CircularsDashboardModel {

  public announcementDocID: string = CommonMethods.EmptyGuid;
  public documentPath: string = "";
  public lastUpdatedDate: Date = new Date();
  
  public announcementID: string = CommonMethods.EmptyGuid;

  public announcer: string = CommonMethods.EmptyGuid;
  // public active: boolean = true;
  // public severity: string = "";
  public summary: string = "";
  public detail: string = "";
  // public appliedLevel: string ="";
  // public acknowledgedBy: string ="";
  // public createdTime: Date = new Date();
  // public groupCompanyID: string = CommonMethods.EmptyGuid;

  public uploadFile: any = null;
  public fullName: string = "";
  
  // 2020-16-12 PCLQ: HR Requested for Visibility Filter
  public visibleTo:string = ""; //Stores the GUID of the visibility
  public userRankName:string = ""; //Stores the string of user's rank visible to
}