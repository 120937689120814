import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router';
// import { CommentModel } from '../Model/CommentModel'
import { PostModel } from '../Model/PostModel'

export default function TimeStamp({ post }: { post: PostModel }) {
    const history = useHistory();

    // Add 8 hrs because CreatedTime saved in DB is GMT +0
    const longTimeStamp = moment(post.createdTime).add(8, 'hours').format('dddd, MMMM DD, YYYY [at] h:mm A')
    const fromNow = moment(post.createdTime).add(8, 'hours').fromNow()
    const formattedTime = moment(post.createdTime).add(8, 'hours').calendar({
        // nextDay: '[Tomorrow]',
        // nextWeek: 'dddd',
        sameDay: () => { return "[" + fromNow + "]" },
        lastDay: '[Yesterday]',
        lastWeek: 'MMMM DD [at] LT',
        sameElse: function () {
            if (moment(post.createdTime).add(8, 'hours').year() === moment().year()) {
                return 'MMMM D'
            } else {
                return 'MMMM D, YYYY';
            }
        },
    })

    function enterPost(post: PostModel) {
        history.push(`/companyfeed/post/${post.postId}`, { postId: post.postId })
    }

    return (
        <div className="timestamp cursor-underline position-relative" onClick={() => enterPost(post)}>
            {formattedTime}
            <span className="timestamp-tooltip">{longTimeStamp}</span>
        </div>
    )
}

