import { HttpClient } from "./HttpClient";
import { AccountingCodeModel } from "../components/accountingcode/AccountingCodeModel";

export class AccountingCodeService {
    httpClient: HttpClient;

    constructor() {
      this.httpClient = new HttpClient();
    }
    
    async getAllAccountingCodesByGCID() {
      const res = await this.httpClient.get("/api/accountingcode");
      return res;
    }

    async createAccountingCode(obj: AccountingCodeModel) {
      const res = await this.httpClient.post("/api/accountingcode", obj);
      return res;
    }

    async updateAccountingCode(obj: AccountingCodeModel) {
      const res = await this.httpClient.put("/api/accountingcode", obj);
      return res;
    }

    async deleteAccountingCode(ID: string) {
      const res = this.httpClient.delete("/api/accountingcode/" + ID);
      return res;
    }

    async getAccountingCodeByID(id: string) {
      const res = await this.httpClient.get("/api/accountingcode/" + id);
      return res;
    }
    
}
  
export default AccountingCodeService;
