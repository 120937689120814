import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ImageFile, PostModel } from '../../../Model/PostModel'
import CompanyFeedService from '../../../Service/CompanyFeedService'
import DragAndDropUpload from './DragAndDropUpload'
import placeholder from '../../../Assets/missing-image.jpg'
import Editor from '@draft-js-plugins/editor';
import PostDraftJsEditor from '../../DraftJsEditors/PostDraftJsEditor'
import { getContext, toTitleCase } from '../../../CompanyFeedFunctions'
import { NotificationsService } from '../../../../../service/NotificationsService'
import { ACTIONS } from '../../../DispatchActions'


export default function EditPost({
    setIsEditPost,
    post,
    isShowEditDialog,
    setIsShowEditDialog,
    contextName,
    parentComponent,
}: {
    setIsEditPost: React.Dispatch<React.SetStateAction<boolean>>;
    post: PostModel;
    isShowEditDialog: boolean
    setIsShowEditDialog: React.Dispatch<React.SetStateAction<boolean>>;
    contextName: string
    parentComponent?: string | undefined
}) {
    const { state, dispatch } = useContext(getContext(contextName));
    const [images, setImages] = useState<ImageFile[] | null>(null)
    const [imagePathArray, setImagePathArray] = useState<string[] | null>(null)
    const [isShowImagesDialog, setIsShowImagesDialog] = useState(false)
    const imageInput = useRef<HTMLInputElement>(null)

    const [rawEditorContentJsonStr, setRawEditorContentJsonStr] = useState<string | null>(null)
    const [usersTaggedArr, setUsersTaggedArr] = useState<null | string[]>(null)
    const editorRef = useRef<Editor>(null);
    // for update disabled button
    const [hasText, setHasText] = useState(false)
    const notifSvc = new NotificationsService();
    const [previouslyTaggedUsers, setPreviouslyTaggedUsers] = useState<string[]>([])

    useEffect(() => {
        if (post.usersTagged) setPreviouslyTaggedUsers(JSON.parse(post.usersTagged))
    }, [])

    useEffect(() => {
        console.log('previouslyTaggedUsers', previouslyTaggedUsers)
    }, [previouslyTaggedUsers])

    // close imageDialog and return to editPostDialog when last image is deleted
    useEffect(() => {
        if (imagePathArray == null && isShowImagesDialog === true) {
            setIsShowImagesDialog(false)
            setIsShowEditDialog(true)
        }
    }, [imagePathArray])

    useEffect(() => {
        setRawEditorContentJsonStr(post.rawEditorContentJsonStr)
        setImagePathArray(post.imagePathArray)
        // if (post.usersTagged) setUsersTaggedArr(JSON.parse(post.usersTagged))
    }, [])

    useEffect(() => {
        console.log('usersTaggedArr', usersTaggedArr)
    }, [usersTaggedArr])

    const handleUpdateSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        let updateFormData = new FormData();
        if (rawEditorContentJsonStr) updateFormData.append("RawEditorContentJsonStr", rawEditorContentJsonStr);
        if (usersTaggedArr) updateFormData.append("UsersTagged", JSON.stringify(usersTaggedArr));
        if (post.userId) updateFormData.append("UserId", post.userId);
        updateFormData.append("PostId", post.postId);
        // send over imagePathArray, remove item if startswith "blob"
        if (imagePathArray !== null) {
            let prevImages = imagePathArray.filter(item => !item.startsWith('blob'))
            console.log("prevImages without blob", prevImages)
            updateFormData.append("ImagePath", JSON.stringify(prevImages));
        }

        // handle existing imagePaths, send over remaining imagePaths before edit, don't include new blobpath
        if (images != null) {
            images.forEach((image: File) => {
                updateFormData.append("MultipleUploads", image)
            })
        }

        const companyFeedService = new CompanyFeedService();
        console.log('updateFormData', updateFormData)
        companyFeedService.editPost(updateFormData)
            .then((res) => {
                // console.log('axios response: ', res)
                if (parentComponent === "TodayBirthdayPost") dispatch({ type: ACTIONS.BIRTHDAY_POST.EDIT_POST, payload: res.data })
                else dispatch({ type: ACTIONS.EDIT_POST, payload: res.data })
                console.log("Update success ", res.data);
                // notifications
                if (usersTaggedArr) {
                    let notifObjArr = []
                    // exclude users previously tagged in comment.usersTagged
                    let usersToNotify = usersTaggedArr.filter((el) => !previouslyTaggedUsers.includes(el));
                    // don't notify sender if they tag themself
                    usersToNotify = usersToNotify.filter((el) => (el !== state.currentUser.userId))

                    console.log('usersTaggedArr', usersTaggedArr)
                    console.log('previouslyTaggedUsers', previouslyTaggedUsers)
                    console.log('usersToNotify', usersToNotify)

                    for (let i = 0; i < usersToNotify.length; i++) {
                        let notifObj = {
                            RecipientUserID: usersToNotify[i],
                            SenderUserID: state.currentUser.userId,
                            Url: `/companyfeed/post/${post.postId}`,
                            MsgHeader: "Tagged In Post",
                            Message: `${toTitleCase(state.currentUser.firstName) + " " + toTitleCase(state.currentUser.lastName)} has tagged you in a post`
                        }
                        notifObjArr.push(notifObj)
                        // console.log('notifObj create', notifObj)
                    }
                    notifSvc.createNotification(notifObjArr).then()
                }
            })
            .catch((err) => {
                console.log("Update Error:", err)
            });
        hideDialog()
    };

    function hideDialog() {
        setIsEditPost(false)
    }

    function hideImagesDialog() {
        setIsShowEditDialog(true)
        setIsShowImagesDialog(false)
    }

    function removeImage(path: string) {
        let filteredArray: string[] = []
        if (imagePathArray) {
            for (let i = 0; i < imagePathArray.length; i++) {
                if (imagePathArray[i] !== path) {
                    filteredArray.push(imagePathArray[i]);
                }
            }
            setImagePathArray(filteredArray.length > 0 ? filteredArray : null)
        }
        let filteredImages: ImageFile[] = []
        if (images) {
            for (let i = 0; i < images.length; i++) {
                if (images[i].objectURL !== path) {
                    filteredImages.push(images[i])
                }
            }
            setImages(filteredImages.length > 0 ? filteredImages : null)
        }
    }

    function openImageInput() {
        if (imageInput.current) {
            imageInput.current.click()
        }
    }

    function addImages(e: React.ChangeEvent<HTMLInputElement>) {
        let addedImages = e.target.files
        const imageArray: ImageFile[] = []
        const imagePaths: string[] = []
        if (addedImages) {
            for (let i = 0; i < addedImages.length; i++) {
                const image: ImageFile = addedImages[i]
                image.objectURL = URL.createObjectURL(image)
                imageArray.push(image)
                imagePaths.push(image.objectURL);
            }
            setImages(prev => prev === null ? imageArray : [...prev, ...imageArray])
            setImagePathArray(prev => prev === null ? imagePaths : [...prev, ...imagePaths])
        }
    }

    function onShowDialog() {
        if (editorRef.current) editorRef.current.focus()
    }

    return (
        <div className="edit-post-parent">
            <Dialog
                visible={isShowEditDialog}
                onHide={hideDialog}
                onShow={onShowDialog}
                className="rounded-border post-form-dialog"
                // Created custom header below, 
                // but for some reason if I put showHeader={false} it crashes, so I left it on true
                // Dialog Header is hidden using css
                showHeader={true}
                blockScroll
                dismissableMask={true}
            >
                <form onSubmit={handleUpdateSubmit}>
                    <div className="flex-column post-form">
                        <div className="header-postform">
                            <h1 className="postform-header-title">Edit Post</h1>
                            <span
                                className="material-icons md-36 postform-cancel"
                                onClick={hideDialog}
                            >close</span>
                        </div>
                        <PostDraftJsEditor
                            rawEditorContentJsonStr={rawEditorContentJsonStr}
                            setRawEditorContentJsonStr={setRawEditorContentJsonStr}
                            setUsersTaggedArr={setUsersTaggedArr}
                            editorRef={editorRef}
                            setHasText={setHasText}
                            postText={post.postText}
                            contextName={contextName}
                        />
                        <div className="position-relative">
                            <DragAndDropUpload
                                images={images}
                                setImages={setImages}
                                imagePathArray={imagePathArray}
                                setImagePathArray={setImagePathArray}
                                setIsShowEditDialog={setIsShowEditDialog}
                                setIsShowImagesDialog={setIsShowImagesDialog}
                            />
                            <button type="button" className="image-upload-button add-image-button" onClick={openImageInput}>
                                <span className="material-icons material-icon-align">add</span>
                                <span className="image-upload-button-text">Add</span>
                            </button>
                            <input
                                type="file"
                                multiple
                                accept="image/*"
                                style={{ display: "none" }}
                                ref={imageInput}
                                onChange={(e) => addImages(e)}
                            />
                        </div>
                        <Button
                            type="submit"
                            label="Update"
                            disabled={(!images && !hasText)}
                            className="postform-submit"
                        />
                    </div>
                </form>
            </Dialog>
            <Dialog
                visible={isShowImagesDialog}
                onHide={hideImagesDialog}
                className="rounded-border post-form-dialog post-form-image-dialog position-relative"
                showHeader={true}
                blockScroll
                dismissableMask={true}
            >
                <h1 className="textalign-center">Photos</h1>
                <ul className="images-dialog-ul-flex">
                    {imagePathArray?.map(item => {
                        return (
                            <li className="images-dialog-li" key={item}>
                                <img
                                    src={item}
                                    alt=""
                                    className="images-dialog-img"
                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => e.currentTarget.src = placeholder}
                                />
                                <span
                                    className="material-icons viewimages-remove"
                                    onClick={() => removeImage(item)}
                                >
                                    close
                                </span>
                            </li>
                        )
                    })}
                </ul>
                <span
                    className="material-icons image-dialog-back"
                    onClick={hideImagesDialog}
                >
                    keyboard_backspace
                        </span>
            </Dialog>
        </div>
    )
}

