import { LogisticsStatusTypeModel, NewLogisticsModel } from "../components/resources/ExportClass";
import { HttpClient } from "./HttpClient";
export class LogisticsService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }
  //    _                _      _    _          ___  _          _              _____
  //   | |    ___  __ _ (_) ___| |_ (_) __  ___/ __|| |_  __ _ | |_  _  _  ___|_   _|_  _  _ __  ___
  //   | |__ / _ \/ _` || |(_-<|  _|| |/ _|(_-<\__ \|  _|/ _` ||  _|| || |(_-<  | | | || || '_ \/ -_)
  //   |____|\___/\__, ||_|/__/ \__||_|\__|/__/|___/ \__|\__,_| \__| \_,_|/__/  |_|  \_, || .__/\___|
  //              |___/                                                              |__/ |_|

  /**
   * [POST REQUEST] Add New Status Type
   * @param {string} statusName
   */
  async addLogisticsStatusType(statusTypeObject: LogisticsStatusTypeModel) {
    const res = await this.httpClient.post(
      "/api/logistics/status/add",
      statusTypeObject
    );
    return res;
  }

  /**
   * [GET REQUEST] Get All the Status Type
   * @return Array<object> LogisticsStatusTypeModel
   */
  async getAllLogisticsStatusType() {
    const res = await this.httpClient.get("/api/logistics/status/all");
    return res;
  }

  /**
   * [PUT REQUEST] Update Status Type
   * @param {LogisticsStatusTypeModel} statusTypeObject
   */
  async UpdateLogisticsStatusType(statusTypeObject: LogisticsStatusTypeModel) {
    const res = await this.httpClient.put(
      "/api/logistics/status/update",
      statusTypeObject
    );
    return res;
  }

  /**
   * [DELETE REQUEST] Delete Status Type
   * @param {string} statusTypeID
   */
  async DeleteLogisticsStatusType(statusTypeID: string) {
    const res = await this.httpClient.delete(
      "/api/logistics/status/delete/" + statusTypeID
    );
    return res;
  }

  async batchUpdateEquipment(type:string, model:any){
    const res = await this.httpClient.post(
      `/api/logistics/${type}/batchupdate`, model
    );
    return res;
  }

  
//    ___              _                         _       
//   | __| __ _  _  _ (_) _ __  _ __   ___  _ _ | |_  ___
//   | _| / _` || || || || '_ \| '  \ / -_)| ' \|  _|(_-<
//   |___|\__, | \_,_||_|| .__/|_|_|_|\___||_||_|\__|/__/
//           |_|         |_|                             

  
    /**
   * [GET REQUEST] Get All Equipment as List
   * @return Array<object> LogisticsModel
   */
  async getDropdownAssignees(groupID:string) {
    const res = await this.httpClient.get("/api/dropdown/logs/assignto/"+groupID);
    return res;
  }


/**
   * [POST REQUEST] Add New Equipment
   * @param {NewLogisticsModel} equipObj
   */
  async addNewEquipment(equipObj: any) {
    var form_data = new FormData();

    for (var key in equipObj) {
      if( equipObj[key] !== null){
        form_data.append(key, equipObj[key]);
      }
    }
    const res = await this.httpClient.post(
      "/api/logistics/add",
      form_data
    );
    return res;
  }

    /**
   * [GET REQUEST] Get All Equipment as List
   * @return Array<object> LogisticsModel
   */
  async getAllEquipmentList() {
    const res = await this.httpClient.get("/api/logistics/all");
    return res;
  }

    /**
   * [GET REQUEST] Get Equipment By Their Serial Number
   * @param  {string} serialNo
   * @return Array<object> LogisticsModel
   */
  async getEquipmentBySerial(serialNo:string) {
    const res = await this.httpClient.get("/api/logistics/item/serial/"+serialNo);
    return res;
  }

    /**
   * [GET REQUEST] Get Equipment By Their LogisticsID
   * @param  {string} logsID
   * @return Array<object> LogisticsModel
   */
  async getEquipmentByLogsId(logsID:string) {
    const res = await this.httpClient.get("/api/logistics/item/id/"+logsID);
    return res;
  }

    /**
   * [PUT REQUEST] Add New Equipment
   * @param {NewLogisticsModel} equipObj
   */
  async updateEquipment(equipObj: any) {
    var form_data = new FormData();

    for (var key in equipObj) {
      if( equipObj[key] !== null){
        form_data.append(key, equipObj[key]);
      }
    }
    
    const res = await this.httpClient.put(
      "/api/logistics/update",
      form_data
    );
    return res;
  }

      /**
   * [DELETE REQUEST] Get Equipment By Their LogisticsID
   * @param  {string} logsID
   * @return Array<object> LogisticsModel
   */
  async deleteEquipmentByLogsId(logsID:string) {
    const res = await this.httpClient.delete("/api/logistics/delete/"+logsID);
    return res;
  }





  async getAllLogisticsCategories() {
    let res = await this.httpClient.get("/api/logisticscategory/all");
    return res;
  }

}
