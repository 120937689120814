import React, { useContext, useState } from 'react'
import ReactLinkify from 'react-linkify';
import AboutDialog from './AboutDialog';
import { PublicProfileContext } from './PublicProfile';

export default function About() {
    const { state } = useContext(PublicProfileContext);
    const [isShowDialog, setIsShowDialog] = useState(false)

    function editAbout() {
        setIsShowDialog(true)
    }

    if (state.userProfile.aboutMe == null && !state.userProfile.isEditable) return null

    return (
        <div className="position-relative background-white">
            <div className="profile-card-title">About</div>
            <div className="profile-card-content">
                <AboutMeText aboutMe={state.userProfile.aboutMe} />
            </div>
            {state.userProfile.isEditable ?
                <div onClick={editAbout}>
                    <span className="material-icons-outlined profile-card-edit-button">edit</span>
                </div>
                : null}
            <AboutDialog
                header="Edit About"
                isShowDialog={isShowDialog}
                setIsShowDialog={setIsShowDialog}
            />
        </div>
    )
}



function AboutMeText({
    aboutMe,
}: {
    aboutMe: string | null
}) {
    const [showAll, setShowAll] = useState(false)
    function toggleReadMore() {
        setShowAll(true)
    }

    const componentDecorator = (href: string, text: string, key: number) => (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );

    if (aboutMe == null) return null
    if (aboutMe.length < 250 || showAll) {
        return (
            <div className="whitespace-prewrap">
                <ReactLinkify componentDecorator={componentDecorator}>{aboutMe}</ReactLinkify>
            </div>
        )
    }
    if (aboutMe.length >= 250) {
        const splitText = aboutMe.substring(0, 250)
        return (
            <div className="whitespace-prewrap">
                <ReactLinkify componentDecorator={componentDecorator}>{splitText + "... "}</ReactLinkify>
                <div className="read-more-text" onClick={toggleReadMore}>Read more</div>
            </div>
        )
    }
    return null
}
