import React, { useEffect, useRef, useState } from 'react'
import { PostModel } from '../../Model/PostModel';
import CompanyFeedService from '../../Service/CompanyFeedService';
import heart from './LottieFiles/heart-coloured.json'
import lottie, { AnimationItem } from 'lottie-web'
import { ACTIONS } from '../../DispatchActions';

export default function LikeButton({
    item,
    currentUser,
    dispatch,
    parentComponent,
}: {
    item: PostModel;
    currentUser: string;
    dispatch: React.Dispatch<{
        type: any;
        payload: any;
    }>
    parentComponent: string | undefined
}) {
    const heartRef = useRef<HTMLDivElement>(null)
    const [anim, setAnim] = useState<AnimationItem | null>(null)
    // used to toggle between like/unlike
    const [isLikedByCurrUser, setIsLikedByCurrUser] = useState(false)
    // determines starting point of heart animation
    const [wasLikedByCurrUser, setWasLikedByCurrUser] = useState(false)

    useEffect(() => {
        if (heartRef.current) {
            setAnim(lottie.loadAnimation({
                container: heartRef.current,
                renderer: "svg",
                loop: false,
                autoplay: false,
                animationData: heart,
            }))
        }
    }, []);

    useEffect(() => {
        // 1. check that usersWhoLiked contains someone,
        // 2. wait for currentUser to load first if not includes('') will be always true
        // 3. check if currentUser is inside usersWhoLikedArray
        if (item.usersWhoLiked &&
            (currentUser.length > 0) &&
            item.usersWhoLikedArray &&
            item.usersWhoLikedArray.includes(currentUser)
        ) {
            setIsLikedByCurrUser(true)
            setWasLikedByCurrUser(true)
        }
    }, [currentUser])

    // set initial look of heart (starting point of animation) 
    // Animation doesn't seem to work right when toggling isLikedByCurrUser state
    // hence use of wasLikedByCurrUser state
    useEffect(() => {
        if (wasLikedByCurrUser && anim) {
            anim.goToAndStop(110, true)
        }
    }, [wasLikedByCurrUser, anim])

    async function toggleLike(postId: string) {
        const companyFeedService = new CompanyFeedService()
        setIsLikedByCurrUser(prev => !prev)
        if (!isLikedByCurrUser && anim) {
            if (parentComponent === "TodayBirthdayPost")
                dispatch({ type: ACTIONS.BIRTHDAY_POST.ADD_LIKE, payload: { postId, userId: currentUser } })
            else
                dispatch({ type: ACTIONS.ADD_LIKE, payload: { postId, userId: currentUser } })
            anim.playSegments([58, 110], true);
            await companyFeedService.likePost(postId)
        }
        if (isLikedByCurrUser && anim) {
            if (parentComponent === "TodayBirthdayPost")
                dispatch({ type: ACTIONS.BIRTHDAY_POST.REMOVE_LIKE, payload: { postId, userId: currentUser } })
            else
                dispatch({ type: ACTIONS.REMOVE_LIKE, payload: { postId, userId: currentUser } })
            anim.playSegments([64, 58], true);
            await companyFeedService.unlikePost(postId)
        }
    }

    return (
        <div id="toggle-like" onClick={() => toggleLike(item.postId)}>
            <div id="heart-animation" ref={heartRef}></div>
        </div>
    );
}