import { HttpClient } from "./HttpClient";
import { DeliverableTypeModel } from "../components/deliverable/DeliverableTypeModel";
export class DeliverableTypeService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async createDeliverableType(deliverableObj: DeliverableTypeModel) {
    const res = await this.httpClient.post(
      "/api/deliverabletype/create",
      deliverableObj
    );
    return res;
  }

  async getAllDeliverableTypesByGCID() {
    const res = await this.httpClient.get("/api/deliverabletype/gcid");
    return res;
  }

  async getDeliverableTypeByID(id: string) {
    const res = await this.httpClient.get("/api/deliverabletype/id/" + id);
    return res;
  }

  async deleteDeliverableTypeByID(id: string) {
    const res = await this.httpClient.delete("/api/deliverabletype/id/" + id);
    return res;
  }
  async updateDeliverableType(deliverableObj: DeliverableTypeModel) {
    const res = await this.httpClient.post(
      "/api/deliverabletype/update",
      deliverableObj
    );
    return res;
  }

  async deactivateDeliverableType(ID: string) {
    const res = this.httpClient.get("/api/deliverabletype/deactivate/" + ID);
    return res;
  }
  async activateDeliverableType(ID: string) {
    const res = this.httpClient.get("/api/deliverabletype/activate/" + ID);
    return res;
  }
  async deleteDeliverableType(ID: string) {
    const res = this.httpClient.get("/api/deliverabletype/delete/" + ID);
    return res;
  }

  async updateDeliverableTypeStatus(deliverableObj: DeliverableTypeModel) {
    const res = this.httpClient.put(
      "/api/deliverabletype/updatestatus",
      deliverableObj
    );
    return res;
  }
}

export default DeliverableTypeService;
