import * as signalR from "@aspnet/signalr";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { ProgressBar } from "primereact/progressbar";
import { ScrollPanel } from "primereact/scrollpanel";
import { Sidebar } from "primereact/sidebar";
import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "react-router";
import ReactTooltip from "react-tooltip";
import SidebarNotifications from "./components/notifications/SidebarNotifications";
import {
  AccessModel,
  CommonMethods,
} from "./components/resources/CommonMethods";
import { ToastStateContext } from "./components/resources/ToastContext";
import { AnnouncementService } from "./service/AnnouncementService";
import { AppService } from "./service/AppService";
import { LoginService } from "./service/LoginService";
import { NotificationsService } from "./service/NotificationsService";
import { UserService } from "./service/UserService";
import { UserModel } from "./components/user/User";

interface AppProps {
  onToggleMenu: any;
}

interface AppTopData {
  announcements: [];
  notifications: [];
  access: AccessModel;
  displaySetup: boolean;
  setup: [];
  user: UserModel;
}
interface AppState {
  notificationVisible: boolean;
  notifications: any[];
  announcements: any[];
  user: any;
  redirectOut: boolean;
  redirectPath: string;
  displayTopBarIcons: boolean;
  displaySetupGuide: boolean;
  setupProgressValue: number;
  noOfTasksCompleted: number;
}

export class AppTopbar extends React.Component<AppProps, AppState> {
  static defaultProps = {
    onToggleMenu: null,
  };

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
  };
  op: any;
  opSetup: any;
  static contextType = ToastStateContext;

  announcementService: AnnouncementService;
  notificationsService: NotificationsService;
  commonMethods: CommonMethods;
  loginService: LoginService;
  userService: UserService;
  appService: AppService;
  connection: any;

  constructor(props: AppProps) {
    super(props);
    this.state = {
      notificationVisible: false,
      notifications: [],
      announcements: [],
      user: {},
      redirectOut: false,
      redirectPath: "",
      displayTopBarIcons: false,
      displaySetupGuide: false,
      setupProgressValue: 0,
      noOfTasksCompleted: 0,
    };
    this.announcementService = new AnnouncementService();
    this.notificationsService = new NotificationsService();
    this.loginService = new LoginService();
    this.userService = new UserService();
    this.appService = new AppService();
    this.commonMethods = new CommonMethods();
    this.logout = this.logout.bind(this);
    // this.email = this.email.bind(this);
  }

  componentDidMount() {
    this.init();
    this.setWebSocket();
  }

  setWebSocket() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl("/hub")
      .build();
    this.connection.on("messageReceived", (notif: any) => {
      // this.context.SetSuccess(notif.body); // growl
      new Notification(notif.title, notif); //desktop notification
      this.getNotifications();
    });

    this.connection
      .start()
      .then(() => {
        this.connection.send("addToGroup");
      })
      .catch((err: any) => {
        console.log("notification hub error: ", err);
      });
  }

  getNotifications() {
    this.notificationsService
      .getUnreadNotifications()
      .then((res) => {
        this.setState({ notifications: res });
      })
      .catch((err) => {
        // let errMsg = this.commonMethods.getErrorMessage(err);
        this.context.SetError("Fail to get unread notifications");
      });
  }

  readAllNotifications() {
    this.notificationsService
      .readAllNotifications()
      .then((res) => {
        this.setState({ notifications: [] });
      })
      .catch((err) => {
        this.context.SetError(this.commonMethods.getErrorMessage(err));
      });
  }

  init() {
    this.appService
      .getAppTopBar()
      .then((res: AppTopData) => {
        // topbaricons are hidden for onboarding pages
        if (!res.access.onboardingComplete) {
          this.setState({ redirectPath: "/onboarding", redirectOut: true });
        } else {
          this.setState({ displayTopBarIcons: true });
        }
        if (res.displaySetup) {
          this.checkSetupProgress(res.setup);
        }
        this.setState({
          announcements: res.announcements,
          notifications: res.notifications,
          user: res.user,
        });
      })
      .catch((err) => {
        this.context.SetError(this.commonMethods.getErrorMessage(err));
      });
  }

  checkSetupProgress(res: string[]) {
    try {
      if (res.length < 4) {
        res.forEach((completedTask: string) => {
          const myElement = document.getElementById(completedTask);
          if (completedTask === "User") {
            completedTask = "Employee";
          }
          if (myElement) {
            myElement.setAttribute(
              "style",
              "background-color: lightgrey; color: white; border-color: transparent; margin-bottom: 2px"
            );
            myElement.innerHTML =
              '<span><span class="pi pi-check-circle"></span>Add ' +
              completedTask +
              "</span>";
          }
        });
        this.setState({
          displaySetupGuide: true,
          setupProgressValue: Math.round((res.length / 4) * 100),
          noOfTasksCompleted: res.length,
        });
      }
    } catch (e) {}
  }

  logout() {
    this.loginService.logout().then(() => {
      window.location.replace("/");
    });
  }

  render() {
    let announcementsBadge;
    let notificationsBadge;
    if (this.state.announcements.length !== 0) {
      announcementsBadge = (
        <span className="layout-topbar-badge">
          {this.state.announcements.length}
        </span>
      );
    } else {
      announcementsBadge = <></>;
    }
    if (this.state.notifications.length !== 0) {
      notificationsBadge = (
        <span className="layout-topbar-badge">
          {this.state.notifications.length}
        </span>
      );
    } else {
      notificationsBadge = <></>;
    }
    let setupProgressButton;
    if (this.state.displaySetupGuide) {
      setupProgressButton = (
        <button
          id="setupProgressGuide"
          className="p-link mx-1"
          onClick={(e) => {
            this.opSetup.toggle(e);
          }}
        >
          <span className="layout-topbar-item-text">Set Up</span>
          <span
            className="layout-topbar-icon setup-progress pi pi-exclamation-triangle"
            data-tour-id="set-up-icon"
            data-tour="Finish setting up your company details with this nifty guide!"
            data-tip=""
            data-for="setupitem"
          />
          <ReactTooltip
            id="setupitem"
            aria-haspopup="true"
            className="tooltipFont"
            place="bottom"
          >
            Setup Guide
          </ReactTooltip>
        </button>
      );
    } else {
      setupProgressButton = <></>;
    }
    let setUpGuide = (
      <>
        <div className="card" id="Department" style={{ marginBottom: "2px" }}>
          <a href="/admin/department/create">
            <span className="pi pi-circle-off" />
            Add Department
            <span className="pi pi-angle-right" />
          </a>
        </div>
        <div className="card" id="JobTitle" style={{ marginBottom: "2px" }}>
          <a href="/admin/job_title/create">
            <span className="pi pi-circle-off" />
            Add Job Title
            <span className="pi pi-angle-right" />
          </a>
        </div>
        <div className="card" id="User" style={{ marginBottom: "2px" }}>
          <a href="/admin/employees/create">
            <span className="pi pi-circle-off" />
            Add Employee
            <span className="pi pi-angle-right" />
          </a>
        </div>
        <div className="card" id="Contract" style={{ marginBottom: "2px" }}>
          <a href="/admin/contract/create">
            <span className="pi pi-circle-off" />
            Add Contract
            <span className="pi pi-angle-right" />
          </a>
        </div>
      </>
    );

    let topbariconsDisplay;
    if (this.state.displayTopBarIcons) {
      topbariconsDisplay = (
        <div className="layout-topbar-icons">
          {setupProgressButton}

          <button
            className="p-link"
            onClick={() => {
              this.context.SetTour(true);
            }}
          >
            <span className="layout-topbar-item-text">Help</span>
            <span
              className="layout-topbar-icon pi pi-question"
              data-tip=""
              data-for="helpitem"
            />
            <ReactTooltip
              id="helpitem"
              aria-haspopup="true"
              className="tooltipFont"
              place="bottom"
            >
              Help
            </ReactTooltip>
          </button>

          <a href="/announcements" rel="noopener noreferrer">
            <button className="p-link mx-1">
              <span className="layout-topbar-item-text">Announcements</span>

              <span
                className="layout-topbar-icon pi pi-bell"
                data-tip=""
                data-for="announcementitem"
                data-tour-id="announcement-icon"
                data-tour="Click here to view announcements."
              />
              {announcementsBadge}
              <ReactTooltip
                id="announcementitem"
                aria-haspopup="true"
                className="tooltipFont"
                place="bottom"
              >
                Announcements
              </ReactTooltip>
            </button>
          </a>

          <a href="/companyfeed" rel="noopener noreferrer">
            <button className="p-link mx-1">
              <span className="layout-topbar-item-text">Company Feed</span>

              <span
                className="layout-topbar-icon pi pi-comments"
                data-tip=""
                data-for="companyfeeditem"
              />
              <ReactTooltip
                id="companyfeeditem"
                aria-haspopup="true"
                className="tooltipFont"
                place="bottom"
              >
                Company Feed
              </ReactTooltip>
            </button>
          </a>


          <button
            className="p-link mr-3 mx-2"
            onClick={() => {
              this.setState({ notificationVisible: true });
            }}
          >
            <span className="layout-topbar-item-text">Notifications</span>
            <span
              className="layout-topbar-icon pi pi-inbox"
              data-tour-id="notifications-icon"
              data-tour="Click here to view notifications."
              data-tip=""
              data-for="notifitem"
              // style={{ fontSize: "2.3em" }}
            />
            {notificationsBadge}
            <ReactTooltip
              id="notifitem"
              aria-haspopup="true"
              className="tooltipFont"
              place="bottom"
            >
              Notifications
            </ReactTooltip>
          </button>
        </div>
      );
    }

    return (
      <div>
        {this.state.redirectOut ? (
          <Redirect
            push
            to={{
              pathname: this.state.redirectPath,
            }}
          />
        ) : null}
        <div
          id="nav-topbar"
          className="layout-topbar clearfix d-flex align-items-center justify-content-between"
        >
          <button
            className="p-link layout-menu-button mx-3 mr-auto"
            onClick={this.props.onToggleMenu}
          >
            <span className="pi pi-bars" />
          </button>
          <div>
            <div className="d-flex align-items-center justify-content-between">
              {topbariconsDisplay}
              <span className="pointer" onClick={(e) => this.op.toggle(e)}>
                <img
                  alt="displayPic"
                  onClick={(e) => this.op.toggle(e)}
                  src={this.state.user.displayPicture}
                  style={{
                    width: "2.6em",
                    height: "2.6em",
                    objectFit: "cover",
                    borderRadius: "50%",
                    border: "2px solid white",
                  }}
                  data-tour-id="profile-picture"
                  data-tour="Click here to view and edit your profile."
                />
              </span>
              <span className="pi pi-fw pi-angle-down menuitem-toggle-icon mr-3"></span>
            </div>
          </div>
        </div>

        <Sidebar
          visible={this.state.notificationVisible}
          position="right"
          modal={false}
          showCloseIcon={false}
          onHide={() => this.setState({ notificationVisible: false })}
        >
          <div className="p-grid p-align-center">
            <div className="p-col">
              <h1>Notifications</h1>
            </div>
            <div className="p-col">
              <Button
                className="p-button-danger p-button-rounded float-right"
                icon="pi pi-times"
                onClick={() => {
                  this.setState({ notificationVisible: false });
                }}
              />
            </div>
          </div>
          {this.state.notifications.length > 0 ? (
            <Button
              className="btn btn-primary btn-lg btn-block mb-4"
              label="Clear All"
              onClick={() => this.readAllNotifications()}
            />
          ) : (
            <div className="text-center my-5 ">
              <p>You have no outstanding notifications.</p>
              <img
                src="https://img.icons8.com/ultraviolet/80/000000/appointment-reminders.png"
                alt="noNotifications"
              />
            </div>
          )}

          <ScrollPanel style={{ width: "100%", height: "90%" }}>
            <div>
              {this.state.notifications.map((row) => (
                <SidebarNotifications
                  key={row.notificationID}
                  header={row.msgHeader}
                  content={row.message}
                  url={row.url}
                  notificationID={row.notificationID}
                />
              ))}
            </div>
          </ScrollPanel>
        </Sidebar>
        <OverlayPanel ref={(el) => (this.op = el)}>
          <div className="row text-center">
            <div className="col-12">
              <img
                alt="displayPicture"
                src={this.state.user.displayPicture}
                style={{
                  height: "8rem",
                  width: "8rem",
                  borderRadius: "50%",
                  objectFit: "cover",
                  border: "2px solid lightgray",
                }}
              />
              <p className="mt-1">
                {this.state.user.firstName} {this.state.user.lastName}
              </p>
            </div>
          </div>
          <div className="row border-top text-center">
            <div className="col-12 my-2">
              <a href={"/publicprofile/" + this.state.user.userID} rel="noopener noreferrer">
                My Public Profile
              </a>
            </div>
          </div>
          <div className="row border-top text-center">
            <div className="col-12 my-2">
              <a href="/settings/changepassword" rel="noopener noreferrer">
                Account Setting
              </a>
            </div>
          </div>
          <div className="row border-top text-center">
            <div className="col-12 mt-2">
              <Button
                className="btn-block"
                onClick={this.logout}
                label="Logout"
              />
            </div>
          </div>
        </OverlayPanel>
        <OverlayPanel ref={(el) => (this.opSetup = el)}>
          <b>Set Up Your Company!</b>
          <p />
          {this.state.noOfTasksCompleted}/4 Completed
          <ProgressBar
            value={this.state.setupProgressValue}
            style={{ height: "3px" }}
          />
          <br />
          {setUpGuide}
        </OverlayPanel>
      </div>
    );
  }
}
