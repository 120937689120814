import React, { useContext, useState } from 'react'
import InterestsHobbiesDialog from './InterestsHobbiesDialog';
import { PublicProfileContext } from './PublicProfile';

export default function InterestsHobbies() {
    const { state, dispatch } = useContext(PublicProfileContext);
    const [isShowDialog, setIsShowDialog] = useState(false)

    function editInterestsHobbies() {
        setIsShowDialog(true)
    }

    if (state.userProfile.interestsHobbies == null && !state.userProfile.isEditable) return null

    return (
        <div className="position-relative background-white">
            <div className="profile-card-title">Interests &amp; Hobbies</div>
            <div className="profile-card-content"><InterestsHobbiesContent interestsHobbies={state.userProfile.interestsHobbies} /></div>
            {state.userProfile.isEditable ?
                <div onClick={editInterestsHobbies}>
                    <span className="material-icons-outlined profile-card-edit-button">edit</span>
                </div>
            : null}
            <InterestsHobbiesDialog
                header="Edit Interests &amp; Hobbies"
                isShowDialog={isShowDialog}
                setIsShowDialog={setIsShowDialog}
            />
        </div>
    )
}


    

    function InterestsHobbiesContent({interestsHobbies}: {interestsHobbies: string | null}) {
        const [showAll, setShowAll] = useState(false)

        function toggleReadMore() {
            setShowAll(true)
        }

        if (interestsHobbies == null) return null
        if (interestsHobbies.length < 250 || showAll) {
            return (
                <div className="whitespace-prewrap">{interestsHobbies}</div>
            )
        }
        if (interestsHobbies.length >= 250) {
            const splitText = interestsHobbies.substring(0, 250)
            console.log('splittext', splitText)
            return (
                <div className="whitespace-prewrap">
                    {splitText + "... "}
                    <div className="read-more-text" onClick={toggleReadMore}>Read more</div>
                </div>
            )
        }
        return null
    }