import Axios from "axios";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import * as React from "react";
import { Redirect } from "react-router";
import { SalesService } from "../../service/SalesService";
import ClientContactCreate from "../client/ClientContactCreate";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ClientContactsModel, SalesClientsModel } from "../resources/ExportClass";
import { ToastStateContext } from "../resources/ToastContext";
import { UserModel } from "../user/User";


export class ClientContactsCompany {
  public salesClient: SalesClientsModel = new SalesClientsModel();
  public clientContacts: ClientContactsModel = new ClientContactsModel();
}
interface AppState {
  salesProfile: UserModel;
  salesClient: SalesClientsModel;
  clientContact: ClientContactsModel;

  salesClientInitial: SalesClientsModel;
  clientContactInitial: ClientContactsModel;

  clientCompanies: { label: string; value: string }[];

  isLoading: boolean;
  contactEditing: boolean;
  leadStatusEditing: boolean;

  isError: boolean;
  errorMsg: string;

  redirectOut: boolean;
  redirectPath: string;
}

export class ClientDetails extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;
  commonMethods: CommonMethods;
  salesService: SalesService;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      salesProfile: new UserModel(),
      salesClient: new SalesClientsModel(),
      clientContact: new ClientContactsModel(),

      salesClientInitial: new SalesClientsModel(),
      clientContactInitial: new ClientContactsModel(),

      clientCompanies: [],

      isLoading: true,
      contactEditing: false,
      leadStatusEditing: false,

      isError: false,
      errorMsg: "",

      redirectOut: false,
      redirectPath: ""
    };
    this.commonMethods = new CommonMethods();
    this.salesService = new SalesService();

    this.saveClientContact = this.saveClientContact.bind(this);
    this.saveLeadStatus = this.saveLeadStatus.bind(this);
  }

  componentDidMount() {
    if (this.commonMethods.isPropsLoaded(this.props)) {
      this.retrieveList(this.props.userProfile.userID);
    }
  }

  componentDidUpdate() {}

  retrieveList(userID: string) {
    Axios.all([
      this.commonMethods.getClientDropdown(),
      this.salesService.getClientOnlyInfo(this.props.match.params.id)
    ])
      .then(
        Axios.spread((dropdown: any, client: ClientContactsCompany) => {
          this.setState({
            clientCompanies: dropdown,
            salesClient: client.salesClient,
            clientContact: client.clientContacts,
            isLoading: false
          });
        })
      )
      .catch(err => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({ isError: true, isLoading: false, errorMsg: errorMsg });
      });
  }

  saveClientContact() {
    this.salesService
      .updateClientcontact(this.state.clientContact)
      .then((res: ClientContactsModel) => {
        this.setState({
          contactEditing: false,
          clientContact: res,
          clientContactInitial: res
        });
        let msg = "Client's contact has been updated!";
        this.context.SetSuccess(msg);
      })
      .catch(err => {
        let msg = "Client's contact cannot be updated!";
        this.context.SetError(msg);
      });
  }
  saveLeadStatus() {
    this.salesService
      .updateSalesClient(this.state.salesClient)
      .then(res => {
        this.setState({
          leadStatusEditing: false,
          salesClient: res,
          salesClientInitial: res
        });
        let msg = "Sales Lead has been updated!";
        this.context.SetSuccess(msg);
      })
      .catch(err => {
        let msg = "Sales Lead cannot be updated!";
        this.context.SetError(msg);
      });
  }

  render() {
    const leadStatusDropdown = this.commonMethods.getLeadStatusDropdown();

    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath
          }}
        />
      );
    }

    let updateSalesLeads = (
      <div className="card">
        <h1 className="pb-2">Update Sales Lead</h1>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">Lead Status</span>
            <Dropdown
              placeholder={"Lead Status"}
              options={leadStatusDropdown}
              value={this.state.salesClient.leadsStatus}
              onChange={e => {
                this.setState({
                  salesClient: {
                    ...this.state.salesClient,
                    leadsStatus: e.value
                  }
                });
              }}
              disabled={true}
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Meeting Date</span>
            <Calendar
              showButtonBar={true}
              selectionMode="single"
              todayButtonClassName="p-justify-center"
              
              value={new Date(this.state.salesClient.startDate)}
              onChange={e => {
                this.setState({
                  salesClient: {
                    ...this.state.salesClient,
                    startDate: new Date(e.value.toString())
                  }
                });
              }}
              inline={false}
              disabled={true}
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Remarks</span>
            <InputTextarea
              rows={5}
              cols={30}
              value={this.state.salesClient.remarks}
              onChange={e =>
                this.setState({
                  salesClient: {
                    ...this.state.salesClient,
                    remarks: e.currentTarget.value
                  }
                })
              }
              autoResize={true}
              // disabled={!this.state.leadStatusEditing}
            />
          </div>
        </div>
        <div className="p-grid p-justify-center mt-2">
          <Button
            label={"Update"}
            type="submit"
            className="mx-1"
            onClick={e => {
              this.saveLeadStatus();
              this.setState({
                redirectOut: true,
                redirectPath: "/sales/view"
              });
            }}
          />
        </div>
      </div>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />; // or some growl/message that stays
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = (
        <>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              {updateSalesLeads}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ClientContactCreate {...this.props} />
            </div>
          </div>
        </>
      );
    }

    // FOR REDIRECT
    return <>{display}</>;
  }
}
