import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import CircularsDashboardService from "../../service/CircularsDashboardService";
import {
  RouteComponentProps,
  CommonMethods,
  ParamIDTypes,
} from "../resources/CommonMethods";
import { CircularsDashboardModel } from "./CircularsDashboardModel";
import CustomError from "../resources/Error";
import { CommonDocumentMethods } from "../resources/CommonDocumentMethods";

export function CircularDocumentView(props: RouteComponentProps<any>) {
  const circularDashboardService = new CircularsDashboardService();
  const [circular, setCircular] = useState<CircularsDashboardModel>();
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const commonMethods = new CommonMethods();
  const { id } = useParams<ParamIDTypes>();

  useEffect(() => {
    if (id !== null) {
      circularDashboardService
        .getCircularByID(id || "")
        .then((res: CircularsDashboardModel) => {
          if (res.documentPath !== null) {
            setCircular(res);
          } else {
            setErrorMessage("You do not have permissions to view this page!");
            setIsError(true);
          }
        })
        .catch((err) => {
          setErrorMessage(commonMethods.getErrorMessage(err));
          setIsError(true);
        });
    } else {
      setErrorMessage("oops!");
      setIsError(true);
    }
  }, []);

  let display;
  if (isError) {
    display = <CustomError message={errorMessage} />;
  } else {
    display =
      // <object
      //   data={circular?.documentPath}
      //   type="application/pdf"
      //   width="100%"
      //   height="800px"
      // ></object>
      circular?.documentPath !== undefined &&
      CommonDocumentMethods.displayPDF(circular?.documentPath) ? (
        <object
          data={circular?.documentPath}
          type="application/pdf"
          width="100%"
          height="800px"
        ></object>
      ) : (
        <a
          href={circular?.documentPath}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={circular?.documentPath} alt="" className="imgThumbnail" />
        </a>
      );
  }

  return (
    <div className="row ">
      <div className="col-12">
        <div className="card">
          <h1>Newsletter / Circulars - {circular?.summary}</h1>
          <p>{circular?.detail}</p>
          {display}
          <p>Posted By: {circular?.fullName}</p>
        </div>
      </div>
    </div>
  );
}
