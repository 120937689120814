import { Messages } from "primereact/messages";
import React, { useEffect, useRef, useState } from "react";
import { AnnouncementService } from "../../service/AnnouncementService";
import NotificationMessage from "./NotificationMessage";

export interface IAnnouncementModel {
  displayPicture: string;
  sticky: boolean;
  active: boolean;
  announcementID: string;
  announcer: string;
  summary: string;
  detail: string;
  severity: string;
  appliedLevel: string;
  acknowledgedBy: string;
  createdTime: Date | null;
  createdByMe: boolean;
}

export interface IAnnouncementDocumentModel {
  displayPicture: string;
  sticky: boolean;
  announcementID: string;
  announcer: string;
  summary: string;
  detail: string;
  active: boolean;
  severity: string;
  appliedLevel: string;
  acknowledgedBy: string;
  createdTime: Date | null;
  announcementDocID: string;
  documentPath: string;
  lastUpdatedDate: Date | null;
  createdByMe: boolean;
}

export class AnnouncementDisplayModel {
  public severity: string = "";
  public sticky: boolean = true;
  public displayPicture: string = "";
  public summary: string = "";
  public detail: string = "";
}

function Announcement(props: any) {
  const announcementService = new AnnouncementService();
  const [announcement, setAnnouncement] = useState<IAnnouncementModel[]>([]);
  const msg = useRef<any>(null);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    async function getAnnouncements() {
      announcementService.getUnreadAnnouncement().then((res) => {
        setAnnouncement(res);
      });
    }
    getAnnouncements();
  }, []);

  useEffect(() => {
    prepAnnouncementForDisplay();
  }, [announcement]);

  const prepAnnouncementForDisplay = () => {
    var result = announcement.map(ann => 
      ({
        id: "message",
        severity: ann.severity, 
        sticky: true,
        detail: ann.announcementID,
        content: (
          <React.Fragment>
            <div style={{display: "flex"}}>
              <img 
                src={ann.displayPicture}
                alt=""
                style={{
                  borderRadius: "50%",
                  width: "3em",
                  height: "3em",
                  objectFit: "cover",
                  border: "2px solid white"
                }}
              />
              <div>
                <p className="ml-2 mb-0 boldLabel">{ann.summary}</p>
                <p className="ml-2 mb-0">{ann.detail}</p>
              </div>
            </div>
          </React.Fragment>
        ),
        onClose: () => {console.log(ann.announcementID)},
      })
    );
    msg.current.show(result);
  }

  const onClose = (announcementID: any) => {
    announcementService
      .acknowledgeMsg(announcementID)
      .then((res) => {
        setHide(true);
      })
  }

  return (
    // <div>
    //   {announcement.map((res) => (
    //     <NotificationMessage
    //       image={res.displayPicture}
    //       severity={res.severity}
    //       detail={res.detail}
    //       summary={res.summary}
    //       announcementID={res.announcementID}
    //       key={res.announcementID}
    //     />
    //   ))}
    // </div>
    <div>
        <Messages
          id="Announcement"
          ref={msg}
          onRemove={(e) => {
            onClose(e.detail);
          }}
        />
    </div>
  );
}

export default Announcement;
