import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Row } from "primereact/row";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { StatusService } from "../../service/StatusService";
import { UserService } from "../../service/UserService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";






interface dataObj {
  [key: string]: string | number;
}

function StatusRecordGCView(props: RouteComponentProps) {
  const statusService = new StatusService();
  const commonMethods = new CommonMethods();
  const userService = new UserService();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userID, setUserID] = useState<string>("");
  const [userIDs, setUserIDs] = useState<any[]>([]);
  const [statusTypeNames, setStatusTypeNames] = useState<string[]>([]);
  const [keyArray, setKeyArray] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [groupBalance, setGroupBalance] = useState<any[]>([]);
  const [toApplyOnBehalf, setToApplyOnBehalf] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [groupAppliedStatus, setGroupAppliedStatus] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState<any>(new Date());

  const [appliedLeaveHeader] = useState<string>(
    "Group Company - Leave Applied"
  );

  const [balanceLeaveHeader] = useState<string>(
    "Group Company - Leave Balance"
  );

  let dt: DataTable | null = null;

  useEffect(() => {
    getStatusTypes();
    if (props.others?.admin) {
      getAllGroupUserIDs();
      getAllBalanceForGroup();
    } else {
      // invalid entry
    }
  }, []);

  useEffect(() => {
    if (props.others?.admin) {
      getGroupCompanyAppliedStatus();
    } else {
      // invalid entry
    }
  }, [selectedMonth]);

  // once department's user balance is in, create an object for every user.
  useEffect(() => {
    createObjForEveryUser(userIDs);
  }, [groupBalance]);

  // gets all active and deductible status type names by GCID
  const getStatusTypes = () => {
    statusService
      .getAllStatusTypeNames()
      .then((res) => {
        setStatusTypeNames(res);
        setKeyArray((keyArrayOriginal) => [...keyArrayOriginal, ...res]);
      })
      .catch(() => {
        setIsError(true);
      });
  };

  const getGroupCompanyAppliedStatus = () => {
    statusService
      .getGroupCompanyAppliedStatus(
        selectedMonth.getFullYear().toString(),
        (selectedMonth.getMonth() + 1).toString()
      )
      .then((res) => {
        setGroupAppliedStatus(res);
      })
      .catch((err) => {});
  };

  /*
  one object looks like this
  {
    fullName: {name},
    userID: {userID},
    statusTypeName: {statusTypeName},
    availableDays: {availableDays}
  }
  */

  // get all balance by gcid
  const getAllBalanceForGroup = () => {
    statusService
      .getGroupCompanyUsersBalance()
      .then((res) => {
        setGroupBalance(res);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
      });
  };

  // get a list of userIDs for the group
  const getAllGroupUserIDs = () => {
    userService
      .userIDsForUsersInGroup()
      .then((res) => {
        setUserIDs(res);
      })
      .catch(() => {
        setIsError(true);
      });
  };

  // utility function to create a generic object for every user int he dept.
  function objConstructor(keyarray: string[]) {
    let object: dataObj = {};
    object["userID"] = "";

    keyarray.forEach((element) => {
      object[element] = 0;
    });
    return object;
  }

  /* createObjForEveryUser
  one user object looks like this
  {
    fullName: {fullName},
    userID: {userID},
    statusTypeName: {availableBalance},
    statusTypeName: {availableBalance},
    statusTypeName: {availableBalance},
    statusTypeName: {availableBalance},
    .
    .
    .
  }
  */
  function createObjForEveryUser(userArray: string[]) {
    for (let index = 0; index < userArray.length; index++) {
      let tempObj = objConstructor(keyArray);
      tempObj.userID = userArray[index];
      tempObj.fullName = userArray[index];
      tempObj.fullNameDisplayed = userArray[index];
      tempObj.companyName = userArray[index];
      tempObj.departmentName = userArray[index];
      tempObj.userRankName = userArray[index];

      groupBalance.forEach((balanceObj) => {
        if (tempObj.userID === balanceObj.userID) {
          tempObj.fullName = balanceObj.fullName;
          tempObj.fullNameDisplayed = balanceObj.fullNameDisplayed;
          tempObj[balanceObj.statusName] = balanceObj.availableDays;
          tempObj.companyName = balanceObj.companyName;
          tempObj.departmentName = balanceObj.departmentName;
          tempObj.userRankName = balanceObj.userRankName;
        }
      });

      if (tempObj.userID !== tempObj.fullName) {
        setTableData((tableDataOriginal) => [...tableDataOriginal, tempObj]);
      }
    }
  }

  // using the status type names, create column headers
  const tableHeaders = () => {
    let headers = [];

    for (var i = 0; i < statusTypeNames.length; i++) {
      headers.push(
        <Column
          key={statusTypeNames[i]}
          header={statusTypeNames[i].replace("Leave", "")}
          colSpan={1}
        />
      );
    }
    return headers;
  };

  const headerGroup = () => {
    if (props.others?.admin) {
      return (
        <ColumnGroup>
          <Row>
            <Column header="Employee" rowSpan={2} />
            <Column header="Company" rowSpan={2} />
            <Column header="Department" rowSpan={2} />
            <Column header="FT/Intern" rowSpan={2} />
            <Column header="Leave Types" colSpan={statusTypeNames.length + 1} />
          </Row>
          <Row>
            {tableHeaders()}
            <Column header="Action" />
          </Row>
        </ColumnGroup>
      );
    }
  };

  const actionTemplate = (rowData: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltipOptions={{ position: "top" }}
          tooltip={"Apply leave on behalf of " + rowData.fullName}
          icon="pi pi-calendar"
          onClick={() => {
            setUserID(rowData.userID);
            setToApplyOnBehalf(true);
          }}
        />
      </div>
    );
  };

  const globalSearchBar = (
    <>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e) => setGlobalFilter(e.currentTarget.value)}
        />
      </span>
    </>
  );

  const tableHeader = () => {
    return (
      <div style={{ textAlign: "left" }}>
        <Calendar
          value={selectedMonth}
          showIcon={true}
          onChange={(e) => setSelectedMonth(e.value)}
          view="month"
          dateFormat="M-yy"
          className="text-left"
        />
      </div>
    );
  };

  const displayBalance = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message="An error has occurred" />;
    } else {
      return (
        <>
          <div className="row mb-2">
            <div className="col text-left">{globalSearchBar}</div>
            <div className="col text-right">
              <Button
                label="Export"
                onClick={() => {
                  if (dt !== null) {
                    dt.exportCSV();
                  }
                }}
              />
            </div>
          </div>
          <div className="datatable-centerHeader datatable-centerContent">
            <DataTable
              value={tableData}
              headerColumnGroup={headerGroup()}
              globalFilter={globalFilter}
              autoLayout={true}
              emptyMessage="No results found."
              paginator={true}
              rows={15}
              alwaysShowPaginator={false}
              ref={(el) => {
                dt = el;
              }}
              exportFilename={
                "StatusBalance_Report_" +
                commonMethods.displayDate_FileName(new Date())
              }
            >
              {initColumns()}
              <Column body={actionTemplate} />
            </DataTable>
          </div>
        </>
      );
    }
  };

  const displayApplied = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message="An error has occurred" />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            rowGroupMode="rowspan"
            groupField="fullName"
            sortField="fullName"
            sortOrder={1}
            value={groupAppliedStatus}
            autoLayout={true}
            paginator={true}
            alwaysShowPaginator={false}
            rows={20}
            emptyMessage="No results found."
            header={tableHeader()}
          >
            <Column className="p-col" field="fullName" header="Employee" />
            <Column
              className="p-col"
              field="statusTypeName"
              header="Leave Type"
            />
            <Column
              className="p-col"
              field="creditDebitValue"
              header="No. of Days"
            />
          </DataTable>
        </div>
      );
    }
  };

  const initColumns = () => {
    let columns: any[] = [];
    if (props.others?.admin) {
      columns = [
        <Column key={"fullNameDisplayed"} field="fullNameDisplayed" />,
        <Column key={"companyName"} field="companyName" />,
        <Column key={"departmentName"} field="departmentName" />,
        <Column key={"userRankName"} field="userRankName" />,
      ];
    } else {
      columns = [
        <Column key={"fullName"} field="fullName" />,
        <Column key={"userRankName"} field="userRankName" />,
      ];
    }

    for (var i = 0; i < statusTypeNames.length; i++) {
      columns.push(
        <Column key={statusTypeNames[i]} field={statusTypeNames[i]} />
      );
    }
    return columns;
  };

  return (
    <div>
      {toApplyOnBehalf ? (
        <Redirect push to={`/status/departmentview/apply/${userID}`} />
      ) : null}
      <div className="row">
        <div className="col-12">
          <div
            className="card"
            data-tour-id="status-department-overview-balance"
            data-tour="This is the remaining amount of leave your subordinates have for the year. You may apply leave on behalf of them through the blue action button."
          >
            <h1 className="pb-2">{balanceLeaveHeader}</h1>
            {displayBalance()}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div
            className="card"
            data-tour-id="status-department-overview-applied"
            data-tour="This is the leaves that your subordinates have applied for the current month."
          >
            <h1 className="pb-2">{appliedLeaveHeader}</h1>
            {displayApplied()}
          </div>
        </div>
      </div>
    </div>
  );
}
export default StatusRecordGCView;
