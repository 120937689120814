import { HttpClient } from "./HttpClient";
import { CircularsDashboardModel } from "../components/circulars/CircularsDashboardModel";
import { convertObjToFormData } from "../components/resources/CommonMethodsServices";

export default class CircularsDashboardService {
    httpClient:HttpClient;

    constructor(){
        this.httpClient = new HttpClient();
    }

    async getAllCirculars() {
        const res = await this.httpClient.get("/api/circularsdashboard");
        return res;
    }

    async getAllCircularsForUserRank() {
        const res = await this.httpClient.get("/api/circularsdashboard/user");
        return res;
    }

    async getCircularByID(announcementDocID: string){
        const res = await this.httpClient.get("/api/circularsdashboard/" + announcementDocID);
        return res;
    }
    async getAcknowledgedBy(announcementID: string){
        const res = await this.httpClient.get("/api/circularsdashboard/acknowledged/" + announcementID);
        return res;
    }
    async createCircular(circularModel: CircularsDashboardModel){
        const res = this.httpClient.post("/api/circularsdashboard/create", convertObjToFormData(circularModel));
        return res;
    }
    async updateCircular(circularModel: CircularsDashboardModel){
        const res = this.httpClient.put("/api/circularsdashboard/update", convertObjToFormData(circularModel));
        return res;
    }
    async deleteCircularByID(announcementID: string){
        const res = this.httpClient.delete("/api/circularsdashboard/" + announcementID);
        return res;
    }
}