import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { MultiSelect } from "primereact/multiselect";
import { ProgressSpinner } from "primereact/progressspinner";
import { RadioButton } from "primereact/radiobutton";
import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { CompanyService } from "../../service/CompanyService";
import { DepartmentService } from "../../service/DepartmentService";
import { JobTitleService } from "../../service/JobTitleService";
import { RoleService } from "../../service/RoleService";
import { UserRankService } from "../../service/UserRankService";
import { UserService } from "../../service/UserService";
import { CommonMethods } from "../resources/CommonMethods";
import CustomError  from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { UserModel } from "./User";
import { Checkbox } from "primereact/checkbox";
import Axios from "axios";


interface AppStateUpdate {
  userObj: UserModel;
  redirectOut: boolean;
  redirectPath: string;
  isLoading: boolean;

  action: string;
  formHeader: string;
  roleDropdownList: [];
  approverDropdownList: [];
  departmentDropdownList: [];
  nationalityDropdownList: [];
  workPassTypeDropdownList: [];
  workLocationDropdownList: [];

  isError: boolean;
  errorMsg: string;

  previewImage: any;
  previewEmailSignatureImage: any;

  swiftList: { label: string; value: string }[];

  ppEmailError: string;
  emailError: string;
  genderError: string;
}

export class UserUpdate extends React.Component<
  RouteComponentProps<any>,
  AppStateUpdate
> {
  static contextType = ToastStateContext;

  martialStatusDropdownList: any;
  accStatusDropdownList: any;
  genderDropdownList: any;
  legalStatusDropdownList: any;
  applicableDropdownList: any;
  salutationDropdownList: any;
  budgetingGroupDropdownList: any;
  userRankDropdownList: any;
  userService: UserService;
  companyService: CompanyService;
  departmentService: DepartmentService;
  userRankService: UserRankService;
  jobTitleService: JobTitleService;
  roleService: RoleService;

  commonMethods: CommonMethods;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      userObj: new UserModel(),

      redirectOut: false,
      redirectPath: "",
      isLoading: true,

      action: "",
      formHeader: "",

      roleDropdownList: [],
      approverDropdownList: [],
      departmentDropdownList: [],
      nationalityDropdownList: [],
      workPassTypeDropdownList: [],
      workLocationDropdownList: [],
      swiftList: [],

      isError: false,
      errorMsg: "",

      previewImage: "",
      previewEmailSignatureImage: "",
      ppEmailError: "",
      emailError: "",
      genderError: "",
    };

    this.accStatusDropdownList = [];
    this.genderDropdownList = [];
    this.applicableDropdownList = [];
    this.salutationDropdownList = [];
	this.budgetingGroupDropdownList = [];
    this.legalStatusDropdownList = [];
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.departmentService = new DepartmentService();
    this.userRankService = new UserRankService();
    this.jobTitleService = new JobTitleService();
    this.roleService = new RoleService();
    this.submitForm = this.submitForm.bind(this);
    this.getDropdownLists = this.getDropdownLists.bind(this);
    this.commonMethods = new CommonMethods();
    this.convertDatesForSubmission = this.convertDatesForSubmission.bind(this);
    this.copyPassword = this.copyPassword.bind(this);
    this.initDateFields = this.initDateFields.bind(this);
    this.formValidationCheck = this.formValidationCheck.bind(this);
  }
  componentDidMount() {
    if (
      //  --- UPDATE---
      this.props.match.params !== {} &&
      this.props.match.params.id !== undefined
    ) {
      var userIDfromQuery = this.props.match.params.id;
      this.userService
        .getUserById(userIDfromQuery)
        .then((res) => {
          this.setState(
            {
              userObj: res,
              action: "update",
              formHeader: "Update Employee",
            },
            () => {
              this.initDateFields(this.state.userObj);
              this.getDropdownLists();
              this.setState({
                previewImage: this.state.userObj.displayPicture,
                previewEmailSignatureImage: this.state.userObj.emailSignatureDisplay
              });
            }
          );
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
          return errorMsg;
        });
    }
  }

  // convert dates to display in correct format inside the calendar fields
  initDateFields(res: UserModel) {
    let data = res;
    data.dateOfBirth = new Date(res.dateOfBirth);
    data.wP_WorkPermitExpiry = new Date(res.wP_WorkPermitExpiry);
    data.prObtainedDate = new Date(res.prObtainedDate);

    if (this.commonMethods.isDateNull(this.state.userObj.dateOfBirth)) {
      this.updateProperty("dateOfBirth", null);
    }

    this.setState({ userObj: data });
  }

  // --- INIT FORM METHODS ---

  getDropdownLists() {
    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";

    Axios.all([
      this.commonMethods.getRoleDropdownListByCid(currentAccessingCompanyId), // role
      this.commonMethods.getDepartmentDropdownList(currentAccessingCompanyId), // dept
      this.commonMethods.getUserDropdownForGroupCompany(), // approver
      this.commonMethods.getBankCodeDropdown(), // Bank Code
      this.commonMethods.getNationalityDropdown(), // country
      this.commonMethods.getWorkPassTypeDropdown(),
      this.commonMethods.getWorkLocationDropdown(),
    ])
      .then(
        Axios.spread((first, second, third, fourth, fifth, sixth, seventh) => {
          this.setState({
            roleDropdownList: first,
            departmentDropdownList: second,
            approverDropdownList: third,
            swiftList: fourth,
            nationalityDropdownList: fifth,
            workPassTypeDropdownList: sixth,
            workLocationDropdownList: seventh,
            isLoading: false,
          });
        })
      )
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });

    this.accStatusDropdownList = this.commonMethods.getStatusSelectionDropdownList();
    this.genderDropdownList = this.commonMethods.getGenderDropdownList();
    this.applicableDropdownList = this.commonMethods.getYesNoDropdownList();
    this.salutationDropdownList = this.commonMethods.getSalutationDropdownList();
    this.martialStatusDropdownList = this.commonMethods.getMaritalStatusDropdownList();
    this.legalStatusDropdownList = this.commonMethods.getLegalStatusDropdownList();
	this.budgetingGroupDropdownList = this.commonMethods.getBudgetingGroupDropdownList();
  }

  updateProperty(property: string, value: any) {
    let tempUserObj = this.state.userObj;
    (tempUserObj as any)[property] = value;
    this.setState({
      userObj: tempUserObj,
    });
  }

  // --- FORM SUBMISSION METHODS ---
  submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // to prevent page from refreshing!
    this.setState({ isLoading: true });

    if (!this.formValidationCheck()) {
      this.convertDatesForSubmission();
      this.cleanUpFieldsForSubmission();

      this.userService
        .updateUser(this.state.userObj)
        .then((res) => {
          this.initDateFields(res);

          let msg = res.firstName + "'s details has been updated";
          this.context.SetSuccess(msg);

          this.goToView();
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.initDateFields(this.state.userObj);
          this.context.SetError(errorMsg);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  goToView() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/employees/view/" + this.state.userObj.userID,
    });
  }

  formValidationCheck() {
    let isError = false;
    this.setState({ ppEmailError: "", emailError: "", genderError: "" });

    if (!this.commonMethods.validateDOB(this.state.userObj.dateOfBirth)) {
      this.context.SetError("Date of Birth cannot be greater than today");
      return true;
    }

    if (!this.commonMethods.validateEmail(this.state.userObj.email)) {
      this.setState({ emailError: "p-error" });
      isError = true;
    }

    if (
      !(
        this.state.userObj.gender === "Male" ||
        this.state.userObj.gender === "Female"
      )
    ) {
      this.setState({ genderError: "p-error" });
      isError = true;
    }

    if (isError) {
      this.context.SetError("Invalid fields");
      return true;
    }
    return false;
  }

  cleanUpFieldsForSubmission() {
    if (this.state.userObj.legalStatus === "FR" && this.state.userObj.wP_WorkLocation === "Remote") {
        this.updateProperty("prObtainedDate", null);
        this.updateProperty("wP_WorkPass", null);
        this.updateProperty("wP_WorkPermitExpiry", null);
        this.updateProperty("wP_DependantPass", "");
    } else if (this.state.userObj.legalStatus === "FR" && this.state.userObj.wP_WorkLocation === "SG") {
        this.updateProperty("prObtainedDate", null);
        this.updateProperty("wP_PassportNumber", null);
    } else if (this.state.userObj.legalStatus === "PR") {
        this.updateProperty("wP_WorkPass", null);
        this.updateProperty("wP_WorkPermitExpiry", null);
        this.updateProperty("wP_DependantPass", "");
        this.updateProperty("wP_WorkLocation", null);
        this.updateProperty("wP_PassportNumber", null);

    } else if (this.state.userObj.legalStatus === "SC") {
        this.updateProperty("prObtainedDate", null);
        this.updateProperty("wP_WorkPass", null);
        this.updateProperty("wP_WorkPermitExpiry", null);
        this.updateProperty("wP_DependantPass", "");
        this.updateProperty("wP_WorkLocation", null);
        this.updateProperty("wP_PassportNumber", null);
    }
    if (this.state.userObj.bank_Code !== "") {
      var index = this.state.swiftList.findIndex(
        (obj) => obj.value === this.state.userObj.bank_Code
      );
      this.setState({
        userObj: {
          ...this.state.userObj,
          bank_Name: this.state.swiftList[index].label,
        },
      });
      // this.state.userObj.bank_Name = this.state.swiftList[index].label;
    }
  }

  // Convert date to ISOString format to write to db because formdata has no date type
  convertDatesForSubmission() {
    if (!this.commonMethods.isDateNull(this.state.userObj.dateOfBirth)) {
      let dob = this.state.userObj.dateOfBirth.toISOString();
      this.updateProperty("dateOfBirth", dob);
    }

    if (
      !this.commonMethods.isDateNull(this.state.userObj.wP_WorkPermitExpiry)
    ) {
      let wP_WorkPermitExpiry = this.state.userObj.wP_WorkPermitExpiry.toISOString();
      this.updateProperty("wP_WorkPermitExpiry", wP_WorkPermitExpiry);
    }

    if (!this.commonMethods.isDateNull(this.state.userObj.prObtainedDate)) {
      let prObtainedDate = this.state.userObj.prObtainedDate.toISOString();
      this.updateProperty("prObtainedDate", prObtainedDate);
    }
  }

  setDisplayPicture(e: React.ChangeEvent<HTMLInputElement>, type: string) {
    var reader = new FileReader();
    let typeFile = type + "File";
    if (
      e !== null &&
      e.currentTarget.files !== null &&
      e.currentTarget.files[0] !== undefined
    ) {
      var file = e.currentTarget.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (type == "displayPicture"){
          this.setState({ previewImage: reader.result });
        } else {
          this.setState({ previewEmailSignatureImage: reader.result });
        }
      };

      this.updateProperty(type, e.currentTarget.files[0].name);
      this.updateProperty(typeFile, e.currentTarget.files[0]);
    } else {
      this.updateProperty(type, "No file chosen");
      this.updateProperty(typeFile, null);
    }
  }

  copyPassword() {
    let copyText = this.state.userObj.passwordHash;
    let textArea = document.createElement("textarea");
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
  }

  render() {
	console.log(this.state.userObj);
    let prDate;
    if (this.state.userObj.legalStatus === "PR") {
      prDate = (
        <div className="p-col-12 pr-0 pl-3">
          <span className="boldLabel">PR Date Obtained</span>
          <Calendar
            dateFormat="dd/mm/yy"
            value={this.state.userObj.prObtainedDate}
            onChange={(e) => this.updateProperty("prObtainedDate", e.value)}
            showButtonBar={true}

            showIcon={true}
            monthNavigator={true}
            yearNavigator={true}
            yearRange="1900:2030"
            showTime={false}
            readOnlyInput={true}
          />
        </div>
      );
    } else {
      prDate = "";
    }

    let workpass;
    let worklocation;
    if (this.state.userObj.legalStatus === "FR") {
      worklocation = (
        <>
          <div className="p-col-12 pr-0 pl-3">
              <span className="boldLabel">Work Location</span>
              <Dropdown
                  value={this.state.userObj.wP_WorkLocation}
                  options={this.state.workLocationDropdownList}
                  onChange={(e) => {
                      this.setState({
                          userObj: {
                              ...this.state.userObj,
                              wP_WorkLocation: e.value,
                          },
                      });
                  }}
                  placeholder="Please Select"
              />
          </div>
        </>
      );
    }

    if (this.state.userObj.legalStatus === "FR" && this.state.userObj.wP_WorkLocation === "Remote") {
      workpass = (
          <>
              <div className="p-col-12 pr-0 pl-3">
                  <span className="boldLabel">Passport Number</span>
                  <InputText
                      value={this.state.userObj.wP_PassportNumber}
                      onChange={(e) =>
                          this.setState({
                              userObj: {
                                  ...this.state.userObj,
                                  wP_PassportNumber: e.currentTarget.value,
                              },
                          })
                      }
                  />
              </div>
          </>
      )
    } else if (this.state.userObj.legalStatus === "FR" && this.state.userObj.wP_WorkLocation === "SG") {
      workpass = (
        <>
          <div className="p-col-12 pr-0 pl-3">
              <span className="boldLabel">Work Pass Type</span>
              <Dropdown
                  value={this.state.userObj.wP_WorkPass}
                  options={this.state.workPassTypeDropdownList}
                  onChange={(e) => {
                      this.setState({
                          userObj: {
                              ...this.state.userObj,
                              wP_WorkPass: e.value,
                          },
                      });
                  }}
                  placeholder="Please Select"
              />
          </div>
          <div className="p-col-12 pr-0 pl-3">
              <span className="boldLabel">Work Pass Number</span>
              <InputText
                  value={this.state.userObj.wP_DependantPass}
                  onChange={(e) =>
                      this.setState({
                          userObj: {
                              ...this.state.userObj,
                              wP_DependantPass: e.currentTarget.value,
                          },
                      })
                  }
              />
          </div>
          <div className="p-col-12 pr-0 pl-3">
              <span className="boldLabel">Work Permit Expiry Date</span>
              <Calendar
                  dateFormat="dd/mm/yy"
                  value={this.state.userObj.wP_WorkPermitExpiry}
                  onChange={(e) =>
                      this.updateProperty("wP_WorkPermitExpiry", e.value)
                  }
                  showButtonBar={true}

                  showIcon={true}
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange="1900:2030"
                  showTime={false}
              />
          </div>
        </>
      );
    }

    let SHG;
    if (this.state.userObj.shgEligible) {
      SHG = (
        <div className="p-col-12">
          <span className="boldLabel">
            Self Help Group <span style={{ color: "red" }}>*</span>
          </span>
          <Dropdown
            value={this.state.userObj.shgGroup}
            options={this.commonMethods.getSHGDropdownOptions()}
            onChange={(e) =>
              this.setState({
                userObj: {
                  ...this.state.userObj,
                  shgGroup: e.value,
                },
              })
            }
            placeholder="Please Select"
            required
          />
        </div>
      );
    }

    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
          }}
        />
      );
    }

    let page = (
      <form onSubmit={this.submitForm}>
        <h5>Employee Details</h5>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="row">
              <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">
                  First Name <span style={{ color: "red" }}>*</span>
                </span>
                <InputText
                  value={this.state.userObj.firstName}
                  onChange={(e) =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        firstName: e.currentTarget.value,
                      },
                    })
                  }
                  required
                />
              </div>
              <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">
                  Last Name <span style={{ color: "red" }}>*</span>
                </span>
                <InputText
                  value={this.state.userObj.lastName}
                  onChange={(e) =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        lastName: e.currentTarget.value,
                      },
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Full Name (as per NRIC) <span style={{ color: "red" }}>*</span>
            </span>

            <InputText
              value={this.state.userObj.fullName}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    fullName: e.currentTarget.value.toUpperCase(),
                  },
                })
              }
              required
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Email <span style={{ color: "red" }}>*</span>
            </span>
            <div
              hidden={this.state.emailError === "" ? true : false}
              className="my-1"
            >
              <Message severity="error" text="Invalid Input" />
            </div>
            <InputText
              className={this.state.emailError}
              value={this.state.userObj.email}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    email: e.currentTarget.value,
                  },
                })
              }
              required
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Account Status
            </span>
            <Dropdown
              value={this.state.userObj.accStatus}
              options={this.accStatusDropdownList}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    accStatus: e.value,
                  },
                })
              }
              // required
              disabled
              placeholder="Please Select"
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Role <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.userObj.roleID}
              options={this.state.roleDropdownList}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    roleID: e.value,
                  },
                })
              }
              required
              placeholder="Please Select"
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Supervisors</span>
            <MultiSelect
              value={this.state.userObj.approverList}
              options={this.state.approverDropdownList}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    approverList: e.value,
                  },
                })
              }
              filter={true}
              placeholder="Please Select"
            />
          </div>

		  <div className="p-col-12">
            <span className="boldLabel">Budgeting Group</span>
            <Dropdown
              value={this.state.userObj.budgetingGroup}
              options={this.budgetingGroupDropdownList}
			  optionLabel="label"
			  filter
              onChange={(e) => {
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    budgetingGroup: e.value,
                  },
                });
              }}
              placeholder="Please Select"
              required
            />
          </div>
        </div>

		<hr style={{ height: 5, marginTop: 20, marginBottom: 10 }} />
        <h5 className="pt-2">Personal Particulars</h5>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="p-grid p-justify-center">
              <div className="imagecropper">
                <img src={this.state.previewImage} alt="" />
              </div>
            </div>
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Display Picture</span>

            <div>
              {/* {this.state.userObj.displayPicture ? (
                    <img
                      src={this.state.userObj.displayPicture}
                      alt="displaypicture"
                      style={{ maxWidth: "5rem", margin: "0.5rem" }}
                    />
                  ) : (
                    <div />
                  )} */}
              <input
                style={{ color: "transparent", width: "110px" }}
                type="file"
                name="displayPicture"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => this.setDisplayPicture(e, "displayPicture")}
              />
            </div>
          </div>

          <div className="p-col-12">
            <div className="p-grid p-justify-center">
              <div className="imagecropper">
                <img src={this.state.previewEmailSignatureImage} alt="" />
              </div>
            </div>
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Email Signature Display</span>

            <div>
              <input
                style={{ color: "transparent", width: "110px" }}
                type="file"
                name="emailSignatureDisplay"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => this.setDisplayPicture(e, "emailSignatureDisplay")}
              />
            </div>
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Nationality <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.userObj.nationality}
              options={this.state.nationalityDropdownList}
              onChange={(e) => {
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    nationality: e.value,
                  },
                });
              }}
              placeholder="Please Select"
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Citizenship <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              className="mb-2"
              value={this.state.userObj.legalStatus}
              options={this.legalStatusDropdownList}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    legalStatus: e.value,
                  },
                })
              }
              placeholder="Please Select"
              required
            />
            <div className="p-col-12 pr-0 pl-3">
              <span className="boldLabel">NRIC/FIN/ID</span>
              <InputText
                  value={this.state.userObj.identificationNumber}
                  onChange={(e) =>
                      this.setState({
                          userObj: {
                              ...this.state.userObj,
                              identificationNumber: e.currentTarget.value,
                          },
                      })
                  }
              />
            </div>
            {worklocation}

            {workpass}

            {prDate}
          </div>

          {/* <div className="p-col-12 p-lg-6">
                <span className="boldLabel">NRIC</span>
                <InputText
                  value={this.state.userObj.nric}
                  onChange={e =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        nric: e.currentTarget.value
                      }
                    })
                  }
                  required
                />
              </div> */}

          <div className="p-col-12">
            <span className="boldLabel">Date of Birth</span>
            <Calendar
              dateFormat="dd/mm/yy"
              value={this.state.userObj.dateOfBirth}
              onChange={(e) => {
                this.updateProperty("dateOfBirth", e.value);
              }}
              showButtonBar={true}

              showIcon={true}
              monthNavigator={true}
              yearNavigator={true}
              yearRange="1900:2020"
              // maxDate={this.state.maxDate}
              showTime={false}
              readOnlyInput={true}
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Gender <span style={{ color: "red" }}>*</span>
            </span>
            <div
              hidden={this.state.genderError === "" ? true : false}
              className="my-1"
            >
              <Message severity="warn" text="Required" />
            </div>
            <div className="p-grid p-col-12 p-1">
              <div className="p-col-4 p-field-radiobutton">
                <RadioButton
                  className={this.state.genderError}
                  inputId="Male"
                  value="Male"
                  name="Male"
                  onChange={(e) =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        gender: e.value,
                      },
                    })
                  }
                  checked={this.state.userObj.gender === "Male"}
                />
                <label htmlFor="Male" className="ml-1">
                  Male
                </label>
              </div>
              <div className="p-col-4 p-field-radiobutton">
                <RadioButton
                  className={this.state.genderError}
                  inputId="Female"
                  value="Female"
                  name="Female"
                  onChange={(e) =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        gender: e.value,
                      },
                    })
                  }
                  checked={this.state.userObj.gender === "Female"}
                />
                <label htmlFor="Female" className="ml-1">
                  Female
                </label>
              </div>
            </div>
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Salutation</span>
            <Dropdown
              value={this.state.userObj.salutation}
              options={this.salutationDropdownList}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    salutation: e.value,
                  },
                })
              }
              placeholder="Please Select"
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Marital Status</span>
            <Dropdown
              value={this.state.userObj.maritalStatus}
              options={this.martialStatusDropdownList}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    maritalStatus: e.value,
                  },
                })
              }
              placeholder="Please Select"
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Race</span>
            <InputText
              value={this.state.userObj.race}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    race: e.currentTarget.value,
                  },
                })
              }
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Religion</span>
            <InputText
              value={this.state.userObj.religion}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    religion: e.currentTarget.value,
                  },
                })
              }
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Personal Email</span>
            <div
              hidden={this.state.ppEmailError === "" ? true : false}
              className="my-1"
            >
              <Message severity="error" text="Invalid Input" />
            </div>
            <InputText
              className={this.state.ppEmailError}
              value={this.state.userObj.pP_PersonalEmail}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    pP_PersonalEmail: e.currentTarget.value,
                  },
                })
              }
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Residential Address</span>
            <InputText
              value={this.state.userObj.pP_ResidentialAddress}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    pP_ResidentialAddress: e.currentTarget.value,
                  },
                })
              }
            />
          </div>

          <div className="p-col-12">
            <div className="row">
              <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">Mobile Number</span>
                <InputText
                  keyfilter="int"
                  value={this.state.userObj.pP_MobileNumber}
                  onChange={(e) =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        pP_MobileNumber: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">Home Number</span>
                <InputText
                  keyfilter="int"
                  value={this.state.userObj.pP_HomeNumber}
                  onChange={(e) =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        pP_HomeNumber: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>{" "}
            </div>
          </div>
        </div>


		<hr style={{ height: 5, marginTop: 20, marginBottom: 10 }} />
        <h5 className="pt-2">Emergency Contact</h5>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">Name</span>
            <InputText
              value={this.state.userObj.emergency_Name}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    emergency_Name: e.currentTarget.value,
                  },
                })
              }
            />
          </div>

          <div className="p-col-12">
            <div className="row">
              <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">Contact Number</span>
                <InputText
                  keyfilter="int"
                  value={this.state.userObj.emergency_Contact}
                  onChange={(e) =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        emergency_Contact: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">Relationship</span>
                <InputText
                  value={this.state.userObj.emergency_Relationship}
                  onChange={(e) =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        emergency_Relationship: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>

		<hr style={{ height: 5, marginTop: 20, marginBottom: 10 }} />
        <h5 className="pt-2">Bank &amp; Statutory Details</h5>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">Bank Name</span>
            <Dropdown
              value={this.state.userObj.bank_Code}
              options={this.state.swiftList}
              onChange={(e) => {
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    bank_Code: e.value,
                    bank_Name: this.state.swiftList[
                      this.state.swiftList.findIndex(
                        (obj) => obj.value === e.value
                      )
                    ].label,
                  },
                });
              }}
              placeholder="Please Select"
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Account Type</span>
            <InputText
              value={this.state.userObj.bank_AccountType}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    bank_AccountType: e.currentTarget.value,
                  },
                })
              }
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Account Name</span>
            <InputText
              value={this.state.userObj.bank_PayeeName}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    bank_PayeeName: e.currentTarget.value,
                  },
                })
              }
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Account Number</span>
            <InputText
              value={this.state.userObj.bank_Number}
              onChange={(e) =>
                this.setState({
                  userObj: {
                    ...this.state.userObj,
                    bank_Number: e.currentTarget.value,
                  },
                })
              }
            />
          </div>
        </div>

		<hr style={{ height: 5, marginTop: 20, marginBottom: 10 }} />
		<h5 className="pt-2">Public Profile Permissions</h5>
		<div className="p-grid p-justify-center">
			<div className="p-col-12 p-lg-8">
				<div className="p-grid p-fluid">
					<div className="p-col-12">
						<div className="field-checkbox">
							<Checkbox
								className="mr-2"
								inputId="publicprofilepermission"
								checked={this.state.userObj.publicProfilePermission}
								onChange={e => {
									this.setState({
										userObj: {
											...this.state.userObj,
											publicProfilePermission: e.checked
										}
									})
								}} />
							<label className="mb-0"htmlFor="publicprofilepermission">
								I agree to have my information (Birthdays) displayed on the Company Feed and in my Profile
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>


		<hr style={{ height: 5, marginTop: 20, marginBottom: 10 }} />
        <h5 className="pt-2">Contributions</h5>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">Self Help Group Contributions</span>
            <div className="p-grid p-col-12 p-1">
              <div className="p-col-4 p-field-radiobutton">
                <RadioButton
                  inputId="YesSHG"
                  value={true}
                  name="Yes"
                  onChange={(e) =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        shgEligible: e.value,
                      },
                    })
                  }
                  checked={this.state.userObj.shgEligible}
                />
                <label htmlFor="YesSHG" className="ml-1">
                  Yes
                </label>
              </div>
              <div className="p-col-4 p-field-radiobutton">
                <RadioButton
                  inputId="NoSHG"
                  value={false}
                  name="No"
                  onChange={(e) => {
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        shgEligible: e.value,
                        shgGroup: "",
                      },
                    });
                  }}
                  checked={!this.state.userObj.shgEligible}
                />
                <label htmlFor="NoSHG" className="ml-1">
                  No
                </label>
              </div>
            </div>
          </div>

          {SHG}

          <div className="p-col-12">
            <span className="boldLabel">SDL Entitlement</span>
            <div className="p-grid p-col-12 p-1">
              <div className="p-col-4 p-field-radiobutton">
                <RadioButton
                  inputId="YesSDL"
                  value={true}
                  name="Yes"
                  onChange={(e) =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        sdlEligible: e.value,
                      },
                    })
                  }
                  checked={this.state.userObj.sdlEligible}
                />
                <label htmlFor="YesSDL" className="ml-1">
                  Yes
                </label>
              </div>
              <div className="p-col-4 p-field-radiobutton">
                <RadioButton
                  inputId="NoSDL"
                  value={false}
                  name="No"
                  onChange={(e) =>
                    this.setState({
                      userObj: {
                        ...this.state.userObj,
                        sdlEligible: e.value,
                      },
                    })
                  }
                  checked={!this.state.userObj.sdlEligible}
                />
                <label htmlFor="NoSDL" className="ml-1">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <div className="p-grid p-justify-center pt-2">
          <Button type="submit" label="Update" />
        </div>
      </form>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = page;
    }

    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{this.state.formHeader}</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {display}
          </div>
        </div>
      </div>
    );
  }
}
