import React, { useState, useEffect } from "react";
import { UserService } from "../../service/UserService";
import { BusinessCardModel, SocialMediaModel } from "../landingpages/BusinessCardModel";
import { UserModel } from "./User";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { ColorPicker } from "primereact/colorpicker";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { useLocation } from "react-router";
import { hexToCSSFilter } from "hex-to-css-filter";
import ReactTooltip from "react-tooltip";




function BusinessCardUser(props: any) {

	const userService = new UserService();
	const location = useLocation();
	const [businessCardObj, setBusinessCardObj] = useState<BusinessCardModel>(new BusinessCardModel);
	const [userObj, setUserObj] = useState<UserModel>(new UserModel());
	const [refreshView, setRefreshView] = useState<boolean>(false);
	const [isMounted, setIsMounted] = useState<boolean>(false); // Needed because ColorPicker is absorbing new BusinessCardModel properties before useeffect


	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isError, setIsError] = useState<boolean>(false);
	const { SetSuccess } = React.useContext(ToastStateContext);
	const [errorMsg, setErrorMsg] = useState<string>("An unidentified error has occurred");
	const [cssFilter, setCSSFilter] = useState<string>("");
	const [dialCode, setDialCode] = useState<string>("");
	const [mobileNumber, setMobileNumber] = useState<string>("");

	const [tempBgColour, setTempBgColour] = useState("");
	const [tempNameTitleColour, setTempNameTitleColour] = useState("");
	const [tempContactColour, setTempContactColour] = useState("");
	const [refreshToken, setRefreshToken] = useState(0)

	const links = [
		{
			name: "twitter",
			url:"https://www.twitter.com/",
			icon: "/assets/social-media-icons/Twitter.svg"
		},
		{
			name: "facebook",
			url: "https://www.facebook.com/",
			icon: "/assets/social-media-icons/Facebook.svg",
		},
		{
			name: "instagram",
			url: "https://www.instagram.com/",
			icon: "/assets/social-media-icons/Instagram.svg",
		},
		{
			name: "tiktok",
			url: "https://www.tiktok.com/",
			icon: "/assets/social-media-icons/TikTok.svg",
		},
		{
			name: "linkedin",
			url: "https://www.linkedin.com/in/",
			icon: "/assets/social-media-icons/LinkedIn.svg",
		},
		{
			name: "spotify",
			url: "https://open.spotify.com/",
			icon: "/assets/social-media-icons/Spotify.svg",
		},
		{
			name: "twitch",
			url: "https://www.twitch.tv/",
			icon: "/assets/social-media-icons/Twitch.svg",
		},
		{
			name: "youtube",
			url: "https://www.youtube.com/",
			icon: "/assets/social-media-icons/Youtube.svg",
		},
		{
			name: "telegram",
			url: "https://t.me/",
			icon: "/assets/social-media-icons/Telegram.svg",
		},
	]

	useEffect(() => {
		myBusinessCardDetails();
	}, [refreshView]);

	const myBusinessCardDetails = () => {
		userService.getMyDisplay()
			.then((res: UserModel) => {
				let json = new BusinessCardModel();
				if (res.businessCardJson != null && res.businessCardJson != "") {
					json = JSON.parse(res.businessCardJson);
					let stringSplit = res.pP_MobileNumber.split(" ");
					console.log(stringSplit);
					if (stringSplit.length > 1){
						setDialCode(stringSplit[0]);
						setMobileNumber(stringSplit[1]);
					} else {
						setDialCode("+65");
						setMobileNumber(res.pP_MobileNumber);
					}
					setTempBgColour(json.bgColour);
					setTempNameTitleColour(json.nameTitleColour);
					setTempContactColour(json.contactColour);
				}
				setBusinessCardObj(json);

				let filter = (hexToCSSFilter("#" + json.contactColour).filter).slice(0,-1);
				setCSSFilter(filter);
				setUserObj(res);
			})
			.catch((error) => {
				setErrorMsg("Error retrieving user details");
				setIsError(true);
			})
			.finally(() => {
				setIsLoading(false);
				setIsMounted(true);
			})
	}

	console.log(dialCode);
	console.log(mobileNumber);


	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		userService
			.staffUpdate(userObj)
			.then((res) => {
				SetSuccess("Business Card updated!");
				setIsMounted(false);
			})
			.finally(() => {
				setRefreshView(!refreshView);
			})

	}

	const socialMediaAction = (action: string, name: string) => {
		if (action === "check") {
			return businessCardObj.socialMedia.some((obj: SocialMediaModel) => obj.name === name)
		} else if (action === "add") {
			let obj = new SocialMediaModel(name);
			setBusinessCardObj({
				...businessCardObj,
				socialMedia: [...businessCardObj.socialMedia, obj]
			})
		} else {
			let tempArray = businessCardObj.socialMedia.filter((obj: SocialMediaModel) => {return obj.name != name});
			setBusinessCardObj({
				...businessCardObj,
				socialMedia: tempArray
			})
		}
	}

	const socialMediaURL = (name: string) => {
		if (socialMediaAction("check", name)) {
			var result = businessCardObj.socialMedia.find((obj: SocialMediaModel) => obj.name === name);
			return result?.url;
		} else {
			return "";
		}
	}

	const socialMediaUpdate = (e: React.FormEvent<HTMLInputElement>, name: string) => {
		setBusinessCardObj({
			...businessCardObj,
			socialMedia: businessCardObj.socialMedia.map(
				(obj: SocialMediaModel) => (obj.name === name ? Object.assign(obj, { url: e.currentTarget.value}) : obj)
			)
		})
	}

	const fullURL = (link: SocialMediaModel) => {
		var object = links.find(obj => obj.name === link.name);
		return object?.url + link.url;
	}

	const userCompany = () => {
		let baseString = "/assets/company-logos/";
		switch(userObj.companyName) {
		  case "Eatbook Pte. Ltd":
			return baseString + "Eatbook.svg";
			break;
		  case "Zula Pte. Ltd.":
			return baseString + "Zula.svg";
			break;
		  case "MS News Pte. Ltd.":
			return baseString + "MSNews.svg";
			break;
		  default:
			return baseString + "TSL.svg";
			break;
		}
	}

	const numberSpacePadding = (obj: string) => {
		var res = "";
		var savei = 0
		if (mobileNumber.length > 4){
			for (let i = obj.length-1; i > 3; i-=4) {
				savei = i-4;
				res = " " + obj.slice(i-3, i+1) + res;
			}
			res = obj.slice(0, savei+1) + res;
			return res;
		} else {
			return obj;
		}
	}

	const iconSource = (icon: string) => {
		if (icon == "Tiktok") {
			icon = "TikTok";
		} else if (icon == "Linkedin") {
			icon = "LinkedIn";
		}

		return "/assets/social-media-icons/" + icon + ".svg"
	}

	// const emailWrapper = (email: string) => {
	// 	if (email.length < 25) {
	// 		return (
	// 			<p className="m-0">{email}</p>
	// 		)
	// 	} else {
	// 		let split = email.split("@");
	// 		return (
	// 			<>
	// 				<p className="m-0">{split[0]}</p>
	// 				<p className="m-0">{"@" + split[1]}</p>
	// 			</>
	// 		)
	// 	}
	// }

	const checkValidColorHex = (hex: string) => {
		if (hex.length !== 3 && hex.length !== 6) {
			return false;
		}
		const regex = new RegExp(/^([A-Fa-f0-9]{6})$/);
		return regex.test(hex);
	}

	let page = (
		<>
			{/* left component */}
			<div className="col-12 col-xl-7">
				<div className="card">
					<h1 className="pb-2">Configuration</h1>
					<form onSubmit={onSubmit}>


						{/* <div className="p-col-12 py-2"><h5>Colours</h5></div> */}
						<div className="p-grid p-2">
								<div className="p-grid p-fluid p-justify-center">

									{/* Colour settings */}
									<div className="p-col-12 py-2"><h5>Colours:</h5></div>
									<div className="p-col-10 p-justify-center pt-0">
										{isMounted &&
											<div className="row">
												<div className="col-12 col-md-4 pt-2 text-center">
													<div className="boldLabel">Background</div>
													<div className="d-flex align-items-center">
														<ColorPicker
															key={refreshToken}
															value={businessCardObj.bgColour}
															onChange={(e) => {
																setBusinessCardObj({
																	...businessCardObj,
																	bgColour: e.value,
																});
																setTempBgColour(e.value);
															}}
															style={{
																width: "2rem",
																heigth: "2rem",
															}}
															className="mr-1"
														/>
														<span className="p-input-icon-left">
															<i className="fas fa-hashtag" />
															<InputText
																value={tempBgColour}
																onChange={(e) => {
																	const value = e.currentTarget.value;
																	setTempBgColour(value);
																	if (checkValidColorHex(value)) {
																		setBusinessCardObj({
																			...businessCardObj,
																			bgColour: value,
																		});
																		setRefreshToken(refreshToken + 1)
																	}
																}}
																maxLength={6}
																className={checkValidColorHex(tempBgColour) ? "" : "p-invalid block"}
															/>
														</span>
													</div>
												</div>
												<div className="col-12 col-md-4 pt-2 text-center">
													<span className="boldLabel">Name/Title</span>
													<div className="d-flex align-items-center">
														<ColorPicker
															key={refreshToken}
															value={businessCardObj.nameTitleColour}
															onChange={(e) => {
																setBusinessCardObj({
																	...businessCardObj,
																	nameTitleColour: e.value,
																});
																setTempNameTitleColour(e.value);
															}}
															style={{
																width: "2rem",
																heigth: "2rem",
															}}
															className="mr-1"
														/>
														<span className="p-input-icon-left">
															<i className="fas fa-hashtag" />
															<InputText
																value={tempNameTitleColour}
																onChange={(e) => {
																	const value = e.currentTarget.value;
																	setTempNameTitleColour(value);
																	if (checkValidColorHex(value)) {
																		setBusinessCardObj({
																			...businessCardObj,
																			nameTitleColour: value,
																		});
																		setRefreshToken(refreshToken + 1)
																	}
																}}
																maxLength={6}
																className={checkValidColorHex(tempNameTitleColour) ? "" : "p-invalid block"}
															/>
														</span>
													</div>
												</div>
												<div className="col-12 col-md-4 pt-2 text-center">
													<span className="boldLabel mr-2">Contact/Icons</span>
													<div className="d-flex align-items-center">
														<ColorPicker
															key={refreshToken}
															value={businessCardObj.contactColour}
															onChange={(e) => {
																setBusinessCardObj({
																	...businessCardObj,
																	contactColour: e.value,
																});
																setTempContactColour(e.value);
															}}
															style={{
																width: "2rem",
																heigth: "2rem",
															}}
															className="mr-1"
														/>
														<span className="p-input-icon-left">
															<i className="fas fa-hashtag" />
															<InputText
																value={tempContactColour}
																onChange={(e) => {
																	const value = e.currentTarget.value;
																	setTempContactColour(value);
																	if (checkValidColorHex(value)) {
																		setBusinessCardObj({
																			...businessCardObj,
																			contactColour: value,
																		});
																		setRefreshToken(refreshToken + 1)
																	}
																}}
																maxLength={6}
																className={checkValidColorHex(tempContactColour) ? "" : "p-invalid block"}
															/>
														</span>
													</div>
												</div>
											</div>
										}
										<div className="p-grid p-justify-center mt-4">
											<Button
												label="Reset"
												tooltip={"Reset colours to default"}
												tooltipOptions={{position: "bottom"}}
												style={{width: "auto"}}
												onClick={(event) => {
													event.preventDefault();
													setBusinessCardObj({
														...businessCardObj,
														bgColour: "177147",
														contactColour: "FFFFFF"
													})
												}}
											/>
										</div>
									</div>

									{/* Links */}
									<div className="p-col-12 mt-4"><h5>Links:</h5></div>
									<div className="p-col-10 p-justify-center pt-0">
										<div className="row">
											<div className="p-col-12 pl-0">

												{/* Twitter */}
												<div className="p-col-12 mb-2 pl-0">
													<div className="field-checkbox mb-1 col-12">
														<Checkbox
															className="mr-2 pl-0"
															inputId="twitter"
															checked={socialMediaAction("check", "twitter")}
															onChange={e => {
																e.checked ? socialMediaAction("add", "twitter") : socialMediaAction("remove", "twitter")
															}}
														/>
														<label className="mb-0"htmlFor="twitter">
															Twitter
														</label>
													</div>
													{socialMediaAction("check", "twitter") &&
														<div className="col-12">
															<span className="boldLabel mr-1">https://www.twitter.com/</span>
															<InputText
																disabled={!socialMediaAction("check", "twitter")}
																value={socialMediaURL("twitter")}
																onChange={(e) => {
																	socialMediaUpdate(e, "twitter");
																}}
																className="w-50 col-12"
															/>
														</div>
													}
												</div>

												{/* Facebook */}
												<div className="p-col-12 mb-2 pl-0">
													<div className="field-checkbox mb-1 col-12">
														<Checkbox
															className="mr-2 pl-0"
															inputId="facebook"
															checked={socialMediaAction("check", "facebook")}
															onChange={e => {
																e.checked ? socialMediaAction("add", "facebook") : socialMediaAction("remove", "facebook")
															}}
														/>
														<label className="mb-0"htmlFor="facebook">
															Facebook
														</label>
													</div>
													{socialMediaAction("check", "facebook") &&
														<div className="col-12">
															<span className="boldLabel mr-1">https://www.facebook.com/</span>
															<InputText
																disabled={!socialMediaAction("check", "facebook")}
																value={socialMediaURL("facebook")}
																onChange={(e) => {
																	socialMediaUpdate(e, "facebook");
																}}
																className="w-50"
															/>
														</div>
													}
												</div>

												{/* Instagram */}
												<div className="p-col-12 mb-2 pl-0">
													<div className="field-checkbox mb-1 col-12">
														<Checkbox
															className="mr-2 pl-0"
															inputId="instagram"
															checked={socialMediaAction("check", "instagram")}
															onChange={e => {
																e.checked ? socialMediaAction("add", "instagram") : socialMediaAction("remove", "instagram")
															}}
														/>
														<label className="mb-0"htmlFor="instagram">
															Instagram
														</label>
													</div>
													{socialMediaAction("check", "instagram") &&
													<div className="col-12">
														<span className="boldLabel mr-1">https://www.instagram.com/</span>
														<InputText
															disabled={!socialMediaAction("check", "instagram")}
															value={socialMediaURL("instagram")}
															onChange={(e) => {
																socialMediaUpdate(e, "instagram");
															}}
															className="w-50"
														/>
													</div>
													}
												</div>

												{/* Tiktok */}
												<div className="p-col-12 mb-2 pl-0">
													<div className="field-checkbox mb-1 col-12">
														<Checkbox
															className="mr-2 pl-0"
															inputId="tiktok"
															checked={socialMediaAction("check", "tiktok")}
															onChange={e => {
																e.checked ? socialMediaAction("add", "tiktok") : socialMediaAction("remove", "tiktok")
															}}
														/>
														<label className="mb-0"htmlFor="tiktok">
															Tiktok
														</label>
													</div>
													{socialMediaAction("check", "tiktok") &&
														<div className="col-12">
															<span className="boldLabel mr-1">https://www.tiktok.com/</span>
															<InputText
																disabled={!socialMediaAction("check", "tiktok")}
																value={socialMediaURL("tiktok")}
																onChange={(e) => {
																	socialMediaUpdate(e, "tiktok");
																}}
																className="w-50"
															/>
														</div>
													}
												</div>

												{/* LinkedIn */}
												<div className="p-col-12 mb-2 pl-0">
													<div className="field-checkbox mb-1 col-12">
														<Checkbox
															className="mr-2 pl-0"
															inputId="linkedin"
															checked={socialMediaAction("check", "linkedin")}
															onChange={e => {
																e.checked ? socialMediaAction("add", "linkedin") : socialMediaAction("remove", "linkedin")
															}}
														/>
														<label className="mb-0"htmlFor="linkedin">
															LinkedIn
														</label>
													</div>
													{socialMediaAction("check", "linkedin") &&
														<div className="col-12">
															<span className="boldLabel mr-1">https://www.linkedin.com/in/</span>
															<InputText
																disabled={!socialMediaAction("check", "linkedin")}
																value={socialMediaURL("linkedin")}
																onChange={(e) => {
																	socialMediaUpdate(e, "linkedin");
																}}
																className="w-50"
															/>
														</div>
													}
												</div>

												{/* Spotify */}
												<div className="p-col-12 mb-2 pl-0">
													<div className="field-checkbox mb-1 col-12">
														<Checkbox
															className="mr-2 pl-0"
															inputId="spotify"
															checked={socialMediaAction("check", "spotify")}
															onChange={e => {
																e.checked ? socialMediaAction("add", "spotify") : socialMediaAction("remove", "spotify")
															}}
														/>
														<label className="mb-0"htmlFor="spotify">
															Spotify
														</label>
													</div>
													{socialMediaAction("check", "spotify") &&
														<div className="col-12">
															<span className="boldLabel mr-1">https://open.spotify.com/</span>
															<InputText
																disabled={!socialMediaAction("check", "spotify")}
																value={socialMediaURL("spotify")}
																onChange={(e) => {
																	socialMediaUpdate(e, "spotify");
																}}
																className="w-50"
															/>
														</div>
													}
												</div>



												{/* Twitch */}
												<div className="p-col-12 mb-2 pl-0">
													<div className="field-checkbox mb-1 col-12">
														<Checkbox
															className="mr-2 pl-0"
															inputId="twitch"
															checked={socialMediaAction("check", "twitch")}
															onChange={e => {
																e.checked ? socialMediaAction("add", "twitch") : socialMediaAction("remove", "twitch")
															}}
														/>
														<label className="mb-0"htmlFor="twitch">
															Twitch
														</label>
													</div>
													{socialMediaAction("check", "twitch") &&
														<div className="col-12">
															<span className="boldLabel mr-1">https://www.twitch.tv/</span>
															<InputText
																disabled={!socialMediaAction("check", "twitch")}
																value={socialMediaURL("twitch")}
																onChange={(e) => {
																	socialMediaUpdate(e, "twitch");
																}}
																className="w-50"
															/>
														</div>
													}
												</div>

												{/* Youtube */}
												<div className="p-col-12 mb-2 pl-0">
													<div className="field-checkbox mb-1 col-12">
														<Checkbox
															className="mr-2 pl-0"
															inputId="youtube"
															checked={socialMediaAction("check", "youtube")}
															onChange={e => {
																e.checked ? socialMediaAction("add", "youtube") : socialMediaAction("remove", "youtube")
															}}
														/>
														<label className="mb-0"htmlFor="youtube">
															Youtube
														</label>
													</div>
													{socialMediaAction("check", "youtube") &&
														<div className="col-12">
															<span className="boldLabel mr-1">https://www.youtube.com/</span>
															<InputText
																disabled={!socialMediaAction("check", "youtube")}
																value={socialMediaURL("youtube")}
																onChange={(e) => {
																	socialMediaUpdate(e, "youtube");
																}}
																className="w-50"
															/>
														</div>
													}
												</div>

												{/* Telegram */}
												<div className="p-col-12 mb-2 pl-0">
													<div className="field-checkbox mb-1 col-12">
														<Checkbox
															className="mr-2 pl-0"
															inputId="telegram"
															checked={socialMediaAction("check", "telegram")}
															onChange={e => {
																e.checked ? socialMediaAction("add", "telegram") : socialMediaAction("remove", "telegram")
															}}
														/>
														<label className="mb-0"htmlFor="telegram">
															Telegram
														</label>
													</div>
													{socialMediaAction("check", "telegram") &&
														<div className="col-12">
															<span className="boldLabel mr-1">https://t.me/</span>
															<InputText
																disabled={!socialMediaAction("check", "telegram")}
																value={socialMediaURL("telegram")}
																onChange={(e) => {
																	socialMediaUpdate(e, "telegram");
																}}
																className="w-50"
															/>
														</div>
													}
												</div>


											</div>
										</div>
									</div>

									{/* Mobile Number */}
									<div className="p-col-12 mt-4"><h5>Mobile: {" "}</h5></div>
									<div className="p-col-10 p-justify-center pt-0">
										<div className="row">
											<div className="p-col-12 pl-0">
												<div className="p-col-12 mb-2 pl-0">
													<div className="field-checkbox mb-1 col-12">
														<Checkbox
															className="mr-2 pl-0"
															inputId="mobile"
															checked={businessCardObj.displayMobile}
															onChange={e => {
																setBusinessCardObj({
																	...businessCardObj,
																	displayMobile: e.checked
																});
															}}
														/>
														<label className="mb-0"htmlFor="mobile">
															Display Mobile Number?{" "}
															<i
																className="fas fa-info-circle informationStyle"
																data-tip=""
																data-for="mobileInfo"
															></i>{" "}
															<ReactTooltip
																id="mobileInfo"
																aria-haspopup="true"
																className="tooltipFont"
																>
																<p className="text-center">
																	Your mobile number will not appear if you did not enter it in your Profile page
																</p>
															</ReactTooltip>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>
						</div>
						<div className="p-grid p-justify-center">
							<Button
								label="Save"
								type="submit"
								onClick={() => {
									setUserObj({
										...userObj,
										businessCardJson: JSON.stringify(businessCardObj)
									})
								}}
							/>
						</div>
					</form>
				</div>
			</div>

			{/* Right component */}
			<div className="col-12 col-xl-5">
				<div className="card">
					<h1 className="pb-2">Preview
						<Button
							label="Copy Link"
							type="button"
							className="float-right"
							style={{top: "-3px"}}
							tooltip={"Copy your business card link here!"}
							tooltipOptions={{position: "top"}}
							onClick={() => {
								navigator.clipboard.writeText(window.location.href.replace('card', 'businesscard') + "/" + userObj.userID);
								SetSuccess("Link copied!");
							}}
						/>
					</h1>
					<div className="w-100 h-100 pt-4" style={{backgroundColor: "#" + businessCardObj.bgColour, minHeight: "700px"}}>
						<div className="container textalign-center pt-5 position-relative" style={{height: "95%"}}>
							<div>
								<img src={userObj.displayPicture} alt="" className="businessCardDisplay" />
								{/* all h1-h6 has an overwritten color in overrides.scss. Don't want to change it in case, so we'll be creating a new class instead */}
								<p className="m-0" style={{color: "#" + businessCardObj.nameTitleColour, fontSize: "20px", fontWeight: 700, fontFamily: "ObjectSans-Bold,sans-serif"}}>{userObj.firstName + " " + userObj.lastName}</p>
								<p className="m-0" style={{color: "#" + businessCardObj.nameTitleColour, fontFamily: "ObjectSans-Regular,sans-serif"}}>{userObj.jobTitle}</p>
							</div>

							{/* Have to individually add the column sizing, column sizing added to parent element will screw up the center position */}
							<div className="my-5">
								<div>
									<Button className="p-button-outlined mx-0 col-10 col-sm-6 col-md-5 col-lg-4 col-xl-7" style={{borderRadius: "15px", fontFamily: "ObjectSans-Bold,sans-serif", borderColor: "#" + businessCardObj.contactColour}}>
										<span className="textalign-start" style={{fontSize:"12px", color: "#" + businessCardObj.contactColour}}>E</span>
										<span className="w-100 px-2">
											<a href={"mailto:" + userObj.email} style={{fontSize:"12px", color: "#" + businessCardObj.contactColour, overflowWrap: "anywhere"}}>
												{userObj.email}
											</a>
										</span>
										<Button
											type="button"
											icon="pi pi-copy"
											onClick={() => {
												navigator.clipboard.writeText(userObj.email)}
											}
											className="p-0 border-0 bg-transparent"
											style={{color: "#" + businessCardObj.contactColour, width: "auto"}}
										/>
									</Button>
								</div>
								{(userObj.pP_MobileNumber && businessCardObj.displayMobile) &&
									<div>
										<Button className="p-button-outlined mx-0 col-10 col-sm-6 col-md-5 col-lg-4 col-xl-7" style={{marginTop: "0.45rem", borderRadius: "15px", fontFamily: "ObjectSans-Bold,sans-serif", borderColor: "#" + businessCardObj.contactColour}}>
											<span className="textalign-start" style={{fontSize:"12px", color: "#" + businessCardObj.contactColour}}>M</span>
											<span className="w-100 px-2"><a href={"tel:" + dialCode + mobileNumber} style={{fontSize:"12px", color: "#" + businessCardObj.contactColour}}>{dialCode + " " + numberSpacePadding(mobileNumber)}</a></span>
											<Button
												type="button"
												icon="pi pi-copy"
												onClick={() => {
													navigator.clipboard.writeText(dialCode + mobileNumber)}
												}
												className="p-0 border-0 bg-transparent"
												style={{color: "#" + businessCardObj.contactColour, width: "auto"}}
											/>
										</Button>
									</div>
								}
								<div>
									<Button className="p-button-outlined mx-0 col-10 col-sm-6 col-md-5 col-lg-4 col-xl-7" style={{marginTop: "0.45rem", borderRadius: "15px", fontFamily: "ObjectSans-Bold,sans-serif", borderColor: "#" + businessCardObj.contactColour}}>
										<span className="textalign-start" style={{fontSize:"12px", color: "#" + businessCardObj.contactColour}}>O</span>
										<span className="w-100 px-2"><a href="tel:+6565140510" style={{fontSize:"12px", color: "#" + businessCardObj.contactColour}}>+65 6514 0510</a></span>
											<Button
												type="button"
												icon="pi pi-copy"
												onClick={() => {
													navigator.clipboard.writeText("+6565140510")}
												}
												className="p-0 border-0 bg-transparent"
												style={{color: "#" + businessCardObj.contactColour, width: "auto"}}
											/>
									</Button>
								</div>
							</div>
								<div className="row p-justify-center mx-2" style={{filter: cssFilter}}>
									{(businessCardObj.socialMedia).map((link: SocialMediaModel) => (
										<a href={fullURL(link)} className="mx-2 my-1" rel="noopener noreferrer" target="_blank">
											<img height="24" src={iconSource(link.icon)}/>
										</a>
									))}
								</div>

							{/* Using style here until you find out the bootstrap equivalent */}
							<div className="position-absolute mt-5" style={{left: 0, right: 0}}>
								<img
									alt="Zula_Logo"
									height="50"
									src={userCompany()}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)

	let display = <ProgressSpinner />;
	if (isError) {
		display = <CustomError message={errorMsg} />
	} else {
		display = page;
	}

	return (
		<div className="row">
			{display}
		</div>
	)
}


export default BusinessCardUser
