import React from "react";
import { RouteComponentProps } from "../resources/CommonMethods";
import { InputNumber } from 'primereact/inputnumber';
import { ToastStateContext } from "../resources/ToastContext";
import { Button } from "primereact/button";

// import { InputNumber } from 'primereact/inputnumber';
// import React, { useState } from "react";
// import { RouteComponentProps } from "../resources/CommonMethods";
// import { Button } from "primereact/button";

// function Question1(props: RouteComponentProps<any>) {
//   const [numberOfCompany, setNumberOfCompany] = useState<number>(0);

//   const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     // setIsLoading(true);
//     // props.others(numberOfCompany);
//   };

//   const isValidated = () => {
//     props.others(numberOfCompany);
//     return true;
//   }

//   const question = () => {
//     return (
//       <form onSubmit={handleSubmit}>
//         <div className="p-grid p-fluid">
//           <div className="p-col-12">
//             <span className="boldLabel">No. of Companies</span>
//             <Spinner
//               formatInput={false}
//               value={numberOfCompany}
//               onChange={e => setNumberOfCompany(e.value)}
//               min={0}
//               max={100}
//               step={1}
//               required
//             />
//           </div>
//         </div>
//         {/* <div className="p-grid p-justify-center pt-2">
//           <Button type="submit" label="Next" />
//         </div> */}
//       </form>
//     );
//   };
//   return (
//     <div className="row">
//       <div className="col-12 col-md-6 col-lg-6 col-xl-6">
//         <div className="card">
//           <h1 className="pb-2"> How many companies do you have?</h1>
//           <div className="row p-justify-center">
//             <div className="col-12">{question()}</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
// export default Question1;

type AppState = {
  numberOfCompany: number;
};
export class Question1 extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      numberOfCompany: 1
    };
    this.submitMethod = this.submitMethod.bind(this);
  }

  submitMethod(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (this.state.numberOfCompany < 1) {
      this.context.SetError("You must have at least one company.");
      return false;
    }
    this.props.others(this.state.numberOfCompany);
    return true;
  }
  isValidated() {}

  render() {
    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2"> Let's get started!</h1>
            <div className="row p-justify-center">
              <div className="col-12">
                {" "}
                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <span className="boldLabel">
                      How many companies do you have?
                    </span>
                    <InputNumber
                      value={this.state.numberOfCompany}
                      onChange={e =>
                        this.setState({ numberOfCompany: e.value })
                      }
                      min={0}
                      max={100}
                      step={1}
                      required
                      showButtons
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-grid  pl-2 ">
            <Button type="submit" label="Next" onClick={this.submitMethod} />
          </div>
        </div>
      </div>
    );
  }
}
