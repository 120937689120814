import { HttpClient } from "./HttpClient";
import { WeeklyDutiesModel } from "../components/weeklyduties/WeeklyDuties";
import moment from "moment";
import { CommonMethods } from "../components/resources/CommonMethods";

export class WeeklyDutiesService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async getWeeklyDutyToday(date: Date) {
    // 2020-02-12 PCLQ: Changed from post to get request
    let utcDate = moment(date).format("YYYY-MM-DD");

    const res = await this.httpClient.get(`/api/weeklyduties/today/${utcDate}`);
    return res;
  }

  async createWeeklyDuty(weeklyDutyObj: any) {
    var form_data = new FormData();

    for (var key in weeklyDutyObj) {
      if (key === "dutyStartDate" || key === "dutyEndDate") {
        let utcDate = moment(weeklyDutyObj[key]).format(CommonMethods.DateForBackendStringFormat);
        form_data.append(key, utcDate);
      } else {
        form_data.append(key, weeklyDutyObj[key]);
      }
    }
    const res = await this.httpClient.post(
      "/api/weeklyduties/create",
      form_data
    );
    return res;
  }

  async getAllWeeklyDutiesByGCID() {
    const res = await this.httpClient.get("/api/weeklyduties/gcid");
    return res;
  }

  async getWeeklyDutyByID(id: string) {
    const res = await this.httpClient.get("/api/weeklyduties/id/" + id);
    return res;
  }

  async deleteWeeklyDutyByID(id: string) {
    const res = await this.httpClient.delete("/api/weeklyduties/" + id);
    return res;
  }
  async updateWeeklyDuty(weeklyDutyObj: any) {
    var form_data = new FormData();

    for (var key in weeklyDutyObj) {
      if (key === "dutyStartDate" || key === "dutyEndDate") {
        let utcDate = moment(weeklyDutyObj[key]).format("YYYY-MM-DD HH:mm:ss");
        form_data.append(key, utcDate);
      } else {
        form_data.append(key, weeklyDutyObj[key]);
      }
    }
    const res = await this.httpClient.post(
      "/api/weeklyduties/update",
      form_data
    );
    return res;
  }

  async deleteWeeklyDuty(ID: string) {
    const res = this.httpClient.get("/api/weeklyduties/delete/" + ID);
    return res;
  }

  async updateWeeklyDutyStatus(weeklyDutyObj: WeeklyDutiesModel) {
    const res = this.httpClient.put(
      "/api/weeklyduties/updatestatus",
      weeklyDutyObj
    );
    return res;
  }

  async filterWeeklyDutiesByDate(dateSelection: Date) {
    const dutySelectionString = moment(dateSelection).format("YYYY-MM-DD HH:mm:ss");
    const res = await this.httpClient.get(`/api/weeklyduties/gcid/${dutySelectionString}`);
    return res;
  }
}

export default WeeklyDutiesService;
