import React, { useState, useEffect } from "react";
import { RouteComponentProps, CommonMethods } from "../resources/CommonMethods";
import { ClientCompanyModel } from "./AllClientCompanies";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import CustomError from "../resources/Error";
import { Redirect } from "react-router";
import { ClientCompanyService } from "../../service/ClientCompanyService";
import { ToastStateContext } from "../resources/ToastContext";
import {
  CommonDocumentMethods,
  ProcessedImage,
} from "../resources/CommonDocumentMethods";

let clientCompanyService = new ClientCompanyService();
let commonMethods = new CommonMethods();
function ClientCompanyCreate(props: RouteComponentProps<any>) {
  const [clientCompanyData, setClientCompanyData] = useState<
    ClientCompanyModel
  >(new ClientCompanyModel());
  const [buttonLabel, setButtonLabel] = useState<string>("Create");
  const [entity, setEntity] = useState("Client Company");
  const [isError, setisError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);
  const [previewImage, setPreviewImage] = useState<any>("");

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");

  useEffect(() => {
    if (
      props.match.params.supplier !== undefined &&
      props.match.params.supplier === "supplier"
    ) {
      setEntity("Supplier");
      setRedirectPath("/finance/operational_cost");
      setClientCompanyData({ ...clientCompanyData, isSupplier: true });
    }
    if (props.match.params.id !== undefined) {
      clientCompanyService
        .getClientCompanyByID(props.match.params.id)
        .then((res) => {
          setPreviewImage(res.clientLogoImagePath);
          setClientCompanyData(res);
        })
        .catch(() => {
          setisError(true);
        });

      setButtonLabel("Update");
    }

    setIsLoading(false);
  }, []);

  const setCompanyLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e !== null &&
      e.currentTarget.files !== null &&
      e.currentTarget.files[0] !== undefined
    ) {
      const uploadedFile = e.currentTarget.files[0];
      CommonDocumentMethods.uploadImage(uploadedFile, 500, 500, "payslip")
        .then((res) => {
          setPreviewImage(res.base64);
          setClientCompanyData({
            ...clientCompanyData,
            clientLogoImagePath: uploadedFile.name,
            clientLogoImage: res.file,
          });
        })
        .catch((err: ProcessedImage) => {
          SetError(err.errorMsg);
        });
    } else {
      setClientCompanyData({
        ...clientCompanyData,
        clientLogoImagePath: "",
        clientLogoImage: "",
      });
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!commonMethods.validateEmail(clientCompanyData.clientEmail)) {
      SetError("Invalid Email Address");
    } else {
      if (buttonLabel === "Create") {
        clientCompanyService
          .createClientCompany(clientCompanyData)
          .then((res) => {
            SetSuccess(`New ${entity} Created!`);
            if (entity !== "Supplier") {
              setRedirectPath("/sales/clientcontacts/" + res.clientCompanyID);
            }
            setRedirectOut(true);
          })
          .catch((err) => {
            SetError(`Could not create new ${entity}.`);
          });
      } else if (buttonLabel === "Update") {
        clientCompanyService
          .updateClientCompany(clientCompanyData)
          .then((res) => {
            SetSuccess("Client Company Updated!");
            if (entity !== "Supplier") {
              setRedirectPath("/sales/clientcompany");
            }
            setRedirectOut(true);
          })
          .catch((err) => {
            SetError(`Could not update ${entity}.`);
          });
      }
    }
  };
  const form = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <p className="boldLabel">Company Logo</p>
            {clientCompanyData.clientLogoImagePath !== null &&
              clientCompanyData.clientLogoImagePath !== "" && (
                <div className="companyImageCropper">
                  <img src={previewImage} alt="" />
                </div>
              )}

            <input
              style={{ color: "transparent", width: "110px" }}
              type="file"
              name="displayPicture"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => setCompanyLogo(e)}
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Company Name <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={clientCompanyData.clientCompany}
              onChange={(event) =>
                setClientCompanyData({
                  ...clientCompanyData!,
                  clientCompany: event.currentTarget.value,
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">UEN Number </span>
            <InputText
              value={clientCompanyData.clientCompanyUEN}
              onChange={(event) =>
                setClientCompanyData({
                  ...clientCompanyData!,
                  clientCompanyUEN: event.currentTarget.value,
                })
              }
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel"> Email Address</span>
            <InputText
              value={clientCompanyData.clientEmail}
              onChange={(event) => {
                setClientCompanyData({
                  ...clientCompanyData!,
                  clientEmail: event.currentTarget.value,
                });
              }}
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Address </span>
            <InputText
              value={clientCompanyData.clientAddress}
              onChange={(event) =>
                setClientCompanyData({
                  ...clientCompanyData!,
                  clientAddress: event.currentTarget.value,
                })
              }
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Postal Code </span>
            <InputText
              value={clientCompanyData!.postalCode}
              onChange={(event) =>
                setClientCompanyData({
                  ...clientCompanyData,
                  postalCode: event.currentTarget.value,
                })
              }
              keyfilter="int"
              maxLength={6}
            />
          </div>
          {/* <div className="p-col-12">
            <span className="boldLabel">Industry Category </span>
            <Dropdown
              className="mb-1"
              value={clientCompanyData.industry}
              options={commonMethods.getIndustryDropdownOptions()}
              onChange={e =>
                setClientCompanyData({
                  ...clientCompanyData!,
                  industry: e.value
                })
              }
              placeholder="Please select"
            />
          </div> */}
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button type="submit" label={buttonLabel} />
        </div>
      </form>
    );
  };

  if (isLoading) {
    return <ProgressSpinner />;
  } else if (isError) {
    return <CustomError message={"An Error Has Occurred."} />;
  } else {
    return (
      <div className="row">
        {redirectOut ? <Redirect push to={redirectPath} /> : null}
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">
              {buttonLabel} {entity}
            </h1>
            {form()}
          </div>
        </div>
      </div>
    );
  }
}

export default ClientCompanyCreate;
