import { HttpClient } from "./HttpClient";
import { ClientContactsModel } from "../components/resources/ExportClass";


export class ClientContactsService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
    }
    
    async getListofClientContacts(clientcompanyid: string) {        
        const res = this.httpClient.get("/api/clientcontacts/company/" + clientcompanyid);
        return res;
    }

    async getClientContactByID(id: string) {
        const res = this.httpClient.get("/api/clientcontacts/" + id);
        return res;
    }    
    
    async createClientContact(clientContactsObj: ClientContactsModel)
    {        
        const res = this.httpClient.post("/api/clientcontacts/create", clientContactsObj);
        return res;
    }

    async updateClientContact(clientContactsObj: ClientContactsModel)
    {
        
        const res = this.httpClient.put("/api/clientcontacts/update", clientContactsObj);
        return res;
    }

    async updateClientContactStatus(clientContactsObj: ClientContactsModel) {
        const res = this.httpClient.put("/api/clientcontacts/updatestatus", clientContactsObj);
        return res;
    }

 
}
