import React from "react";

function PrivacyPolicy() {
  return (
    <div>
      <section
        className="fdb-block"
        data-block-type="call_to_action"
        data-id="2"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Privacy Policy</h1>
            </div>
          </div>

          <div className="row align-items-center pt-2 pt-lg-5">
            <p>
              NeuHR respects your privacy and is committed to protecting your
              personal information.
            </p>{" "}
            <p>
              This Data Protection Policy (“Policy”) sets out the basis upon
              which NeuHR (“we”, “us” or “our”) may collect, use, disclose or
              otherwise process personal data of individuals in accordance with
              the Personal Data Protection Act (“PDPA”).
            </p>
            <p>
              {" "}
              <b>1. PDPA INTRODUCTION</b>
            </p>
            <p>
              1.1 “Personal Data” is defined by the PDPA as data, whether true
              or not, about an individual who can be identified from that data,
              or from that data and other information to which an organization
              has or is likely to have access. Common examples of personal data
              could include names, identification numbers, contact information,
              medical records and photographs.
            </p>
            <p>
              1.2 We will only collect your personal data in accordance with the
              PDPA. We will obtain your consent for the collection, use,
              disclosure and/or processing of your personal data for the
              intended purposes, unless an exception under the law permits us to
              collect and process your personal data without your consent.
            </p>
            <p>
              <b>
                2. PURPOSES FOR COLLECTION, USE, DISCLOSURE AND PROCESSING OF
                PERSONAL DATA
              </b>
            </p>
            <p>
              2.1 The personal data we collect may be used, disclosed and/or
              processed for various purposes, depending on the circumstances,
              which include but are not limited to:{" "}
              <ol type="a">
                <li>
                  Administrative matters on the service purchases and/or
                  subscriptions, managing your accounts, processing your
                  signups/registrations for mailing lists, etc.
                </li>

                <li>
                  Facilitating NeuHR’s ability to provide you with the products
                  and services which you use
                </li>

                <li>
                  Managing your enquiries and instructions, sending you
                  marketing and/or promotional information and materials
                  relating to products and/or services that we are selling or
                  marketing.
                </li>

                <li>
                  Conducting market research and/or customer satisfaction
                  surveys for NeuHR; and
                </li>

                <li>
                  Any other purposes that we notify you of at the time of
                  obtaining your consent. (collectively, the “Purposes”)
                </li>
              </ol>
            </p>
            <p>
              <b>
                3. SPECIFIC ISSUES FOR THE DISCLOSURE OF PERSONAL DATA TO THIRD
                PARTIES
              </b>
            </p>
            <p>
              3.1 We respect that the personal data you have provided us is
              confidential. With that in mind, we will not disclose your
              personal data to other third parties without obtaining your
              permission first.
            </p>
            <p>
              3.2 However, we may disclose your personal data to other third
              parties without obtaining your permission first in certain
              situations which include but are not limited to: situations in
              which the disclosure is required or authorized based on the law
              and/or regulations and/or permitted by the PDPA or law situations
              in which the disclosure is necessary to respond to an emergency
              that threatens the life, health or safety of any individual;
              situations in which the disclosure is compulsory for any
              investigation or proceedings situations in which the disclosure is
              to a public agency and such disclosure is necessary in the public
              interest;
            </p>
            <p>
              3.3 If we disclose your personal data to third parties with your
              consent, we will ensure the best efforts are taken to provide
              adequate supervision over the handling of your personal data by
              such third parties to protect your personal data.
            </p>
            <p>
              <b>4. REQUEST FOR ACCESS AND/OR CORRECTION OF PERSONAL DATA</b>
            </p>
            <p>
              4.1 You may request to access and/or correct your personal data
              that is in our possession by submitting a written request to us.
              Please contact us for further information about how to access your
              personal data.
            </p>
            <p>
              4.2 When you make a request to access your personal data, we will
              request certain information from you in order to determine your
              identity with certainty. Once we have sufficient information, we
              will respond to your request within 30 days. Please note that the
              PDPA exempts certain types of personal data from being subject to
              your request to access it.
            </p>
            <p>
              4.3 Please note that we may have to withhold access to your
              Personal Data in certain situations, for example when we are
              unable to confirm your identity.
            </p>
            <p>
              4.4 There may be a small but reasonable charge for the handling
              and processing of your requests to access and/or correct your
              personal data. We will provide you a written estimate of the
              charges, and we are not required to respond to or deal with your
              request unless you have agreed to pay the charge.
            </p>
            <p
              style={{
                width: "-webkit-fill-available",
              }}
            >
              <b>5. REQUEST TO WITHDRAW CONSENT </b>
            </p>
            <p>
              5.1 If you do not agree with this Policy, please DO NOT continue
              to use or access the Services.
            </p>
            <p>
              5.2 If you choose to withdraw your consent to any or all or the
              disclosure of your personal data, please note that we may not be
              in a position to continue providing some of our services to you.
              We will process your request within a reasonable time from such a
              request for withdrawal of consent being made, and will thereafter
              refrain from collecting, using and/or disclosing your personal
              data in the manner stated in your request.
            </p>
            <p>
              {" "}
              <b>6. STORAGE AND SECURITY OF PERSONAL DATA</b>
            </p>
            <p>
              6.1 NeuHR takes breaches of privacy very seriously. Our employees
              are trained to handle the personal data securely and with utmost
              respect and confidentiality, failing which they may be subject to
              disciplinary action.
            </p>
            <p>
              6.2 We will also ensure that your personal data is adequately
              protected and secured. We will place reasonable security
              arrangements to prevent any unauthorized access, collection, use,
              alteration, disclosure, loss and/or damage of your personal data.
              However, we do not take responsibility for unauthorized access or
              use of your personal data by third parties that are wholly due to
              factors that are beyond our control.
            </p>
            <p>
              {" "}
              <b>7. COOKIES</b>
            </p>
            <p>
              7.1 We use cookies for the following purposes: to improve the your
              browsing experience; collect analytics data, or use third-party
              analytics tool to assist us in monitoring traffic and usage trends
              of the website; to assist the website in functioning properly.
            </p>
            <p>
              7.2 You may control which cookies you want to enable or disable
              via your web browser’s settings. If you block the cookies on our
              website, you should note that you might not be able to access the
              full range of functions that our website offers.
            </p>
            <p>
              7.3 Personal Data that we collect from you through our cookies may
              be passed onto third party service providers (whether within or
              outside Singapore), for managing or improving our website, for
              data hosting or backup or for one or more of the purposes set out
              in 7.1 above.
            </p>
            <p>
              {" "}
              <b> 8. CHANGES/UPDATES ON DATA PROTECTION POLICY </b>
            </p>
            <p>
              8.1 As part of our efforts to ensure that we properly manage,
              protect and process your personal data, we will be reviewing our
              policies, procedures and processes from time to time. We encourage
              you to periodically review this privacy statement to stay informed
              about how we are helping to protect the personal information we
              collect.
            </p>
            <p>
              8.2 We reserve the right to make changes to this policy at its
              sole discretion. Any changes made in this manner will be posted on
              this page.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
