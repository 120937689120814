import React, { useEffect, useState } from "react";

import { UserService } from "../../service/UserService";
import {
  CommonMethods,
  RouteComponentProps,
  DropdownModel,
} from "../resources/CommonMethods";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import CustomError from "../resources/Error";
import { Dropdown } from "primereact/dropdown";
import _ from "lodash";

function CompanyBirdseyeView(props: RouteComponentProps<any>) {
  const userService = new UserService();
  let commonMethods = new CommonMethods();

  const [departments, setDepartments] = useState([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [companyName, setCompanyName] = useState<string>("");
  const [openTabIndexes, setOpenTabIndexes] = useState<any>([]);

  const [companyDropdown, setCompanyDropdown] = useState<Array<DropdownModel>>(
    new Array<DropdownModel>()
  );
  const [selectedCompany, setSelectedCompany] = useState<string>(
    props.userProfile.companyID
  );

  const openAllAccordionTabs = () => {
    if (openTabIndexes.length === 0) {
      setOpenTabIndexes([...Array(departments.length).keys()]);
    } else {
      setOpenTabIndexes([]);
    }
  };

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    commonMethods
      .getCompanyDropdownListByGcid()
      .then((res) => {
        if (myAbortController.signal.aborted) {
          return;
        }
        setCompanyDropdown(res);

        // init name
        setCompanyName(_.find(res, ["value", selectedCompany])?.label || "-")

      })
      .catch((err) => {
        if (myAbortController.signal.aborted) {
          return;
        }
        const error = commonMethods.getErrorMessage(err);
        setErrorMessage(error);
        setIsError(true);
      });

    return () => {
      myAbortController.abort();
    };
  }, []);

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    // commonMethods
    //   .getCompanyNameFromID(selectedCompany)
    //   .then((res) => {
    //     if (myAbortController.signal.aborted) {
    //       return;
    //     }
    //     setCompanyName(res);
    //   })
    //   .catch((err) => {
    //     if (myAbortController.signal.aborted) {
    //       return;
    //     }
    //     const error = commonMethods.getErrorMessage(err);
    //     setErrorMessage(error);
    //     setIsError(true);
    //   });
    userService
      .getAllActiveUsersInCompany(selectedCompany)
      .then((depts) => {
        if (myAbortController.signal.aborted) {
          return;
        }
        setDepartments(depts);
        setOpenTabIndexes([...Array(depts.length).keys()]);
        setIsLoading(false);
      })
      .catch((err) => {
        if (myAbortController.signal.aborted) {
          return;
        }
        const error = commonMethods.getErrorMessage(err);
        setErrorMessage(error);
        setIsError(true);
      });

      return () => {
        myAbortController.abort();
      };
  }, [selectedCompany]);

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMessage} />;
    } else {
      return (
        <>
          <div className="row">
            <div className="col-12">
              <span className="boldLabel">Company</span>
              <div>
                <Dropdown
                  tooltip="Change Campaign Status"
                  tooltipOptions={{ position: "top" }}
                  value={selectedCompany}
                  options={companyDropdown}
                  appendTo={document.body}
                  onChange={(e) => {
                    setIsLoading(true);
                    setSelectedCompany(e.value);
                    setCompanyName(_.find(companyDropdown, ["value", e.value])?.label || "-")
                  }}
                  placeholder="Please Select"
                />

             
                <Button
                  label="All"
                  type="button"
                  iconPos="left"
                  icon="fas fa-angle-double-down"
                  className="p-button-primary editButtonFloatRight"
                  onClick={(e) => openAllAccordionTabs()}
                />
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <Accordion
              multiple
              activeIndex={openTabIndexes}
              onTabChange={(e) => setOpenTabIndexes(e.index)}
              className="row"
            >
              {Object.values(departments).map((value: any) => (
                <AccordionTab
                  header={value["departmentName"]}
                  headerClassName="col-lg companyOverview"
                  contentClassName="col-lg"
                >
                  {value["staff"].map(
                    (user: {
                      userID: string;
                      displayPicture: string | undefined;
                      firstName: string;
                      lastName: string;
                      jobTitle: string;
                    }) => (
                      <div className="row py-2" key={user.userID}>
                        <div className="imagecropper-small">
                          <img src={user.displayPicture} alt="" />
                        </div>
                        <div className="col-8 col-lg-8 namePadding">
                          <strong>
                            {user.firstName} {user.lastName}
                          </strong>
                          <br />
                          {user.jobTitle}
                        </div>
                      </div>
                    )
                  )}
                </AccordionTab>
              ))}
            </Accordion>
          </div>
        </>
      );
    }
  };

  // Render all the cards
  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">
            Company Structure {companyName !== "" ? "of " + companyName : ""}
            {"  "}
          </h1>
          {display()}
        </div>
      </div>
    </div>
  );
}

export default CompanyBirdseyeView;
