import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { TaxFilingService } from "../../service/TaxFilingService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { TaxSubmissionResults } from "./TaxFilingModel";

export default function SubmissionDashboard(props: RouteComponentProps) {
  const taxFilingService = new TaxFilingService();
  const commonMethods = new CommonMethods();
  const headerID: string = props.others;
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const [isBodyLoading, setIsBodyLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(
    "An unidentified error has occurred"
  );
  const [submissionTable, setSubmissionTable] = useState<
    Array<TaxSubmissionResults>
  >(new Array<TaxSubmissionResults>());

  useEffect(() => {
    if (headerID !== null) {
      loadSubmission();
    }
  }, []);

  const loadSubmission = () => {
    try {
      taxFilingService.getAllSubmissionDetails(headerID).then((res) => {
        setSubmissionTable(res);
        setIsLoading(false);
      });
    } catch (e) {
      setErrorMsg(commonMethods.getErrorMessage(e));
      setIsError(true);
      setIsLoading(false);
    }
  };

  const dateFormat = (rowData: TaxSubmissionResults) => {
    return commonMethods.displayDate(rowData.createdTime);
  };

  const headerStatus = (rowData: TaxSubmissionResults) => {
    let status = rowData.isSuccessfulContent ? "Success" : "Failed";
    return status;
  };

  const actionTemplate = (rowData: TaxSubmissionResults) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="View Submission Details"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-eye"
          className="p-button-primary mr-1"
          onClick={() => {
            setRedirectOut(true);
            setRedirectPath(
              "/tax_filing/submit/success?id=" + rowData.taxSubmissionID
            );
          }}
        />
      </div>
    );
  };

  const bodyDisplay = () => {
    return (
      <div>
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={submissionTable}
            emptyMessage="No submissions have been made."
            autoLayout={true}
            alwaysShowPaginator={false}
            // paginator={true}
            rows={3}
            sortField="createdTime"
            sortOrder={-1}
          >
            <Column body={headerStatus} header="Status" />
            {/* <Column field="submittedByName" header="Submitted By" /> */}
            <Column body={dateFormat} field="createdTime" header="Run On" />
            <Column body={actionTemplate} header="Actions" />
            {/* <Column field="createdTime" className="d-none" /> */}
          </DataTable>
        </div>
      </div>
    );
  };

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = (
      <div className="text-center">
        <ProgressSpinner />
      </div>
    );
  } else {
    display = bodyDisplay();
  }

  return (
    <div className="row">
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      <div className="col-12 col-xl-12">
        <h2 className="pb-2">Submissions</h2>
        {display}
      </div>
    </div>
  );
}
