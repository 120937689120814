import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import ReactTooltip from "react-tooltip";
import { TaxFilingService } from "../../service/TaxFilingService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import {
  TaxBodyCreationModel,
  TaxBodyModel,
  TaxFilingHeader,
  TaxHeaderStatus,
} from "./TaxFilingModel";
import TaxTrailerDetails from "./TaxTrailerDetails";

export default function TaxBodyDetails(props: RouteComponentProps) {
  const taxFilingService = new TaxFilingService();
  const commonMethods = new CommonMethods();

  const { SetError, SetSuccess } = React.useContext(ToastStateContext);
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(
    "An unidentified error has occurred"
  );

  const [submissionErrorMsg, setSubmissionErrorMsg] = useState<string>("");

  const [dialogStr, setDialogStr] = useState<string>("");
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [tableDataChanged, setTableDataChanged] = useState(false);

  const [details, setDetails] = useState<Array<TaxBodyModel>>(
    new Array<TaxBodyModel>()
  );
  const [taxHeaderId, setTaxHeaderId] = useState<string>("");
  // const [taxItem, setTaxItem] = useState<TaxBodyModel>(new TaxBodyModel());
  const [header, setHeader] = useState<TaxFilingHeader>(new TaxFilingHeader());

  const [employeeDropdown, setEmployeeDropdown] = useState<any[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);

  useEffect(() => {
    if (props.others.taxHeaderId !== "" && props.others.taxHeaderId !== null) {
      setIsLoading(true);
      setTaxHeaderId(props.others.taxHeaderId);
    }
  }, [props.others.taxHeaderId]);

  useEffect(() => {
    if (taxHeaderId !== "") {
      loadBody();
    }
  }, [taxHeaderId]);

  useEffect(() => {
    if (taxHeaderId !== "" && taxHeaderId !== null) {
      loadBody();
    }
  }, [tableDataChanged]);

  useEffect(() => {
    if (details !== []) {
      for (let body in details) {
        selectedEmployees.push(details[body].userID);
      }
    }
  }, [details]);

  const loadBody = async () => {
    try {
      setIsLoading(true);
      await taxFilingService.getEmployeeList(taxHeaderId).then((res) => {
        setEmployeeDropdown(res);
      });
      await taxFilingService.getAllBodyDetails(taxHeaderId).then((res) => {
        // selectEmployees(res);
        setSelectedEmployees([]);
        setDetails(res);
        setIsLoading(false);
      });
      await taxFilingService
        .getHeaderDetails(taxHeaderId)
        .then((res: TaxFilingHeader) => {
          setHeader(res);
        });
    } catch (e) {
      setErrorMsg(commonMethods.getErrorMessage(e));
      setIsError(true);
      setIsLoading(false);
    }
  };

  // const selectEmployees = (result: any) => {
  //   for (let body in result) {
  //     selectedEmployees.push(result[body].userID);
  //   }
  // };

  const createTaxBody = () => {
    try {
      let obj = new TaxBodyCreationModel();
      obj.users = selectedEmployees;
      obj.taxheaderid = taxHeaderId;

      taxFilingService.createBody(obj).then((res) => {
        setTableDataChanged(!tableDataChanged);

        SetSuccess(details.length === 0 ? "Successfully created" : "Successfully updated");
      });
    } catch (e) {
      SetError(commonMethods.getErrorMessage(e));
    }
  };

  const setDialog = (dialogHeader: string, content: string) => {
    setConfirmStr(dialogHeader);
    setDialogStr(content);
  };

  // const updateBody = () => {
  //   taxFilingService
  //     .updateBodyDetails(details, taxHeaderId)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       setErrorMsg(commonMethods.getErrorMessage(err));
  //       setIsError(true);
  //     });
  // };

  // const resetBody = () => {
  //   taxFilingService
  //     .resetBody(taxHeaderId)
  //     .then((res) => {
  //       setTableDataChanged(!tableDataChanged);
  //       setDialogVisibility(false);
  //       SetSuccess(`Page has been reset.`);
  //     })
  //     .catch((err) => {
  //       setDialogVisibility(false);
  //       setErrorMsg(commonMethods.getErrorMessage(err));
  //       setIsError(true);
  //     });
  // };

  // const deletePerson = () => {
  //   taxFilingService
  //     .deletePerson(taxItem.taxBodyID)
  //     .then((res) => {
  //       setTableDataChanged(!tableDataChanged);
  //       setDialogVisibility(false);
  //       SetSuccess(`${taxItem.fullName} has been deleted.`);
  //     })
  //     .catch((err) => {
  //       setDialogVisibility(false);
  //       setErrorMsg(commonMethods.getErrorMessage(err));
  //       setIsError(true);
  //     });
  // };

  const submitBody = () => {
    setIsLoading(true);
    taxFilingService
      .GetCorppassRedirectUrl(taxHeaderId)
      .then((res) => {
        setDialogVisibility(false);
        // setRedirectOut(true);
        // setRedirectPath(res);
        window.location.replace(res);
      })
      .catch((err) => {
        setSubmissionErrorMsg(commonMethods.getErrorMessage(err));
        setDialogVisibility(false);
        setIsLoading(false);
        SetError("Unable to submit until all errors are cleared.");
      });
  };

  var dataExport: DataTable | null = null;
  const exportAsExcel = () => {
    if (details.length === 0 || dataExport === null) {
      return SetError("Table is empty")
    } else {
      dataExport.exportCSV();
    }
  }

  const errorTemplate = (rowData: TaxBodyModel) => {
    if (rowData.errorMsg !== null && rowData.errorMsg !== "") {
      return (
        <div data-tip="" data-for="err">
          {rowData.errorMsg}{" "}
          {/* <i
            className="fas fa-info-circle informationStyle"
            data-tip=""
            data-for="err"
          ></i>{" "} */}
          <ReactTooltip id="err" aria-haspopup="true" className="tooltipFont">
            <p>Delete and re-add employee after fixing the error.</p>
          </ReactTooltip>
        </div>
      );
    }
    return <div></div>;
  };

  const actionTemplate = (rowData: TaxBodyModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Edit Employee Details"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          disabled={header.status === TaxHeaderStatus.SUBMITTED}
          onClick={() => {
            // setTaxItem(rowData);
            setRedirectOut(true);
            setRedirectPath("/finance/tax_filing/update/" + rowData.taxBodyID);
          }}
        />
      </div>
    );
  };

  const dateDisplay = (rowData: any, col: any) => {
    return commonMethods.displayDate(rowData[col.field]);
  };

  const bodyDisplay = () => {
    let tableheader = (
      <div style={{ textAlign: "left" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            onInput={(e: any) => setSearchValue(e.currentTarget.value)}
          />
        </span>
      </div>
    );

    const employeeList = (
      <div className="col-12">
        <div className="boldLabel">
          Select Employees to generate IRAS{" "}
          <i
            className="fas fa-info-circle informationStyle"
            data-tip=""
            data-for="selectEmployees"
          ></i>{" "}
          <ReactTooltip
            id="selectEmployees"
            aria-haspopup="true"
            className="tooltipFont"
          >
            <p>Check to add employees, uncheck to delete employees.</p>
            <p>Click "Create" to create a list of selected employees.</p>
          </ReactTooltip>
        </div>
        <div className="row m-0 pb-4">
          <MultiSelect
            value={selectedEmployees}
            options={employeeDropdown}
            onChange={(e) => {
              setSelectedEmployees(e.value);
            }}
            filter={true}
            placeholder="Please Select"
            scrollHeight="500px"
          />
          <Button
            type="button"
            label={details.length === 0 ? "Create" : "Update"}
            onClick={createTaxBody}
          />
        </div>
      </div>
    );

    const body = (
      <div className="col-12 datatable-centerHeader datatable-centerContent">
        <DataTable
          value={details}
          emptyMessage="No results found."
          autoLayout={true}
          header={tableheader}
          globalFilter={searchValue}
          alwaysShowPaginator={false}
          paginator={true}
          rows={15}
          ref={(e) => {dataExport = e}}
          exportFilename={"TaxBody_" + header.incomeYear + "_" + header.fileType + "_" + header.organizationName}
        >
          {/* show */}
          <Column field="fullName" header="Name" />
          <Column field="dateOfBirth" body={dateDisplay} header="D.O.B" />
          {/* hidden */}
          <Column field="nationality" className="d-none" header="Nationality Code" />
          <Column field="nationalityName" className="d-none" header="Nationality"/>
          <Column field="gender" className="d-none" header="Gender"/>
          <Column field="employeeIDType" className="d-none" header="Employee ID Type Code"/>
          {/* show */}
          <Column field="employeeIDTypeName" header="ID Type" />
          {/* hidden */}
          <Column field="bankCode" className="d-none" header="Bank Code"/>
          <Column field="bankCodeName" className="d-none" header="Bank Name"/>
          <Column field="designation" className="d-none" header="Designation"/>
          {/* show */}
          <Column field="employeeIDNumber" header="ID No." />
          <Column field="fromDate" body={dateDisplay} header="From" />
          <Column field="toDate" body={dateDisplay} header="To" />
          {/* hidden */}
          <Column field="dateOfCommencement" className="d-none" header="Date of Commencement" />
          <Column field="dateOfCessation" className="d-none" header="Date of Cessation" />
          <Column field="cessationProvisionsIndicator" className="d-none" header="Cessation Provisions Indicator" />
          <Column field="appendix8AIndicator" className="d-none" header="Appendix 8A Indicator" />
          <Column field="formIR8SIndicator" className="d-none" header="IR8S Form Indicator" />
          {/* show */}
          <Column field="amount" header="Amount" />
          {/* hidden */}
          <Column field="mosqueBuildingFund" className="d-none" header="Mosque Building Fund"/>
          {/* show */}
          <Column field="donation" header="Donation" />
          <Column field="cpf" header="Employee CPF" />
          {/* hidden */}
          <Column field="employeeCompulsoryContributionFundName" className="d-none" header="Employee's Compulsory Contribution Fund Name"/>
          <Column field="lifeInsurancePremiums" className="d-none" header="Life Insurance Premiums"/>
          {/* show */}
          <Column field="salary" header="Salary" />
          <Column field="bonus" header="Bonus" />
          {/* hidden */}
          <Column field="bonusDateOfDeclaration" className="d-none" header="Bonus Date of Declaration"/>
          <Column field="directorsFee" className="d-none" header="Director's Fee"/>
          <Column field="directorsFeeDateOfApproval" className="d-none" header="Director's Fee Date of Approval"/>
          <Column field="others" className="d-none" header="Others"/>
          <Column field="gratuity_NoticePay_Exgratiapayment" className="d-none" header="Gratuity/Notice Pay/Ex-gratia Payment Indicator" />
          <Column field="gratuity_NoticePay_ExgratiapaymentAmount" className="d-none" header="Gratuity/Notice Pay/Ex-gratia Payment Amount"/>
          {/* show */}
          <Column field="grossCommission" header="Commissions" />
          {/* hidden */}
          <Column field="grossCommissionPeriodFrom" className="d-none" header="Gross Commission Period From"/>
          <Column field="grossCommissionPeriodTo" className="d-none" header="Gross Commission Period To"/>
          <Column field="grossCommissionIndicator" className="d-none" header="Gross Commission Indicator"/>
          <Column field="allowanceTransport" className="d-none" header="Transport Allowance"/>
          <Column field="allowanceEntertainment" className="d-none" header="Entertainment Allowance"/>
          <Column field="allowanceOther" className="d-none" header="Other Allowances"/>
          <Column field="pension" className="d-none" header="Pension"/>
          <Column field="compensationForLossOfOfficeIndicator" className="d-none" header="Compensation for Loss of Office Indicator"/>
          <Column field="compensationForLossOfOfficeApprovalObtained" className="d-none" header="Compensation for Loss of Office Approval"/>
          <Column field="compensationForLossOfOfficeDateOfApproval" className="d-none" header="Compensation for Loss of Office Date of Approval"/>
          <Column field="compensationForLossOfOffice" className="d-none" header="Compensation for Loss of Office Amount"/>
          <Column field="retirement" className="d-none" header="Retirement"/>
          <Column field="reirementFrom1993" className="d-none" header="Retirement from 1993"/>
          <Column field="retirementFundName" className="d-none" header="Retirement Fund Name"/>
          <Column field="contributionsByEmployerToOutsideSg" className="d-none" header="Contributions made by Employer outside of Singapore"/>
          <Column field="excessVoluntaryCPFContributionByEmployer" className="d-none" header="Excess Voluntary CPF Contribution by Employer"/>
          <Column field="gainsAndProfitFromShareOptionsFromS101b" className="d-none" header="Gains and Profits from Share Options from S101b"/>
          <Column field="gainsAndProfitFromShareOptionsFromS101g" className="d-none" header="Gains and Profits from Share Options from S101g"/>
          <Column field="valueOfBenefitsInKind" className="d-none" header="Value of Benefits in Kind"/>
          <Column field="exemptIncomeIndicator" className="d-none" header="Exempt Income Indicator"/>
          <Column field="exemptIncomeAmount" className="d-none" header="Exempt Income Amount"/>
          <Column field="employmentIncomeTaxBorneByEmployerAmount" className="d-none" header="Employment Income Tax borne by Employer"/>
          <Column field="incomeTaxBorneByEmployeeAmount" className="d-none" header="Employment Income Tax borne by Employee"/>
          <Column field="employmentIncomeTaxBorneByEmployerIndicator" className="d-none" header="Employment Income Tax borne by Employer Indicator" />
          <Column field="createdTime" className="d-none" header="Created at"/>

          {/* show */}
          <Column field="errorMsg" body={errorTemplate} header={"Errors"} />
          <Column body={actionTemplate} header="Actions" />
        </DataTable>

        <div className="row p-justify-center">
            <div className="p-grid p-justify-center pt-4">
              {header.status !== TaxHeaderStatus.SUBMITTED && (
              <span className="px-1 mb-2">
                <Button
                  onClick={() => {
                    setDialog(
                      `Submit to IRAS`,
                      `This page can no longer be edited once submitted. You will be redirected to Corppass login page.`
                    );
                    setDialogVisibility(true);
                  }}
                  label={"Submit to IRAS"}
                />
              </span>
              )}
              <span className="px-1">
                <Button
                  className="button-primary"
                  tooltip="Download Tax Body as excel."
                  tooltipOptions={{ position: "top" }}
                  label="Download as Excel"
                  icon="pi pi-download"
                  iconPos="left"
                  onClick={exportAsExcel}
                />
              </span>
            </div>
        </div>
      </div>
    );

    const trailerDisplay = (
      <div className="col-12 col-lg-6 offset-lg-6">
        <TaxTrailerDetails {...props} others={taxHeaderId} />
      </div>
    );

    const errDisplay = <p style={{ color: "red" }}>{submissionErrorMsg}</p>;

    return (
      <div className="row">
        <div className="col-12">
          <p>
            This page is intended for users to fill in the values that are not
            included in pay elements but required for IRAS, and to verify and
            edit necessary details if required.
          </p>
        </div>

        {header.status !== TaxHeaderStatus.SUBMITTED && employeeList}

        {submissionErrorMsg !== "" && errDisplay}

        {details.length !== 0 && body}
        {details.length !== 0 && trailerDisplay}
      </div>
    );
  };

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = (
      <div className="text-center">
        <ProgressSpinner />
      </div>
    );
  } else {
    display = bodyDisplay();
  }

  return (
    <div className="row">
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      {/* Change to submit button dialog */}
      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={submitBody}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>

      <div className="col-12 col-xl-12">
        <h2 className="pb-2">IRAS Employee Details</h2> {display}
      </div>
    </div>
  );
}
