import { Button } from "primereact/button";
import { PickList } from "primereact/picklist";
import { ProgressSpinner } from "primereact/progressspinner";
import * as React from "react";
import { CompanyService } from "../../service/CompanyService";
import { RoleService } from "../../service/RoleService";
import { UserService } from "../../service/UserService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError  from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";

interface AppState {
  roleListTarget: Array<PickListModel>;
  roleListSource: Array<PickListModel>;
  roleResultList: SettingResultsModel;
  userDetails: UserDetailsModel;
  userName: string;
  userID: string;
  roleName: string;
  userRoleSettingAvailable: boolean;
  displayRoleAccessButtons: boolean;

  isLoading: boolean;
  isError: boolean;
  errorMsg: string;

  isUserDetailsLoaded: boolean;
  isRolePartLoaded: boolean;

  companyId: string;
  companyIdChanged: boolean;
}

export class PickListModel {
  public name: string = "";
  public code: string = "";
}

export class SettingResultsModel {
  public selectedList: string[] = [];
  public notSelectedList: string[] = [];
  public userID: string = "";
}

export class UserDetailsModel {
  public userCompanySettingsID: string = "";
  public companyID: string = "";
  public companyName: string = "";
  public groupCompanyID: string = "";
  public userID: string = "";
  public userName: string = "";
  public roleID: string = "";
}

export class UserRoleSetting extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  companyService: CompanyService;
  userService: UserService;
  roleService: RoleService;
  commonMethods: CommonMethods;
  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      roleListTarget: new Array<PickListModel>(),
      roleListSource: new Array<PickListModel>(),
      roleResultList: new SettingResultsModel(),
      userDetails: new UserDetailsModel(),
      userName: "",
      userID: "",
      roleName: "",
      userRoleSettingAvailable: false,
      displayRoleAccessButtons: false,
      isError: false,
      isLoading: true,
      errorMsg: "",
      isRolePartLoaded: false,
      isUserDetailsLoaded: false,
      companyId: "",
      companyIdChanged: false
    };

    this.companyService = new CompanyService();
    this.userService = new UserService();
    this.roleService = new RoleService();
    this.commonMethods = new CommonMethods();
    this.submitRoleForm = this.submitRoleForm.bind(this);
    this.getUserRolesFromDB = this.getUserRolesFromDB.bind(this);
  }

  componentDidMount() {
    this.initUserDetails();
  }

  initUserDetails() {
    if (!this.state.isUserDetailsLoaded) {
      if (
        this.props.match.params !== {} &&
        this.props.match.params.id !== undefined
      ) {
        var userIDfromQuery = this.props.match.params.id;
        this.userService
          .getDetailsByUserID(userIDfromQuery)
          .then(res => {
            this.setState({
              userDetails: res,
              userName: res.userName,
              userID: res.userID,
              isUserDetailsLoaded: true,
              roleName: res.roleName
            });
          })
          .catch(error => {
            let errorMsg = this.commonMethods.getErrorMessage(error);
            this.setState({
              isError: true,
              isLoading: false,
              errorMsg: errorMsg
            });
          });
      }
    }
  }

  componentDidUpdate(prevProps: RouteComponentProps<any>) {
    if (this.commonMethods.isPropsLoaded(this.props)) {
      this.updateAndNotify();
    }
  }

  updateAndNotify() {
    // check permissions to view, edit and save - User - Multi Role part - on first load
    if (
      !this.state.isRolePartLoaded &&
      this.state.isUserDetailsLoaded 
      // &&
      // this.commonMethods.isArrayPresentInArray(
      //   this.props.userProfile.taskList,
      //   [TaskList.userroleaccess]
      // )
    ) {
      this.getUserRolesFromDB(this.state.userDetails.companyID);
      this.setState({ isRolePartLoaded: true, displayRoleAccessButtons: true });
    }

    // when companyid changes at dropdown
    // if (this.state.companyIdChanged) {
    //   this.getUserRolesFromDB(this.state.companyId);
    // }
  }

  assignToSourceAndTarget(
    roleListTarget: Array<PickListModel>,
    roleListSource: Array<PickListModel>,
    roleListUser: any
  ) {
    for (let i = 0; i < roleListSource.length; i++) {
      for (let index in roleListUser) {
        if (roleListSource[i].code === roleListUser[index]) {
          roleListTarget.push(roleListSource[i]);

          if (roleListUser[index] === this.state.userDetails.roleID) {
            // remove primary role from the assigned list
            roleListTarget.pop();
            // this.setState({
            //   roleName: roleListSource[i].name
            // });
          }
          roleListSource.splice(i, 1);
          i -= 1;
          break;
        }
      }
    }

    if (roleListSource.length > 0 || roleListTarget.length > 0) {
      this.setState({ userRoleSettingAvailable: true });
    } else {
      this.setState({ userRoleSettingAvailable: false });
    }
    this.setState({
      roleListSource: roleListSource,
      roleListTarget: roleListTarget,
      isLoading: false
    });
  }

  getUserRolesFromDB(companyId: string) {
    // get all roles available
    this.commonMethods
      .getRolePickListDropdownList()
      .then(roleDropdownRes => {
        let roleListSource = roleDropdownRes;
        this.userService.getRolesByUserID(this.state.userID).then(res => {
          let roleListUser = res.roleList;
          // assign to source (available companies) and target (assigned companies)
          let roleListTarget = new Array<PickListModel>();
          this.assignToSourceAndTarget(
            roleListTarget,
            roleListSource,
            roleListUser
          );
          // this.setState({ companyIdChanged: false });
        });
      })
      .catch(error => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg
        });
      });
  }

  roleSettingTemplate(role: any) {
    return (
      <div className="p-clearfix">
        <div style={{ fontSize: "14px", margin: "15px 5px 0 0" }}>
          {role.name}
        </div>
      </div>
    );
  }

  setTargetFromSourceRole(event: any) {
    this.setState({
      roleListSource: event.source,
      roleListTarget: event.target
    });
  }

  submitRoleForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // to prevent page from refreshing!

    let resultObj = new SettingResultsModel();
    for (var i in this.state.roleListTarget) {
      resultObj.selectedList[i] = this.state.roleListTarget[i].code;
   
    }
    // Put back user's primary roleID into selectedList
    resultObj.selectedList.push(this.state.userDetails.roleID);

    for (var index in this.state.roleListSource) {
      resultObj.notSelectedList[index] = this.state.roleListSource[index].code;
    }

    resultObj.userID = this.state.userID;
    this.setState({ roleResultList: resultObj });

    this.userService
      .updateUserRoleSetting(resultObj)
      .then(res => {
        let msg = "Role has been updated";
        this.context.SetSuccess(msg);
      })
      .catch(error => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.context.SetError(errorMsg);
        this.setState({
          isLoading: false
        });
      });
  }

  render() {
    let multiRoleSaveButton;
    if (this.state.displayRoleAccessButtons) {
      multiRoleSaveButton = (
        <div className="p-grid p-justify-center p-2">
          <Button type="submit" label="Save" />
        </div>
      );
    } else {
      multiRoleSaveButton = "";
    }

    let roleuserselection;
    if (this.state.userRoleSettingAvailable) {
      roleuserselection = (
        <>
          <form onSubmit={this.submitRoleForm}>
            <div className="content-section implementation p-grid p-justify-center p-2">
              <PickList
                source={this.state.roleListSource}
                target={this.state.roleListTarget}
                itemTemplate={this.roleSettingTemplate}
                sourceHeader="Available Roles"
                targetHeader="Assigned Roles"
                sourceStyle={{ height: "500px" }}
                targetStyle={{ height: "500px" }}
                showSourceControls={false}
                showTargetControls={false}
                onChange={e => this.setTargetFromSourceRole(e)}
              />
            </div>
            {multiRoleSaveButton}
          </form>
        </>
      );
    } else {
      roleuserselection = (
        <>
          <div className="p-grid p-justify-center p-2">
            This function is unavailable as there is only one Role in this
            Company.
          </div>
        </>
      );
    }

    let finalDisplay;
    if (this.state.isLoading) {
      finalDisplay = <ProgressSpinner />;
    } else if (this.state.isError) {
      finalDisplay = <CustomError message={this.state.errorMsg} />;
    } else {
      finalDisplay = (
        <>
          <div className="pb-2">
            <span className="boldLabel">Primary Company: </span>
            {this.state.userDetails.companyName}
          </div>

          <div className="pb-2">
            <span className="boldLabel">Primary Role: </span>
            {this.state.roleName}
          </div>

          {/* <this.companyDropdownDisplay /> */}

          {roleuserselection}
        </>
      );
    }
    return (
      <div className="p-grid ">
     
        <div className="p-col">
          <div className="card p-col-12">
            <h1 className="pb-2">
              Role Settings for {this.state.userName}
            </h1>

            {finalDisplay}
          </div>
        </div>
      </div>
    );
  }
}
