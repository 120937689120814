import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import WeeklyDutiesService from "../../service/WeeklyDutiesService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { WeeklyDutiesModel } from "./WeeklyDuties";
import moment from "moment";

function WeeklyDutyCreate(props: RouteComponentProps<any>) {
  const weeklyDutiesService = new WeeklyDutiesService();

  let commonMethods = new CommonMethods();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [action, setAction] = useState("Create");
  const [weeklyDutyObj, setWeeklyDutyObj] = useState<WeeklyDutiesModel>(
    new WeeklyDutiesModel()
  );

  const [companyDropdown, setCompanyDropdown] = useState<[]>([]);
  const [usersDropdown, setUsersDropdown] = useState<[]>([]);

  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);

  // const [componentUpdated, setComponentUpdated] = useState(false);

  // 2020-02-12 PCLQ: Replaced CampaignStatusDropdown to preset dropdown
  const weeklyDutyStatusDropdown = [
    { label: "Active", value: "Active" },
    { label: "On Hold", value: "On Hold" }
  ];

  useEffect(() => {
    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";

    if (props.match.params !== {} && props.match.params.id !== undefined) {
      // update
      weeklyDutiesService
        .getWeeklyDutyByID(props.match.params.id)
        .then(res => {
          setAction("Update");
          setWeeklyDutyObj(res);
          setIsLoading(false);
        })
        .catch(err => {
          let errorMsg = commonMethods.getErrorMessage(err);
          setErrorMessage(errorMsg);
          setIsError(true);
          setIsLoading(false);
        });
    } else {
      // create
      setIsLoading(false);
      setWeeklyDutyObj({
        ...weeklyDutyObj,
        companyID: currentAccessingCompanyId
      });
    }

    getDropdown();
  }, []);

  // const formValidations = (tempWeeklyDutyObj: WeeklyDutiesModel) => {
  //   if (moment(tempWeeklyDutyObj.dutyStartDate).isAfter(moment(tempWeeklyDutyObj.dutyEndDate))) {
  //     SetError("Start date must be before end date!");
  //     return tempWeeklyDutyObj;
  //   }
  //   return tempWeeklyDutyObj;
  // };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // process dates and field validations
    let tempWeeklyDutyObj = weeklyDutyObj;
    if (moment(tempWeeklyDutyObj.dutyStartDate).isAfter(moment(tempWeeklyDutyObj.dutyEndDate))) {
      SetError("Start date must be before end date!");
      return;
    }

    if (action === "Update") {
      weeklyDutiesService
        .updateWeeklyDuty(tempWeeklyDutyObj)
        .then(res => {
          SetSuccess(weeklyDutyObj.dutyName + " has been updated.");
          setRedirectPath("/admin/weekly_duties");
          setRedirectOut(true);
        })
        .catch(err => {
          const error = commonMethods.getErrorMessage(err);
          SetError(error);
        });
    } else if (action === "Create") {
      weeklyDutiesService
        .createWeeklyDuty(tempWeeklyDutyObj)
        .then(res => {
          SetSuccess(weeklyDutyObj.dutyName + " has been created.");
          setRedirectPath("/admin/weekly_duties");
          setRedirectOut(true);
        })
        .catch(err => {
          const error = commonMethods.getErrorMessage(err);
          SetError(error);
        });
    }
  };

  const getDropdown = () => {
    commonMethods
      .getUserDropdownForGroupCompany()
      .then(res => {
        setUsersDropdown(res);
      })
      .catch(err => {
        let errorMsg = commonMethods.getErrorMessage(err);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(errorMsg);
      });

    commonMethods
      .getCompanyDropdownListByGcid()
      .then(res => {
        setCompanyDropdown(res);
      })
      .catch(err => {
        let errorMsg = commonMethods.getErrorMessage(err);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(errorMsg);
      });
  };

  let page = (
    <form onSubmit={handleSubmit}>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <span className="boldLabel">Company <span style={{color: "red"}}>*</span></span>
          <Dropdown
            value={weeklyDutyObj.companyID}
            options={companyDropdown}
            onChange={e =>
              setWeeklyDutyObj({
                ...weeklyDutyObj,
                companyID: e.value
              })
            }
            placeholder="Please Select"
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Duty Name <span style={{color: "red"}}>*</span></span>
          <InputText
            value={weeklyDutyObj.dutyName}
            onChange={e =>
              setWeeklyDutyObj({
                ...weeklyDutyObj,
                dutyName: e.currentTarget.value
              })
            }
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Duty Status <span style={{color: "red"}}>*</span></span>
          <Dropdown
            value={weeklyDutyObj.dutyStatus}
            options={weeklyDutyStatusDropdown}
            onChange={e =>
              setWeeklyDutyObj({
                ...weeklyDutyObj,
                dutyStatus: e.value
              })
            }
            placeholder="Please Select"
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Start Date</span>
          <Calendar
            dateFormat="dd/mm/yy"
            value={new Date(weeklyDutyObj.dutyStartDate)}
            onChange={e => {
              setWeeklyDutyObj({
                ...weeklyDutyObj,
                dutyStartDate: new Date(e.value.toString())
              });
            }}
            showButtonBar={true}
            
            showIcon={true}
            monthNavigator={true}
            yearNavigator={true}
            yearRange="1900:2030"
            // maxDate={this.state.maxDate}
            showTime={false}
            readOnlyInput={true}
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">End Date</span>
          <Calendar
            dateFormat="dd/mm/yy"
            value={new Date(weeklyDutyObj.dutyEndDate)}
            onChange={e => {
              setWeeklyDutyObj({
                ...weeklyDutyObj,
                dutyEndDate: new Date(e.value.toString())
              });
            }}
            showButtonBar={true}
            
            showIcon={true}
            monthNavigator={true}
            yearNavigator={true}
            yearRange="1900:2030"
            // maxDate={this.state.maxDate}
            showTime={false}
            readOnlyInput={true}
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Employees On Duty</span>
          <MultiSelect
            value={weeklyDutyObj.employeesOnDutyIDStrArr}
            options={usersDropdown}
            onChange={e =>
              setWeeklyDutyObj({
                ...weeklyDutyObj,
                employeesOnDutyIDStrArr: e.value
              })
            }
            filter={true}
            placeholder="Please Select"
          />
        </div>
      </div>

      <div className="p-grid p-justify-center pt-2">
        <Button type="submit" label={action} />
      </div>
    </form>
  );
  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = (
      <div className="row p-justify-center">
        <div className="col-12">{page}</div>
      </div>
    );
  }

  return (
    <>
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{action} Weekly Duty</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {display}
          </div>
        </div>
      </div>
    </>
  );
}

export default WeeklyDutyCreate;
