import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { TaxFilingService } from "../../service/TaxFilingService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import {
  TaxFilingHeader,
  TaxFilingMethods,
  TaxHeaderStatus,
} from "./TaxFilingModel";
import { ToastStateContext } from "../resources/ToastContext";

export default function TaxFilingDashboard(props: RouteComponentProps<any>) {
  const commonMethods = new CommonMethods();
  const taxFilingService = new TaxFilingService();
  const [taxfilings, setTaxfilings] = useState<Array<TaxFilingHeader>>(
    new Array<TaxFilingHeader>()
  );
  const [taxFilingHeader, setTaxFilingHeader] = useState(new TaxFilingHeader());
  const { SetSuccess, SetError, SetWarn } = React.useContext(ToastStateContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(
    "An unidentified error has occurred"
  );
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");

  const [addVisibility, setAddVisibility] = useState<boolean>(false);
  const [deleteVisibility, setDeleteVisibility] = useState<boolean>(false);

  const [formTypeDropdown, setFormTypeDropdown] = useState<any[]>([]);
  const [yearDropdown, setYearDropdown] = useState<any[]>([]);
  const [fileTypeDropdown, setFileTypeDropdown] = useState<any[]>([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";
    setYearDropdown(TaxFilingMethods.getTaxFilingYearDropdown());
    setFormTypeDropdown(TaxFilingMethods.getTaxFilingFormTypeDropdown());
    setFileTypeDropdown(TaxFilingMethods.getTaxFilingFileTypeDropdown());

    createNewTax();
    taxFilingService
      .getAllTaxHeaders(currentAccessingCompanyId)
      .then((res) => {
        setTaxfilings(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMsg(commonMethods.getErrorMessage(err));
        setIsError(true);
      });
  };

  const createNewTax = () => {
    const currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";
    let newTax = new TaxFilingHeader();
    newTax.incomeYear = new Date().getFullYear().toString(); // Default year to current
    newTax.formType = "IR8A"; // Default form type
    newTax.fileType = "O"; // Default form type
    newTax.companyID = currentAccessingCompanyId;
    setTaxFilingHeader(newTax);
  };

  const createNewTaxFile = () => {
    taxFilingService
      .createTax(taxFilingHeader)
      .then((res) => {
        setAddVisibility(false);
        checkPayrollsOnInit();
        setRedirectPath("/finance/tax_filing/" + res.taxHeaderID);
        setRedirectOut(true);
      })
      .catch((err) => {
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  const checkPayrollsOnInit = () => {
    const currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";

    taxFilingService
      .getPayrollCheck(
        parseInt(taxFilingHeader.incomeYear),
        currentAccessingCompanyId
      )
      .then()
      .catch((err) => {
        const msg = commonMethods.getErrorMessage(err);
        if (commonMethods.isWarning(err)) {
          SetWarn(msg);
        }
      })
      .catch((err) => {
        setErrorMsg(commonMethods.getErrorMessage(err));
        setIsError(true);
      });
  };

  const yearFormDisplay = () => {
    return (
      <div className="p-grid p-fluid">
        <div className="text-left p-col-12 p-fluid">
          <span className="boldLabel">Year</span>
          <span className="requiredRed">*</span>
          <Dropdown
            value={taxFilingHeader.incomeYear}
            options={yearDropdown}
            onChange={(e) =>
              setTaxFilingHeader({ ...taxFilingHeader, incomeYear: e.value })
            }
            required
            placeholder="Please Select"
            scrollHeight="350px"
          />
        </div>
        <div className="text-left p-col-12 p-fluid">
          <span className="boldLabel">Form Type</span>
          <span className="requiredRed">*</span>
          <Dropdown
            value={taxFilingHeader.formType}
            options={formTypeDropdown}
            onChange={(e) =>
              setTaxFilingHeader({ ...taxFilingHeader, formType: e.value })
            }
            required
            placeholder="Please Select"
            scrollHeight="350px"
          />
        </div>
        <div className="text-left p-col-12 p-fluid">
          <span className="boldLabel">File Type</span>
          <span className="requiredRed">*</span>
          <Dropdown
            value={taxFilingHeader.fileType}
            options={fileTypeDropdown}
            onChange={(e) =>
              setTaxFilingHeader({ ...taxFilingHeader, fileType: e.value })
            }
            required
            placeholder="Please Select"
            scrollHeight="350px"
          />
        </div>
      </div>
    );
  };

  const actionTemplate = (rowData: TaxFilingHeader) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="View"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-eye"
          className="p-button-primary"
          onClick={() => {
            setRedirectOut(true);
            setRedirectPath("/finance/tax_filing/" + rowData.taxHeaderID);
          }}
        />
        <Button
          tooltip="Delete"
          disabled={rowData.status == TaxHeaderStatus.SUBMITTED}
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setTaxFilingHeader(rowData);
            setDeleteVisibility(true);
          }}
        />
      </div>
    );
  };

  const dateFormat = (rowData: TaxFilingHeader) => {
    return commonMethods.displayDate(rowData.createdTime);
  };

  const deleteTaxFile = () => {
    taxFilingService
      .deleteTax(taxFilingHeader.taxHeaderID)
      .then((res) => {
        SetSuccess("Successfully deleted");
        setDeleteVisibility(false);
        init();
      })
      .catch((err) => {
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = (
      <div className="datatable-centerHeader datatable-centerContent">
        {" "}
        <DataTable
          value={taxfilings}
          emptyMessage="No results found."
          autoLayout={true}
          //   header={tableheader}
          //   globalFilter={searchValue}
          alwaysShowPaginator={false}
          paginator={true}
          rows={15}
        >
          <Column field="incomeYear" header="Year" />
          <Column field="fileType" header="File Type" />
          <Column field="formType" header="Form Type" />
          <Column field="createdTime" body={dateFormat} header="Created On" />
          <Column field="status" header="Status" />

          <Column body={actionTemplate} header="Actions" />
        </DataTable>
      </div>
    );
  }

  let fullDisplay = (
    <div className="col-12 col-xl-12">
      <div className="card">
        <h1 className="pb-2">
          Tax filing (Beta)
          <span className="pl-3">
            <Button
              label="Add"
              tooltip="Add"
              tooltipOptions={{ position: "right" }}
              type="button"
              icon="pi pi-plus"
              onClick={(e) => {
                setAddVisibility(true);
                createNewTax();
              }}
              className="p-button-danger"
            />
          </span>
        </h1>
        {display}
      </div>

      {/* Dialog for Add button */}
      <Dialog
        header="Add new Tax File"
        visible={addVisibility}
        modal={true}
        className="popupDialog"
        onHide={() => setAddVisibility(false)}
        footer={
          <div>
            <Button
              label="Submit"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              onClick={(e) => createNewTaxFile()}
            />
            <Button
              label="Cancel"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setAddVisibility(false);
              }}
            />
          </div>
        }
      >
        <div className="text-left text-md-center py-2">{yearFormDisplay()}</div>
      </Dialog>

      {/* Dialog for Delete button */}
      <Dialog
        header="Delete Tax File"
        visible={deleteVisibility}
        modal={true}
        className="popupDialog"
        onHide={() => setDeleteVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                deleteTaxFile();
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDeleteVisibility(false);
              }}
            />
          </div>
        }
      >
        {`Are you sure you want to delete Tax File Year ` +
          taxFilingHeader.incomeYear}
      </Dialog>
    </div>
  );

  // let notReadyDisplay = (
  //   <div className="col-12">
  //     {" "}
  //     <div className="card">
  //       <h1 className="pb-2">Tax filing (Beta)</h1>
  //       <div>We'll be ready soon!</div>
  //     </div>
  //   </div>
  // );
  return (
    <div className="row">
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      {fullDisplay}
    </div>
  );
}
