import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect, useState } from "react";
import useForm from "react-hook-form";
import { ScoreIndicatorService } from "../../../service/ScoreIndicatorService";
import { CommonMethods, DropdownModel } from "../../resources/CommonMethods";
import { ToastStateContext } from "../../resources/ToastContext";
import { Calendar } from "primereact/calendar";
import { UserIndicatorModel, UserIndicatorMultiSelectModel } from "./KPIModels";
import { UserModel } from "../../user/User";
import { UserService } from "../../../service/UserService";
import { MultiSelect } from "primereact/multiselect";


export default function CreateKPI(props: any) {
  const sis = new ScoreIndicatorService();
  const userSvc = new UserService();

  const { handleSubmit } = useForm();
  const [KPIEntry, setKPIEntry] = useState<UserIndicatorMultiSelectModel>(
    new UserIndicatorMultiSelectModel()
  );
  const [tasks, setTasks] = useState();
  const [deliverables, setDeliverables] = useState();
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [isCustomPointEntry, setCustomPointEntry] = useState<boolean>(false);
  const [buttonText, setButtonTest] = useState<string>("Add");
  const commonMethods = new CommonMethods();

  const [hodView, setHODView] = useState(true);
  const [usersInSameDept, setUsersInSameDept] = useState<Array<DropdownModel>>(new Array<DropdownModel>());

  // Create
  // useEffect(() => {
  //   if (props.isCustomPointEntry) {
  //     setCustomPointEntry(true);
  //     setKPIEntry({ ...KPIEntry, customPoint: props.isCustomPointEntry });
  //     getForCustom();
  //   } else {
  //     getForNormal();
  //   }

  //   if (props.hasOwnProperty('hodView') && props.hasOwnProperty('thisUser')) {
  //     setHODView(props.hodview);
  //     getUsersInDept();
  //     setKPIEntry({ ...KPIEntry, userID: props.thisUser });
  //   }
  // }, []);

  useEffect(() => {
    setCustomPointEntry(props.isCustomPointEntry);
    setHODView(props.hodView);
    setKPIEntry({
      ...KPIEntry,
      customPoint: props.isCustomPointEntry,
      userID: props.thisUser
    });

    if (props.hodView && (props.thisUser !== CommonMethods.EmptyGuid)){
      getUsersInDept();
    }

    if (props.isCustomPointEntry) {
      getForCustom();
    } else {
      getForNormal();
    }
  }, []);


  const getForCustom = () => {
    commonMethods
      .getCustomTasksUserDropdown()
      .then((res) => {
        setTasks(res);
      })
      .catch((err) => {
        let errMsg = commonMethods.getErrorMessage(err);
        SetError(errMsg);
      });
    commonMethods
      .getDeliverableUserDropdown()
      .then((res) => {
        res.unshift({
          label: "None",
          value: CommonMethods.EmptyGuid,
        });
        setDeliverables(res);
      })
      .catch((err) => {
        let errMsg = commonMethods.getErrorMessage(err);
        SetError(errMsg);
      });
  };

  const getForNormal = () => {
    commonMethods
      .getTasksUserDropdown()
      .then((res) => {
        setTasks(res);
      })
      .catch((err) => {
        let errMsg = commonMethods.getErrorMessage(err);
        SetError(errMsg);
      });
    commonMethods
      .getDeliverableUserDropdown()
      .then((res) => {
        res.unshift({
          label: "None",
          value: CommonMethods.EmptyGuid,
        });
        setDeliverables(res);
      })
      .catch((err) => {
        let errMsg = commonMethods.getErrorMessage(err);
        SetError(errMsg);
      });
  };

  const getUsersInDept = () => {
    userSvc
      .listOfUsersInSameDept()
      .then((res: Array<UserModel>) => {
        var list: Array<DropdownModel> = new Array<DropdownModel>();
        res.forEach(person => {
          list.push({ label: person.firstName + " " + person.lastName, value: person.userID })
        });
        setUsersInSameDept(list);
      })
      .catch((err) => {
        let errMsg = commonMethods.getErrorMessage(err);
        SetError(errMsg);
      });
  };

  // Update
  useEffect(() => {
    if (props.isUpdate && props.id !== undefined && props.id !== "") {
      sis
        .getEntryByID(props.id)
        .then((res) => {
          setButtonTest("Update");

          if (res.deliverableID === null) {
            res.deliverableID = CommonMethods.EmptyGuid;
          }

          if (res.customPoint) {
            setCustomPointEntry(true);

            setKPIEntry(res);
            getForCustom();
          } else {
            getForNormal();
            setKPIEntry(res);
            setCustomPointEntry(false);
          }
        })
        .catch((err) => {
          let errMsg = commonMethods.getErrorMessage(err);
          SetError(errMsg);
        });
    }
  }, [props.id]);

  const onSubmit = (data: any) => {
    if (props.isUpdate) {
      sis
        .updateEntry(KPIEntry)
        .then((res) => {
          SetSuccess("Score indicator updated");
          props.setRefreshView(!props.refresh);
          props.callback(false);
          setKPIEntry(new UserIndicatorMultiSelectModel());
        })
        .catch((err) => {
          let errMsg = commonMethods.getErrorMessage(err);
          SetError(errMsg);
        });
    } else {
      if (!props.isCustomPointEntry && (KPIEntry.indicatorIDList).length === 0){
        let errMsg = "Select at least one task";
        SetError(errMsg);
        return;
      }
      sis
        .addEntry(KPIEntry)
        .then((res) => {
          SetSuccess("Score indicator created");
          props.setRefreshView(!props.refresh);
          props.callback(false);
          setKPIEntry(new UserIndicatorMultiSelectModel());
        })
        .catch((err) => {
          let errMsg = commonMethods.getErrorMessage(err);
          SetError(errMsg);
        });
    }
  };

  const displayCustomPoints = () => {
    if (isCustomPointEntry) {
      return (
        <div className="p-col-12 p-fluid">
          <span className="boldLabel">Custom Points</span>{" "}
          <span className="requiredRed">*</span>
          <InputNumber
            value={KPIEntry.points}
            onChange={(e) => {
              setKPIEntry({ ...KPIEntry, points: e.value });
            }}
            minFractionDigits={2}
            mode="decimal"
            showButtons
            step={0.25}
            required
          />
        </div>
      );
    }
  };

  const displayCustomPointsDesc = () => {
    if (isCustomPointEntry) {
      return (
        <p className="text-center">
          Calculate custom points yourself according to the points stated on
          your selected task.
        </p>
      );
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          {hodView ? (<div className="p-col-12 p-fluid">
            <span className="boldLabel">Staff</span>{" "}
            <span className="requiredRed">*</span>
            <Dropdown
              options={usersInSameDept}
              placeholder="Please Select"
              scrollHeight="350px"
              value={KPIEntry.userID}
              filter={true}
              filterPlaceholder="Search Staff"
              filterBy="label,value"
              required
              onChange={(e) =>
                setKPIEntry({ ...KPIEntry, userID: e.value })
              }
            />
          </div>) : (<div></div>)}
          {(!props.isUpdate && !props.isCustomPointEntry) ? 
            <div className="p-col-12 p-fluid">
              <span className="boldLabel">Task</span>{" "}
              <MultiSelect
                options={tasks}
                placeholder="Please Select"
                scrollHeight="350px"
                filter={true}
                filterPlaceholder="Search Task"
                filterBy="label,value"
                value={KPIEntry.indicatorIDList}
                onChange={(e) => {
                  setKPIEntry({ ...KPIEntry, indicatorIDList: e.value });
                }}
              />
            </div>
          :
            <div className="p-col-12 p-fluid">
              {displayCustomPointsDesc()}
              <span className="boldLabel">Task</span>{" "}
              <span className="requiredRed">*</span>
              <Dropdown
                options={tasks}
                placeholder="Please Select"
                scrollHeight="350px"
                required
                filter={true}
                filterPlaceholder="Search Task"
                filterBy="label,value"
                value={KPIEntry.indicatorID}
                onChange={(e) => {
                  setKPIEntry({ ...KPIEntry, indicatorID: e.value });
                }}
              />
            </div>
          }
          <div className="p-col-12 p-fluid">
            <span className="boldLabel">Deliverable</span>{" "}
            <span className="requiredRed">*</span>
            <Dropdown
              options={deliverables}
              placeholder="Please Select"
              scrollHeight="350px"
              value={KPIEntry.deliverableID}
              filter={true}
              filterPlaceholder="Search Deliverable"
              filterBy="label,value"
              required
              onChange={(e) =>
                setKPIEntry({ ...KPIEntry, deliverableID: e.value })
              }
            />
          </div>
          <div className="p-col-12 p-fluid">
            <span className="boldLabel">Date</span>
            <span className="requiredRed">*</span>

            <Calendar
              dateFormat="dd/mm/yy"
              value={new Date(KPIEntry.createdTime)}
              onChange={(e) => {
                setKPIEntry({
                  ...KPIEntry,
                  createdTime: new Date(e.value.toString()),
                });
              }}
              showButtonBar={true}
              clearButtonClassName="d-none"
              showIcon={true}
              monthNavigator={true}
              // yearNavigator={true}
              yearRange="1900:2030"
              // maxDate={this.state.maxDate}
              showTime={false}
              readOnlyInput={true}

            />
          </div>
          {displayCustomPoints()}
          <div className="p-col-12 p-fluid">
            <span className="boldLabel">Link</span>

            <InputText
              placeholder=""
              value={KPIEntry.link}
              onChange={(e) =>
                setKPIEntry({ ...KPIEntry, link: e.currentTarget.value })
              }
            />
          </div>
          <div className="p-col-12 p-fluid">
            <span className="boldLabel">Facebook Link</span>

            <InputText
              placeholder=""
              value={KPIEntry.facebookLink}
              onChange={(e) =>
                setKPIEntry({
                  ...KPIEntry,
                  facebookLink: e.currentTarget.value,
                })
              }
            />
          </div>
          <div className="p-col-12 p-fluid">
            <span className="boldLabel">Remarks</span>

            <InputTextarea
              placeholder=""
              value={KPIEntry.remarks}
              onChange={(e) =>
                setKPIEntry({ ...KPIEntry, remarks: e.currentTarget.value })
              }
            />
          </div>

          {/* <div className="p-col-2 p-fluid">
            <span className="boldLabel">Modifier </span>
            <RHFInput
              as={
                <NumberFormat
                  className="p-inputtext"
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              }
              register={register}
              setValue={setValue}
              name="modifier"
            />
          </div> */}
        </div>
        <div className="p-grid p-justify-center pt-4">
          <Button
            type="submit"
            label={buttonText}
            className="p-button-primary"
          />
        </div>
      </form>
    </div>
  );
}
