import { RouteComponentProps, useParams, Redirect } from "react-router";
import { PayslipService } from "../../service/PayslipService";
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ToastStateContext } from "../resources/ToastContext";
import CustomError from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";
import { CommonMethods, DropdownModel, ActionTypes } from "../resources/CommonMethods";
import { Dropdown } from "primereact/dropdown";
// import ReactTooltip from "react-tooltip";
import { ToggleButton } from 'primereact/togglebutton';
import { PayItemModel } from "../resources/ExportClass";


function PayItemTypeCreate(props: RouteComponentProps) {
  const payslipService = new PayslipService();
  const params: { id?: string } = useParams();
  const buttonLabel = params.id === undefined ? ActionTypes.create : ActionTypes.update;
  const confirmationMessage = params.id === undefined ? "created" : "updated";
  const headerTitle =
    params.id === undefined ? "Create Pay Item" : "Update Pay Item Details";

  const commonMethods = new CommonMethods();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [toHome, setToHome] = useState(false);

  // const [payItemTypeObj, setPayItemTypeObj] = useState<PayItemModel>(new PayItemModel());
  const [payItemTypeData, setPayItemTypeData] = useState<PayItemModel>(
    new PayItemModel()
  );
  const [cpfTypesDropdown, setCPFTypesDropdown] = useState<
    Array<DropdownModel>
  >([]);
  const [categoriesDropdown, setCategoriesDropdown] = useState<
    Array<DropdownModel>
  >([]);
  const [ir8aCodesDropdown, setIR8ACodesDropdown] = useState<
    Array<DropdownModel>
  >([]);
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  useEffect(() => {
    if (params.id !== undefined) {
      payslipService
        .getPayItemTypeById(params.id)
        .then((res) => {
          // setPayItemTypeObj(res);
          setPayItemTypeData(res);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMessage(commonMethods.getErrorMessage(err));
        });
    } else {
      setIsLoading(false);
    }
    getDropdown();
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (params.id === undefined) {
      payslipService
        .createPayItemType(payItemTypeData)
        .then(() => {
          SetSuccess(
            "Pay item type " +
            payItemTypeData.payItemName +
            " has been " +
            confirmationMessage +
            "."
          );
          setToHome(true);
        })
        .catch((err) => {
          SetError(commonMethods.getErrorMessage(err));
        });
    } else {
      payslipService.updatePayItemType(payItemTypeData)
        .then(() => {
          SetSuccess(
            "Pay item type " +
            payItemTypeData.payItemName +
            " has been " +
            confirmationMessage +
            "."
          );
          setToHome(true);
        })
        .catch((err) => {
          SetError(commonMethods.getErrorMessage(err));
        });
    }
  };

  const getDropdown = () => {
    try {
      payslipService.getCPFTypesDropdownOptions().then((res) => {
        setCPFTypesDropdown(res);
      });

      payslipService.getPayItemCategoryDropdownOptions().then((res) => {
        setCategoriesDropdown(res);
      });

      payslipService.getIR8ADropdownOptions().then((res) => {
        setIR8ACodesDropdown(res);
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(commonMethods.getErrorMessage(e));
      setIsError(true);
    }
  };

  let page = (
    <form onSubmit={handleSubmit}>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <span className="boldLabel">
            Pay Item Name <span style={{ color: "red" }}>*</span>
          </span>
          <InputText
            value={payItemTypeData.payItemName}
            placeholder=""
            onChange={(event) =>
              setPayItemTypeData({
                ...payItemTypeData,
                payItemName: event.currentTarget.value,
              })
            }
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            IR8A Code <span style={{ color: "red" }}>*</span>
          </span>
          <Dropdown
            value={payItemTypeData.iR8ACode}
            options={ir8aCodesDropdown}
            placeholder="Please Select"
            onChange={(event) =>
              setPayItemTypeData({
                ...payItemTypeData,
                iR8ACode: event.value,
              })
            }
            required
          />
        </div>
        <div className="p-col-12">
          <span className="boldLabel">
            CPF Type <span style={{ color: "red" }}>*</span>
          </span>
          <Dropdown
            value={payItemTypeData.cpfType}
            options={cpfTypesDropdown}
            placeholder="Please Select"
            onChange={(event) =>
              setPayItemTypeData({
                ...payItemTypeData,
                cpfType: event.value,
              })
            }
            required
          />
        </div>
        <div className="p-col-12">
          <span className="boldLabel">
            Category <span style={{ color: "red" }}>*</span>
          </span>
          <Dropdown
            value={payItemTypeData.category}
            options={categoriesDropdown}
            placeholder="Please Select"
            onChange={(event) => {
              setPayItemTypeData({
                ...payItemTypeData,
                category: event.value,
              });
            }}
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            {/* Is CPF Payable? <span style={{ color: "red" }}>*</span> */}
          </span>
          <ToggleButton onLabel="This Payitem is CPF Payable" offLabel="This Payitem is NOT CPF Payable" onIcon="pi pi-check" offIcon="pi pi-times"
            checked={payItemTypeData.isCPFPayable} onChange={(event) => {
              setPayItemTypeData({
                ...payItemTypeData,
                isCPFPayable: event.value,
              });
            }} />
        </div>
      </div>

      <div className="p-grid p-justify-center pt-2">
        <Button type="submit" label={buttonLabel} />
      </div>
    </form>
  );
  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <>
      {toHome ? <Redirect push to="/finance/pay_item_type" /> : null}

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{headerTitle}</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {display}
          </div>
        </div>
      </div>
    </>
  );
}

export default PayItemTypeCreate;

// { " " }
// <i
//     className="fas fa-info-circle informationStyle"
//     data-tip=""
//     data-for="roomorder"
// ></i>
