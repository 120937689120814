import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { PDFExport } from "@progress/kendo-react-pdf";
import * as React from "react";
import { Redirect } from "react-router";
import { PayslipService } from "../../service/PayslipService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import {
  PayslipInfo,
  payslipmeta,
  PayslipModel,
  PayslipObjAndInfoModel,
  UpdateLog
} from "../resources/ExportClass";
import { ToastStateContext } from "../resources/ToastContext";

interface AppState {
  payslipInfo: PayslipInfo;
  payslipObj: PayslipModel;
  updateLog: Array<UpdateLog>;

  errorMsg: string;
  isError: boolean;

  redirectOut: boolean;
  redirectPath: string;

  preparedBy: string;
}
const logoCSS: React.CSSProperties = {
  // width: "70%",
  overflow: "hidden",
  textAlign: "center",
};

const footerCSS: React.CSSProperties = {
  position: "absolute",
  textAlign: "center",
  zIndex: 99999999,
  fontSize: 8,
  bottom: 10,
  left: 0,
  right: 0,
};

export class PayslipExport extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  payslipService: PayslipService;
  commonMethods: CommonMethods;
  static contextType = ToastStateContext;
  localExport: PDFExport | null = null;
  divToDraw: any;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      payslipInfo: new PayslipInfo(),
      payslipObj: new PayslipModel(),
      updateLog: new Array<UpdateLog>(),

      errorMsg: "",
      isError: false,

      redirectOut: false,
      redirectPath: "",

      preparedBy: "System",
    };

    // SERVICE SET
    this.payslipService = new PayslipService();
    this.commonMethods = new CommonMethods();

    //BINDING RENDER
    this.renderEarnings = this.renderEarnings.bind(this);
    this.renderAdditionalWage = this.renderAdditionalWage.bind(this);
    this.renderContributions = this.renderContributions.bind(this);
    this.renderOtherDeductables = this.renderOtherDeductables.bind(this);
    this.renderYTDearnings = this.renderYTDearnings.bind(this);
    this.renderUnpaidBreakdown = this.renderUnpaidBreakdown.bind(this);
    this.renderEmployerContributions = this.renderEmployerContributions.bind(
      this
    );
    this.renderPreparedBy = this.renderPreparedBy.bind(this);
    this.renderGrossNetPay = this.renderGrossNetPay.bind(this);
    this.renderEmployeeInfo = this.renderEmployeeInfo.bind(this);
    this.renderCompanyInformation = this.renderCompanyInformation.bind(this);
    this.exportMethod = this.exportMethod.bind(this);
  }

  componentDidMount() {
    if (this.props.others !== null && this.props.others.payslipID !== "") {
      this.setupCalls(this.props.others.payslipID);
    }
  }
  componentDidUpdate(prevProps: any) {
    if (this.props.others !== prevProps.others) {
      if (this.props.others.payslipID !== prevProps.others.payslipID) {
        this.divToDraw = null;
        this.setupCalls(this.props.others.payslipID);
      }
    }
  }

  // API CALL
  setupCalls(payslipID: string) {
    this.commonMethods
      .getFullDisplayNameFromProvidedUserID(this.props.userProfile.userID)
      .then((res) => {
        this.setState({
          preparedBy: res,
        });
      });

    this.payslipService
      .getPayslipWithUpdateLogByPayslipId(payslipID)
      .then((res: PayslipObjAndInfoModel) => {
        this.setState(
          {
            payslipInfo: res.payslipInfo,
            payslipObj: res.payslipModel,
            updateLog: res.updateLog,
          },
          () => {
            this.exportMethod();
          }
        );
      })
      .catch((err) => {
        this.setState({
          payslipInfo: new PayslipInfo(),
          payslipObj: new PayslipModel(),
          updateLog: new Array<UpdateLog>(),
          errorMsg: "Contract is not active",
          isError: true,
        });
        // callback
        this.returnError();
      });
  }

  returnError() {
    // callback
    this.props.others.completedCallback(false, "");
  }

  formatDate(datetime: Date, year?: boolean) {
    var date = new Date(datetime);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return (
      <span>
        {date.getDate()} {months[date.getMonth()]}{" "}
        {year ? date.getFullYear() : ""}
      </span>
    );
  }

  formatPayAdviceDate(datetime: Date) {
    var date = new Date(datetime);
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return (
      <span>
        {months[date.getMonth()]} {" "}{date.getFullYear()}
      </span>
    );
  }

  formatTime(datetime: Date) {
    var date = new Date(datetime);

    return <span>{date.toTimeString().slice(0, 8)}</span>;
  }

  formatBirthday(birthdate: Date) {
    var today = new Date();
    var birthday = new Date(birthdate);

    var age_now = today.getFullYear() - birthday.getFullYear();
    var m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age_now--;
    }
    return age_now;
  }

  camelCase(text: string) {
    return text.substr(0, 1).toUpperCase() + text.substr(1);
  }

  getIndex(haystack: Array<payslipmeta>, needle: string) {
    return haystack.map((o) => o.meta_key).indexOf(needle);
  }

  // END FUNCTIONS

  // RENDER
  renderCompanyInformation() {
    const { payslipInfo } = this.state;

    return (
      <div className="p-grid p-fluid p-justify-between">
        <div className="p-col-5 outerCenterWrap">
          <div style={logoCSS}>
            <img
              src={payslipInfo.payslipLogo}
              alt="company payslip logo"
              className="payslip-pic"
            />
          </div>
        </div>
        <div className="p-col-4 p-col-align-end text-left">
          <b>{payslipInfo.companyName}</b>

          <p className="mb-0">
            <span className="boldLabel">UEN:</span>
            {payslipInfo.companyRegNum}{" "}
          </p>
          <p className="mb-0">{payslipInfo.companyAddress}</p>
        </div>
      </div>
    );
  }

  renderEmployeeInfo() {
    const { payslipInfo } = this.state;

    return (
      <div className="p-col-11 text-left p-justify-center">
        <div className="p-grid p-fluid">
          <div className="p-col-8">
            <div className="p-col-12">
              Employee Name
              <br />
              <span className="font-weight-bold">{payslipInfo.fullName}</span>
            </div>
            <div className="p-col-12 p-grid pb-0 pt-0">
              <div className="p-col-4">D.O.B:</div>
              <div className="p-col-8 font-weight-bold">
                {this.formatDate(payslipInfo.dateOfBirth, true)}
              </div>
            </div>
            <div className="p-col-12 p-grid pb-0 pt-0">
              <div className="p-col-4">Tax Ref/Fin No.:</div>
              <div className="p-col-8 font-weight-bold">
                {payslipInfo.identificationNumber}
              </div>
            </div>
            <div className="p-col-12 p-grid pb-0 pt-0">
              <div className="p-col-4">Age</div>
              <div className="p-col-8 font-weight-bold">
                {this.formatBirthday(payslipInfo.dateOfBirth)}
              </div>
            </div>
            <div className="p-col-12 p-grid pb-0 pt-0">
              <div className="p-col-4">Currency</div>
              <div className="p-col-8 font-weight-bold">
                {this.state.payslipObj.currency}
              </div>
            </div>
          </div>
          <div className="p-col-4 p-col-align-center">
            <b style={{ fontSize: "14pt" }}>Pay Advice For</b> <br />
            <b>{this.formatPayAdviceDate(this.state.payslipObj.paymentDate)}</b>
            <div className="p-col-12">
              A/C No.: {"    "}
              {payslipInfo.bank_Number}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAdditionalWage() {
    const display: any = [];

    if (this.state.payslipObj.additionalWageDisplay.length > 0) {
      this.state.payslipObj.additionalWageDisplay.forEach((value, index) => {
        display.push(
          <div className="p-col-12 p-grid" key={index}>
            <div className="p-col-6">
              <b>{this.camelCase(value.meta_key)}</b>
            </div>
            <div className="p-col-6">
              {this.formatMoneyDisplay(parseFloat(value.meta_value))}
            </div>
          </div>
        );
      });
    }

    return <>{display}</>;
  }

  renderEarnings() {
    return (
      <>
        <div className="p-col-6">
          <b>Basic Pay</b>
        </div>
        <div className="p-col-6">
          {this.formatMoneyDisplay(this.state.payslipObj.basicSalary)}
        </div>
        <div className="p-col-6">
          <b>Work Days</b>
        </div>
        <div className="p-col-6">
          {this.state.payslipObj.workDays}
          {" day(s)"}
        </div>
        <div className="p-col-6">
          <b>Unpaid Leaves</b>
        </div>
        <div className="p-col-6">
          {this.state.payslipObj.unpaidLeaveDays}
          {" day(s)"}
        </div>
        <div className="p-col-6">
          <b>Ordinary Wage</b>
        </div>
        <div className="p-col-6 border-top">
          {this.formatMoneyDisplay(this.state.payslipObj.ordinaryWage)}
        </div>
      </>
    );
  }

  renderContributions() {
    const { contributions, donation } = this.state.payslipObj;
    const display = [];
    if (this.getIndex(contributions, "Employee Contribution") > 0) {
      display.push(
        <div className="p-col-12 p-grid" key="employee_contribution">
          <div className="p-col-6">
            <b>
              {
                contributions[
                  this.getIndex(contributions, "Employee Contribution")
                ].meta_key
              }
            </b>
          </div>
          <div className="p-col-6">
            {this.formatMoneyDisplay(
              parseFloat(
                contributions[
                  this.getIndex(contributions, "Employee Contribution")
                ].meta_value
              )
            )}
          </div>
        </div>
      );
    }
    if (donation.length > 0) {
      this.state.payslipObj.donation.forEach((obj, i) => {
        display.push(
          <div className="p-col-12 p-grid" key={i}>
            <div className="p-col-6">
              <b>{obj.meta_key}</b>
            </div>
            <div className="p-col-6">
              {this.formatMoneyDisplay(parseFloat(obj.meta_value))}

              {/* <NumberFormat
                displayType="text"
                value={obj.meta_value}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
                fixedDecimalScale={true}
              /> */}
            </div>
          </div>
        );
      });
    }
    return <>{display}</>;
  }
  renderOtherDeductables() {
    const display: any = [];

    if (this.state.payslipObj.deductablesDisplay.length > 0) {
      this.state.payslipObj.deductablesDisplay.forEach((value, index) => {
        display.push(
          <div className="p-col-12 p-grid" key={index}>
            <div className="p-col-6">
              <b>{this.camelCase(value.meta_key)}</b>
            </div>
            <div className="p-col-6">
              {this.formatMoneyDisplay(parseFloat(value.meta_value))}
            </div>
          </div>
        );
      });
    }

    return <>{display}</>;
  }

  renderGrossNetPay() {
    return (
      <div className="p-col-12 border-0">
        <div className="p-grid p-fluid">
          <div className="p-col-3 payslipSections">
            <b>Gross Pay</b>
          </div>
          <div className="p-col-3 border-top border-bottom">
            {this.formatMoneyDisplay(this.state.payslipObj.grossSalary)}
          </div>
          <div className="p-col-3 payslipSections">
            <b>Net Pay</b>
          </div>
          <div className="p-col-3 border-top border-bottom">
            {this.formatMoneyDisplay(this.state.payslipObj.netSalary)}
          </div>
        </div>
      </div>
    );
  }

  renderYTDearnings() {
    const { ytdPayout } = this.state.payslipObj;
    return (
      <div className="p-col-12 text-center border mb-3">
        <div className="row">
          <div className="col-12 col-xl-6">
            <div className="col-12 text-left pb-2">
              <span
                className="pb-3 font-weight-bold"
                style={{ color: "#4E79AD" }}
              >
                Year to Date Earnings
              </span>
            </div>
            <div className="row">
              <div className="p-col-6">
                <b>Total Salary</b>
              </div>
              <div className="p-col-6">
                {this.formatMoneyDisplay(ytdPayout)}
              </div>

              {/* <div className="p-col-6">
                <b>Bonus Entitlement</b>
              </div>
              <div className="p-col-6">-</div> */}

              {/* <div className="p-col-6">{ytdMonths} Month(s)</div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUnpaidBreakdown() {
    return (
      <div className="p-col-12 text-center border mb-3">
        <div className="p-col-12">
          <span className="pb-3 font-weight-bold" style={{ color: "#4E79AD" }}>
            Unpaid Leave Calculation
          </span>
          <div className="p-grid p-fluid">
            {this.state.payslipObj.unpaidLeaveDays > 0 ? (
              <>
                <div className="p-col-4">
                  {" "}
                  <b>Unpaid Leaves</b>{" "}
                </div>{" "}
                <div className="p-col-8">
                  {this.state.payslipObj.unpaidLeaveDays} Days
                </div>
              </>
            ) : (
              <div className="p-col-12">No Records</div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderEmployerContributions() {
    return (
      <div className="p-col-12 text-center border mb-3">
        <div className="row">
          <div className="col-12 col-xl-6">
            <div className="col-12 text-left pb-2">
              <span
                className="pb-3 font-weight-bold"
                style={{ color: "#4E79AD" }}
              >
                Employer Contributions
              </span>
            </div>
            <div className="row">
              <div className="p-col-6">
                <b>Employer CPF</b>
              </div>
              <div className="p-col-6">
                {this.getIndex(
                  this.state.payslipObj.contributions,
                  "Employer Contribution"
                ) > 0
                  ? this.formatMoneyDisplay(
                      parseFloat(
                        this.state.payslipObj.contributions[
                          this.getIndex(
                            this.state.payslipObj.contributions,
                            "Employer Contribution"
                          )
                        ].meta_value
                      )
                    )
                  : "-"}

                {/* <NumberFormat
                  displayType="text"
                  value={
                    this.getIndex(
                      this.state.payslipObj.contributions,
                      "Employer Contribution"
                    ) > 0
                      ? this.state.payslipObj.contributions[
                          this.getIndex(
                            this.state.payslipObj.contributions,
                            "Employer Contribution"
                          )
                        ].meta_value
                      : "-"
                  }
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                /> */}
              </div>

              <div className="p-col-6">
                <b>SDF (0.25%)</b>
              </div>
              <div className="p-col-6">
                {this.formatMoneyDisplay(this.state.payslipObj.sdf)}
              </div>
            </div>
          </div>

          <div className="col-12 col-xl-6">
            <div className="col-12 text-left pb-2"> &nbsp; </div>

            <div className="row">
              <div className="p-col-6 font-weight-bold">Pay Period</div>
              <div className="p-col-6">
                {this.formatDate(this.state.payslipObj.payStartDate, false)}{" "}
                {"-"} {this.formatDate(this.state.payslipObj.payEndDate, false)}
              </div>

              <div className="p-col-6 font-weight-bold">Pay Day</div>
              <div className="p-col-6">
                {this.formatDate(this.state.payslipObj.paymentDate, false)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPreparedBy() {
    return (
      <div className="p-col-3 p-col-offset-9 p-offset-9 text-center">
        <p className="mb-0">Prepared by</p>
        <p className="font-weight-bold">{this.state.preparedBy}</p>
      </div>
    );
  }

  formatMoneyDisplay(amount: number) {
    return (
      // NumberFormat doesn't render properly for number values(?) Renders properly for string values (Deductions)
      // <NumberFormat
      //   displayType="text"
      //   value={amount}
      //   thousandSeparator={true}
      //   defaultValue={amount}
      //   prefix={"$"}
      //   decimalScale={2}
      //   fixedDecimalScale={true}
      // />
      "$" + amount.toFixed(2)
    );
  }

  async export() {
    let drawOptions = {
      paperSize: "A4",
      title: "",
      subject: "",
      keywords: "",
    };

    return await drawDOM(this.divToDraw, drawOptions)
      .then((group) => {
        return exportPDF(group);
      })
      .then((dataUri) => {
        return dataUri;
      });
  }

  exportMethod() {
    if (this.divToDraw !== null) {
      this.export()
        .then((dataUri) => {
          this.payslipService
            .exportPayslip(dataUri, this.state.payslipObj.payslipID)
            .then((res) => {
              // callback
              this.props.others.completedCallback(
                true,
                this.state.payslipObj.payslipID
              );
            })
            .catch((err) => {
              this.returnError();
            });
        })
        .catch((err) => {
          this.returnError();
        });
    }
  }

  render() {
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
          }}
        />
      );
    }

    let pdfCopyToBeExported = (
      <div
        ref={(r) => (this.divToDraw = r)}
        style={{
          height: 842,
          width: 595,
          padding: "none",
          backgroundColor: "white",
          boxShadow: "5px 5px 5px black",
          margin: "auto",
          overflowX: "hidden",
          overflowY: "hidden",
          fontSize: "x-small",
          fontFamily: "DejaVu Sans,sans-serif",
        }}
      >
        <div className="p-flex">
          <div className="p-grid p-justify-center pt-3 pb-3 mb-3">
            <div className="col-12">
              {/* <this.renderLoadingOverlay /> */}

              <div className="p-col-12 text-center mb-3">
                <div className="p-grid p-justify-center">
                  <div
                    className="p-col-12"
                    style={{ borderBottom: "2px solid #DFE9F2" }}
                  >
                    <this.renderCompanyInformation />
                  </div>

                  <this.renderEmployeeInfo />
                </div>
              </div>

              {/* PAYSLIP DETAILS */}
              <div className="p-col-12 text-center border mb-3">
                <div className="p-grid p-fluid p-align-start">
                  {/* Wage Earnings Information */}
                  <div className="p-col-6 border-0">
                    <div className="p-grid p-fluid">
                      <div className="p-col-12 px-2 payslipSections">
                        <span className="font-weight-bold">Earnings</span>
                      </div>
                      <this.renderEarnings />
                      <div className="p-col-12 px-2 payslipSections">
                        <span className="font-weight-bold">
                          Additional Wages
                        </span>
                      </div>
                      {<this.renderAdditionalWage />}
                    </div>
                  </div>
                  {/* // Wage Earnings Information */}

                  {/* Wage Deduction Information */}
                  <div className="p-col-6 border-0">
                    <div className="p-grid p-fluid">
                      <div className="p-col-12 px-2 payslipSections">
                        <span className="font-weight-bold">Deductions</span>
                      </div>
                      <this.renderContributions />
                      <this.renderOtherDeductables />
                    </div>
                  </div>
                  {/* // Wage Deduction Information */}

                  <this.renderGrossNetPay />
                </div>
              </div>
              {/* // PAYSLIP DETAILS */}

              <this.renderEmployerContributions />

              <this.renderYTDearnings />

              {/* <this.renderUnpaidBreakdown /> */}

              {/* Prepared By */}
              <this.renderPreparedBy />
              {/* //Prepared By */}
            </div>
          </div>
          <div style={footerCSS}>
            <span>This is a digital copy. No signature is required</span>
          </div>
        </div>
      </div>
    );

    let display = (
      <div style={{ position: "absolute", left: "-1000px", top: 0 }}>
        {pdfCopyToBeExported}
      </div> // this hides it from the user but is still rendered :D
    );

    return <div>{display}</div>;
  }
}
