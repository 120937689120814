import moment from "moment";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AccountManagementService } from "../../service/AccountManagementService";
import { PayslipService } from "../../service/PayslipService";
import { CommonMethods, ParamIDTypes, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { CompanyDisplay, UserPayrollModel } from "../resources/ExportClass";
import { ToastStateContext } from "../resources/ToastContext";
import { PayrollModel } from "./PayrollCurrent";
import { PayrollSummary } from "./PayrollSummary";
import { PayrollTable } from "./PayrollTable";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

export function PayrollHistoryMonthDetails(props: RouteComponentProps<any>) {
  const { SetInfo } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);

  // const [companyID, setCompanyID] = useState(
  //   localStorage.getItem("currentAccessingCompany") ||
  //     props.userProfile.companyID
  // );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  // const [yearMonthFromParams, setYearMonthFromParams] = useState<string>("");
  const [payslipList, setPayslipList] = useState<UserPayrollModel[]>(
    new Array<UserPayrollModel>()
  );
  const [companyName, setCompanyName] = useState<string>("");
  const [payrollDate, setPayrollDate] = useState<Date>(new Date());
  const [payrollModel, setPayrollModel] = useState<PayrollModel>(
    new PayrollModel()
  );

  const [isPremiumPlan, setIsPremiumPlan] = useState<boolean>(false);
  const [exportStatutoryData, setExportStatutoryData] = useState<any>([]);

  const [companyAccount, setCompanyAccount] = useState<CompanyDisplay>();

  let payslipService = new PayslipService();
  let commonMethods = new CommonMethods();
  let accMgmt = new AccountManagementService();
  const { id } = useParams<ParamIDTypes>();

  useEffect(() => {
    getPayrollHistoryForMonthYear();
    // if (props.permission !== undefined) {
    //   commonMethods
    //     .strictCheck(props.userProfile, companyID, props.permission)
    //     .then((res) => {
    //       if (res !== undefined) {
    //         setAccessPermitted(res);
    //       }
    //       if (res) {
    //         getPayrollHistoryForMonthYear();
    //       }
    //     });
    // }
  }, []);

  const getPayrollHistoryForMonthYear = () => {
    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";
    setCompanyName(localStorage.getItem("currentAccessingCompanyName") || "");
    getCompanyAccount(currentAccessingCompanyId);
    commonMethods
      .hasPremiumPlanAccess()
      .then((res) => {
        setIsPremiumPlan(res);
        if (res) {
          payslipService
            .getPayrollHistory(id || "")
            .then((res) => {
              setPayrollModel(res);
              setPayslipList(res.userPayrollModelList);
              setPayrollDate(res.payrollPeriod);
              setIsLoading(false);
            })
            .catch((err) => {
              setErrorMessage(commonMethods.getErrorMessage(err));
              setIsError(true);
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
        setIsLoading(false);
      });
  };

  const getCompanyAccount = (currentAccessingCompanyId: string) => {
    accMgmt
      .getAllAccounts("company", currentAccessingCompanyId)
      .then((res) => {
        setCompanyAccount(res[0]);
      })
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
        setIsLoading(false);
      });
  };

  // fOOTER METHODS
  const calculateTotalNetPayable = () => {
    let total = 0;
    payslipList.forEach((obj) => {
      total += obj.netSalary;
    });
    return total;
  };

  const calculateTotalEmployeeCPF = () => {
    let total = 0;
    payslipList.forEach((obj) => {
      total += obj.employeeCPFValue;
    });
    return total;
  };
  const calculateTotalSHG = () => {
    let total = 0;
    payslipList.forEach((obj) => {
      total += obj.shgValue;
    });
    return total;
  };
  const calculateTotalSDL = () => {
    let total = 0;
    payslipList.forEach((obj, index) => {
      total += obj.sdf;
    });
    total = Math.floor(total);
    return total;
  };
  const calculateTotalEmployerCPF = () => {
    let total = 0;
    payslipList.forEach((obj, index) => {
      total += obj.employerCPFValue;
    });
    return total;
  };
  const getSummary = () => {
    let others = {
      totalNetPay: calculateTotalNetPayable(),
      totalCPF: calculateTotalEmployerCPF() + calculateTotalEmployeeCPF(),
      totalSHG: calculateTotalSHG(),
      totalSDL: calculateTotalSDL(),
      isPremium: isPremiumPlan,
    };
    return others;
  };

  const exportStatutory = () => {
    if (!isPremiumPlan) {
      SetError("This is a requires premium access.");
    } else {
      SetInfo("This feature is coming soon!");
    }
    return false;
  };

  let tables = (
    <>
      {!isPremiumPlan && (
        <div className="pb-2 text-danger">
          This page requires Premium access. To unlock, click{" "}
          <Link to="/priceplans" target="_blank">
            here
          </Link>
          .
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <div className="row ">
            <div className="col-12 col-lg-6 px-4 text-center">
              <h5 className="p-col-12">
                {commonMethods.formatDateDisplayForPayrollPeriod(payrollDate)}
              </h5>
              <span className="boldLabel">Company</span>
              <p>{companyName}</p>

              <span className="boldLabel">Pay Day</span>

              <p> {commonMethods.displayDate_Words(payrollDate)}</p>
            </div>
            <div className="col-12 col-lg-6 px-4">
              <div className="card p-0">
                <div className="card-header">
                  <b>Statutory</b>
                </div>
                <div className="card-body">
                  <div className="p-grid p-align-center p-justify-between">
                    <div className="p-col-5">CPF Submission</div>
                    <span style={{ float: "right" }}>
                      <CSVLink
                        onClick={exportStatutory}
                        data={exportStatutoryData}
                        filename={
                          "CPF_" +
                          companyName.replace(/ /g, "") +
                          moment(payrollDate).format("MM-YYYY") +
                          ".csv"
                        }
                      >
                        <Button
                          className="p-button"
                          label="Download"
                          onClick={(e) => e.preventDefault}
                          disabled={!isPremiumPlan}
                        />
                      </CSVLink>
                    </span>
                  </div>
                </div>
              </div>
              <div className="card p-0">
                <PayrollSummary {...props} others={getSummary()} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PayrollTable
        {...props}
        others={{
          companyAccount: companyAccount,
          swiftCodeList: [],
          payrollModel: payrollModel,
          payslipObjects: payslipList,
          isPremiumPlan: isPremiumPlan,
		  completed: true,
        }}
      />
    </>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = tables;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <h1 className="pb-2">Payroll History</h1>
          {display}
        </div>
      </div>
    </div>
  );
}
