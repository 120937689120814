import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DeliverableService from "../../service/DeliverableService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import { ToastStateContext } from "../resources/ToastContext";
import { DeliverableModel } from "./DeliverablesInCampaign";

function Board(props: RouteComponentProps<any>) {
  let commonMethods = new CommonMethods();
  let deliverableService = new DeliverableService();
  const [deliverables, setDeliverables] = useState<any[]>([]);
  const [deliverableOptions, setDeliverableOptions] = useState<any[]>([]);
  // const [redirectOut, setRedirectOut] = useState<boolean>(false);
  // const [redirectPath, setRedirectPath] = useState<string>("");
  // const [dialogHeaderStr, setDialogHeaderStr] = useState<string>("");
  // const [infoVisible, setInfoVisible] = useState<boolean>(false);
  // const [dialogStr, setDialogStr] = useState<JSX.Element>();

  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  useEffect(() => {
    let boardDeliverables: any = {};
    if (deliverableOptions) {
      deliverableOptions?.forEach(element => {
        boardDeliverables[element] = [];
        props.others?.map((del: any) => {
          if (del.deliverableStatus === element) {
            boardDeliverables[element].push(del);
          }
        });
      });
      setDeliverables(boardDeliverables);
    }
  }, [props.others, deliverableOptions]);

  useEffect(() => {
    setDeliverableOptions(
      commonMethods.getDeliverableStatusDropdown().map(ele => {
        return ele.label;
      })
    );
  }, []);

  // const actionTemplate = (rowData: any) => {
  //   return (
  //     <div style={{ display: "flex", justifyContent: "flex-end" }}>
  //       <Button
  //         tooltip="View Deliverable"
  //         tooltipOptions={{ position: "top" }}
  //         type="button"
  //         icon="pi pi-eye"
  //         className="p-button-primary"
  //         onClick={() => showInfoDialog(rowData)}
  //       />
  //       <Button
  //         tooltip="Edit Deliverable"
  //         tooltipOptions={{ position: "top" }}
  //         icon="pi pi-pencil"
  //         className="p-button-warning mx-1"
  //         onClick={() => {
  //           setRedirectPath(
  //             "/deliverable/" +
  //               rowData.campaignID +
  //               "/update/" +
  //               rowData.deliverableID
  //           );
  //           setRedirectOut(true);
  //         }}
  //       />
  //       <Button
  //         tooltip="Delete Deliverable"
  //         tooltipOptions={{ position: "top" }}
  //         icon="pi pi-trash"
  //         className="p-button-danger"
  //         // onClick={() => {
  //         //   setDeliverableObj(rowData);
  //         //   setDialog(
  //         //     `Delete ${rowData.deliverableName}`,
  //         //     `Are you sure you want to delete this deliverable?`,
  //         //     "delete"
  //         //   );
  //         // }}
  //       />
  //     </div>
  //   );
  // };

  // const showInfoDialog = (deliverable: DeliverableModel) => {
  //   setDialogHeaderStr("Deliverable Details");

  //   let displayLinks: any[] = [];
  //   if (deliverable.deliverableLink !== "") {
  //     let delLinkArr = deliverable.deliverableLink.split(";");
  //     delLinkArr.forEach(link => {
  //       displayLinks.push(
  //         <p className="p-0 m-0">
  //           <a href={link} target="_blank" rel="noopener noreferrer">
  //             {_.truncate(link)}
  //           </a>
  //         </p>
  //       );
  //     });
  //   }

  //   let closedFields = (
  //     <>
  //       <tr>
  //         <td className="font-weight-bold">Closed By:</td>
  //         <td>{deliverable.closedByName}</td>
  //       </tr>
  //       <tr>
  //         <td className="font-weight-bold">Closed Date:</td>
  //         <td>
  //           {commonMethods.isDateStringNull(deliverable.closedDate)
  //             ? ""
  //             : commonMethods.displayDate(
  //                 new Date(deliverable.closedDate)
  //               )}
  //         </td>
  //       </tr>
  //     </>
  //   );

  //   let html = (
  //     <>
  //       <table className="leave-info-dialog table">
  //         <tbody>
  //           <tr>
  //             <td className="font-weight-bold">Serial:</td>
  //             <td>{deliverable.deliverableSerialNo}</td>
  //           </tr>
  //           <tr>
  //             <td className="font-weight-bold"> Name:</td>
  //             <td>{deliverable.deliverableName}</td>
  //           </tr>
  //           <tr>
  //             <td className="font-weight-bold"> Type:</td>
  //             <td>{deliverable.deliverableTypeName}</td>
  //           </tr>
  //           <tr>
  //             <td className="font-weight-bold"> Status</td>
  //             <td>{deliverable.deliverableStatus}</td>
  //           </tr>
  //           <tr>
  //             <td className="font-weight-bold">Requirements:</td>
  //             <td>{deliverable.requirements}</td>
  //           </tr>
  //           {/* <tr>
  //             <td className="font-weight-bold">Remarks:</td>
  //             <td>{deliverable.remarks}</td>
  //           </tr> */}

  //           <tr>
  //             <td className="font-weight-bold">Created By:</td>
  //             <td>{deliverable.createdByName}</td>
  //           </tr>
  //           <tr>
  //             <td className="font-weight-bold">Created On:</td>
  //             <td>
  //               {commonMethods.displayDate(
  //                 new Date(deliverable.createdTime)
  //               )}
  //             </td>
  //           </tr>
  //           {deliverable.deliverableStatus === "Published" && closedFields}
  //           <tr>
  //             <td className="font-weight-bold">Publication Date:</td>
  //             <td>
  //               {" "}
  //               {commonMethods.displayDate(
  //                 deliverable.publicationDate
  //               )}
  //             </td>
  //           </tr>
  //           <tr>
  //             <td className="font-weight-bold">Link:</td>
  //             <td>{displayLinks}</td>
  //           </tr>

  //           <tr>
  //             <td className="font-weight-bold">Members:</td>
  //             <td>{pmoTemplate(deliverable)}</td>
  //           </tr>
  //         </tbody>
  //       </table>
  //     </>
  //   );
  //   setDialogStr(html);
  //   setInfoVisible(true);
  // };

  const pmoTemplate = (rowData: DeliverableModel) => {
    return rowData.deliverableMembersForDisplay.map(
      (item: string, index: number) => {
        if (index === rowData.deliverableMembersForDisplay.length - 1) {
          return item;
        } else {
          return item + ", ";
        }
      }
    );
  };

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination || destination.droppableId === source.droppableId) {
      return;
    } else {
      let clone = Object.assign({}, deliverables);
      let sourceDeliverable = clone[source.droppableId][source.index];
      sourceDeliverable.deliverableStatus = destination.droppableId;
      deliverableService
        .updateDeliverableStatus(sourceDeliverable)
        .then(res => {
          SetSuccess(
            `${sourceDeliverable.deliverableName} successfully changed to ${destination.droppableId}`
          );
        })
        .catch(err => {
          SetError(
            `Failed to change ${sourceDeliverable.deliverableName} to ${destination.droppableId}`
          );
        });
      clone[destination.droppableId].splice(
        destination.index,
        0,
        sourceDeliverable
      );
      clone[source.droppableId].splice(source.index, 1);
      setDeliverables(clone);
      return;
    }
  };

  return (
    <div className="row no-gutters">
      {/* <Dialog
        header={dialogHeaderStr}
        visible={infoVisible}
        modal={true}
        //dismissableMask={true}
        onHide={() => setInfoVisible(false)}
      >
        {dialogStr}
      </Dialog> */}
      {/* {redirectOut ? (
        <Redirect
          push
          to={{
            pathname: redirectPath,
          }}
        />
      ) : null} */}
      <div className="col-12">
        {/* <div className="card px-0 py-0"> */}
        <div className="row no-gutters">
          <DragDropContext onDragEnd={onDragEnd}>
            {Object.keys(deliverables).map((element: any) => {
              return (
                <Droppable droppableId={element} key={element}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="col"
                      >
                        <div className="card border-0">
                          <h5 className="card-header">{element}</h5>
                          <div className="card-body px-0">
                            {deliverables[element].map(
                              (deliverable: DeliverableModel, index: number) => {
                                return (
                                  <Draggable
                                    draggableId={
                                      deliverable.deliverableSerialNo
                                    }
                                    index={index}
                                    key={deliverable.deliverableSerialNo}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="card"
                                        key={deliverable.deliverableSerialNo}
                                      >
                                        <h6>{deliverable.deliverableName} <span className="float-right">{deliverable.deliverableSerialNo}</span></h6> 
                                        <span>
                                          {deliverable.deliverableTypeName}
                                        </span>
                                        <span>
                                       {pmoTemplate(deliverable)}
                                        </span>
                                        {/* {actionTemplate(deliverable)} */}
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              }
                            )}
                          </div>
                        </div>
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              );
            })}
          </DragDropContext>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default Board;
