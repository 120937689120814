import React, { useState, useEffect, useRef } from "react";
import { RouteComponentProps } from "../resources/CommonMethods";
import {
  MultipleEquipmentLoanModel,
  EquipmentsModel
} from "../resources/ExportClass";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ToastStateContext } from "../resources/ToastContext";
import { SelectButton } from "primereact/selectbutton";
import { UserService } from "../../service/UserService";
import { UserModel } from "../user/User";
import { ChronosService } from "../../service/ChronosService";
import Axios from "axios";

const userSvc = new UserService();
const chronosSvc = new ChronosService();
const movementType = [
  { label: "Withdraw", value: "OUT" },
  { label: "Return", value: "IN" }
];

/**
 * Equipment withdrawal / return
 * @param props
 */
function EquipmentInOut(props: RouteComponentProps<any>) {
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [isEquipmentScanning, setEquipmentScanningStatus] = useState<boolean>(
    false
  );
  const [scannedEquipmentID, setScannedEquipmentID] = useState<string>("");
  const [scannedEquipmentsDisplay, setScannedEquipmentsDisplay] = useState<
    Array<any>
  >([]);
  const [userWithdrawnDisplay, setUserWithdrawnDisplay] = useState<Array<any>>(
    []
  );

  const [userInfo, setUserInfo] = useState<UserModel>(new UserModel());
  const [userWithdrawnList, setUserWithdrawnList] = useState<
    Array<EquipmentsModel>
  >([]);
  const [multipleLoanObj, setMultipleLoanObj] = useState<
    MultipleEquipmentLoanModel
  >(new MultipleEquipmentLoanModel());

  let inputRef: any = useRef(null);

  // Default set to 'Withdraw'
  useEffect(() => {
    setMultipleLoanObj({
      ...multipleLoanObj,
      transType: "OUT"
    });
  }, []);

  useEffect(() => {
    if (isEquipmentScanning && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEquipmentScanning]);

  // ComponentDidMount
  useEffect(() => {
    let display: any = [];
    if (userWithdrawnList.length > 0) {
      userWithdrawnList.forEach((value: EquipmentsModel, index: number) => {
        display.push(
          <>
            <p className="mb-1">
              <span className="boldLabel">{value.equipmentSerialNo}</span>{" "}
              <span>{value.equipmentName}</span>
            </p>
          </>
        );
      });
    }
    setUserWithdrawnDisplay(display);
  }, [userWithdrawnList.length]);

  // Functions
  const getUserInfo = () => {
    if (multipleLoanObj.userID !== "") {
      Axios.all([
        userSvc.getUserById(multipleLoanObj.userID),
        chronosSvc.getListOfUsersWithdrawnEquipment(multipleLoanObj.userID)
      ])
        .then(
          Axios.spread((first, second) => {
            setUserInfo(first);
            setUserWithdrawnList(second);
            setEquipmentScanningStatus(true);
          })
        )
        .catch(err => {
          setMultipleLoanObj({
            ...multipleLoanObj,
            userID: ""
          });
        });
    }
  };

  const saveEquipmentInfo = () => {
    if (scannedEquipmentID.match(/^TSL-\d{4}-\d{4}/) !== null) {
      if (
        multipleLoanObj.logisticsIDList.findIndex(
          e => e.equipmentSerialNo === scannedEquipmentID
        ) >= 0
      ) {
        SetError("Equipment Already Scanned");
      } else {
        chronosSvc
          .getEquipmentAvailability(
            multipleLoanObj.transType,
            scannedEquipmentID
          )
          .then(res => {
            var temp = multipleLoanObj.logisticsIDList;
            temp.push(res);
            setMultipleLoanObj({ ...multipleLoanObj, logisticsIDList: temp });
          })
          .catch(err => {
            SetError("Equipment Unavailable!");
          });
      }
    } else {
      SetError("Wrong Equipment ID");
    }

    setScannedEquipmentID("");
  };

  const registerEquipmentLoan = () => {
    if (multipleLoanObj.logisticsIDList.length > 0) {
      chronosSvc
        .postEquipmentLoan(multipleLoanObj)
        .then(res => {
          if (multipleLoanObj.transType === "IN") {
            SetSuccess("Equipments Returned");
          } else {
            SetSuccess("Equipments Withdrawn");
          }
        })
        .catch(err => {
          SetError("Unable to submit request");
        })
        .finally(() => {
          resetAll();
        });
    }
  };

  const resetAll = () => {
    setMultipleLoanObj({
      ...multipleLoanObj,
      userID: ""
    });

    setUserInfo(new UserModel());
    setUserWithdrawnList([]);
    setUserWithdrawnDisplay([]);
    setScannedEquipmentsDisplay([]);
    setEquipmentScanningStatus(false);
    setMultipleLoanObj(new MultipleEquipmentLoanModel());
    setScannedEquipmentID("");
  };

  useEffect(() => {
    let temp: any = [];
    for (let i = 0; i < multipleLoanObj.logisticsIDList.length; i++) {
      temp.push(
        <p className="mb-1">
          <span className="boldLabel">
            {multipleLoanObj.logisticsIDList[i].equipmentSerialNo}
          </span>{" "}
          <span>{multipleLoanObj.logisticsIDList[i].equipmentName}</span>
          <span
            style={{ verticalAlign: "sub" }}
            onClick={() => {
              multipleLoanObj.logisticsIDList.splice(i, 1);
            }}
          >
            <i className="pi pi-fw pi-times" />
          </span>
        </p>
      );
    }
    setScannedEquipmentsDisplay(temp);
  }, [multipleLoanObj.logisticsIDList.length]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-12">
          <div
            className="card"
            data-tour-id="equipment-transfer"
            data-tour="Use this page to change the loan status of the equipment you intend to use."
          >
            <h1>Equipment Transfer</h1>
            <div
              className="col-12 text-center my-3"
              data-tour-id="equipment-return-withdraw"
              data-tour="Select your action, Withdraw or Return"
            >
              <SelectButton
                value={multipleLoanObj.transType}
                options={movementType}
                onChange={e =>
                  setMultipleLoanObj({
                    ...multipleLoanObj,
                    transType: e.value
                  })
                }
              />
            </div>

            {multipleLoanObj.transType === "" ? (
              <></>
            ) : (
              <div className="row card-body">
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <div className="form-group row">
                        <span className="boldLabel col-12">User</span>
                        <div className="col-12">
                          <InputText
                            style={{ width: "80%" }}
                            value={
                              userInfo.firstName !== ""
                                ? userInfo.firstName + " " + userInfo.lastName
                                : multipleLoanObj.userID
                            }
                            placeholder="Scan User Card"
                            onKeyPress={e => {
                              if (e.key === "Enter") {
                                getUserInfo();
                              }
                            }}
                            onChange={(
                              e: React.FormEvent<HTMLInputElement>
                            ) => {
                              setMultipleLoanObj({
                                ...multipleLoanObj,
                                userID: Buffer.from(e.currentTarget.value, 'base64').toString('hex').replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/,"$1-$2-$3-$4-$5")
                              });
                            }}
                            disabled={userInfo.firstName !== ""}
                            autoFocus={true}
                          />
                          <span onClick={() => resetAll()}>
                            <i className="pi pi-fw pi-replay" />
                          </span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12">
                          <span className="boldLabel">Equipments</span>
                        </div>
                        <div className="col-12">
                          {isEquipmentScanning ? (
                            <InputText
                              id="equipmentScanningInput"
                              ref={(ref: any) => (inputRef = ref)}
                              style={{ width: "80%" }}
                              value={scannedEquipmentID}
                              autoFocus
                              disabled={!isEquipmentScanning}
                              onChange={(
                                e: React.FormEvent<HTMLInputElement>
                              ) => {
                                setScannedEquipmentID(e.currentTarget.value);
                              }}
                              onKeyPress={e => {
                                if (e.key === "Enter") {
                                  saveEquipmentInfo();
                                }
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12">{scannedEquipmentsDisplay}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col-12 form-group"
                      data-tour-id="equipment-return-withdraw-button"
                      data-tour="Click Transfer when you are done selecting"
                    >
                      <Button
                        label="Transfer"
                        onClick={e => {
                          e.preventDefault();
                          registerEquipmentLoan();
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <span className="boldLabel">Not Returned</span>
                  {userWithdrawnDisplay}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default EquipmentInOut;
