import React, { useEffect, useState } from 'react'
import Picker from 'emoji-picker-react';
import { IEmojiData } from 'emoji-picker-react'
import { EditorState, Modifier } from 'draft-js';

export default function EmojiPicker({
    loadEmojiPicker,
    setLoadEmojiPicker,
    editorState,
    setEditorState,
    setIsEditorFocused,
}: {
    loadEmojiPicker: boolean;
    setLoadEmojiPicker: React.Dispatch<React.SetStateAction<boolean>>;
    editorState: EditorState
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>
    setIsEditorFocused?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [chosenEmoji, setChosenEmoji] = useState<IEmojiData | null>(null);

    const onEmojiClick = (event: React.MouseEvent, emojiObject: IEmojiData) => {
        setChosenEmoji(emojiObject);
        setEditorState(insertText(emojiObject.emoji, editorState))
        if (setIsEditorFocused) setIsEditorFocused(true)
        setLoadEmojiPicker(false)
    };

    function insertText(text: string, editorState: EditorState) {
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            text
        );

        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
        return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
    }

    return (
        <div>
            {loadEmojiPicker ? <Picker onEmojiClick={onEmojiClick} /> : null}
        </div>
    )
}
