import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AttendanceService } from "../../service/AttendanceService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";

export class GuestTrackingModel {
  public fullName: string = "";
  public mobileNo: string = "";
  public identityNo: string = "";
  public identityType: string = "R";
  public guestTrackingID: string = CommonMethods.EmptyGuid;
  public checkInDateTime: Date = new Date();
  public checkOutDateTime: Date = new Date();
  public personType: string = "G";
  public companyID: string = CommonMethods.EmptyGuid;
  public groupCompanyID: string = CommonMethods.EmptyGuid;
}

export class LoginModel {
  public email: string = "";
  public password: string = "";
}
enum GuestTrackingIDType {
  Company = "c",
  GroupCompany = "g",
  Staff = "S",
  Guest = "G",
}

function SafeEntry(props: RouteComponentProps<any>) {
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);
  const { SetWarn } = React.useContext(ToastStateContext);
  const [guestTrackingModel, setGuestTrackingModel] = useState<
    GuestTrackingModel
  >(new GuestTrackingModel());
  const [staffLoginModel, setStaffLoginModel] = useState<LoginModel>(
    new LoginModel()
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [isCheckIn, setIsCheckIn] = useState<boolean>(false);
  const [isStaffCheckIn, setIsStaffCheckIn] = useState<boolean>(false);
  const [isCheckOut, setIsCheckOut] = useState<boolean>(false);

  const [type, setType] = useState<string>("");
  const [typeid, setTypeid] = useState<string>("");

  const [location, setLocation] = useState<string>("");

  const [urlErrorMsg] = useState<string>("Please ensure your URL is correct");

  const [afterFormSubmission, setAfterFormSubmission] = useState<boolean>(
    false
  );

  let commonMethods = new CommonMethods();
  let attService = new AttendanceService();

  let typeDropdown = commonMethods.getIdentityTypeDropdown();
  let { id } = useParams();
  useEffect(() => {
    let err = false;

    if (id !== undefined && id !== "") {
      setTypeid(id);
      if (props.location.search === "?g") {
        setType(GuestTrackingIDType.GroupCompany);
      } else if (props.location.search === "?c") {
        setType(GuestTrackingIDType.Company);
      } else {
        err = true;
      }
    } else {
      err = true;
    }
    if (err) {
      SetError("Invalid URL");
      setIsError(true);
    }
  }, []);

  useEffect(() => {
    // get company/groupcompany name
    if (type !== "" && typeid !== "") {
      getLocationName();
    }
  }, [type, typeid]);

  const getLocationName = () => {
    if (
      type !== GuestTrackingIDType.Company &&
      type !== GuestTrackingIDType.GroupCompany
    ) {
      SetError(urlErrorMsg);
      return;
    }
    attService
      .getLocation(type, typeid)
      .then((res) => {
        setLocation(res);
      })
      .catch((err) => {
        let errorMsg = commonMethods.getErrorMessage(err);
        SetError(errorMsg);
        // SetError(urlErrorMsg);
        setIsError(true);
      });
  };

  const handleSubmit = () => {
    setIsLoading(true);

    //validate
    let fullItem = guestTrackingModel;
    if (type === GuestTrackingIDType.GroupCompany) {
      fullItem.groupCompanyID = typeid;
    } else if (type === GuestTrackingIDType.Company) {
      fullItem.companyID = typeid;
    } else {
      SetError(urlErrorMsg);
      setIsLoading(false);
      return;
    }
    //submit
    attService
      .guestCheckIn(fullItem)
      .then((res) => {
        SetSuccess("Checked In!");
        setAfterFormSubmission(true);
        setIsCheckIn(true);
      })
      .catch((err) => {
        let errorMsg = commonMethods.getErrorMessage(err);
        if (commonMethods.isWarning(err)) {
          SetWarn(errorMsg);
          setAfterFormSubmission(true);
          setIsCheckIn(true);
        } else {
          SetError(errorMsg);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleStaffSubmit = () => {
    setIsLoading(true);
    // validate
    let fullItem = guestTrackingModel;
    if (type === GuestTrackingIDType.GroupCompany) {
      fullItem.groupCompanyID = typeid;
    } else if (type === GuestTrackingIDType.Company) {
      fullItem.companyID = typeid;
    } else {
      SetError(urlErrorMsg);
      setIsLoading(false);
      return;
    }
    // submit
    attService
      .staffCheckIn(staffLoginModel)
      .then((res) => {
        setGuestTrackingModel(res);
      })
      .catch((err) => {
        let errorMsg = commonMethods.getErrorMessage(err);
        SetError(errorMsg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmitCheckOut = () => {
    setIsLoading(true);
    // validate
    let fullItem = guestTrackingModel;
    if (type === GuestTrackingIDType.GroupCompany) {
      fullItem.groupCompanyID = typeid;
    } else if (type === GuestTrackingIDType.Company) {
      fullItem.companyID = typeid;
    } else {
      SetError(urlErrorMsg);
      setIsLoading(false);
      return;
    }

    // submit form
    attService
      .guestCheckOut(fullItem)
      .then((res) => {
        SetSuccess("Checked Out!");
        setAfterFormSubmission(true);
        setIsCheckOut(true);
      })
      .catch((err) => {
        let errorMsg = commonMethods.getErrorMessage(err);
        if (commonMethods.isWarning(err)) {
          SetWarn(errorMsg);
          setAfterFormSubmission(true);
          setIsCheckOut(true);
        } else {
          SetError(errorMsg);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkIn = () => {
    setIsCheckIn(true);
    setGuestTrackingModel({
      ...guestTrackingModel,
      personType: GuestTrackingIDType.Guest,
    });
  };
  const staffCheckIn = () => {
    setIsStaffCheckIn(true);
    setGuestTrackingModel({
      ...guestTrackingModel,
      personType: GuestTrackingIDType.Staff,
    });
  };
  const checkOut = () => {
    setIsCheckOut(true);
    // getCheckOutDropdown();
  };
  const refreshPage = () => {
    setGuestTrackingModel(new GuestTrackingModel());
    setStaffLoginModel(new LoginModel());
    setIsCheckIn(false);
    setIsStaffCheckIn(false);
    setIsCheckOut(false);
    setIsLoading(false);
    setAfterFormSubmission(false);
  };

  let display = (
    <div className="p-grid p-fluid pt-5">
      <div className="p-col-12 text-center">
        <div className="card pointer" onClick={staffCheckIn}>
          <div className="d-flex align-items-center justify-content-center">
            <i className="pi pi-sign-in bigSign greenIcon d-inline"></i>
            <h5 className="d-inline m-0">CHECK-IN (STAFF)</h5>
          </div>
        </div>
        <div className="card pointer" onClick={checkIn}>
          <div className="d-flex align-items-center justify-content-center">
            <i className="pi pi-sign-in bigSign greenIcon d-inline"></i>
            <h5 className="d-inline m-0">CHECK-IN (VISITOR)</h5>
          </div>
        </div>
        <div className="card pointer" onClick={checkOut}>
          <div className="d-flex align-items-center justify-content-center">
            <i className="pi pi-sign-in bigSign redIcon d-inline"></i>
            <h5 className="d-inline m-0">CHECK-OUT</h5>
          </div>
        </div>
      </div>
    </div>
  );

  let terms = (
    <div className="p-grid p-fluid pt-5 pb-5">
      <div className="p-col-12">
        <span className="boldLabel">Terms</span>

        <p>
          You represent and warrant that your name, NRIC number and mobile
          number (“Contact Data”) submitted by you are true, current and
          complete.
        </p>
        <p>
          Through your use of this form, you agree that such Contact Data may be
          shared, transferred and disclosed to the relevant public sector
          agencies and third parties for the purpose of preventing or
          controlling the incidence or transmission of COVID-19.
        </p>
      </div>
    </div>
  );

  let checkInNotes = (
    <div className="p-col-12">
      <p>
        <b>By checking in, you declare that:</b>
      </p>

      <ul>
        <li>
          You have not had close contact with a confirmed COVID-19 case in the
          past 14 days *#
        </li>
        <li>
          You are not currently under a Quarantine Order or Stay-Home Notice *
        </li>
        <li>You do not have any fever or flu-like symptoms *</li>
        <li>
          You agree to the terms and consent to the collection/use of your
          information for COVID-19 contact tracing
        </li>
      </ul>
      <span className="textFade">
        {" "}
        * not applicable if you are seeking treatment at a medical facility{" "}
        <br></br># not applicable to COVID-19 frontline workers
      </span>
    </div>
  );
  if (isCheckIn) {
    display = (
      <>
        <form onSubmit={handleSubmit}>
          <div className="pb-4">
            <div className="d-flex align-items-center justify-content-center">
              <i className="pi pi-sign-in bigSign greenIcon d-inline"></i>
              <h5 className="d-inline m-0">CHECK-IN (VISITOR)</h5>
            </div>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <span className="boldLabel">
                Identity Type <span style={{ color: "red" }}>*</span>
              </span>
              <Dropdown
                required
                options={typeDropdown}
                placeholder="Please Select"
                value={guestTrackingModel.identityType}
                onChange={(e) => {
                  setGuestTrackingModel({
                    ...guestTrackingModel,
                    identityType: e.value,
                  });
                }}
              />
            </div>
            <div className="p-col-12">
              <span className="boldLabel">
                NRIC/FIN or Passport <span style={{ color: "red" }}>*</span>
              </span>
              <InputText
                value={guestTrackingModel.identityNo}
                onChange={(e) =>
                  setGuestTrackingModel({
                    ...guestTrackingModel,
                    identityNo: e.currentTarget.value,
                  })
                }
                required
              />
            </div>
            <div className="p-col-12">
              <span className="boldLabel">
                Full Name
                <span style={{ color: "red" }}>*</span>
              </span>
              <InputText
                value={guestTrackingModel.fullName}
                onChange={(e) =>
                  setGuestTrackingModel({
                    ...guestTrackingModel,
                    fullName: e.currentTarget.value,
                  })
                }
                required
              />
            </div>
            <div className="p-col-12">
              <span className="boldLabel">
                Mobile No. <span style={{ color: "red" }}>*</span>
              </span>
              <InputText
                keyfilter="int"
                value={guestTrackingModel.mobileNo}
                onChange={(e) =>
                  setGuestTrackingModel({
                    ...guestTrackingModel,
                    mobileNo: e.currentTarget.value,
                  })
                }
                required
              />
            </div>
            {checkInNotes}
          </div>
          <div className="p-grid p-justify-center pt-4">
            <Button type="submit" label={"Check In"} />
          </div>
        </form>

        <div className="pb-4"></div>
      </>
    );
  }

  if (isStaffCheckIn) {
    display = (
      <>
        <div className="pb-4">
          <div className="d-flex align-items-center justify-content-center">
            <i className="pi pi-sign-in bigSign greenIcon d-inline"></i>
            <h5 className="d-inline m-0">CHECK-IN (STAFF)</h5>
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">
              Email <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              maxLength={254}
              required
              keyfilter="email"
              type="text"
              value={staffLoginModel.email}
              onChange={(e) =>
                setStaffLoginModel({
                  ...staffLoginModel,
                  email: e.currentTarget.value,
                })
              }
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Password <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              maxLength={128}
              required
              type="password"
              value={staffLoginModel.password}
              onChange={(e) =>
                setStaffLoginModel({
                  ...staffLoginModel,
                  password: e.currentTarget.value,
                })
              }
            />
          </div>
        </div>
        <div className="p-grid p-justify-center pt-4">
          <Button
            type="submit"
            label={"Retrieve Staff Details"}
            onClick={(e) => {
              e.preventDefault();
              handleStaffSubmit();
            }}
          />
        </div>

        <form onSubmit={handleSubmit}>
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <span className="boldLabel textFade">
                Identity Type <span style={{ color: "red" }}>*</span>
              </span>
              <Dropdown
                disabled
                required
                options={typeDropdown}
                placeholder="Please Select"
                value={guestTrackingModel.identityType}
                onChange={(e) => {
                  setGuestTrackingModel({
                    ...guestTrackingModel,
                    identityType: e.value,
                  });
                }}
              />
            </div>
            <div className="p-col-12">
              <span className="boldLabel textFade">
                NRIC/FIN or Passport <span style={{ color: "red" }}>*</span>
              </span>
              <InputText
                disabled
                value={guestTrackingModel.identityNo}
                onChange={(e) =>
                  setGuestTrackingModel({
                    ...guestTrackingModel,
                    identityNo: e.currentTarget.value,
                  })
                }
                required
              />
            </div>
            <div className="p-col-12">
              <span className="boldLabel textFade">
                Full Name
                <span style={{ color: "red" }}>*</span>
              </span>
              <InputText
                disabled
                value={guestTrackingModel.fullName}
                onChange={(e) =>
                  setGuestTrackingModel({
                    ...guestTrackingModel,
                    fullName: e.currentTarget.value,
                  })
                }
                required
              />
            </div>
            <div className="p-col-12">
              <span className="boldLabel textFade">
                Mobile No. <span style={{ color: "red" }}>*</span>
              </span>
              <InputText
                keyfilter="int"
                disabled
                value={guestTrackingModel.mobileNo}
                onChange={(e) =>
                  setGuestTrackingModel({
                    ...guestTrackingModel,
                    mobileNo: e.currentTarget.value,
                  })
                }
                required
              />
            </div>
            {checkInNotes}
          </div>
          <div className="p-grid p-justify-center pt-4">
            <Button type="submit" label={"Check In"} />
          </div>
        </form>

        <div className="pb-4"></div>
      </>
    );
  }

  if (isCheckOut) {
    display = (
      <form onSubmit={handleSubmitCheckOut}>
        <div className="pb-4">
          <div className="d-flex align-items-center justify-content-center">
            <i className="pi pi-sign-in bigSign redIcon d-inline"></i>
            <h5 className="d-inline m-0">CHECK-OUT</h5>
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">
              Identity Type <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              required
              options={typeDropdown}
              placeholder="Please Select"
              value={guestTrackingModel.identityType}
              onChange={(e) => {
                setGuestTrackingModel({
                  ...guestTrackingModel,
                  identityType: e.value,
                });
              }}
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              NRIC/FIN or Passport <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={guestTrackingModel.identityNo}
              onChange={(e) =>
                setGuestTrackingModel({
                  ...guestTrackingModel,
                  identityNo: e.currentTarget.value,
                })
              }
              required
            />
          </div>
        </div>
        <div className="p-grid p-justify-center pt-4">
          <Button type="submit" label={"Check Out"} />
        </div>
      </form>
    );
  }

  if (isLoading) {
    display = (
      <div className="text-center">
        <ProgressSpinner />
      </div>
    );
  }

  if (isError) {
    display = (
      <div className="text-center">
        <CustomError message="Please ensure that your Entry/Exit url is valid." />
      </div>
    );
  }

  if (afterFormSubmission && isCheckIn) {
    display = (
      <div className="p-4">
        <div className="card p-0 text-center">
          <div className="card-header safeEntryCheckIn py-4">
            {" "}
            <i className="pi pi-check bigSign "></i>
            <h3>SAFE ENTRY</h3>
          </div>
          <div className="card-body safeEntryCheckInOutCard pb-4">
            {" "}
            <p>You have successfully checked in to </p>
            <h3>{location.toUpperCase()}</h3> <p>on </p>{" "}
            <h3>
              {commonMethods.displayDateTime_Words(
                guestTrackingModel.checkInDateTime
              )}
            </h3>
          </div>
        </div>
      </div>
    );
  }
  if (afterFormSubmission && isCheckOut) {
    display = (
      <div className="p-4">
        <div className="card p-0 text-center">
          <div className="card-header safeEntryCheckOut py-4">
            {" "}
            <i className="pi pi-check bigSign "></i>
            <h3>SAFE EXIT</h3>
          </div>
          <div className="card-body safeEntryCheckInOutCard pb-4">
            {" "}
            <p>You have successfully checked out of </p>
            <h3>{location.toUpperCase()}</h3> <p>on </p>{" "}
            <h3>
              {commonMethods.displayDateTime_Words(
                guestTrackingModel.checkOutDateTime
              )}
            </h3>
          </div>
        </div>
      </div>
    );
  }
  let displayOuter;
  if (afterFormSubmission) {
    displayOuter = (
      <>
        <div className="row p-justify-center">
          <div className="col-12 col-xl-6">{display}</div>
        </div>

        <div className="row p-justify-center pt-4"></div>
      </>
    );
  } else {
    displayOuter = (
      <>
        <span className="text-center">
          {" "}
          <i className="fas fa-map-marker-alt bigSign text-danger"></i>{" "}
        </span>
        <h5 className="text-center pt-2"> {location}</h5>
        <div className="row p-justify-center">
          <div className="col-12 col-xl-6">
            {" "}
            <div className="p-col-12">
              <p className="text-center textFade">
                This is to comply with the government's protocol for contact
                tracing.
              </p>
            </div>{" "}
            {display}
          </div>
        </div>

        <div className="row p-justify-center pt-4">
          <div className="col-12 col-xl-6"> {terms}</div>
        </div>
      </>
    );
  }
  return (
    <div className="container">
      <div className="row pt-4">
        <div className="col-12 col-xl-12">
          <div className="card">
            <h1 className="pb-2 text-center">
              {" "}
              <i
                className="pi pi-refresh float-left pointer"
                onClick={refreshPage}
              ></i>
              Safe Entry/Exit
            </h1>

            {displayOuter}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SafeEntry;
