import { JSXElementConstructor, ReactNode } from "react";

export class ProcessedImage {
  base64: string | ArrayBuffer | null = null;
  file: File | null = null;
  errorMsg: string = "";
}

export class CommonDocumentMethods {
  static displayPDF(filePath: string) {
    if (filePath.includes(".pdf") || filePath.includes(".PDF")) {
      return true;
    }
    return false;
  }

  // outputs a base64 string to be used as a preview image and the file to be sent back to server side
  // Company/Payslip logo, Profile pic
  static uploadImage(
    file: File,
    maxWidth: number,
    maxHeight: number,
    type: string
  ): Promise<ProcessedImage> {
    return new Promise((resolve, reject) => {
      let returnObj = new ProcessedImage();
      // file size
      if (file.size > 2000000) {
        returnObj.errorMsg = "File too big! Has to be below 2MB.";
        reject(returnObj);
        return;
      }
      CommonDocumentMethods.resizeImage(file, maxWidth, maxHeight, type)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  private static resizeImage(
    file: File,
    maxWidth: number,
    maxHeight: number,
    type: string
  ): Promise<ProcessedImage> {
    return new Promise((resolve, reject) => {
      let returnObj = new ProcessedImage();

      var image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        let newWidth = maxWidth;
        let newHeight = maxHeight;

        if (type !== "company") {
          if (width > height) {
            newHeight = height * (maxWidth / width);
            newWidth = maxWidth;
          } else {
            newWidth = width * (maxHeight / height);
            newHeight = maxHeight;
          }
        } else {
          if (width > height) {
            newWidth = maxWidth;
          } else {
            newWidth = width * (maxHeight / height);
          }
        }

        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        canvas.width = newWidth;
        canvas.height = newHeight;

        if (ctx !== null) {
          ctx.drawImage(image, 0, 0, newWidth, newHeight);
          var dataurl = canvas.toDataURL();
          this.urltoFile(dataurl, file.name, "image/png")
            .then((res) => {
              returnObj.base64 = dataurl;
              returnObj.file = res;
              resolve(returnObj);
            })
            .catch((err) => reject(err));
        }
      };
      image.onerror = reject;
    });
  }

  private static urltoFile(url: string, filename: string, mimeType: string) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  // For Leave, Claim, Circular documents
  static async uploadImageOrDocument(imageFile: File): Promise<ProcessedImage> {
    var extension = imageFile.type;
    var fileName = imageFile.name;
    let imagetypes = ["jpg", "jpeg", "png"];
    let maxFileSize = 5000000;

    // image
    if (imagetypes.some((item) => extension.includes(item))) {
      if (imageFile.size <= maxFileSize) {
        // small enough
        return this.returnWithoutCompressing(imageFile);
      } else {
        // need to compress and resize
        return this.compressImage(imageFile, extension, fileName);
      }
    } else {
      // pdf
      if (imageFile.size <= maxFileSize) {
        return this.returnWithoutCompressing(imageFile);
      } else {
        let processedImage = new ProcessedImage();
        processedImage.errorMsg = "File size too large. Has to be below 5MB.";
        return processedImage;
      }
    }
  }

  private static returnWithoutCompressing(
    imageFile: File
  ): Promise<ProcessedImage> {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onloadend = () => {
        let processedImage = new ProcessedImage();
        processedImage.base64 = reader.result;
        processedImage.file = imageFile;
        resolve(processedImage);
      };
    });
  }

  private static compressImage(
    imageFile: File,
    extension: string,
    fileName: string
  ): Promise<ProcessedImage> {
    return new Promise((resolve, reject) => {
      var mime_type = "image/jpeg";
      var image = new Image();
      image.src = URL.createObjectURL(imageFile);
      image.onload = () => {
        var cvs = document.createElement("canvas");
        cvs.width = image.width;
        cvs.height = image.height;
        if (cvs !== null && cvs.getContext("2d") !== null) {
          var ctx = cvs.getContext("2d")!.drawImage(image, 0, 0);
          var newImageData = cvs.toDataURL(mime_type, 0.3);
          var result_image_obj = new Image();
          result_image_obj.src = newImageData;
          let result = result_image_obj.src;
          var base64String = result;

          // convert base64 to file
          const file = new File(
            [this.convertDataUrlToBlob(base64String)],
            fileName,
            { type: extension }
          );

          // return result;
          let processedImage = new ProcessedImage();
          processedImage.base64 = base64String;
          processedImage.file = file;
          resolve(processedImage);
        }
        let processedImage = new ProcessedImage();
        resolve(processedImage);
        // return null;
      };

      image.onerror = reject;
    });
  }

  private static convertDataUrlToBlob(dataUrl: string): Blob {
    const arr = dataUrl.split(",");

    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }
}
