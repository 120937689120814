import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as React from "react";
import { Redirect } from "react-router";
import { GroupCompanyService } from "../../service/GroupCompanyService";
import { RouteComponentProps, CommonMethods } from "../resources/CommonMethods";
import { ToastStateContext } from "../resources/ToastContext";

interface AppProps {
  router: RouteComponentProps<any>;
}

interface AppState {
  groupCompanyObj: GroupCompanyModel;
  groupCompanies: [];
  isLoading: boolean;
  redirectOut: boolean;
  redirectPath: string;

  // create
  formHeader: string;
  action: string;

  displayButton: boolean;
}

export class GroupCompanyModel {
  public groupCompanyID: string = CommonMethods.EmptyGuid;
  public groupCompanyName: string = "";
  public groupCompanyDescription: string = "";
  public groupCompanyStatus: string = "";
  public defaultPlan: boolean = false;
  public createdTime: Date = new Date();
}

// export class GroupCompany extends React.Component<AppProps, AppState> {
//   groupCompanyService: GroupCompanyService;

//   constructor(props: AppProps) {
//     super(props);
//     this.state = {
//       groupCompanyObj: new GroupCompanyModel(),
//       groupCompanies: [],
//       isLoading: true,
//       redirectOut: false,
//       redirectPath: "",

//       formHeader: "",
//       action: ""
//     };
//     this.groupCompanyService = new GroupCompanyService();

//     this.updateItem = this.updateItem.bind(this);

//     this.updateAccStatus = this.updateAccStatus.bind(this);
//     this.actionbuttons = this.actionbuttons.bind(this);
//   }

//   componentDidMount() {
//     this.getGroupCompanies();
//   }

//   getGroupCompanies() {
//     this.groupCompanyService.getGroupCompanies().then(res => {
//       this.setState({ groupCompanies: res, isLoading: false });
//     });
//   }

//   // addItem() {
//   //   this.setState({
//   //     redirectOut: true,
//   //     redirectPath: "/groupcompany/create"
//   //   });
//   // }

//   updateItem(updateGroupCompanyObj: GroupCompanyModel) {
//     this.setState({
//       redirectOut: true,
//       redirectPath:
//         "/groupcompany/update/" + updateGroupCompanyObj.groupCompanyID
//     });
//   }

//   updateStatus(selectedObj: GroupCompanyModel) {
//     if (selectedObj["groupCompanyStatus"] === "Active") {
//       selectedObj["groupCompanyStatus"] = "Deactivated";
//     } else if (selectedObj["groupCompanyStatus"] === "Deactivated") {
//       selectedObj["groupCompanyStatus"] = "Active";
//     }

//     this.groupCompanyService.updateGroupCompany(selectedObj).then(res => {
//     });
//   }

//   actionbuttons(gc: GroupCompanyModel) {
//     return (
//       <div className="p-grid">
//         <div
//           className="p-col"
//           style={{ display: "flex", justifyContent: "center" }}
//         >
//           <Button
//             type="button"
//             icon="pi pi-pencil"
//             className="p-button-warning"
//             onClick={() => {
//               this.updateItem(gc);
//             }}
//           />
//         </div>
//       </div>
//     );
//   }

//   updateAccStatus(rowData: GroupCompanyModel) {
//     return (
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         <InputSwitch
//           checked={rowData.groupCompanyStatus === "Active"}
//           onChange={e => this.updateStatus(rowData)}
//         />
//       </div>
//     );
//   }

//   render() {
//     // TO SHOW LOADING ICON
//     if (this.state.isLoading) {
//       return null;
//     }

//     // FOR REDIRECT
//     if (this.state.redirectOut) {
//       return (
//         <Redirect
//           push
//           to={{
//             pathname: this.state.redirectPath
//           }}
//         />
//       );
//     }

//     let data;
//     if (this.state.groupCompanies.length > 0) {
//       data = (
//         <DataTable value={this.state.groupCompanies} responsive={true}>
//           <Column className="p-col-4" field="groupCompanyName" header="Name" />
//           <Column
//             className="p-col-5"
//             field="groupCompanyDescription"
//             header="Description"
//           />
//           <Column
//             className="p-col-1 p-lg-1"
//             body={this.updateAccStatus}
//             header="Active"
//           />
//           <Column
//             className="p-col-1 p-lg-1"
//             body={this.actionbuttons}
//             header="Action"
//           />
//         </DataTable>
//       );
//     } else {
//       data = <div>No results found.</div>;
//     }

//     return (
//       <div className="p-grid">
//         <div className="p-col">
//           <div className="card">
//             <h1 className="pb-2">
//               Group Company
//               {/* <span className="pl-3">
//                 <Button
//                   type="button"
//                   icon="pi pi-plus"
//                   iconPos="right"
//                   label="Add"
//                   className="p-button-danger"
//                   onClick={() => {
//                     this.addItem();
//                   }}
//                 />
//               </span> */}
//             </h1>
//             {data}
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

export class GroupCompanyCreate extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  groupCompanyService: GroupCompanyService;
  static contextType = ToastStateContext;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      groupCompanyObj: new GroupCompanyModel(),
      groupCompanies: [],
      isLoading: true,
      redirectOut: false,
      redirectPath: "",

      formHeader: "",
      action: "",

      displayButton: false
    };

    this.submitForm = this.submitForm.bind(this);

    this.groupCompanyService = new GroupCompanyService();
  }

  componentDidMount() {
    // UPDATE

    if (this.props.others !== "" && this.props.others === "onboarding") {
      let gcid = this.props.userProfile.groupCompanyID;
      this.groupCompanyService.getGroupCompanyById(gcid).then(res => {
        this.setState({
          groupCompanyObj: res,
          action: "update",
          formHeader: "Create Group Company",
          displayButton: false
        });
      });
    } else {
      if (this.props.match.params.id !== undefined) {
        var gcIDfromQuery = this.props.match.params.id;
        this.groupCompanyService
          .getGroupCompanyById(gcIDfromQuery)
          .then(res => {
            this.setState({
              groupCompanyObj: res,
              action: "update",
              formHeader: "Update Group Company",
              displayButton: true
            });
          });
      } else {
        let gcid = this.props.userProfile.groupCompanyID;
        this.groupCompanyService.getGroupCompanyById(gcid).then(res => {
          this.setState({
            groupCompanyObj: res,
            action: "update",
            formHeader: "Update Group Company",
            displayButton: true
          });
        });
      }
    }
  }

  updateProperty(property: string, value: any) {
    let tempObj = this.state.groupCompanyObj;
    (tempObj as any)[property] = value;
    this.setState({
      groupCompanyObj: tempObj
    });
  }

  isValidated() {
    let description = this.state.groupCompanyObj.groupCompanyDescription;
    if (description === "" || description === undefined) {
      this.context.SetError("Group company description cannot be empty");
      return false;
    } else {
      this.groupCompanyService
        .updateGroupCompany(this.state.groupCompanyObj)
        .then(() => {
          this.context.SetSuccess("Group Company has been created.");
          return true;
        })
        .catch(() => {
          return false;
        });
    }
  }

  submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // to prevent page from refreshing!
    // if (this.state.action === "create") {
    //   this.groupCompanyService
    //     .createGroupCompany(this.state.groupCompanyObj)
    //     .then(res => {
    //       this.setState({
    //         redirectOut: true,
    //         redirectPath: "/company"
    //       });
    //     });
    // } else if (this.state.action === "update") {
    this.groupCompanyService
      .updateGroupCompany(this.state.groupCompanyObj)
      .then(res => {
        this.setState({
          redirectOut: true,
          redirectPath: "/admin/company"
        });
      });
    // }
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath
          }}
        />
      );
    }

    let displayButton;
    if (this.state.displayButton) {
      displayButton = (
        <Button
          type="submit"
          label={this.state.action === "create" ? "Submit" : "Update"}
        />
      );
    }
    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{this.state.formHeader}</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            <form onSubmit={this.submitForm}>
              <div className="p-grid p-fluid">
                <div className="p-col-12">
                  <span className="boldLabel">
                    Name <span style={{ color: "red" }}>*</span>
                  </span>
                  <InputText
                    display="none"
                    id="groupCompanyName"
                    onChange={e =>
                      this.setState({
                        groupCompanyObj: {
                          ...this.state.groupCompanyObj,
                          groupCompanyName: e.currentTarget.value
                        }
                      })
                    }
                    value={this.state.groupCompanyObj.groupCompanyName}
                    required
                  />
                </div>
                <div className="p-col-12">
                  <span className="boldLabel">
                    Description <span style={{ color: "red" }}>*</span>
                  </span>
                  <InputText
                    // id="groupCompanyDescription"
                    onChange={e =>
                      this.setState({
                        groupCompanyObj: {
                          ...this.state.groupCompanyObj,
                          groupCompanyDescription: e.currentTarget.value
                        }
                      })
                    }
                    value={this.state.groupCompanyObj.groupCompanyDescription}
                    required
                  />
                </div>
              </div>

              <div className="p-grid p-justify-center pt-2">
                {displayButton}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
