import { HttpClient } from "./HttpClient";
import { convertObjToFormData } from "../components/resources/CommonMethodsServices";
export class HolidayService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getAllHolidayValues() {
    const res = await this.httpClient.get("/api/holiday");
    return res;
  }

  async getHolidayValues() {
    const res = await this.httpClient.get("/api/holiday/company");
    return res;
  }

  async getHolidayForUser() {
    const res = await this.httpClient.get("/api/holiday/user");
    return res
  }

  async getOneHolidayValue(holidayid: string) {
    const res = await this.httpClient.get("/api/holiday/" + holidayid);
    return res;
  }

  async createHolidayValues(holidayobj: any) {
    const res = await this.httpClient.post("/api/holiday/create", convertObjToFormData(holidayobj));
    return res;
  }

  async createHolidaysBulk(holidayData: any[]) {
    const res = await this.httpClient.post("/api/holiday/import", holidayData);
    return res;
  }

  async deleteHolidayValues(holidayid: string) {
    const res = await this.httpClient.delete("/api/holiday/" + holidayid);
    return res;
  }
  

  async updateHolidayValues(holidayobj: any) {
    // holidayobj["holidayDate"] = holidayobj.holidayDate.toLocaleString();
    const res = await this.httpClient.put("/api/holiday/update", convertObjToFormData(holidayobj));
    return res;
  }
}
