import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { StatusService } from "../../service/StatusService";
import { CommonMethods } from "../resources/CommonMethods";
import CustomError from "../resources/Error";

export function LeaveBalancePanel() {
  const statusService = new StatusService();
  const commonMethods = new CommonMethods();
  const [leaveBalanceArray, setLeaveBalanceArray] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState<string>("An error has occurred");

  const [showOtherLeaves, setShowOtherLeaves] = useState(false);

  const colorArray: string[] = [
    "4E5ED6",
    "3444BC",
    "6777ef",
    "8191FF",
    "9AAAFF",
    "B4C4FF",
  ];

  useEffect(() => {
    statusService
      .getMyLeaveBalance()
      .then((res) => {
        setLeaveBalanceArray(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
        setErrMsg(commonMethods.getErrorMessage(err));
      });
  }, []);

  const displayBalance = () => {
    if (isError) {
      return <CustomError message={errMsg} />;
    } else {
      let tempArray: any = [];
      let count: number = 0;

      leaveBalanceArray.forEach((element) => {
        tempArray.push(
          <div
            className="col-12 col-lg-6 pt-2"
            key={element.statusTypeName.trim("Leave")}
            hidden={showOtherLeaves === true ? false : !element.toDisplay}
          >
            <div className="p-col-sm-2 m-sm-auto mx-2 text-center">
              <svg
                className="bd-placeholder-img rounded-circle"
                width="60"
                height="60"
                preserveAspectRatio="xMidYMid slice"
                aria-label="Placeholder: 140x140"
                focusable="false"
              >
                <title>
                  Expires on: { commonMethods.displayDate(element.expiryDate)}
                </title>
                <rect
                  width="100%"
                  height="100%"
                  fill={`#${
                    colorArray[
                      count === colorArray.length ? (count = 0) : count++
                    ]
                  }`}
                ></rect>
                <text
                  x="50%"
                  y="50%"
                  fill="#FFFFFF"
                  textAnchor="middle"
                  dominantBaseline="central"
                >
                  {element.availableDays}
                </text>
              </svg>

              <p className="text-center">
                <strong>
                  {element.statusTypeName.replace("Leave", "").trim()}
                </strong>
              </p>
            </div>
          </div>
        );
      });

      return tempArray;
    }
  };

  const display = () => {
    return (
      <>
        <div>
          <h1 className="pb-2">Leave Balance</h1>

          {/* <InputSwitch
            
            tooltip="Show other leaves"
            tooltipOptions={{ position: "top" }}
              checked={showOtherLeaves}
              onChange={e =>
                setShowOtherLeaves(e.value)
              }
            /> */}
        </div>

        <div className="row text-center justify-content-center">
          <div className="p-col-12">
            <div className="row">
              {leaveBalanceArray.length > 0 ? (
                displayBalance()
              ) : (
                <div className="text-center text-danger p-col-12">
                  <div hidden={!isLoading}>
                    <ProgressSpinner />
                  </div>
                  <div hidden={isLoading}>No Records Found.</div>
                </div>
              )}
            </div>
          </div>
          <a
            href="#"
            className=""
            onClick={(event) => {
              event.preventDefault();
              setShowOtherLeaves(!showOtherLeaves);
            }}
            style={{
              display: "inline-block",
              float: "right",
              color: "#6777EF",
              fontSize: "small",
            }}
          >
            {showOtherLeaves === false ? "Show more.." : "Show less.."}
          </a>
        </div>
      </>
    );
  };

  console.log(leaveBalanceArray);
  return (
    <div className="row">
      <div
        className="col-12"
        data-tour-id="leave"
        data-tour="These are your remaining days of leave. Click Show More for more eligible leaves."
      >
        {display()}
      </div>
    </div>
  );
}

export default LeaveBalancePanel;
