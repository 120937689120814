import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { ScoreIndicatorService } from "../../service/ScoreIndicatorService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { LinkPerformanceModel } from "./linkperformance/LinkStatsModel";

export class IRNLinkStatsDashboardModel {
  public noOfEntries: number = 0;
  public kpiDate: Date = new Date();
  public lastUpdatedFromIRNDate: any = "";
  public invalidLinks: Array<LinkPerformanceModel> = new Array<
    LinkPerformanceModel
  >();
}

// class InvalidLinks {
//   public link: string = "";
//   public facebookLink: string = "";
//   public lastUpdatedFromIRNDate: Date | null = null;

// }

function IRNLinkStatsDashboard(props: RouteComponentProps<any>) {
  const commonMethods = new CommonMethods();
  const sis = new ScoreIndicatorService();

  const { SetError, SetSuccess } = React.useContext(ToastStateContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const [searchValue, setSearchValue] = useState<string>("");
  const [tableData, setTableData] = useState<IRNLinkStatsDashboardModel[]>([]);
  const [trackingData, setTrackingData] = useState<[]>([]);

  const [expandedRows, setExpandedRows] = useState<
    IRNLinkStatsDashboardModel[]
  >();
  const [expandAllRows, setExpandAllRows] = useState<boolean>(false);

  const [KPIDate, setKPIDate] = useState<Date>(new Date());

  useEffect(() => {
    getLinkStatsTrackingData();
    sis
      .getIRNDashboardDetails()
      .then((res) => {
        setTableData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMsg(commonMethods.getErrorMessage(err));
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  const getLinkStatsTrackingData = () => {
    sis
      .getLinkStatsTrackingResults()
      .then((res) => {
        setTrackingData(res);
      })
      .catch((err) => {
        setErrorMsg(commonMethods.getErrorMessage(err));
        setIsError(true);
        setIsLoading(false);
      });
  };

  const sendLinkstoIrn = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    sis
      .manualSendLinksToIrn(KPIDate.getMonth() + 1, KPIDate.getFullYear())
      .then((res) => {
        SetSuccess("Links sent!");
        getLinkStatsTrackingData();
      })
      .catch((err) => {
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  const monthTemplate = (rowData: IRNLinkStatsDashboardModel) => {
    const month = commonMethods.displayMonth_Full(rowData.kpiDate);
    return month;
  };

  const dateTemplate = (rowData: any, col: any) => {
    return commonMethods.displayDateTime(rowData[col.field]);
  };

  // const dateKPITemplate = (rowData: any, col: any) => {
  //   if (rowData.kpiDate === null) {
  //     return "-";
  //   }
  //   return commonMethods.displayDate(rowData[col.field]);
  // };

  const no_invalidLinks = (rowData: IRNLinkStatsDashboardModel) => {
    const count = rowData.invalidLinks.length;
    return count;
  };

  const successTemplate = (rowData: any) => {
    if (rowData.success) {
      return "Yes";
    } else {
      return "No";
    }
  };

  let tableheader = (
    <div style={{ textAlign: "left" }}>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e: any) => setSearchValue(e.currentTarget.value)}
        />
      </span>
    </div>
  );

  const rowExpansionTemplate = (data: IRNLinkStatsDashboardModel) => {
    let arrToDisplay: any[] = [];
    arrToDisplay.push(
      <tr key="headerkey">
        <td className="font-weight-bold" style={{ width: "50px" }}>
          No.
        </td>
        <td className="font-weight-bold">Publisher</td>

        <td className="font-weight-bold">Link</td>
        <td className="font-weight-bold">Facebook Link</td>
        <td className="font-weight-bold">Last Updated From IRN Date</td>
        <td className="font-weight-bold">Ver</td>
        <td className="font-weight-bold">Created Time</td>
      </tr>
    );

    data.invalidLinks.forEach((item, index) => {
      var lastupdateddate = item.lastUpdatedFromIRNDate;
      let row = (
        <tr>
          <td>{index}</td>
          <td>{item.publisherName}</td>
          <td>{item.link}</td>
          <td>{item.facebookLink}</td>
          <td>
            {lastupdateddate === null? "-": commonMethods.displayDate(lastupdateddate)}
          </td>
          <td>{item.lastUpdatedFromIRNVer}</td>
          <td>{commonMethods.displayDate(item.createdTime)}</td>
        </tr>
      );
      arrToDisplay.push(row);
    });

    let tablelinks = (
      <div className="text-left">
        <div className="row p-2 pl-4">
          <h2>
            Invalid Links{" "}
            {data.invalidLinks.length > 0 && <>({data.invalidLinks.length}) </>}
          </h2>
          <table>
            <tbody>{arrToDisplay}</tbody>
          </table>
        </div>
      </div>
    );

    return tablelinks;
  };

  const expandAll = () => {
    if (expandAllRows) {
      setExpandAllRows(false);
      setExpandedRows([]);
    } else {
      setExpandAllRows(true);
      setExpandedRows(tableData);
    }
  };

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMsg} />;
    } else {
      return (
        <>
          <p className="boldLabel">
           Table 3 - TSLOS receiving links from IRN cronjob
          </p>
          <p>
            A link is INVALID when:<br></br>
            1. <b>LastUpdatedFromIRNDate is - and Ver is 0 </b><br></br>- This might
            mean that the link is not saved at IRN's db yet. Hence IRN cannot
            crawl to get stats.<br></br>
            or
            <br></br>
            2. <b>LastUpdatedFromIRNDate is not - and Ver is not 0.</b> <br></br>- This means
            the Views, Engagement, Reach, Impressions are all 0 <br></br>- This
            might mean the link itself is invalid and we can just ignore
          </p>
          <div className="datatable-centerHeader datatable-centerContent">
            <DataTable
              value={tableData}
              paginator={true}
              rows={15}
              autoLayout={true}
              header={tableheader}
              globalFilter={searchValue}
              alwaysShowPaginator={false}
              emptyMessage="No results found."
              expandedRows={expandedRows}
              onRowToggle={(e) => {
                setExpandedRows(e.data);
              }}
              rowExpansionTemplate={rowExpansionTemplate}
            >
              <Column
                expander={true}
                style={{ width: "2em" }}
                header={
                  <Button
                    label="All"
                    type="button"
                    iconPos="left"
                    icon="fas fa-angle-double-down"
                    className="p-button-primary"
                    onClick={expandAll}
                  />
                }
              />
              <Column body={monthTemplate} header="KPI Month" />
              <Column field="kpiDate" header="KPI Date" className="d-none" />
              <Column
                field="lastUpdatedFromIRNDate"
                body={dateTemplate}
                header="Last Updated From IRN Date"
              />
              <Column field="noOfEntries" header="No. Of Entries" />
              <Column body={no_invalidLinks} header="No. Of Invalid Links" />
            </DataTable>
          </div>
          {displayTracking()}
        </>
      );
    }
  };

  const displayTracking = () => {
    return (
      <div className="row">
        <div className="col-12 col-lg-6 pt-4">
          <p className="boldLabel">Table 1 - TSLOS sending links to IRN cronjob</p>

          <div className="datatable-centerHeader datatable-centerContent">
            <DataTable
              value={trackingData}
              paginator={true}
              rows={6}
              autoLayout={true}
              alwaysShowPaginator={false}
              emptyMessage="No results found."
            >
              <Column
                field="createdTime"
                body={dateTemplate}
                header="TSLOS sent on"
              />
              <Column field="success" body={successTemplate} header="Success" />
              <Column field="kpiDate" body={monthTemplate} header="KPI Date" />

              <Column field="noOfLinks" header="No. Of Links" />
            </DataTable>
          </div>
        </div>
        <div className="col-12 col-lg-6 pt-4">
          <p className="boldLabel">Table 2 - IRN receiving links from TSLOS</p>
        </div>
      </div>
    );
  };
  const displayUpdate = () => {
    return (
      <div className="p-grid p-fluid pt-4">
        <div className="p-col-12 p-lg-3">
          <div className="card">
            <span className="boldLabel">Send Links to IRN for month</span>
            <Calendar
              value={KPIDate}
              onChange={(e) => {
                setKPIDate(new Date(e.value.toString()));
              }}
              showIcon={true}
              // yearNavigator={true}
              yearRange="2020:2023"
              // maxDate={this.state.maxDate}
              showTime={false}
              readOnlyInput={true}

              view="month"
              dateFormat="mm/yy"
            />
            <Button
              label="Send"
              className="p-button-primary mt-2"
              onClick={sendLinkstoIrn}
            ></Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">Dashboard of stats of KPI Links :') </h1>

          {display()}
          {displayUpdate()}
        </div>
      </div>
    </div>
  );
}

export default IRNLinkStatsDashboard;
