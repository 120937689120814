import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router";
import { TaxFilingService } from "../../service/TaxFilingService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";

export default function IrasSubmissionLoading(props: RouteComponentProps) {
  const commonMethods = new CommonMethods();
  const taxFilingService = new TaxFilingService();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(
    "An unidentified error has occurred"
  );

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");

  const loc = useLocation();
  useEffect(() => {
    const query = commonMethods.getQueryFromURL(loc);
    const stateId = query.get("state") || "";
    if (stateId !== "") {
      taxFilingService
        .submitBody(stateId)
        .then((res) => {
          // redirect to page
          setRedirectPath(res);
          setRedirectOut(true);
        })
        .catch((err) => {
          setErrorMsg(commonMethods.getErrorMessage(err));
          setIsError(true);
          setIsLoading(false);
        });
    } else {
      setIsError(true);
      setIsLoading(false);
      setErrorMsg("Error occured.");
    }
  }, []);

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = <></>;
  }
  return (
    <div className="row">
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      <div className="col-12 col-xl-12">
        <div className="card">
          <h1>IRAS Submission</h1>
          {display}
        </div>
      </div>
    </div>
  );
}
