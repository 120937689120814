import { CommonMethods } from "../components/resources/CommonMethods";
import { convertObjToFormData } from "../components/resources/CommonMethodsServices";
import { HttpClient } from "./HttpClient";

export class ContractService {
  httpClient: HttpClient;
  commonMethods: CommonMethods;
  constructor() {
    this.httpClient = new HttpClient();
    this.commonMethods = new CommonMethods();
  }

  async getContractsByCompany(companyID: string) {
    const res = await this.httpClient.get("/api/contract/company/" + companyID);
    return res;
  }

  // async getUserDetailsForContract(userID:string) {
  //   try {
  //     const res = await this.httpClient.get("/api/contract/userdetails/" + userID);
  //     return res;
  //   } catch (err) {
  //     return err;
  //   }
  // }

  async getUserActiveOrLatestContract(userID: string) {
    const res = await this.httpClient.get("/api/contract/active/" + userID);
    return res;
  }

  async getContractsByUserID(userID: string) {
    const res = await this.httpClient.get("/api/contract/user/" + userID);
    return res;
  }

  async getContractByID(contractID: string) {
    const res = await this.httpClient.get("/api/contract/" + contractID);
    return res;
  }

  async createContract(contractObj: any) {
    const res = await this.httpClient.post(
      "/api/contract/create",
      convertObjToFormData(contractObj)
    );
    return res;
  }

  async createExistingContract(contractObj: any) {
    // var form_data = new FormData();

    // for (var key in contractObj) {
    //   if (
    //     key === "jobStartDate" ||
    //     key === "createdTime" ||
    //     key === "contractCreatedTime"
    //   ) {
    //     let utcDate = moment(contractObj[key]).format(CommonMethods.DateForBackendStringFormat);
    //     form_data.append(key, utcDate);
    //   } else {
    //     form_data.append(key, contractObj[key]);
    //   }
    // }
    const res = await this.httpClient.post(
      "/api/contract/createexisting",
      convertObjToFormData(contractObj)
    );
    return res;
  }

  async updateContract(contractObj: any) {
    // var form_data = new FormData();

    // for (var key in contractObj) {
    //   if (
    //     key === "jobStartDate" ||
    //     key === "createdTime" ||
    //     key === "contractCreatedTime"
    //   ) {
    //     let utcDate = moment(contractObj[key]).format(CommonMethods.DateForBackendStringFormat);
    //     form_data.append(key, utcDate);
    //   } else {
    //     form_data.append(key, contractObj[key]);
    //   }
    // }

    const res = await this.httpClient.put("/api/contract/update",  convertObjToFormData(contractObj));
    return res;
  }

  async updateContractStatus(contractObj: any) {
    const res = await this.httpClient.put("/api/contract/status", contractObj);
    return res;
  }

  async nullifyContract(contractID: string) {
    const res = await this.httpClient.get(
      "/api/contract/nullify/" + contractID
    );
    return res;
  }
  async deleteContract(contractID: string) {
    const res = await this.httpClient.delete("/api/contract/" + contractID);
    return res;
  }
  
  async getContractReportDetails(reportObj: any) {
    const res = await this.httpClient.post("/api/contract/report", reportObj);
    return res;
  }
}
