import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "../../resources/CommonMethods";
import LinkPerformanceDeptView from "../linkperformance/LinkPerformanceDeptView";
import KPIDeptView from "./KPIDeptView";

function OneDeptCombinedView(props: RouteComponentProps<any>) {
  const [deptID, setDeptID] = useState<string | undefined>(undefined);

  const [name, setName] = useState<string>("");
  useEffect(() => {
    if (
      props.others !== undefined &&
      props.others.departmentID !== undefined &&
      props.others.departmentID !== ""
    ) {
      setDeptID(props.others.departmentID);
      setName(props.others.departmentName);
    }
  }, [props.others]);

  const displayOneDeptView = () => {
    return (
      <>
        <div className="row">
          <div className="col-12 col-xl-12">
            <div className="card">
              <h1 className="pb-2">KPI Points {name}</h1>
              {deptID === undefined ? (
                "Department not selected"
              ) : (
                <KPIDeptView {...props} others={deptID} />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-12">
            <div className="card">
              <h1 className="pb-2">Link Performance {name}</h1>
              {deptID === undefined ? (
                "Department not selected"
              ) : (
                <LinkPerformanceDeptView {...props} others={deptID} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  let display = displayOneDeptView();

  return <>{display}</>;
}

export default OneDeptCombinedView;
