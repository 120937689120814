import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { DocumentTypeService } from "../../service/DocumentTypeService";
import { CommonMethods } from "../resources/CommonMethods";
import  CustomError  from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { ProgressSpinner } from "primereact/progressspinner";

interface AppState {
  documentTypeList: [];
  documentTypeObj: DocumentTypeModel;
  redirectOut: boolean;
  redirectPath: string;
  isLoading: boolean;
  isDialogVisible: boolean;
  isError: boolean;
  errorMsg: string;
  dialogStr: string;
  dialogHeaderStr: string;
  visible: boolean;
  dialogIcon: string;
  dialogShowError: boolean;

  // create
  formHeader: string;
  docID: string;
  action: string;
  globalFilter: any;
}

export class DocumentTypeModel {
  public documentTypeID: string = CommonMethods.EmptyGuid;
  public documentTypeName: string = "";
  public documentTypeRemarks: string = "";
}

export class DocumentTypeSetting extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  documentTypeService: DocumentTypeService;
  commonMethods: CommonMethods;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      documentTypeList: [],
      documentTypeObj: new DocumentTypeModel(),
      redirectOut: false,
      redirectPath: "",
      isLoading: true,
      isDialogVisible: false,

      formHeader: "",
      docID: "",
      action: "create",
      isError: false,
      errorMsg: "",
      dialogStr: "",
      dialogHeaderStr: "",
      visible: false,
      dialogIcon: "",
      dialogShowError: false,
      globalFilter: "",
    };

    this.documentTypeService = new DocumentTypeService();
    this.commonMethods = new CommonMethods();
    this.actionButton = this.actionButton.bind(this);
    this.getDocumentTypeList = this.getDocumentTypeList.bind(this);
    // this.confirmDelete = this.confirmDelete.bind(this);
  }

  componentDidMount() {
    this.getDocumentTypeList();
  }

  getDocumentTypeList() {
    this.documentTypeService
      .getDocumentTypes()
      .then(res => {
        this.setState({ documentTypeList: res, isLoading: false });
      })
      .catch(err => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({
          isError: true, // go to error page because cannot get anything to display
          isLoading: false,
          errorMsg: errorMsg
        });
      });
  }

  addItem() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/document_type/create"
    });
  }

  updateItem(rowData: DocumentTypeModel) {
    this.setState({
      redirectOut: true,
      redirectPath:
        "/admin/document_type/update/" + rowData.documentTypeID
    });
  }

  deleteItem(rowData: DocumentTypeModel) {
    this.setState({
      isDialogVisible: true,
      documentTypeObj: rowData
    });
  }

  confirmDelete() {
    this.setState({
      isDialogVisible: false
    });
    this.documentTypeService
      .deleteDocumentType(this.state.documentTypeObj)
      .then(res => {
        let msg = res.documentTypeName + " has been deleted.";
        this.context.SetSuccess(msg);

        this.getDocumentTypeList();
      })
      .catch(error => {
        let errorMsg = this.commonMethods.getErrorMessage(error);

        this.context.SetError(errorMsg);

        this.setState({
          isLoading: false
        });
      });
  }

  actionButton(rowData: DocumentTypeModel) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning mx-1"
          onClick={() => {
            this.updateItem(rowData);
          }}
        />

        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={e => {
            e.preventDefault();
            this.deleteItem(rowData);
          }}
        />
      </div>
    );
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectPath
          }}
          push
        />
      );
    }
      
  let header = (
    <div style={{ textAlign: "left" }}>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onChange={(e) => {
            this.setState({ globalFilter: e.currentTarget.value });
          }}
        />
      </span>
    </div>
  );


    let data = (
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          value={this.state.documentTypeList}
          header={header}
          autoLayout={true}
          globalFilter={this.state.globalFilter}
          emptyMessage="No results found."
          paginator={true}
          rows={15}
          alwaysShowPaginator={false}
        >
          <Column className="p-col" field="documentTypeName" header="Name" />
          <Column
            className="p-col"
            field="documentTypeRemarks"
            header="Remarks"
          />
          <Column
            className="p-col-2"
            body={this.actionButton}
            header="Action"
          />
        </DataTable>
      </div>
    );
    if (this.state.isError) {
      data = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      data = <ProgressSpinner />;
    }

    return (
      <div className="row">
        <div className="col-12 col-xl-10">
          <div className="card">
            <h1 className="pb-2">
              Document Types
              <span className="pl-3">
                <Button
                  type="button"
                  icon="pi pi-plus"
                  iconPos="right"
                  label="Add"
                  className="p-button-danger"
                  onClick={() => {
                    this.addItem();
                  }}
                />
              </span>
            </h1>
            <p>These are to classify documents that users upload.</p>
            {data}
            <Dialog
              className="popupDialog"
              header={"Delete " + this.state.documentTypeObj.documentTypeName}
              visible={this.state.isDialogVisible}
              modal={true}
              //dismissableMask={true}
              onHide={() => this.setState({ isDialogVisible: false })}
              footer={
                <div>
                  <Button
                    label="Yes"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={() => this.confirmDelete()}
                  />
                  <Button
                    label="No"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => this.setState({ isDialogVisible: false })}
                  />
                </div>
              }
            >
              Are you sure you want to delete this item?
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

export class DocumentTypeSettingCreate extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  documentTypeService: DocumentTypeService;
  commonMethods: CommonMethods;

  constructor(props: RouteComponentProps<any>) {
    super(props);

    this.state = {
      documentTypeList: [],
      documentTypeObj: new DocumentTypeModel(),
      redirectOut: false,
      redirectPath: "",
      isLoading: true,
      isDialogVisible: false,
      formHeader: "",
      docID: "",
      action: "",
      isError: false,
      errorMsg: "",
      dialogStr: "",
      dialogHeaderStr: "",
      visible: false,
      dialogIcon: "",
      dialogShowError: false,
      globalFilter: "",
    };

    this.documentTypeService = new DocumentTypeService();
    this.commonMethods = new CommonMethods();
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    if (
      this.props.match.params !== {} &&
      this.props.match.params.id !== undefined
    ) {
      var IDfromQuery = this.props.match.params.id;

      this.setState({
        docID: IDfromQuery,
        action: "update",
        formHeader: "Update User Document Type"
      });
      this.getDocObjFromDB(IDfromQuery);
    } else {
      this.setState({
        formHeader: "Create User Document Type",
        action: "create"
      });
    }
  }

  getDocObjFromDB(IDfromQuery: string) {
    this.documentTypeService
      .getDocumentTypeById(IDfromQuery)
      .then(res => {
        this.setState({ documentTypeObj: res });
      })
      .catch(error => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg
        });
        return errorMsg;
      });
  }

  goBack() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/document_type"
    });
  }

  submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // to prevent page from refreshing!

    if (this.state.action === "create") {
      this.documentTypeService
        .createDocumentType(this.state.documentTypeObj)
        .then(res => {
          let msg = res.documentTypeName + " has been created.";

          this.context.SetSuccess(msg);
          this.goBack();
        })
        .catch(error => {
          // error dialog
          let errorMsg = this.commonMethods.getErrorMessage(error);

          this.context.SetError(errorMsg);

          this.setState({
            isLoading: false
          });
        });
    } else if (this.state.action === "update") {
      this.documentTypeService
        .updateDocumentType(this.state.documentTypeObj)
        .then(res => {
          let msg = res.documentTypeName + " has been updated.";

          this.context.SetSuccess(msg);
          this.goBack();
        })
        .catch(error => {
          // error dialog
          let errorMsg = this.commonMethods.getErrorMessage(error);

          this.context.SetError(errorMsg);

          this.setState({
            isLoading: false
          });
        });
    }
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath
          }}
        />
      );
    }

    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{this.state.formHeader}</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            <form onSubmit={this.submitForm}>
              <div className="p-grid p-fluid">
                <div className="p-col-12">
                  <span className="boldLabel">Name of Document Type <span style={{color: "red"}}>*</span></span>
                  <InputText
                    id="documentTypeName"
                    onChange={e =>
                      this.setState({
                        documentTypeObj: {
                          ...this.state.documentTypeObj,
                          documentTypeName: e.currentTarget.value
                        }
                      })
                    }
                    required
                    value={this.state.documentTypeObj.documentTypeName}
                  />
                </div>
                <div className="p-col-12">
                  <span className="boldLabel">Remarks <span style={{color: "red"}}>*</span></span>
                  <InputText
                    id="documentTypeRemarks"
                    onChange={e =>
                      this.setState({
                        documentTypeObj: {
                          ...this.state.documentTypeObj,
                          documentTypeRemarks: e.currentTarget.value
                        }
                      })
                    }
                    value={this.state.documentTypeObj.documentTypeRemarks}
                    required
                  />
                </div>
              </div>

              <div className="p-grid p-justify-center pt-2">
                <Button
                  type="submit"
                  label={this.state.action === "create" ? "Submit" : "Update"}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
