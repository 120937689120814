import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { TaxFilingService } from "../../service/TaxFilingService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { TaxTrailerModel } from "./TaxFilingModel";

export default function TaxTrailerDetails(props: RouteComponentProps) {
  const taxFilingService = new TaxFilingService();

  const headerID: string = props.others;
  const [trailer, setTrailer] = useState<TaxTrailerModel>(
    new TaxTrailerModel()
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(
    "An unidentified error has occurred"
  );

  const commonMethods = new CommonMethods();

  useEffect(() => {
    if (headerID !== null) {
      taxFilingService
        .getTrailer(headerID)
        .then((res) => {
          setTrailer(res);
          setIsLoading(false);
        })
        .catch((err) => {
          setErrorMsg(commonMethods.getErrorMessage(err));
          setIsError(true);
          setIsLoading(false);
        });
    }
  }, []);




  let page = (
    <div className="card p-0 col-12">
      <div className="card-header font-weight-bold text-left">Summary</div>
      <div className="card-body pt-0 pb-0">
        <div className="row">
          <div className="col-12 border-bottom px-3 py-2">
            <div className="row">
              <div className="col text-left">No. of Records</div>
              <div className="col text-right">
                <NumberFormat displayType="text" value={trailer.noofRecords} />
              </div>
            </div>
          </div>

          <div className="col-12 border-bottom px-3 py-2">
            <div className="row">
              <div className="col text-left">Total Amount of Salary</div>
              <div className="col text-right">
                <NumberFormat
                  displayType="text"
                  value={trailer.totalAmountOfSalary}
                  thousandSeparator
                  prefix={"$"}
                />
              </div>
            </div>
          </div>

          <div className="col-12 border-bottom px-3 py-2">
            <div className="row">
              <div className="col text-left">Total Amount of Bonus</div>
              <div className="col text-right">
                <NumberFormat
                  displayType="text"
                  value={trailer.totalAmountOfBonus}
                  thousandSeparator
                  prefix={"$"}
                />
              </div>
            </div>
          </div>

          <div className="col-12 border-bottom px-3 py-2">
            <div className="row">
              <div className="col text-left">
                Total Amount of Director's Fee
              </div>
              <div className="col text-right">
                <NumberFormat
                  displayType="text"
                  value={trailer.totalAmountOfDirectorsFee}
                  thousandSeparator
                  prefix={"$"}
                />
              </div>
            </div>
          </div>

          <div className="col-12 border-bottom px-3 py-2">
            <div className="row">
              <div className="col text-left">Total Amount of Others</div>
              <div className="col text-right">
                <NumberFormat
                  displayType="text"
                  value={trailer.totalAmountOfOthers}
                  thousandSeparator
                  prefix={"$"}
                />
              </div>
            </div>
          </div>

          <div className="col-12 border-bottom px-3 py-2 card-header">
            <div className="row">
              <div className="col text-left boldLabel">
                Total Amount of Payment
              </div>
              <div className="col text-right boldLabel">
                <NumberFormat
                  displayType="text"
                  value={trailer.totalAmountOfPayment}
                  thousandSeparator
                  prefix={"$"}
                />
              </div>
            </div>
          </div>

          <div className="col-12 border-bottom px-3 py-2">
            <div className="row">
              <div className="col text-left">Total Amount of Exempt Income</div>
              <div className="col text-right">
                <NumberFormat
                  displayType="text"
                  value={trailer.totalAmountOfExemptIncome}
                  thousandSeparator
                  prefix={"$"}
                />
              </div>
            </div>
          </div>

          <div className="col-12 border-bottom px-3 py-2">
            <div className="row">
              <div className="col text-left">
                Total Amount of Income Tax borne by Employer
              </div>
              <div className="col text-right">
                <NumberFormat
                  displayType="text"
                  value={
                    trailer.totalAmountOfEmploymentIncomeTaxBorneByEmployer
                  }
                  thousandSeparator
                  prefix={"$"}
                />
              </div>
            </div>
          </div>

          <div className="col-12 border-bottom px-3 py-2">
            <div className="row">
              <div className="col text-left">
                Total Amount of Income Tax borne by Employee
              </div>
              <div className="col text-right">
                <NumberFormat
                  displayType="text"
                  value={trailer.totalAmountOfIncomeTaxBorneByEmployee}
                  thousandSeparator
                  prefix={"$"}
                />
              </div>
            </div>
          </div>

          <div className="col-12 border-bottom px-3 py-2">
            <div className="row">
              <div className="col text-left">Total Amount of Donations</div>
              <div className="col text-right">
                <NumberFormat
                  displayType="text"
                  value={trailer.totalAmountOfDonation}
                  thousandSeparator
                  prefix={"$"}
                />
              </div>
            </div>
          </div>

          <div className="col-12 border-bottom px-3 py-2">
            <div className="row">
              <div className="col text-left">Total Amount of CPF</div>
              <div className="col text-right">
                <NumberFormat
                  displayType="text"
                  value={trailer.totalAmountOfCPF}
                  thousandSeparator
                  prefix={"$"}
                />
              </div>
            </div>
          </div>

          <div className="col-12 border-bottom px-3 py-2">
            <div className="row">
              <div className="col text-left">Total Amount of Insurance</div>
              <div className="col text-right">
                <NumberFormat
                  displayType="text"
                  value={trailer.totalAmountOfInsurance}
                  thousandSeparator
                  prefix={"$"}
                />
              </div>
            </div>
          </div>

          <div className="col-12 border-bottom px-3 py-2">
            <div className="row">
              <div className="col text-left">
                Total Amount of Mosque Building Fund
              </div>
              <div className="col text-right">
                <NumberFormat
                  displayType="text"
                  value={trailer.totalAmountOfMBF}
                  thousandSeparator
                  prefix={"$"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <div className="row">
      <div className="col-12 col-xl-12 pt-5">{display}</div>
    </div>
  );
}
