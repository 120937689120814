import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { PickList } from "primereact/picklist";
import * as React from "react";
import { Redirect } from "react-router";
import { RoleService } from "../../service/RoleService";
import { TaskService } from "../../service/TaskService";
import {
  CommonMethods,
  RouteComponentProps,
  DropdownModel,
} from "../resources/CommonMethods";
import { ToastStateContext } from "../resources/ToastContext";
import { RoleModel } from "./Role";

export class RoleTaskModel {
  public roleID: string = "";
  public taskArr: Array<string> = new Array<string>();
  public roleTaskRemarks: string = "";
}

export class TaskModuleModel {
  public taskModuleID: string = "";
  public menuModule: string = "";
  public taskAccess: string = "";
  public taskConfigName: string = "";
  public defaultTask: boolean = true;
  public createdTime: Date = new Date();
}

interface AppState {
  fullTaskList: Array<TaskModuleModel>;
  unassignedTaskList: Array<TaskModuleModel>;
  roleTaskList: Array<TaskModuleModel>;
  thisRoleTask: RoleTaskModel;

  fullRoleList: Array<DropdownModel>;
  copyThisRoleID: string;
  thisRoleName: string;

  isLoading: boolean;

  redirectOut: boolean;
  redirectPath: string;

  currentRole: RoleModel;
}

export class RoleTaskSetting extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;
  taskService: TaskService;
  roleService: RoleService;
  commonMethods: CommonMethods;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      fullTaskList: [], // Full List of Tasks = unassignedTaskList + roleTaskList
      unassignedTaskList: [], // Tasks that are not assigned to this role
      roleTaskList: [], // Tasks assigned to this role
      thisRoleTask: new RoleTaskModel(), // To send back to controller

      fullRoleList: [],
      copyThisRoleID: "",
      thisRoleName: "",

      isLoading: true,

      redirectOut: false,
      redirectPath: "",

      currentRole: new RoleModel(),
    };
    this.taskService = new TaskService();
    this.roleService = new RoleService();
    this.commonMethods = new CommonMethods();

    this.getFullTaskList = this.getFullTaskList.bind(this);
    this.getUserTaskList = this.getUserTaskList.bind(this);
    this.getRolesFromDB = this.getRolesFromDB.bind(this);
    this.getRoleName = this.getRoleName.bind(this);
    this.filterTaskList = this.filterTaskList.bind(this);
    this.setupRoleTask = this.setupRoleTask.bind(this);

    this.updateRolePermissions = this.updateRolePermissions.bind(this);
    this.copyRoleSettings = this.copyRoleSettings.bind(this);
  }
  async componentDidMount() {
    await this.getRoleDetails();
    await this.getFullTaskList();
    await this.getUserTaskList();
    await this.getRolesFromDB();

    this.filterTaskList();
    this.setupRoleTask();
    this.getRoleName();
  }
  componentDidUpdate() {
    // if (
    //   this.state.fullTaskList.length > 0 &&
    //   // this.state.roleTaskList.length > 0 &&
    //   this.state.isLoading
    // ) {
    //   this.filterTaskList();
    //   this.setupRoleTask();
    //   this.getRoleName();
    // }
  }
  async getRoleDetails() {
    await this.roleService
      .getRoleByID(this.props.match.params.id)
      .then((res) => {
        this.setState({ currentRole: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.context.SetError(errorMsg);
      });
  }
  async getFullTaskList() {
    await this.taskService
      .getAllPermittedTaskAccess(this.props.userProfile.groupCompanyID)
      .then((res) => {
        this.setState({ fullTaskList: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.context.SetError(errorMsg);
      });
  }
  async getUserTaskList() {
    await this.roleService
      .getRoleTaskList(this.props.match.params.id)
      .then((res) => {
        this.setState({ roleTaskList: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.context.SetError(errorMsg);
      });
    // .finally(() => {
    //   this.filterTaskList();
    //   this.setupRoleTask();
    // });
  }
  async getRolesFromDB() {
    await this.commonMethods
      .getRoleDropdownListByGcid(this.props.userProfile.groupCompanyID)
      .then((res) => {
        this.setState({ fullRoleList: res });
      })
      .catch((err) => {
        this.context.SetError("Error");
      });
    // .finally(() => {
    //   this.getRoleName();
    // });
  }

  getRoleName() {
    var index = this.state.fullRoleList.findIndex(
      (e: any) => e.value === this.props.match.params.id
    );
    if (index >= 0) {
      this.setState({ thisRoleName: this.state.fullRoleList[index].label });
    }
  }

  // filter out already assigned tasks for the left box
  filterTaskList() {
    const { fullTaskList, roleTaskList: userTaskList } = this.state;
    var filtered: Array<TaskModuleModel> = fullTaskList;
    userTaskList.forEach((assigned: TaskModuleModel) => {
      filtered = filtered.filter(
        (task) => task.taskAccess !== assigned.taskAccess
      );
    });
    this.setState({ unassignedTaskList: filtered, isLoading: false });
  }

  setupRoleTask() {
    let thisRole: RoleTaskModel = new RoleTaskModel();
    thisRole.roleID = this.props.match.params.id;
    var userTaskList: Array<string> = [];
    this.state.roleTaskList.forEach((userTask: TaskModuleModel) => {
      userTaskList.push(userTask.taskAccess);
    });
    thisRole.taskArr = userTaskList;
    this.setState({ thisRoleTask: thisRole });
  }

  updateRolePermissions() {
    var object: RoleTaskModel = this.state.thisRoleTask;
    var userTaskList: Array<string> = [];
    this.state.roleTaskList.forEach((userTask: TaskModuleModel) => {
      userTaskList.push(userTask.taskAccess);
    });
    object.taskArr = userTaskList;

    this.roleService
      .updateTasksForRole(object)
      .then((res) => {
        this.context.SetSuccess("Updated Role Permissions");
      })
      .catch((err) => {
        this.context.SetError("Error updating role permissions");
      })
      .finally(() => {
        this.getFullTaskList();
        this.getUserTaskList();
      });
  }

  copyRoleSettings() {
    this.roleService
      .getRoleTaskList(this.state.copyThisRoleID)
      .then((res) => {
        this.setState({ roleTaskList: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.context.SetError(errorMsg);
      })
      .finally(() => {
        this.filterTaskList();
        this.setupRoleTask();
      });
  }

  taskAccessTemplate(taskModule: TaskModuleModel) {
    return <span>{taskModule.taskConfigName}</span>;
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectPath,
          }}
          push
        />
      );
    }
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="p-grid p-justify-center p-align-center">
              <div className="p-col-12">
                <h1 className="pb-2">Role Permission Mapping</h1>
                <div className="row">
                  <div className="p-col-7 p-grid" style={{ margin: "0 auto" }}>
                    <div className="p-col-4">
                      <h5>Role Selected: </h5>{" "}
                      <span>{this.state.thisRoleName}</span>
                      {this.state.currentRole.isAdmin && <p className="requiredRed">Administrator Role cannot be edited.</p>}
                    </div>
                    <div className="p-col-8 text-center p-grid p-fluid">
                      <div className="p-col-12 mb-0 pb-0">
                        <p className="mb-0 pb-0 boldLabel">
                          You may select a Role to copy assigned tasks from
                        </p>
                      </div>
                      <div className="p-col-6 mb-0 pb-0">
                        <Dropdown
                          disabled={this.state.currentRole.isAdmin}
                          value={this.state.copyThisRoleID}
                          options={this.state.fullRoleList}
                          onChange={(e) => {
                            this.setState({ copyThisRoleID: e.value });
                          }}
                          placeholder="Role"
                        />
                      </div>
                      <div className="p-col-3 px-0">
                        <Button
                          disabled={this.state.currentRole.isAdmin}
                          label={"Copy"}
                          onClick={() => {
                            this.copyRoleSettings();
                          }}
                        />
                      </div>
                      <div className="p-col-3 px-0">
                        <Button
                          disabled={this.state.currentRole.isAdmin}
                          label={"Clear"}
                          className="p-button-secondary"
                          onClick={() => {
                            this.getUserTaskList();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-col-12">
                    <PickList
                      style={{ margin: "0 auto" }}
                      source={this.state.unassignedTaskList}
                      sourceHeader="Available Tasks"
                      target={this.state.roleTaskList}
                      targetHeader="Assigned Tasks"
                      itemTemplate={this.taskAccessTemplate}
                      sourceStyle={{ height: "500px" }}
                      targetStyle={{ height: "500px" }}
                      showSourceControls={false}
                      showTargetControls={false}
                      metaKeySelection={true}
                      onChange={(e) =>
                        this.setState({
                          unassignedTaskList: e.source,
                          roleTaskList: e.target,
                          thisRoleTask: {
                            ...this.state.thisRoleTask,
                            taskArr: e.target,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              {/* <div className="p-col-12">
                <div className="p-col-6 m-auto">
                  <span className="boldLabel">Remarks</span>
                  <InputTextarea
                    disabled={this.state.currentRole.isAdmin}
                    className="p-col-12"
                    rows={3}
                    value={this.state.thisRoleTask.roleTaskRemarks}
                    onChange={(e) =>
                      this.setState({
                        thisRoleTask: {
                          ...this.state.thisRoleTask,
                          roleTaskRemarks: e.currentTarget.value,
                        },
                      })
                    }
                  />
                </div>
              </div> */}
              <div className="p-col-12 pt-5">
                <div className="p-grid p-justify-center">
                  <Button
                    label="Update"
                    onClick={this.updateRolePermissions}
                    disabled={this.state.currentRole.isAdmin}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
