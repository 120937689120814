import { Button } from 'primereact/button'
import React, { useContext, useState } from 'react'
import AddTelegramDialog from './AddTelegramDialog';
import EditTelegramDialog from './EditTelegramDialog';
import { PublicProfileContext } from './PublicProfile';

export default function Telegram() {
    const { state } = useContext(PublicProfileContext);
    let profile = state.userProfile
    const [showAddDialog, setShowAddDialog] = useState(false)
    const [showEditDialog, setShowEditDialog] = useState(false)
    
    function addTelegramUsername() {
        setShowAddDialog(true)
    }

    function editTelegramUsername() {
        setShowEditDialog(true)
    }

    return (
        <div className="position-relative">
            {profile.telegramUsername ?
                <>
                    <a className="telegram-link" href={"https://t.me/" + profile.telegramUsername} target="_blank" rel="noopener noreferrer">
                        <Button label="Telegram" icon="pi pi-send" className="p-button-raised p-button-rounded" />
                    </a>
                    {profile.isEditable ?
                        <div onClick={editTelegramUsername}>
                            <span className="material-icons-outlined edit-telegram-button">edit</span>
                        </div> : null}
                </>
                :
                <>{profile.isEditable ? <div className="add-telegram">
                    <Button onClick={addTelegramUsername} label="Add Telegram Username" className="p-button-outlined p-button-secondary p-button-rounded" />
                </div> : null}</>
            }
            <AddTelegramDialog showAddDialog={showAddDialog} setShowAddDialog={setShowAddDialog}/>
            <EditTelegramDialog showEditDialog={showEditDialog} setShowEditDialog={setShowEditDialog}/>
        </div>
    )
}
