import React, { useEffect, useRef, useState } from 'react'
import { Galleria } from 'primereact/galleria';
import placeholder from '../../Assets/missing-image.jpg'

export default function PostImagesContainer({ imagePathArray }: { imagePathArray: string[] | null, style?: React.CSSProperties }) {
    const [activeIndex, setActiveIndex] = useState(0)
    const galleriaRef = useRef<any | null>(null)
    const [isShowItemNavs, setIsShowItemNavs] = useState(true)

    useEffect(() => {
        if (imagePathArray) {
            switch (imagePathArray.length) {
                case 1:
                    setIsShowItemNavs(false)
                    break;
                default:
                    setIsShowItemNavs(true)
                    break;
            }
        }
    }, [imagePathArray])

    function handleError(e: React.SyntheticEvent<HTMLImageElement, Event>) {
        e.currentTarget.src = placeholder
    }

    function itemTemplate(item: string) {
        return <img src={item} alt="" className="post-image-gallery" onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}/>;
    }

    function handleImgClick(index: number) {
        setActiveIndex(index)
        if (galleriaRef.current) {
            galleriaRef.current.show()
        }
        console.log('galleriaRef', galleriaRef.current)
    }

    function PostImages() {
        if (imagePathArray == null) {
            return null
        } else {
            // console.log('imagePathArray length', imagePathArray.length)
            if (imagePathArray.length === 1) {
                return (
                    <div className="">
                        <img
                            className="posts-img-1 img-1"
                            src={imagePathArray[0]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(0)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                    </div>
                )
            } else if (imagePathArray.length === 2) {
                return (
                    <div className="posts-dropzone-image-grid">
                        <img
                            className="img-2 img-2-1"
                            src={imagePathArray[0]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(0)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                        <img
                            className="img-2 img-2-2"
                            src={imagePathArray[1]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(1)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                    </div>
                )
            } else if (imagePathArray.length === 3) {
                return (
                    <div className="posts-dropzone-image-grid">
                        <img
                            className="img-3 img-3-1"
                            src={imagePathArray[0]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(0)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                        <img
                            className="img-3 img-3-2"
                            src={imagePathArray[1]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(1)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                        <img
                            className="img-3 img-3-3"
                            src={imagePathArray[2]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(2)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                    </div>
                )
            } else if (imagePathArray.length === 4) {
                return (
                    <div className="posts-dropzone-image-grid">
                        <img
                            className="img-4 img-4-1"
                            src={imagePathArray[0]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(0)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                        <img
                            className="img-4 img-4-2"
                            src={imagePathArray[1]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(1)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                        <img
                            className="img-4 img-4-3"
                            src={imagePathArray[2]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(2)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                        <img
                            className="img-4 img-4-4"
                            src={imagePathArray[3]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(3)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                    </div>
                )
            } else if (imagePathArray.length > 4) {
                return (
                    <div className="posts-dropzone-image-grid">
                        <img
                            className="img-4 img-4-1"
                            src={imagePathArray[0]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(0)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                        <img
                            className="img-4 img-4-2"
                            src={imagePathArray[1]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(1)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                        <img
                            className="img-4 img-4-3"
                            src={imagePathArray[2]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(2)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                        <img
                            className="img-4 img-4-4"
                            src={imagePathArray[3]}
                            alt="uploaded file"
                            onClick={() => handleImgClick(3)}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
                        />
                        <div className="images-dropped-overlay">
                            + {imagePathArray.length - 4}
                        </div>
                    </div>
                )
            } else {
                return null
            }
        }
    }

    return (
        <div>
            <Galleria
                ref={galleriaRef}
                // @ts-ignore
                value={imagePathArray}
                activeIndex={activeIndex}
                onItemChange={(e) => setActiveIndex(e.index)}
                circular
                fullScreen
                showItemNavigators={isShowItemNavs}
                showThumbnails={false}
                item={itemTemplate}
                className="post-galleria"
            />
            <PostImages />
        </div>
    )
}



// event listeners, couldn't use cause they were being called multiple times same as number of Galleria instances
 // useEffect(() => {
    //     const listener = (event: { code: string; preventDefault: () => void; }) => {
    //         if (event.code === "Escape") {
    //             console.log('Escape')
    //             event.preventDefault();
    //             if (galleriaRef.current) galleriaRef.current.hide()
    //         }
    //     };
    //     document.addEventListener("keydown", listener);
    //     return () => {
    //         document.removeEventListener("keydown", listener);
    //     };
    // }, []);

    // useEffect(() => {
    //     const listener = (event: { code: string; preventDefault: () => void; }) => {
    //         if (event.code === "ArrowLeft") {
    //             event.preventDefault();
    //             console.log('ArrowLeft')
    //             if (imagePathArray) {
    //                 setActiveIndex(activeIndex !== 0 ? imagePathArray.length - 1 : 0)
    //             }
    //         }
    //         if (event.code === "ArrowRight") {
    //             event.preventDefault();
    //             console.log('ArrowRight')
    //             if (imagePathArray) {
    //                 setActiveIndex(activeIndex !== imagePathArray.length ? imagePathArray.length + 1 : imagePathArray.length)
    //             }
    //         }
    //     };
    //     document.addEventListener("keydown", listener);
    //     return () => {
    //         document.removeEventListener("keydown", listener);
    //     };
    // }, []);