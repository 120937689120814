import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { ClientCompanyService } from "../../service/ClientCompanyService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ClientCompanyModel } from "./AllClientCompanies";

let commonMethods = new CommonMethods();
let clientCompanyService = new ClientCompanyService();
function ClientCompanySummary(props: RouteComponentProps<any>) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );

  const [clientCompanyObj, setClientCompanyObj] = useState<ClientCompanyModel>(
    new ClientCompanyModel()
  );

  useEffect(() => {
    getClientCompanyDetails();
  }, []);

  const getClientCompanyDetails = () => {
    if (props.match.params !== {} && props.match.params.id !== undefined) {
      let clientCompanyId = props.match.params.id;

      clientCompanyService
        .getClientCompanyByID(clientCompanyId)
        .then(res => {
          setClientCompanyObj(res);
          setIsLoading(false);
        })
        .catch(err => {
          let errorMsg = commonMethods.getErrorMessage(err);
          setIsError(true);
          setIsLoading(false);
          setErrorMessage(errorMsg);
        });
    }
  };

  let page = (
    <>
      <div className="p-col-12">
        <div className="p-grid">
          <div className="p-col-12 p-lg-6">
            <div className="p-grid">
              <div className="p-col-4">
                <strong> Name: </strong>
              </div>
              <div className="p-col-8">{clientCompanyObj.clientCompany}</div>
              <div className="p-col-4">
                <strong> UEN: </strong>
              </div>
              <div className="p-col-8">{clientCompanyObj.clientCompanyUEN}</div>

              <div className="p-col-4">
                <strong>Status: </strong>
              </div>
              <div className="p-col-8">{clientCompanyObj.clientStatus}</div>
              <div className="p-col-4">
                <strong>Vertical: </strong>
              </div>
              <div className="p-col-8">{clientCompanyObj.industry}</div>
            </div>
          </div>
          <div className="p-col-12 p-lg-6">
            <div className="p-grid">
              <div className="p-col-4">
                <strong>Email: </strong>
              </div>
              <div className="p-col-8">{clientCompanyObj.clientEmail}</div>
              <div className="p-col-4">
                <strong>Address: </strong>
              </div>
              <div className="p-col-8">{clientCompanyObj.clientAddress}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <div className="row">
      <div
        className="col-12 col-xl-8"
        data-tour-id="client-company-details"
        data-tour="Information of the Client Company"
      >
        <div className="card">
          <h1 className="pb-2">
            Client Company - {clientCompanyObj.clientCompany}
          </h1>
          {display}
        </div>
      </div>
    </div>
  );
}

export default ClientCompanySummary;
