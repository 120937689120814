import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import CampaignService, {
  CampaignDocumentService
} from "../../service/CampaignService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { CampaignModel } from "./Campaign";

export class CampaignDocumentModel {
  campaignDocID: string = CommonMethods.EmptyGuid;
  campaignID: string = CommonMethods.EmptyGuid;
  documentTypeID: string = CommonMethods.EmptyGuid;
  documentType: string = "";
  uploadDate: string = "";
  uploadPath: string = "";
  uploadFile: File | any = null;
  remarks: string = "";
  campaignDocumentStatus: string = "";
}

let commonMethods = new CommonMethods();
let campaignDocumentService = new CampaignDocumentService();
let campaignService = new CampaignService();

function CampaignDocumentTable(props: RouteComponentProps) {
  let params: { id?: string } = useParams();

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");

  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );

  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [dialogStr, setDialogStr] = useState<string>("");
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [tableData, setTableData] = useState([]);
  const [tableDataChanged, setTableDataChanged] = useState(true);
  const [campaignDocumentData, setCampaignDocumentData] = useState(
    new CampaignDocumentModel()
  );
  const [campaignData, setCampaignData] = useState(new CampaignModel());

  useEffect(() => {
    setIsLoading(true);
    if (params.id !== undefined) {
      campaignService
        .getCampaignByCampaignID(params.id)
        .then(res => {
          setCampaignData(res);
          let campaignObj: CampaignModel;
          campaignObj = res;
          campaignDocumentService
            .getDocumentByCampaignID(campaignObj.campaignID!)
            .then(res => setTableData(res))
            .catch(err => {
              let errMsg = commonMethods.getErrorMessage(err)
              setErrorMessage(errMsg)
              setIsError(true)
            });
        })
        .catch(err => {
          let errorMsg = commonMethods.getErrorMessage(err);
          setErrorMessage(errorMsg);
          setIsError(true);
          setIsLoading(false);
        });
    }
    setIsLoading(false);
  }, [tableDataChanged]);

  const setDialog = (header: string, content: any) => {
    setConfirmStr(header);
    setDialogStr(content);
  };

  const uploadedDateTemplate = (rowData: CampaignDocumentModel) => {
    return commonMethods.displayDate(new Date(rowData.uploadDate));
  };

  const openDocument = (rowData: CampaignDocumentModel) => {
    window.open(rowData.uploadPath, "_blank");
  };

  const updateItem = (rowData: CampaignDocumentModel) => {
    setRedirectPath(
      "/campaign/document/" + params.id + "/update/" + rowData.campaignDocID
    );
    setRedirectOut(true);
  };

  const deleteItem = (rowData: CampaignDocumentModel) => {
    setCampaignDocumentData(rowData);
    setDialogVisibility(true);
    setDialog(
      "Confirm Delete",
      "Are you sure you want to delete this document"
    );
  };

  const actionButtons = (rowData: CampaignDocumentModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          className="p-button-primary mx-1 button-document"
          tooltip="View Document"
          tooltipOptions={{ position: "top" }}
          onClick={() => {
            openDocument(rowData);
          }}
        />

        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          tooltip="Edit Document Details"
          tooltipOptions={{ position: "top" }}
          onClick={() => {
            updateItem(rowData);
          }}
        />

        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          tooltip="Delete Document"
          tooltipOptions={{ position: "top" }}
          onClick={() => {
            deleteItem(rowData);
          }}
        />
      </div>
    );
  };

  const table = () => {
    return (
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          value={tableData}
          paginator={true}
          rows={15}
          autoLayout={true}
          alwaysShowPaginator={false}
          emptyMessage="No results found."
        >
          <Column field="documentType" header="Document Type" />
          <Column field="remarks" header="Description" />
          <Column body={uploadedDateTemplate} header="Upload Date" />
          <Column body={actionButtons} header="Actions" />
        </DataTable>
      </div>
    );
  };

  const page = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMsg} />;
    } else {
      return (
        <div className="p-col-12">
          <div className="p-grid p-justify-center">
            <div className="p-col-12">{table()}</div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="row">
      {redirectOut ? (
        <Redirect
          push
          to={{
            pathname: redirectPath
          }}
        />
      ) : null}

      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        onHide={() => {
          setDialogVisibility(false);
        }}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                switch (confirmStr) {
                  case "Confirm Delete":
                    campaignDocumentService
                      .deleteCampaignDocument(
                        campaignDocumentData.campaignDocID
                      )
                      .then(res => {
                        SetSuccess("Document Deleted.");
                        setTableDataChanged(!tableDataChanged);
                      })
                      .catch(err => {
                        SetError("An error has occurred.");
                      });
                    break;

                  default:
                    SetError("An error has occurred.");

                    break;
                }
                setDialogVisibility(false);
              }}
            />
            <Button
              label="No"
              type="button"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>
      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">
            Documents for Campaign: {campaignData.campaignName}
            <span className="pl-3">
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={() => {
                  setRedirectPath(
                    "/campaign/document/" + params.id + "/create"
                  );
                  setRedirectOut(true);
                }}
              />
            </span>
          </h1>
          {page()}
        </div>
      </div>
    </div>
  );
}

export default CampaignDocumentTable;
