import _ from "lodash";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { AttendanceService } from "../../service/AttendanceService";
import { CommonMethods, DropdownModel, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { GuestTrackingModel } from "./SafeEntry";

export function SafeEntryLogs(props: RouteComponentProps<any>) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [companySelected, setCompanySelected] = useState<string>(
    props.userProfile.groupCompanyID
  );
  const [companySelectedName, setCompanySelectedName] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");

  const [companyDropdown, setCompanyDropdown] = useState<DropdownModel[]>(
    new Array<DropdownModel>()
  );

  const [logs, setLogs] = useState<GuestTrackingModel[]>(
    new Array<GuestTrackingModel>()
  );
  let attService = new AttendanceService();
  let commonMethods = new CommonMethods();

  const [startDateFilter, setStartDateFilter] = useState<
    Date | undefined
  >(undefined);
  const [endDateFilter, setEndDateFilter] = useState<Date| undefined>(
    undefined
  );

  let dtExport: DataTable | null = null;

  useEffect(() => {
    let attService = new AttendanceService();
    let commonMethods = new CommonMethods();

    attService
      .getCompanyDropdown()
      .then((res) => {
        setCompanyDropdown(res);
        setIsLoading(false);
        // setCompanySelected(props.userProfile.groupCompanyID);

        setCompanySelectedName(
          _.find(res, ["value", companySelected])?.label || ""
        );
      })
      .catch((err) => {
        let errorMsg = commonMethods.getErrorMessage(err);
        setErrorMessage(errorMsg);
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);

    let attService = new AttendanceService();
    let commonMethods = new CommonMethods();
    setCompanySelectedName(
      _.find(companyDropdown, ["value", companySelected])?.value || ""
    );
    attService
      .getLogs(companySelected)
      .then((res) => {
        setLogs(res);
        setIsLoading(false);
      })
      .catch((err) => {
        let errorMsg = commonMethods.getErrorMessage(err);
        setErrorMessage(errorMsg);
        setIsError(true);
        setIsLoading(false);
      });
  }, [companySelected]);

  const formatDate = (rowData: any, column: any) => {
    if (rowData[column.field] === null) {
      return "-";
    }
    return commonMethods.displayDateTime(rowData[column.field])
  };

  const formatIdentityType = (rowData: GuestTrackingModel) => {
    if (rowData.identityType === "R") {
      return "Resident";
    } else if (rowData.identityType === "NR") {
      return "Non-Resident";
    } else {
      return "-";
    }
  };

  const exportAsExcel = () => {
    if (dtExport !== null) {
      dtExport.exportCSV();
    }
  };

  const filterByDate = () => {
    if (
      startDateFilter !== null &&
      startDateFilter !== undefined &&
      endDateFilter !== null &&
      endDateFilter !== undefined
    ) {
      attService
        .getLogsDateRange(companySelected, startDateFilter, endDateFilter)
        .then((res) => {
          setLogs(res);
        })
        .catch((err) => {
          let errorMsg = commonMethods.getErrorMessage(err);
          setErrorMessage(errorMsg);
          setIsError(true);
          setIsLoading(false);
        });
    }
  };
  let tableheader = (
    <div className="row pb-2">
      <div className="col text-md-left">
        <span className="p-input-icon-left mr-1 mb-1">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            onInput={(e: any) => setSearchValue(e.currentTarget.value)}
          />
        </span>

        <span>
          <Dropdown
            value={companySelected}
            options={companyDropdown}
            onChange={(e) => {
              setCompanySelected(e.value);
            }}
            placeholder="All"
          />
        </span>
      </div>
      <div className="col text-md-right">
        <Calendar
          className="mb-1 mx-1"
          placeholder="From Date"
          value={startDateFilter}
          dateFormat="dd/mm/yy"
          onChange={(e) => { e.value!== null && setStartDateFilter(new Date(e.value.toString())) }}
          readOnlyInput={true}
        />
        <Calendar
          className="mb-1 mx-1"
          placeholder="To Date"
          value={endDateFilter}
          dateFormat="dd/mm/yy"
          onChange={(e) => e.value!== null &&  setEndDateFilter(new Date(e.value.toString()))}
          readOnlyInput={true}
        />
        <Button
          label="Filter"
          onClick={() => {
            filterByDate();
          }}
          className="mx-1"
        />
        <Button
          className="button-primary mx-1 mt-1"
          tooltip="Download as excel."
          tooltipOptions={{ position: "top" }}
          label="Export"
          icon="pi pi-download"
          iconPos="left"
          onClick={() => {
            exportAsExcel();
          }}
        />
      </div>
    </div>
  );

  let tableDetails = (
    <>
      <div className="row pt-2">
        <div className="col-12">
          {tableheader}
          <div className="datatable-centerHeader datatable-centerContent">
            <DataTable
              // header={tableheader}
              value={logs}
              globalFilter={searchValue}
              paginator={true}
              rows={20}
              autoLayout={true}
              alwaysShowPaginator={false}
              emptyMessage="No results found."
              ref={(e) => (dtExport = e)}
              exportFilename={
                "Entry-Exit-" +
                companySelectedName +
                "-" +
                commonMethods.displayDate(new Date())
              }
            >
              <Column
                field="identityType"
                body={formatIdentityType}
                header="Identity Type"
              />
              <Column field="identityNo" header="Identity No" />
              <Column field="fullName" header="Name" />
              <Column field="mobileNo" header="Mobile No" />
              <Column
                field="checkInDateTime"
                body={formatDate}
                header="Check In"
              />
              <Column
                field="checkOutDateTime"
                body={formatDate}
                header="Check Out"
              />
              <Column field="personType" header="Guest/Staff" />
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
  if (isError) {
    tableDetails = <CustomError message={errorMessage} />;
  }
  if (isLoading) {
    tableDetails = <ProgressSpinner />;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <h1 className="pb-2">Entry/Exit Logs</h1>

          {tableDetails}
        </div>
      </div>
    </div>
  );
}
