import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { TaxFilingService } from "../../service/TaxFilingService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import SubmissionDetails from "./SubmissionDetails";
import { TaxSubmissionResults } from "./TaxFilingModel";

export default function IrasSubmissionComplete(props: RouteComponentProps) {
  const commonMethods = new CommonMethods();
  const taxFilingService = new TaxFilingService();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(
    "An unidentified error has occurred"
  );

  const [results, setResults] = useState<TaxSubmissionResults>(
    new TaxSubmissionResults()
  );

  const [submissionAttemptId, setSubmissionAttemptId] = useState<string>("");

  const loc = useLocation();

  useEffect(() => {
    const query = commonMethods.getQueryFromURL(loc);
    const id = query.get("id") || "";
    if (id !== "") {
      setSubmissionAttemptId(id);
      setIsLoading(false);

      taxFilingService
        .getSubmissionDetails(id)
        .then((res) => {
          setResults(res);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
    } else {
      setIsError(true);
      setIsLoading(false);
      setErrorMsg("Error occured.");
    }
  }, []);

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = (
      <SubmissionDetails {...props} others={{ id: submissionAttemptId }} />
    );
  }
  return (
    <div className="row">
      <div className="col-12">
        <Link to={"/finance/tax_filing/" + results.taxHeader.taxHeaderID}>
          <i className="pi pi-angle-left align-middle" />
          <span> Back to tax filing</span>
        </Link>
      </div>
      <div className="col-12">
        <div className="card">
          <h1>IRAS Submission - Complete</h1>
          {display}
        </div>
      </div>
    </div>
  );
}
