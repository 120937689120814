import React, { useState } from 'react'
import CloseFullScreenButton from './CloseFullScreenButton'
// const tslcover = require('./assets/TSLcover.jpg')
const sunset = require('../../Assets/sunset.jpg')


export default function CoverPicture() {
    const [isFullscreenImage, setIsFullscreenImage] = useState(false)

    function openImage() {
        setIsFullscreenImage(true)
    }

    return (
        <div className={isFullscreenImage ? "fullscreen-background-cover" : "position-relative"}>
            <CloseFullScreenButton isFullscreenImage={isFullscreenImage} setIsFullscreenImage={setIsFullscreenImage} />
            <img
                className={isFullscreenImage ? "fullscreen-cover" : "profile-page-cover"}
                src={sunset}
                alt=""
                onClick={openImage} />
        </div>
    )
}