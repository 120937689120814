import { Button } from 'primereact/button'
import { confirmDialog } from 'primereact/confirmdialog'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { CommentModel } from '../../Model/CommentModel'
import { PreviewProps, UserPreview } from '../../Model/PostModel'
import CompanyFeedService from '../../Service/CompanyFeedService'
import ProfilePreview from '../ProfilePreview/ProfilePreview'
import TimeStampFromNow from '../TimeStampFromNow'
import CommentAuthor from './CommentAuthor'
import CommentAuthorPicture from './CommentAuthorPicture'
import EditComment from './Forms/EditComment'
import ReplyButton from './ReplyButton'
import ViewRepliesButton from './ViewRepliesButton'
import CommentDraftJsEditorReadOnly from '../DraftJsEditors/CommentDraftJsEditorReadOnly';
import { getContext } from '../../CompanyFeedFunctions'
import { ACTIONS } from '../../DispatchActions'


export default function Comment({
    comment,
    previewData,
    setPreviewData,
    previewProps,
    setPreviewProps,
    setReplyCommentIdentifier,
    isEditMode,
    setIsEditMode,
    showOnHover,
    hoveringOverComment,
    setHoveringOverComment,
    setDisplay,
    editCommentIdentifier,
    setEditCommentIdentifier,
    setViewMoreThreshold,
    setCommentReplyCounter,
    contextName,
    parentComponent,
}: {
    comment: CommentModel;
    previewData: UserPreview | null
    setPreviewData: React.Dispatch<React.SetStateAction<UserPreview | null>>
    previewProps: PreviewProps
    setPreviewProps: React.Dispatch<React.SetStateAction<PreviewProps>>
    setReplyCommentIdentifier: React.Dispatch<React.SetStateAction<string>>;
    isEditMode: boolean
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>
    showOnHover: {
        display: string;
    }
    hoveringOverComment: string
    setHoveringOverComment: React.Dispatch<React.SetStateAction<string>>
    setDisplay: React.Dispatch<React.SetStateAction<string>>
    editCommentIdentifier: string;
    setEditCommentIdentifier: React.Dispatch<React.SetStateAction<string>>
    setViewMoreThreshold: React.Dispatch<React.SetStateAction<number>>
    setCommentReplyCounter: React.Dispatch<React.SetStateAction<number>>
    contextName: string
    parentComponent: string | undefined
}) {
    const { state, dispatch } = useContext(getContext(contextName));
    const commentAuthorPictureRef = useRef<HTMLDivElement>(null)
    const commentAuthorNameRef = useRef<HTMLDivElement>(null)
    const clearPreviewTimer = useRef<ReturnType<typeof setTimeout> | null>(null)
    const showPreviewTimer = useRef<ReturnType<typeof setTimeout> | null>(null)
    const history = useHistory();
    const companyFeedService = new CompanyFeedService();
    const [initialNumOfChildComments] = useState(comment.numberOfChildComments)

    useEffect(() => {
        return () => {
            if (showPreviewTimer.current) clearTimeout(showPreviewTimer.current)
            if (clearPreviewTimer.current) clearTimeout(clearPreviewTimer.current)
        }
    }, [])

    function openReplyInput(comment: CommentModel) {
        if (setCommentReplyCounter) setCommentReplyCounter(prev => prev + 1)
        if (comment.parentCommentId) {
            setReplyCommentIdentifier(comment.parentCommentId)
            return
        }
        if (comment.commentId) {
            setReplyCommentIdentifier(comment.commentId)
            return
        }
    }

    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }

    async function getAndSetPreviewData(item: CommentModel) {
        if (previewProps.cachePreview.length > 0) {
            let foundCache = previewProps.cachePreview.find(preview => preview.userId === item.userId)
            if (foundCache) {
                await timeout(300)
                setPreviewData(foundCache)
                return
            }
        }
        const result = await companyFeedService.getUserPreview(item.userId)
        setPreviewData(result.data)
        setPreviewProps(state => (
            { ...state, cachePreview: [...state.cachePreview, result.data] }
        ))
    }

    async function setPreviewStates(item: CommentModel) {
        await getAndSetPreviewData(item)
        if (commentAuthorNameRef.current) {
            const position = commentAuthorNameRef.current.getBoundingClientRect()
            setPreviewProps(state => (
                { ...state, previewPosition: position, loadPreviewIdentifier: item.commentId }
            ))
        }
    }

    function clearPreviewStates() {
        setPreviewData(null)
        setPreviewProps(state => (
            { ...state, previewPosition: null, loadPreviewIdentifier: "" }
        ))
    }

    async function mouseEnterPic(item: CommentModel) {
        if (clearPreviewTimer.current) clearTimeout(clearPreviewTimer.current)
        const enterTimer = setTimeout(async () => { setPreviewStates(item) }, 250)
        showPreviewTimer.current = enterTimer
    }

    async function mouseEnterName(item: CommentModel) {
        if (clearPreviewTimer.current) clearTimeout(clearPreviewTimer.current)
        const enterTimer = setTimeout(async () => { setPreviewStates(item) }, 250)
        showPreviewTimer.current = enterTimer
    }

    function mouseLeavePic() {
        if (showPreviewTimer.current) clearTimeout(showPreviewTimer.current)
        const leaveTimer = setTimeout(() => { clearPreviewStates() }, 200)
        clearPreviewTimer.current = leaveTimer
    }

    function mouseLeaveName() {
        if (showPreviewTimer.current) clearTimeout(showPreviewTimer.current)
        const leaveTimer = setTimeout(() => { clearPreviewStates() }, 200)
        clearPreviewTimer.current = leaveTimer
    }

    function mouseEnterPreview() {
        if (clearPreviewTimer.current) clearTimeout(clearPreviewTimer.current)
    }

    function mouseLeavePreview() {
        if (showPreviewTimer.current) clearTimeout(showPreviewTimer.current)
        const leaveTimer = setTimeout(() => { clearPreviewStates() }, 200)
        clearPreviewTimer.current = leaveTimer
    }

    async function enterProfile(item: CommentModel) {
        history.push(`/publicprofile/${item.userId}`, { userId: item.userId }); // navigate to route
    }

    function mouseEnterComment(comment: CommentModel) {
        if (!isEditMode) {
            setHoveringOverComment(comment.commentId!)
            setDisplay("")
        }
    }
    function mouseLeaveComment() {
        if (!isEditMode) {
            setHoveringOverComment("")
            setDisplay("none")
        }
    }
    function handleEditComment(commentId: string) {
        if (!isEditMode) {
            setIsEditMode(true)
            setEditCommentIdentifier(commentId)
        } else {
            setIsEditMode(false)
            setEditCommentIdentifier('')
        }
    }
    async function handleDeleteComment(comment: CommentModel) {
        try {
            // Need to check if successfully deleted comment
            await companyFeedService.deleteComment(comment.commentId)

            console.log('total comments deleted', (comment.numberOfChildComments) + 1)

            if (comment.parentCommentId == null) {
                if (parentComponent === "TodayBirthdayPost")
                    dispatch({
                        type: ACTIONS.BIRTHDAY_POST.DELETE_COMMENT, payload: {
                            postId: comment.postId,
                            commentId: comment.commentId,
                            numberOfCommentsDeleted: (comment.numberOfChildComments) + 1
                        }
                    })
                else
                    dispatch({
                        type: ACTIONS.DELETE_COMMENT, payload: {
                            postId: comment.postId,
                            commentId: comment.commentId,
                            numberOfCommentsDeleted: (comment.numberOfChildComments) + 1
                        }
                    })
                // prevents deleting comment from showing older comment
                setViewMoreThreshold(prevState => prevState - 1)
            } else {
                if (parentComponent === "TodayBirthdayPost")
                    dispatch({
                        type: ACTIONS.BIRTHDAY_POST.DELETE_CHILD_COMMENT, payload: {
                            postId: comment.postId,
                            commentId: comment.commentId,
                            parentCommentId: comment.parentCommentId,
                        }
                    })
                else
                    dispatch({
                        type: ACTIONS.DELETE_CHILD_COMMENT, payload: {
                            postId: comment.postId,
                            commentId: comment.commentId,
                            parentCommentId: comment.parentCommentId,
                        }
                    })
            }
        } catch (e) {
            console.log('error', e)
        }
    }
    const confirmDelete = (comment: CommentModel) => {
        confirmDialog({
            message: 'Do you want to delete this comment?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => handleDeleteComment(comment),
            className: "confirm-delete-dialog"
        });
    }

    let ChildComments = (comment?.childComments?.map(child => {
        return (
            <div key={child.commentId} className="child-comments">
                <Comment
                    comment={child}
                    previewData={previewData}
                    setPreviewData={setPreviewData}
                    previewProps={previewProps}
                    setPreviewProps={setPreviewProps}
                    setReplyCommentIdentifier={setReplyCommentIdentifier}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    showOnHover={showOnHover}
                    hoveringOverComment={hoveringOverComment}
                    setHoveringOverComment={setHoveringOverComment}
                    setDisplay={setDisplay}
                    editCommentIdentifier={editCommentIdentifier}
                    setEditCommentIdentifier={setEditCommentIdentifier}
                    contextName={contextName}
                    setViewMoreThreshold={setViewMoreThreshold}
                    setCommentReplyCounter={setCommentReplyCounter}
                    parentComponent={parentComponent}
                />
            </div>
        )
    }))

    return (
        <div className="position-relative">
            <div className="flex-row each-comment" onMouseEnter={() => mouseEnterComment(comment)} onMouseLeave={mouseLeaveComment}>
                <div
                    ref={commentAuthorPictureRef}
                    onMouseEnter={() => mouseEnterPic(comment)}
                    onMouseLeave={mouseLeavePic}
                    onClick={() => enterProfile(comment)}
                >
                    <CommentAuthorPicture item={comment} />
                </div>
                {isEditMode && comment.commentId === editCommentIdentifier
                    ?
                    <EditComment
                        comment={comment}
                        useEditMode={[isEditMode, setIsEditMode]}
                        contextName={contextName}
                        parentComponent={parentComponent}
                    />
                    : <>
                        <div className="flex-column">
                            <div className="flex-row">
                                <div className="flex-column comment-bubble">
                                    <div
                                        className="comment-author"
                                        ref={commentAuthorNameRef}
                                        onMouseEnter={() => mouseEnterName(comment)}
                                        onMouseLeave={mouseLeaveName}
                                        onClick={() => enterProfile(comment)}
                                    >
                                        <CommentAuthor item={comment} />
                                    </div>
                                    <CommentDraftJsEditorReadOnly
                                        rawEditorContentJsonStr={comment.rawEditorContentJsonStr}
                                        commentText={comment.commentText}
                                    />
                                </div>
                                {((comment.userId === state.currentUser.userId) || (state.currentUser.hasCompanyFeedAdminAccess)) && comment.commentId === hoveringOverComment
                                    ?
                                    <div className="comment-buttons" style={showOnHover} >
                                        <Button onClick={() => handleEditComment(comment.commentId!)}
                                            icon="pi pi-pencil" className="p-button-secondary p-button-text" />
                                        <Button onClick={() => confirmDelete(comment)}
                                            icon="pi pi-trash" className="p-button-secondary p-button-text" />
                                    </div>
                                    : <div className="comment-buttons-placeholder"></div>}
                            </div>
                            <div className="flex-row align-items-center reply-and-timestamp">
                                <div onClick={() => openReplyInput(comment)}>
                                    <ReplyButton item={comment} />
                                </div>
                                <div className="interpunct">·</div>
                                <TimeStampFromNow comment={comment} />
                            </div>
                            {comment.parentCommentId == null && !comment.loadedReplies && initialNumOfChildComments > 0 ?
                                <div className="view-replies-parent">
                                    <ViewRepliesButton
                                        item={comment}
                                        dispatch={dispatch}
                                        parentComponent={parentComponent}
                                    />
                                </div>
                                : null
                            }
                        </div>
                    </>
                }
            </div>
            {ChildComments}
            <div onMouseEnter={mouseEnterPreview} onMouseLeave={mouseLeavePreview}>
                <ProfilePreview previewData={previewData} previewProps={previewProps} parentId={comment.commentId} />
            </div>
        </div>
    )
}