import React from "react";

export enum TaskList {
  adminchangepw = "adminchangepw",
  adminmenuaccess = "adminmenuaccess",
  approveclaimsaccess = "approveclaimsaccess",
  approveleaveaccess = "approveleaveaccess",
  basicaccess = "basicaccess",
  bossaccess = "bossaccess",
  campaignaccess = "campaignaccess",
  campaignadminaccess = "campaignadminaccess",
  campaigninvoiceaccess = "campaigninvoiceaccess",
  campaigntypeaccess = "campaigntypeaccess",
  chronosaccess = "chronosaccess",
  clientaccess = "clientaccess",
  companyaccess = "companyaccess",
  companyannouncement = "companyannouncement",
  companyfeedadmin = "companyfeedadmin",
  contractaccess = "contractaccess",
  costtypeaccess = "costtypeaccess",
  customkpiaccess = "customkpiaccess",
  customleaveaccess = "customleaveaccess",
  deliverabletypeaccess = "deliverabletypeaccess",
  departmentaccess = "departmentaccess",
  departmentleaveaccess = "departmentleaveaccess",
  departmentuserindicatoraccess = "departmentuserindicatoraccess",
  deptannouncement = "deptannouncement",
  documenttypeaccess = "documenttypeaccess",
  endorseclaimaccess = "endorseclaimaccess",
  equipmentadminaccess = "equipmentadminaccess",
  financeaccess = "financeaccess",
  groupannouncement = "groupannouncement",
  holidayaccess = "holidayaccess",
  insightsrnaccess = "insightsrnaccess",
  jobtitleaccess = "jobtitleaccess",
  leaveadminaccess = "leaveadminaccess",
  operationalcostaccess = "operationalcostaccess",
  paymenttermsaccess = "paymenttermsaccess",
  payrollaccess = "payrollaccess",
  publisheraccess = "publisheraccess",
  quicklinksaccess = "quicklinksaccess",
  reportaccess = "reportaccess",
  roleaccess = "roleaccess",
  roomaccess = "roomaccess",
  safeentrydashboardaccess = "safeentrydashboardaccess",
  salesaccess = "salesaccess",
  scoreindicatoraccess = "scoreindicatoraccess",
  statustypeaccess = "statustypeaccess",
  techaccess = "techaccess",
  useraccess = "useraccess",
  userdocaccess = "userdocaccess",
  userindicatoraccess = "userindicatoraccess",
  userindicatorsbossaccess = "userindicatorsbossaccess",
  userroleaccess = "userroleaccess",
  viewclaimsaccess = "viewclaimsaccess",
  weeklydutiesaccess = "weeklydutiesaccess",
}
