import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ProgressSpinner } from "primereact/progressspinner";
import { Prompt, Redirect, StaticContext } from "react-router";
import { PayslipService } from "../../service/PayslipService";
import {
    CommonMethods,
    DropdownModel,
    RouteComponentProps,
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import {
    PayItemCategory,
    PayItemMeta,
    PayItemModel,
    PayslipInfo,
    payslipmeta,
    PayslipModel,
    PayslipObjAndInfoModel,
    UpdateLog,
} from "../resources/ExportClass";
import NumberFormat from "react-number-format";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function PayslipUpdatefunction(props: RouteComponentProps<any>) {
    const { SetSuccess, SetError } = React.useContext(ToastStateContext);
    const payslipService = new PayslipService();
    const commonMethods = new CommonMethods();
    // Declare Constants & States
    const logoCSS: React.CSSProperties = {
        // width: "70%",
        overflow: "hidden",
        textAlign: "center",
    };


    const [payslipInfo, setPayslipInfo] = useState<PayslipInfo>(new PayslipInfo());
    const [payslipObj, setPayslipObj] = useState<PayslipModel>(new PayslipModel());
    const [updateLog, setUpdateLog] = useState<Array<UpdateLog>>(new Array<UpdateLog>());
    const [payItemDropdown, setPayItemDropdown] = useState<Array<DropdownModel>>(new Array<DropdownModel>());
    const [payItemDropdownFullObj, setPayItemDropdownFullObj] = useState<Array<PayItemModel>>(new Array<PayItemModel>());

    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");

    const [redirectOut, setRedirectOut] = useState<boolean>(false);
    const [redirectPath, setRedirectPath] = useState<string>("");

    const [preparedBy, setPreparedBy] = useState<string>("System");

    const [payItemListAfterFetch, setPayItemListAfterFetch] = useState<boolean>(false);
    const [payItemList, setPayItemList] = useState<Array<PayItemMeta>>(new Array<PayItemMeta>());

    // UseEffects
    useEffect(() => {
        setupCalls(props);
    }, []);

    useEffect(() => {
        if (!payItemListAfterFetch && (payslipObj.additionalWage.length > 0 || payslipObj.deductables.length > 0) && !isFetching) {
            console.log("uwu");
            setPayItemList(payslipObj.additionalWage.concat(payslipObj.deductables));
            setPayItemListAfterFetch(true); // To prevent infinite render loop
        }
        setIsLoaded(true);
    }, [payslipObj]);


    // Functions
    const camelCase = (text: string) => {
        return text.substr(0, 1).toUpperCase() + text.substr(1);
    }

    const getIndex = (haystack: Array<payslipmeta>, needle: string) => {
        return haystack.map((o) => o.meta_key).indexOf(needle);
    }

    const setupCalls = (passedProps: RouteComponentProps<any>) => {
        commonMethods
            .getFullDisplayNameFromProvidedUserID(passedProps.userProfile.userID)
            .then((res) => {
                setPreparedBy(res);
            })
            .catch((err) => {
                let errMsg = commonMethods.getErrorMessage(err);
                setErrorMsg(errMsg);
                setIsFetching(false);
                setIsError(true);
            });

        payslipService
            .getPayItemTypeDropdown()
            .then((res) => {
                setPayItemDropdown(res);
            })
            .catch((err) => {
                let errMsg = commonMethods.getErrorMessage(err);
                setErrorMsg(errMsg);
                setIsFetching(false);
                setIsError(true);
            });
        payslipService
            .getAllPayItemTypes()
            .then((res) => {
                setPayItemDropdownFullObj(res);
            })
            .catch((err) => {
                let errMsg = commonMethods.getErrorMessage(err);
                setErrorMsg(errMsg);
                setIsFetching(false);
                setIsError(true);
            });

        payslipService
            .getPayslipWithUpdateLogByPayslipId(passedProps.match.params.id)
            .then((res: PayslipObjAndInfoModel) => {
                setPayslipInfo(res.payslipInfo);
                setPayslipObj({ ...res.payslipModel, remarks: "" });
                setUpdateLog(res.updateLog);
                setIsFetching(false);

            })
            .catch((err) => {
                let errMsg = commonMethods.getErrorMessage(err);
                setErrorMsg(errMsg);
                setIsFetching(false);
                setIsError(true);
            });
    }

    // Function Setup
    const addField = () => {
        setPayItemList([...payItemList, new PayItemMeta()]);
        setIsEdited(true);
    };

    const removeField = (index: number) => {
        payItemList.splice(index, 1);
    };

    const addNewPayItemID = (e: {
        originalEvent: Event;
        target: any;
        value: any;
    }) => {
        if (payItemList.length <= e.target.id) {
            let newItem = new PayItemMeta();
            newItem.meta_key = e.value;
            payItemList.push(newItem);
        } else {
            payItemList[e.target.id].meta_key = e.value;
        }
    };

    const addNewPayItemAmount = (amount: string, index: number) => {
        if (payItemList.length <= index) {
            let newItem = new PayItemMeta();
            newItem.meta_value = amount;
            payItemList.push(newItem);
        } else {
            payItemList[index].meta_value = amount;
        }
    };
    const splitAddandDeduct = () => {
        let add: Array<PayItemMeta> = new Array<PayItemMeta>();
        let deduct: Array<PayItemMeta> = new Array<PayItemMeta>();
        let error = false;

        if (payItemList.length > 0) {
            for (const payItem of payItemList) {
                if (payItem.meta_value === "0" || payItem.meta_key === "") {
                    SetError("Please fill up both Pay Item and Amount.");
                    error = true;
                    return { add, deduct, error };
                }
                const payItemFound = _.find(payItemDropdownFullObj, [
                    "payItemID",
                    payItem.meta_key,
                ]);

                if (payItemFound?.category === PayItemCategory.Add.toString()) {
                    add.push({
                        meta_key: payItemFound.payItemID,
                        meta_value: payItem.meta_value,
                    });
                } else if (payItemFound?.category === PayItemCategory.Deduct) {
                    deduct.push({
                        meta_key: payItemFound.payItemID,
                        meta_value: payItem.meta_value,
                    });
                }
            }
        }
        return { add, deduct, error };
    };
    const recalculate = () => {
        console.log("recalculate");
        const addAndDeductObj = splitAddandDeduct();
        if (!addAndDeductObj.error) {
            const thisPayslip: PayslipModel = payslipObj;
            thisPayslip.additionalWage = addAndDeductObj.add;
            thisPayslip.deductables = addAndDeductObj.deduct;

            payslipService.updatePayslipCalculations(
                thisPayslip,
                payslipInfo
            )
                .then((res: PayslipObjAndInfoModel) => {
                    setPayslipObj(res.payslipModel);
                    setPayslipInfo(res.payslipInfo);
                    setIsEdited(true);
                });
        }
    };

    const updatePayslip = () => {
        console.log("updatePayslip");
        if (payslipObj.remarks === "") {
            SetError("Remarks is required.");
            return;
        }

        if (isEdited) {
            const addAndDeductObj = splitAddandDeduct();
            if (!addAndDeductObj.error) {
                const thisPayslip: PayslipModel = payslipObj;
                thisPayslip.additionalWage = addAndDeductObj.add;
                thisPayslip.deductables = addAndDeductObj.deduct;
                setPayslipObj(thisPayslip);

                payslipService
                    .updatePayslip(thisPayslip)
                    .then((res: PayslipObjAndInfoModel) => {
                        SetSuccess("Payslip updated!");
                        setupCalls(props);
                        setIsEdited(false);
                    })
                    .catch((err) => {
                        let errMsg = commonMethods.getErrorMessage(err);
                        SetError(errMsg);
                    });
            }
        }
    };

    const updateDateFormat = (rowData: any) => {
        var months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        var thisDate = new Date(rowData.updateDate);
        return (
            thisDate.getDate() +
            " " +
            months[thisDate.getMonth()] +
            " " +
            thisDate.getFullYear()
        );
    }

    // End Functions


    // Render Functions
    const formatMoneyDisplay = (amount: number) => {
        return (
            // NumberFormat doesn't render properly for number values(?) Renders properly for string values (Deductions)
            // <NumberFormat
            //   displayType="text"
            //   value={amount}
            //   thousandSeparator={true}
            //   defaultValue={amount}
            //   prefix={"$"}
            //   decimalScale={2}
            //   fixedDecimalScale={true}
            // />
            "$" + amount.toFixed(2)
        );
    }

    // const renderOTbreakdown = () => {
    //   const renderDisplay: JSX.Element[] = [];
    //   if (
    //     state.payslipObj.overtimeWage.length > 0 &&
    //     state.isFetching === false
    //   ) {
    //     state.payslipObj.overtimeWage.forEach((log, index) => {
    //       renderDisplay.push(
    //         <div className="p-col-12 p-grid" key={index}>
    //           <div className="p-col-4">{formatDate(log.endDate, true)}</div>
    //           <div className="p-col-2">
    //             {formatTime(log.startDate)}
    //             {" to "}
    //             {formatTime(log.endDate)}
    //           </div>
    //           <div className="p-col-4">
    //             {formatDate(log.lastUpdated, true)}
    //           </div>
    //           <div className="p-col-2">{log.hoursClocked}</div>
    //         </div>
    //       );
    //     });
    //   } else {
    //     renderDisplay.push(
    //       <div className="p-col-12" key={"0"}>
    //         No Records
    //       </div>
    //     );
    //   }

    //   return <>{display}</>;
    // }

    // const goToExport = () => {
    //     if (localExport !== null) {
    //       payslipService
    //         .ExportPayslip(state.payslipObj)
    //         .then((res) => {
    //           if (localExport !== null) {
    //             localExport.save(() => {
    //               context.SetSuccess("Payslip PDF generated.");
    //               setupCalls(state.payslipObj.payslipID)
    //             });
    //           }
    //         })
    //         .catch((err) => {
    //           let errMsg = commonMethods.getErrorMessage(err);
    //           context.SetError("Failed to export. " + errMsg);
    //         });
    //     }
    // }

    // const goBack = () => {
    //     setRedirectOut(true);
    //     setRedirectPath("/finance/payroll/" + payslipObj.payrollID);
    // }


    // End Render Functions

    // Output display for finance
    if (redirectOut) {
        return (
            <Redirect
                push
                to={{
                    pathname: redirectPath,
                }}
            />
        );
    }

    return (
        <>
            <div className="row">
                <Prompt
                    when={isEdited}
                    message={
                        "There are unsaved changes. Are you sure you want to leave?"
                    }
                />
                {/* <div className="col-12">
            <div className="p-grid p-2">
              <Button
                onClick={goBack}
                label="Back"
                className="p-button-secondary"
              />
            </div>
          </div> */}
                <div className="col-12 col-xl-7">
                    <div className="card">
                        <h1 className="pb-2">Payslip Preview</h1>
                        {isError ? <CustomError message={errorMsg} /> : ( // or some growl/message that stays
                            isFetching ? <ProgressSpinner /> : (<div className="p-flex">
                                <div className="p-grid p-justify-center pt-3 pb-3 mb-3">
                                    <div className="col-12">
                                        {/* <renderLoadingOverlay /> */}
                                        {/* INFORMATION */}
                                        <div className="p-col-12 text-center mb-3">
                                            <div className="p-grid p-justify-center">
                                                <div
                                                    className="p-col-12"
                                                    style={{ borderBottom: "2px solid #DFE9F2" }}
                                                ><div className="p-grid p-fluid p-justify-between">
                                                        <div className="p-col-5 outerCenterWrap">
                                                            <div style={logoCSS}>
                                                                <img
                                                                    src={payslipInfo.payslipLogo}
                                                                    alt="company payslip logo"
                                                                    className="payslip-pic"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="p-col-4 p-col-align-end text-left">
                                                            <b>{payslipInfo.companyName}</b>

                                                            <p className="mb-0">
                                                                <span className="boldLabel">UEN:</span>
                                                                {payslipInfo.companyRegNum}{" "}
                                                            </p>
                                                            <p className="mb-0">{payslipInfo.companyAddress}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-11 text-left p-justify-center">
                                                    <div className="p-grid p-fluid">
                                                        <div className="p-col-8">
                                                            <div className="p-col-12">
                                                                Employee Name
                  <br />
                                                                <span className="font-weight-bold">{payslipInfo.fullName}</span>
                                                            </div>
                                                            <div className="p-col-12 p-grid pb-0 pt-0">
                                                                <div className="p-col-4">D.O.B:</div>
                                                                <div className="p-col-8 font-weight-bold">
                                                                    {commonMethods.displayDate_Words(payslipInfo.dateOfBirth)}
                                                                </div>
                                                            </div>
                                                            <div className="p-col-12 p-grid pb-0 pt-0">
                                                                <div className="p-col-4">Tax Ref/Fin No.:</div>
                                                                <div className="p-col-8 font-weight-bold">
                                                                    {payslipInfo.identificationNumber}
                                                                </div>
                                                            </div>
                                                            <div className="p-col-12 p-grid pb-0 pt-0">
                                                                <div className="p-col-4">Age</div>
                                                                <div className="p-col-8 font-weight-bold">
                                                                    {commonMethods.calculateAge(payslipInfo.dateOfBirth)}
                                                                </div>
                                                            </div>
                                                            <div className="p-col-12 p-grid pb-0 pt-0">
                                                                <div className="p-col-4">Currency</div>
                                                                <div className="p-col-8 font-weight-bold">
                                                                    {payslipObj.currency}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-4 p-col-align-center">
                                                            <b style={{ fontSize: "14pt" }}>Pay Advice For</b> <br />
                                                            <b>
                                                                {commonMethods.displayMonthYear_Full(
                                                                    payslipObj.paymentDate
                                                                )}
                                                            </b>
                                                            <div className="p-col-12">
                                                                A/C No.: {"    "}
                                                                {payslipInfo.bank_ShortName + payslipInfo.bank_Number}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* // INFORMATION  */}
                                        {/* PAYSLIP DETAILS */}
                                        <div className="p-col-12 text-center border mb-3">
                                            <div className="p-grid p-fluid p-align-start">
                                                {/* Wage Earnings Information */}
                                                <div className="p-col-6 border-0">
                                                    <div className="p-grid p-fluid">
                                                        <div className="p-col-12 px-2 payslipSections">
                                                            <span className="font-weight-bold">Earnings</span>
                                                        </div>
                                                        <>
                                                            <div className="p-col-6">
                                                                <b>Basic Pay</b>
                                                            </div>
                                                            <div className="p-col-6">
                                                                {formatMoneyDisplay(payslipObj.basicSalary)}
                                                            </div>
                                                            <div className="p-col-6">
                                                                <b>Work Days</b>
                                                            </div>
                                                            <div className="p-col-6">
                                                                {payslipObj.workDays}
                                                                {" day(s)"}
                                                            </div>
                                                            <div className="p-col-6">
                                                                <b>Unpaid Leaves</b>
                                                            </div>
                                                            <div className="p-col-6">
                                                                {payslipObj.unpaidLeaveDays}
                                                                {" day(s)"}
                                                            </div>
                                                            <div className="p-col-6">
                                                                <b>Ordinary Wage</b>
                                                            </div>
                                                            <div className="p-col-6 border-top">
                                                                {formatMoneyDisplay(payslipObj.ordinaryWage)}
                                                            </div>
                                                        </>

                                                        <div className="p-col-12 px-2 payslipSections">
                                                            <span className="font-weight-bold">Additional Wages</span>
                                                        </div>
                                                        {payslipObj.additionalWageDisplay.map((value, index) => {
                                                            return (
                                                                <div className="p-col-12 p-grid" key={index}>
                                                                    <div className="p-col-6">
                                                                        <b>{camelCase(value.meta_key)}</b>
                                                                    </div>
                                                                    <div className="p-col-6">
                                                                        <NumberFormat
                                                                            displayType="text"
                                                                            value={value.meta_value}
                                                                            thousandSeparator={true}
                                                                            prefix={"$"}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                {/* // Wage Earnings Information */}

                                                {/* Wage Deduction Information */}
                                                <div className="p-col-6 border-0">
                                                    <div className="p-grid p-fluid">
                                                        <div className="p-col-12 px-2 payslipSections">
                                                            <span className="font-weight-bold">Deductions</span>
                                                        </div>
                                                        {(getIndex(payslipObj.contributions, "Employee Contribution") > 0) ?
                                                            <div className="p-col-12 p-grid" key="employee_contribution">
                                                                <div className="p-col-6">
                                                                    <b>
                                                                        {
                                                                            payslipObj.contributions[
                                                                                getIndex(payslipObj.contributions, "Employee Contribution")
                                                                            ].meta_key
                                                                        }
                                                                    </b>
                                                                </div>
                                                                <div className="p-col-6">
                                                                    <NumberFormat
                                                                        displayType="text"
                                                                        value={
                                                                            payslipObj.contributions[
                                                                                getIndex(payslipObj.contributions, "Employee Contribution")
                                                                            ].meta_value
                                                                        }
                                                                        thousandSeparator={true}
                                                                        prefix={"$"}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            : <></>
                                                        }

                                                        {payslipObj.donation.map((obj, i) => {
                                                            return (
                                                                <div className="p-col-12 p-grid" key={i}>
                                                                    <div className="p-col-6">
                                                                        <b>{obj.meta_key}</b>
                                                                    </div>
                                                                    <div className="p-col-6">
                                                                        <NumberFormat
                                                                            displayType="text"
                                                                            value={obj.meta_value}
                                                                            thousandSeparator={true}
                                                                            prefix={"$"}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                        {payslipObj.deductablesDisplay.map((value, index) => {
                                                            return (
                                                                <div className="p-col-12 p-grid" key={index}>
                                                                    <div className="p-col-6">
                                                                        <b>{camelCase(value.meta_key)}</b>
                                                                    </div>
                                                                    <div className="p-col-6">
                                                                        <NumberFormat
                                                                            displayType="text"
                                                                            value={value.meta_value}
                                                                            thousandSeparator={true}
                                                                            prefix={"$"}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                {/* // Wage Deduction Information */}

                                                {/* Gross and Net Pay */}
                                                <div className="p-col-12 border-0">
                                                    <div className="p-grid p-fluid">
                                                        <div className="p-col-3 payslipSections">
                                                            <b>Gross Pay</b>
                                                        </div>
                                                        <div className="p-col-3 border-top border-bottom">
                                                            {formatMoneyDisplay(payslipObj.grossSalary)}
                                                        </div>
                                                        <div className="p-col-3 payslipSections">
                                                            <b>Net Pay</b>
                                                        </div>
                                                        <div className="p-col-3 border-top border-bottom">
                                                            {formatMoneyDisplay(payslipObj.netSalary)}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* // Gross and Net Pay */}
                                            </div>
                                        </div>

                                        {/* // PAYSLIP DETAILS */}
                                        <div className="p-col-12 text-center border mb-3">
                                            <div className="row">
                                                <div className="col-12 col-xl-6">
                                                    <div className="col-12 text-left pb-2">
                                                        <span
                                                            className="pb-3 font-weight-bold"
                                                            style={{ color: "#4E79AD" }}
                                                        >
                                                            Employer Contributions
                  </span>
                                                    </div>
                                                    <div className="row">
                                                        <div className="p-col-6">
                                                            <b>Employer CPF</b>
                                                        </div>
                                                        <div className="p-col-6">
                                                            {getIndex(
                                                                payslipObj.contributions,
                                                                "Employer Contribution"
                                                            ) > 0
                                                                ? formatMoneyDisplay(
                                                                    parseFloat(
                                                                        payslipObj.contributions[
                                                                            getIndex(
                                                                                payslipObj.contributions,
                                                                                "Employer Contribution"
                                                                            )
                                                                        ].meta_value
                                                                    )
                                                                )
                                                                : "-"}

                                                            {/* <NumberFormat
                      displayType="text"
                      value={
                        getIndex(
                          payslipObj.contributions,
                          "Employer Contribution"
                        ) > 0
                          ? payslipObj.contributions[
                              getIndex(
                                payslipObj.contributions,
                                "Employer Contribution"
                              )
                            ].meta_value
                          : "-"
                      }
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    /> */}
                                                        </div>

                                                        <div className="p-col-6">
                                                            <b>SDF (0.25%)</b>
                                                        </div>
                                                        <div className="p-col-6">
                                                            {formatMoneyDisplay(payslipObj.sdf)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-xl-6">
                                                    <div className="col-12 text-left pb-2"> &nbsp; </div>

                                                    <div className="row">
                                                        <div className="p-col-6 font-weight-bold">Pay Period</div>
                                                        <div className="p-col-6">
                                                            {commonMethods.formatDateDisplayPayPeriod(
                                                                payslipObj.payStartDate,
                                                                payslipObj.payEndDate
                                                            )}
                                                        </div>

                                                        <div className="p-col-6 font-weight-bold">Pay Day</div>
                                                        <div className="p-col-6">
                                                            {commonMethods.formatDatePayDay(
                                                                payslipObj.paymentDate
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* YTD (YearToDate) Earnings */}
                                        <div className="p-col-12 text-center border mb-3">
                                            <div className="row">
                                                <div className="col-12 col-xl-6">
                                                    <div className="col-12 text-left pb-2">
                                                        <span
                                                            className="pb-3 font-weight-bold"
                                                            style={{ color: "#4E79AD" }}
                                                        >
                                                            Year to Date Earnings
                  </span>
                                                    </div>
                                                    <div className="row">
                                                        <div className="p-col-6">
                                                            <b>Total Salary</b>
                                                        </div>
                                                        {/* <div className="p-col-6">-</div> */}

                                                        <div className="p-col-6">
                                                            {formatMoneyDisplay(payslipObj.ytdPayout)}
                                                        </div>

                                                        {/* <div className="p-col-6">
                    <b>Bonus Entitlement</b>
                  </div>
                  <div className="p-col-6">-</div> */}

                                                        {/* <div className="p-col-6">{ytdMonths} Month(s)</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* // YTD (YearToDate) Earnings */}

                                        {/* Unpaid Leave Calculation */}
                                        <div className="p-col-12 text-center border mb-3">
                                            <div className="p-col-12">
                                                <span className="pb-3 font-weight-bold" style={{ color: "#4E79AD" }}>Unpaid Leave Calculation</span>
                                                <div className="p-grid p-fluid">
                                                    {payslipObj.unpaidLeaveDays > 0 ? (
                                                        <>
                                                            <div className="p-col-4">
                                                                {" "}
                                                                <b>Unpaid Leaves</b>{" "}
                                                            </div>{" "}
                                                            <div className="p-col-8"> {payslipObj.unpaidLeaveDays} Days </div>
                                                        </>
                                                    ) : (
                                                            <div className="p-col-12">No Records</div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* // Unpaid Leave Calculation */}

                                        {/* Prepared By */}
                                        <div className="p-col-3 p-col-offset-9 p-offset-9 text-center">
                                            <p className="mb-0">Prepared by</p>
                                            <p className="font-weight-bold">{preparedBy}</p>
                                        </div>
                                        {/* //Prepared By */}
                                    </div>
                                </div>
                            </div>
                            ))}
                    </div>
                </div>
                <div className="col-12 col-xl-5">
                    <div className="card">
                        <h1 className="pb-2">Update Payslip</h1>
                        {/* Finance View */}
                        <h5 className="p-col-12">
                            <span>1. Add Pay Items</span>
                            <Button
                                className="p-button-info ml-2"
                                onClick={addField}
                                label="Add"
                            />
                        </h5>

                        {/* Additional Wages and Deductions */}
                        <div className="p-col-12 mb-3">
                            <div className="p-grid p-fluid">
                                {payItemList.map((value, index) => {
                                    return (
                                        <div className="p-col-12 p-grid p-justify-center" key={index}>
                                            <div className="p-col-7">
                                                <span className="boldLabel">Pay Item</span>

                                                <Dropdown
                                                    options={payItemDropdown}
                                                    placeholder="Please Select"
                                                    id={index + ""}
                                                    value={payItemList[index].meta_key}
                                                    onChange={(e) => addNewPayItemID(e)}
                                                />
                                            </div>

                                            <div className="p-col-4">
                                                <span className="boldLabel">Amount</span>
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">$</span>
                                                    <NumberFormat
                                                        className="p-inputtext"
                                                        value={payItemList[index]?.meta_value}
                                                        thousandSeparator={true}
                                                        id={`${index}-amount`}
                                                        // prefix={"$"}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        onValueChange={(e) => {
                                                            addNewPayItemAmount(e.value, index);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-1 p-col-align-end">
                                                <Button
                                                    icon="pi pi-times"
                                                    className="p-button-danger"
                                                    onClick={(e) => {
                                                        removeField(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="text-center pt-4">
                                <span>Click to preview pay items on payslip with recalculated amounts.</span>
                            </div>
                            <div className="text-center pt-2">
                                <Button
                                    className="p-button"
                                    onClick={recalculate}
                                    label="Calculate"
                                />
                            </div>
                        </div>

                        <div className="p-col-12">
                            <h5>2. Save your changes</h5>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <span className="boldLabel">Remarks</span>{" "}
                                    <span className="requiredRed">*</span>
                                    <InputTextarea
                                        value={payslipObj.remarks}
                                        onChange={(e: any) => {
                                            setPayslipObj({ ...payslipObj, remarks: e.target.value });
                                            setIsEdited(true);
                                        }
                                        }
                                        autoResize={true}
                                        required
                                    />
                                </div>
                            </div>{" "}
                            <div className="text-center pt-2">
                                <Button
                                    className="p-button mr-2"
                                    onClick={updatePayslip}
                                    label="Update"
                                />
                            </div>
                        </div>

                        <div className="p-col-12 pt-3 pb-3">
                            <div className="p-grid p-align-end">
                                <h5 className="p-col-12">Update History</h5>

                                <div className="p-col-12">
                                    <div className="datatable-centerHeader datatable-centerContent">
                                        <DataTable
                                            value={updateLog}
                                            paginator={true}
                                            rows={5}
                                            autoLayout={true}
                                            alwaysShowPaginator={false}
                                            emptyMessage="No results found."
                                        >
                                            <Column
                                                field="versionNumber"
                                                header="Ver"
                                                className="p-col-1"
                                            />
                                            <Column body={updateDateFormat} header="Date" />
                                            <Column
                                                field="updateFullName"
                                                header="Updated By"
                                                style={{ width: "10em" }}
                                            />
                                            <Column field="remarks" header="Remarks" />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* // Finance View */}
                    </div>
                </div>
            </div>
            {/* <div style={{ position: "absolute", left: "-1000px", top: 0 }}>
          <PDFExport
            paperSize={"A4"}
            title=""
            subject=""
            keywords=""
            forceProxy={true}
            proxyURL="/api/SavePDF/Payslip"
            proxyTarget="_blank"
            ref={(r) => (localExport = r)}
            fileName={state.payslipObj.payslipID}
          >
            <div
              style={{
                height: 842,
                width: 595,
                padding: "none",
                backgroundColor: "white",
                boxShadow: "5px 5px 5px black",
                margin: "auto",
                overflowX: "hidden",
                overflowY: "hidden",
                fontSize: "x-small",
                fontFamily: "DejaVu Sans,sans-serif",
              }}
            >
              {pdfCopyToBeExported}
            </div>
          </PDFExport>
        </div> */
            }
        </>
    );
}
export default PayslipUpdatefunction;