import { HttpClient } from "./HttpClient";
import { RoomModel } from "../components/room/RoomModel";

export class RoomService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  //includes deleted
  async getAllRooms() {
    const res = await this.httpClient.get("/api/room/");
    return res;
  }
  async getAllActiveRooms() {
    const res = this.httpClient.get("/api/room/activerooms");
    return res;
  }
  async getAllActiveAndInactiveRooms() {
    const res = this.httpClient.get("/api/room/activeandinactive");
    return res;
  }

  async createRoom(roomModel: RoomModel) {
    const res = this.httpClient.post("/api/room/create", roomModel);
    return res;
  }

  async updateRoom(roomModel: RoomModel) {
    const res = this.httpClient.put("/api/room/update", roomModel);
    return res;
  }

  async deleteRoom(roomID: string) {
    const res = this.httpClient.delete("/api/room/delete/" + roomID);
    return res;
  }
  async deactivateRoom(roomID: string) {
    const res = this.httpClient.get("/api/room/deactivate/" + roomID);
    return res;
  }

  async activateRoom(roomID: string) {
    const res = this.httpClient.get("/api/room/activate/" + roomID);
    return res;
  }

  async updateRoomStatus(roomObj: RoomModel) {
    const res = this.httpClient.put("/api/room/updatestatus", roomObj)
    return res
  }

  async getRoomById(roomID: string) {
    const res = this.httpClient.get("/api/room/roomID/" + roomID);
    return res;
  }
}