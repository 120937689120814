import { HttpClient } from "./HttpClient";
export class DepartmentService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async getCompanyDepartments(companyId: string, status: string) {
    const res = await this.httpClient.get(
      "/api/department/company/" + status + "/" + companyId
    );
    return res;
  }

  async getAllDeptInCompany(companyId: string) {
    const res = await this.httpClient.get(
      "/api/department/company/" + companyId
    );
    return res;
  }

  async getDeptAndCompanyNames(deptid: string) {
    const res = await this.httpClient.get(
      "/api/department/deptandcompany/" + deptid
    );
    return res;
  }

  async getAllDeptsInAllCompanies() {
    const res = await this.httpClient.get("/api/department/groupcompany/");
    return res;
  }
  async getDepartmentById(id: string) {
    const res = await this.httpClient.get("/api/department/" + id);
    return res;
  }

  async createDepartment(departmentObj: any) {
    const res = await this.httpClient.post(
      "/api/department/create",
      departmentObj
    );
    return res;
  }

  async updateDepartment(departmentObj: any) {
    const res = await this.httpClient.put(
      "/api/department/update",
      departmentObj
    );
    return res;
  }
  async deleteDepartmentById(departmentObj: any) {
    const res = await this.httpClient.delete(
      "/api/department/" + departmentObj.departmentID
    );
    return res;
  }

  async updateDepartmentStatus(departmentObj: any) {
    const res = await this.httpClient.put(
      "/api/department/status",
      departmentObj
    );
    return res;
  }
}
