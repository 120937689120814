import { HttpClient } from "./HttpClient";
export class NotificationsService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async getAllNotifications() {
    const res = await this.httpClient.get("/api/notifications");
    return res;
  }

  async getUnreadNotifications() {
    const res = await this.httpClient.get("/api/notifications/unread");
    return res;
  }

  async readNotification(notificationObj: { notificationID: string }) {
    const res = await this.httpClient.put(
      "/api/notifications/update",
      notificationObj
    );
    return res;
  }

  async readAllNotifications() {
    const res = await this.httpClient.get("/api/notifications/readall");
    return res;
  }

  async createNotification(notificationObjArr: {
    RecipientUserID: string;
    SenderUserID: string;
    Url: string;
    MsgHeader: string;
    Message: string;
  }[]) {
    const res = await this.httpClient.post(
      "/api/notifications/create",
      notificationObjArr
    );
    console.log('createNotification', res)
    return res;
  }

  // async createNotification(notificationObj: {
  //   RecipientUserID: string;
  //   Url: string;
  //   Message: string;
  // }) {
  //   const res = await this.httpClient.post(
  //     "/api/notifications/create",
  //     notificationObj
  //   );
  //   return res;
  // }


  // async email() {
  //   return await this.httpClient.get("/api/notifications/email");
  // }

}
