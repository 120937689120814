import React, { useEffect, useState } from "react";
import WeeklyDutiesService from "../../service/WeeklyDutiesService";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import { Redirect } from "react-router";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { ProgressSpinner } from "primereact/progressspinner";

//    __  __  ___  ___  ___ _
//   |  \/  |/ _ \|   \| __| |
//   | |\/| | (_) | |) | _|| |__
//   |_|  |_|\___/|___/|___|____|
//

export class WeeklyDutiesModel {
  public dutyID: string = CommonMethods.EmptyGuid;
  public dutyName: string = "";
  public dutyStatus: string = "";
  public employeesOnDutyID: string = "";
  public employeesOnDutyForDisplay: Array<string> = new Array<string>();
  public employeesOnDutyIDStrArr: string[] = [];
  public dutyStartDate: Date = new Date();
  public dutyEndDate: Date = new Date();
  public companyID: string = CommonMethods.EmptyGuid;
}
let weeklyDutiesService = new WeeklyDutiesService();
let commonMethods = new CommonMethods();

function WeeklyDuties(props: RouteComponentProps) {
  //Growls
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);
  // Loading, Error, Redirect
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [dialogStr, setDialogStr] = useState<string>("");
  // const [confirmStr, setConfirmStr] = useState<string>("");
  const [dialogHeader, setDialogHeader] = useState<string>("");
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [weeklyDutyObj, setWeeklyDutyObj] = useState<WeeklyDutiesModel>(
    new WeeklyDutiesModel()
  );
  const [searchValue, setSearchValue] = useState<string>("");
  // const [statusToBeUpdated, setStatusToBeUpdated] = useState<string>("");
  const [
    dutyStatusViewDropdownOptions,
    setDutyStatusViewDropdownOptions
  ] = useState<any[]>([]);
  const [dutyList, setDutyList] = useState<Array<WeeklyDutiesModel>>(
    new Array<WeeklyDutiesModel>()
  );
  const [dateSelection, setDateSelection] = useState<Date>();

  useEffect(() => {
    loadPage();
  }, []);

  const loadPage = () => {
    weeklyDutiesService
      .getAllWeeklyDutiesByGCID()
      .then(res => {
        setDutyList(res);
        setIsLoading(false);
      })
      .catch(err => {
        let errorMsg = commonMethods.getErrorMessage(err);
        setErrorMessage(errorMsg);
        setIsError(true);
        setIsLoading(false);
      });
    setDutyStatusViewDropdownOptions(
      commonMethods.getCampaignStatusViewDropdown()
    );
  };

  const confirmDelete = () => {
    weeklyDutiesService
      .deleteWeeklyDutyByID(weeklyDutyObj.dutyID)
      .then(res => {
        SetSuccess(weeklyDutyObj.dutyName + " has been deleted.");
        setDialogVisibility(false);
        setIsLoading(false);
        loadPage();
      })
      .catch(err => {
        const error = commonMethods.getErrorMessage(err);
        setIsLoading(false);
        SetError(error);
      });
  };

  const filterByDate = () => {
    if (dateSelection !== null && dateSelection!==undefined) {
      weeklyDutiesService
        .filterWeeklyDutiesByDate(dateSelection)
        .then(res => {
          setDutyList(res);
          setIsLoading(false);
        })
        .catch(err => {
          let errorMsg = commonMethods.getErrorMessage(err);
          setIsError(true);
          setIsLoading(false);
          setErrorMessage(errorMsg);
        });
    } else {
      SetError("Start date must be before end date!");
    }
  };

  const startDateTemplate = (rowData: WeeklyDutiesModel) => {
    return commonMethods.displayDate(new Date(rowData.dutyStartDate));
  };

  const endDateTemplate = (rowData: WeeklyDutiesModel) => {
    return commonMethods.displayDate(new Date(rowData.dutyEndDate));
  };

  const employeesOnDutyForDisplay = (rowData: WeeklyDutiesModel) => {
    if (rowData.employeesOnDutyForDisplay.length === 0) {
      return "-";
    }

    return rowData.employeesOnDutyForDisplay.map(
      (item: string, index: number) => {
        if (index === rowData.employeesOnDutyForDisplay.length - 1) {
          return item;
        } else {
          return item + ", ";
        }
      }
    );
  };

  const tableheader = (
    <div className="row mb-2">
      <div className="col text-md-left">
        <span className="p-input-icon-left mr-1 mb-1">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            onInput={(e: any) => setSearchValue(e.currentTarget.value)}
          />
        </span>
        <Dropdown
          placeholder="All Duty Status"
          value={searchValue}
          options={dutyStatusViewDropdownOptions}
          onChange={e => {
            setSearchValue(e.value);
          }}
        />
      </div>
      <div className="col text-md-right">
        <Calendar
          className="mb-1 mx-1"
          placeholder="Find Duties By Date"
          value={dateSelection}
          dateFormat="dd/mm/yy"
          onChange={e => setDateSelection(new Date(e.value.toString()))}
        />
        <Button
          label="Filter"
          onClick={() => {
            filterByDate();
          }}
          className="mx-1"
        />
        <Button
          label="Clear"
          onClick={() => {
            loadPage();
          }}
          className="mx-1"
        />
      </div>
    </div>
  );

  const actionTemplate = (rowData: WeeklyDutiesModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Edit Duty"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            setRedirectPath(
              "/admin/weekly_duties/update/" + rowData.dutyID
            );
            setRedirectOut(true);
          }}
        />
        <Button
          tooltip="Delete Duty"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setDialogVisibility(true);
            setWeeklyDutyObj(rowData);
            setDialogHeader("Delete Duty");
            setDialogStr(`Are you sure you want to delete this duty?`);
          }}
        />
      </div>
    );
  };

  let page = (
    <div
      data-tour-id="duty-masterlist"
      data-tour="Here is where you can add new duties and edit the employees on duty!"
    >
      {tableheader}
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          value={dutyList}
          paginator={true}
          rows={15}
          autoLayout={true}
          alwaysShowPaginator={false}
          emptyMessage="No duties found."
          globalFilter={searchValue}
        >
          <Column field="dutyName" header="Name" />
          <Column body={startDateTemplate} header="Start Date" className="pr-1" />
          <Column body={endDateTemplate} header="End Date" className="pl-1" />
          <Column
            body={employeesOnDutyForDisplay}
            header="On Duty"
            className="p-col-3"
          />
          <Column field="dutyStatus" header="Status" />
          <Column body={actionTemplate} header="Actions" className="p-1" />
        </DataTable>
        </div>
    </div>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <div className="row">
      {redirectOut ? <Redirect push to={{ pathname: redirectPath }} /> : null}
      <Dialog
        className="popupDialog"
        header={dialogHeader}
        visible={dialogVisibility}
        modal={true}
        //dismissableMask={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                setIsLoading(true);
                setDialogVisibility(false);
                confirmDelete();
                setIsLoading(true);
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>

      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">
            Weekly Duties
            <span
              className="pl-3"
              data-tour-id="add-duty"
              data-tour="Add a new duty with this button."
            >
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={e => {
                  setRedirectPath("/admin/weekly_duties/create");
                  setRedirectOut(true);
                }}
              />
            </span>
          </h1>
          <p>Assign employees to duties on a weekly basis. These will appear on the noticeboard. You may choose to ignore this module.</p>
          {display}
        </div>
      </div>
    </div>
  );
}

export default WeeklyDuties;
