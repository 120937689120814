import React, { useEffect, useRef } from 'react'
import { PostModel, PreviewProps, UserPreview } from '../../Model/PostModel'
import CompanyFeedService from '../../Service/CompanyFeedService'
import ProfilePreview from '../ProfilePreview/ProfilePreview'
import TimeStamp from '../TimeStamp'
import { useHistory } from "react-router"
import ProfilePicture from './ProfilePicture'
import ProfileName from './ProfileName'

export default function PostUserAvatar({
    item,
    previewData,
    setPreviewData,
    previewProps,
    setPreviewProps,
}: {
    item: PostModel;
    previewData: UserPreview | null
    setPreviewData: React.Dispatch<React.SetStateAction<UserPreview | null>>
    previewProps: PreviewProps
    setPreviewProps: React.Dispatch<React.SetStateAction<PreviewProps>>
}) {

    const clearPreviewTimer = useRef<ReturnType<typeof setTimeout> | null>(null)
    const showPreviewTimer = useRef<ReturnType<typeof setTimeout> | null>(null)
    const postAuthorNameRef = useRef<HTMLDivElement>(null)
    const history = useHistory();

    useEffect(() => {
        return () => {
            if (showPreviewTimer.current) clearTimeout(showPreviewTimer.current)
            if (clearPreviewTimer.current) clearTimeout(clearPreviewTimer.current)
        }
    }, [])

    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }

    async function getAndSetPreviewData(item: PostModel) {
        if (previewProps.cachePreview.length > 0) {
            let foundCache = previewProps.cachePreview.find(preview => preview.userId === item.userId)
            if (foundCache) {
                await timeout(300)
                setPreviewData(foundCache)
                return
            }
        }
        const companyFeedService = new CompanyFeedService()
        const result = await companyFeedService.getUserPreview(item.userId)
        setPreviewData(result.data)
        setPreviewProps(state => (
            { ...state, cachePreview: [...state.cachePreview, result.data] }
        ))

    }

    async function setPreviewStates(item: PostModel) {
        await getAndSetPreviewData(item)
        if (postAuthorNameRef.current) {
            const position = postAuthorNameRef.current.getBoundingClientRect()
            setPreviewProps(state => (
                { ...state, previewPosition: position, loadPreviewIdentifier: item.postId }
            ))
        }
    }

    function clearPreviewStates() {
        setPreviewData(null)
        setPreviewProps(state => (
            { ...state, previewPosition: null, loadPreviewIdentifier: "" }
        ))
    }

    async function mouseEnterPic(item: PostModel) {
        if (clearPreviewTimer.current) clearTimeout(clearPreviewTimer.current)
        const enterTimer = setTimeout(async () => { setPreviewStates(item) }, 250)
        showPreviewTimer.current = enterTimer
    }

    async function mouseEnterName(item: PostModel) {
        if (clearPreviewTimer.current) clearTimeout(clearPreviewTimer.current)
        const enterTimer = setTimeout(async () => { setPreviewStates(item) }, 250)
        showPreviewTimer.current = enterTimer
    }

    function mouseLeavePic() {
        if (showPreviewTimer.current) clearTimeout(showPreviewTimer.current)
        const leaveTimer = setTimeout(() => { clearPreviewStates() }, 200)
        clearPreviewTimer.current = leaveTimer
    }

    function mouseLeaveName() {
        if (showPreviewTimer.current) clearTimeout(showPreviewTimer.current)
        const leaveTimer = setTimeout(() => { clearPreviewStates() }, 200)
        clearPreviewTimer.current = leaveTimer
    }

    function mouseEnterPreview() {
        if (clearPreviewTimer.current) clearTimeout(clearPreviewTimer.current)
    }

    function mouseLeavePreview() {
        if (showPreviewTimer.current) clearTimeout(showPreviewTimer.current)
        const leaveTimer = setTimeout(() => { clearPreviewStates() }, 200)
        clearPreviewTimer.current = leaveTimer
    }

    async function enterProfile(item: PostModel) {
        history.push(`/publicprofile/${item.userId}`, { userId: item.userId }); // navigate to route
    }

    return (
        <div className="useravatar-parent">
            <div
                onMouseEnter={() => mouseEnterPic(item)}
                onMouseLeave={mouseLeavePic}
                onClick={() => enterProfile(item)}
            >
                <ProfilePicture displayPicture={item.displayPicture} />
            </div>
            <div className="margin-left-6px">
                <div
                    ref={postAuthorNameRef}
                    onMouseEnter={() => mouseEnterName(item)}
                    onMouseLeave={mouseLeaveName}
                    onClick={() => enterProfile(item)}
                >
                    <ProfileName item={item} />
                </div>
                <TimeStamp post={item} />
            </div>
            {/* TODO: Should replace profilePreview with TippyJs, I did my own implementation of ProfilePreview and only discovered TippyJs later */}
            {/* Look at Tippy in PostDraftJsEditor.tsx and CommentDraftJsEditor.tsx for example, it is used for the @Mentions profile preview */}
            <div onMouseEnter={mouseEnterPreview} onMouseLeave={mouseLeavePreview}>
                <ProfilePreview previewData={previewData} previewProps={previewProps} parentId={item.postId} />
            </div>
        </div>
    )
}
