import { HttpClient } from "./HttpClient";
import { PaymentTermsModel } from "../components/campaign/PaymentTerms"


export class PaymentTermsService {
    httpClient: HttpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }
 
    
    async createPaymentTerms(paymentTermsObj: PaymentTermsModel)
    {
        const res = await this.httpClient.post(
            "/api/paymentterms/create",
            paymentTermsObj
        )
        return res

    }

    async getAllPaymentTermsByGCID()
    {
        const res = await this.httpClient.get(
            "/api/paymentterms/gcid")
        return res
    }

    async getPaymentTermsByID(id: string)
    {
        const res = await this.httpClient.get(
            "/api/paymentterms/id/" + id
        )
        return res;
    }

    async deletePaymentTermsByID(id: string)
    {
        const res = await this.httpClient.delete(
            "/api/paymentterms/id/" + id
        )
        return res;
    }
    async updatePaymentTerms(paymentTermsObj: PaymentTermsModel)
    {
        const res = await this.httpClient.post(
            "/api/paymentterms/update",
            paymentTermsObj
        )
        return res

    }

    async deactivatePaymentTerms(ID: string) {
        const res = this.httpClient.get("/api/paymentterms/deactivate/" + ID);
        return res;
      }
    async activatePaymentTerms(ID: string) {
        const res = this.httpClient.get("/api/paymentterms/activate/" + ID);
        return res;
      }
    async deletePaymentTerms(ID: string) {
        const res = this.httpClient.get("/api/paymentterms/delete/" + ID);
        return res;
    }
    
    
  async updatePaymentTermsStatus(paymentTermsObj: PaymentTermsModel) {
    const res = this.httpClient.put("/api/paymentterms/updatestatus", paymentTermsObj)
    return res
  }

}

export default PaymentTermsService;