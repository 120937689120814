import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import React from "react";
import { NotificationsService } from "../../service/NotificationsService";

function SidebarNotifications(props: {
  header: any;
  content: React.ReactNode;
  url: string | undefined;
  notificationID: string
}) {
  let notificationsService = new NotificationsService();
  // const [hide, setHide] = useState(false);
  const onClose = (notificationID: string) => {
    notificationsService.readNotification({notificationID: notificationID}).then(res => {    
      // setHide(true);
      window.location.replace(props.url!)
    });
  };
  
  return (
    <div className="my-2">
      <Panel header={props.header}>
        {props.content}
        <div className="p-grid">
          <div className="p-col">
            <Button className="float-right buttonAsLink" onClick={() => onClose(props.notificationID)} label ="View"> </Button>
            {/* <a className="float-right" href="" onClick={() => onClose(props.notificationID)}>
              View
            </a> */}
          </div>
        </div>
      </Panel>
    </div>
  );
}

export default SidebarNotifications;
