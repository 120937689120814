import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import * as React from "react";
import { Redirect } from "react-router";
import { SalesService } from "../../service/SalesService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import {
  ClientContactsModel,
  SalesClientsModel
} from "../resources/ExportClass";
import { ToastStateContext } from "../resources/ToastContext";

interface AppState {
  salesClient: SalesClientsModel;
  clientContact: ClientContactsModel;

  clientCompanies: { label: string; value: string }[];

  isLoading: boolean;

  isError: boolean;
  errorMsg: string;

  redirectOut: boolean;
  redirectPath: string;

  title: any[];
  leadStatusDropdown: any[];

  clientCompanySelected: boolean;
  clientContactDropdown: any[];

  salesPersonDropdown: any[];
}

export class SalesClientCreate extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;
  commonMethods: CommonMethods;
  salesService: SalesService;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      salesClient: new SalesClientsModel(),
      clientContact: new ClientContactsModel(),

      clientCompanies: [],

      isLoading: true,

      isError: false,
      errorMsg: "",

      redirectOut: false,
      redirectPath: "",

      title: [],
      leadStatusDropdown: [],
      clientCompanySelected: false,

      clientContactDropdown: [],
      salesPersonDropdown: []
    };
    this.commonMethods = new CommonMethods();
    this.salesService = new SalesService();

    // this.insertNewClient = this.insertNewClient.bind(this);
    this.insertLeadStatus = this.insertLeadStatus.bind(this);
    this.getSalesPersonDropdown = this.getSalesPersonDropdown.bind(this);
  }

  componentDidMount() {
    this.setState({
      title: this.commonMethods.getSalutationDropdownList(),
      leadStatusDropdown: this.commonMethods.getLeadStatusDropdown()
    });

    this.getClientCompanyDropdown();

    if (
      this.props.match.params !== undefined &&
      this.props.match.params !== {} &&
      this.props.match.params.company !== undefined &&
      this.props.match.params.contact !== undefined
    ) {
      this.setState(
        {
          clientCompanySelected: true,
          salesClient: {
            ...this.state.salesClient,
            clientContactsID: this.props.match.params.contact
          },
          clientContact: {
            ...this.state.clientContact,
            clientCompanyID: this.props.match.params.company
          }
        },
        () => {
          this.getClientContactDropdown(this.props.match.params.company);
        }
      );
    }
    this.getSalesPersonDropdown();
  }
  componentDidUpdate() {}

  getSalesPersonDropdown() {
    this.commonMethods
      .getUserDropdownForGroupCompany()
      .then(res => {
        this.setState({
          salesPersonDropdown: res,
          salesClient: {
            ...this.state.salesClient,
            salesPersonID: this.props.userProfile.userID
          }
        });
      })
      .catch(err => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({ isError: true, isLoading: false, errorMsg: errorMsg });
      });
  }

  getClientCompanyDropdown() {
    this.commonMethods
      .getClientDropdown()
      .then(res => {
        this.setState({
          clientCompanies: res,
          isLoading: false
        });
      })
      .catch(err => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({ isError: true, isLoading: false, errorMsg: errorMsg });
      });
  }

  // insertNewClient() {
  //   this.salesService
  //     .insertNewClientContact(this.state.clientContact)
  //     .then((res: ClientContactsModel) => {
  //       this.setState(
  //         {
  //           clientContact: res,
  //           salesClient: {
  //             ...this.state.salesClient,
  //             clientContactsID: res.clientContactsID
  //           }
  //         },
  //         () => {
  //           this.insertLeadStatus();
  //         }
  //       );
  //     })
  //     .catch(err => {
  //       let msg = "Client cannot be added!";
  //       this.context.SetError(msg);
  //     });
  // }

  insertLeadStatus() {
    this.setState({ isLoading: true }, () => {
      this.salesService
        .insertNewSalesClient(this.state.salesClient)
        .then(res => {
          let msg = "Client has been added!";
          this.context.SetSuccess(msg);
          this.setState({
            redirectOut: true,
            redirectPath:
              "/sales/clientcontacts/" +
              this.state.clientContact.clientCompanyID
          });
        })
        .catch(err => {
          let msg = "Client cannot be added!";
          this.context.SetError(msg);
        });
    });
  }

  getClientContactDropdown(id: string) {
    this.commonMethods.getClientContactDropdown(id).then(res => {
      this.setState({ clientContactDropdown: res });
    });
  }

  render() {
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath
          }}
        />
      );
    }

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />; // or some growl/message that stays
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = (
        <>
          {/* Add Client Contact */}
          {/* <div className="card">
            <h1 className="pb-2">Add New Client</h1>
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <span className="boldLabel">Client Company</span>
                <Dropdown
                  placeholder={"Client Company"}
                  value={this.state.clientContact.clientCompanyID}
                  options={this.state.clientCompanies}
                  onChange={e => {
                    this.setState({
                      clientContact: {
                        ...this.state.clientContact,
                        clientCompanyID: e.value
                      }
                    });
                  }}
                  disabled={this.state.clientCompanySelected}
                  required
                />
              </div>
              <div className="p-col-12">
                <span className="boldLabel">Salutation</span>
                <Dropdown
                  placeholder={"Salulation"}
                  value={this.state.clientContact.title}
                  options={this.state.title}
                  onChange={e => {
                    this.setState({
                      clientContact: {
                        ...this.state.clientContact,
                        title: e.value
                      }
                    });
                  }}
                  required
                />
              </div>
              <div className="p-col-12">
                <span className="boldLabel">Client Name</span>
                <InputText
                  value={this.state.clientContact.contactName}
                  onChange={(e: any) => {
                    this.setState({
                      clientContact: {
                        ...this.state.clientContact,
                        contactName: e.target.value
                      }
                    });
                  }}
                  required
                />
              </div>
              <div className="p-col-12">
                <span className="boldLabel">Phone Number</span>
                <InputText
                  value={this.state.clientContact.contactNumber}
                  onChange={(e: any) => {
                    this.setState({
                      clientContact: {
                        ...this.state.clientContact,
                        contactNumber: e.target.value
                      }
                    });
                  }}
                  keyfilter="num"
                  required
                />
              </div>

              <div className="p-col-12">
                <span className="boldLabel">E-mail</span>
                <InputText
                  value={this.state.clientContact.contactEmail}
                  onChange={(e: any) => {
                    this.setState({
                      clientContact: {
                        ...this.state.clientContact,
                        contactEmail: e.target.value
                      }
                    });
                  }}
                  keyfilter="email"
                  required
                />
              </div>

              <div className="p-col-12">
                <span className="boldLabel">Designation</span>
                <InputText
                  value={this.state.clientContact.designation}
                  onChange={(e: any) => {
                    this.setState({
                      clientContact: {
                        ...this.state.clientContact,
                        designation: e.target.value
                      }
                    });
                  }}
                  required
                />
              </div>
              <div className="p-col-12">
                <span className="boldLabel">Remarks</span>
                <InputTextarea
                  rows={5}
                  cols={30}
                  value={this.state.clientContact.remarks}
                  onChange={e =>
                    this.setState({
                      clientContact: {
                        ...this.state.clientContact,
                        remarks: e.currentTarget.value
                      }
                    })
                  }
                  autoResize={true}
                />
              </div>
            </div>
          </div> */}
          {/* // Add Client Contact */}

          {/* Tie Client to Sales */}
          <div className="card">
            <h1 className="pb-2">Add Sales Lead</h1>
            <form onSubmit={this.insertLeadStatus}>
              <div className="p-grid p-fluid">
                <div className="p-col-12">
                  <span className="boldLabel">Client Company</span>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <Dropdown
                    placeholder="Please Select"
                    value={this.state.clientContact.clientCompanyID}
                    options={this.state.clientCompanies}
                    onChange={e => {
                      this.getClientContactDropdown(e.value);
                      this.setState({
                        clientContact: {
                          ...this.state.clientContact,
                          clientCompanyID: e.value
                        }
                      });
                    }}
                    disabled={this.state.clientCompanySelected}
                    required
                  />
                </div>

                <div className="p-col-12">
                  <span className="boldLabel">Client Contact</span>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <Dropdown
                    placeholder="Please Select"
                    value={this.state.salesClient.clientContactsID}
                    options={this.state.clientContactDropdown}
                    disabled={this.state.clientCompanySelected}
                    onChange={e => {
                      this.setState({
                        salesClient: {
                          ...this.state.salesClient,
                          clientContactsID: e.value
                        }
                      });
                    }}
                    required
                  />
                </div>
                <div className="p-col-12">
                  <span className="boldLabel">Sales Person</span>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <Dropdown
                    placeholder="Please Select"
                    value={this.state.salesClient.salesPersonID}
                    options={this.state.salesPersonDropdown}
                    onChange={e => {
                      // this.getClientContactDropdown(e.value);
                      this.setState({
                        salesClient: {
                          ...this.state.salesClient,
                          salesPersonID: e.value
                        }
                      });
                    }}
                    required
                  />
                </div>
                <div className="p-col-12">
                  <span className="boldLabel">Lead Status</span>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <Dropdown
                    placeholder="Please Select"
                    options={this.state.leadStatusDropdown}
                    value={this.state.salesClient.leadsStatus}
                    onChange={e => {
                      this.setState({
                        salesClient: {
                          ...this.state.salesClient,
                          leadsStatus: e.value
                        }
                      });
                    }}
                    required
                  />
                </div>

                <div className="p-col-12">
                  <span className="boldLabel">Meeting Date</span>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <Calendar
                    showButtonBar={true}
                    selectionMode="single"
                    todayButtonClassName="p-justify-center"
                    
                    value={new Date(this.state.salesClient.startDate)}
                    onChange={e => {
                      this.setState({
                        salesClient: {
                          ...this.state.salesClient,
                          startDate: new Date(e.value.toString()).toISOString()
                        }
                      });
                    }}
                    inline={false}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                {/* <div className="p-col-12">
                <span className="boldLabel">End Date</span>
                <Calendar
                  showButtonBar={true}
                  selectionMode="single"
                  todayButtonClassName="p-justify-center"
                  
                  value={new Date(this.state.salesClient.endDate)}
                  onChange={e => {
                    this.setState({
                      salesClient: {
                        ...this.state.salesClient,
                        endDate: new Date(e.value.toString()).toISOString()
                      }
                    });
                  }}
                  inline={false}
                  minDate={new Date(this.state.salesClient.startDate)}
                />
              </div> */}

                <div className="p-col-12">
                  <span className="boldLabel">Remarks</span>
                  <InputTextarea
                    rows={5}
                    cols={30}
                    value={this.state.salesClient.remarks}
                    onChange={e =>
                      this.setState({
                        salesClient: {
                          ...this.state.salesClient,
                          remarks: e.currentTarget.value
                        }
                      })
                    }
                    autoResize={true}
                  />
                </div>
              </div>
              <div className="p-grid p-justify-center pt-2">
                <Button label="Submit" />
              </div>
            </form>
          </div>
          {/* // Tie Client to Sales */}
        </>
      );
    }

    // FOR REDIRECT
    return (
      <>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            {/* <Link to="/sales/view" style={{ color: "black" }}>
              <i className="pi pi-angle-left" />
              Back
            </Link> */}
            {display}
          </div>
        </div>
      </>
    );
  }
}
