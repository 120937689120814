import * as React from "react";
import * as H from "history";
import errorImg from "../../assets/images/404_image.jpg";

interface AppProps {
  message: string;
  location?: H.Location<{ errorMsg: string }>;
}

interface AppState {
  errorMsg: string;
}

export default class CustomError extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      errorMsg: "",
    };
  }
  componentDidMount() {
    if (this.props.message !== undefined && this.props.message !== null) {
      this.setState({ errorMsg: this.props.message });
    } else if (
      this.props.location !== undefined &&
      this.props.location.state !== undefined
    ) {
      this.setState({ errorMsg: this.props.location.state.errorMsg });
    } else {
      this.setState({ errorMsg: "" });
    }
  }
  render() {
    return (
      <div className="card p-4">
        <div className="text-center">
          <img src={errorImg} width="50%" alt="404 ERROR" className="mb-4" />
          <h4>
            <b>Oops! Something went wrong</b>
          </h4>
          <h5>{this.state.errorMsg}</h5>
          <p>
            The page you are looking for might have been moved, removed, renamed
            or never existed.
          </p>
          <p>
            If that's not the case, refresh the page or re-login to try again.
          </p>
          <br />
        </div>
      </div>
    );
  }
}
