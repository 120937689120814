import * as React from "react";
import { PayslipService } from "../../service/PayslipService";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PayrollHistory } from "../resources/ExportClass";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { Button } from "primereact/button";

interface AppState {
  isFetching: boolean;
  payslips: Array<PayrollHistory>;

  isError: boolean;
  errorMsg: string;
}

export class PayslipUserAll extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  payslipService: PayslipService;
  commonMethods: CommonMethods;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      isFetching: true,
      payslips: new Array<PayrollHistory>(),

      isError: false,
      errorMsg: ""
    };
    this.payslipService = new PayslipService();
    this.commonMethods = new CommonMethods();

    this.getUserPayslipHistory = this.getUserPayslipHistory.bind(this);
    this.viewPDF = this.viewPDF.bind(this);
  }

  componentDidMount() {
    if (this.commonMethods.isPropsLoaded(this.props) && this.state.isFetching) {
      this.getUserPayslipHistory();
    }
  }

  getUserPayslipHistory() {
    this.payslipService
      .getAllPayslipsForUser()
      .then(res => {
        this.setState({ payslips: res, isFetching: false });
      })
      .catch(err => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({
          isError: true,
          isFetching: false,
          errorMsg: errorMsg
        });
        return errorMsg;
      });
  }

  payslipYear(rowData: PayrollHistory) {
    var date = new Date(rowData.generatedDate);
    var datestring: string = date.getFullYear().toString();
    return datestring;
  }

  viewPDF(rowData: PayrollHistory) {
    return (
      <Button
        type="button"
        icon="pi pi-external-link"
        className="button-document"
        onClick={() => {
          window.open(
            rowData.uploadPath,
            "View Payslip",
            "width=560,height=900,toolbar=0,menubar=0,location=0"
          );
        }}
        disabled={rowData.uploadPath !== null ? false : true}
      />
    );
  }

  // RENDER
  render() {
    const { isFetching, isError } = this.state;

    let page = (
      <div data-tour-id="user-payslips" data-tour="This page holds all your past payslips. You may download the PDF if you wish.">
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={this.state.payslips}
            paginator={true}
            rows={15}
            autoLayout={true}
            alwaysShowPaginator={false}
            emptyMessage="No results found."
          >
            <Column field="generatedForMonth" header="Month" />
            <Column body={this.payslipYear} header="Year" />
            <Column body={this.viewPDF} header="PDF" style={{ width: "5em" }} />
          </DataTable>
        </div>
      </div>
    );
    let display;
    if (isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (isFetching) {
      display = <ProgressSpinner />;
    } else {
      display = page;
    }

    return (
      <div className="row">
        <div className="col-12 col-xl-10">
          <div className="card">
            <h1 className="pb-2"> Payslips </h1>
            {display}
          </div>
        </div>
      </div>
    );
  }
}
