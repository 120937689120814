import moment from "moment";
import { CommonMethods } from "../components/resources/CommonMethods";
import { HttpClient } from "./HttpClient";
import { convertObjToFormData } from "../components/resources/CommonMethodsServices";
import { ClaimsModel } from "../components/claims/ClaimsModel";
export class ClaimsService {
  httpClient: HttpClient;
  commonMethods: CommonMethods;

  constructor() {
    this.httpClient = new HttpClient();
    this.commonMethods = new CommonMethods();
  }

  // async viewMyClaim() {
  //   return await this.httpClient.get("/api/claims");
  // }

  async viewMyPendingClaims() {
    let res = await this.httpClient.get("/api/claims/mypending");
    return res;
  }

  async viewMyNotPendingClaims() {
    let res = await this.httpClient.get("/api/claims/myhistory");
    return res;
  }

  async getClaimByID(id: string) {
    let res = await this.httpClient.get("/api/claims/id/" + id);
    return res;
  }

  async getStaffBenefitClaimsByUserID(userid: string) {
    let res = await this.httpClient.get("/api/claims/userbenefits/" + userid);
    return res;
  }

  async getMyPendingClaimsCount() {
    return await this.httpClient.get("/api/claims/count/mypending");
  }

  //endorse claims
  async getClaimsAsEndorser() {
    let res = await this.httpClient.get("/api/claims/toendorse");
    return res;
  }

  async endorsedHistory() {
    let res = await this.httpClient.get("/api/claims/endorsedHistory");
    return res;
  }

  // approve claims
  async getClaimsAsApprover(companyID: string) {
    let res = await this.httpClient.get("/api/claims/toapprove/" + companyID);
    return res;
  }

  async getAllClaimsHistoryAsAdmin(companyID: string) {
    let res = await this.httpClient.get(
      "/api/claims/adminHistory/" + companyID
    );
    return res;
  }

  // approvingHistory == pendingProcessing
  async approvingHistory(companyID: string) {
    let res = await this.httpClient.get("/api/claims/toprocess/" + companyID);
    return res;
  }

  processCreatedDateClaimsArr(res: any) {
    // convert created time to local time
    res.forEach((claimItem: any) => {
      // if (claimItem.createdDate !== null) {
      //   claimItem = this.commonMethods.updateProperty(
      //     "createdDate",
      //     this.commonMethods.convertDateToLocalTime(claimItem.createdDate),
      //     claimItem
      //   );
      // }
      // if (claimItem.approvedDate !== null) {
      //   claimItem = this.commonMethods.updateProperty(
      //     "approvedDate",
      //     this.commonMethods.convertDateToLocalTime(claimItem.approvedDate),
      //     claimItem
      //   );
      // }
      // if (claimItem.endorsedDate !== null) {
      //   claimItem = this.commonMethods.updateProperty(
      //     "endorsedDate",
      //     this.commonMethods.convertDateToLocalTime(claimItem.endorsedDate),
      //     claimItem
      //   );
      // }
      // if (claimItem.lastUpdated !== null) {
      //   claimItem = this.commonMethods.updateProperty(
      //     "lastUpdated",
      //     this.commonMethods.convertDateToLocalTime(claimItem.lastUpdated),
      //     claimItem
      //   );
      // }
    });
    return res;
  }

  async getClaimDataWidget(currency: string) {
    return await this.httpClient.get("/api/claims/monthlycost/" + currency);
  }

  async createClaim(claimObj: any) {
    var form_data = new FormData();
    for (var key in claimObj) {
      if (key.includes("Date")) {
        let utcDate = moment(claimObj[key]).format(CommonMethods.DateForBackendStringFormat);
        form_data.append(key, utcDate);
      } else if (key === "campaignID") {
        let campaignIDVals = claimObj[key] === null? CommonMethods.EmptyGuid : claimObj[key];
        form_data.append(key, campaignIDVals);
      } else if (key === "receiptPathFile") {
        claimObj.receiptPathFile.forEach((element: any) => {
          form_data.append(key, element);
        });
      } else {
        form_data.append(key, claimObj[key]);
      }
    }

    // const res = await this.httpClient.post("/api/campaign/create", convertObjToFormData(claimObj));
    const res = await this.httpClient.post("/api/claims/create", form_data);
    return res;
  }

  async updateClaim(claimObj: any) {
    const res = await this.httpClient.put(
      "/api/claims/update",
      claimObj
    );
    return res;
  }

  async getClaimReport(claimObj: any) {
    const res = await this.httpClient.put("/api/claims/report", claimObj);
    return res;
  }

  async approveClaim(claimObj: any) {
    return await this.httpClient.put("/api/claims/approve", claimObj);
  }

  async rejectClaim(claimObj: any) {
    return await this.httpClient.put("/api/claims/reject", claimObj);
  }

  async endorseClaim(claimObj: any) {
    return await this.httpClient.put("/api/claims/endorse", claimObj);
  }

  async cancelClaim(claimObj: any) {
    return await this.httpClient.put("/api/claims/cancel", claimObj);
  }

  async batchProcessClaims(claimsArray: string[]) {
    return await this.httpClient.put("/api/claims/batchprocess", claimsArray);
  }
}
