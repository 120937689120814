import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import CustomError from "./Error";
import { UserProfileModel } from "./ExportClass";
import { AdminDashboardBreadCrumbs } from "./AdminDashboardBreadcrumbs";
import { MenuItem } from "./MenuList";
import { CommonMethods } from "./CommonMethods";

interface OuterclassProps {
  userProfile: UserProfileModel;
  routeProps: RouteComponentProps<any>;
  component: React.ReactType;
  menuModule: MenuItem;
  strictCheck: boolean;
}

// Checks if authorized before rendering component
export function Outerclass(props: OuterclassProps) {
  const [display, setDisplay] = useState<any>(<div className="text-center"><ProgressSpinner /></div>);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [unauthorized, setUnauthorized] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>("Unauthorized access");
  const commonMethods = new CommonMethods();

  // Strict check if authorized to render the page
  useEffect(() => {
    if (props.strictCheck) {
      isAuthorized(props.userProfile, props.menuModule.permission)
        .then((res) => (res ? setAuthorized(res) : setUnauthorized(!res)))
        .catch((err) => {
          setUnauthorized(true);
          setErrMsg(commonMethods.getErrorMessage(err));
        });
    } else {
      // set localstorage companyid back to default
      localStorage.setItem(
        "currentAccessingCompany",
        props.userProfile.companyID
      );
      setAuthorized(true);
    }
  }, []);

  // Display accordingly
  useEffect(() => {
    if (authorized) {
      setDisplay(
        <props.component
          {...props.routeProps}
          userProfile={props.userProfile}
        />
      );
    }

    if (unauthorized) {
      setDisplay(<CustomError message={errMsg} />);
    }
  }, [authorized, unauthorized]);

  return (
    <>
      {AdminDashboardBreadCrumbs()}
      {display}
    </>
  );
}

async function isAuthorized(
  userProfile: UserProfileModel,
  permission: Array<string> | undefined
) {
  const commonMethods = new CommonMethods();
  if (permission === undefined) {
    return false;
  }

  if (userProfile.taskList.some((v: any) => permission.includes(v))) {
    const companyID =
      localStorage.getItem("currentAccessingCompany") || userProfile.companyID;
    return await commonMethods
      .strictCheck(userProfile, companyID, permission)
      .then((res) => {
        return res ? true : false;
      });
  }
  return false;
}
