import React, { useEffect, useState } from "react";
import { UserService } from "../../service/UserService";
import { useParams, useHistory } from "react-router";
import { ParamIDTypes } from "../resources/CommonMethods";
import { UserModel } from "../user/User";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { BusinessCardModel, SocialMediaModel } from "./BusinessCardModel";
import CustomError from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";
import { hexToCSSFilter } from "hex-to-css-filter";
import { Helmet } from "react-helmet";


export default function BusinessCard() {

	const userService = new UserService();
	const { id } = useParams<ParamIDTypes>();
	const history = useHistory();
	const [userDetails, setUserDetails] = useState<UserModel>(new UserModel);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isError, setIsError] = useState<boolean>(false);
	const [errorMsg, setErrorMessage] = useState<string>(
		"An unidentified error has occurred"
	);
	const [displayMobile, setDisplayMobile] = useState<boolean>(false);
	const [businessCardObj, setBusinessCardObj] = useState<BusinessCardModel>(new BusinessCardModel);
	const [cssFilter, setCSSFilter] = useState<string>("");
	const [dialCode, setDialCode] = useState<string>("");
    const [mobileNumber, setMobileNumber] = useState<string>("");

	const links = [
		{
			name: "twitter",
			url:"https://www.twitter.com/",
			icon: "/assets/social-media-icons/Twitter.svg"
		},
		{
			name: "facebook",
			url: "https://www.facebook.com/",
			icon: "/assets/social-media-icons/Facebook.svg",
		},
		{
			name: "instagram",
			url: "https://www.instagram.com/",
			icon: "/assets/social-media-icons/Instagram.svg",
		},
		{
			name: "tiktok",
			url: "https://www.tiktok.com/",
			icon: "/assets/social-media-icons/TikTok.svg",
		},
		{
			name: "linkedin",
			url: "https://www.linkedin.com/in/",
			icon: "/assets/social-media-icons/LinkedIn.svg",
		},
		{
			name: "spotify",
			url: "https://open.spotify.com/",
			icon: "/assets/social-media-icons/Spotify.svg",
		},
		{
			name: "twitch",
			url: "https://www.twitch.tv/",
			icon: "/assets/social-media-icons/Twitch.svg",
		},
		{
			name: "youtube",
			url: "https://www.youtube.com/",
			icon: "/assets/social-media-icons/Youtube.svg",
		},
		{
			name: "telegram",
			url: "https://t.me/",
			icon: "/assets/social-media-icons/Telegram.svg",
		},
	]

	useEffect(() => {
		businessCardDetails();
	}, []);



	const businessCardDetails = () => {
		userService
			.getBusinessCardDetails(id)
			.then((res: UserModel) => {
				// history.push("/businesscard/" + res.firstName + "-" + res.lastName);
				setUserDetails(res);

				let json = new BusinessCardModel();
				if (res.businessCardJson != null) {
					json = JSON.parse(res.businessCardJson);
				}
				let stringSplit = res.pP_MobileNumber.split(" ");
				console.log(stringSplit);
				if (stringSplit.length > 1){
					setDialCode(stringSplit[0]);
					setMobileNumber(stringSplit[1]);
				} else {
					setDialCode("+65");
					setMobileNumber(res.pP_MobileNumber);
				}
				setBusinessCardObj(json);
				let filter = (hexToCSSFilter("#" + json.contactColour).filter).slice(0,-1);
				setCSSFilter(filter);
			})
			.catch((err) => {
				setErrorMessage("Error retrieving business card details");
				setIsError(true);
			})
			.finally(() => {
				setIsLoading(false);
			})
	}

	const numberSpacePadding = (obj: string) => {
		var res = "";
		var savei = 0
		if (mobileNumber.length > 4){
			for (let i = obj.length-1; i > 3; i-=4) {
				savei = i-4;
				res = " " + obj.slice(i-3, i+1) + res;
			}
			res = obj.slice(0, savei+1) + res;
			return res;
		} else {
			return obj;
		}
	}

	const fullURL = (link: SocialMediaModel) => {
		var object = links.find(obj => obj.name === link.name);
		return object?.url + link.url;
	}

	// const emailWrapper = (email: string) => {
	// 	if (email.length < 25) {
	// 		return (
	// 			<p className="m-0">{email}</p>
	// 		)
	// 	} else {
	// 		let split = email.split("@");
	// 		return (
	// 			<>
	// 				<p className="m-0">{split[0]}</p>
	// 				<p className="m-0">{"@" + split[1]}</p>
	// 			</>
	// 		)
	// 	}
	// }

	const userCompany = () => {
		let baseString = "/assets/company-logos/";
		switch(userDetails.companyName) {
		  case "Eatbook Pte. Ltd":
			return baseString + "Eatbook.svg";
			break;
		  case "Zula Pte. Ltd.":
			return baseString + "Zula.svg";
			break;
		  case "MS News Pte. Ltd.":
			return baseString + "MSNews.svg";
			break;
		  default:
			return baseString + "TSL.svg";
			break;
		}
	}

	const iconSource = (icon: string) => {
		if (icon == "Tiktok") {
			icon = "TikTok";
		} else if (icon == "Linkedin") {
			icon = "LinkedIn";
		}

		return "/assets/social-media-icons/" + icon + ".svg"
	}

	let display = (
		// bootstrap only allows intervals of 25% for height
		<div className="w-100 h-100 pt-4" style={{backgroundColor: "#" + businessCardObj.bgColour}}>
			<Helmet>
				<style>{`body { background-color: ${"#" + businessCardObj.bgColour} !important; }`}</style>
				<meta name="theme-color" content={businessCardObj.bgColour} />
			</Helmet>
			<div className="container textalign-center pt-5 position-relative" style={{height: "95%"}}>
				<div>
				<img src={userDetails.displayPicture} alt="" className="businessCardDisplay" />

					{/* all h1-h6 has an overwritten color in overrides.scss. Don't want to change it in case, so we'll be creating a new class instead */}
					<p className="m-0" style={{color: "#" + businessCardObj.nameTitleColour, fontSize: "20px", fontFamily: "ObjectSans-Bold,sans-serif"}}>{userDetails.firstName + " " + userDetails.lastName}</p>
					<p className="m-0" style={{color: "#" + businessCardObj.nameTitleColour, fontFamily: "ObjectSans-Regular,sans-serif"}}>{userDetails.jobTitle}</p>
				</div>

				{/* Have to individually add the column sizing, column sizing added to parent element will screw up the center position */}
				<div className="my-5">
					<div>
						<Button className="p-button-outlined mx-0 col-10 col-sm-7 col-md-5 col-lg-4" style={{borderRadius: "15px", fontFamily: "ObjectSans-Bold,sans-serif", borderColor: "#" + businessCardObj.contactColour}}>
							<span className="textalign-start" style={{fontSize:"12px", color: "#" + businessCardObj.contactColour}}>E</span>
							<span className="w-100 px-2">
								<a href={"mailto:" + userDetails.email} style={{fontSize:"12px", color: "#" + businessCardObj.contactColour, overflowWrap:"anywhere"}}>
									{userDetails.email}
								</a>
							</span>
							<Button
								type="button"
								icon="pi pi-copy"
								onClick={() => {
									navigator.clipboard.writeText(userDetails.email)}
								}
								className="p-0 border-0 bg-transparent"
								style={{color: "#" + businessCardObj.contactColour, width: "auto"}}
							/>
							</Button>
					</div>
					{(userDetails.pP_MobileNumber && businessCardObj.displayMobile) &&
						<div>
							<Button className="p-button-outlined mx-0 col-10 col-sm-7 col-md-5 col-lg-4" style={{marginTop: "0.45rem", borderRadius: "15px", fontFamily: "ObjectSans-Bold,sans-serif", borderColor: "#" + businessCardObj.contactColour}}>
								<span className="textalign-start" style={{fontSize:"12px", color: "#" + businessCardObj.contactColour}}>M</span>
								<span className="w-100 px-2"><a href={"tel:" + dialCode + mobileNumber} style={{fontSize:"12px", color: "#" + businessCardObj.contactColour}}>{dialCode + " " + numberSpacePadding(mobileNumber)}</a></span>
								<Button
									type="button"
									icon="pi pi-copy"
									onClick={() => {
										navigator.clipboard.writeText(dialCode + mobileNumber)}
									}
									className="p-0 border-0 bg-transparent"
									style={{color: "#" + businessCardObj.contactColour, width: "auto"}}
								/>
							</Button>
						</div>
					}
					<div>
						<Button className="p-button-outlined mx-0 col-10 col-sm-7 col-md-5 col-lg-4" style={{marginTop: "0.45rem", borderRadius: "15px", fontFamily: "ObjectSans-Bold,sans-serif", borderColor: "#" + businessCardObj.contactColour}}>
							<span className="textalign-start" style={{fontSize:"12px", color: "#" + businessCardObj.contactColour}}>O</span>
							<span className="w-100 px-2"><a href="tel:+6565140510" style={{fontSize:"12px", color: "#" + businessCardObj.contactColour}}>+65 6514 0510</a></span>
							<Button
								type="button"
								icon="pi pi-copy"
								onClick={() => {
									navigator.clipboard.writeText("+6565140510")}
								}
								className="p-0 border-0 bg-transparent"
								style={{color: "#" + businessCardObj.contactColour, width: "auto"}}
							/>
						</Button>
					</div>
				</div>
				<div className="row p-justify-center mx-2" style={{filter: cssFilter}}>
					{(businessCardObj.socialMedia).map((link: SocialMediaModel) => (
						<a href={fullURL(link)} className="mx-2 my-1" rel="noopener noreferrer" target="_blank">
							<img height="24" src={iconSource(link.icon)}/>
						</a>
					))}
					{/* <a href="https://google.com">
						<img alt="Zula_Logo" height="50" src="/assets/company-logos/Wiki.sg.svg"/>
					</a> */}
				</div>

				{/* Using style here until you find out the bootstrap equivalent */}
				<div className="mt-5">
					<img
						alt="Zula_Logo"
						height="50"
						src={userCompany()}
					/>
				</div>

			</div>
		</div>
	)

	console.log('test &#8203');

    if (isError) {
    	display = <CustomError message={errorMsg} />;
	} else if (isLoading) {
		display = <div className="text-center h-100"><ProgressSpinner /></div>
	}

	return display;
}

