import { HttpClient } from "./HttpClient";
export class DocumentTypeService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getDocumentTypes() {
    const res = await this.httpClient.get("/api/documenttype");
    return res;
  }

  async getDocumentTypeById(id: string) {
    const res = await this.httpClient.get("/api/documenttype/" + id);
    return res;
  }

  async getDocumentTypeDropdown() {
    const res = await this.httpClient.get("/api/dropdown/documenttype");
    return res;
  }

  async createDocumentType(obj: any) {
    const res = await this.httpClient.post("/api/documenttype/create", obj);
    return res;
  }

  async updateDocumentType(obj: any) {
    const res = await this.httpClient.put("/api/documenttype/update", obj);
    return res;
  }

  async deleteDocumentType(obj: any) {
    const res = await this.httpClient.delete("/api/documenttype/"+obj.documentTypeID);
    return res;
  }

}
