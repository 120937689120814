import React, { useState, useEffect } from "react";
import { RouteComponentProps, CommonMethods,DropdownModel } from "../resources/CommonMethods";
import {
  LogisticsStatusTypeModel,
  LogisticsModel,
} from "../resources/ExportClass";
import { LogisticsService } from "../../service/LogisticsService";
import { ToastStateContext } from "../resources/ToastContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Redirect } from "react-router";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

const commonMethods = new CommonMethods();
const logsService = new LogisticsService();
/**
 * Display the page for Creation of Logistics (aka Equipments)
 * @param props
 */
function WriteoffEquipment(props: RouteComponentProps<any>) {
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [isLoading, setLoadingStatus] = useState<boolean>(true);
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");

  const [equipList, setEquipmentList] = useState<Array<LogisticsModel>>([]);
  const [userList, setUserList] = useState<Array<DropdownModel>>([]);
  const [statusList, setStatusList] = useState<Array<LogisticsStatusTypeModel>>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [statusFilterSelected, setStatusFilterSelected] = useState<
    LogisticsStatusTypeModel
  >(new LogisticsStatusTypeModel());

  // ComponentDidMount
  useEffect(() => {
    getUserListForDropdown();
    getStatusTypeList();
  }, []);

  useEffect(() => {
    if (userList.length > 0 && statusList.length > 0) {
      getAllEquipmentList();
    }
  }, [userList, statusList]);

  // Functions
  const getStatusTypeList = () => {
    logsService.getAllLogisticsStatusType().then(res => {
      setStatusList(res);
    });
  };

  const getUserListForDropdown = () => {
    commonMethods.getUserDropdownForGroupCompany().then(res => {
      setUserList(res);
    });
  };
  const getAllEquipmentList = () => {
    logsService.getAllEquipmentList().then(res => {
      setEquipmentList(res);
      setLoadingStatus(false);
    });
  };

  const displayStatus = (rowData: LogisticsModel) => {
    return (
      <span>
        {
          statusList[
            statusList.findIndex(
              (el: LogisticsStatusTypeModel) =>
                rowData.logisticsStatusTypeID === el.logisticsStatusTypeID
            )
          ].logisticsStatusTypeName
        }
      </span>
    );
  };
  const displayDate = (rowData: any, column: any) => {
    return new Date(rowData[column.field]).toDateString();
  };

  const displayDepreciation = (rowData: any, column: any) => {
    const depreciationDate = new Date(
      new Date(rowData[column.field]).getFullYear() + 2,
      new Date(rowData[column.field]).getMonth(),
      new Date(rowData[column.field]).getDate()
    );
    const depreciationDate_UNIX = depreciationDate.getTime();
    const today_UNIX = Date.now(); // UNIX TIME

    if (today_UNIX >= depreciationDate_UNIX) {
      return "DEPRECIATED";
    } else {
      var timeLeft = "";

      var timeLeftSeconds = (depreciationDate_UNIX - today_UNIX) / 1000;
      var timeLeftDays = timeLeftSeconds / (24 * 60 * 60);
      var timeLeftMonths = timeLeftSeconds / (30 * 24 * 60 * 60);
      var timeLeftYear = timeLeftSeconds / (365 * 24 * 60 * 60);

      if (timeLeftYear > 0) {
        var remainder = (timeLeftYear - Math.floor(timeLeftYear)) * 365;
        var remainderString =
          remainder > 30
            ? Math.floor(remainder / 30) + " Months"
            : Math.floor(remainder) + " Days";

        timeLeft = `${Math.floor(timeLeftYear)} Years and ${remainderString}`;
      } else if (timeLeftMonths > 0) {
        var remainder = timeLeftMonths - Math.floor(timeLeftMonths);
        timeLeft = `${Math.floor(timeLeftMonths)} Months and ${remainder}`;
      } else {
        timeLeft = `${Math.floor(timeLeftDays)} Days`;
      }

      return timeLeft;
    }
  };

  const dtActionButtons = (rowData: LogisticsModel) => {
    return (
      <>
        <Button
          icon="pi pi-info-circle"
          onClick={() => {
            setRedirectOut(true);
            setRedirectPath("/equipment/" + rowData.equipmentSerialNo);
          }}
          className="p-button"
        />
      </>
    );
  };

  // Filter
  var header = (
    <div style={{ textAlign: "left" }}>
      {/* <i className="pi pi-search" style={{margin: "4px 4px 0 0"}}></i> */}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={e => setGlobalFilter(e.currentTarget.value)}
        />
      </span>
    </div>
  );

  let statusFilter = (
    <Dropdown
      style={{ width: "100%" }}
      value={
        statusList[
          statusList.findIndex(
            (el: LogisticsStatusTypeModel) =>
              statusFilterSelected.logisticsStatusTypeID ===
              el.logisticsStatusTypeID
          )
        ]
      }
      optionLabel="logisticsStatusTypeName"
      options={statusList}
      onChange={e => setStatusFilterSelected(e.value.logisticsStatusTypeID)}
      placeholder="Filter by Status"
    />
  );

  return (
    <>
      {redirectOut ? (
        <Redirect
          push
          to={{
            pathname: redirectPath
          }}
        />
      ) : null}

      <div className="row">
        <div className="col-12 col-xl-10">
          <div className="card">
            <h1>Equipment List (Finance)</h1>
            {isLoading ? (
              <ProgressSpinner />
            ) : (
              <div className="row">
                <div className="datatable-centerHeader datatable-centerContent">
                  <DataTable
                    value={equipList}
                    emptyMessage="No results found."
                    autoLayout={true}
                    paginator={true}
                    rows={15}
                    alwaysShowPaginator={false}
                    header={header}
                    globalFilter={globalFilter}
                  >
                    <Column field="equipmentName" header="Equipment" />
                    <Column field="equipmentSerialNo" header="Serial" />
                    <Column
                      field="logisticsStatusTypeID"
                      body={displayStatus}
                      header="Status"
                      filter={true}
                      filterElement={statusFilter}
                      style={{ width: "5em" }}
                    />
                    <Column
                      field="purchasedDate"
                      body={displayDate}
                      header="Purchased Date"
                    />
                    <Column
                      field="warrantyExpiryDate"
                      body={displayDate}
                      header="Warranty Till"
                    />
                    <Column
                      field="purchasedDate"
                      body={displayDepreciation}
                      header="Depreciation"
                    />
                    <Column body={dtActionButtons} header="Action" />
                  </DataTable>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default WriteoffEquipment;
