import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "../resources/CommonMethods";
import { UserRoleSetting } from "./UserRoleSetting";
import { MultiDeptSetting } from "./MultiDeptSetting";
import { UserService } from "../../service/UserService";
import { TaskList } from "../resources/ListOfTasks";

export function UserAccessSetting(props: RouteComponentProps<any>) {
  const [displayMultiDeptKPI, setDisplayMultiDeptKPI] = useState<boolean>(
    false
  );
  const [displayMultiDeptLeave, setDisplayMultiDeptLeave] = useState<boolean>(
    false
  );

  let userService = new UserService();
  useEffect(() => {
    if (props.match.params.id !== undefined && props.match.params.id !== null) {
      let userid = props.match.params.id;
      userService.getTaskListByUserID(userid).then((res: string[]) => {
        if (res.includes(TaskList.customkpiaccess)) {
          setDisplayMultiDeptKPI(true);
        }
        if (res.includes(TaskList.customleaveaccess)) {
          setDisplayMultiDeptLeave(true);
        }
      });
    }
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <UserRoleSetting {...props} />
      </div>
      <div className="col-lg-6 col-md-8 col-s-12 col-xs-12">
        {displayMultiDeptKPI && (
          <MultiDeptSetting {...props} others={{ type: "kpi" }} />
        )}
      </div>
      {displayMultiDeptLeave && (
        <div className="col-lg-6 col-md-8 col-s-12 col-xs-12">
          <MultiDeptSetting {...props} others={{ type: "leave" }} />{" "}
        </div>
      )}
    </div>
  );
}
