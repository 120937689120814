import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "../resources/CommonMethods";
import { LeaveApplicationForm } from "./LeaveApplicationForm";
import LeaveBalancePanelHorizontal from "./LeaveBalancePanelHorizontal";
import StatusCreditCreate from "./StatusCredit";

function LeaveCreditApplicationCombined(props: RouteComponentProps<any>) {
  const [displayLeaveBalance, setDisplayLeaveBalance] = useState(<></>);
  const [displayLeaveApplication, setDisplayLeaveApplication] = useState(<></>);
  const [refreshComponent, setRefreshComponent] = useState<boolean>(false);

  const refreshComponentMethod = (refresh: boolean) => {
    // setDisplayLeaveBalance(displayLeaveBalanceHorizontal());
    setRefreshComponent(refresh);
  };

  useEffect(() => {
    loadPage();
  }, []);

  useEffect(() => {
    loadPage();
  }, [refreshComponent]);

  const loadPage = () => {
    if (props.location.search !== undefined && props.location.search !== "") {
      if (props.location.search === "?batch") {
        setDisplayLeaveBalance(<></>);
        setDisplayLeaveApplication(<></>);
      } else {
        setDisplayLeaveBalance(displayLeaveBalanceHorizontal());
        setDisplayLeaveApplication(displayLeaveApplicationPanel());
      }
    } else {
      setDisplayLeaveBalance(displayLeaveBalanceHorizontal());
      setDisplayLeaveApplication(displayLeaveApplicationPanel());
    }
  };

  const displayLeaveBalanceHorizontal = () => {
    return (
      <div className="row">
        <div className="col-12 col-lg-12 col-lg-10">
          <div className="card">
            {refreshComponent && (
              <LeaveBalancePanelHorizontal {...props} others="StatusCredit" />
            )}
            {!refreshComponent && (
              <LeaveBalancePanelHorizontal {...props} others="StatusCredit" />
            )}
          </div>
        </div>
      </div>
    );
  };

  const displayLeaveApplicationPanel = () => {
    return (
      <div className="col-12 col-md-6 col-lg-6 col-xl-5">
        <div className="card">
          <LeaveApplicationForm
            {...props}
            others={{ refreshState: refreshComponent, refreshMethod: refreshComponentMethod }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {refreshComponent && displayLeaveBalance}
      {!refreshComponent && displayLeaveBalance}
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-5">
          <div className="card">
            {refreshComponent && (
              <StatusCreditCreate {...props} others={refreshComponentMethod} />
            )}
            {!refreshComponent && (
              <StatusCreditCreate {...props} others={refreshComponentMethod} />
            )}
          </div>
        </div>
        {displayLeaveApplication}
      </div>
    </>
  );
}

export default LeaveCreditApplicationCombined;
