import React, { useEffect, useState } from "react";
import { RouteComponentProps, CommonMethods } from "../resources/CommonMethods";
import { DepartmentService } from "../../service/DepartmentService";
import _, { Dictionary } from "lodash";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { MultiDeptService } from "../../service/MultiDeptService";
import { ToastStateContext } from "../resources/ToastContext";

export class CompanyDepartmentModel {
  public companyID: string = "";
  public companyName: string = "";
  public departmentName: string = "";
  public departmentID: string = "";
}
export class MultiDeptSubmitModel {
  public settingType: string = "";
  public userID: string = "";
  public selectedDepartments: CompanyDepartmentModel[] = new Array<
    CompanyDepartmentModel
  >();
}
enum MultiDeptSettingKeyTypes {
  KPI = "KPI",
  Leave = "L",
}
export function MultiDeptSetting(props: RouteComponentProps<any>) {
  let deptService = new DepartmentService();
  let multiDeptService = new MultiDeptService();
  let commonMethods = new CommonMethods();
  const [displayText, setDisplayText] = useState<string>();
  const [deptList, setDeptList] = useState<CompanyDepartmentModel[]>(
    new Array<CompanyDepartmentModel>()
  );
  const [selectedList, setSelectedList] = useState<
    Array<CompanyDepartmentModel>
  >(new Array<CompanyDepartmentModel>());
  const [keyType, setKeyType] = useState<string>("");
  const [userID, setUserID] = useState<string>("");

  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);

  useEffect(() => {
    if (props.others !== null) {
      if (props.others.type === "leave") {
        setDisplayText("Leave Overview");
        setKeyType(MultiDeptSettingKeyTypes.Leave);
      } else if (props.others.type === "kpi") {
        setDisplayText("KPI Overview");
        setKeyType(MultiDeptSettingKeyTypes.KPI);
      }
      if (props.match.params !== null && props.match.params !== "") {
        setUserID(props.match.params.id);
      } else {
      }
    }
  }, []);

  useEffect(() => {
    retrieveInfo();
  }, [keyType, userID]);

  const checkboxMethod = (e: {
    value: any;
    checked: boolean;
    originalEvent: Event;
  }) => {
    let selectedCheckboxes = [...selectedList];

    if (e.checked) selectedCheckboxes.push(e.value);
    else selectedCheckboxes.splice(selectedCheckboxes.indexOf(e.value), 1);

    setSelectedList(selectedCheckboxes);
  };

  const retrieveInfo = () => {
    if (keyType !== "" && userID !== "") {
      deptService
        .getAllDeptsInAllCompanies()
        .then((res) => {
          setDeptList(res);
          // get user settings
          multiDeptService
            .getCustomMultiDeptSetting(userID, keyType)
            .then((res) => {
              setSelectedList(res);
            });
        })
        .catch((err) => {
          let errMsg = commonMethods.getErrorMessage(err);
          SetError(errMsg);
        });
    }
  };

  const submitForm = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let submitModel = new MultiDeptSubmitModel();
    submitModel.selectedDepartments = selectedList;
    submitModel.settingType = keyType;
    submitModel.userID = userID;
    multiDeptService
      .setCustomMultiDeptSetting(submitModel)
      .then((res) => {
        SetSuccess("Saved");
      })
      .catch((err) => {
        let errMsg = commonMethods.getErrorMessage(err);
        SetError(errMsg);
      });
  };

  const prepDisplay = () => {
    let display = [];

    let groupedList: Dictionary<CompanyDepartmentModel[]> = _.groupBy(
      deptList,
      "companyName"
    );
    for (var company in groupedList) {
      let companyheader = (
        <div className="col-12 pt-3" key={company + keyType}>
          <h5>{company}</h5>
        </div>
      );
      display.push(companyheader);
      groupedList[company].forEach((dept) => {
        let item = (
          <div className="col-12 p-field-checkbox" key={dept.departmentID + keyType}>
            <Checkbox
              inputId={dept.departmentID + keyType}
              value={dept}
              onChange={checkboxMethod}
              checked={
                _.find(selectedList, { departmentID: dept.departmentID }) !==
                undefined
              }
            ></Checkbox>
            <label
              htmlFor={dept.departmentID + keyType} className="ml-1"
            >
              {dept.departmentName}
            </label>
          </div>
        );
        display.push(item);
      });
    }
    return display;
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <h1>Custom Settings - {displayText} </h1>
          <p>
            Please select departments and companies that this employee can have
            access to view.
          </p>

          <div className="row">
            {prepDisplay()}

            <div className="col-12 text-center">
              <Button type="submit" label="Save" onClick={submitForm} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
