import React from "react";

function TermsOfService() {
  return (
    <div>
      <section
        className="fdb-block"
        data-block-type="call_to_action"
        data-id="2"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Terms Of Service</h1>
            </div>
          </div>

          <div className="row align-items-center pt-2 pt-lg-5">
            <p>
              {" "}
              THIS AGREEMENT GOVERNS YOUR ACQUISITION AND USE OF OUR SERVICES.
            </p>
            <p>
              IF YOU REGISTER FOR A FREE TRIAL FOR OUR SERVICES, THE APPLICABLE
              PROVISIONS OF THIS AGREEMENT WILL ALSO GOVERN THAT FREE TRIAL.
            </p>
            <p>
              <b> 1. ACCEPTANCE OF TERMS </b>
              <p>
                NeuHR provides its Service to you, subject to the following
                Terms of Service ("TOS"), which may be updated by us from time
                to time without notice to you. You can review the most current
                version of the TOS at any time on this page.
              </p>
            </p>
            <p>
              <b> 2. DESCRIPTION OF SERVICE </b>
              <p>
                NeuHR’s provision of any Service is subject to the terms of this
                Agreement.If the terms hereof conflict with any Service Terms,
                the Service Terms will govern with respect to the matters
                contemplated thereby. <br></br><br></br> In order to receive any Service,
                User must be enrolled in one of the following plans (each, a
                “Service Plan”), which coincides with User’s Plan Selection (as
                defined below) or Service Plan access by <br></br>(i) clicking
                the applicable button to indicate User’s Service Plan choice
                (the “Plan Selection”); <br></br>(ii) clicking the applicable
                button to indicate User’s acceptance of this Agreement (in the
                event that User is already enrolled in a Service Plan); or{" "}
                <br></br>(iii) accessing or using the Services. By taking any of
                the aforementioned actions, User agrees to be bound by the
                applicable Service Terms as of the time that such action occurs.{" "}
                
                <br></br><br></br> <b>Basic:</b> 90 days free trial with a maximum of 20
                employees. <br></br><br></br><b>Premium:</b> Our All-In-One HR platform for
                maximum automation.
              </p>
            </p>
            <p>
              <b>3. GENERAL CONDITIONS AND RESTRICTIONS </b>{" "}
              <p>
                Without prejudice to the other provisions of these Terms, the
                subscription granted is subject to the following conditions:{" "}
                <br></br>
                <ol type="a">
                  <li>
                    The Services and Software must only be used by you for your
                    own internal business purposes
                  </li>
                  <li>
                    To the extent permitted by law, you shall not decompile,
                    reverse engineer, disassemble or otherwise derive the source
                    code of the Software , nor permit any third party to do so,
                    nor alter or modify the Software without the written consent
                    of NeuHR.
                  </li>

                  <li>
                    The subscription to use the Services or the Software may not
                    be transferred to any third party without the prior written
                    consent of NeuHR and will be subject to a third party
                    entering into an agreement with NeuHR to do so.
                  </li>

                  <li>
                    You are responsible for compliance with the provisions of
                    these Terms by your agents and authorised users and for any
                    and all activities that occur under your account.
                  </li>

                  <li>
                    You and your authorised users are solely responsible for
                    maintaining the confidentiality of all login credentials for
                    your account.
                  </li>

                  <li>
                    You shall comply with any codes of conduct, policies,
                    guidelines or other notices implemented by NeuHR or
                    published on our website from time to time in connection
                    with the Services.
                  </li>

                  <li>
                    In accessing the Service, you shall not impersonate any
                    other person or entity, including without limitation any
                    representative of NeuHR.
                  </li>
                </ol>
         <br></br>You as customer agree that you will:

                <ol type="a">
                  <li>
                    ensure that your authorised users use the Services and the
                    Software in accordance with these terms;
                  </li>
                  <li>
                    provide true, accurate and complete information as required
                    by NeuHR;
                  </li>
                  <li>
                    not use the content off this website to violate any state or
                    international laws or regulations and transmit any material
                    that is abusive, defamatory, vulgar, obscene, and invasive
                    of another person’s privacy, racially or ethnically
                    discriminatory. You shall not use our content to transmit
                    any unsolicited advertising, promotional materials, junk
                    mail, spam, chain letters or material that contains adware,
                    malware, and spyware, software viruses that have been
                    designed to interrupt and destroy the functionality of
                    computer software, hardware or other telecommunication
                    equipment. Interference or disruption of the services or
                    servers of our network, as well as disobedience to the
                    requirements, policies or regulations of networks connected
                    to the services will not be tolerated.
                  </li>
                </ol>
              </p>
            </p>
            <p>
              <b>4. LIMITATION OF LIABILITY </b>{" "}
              <p>
                Under no circumstances shall NeuHR be held accountable for any
                damages suffered by users, whether or not they are incidental,
                special, indirect or consequential damages. This includes but is
                not limited to any lost profits or damages from the interruption
                of users’ business, loss of information, programs or data that
                arise from the access to and use of our website. <br></br><br></br>
                Without limiting the foregoing, you agree and acknowledge that
                NeuHR’s total liability will not exceed the total of the Fees
                paid (if any) by you for your subscription during the 12-month
                period immediately preceding the alleged claim or cause of
                action.
              </p>
            </p>
            <p>
              <b>5. FEES AND CHARGES</b>{" "}
              <p>
                {" "}
                You shall pay NeuHR the agreed upon fees and charges set out in
                this Service Agreement. All payments hereunder shall be made by
                way of cheques, bank drafts or any other payment method NeuHR
                may deem acceptable in its sole discretion within ten (10) days
                from the date of NeuHR’s invoice as detailed therein except
                where specified otherwise. All fees are non-refundable.{" "}
                <br></br><br></br> You shall pay any good and services tax or any other
                tax levies or charges now or hereafter imposed by law. <br></br><br></br>{" "}
                For the purpose of calculating the subscription fee per month
                for the current month, the total number of users shall be based
                on the total number of active users on the last day of the
                previous month, or the number of users stated in the Service
                Agreement during the minimum contract period, whichever is
                higher. <br></br><br></br> NeuHR reserves the right to change the fees
                for its Services from time to time. Users will be notified of
                any change to existing fees at least thirty (30) days before the
                fee change goes into effect. If a fee increase or change to this
                Agreement is not acceptable to User, User may cancel the
                Services as provided herein prior to the time when such fee
                increase or change to this Agreement takes effect. <br></br><br></br> If
                you fail to make payment when due, NeuHR may charge you interest
                at one and a half percent (1.5%) per month on the amounts
                outstanding, from the date such payment is due until the date
                payment is made in full. Billing will start from the
                commencement date regardless of whether you have started using
                the services. <br></br><br></br>If User chooses to upgrade from User’s
                current Service Plan (the “Current Plan”) to a more expensive
                Service Plan (the “New Upgrade Plan”), then such upgrade will
                promptly go into effect, and User will begin receiving access to
                the features and Services available under the New Upgrade Plan
                at the time of such upgrade. The fee schedule for the New
                Upgrade Plan will be applied to User’s Service Plan charge for
                the calendar month in which User upgraded and for each calendar
                month thereafter for so long as User is subscribed to the New
                Upgrade Plan.
              </p>
            </p>
            <p>
              <b>6. REPRESENTATIONS AND WARRANTIES </b>{" "}
              <p>
                You acknowledge that your use of the Service is at your sole
                risk. The Service is provided on an “As Is” basis, and NeuHR
                does not make any warranty, express or implied, with respect to
                the Service, including without limitation any implied warranty
                of merchantability, fitness for a particular purpose or
                non-infringement of third party rights.
              </p>
            </p>
            <p>
              <b>7. COPYRIGHT AND TRADEMARK RIGHTS </b>{" "}
              <p>
                The content as provided on this website is the property of NeuHR
                and is protected by copyright, trademark and other intellectual
                property regulations. Users are not to copy, modify, distribute,
                publish, sell or use any information available through this
                website for commercial purposes.
              </p>
            </p>
            <p>
              <b>8. EXCHANGE AND REFUND</b>
              <p>
                All our products and services sold are non-exchangeable and
                non-refundable.
              </p>
            </p>
            <p>
              <b> 9. NEUHR WILL NOT BE HELD LIABLE FOR FORCE MAJEURE</b>{" "}
              <p>
                {" "}
                NeuHR is not responsible or liable for any delays or failures in
                performance from any cause beyond NeuHR control, including, but
                not limited to, acts of God, changes to laws or regulations,
                embargoes, wars, terrorist acts, acts or omissions of
                third-party technology providers, riots, fires, earthquakes,
                floods, power outages, strikes, weather conditions, acts of
                hackers, acts of internet service providers, acts of any other
                third party, or acts or omissions of User. Without limiting the
                generality of the foregoing or Section 18, the Platform and the
                Services rely on third-party technology and services, such as
                application programming interfaces, for Third-Party Services and
                web hosting services. Any change to the products or services
                offered by any of these third-party providers may materially and
                adversely affect, or entirely disable, User’s use of or access
                to the Platform and the Services. Likewise, NeuHR cannot
                guarantee that any User Content hosted on a third-party server
                will remain secure.
              </p>
            </p>
            <p>
              <b>10. CONTACTING NEUHR </b>{" "}
              <p>
                If you have any questions concerning this TOS, or feedback you
                would like to share, please contact:<br></br><br></br>
              </p>
              <p>NeuHR <br></br>hello@neuhr.com</p>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TermsOfService;
