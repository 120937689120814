import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputNumber } from 'primereact/inputnumber';

import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { Redirect } from "react-router";
import { ContractService } from "../../service/ContractService";
import { JobTitleService } from "../../service/JobTitleService";
import { StatusService } from "../../service/StatusService";
import { UserRankService } from "../../service/UserRankService";
import { UserService } from "../../service/UserService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError  from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { ContractModel } from "./Contract";
import { SelectButton } from "primereact/selectbutton";
import { Checkbox } from "primereact/checkbox";
import { statusTypeObj } from "../statusrecord/StatusType";
import { AppService } from "../../service/AppService";
import _ from "lodash";
import { UserModel } from "../user/User";

interface dataObj {
  [key: string]: number | string;
}

export interface CreateContractState {
  contractObject: ContractModel;
  headerStr: string;
  submitStr: string;
  additionalStatus: any[];
  redirectOut: boolean;
  redirectPath: string;
  redirectParam: string;
  redirectState: string;
  companyDropdownList: any[];
  departmentDropdownList: [];
  jobTitleDropdownList: [];
  userRankDropdownList: [];
  countryDropdownList: [];
  // statusDropdownList: any[];
  currencyDropdownList: any[];

  isError: boolean;
  isLoading: boolean;
  errorMsg: string;

  isFromExistingUser: boolean;

  allStatusTypes: statusTypeObj[];
  workingDaysOptions: any[];
  setupCreate: boolean;
  me: UserModel;

  // contractID: string;
}

export default class CreateContract extends Component<
  RouteComponentProps<any>,
  CreateContractState
> {
  static contextType = ToastStateContext;

  contractService: ContractService;
  userService: UserService;
  commonMethods: CommonMethods;
  userRankService: UserRankService;
  jobTitleService: JobTitleService;
  statusService: StatusService;
  appService: AppService;

  salaryPeriodDropdownList: any;

  allCompanies: any[];

  userView: string | any;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      contractObject: new ContractModel(),
      headerStr: "Create New Contract",
      additionalStatus: [],
      submitStr: "Create",
      redirectOut: false,
      redirectPath: "",
      redirectParam: "",
      redirectState: "",
      currencyDropdownList: [],
      companyDropdownList: [],
      departmentDropdownList: [],
      jobTitleDropdownList: [],
      userRankDropdownList: [],
      countryDropdownList: [],
      // statusDropdownList: [],
      isError: false,
      allStatusTypes: Array<statusTypeObj>(),
      workingDaysOptions: [],
      setupCreate: false,

      errorMsg: "",
      isLoading: true,
      isFromExistingUser: false,
	  me: new UserModel(),
    };
    this.commonMethods = new CommonMethods();
    this.contractService = new ContractService();
    this.userService = new UserService();
    this.userRankService = new UserRankService();
    this.jobTitleService = new JobTitleService();
    this.statusService = new StatusService();
    this.appService = new AppService();

    // this.contractStatusDropdownList = [];
    // this.contractTypeDropdownList = [];

    this.allCompanies = [];

    this.initiateDropDownLists = this.initiateDropDownLists.bind(this);
    this.updateProperty = this.updateProperty.bind(this);
    this.showOtherLeaves = this.showOtherLeaves.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.initDefaultStatusTypeValues = this.initDefaultStatusTypeValues.bind(
      this
    );
    this.displayStatusTypeName = this.displayStatusTypeName.bind(this);
    // this.changeStatusOnly = this.changeStatusOnly.bind(this);

    this.userView = this.commonMethods.checkLocState(this.props)
      ? this.props.location.state
      : "";
  }

  createContractExistingUser() {
    // "Create" contract from User
    this.setState({ isFromExistingUser: true });

    let userID = this.props.match.params.id;

    this.statusService
      .getLeaveBalanceByUserID_ContractView(userID)
      .then((res) => {
        let leaveBalanceArray: any[] = res;

        this.contractService
          .getUserActiveOrLatestContract(userID)
          .then((res) => {
			this.checkHRAccess(res);
            let data: ContractModel = res;

            data.jobStartDate = new Date(res.jobStartDate);
            data.contractStatus = "Pending";

            if (data.identificationNumber.length === 36) {
              // because original was set as a GUID for uniqueness
              data.identificationNumber = "";
            }

            this.setState({ contractObject: data,}, () => {
                // 15/05/2020 - HM added to take leave from previous contract.
                var jsonList = JSON.parse(this.state.contractObject.otherLeaves);
                // Add in missing leave types if not present in previous ver of contract.

                if (jsonList !== null) {
                  for (var i = 0; i < this.state.allStatusTypes.length; i++) {
                    if (jsonList[0][this.state.allStatusTypes[i].statusTypeID] === undefined) {
                      jsonList[0][
                        this.state.allStatusTypes[i].statusTypeID
                      ] = this.state.allStatusTypes[i].defaultValue;
                    }
                  }
                  for (var i = 0; i < leaveBalanceArray.length; i++){
                    let currentLeaveStatusID = leaveBalanceArray[i].statusTypeID;
                    let availableDays = leaveBalanceArray[i].availableDays;
                    if (jsonList[0][currentLeaveStatusID] !== undefined){
                      jsonList[0][currentLeaveStatusID] = availableDays;
                    }
                  }
                  this.setState({
                    additionalStatus: jsonList,
                  });
                }

                // update job title dropdown
                this.displayJobTitleByDeptSelected(
                  this.state.contractObject.departmentID
                );

                this.setState({
                  isLoading: false,
                });
              }
            );
          })
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  updateContract() {
    // Update Contract
    if (
      this.props.match.params.user === "user"
    ) {
      this.setState({ isFromExistingUser: true });
    }

    const contractID = this.props.match.params.id;

    this.contractService
      .getContractByID(contractID)
      .then((res) => {
		this.checkHRAccess(res);
        let data: ContractModel = res;
        data.jobStartDate = new Date(res.jobStartDate);

        if (data.identificationNumber.length === 36) {
          // because original was set as a GUID for uniqueness
          data.identificationNumber = "";
        }

        this.setState(
          {
            contractObject: data,
            headerStr: "Update Contract",
            submitStr: "Update",
          },
          () => {
            var jsonList = JSON.parse(this.state.contractObject.otherLeaves);

            if (jsonList !== null) {
              this.setState({
                additionalStatus: jsonList,
              });
            }
            this.displayJobTitleByDeptSelected(
              this.state.contractObject.departmentID
            );
            this.displayDeptByCompanySelected(
              localStorage.getItem("currentAccessingCompany") || ""
            );
            this.setState({
              isLoading: false,
            });
          }
        );
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  checkHRAccess(userDetails: ContractModel){
	this.commonMethods
      .getDepartmentDropdownList(localStorage.getItem("currentAccessingCompany") || "")
      .then((res: any[]) => {
        let userDeptName = (res.find(dept => dept.value == userDetails.departmentID)).label;
		this.userService
			.getMyDisplay()
			.then((result: UserModel) => {
				if (result.departmentName == "Human Resource" && !(result.jobTitle == "HR Manager" || result.jobTitle == "Senior HR Manager") && userDeptName == "Human Resource" && userDetails.userID != result.userID){
					let errorMsg = "Cannot view HR personnel's contract";
					this.setState({
					isError: true,
					isLoading: false,
					errorMsg: errorMsg,
					});
					return errorMsg;
				}
			})
			.catch((error) => {
				let errorMsg = "Error retrieving personal information";
				this.setState({
				  isError: true,
				  isLoading: false,
				  errorMsg: errorMsg,
				});
				return errorMsg;
			  });
      })
      .catch((error) => {
        let errorMsg = "Error retrieving department list";
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  createNewContract() {
    let data = new ContractModel();
    data.currency = "SGD";
    data.workLocation = "SG";
    data.workingDaysArr = ["1", "2", "3", "4", "5"];

    this.setState({ contractObject: data });
  }

  componentDidMount() {
    this.setupProgress();
    this.statusService
      .getActiveAndDeductibleStatusTypes()
      .then((res) => {
        this.setState({ allStatusTypes: res }, () => {
          if (
            this.props.match.params !== {} &&
            this.props.match.params.id !== undefined
          ) {
            if (this.props.match.path.includes("create")) {
              this.createContractExistingUser();
            } else if (this.props.match.path.includes("update")) {
              this.updateContract();
            }
          } else {
            // Brand new person
            this.createNewContract();
            this.initDefaultStatusTypeValues();
          }
        });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });

    this.initiateDropDownLists();
  }

  setupProgress() {
    this.appService
      .getAppTopBar()
      .then((res: any) => {
        if (!res.setup.includes("Contract")) {
          this.setState({setupCreate: true});
        }
      })
      .catch ((err) => {
        this.context.SetError(this.commonMethods.getErrorMessage(err));
      })
  }

  initDefaultStatusTypeValues = () => {
    let object: dataObj = {};
    for (var i = 0; i < this.state.allStatusTypes.length; i++) {
      object[
        this.state.allStatusTypes[i].statusTypeID
      ] = this.state.allStatusTypes[i].defaultValue;
    }
    this.setState({ additionalStatus: [object] });
  };

  showOtherLeaves = () => {
    if (this.state.additionalStatus.length > 0) {
      let otherLeaves: any[] = [];
      let additionalStatus = this.state.additionalStatus[0];
      let keys = Object.keys(additionalStatus);

      for (var i = 0; i < Object.keys(additionalStatus).length; i++) {
        let currentStatus = keys[i];
        let currentStatusName = this.displayStatusTypeName(currentStatus);
        if ( currentStatusName != "Annual Leave"){
          otherLeaves.push(
            <div className="p-col-12" key={keys[i]}>
              <span className="boldLabel">
                {this.displayStatusTypeName(keys[i])}
              </span>

              <InputNumber
                name={keys[i]}
                value={additionalStatus[keys[i]]}
                // onChange={(e: any) => {
                //   if (!isNaN(Number(e.value))) {
                //     additionalStatus[e.target.name] = e.value;
                //     this.setState({ additionalStatus: [additionalStatus] });
                //   }
                // }}
                onChange={(e:any) => {
                  if (!isNaN(Number(e.value))) {
                    additionalStatus[currentStatus] = e.value;
                    this.setState({ additionalStatus: [additionalStatus]});
                  }
                }}
                min={0}
                max={100}
                step={0.5}
                mode="decimal"
                showButtons
                minFractionDigits={1}
                required
              />
            </div>
          );
        } else {
          otherLeaves.push(
            <>
              <div className="p-col-6">
                <span className="boldLabel">
                  1st Year Annual Leave Entitlement
                </span>
                <InputNumber
                  value={this.state.contractObject.yearlyAnnualLeave}
                  // onChange={(e: any) => {
                  //   if (!isNaN(Number(e.value))) {
                  //     additionalStatus[e.target.name] = e.value;
                  //     this.setState({ additionalStatus: [additionalStatus] });
                  //   }
                  // }}
                  onChange={(e:any) => {
                    if (!isNaN(Number(e.value))) {
                      this.setState({
                        contractObject: {
                          ...this.state.contractObject,
                          yearlyAnnualLeave: e.value,
                        },
                      });
                    }
                  }}
                  min={0}
                  max={100}
                  step={0.5}
                  mode="decimal"
                  showButtons
                  minFractionDigits={1}
                  required
                />
              </div>
              <div className="p-col-6" key={keys[i]}>
                <span className="boldLabel">
                  {this.displayStatusTypeName(keys[i])}
                </span>

                <InputNumber
                  name={keys[i]}
                  value={additionalStatus[keys[i]]}
                  // onChange={(e: any) => {
                  //   if (!isNaN(Number(e.value))) {
                  //     additionalStatus[e.target.name] = e.value;
                  //     this.setState({ additionalStatus: [additionalStatus] });
                  //   }
                  // }}
                  onChange={(e:any) => {
                    if (!isNaN(Number(e.value))) {
                      additionalStatus[currentStatus] = e.value;
                      this.setState({ additionalStatus: [additionalStatus]});
                    }
                  }}
                  min={0}
                  max={100}
                  step={0.5}
                  mode="decimal"
                  showButtons
                  minFractionDigits={1}
                  required
                />
              </div>
            </>
          );
        }
      }

      return otherLeaves;
    } else {
      return "No other leaves";
    }
  };

  updateProperty(property: string, value: any) {
    let tempContractObject = this.state.contractObject;
    (tempContractObject as any)[property] = value;
    this.setState({
      contractObject: tempContractObject,
    });
  }

  displayStatusTypeName(statusTypeID: string) {
    let leaveObject = this.state.allStatusTypes.filter(
      (element) => element.statusTypeID === statusTypeID
    );

    if (leaveObject.length > 0) {
      return leaveObject[0].statusTypeName;
    } else {
      return <> old otherleaves array so won't display name :( </>;
    }
  }

  convertDatesForSubmission() {
    if (this.state.contractObject.jobStartDate !== null) {
      let jsd = this.state.contractObject.jobStartDate.toISOString();

      this.updateProperty("jobStartDate", jsd);
    }
  }

  submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (
      this.state.contractObject.workingDaysArr === null ||
      this.state.contractObject.workingDaysArr.length === 0
    ) {
      this.context.SetError("Working Days is required");
      return;
    }

    this.setState(
      {
        contractObject: {
          ...this.state.contractObject,
          otherLeaves: JSON.stringify(this.state.additionalStatus),
        },
        isLoading: true,
      },

      () => {
        if (this.state.submitStr === "Create") {
          if (this.state.isFromExistingUser) {
            // Create from User List page
            this.contractService
              .createExistingContract(this.state.contractObject)
              .then((res) => {
                let msg = "Contract has been created";
                this.context.SetSuccess(msg);

                this.props.history.goBack();
              })
              .catch((error) => {
                let errorMsg = this.commonMethods.getErrorMessage(error);
                this.context.SetError(errorMsg);
                this.setState({
                  isLoading: false,
                });
              });
          } else {
            // Create from Contract page

            this.contractService
              .createContract(this.state.contractObject)
              .then((res) => {
                if (!this.state.setupCreate) {
                  let msg = "Contract has been created";
                  this.context.SetSuccess(msg);
                  this.props.history.goBack();
                } else {
                  this.props.history.goBack();
                  window.location.reload();
                }
              })
              .catch((error) => {
                let errorMsg = this.commonMethods.getErrorMessage(error);
                this.context.SetError(errorMsg);
                this.setState({
                  isLoading: false,
                });
              });
          }
        } else {
          // Update
          this.contractService
            .updateContract(this.state.contractObject)
            .then((res) => {
              let msg = "Contract has been updated";
              this.context.SetSuccess(msg);

              this.props.history.goBack();
            })
            .catch((error) => {
              let errorMsg = this.commonMethods.getErrorMessage(error);
              this.context.SetError(errorMsg);
              this.setState({
                isLoading: false,
              });
            });
        }
      }
    );
  }

  async initiateDropDownLists() {
    try {
      await this.commonMethods.getCountryDropdown().then((res) => {
        this.setState({ countryDropdownList: res });
      });

      await this.commonMethods.getCurrencyDropdown().then((res) => {
        this.setState({
          currencyDropdownList: this.commonMethods.addCommonCurrencies(res),
        });
      });

      this.salaryPeriodDropdownList = this.commonMethods.getSalaryPeriodOptions();

      await this.userRankService.getUserRankDropdownList().then((res) => {
        this.setState({ userRankDropdownList: res });
      });

      await this.commonMethods
        .getDepartmentDropdownList(
          localStorage.getItem("currentAccessingCompany") || ""
        )
        .then((res) => {
          this.setState({ departmentDropdownList: res });
        });

      await this.commonMethods.getCompanyDropdownListByGcid().then((res) => {
        this.setState({
          companyDropdownList: res,
          contractObject: {
            ...this.state.contractObject,
            companyID: localStorage.getItem("currentAccessingCompany") || "",
          },
        });
      });

      this.setState({
        workingDaysOptions: this.commonMethods.getWorkingDaysOptions(),
        isLoading: false,
      });
    } catch (error) {
      let errorMsg = this.commonMethods.getErrorMessage(error);
      this.setState({
        isError: true,
        isLoading: false,
        errorMsg: errorMsg,
      });
      return errorMsg;
    }
  }

  // 12/08/2020 - temp fix for wrong company from localstorage - e.g. editing an EB contract but on another tab you open MSN, currentaccessingcompany changes to MSN.
  displayDeptByCompanySelected(companyid: string) {
    this.commonMethods.getDepartmentDropdownList(companyid).then((res) => {
      this.setState(
        {
          departmentDropdownList: res,
        },
        () => {
          if (
            _.find(this.state.departmentDropdownList, [
              "value",
              this.state.contractObject.departmentID,
            ]) === null
          ) {
            this.setState({
              contractObject: {
                ...this.state.contractObject,
                departmentID: CommonMethods.EmptyGuid,
              },
            });
          }
        }
      );
    });
  }

  displayJobTitleByDeptSelected(departmentid: string) {
    this.commonMethods
      .getDeptJobTitleDropdown(departmentid)
      .then((res) => {
        this.setState({ jobTitleDropdownList: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  render() {
    let page = (
      <form onSubmit={this.submitForm}>
        <h5>Personal Particulars</h5>

        <div className="p-grid p-fluid">
          {/* {isExistingStaffButton} */}
          {/* {displayUserDropdown} */}
          <div className="p-col-12">
            <div className="row">
              <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">
                  First Name <span style={{ color: "red" }}>*</span>
                </span>
                <InputText
                  value={this.state.contractObject.firstName}
                  onChange={(e) =>
                    this.setState({
                      contractObject: {
                        ...this.state.contractObject,
                        firstName: e.currentTarget.value,
                      },
                    })
                  }
                  required
                />
              </div>
              <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">
                  Last Name <span style={{ color: "red" }}>*</span>
                </span>
                <InputText
                  value={this.state.contractObject.lastName}
                  onChange={(e) =>
                    this.setState({
                      contractObject: {
                        ...this.state.contractObject,
                        lastName: e.currentTarget.value,
                      },
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Full Name (as per NRIC) <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={this.state.contractObject.fullName}
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    fullName: e.currentTarget.value.toUpperCase(),
                  },
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Identification Number <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={this.state.contractObject.identificationNumber}
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    identificationNumber: e.currentTarget.value.toUpperCase(),
                  },
                })
              }
              required
            />
          </div>
        </div>

        <h5 className="pt-4">Contract Details </h5>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">Contract Status</span>
            <InputText
              // value={this.state.contractObject.contractStatus}
              placeholder="Pending"
              disabled
            />
          </div>
          <div className="p-col-12">
            <div className="row">
              <div className="col-12 col-lg-4 pt-2">
                <span className="boldLabel">
                  Duration (months) <span style={{ color: "red" }}>*</span>
                </span>
                <InputNumber
                  value={this.state.contractObject.contractDuration}
                  onChange={(e) => {
                    // if (!Number.isInteger(Number(e.value))) {
                    //   this.context.SetError(
                    //     "Only integers are allowed for Duration"
                    //   );
                    // }
                    if (!isNaN(Number(e.value))) {
                      this.setState({
                        contractObject: {
                          ...this.state.contractObject,
                          contractDuration: e.value,
                        },
                      });
                    }
                  }}
                  min={0}
                  max={100}
                  step={1}
                  mode="decimal"
                  showButtons
                  minFractionDigits={2}
                />
              </div>
              <div className="col-12 col-lg-4 pt-2">
                <span className="boldLabel">Start Date</span>
                <Calendar
                  dateFormat="dd/mm/yy"
                  readOnlyInput={true}
                  value={this.state.contractObject.jobStartDate}
                  onChange={(e) => {
                    this.updateProperty("jobStartDate", e.value);
                  }}
                  showIcon={true}
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange={`${
                    new Date().getFullYear() - 10
                  }:${new Date().getFullYear()}`}
                  required
                />
              </div>
              <div className="col-12 col-lg-4 pt-2">
                <span className="boldLabel">Contract Effective Date</span>
                <Calendar
                  dateFormat="dd/mm/yy"
                  readOnlyInput={true}
                  value={this.state.contractObject.contractEffectiveDate != null ?
                    new Date(this.state.contractObject.contractEffectiveDate.toString())
                  : undefined}
                  onChange={(e) => {
                    this.updateProperty("contractEffectiveDate", e.value);
                  }}
                  showIcon={true}
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange={`${
                    new Date().getFullYear() - 10
                  }:${new Date().getFullYear()}`}
                  required
                />
              </div>
            </div>
          </div>

          {/* <div className="p-col-12">
            <span className="boldLabel">Contract Type</span>
            <Dropdown
              value={this.state.contractObject.contractType}
              options={this.contractTypeDropdownList}
              onChange={e => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    contractType: e.value
                  }
                });
              }}
              required
              placeholder="Please Select"
              disabled
            />
          </div> */}
          <div className="p-col-12">
            <span className="boldLabel">
              Company <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.contractObject.companyID}
              options={this.state.companyDropdownList}
              required
              onChange={(e) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    companyID: e.value,
                  },
                });
                this.displayDeptByCompanySelected(e.value);
              }}
              placeholder="Please Select"
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Department <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.contractObject.departmentID}
              options={this.state.departmentDropdownList}
              required
              onChange={(e) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    departmentID: e.value,
                  },
                });
                this.displayJobTitleByDeptSelected(e.value);
              }}
              placeholder="Please Select"
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Job Title <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.contractObject.jobTitleID}
              options={this.state.jobTitleDropdownList}
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    jobTitleID: e.value,
                  },
                })
              }
              required
              placeholder="Please Select"
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              User Rank <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.contractObject.userRankID}
              options={this.state.userRankDropdownList}
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    userRankID: e.value,
                  },
                })
              }
              required
              placeholder="Please Select"
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Work Location <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              filter={true}
              filterPlaceholder="Search Work Location"
              filterBy="label,value"
              value={this.state.contractObject.workLocation}
              options={this.state.countryDropdownList}
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    workLocation: e.value,
                  },
                })
              }
              required
              placeholder="Please Select"
            />
          </div>
        </div>

        <h5 className="pt-4">Work Details</h5>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">Work Hours</span>
            <InputNumber
              value={this.state.contractObject.workHours}
              onChange={(e) => {
                if (!isNaN(Number(e.value))) {
                  this.setState({
                    contractObject: {
                      ...this.state.contractObject,
                      workHours: e.value,
                    },
                  });
                }
              }}
              min={0}
              max={100}
              step={0.5}
              mode="decimal"
              showButtons
              minFractionDigits={1}
            />

            {/* <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">Weekly Work Days</span>
                <Spinner
                  value={this.state.contractObject.weeklyWorkDays}
                  onChange={e => {
                    if (!isNaN(Number(e.value))) {
                      this.setState({
                        contractObject: {
                          ...this.state.contractObject,
                          weeklyWorkDays: e.value,
                          workingDaysArr:
                            e.value === 7
                              ? Array.from(Array(e.value), (x, i) =>
                                  i.toString()
                                )
                              : Array.from(Array(e.value), (x, i) =>
                                  (i + 1).toString()
                                )
                        }
                      });
                    }
                  }}
                  min={0}
                  max={7}
                  step={0.5}
                />
              </div> */}
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Working Days <span style={{ color: "red" }}>*</span>
            </span>

            <SelectButton
              style={{ width:"70%", overflow: "auto" }}
              value={this.state.contractObject.workingDaysArr}
              options={this.state.workingDaysOptions}
              onChange={(e) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    workingDaysArr: e.value,
                    weeklyWorkDays: e.value.length,
                  },
                });
              }}
              multiple
            ></SelectButton>
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Probation Length (months)</span>
            <InputNumber
              value={this.state.contractObject.probationLength}
              onChange={(e) => {
                if (!isNaN(Number(e.value))) {
                  this.setState({
                    contractObject: {
                      ...this.state.contractObject,
                      probationLength: e.value,
                    },
                  });
                }
              }}
              step={0.5}
              min={0}
              max={50}
              mode="decimal"
              showButtons
              minFractionDigits={1}
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Notice Period (months)</span>
            <InputNumber
              value={this.state.contractObject.noticePeriod}
              onChange={(e) => {
                if (!isNaN(Number(e.value))) {
                  this.setState({
                    contractObject: {
                      ...this.state.contractObject,
                      noticePeriod: e.value,
                    },
                  });
                }
              }}
              step={0.5}
              min={0}
              max={50}
              mode="decimal"
              showButtons
              minFractionDigits={1}
            />
          </div>
        </div>

        <h5 className="pt-4">Salary Details</h5>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">
              Wage Type <span style={{ color: "red" }}>*</span>
            </span>
            <Dropdown
              value={this.state.contractObject.salaryPeriod}
              options={this.salaryPeriodDropdownList}
              onChange={(e) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    salaryPeriod: e.value,
                  },
                });
              }}
              required
              placeholder="Please Select"
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Currency <span style={{ color: "red" }}>*</span>
            </span>

            <Dropdown
              value={this.state.contractObject.currency}
              options={this.state.currencyDropdownList}
              onChange={(e) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    currency: e.value,
                  },
                });
              }}
              placeholder="Please Select"
              required
            />
          </div>
          <div className="p-col-12 ">
            <span className="boldLabel">Basic Salary</span>

            <NumberFormat
              className="p-inputtext"
              value={this.state.contractObject.basicSalary}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
              onValueChange={(value) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    basicSalary:
                      value.floatValue === undefined ? 0 : value.floatValue,
                  },
                });
              }}
            />
          </div>

          <div className="p-col-12 ">
            <span className="boldLabel">
              Job Grade <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={this.state.contractObject.jobGrade}
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    jobGrade: e.currentTarget.value,
                  },
                })
              }
            />
          </div>

          {/* <div className="p-col-12 ">
            <span className="boldLabel">Allowance</span>

            <NumberFormat
              className="p-inputtext"
              value={this.state.contractObject.allowance}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
              defaultValue={0}
              fixedDecimalScale={true}
              onValueChange={(value) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    allowance:
                      value.floatValue === undefined ? 0 : value.floatValue,
                  },
                });
              }}
            />
          </div> */}
          <div className="p-col-12">
            <span className="boldLabel pr-3">Eligible for CPF</span>
            <Checkbox
              checked={
                this.state.contractObject.cpfEligible === null
                  ? false
                  : this.state.contractObject.cpfEligible
              }
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    cpfEligible: e.checked,
                  },
                })
              }
            />
          </div>
        </div>

        <h5 className="pt-4">Leave Details</h5>

        <div className="p-grid p-fluid">
          {this.showOtherLeaves()}
          {/* <this.AddOtherTypesOfLeave /> */}
        </div>

        <h5 className="pt-4">Other Terms</h5>

        <div className="p-grid p-fluid">
          {/* <div className="p-col-12">
            <span className="boldLabel">
              Payday <span style={{ color: "red" }}>*</span>
            </span>
            <Spinner
              formatInput={false}
              decimalSeparator=""
              thousandSeparator=""
              value={this.state.contractObject.payDay}
              onChange={(e) => {
                if (!isNaN(Number(e.value))) {
                  this.setState({
                    contractObject: {
                      ...this.state.contractObject,
                      payDay: e.value,
                    },
                  });
                }
              }}
              min={0}
              max={31}
              required
            />
          </div> */}
          <div className="p-col-12">
            <span className="boldLabel">Confidentiality</span>
            <InputText
              value={
                this.state.contractObject.confidentiality === null
                  ? ""
                  : this.state.contractObject.confidentiality
              }
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    confidentiality: e.currentTarget.value,
                  },
                })
              }
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Non-Compete</span>
            <InputText
              value={
                this.state.contractObject.nonCompete === null
                  ? ""
                  : this.state.contractObject.nonCompete
              }
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    nonCompete: e.currentTarget.value,
                  },
                })
              }
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Damage Compensation</span>
            <InputText
              value={
                this.state.contractObject.damageCompensation === null
                  ? ""
                  : this.state.contractObject.damageCompensation
              }
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    damageCompensation: e.currentTarget.value,
                  },
                })
              }
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Copyright</span>
            <InputText
              value={
                this.state.contractObject.copyright === null
                  ? ""
                  : this.state.contractObject.copyright
              }
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    copyright: e.currentTarget.value,
                  },
                })
              }
            />
          </div>
        </div>

        <div className="p-grid p-justify-center pt-4">
          <Button type="submit" label={this.state.submitStr} />
        </div>
      </form>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = page;
    }

    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
            search: this.state.redirectParam,
          }}
        />
      );
    }

    return (
      <>
        {/* <Dialog
          className="popupDialog"
          visible={this.state.visible}
          header={this.state.confirmStr}
          modal={true}
          onHide={() => {
            this.props.history.goBack();
          }}
          footer={
            <div>
              <Button
                label="Okay"
                type="button"
                iconPos="left"
                icon="pi pi-check"
                className="p-button-success"
                onClick={() => {
                  this.props.history.goBack();
                }}
              />
            </div>
          }
        >
          {this.state.dialogStr}
        </Dialog> */}

        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <div className="card">
              <h1 className="pb-2">{this.state.headerStr}</h1>

              <div className="requiredRed">
                <p>
                  * Required
                  <br />
                  Please ensure that this employee's <b>department</b> and{" "}
                  <b>job title</b> exists before creating their contract.
                </p>
              </div>
              {display}
            </div>
          </div>
        </div>
      </>
    );
  }
}
