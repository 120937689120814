import React from 'react'

export default function NumberOfComments({ numberOfComments }: { numberOfComments: number }) {
    if (numberOfComments > 1) {
        return (
            <>
                {numberOfComments} Comments
            </>
        )
    } else if (numberOfComments === 1) {
        return (
            <>
                1 Comment
            </>
        )
    } else {
        return (
            <>
                New Comment
            </>
        )
    }
}