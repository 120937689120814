import React, { useState, useEffect, useLayoutEffect, useRef, createRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FullCalendar } from "primereact/fullcalendar";
import { RoomBookingService } from "../../service/RoomBookingService";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { ToastStateContext } from "../resources/ToastContext";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import { RoomBookingModel } from "../../service/RoomBookingService";
import CustomError from "../resources/Error";

import { Redirect, useParams } from "react-router";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Dropdown } from "primereact/dropdown";

import { RoomService } from "../../service/RoomService";

import { ProgressSpinner } from "primereact/progressspinner";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { TaskList } from "../resources/ListOfTasks";
interface dataObj {
  [key: string]: number | string;
}
interface roomModel {
  roomID: string;
  roomName: string;
  roomDescription: string;
  roomStatus: string;
}

function RoomBooking(props: RouteComponentProps<any>) {
  let roomBookingService = new RoomBookingService();
  let roomService = new RoomService();
  let params: { id?: string } = useParams();
  let commonMethods = new CommonMethods();
  let colorArray: string[] = commonMethods.getColourArr();

  const [buttonLabel, setButtonLabel] = useState("Book");
  const [tooltipData, setTooltipData] = useState<any[]>([]);
  const [dialogStr, setDialogStr] = useState<string>("");
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [toMyBookings, SetToMyBookings] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [roomDropdown, setRoomDropdown] = useState<any[]>([]);
  const [roomDropdownByDateAndTime, setRoomDropdownByDateAndTime] = useState<any[]>([]);
  const [loadingRoomDropdownByDateAndTime, setLoadingRoomDropdownByDateAndTime] = useState(false);
  const [formRoomData, setFormRoomData] = useState<roomModel>({
    roomID: "",
    roomName: "",
    roomDescription: "",
    roomStatus: "",
  });
  const [selectedRoom, setSelectedRoom] = useState<string | null>("");
  const [roomData, setRoomData] = useState<roomModel>({
    roomID: "",
    roomName: "",
    roomDescription: "",
    roomStatus: "",
  });
  const [roomBookingData, setRoomBookingData] = useState<RoomBookingModel>(new RoomBookingModel());
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [dialogVisibility, setDialogVisibility] = useState(false);

  // 2020-12-15 PCLQ: For Room Booking Edit Admin Access
  const [toEditBookingAdmin, setToEditBookingAdmin] = useState<boolean>(false);
  const [editBookingID, setEditBookingID] = useState<string>("");
  const [toMainBooking, setToMainBooking] = useState<boolean>(false);
  const [initialRender, setInitialRender] = useState<boolean>(false);
  const [calendarLoaded, setCalendarLoaded] = useState<boolean>(false);

  //     ___    _    _     ___  _  _  ___    _    ___     ___  _____  _   _  ___  ___
  //    / __|  /_\  | |   | __|| \| ||   \  /_\  | _ \   / __||_   _|| | | || __|| __|
  //   | (__  / _ \ | |__ | _| | .` || |) |/ _ \ |   /   \__ \  | |  | |_| || _| | _|
  //    \___|/_/ \_\|____||___||_|\_||___//_/ \_\|_|_\   |___/  |_|   \___/ |_|  |_|
  //

  const [events, setEvents] = useState<dataObj[]>([]);
  const [options] = useState({
    columnHeaderText: (date: Date) => {
      switch (date.getDay()) {
        case 0:
          return "Su";

        case 1:
          return "M";

        case 2:
          return "T";

        case 3:
          return "W";

        case 4:
          return "T";

        case 5:
          return "F";

        case 6:
          return "Sa";
        default:
      }
    },
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    defaultView: ["dayGridMonth"],
    defaultDate: new Date(),
    displayEventEnd: true,
    titleFormat: { year: "numeric", month: "short" },
    height: 700,
    eventLimit: true,
    eventTimeFormat: {
      hour: "numeric",
      minute: "2-digit",
      meridiem: true,
      omitZeroMinute: true,
    },
    // firstDay: 1,
    nowIndicator: true,
    minTime: "08:00:00",
    aspectRatio: 1,
    header: {
      left: "prev,next",
      center: "title",
      right: "dayGridMonth,timeGridWeek,timeGridDay",
    },
    events: (info: any, success: any, failure: any) => {},
    editable: false,
    eventMouseEnter: (info: any) => {
      setTooltipData([
        info.event.extendedProps.room,
        info.event.extendedProps.name,
        info.event.extendedProps.purpose,
        info.event.extendedProps.bookedBy,
        info.event.extendedProps.bookingId,
      ]);
      info.el.setAttribute(
        "data-tip",
        `Room: ${info.event.extendedProps.room} | Booked By: ${info.event.extendedProps.bookedBy} | Purpose: ${info.event.extendedProps.purpose}`
      );
      ReactTooltip.rebuild();
    },
  });

  // 1st useEffect to load up everything, including dropdown
  useEffect(() => {
    // 1) Load up dropdown
    roomBookingService.getRoomDropdownByGCID().then((res) => {
      setRoomDropdown([{ label: "All Rooms", value: null }, ...res]);
    });
    // 2) If params are present, get roombooking details by id, else get ALL room booking details
    if (params.id !== undefined) {
      setButtonLabel("Update");
      roomBookingService.getRoomBookingByID(params.id).then((res) => {
        setSelectedRoom(res.roomID);
        setRoomBookingData(res);
      });
    } else {
      setButtonLabel("Book");
      setSelectedRoom(null);
    }
  }, []);

  useEffect(() => {
    // to prevent this 2nd useEffect from running twice on page load
    if (!initialRender) {
      setInitialRender(true);
    } else {
      setIsLoading(false);
      setCalendarLoaded(false);
    }
  }, [selectedRoom]);

  useEffect(() => {
    fetchRoomDropdownByDateAndTime();
  }, [roomBookingData.bookedDate, roomBookingData.startDateTime, roomBookingData.endDateTime]);

  useEffect(() => {
    checkRoomInDropdown();
  }, [roomDropdownByDateAndTime]);

  const calendarContainerRef = useRef<HTMLDivElement | null>(null);
  const calendarRef = createRef<any>();

  useLayoutEffect(() => {
    if (calendarContainerRef.current && calendarRef.current) {
      const btnPrev = calendarContainerRef.current.getElementsByClassName("fc-prev-button")[0];
      const btnNext = calendarContainerRef.current.getElementsByClassName("fc-next-button")[0];

      const calendarComponent = calendarRef.current;
      const calendarRange = calendarComponent.calendar.state.dateProfile.activeRange;
      const currentDate = calendarComponent.calendar.state.currentDate;
      const currentView = calendarComponent.calendar.state.viewType;
      const startDate = calendarRange.start;
      const endDate = calendarRange.end;

      if (!calendarLoaded) {
        options.defaultDate = currentDate;
        options.defaultView = currentView;
        handleRoomSelectionChange(startDate, endDate);
        setCalendarLoaded(true);
      }

      btnPrev.addEventListener("click", () => setCalendarLoaded(false));
      btnNext.addEventListener("click", () => setCalendarLoaded(false));

      return () => {
        btnPrev.removeEventListener("click", () => setCalendarLoaded(false));
        btnNext.removeEventListener("click", () => setCalendarLoaded(false));
      };
    }
  });

  const handleRoomSelectionChange = async (startDate: Date, endDate: Date) => {
    setIsLoading(true);
    if (selectedRoom === null || selectedRoom === "") {
      resetForm();
      getAllRoomCalendarData(startDate, endDate);
    } else if (selectedRoom.length > 1) {
      await roomService
        .getRoomById(selectedRoom)
        .then((res: any) => {
          setRoomData(res);
        })
        .catch((err) => {
          const error = commonMethods.getErrorMessage(err);
          SetError(error);
        });
      getRoomSpecificCalendarData(startDate, endDate);
    }
  };

  const resetForm = () => {
    let freshForm = new RoomBookingModel();
    freshForm.startDateTime = commonMethods.getTodayAsDate();
    freshForm.endDateTime = commonMethods.getTodayAsDate();
    freshForm.roomID = "";
    setRoomBookingData(freshForm);

    setRoomData({
      roomID: "",
      roomName: "",
      roomDescription: "",
      roomStatus: "",
    });
  };

  const getAllRoomCalendarData = async (startDate: Date, endDate: Date) => {
    setEvents([]);

    // TODO: change to load by month if not in future it's going to take very long.
    await roomBookingService.getAllBookingsByDateRange(startDate, endDate).then(async (res) => {
      let roomColorArray: dataObj[] = [];

      res.forEach((element: any) => {
        if (!roomColorArray.some((e) => e.id === element.roomID)) {
          roomColorArray.push({
            id: element.roomID,
            name: element.roomName,
            color: colorArray.pop()! + " !important",
          });
        }
        let color = roomColorArray.filter((obj) => obj.id === element.roomID).map((obj) => obj.color);
        var tempObj: dataObj = {
          bookingId: element.roomBookingID,
          room: element.roomName,
          name: element.firstName,
          bookedBy: element.bookedBy,
          purpose: element.purpose,
          title: `${element.firstName}, ${element.purpose}`,
          // start: combineTwoDateTimeObjs(
          //   element.startDateTime,
          //   element.bookedDate
          // ).toISOString(),
          start: element.startDateTime,
          // end: combineTwoDateTimeObjs(
          //   element.endDateTime,
          //   element.bookedDate
          // ).toISOString(),
          end: element.endDateTime,
          backgroundColor: color[0],
        };
        setEvents((events) => [...events, tempObj]);
      });
      setIsLoading(false);
    });
  };

  const getRoomSpecificCalendarData = (startDate: Date, endDate: Date) => {
    setEvents([]);

    roomBookingService.getRoomBookingsByRoomIdAndDateRange(selectedRoom!, startDate, endDate).then((res) => {
      if (res.length > 0 && res !== "value0") {
        res.forEach((element: RoomBookingModel) => {
          var tempObj: dataObj = {
            bookingId: element.roomBookingID,
            room: element.roomName,
            name: element.firstName,
            bookedBy: element.bookedBy,
            purpose: element.purpose,
            title: `${element.firstName}, ${element.purpose}`,
            start: combineTwoDateTimeObjs(element.startDateTime, element.bookedDate).toISOString(),
            end: combineTwoDateTimeObjs(element.endDateTime, element.bookedDate).toISOString(),
          };
          setEvents((events) => [...events, tempObj]);
        });
      }
      setIsLoading(false);
    });
  };

  const combineTwoDateTimeObjs = (bookedTime: Date, bookedDate: Date) => {
    bookedDate = moment(bookedDate).toDate();
    bookedTime = moment(bookedTime).toDate();

    let combined = new Date(
      bookedDate.getFullYear(),
      bookedDate.getMonth(),
      bookedDate.getDate(),
      bookedTime.getHours(),
      bookedTime.getMinutes(),
      bookedTime.getSeconds()
    );

    return combined;
  };

  const roomBookingForm = () => {
    if (roomData.roomStatus !== "Active" && roomData.roomStatus !== "") {
      return (
        <>
          <div className="p-grid p-fluid">{roomSelection()}</div>
          <div className="p-col-12 p-md-12 p-lg-6">
            <CustomError message="Selected Room is not active." />
          </div>
        </>
      );
    } else {
      return (
        <div data-tour-id="roombooking-form" data-tour="Fill in your booking details here and click Book!">
          <h1 className="pb-2">Book Room: {roomData.roomName}</h1>
          <div className="requiredRed">
            <p>* Required</p>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <span className="boldLabel">
                Date <span style={{ color: "red" }}>*</span>
              </span>

              <Calendar
                showButtonBar={true}
                dateFormat="dd/mm/yy"
                id="mybookings-datepicker"
                todayButtonClassName="p-justify-center"
                clearButtonClassName="d-none"
                value={new Date(roomBookingData.bookedDate)}
                onChange={(e) => {
                  setRoomBookingData({
                    ...roomBookingData,
                    bookedDate: new Date(e.value.toString()),
                  });
                }}
                inline={false}
                minDate={commonMethods.getStartOfTodayAsDate()}
              ></Calendar>
            </div>

            <div className="p-col-12">
              <div className="row">
                <div className="col-12 col-lg-12 col-xl-6 pt-2">
                  <span className="boldLabel">
                    Start Time <span style={{ color: "red" }}>*</span>
                  </span>
                  <Calendar
                    value={new Date(roomBookingData.startDateTime.toString())}
                    onChange={(e) => {
                      setRoomBookingData({
                        ...roomBookingData,
                        startDateTime: new Date(e.value.toString()),
                      });
                      e.originalEvent.preventDefault();
                    }}
                    onViewDateChange={(e) => {
                      e.originalEvent.preventDefault();
                    }}
                    hourFormat="12"
                    inline={true}
                    timeOnly={true}
                    stepMinute={15}
                  ></Calendar>
                </div>

                <div className="col-12 col-lg-12 col-xl-6 pt-2">
                  <span className="boldLabel">
                    End Time <span style={{ color: "red" }}>*</span>
                  </span>
                  <Calendar
                    value={new Date(roomBookingData.endDateTime.toString())}
                    onChange={(e) => {
                      setRoomBookingData({
                        ...roomBookingData,
                        endDateTime: new Date(e.value.toString()),
                      });
                    }}
                    hourFormat="12"
                    inline={true}
                    timeOnly={true}
                    stepMinute={15}
                    maxDate={commonMethods.getEndOfTodayAsDate()}
                  ></Calendar>
                </div>
              </div>
            </div>

            {roomSelection()}

            <div className="p-col-12">
              <span className="boldLabel">
                Purpose <span style={{ color: "red" }}>*</span>
              </span>
              <InputText
                disabled={roomBookingData.roomID === null || roomBookingData.roomID === ""}
                value={roomBookingData.purpose}
                onChange={(e) => {
                  setRoomBookingData({
                    ...roomBookingData,
                    purpose: e.currentTarget.value,
                  });
                }}
                required
              />
            </div>
          </div>
          <div className="p-grid p-justify-center pt-4">
            <Button
              type="submit"
              label={buttonLabel}
              disabled={roomBookingData.roomID === null || roomBookingData.roomID === ""}
              onClick={(e) => {
                handleSubmit(e);
              }}
            />
          </div>
        </div>
      );
    }
  };

  async function fetchRoomData(roomId: string) {
    try {
      const res = await roomService.getRoomById(roomId);
      return res;
    } catch (err) {
      SetError(commonMethods.getErrorMessage(err));
      return "";
    }
  }

  function checkRoomInDropdown() {
    const roomInDropdown = roomDropdownByDateAndTime.find((room) => room.value === roomBookingData.roomID);
    if (!roomInDropdown) {
      setRoomBookingData({
        ...roomBookingData,
        roomID: "",
      });
    }
  }

  async function fetchRoomDropdownByDateAndTime() {
    setLoadingRoomDropdownByDateAndTime(true);
    const bookingObj = JSON.parse(JSON.stringify(roomBookingData));

    if (!bookingObj.bookedDate) {
      bookingObj.bookedDate = new Date();
    } else {
      bookingObj.bookedDate = new Date(bookingObj.bookedDate);
    }

    bookingObj.startDateTime = combineTwoDateTimeObjs(bookingObj.startDateTime, bookingObj.bookedDate);

    bookingObj.endDateTime = combineTwoDateTimeObjs(bookingObj.endDateTime, bookingObj.bookedDate);

    try {
      const res: { label: string; value: string }[] = await roomBookingService.getRoomDropdownByByDateAndTime(
        moment(bookingObj.bookedDate).format("YYYY-MM-DD HH:mm:ss"),
        moment(bookingObj.startDateTime).format("YYYY-MM-DD HH:mm:ss"),
        moment(bookingObj.endDateTime).format("YYYY-MM-DD HH:mm:ss")
      );

      const allRoom: { label: string; value: string }[] = await roomBookingService.getRoomDropdownByGCID();

      const mapped = allRoom.map((room) => {
        const roomInDropdown = res.find((r) => r.value === room.value);
        if (roomInDropdown) {
          return roomInDropdown;
        } else {
          return { label: `${room.label} (Unavailable)`, value: room.value, disabled: true };
        }
      });

      setRoomDropdownByDateAndTime(mapped);
    } catch (err) {
      SetError(commonMethods.getErrorMessage(err));
    } finally {
      setLoadingRoomDropdownByDateAndTime(false);
    }
  }

  const roomSelection = () => {
    return (
      <div className="p-col-12">
        <div>
          <span className="boldLabel">
            Available Room <span style={{ color: "red" }}>*</span>
          </span>
        </div>
        <Dropdown
          value={roomBookingData.roomID}
          disabled={loadingRoomDropdownByDateAndTime}
          options={roomDropdownByDateAndTime}
          placeholder="Select available room"
          onChange={async (e) => {
            setFormRoomData(await fetchRoomData(e.value));
            setRoomBookingData({
              ...roomBookingData,
              roomID: e.value,
            });
          }}
        />
        <div>
          <br />
          <span className="boldLabel">Description:</span>
          <span>{formRoomData.roomDescription ? formRoomData.roomDescription : ""}</span>
        </div>
      </div>
    );
  };

  const handleSubmit = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }
    //create the booking obj
    let bookingObj = roomBookingData;

    bookingObj.startDateTime = combineTwoDateTimeObjs(bookingObj.startDateTime, bookingObj.bookedDate);

    bookingObj.endDateTime = combineTwoDateTimeObjs(bookingObj.endDateTime, bookingObj.bookedDate);

    if (bookingObj.roomBookingID.length > 0) {
      roomBookingService
        .updateRoomBooking(bookingObj)
        .then((res) => {
          setIsLoading(false);
          SetSuccess(
            "Booking Updated Successfully!",
            `Successfully booked room ${roomData.roomName} from ${commonMethods.displayTime12Hour(
              new Date((res.startDateTime = "Z"))
            )} to ${commonMethods.displayTime12Hour(new Date(res.endDateTime + "Z"))}`
          );
          setToMainBooking(true);
        })
        .catch((err) => {
          setIsLoading(false);
          const error = commonMethods.getErrorMessage(err);
          if (error !== null) {
            SetError(error);
          } else {
            SetError("Unable to update a room booking");
          }
        });
    } else {
      roomBookingService
        .createBooking(bookingObj)
        .then((res) => {
          setIsLoading(false);
          SetSuccess(
            `Successfully booked room ${roomData.roomName} from ${commonMethods.displayTime12Hour(
              new Date(res.startDateTime)
            )} to ${commonMethods.displayTime12Hour(new Date(res.endDateTime))}`
          );
          setSelectedRoom(null);
        })
        .catch((err) => {
          setIsLoading(false);
          const error = commonMethods.getErrorMessage(err);
          if (error !== null) {
            SetError(error);
          } else {
            SetError("Unable to create a room booking");
          }
        });
    }
  };

  const validateForm = () => {
    if (roomBookingData.purpose === null || roomBookingData.purpose === "") {
      SetError("Purpose is required.");
      return false;
    }
    if (!isValidBookingTime(roomBookingData.startDateTime, roomBookingData.endDateTime)) {
      return false;
    }
    return true;
  };

  const isValidBookingTime = (startTime: string | Date, endtime: string | Date) => {
    if (new Date(startTime).toISOString() > new Date(endtime).toISOString()) {
      SetError("Booking End Time cannot be before Start Time");
      return false;
    } else if (new Date(startTime).toISOString() === new Date(endtime).toISOString()) {
      SetError("Booking Start Time cannot be the same as End Time");
      return false;
    }

    return true;
  };

  const tooltipDisplay = () => {
    if (
      tooltipData.length > 0 &&
      props.userProfile.taskList !== undefined &&
      commonMethods.isArrayPresentInArray(props.userProfile.taskList, [TaskList.roomaccess])
    ) {
      let table = (
        <>
          <p>Are you sure you want to alter this booking?</p>
          <table className="leave-info-dialog table">
            <tbody>
              <tr>
                <td className="font-weight-bold">Room Name:</td>
                <td>{tooltipData[0]}</td>
              </tr>

              <tr>
                <td className="font-weight-bold">Booked By:</td>
                <td>{tooltipData[1]}</td>
              </tr>

              <tr>
                <td className="font-weight-bold">Purpose:</td>
                <td>{tooltipData[2]}</td>
              </tr>
            </tbody>
          </table>
        </>
      );
      return (
        <div style={{ maxWidth: "200px" }}>
          <p className="mt-1">
            Room: {tooltipData[0]}
            <br></br>
            Booked By: {tooltipData[1]}
            <br></br>
            Purpose: {tooltipData[2]}
          </p>
          <p className="text-right mt-1">
            <Button
              label="Edit"
              onClick={() => {
                setDialog("Edit Booking", table);
                setDialogVisibility(true);
              }}
            />
            <Button
              label="Delete"
              className="p-button-danger"
              onClick={() => {
                setDialog("Delete Booking", table);
                setDialogVisibility(true);
              }}
            />
          </p>
        </div>
      );
    } else {
      return (
        <div style={{ maxWidth: "200px" }}>
          <p className="mt-1">
            Room: {tooltipData[0]}
            <br></br>
            Booked By: {tooltipData[1]}
            <br></br>
            Purpose: {tooltipData[2]}
          </p>
        </div>
      );
    }
  };

  const setDialog = (header: string, content: any) => {
    setConfirmStr(header);
    setDialogStr(content);
  };

  const display = () => {
    return (
      <>
        <div>
          <h1 className="pb-2">
            Bookings Overview {roomData.roomName === "" ? `(All Rooms)` : `(${roomData.roomName})`}
            <span
              className="pl-3"
              // data-tour-id="my-bookings"
              // data-tour="View your booking history here."
            >
              <Button
                className="button-document"
                label="My Bookings"
                icon="pi pi-calendar"
                iconPos="right"
                onClick={() => {
                  SetToMyBookings(true);
                }}
              />
            </span>
          </h1>
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              {isLoading ? (
                <div className="text-center">
                  <ProgressSpinner />
                </div>
              ) : (
                <>
                  <div className="p-col-12">
                    <div>
                      <span className="boldLabel">Selected Room</span>
                    </div>
                    <Dropdown
                      value={selectedRoom}
                      options={roomDropdown}
                      placeholder="All Rooms"
                      onChange={(e: any) => setSelectedRoom(e.value)}
                    />
                  </div>
                  <div className="p-col-12" ref={calendarContainerRef}>
                    <FullCalendar events={events} options={options} ref={calendarRef} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="row">
      {toMainBooking ? <Redirect push to="/room/book" /> : null}
      {toEditBookingAdmin ? <Redirect push to={"/room/book/" + editBookingID} /> : null}
      {toMyBookings ? <Redirect push to="/room/mybookings" /> : null}
      <ReactTooltip effect="solid" place="top" delayHide={600} clickable={true} className="tooltipFont">
        {tooltipDisplay()}
      </ReactTooltip>
      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        //dismissableMask={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Okay"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                if (confirmStr.includes("Delete")) {
                  roomBookingService
                    .deleteRoomBooking(tooltipData[4])
                    .then((res) => {
                      SetSuccess("Booking has been deleted.");
                      setDialogVisibility(false);
                    })
                    .catch(async (err) => {
                      const error = commonMethods.getErrorMessage(err);
                      SetError(error);
                      setDialogVisibility(false);
                    })
                    .finally(() => {
                      setEvents(events.filter((event) => event.bookingId !== tooltipData[4]));
                    });
                } else if (confirmStr.includes("Edit")) {
                  setToEditBookingAdmin(true);
                  setEditBookingID(tooltipData[4]);
                }
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>
      <div className="col-12 col-lg-5 col-xl-5">
        <div className="card">{roomBookingForm()}</div>
      </div>
      <div className="col-12 col-lg-7 col-xl-7">
        <div className="card">{display()}</div>
      </div>
    </div>
  );
}

export default RoomBooking;
