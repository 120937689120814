import React from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

let menu: any;

const Header = ({ setLoginModal }: { setLoginModal: any }) => {
  const items = [
    {
      label: "Home",
      icon: "pi pi-fw pi-home",
      url: "/"
    },
    {
      label: "About Us",
      icon: "pi pi-fw pi-users",
      url: "/about-us"
    },
    {
      label: "Features",
      icon: "pi pi-fw pi-users",
      url: "/features"
    },
    {
      label: "Plans",
      icon: "pi pi-fw pi-users",
      url: "/priceplans"
    },
    {
      label: "Contact",
      icon: "pi pi-fw pi-plus-circle",
      url: "/contactus"
    },
    {
      label: "Register",
      icon: "pi pi-fw pi-plus-circle",
      url: "/sme"
    },
    {
      label: "Login",
      icon: "pi pi-fw pi-chevron-circle-right",
      command: () => setLoginModal(true)
    }
  ];

  let register;
  if (!window.location.href.includes("//tsloffice.com")) {
    register = (
      <li className="nav-item">
        <a
          className="btn btn-login ml-md-3"
          id="login-button"
          href={items[5].url}
        >
          Try for free
        </a>

        {/* <a className="nav-link" href={items[5].url}>
            Register
          </a> */}
      </li>
    );
  }
  return (
    <header className="border-bottom" data-block-type="headers" data-id="1">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <a className="navbar-brand" href={items[0].url}>
            <img   src="/assets/NEUHR-BW.png" height="40" alt="" />
          </a>

          <div className="navbar-toggler">
            <Menu model={items} popup={true} ref={el => (menu = el)} />
            <Button icon="pi pi-bars" onClick={event => menu.toggle(event)} />
          </div>

          <div className="collapse navbar-collapse" id="navbarNav13">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a className="nav-link" href={items[0].url}>
                  Home <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={items[1].url}>
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={items[2].url}>
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={items[3].url}>
                  Pricing
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={items[4].url}>
                  Request Demo
                </a>
              </li>
            </ul>
            <ul className="navbar-nav justify-content-end ml-auto">
              <a
                className="nav-link"
                id="login-button"
                onClick={() => setLoginModal(true)}
              >
                Login
              </a>
     
              {register}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
