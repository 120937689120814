import React from "react";
import QuicklinksDashboard from "./QuicklinksDashboard";
import CircularsDashboard from "../circulars/CircularsDashboard";
import { RouteComponentProps } from "../resources/CommonMethods";

export function QCDashboard(props: RouteComponentProps<any>) {
  return (
    <div className="row">
      
      <div className="col-12 col-lg-7">
        <CircularsDashboard {...props} />
      </div>
      
      <div className="col-12 col-lg-5"> 
        {" "}
        <QuicklinksDashboard {...props} /> 
      </div>
    </div>
  );
}
