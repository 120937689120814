import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'
import React, { useContext, useState } from 'react'
import PublicProfileService from '../../Service/PublicProfileService'
import { PP_ACTIONS, PublicProfileContext } from './PublicProfile'

export default function AboutDialog({
    header,
    isShowDialog,
    setIsShowDialog,
}: {
    header: string
    isShowDialog: boolean
    setIsShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { state, dispatch } = useContext(PublicProfileContext);
    const [content, setContent] = useState("")

    async function submitEditedAbout(evt: React.FormEvent<HTMLFormElement>) {
        evt.preventDefault();
        const publicProfileService = new PublicProfileService();
        if (!state.userProfile.hasCreatedPublicProfile) {
            console.log('inserting aboutMe')
            const result = await publicProfileService.insertAboutMe(state.userProfile.userId, content)
            dispatch({ type: PP_ACTIONS.ADD_ABOUT, payload: result.data.aboutMe })
        }
        if (state.userProfile.hasCreatedPublicProfile) {
            console.log('updating aboutMe')
            const result = await publicProfileService.editAboutMe(state.userProfile.userId, content)
            dispatch({ type: PP_ACTIONS.EDIT_ABOUT, payload: result.data.aboutMe })
        }
        setIsShowDialog(false)
    }

    const headerElement = (
        <div>
            <h1>{header}</h1>
        </div>
    )

    function onShowDialog() {
        if (state.userProfile.aboutMe) setContent(state.userProfile.aboutMe)
    }

    function hideDialog() {
        setIsShowDialog(false)
    }

    return (
        <div>
            <Dialog
                visible={isShowDialog}
                onHide={hideDialog}
                className="rounded-border public-profile-dialog"
                showHeader={true}
                header={headerElement}
                blockScroll
                onShow={onShowDialog}
                dismissableMask={true}
            >
                <form onSubmit={submitEditedAbout}>
                    <div className="form-flex-container public-profile-textarea">
                        <InputTextarea
                            className="custom-textarea form-flex-item"
                            cols={30}
                            rows={7}
                            placeholder="Type here"
                            value={content}
                            onChange={(e: React.FormEvent<HTMLTextAreaElement>) => setContent(e.currentTarget.value)}
                            autoFocus
                            autoResize
                        />
                        <div className="form-flex-item">
                        </div>
                        <div className="postform-buttons form-flex-item">
                            <Button
                                type="submit"
                                label="Submit"
                                className="postform-submit"
                            />
                        </div>
                    </div>
                </form>
            </Dialog >
        </div>
    )
}
