import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import useForm from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import { Password } from "primereact/password";

import { ForgetPasswordService } from "../service/ForgetPasswordService";
import { CommonMethods } from "./resources/CommonMethods";

function ForgetPassword() {
  const fps = new ForgetPasswordService();
  const commonMethods = new CommonMethods();
  const [displayMsg, setDisplayMsg] = useState<any>(<></>);
  const [displayForm, setDisplayForm] = useState<boolean>(true);
  const [token, setToken] = useState<string>("");
  const [validated, setValidated] = useState<boolean>(false);
  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    let url = new URL(window.location.toString());
    let params = new URLSearchParams(url.search);
    validateLink(params.get("token"));
  }, []);

  const validateLink = (token: any) => {
    fps
      .validateLink(token)
      .then((res) => {
        setValidated(true);
        setToken(token);
      })
      .catch((err) => {
        setValidated(false);
      });
  };

  const submitForm = (data: any) => {
    if (validated) {
      if (data.password !== data.password2) {
        setDisplayMsg(<span>Both passwords do not match!</span>);
      } else {
        fps
          .newPassword(data, token)
          .then((res) => {
            setDisplayMsg(
              "Your password has been reset! You can login with your new password now."
            );
            setDisplayForm(false);
          })
          .catch((err) => {
            setDisplayMsg(
              "There was an error in resetting your password. Your reset link might be expired."
            );
            setDisplayForm(false);
          });
      }
    } else {
      fps
        .postEmail(data)
        .then((res) => {
          setDisplayMsg(
            <span>
              An email has been sent to you at {data.email}! Please check your
              inbox for instructions on how to reset your password.
            </span>
          );
        })
        .catch((err) => {
          let errMsg = commonMethods.getErrorMessage(err);
          setDisplayMsg(errMsg);
        });
    }
  };
  return (
    <div className="container my-5">
      <div className="row my-5 justify-content-center">
        <div className="col-xl-6 col-12 my-5">
          <div className="card">
            <h1>Forgotten Your Password?</h1>
            <div className="pb-2">
            We've got you covered! Enter your email to reset your password.
          </div>
            {displayMsg}
            {displayForm ? (
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="p-grid p-fluid my-2">
                  {validated ? (
                    <>
                      <div className="p-col-12">
                        <span className="boldLabel">New Password:</span>
                        <RHFInput
                          as={<Password type="text" />}
                          register={register}
                          setValue={setValue}
                          name="newPassword"
                        />
                      </div>
                      <div className="p-col-12">
                        <span className="boldLabel">Confirm New Password:</span>
                        <RHFInput
                          as={<Password type="text" />}
                          register={register}
                          setValue={setValue}
                          name="confirmPassword"
                        />
                      </div>
                    </>
                  ) : (
                    <div className="p-col-12">
                      <span className="boldLabel">Email:</span>
                      <RHFInput
                        as={
                          <InputText
                            keyfilter="email"
                            name="email"
                            type="text"
                          />
                        }
                        register={register}
                        setValue={setValue}
                        name="email"
                      />
                    </div>
                  )}
                </div>
                <div className="p-grid p-justify-end pt-1">
                  <Button type="submit" label="Submit" className="m-1" />
                </div>
              </form>
            ) : null}
          </div>
        </div>
      </div>
      <div className="py-5"></div>
      <div className="py-5"></div>
    </div>
  );
}

export default ForgetPassword;
