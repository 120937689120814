import React, { useEffect, useState } from 'react'
import { ACTIONS } from '../../DispatchActions';
import { CommentModel } from '../../Model/CommentModel'
import CompanyFeedService from '../../Service/CompanyFeedService';

export default function ViewRepliesButton({
    item,
    dispatch,
    parentComponent,
}: {
    item: CommentModel;
    dispatch: React.Dispatch<{
        type: any;
        payload: any;
    }>
    parentComponent: string | undefined
}) {
    async function loadCommentReplies(comment: CommentModel) {
        // order ascending
        // if replies already loaded, just toggle without adding (dispatch)
        const parentCommentId = comment.commentId
        const companyFeedService = new CompanyFeedService();
        const result = await companyFeedService.getChildComments(parentCommentId!)
        console.log('child comments', result)
        if (parentComponent === "TodayBirthdayPost")
            dispatch({ type: ACTIONS.BIRTHDAY_POST.LOAD_CHILD_COMMENTS, payload: { childComments: result, postId: comment.postId, parentCommentId } })
        else
            dispatch({ type: ACTIONS.LOAD_CHILD_COMMENTS, payload: { childComments: result, postId: comment.postId, parentCommentId } })
    }

    let viewOrHide = "View"
    // if (item.isShowReplies) viewOrHide = "Hide"

    if (item.numberOfChildComments > 1) {
        return (
            <span className="comment-view-replies"
                onClick={() => loadCommentReplies(item)}>
                {viewOrHide} {item.numberOfChildComments} replies
            </span>
        )
    }
    if (item.numberOfChildComments === 1) {
        return (
            <span className="comment-view-replies"
                onClick={() => loadCommentReplies(item)}>
                {viewOrHide} reply
            </span>
        )
    }
    return null
}