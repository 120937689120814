import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { TaxFilingService } from "../../service/TaxFilingService";
import { UserService } from "../../service/UserService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { UserModel } from "../user/User";
import {
  TaxFilingHeader,
  TaxFilingMethods,
  TaxHeaderStatus
} from "./TaxFilingModel";

export default function TaxHeaderDetails(props: RouteComponentProps<any>) {
  const taxFilingService = new TaxFilingService();
  const userService = new UserService();
  const commonMethods = new CommonMethods();

  const [edit, setEdit] = useState<boolean>(false);
  const [headerString, setHeaderString] = useState<string>("");
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const [year, setYear] = useState<number>(props.others?.year);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(
    "An unidentified error has occurred"
  );
  const [orgIDTypeDropdown, setOrgIDTypeDropdown] = useState<any[]>([]);
  const [authorizedDropdown, setAuthorizedDropdown] = useState([]);
  const [fileTypeDropdown, setFileTypeDropdown] = useState<any[]>([]);
  const [taxHeaderId, setTaxHeaderId] = useState<string>("");

  const [header, setHeader] = useState<TaxFilingHeader>(new TaxFilingHeader());
  const [headerInitial, setHeaderInitial] = useState<TaxFilingHeader>(
    new TaxFilingHeader()
  );

  useEffect(() => {
    if (taxHeaderId !== "") {
      init();
    }
  }, [taxHeaderId]);

  useEffect(() => {
    getDropdown();
  }, []);

  useEffect(() => {
    if (props.others.taxHeaderId !== "" && props.others.taxHeaderId !== null) {
      setIsLoading(true);
      setTaxHeaderId(props.others.taxHeaderId);
    }
  }, [props.others.taxHeaderId]);

  const init = async () => {
    taxFilingService
      .getHeaderDetails(taxHeaderId)
      .then((res: TaxFilingHeader) => {
        setHeader(res);
        setHeaderInitial(res);
        setHeaderStr(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMsg(commonMethods.getErrorMessage(err));
        setIsError(true);
        setIsLoading(false);
      });
  };

  const setHeaderStr = (res: TaxFilingHeader) => {
    if (res.status === TaxHeaderStatus.NOTSUBMITTED) {
      setHeaderString("");
    } else if (res.status === TaxHeaderStatus.SUBMITTED) {
      setHeaderString(
        "This form has been submitted."
      );
    }
  };
  const getDropdown = async () => {
    setOrgIDTypeDropdown(TaxFilingMethods.getOrganizationTypesDropdown());
    try {
      await commonMethods.getUserDropdownForGroupCompany().then((res) => {
        setAuthorizedDropdown(res);
      });
      await taxFilingService.getFileTypeDropdown().then((res) => {
        setFileTypeDropdown(res);
      });
    } catch (e) {
      setErrorMsg(commonMethods.getErrorMessage(e));
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const setUserInfo = (userid: string) => {
    userService
      .getUserById(userid)
      .then((res: UserModel) => {
        setHeader({
          ...header,
          nameOfAuthorisedPerson: res.fullName,
          telephoneNumber: res.pP_MobileNumber,
          emailAddressOfAuthorisedPerson: res.email,
          authorizedPersonUserID: res.userID,
        });
      })
      .catch((err) => {
        setIsError(true);
        setErrorMsg(commonMethods.getErrorMessage(err));
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    taxFilingService
      .updateHeaderDetails(header)
      .then((res) => {
        SetSuccess("IRAS Header has been updated");
        setEdit(false);
        setHeaderInitial(res);
      })
      .catch((err) => {
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = (
      <div className="text-center">
        <ProgressSpinner />
      </div>
    );
  } else {
    display = (
      <div className="row">
        <div className="col-12 col-xl-12">
          {/* Header */}
          <h2 className="pb-2">
            IRAS Header
            {header.status !== TaxHeaderStatus.SUBMITTED && (
              <Button
                label="Edit"
                className="editButtonFloatRight"
                onClick={(event) => {
                  event.preventDefault();
                  setEdit(true);
                }}
                hidden={edit}
              />
            )}
          </h2>
          <p>{headerString}</p>
          <div className="requiredRed">
            <p>* Required</p>
          </div>

          {/* Form */}
          <form onSubmit={handleSubmit}>
            <div className="row">
              {/* Left side  */}
              <div className="col-6">
                {/* <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <span className="boldLabel">Tax year</span>
                    <InputText
                      id="taxYear"
                      value={header.incomeYear}
                      disabled
                    />
                  </div>
                </div> */}

                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <span className="boldLabel">Form Type</span>
                    <InputText id="formType" value={header.formType} disabled />
                  </div>
                </div>

                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <span className="boldLabel">
                      File Type <span style={{ color: "red" }}>*</span>
                    </span>
                    <Dropdown
                      id="organizationIDNo"
                      options={fileTypeDropdown}
                      value={header.fileType}
                      placeholder="Please Select"
                      disabled={!edit}
                      onChange={(event) =>
                        setHeader({
                          ...header,
                          fileType: event.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>

                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <span className="boldLabel">Company Name</span>
                    <InputText
                      id="companyName"
                      value={header.organizationName}
                      disabled
                    />
                  </div>
                </div>

                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <span className="boldLabel">
                      Organisation ID Type{" "}
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Dropdown
                      id="organizationIDType"
                      value={header.organizationIDType}
                      options={orgIDTypeDropdown}
                      disabled={!edit}
                      onChange={(event) =>
                        setHeader({
                          ...header,
                          organizationIDType: event.value,
                        })
                      }
                      scrollHeight="350px"
                      required
                    />
                  </div>
                </div>

                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <span className="boldLabel">
                      Organisation ID No <span style={{ color: "red" }}>*</span>
                    </span>
                    <InputText
                      id="organizationIDNo"
                      value={header.organizationIDNo}
                      disabled={!edit}
                      maxLength={11}
                      // Longest Organisation ID No. is 11
                      keyfilter={/^[\w ()+=,./?:{}~!@#$%*\-\[\]\\]+$/}
                      onChange={(event) =>
                        setHeader({
                          ...header,
                          organizationIDNo: event.currentTarget.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Right side   */}
              <div className="col-6">
                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <span className="boldLabel">
                      Authorised person <span style={{ color: "red" }}>*</span>
                    </span>
                    <Dropdown
                      id="authorizedPerson"
                      value={header.authorizedPersonUserID}
                      options={authorizedDropdown}
                      onChange={(event) => {
                        setUserInfo(event.value);
                      }}
                      disabled={!edit}
                      required
                    />
                  </div>
                </div>

                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <span className="boldLabel">
                      Authorised person's Full Name{" "}
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <InputText
                      id="authorizedPersonFullname"
                      value={header.nameOfAuthorisedPerson}
                      disabled={!edit}
                      maxLength={30}
                      keyfilter={/^[\w ()+=,./?:{}~!@#$%*\-\[\]\\]+$/}
                      onChange={(event) =>
                        setHeader({
                          ...header,
                          nameOfAuthorisedPerson: event.currentTarget.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>

                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <span className="boldLabel">Designation</span>
                    <InputText
                      id="designation"
                      value={header.designation}
                      disabled={!edit}
                      maxLength={30}
                      keyfilter={/^[\w ()+=,./?:{}~!@#$%*\-\[\]\\]+$/}
                      onChange={(event) =>
                        setHeader({
                          ...header,
                          designation: event.currentTarget.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <span className="boldLabel">
                      Phone number <span style={{ color: "red" }}>*</span>
                    </span>
                    <InputText
                      id="phoneNumber"
                      value={header.telephoneNumber}
                      disabled={!edit}
                      maxLength={20}
                      keyfilter={/^[\w ()+=,./?:{}~!@#$%*\-\[\]\\]+$/}
                      onChange={(event) =>
                        setHeader({
                          ...header,
                          telephoneNumber: event.currentTarget.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>

                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <span className="boldLabel">
                      Email <span style={{ color: "red" }}>*</span>
                    </span>{" "}
                    <InputText
                      id="email"
                      value={header.emailAddressOfAuthorisedPerson}
                      disabled={!edit}
                      maxLength={60}
                      keyfilter={/^[\w ()+=,./?:{}~!@#$%*\-\[\]\\]+$/}
                      onChange={(event) =>
                        setHeader({
                          ...header,
                          emailAddressOfAuthorisedPerson:
                            event.currentTarget.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <br />
            <div className="p-grid p-justify-center pt-2">
              {edit ? (
                <>
                  <Button
                    type="submit"
                    label={"Save"}
                    className="mr-2"
                    // onClick={(event) => {
                    //   // setHeaderInitial(header);
                    // }}
                  />
                  <Button
                    type=""
                    label={"Cancel"}
                    onClick={(event) => {
                      event.preventDefault();
                      setEdit(false);
                      setHeader(headerInitial);
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </form>

          <hr></hr>
        </div>
      </div>
    );
  }

  return (
    <div>
      {" "}
      <div className="row">
        <div className="col-12 col-xl-12">{display}</div>{" "}
      </div>
    </div>
  );
}
