import { Button } from "primereact/button";
import { Dropdown } from "primereact/components/dropdown/Dropdown";
import { InputText } from "primereact/components/inputtext/InputText";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { ClaimsService } from "../../service/ClaimsService";
import {
  CommonMethods,
  DropdownModel,
  ParamIDTypes,
  RouteComponentProps,
} from "../resources/CommonMethods";
import { ClaimsModel, ClaimStatuses, ClaimActionType } from "./ClaimsModel";
import { ToastStateContext } from "../resources/ToastContext";
import NumberFormat from "react-number-format";
import { TaskList } from "../resources/ListOfTasks";
import CustomError from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";
import { CommonDocumentMethods } from "../resources/CommonDocumentMethods";

export function DisplayClaim(props: RouteComponentProps) {
  const commonMethods = new CommonMethods();
  const [claim, setClaim] = useState<ClaimsModel>(new ClaimsModel());
  const [dialogVisible, setDialogVisible] = useState(false);
  const [action, setAction] = useState<string>("");
  const [actionType, setActionType] = useState<string>("");
  const claimsService = new ClaimsService();
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [accountingCodes, setAccountingCodes] = useState<DropdownModel[]>(
    new Array<DropdownModel>()
  );
  const [endorsePerms] = useState(
    props.userProfile.taskList.includes(TaskList.endorseclaimaccess)
  );
  const [approvePerms] = useState(
    props.userProfile.taskList.includes(TaskList.approveclaimsaccess)
  );
  const [displayReceipts, setDisplayReceipts] = useState<string[]>([]);
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const [init, setInit] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );

  const { id } = useParams<ParamIDTypes>();

  useEffect(() => {
    getClaim();
  }, []);

  useEffect(() => {
    if (claim.claimID !== null && init) {
      setConditions();
    }
  }, [claim, init]);

  const getClaim = () => {
    if (id) {
      claimsService
        .getClaimByID(id)
        .then((res: ClaimsModel) => {
          try {
            setDisplayReceipts(JSON.parse(res.receiptPath));
          } catch (error) {
            setDisplayReceipts([res.receiptPath]);
          }
          setClaim(res);
          setInit(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsError(true);
          setErrorMessage(commonMethods.getErrorMessage(err));
        });
    }
  };

  const setConditions = () => {
    if (claim.claimStatus === ClaimStatuses.Pending) {
      if (claim.submittedBy === props.userProfile.userID) {
        setActionType(ClaimActionType.Cancel);
      } else if (
        endorsePerms &&
        claim.submittedBy !== props.userProfile.userID
      ) {
        setActionType(ClaimActionType.Endorse);
      }
    } else if (claim.claimStatus === ClaimStatuses.Endorsed) {
      if (approvePerms) {
        getAccountingCodes();
        setActionType(ClaimActionType.Approve);
      }
    } else {
      setActionType("");
    }

    setInit(false);
  };

  const getAccountingCodes = () => {
    commonMethods
      .getAccountingCodeDropdown()
      .then((res) => {
        setAccountingCodes(res);
      })
      .catch((err) => {
        setIsError(true);
        setErrorMessage(commonMethods.getErrorMessage(err));
      });
  };

  const actionButtons = () => {
    let displayButtons;
    if (actionType === ClaimActionType.Cancel) {
      displayButtons = (
        <Button
          tooltip="Cancel Claim"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-times"
          className="p-button-danger mr-1"
          label="Cancel Claim"
          onClick={() => {
            setAction(ClaimActionType.Cancel);
            setDialogVisible(true);
          }}
        />
      );
    }
    if (actionType === ClaimActionType.Endorse) {
      displayButtons = (
        <>
          <Button
            tooltip="Endorse Claim"
            tooltipOptions={{ position: "top" }}
            type="button"
            icon="pi pi-check"
            className="p-button-success mr-1"
            label="Endorse Claim"
            onClick={() => {
              setAction(ClaimActionType.Endorse);
              setDialogVisible(true);
            }}
          ></Button>
          <Button
            tooltip="Reject Claim"
            tooltipOptions={{ position: "top" }}
            type="button"
            icon="pi pi-times"
            className="p-button-danger"
            label="Reject Claim"
            onClick={() => {
              setAction(ClaimActionType.Reject);
              setDialogVisible(true);
            }}
          />
        </>
      );
    }
    if (actionType === ClaimActionType.Approve) {
      displayButtons = (
        <>
          {" "}
          <Button
            tooltip="Approve Claim"
            tooltipOptions={{ position: "top" }}
            type="button"
            icon="pi pi-check"
            className="p-button-success mr-1"
            label="Approve Claim"
            onClick={() => {
              setAction(ClaimActionType.Approve);
              setDialogVisible(true);
            }}
          ></Button>
          <Button
            tooltip="Reject Claim"
            tooltipOptions={{ position: "top" }}
            type="button"
            icon="pi pi-times"
            className="p-button-danger"
            label="Reject Claim"
            onClick={() => {
              setAction(ClaimActionType.Reject);
              setDialogVisible(true);
            }}
          />
        </>
      );
    }
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {displayButtons}
      </div>
    );
  };

  const endorsedByDisplay = () => {
    let status = ClaimStatuses.Endorsed;
    if (claim.endorsedBy) {
      if (
        claim.claimStatus === ClaimStatuses.Rejected &&
        claim.approvedBy === null
      ) {
        status = ClaimStatuses.Rejected;
      }
      return (
        <>
          <hr></hr>
          <div className="row">
            <div className="col-12">
              <span className="boldLabel">{status} By: </span>
              {claim.endorsedByName}
            </div>
            <div className="col-12">
              <span className="boldLabel">{status} Remarks: </span>
              {claim.endorsedRemarks}
            </div>
            <div className="col-12">
              <span className="boldLabel">{status} Date: </span>
              {commonMethods.displayDateTime(claim.endorsedDate)}
            </div>
          </div>
        </>
      );
    }
  };

  const approvedByDisplay = () => {
    if (claim.approvedBy) {
      return (
        <>
          <hr></hr>
          <div className="row">
            <div className="col-12">
              <span className="boldLabel"> {claim.claimStatus} By: </span>
              {claim.approvedByName}
            </div>

            <div className="col-12">
              <span className="boldLabel"> {claim.claimStatus} Date: </span>
              {commonMethods.displayDateTime(claim.approvedDate)}
            </div>

            <div className="col-12">
              <span className="boldLabel"> {claim.claimStatus} Remarks: </span>
              {claim.approvingRemarks}
            </div>

            <div className="col-12">
              <span className="boldLabel"> Payment Reference: </span>
              {claim.paymentReference}
            </div>
          </div>
        </>
      );
    }
  };

  const processedByDisplay = () => {
    if (claim.approvedBy) {
      return (
        <>
          <hr></hr>
          <div className="row">
            <div className="col-12">
              <span className="boldLabel"> Processed By: </span>
              {claim.processedByName}
            </div>
            <div className="col-12">
              <span className="boldLabel"> Processed Date: </span>
              {commonMethods.displayDateTime(claim.processedDate)}
            </div>
          </div>
        </>
      );
    }
  };

  const setRemarks = (e: React.FormEvent<HTMLTextAreaElement>) => {
    if (actionType === ClaimActionType.Cancel) {
      setClaim({ ...claim, cancelledRemarks: e.currentTarget.value });
    }
    if (actionType === ClaimActionType.Endorse) {
      setClaim({ ...claim, endorsedRemarks: e.currentTarget.value });
    }
    if (actionType === ClaimActionType.Approve) {
      setClaim({ ...claim, approvingRemarks: e.currentTarget.value });
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // const claimform = data as claims;
    setDialogVisible(false);
    if (action === ClaimActionType.Approve) {
      claimsService
        .approveClaim(claim)
        .then((res) => {
          SetSuccess("Claim has been approved");
          setRedirectPath("/finance/claims");
          setRedirectOut(true);
          setDialogVisible(false);
        })
        .catch(() => SetError("Unable to approve claim"));
    } else if (action === ClaimActionType.Reject) {
      claimsService
        .rejectClaim(claim)
        .then((res) => {
          SetSuccess("Claim has been rejected");
          setDialogVisible(false);
          // setActionType("Reject");
          getClaim();
        })
        .catch(() => SetError("Unable to reject claim"));
    } else if (action === ClaimActionType.Endorse) {
      claimsService
        .endorseClaim(claim)
        .then((res) => {
          SetSuccess("Claim has been endorsed");
          setRedirectPath("/claims/endorse/");
          setRedirectOut(true);
          setDialogVisible(false);
        })
        .catch(() => SetError("Unable to endorse claim"));
    } else if (action === ClaimActionType.Cancel) {
      claimsService
        .cancelClaim(claim)
        .then((res) => {
          SetSuccess("Claim has been cancelled");
          setRedirectPath("/claims/view/");
          setRedirectOut(true);
          setDialogVisible(false);
        })
        .catch(() => SetError("Unable to cancel claim"));
    }
  };

  const financeDialog = (
    <Dialog
      header={action}
      visible={dialogVisible}
      modal={true}
      // //dismissableMask={true}
      style={{ width: "25%" }}
      onHide={() => setDialogVisible(false)}
    >
      <form onSubmit={onSubmit}>
        {approvePerms && action === ClaimActionType.Approve ? (
          <>
            <div className="col-12 p-fluid p-2">
              <span className="boldLabel">
                Total Amount ({claim.currency}):
              </span>
              <NumberFormat
                className="p-inputtext disabled"
                displayType={"input"}
                value={claim.amount}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled
              />
            </div>
            <div className="col-12 p-fluid p-2">
              <span className="boldLabel">Tax Amount ({claim.currency}):</span>
              <div className="p-inputgroup">
                <NumberFormat
                  className="p-inputtext "
                  displayType={"input"}
                  value={claim.taxAmount}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onValueChange={(e) => {
                    setClaim({ ...claim, taxAmount: e.floatValue! });
                  }}
                />
                {/* <InputText
                  value={claim.taxAmount.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  onChange={(e) => {
                    let inputvalue = e.currentTarget.value;
                    try {
                      let twodpvalue = parseFloat(
                        parseFloat(inputvalue).toFixed(2)
                      );
                      setClaim({ ...claim, taxAmount: twodpvalue });
                    } catch (e) {}
                  }}
                  required
                /> */}

                <Button
                  label="8%"
                  type="button"
                  onClick={() => {
                    let tax = (claim.amount / 108) * 8;
                    setClaim({
                      ...claim,
                      taxAmount: parseFloat(tax.toFixed(2)),
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-12 p-fluid p-2">
              <span className="boldLabel">Payment Reference: </span>
              <InputText
                value={claim.paymentReference || ""}
                onChange={(e) =>
                  setClaim({
                    ...claim,
                    paymentReference: e.currentTarget.value,
                  })
                }
                //  required
              />
            </div>
            <div className="col-12 p-fluid p-2">
              <span className="boldLabel">Accounting Code: </span>

              <Dropdown
                options={accountingCodes}
                placeholder="Please Select"
                filter={true}
                required
                scrollHeight="350px"
                value={claim.accountingCodeID}
                onChange={(e) => {
                  setClaim({ ...claim, accountingCodeID: e.value });
                }}
              />
            </div>
          </>
        ) : null}

        <div className="col-12 p-fluid p-2">
          <span className="boldLabel">Remarks: </span>
          <InputTextarea
            rows={3}
            cols={30}
            required={action === "Reject" || action === "Cancel" ? true : false}
            onChange={setRemarks}
          />
        </div>
        <div className="text-center">
          {action === "Cancel" ? (
            <Button
              type="submit"
              className={"p-button-success"}
              label={"Submit"}
            />
          ) : (
            <Button
              type="submit"
              className={
                action === "Reject" ? "p-button-danger" : "p-button-success"
              }
              label={action}
            />
          )}
        </div>
      </form>
    </Dialog>
  );

  let claimDisplay = (
    <div className="">
      <div className="row">
        <div className="col">
          <h2 className="d-inline">{claim.claimSerialNo}</h2>
          <span className="d-inline" style={{ float: "right" }}>
            <h2>
              <NumberFormat
                prefix={claim.currency + " "}
                displayType="text"
                thousandSeparator={true}
                decimalScale={2}
                value={claim.amount}
                fixedDecimalScale={true}
              />
              {/* {claim.currency}{" "}
              {claim.amount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} */}
            </h2>
            <p style={{ fontSize: "small" }}> Total Amount </p>
            <h2>
              {/* {claim.currency}{" "} */}
              <NumberFormat
                prefix={claim.currency + " "}
                displayType="text"
                thousandSeparator={true}
                decimalScale={2}
                value={claim.taxAmount}
                fixedDecimalScale={true}
              />
              {/* {claim.taxAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} */}
            </h2>
            <p style={{ fontSize: "small" }}> Tax </p>
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-5 col-xl-3">
          <strong>Campaign:</strong>
        </div>
        <div className="col-7 col-xl-9">
          {claim.campaignName !== "" && claim.campaignName !== null ? (
            <>{claim.campaignName}</>
          ) : (
            "-"
          )}
        </div>

        <div className="col-5 col-xl-3">
          <strong>Campaign Type:</strong>
        </div>
        <div className="col-7 col-xl-9">
          {claim.campaignTypeName !== "" && claim.campaignTypeName !== null ? (
            <>{claim.campaignTypeName}</>
          ) : (
            "-"
          )}
        </div>
        <div className="col-5 col-xl-3">
          <strong>Company:</strong>
        </div>
        <div className="col-7 col-xl-9">{claim.companyName}</div>
        <div className="col-5 col-xl-3">
          <strong>Claim Type:</strong>
        </div>
        <div className="col-7 col-xl-9">{claim.costTypeName}</div>
        <div className="col-5 col-xl-3">
          <strong>Total Amount:</strong>
        </div>
        <div className="col-7 col-xl-9">
          <NumberFormat
            prefix={claim.currency + " "}
            displayType="text"
            thousandSeparator={true}
            decimalScale={2}
            value={claim.amount}
            fixedDecimalScale={true}
          />
        </div>

        <div className="col-5 col-xl-3">
          <strong>Tax Amount:</strong>
        </div>
        <div className="col-7 col-xl-9">
          <NumberFormat
            prefix={claim.currency + " "}
            displayType="text"
            thousandSeparator={true}
            decimalScale={2}
            value={claim.taxAmount}
            fixedDecimalScale={true}
          />
          {/* {claim.currency}{" "}
          {claim.taxAmount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })} */}
        </div>

        <div className="col-5 col-xl-3">
          <strong>Status: </strong>
        </div>
        <div className="col-7 col-xl-9">{claim.claimStatus}</div>
        <div className="col-5 col-xl-3">
          <strong>Submitted By:</strong>
        </div>
        <div className="col-7 col-xl-9">{claim.submittedByName}</div>
        <div className="col-5 col-xl-3">
          <strong>Receipt Date:</strong>
        </div>
        <div className="col-7 col-xl-9">
          {commonMethods.displayDate(claim.receiptDate)}
        </div>
        <div className="col-5 col-xl-3">
          <strong>Created Time: </strong>
        </div>
        <div className="col-7 col-xl-9">
          {commonMethods.displayDateTime(claim.createdDate)}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          {displayReceipts.map((element: string | undefined, index: number) => {
            return (
              <div className="card" key={index}>
                <div className="row d-flex justify-content-center p-2">
                  <div
                    className="p-col-12 p-fluid d-flex justify-content-center"
                    key={index}
                  >
                    {element !== undefined &&
                    CommonDocumentMethods.displayPDF(element) ? (
                      <object
                        data={element}
                        type="application/pdf"
                        width="100%"
                        height="500px"
                      ></object>
                    ) : (
                      <a
                        href={element}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={element} alt="" className="imgThumbnail" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="h5 boldLabel">Remarks</p>
          <p>{claim.remarks}</p>
        </div>
        <div className="col">
          <p className="h4 boldLabel text-center">
            {claim.claimStatus.toUpperCase()}
          </p>
        </div>
      </div>
      {actionButtons()}
      {claim.endorsedBy && endorsedByDisplay()}
      {claim.approvedBy && approvedByDisplay()}
      {claim.processedByName && processedByDisplay()}
    </div>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = claimDisplay;
  }

  return (
    <>
      {redirectOut ? <Redirect push to={redirectPath} /> : null}
      <div className="row">
        <div className="col-12">
          <div
            className="card"
            data-tour-id="claim-by-id"
            data-tour="View claim details here, such as who submitted it for which campaign."
          >
            {financeDialog}
            <h1>View Claim</h1>
            {display}
          </div>
        </div>
      </div>
    </>
  );
}

export default function ViewClaimByID(props: RouteComponentProps) {
  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <DisplayClaim {...props} />
      </div>
    </div>
  );
}
