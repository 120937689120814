import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";

export default function IrasSubmissionError(props: RouteComponentProps) {
  const commonMethods = new CommonMethods();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(
    "An unidentified error has occurred"
  );

  const [linkOut, setLinkOut] = useState<string>("");
  const loc = useLocation();

  useEffect(() => {
    const query = commonMethods.getQueryFromURL(loc);
    const errorFromQuery = query.get("error") || "";
    const id = query.get("id") || "";

    if (id !== "") {
      setLinkOut("/finance/tax_filing/" + id);
    } else {
      setLinkOut("/finance/tax_filing");
    }

    if (errorFromQuery !== "") {
      setIsError(true);
      setIsLoading(false);
      setErrorMsg(errorFromQuery + " Please try again later.");
    } else {
      setIsError(true);
      setIsLoading(false);
      setErrorMsg("Error occured. Please try again later.");
    }
  }, []);

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = <></>;
  }
  return (
    <div className="row">
      <div className="col-12">
        <Link to={linkOut}>
          <i className="pi pi-angle-left align-middle" />
          <span> Back to tax filing</span>
        </Link>
      </div>

      <div className="col-12 col-xl-12">
        <div className="card">
          <h1>IRAS Submission error</h1>
          {display}
        </div>
      </div>
    </div>
  );
}
