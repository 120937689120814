import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScrollPanel } from "primereact/scrollpanel";
import * as React from "react";
import { Redirect } from "react-router";
import ReactTooltip from "react-tooltip";
import { UserRankService } from "../../service/UserRankService";
import { UserService } from "../../service/UserService";
import { StatusService } from "../../service/StatusService";
import {
  CommonMethods,
  RouteComponentProps,
  DropdownModel,
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { Link } from "react-router-dom";
import { TaskList } from "../resources/ListOfTasks";
import { Calendar } from "primereact/calendar";
import { UserAccStatus } from "./UserModels";
import { SelectButton } from "primereact/selectbutton";
import { StatusRecordService } from "../../service/StatusRecordService";
import { CSVLink } from "react-csv";

export class ImportUserModel {
  public fileName: string = "";
  public uploadDate: string = "";
  public uploadPath: string = "";
  public uploadFile: string = "";
  public companyID: string = "";
}

export class UserStatusModel {
	public userList: string = "";
	public startDate: Date = new Date();
	public endDate: Date = new Date();
	public startDuration: string = "FULL DAY";
	public endDuration: string = "FULL DAY";
}

export class ContractProblemsModel {
  public incompleteContracts: Array<string> = new Array<string>();
  public expiredContracts: Array<string> = new Array<string>();
}

export class UserStatusIssueModel {
	public userID: string = "";
	public firstName: string = "";
	public lastName: string = "";
	public issue: string = "";
}

interface dataObj {
	[key: string]: number | string;
}
export class UserModel {
  public userID: string = "";
  public firstName: string = "";
  public lastName: string = "";
  public email: string = "";
  public passwordHash: string = "";
  public saltKey: string = "";
  public roleID: string = "";
  public roleList: string = "";
  public userRoleRemarks: string = "";
  public displayPicture: string = "";
  public displayPictureFile: any = null;
  public authKey: string = "";
  public accStatus: string = "";
  public approvers: string = "";
  public approverList: string[] = [];
  public subordinateList: string[] = [];
  public salutation: string = "";
  public dateOfBirth: Date = new Date();
  public nationality: string = "";
  public maritalStatus: string = "";
  public gender: string = "";
  public race: string = "";
  public religion: string = "";
  public prObtainedDate: Date = new Date();
  public pP_PersonalEmail: string = "";
  public pP_ResidentialAddress: string = "";
  public pP_MobileNumber: string = "";
  public pP_HomeNumber: string = "";
  public legalStatus: string = "";
  public wP_WorkPass: string = ""; // 08/04/2022 Use this for work pass type
  public wP_DependantPass: string = ""; // 08/04/2022 Use this for work pass number
  public wP_WorkPermitExpiry: Date = new Date();
  public wP_WorkLocation: string = ""; // 08/04/2022 Added field
  public wP_PassportNumber: string = ""; // 08/04/2022 Added field

  public emergency_Name: string = "";
  public emergency_Relationship: string = "";
  public emergency_Contact: string = "";
  public bank_Name: string = "";
  public bank_Code: string = "";
  public bank_Number: string = "";
  public bank_AccountType: string = "";
  public bank_PayeeName: string = "";
  public shgEligible: boolean = true;
  public shgGroup: string = "";
  public sdlEligible: boolean = true;
  public missingApproverRights: boolean = false;
  public missingApprover: boolean = false;
  public emailSignatureDisplay: string = "";
  public emailSignatureDisplayFile: any = null;
  // UserDepartmentTable
  public departmentName: string = "";
  public departmentID: string = "";
  // ContractTable
  public jobTitleID: string = "";
  public jobTitle: string = "";
  public userRankID: string = "";
  public identificationNumber: string = "";
  public contractID: string = "";

  public companyName: string = "";
  public userRankName: string = "";
  public roleName: string = "";
  public fullName: string = "";
  public kpiTarget: number = 0;

  public jobEndDate: Date = new Date();
  public publicProfilePermission: boolean = false;

  public businessCardJson: string = "";
  public budgetingGroup: string = "";

}

interface AppState {
  allUsers: Array<UserModel>;
  viewOptionSelected: string;
  userObj: UserModel;
  userObjList: Array<UserModel>;
  incompleteContractUserList: Array<string>;
  expiredContractUserList: Array<string>;

  searchValue: string;
  redirectOut: boolean;
  redirectPath: string;
  redirectParam: string;
  redirectState: Array<UserModel> | string;
  isLoading: boolean;
  checked: boolean;
  primaryFilterOptionSelected: string;
  secondaryFilterOptionSelected: string;
  orderBySelected: string;
  batchUpdateSelected: boolean;
  bulkOption: string;
  contractOptionSelected: string;

  action: string;
  formHeader: string;
  maxDate: Date;
  userID: string;

  primaryFilterOptions: any;
  orderByOptions: any;
  viewOptions: any;
  secondaryFilter: string;
  secondaryFilterOptions: any;
  secondaryFilterError: string;

  isError: boolean;
  errorMsg: string;

  statusToBeUpdated: string;
  importVisible: boolean;
  updateStatusDialogVisible: boolean;
  bulkUpdateStatusDialogVisible: boolean;
  deactivatedDate: Date;
  bulkCreateLeaveDialog: boolean;
  resetLeavesDialog: boolean;
  businessCardDialog: boolean;
  massApplyLeaveDialog: boolean;
  dialogStr: any;

  displayCheckboxes: string;

  hidePersonalDetails: boolean;
  propsLoaded: boolean;

  importUserModel: ImportUserModel;

  scrollPanelHeight: string;

  confirmDeleteVisible: boolean;

  deleteDummyButton: boolean;

  contractAccess: boolean;
  me: UserModel;
  isHR: boolean;
  isHRManager: boolean;

  leaveStartDate: Date;
  leaveEndDate: Date;
  leaveStartDuration: string;
  leaveEndDuration: string;
  listOfUserIssues: Array<UserStatusIssueModel>;
}

export class User extends React.Component<RouteComponentProps<any>, AppState> {
  static contextType = ToastStateContext;
  _isMounted: boolean;
  dt: any;
  userService: UserService;
  userRankService: UserRankService;
  statusService: StatusService;
  commonMethods: CommonMethods;
  statusRecordService: StatusRecordService;
  leaveDurationSameDaySelection: dataObj[];
  leaveDurationDifferentDaySelectionForStartDate: dataObj[];
  leaveDurationDifferentDaySelectionForEndDate: dataObj[];
  headers: any[];
  csvLinkEl: any;

  groupCompanyID: string;
  companyID: string;
  op: any;
  statusDropdownOptions: any[];
  contractOptions: Array<DropdownModel>;

  currentAccessingCompanyId =
    localStorage.getItem("currentAccessingCompany") || "";

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      allUsers: new Array<UserModel>(),
      viewOptionSelected: "",
      userObj: new UserModel(),
      userObjList: new Array<UserModel>(),
      incompleteContractUserList: new Array<string>(),
      expiredContractUserList: new Array<string>(),
      searchValue: "",
      redirectOut: false,
      redirectPath: "",
      redirectParam: "",
      redirectState: "" || new Array<UserModel>(),
      isLoading: true,
      checked: false,
      primaryFilterOptionSelected: "",
      secondaryFilterOptionSelected: "",
      orderBySelected: "",
      batchUpdateSelected: false,
      bulkOption: "",
      contractOptionSelected: "all",

      action: "",
      formHeader: "",
      maxDate: new Date(),
      userID: "",

      primaryFilterOptions: [],
      orderByOptions: [],
      viewOptions: [],

      secondaryFilter: "",
      secondaryFilterOptions: [],
      secondaryFilterError: "",

      isError: false,
      errorMsg: "",

      bulkCreateLeaveDialog: false,
      resetLeavesDialog: false,
	  businessCardDialog: false,
	  massApplyLeaveDialog: false,
      importVisible: false,
      updateStatusDialogVisible: false,
      bulkUpdateStatusDialogVisible: false,
      deactivatedDate: new Date(),
      dialogStr: "",
      statusToBeUpdated: "",

      displayCheckboxes: "",

      hidePersonalDetails: true,
      propsLoaded: false,

      importUserModel: new ImportUserModel(),
      scrollPanelHeight: "200px",
      confirmDeleteVisible: false,
      deleteDummyButton: false,
	  contractAccess: false,
	  me: new UserModel(),
	  isHR: false,
	  isHRManager: false,

	  leaveStartDate: new Date(),
	  leaveEndDate: new Date(),
	  leaveStartDuration: "FULL DAY",
	  leaveEndDuration: "FULL DAY",
	  listOfUserIssues :new Array<UserStatusIssueModel>(),
    };
    this._isMounted = false;

    this.userService = new UserService();
    this.userRankService = new UserRankService();
    this.statusService = new StatusService();
    this.commonMethods = new CommonMethods();
	this.statusRecordService = new StatusRecordService();
    this.contractOptions = this.commonMethods.getContractFilterOptions();
    this.viewUserDetailsButtons = this.viewUserDetailsButtons.bind(this);
    this.displayUserExclamationMark = this.displayUserExclamationMark.bind(
      this
    );
    this.viewStatusRecordsDetailsButtons = this.viewStatusRecordsDetailsButtons.bind(
      this
    );
    this.viewAdminButtons = this.viewAdminButtons.bind(this);
    this.initSecondaryFilterView = this.initSecondaryFilterView.bind(this);
    this.statusButton = this.statusButton.bind(this);
    this.contractButtons = this.contractButtons.bind(this);
    this.displayFirstLastName = this.displayFirstLastName.bind(this);
    this.displayFilteredView = this.displayFilteredView.bind(this);
    this.statusDropdownOptions = this.commonMethods.getStatusSelectionDropdownList();
    this.checkboxcol = this.checkboxcol.bind(this);
    this.onUserCheckboxChange = this.onUserCheckboxChange.bind(this);
    this.displayContractExclamationMark = this.displayContractExclamationMark.bind(
      this
    );
    this.contractFilterOnChange = this.contractFilterOnChange.bind(this);

    this.groupCompanyID = "";
    this.companyID = "";

	this.leaveDurationSameDaySelection = this.commonMethods.getDurationOptions();
    this.leaveDurationDifferentDaySelectionForEndDate = [
      { label: "FULL", value: "FULL DAY" },
      { label: "AM", value: "AM" },
    ];
    this.leaveDurationDifferentDaySelectionForStartDate = [
      { label: "FULL", value: "FULL DAY" },
      { label: "PM", value: "PM" },
    ];
	this.headers = [
		{ label: "First Name", key: "firstName" },
		{ label: "Last Name", key: "lastName" },
		{ label: "Issue", key: "issue" },
		{ label: "User ID", key: "userID" }
	];
	this.csvLinkEl = React.createRef();
  }

  initTable() {
    this._isMounted = true;
    this._isMounted && this.setState({ isLoading: true });
	if (this.props.userProfile.taskList !== undefined && this.commonMethods.isArrayPresentInArray(this.props.userProfile.taskList, [TaskList.contractaccess])) {
		this.setState({contractAccess: true})
	};
	// get admin details
	this.checkHRAccess();
    // if /user/status/name/id  --- from role/department/jobtitle unable to delete
    if (
      this.props.match.params !== {} &&
      this.props.match.params.status !== undefined &&
      this.props.match.params.name !== undefined &&
      this.props.match.params.id !== undefined
    ) {
      if (this.props.match.params.status === UserAccStatus.Onboarding) {
        this.getUsers(UserAccStatus.Onboarding, "", "");
      } else {
        this._isMounted &&
          this.setState({
            viewOptionSelected: this.props.match.params.status,
            primaryFilterOptionSelected: this.props.match.params.name,
            secondaryFilterOptionSelected: this.props.match.params.id,
            secondaryFilter: this.props.match.params.name,
          });

        this.getSecondaryDropdownAccordingToName(this.props.match.params.name);

        this.getUsers(
          this.props.match.params.status,
          this.props.match.params.name,
          this.props.match.params.id
        );
      }
    } else {
      // if /user
      this.getUsers(UserAccStatus.Active, "", "");
    }

    // Filter bar dropdown lists
    this._isMounted &&
      this.setState({
        viewOptions: this.commonMethods.getViewUserStatusDropdownList(),
        primaryFilterOptions: this.commonMethods.getPrimaryFilterOptions(),
        orderByOptions: this.commonMethods.getOrderByOptions(),
      });
  }

  componentDidMount() {
    this.initTable();
    this.checkDummyEmployees();
  }

  componentDidUpdate() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  checkHRAccess(){
	this.userService
      .getMyDisplay()
      .then((res: UserModel) => {
        this._isMounted && this.setState({ me: res });
		if (res.departmentName == "Human Resource"){
			this.setState({isHR: true})
		}
		if (res.jobTitle == "HR Manager" || res.jobTitle == "Senior HR Manager"){
			this.setState({isHRManager: true})
		}
      })
      .catch((error) => {
        let errorMsg = "Error retrieving personal information";
        this._isMounted &&
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
        return errorMsg;
      });
  }

  getUsers(status: string, name: string, id: string) {
    this._isMounted && this.setState({ isLoading: true });
    if (status !== UserAccStatus.Active) {
      this._isMounted && this.setState({ displayCheckboxes: "d-none" });
    } else {
      this._isMounted && this.setState({ displayCheckboxes: "" });
    }
    this._isMounted &&
      this.setState({
        viewOptionSelected: status,
        batchUpdateSelected: false,
        userObjList: new Array<UserModel>(),
      });

    if (name !== null && name !== "") {
      name = name.replace(" ", "");
      if (id === "") {
        // Dont send name without id.

        this._isMounted && this.setState({ secondaryFilterError: "true" });
        name = "";
      } else {
        this._isMounted && this.setState({ secondaryFilterError: "" });
      }
    }

    // Get Users
    this.userService
      .getUsers(status, name, id, this.currentAccessingCompanyId)
      .then((res) => {
        this._isMounted && this.setState({ allUsers: res, isLoading: false });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this._isMounted &&
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
        return errorMsg;
      });

    // Get Users with Incomplete contract
    this.userService
      .getUsersWithIncompleteContract(this.currentAccessingCompanyId)
      .then((res: ContractProblemsModel) => {
        this._isMounted &&
          this.setState({
            incompleteContractUserList: res.incompleteContracts,
            expiredContractUserList: res.expiredContracts,
          });
      });
  }

  checkDummyEmployees(){
    this.userService
      .getDummyInUsers(this.currentAccessingCompanyId)
      .then((res) => {
        this.setState({ deleteDummyButton: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this._isMounted &&
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
        return errorMsg;
      });
  }

  deleteDummyUsers() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.userService
        .deleteDefaultDummyEmployees()
        .then(() => {
          window.location.reload();
          // reload to not only reload table component but reload "Delete Test Users" button check
          // Cannot put below success messages because the page reloads.
          // Temporary measure until you can find out how to reload the button component
          // let msg = "Dummy Employees have been deleted";
          // this.context.SetSuccess(msg);
        })
        .catch((error) => {
          this.context.SetError(this.commonMethods.getErrorMessage(error));
          this.setState({
            isLoading: false
          });
        })
        .finally(() => {
          this.setState({ confirmDeleteVisible: false });
          this.setState({
            isLoading: false
          });
        });
      }
    );
  }

  //    _   _                 ___        _          _  _       ___        _    _
  //   | | | | ___ ___  _ _  |   \  ___ | |_  __ _ (_)| | ___ | _ ) _  _ | |_ | |_  ___  _ _   ___
  //   | |_| |(_-</ -_)| '_| | |) |/ -_)|  _|/ _` || || |(_-< | _ \| || ||  _||  _|/ _ \| ' \ (_-<
  //    \___/ /__/\___||_|   |___/ \___| \__|\__,_||_||_|/__/ |___/ \_,_| \__| \__|\___/|_||_|/__/
  //

  checkboxcol(rowData: UserModel) {
    return (
      <>
        <Checkbox
          value={rowData}
          onChange={this.onUserCheckboxChange}
          checked={this.state.userObjList.includes(rowData)}
          inputId={rowData.userID}
        />
      </>
    );
  }

  onUserCheckboxChange(e: any) {
    let selectedUsers = [...this.state.userObjList];
    if (e.checked) {
      selectedUsers.push(e.value);
    } else {
      selectedUsers.splice(selectedUsers.indexOf(e.value), 1);
    }
    this._isMounted && this.setState({ userObjList: selectedUsers });
  }

  displayFirstLastName(rowData: UserModel) {
    return (
      <>
        {rowData.firstName} {rowData.lastName}
      </>
    );
  }

  addUserButton() {
    if (
      this.commonMethods.isArrayPresentInArray(
        this.props.userProfile.taskList,
        [TaskList.useraccess]
      )
    ) {
      return (
        <span className="pl-3">
          <Button
            tooltip="Create New Employee"
            tooltipOptions={{ position: "top" }}
            type="button"
            icon="pi pi-plus"
            iconPos="right"
            label="Add"
            className="p-button-danger mt-2"
            onClick={() => {
              this.addUser();
            }}
          />

          <Button
            tooltip="Bulk Create Employees"
            tooltipOptions={{ position: "top" }}
            type="button"
            icon="pi pi-plus"
            iconPos="right"
            label="Import"
            className="p-button-primary mx-2 mt-2"
            onClick={() => {
              this._isMounted &&
                this.setState({
                  importVisible: true,
                  importUserModel: {
                    ...this.state.importUserModel,
                    companyID: this.currentAccessingCompanyId,
                  },
                });
            }}
          />
          { this.state.deleteDummyButton == true ?
          <Button
            tooltip="Delete Dummy Employees"
            tooltipOptions={{ position: "top" }}
            type="button"
            icon="pi pi-trash"
            iconPos="right"
            label="Delete Test Users"
            className="p-button-danger mx-1"
            onClick={() => {
              this.setState({ confirmDeleteVisible: true });
            }}
          />
          : <></>}
        </span>
      );
    } else {
      return "";
    }
  }

  viewUserDetailsButtons(rowData: UserModel) {
    let adminUpdatePassword;
    if (
      this.commonMethods.isArrayPresentInArray(
        this.props.userProfile.taskList,
        [TaskList.adminchangepw]
      )
    ) {
      adminUpdatePassword = (
        <Button
          tooltip="Reset Password"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-key"
          className="p-button-danger mx-1"
          onClick={() => {
            this.updatePassword(rowData);
          }}
        />
      );
    } else {
      adminUpdatePassword = "";
    }

    let disableUserButton = true;
    let updateUserButton;
    updateUserButton = "";
    if (
      this.props.userProfile.taskList !== undefined &&
      this.commonMethods.isArrayPresentInArray(
        this.props.userProfile.taskList,
        [TaskList.useraccess]
      )
    ) {
      if (rowData.accStatus === UserAccStatus.Active) {
        disableUserButton = false;
      }

      let URL = "/admin/employees/update/" + rowData.userID;

      updateUserButton = disableUserButton ? (
        <Button
          disabled={disableUserButton}
          tooltip="Edit Employee Details"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning mx-1"
          // onClick={() => {
          //   this.updateUser(rowData);
          // }}
        />
      ) : (
        <Link to={URL} target="_blank">
          <Button
            disabled={disableUserButton}
            tooltip="Edit Employee Details"
            tooltipOptions={{ position: "top" }}
            type="button"
            icon="pi pi-pencil"
            className="p-button-warning mx-1"
            // onClick={() => {
            //   this.updateUser(rowData);
            // }}
          />
        </Link>
      );
    }

    let userDocButton;
    let disableUserDocButton = true;
    if (
      this.props.userProfile.taskList !== undefined &&
      this.commonMethods.isArrayPresentInArray(
        this.props.userProfile.taskList,
        [TaskList.userdocaccess]
      )
    ) {
      if (rowData.accStatus !== UserAccStatus.Onboarding) {
        disableUserDocButton = false;
      }
      userDocButton = (
        <Button
          disabled={disableUserDocButton}
          tooltip="View Employee Documents"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-folder"
          className="button-document"
          onClick={() => {
            this.viewDocuments(rowData);
          }}
        />
      );
    } else {
      userDocButton = "";
    }

    let disableViewUserButton = true;
    let updateOnboardingUser;

    if (rowData.accStatus !== UserAccStatus.Onboarding) {
      disableViewUserButton = false;
      updateOnboardingUser = "";
    } else {
      updateUserButton = "";
      updateOnboardingUser = (
        <Button
          tooltip="Edit Employee Details"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning mx-1"
          onClick={() => {
            this.updateOnboardingUser(rowData);
          }}
        />
      );
    }

    let viewUserButton;
    if (
      this.props.userProfile.taskList !== undefined &&
      this.commonMethods.isArrayPresentInArray(
        this.props.userProfile.taskList,
        [TaskList.useraccess]
      )
    ) {
      viewUserButton = (
        <Button
          disabled={disableViewUserButton}
          tooltip="View Employee Details"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-eye"
          className="p-button-primary"
          onClick={() => {
            this.viewUser(rowData);
          }}
        />
      );
    } else {
      viewUserButton = "";
    }

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {viewUserButton}
        {updateOnboardingUser}
        {updateUserButton}

        {userDocButton}

        {adminUpdatePassword}
      </div>
    );
  }

  displayUserExclamationMark(rowData: UserModel) {
    let icon;

    if (
      this.props.userProfile.taskList !== undefined &&
      this.commonMethods.isArrayPresentInArray(
        this.props.userProfile.taskList,
        [TaskList.useraccess]
      )
    ) {
      if (rowData.accStatus !== UserAccStatus.Onboarding) {
        if (rowData.accStatus === UserAccStatus.Active) {
          if (rowData.missingApprover) {
            icon = (
              <>
                <span data-tip="" data-for="exclamationmarkSupervisor0">
                  <i className="fas fa-exclamation exclamationStyle"></i>
                </span>

                <ReactTooltip
                  id="exclamationmarkSupervisor0"
                  aria-haspopup="true"
                  className="tooltipFont"
                >
                  <p className="text-center">
                    No supervisor assigned. Please update.
                  </p>
                </ReactTooltip>
              </>
            );
          } else {
            if (rowData.missingApproverRights) {
              icon = (
                <>
                  <span data-tip="" data-for="exclamationmarkSupervisor">
                    <i className="fas fa-exclamation exclamationStyle"></i>
                  </span>

                  <ReactTooltip
                    id="exclamationmarkSupervisor"
                    aria-haspopup="true"
                    className="tooltipFont"
                  >
                    <p className="text-center">
                      No supervisor with approving rights is assigned. Please
                      update.
                    </p>
                  </ReactTooltip>
                </>
              );
            }
          }
        }
      } else {
        icon = (
          <>
            <span data-tip="" data-for="exclamationmarkOnboarding">
              <i className="fas fa-exclamation exclamationStyle"></i>
            </span>

            <ReactTooltip
              id="exclamationmarkOnboarding"
              aria-haspopup="true"
              className="tooltipFont"
            >
              <p className="text-center">
                Please update user details to activate account
              </p>
            </ReactTooltip>
          </>
        );
      }
    }
    return icon;
  }
  viewUser(rowData: UserModel) {
    this._isMounted &&
      this.setState({
        redirectOut: true,
        redirectPath: "/admin/employees/view/" + rowData.userID,
      });
  }

  addUser() {
    this._isMounted &&
      this.setState({
        redirectOut: true,
        redirectPath: "/admin/employees/create",
      });
  }

  updateOnboardingUser(rowData: UserModel) {
    this._isMounted &&
      this.setState({
        redirectOut: true,
        redirectPath: "/admin/employees/onboarding/" + rowData.userID,
      });
  }

  viewDocuments(rowData: UserModel) {
    this._isMounted &&
      this.setState({
        redirectOut: true,
        redirectPath: "/admin/employees/document/" + rowData.userID,
      });
  }

  updatePassword(rowData: UserModel) {
    this._isMounted &&
      this.setState({
        redirectOut: true,
        redirectPath: "/admin/employees/password/" + rowData.userID,
      });
  }

  viewPeronalDetailsButtons() {
    if (
      this.props.userProfile.taskList !== undefined &&
      this.commonMethods.isArrayPresentInArray(
        this.props.userProfile.taskList,
        [TaskList.useraccess, TaskList.userdocaccess, TaskList.adminchangepw]
      )
    ) {
      return (
        <Column
          className="p-col-2"
          body={this.viewUserDetailsButtons}
          header="Personal Details"
        />
      );
    } else {
      return <Column className="p-col d-none" header="Personal Details" />;
    }
  }

  //    ___  _          _               ___                        _   ___        _    _
  //   / __|| |_  __ _ | |_  _  _  ___ | _ \ ___  __  ___  _ _  __| | | _ ) _  _ | |_ | |_  ___  _ _   ___
  //   \__ \|  _|/ _` ||  _|| || |(_-< |   // -_)/ _|/ _ \| '_|/ _` | | _ \| || ||  _||  _|/ _ \| ' \ (_-<
  //   |___/ \__|\__,_| \__| \_,_|/__/ |_|_\\___|\__|\___/|_|  \__,_| |___/ \_,_| \__| \__|\___/|_||_|/__/
  //

  viewStatusRecordButtons() {
    if (
      this.props.userProfile.taskList !== undefined &&
      this.commonMethods.isArrayPresentInArray(
        this.props.userProfile.taskList,
        [TaskList.leaveadminaccess]
      )
    ) {
      return (
        <Column
          className="p-col"
          body={this.viewStatusRecordsDetailsButtons}
          header="Leave Details"
        />
      );
    } else {
      return <Column className="p-col d-none" header="Leave Details" />;
    }
  }

  viewStatusRecordsDetailsButtons(rowData: UserModel) {
    let disableLeaveViewButton = true;
    let disableLeaveCreditButton = true;

    if (rowData.accStatus === UserAccStatus.Active) {
      disableLeaveViewButton = false;
      disableLeaveCreditButton = false;
    } else if (rowData.accStatus === UserAccStatus.Deactivated) {
      disableLeaveViewButton = false;
    }

    let viewStatusRecordURL =
      "/admin/employees/leave_summary/" + rowData.userID;
    let creditLeaveUrl = "/admin/employees/credit_leave/" + rowData.userID;
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {disableLeaveViewButton ? (
          <Button
            disabled={disableLeaveViewButton}
            tooltip="Leave Records"
            tooltipOptions={{ position: "top" }}
            type="button"
            icon="pi pi-eye"
            className="p-button-primary mx-1"
            // onClick={() => {
            //   this.viewStatusRecord(rowData);
            // }}
          />
        ) : (
          <Link to={viewStatusRecordURL} target="_blank">
            <Button
              disabled={disableLeaveViewButton}
              tooltip="Leave Records"
              tooltipOptions={{ position: "top" }}
              type="button"
              icon="pi pi-eye"
              className="p-button-primary mx-1"
              // onClick={() => {
              //   this.viewStatusRecord(rowData);
              // }}
            />
          </Link>
        )}

        {disableLeaveCreditButton ? (
          <Button
            disabled={disableLeaveCreditButton}
            tooltip="Apply/Credit Leave"
            tooltipOptions={{ position: "top" }}
            type="button"
            icon="pi pi-pencil"
            className="p-button-warning"
            // onClick={() => {
            //   this.updateStatusRecord(rowData);
            // }}
          />
        ) : (
          <Link to={creditLeaveUrl} target="_blank">
            <Button
              disabled={disableLeaveCreditButton}
              tooltip="Apply/Credit Leave"
              tooltipOptions={{ position: "top" }}
              type="button"
              icon="pi pi-pencil"
              className="p-button-warning"
              // onClick={() => {
              //   this.updateStatusRecord(rowData);
              // }}
            />
          </Link>
        )}
      </div>
    );
  }

  // viewStatusRecord(rowData: UserModel) {
  //   this._isMounted &&
  //     this.setState({
  //       redirectOut: true,
  //       redirectPath: "/admin/employees/leave_summary/" + rowData.userID,
  //     });
  // }

  // updateStatusRecord(rowData: UserModel) {
  //   this._isMounted &&
  //     this.setState({
  //       redirectOut: true,
  //       redirectPath: "/admin/employees/credit_leave/" + rowData.userID,
  //     });
  // }

  //    ___  _  _  _                ___         _    _              ___        _    _
  //   | __|(_)| || |_  ___  _ _   / _ \  _ __ | |_ (_) ___  _ _   | _ ) _  _ | |_ | |_  ___  _ _   ___
  //   | _| | || ||  _|/ -_)| '_| | (_) || '_ \|  _|| |/ _ \| ' \  | _ \| || ||  _||  _|/ _ \| ' \ (_-<
  //   |_|  |_||_| \__|\___||_|    \___/ | .__/ \__||_|\___/|_||_| |___/ \_,_| \__| \__|\___/|_||_|/__/
  //                                     |_|

  initSecondaryFilterView(e: { originalEvent: Event; value: any }) {
    this._isMounted &&
      this.setState(
        {
          primaryFilterOptionSelected: e.value,
          secondaryFilterOptionSelected: "",
          secondaryFilter: e.value,
        },
        () => this.getSecondaryDropdownAccordingToName(e.value)
      );
  }

  getSecondaryDropdownAccordingToName(name: string) {
    if (name === "Department") {
      this.commonMethods.getCompanyIDFromUserID().then((res) => {
        this.companyID = res;
        this.commonMethods
          .getDepartmentDropdownList(this.companyID)
          .then((res) => {
            this._isMounted && this.setState({ secondaryFilterOptions: res });
          })
          .catch((error) => {
            let errorMsg = this.commonMethods.getErrorMessage(error);
            this._isMounted &&
              this.setState({
                isError: true,
                isLoading: false,
                errorMsg: errorMsg,
              });
            return errorMsg;
          });
      });
    } else if (name === "Role") {
      this.commonMethods
        .getGroupCompanyIDFromUserID()
        .then((res) => {
          this.groupCompanyID = res;
          this.commonMethods
            .getRoleDropdownListByGcid(this.groupCompanyID)
            .then((res) => {
              this._isMounted && this.setState({ secondaryFilterOptions: res });
            })
            .catch((error) => {
              let errorMsg = this.commonMethods.getErrorMessage(error);
              this._isMounted &&
                this.setState({
                  isError: true,
                  isLoading: false,
                  errorMsg: errorMsg,
                });
              return errorMsg;
            });
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this._isMounted &&
            this.setState({
              isError: true,
              isLoading: false,
              errorMsg: errorMsg,
            });
          return errorMsg;
        });
    } else if (name === "UserRank") {
      this.userRankService
        .getUserRankDropdownList()
        .then((res) => {
          // this.secondaryFilterOptions = res;
          this._isMounted && this.setState({ secondaryFilterOptions: res });
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this._isMounted &&
            this.setState({
              isError: true,
              isLoading: false,
              errorMsg: errorMsg,
            });
          return errorMsg;
        });
    } else if (name === "JobTitle") {
      this.commonMethods
        .getCompanyJobTitleDropdown(this.currentAccessingCompanyId)
        .then((res) => {
          this._isMounted && this.setState({ secondaryFilterOptions: res });
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this._isMounted &&
            this.setState({
              isError: true,
              isLoading: false,
              errorMsg: errorMsg,
            });
          return errorMsg;
        });
    } else if (name === "") {
      this.getUsers(this.state.viewOptionSelected, "", "");
    }
  }

  displayFilteredView(e: { originalEvent: Event; value: any }) {
    this._isMounted &&
      this.setState({ secondaryFilterOptionSelected: e.value });

    this.getUsers(
      this.state.viewOptionSelected,
      this.state.primaryFilterOptionSelected,
      e.value
    );
  }

  setSecondaryFilter() {
    if (this.state.secondaryFilter !== "") {
      return (
        <Dropdown
          value={this.state.secondaryFilterOptionSelected}
          options={this.state.secondaryFilterOptions}
          onChange={this.displayFilteredView}
          placeholder="Please Select"
          className={
            this.state.secondaryFilterError ? "p-error mx-1 mb-1" : " mx-1 mb-1"
          }
        />
      );
    }
    return;
  }

  // 2020-03-05 PCLQ: Contract Filter
  contractFilterOnChange(e: { originalEvent: Event; value: any }) {
    this._isMounted &&
      this.setState({ contractOptionSelected: e.value }, () =>
        this.getContracts(e.value)
      );
  }

  getContracts(contractType: string) {
    this._isMounted && this.setState({ isLoading: true });

    // Set Defaults
    var currentUsers: UserModel[] = [];
    var filteredUsers: UserModel[] = [];

    // Get All Users List with current option settings
    this.userService
      .getUsers(
        this.state.viewOptionSelected,
        this.state.primaryFilterOptionSelected,
        this.state.secondaryFilterOptionSelected,
        this.currentAccessingCompanyId
      )
      .then((res) => {
        currentUsers = res;

        if (contractType === "incomplete") {
          currentUsers.forEach((user) => {
            if (this.state.incompleteContractUserList.includes(user.userID)) {
              filteredUsers.push(user);
            }
          });
        } else if (contractType === "expired") {
          currentUsers.forEach((user) => {
            if (this.state.expiredContractUserList.includes(user.userID)) {
              filteredUsers.push(user);
            }
          });
        } else {
          filteredUsers = currentUsers;
        }

        this._isMounted &&
          this.setState({ allUsers: filteredUsers, isLoading: false });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this._isMounted &&
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
        return errorMsg;
      });
  }

  //    ___                   _          _              ___        _    _
  //   | _ \ ___  _ _  _ __  (_) ___ ___(_) ___  _ _   | _ ) _  _ | |_ | |_  ___  _ _   ___
  //   |  _// -_)| '_|| '  \ | |(_-<(_-<| |/ _ \| ' \  | _ \| || ||  _||  _|/ _ \| ' \ (_-<
  //   |_|  \___||_|  |_|_|_||_|/__//__/|_|\___/|_||_| |___/ \_,_| \__| \__|\___/|_||_|/__/
  //
  viewAdminButtons(rowData: UserModel) {
    let disablePermissionButton = true;

    if (rowData.accStatus === UserAccStatus.Active) {
      disablePermissionButton = false;
    }
    return (
      <>
        <Button
          disabled={disablePermissionButton}
          tooltip="Assign Roles"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning "
          onClick={() => {
            this.editPermissionSetting(rowData);
          }}
        />
      </>
    );
  }

  editPermissionSetting(rowData: UserModel) {
    this._isMounted &&
      this.setState({
        redirectOut: true,
        redirectPath: "/admin/employees/user_role/" + rowData.userID,
      });
  }

  displayPermissionButton() {
    if (
      this.props.userProfile.taskList !== undefined &&
      this.commonMethods.isArrayPresentInArray(
        this.props.userProfile.taskList,
        [TaskList.userroleaccess]
      )
    ) {
      return (
        <Column className="p-col" body={this.viewAdminButtons} header="Roles" />
      );
    } else {
      return <Column className="p-col d-none" />;
    }
  }

  //     ___            _                   _
  //    / __| ___  _ _ | |_  _ _  __ _  __ | |_
  //   | (__ / _ \| ' \|  _|| '_|/ _` |/ _||  _|
  //    \___|\___/|_||_|\__||_|  \__,_|\__| \__|
  //

  displayContractButtons() {
    if (this.state.contractAccess) {
      return (
        <Column
          className="p-col-1"
          style={{ padding: "0px" }}
          body={this.contractButtons}
          header="Contract"
        />
      );
    } else {
      return <Column className="p-col d-none" />;
    }
  }

  contractButtons(rowData: UserModel) {
    let URL = "/admin/employees/contract_of_user/" + rowData.userID;
	if (this.state.isHR && !this.state.isHRManager && rowData.departmentName == "Human Resource" && rowData.userID != this.state.me.userID){
		return (
			<Button
				tooltip="Unable to view HR's contracts"
				tooltipOptions={{ position: "top" }}
				type="button"
				icon="pi pi-eye"
				className="p-button-primary"
				disabled
			/>
		  );
	}
	return (
		<Link to={URL} target="_blank">
			<Button
			tooltip="View Employee's Contracts"
			tooltipOptions={{ position: "top" }}
			type="button"
			icon="pi pi-eye"
			className="p-button-primary"
			// onClick={() => {
			//   this.viewAllContracts(rowData);
			// }}
			/>
		</Link>
		);
  }
  displayContractExclamationMark(rowData: UserModel) {
    let icon;

    if (this.state.contractAccess) {
      if (
        this.state.incompleteContractUserList.indexOf(rowData.userID) !== -1
      ) {
        icon = (
          <>
            <span data-tip="" data-for="exclamationmarkContract">
              <i className="fas fa-exclamation exclamationStyle"></i>
            </span>

            <ReactTooltip
              id="exclamationmarkContract"
              aria-haspopup="true"
              className="tooltipFont"
            >
              <p className="text-center">
                Contract is Incomplete. <br></br> Please enter employment
                details.
              </p>
            </ReactTooltip>
          </>
        );
      } else if (
        this.state.expiredContractUserList.indexOf(rowData.userID) !== -1
      ) {
        icon = (
          <>
            <span data-tip="" data-for="exclamationmarkContractExpired">
              <i className="fas fa-exclamation exclamationStyle"></i>
            </span>

            <ReactTooltip
              id="exclamationmarkContractExpired"
              aria-haspopup="true"
              className="tooltipFont"
            >
              <p className="text-center">
                Contract has expired. Please deactivate the user or create a new
                contract.
              </p>
            </ReactTooltip>
          </>
        );
      }
    }
    return icon;
  }

  //    ___  _          _
  //   / __|| |_  __ _ | |_  _  _  ___
  //   \__ \|  _|/ _` ||  _|| || |(_-<
  //   |___/ \__|\__,_| \__| \_,_|/__/
  //
  displayStatusButtons() {
    return (
      <Column
        className="p-col"
        body={this.statusButton}
        header="Current Status"
      />
    );
  }
  statusButton(rowData: UserModel) {
    let isDisabled = false;

    if (rowData.accStatus === UserAccStatus.Onboarding) {
      isDisabled = true;
    }

    let accbutton;
    if (
      this.props.userProfile.taskList !== undefined &&
      this.commonMethods.isArrayPresentInArray(
        this.props.userProfile.taskList,
        [TaskList.useraccess]
      )
    ) {
      accbutton = (
        <Dropdown
          tooltip="Change Account Status"
          tooltipOptions={{ position: "top" }}
          value={rowData.accStatus}
          options={this.statusDropdownOptions}
          appendTo={document.body}
          onChange={(e) => {
            this._isMounted &&
              this.setState(
                {
                  userObj: rowData,
                  statusToBeUpdated: e.value,
                },
                () => this.confirmUpdateDialog(e)
              );
          }}
          disabled={isDisabled}
          placeholder="Please Select"
        />
      );
    }

    return <>{accbutton}</>;
  }

  confirmUpdateDialog(e: { originalEvent: Event; value: any }) {
    let activatestr = `Activate ${this.state.userObj.firstName} ${this.state.userObj.lastName}'s account?`;
    let deactivatestr = `Deactivate ${this.state.userObj.firstName} ${this.state.userObj.lastName}'s account?`;
    this._isMounted &&
      this.setState({
        dialogStr:
          e.value === UserAccStatus.Active ? activatestr : deactivatestr,
        updateStatusDialogVisible: true,
      });
  }

  updateUserStatus() {
    this._isMounted &&
      this.setState(
        {
          updateStatusDialogVisible: false,
          isLoading: true,
          userObj: {
            ...this.state.userObj,
            accStatus: this.state.statusToBeUpdated,
            jobEndDate: this.state.deactivatedDate,
          },
        },
        () => {
          this.userService
            .updateUserStatus(this.state.userObj)
            .then((res) => {
              this._isMounted && this.setState({ userObj: res });
              let msg = res.firstName + "'s account is now " + res.accStatus;

              this.context.SetSuccess(msg);

              this.getUsers(UserAccStatus.Active, "", "");
            })
            .catch((error) => {
              let errorMsg = this.commonMethods.getErrorMessage(error);
              this.context.SetError(errorMsg);
              this._isMounted && this.setState({ isLoading: false });
            });
        }
      );
  }

  //    ___        _  _                    _        _
  //   | _ ) _  _ | || |__  _  _  _ __  __| | __ _ | |_  ___
  //   | _ \| || || || / / | || || '_ \/ _` |/ _` ||  _|/ -_)
  //   |___/ \_,_||_||_\_\  \_,_|| .__/\__,_|\__,_| \__|\___|
  //                             |_|

  getNameList() {
    let namelist: any;
    let arrToDisplay: any[] = [];
    this.state.userObjList.forEach((item, index) => {
      namelist = (
        <tr>
          <td className="font-weight-bold">{index + 1}</td>
          <td>
            {item.firstName} {item.lastName}
          </td>
        </tr>
      );
      arrToDisplay.push(namelist);
    });
    return arrToDisplay;
  }
  confirmBulkUpdateLeaveCreditsDialog() {
    let arrToDisplay = this.getNameList();

    let dialogstr = (
      <table className="leave-info-dialog table">
        <tbody>{arrToDisplay}</tbody>
      </table>
    );

    this._isMounted &&
      this.setState({
        dialogStr: dialogstr,
        bulkCreateLeaveDialog: true,
        batchUpdateSelected: true,
        bulkOption: "Leave",
      });
  }

  resetLeavesDialog(){
    let arrToDisplay = this.getNameList();

    let dialogstr = (
      <table className="leave-info-dialog table">
        <tbody>{arrToDisplay}</tbody>
      </table>
    );

    this._isMounted &&
      this.setState({
        dialogStr: dialogstr,
        resetLeavesDialog: true,
      });
  }

  businessCardDialog(){
	let arrToDisplay = this.getNameList();

    let dialogstr = (
      <table className="leave-info-dialog table">
        <tbody>{arrToDisplay}</tbody>
      </table>
    );

    this._isMounted &&
      this.setState({
        dialogStr: dialogstr,
        businessCardDialog: true,
      });
  }

  massApplyLeaveDialog(){
	let arrToDisplay = this.getNameList();

    let dialogstr = (
      <table className="leave-info-dialog table">
        <tbody>{arrToDisplay}</tbody>
      </table>
    );

    this._isMounted &&
      this.setState({
        dialogStr: dialogstr,
        massApplyLeaveDialog: true,
      });
  }

  bulkUpdateLeaveCredits() {
    this._isMounted &&
      this.setState({
        redirectOut: true,
        redirectPath: "/admin/employees/credit_leave",
        redirectState: this.state.userObjList,
        redirectParam: "?batch",
      });
  }

  setStartDatesSelected(type: string) {
    if (type === "date") {
    	this.setState({
            leaveStartDate: this.commonMethods.setStartOfDayOfDate(this.state.leaveStartDate),
            leaveEndDate: this.commonMethods.setEndOfDayOfDate(this.state.leaveStartDate),
            leaveEndDuration: "",
            leaveStartDuration: "FULL DAY",
        });
    } else if (type === "duration") {
    	if (this.state.leaveStartDuration === "AM") {
        	this.setState({
				leaveStartDate: this.commonMethods.setStartOfDayOfDate(this.state.leaveStartDate),
              	leaveEndDate: this.commonMethods.setDateAtTwoPM(this.state.leaveStartDate),
              	leaveEndDuration: "",
          	});
      	} else if (this.state.leaveStartDuration === "PM") {
        	this.setState({
				leaveStartDate: this.commonMethods.setDateAtTwoPM(this.state.leaveStartDate),
          	});
      	} else {
        	this.setState({
            	leaveStartDate: this.commonMethods.setStartOfDayOfDate(this.state.leaveStartDate),
            	leaveStartDuration: "FULL DAY",
          	});
      	}
    }
  }

  setEndDatesSelected(type: string) {
	if (type === "date") {
      	let startduration = "FULL DAY";
      	if (this.state.leaveStartDuration === "PM") {
        	startduration = "PM";
      	}
      	this.setState({
			leaveEndDate: this.commonMethods.setEndOfDayOfDate(this.state.leaveEndDate),
			leaveStartDuration: startduration,
        });
    } else if (type === "duration") {
      	if (this.state.leaveEndDuration === "AM") {
        	this.setState({
				leaveEndDate: this.commonMethods.setDateAtTwoPM(this.state.leaveEndDate)
			});
      } else {
        	this.setState(
          	{
				leaveEndDate: this.commonMethods.setEndOfDayOfDate(this.state.leaveEndDate),
              	leaveEndDuration: "FULL DAY",
          },
        );
      }
    }
  }

  resetLeavesForSelectedEmployees(){
    this.statusService
      .resetLeavesForSelectedEmployees(this.state.userObjList)
      .then(res => {
        this.context.SetSuccess(
          "Leaves have been resetted for the selected users"
        );
        this.setState({ resetLeavesDialog: false});
      })
      .catch(err => {
        const error = this.commonMethods.getErrorMessage(err);
        this.context.SetError(error);
        this.setState({ isLoading: false });
      })
  }


  generatePrintsForSelectedEmployees(){
	this.userService
		.generatePrintsForSelectedEmployees(this.state.userObjList)
		.then((res: any) => {

			var blob = new Blob([res.data], {type: "application/zip"});
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', "businesscard.zip");
			document.body.appendChild(link);
			link.click()

			this.context.SetSuccess(
				"Business Card prints is being generated for the selected users"
			);
			this.setState({ businessCardDialog: false });
		})
		.catch(err => {
			this.context.SetError("Error generating Business Card Prints for Users");
			this.setState({ isLoading: false });
		})
  }

  applyLeavesForSelectedEmployees(){
	let userStatusObj = new UserStatusModel();
	userStatusObj.userList = JSON.stringify(this.state.userObjList);
	userStatusObj.startDate = this.state.leaveStartDate;
	userStatusObj.startDuration = this.state.leaveStartDuration;
	userStatusObj.endDate = this.state.leaveEndDate;
	userStatusObj.endDuration = this.state.leaveEndDuration;

	console.log("initial tranfer");
	console.log(userStatusObj);

	this.statusRecordService
		.createStatusRecordMultiple(userStatusObj)
		.then((res: UserStatusIssueModel[]) => {
			this.setState({ listOfUserIssues: res}, () => {
				setTimeout(() => {
					this.csvLinkEl.current.link.click();
				})
			})
		})
		.catch((err) => {
			this.context.SetError("Error apply leaves for users");
			this.setState({ isLoading: false})
		})
		.finally(() => {
			this.setState({ massApplyLeaveDialog: false});
		})
  }

  confirmBulkUpdateStatusDialog() {
    let arrToDisplay = this.getNameList();

    let dialogstr = (
      <table className="leave-info-dialog table">
        <tbody>{arrToDisplay}</tbody>
      </table>
    );

    this._isMounted &&
      this.setState({
        dialogStr: dialogstr,
        bulkUpdateStatusDialogVisible: true,
        batchUpdateSelected: true,
        bulkOption: "Status",
      });
  }

  // bulkUpdateUserStatus() {
  //   this.state.userObjList.forEach((user) => {

  //     user.jobEndDate = this.state.deactivatedDate;
  //   });
  //   this._isMounted &&
  //     this.setState(
  //       {
  //         bulkUpdateStatusDialogVisible: false,
  //       },
  //       () => {
  //         this.userService
  //           .updateUserStatusBulk(this.state.userObjList)
  //           .then((res) => {
  //             this._isMounted && this.setState({ userObj: res });
  //             let msg = "Account Status has been deactivated";

  //             this.context.SetSuccess(msg);

  //             this.getUsers(UserAccStatus.Active, "", "");
  //           })
  //           .catch((error) => {
  //             let errorMsg = this.commonMethods.getErrorMessage(error);

  //             this.context.SetError(errorMsg);

  //             this._isMounted &&
  //               this.setState({
  //                 isLoading: false,
  //               });
  //           });
  //       }
  //     );
  // }

  //    ___                         _     _   _
  //   |_ _| _ __   _ __  ___  _ _ | |_  | | | | ___ ___  _ _  ___
  //    | | | '  \ | '_ \/ _ \| '_||  _| | |_| |(_-</ -_)| '_|(_-<
  //   |___||_|_|_|| .__/\___/|_|   \__|  \___/ /__/\___||_|  /__/
  //               |_|

  downloadTemplate(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.userService.downloadTemplate({}).then((res) => {
      window.open(res);
    });
  }

  uploadDocument(e: React.ChangeEvent<HTMLInputElement>) {
    if (
      e !== null &&
      e.currentTarget.files !== null &&
      e.currentTarget.files[0] !== undefined
    ) {
      this.updateProperty("uploadPath", e.currentTarget.files[0].name);
      this.updateProperty("uploadFile", e.currentTarget.files[0]);
    }
  }

  updateProperty(property: string, value: any) {
    let tempObj = this.state.importUserModel;
    (tempObj as any)[property] = value;
    this._isMounted &&
      this.setState({
        importUserModel: tempObj,
      });
  }

  uploadImportedDoc() {
    this._isMounted &&
      this.setState({ isLoading: true }, () => {
        if (this.state.importUserModel.uploadFile !== "") {
          this.userService
            .importEmployees(this.state.importUserModel)
            .then((res) => {
              this.context.SetSuccess("Successfully imported.");
              this._isMounted &&
                this.setState({ importVisible: false, isLoading: false });
            })
            .catch((err) => {
              if (this.commonMethods.isWarning(err)) {
                let errorMsg = this.commonMethods.getErrorMessage(err);
                let errMsg = err.response.data.warning + " - " + errorMsg;
                this.context.SetError(errMsg);
              } else {
                let errorMsg = this.commonMethods.getErrorMessage(err);
                this.context.SetError(errorMsg);
              }

              this._isMounted &&
                this.setState({
                  isLoading: false,
                });
            });
        } else {
          this._isMounted &&
            this.setState({
              isLoading: false,
            });
          this.context.SetError("Please upload a file");
        }
      });
  }

  render() {
    let secondaryFilter = this.setSecondaryFilter();
    let permissionbutton = this.displayPermissionButton();
    let addUserButton = this.addUserButton();
    let statusRecordButtons = this.viewStatusRecordButtons();
    let contractbuttons = this.displayContractButtons();
    let statusbuttons = this.displayStatusButtons();
    let viewPeronalDetailsButtons = this.viewPeronalDetailsButtons();
    let listHeader = (
      <div style={{ textAlign: "left" }}>
        <span className="p-input-icon-left mr-1 mb-1">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            onInput={(e) => {
              this._isMounted &&
                this.setState({ searchValue: e.currentTarget.value });
            }}
          />
        </span>
        <Dropdown
          className="mb-1 mr-1"
          value={this.state.viewOptionSelected}
          options={this.state.viewOptions}
          onChange={(e) =>
            this.getUsers(
              e.value,
              this.state.primaryFilterOptionSelected,
              this.state.secondaryFilterOptionSelected
            )
          }
          placeholder={UserAccStatus.Active}
        />
        <Dropdown
          className="mb-1 mr-1"
          value={this.state.primaryFilterOptionSelected}
          options={this.state.primaryFilterOptions}
          onChange={this.initSecondaryFilterView}
          placeholder="Please Select"
        />
        {secondaryFilter}
        <Dropdown
          value={this.state.contractOptionSelected}
          options={this.contractOptions}
          onChange={this.contractFilterOnChange}
          placeholder="Contract Status"
        />
      </div>
    );

    if (
      this.state.userObjList.length > 0 &&
      this.state.viewOptionSelected === UserAccStatus.Active
    ) {
      listHeader = (
        <div style={{ textAlign: "left" }}>
          <span className="p-input-icon-left mr-1 mb-1">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            onInput={(e) => {
              this._isMounted &&
                this.setState({ searchValue: e.currentTarget.value });
            }}
          />
        </span>

          <Button
            label="Credit/Debit Leave"
            tooltip=""
            tooltipOptions={{ position: "top" }}
            type="button"
            className={"p-button-warning mx-1"}
            onClick={() => this.confirmBulkUpdateLeaveCreditsDialog()}
          />

          <Button
            label="Reset Leaves"
            tooltip="Reset leaves for the end of the year"
            tooltipOptions={{ position: "top" }}
            type="button"
            className={"p-button-primary mx-1"}
            onClick={() => this.resetLeavesDialog()}
          />

		<Button
            label="Generate Business Card"
            tooltip="Generate prints for Business Cards"
            tooltipOptions={{ position: "top" }}
            type="button"
            className={"p-button-success mx-1"}
            onClick={() => this.businessCardDialog()}
		/>

		<Button
            label="Mass Apply Leaves"
            tooltip="Apply leaves for multiple employees"
            tooltipOptions={{ position: "top" }}
            type="button"
            className={"p-button-primary mx-1"}
            onClick={() => this.massApplyLeaveDialog()}
		/>

          {/* <Button
            icon="pi pi-trash"
            tooltip="Deactivate Employees"
            tooltipOptions={{ position: "top" }}
            type="button"
            className={"p-button-danger "}
            onClick={() => this.confirmBulkUpdateStatusDialog()}
          /> */}
        </div>
      );
    }

    let data = (
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          value={this.state.allUsers}
          autoLayout={true}
          paginator={true}
          alwaysShowPaginator={false}
          rows={20}
          header={listHeader}
          globalFilter={this.state.searchValue}
          selection={this.state.userObjList}
          onSelectionChange={(e) => {
            this._isMounted &&
              this.setState({
                userObjList: e.value,
                scrollPanelHeight:
                  e.value.length === 1
                    ? "100px"
                    : e.value.length * 20 < 200
                    ? "150px"
                    : e.value.length * 20 > 200
                    ? "350px"
                    : (e.value.length * 20).toString(),
              });
          }}
          emptyMessage="No results found."
        >
          <Column
            selectionMode="multiple"
            style={{ width: "1em", paddingRight: "0px" }}
            className={this.state.displayCheckboxes}
          />
          <Column
            className="p-col"
            body={this.displayFirstLastName}
            header="Name"
          />

          <Column className="p-col" field="departmentName" header="Department" />
          {viewPeronalDetailsButtons}

          <Column
            style={{ padding: "0px" }}
            body={this.displayUserExclamationMark}
            header=""
          />
          {statusRecordButtons}

          {permissionbutton}

          {contractbuttons}

          <Column
            style={{ padding: "0px" }}
            body={this.displayContractExclamationMark}
            header=""
          />

          {statusbuttons}

          {/* This 2 columns are for search */}
          <Column className="p-col d-none" field="firstName" header="firstname" />
          <Column className="p-col d-none" field="lastName" header="lastname" />
        </DataTable>
      </div>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = data;
    }

    let updateStatusDialog = (
      <Dialog
        // className="popupDialog"
        header={"Update status"}
        visible={this.state.updateStatusDialogVisible}
        modal={true}
        onHide={() =>
          this._isMounted && this.setState({ updateStatusDialogVisible: false })
        }
        footer={
          <>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              onClick={() => {
                this.updateUserStatus();
              }}
            />
            <Button
              label="No"
              icon="pi pi-times"
              onClick={() =>
                this._isMounted &&
                this.setState({ updateStatusDialogVisible: false })
              }
            />
          </>
        }
      >
        <div>
          <div className="text-center pb-4 ">{this.state.dialogStr}</div>
          {this.state.statusToBeUpdated === UserAccStatus.Deactivated && (
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <span className="boldLabel">
                  Last official working day{" "}
                  <span style={{ color: "red" }}>*</span>
                </span>
                <Calendar
                  dateFormat="dd/mm/yy"
                  readOnlyInput={true}
                  value={this.state.deactivatedDate}
                  onChange={(e) => {
                    this.setState({
                      deactivatedDate: new Date(e.value.toString()),
                    });
                  }}
                  required
                />
              </div>
            </div>
          )}
        </div>
      </Dialog>
    );

    // let bulkUpdateStatusDialog = (
    //   <Dialog
    //     // className="popupDialog"
    //     header={"Bulk update status"}
    //     visible={this.state.bulkUpdateStatusDialogVisible}
    //     modal={true}
    //     onHide={() =>
    //       this._isMounted &&
    //       this.setState({ bulkUpdateStatusDialogVisible: false })
    //     }
    //     footer={
    //       <>
    //         <Button
    //           label="Yes"
    //           type="button"
    //           iconPos="left"
    //           icon="pi pi-check"
    //           onClick={() => {
    //             this.bulkUpdateUserStatus();
    //           }}
    //         />
    //         <Button
    //           label="No"
    //           icon="pi pi-times"
    //           onClick={() =>
    //             this._isMounted &&
    //             this.setState({ bulkUpdateStatusDialogVisible: false })
    //           }
    //         />
    //       </>
    //     }
    //   >
    //     <div>
    //       <ScrollPanel style={{ height: "500px" }}>
    //         <div className="p-grid p-fluid">
    //           <div className="p-col-12">
    //             <span className="boldLabel">
    //               Last official working day{" "}
    //               <span style={{ color: "red" }}>*</span>
    //             </span>
    //             <Calendar
    //               dateFormat="dd/mm/yy"
    //               readOnlyInput={true}
    //               value={this.state.deactivatedDate}
    //               onChange={(e) => {
    //                 this.setState({
    //                   deactivatedDate: new Date(e.value.toString()),
    //                 });
    //               }}
    //               required
    //             />
    //           </div>
    //         </div>

    //         {this.state.dialogStr}
    //       </ScrollPanel>
    //     </div>
    //   </Dialog>
    // );

    let importEmployeeDialog = (
      <Dialog
        header="Import Employees"
        visible={this.state.importVisible}
        modal={true}
        //dismissableMask={true}
        onHide={() =>
          this._isMounted && this.setState({ importVisible: false })
        }
        footer={
          <>
            <Button
              label="Submit"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              onClick={() => {
                this.uploadImportedDoc();
              }}
            />
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={() =>
                this._isMounted && this.setState({ importVisible: false })
              }
            />
          </>
        }
      >
        <p>Import your employees by filling up our bulk upload template.</p>
        <span className="boldLabel py-2">Download Template</span>
        <div className="py-2">
          <Button
            label="Download"
            type="button"
            iconPos="left"
            onClick={(e) => {
              this.downloadTemplate(e);
            }}
          />
        </div>

        <span className="boldLabel py-2">Upload SpreadSheet</span>
        <div className="py-2">
          <input
            style={{ color: "transparent", width: "110px" }}
            type="file"
            name="uploadFile"
            onChange={(e) => {
              this.uploadDocument(e);
            }}
          />
          {this.state.importUserModel.uploadPath}
        </div>
      </Dialog>
    );

    let bulkCreditLeaveDialog = (
      <Dialog
        className="popupDialog"
        //dismissableMask={true}
        header="Credit/Debit Leaves to these users?"
        visible={this.state.bulkCreateLeaveDialog}
        modal={true}
        onHide={() =>
          this._isMounted && this.setState({ bulkCreateLeaveDialog: false })
        }
        footer={
          <>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              onClick={() => {
                this.bulkUpdateLeaveCredits();
              }}
            />
            <Button
              label="No"
              icon="pi pi-times"
              onClick={() =>
                this._isMounted &&
                this.setState({ bulkCreateLeaveDialog: false })
              }
            />
          </>
        }
      >
        <ScrollPanel style={{ height: "500px" }}>
          <div>{this.state.dialogStr}</div>
        </ScrollPanel>
      </Dialog>
    );

    let resetLeavesForSelectedEmployeesDialog = (
      <Dialog
        className="popupDialog"
        header="Reset Leaves for these users"
        visible={this.state.resetLeavesDialog}
        modal={true}
        onHide={()=>
          this._isMounted && this.setState({ resetLeavesDialog: false})
        }
        footer={
          <>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              onClick={() => {
                this.resetLeavesForSelectedEmployees()
              }}
            />
            <Button
              label="No"
              icon="pi pi-times"
              onClick={() =>
                this._isMounted && this.setState({ resetLeavesDialog: false })
              }
            />
          </>
        }
      >
        <ScrollPanel style={{ height: "500px" }}>
          <div>{this.state.dialogStr}</div>
        </ScrollPanel>
      </Dialog>
    )

	let generateBusinessCardsForSelectedEmployeesDialog = (
		<Dialog
		  className="popupDialog"
		  header="Generate Business Card Prints for these users"
		  visible={this.state.businessCardDialog}
		  modal={true}
		  onHide={()=>
			this._isMounted && this.setState({ businessCardDialog: false})
		  }
		  footer={
			<>
			  <Button
				label="Yes"
				type="button"
				iconPos="left"
				icon="pi pi-check"
				onClick={() => {
				  this.generatePrintsForSelectedEmployees()
				}}
			  />
			  <Button
				label="No"
				icon="pi pi-times"
				onClick={() =>
				  this._isMounted && this.setState({ businessCardDialog: false })
				}
			  />
			</>
		  }
		>
		  <ScrollPanel style={{ height: "500px" }}>
			<div>{this.state.dialogStr}</div>
		  </ScrollPanel>
		</Dialog>
	);

	let massApplyLeaveDialogBox = (
		<Dialog
		  className="popupDialogModified"
		  header="Apply leaves for these users"
		  visible={this.state.massApplyLeaveDialog}
		  modal={true}
		  onHide={()=>
			this._isMounted && this.setState({ massApplyLeaveDialog: false})
		  }
		  footer={
			<>
			  <Button
				label="Yes"
				type="button"
				iconPos="left"
				icon="pi pi-check"
				onClick={() => {
				  this.applyLeavesForSelectedEmployees();
				}}
			  />
			  <Button
				label="No"
				icon="pi pi-times"
				onClick={() =>
				  this._isMounted && this.setState({ massApplyLeaveDialog: false })
				}
			  />
			</>
		  }
		>
			<ScrollPanel style={{ height: "200px" }}>
				<div>{this.state.dialogStr}</div>
			</ScrollPanel>

			<span className="boldLabel">Leave Type: Annual Leave</span>
				<div className="col-12 pt-2 pl-0">
					<div>
						<span className="boldLabel">Start Date {" "}</span>
						<Calendar
							id="StartDate"
							readOnlyInput={true}
							dateFormat="dd/mm/yy"
							viewDate={this.state.leaveStartDate}
							value={this.state.leaveStartDate}
							showButtonBar={true}
							inline={false}
							maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
							onChange={(e) => {
								e.originalEvent.preventDefault();
								if (e.value !== undefined) {
									this.setState(
									{
										leaveStartDate: new Date(e.value.toString()),
										leaveEndDate: new Date(e.value.toString()),
									})
								}
							}}
						/>
					</div>
					<div className="my-2">
						<SelectButton
							id="select-button"
							value={this.state.leaveStartDuration}
							options={
							new Date(
								this.state.leaveStartDate
							).toLocaleDateString() ===
							new Date(
								this.state.leaveEndDate
							).toLocaleDateString()
								? this.leaveDurationSameDaySelection
								: this.leaveDurationDifferentDaySelectionForStartDate
							}
							onChange={(e) => {
								e.value !== null &&
									this.setState({
										leaveStartDuration: e.value
									},
									() => {
										this.setStartDatesSelected("duration");
									});
							}}
						/>
					</div>
				</div>
				<div className="col-12 pt-2 pl-0">
					<div>
						<span className="boldLabel">End Date {" "}</span>
						<Calendar
							id="EndDate"
							readOnlyInput={true}
							dateFormat="dd/mm/yy"
							value={this.state.leaveEndDate}
							inline={false}
							minDate={new Date(this.state.leaveStartDate.toString())}
							onChange={(e) => {
								e.value !== undefined
									? this.setState({
										leaveEndDate: new Date(e.value.toString()),
										leaveEndDuration: "FULL DAY",
									},
									() => {
										this.setEndDatesSelected("date");
									}
									)
									: this.setState({
										leaveEndDate: this.state.leaveStartDate,
									});
							}}
						/>
					</div>
					<div className="my-2">
						<SelectButton
							id="select-button"
							value={this.state.leaveEndDuration}
							options={
							new Date(
								this.state.leaveStartDate
							).toLocaleDateString() ===
							new Date(
								this.state.leaveEndDate
							).toLocaleDateString()
								? this.leaveDurationSameDaySelection
								: this.leaveDurationDifferentDaySelectionForEndDate
							}
							disabled={
								new Date(this.state.leaveStartDate).toLocaleDateString()
								===
								new Date(this.state.leaveEndDate).toLocaleDateString()
							  }
							onChange={(e) => {
							if (e.value !== null) {
								this.setState(
								{
									leaveEndDuration: e.value,
								},
								() => {
									this.setEndDatesSelected("duration");
								}
								);
							}
							}}
						/>
					</div>
				</div>
		</Dialog>


	);

    let deleteDummyDialog = (
      <Dialog
        className="popupDialog"
        header="Delete Test Users"
        visible={this.state.confirmDeleteVisible}
        modal={true}
        onHide={() =>
          this._isMounted && this.setState({ confirmDeleteVisible: false})
        }
        footer={
          <>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              onClick={() => {
                this.deleteDummyUsers();
              }}
            />
            <Button
              label="No"
              icon="pi pi-times"
              onClick={() =>
                this._isMounted && this.setState({ confirmDeleteVisible: false })
              }
            />
          </>
        }
      >
        Are you sure you want to delete the test users?
      </Dialog>
    )

    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
            state: this.state.redirectState,
            search: this.state.redirectParam,
          }}
        />
      );
    }

    return (
      <div className="row">
        <div className="col-12 col-xl-10">
          {updateStatusDialog}
          {/* {bulkUpdateStatusDialog} */}
          {importEmployeeDialog}
          {bulkCreditLeaveDialog}
          {resetLeavesForSelectedEmployeesDialog}
		  {generateBusinessCardsForSelectedEmployeesDialog}
		  {massApplyLeaveDialogBox}
          {deleteDummyDialog}
		  	<CSVLink
				headers={this.headers}
				filename="User Leave Issues EOY Closure.csv"
				data={this.state.listOfUserIssues}
				ref={this.csvLinkEl}
				className="display-none"
			/>
          <div className="card">
            <h1 className="pb-2">
              Employees
              {addUserButton}
              <p
                className="float-right"
                hidden={this.state.userObjList.length === 0}
              >
                {this.state.userObjList.length} items selected
              </p>
            </h1>
            {display}
          </div>
        </div>
      </div>
    );
  }
}
