import { MentionData } from '@draft-js-plugins/mention'
import React, { useEffect, useReducer, useState } from 'react'
import { RouteComponentProps } from "../../../resources/CommonMethods";
import { TaskList } from '../../../resources/ListOfTasks'
import { toTitleCase } from '../../CompanyFeedFunctions'
import { ACTIONS } from '../../DispatchActions';
import { PostModel, UserDetails } from '../../Model/PostModel'
import CompanyFeedService from '../../Service/CompanyFeedService'
import CompanyFeedErrorPage from '../Error/CompanyFeedErrorPage'
import SinglePost from './SinglePost'


export class SinglePostState {
    currentUser: UserDetails = new UserDetails()
    post: PostModel = new PostModel()
    isLoaded: boolean = false
    allMentionSuggestions: MentionData[] = []
}

function reducer(
    state: SinglePostState,
    action: { type: any; payload: any; }) {
    switch (action.type) {
        case ACTIONS.LOAD_POST: {
            return { ...state, post: action.payload }
        }
        case ACTIONS.SET_POST_LOADED: {
            return { ...state, isLoaded: action.payload }
        }
        case ACTIONS.EDIT_POST: {
            const editedPost = action.payload
            console.log('SINGLEPOST EDIT_POST', editedPost)
            let postObjectEdit = {
                ...state.post,
                postText: editedPost.postText,
                rawEditorContentJsonStr: editedPost.rawEditorContentJsonStr,
                usersTagged: editedPost.usersTagged,
                usersTaggedArray: editedPost.usersTaggedArray,
                imagePath: editedPost.imagePath,
                imagePathArray: editedPost.imagePathArray,
                isEdited: editedPost.isEdited,
                lastUpdated: editedPost.lastUpdated,
            }
            return { ...state, post: postObjectEdit };
        }
        case ACTIONS.LOAD_COMMENTS: {
            console.log('SinglePost LOAD_COMMENTS')
            const postObject = state.post
            postObject.comments = action.payload.comments
            return { ...state, post: postObject };
        }
        case ACTIONS.ADD_COMMENT: {
            console.log('SinglePost ADD_COMMENT')
            const commentToAdd = action.payload
            const postObject = state.post
            if (postObject.postId === commentToAdd.postId) {
                // postObject.comments?.push(commentToAdd)
                postObject.comments?.unshift(commentToAdd)
                postObject.numberOfComments++
            }
            return { ...state, post: postObject };
        }
        case ACTIONS.EDIT_COMMENT: {
            console.log('SinglePost EDIT_COMMENT')
            const editedComment = action.payload.editedComment
            const postObject = state.post
            if (postObject.postId === editedComment.postId) {
                postObject.comments?.map(comment => {
                    if (comment.commentId === editedComment.commentId) {
                        comment.commentText = editedComment.commentText
                        comment.isEdited = editedComment.isEdited
                        comment.lastUpdated = editedComment.lastUpdated
                        comment.rawEditorContentJsonStr = editedComment.rawEditorContentJsonStr
                        comment.usersTagged = editedComment.usersTagged
                        comment.usersTaggedArray = editedComment.usersTaggedArray
                    }
                    return comment
                })
            }
            return { ...state, post: postObject };
        }
        case ACTIONS.DELETE_COMMENT: {
            console.log('SinglePost DELETE_COMMENT')
            const postId = action.payload.postId
            const commentId = action.payload.commentId
            const postObject = state.post
            if (postObject.postId === postId) {
                postObject.comments = postObject.comments!.filter(comment => comment.commentId !== commentId)
                // postObject.numberOfComments--
                postObject.numberOfComments = postObject.numberOfComments - action.payload.numberOfCommentsDeleted
            }
            return { ...state, post: postObject };
        }
        case ACTIONS.LOAD_CHILD_COMMENTS: {
            console.log('SinglePost LOAD_CHILD_COMMENTS')
            const childComments = action.payload.childComments
            const parentCommentId = action.payload.parentCommentId
            const postObject = state.post
            postObject.comments!.map(comment => {
                if (comment.commentId === parentCommentId) {
                    comment.childComments = childComments
                    comment.loadedReplies = true
                }
                return comment
            })
            return { ...state, post: postObject };
        }
        case ACTIONS.ADD_CHILD_COMMENT: {
            console.log('SinglePost ADD_CHILD_COMMENT')
            const childToAdd = action.payload
            const postObject = state.post
            if (postObject.postId === childToAdd.postId) {
                postObject.comments?.map(comment => {
                    if (comment.commentId === childToAdd.parentCommentId) {
                        postObject.numberOfComments++
                        comment.numberOfChildComments++
                        if (comment.childComments!?.length > 0) comment.childComments!.push(childToAdd)
                        // if (comment.childComments!?.length > 0) comment.childComments!.unshift(childToAdd)
                        else comment.childComments = [childToAdd]
                    }
                    return comment
                })
            }
            return { ...state, post: postObject };
        }
        case ACTIONS.EDIT_CHILD_COMMENT: {
            console.log('SinglePost EDIT_CHILD_COMMENT')
            const editedComment = action.payload.editedComment
            const postObject = state.post
            if (postObject.postId === editedComment.postId) {
                postObject.comments?.map(parent => {
                    if (parent.commentId === editedComment.parentCommentId) {
                        parent.childComments?.map(child => {
                            if (child.commentId === editedComment.commentId) {
                                child.commentText = editedComment.commentText
                                child.isEdited = editedComment.isEdited
                                child.lastUpdated = editedComment.lastUpdated
                                child.rawEditorContentJsonStr = editedComment.rawEditorContentJsonStr
                                child.usersTagged = editedComment.usersTagged
                                child.usersTaggedArray = editedComment.usersTaggedArray
                            }
                            return child
                        })
                    }
                    return parent
                })
            }
            return { ...state, post: postObject };
        }
        case ACTIONS.DELETE_CHILD_COMMENT: {
            console.log('SinglePost DELETE_CHILD_COMMENT')
            const postId = action.payload.postId
            const commentId = action.payload.commentId
            const parentCommentId = action.payload.parentCommentId
            const postObject = state.post
            if (postObject.postId === postId) {
                postObject.comments!.map(parent => {
                    if (parent.commentId === parentCommentId) {
                        parent.childComments = parent.childComments!.filter(child => child.commentId !== commentId)
                        postObject.numberOfComments--
                    }
                    return parent
                })
                postObject.comments = postObject.comments!.filter(comment => comment.commentId !== commentId)
            }
            return { ...state, post: postObject };
        }
        case ACTIONS.ADD_LIKE: {
            const postId = action.payload.postId
            const userId = action.payload.userId
            console.log('SinglePost ADD_LIKE', userId)
            const postObject = state.post
            if (postObject.postId === postId) {
                if (postObject.usersWhoLikedArray) postObject.usersWhoLikedArray.push(userId)
                if (postObject.usersWhoLikedArray == null) postObject.usersWhoLikedArray = [userId]
            }
            return { ...state, post: postObject }
        }
        case ACTIONS.REMOVE_LIKE: {
            const postId = action.payload.postId
            const userId = action.payload.userId
            console.log('SinglePost REMOVE-LIKE', userId)
            const postObject = state.post
            if (postObject.postId === postId && postObject.usersWhoLikedArray) {
                postObject.usersWhoLikedArray = postObject.usersWhoLikedArray.filter(item => item !== userId)
            }
            return { ...state, post: postObject }
        }
        case ACTIONS.SAVE_CURRENT_USER: {
            console.log('SinglePost SAVE_CURRENT_USER', action.payload)
            return { ...state, currentUser: {...state.currentUser, ...action.payload} }
        }
        case ACTIONS.SET_CURRENT_USER_COMPANYFEED_ADMIN: {
            console.log('SinglePost SET_CURRENT_USER_COMPANYFEED_ADMIN', action.payload)
            return { ...state, currentUser: { ...state.currentUser, hasCompanyFeedAdminAccess: action.payload } }
        }
        case ACTIONS.SET_ALL_MENTION_SUGGESTIONS: {
            console.log('SinglePost SET_ALL_MENTION_SUGGESTIONS')
            return { ...state, allMentionSuggestions: action.payload }
        }
        default:
            throw new Error("reducer error");
    }
}

export const SinglePostContext = React.createContext<{
    state: SinglePostState
    dispatch: React.Dispatch<{
        type: any;
        payload: any;
    }>
}>({
    state: new SinglePostState(),
    dispatch: () => undefined,
});

export default function SinglePostContainer(props: RouteComponentProps<any>) {
    const [state, dispatch] = useReducer(reducer, new SinglePostState());
    const [hasError, setHasError] = useState(false)
    const [hasCompanyFeedAdminAccess] = useState(
        props.userProfile.taskList.includes(TaskList.companyfeedadmin)
      );

      useEffect(() => {
        dispatch({ type: ACTIONS.SET_CURRENT_USER_COMPANYFEED_ADMIN, payload: hasCompanyFeedAdminAccess})
    }, [hasCompanyFeedAdminAccess])


    useEffect(() => {
        console.log('SinglePost State', state)
    }, [state])

    // TODO: check if comment/childComment
    // if comment, open comment and window.scrollTo comment
    // if childComment, retrieve childComment from database, open and scroll to it
    useEffect(() => {
        var propsLocationState = props.location.state
        console.log('propsLocationState', propsLocationState)
        const postId = props.match.params.postId

        async function getPostById() {
            const companyFeedSvc = new CompanyFeedService()
            const res = await companyFeedSvc.getPostById(postId)
            if (res.status === 200) {
                dispatch({ type: ACTIONS.LOAD_POST, payload: res.data })
                dispatch({ type: ACTIONS.SET_POST_LOADED, payload: true })

                // TODO: handle link to comment
                // if (propsLocationState && propsLocationState.parentCommentId) {
                //     // if link to childComment, open the parentComment and load childComments

                //     return
                // }
                // if (propsLocationState && propsLocationState.commentId) {
                //     // if link to comment, open the comment

                //     return
                // }
                // return
            }
            if (res.response && res.response.status === 400) {
                console.log('res.response.status', res.response.status)
                // show post not found page, "might have been deleted", go back to companyfeed button
                setHasError(true)
                return
            }
        }
        getPostById()

        // scroll to location of the post/comment
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        async function getAllMentionSuggestions() {
            // TODO:
            // instead of loading this everytime CompanyFeed is rendered,
            // save this locally or on server as a json file? 
            // and change the json file when UserTable changes
            const companyFeedSvc = new CompanyFeedService();
            const results = await companyFeedSvc.getAllMentionSuggestions()
            for (let i = 0; i < results.length; i++) {
                results[i].id = results[i].userId
                results[i].name = toTitleCase(results[i].firstName.concat(' ', results[i].lastName))
                results[i].avatar = results[i].displayPicture
                // results[i].link = `http://localhost:3000/publicprofile/${results[i].userId}`
            }
            // console.log('all mention suggestions', results)
            dispatch({ type: ACTIONS.SET_ALL_MENTION_SUGGESTIONS, payload: results })
        }
        getAllMentionSuggestions()
    }, [])

    useEffect(() => {
        async function getCurrUserDetailsAndDispatch(userId: string) {
            const companyFeedSvc = new CompanyFeedService();
            const results = await companyFeedSvc.getCurrentUserDetails(userId)
            // console.log('GET CURRENT USER DETAILS', results.data)
            dispatch({ type: ACTIONS.SAVE_CURRENT_USER, payload: { userId, ...results.data } })
        }
        getCurrUserDetailsAndDispatch(props.userProfile.userID)
    }, [props.userProfile.userID])

    if (hasError) return <CompanyFeedErrorPage />

    return (
        <SinglePostContext.Provider value={{ state, dispatch }}>
            <div>
                <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet" />
                <SinglePost post={state.post} contextName="SinglePost" parentComponent="SinglePostContainer" isLoaded={state.isLoaded}/>
            </div>
        </SinglePostContext.Provider>
    )
}
