import { CampaignModel } from "../components/campaign/Campaign";
import { convertObjToFormData } from "../components/resources/CommonMethodsServices";
import { HttpClient } from "./HttpClient";
export class CampaignService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async createcampaign(campaignObj: CampaignModel) {
    const res = await this.httpClient.post(
      "/api/campaign/create",
      convertObjToFormData(campaignObj)
    );
    return res;
  }

  async updatecampaign(campaignObj: CampaignModel) {
    const res = await this.httpClient.put(
      "/api/campaign/update",
      convertObjToFormData(campaignObj)
    );
    return res;
  }

  async updatecampaigncontent(campaignObj: any) {
    const res = await this.httpClient.put(
      "/api/campaign/updatecampaigncontent",
      convertObjToFormData(campaignObj)
    );
    return res;
  }

  async getAllCampaignsInGroupCompany() {
    const res = await this.httpClient.get("/api/campaign");
    return res;
  }

  async getAllCampaignsInGroupCompanyByYear(year: string) {
    const res = await this.httpClient.get("/api/campaign/year/" + year);
    return res;
  }

  async getAllMyCampaigns() {
    const res = await this.httpClient.get("/api/campaign/me");
    return res;
  }
  async getAllCampaignsByClientCompanyID(id: string) {
    const res = await this.httpClient.get(
      "/api/campaign/ClientcompanyID/" + id
    );
    return res;
  }

  async getCampaignByCampaignID(id: string) {
    const res = await this.httpClient.get("/api/campaign/" + id);
    return res;
  }

  // async getCampaignIDByCampaignUrl(id: string) {
  //   const res = await this.httpClient.get("/api/campaign/campaignid/" + id);
  //   return res;
  // }

  // async getCampaignUrlByCampaignID(id: string) {
  //   const res = await this.httpClient.get("/api/campaign/campaignurl/" + id);
  //   return res;
  // }

  async deletecampaignByID(id: string) {
    const res = await this.httpClient.delete("/api/campaign/" + id);
    return res;
  }

  async updateCampaignStatus(campaignObj: any) {
    const res = this.httpClient.put("/api/campaign/status", campaignObj);
    return res;
  }

  async getCampaignCountForTheMonth() {
    const res = this.httpClient.get("/api/campaign/OpenedCampaigns/Month");
    return res;
  }
}

export class CampaignDocumentService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async getDocumentByCampaignID(ID: string) {
    const res = await this.httpClient.get(
      "/api/campaigndocument/campaignID/" + ID
    );
    return res;
  }
  async getDocumentByID(ID: string) {
    const res = await this.httpClient.get("/api/campaigndocument/" + ID);
    return res;
  }

  async createCampaignDocument(campaignDocObj: any) {
    const res = await this.httpClient.post(
      "/api/campaigndocument/create",
      convertObjToFormData(campaignDocObj)
    );
    return res;
  }

  async deleteCampaignDocument(ID: string) {
    const res = await this.httpClient.delete("/api/campaigndocument/" + ID);
    return res;
  }

  async updateCampaignDocument(campaignDocObj: any) {
    const res = await this.httpClient.put(
      "/api/campaigndocument/update",
      convertObjToFormData(campaignDocObj)
    );
    return res;
  }
}

export default CampaignService;
