import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import * as React from "react";
import { Redirect } from "react-router";
import { LoginService } from "../service/LoginService";
import { CommonMethods } from "./resources/CommonMethods";

interface AppProps {
  loginCallback: Function;
}

interface AppState {
  email: string;
  password: string;
  token: string;
  isLoading: boolean;
  errorMsg: string;
  redirectOut: boolean;
  forgetpassword: boolean;
}

declare global {
  interface Window {
    gapi: any;
  }
}

window.gapi = window.gapi || {};

export class Login extends React.Component<AppProps, AppState> {
  loginservice: LoginService;
  commonMethods: CommonMethods;
  constructor(props: AppProps) {
    super(props);

    this.state = {
      email: "",
      password: "",
      token: "",
      isLoading: true,
      errorMsg: "",
      redirectOut: false,
      forgetpassword: false,
    };
    this.loginservice = new LoginService();
    this.submitForm = this.submitForm.bind(this);
    // this.renderButton = this.renderButton.bind(this);
    // this.onSignIn = this.onSignIn.bind(this);
    this.commonMethods = new CommonMethods();
  }

  componentDidMount() {
    // this.renderButton();
    this.setState({ isLoading: false });
  }

  // renderButton() {
  //   window.gapi.load("auth2", () => {
  //     window.gapi.auth2
  //       .init({
  //         client_id:
  //           "508785376350-49g4cobeb0ore66mj2cnruq7bcrsn95a.apps.googleusercontent.com"
  //       })
  //       .then(() => {
  //         window.gapi.signin2.render("g-signin2", {
  //           scope: "https://www.googleapis.com/auth/plus.login",
  //           width: 250,
  //           height: 50,
  //           longtitle: false,
  //           theme: "dark",
  //           onsuccess: this.onSignIn,
  //           onfailure: this.onFailure
  //         });
  //       });
  //   });
  //   this.setState({ isLoading: false });
  // }

  submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // to prevent page from refreshing!
    this.setState({ isLoading: true, errorMsg: "" });
    this.loginservice
      .login(this.state)
      .then((res) => {
        this.setState({ isLoading: false });
        this.props.loginCallback("success");
      })
      .catch((err) => {
        this.setState({
          errorMsg: this.commonMethods.getErrorMessage(err),
          isLoading: false,
        });
        // this.renderButton();
      });
  }

  forgetpassword() {
    this.loginservice.forgetpassword(this.state);
  }

  // onFailure() {}

  // onSignIn(googleUser: any) {
  //   // var profile = googleUser.getBasicProfile();
  //   var id_token = googleUser.getAuthResponse().id_token;
  //   var userLogin = {
  //     Email: "",
  //     Password: "",
  //     Token: id_token
  //   };
  //   var data = JSON.stringify(userLogin);

  //   function makeRequest() {
  //     return new Promise(function(resolve, reject) {
  //       var xhr = new XMLHttpRequest();
  //       xhr.open("POST", "/api/authentication/googleauth", true);
  //       xhr.setRequestHeader("Content-Type", "application/json");
  //       xhr.onload = function() {
  //         if (xhr.status >= 200 && xhr.status < 300) {
  //           resolve(xhr.response);
  //         } else {
  //           reject(xhr.response);
  //         }
  //       };
  //       xhr.send(data);
  //     });
  //   }

  //   makeRequest()
  //     .then(res => {
  //       this.props.loginCallback("success");
  //     })
  //     .catch(error => {
  //       this.setState({
  //         errorMsg: this.commonMethods.getErrorMessage(error),
  //         isLoading: false
  //       });
  //     });
  // }

  render() {
    let errorMsg;
    if (this.state.errorMsg !== "") {
      errorMsg = (
        <span className="text-danger">{this.state.errorMsg}</span>
        // <Message
        //   className="p-col-6"
        //   severity="error"
        //   text={this.state.errorMsg}
        // />
      );
    }
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: "/onboarding",
          }}
        />
      );
    }

    let form = (
      <form onSubmit={this.submitForm}>
        <div className="p-grid p-fluid">
          <div className="p-col-12"> {errorMsg} </div>
          <div className="p-col-12">
            <span className="boldLabel">Email</span>
            <InputText
              maxLength={254}
              required
              keyfilter="email"
              type="text"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.currentTarget.value })}
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Password</span>
            <InputText
              maxLength={128}
              required
              type="password"
              value={this.state.password}
              onChange={(e) =>
                this.setState({ password: e.currentTarget.value })
              }
            />
          </div>
        </div>
        <div className="p-grid p-justify-between pt-3">
          <Button
            type="button"
            label="Forgot Password?"
            className="m-1 p-button-secondary"
            onClick={() => {
              window.location.href = "/forgetpassword";
            }}
          />
          <Button type="submit" label="Submit" className="m-1" />
        </div>

        {/* <div className="p-grid p-justify-center p-4">
          <div id="g-signin2" />
        </div> */}
      </form>
    );

    let display;
    if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = form;
    }

    return (
      <div className="p-grid">
        <div className="p-col">
          <div className="card">
            <h1 className="pb-2">Login</h1>

            {display}
          </div>
        </div>
      </div>
    );
  }
}
