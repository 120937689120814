import { Button } from "primereact/button";
import React from "react";

function Home() {
  return (
    <>
      <section
        className="fdb-block"
        data-block-type="call_to_action"
        data-id="2"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              {/* <h1>NeuHR</h1> */}
              <img
              alt="NeuHR"
              src="/assets/NEUHR-BW.png" height="80"
            />
              <p className="h3">The HR solution you've always wanted.</p>
            </div>
          </div>

          <div className="row align-items-center pt-lg-3">
            <img
              alt=""
              className="img-fluid"
              src="/assets/thumbnails/dashboardss.png"
            />
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row text-center justify-content-center">
          <div className="col-12">
            <hr></hr>
          </div>
        </div>
      </div>

      <section className="my-5" data-block-type="contents" data-id="4">
        <div className="container text-sm-left text-center">
          <div className="row align-items-center my-5 ">
            <div className="col-12 col-lg-7 py-5 m-md-auto ">
              <img
                alt=""
                className="img-fluid"
                src="/assets/thumbnails/imdapsg-nopreapproved.png"
              />
              <h2 className="pt-5">Productivity Solutions Grant (PSG)</h2>
              <p className="lead">
                Up to 70% of funding support is provided when you adopt a
                solution to improve productivity through automation.
              </p>
              <p className="mt-4">
                <Button
                  className="btn btn-primary btn-darker mx-1 my-1"
                  onClick={() => (window.location.href = "/contactus")}
                  label="Request Demo"
                />
                <Button
                  className="btn btn-primary btn-login"
                  onClick={() => (window.location.href = "/sme")}
                  label="Try for free"
                />
              </p>
            </div>

            <div className="col-12 col-lg-5 m-auto m-md-0 ml-md-auto  ">
              <img
                alt=""
                className="img-fluid"
                src="/assets/thumbnails/Zina.jpg"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row text-center justify-content-center">
          <div className="col-12">
            <hr></hr>
          </div>
        </div>
      </div>

      <section className="fdb-block" data-block-type="features" data-id="11">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h1>Features</h1>
            </div>
          </div>

          <div className="row text-sm-left text-center align-items-center py-1">
            <div className="col-12 col-md-5">
              <img
                alt=""
                className="img-fluid"
                src="/assets/thumbnails/secure_data.png"
                height="100px"
              />
            </div>

            <div className="col-12 col-md-5 m-md-auto">
              <h2>
                <strong>Cloud Payroll</strong>
              </h2>
              <p className="lead">
                Fuss-free first time setup for the employees pay and watch it
                automate itself every month.
              </p>
              <p className="boldLabel">
                {" "}
                Employers must issue itemised pay slips to employees covered by
                the Employment Act. Records must also be maintained for 2 years.{" "}
                <sup>
                  <a href="https://www.mom.gov.sg/employment-practices/salary/paying-salary">
                    [i]
                  </a>
                </sup>{" "}
              </p>
            </div>
          </div>

          <div className="row text-sm-left text-center align-items-center py-1">
            <div className="col-12 col-md-5 m-md-auto order-md-5">
              <img
                alt=""
                className="img-fluid"
                src="/assets/thumbnails/time_management.png"
                height="50%"
              />
            </div>

            <div className="col-12 col-md-5">
              <h2>
                <strong>Leave Application</strong>
              </h2>
              <p className="lead">
                Check your leave balances and apply your leaves in an instant.
                Create your own leave types. We also support uploading of
                documents for verification.
              </p>
            </div>
          </div>

          <div className="row text-sm-left text-center align-items-center py-1">
            <div className="col-12 col-md-5">
              <img
                alt=""
                className="img-fluid"
                src="/assets/thumbnails/finance.png"
              />
            </div>

            <div className="col-12 col-md-5 m-md-auto">
              <h2>
                <strong>Company Overview</strong>
              </h2>
              <p className="lead">
                Our daily noticeboard gives you an overview of who's in and
                who's out of office at a glance.
              </p>
              <p className="lead">
                Our boss dashboard gives an overall view on finances over
                multiple companies!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fdb-block" data-block-type="features" data-id="12">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <h2>There's More...</h2>
            </div>
          </div>
          <div className="row text-center justify-content-center mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <img
                alt=""
                className="img-fluid"
                src="/assets/thumbnails/hiring.png"
                style={{ maxHeight: "10rem" }}
              />
              <h3>
                <strong>Documents Upload</strong>
              </h3>
              <p>
                Upload documents tagged to the employee such as resumes,
                marriage certificates and medical certificates.
              </p>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-4 pt-lg-0">
              <img
                alt=""
                className="img-fluid"
                src="/assets/thumbnails/access_denied.png"
                style={{ maxHeight: "10rem" }}
              />
              <h3>
                <strong>Permissions Control</strong>
              </h3>
              <p>
                Granular permission controls for each designated role defined by
                you. Hybrid roles with HR and accounting rights can be created.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4 pt-sm-0">
              <img
                alt=""
                className="img-fluid"
                src="/assets/thumbnails/booking.png"
                style={{ maxHeight: "10rem" }}
              />
              <h3>
                <strong>Facilities Booking</strong>
              </h3>
              <p>
                Create and manage facility bookings within your company. No more
                overlap bookings for the meeting room.
                {/* Book and allocate meeting rooms, sleeping pods, or even
                  pantry spaces. */}
              </p>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-4 pt-lg-0">
              <img
                alt=""
                className="img-fluid"
                src="/assets/thumbnails/personal_goals.png"
                style={{ maxHeight: "10rem" }}
              />
              <h3>
                <strong>Claims</strong>
              </h3>
              <p>
                An all-in-one claims applications, approval and reimbursement
                software. NeuHR automatically tabulates GST and supports bank
                export formats.
              </p>
            </div>
            <div className="col-12 py-3">
              <Button
                className="btn btn-primary btn-login"
                onClick={() => (window.location.href = "/features")}
                label="Learn more"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row text-center justify-content-center">
          <div className="col-12">
            <hr></hr>
          </div>
        </div>
      </div>

      <section
        className="fdb-block "
        data-block-type="testimonials"
        data-id="6"
      >
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-12">
              <h2>Our Happy Customers</h2>
              <p className="lead">
                They are already using NeuHR, what about you?
              </p>
              <div className="mt-5 justify-content-center">
                <img
                  alt="TheSmartLocal_Logo"
                  height="100"
                  className="ml-3 mr-3 mb-2 mt-2"
                  src="/assets/partners-logo/TheSmartLocal.png"
                />
                <img
                  alt="EatBook_Logo"
                  height="100"
                  className="ml-3 mr-3 mb-2 mt-2"
                  src="/assets/partners-logo/EatBook.png"
                />
                <img
                  alt="MustShareNews_Logo"
                  height="100"
                  className="ml-3 mr-3 mb-2 mt-2"
                  src="/assets/partners-logo/MustShareNews.png"
                />
                <img
                  alt="Zula_Logo"
                  height="100"
                  className="ml-3 mr-3 mb-2 mt-2"
                  src="/assets/partners-logo/Zula.png"
                />
              </div>
            </div>

            <div className="col-12 py-5 mt-5 colouredbgroundedcard">
              <div className="col-12">
                <h1 className="pb-2">Start your journey with NeuHR</h1>
              </div>
              <p style={{ fontSize: "22px" }}>
                Try NeuHR for free, and explore all the tools and services you
                need to start and grow your business.
              </p>
              <p className="pt-4">
                <Button
                  className="btn btn-primary btn-darker mx-1 my-1"
                  onClick={() => (window.location.href = "/contactus")}
                  label="Request Demo"
                />
                <Button
                  className="btn btn-primary btn-login"
                  onClick={() => (window.location.href = "/sme")}
                  label="Try for free"
                />
              </p>
              <b>90 days free trial. No credit card required.</b>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="container">
        <div className="row text-center justify-content-center">
          <div className="col-12">
            <hr></hr>
          </div>
        </div>
      </div> */}

      {/* <section
        className="fdb-block"
        data-block-type="call_to_action"
        data-id="8"
      >
        <div className="container">
          <div className="row align-items-center text-center justify-content-center">
            <div className="col-12 col-md-8 ">
              <h2>
                What's There To Wait?{" "}
                <span role="img" aria-label="rocket zoomzoom">
                  🚀
                </span>
              </h2>
              <p className="lead">
                Sign up now to automate your business and people through
                all-in-one HR platform.
              </p>
              <p>
                <Button
                  className="btn btn-primary"
                  onClick={() => (window.location.href = "/sme")}
                  label="Try it for free"
                />
              </p>
              <b>90 days free trial. No credit card required.</b>
            </div>
           
          </div>

         
        </div>
      </section> */}
    </>
  );
}
export default Home;
