import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { CampaignDocumentService } from "../../service/CampaignService";
import { DocumentTypeService } from "../../service/DocumentTypeService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";

export class CampaignDocumentModel {
  campaignDocID: string = CommonMethods.EmptyGuid;
  campaignID: string = CommonMethods.EmptyGuid;
  documentTypeID: string = CommonMethods.EmptyGuid;
  documentType: string = "";
  uploadDate: string = "";
  uploadPath: string = "";
  uploadFile: File | any = null;
  remarks: string = "";
  campaignDocumentStatus: string = "";
}

let commonMethods = new CommonMethods();
let campaignDocumentService = new CampaignDocumentService();
let documentTypeService = new DocumentTypeService();

function CampaignDocumentForm(props: RouteComponentProps<any>) {
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [btnLabel] = useState("Upload");
  const [action, setAction] = useState("Create");
  const [documentTypeDropdown, setDocumentTypeDropdown] = useState([]);
  const [campaignDocumentData, setCampaignDocumentData] = useState(
    new CampaignDocumentModel()
  );
  let { campaignid, docid } = useParams<{ campaignid: string, docid: string}>();


  useEffect(() => {
    documentTypeService
      .getDocumentTypeDropdown()
      .then((res) => {
        setDocumentTypeDropdown(res);
      })
      .catch((err) => {
        let errorMsg = commonMethods.getErrorMessage(err);
        setErrorMessage(errorMsg);
        setIsError(true);
        setIsLoading(false);
      })
      .finally(() => {});

    if (campaignid !== undefined) {
      // create
      if (props.match.path === "/campaign/document/:campaignid/create") {
        setCampaignDocumentData({
          ...campaignDocumentData,
          campaignID: campaignid,
        });
        setIsLoading(false);
        setRedirectPath("/campaign/documents/" + campaignid);

        // update
      } else if (
        props.match.path === "/campaign/document/:campaignid/update/:docid"
      ) {
        setAction("Update");
        campaignDocumentService
          .getDocumentByID(docid || "")
          .then((res) => {
            let arr = res.uploadPath.split("/");
            res.uploadPath = arr[arr.length - 1];
            setCampaignDocumentData(res);
            setIsLoading(false);
            setRedirectPath(
              "/campaign/documents/" + campaignid
            );
          })
          .catch((err) => {
            let errorMsg = commonMethods.getErrorMessage(err);
            setErrorMessage(errorMsg);
            setIsError(true);
            setIsLoading(false);
          });
      }
    } else {
      setIsError(true);
      setIsLoading(false);
      setErrorMessage(errorMsg);
    }
  }, []);

  const uploadDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e !== null &&
      e.currentTarget.files !== null &&
      e.currentTarget.files[0] !== undefined
    ) {
      if (e.currentTarget.files![0].size > 2000000) {
        SetError(
          "File too big! Has to be below 2MB. Please compress your file before uploading."
        );
      } else {
        setCampaignDocumentData({
          ...campaignDocumentData,
          uploadPath: e.currentTarget.files[0].name,
          uploadFile: e.currentTarget.files[0],
        });
      }
    } else {
      setCampaignDocumentData({
        ...campaignDocumentData,
        uploadPath: "No file chosen.",
        uploadFile: null,
      });
    }
  };

  const handleSubmit = async () => {
    if (action === "Create") {
      campaignDocumentService
        .createCampaignDocument(campaignDocumentData)
        .then((res) => {
          SetSuccess("Document Uploaded");

          setRedirectOut(true);
        })
        .catch((err) => {
          const error = commonMethods.getErrorMessage(err);
          SetError(error);
        });
    } else if (action === "Update") {
      campaignDocumentService
        .updateCampaignDocument(campaignDocumentData)
        .then((res) => {
          SetSuccess("Document Updated");

          setRedirectOut(true);
        })
        .catch((err) => {
          const error = commonMethods.getErrorMessage(err);
          SetError(error);
        });
    } else {
      SetError("Invalid Entry to Form");
    }
  };

  const page = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMsg} />;
    } else {
      return (
        <form onSubmit={handleSubmit}>
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <span className="boldLabel">Document Type:</span>
              <Dropdown
                placeholder="Please Select"
                value={campaignDocumentData.documentTypeID}
                options={documentTypeDropdown}
                onChange={(e) => {
                  let obj: any = documentTypeDropdown.find(
                    (element: any) => element.value === e.value
                  );
                  setCampaignDocumentData({
                    ...campaignDocumentData,
                    documentTypeID: e.value,
                    documentType: obj.label,
                  });
                }}
              />
            </div>

            <div className="p-col-12">
              <p className="boldLabel">Upload File</p>
              <input
                disabled={props.match.path === "/campaign/document/update/:id"}
                style={{ color: "transparent", width: "110px" }}
                type="file"
                name="uploadFile"
                onChange={(e) => {
                  uploadDocument(e);
                }}
              />
              {campaignDocumentData.uploadPath}
            </div>

            <div className="p-col-12">
              <span className="boldLabel">Remarks:</span>
              <InputText
                value={campaignDocumentData.remarks}
                onChange={(e) => {
                  setCampaignDocumentData({
                    ...campaignDocumentData,
                    remarks: e.currentTarget.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="p-col-12">
            <div className="p-grid p-justify-center">
              <Button
                label={btnLabel}
                className="mr-2"
                onClick={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              />
            </div>
          </div>
        </form>
      );
    }
  };

  return (
    <div className="row">
      {redirectOut ? <Redirect push to={redirectPath} /> : null}
      <Dialog
        className="popupDialog"
        closable={false}
        //   header={this.state.confirmstr}
        //   visible={this.state.visible}
        modal={true}
        onHide={() => {}}
        footer={
          <div>
            <Button
              label="Okay"
              type="button"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                //   this.redirectToBalanceAndHistory();
              }}
            />
          </div>
        }
      >
        {/* {this.state.dialogstr} */}
      </Dialog>

      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
        <div className="card">
          <h1 className="pb-2"> Campaign Document </h1>
          <div className="row p-justify-center">
            <div className="col-12">{page()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignDocumentForm;
