import React, {
    ReactElement,
    MouseEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { ContentState, convertFromRaw, convertToRaw, DraftHandleValue, EditorState, getDefaultKeyBinding, Modifier, RichUtils, SelectionState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
    defaultSuggestionsFilter, MentionData, MentionPluginTheme,
} from '@draft-js-plugins/mention';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import editorStyles from './CommentDraftJsEditor.module.css';
import mentionsStyles from './MentionsStyles.module.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import { getContext, toTitleCase } from '../../CompanyFeedFunctions';
import EmojiPicker from '../Comment/Forms/EmojiPicker';
import Tippy from '@tippyjs/react';
const placeholder = require('../../Assets/profileplaceholder.jpg')

export interface EntryComponentProps {
    className?: string;
    onMouseDown(event: MouseEvent): void;
    onMouseUp(event: MouseEvent): void;
    onMouseEnter(event: MouseEvent): void;
    role: string;
    id: string;
    'aria-selected'?: boolean | 'false' | 'true';
    theme?: MentionPluginTheme;
    mention: MentionData;
    isFocused: boolean;
    searchValue?: string;
}

export default function CommentDraftJsEditor({
    rawEditorContentJsonStr,
    commentText,
    setRawEditorContentJsonStr,
    setUsersTaggedArr,
    editorRef,
    setHasText,
    isSubmitted,
    setIsSubmitted,
    inputPlaceholder,
    parentComponent,
    contextName,
    setIsEditorFocused,
    setMentionSuggestionsOpened,
}: {
    rawEditorContentJsonStr?: string | null
    commentText?: string | null
    setRawEditorContentJsonStr: React.Dispatch<React.SetStateAction<string | null>>
    setUsersTaggedArr: React.Dispatch<React.SetStateAction<string[] | null>>
    editorRef: React.RefObject<Editor>
    setHasText: React.Dispatch<React.SetStateAction<boolean>>
    isSubmitted: boolean
    setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>
    inputPlaceholder: string
    parentComponent?: string | undefined
    contextName: string
    setIsEditorFocused: React.Dispatch<React.SetStateAction<boolean>>
    setMentionSuggestionsOpened: React.Dispatch<React.SetStateAction<boolean>>
}): ReactElement {
    const { state } = useContext(getContext(contextName));
    const [editorState, setEditorState] = useState(() => {
        console.log('init editorstate: ', rawEditorContentJsonStr)
        if (rawEditorContentJsonStr) return EditorState.createWithContent(convertFromRaw(JSON.parse(rawEditorContentJsonStr)))
        if (commentText) return EditorState.createWithContent(ContentState.createFromText(commentText))
        return EditorState.createEmpty()
    });
    const [open, setOpen] = useState(false);
    const [mentions, setMentions] = useState<MentionData[]>(state.allMentionSuggestions)
    const [suggestions, setSuggestions] = useState(state.allMentionSuggestions);
    const [renderedEditCommentContent, setRenderedEditCommentContent] = useState(false)

    const [loadEmojiPicker, setLoadEmojiPicker] = useState(false)

    const { plugins, MentionSuggestions } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            theme: mentionsStyles,
            mentionPrefix: '@',
            supportWhitespace: true,
        });
        const linkifyPlugin = createLinkifyPlugin({
            rel: "noopener noreferrer",
            target: "_blank"
        });
        const emojiPlugin = createEmojiPlugin({
            // selectButtonContent: <span className="draftjs-emoji-button-span">☺</span>,
        });
        // const { EmojiSelect } = emojiPlugin;
        const { MentionSuggestions } = mentionPlugin;
        const plugins = [mentionPlugin, linkifyPlugin, emojiPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    useEffect(() => {
        console.log('loadEmojiPicker', loadEmojiPicker)
    }, [loadEmojiPicker])

    useEffect(() => {
        console.log('open', open)
    }, [open])

    const onOpenChange = useCallback((_open: boolean) => {
        setOpen(_open);
        setMentionSuggestionsOpened(_open)
    }, []);
    const onSearchChange = useCallback(({ value }: { value: string }) => {
        setSuggestions(defaultSuggestionsFilter(value, mentions));
    }, []);

    useEffect(() => {
        setMentions(state.allMentionSuggestions)
    }, [state.allMentionSuggestions])

    useEffect(() => {
        setHasText(!!editorState.getCurrentContent().getPlainText().trim())
        setRawEditorContentJsonStr(editorStateToJsonStr(editorState))
        setUsersTaggedArr(getMentions(editorState))
    }, [editorState, setHasText, setRawEditorContentJsonStr, setUsersTaggedArr])

    useEffect(() => {
        if (rawEditorContentJsonStr && !renderedEditCommentContent) {
            let createdEditorState = EditorState.createWithContent(convertFromRaw(JSON.parse(rawEditorContentJsonStr)))
            // createdEditorState = EditorState.moveFocusToEnd(createdEditorState)
            setEditorState(createdEditorState)
            setRenderedEditCommentContent(true)
            setTimeout(() => {
                if (editorRef.current) editorRef.current.focus()
            }, 0);
        }
    }, [rawEditorContentJsonStr])

    // used to reset draftjs after comment submit
    useEffect(() => {
        if (isSubmitted) {
            console.log('run is submitted reset')
            setEditorState(prev => resetContentState(prev))
            setIsSubmitted(false)
        }
    }, [isSubmitted, setIsSubmitted])

    function editorStateToJsonStr(editorState: EditorState) {
        return JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    }

    function getMentions(editorState: EditorState) {
        var rawJson = convertToRaw(editorState.getCurrentContent())
        let mentionedUsers: string[] = []
        for (const key in rawJson.entityMap) {
            if (Object.prototype.hasOwnProperty.call(rawJson.entityMap, key)) {
                const element = rawJson.entityMap[key];
                if (element.type === "mention" && !mentionedUsers.includes(element.data.mention.userId)) {
                    mentionedUsers.push(element.data.mention.userId)
                }
            }
        }
        // to save usersTagged as null into database if empty array
        if (mentionedUsers.length === 0) return null
        return mentionedUsers
    }

    function resetContentState(editorState: EditorState) {
        let contentState = editorState.getCurrentContent();
        const firstBlock = contentState.getFirstBlock();
        const lastBlock = contentState.getLastBlock();
        const allSelected = new SelectionState({
            anchorKey: firstBlock.getKey(),
            anchorOffset: 0,
            focusKey: lastBlock.getKey(),
            focusOffset: lastBlock.getLength(),
            hasFocus: true
        });
        contentState = Modifier.removeRange(contentState, allSelected, 'backward');
        editorState = EditorState.push(editorState, contentState, 'remove-range');
        editorState = EditorState.forceSelection(editorState, contentState.getSelectionAfter());
        return editorState
    }

    function keyBindingFn(e: React.KeyboardEvent<Element>): string | null {
        // if (e.shiftKey && e.key === "Enter") {return 'split-block';} // does not focus at new block
        if (e.shiftKey && e.key === "Enter") { return 'shift-enter'; } // does not focus at new block
        // avoid conflict with "Enter" key to submit comment, activates when @MentionSuggestions are opened 
        if (e.key === "Enter") { return 'do-nothing'; }
        return getDefaultKeyBinding(e);
    }

    function handleKeyCommand(command: string): DraftHandleValue {
        // if (command === 'custom-command') {
        //     // Perform a request to save your contents, set a new `editorState`, etc.
        //     console.log('custom-command executed')
        //     return 'handled';
        // }
        if (command === 'shift-enter') {
            console.log('shift-enter')
            const newEditorState = RichUtils.insertSoftNewline(editorState);
            setEditorState(newEditorState)
        }
        if (command === 'do-nothing') console.log('do nothing')
        return 'not-handled';
    }

    // function logEditorState(editorState: EditorState) {
    //     console.log('raw json of ContentState', convertToRaw(editorState.getCurrentContent()))
    //     console.log('EditorState Json:', editorState.toJS())
    //     // console.log('decorator', editorState.getDecorator())
    //     console.log('plugins', plugins)
    // }

    // function logState() {
    //     console.log('state', state)
    // }

    function clickEmojiPicker(e: any) {
        setLoadEmojiPicker(prev => !prev)
    }

    return (
        <div className="position-relative">
            <div
                className={editorStyles.editor}
                onClick={() => {
                    editorRef.current!.focus();
                    setIsEditorFocused(true)
                    console.log('focus draftjs editor')
                }}
            >
                <Editor
                    // editorKey={'editor'}
                    editorState={editorState}
                    onChange={setEditorState}
                    plugins={plugins}
                    ref={editorRef}
                    placeholder={inputPlaceholder}
                    onFocus={() => setIsEditorFocused(true)}
                    onBlur={() => setIsEditorFocused(false)}
                    keyBindingFn={open ? undefined : keyBindingFn}
                    handleKeyCommand={handleKeyCommand}
                />
                {/* <button type="button" onClick={() => logState()}>Log State</button>
            <button type="button" onClick={() => logEditorState(editorState)}>Log Editor State</button> */}
                <MentionSuggestions
                    open={open}
                    onOpenChange={onOpenChange}
                    suggestions={suggestions}
                    onSearchChange={onSearchChange}
                    onAddMention={(mention) => {
                        setTimeout(() => {
                            if (editorRef.current) editorRef.current.focus()
                        }, 0);
                    }}
                    entryComponent={Entry}
                // popoverContainer={({ children }) => <div>{children}</div>}
                />
            </div>
            <div>
                <Tippy
                    content={
                        <EmojiPicker
                            editorState={editorState}
                            setEditorState={setEditorState}
                            loadEmojiPicker={loadEmojiPicker}
                            setLoadEmojiPicker={setLoadEmojiPicker}
                            setIsEditorFocused={setIsEditorFocused}
                        />
                    }
                    interactive={true}
                    appendTo={document.body}
                    placement="auto"
                    offset={[2, 2]}
                    zIndex={9}
                    visible={loadEmojiPicker}
                    onClickOutside={() => setLoadEmojiPicker(false)}
                >
                    <span
                        className="material-icons-outlined emoji-picker-button"
                        onClick={e => clickEmojiPicker(e)}
                    >sentiment_satisfied_alt</span>
                </Tippy>
            </div>
        </div>
    );
}

function Entry(props: EntryComponentProps): ReactElement {
    const {
        mention,
        theme,
        searchValue,
        isFocused,
        ...parentProps
    } = props;

    return (
        <div {...parentProps}>
            <div className={theme?.mentionSuggestionsEntryContainer}>
                <div className={theme?.mentionSuggestionsEntryContainerLeft}>
                    <img
                        src={mention.avatar}
                        className={theme?.mentionSuggestionsEntryAvatar}
                        role="presentation"
                        alt=""
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => e.currentTarget.src = placeholder}
                    />
                </div>

                <div className={theme?.mentionSuggestionsEntryContainerRight}>
                    <div className={theme?.mentionSuggestionsEntryText}>
                        {toTitleCase(mention.name)}
                    </div>

                    <div className={theme?.mentionSuggestionsEntryTitle}>
                        {toTitleCase(mention.jobTitle)} · {toTitleCase(mention.companyName)}
                    </div>
                </div>
            </div>
        </div>
    );
}