import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useState } from "react";
import { UserService } from "../../service/UserService";
import { CommonMethods } from "../resources/CommonMethods";


const Register = (props: { loginCallback: any }) => {
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userEmailError, setUserEmailError] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [pwErrorMsg, setPwErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    " Unable to register. Please contact us for more information."
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userService = new UserService();
  const commonMethods = new CommonMethods();

  const validation = () => {
    if (!commonMethods.validateEmail(userEmail)) {
      setUserEmailError("Invalid email address");
      return false;
    } else if (!commonMethods.pwValidation(userPassword)) {
      setPwErrorMsg(true);
      setUserConfirmPassword("");
      return false;
    }
    return true;
  };
  const submitForm = (event: React.FormEvent) => {
    event.preventDefault();
    setUserEmailError("");
    setIsLoading(true);
    setPwErrorMsg(false);

    if (!validation()) {
      setIsLoading(false);
    } else {
      let user = {
        firstName: userFirstName,
        lastName: userLastName,
        email: userEmail,
        passwordHash: userPassword,
      };
      userService
        .signup(user)
        .then((res) => {
          return res;
        })
        .then((res) => {
          props.loginCallback("success");
          // let login = new LoginService();
          // login
          //   .signinaftersignup(res)
          //   .then(() => {
          //     // window.location.replace("/");
          //   })
          //   .catch((err) => {
          //     setIsLoading(false);
          //     let errorMsg = commonMethods.getErrorMessage(err);
          //     setIsError(true);
          //     setErrorMsg(errorMsg);
          //   });
        })
        .catch((err) => {
          let errorMsg = commonMethods.getErrorMessage(err);
          setIsLoading(false);
          if (commonMethods.isWarning(err)) {
            setUserEmailError(errorMsg);
          } else {
            setIsError(true);
            setErrorMsg(errorMsg);
          }
        });
    }
  };

  const errMsg = (
    <div className="p-col-12 text-danger">
      Password must at least <br></br>
      <ul>
        <li> be 8 characters long </li>
        <li> contain one number </li>
        <li> include both lower and upper case characters </li>
      </ul>
    </div>
  );

  const emailErrMsg = (
    <div className="p-col-12 text-danger">{userEmailError}</div>
  );

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <div className="text-danger">{errorMsg}</div>;
    } else {
      return (
        <form onSubmit={submitForm} className="col-12">
          <div className="p-grid p-fluid p-justify-center pt-3">
            <div className="p-col-12">
              <span className="boldLabel">First Name</span>
              <InputText
                value={userFirstName}
                onChange={(e) => setUserFirstName(e.currentTarget.value)}
                required
              />
            </div>
            <div className="p-col-12">
              <span className="boldLabel">Last Name</span>
              <InputText
                value={userLastName}
                onChange={(e) => setUserLastName(e.currentTarget.value)}
                required
              />
            </div>
            <div className="p-col-12">
              <span className="boldLabel">Email</span>
              <InputText
                value={userEmail}
                onChange={(e) => setUserEmail(e.currentTarget.value)}
                required
              />
            </div>
            {userEmailError ? emailErrMsg : ""}
            <div className="p-col-12">
              <span className="boldLabel">Password </span>
              <InputText
                type="password"
                value={userPassword}
                onChange={(e) => setUserPassword(e.currentTarget.value)}
                required
              />
            </div>
            {pwErrorMsg ? errMsg : ""}
            <div className="p-col-12">
              <span className="boldLabel">Confirm Password</span>
              <InputText
                type="password"
                value={userConfirmPassword}
                onChange={(e) => setUserConfirmPassword(e.currentTarget.value)}
                required
              />
            </div>
            <div className="p-col-12 text-center">
              <span>
                <i>
                  By clicking Create Account, you agree to our Terms &amp;
                  Privacy Policy.
                </i>
              </span>
            </div>
          </div>
          <div className="p-grid p-justify-center pt-3">
            <Button type="submit" label="Create Account" />
          </div>
        </form>
      );
    }
  };

  return (
    <section
      className="fdb-block "
      data-block-type="call_to_action"
      data-id="4"
    >
      <div className="container">
        <div className="row align-items-center justify-content-center pt-2 pt-lg-5">
          <div className="col-12 col-md-8 text-center">
            <h1>Get started with NeuHR</h1>
            <p className="h3">Try for FREE. Start your 90 days trial today!</p>
          </div>
          <div className="pt-4 col-md-8"> {display()}</div>
        </div>
      </div>{" "}
    </section>
  );
};

export default Register;
