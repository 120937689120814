import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { ProgressSpinner } from "primereact/progressspinner";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import { UserService } from "../../service/UserService";
import {
  CommonMethods,
  RouteComponentProps,
  DropdownModel,
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import {
  ReportLeaveObjectModel,
  ReportsModel,
  ReportType,
  ProfileReportModel,
} from "./Reports";
import ReactTooltip from "react-tooltip";
import { InputSwitch } from "primereact/inputswitch";

function ProfileReport(props: RouteComponentProps<any>) {
  const { SetError } = React.useContext(ToastStateContext);

  const commonMethods = new CommonMethods();
  const userService = new UserService();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [companyDropdown, setCompanyDropdown] = useState<DropdownModel[]>([]);

  const [userDropdown, setUserDropdown] = useState<DropdownModel[]>([]);
  const [inactiveUserDropdown, setInactiveUserDropdown] = useState<
    DropdownModel[]
  >([]);
  const [switchButton, setSwitchButton] = useState(false);

  const [reportObj, setReportObj] = useState<ProfileReportModel>(
    new ProfileReportModel()
  );
  const [profileReportData, setProfileReportData] = useState<any[]>([]);

  let Profiletest: DataTable | null = null;

  const [reportType, setReportType] = useState<ReportType>({
    groupCompany: false,
    company: false,
  });

  const [selectedProfileHeader, setSelectedProfileHeader] = useState<any[]>([]);

  const newProfileHeaders = useState<any[]>([
    { label: "Full Name", key: "fullName" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" },
    { label: "Role", key: "roleName" },
    { label: "Account Status", key: "accStatus" },
    { label: "Supervisors", key: "approverList" },
    { label: "Salutation", key: "salutation" },
    { label: "Date Of Birth", key: "dateOfBirth" },
    { label: "Nationality", key: "nationality" },
    { label: "Marital Status", key: "maritalStatus" },
    { label: "Gender", key: "gender" },
    { label: "Race", key: "race" },
    { label: "Religion", key: "religion" },
    { label: "PR Obtained Date", key: "prObtainedDate" },
    { label: "Personal Email", key: "pP_PersonalEmail" },
    { label: "Address", key: "pP_ResidentialAddress" },
    { label: "Mobile Number", key: "pP_MobileNumber" },
    { label: "Home Number", key: "pP_HomeNumber" },
    { label: "Legal Status", key: "legalStatus" },
    { label: "Work Pass", key: "wP_WorkPass" },
    { label: "Dependant Pass", key: "wP_DependantPass" },
    { label: "Work Permit Expiry", key: "wP_WorkPermitExpiry" },
    { label: "Emergency Contact's Name", key: "emergency_Name" },
    {
      label: "Emergency Contact's Relationship",
      key: "emergency_Relationship",
    },
    { label: "Emergency Contact's Number", key: "emergency_Contact" },
    { label: "Bank Name", key: "bank_Name" },
    { label: "Account Number", key: "bank_Number" },
    { label: "Account Type", key: "bank_AccountType" },
    { label: "Account Name", key: "bank_PayeeName" },
    { label: "SHG Eligibility", key: "shgEligible" },
    { label: "SHG Group", key: "shgGroup" },
    { label: "SDL Eligibility", key: "sdlEligible" },
	{ label : "Public Profile Permission", key: "publicProfilePermission"},

    { label: "Company", key: "companyName" },
    { label: "Department", key: "departmentName" },
    { label: "Job Title", key: "jobTitle" },
    { label: "User's Rank", key: "userRankName" },
    { label: "Identification Number", key: "identificationNumber" },
    { label: "Start Date", key: "jobStartDate" },
    { label: "Contract Duration", key: "contractDuration" },
    { label: "Work Location", key: "workLocation" },
    { label: "Work Hours", key: "workHours" },
    { label: "Working Days", key: "workingDays" },
    { label: "No. of work days per week", key: "weeklyWorkDays" },
    { label: "Salary Period", key: "salaryPeriod" },
    { label: "Pay Day", key: "payDay" },

    { label: "Basic Salary", key: "basicSalaryDec" },
    { label: "Allowance", key: "allowanceDec" },
    { label: "Job Grade", key: "jobGrade" },

    { label: "Annual Leave Entitlement", key: "yearlyAnnualLeave" },
    // { label: "Leaves", key: "OtherLeaves" },
    { label: "Probation Length", key: "probationLength" },
    { label: "Notice Period", key: "noticePeriod" },
    { label: "Contract Created Date ", key: "createdTime" },
    { label: "Currency", key: "currency" },
    { label: "CPF Eligibility", key: "cpfEligible" },
    { label: "Contract Effective Date", key: "contractEffectiveDate" },
  ]);

  const [userFilter] = useState<DropdownModel[]>([
    { label: "First Name", value: "FirstName" },
    { label: "Last Name", value: "LastName" },
    { label: "Full Name", value: "FullName" },
    { label: "Email", value: "Email" },
    { label: "Role", value: "RoleName" },
    { label: "Account Status", value: "AccStatus" },
    { label: "Supervisors", value: "Approvers" },
    { label: "Salutation", value: "Salutation" },
    { label: "Date Of Birth", value: "DateOfBirth" },
    { label: "Nationality", value: "Nationality" },
    { label: "Marital Status", value: "MaritalStatus" },
    { label: "Gender", value: "Gender" },
    { label: "Race", value: "Race" },
    { label: "Religion", value: "Religion" },
    { label: "PR Obtained Date", value: "PRObtainedDate" },
    { label: "Personal Email", value: "PP_PersonalEmail" },
    { label: "Residential Address", value: "PP_ResidentialAddress" },
    { label: "Mobile Number", value: "PP_MobileNumber" },
    { label: "Home Number", value: "PP_HomeNumber" },
    { label: "Legal Status", value: "LegalStatus" },
    { label: "Work Pass", value: "WP_WorkPass" },
    { label: "Dependant Pass", value: "WP_DependantPass" },
    { label: "Work Permit Expiry", value: "WP_WorkPermitExpiry" },
    { label: "Emergency Contact's Name", value: "Emergency_Name" },
    {
      label: "Emergency Contact's Relationship",
      value: "Emergency_Relationship",
    },
    { label: "Emergency Contact's Number", value: "Emergency_Contact" },
    { label: "Bank Name", value: "Bank_Name" },
    // { label: "Bank Code", value: "Bank_Code" },
    { label: "Bank Number", value: "Bank_Number" },
    { label: "Bank Account Type", value: "Bank_AccountType" },
    { label: "Bank Payee's Name", value: "Bank_PayeeName" },
    { label: "SHG Eligibility", value: "SHGEligible" },
    { label: "SHG Group", value: "SHGGroup" },
    { label: "SDL Eligibility", value: "SDLEligible" },
	{ label: "Public Profile Permission", value: "PublicProfilePermission"}
  ]);

  const [contractFilter] = useState<DropdownModel[]>([
    { label: "Company", value: "CompanyName" },
    { label: "Department", value: "DepartmentName" },
    { label: "Job Title", value: "JobTitle" },
    { label: "User's Rank", value: "UserRankName" },
    { label: "Identification Number", value: "IdentificationNumber" },
    { label: "Start Date", value: "JobStartDate" },
    { label: "Contract Duration", value: "ContractDuration" },
    { label: "Work Location", value: "WorkLocation" },
    { label: "Work Hours", value: "WorkHours" },
    { label: "Working Days", value: "WorkingDays" },
    { label: "No. of work days per week", value: "WeeklyWorkDays" },
    { label: "Salary Period", value: "SalaryPeriod" },
    { label: "Pay Day", value: "PayDay" },

    { label: "Basic Salary", value: "BasicSalary" },
    { label: "Allowance", value: "Allowance" },
    { label: "Job Grade", value: "JobGrade" },

    { label: "Annual Leave Entitlement", value: "YearlyAnnualLeave" },
    { label: "Leaves", value: "OtherLeaves" },
    { label: "Probation Length", value: "ProbationLength" },
    { label: "Notice Period", value: "NoticePeriod" },
    { label: "Contract Created Date ", value: "CreatedTime" },
    { label: "Currency", value: "Currency" },
    { label: "CPF Eligibility", value: "CPFEligible" },
    { label: "Contract Effective Date", value: "ContractEffectiveDate" },
  ]);

  useEffect(() => {
    getCompanyDropdownOptions();
  }, []);

  useEffect(() => {
    if (Profiletest !== null) {
      Profiletest.exportCSV();
    }
  }, [profileReportData]);

  useEffect(() => {
    if (reportObj.companyID !== CommonMethods.EmptyGuid) {
      setReportObj({
        ...reportObj,
        employeeListStrArr: [],
        inactiveEmployeeListStrArr: [],
      });
      setSwitchButton(false);
      commonMethods
        .getUserDropdownByCompany(reportObj.companyID)
        .then((res) => {
          setUserDropdown(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
      commonMethods
        .getInactiveUsersDropdownByCompany(reportObj.companyID)
        .then((res) => {
          setInactiveUserDropdown(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
    }
  }, [reportObj.companyID]);

  useEffect(() => {
    setUserDropdown([]);
    setInactiveUserDropdown([]);
    setReportObj({
      ...reportObj,
      employeeListStrArr: [],
      inactiveEmployeeListStrArr: [],
    });
    setSwitchButton(false);
    if (reportType.groupCompany) {
      setReportObj({
        ...reportObj,
        companyID: CommonMethods.EmptyGuid,
        employeeListStrArr: [],
        inactiveEmployeeListStrArr: [],
      });
      commonMethods
        .getUserDropdownForGroupCompany()
        .then((res) => {
          setUserDropdown(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
      commonMethods
        .getInactiveUserDropdownByGroupCompany()
        .then((res) => {
          setInactiveUserDropdown(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
    }
  }, [reportType]);

  const getCompanyDropdownOptions = () => {
    commonMethods
      .getCompanyDropdownListByGcid()
      .then((res) => {
        setCompanyDropdown(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
        setErrorMsg(commonMethods.getErrorMessage(err));
      });
  };

  const configureProfileHeaders = () => {
    let tempArray: any[] = [];
    reportObj.selectedUserFilter.forEach((filter) => {
      var modified;
      switch (filter) {
        case "Approvers":
          modified = "approverList";
          break;
        case "PRObtainedDate":
          modified = "prObtainedDate";
          break;
        case "SHGEligible":
          modified = "shgEligible";
          break;
        case "SHGGroup":
          modified = "shgGroup";
          break;
        case "SDLEligible":
          modified = "sdlEligible";
          break;
        case "BasicSalary":
          modified = "basicSalaryDec";
          break;
        case "Allowance":
          modified = "allowanceDec";
          break;
        default:
          modified = filter[0].toLowerCase() + filter.substring(1);
      }

      for (var number in newProfileHeaders[0]) {
        let obj = newProfileHeaders[0][number];
        if (modified == obj.key) {
          tempArray.push(obj);
        }
      }
    });

    reportObj.selectedContractFilter.forEach((filter) => {
      var modified;
      switch (filter) {
        case "CPFEligible":
          modified = "cpfEligible";
          break;
        case "BasicSalary":
          modified = "basicSalaryDec";
          break;
        case "Allowance":
          modified = "allowanceDec";
          break;
        default:
          modified = filter[0].toLowerCase() + filter.substring(1);
      }
      for (var number in newProfileHeaders[0]) {
        let obj = newProfileHeaders[0][number];
        if (modified == obj.key) {
          tempArray.push(obj);
          break;
        }
      }
    });
    setSelectedProfileHeader(tempArray);
  };

  const generateProfileReport = () => {
    if (reportObj.employeeListStrArr.length === 0 && reportObj.inactiveEmployeeListStrArr.length === 0){
      SetError("Please select employees");
      return;
    }
    if (reportObj.selectedUserFilter.length === 0 && reportObj.selectedContractFilter.length === 0 ){
      SetError("Please select filters");
      return;
    }
    configureProfileHeaders();

    userService
      .getProfileReportDetails(reportObj)
      .then((res) => {
		console.log(res);
        res.forEach((user: any) => {
          if (user.dateOfBirth) {
            user.dateOfBirth = user.dateOfBirth.split("T")[0];
          }
          if (user.prObtainedDate) {
            user.prObtainedDate = user.prObtainedDate.split("T")[0];
          }
          if (user.wP_WorkPermitExpiry) {
            user.wP_WorkPermitExpiry = user.wP_WorkPermitExpiry.split("T")[0];
          }
          if (user.jobStartDate) {
            user.jobStartDate = user.jobStartDate.split("T")[0];
          }
          if (user.createdTime) {
            user.createdTime = user.createdTime.split("T")[0];
          }
          if (user.contractEffectiveDate) {
            user.contractEffectiveDate =
              user.contractEffectiveDate.split("T")[0];
          }
        });
        setProfileReportData(res);
      })
      .catch(() => {
        SetError("Unable to export Employees' Details Report");
      });
  };

  const companyDropdownDisplay = () => {
    if (reportType.company) {
      return (
        <div className="p-col-12">
          <span className="boldLabel">Company</span>
          <Dropdown
            value={reportObj.companyID}
            options={companyDropdown}
            onChange={(e) => {
              setReportObj({
                ...reportObj,
                companyID: e.value,
              });
            }}
            placeholder="Please Select"
            required
          />
        </div>
      );
    }
  };

  const dataTableRender = () => {
    return (
      <div className="p-col-12 pt-4">
        <div className="p-grid p-justify-center">
          <DataTable
            style={{ display: "none" }}
            value={profileReportData}
            ref={(el) => {
              Profiletest = el;
            }}
            exportFilename={
              "Employee_Details_Report_" +
              commonMethods.displayDate_FileName(new Date()) +
              ".csv"
            }
          >
            {selectedProfileHeader.map((col: any, i) => {
              return (
                <Column key={col.key} field={col.key} header={col.label} />
              );
            })}
          </DataTable>
          <Button
            label="Download Report"
            className="mt-2"
            onClick={(event) => {
              event.preventDefault();
              generateProfileReport();
            }}
          />
        </div>
      </div>
    );
  };

  const displayProfileReportPart = () => {
    return (
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="card">
            <h5 className="pb-4">
              Employee Details Report
              <span data-for="employeedetails" data-tip="">
                {" "}
                <i className="fas fa-info-circle informationStyle"></i>
              </span>
            </h5>
            <ReactTooltip
              id="employeedetails"
              aria-haspopup="true"
              className="tooltipFont"
            >
              <p className="text-center mb-0">
                Retrieve employee's personal details and active contract details (Latest contract for deactivated employees)
              </p>
            </ReactTooltip>
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <span className="boldLabel">
                  Type <span style={{ color: "red" }}>*</span>
                </span>
                <div className="p-grid p-fluid pt-1">
                  <div className="p-col-12 p-field-radiobutton">
                    <RadioButton
                      inputId="GroupCompanyProfile"
                      value={true}
                      name="GroupCompany"
                      onChange={(e) => {
                        setReportType({
                          ...reportType,
                          groupCompany: true,
                          company: false,
                        });
                      }}
                      checked={reportType.groupCompany === true}
                    />
                    <label htmlFor="GroupCompanyProfile" className="ml-1">
                      Group Company
                    </label>

                    <RadioButton
                      inputId="CompanyProfile"
                      value={true}
                      className="ml-3"
                      name="Company"
                      onChange={(e) => {
                        setReportType({
                          ...reportType,
                          groupCompany: false,
                          company: true,
                        });
                      }}
                      checked={reportType.company === true}
                    />
                    <label htmlFor="CompanyProfile" className="ml-1">
                      Company
                    </label>
                  </div>
                </div>
              </div>
              {companyDropdownDisplay()}
              <div className="p-col-12">
                <span className="boldLabel">Employees</span>
                <MultiSelect
                  value={reportObj.employeeListStrArr}
                  options={userDropdown}
                  onChange={(e) => {
                    setReportObj({
                      ...reportObj,
                      employeeListStrArr: e.value,
                    });
                  }}
                  filter={true}
                  placeholder="Please Select"
                  disabled={userDropdown.length === 0}
                />
              </div>
              <div className="p-col-12">
                <span className="boldLabel">
                  Include deactivated employees?
                </span>
                <div className="field-checkbox">
                  <InputSwitch
                    checked={switchButton}
                    onChange={(e) => {
                      setSwitchButton(e.value);
                      setReportObj({
                        ...reportObj,
                        inactiveEmployeeListStrArr: new Array<string>(),
                      });
                    }}
                    disabled={userDropdown.length === 0}
                  />
                </div>
              </div>
              {switchButton && (
                <div className="p-col-12">
                  <span className="boldLabel">Deactivated Employees</span>
                  <MultiSelect
                    value={reportObj.inactiveEmployeeListStrArr}
                    options={inactiveUserDropdown}
                    onChange={(e) => {
                      setReportObj({
                        ...reportObj,
                        inactiveEmployeeListStrArr: e.value,
                      });
                    }}
                    filter={true}
                    placeholder="Please Select"
                    disabled={inactiveUserDropdown.length === 0}
                  />
                </div>
              )}
              <div className="p-col-6">
                <span className="boldLabel">User Information</span>
                <MultiSelect
                  value={reportObj.selectedUserFilter}
                  options={userFilter}
                  onChange={(e: any) => {
                    setReportObj({
                      ...reportObj,
                      selectedUserFilter: e.value,
                    });
                  }}
                  optionLabel="label"
                  placeholder="Select filters"
                  disabled={userDropdown.length === 0}
                />
              </div>
              <div className="p-col-6">
                <span className="boldLabel">Contract Information</span>
                <MultiSelect
                  value={reportObj.selectedContractFilter}
                  options={contractFilter}
                  onChange={(e: any) => {
                    setReportObj({
                      ...reportObj,
                      selectedContractFilter: e.value,
                    });
                  }}
                  optionLabel="label"
                  placeholder="Select filters"
                  disabled={userDropdown.length === 0}
                />
              </div>
            </div>
            {dataTableRender()}
          </div>
        </div>
      </div>
    );
  };

  let page = <>{displayProfileReportPart()}</>;

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return <>{display}</>;
}

export default ProfileReport;
