import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import classNames from "classnames";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova/theme.css";
import * as React from "react";
import { Route } from "react-router";
import "./App.scss";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppProfile } from "./AppProfile";
import { AppTopbar } from "./AppTopbar";
import Priceplans from "./components/landingpages/Priceplans";
import Announcement from "./components/notifications/Announcement";
import { AccessModel, CommonMethods } from "./components/resources/CommonMethods";
import  CustomError  from "./components/resources/Error";
import ToastStateProvider, { ToastStateContext } from "./components/resources/ToastContext";
import { MenuList } from "./components/resources/MenuList";
import RouteProvider from "./components/resources/RouteProvider";
import "./layout/layout.scss";
import { build, fullver } from "./others/properties.json";
import { LoginService } from "./service/LoginService";

interface AppProps {
  isLogin: boolean;
}

interface AppState {
  layoutMode: string;
  layoutColorMode: string;
  staticMenuInactive: boolean;
  overlayMenuActive: boolean;
  mobileMenuActive: boolean;
  isError: boolean;
  isLoading: boolean;
  errorMsg: string;
  stateMenu: any;
  displayOnboarding: boolean;
  titleAndBreadcrumbs: JSX.Element;
  displayExpired: boolean;
}

export default class AppMain extends React.Component<AppProps, AppState> {
  loginService: LoginService;
  menuClick: any;
  sidebar: any;
  commonMethods: CommonMethods;
  menuList: any;
  static contextType = ToastStateContext;
  constructor(props: AppProps) {
    super(props);
    this.state = {
      layoutMode: "static",
      layoutColorMode: "light",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      stateMenu: [],
      displayOnboarding: false,
      displayExpired: false,
      isError: false,
      isLoading: true,
      errorMsg: "",
      titleAndBreadcrumbs: <></>,
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.loginService = new LoginService();
    this.commonMethods = new CommonMethods();
    this.menuList = new MenuList();
    this.addMenuAccordingToRole = this.addMenuAccordingToRole.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.addMenuAccordingToRole();
    this.onboardingExpiredDeactivatedCheck();
  }

  componentDidUpdate() {}

  onboardingExpiredDeactivatedCheck() {
    this.commonMethods
      .onboardingExpiredDeactivatedCheck()
      .then((res: AccessModel) => {
        if (!res.onboardingComplete) {
          this.setState({ displayOnboarding: true });
        }
        if (res.planExpired) {
          this.setState({ displayExpired: true });
        }
        if (!res.accActive) {
          window.alert(
            "Your account has already been deactivated. Logging you out.."
          );
          // LOGOUT
          this.loginService.logout().then(() => {
            window.location.replace("/");
          });
        }
      })
      .catch((err) => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({
          isError: true,
          errorMsg: errorMsg,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  //    __  __                   __  __       _    _             _
  //   |  \/  | ___  _ _  _  _  |  \/  | ___ | |_ | |_   ___  __| | ___
  //   | |\/| |/ -_)| ' \| || | | |\/| |/ -_)|  _|| ' \ / _ \/ _` |(_-<
  //   |_|  |_|\___||_||_|\_,_| |_|  |_|\___| \__||_||_|\___/\__,_|/__/
  //

  onWrapperClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    // This causes it to render everywhere
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
    this.menuClick = false;
  }

  onToggleMenu(event: React.MouseEvent<HTMLInputElement>) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === "overlay") {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === "static") {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  }

  onSidebarClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.menuClick = true;
  }

  onMenuItemClick(event: { originalEvent: Event; item: MenuItem }) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  addMenuAccordingToRole() {
    this.commonMethods
      .getPermittedMenuModules()
      .then((res) => {
        let permittedAccessModules: Array<string> = res;
        // 1. Push default menus first
        let finalMenu = this.state.stateMenu;
        this.menuList.DefaultList.items.forEach((defaultModuleName: string) => {
          if (permittedAccessModules.includes(defaultModuleName)) {
            let moduleToAdd = this.menuList.Menu[defaultModuleName];
            finalMenu.push(moduleToAdd);
          }
        });

        // 2. Push role defined menus
        this.menuList.SubMenuList.forEach((subMenuListItem: string) => {
          this.menuList[subMenuListItem].items.forEach((moduleName: string) => {
            if (permittedAccessModules.includes(moduleName)) {
              //2.1 Get the parent group
              let parentMenu = this.menuList.Menu[
                this.menuList[subMenuListItem].group
              ];
              var index = finalMenu.findIndex(
                (menuItems: { label: any }) =>
                  menuItems.label === parentMenu.label
              );

              // 2.2 Add the child into parent group if it exists
              if (index < 0) {
                parentMenu.items.push(this.menuList.Menu[moduleName]);
                finalMenu.push(parentMenu);
              } else {
                parentMenu = finalMenu[index];
                parentMenu.items.push(this.menuList.Menu[moduleName]);
                finalMenu[index] = parentMenu;
              }
            }
          });
        });

        // 4. sort according to menu rank for display
        finalMenu.sort((a: any, b: any) => (a.rank > b.rank ? 1 : -1));
        this.setState({ stateMenu: finalMenu });
      })
      .catch((err) => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({
          isError: true,
          errorMsg: errorMsg,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  render() {
    // const logo = "/assets/neuhr.png";

    const wrapperClass = classNames("layout-wrapper", {
      "layout-overlay": this.state.layoutMode === "overlay",
      "layout-static": this.state.layoutMode === "static",
      "layout-static-sidebar-inactive":
        this.state.staticMenuInactive && this.state.layoutMode === "static",
      "layout-overlay-sidebar-active":
        this.state.overlayMenuActive && this.state.layoutMode === "overlay",
      "layout-mobile-sidebar-active": this.state.mobileMenuActive,
    });

    const sidebarClassName = classNames("layout-sidebar", {
      "layout-sidebar-dark": this.state.layoutColorMode === "dark",
      "layout-sidebar-light": this.state.layoutColorMode === "light",
    });

    let display = (
      <div className="layout-main">
        <Announcement></Announcement>
        {/* <Route
          path="/error"
          exact
          render={(props) => <CustomError message="Please Refresh" />}
        /> */}
        <RouteProvider />
      </div>
    );

    if (this.state.isError) {
      display = (
        <div className="layout-main">
          <CustomError message="Please refresh"></CustomError>
        </div>
      );
    }

    if (this.state.displayExpired) {
      display = (
        <div className="layout-main">
          <Announcement></Announcement>
          <Route path="/error" exact component={CustomError} />
          <Priceplans
            header="Your existing free trial has expired"
            description="Come onboard with us!"
            hidebasic={true}
          />
        </div>
      );
    }

    // if (this.state.displayOnboarding) {
    //   display = (
    //     <div className="layout-main">
    //       <Announcement></Announcement>
    //       <Route path="/error" exact component={CustomError} />
    //       <ProfileProvider />
    //     </div>
    //   );
    // }
    // if (this.state.isError) {
    //   return (
    //     <div
    //       id="wrapper"
    //       className={wrapperClass}
    //       onClick={this.onWrapperClick}
    //     >
    //       <AppTopbar onToggleMenu={this.onToggleMenu} />
    //       <div
    //         ref={(el) => (this.sidebar = el)}
    //         className={sidebarClassName}
    //         onClick={this.onSidebarClick}
    //       >
    //         <div className="layout-logo">
    //           <img alt="Logo" src={logo} height="60" />
    //         </div>
    //         <AppMenu
    //           model={this.state.stateMenu}
    //           onMenuItemClick={this.onMenuItemClick}
    //         />
    //       </div>
    //       <CustomError message="Please refresh"></CustomError>

    //       <AppFooter />
    //     </div>
    //   );
    // }

    // Normal login page
    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        <ToastStateProvider>
          <AppTopbar onToggleMenu={this.onToggleMenu} />
          <div
            ref={(el) => (this.sidebar = el)}
            className={sidebarClassName}
            onClick={this.onSidebarClick}
          >
            <AppProfile />

            {!this.state.displayOnboarding && (
              <>
                <AppMenu
                  model={this.state.stateMenu}
                  onMenuItemClick={this.onMenuItemClick}
                />{" "}
                <div className="text-center" style={{ fontSize: "10px" }}>
                  v {fullver}.{build}
                </div>
              </>
            )}
          </div>

          {display}
        </ToastStateProvider>
        <AppFooter />

        <div className="layout-mask" />
      </div>
    );
  }
}
