import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import ForgetPassword from "../ForgetPassword";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Features from "./Features";
import Footer from "./Footer";
import Header from "./Header";
import Home from "./Home";
import Priceplans from "./Priceplans";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";

function Homepage(props: { login: React.ReactNode; register: JSX.Element }) {
  const [loginModal, setLoginModal] = useState(false);

  return (
    <>
      <div className="homepage">
        <Header setLoginModal={setLoginModal} />
        <Dialog
          header="Login"
          visible={loginModal}
          modal={true}
          // dismissableMask={true}
          onHide={() => setLoginModal(false)}
        >
          {props.login}
        </Dialog>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/features" exact component={Features} />
          <Route path="/about-us" exact component={AboutUs} />
          {/* <Route path="/sme" exact component={Register} /> */}
          <Route path="/sme" exact render={() => props.register} />

          <Route path="/contactus" exact component={ContactUs} />
          <Route
            path="/priceplans"
            exact
            render={(props) => (
              <Priceplans
                header="Available Plans"
                description="No signup cost. No hidden fees."
                hidebasic={false}
              />
            )}
          />

          <Route path="/forgetpassword" exact component={ForgetPassword} />
          <Route path="/terms" exact component={TermsOfService} />
          <Route path="/privacypolicy" exact component={PrivacyPolicy} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/gh/froala/design-blocks@master/dist/css/froala_blocks.min.css"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/froala-editor@2.9.1/css/froala_style.min.css"
        />
        <Footer />
      </div>
    </>
  );
}

export default Homepage;
