import { HttpClient } from "./HttpClient";
import { QuicklinksDashboardModel } from "../components/quicklinks/QuicklinksDashboardModel";

export class QuicklinksDashboardService {
    httpClient:HttpClient;

    constructor(){
        this.httpClient = new HttpClient();
    }

    async getAllLinks() {
        const res = await this.httpClient.get("/api/quicklinksdashboard");
        return res;
    }
    async getLinkByID(linkID: string){
        const res = await this.httpClient.get("/api/quicklinksdashboard/" + linkID);
        return res;
    }
    async createLink(linkModel: QuicklinksDashboardModel){
        const res = this.httpClient.post("/api/quicklinksdashboard/create", linkModel);
        return res;
    }
    async updateLinkByID(linkModel: QuicklinksDashboardModel){
        const res = this.httpClient.put("/api/quicklinksdashboard/update", linkModel);
        return res;
    }
    async deleteLinkByID(linkID: string){
        const res = this.httpClient.delete("/api/quicklinksdashboard/" + linkID);
        return res;
    }
}
