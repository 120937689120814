import React, { useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { ScoreIndicatorService } from "../../../service/ScoreIndicatorService";
import {
  RouteComponentProps,
  CommonMethods,
} from "../../resources/CommonMethods";
import { LinkPerformanceModel } from "./LinkStatsModel";
import  CustomError  from "../../resources/Error";

export default function LinkPerformanceView(props: RouteComponentProps<any>) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [linkStatsData, setLinkStatsData] = useState<LinkPerformanceModel[]>(
    new Array<LinkPerformanceModel>()
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [dateFilter, setDateFilter] = useState<Date>(new Date());
  const sis = new ScoreIndicatorService();
  const commonMethods = new CommonMethods();
  //   useEffect(() => {
  //     // get data
  //     getData();
  //   }, []);

  useEffect(() => {
    getData();
  }, [dateFilter]);

  const getData = () => {
    let month = moment(dateFilter).month() + 1;
    let year = moment(dateFilter).year();

    sis
      .getLinkStatsPerformance(props.userProfile.userID, month, year)
      .then((res) => {
        setLinkStatsData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
        setErrorMsg(commonMethods.getErrorMessage(err));
      });
  };

  let tableheader = (
    <>
      <div className="row">
        <div className="col-12" style={{ textAlign: "left" }}>
          <span className="p-input-icon-left mr-1 mb-1">
            <i className="pi pi-search" />
            <InputText
              placeholder="Search"
              onInput={(e: any) => setSearchValue(e.currentTarget.value)}
            />
          </span>

          <Calendar
            yearNavigator
            yearRange="2020:2023"
            id="MonthCalendar"
            view="month"
            placeholder="Select Month"
            value={dateFilter}
            dateFormat="M-yy"
            onChange={(e) => {
              setDateFilter(new Date(e.value.toString()));
            }}
          />
        </div>
      </div>
    </>
  );

  const dateTemplate = (rowData: LinkPerformanceModel) => {
    const lastUpdated = rowData.lastUpdatedFromIRNDate;
    if (lastUpdated == null) {
      return <>{"Not Updated"}</>;
    } else {
      return <>{commonMethods.displayDate(lastUpdated)}</>
    }

  };

  let table = (
    <div className="datatable-centerHeader datatable-centerContent">
      <DataTable
        value={linkStatsData}
        paginator={true}
        rows={20}
        autoLayout={true}
        emptyMessage="No results found."
        sortField="createdTime"
        sortOrder={-1}
        header={tableheader}
        globalFilter={searchValue}
      >
        <Column field="publisherName" header="Publisher" />
        <Column field="link" header="Link" style={{ maxWidth: "300px" }} />
        <Column
          field="facebookLink"
          header="FB Link"
          style={{ maxWidth: "300px" }}
        />
        <Column field="views" header="Views" />
        <Column field="reach" header="Reach" />

        <Column field="engagement" header="Engagement" />
        <Column field="impressions" header="Impressions" />
        <Column body={dateTemplate} header="Updated On" className="p-col" />
      </DataTable>
    </div>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = (
      <>
        <p>
          Newly added links in KPI above will take 1 day to be updated here.
          Refer to Link Guide if you are unsure why your link is not appearing.
        </p>

        {table}
      </>
    );
  }

  return (
    <div>
      <div className="card">
        <h1
          className="pb-2"
          data-tour-id="create-my-kpi"
          data-tour="Add your completed assignments here to track your KPI"
        >
          Link Performance
        </h1>
        {display}
      </div>
    </div>
  );
}
