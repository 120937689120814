import React, { useEffect, useState } from "react";
import {
  RouteComponentProps,
  CommonMethods,
} from "../../resources/CommonMethods";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { LinkPerformanceModel } from "./LinkStatsModel";
import { ScoreIndicatorService } from "../../../service/ScoreIndicatorService";
import CustomError from "../../resources/Error";

function LinkPerformanceDeptView(props: RouteComponentProps<any>) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrorMsg] = useState<string>("An error has occurred");

  const [linkStatsData, setLinkStatsData] = useState<LinkPerformanceModel[]>(
    new Array<LinkPerformanceModel>()
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [dateFilter, setDateFilter] = useState<Date>(new Date());
  const sis = new ScoreIndicatorService();
  const commonMethods = new CommonMethods();

  useEffect(() => {
    getDept();
  }, [dateFilter]);

  useEffect(() => {
    getDept();
  }, [props.others]);

  const getDept = () => {
    if (props.others !== null && props.others !== "") {
      const deptid = props.others;
      let month = moment(dateFilter).month() + 1;
      let year = moment(dateFilter).year();
      sis
        .getDeptLinkStatsPerformance(deptid, month, year)
        .then((res) => {
          setLinkStatsData(res);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError(true);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
    }
  };

  let tableheader = (
    <>
      <div className="row">
        <div className="col-12" style={{ textAlign: "left" }}>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              placeholder="Search"
              onInput={(e: any) => setSearchValue(e.currentTarget.value)}
            />
          </span>

          <Calendar
            yearNavigator
            yearRange="2020:2023"
            id="MonthCalendar"
            view="month"
            className="mb-1 mx-1"
            placeholder="Select Month"
            value={dateFilter}
            dateFormat="M-yy"
            onChange={(e) => {
              setDateFilter(new Date(e.value.toString()));
            }}
          />
        </div>
      </div>
    </>
  );

  const dateTemplate = (rowData: LinkPerformanceModel) => {
    const lastUpdated = rowData.lastUpdatedFromIRNDate;
    if (lastUpdated == null) {
      return <>{"Not Updated"}</>;
    } else {
      return <>{commonMethods.displayDate(lastUpdated)}</>
    }

    // return <>{commonMethods.displayDate(new Date(rowData.lastUpdatedFromIRNDate + "Z"))}</>
  };

  const membersTemplate = (rowData: LinkPerformanceModel) => {
    let names = "";
    rowData.members.forEach((member, index) => {
      if (index === rowData.members.length - 1) {
        names += member;
      } else {
        names += member + ", ";
      }
    });
    return names;
  };

  const facebookLinkDisplay = (rowData: LinkPerformanceModel) => {
    if (rowData.facebookLink) {
      return <a href={rowData.facebookLink} rel="noopener noreferrer" target="_blank">Link</a>
    }
  }

  let table = (
    <div className="datatable-centerHeader datatable-centerContent">
      <DataTable
        value={linkStatsData}
        paginator={true}
        rows={20}
        autoLayout={true}
        emptyMessage="No results found."
        sortField="createdTime"
        sortOrder={-1}
        header={tableheader}
        globalFilter={searchValue}
      >
        <Column field="publisherName" header="Publisher" />
        <Column body={membersTemplate} header="Members" />
        <Column field="members" header="memb" className="d-none" />

        <Column field="deliverableName" header="Deliverable" />

        <Column field="link" header="Link" style={{ maxWidth: "300px" }} />
        <Column
          field="facebookLink"
          body={facebookLinkDisplay}
          header="FB Link"
          style={{ maxWidth: "300px" }}
        />
        <Column field="views" header="Views" />
        <Column field="reach" header="Reach" />

        <Column field="engagement" header="Engagement" />
        <Column field="impressions" header="Impressions" />
        <Column body={dateTemplate} header="Updated On" className="p-col" />
      </DataTable>
    </div>
  );

  let display;

  if (isError) {
    display = <CustomError message={errMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = table;
  }

  return <>{display}</>;
}

export default LinkPerformanceDeptView;
