import { HttpClient } from "./HttpClient";
export class StatusService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getStatusTypes(status: string) {
    const res = await this.httpClient.get("/api/statustype/status/" + status);
    return res;
  }

  // gets all active and deductible status type names by GCID
  async getAllStatusTypeNames() {
    const res = await this.httpClient.get("/api/statustype/names");
    return res;
  }

  async getAllStatusTypeNamesIncBF() {
	  const res = await this.httpClient.get("/api/statustype/names/bf");
	  return res;
  }

  async getDepartmentAppliedStatus(year: string, month: string) {
    return await this.httpClient.get(
      `/api/statusrecord/deptAppliedStatus/${year}/${month}`
    );
  }

  async getLeaveAppliedByDeptId(deptid: string, year: string, month: string) {
    return await this.httpClient.get(
      `/api/statusrecord/deptAppliedStatus/${deptid}/${year}/${month}`
    );
  }

  async getGroupCompanyAppliedStatus(year: string, month: string) {
    return await this.httpClient.get(
      `/api/statusrecord/groupCompanyAppliedStatus/${year}/${month}`
    );
  }

  async getStatusTypeById(id: string) {
    try {
      const res = await this.httpClient.get("/api/statustype/" + id);
      return res;
    } catch (err) {
      return err;
    }
  }

  async getTypesThatRequireDocuments() {
    const res = await this.httpClient.get("/api/statustype/documentrequired");
    return res;
  }

  async getStatusIdByName(name: string) {
    const res = await this.httpClient.get("/api/statustype/" + name + "/names");
    return res;
  }

  // async getStatusNameById(id: string)
  // {
  //   const res = await this.httpClient.get("/api/statustype/" + id)
  //   return res.statusTypeName
  // }

  async createStatusType(statusTypeObj: any) {
    const res = await this.httpClient.post(
      "/api/statustype/create",
      statusTypeObj
    );
    return res;
  }

  async getStatusTypeDropdown() {
    // used at personal leave application
    try {
      const res = await this.httpClient.get("/api/dropdown/statustype");
      return res;
    } catch (err) {
      return err;
    }
  }

  async getStatusTypeDropdownForThisUser(userid: string) {
    // used for apply on behalf (supervisor)
    try {
      const res = await this.httpClient.get(
        "/api/dropdown/statustype/" + userid
      );
      return res;
    } catch (err) {
      return err;
    }
  }

  async getActiveAndDeductibleStatusTypes() {
    const res = await this.httpClient.get(
      "/api/dropdown/statustype/activedeductible"
    );
    return res;
  }

  async getStatusTypeDropdownAll() {
    // used for noticeboard & admin apply on behalf
    try {
      const res = await this.httpClient.get("/api/dropdown/statustype/all");
      return res;
    } catch (err) {
      return err;
    }
  }

  async getStatusTypeDropdownDeductible() {
    const res = await this.httpClient.get(
      "/api/dropdown/statustype/deductible"
    );
    return res;
  }
  async getActiveStatusTypeDropdown() {
    const res = await this.httpClient.get("/api/dropdown/statustype/active");
    return res;
  }

  async updateStatusType(statusTypeObj: any) {
    const res = await this.httpClient.put(
      "/api/statustype/update",
      statusTypeObj
    );
    return res;
  }

  async tempDeleteStatusType(statusTypeID: any) {
    try {
      const res = await this.httpClient.get(
        "/api/statustype/tempdelete/" + statusTypeID
      );
      return res;
    } catch (err) {
      return err;
    }
  }

  async deleteStatusType(statusTypeObj: any) {
    const res = await this.httpClient.delete(
      "/api/statustype/" + statusTypeObj.statusTypeID
    );
    return res;
  }

  async getCreditHistoryByUserID(userID: string) {
    const res = await this.httpClient.get(`/api/statuscredit/userid/${userID}`);
    return res;
  }

  async getMyCreditHistory() {
    const res = await this.httpClient.get(`/api/statuscredit/me`);
    return res;
  }

  async creditStatus(statusCreditObj: any) {
    var form_data = new FormData();

    for (var key in statusCreditObj) {
      form_data.append(key, statusCreditObj[key]);
    }

    const res = await this.httpClient.post(
      "/api/statuscredit/create",
      form_data
    );

    return res;
  }

  async changeAllocatedDays(statusCreditObj: any) {
    var form_data = new FormData();

    for (var key in statusCreditObj) {
      form_data.append(key, statusCreditObj[key]);
    }

    const res = await this.httpClient.post(
      "/api/statuscredit/allocate",
      form_data
    );
    return res;
  }

  async batchCreditStatus(statusCreditObj: any) {
    try {
      const res = await this.httpClient.post(
        "/api/statuscredit/create/batch",
        statusCreditObj
      );
      return res;
    } catch (err) {
      return err;
    }
  }

  async resetLeavesForSelectedEmployees(userListObj: any) {
    try {
      const res = await this.httpClient.post(
        "/api/statuscredit/reset",
        userListObj
      );
      return res;
    } catch (err) {
      return err;
    }
  }

  /***
   *           _          _                 _             _
   *      ___ | |_  __ _ | |_  _   _  ___  | |__    __ _ | |  __ _  _ __    ___  ___
   *     / __|| __|/ _` || __|| | | |/ __| | '_ \  / _` || | / _` || '_ \  / __|/ _ \
   *     \__ \| |_| (_| || |_ | |_| |\__ \ | |_) || (_| || || (_| || | | || (__|  __/
   *     |___/ \__|\__,_| \__| \__,_||___/ |_.__/  \__,_||_| \__,_||_| |_| \___|\___|
   *
   */
  async getAllDepartmentBalance() {
    const res = await this.httpClient.get(
      "/api/statusbalance/departmentbalance"
    );
    return res;
  }

  async getMultipleDeptBalance() {
    const res = await this.httpClient.get(
      "/api/statusbalance/departmentbalance/multi"
    );
    return res;
  }

  async getGroupCompanyUsersBalance() {
    const res = await this.httpClient.get(
      "/api/statusbalance/groupcompanybalance"
    );
    return res;
  }

  async getStatusBalanceByStatusName(name: string) {
    // let newObj = { leaveName: name };
    const res = await this.httpClient.get("/api/statusbalance/type/" + name);
    return res;
  }

  async getMyLeaveBalance() {
    const res = await this.httpClient.get(`/api/statusbalance/me`);

    return res;
  }
  async getLeaveBalanceByUserID_StaffView(userID: string) {
    const res = await this.httpClient.get(`/api/statusbalance/user/${userID}`);
    return res;
  }

  async getLeaveBalanceByUserID_AdminView(userID: string) {
    const res = await this.httpClient.get(
      `/api/statusbalance/user/${userID}/admin`
    );

    return res;
  }

  async getLeaveBalanceByUserID_ContractView(userID: string) {
    const res = await this.httpClient.get(
      `/api/statusbalance/user/${userID}/contract`
    );

    return res;
  }
}
