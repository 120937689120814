import { HttpClient } from "./HttpClient";
import { DeliverableModel } from "../components/deliverable/DeliverablesInCampaign";
import moment from "moment";
import { CommonMethods } from "../components/resources/CommonMethods";
export class DeliverableService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async createDeliverable(DeliverableObj: any) {
    var form_data = new FormData();


    for (var key in DeliverableObj) {
      if (key === "publicationDate" || key === "closedDate") {
        let utcDate = moment(DeliverableObj[key]).format(
          CommonMethods.DateForBackendStringFormat
        );
        form_data.append(key, utcDate);
      } else {
        form_data.append(key, DeliverableObj[key]);
      }
    }

    const res = await this.httpClient.post("/api/deliverable/create", form_data);
    return res;
  }

  async updateDeliverable(DeliverableObj: any) {
    var form_data = new FormData();

    for (var key in DeliverableObj) {
      if (key === "publicationDate" || key === "closedDate") {
        let utcDate = moment(DeliverableObj[key]).format(
          CommonMethods.DateForBackendStringFormat
        );
        form_data.append(key, utcDate);
      } else {
        form_data.append(key, DeliverableObj[key]);
      }
    }

    const res = await this.httpClient.put("/api/deliverable/update", form_data);
    return res;
  }

  async updateDeliverableBilledStatus(DeliverableObj: DeliverableModel) {
    const res = this.httpClient.put("/api/deliverable/update/billedstatus", DeliverableObj);
    return res;
  }

  async getAllDeliverablesInGroupCompany() {
    const res = await this.httpClient.get("/api/deliverable");
    return res;
  }

  async getAllDeliverablesInCampaign(id: string) {
    const res = await this.httpClient.get("/api/deliverable/campaign/" + id);
    return res;
  }

  async getDeliverableByUrl(id: string) {
    const res = await this.httpClient.get("/api/deliverable/" + id);
    return res;
  }

  async deleteDeliverableByID(id: string) {
    const res = await this.httpClient.delete("/api/deliverable/" + id);
    return res;
  }

  async updateDeliverableStatus(DeliverableObj: any) {
    const res = this.httpClient.put("/api/deliverable/status", DeliverableObj);
    return res;
  }

  async getAllDeliverablesForUser() {
    const res = this.httpClient.get("/api/deliverable/userID");
    return res;
  }

  async getSalesPersonCampaignCountForTheMonth() {
    const res = await this.httpClient.get("/api/deliverable/SalesPersonCampaignCount/Month");
    return res
  }

  async getAllDeliverablesFromUserDepartment() {
    const res = this.httpClient.get("/api/deliverable/department");
    return res;
  }

}

export default DeliverableService;