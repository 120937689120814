import Editor from '@draft-js-plugins/editor/lib/Editor';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { NotificationsService } from '../../../../../service/NotificationsService';
import { getContext, toTitleCase } from '../../../CompanyFeedFunctions';
import { CommentModel } from '../../../Model/CommentModel';
import { PostModel } from '../../../Model/PostModel';
import CompanyFeedService from '../../../Service/CompanyFeedService';
import CommentDraftJsEditor from '../../DraftJsEditors/CommentDraftJsEditor';
import profileplaceholder from '../../../Assets/profileplaceholder.jpg'
import { ACTIONS } from '../../../DispatchActions';

export default function CreateComment({
    post,
    comment,
    inputPlaceholder,
    setViewMoreThreshold,
    commentReplyCounter,
    contextName,
    parentComponent,
}: {
    className?: string;
    post?: PostModel;
    comment?: CommentModel;
    inputPlaceholder: string;
    setViewMoreThreshold: React.Dispatch<React.SetStateAction<number>>
    commentReplyCounter?: number
    contextName: string
    parentComponent?: string | undefined
}) {
    const { state, dispatch } = useContext(getContext(contextName));
    const commentSubmitRef = useRef<HTMLButtonElement>(null)
    const [rawEditorContentJsonStr, setRawEditorContentJsonStr] = useState<string | null>(null)
    const [usersTaggedArr, setUsersTaggedArr] = useState<null | string[]>(null)
    const editorRef = useRef<Editor>(null);
    const [hasText, setHasText] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isEditorFocused, setIsEditorFocused] = useState(false)
    const [mentionSuggestionsOpened, setMentionSuggestionsOpened] = useState(false)
    const notifSvc = new NotificationsService();
    const companyFeedService = new CompanyFeedService();

    // useEffect(() => {
    //     console.log('mentionSuggestionsOpened', mentionSuggestionsOpened)
    //     console.log('focus', focus)
    // }, [mentionSuggestionsOpened, focus])

    useEffect(() => {
        // Draftjs issue with focus() breaking plugins, call setTimeout as a temporary solution
        // https://github.com/draft-js-plugins/draft-js-plugins/issues/800
        setTimeout(() => {
            if (editorRef.current) editorRef.current.focus()
        }, 0);
    }, [commentReplyCounter])

    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if ((event.code === "Enter" && !event.shiftKey) || event.code === "NumpadEnter") {
                event.preventDefault();
                if (commentSubmitRef.current) {
                    commentSubmitRef.current.click()
                }
            }
        };
        if (mentionSuggestionsOpened) document.removeEventListener("keydown", listener);
        if (isEditorFocused) document.addEventListener("keydown", listener);
        if (!isEditorFocused) document.removeEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [isEditorFocused, mentionSuggestionsOpened]);



    const handleCommentSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (post) {
            companyFeedService.insertComment(post.postId, null, null, rawEditorContentJsonStr, (usersTaggedArr ? JSON.stringify(usersTaggedArr) : null))
                .then((res) => {
                    const comment = res.data
                    // console.log('comment posted', comment)
                    if (parentComponent === "TodayBirthdayPost") dispatch({type: ACTIONS.BIRTHDAY_POST.ADD_COMMENT, payload: comment})
                    else dispatch({type: ACTIONS.ADD_COMMENT, payload: comment})
                    // prevents new comment from hiding older comment
                    setViewMoreThreshold(prevState => prevState + 1)

                    // notifications
                    if (usersTaggedArr) {
                        // don't notify sender if they tag themself
                        let usersToNotify = usersTaggedArr.filter((el) => (el !== state.currentUser.userId))
                        let notifObjArr = []
                        for (let i = 0; i < usersToNotify.length; i++) {
                            let notifObj = {
                                RecipientUserID: usersToNotify[i],
                                SenderUserID: state.currentUser.userId,
                                Url: `/companyfeed/post/${comment.postId}?commentId=${comment.commentId}`,
                                MsgHeader: "Tagged In Comment",
                                Message: `${toTitleCase(state.currentUser.firstName) + " " + toTitleCase(state.currentUser.lastName)} has tagged you in a comment`
                            }
                            notifObjArr.push(notifObj)
                            // console.log('notifObj create', notifObj)
                        }
                        notifSvc.createNotification(notifObjArr).then()
                    }
                })
                .catch((err) => {
                    console.log(err)
                    alert("Comment Error")
                });
        } else if (comment) {
            companyFeedService.insertComment(comment.postId, comment.commentId, null, rawEditorContentJsonStr, (usersTaggedArr ? JSON.stringify(usersTaggedArr) : null))
                .then((res) => {
                    const comment = res.data
                    // console.log('comment posted', comment)
                    if (parentComponent === "TodayBirthdayPost") dispatch({type: ACTIONS.BIRTHDAY_POST.ADD_CHILD_COMMENT, payload: comment})
                    else dispatch({type: ACTIONS.ADD_CHILD_COMMENT, payload: comment})
                    // notifications
                    if (usersTaggedArr) {
                        // don't notify sender if they tag themself
                        let usersToNotify = usersTaggedArr.filter((el) => (el !== state.currentUser.userId))
                        let notifObjArr = []
                        for (let i = 0; i < usersToNotify.length; i++) {
                            let notifObj = {
                                RecipientUserID: usersToNotify[i],
                                SenderUserID: state.currentUser.userId,
                                Url: `/companyfeed/post/${comment.postId}?commentId=${comment.parentCommentId}&replyCommentId=${comment.commentId}`,
                                MsgHeader: "Tagged In Comment",
                                Message: `${toTitleCase(state.currentUser.firstName) + " " + toTitleCase(state.currentUser.lastName)} has tagged you in a comment`
                            }
                            notifObjArr.push(notifObj)
                            // console.log('notifObj create', notifObj)
                        }
                        notifSvc.createNotification(notifObjArr).then()
                    }
                })
                .catch((err) => {
                    console.log(err)
                    alert("Comment Error")
                });
        } else return
        resetForm();
    };

    function resetForm() {
        setRawEditorContentJsonStr(null)
        setUsersTaggedArr(null)
        setIsSubmitted(true)
    };

    return (
        <>{
            <form onSubmit={handleCommentSubmit}>
                <div className="flex-row">
                    <div>
                        <img
                            className="p-avatar-circle-comment"
                            src={state.currentUser.displayPicture ?? profileplaceholder}
                            alt=""
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => e.currentTarget.src = profileplaceholder}
                        />
                    </div>
                    <div className="create-comment">
                        <div className="position-relative">
                            <CommentDraftJsEditor
                                setRawEditorContentJsonStr={setRawEditorContentJsonStr}
                                setUsersTaggedArr={setUsersTaggedArr}
                                editorRef={editorRef}
                                setHasText={setHasText}
                                isSubmitted={isSubmitted}
                                setIsSubmitted={setIsSubmitted}
                                setIsEditorFocused={setIsEditorFocused}
                                setMentionSuggestionsOpened={setMentionSuggestionsOpened}
                                inputPlaceholder={inputPlaceholder}
                                parentComponent="CreateComment"
                                contextName={contextName}
                            />
                        </div>
                    </div>
                    <div className="create-comment-button">
                        <button
                            ref={commentSubmitRef}
                            type="submit"
                            disabled={!hasText}
                            className="create-comment-submit"
                        >
                            <span className="material-icons md-22 create-comment-send">send</span>
                        </button>
                    </div>
                </div>
            </form>
        }</>
    );
}