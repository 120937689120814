import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import DeliverableService from "../../service/DeliverableService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { DeliverableModel } from "./DeliverablesInCampaign";

let deliverableService = new DeliverableService();
// let campaignService = new CampaignService();
let commonMethods = new CommonMethods();
function DeliverablesOutstandingView(props: RouteComponentProps<any>) {
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    setIsLoading(true);
    deliverableService
      .getAllDeliverablesForUser()
      .then((res) => {
        if (myAbortController.signal.aborted) {
          return;
        }
        setIsLoading(false);
        setTableData(res);
      })
      .catch((err) => {
        if (myAbortController.signal.aborted) {
          return;
        }
        setIsLoading(false);
        setIsError(true);
        setErrorMsg(err);
      });

    return () => {
      myAbortController.abort();
    };
  }, []);

  // const pmoTemplate = (rowData: DeliverableModel) => {
  //   return rowData.deliverableMembersForDisplay.map(
  //     (item: string, index: number) => {
  //       if (index === rowData.deliverableMembersForDisplay.length - 1) {
  //         return item;
  //       } else {
  //         return item + ", ";
  //       }
  //     }
  //   );
  // };

  const createdDateTemplate = (rowData: DeliverableModel) => {
    return commonMethods.displayDate(new Date(rowData.createdTime));
  };

  const goToCampaignSummary = (rowData: DeliverableModel) => {
    setRedirectPath("/campaign/summary/" + rowData.campaignID);
    setRedirectOut(true);
  };

  const table = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMsg} />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={tableData}
            rowClassName={() => {
              return { pointer: "pointer" };
            }}
            onRowClick={(event) => {
              goToCampaignSummary(event.data);
            }}
            paginator={true}
            rows={15}
            alwaysShowPaginator={false}
            autoLayout={true}
            emptyMessage="No Deliverables Found"
            groupField="campaignName"
            sortField="campaignName"
            rowGroupMode="rowspan"
          >
            <Column field="deliverableSerialNo" header="Serial" />
            <Column field="deliverableTypeName" header="Type" />
            <Column field="deliverableName" header="Deliverable" />
            <Column field="campaignName" header="Campaign" />
            {/* <Column field="deliverablePoint" header="Points" /> */}
            {/* <Column body={pmoTemplate} header="Members" /> */}
            <Column field="requirements" header="Requirements" />
            <Column body={createdDateTemplate} header="Created Date" />
            {/* <Column field="deliverableStatus" header="Status" /> */}
          </DataTable>
        </div>
      );
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <div
          className="card"
          data-tour-id="outstanding-deliverables"
          data-tour="This is a list of all your campaign deliverables which have not been completed. Click in to view more!"
        >
          {redirectOut ? <Redirect push to={redirectPath} /> : null}
          <h1 className="pb-2">My Outstanding Deliverables (In Progress)</h1>
          {table()}
        </div>
      </div>
    </div>
  );
}

export default DeliverablesOutstandingView;
