import { HttpClient } from "./HttpClient";
import moment from "moment";
import { CommonMethods } from "../components/resources/CommonMethods";

export class RoomBookingService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  // This method is replaced by getAllBookingsByDateRange
  async getAllBookingsByGCID() {
    const res = await this.httpClient.get("/api/roombooking");
    return res;
  }

  async getAllBookingsByDateRange(startDate: Date, endDate: Date) {
    var startDateTime = moment(startDate).format("YYYY-MM-DD");
    var endDateTime = moment(endDate).subtract(1, 'day').format("YYYY-MM-DD");

    const res = await this.httpClient.get(`/api/roombooking/daterange/${startDateTime}/${endDateTime}`);
    return res;
  }

  // async getAllBookingsByMonth(date:Date) {
  //   const res = await this.httpClient.get("/api/roombooking/month/"+moment(date).month());
  //   return res;
  // }

  async getAllBookingsByMonth(startDate: Date){
    const res = await this.httpClient.get(`/api/roombooking/month/${startDate}`);
    return res;
  }

  async getAllBookingsForDate(date: Date) {
    // 2020-02-12 PCLQ: Changed from post to get request
    var thisDate = moment(date).format("YYYY-MM-DD");
    const res = await this.httpClient.get(`/api/roombooking/date/${thisDate}`);
    return res;
  }

  async getRoomDropdownByGCID() {
    const res = await this.httpClient.get("/api/dropdown/room");
    return res;
  }

  async getRoomDropdownByByDateAndTime(
    date: string,
    start: string,
    end: string
  ) {
    const res = await this.httpClient.get(
      `/api/dropdown/room/available/${date}/${start}/${end}`
    );
    return res;
  }

  async createBooking(roomBookingModel: any) {
    var form_data = new FormData();

    for (var key in roomBookingModel) {
      if (
        key === "startDateTime" ||
        key === "endDateTime" ||
        key === "bookedDate"
      ) {
        // let utcDate = roomBookingModel[key].toLocaleString();
        let utcDate = moment(roomBookingModel[key]).format(
          CommonMethods.DateForBackendStringFormat
        );
        form_data.append(key, utcDate);
      } else {
        form_data.append(key, roomBookingModel[key]);
      }
    }

    const res = await this.httpClient.post(
      "/api/roombooking/create",
      form_data
    );
    return res;
  }

  async updateRoomBooking(roomBookingModel: any) {
    var form_data = new FormData();

    for (var key in roomBookingModel) {
      if (
        key === "startDateTime" ||
        key === "endDateTime" ||
        key === "bookedDate"
      ) {
        // let utcDate = roomBookingModel[key].toLocaleString();
        let utcDate = moment(roomBookingModel[key]).format(
          CommonMethods.DateForBackendStringFormat
        );

        form_data.append(key, utcDate);
      } else {
        form_data.append(key, roomBookingModel[key]);
      }
    }

    const res = await this.httpClient.put("/api/roombooking/update", form_data);
    return res;
  }

  async deleteRoomBooking(roomID: string) {
    const res = await this.httpClient.delete("/api/roombooking/" + roomID);
    return res;
  }

  // This method is replaced by getRoomBookingsByRoomIdAndDateRange
  async getRoomBookingsByRoomId(roomID: string) {
    const res = await this.httpClient.get("/api/roombooking/roomID/" + roomID);

    return res;
  }

  async getRoomBookingsByRoomIdAndDateRange(roomID: string, startDate: Date, endDate: Date) {
    var startDateTime = moment(startDate).format("YYYY-MM-DD");
    var endDateTime = moment(endDate).subtract(1, 'day').format("YYYY-MM-DD");

    const res = await this.httpClient.get(`/api/roombooking/roomID/${roomID}/daterange/${startDateTime}/${endDateTime}`);

    return res;
  }

  async getAllBookingsByUserID(userID: string) {
    const res = await this.httpClient.get("/api/roombooking/userID/" + userID);
    return res;
  }

  async getRoomBookingByID(bookingID: string) {
    const res = await this.httpClient.get(
      "/api/roombooking/bookingID/" + bookingID
    );
    return res;
  }

  async getMyRoomBookings() {
    return await this.httpClient.get("/api/roombooking/mybookings/");
  }

  async getMyRoomBookingsHistory() {
    return await this.httpClient.get("/api/roombooking/mybookingshistory/");
  }
}

export class RoomBookingModel {
  public roomBookingID: string = "";
  public roomID: string = "";
  public roomName: string = "";
  public bookedBy: string = "";
  public startDateTime: Date = new Date();
  public endDateTime: Date = new Date();
  public bookedDate: Date = new Date();
  public purpose: string = "";
  public remarks: string = "";
  // hm added for noticeboard
  public firstName: string = "";
  public lastName: string = "";
}
