import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { ScoreIndicatorService } from "../../../service/ScoreIndicatorService";
import {
  RouteComponentProps,
  CommonMethods,
} from "../../resources/CommonMethods";
import { ToastStateContext } from "../../resources/ToastContext";
import CreateKPI from "./CreateIndicator";
import KPIProgressBar from "./KPIProgressBar";
import ProrateUserTarget from "./ProrateUserTarget";
import { ProgressSpinner } from "primereact/progressspinner";
import ReactTooltip from "react-tooltip";
import { UserIndicatorWithTargetModel, UserIndicatorModel } from "./KPIModels";

function KPITable(props: RouteComponentProps<any>) {
  const [myKPI, setMyKPI] = useState<UserIndicatorWithTargetModel>(
    new UserIndicatorWithTargetModel()
  );

  const [isLoading, setIsLoading] = useState(true);

  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [addDialogVisibility, setAddDialogVisibility] = useState(false);
  const [customdialogVisibility, setCustomDialogVisibility] = useState(false);
  const [refreshView, setRefreshView] = useState(false);
  const [userIndicatorId, setUserIndicatorId] = useState<string>();

  const [hodView, setHODView] = useState(true);
  const [searchValue, setSearchValue] = useState<string>("");

  const [dateFilter, setDateFilter] = useState<Date>(new Date());
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [viewingMonth, setViewingMonth] = useState<string>();
  const [viewingYear, setViewingYear] = useState<string>();
  const [deleteDialogVisibility, setDeleteDialogVisibility] = useState<boolean>(
    false
  );
  const [selectedItem, setSelectedItem] = useState<UserIndicatorModel>();

  const sis = new ScoreIndicatorService();
  const commonMethods = new CommonMethods();

  useEffect(() => {
    setHODView(props.others.hodview);
  }, []);

  useEffect(() => {
    setDateFilter(new Date());
    setMyKPI(props.others.item);
    if (props.others.item.userTargetModel.targetMonth !== "" && props.others.item.userTargetModel.targetYear !== "") {
      setViewingMonth(
        commonMethods.displayMonth_Full(
          props.others.item.userTargetModel.targetMonth
        )
      );
      setViewingYear(
        props.others.item.userTargetModel.targetYear
      );
      setIsLoading(false);
    }
  }, [props.others.item]);

  useEffect(() => {
    if(!hodView){
      props.others.update();
    }
  }, [refreshView]);

  const getData = () => {
    let month = moment(dateFilter).month() + 1;
    let year = moment(dateFilter).year();

    if (month === selectedMonth && year === selectedYear) {
      // setIsLoading(false);
      return;
    }

    if (myKPI.userID !== "") {
      setSelectedMonth(month);
      setSelectedYear(year);
      sis.getMyKPIEntriesByMonthYear(myKPI.userID, month, year).then((res) => {
        setMyKPI(res);
        setViewingMonth(
          commonMethods.displayMonth_Full(res.userTargetModel.targetMonth)
        );
        setViewingYear(
          res.userTargetModel.targetYear
        );
        setIsLoading(false);
      });
    }
  };

  // refresh table when month selected changes.
  useEffect(() => {
    getData();
  }, [dateFilter]);

  // const calculateMonthlyTotal = () => {
  //   let total = 0;
  //   myKPITable.userIndicatorList.forEach((kpi: any) => {
  //     total += kpi.points;
  //   });
  //   return total;
  // };

  const deleteMyKPI = (rowData: any) => {
    sis
      .removeMyKPI(rowData.userIndicatorID)
      .then((res) => {
        SetSuccess("KPI deleted!");
        props.others.update();
        setIsLoading(true);
        setDeleteDialogVisibility(false);
      })
      .catch((err) => {
        SetError("Unable to delete KPI!");
      });
  };

  const updateMyKPI = (rowData: any) => {
    setDialogVisibility(true);
    setUserIndicatorId(rowData.userIndicatorID);
  };

  const dateTemplate = (rowData: any) => {
    return (
      <>{commonMethods.displayDate(new Date(rowData.createdTime + "Z"))}</>
    );
  };

  const gotStats = (rowData: any) => {
    if (rowData.validStatsLink) {
      return (
        <span data-for="yesperf" data-tip="">
          <ReactTooltip
            id="yesperf"
            aria-haspopup="true"
            className="tooltipFont"
          >
            <p className="text-center mb-0">
              Link can be found in the Link Performance Table
            </p>
          </ReactTooltip>{" "}
          Y
        </span>
      );
    }
    return (
      <span data-for="noperf" data-tip="">
        <ReactTooltip id="noperf" aria-haspopup="true" className="tooltipFont">
          <p className="text-center mb-0">
            Not a valid link for performance tracking{" "}
          </p>
        </ReactTooltip>{" "}
        -
      </span>
    );
  };

  const actionTemplate = (rowData: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Edit"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning mr-1"
          onClick={() => {
            updateMyKPI(rowData);
          }}
        ></Button>
        <Button
          tooltip="Delete"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            setDeleteDialogVisibility(true);
            setSelectedItem(rowData);
          }}
        />
      </div>
    );
  };

  let tableheader = (
    <>
      {/* <div className="row">
        <div className="col-12">
          <h5>
            {moment(dateFilter).format("MMMM")}:{" "}
            <b>
              {calculateMonthlyTotal().toFixed(2)}/
              {myKPITable.userTargetModel.targetPoints}{" "}
            </b>
            (
            {(
              (calculateMonthlyTotal() /
                myKPITable.userTargetModel.targetPoints) *
              100
            ).toFixed(2)}
            %)
          </h5>
        </div>
      </div>{" "} */}
      <div className="row">
        <div className="col-12" style={{ textAlign: "left" }}>
          <span className="p-input-icon-left mr-1 mb-1">
            <i className="pi pi-search" />
            <InputText
              placeholder="Search"
              onInput={(e: any) => setSearchValue(e.currentTarget.value)}
            />
          </span>

          <Calendar
            id="MonthCalendar"
            yearNavigator
            yearRange="2020:2023"
            view="month"
            className="mb-1"
            placeholder="Select Month"
            value={dateFilter}
            dateFormat="M-yy"
            onChange={(e) => {
              setDateFilter(new Date(e.value.toString()));
            }}
          />
          {hodView && (
            <><span className="pl-3">
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                tooltip="Add KPI"
                tooltipOptions={{ position: "top" }}
                className="p-button-danger mt-1 mr-1"
                onClick={() => {
                  setAddDialogVisibility(true);
                }}
              />
            </span>
              <span>
                <Button
                  type="button"
                  icon="pi pi-plus"
                  iconPos="right"
                  label="Add Custom"
                  tooltip="Add Custom KPI"
                  tooltipOptions={{ position: "top" }}
                  className="mx-1 mt-1"
                  onClick={() => {
                    setCustomDialogVisibility(true);
                  }}
                /></span></>
          )}
        </div>
      </div>
    </>
  );

  let display = (
    <div>
      {/* justify-content-around/ */}
      <div className="row mt-1 justify-content-around pb-3">
        <div className="col-12 col-md-3 justify-content-center align-self-center text-center">
          <p>Now viewing:</p>
          <h3>{viewingMonth}{" "}{viewingYear}</h3>
        </div>
        <div className="col-12 col-md-6 pb-2">
          <KPIProgressBar {...props} others={myKPI} />
        </div>
        <div className="col-12 col-md-3 align-self-center justify-content-center">
          <ProrateUserTarget {...props} others={myKPI} />
        </div>
      </div>

        <Dialog
          header="Edit KPI"
          visible={dialogVisibility}
          modal={true}
          //dismissableMask={true}
          onHide={() => setDialogVisibility(false)}
        >
          <CreateKPI
            isUpdate={true}
            id={userIndicatorId}
            refresh={refreshView}
            setRefreshView={setRefreshView}
            callback={setDialogVisibility}
          />
        </Dialog>

      {hodView && (<>
        <Dialog
          header="Add KPI"
          visible={addDialogVisibility}
          modal={true}
          //dismissableMask={true}
          onHide={() => setAddDialogVisibility(false)}
          className="popupDialogKPI"
        >
          {addDialogVisibility && (
            <CreateKPI
              isUpdate={false}
              refresh={refreshView}
              setRefreshView={setRefreshView}
              callback={setAddDialogVisibility}
              isCustomPointEntry={false}
              hodView={hodView}
              thisUser={myKPI.userID}
            />
          )}
        </Dialog>

        <Dialog
          header="Add Custom KPI"
          visible={customdialogVisibility}
          modal={true}
          className="popupDialogKPI"
          //dismissableMask={true}
          onHide={() => setCustomDialogVisibility(false)}
        >
          {customdialogVisibility && (
            <CreateKPI
              isUpdate={false}
              refresh={refreshView}
              setRefreshView={setRefreshView}
              callback={setCustomDialogVisibility}
              isCustomPointEntry={true}
              hodView={hodView}
              thisUser={myKPI.userID}
            />
          )}
        </Dialog>
      </>
      )}
      {/* <Button
  label="All"
  type="button"
  iconPos="left"
  icon="fas fa-angle-double-down"
  className="p-button-primary mb-2 mx-3"
  onClick={expandAll}
/> */}
      <hr></hr>
      <div
        data-tour-id="my-kpi-table"
        data-tour="These are your completed assignments and points."
      >
        {/* Pagination doesnt work with rowGroup -> second page onwards will just be blank -- 22/04/2020 */}
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            // header={moment(dateFilter).format("MMM")}
            value={myKPI.userIndicatorList}
            paginator={true}
            rows={20}
            autoLayout={true}
            // alwaysShowPaginator={false}
            emptyMessage="No KPI found."
            // rowGroupMode="subheader"
            sortField="createdTime"
            sortOrder={-1}
            // groupField="groupTime"
            // rowGroupHeaderTemplate={headerTemplate}
            // footer={footerTemplate}
            // rowGroupFooterTemplate={footerTemplate}
            // expandableRowGroups={true}
            // expandedRows={expandedRows}
            // onRowToggle={(e) => setExpandedRows(e.data)}
            header={tableheader}
            globalFilter={searchValue}
          >
            <Column
              field="indicatorName"
              header="Task Name"
              style={{ maxWidth: "300px" }}
            />
            <Column
              field="deliverableName"
              header="Deliverable"
              style={{ maxWidth: "300px" }}
            />
            <Column field="campaignName" header="Campaign" />

            <Column field="points" header="Points" />
            {/* <Column field="modifier" header="Modifier" /> */}
            <Column field="link" header="Link" style={{ maxWidth: "300px" }} />
            <Column
              field="facebookLink"
              header="FB Link"
              style={{ maxWidth: "300px" }}
            />

            <Column
              field="remarks"
              header="Remarks"
              style={{ maxWidth: "300px" }}
            />
            <Column body={dateTemplate} header="Added On" />
            <Column body={gotStats} header="Perf" />

            <Column body={actionTemplate} header="Action" />
          </DataTable>
        </div>
      </div>
    </div>
  );
  if (isLoading) {
    return <ProgressSpinner />;
  } else {
    return (
      <div
        // className="card"
        data-tour-id="my-kpi-target"
        data-tour="Here is your progress and target."
      >
        {display}
        <Dialog
          header={"Delete KPI"}
          visible={deleteDialogVisibility}
          modal={true}
          //dismissableMask={true}
          onHide={() => setDeleteDialogVisibility(false)}
          footer={
            <div>
              <Button
                label="Yes"
                type="button"
                iconPos="left"
                icon="pi pi-check"
                className="p-button-success"
                onClick={() => {
                  deleteMyKPI(selectedItem);
                }}
              />
            </div>
          }
        >
          <p>Are you sure you want to delete?</p>
        </Dialog>
      </div>
    );
  }
}

export default KPITable;
