import { HttpClient } from "./HttpClient";
import { convertObjToFormData } from "../components/resources/CommonMethodsServices";
export class CompanyService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async getCompanies(status: string) {
    const res = await this.httpClient.get("/api/company/status/" + status);
    return res;
  }

  async getAllCompanies() {
    const res = await this.httpClient.get("/api/company");
    return res;
  }

  async getCompanyById(id: string) {
    const res = await this.httpClient.get("/api/company/" + id);
    return res;
  }

  async createCompany(companyObj: any) {
    var form_data = new FormData();
    for (var key in companyObj) {
      form_data.append(key, companyObj[key]);
    }
    const res = await this.httpClient.post("/api/company/create", form_data);
    return res;
  }

  async updateCompany(companyObj: any) {
    const res = await this.httpClient.put(
      "/api/company/update",
      convertObjToFormData(companyObj)
    );
    return res;
  }

  async updateCompanyFinance(companyObj: any) {
    const res = await this.httpClient.put(
      "/api/company/update/finance",
      convertObjToFormData(companyObj)
    );
    return res;
  }

  async updateCompanyStatus(companyObj: any) {
    const res = await this.httpClient.put("/api/company/status", companyObj);
    return res;
  }

  async deleteCompany(companyID: string) {
    const res = await this.httpClient.delete("/api/company/" + companyID);
    return res;
  }

  async getCompanyLogoFromUser() {
    return await this.httpClient.get("/api/company/companylogo");
  }

}
