import * as React from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Redirect, RouteComponentProps } from "react-router";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { UserDocumentService, UserService } from "../../service/UserService";
import { CommonMethods } from "../resources/CommonMethods";
import { DocumentTypeService } from "../../service/DocumentTypeService";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import CustomError  from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";

interface AppProps {}

interface AppState {
  userDocumentList: [];
  userDocObj: UserDocumentModel;
  userID: string;
  userName: string;
  userdocID: string;
  redirectOut: boolean;
  redirectParam: string;
  redirectPath: string;
  isLoading: boolean;
  isViewFile: boolean;
  isDialogVisible: boolean;
  formHeader: string;
  action: string;
  documentTypeDropdownList: [];

  isError: boolean;
  errorMsg: string;

  dialogStr: string;
  dialogHeaderStr: string;
  visible: boolean;
  dialogIcon: string;
  dialogShowError: boolean;
}

export class UserDocumentModel {
  public userID: string = "";
  public userDocID: string = "";
  public documentTypeID: string = CommonMethods.EmptyGuid;
  public documentTypeName: string = "";
  public fileName: string = "";
  public uploadDate: string = "";
  public uploadPath: string = "";
  public uploadFile: string = "";
  public documentRemarks: string = "";
}

export class UserDocument extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  userdocumentService: UserDocumentService;
  commonMethods: CommonMethods;
  userService: UserService;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      userDocumentList: [],
      userDocObj: new UserDocumentModel(),
      userdocID: "",
      userName: "",
      redirectOut: false,
      redirectParam: "",
      redirectPath: "",
      isLoading: true,
      userID: "",
      isViewFile: false,
      isDialogVisible: false,
      formHeader: "",
      action: "",
      documentTypeDropdownList: [],
      isError: false,
      errorMsg: "",
      dialogStr: "",
      dialogHeaderStr: "",
      visible: false,
      dialogIcon: "",
      dialogShowError: false
    };
    this.actionButton = this.actionButton.bind(this);
    this.uploadDateTemplate = this.uploadDateTemplate.bind(this);
    this.userdocumentService = new UserDocumentService();
    this.commonMethods = new CommonMethods();
    this.userService = new UserService();
  }

  componentDidMount() {
    if (
      this.props.match.params !== {} &&
      this.props.match.params.id !== undefined
    ) {
      var userIDfromQuery = this.props.match.params.id;

      this.setState({
        userID: userIDfromQuery
      });
      this.getUserDocumentsFromDB(userIDfromQuery);
    } else {
      // error page
    }
  }

  getUserDocumentsFromDB(userIDfromQuery: string) {
    this.userdocumentService
      .getUserDocumentsByUser(userIDfromQuery)
      .then(res => {
        
        this.setState({
          userDocumentList: res
        });

        this.commonMethods.getFullDisplayNameFromProvidedUserID(userIDfromQuery).then(nameRes => {
          this.setState({
            isLoading: false,
            userName: nameRes
          });
        });
      })
      .catch(error => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg
        });
        return errorMsg;
      });
  }

  uploadDateTemplate(rowData: UserDocumentModel) {
    return this.commonMethods.displayDate(new Date(rowData.uploadDate))
  }

  actionButton(rowData: UserDocumentModel) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          className="p-button-primary mx-1 button-document"
          tooltip="View Document"
          tooltipOptions={{ position: "top" }}
          onClick={() => {
            this.openDocument(rowData);
          }}
        />

        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          tooltip="Edit Document Details"
          tooltipOptions={{ position: "top" }}
          onClick={() => {
            this.updateItem(rowData);
          }}
        />

        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          tooltip="Delete Document"
          tooltipOptions={{ position: "top" }}
          onClick={() => {
            this.deleteItem(rowData);
          }}
        />
      </div>
    );
  }

  openDocument(rowData: UserDocumentModel) {
    this.setState({
      // isViewFile: true,
      userDocObj: rowData
    });

    window.open(rowData.uploadPath, "_blank");
  }

  updateItem(rowData: UserDocumentModel) {
    this.setState({
      redirectOut: true,
      redirectParam: "?action=update",
      redirectPath: "/admin/employees/document_update/" + rowData.userDocID
    });
  }

  addItem() {
    this.setState({
      redirectOut: true,
      redirectParam: "?action=create",
      redirectPath: "/admin/employees/document_create/" + this.state.userID
    });
  }

  deleteItem(rowData: UserDocumentModel) {
    this.setState({
      isDialogVisible: true,
      userDocObj: rowData
    });
  }

  confirmDelete() {
    this.setState(
      {
        isLoading: true,
        isDialogVisible: false
      },
      () => {
        this.userdocumentService
          .deleteUserDocument(this.state.userDocObj)
          .then(res => {
            this.getUserDocumentsFromDB(this.state.userDocObj.userID);
            let msg = "File has been deleted.";
            this.context.SetSuccess(msg);
          })
          .catch(error => {
            let errorMsg = this.commonMethods.getErrorMessage(error);

            this.context.SetError(errorMsg);
          })
          .finally(() => {
            this.setState({
              isLoading: false
            });
          });
      }
    );
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectPath,
            search: this.state.redirectParam
          }}
          push
        />
      );  
    }

    let data = (
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          value={this.state.userDocumentList}
          emptyMessage="No results found."
          paginator={true}
          rows={15}
          autoLayout={true}
          alwaysShowPaginator={false}
        >
          <Column
            className="p-col-2"
            field="documentTypeName"
            header="Document Type"
          />
          <Column className="p-col-3" field="documentRemarks" header="Remarks" />
          <Column
            className="p-col-2"
            body={this.uploadDateTemplate}
            header="Date of Upload"
          />

          <Column className="p-col-2" body={this.actionButton} header="Action" />
        </DataTable>
      </div>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = data;
    }

    return (
      <div className="row">
        <div className="col-12 col-xl-10">
          <div className="card">
            <h1 className="pb-2">
              Documents of {this.state.userName}
              <span className="pl-3">
                <Button
                  type="button"
                  icon="pi pi-plus"
                  iconPos="right"
                  label="Add"
                  className="p-button-danger"
                  onClick={() => {
                    this.addItem();
                  }}
                ></Button>
              </span>
            </h1>
            {display}

            <Dialog
              className="popupDialog"
              header={"Delete " + this.state.userDocObj.documentTypeName}
              visible={this.state.isDialogVisible}
              modal={true}
              onHide={() => this.setState({ isDialogVisible: false })}
              footer={
                <div>
                  <Button
                    label="Yes"
                    icon="pi pi-check"
                    onClick={() => this.confirmDelete()}
                  />
                  <Button
                    label="No"
                    icon="pi pi-times"
                    onClick={() => this.setState({ isDialogVisible: false })}
                  />
                </div>
              }
            >
              Are you sure you want to delete this item?
            </Dialog>

            <Dialog
              className="popupDialog"
              header={"Viewing: " + this.state.userDocObj.documentTypeName}
              visible={this.state.isViewFile}
              modal={true}
              onHide={() => this.setState({ isViewFile: false })}
            >
              <img
                src={this.state.userDocObj.uploadPath}
                alt={this.state.userDocObj.uploadPath}
              />
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

export class UserDocumentCreate extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  userdocumentService: UserDocumentService;
  documentTypeService: DocumentTypeService;
  commonMethods: CommonMethods;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      userDocumentList: [],
      userDocObj: new UserDocumentModel(),
      userdocID: "",
      userName: "",
      redirectOut: false,
      redirectParam: "",
      redirectPath: "",
      isLoading: true,
      userID: "",
      isViewFile: false,
      isDialogVisible: false,
      formHeader: "Add User Document",
      action: "",
      documentTypeDropdownList: [],

      isError: false,
      errorMsg: "",
      dialogStr: "",
      dialogHeaderStr: "",
      visible: false,
      dialogIcon: "",
      dialogShowError: false
    };

    this.userdocumentService = new UserDocumentService();
    this.documentTypeService = new DocumentTypeService();
    this.submitForm = this.submitForm.bind(this);
    this.commonMethods = new CommonMethods();
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    var actionfromQuery = query.get("action");

    let idFromQuery = "";
    if (
      this.props.match.params !== {} &&
      this.props.match.params.id !== undefined
    ) {
      idFromQuery = this.props.match.params.id;
    }

    if (actionfromQuery === "create") {
      this.setState({
        formHeader: "Add User Document",
        userID: idFromQuery,
        action: "create"
      });
      this.updateProperty("userID", idFromQuery);
    } else if (actionfromQuery === "update") {
      this.setState({
        formHeader: "Update User Document",
        userdocID: idFromQuery,
        action: "update"
      });

      this.userdocumentService
        .getUserDocumentByDocId(idFromQuery)
        .then(res => {
          this.setState({ userDocObj: res, userID: res.userID });
        })
        .catch(error => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg
          });
          return errorMsg;
        });
    }

    // dropdown list
    this.documentTypeService
      .getDocumentTypeDropdown()
      .then(res => {
        this.setState({ documentTypeDropdownList: res, isLoading: false });
      })
      .catch(error => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg
        });
        return errorMsg;
      });
  }

  updateProperty(property: string, value: any) {
    let tempObj = this.state.userDocObj;
    (tempObj as any)[property] = value;
    this.setState({
      userDocObj: tempObj
    });
  }

  submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // to prevent page from refreshing!
    this.setState({ isLoading: true }, () => {
      if (this.state.action === "create") {
        this.userdocumentService
          .createUserDocument(this.state.userDocObj)
          .then(res => {
            let msg = "Document uploaded";
            this.context.SetSuccess(msg);
            this.goBack();
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
            let errorMsg = this.commonMethods.getErrorMessage(error);
            this.context.SetError(errorMsg);
          });
      } else if (this.state.action === "update") {
        this.userdocumentService
          .updateUserDocument(this.state.userDocObj)
          .then(res => {
            let msg = "Document details updated";
            this.context.SetSuccess(msg);
            this.goBack();
          })
          .catch(error => {
            let errorMsg = this.commonMethods.getErrorMessage(error);
            this.context.SetError(errorMsg);
            this.setState({
              isLoading: false
            });
          });
      }
    });
  }

  goBack() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/employees/document/" + this.state.userID
    });
  }

  uploadDocument(e: React.ChangeEvent<HTMLInputElement>) {
    if (
      e !== null &&
      e.currentTarget.files !== null &&
      e.currentTarget.files[0] !== undefined
    ) {
      this.updateProperty("uploadPath", e.currentTarget.files[0].name);
      this.updateProperty("uploadFile", e.currentTarget.files[0]);
    } else {
      this.updateProperty("uploadPath", "No file chosen");
      this.updateProperty("uploadFile", null);
    }
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectPath,
            search: this.state.redirectParam
          }}
          push
        />
      );
    }

    let uploadoption;
    if (this.state.action === "update") {
      let arr = this.state.userDocObj.uploadPath.split("/");

      uploadoption = (
        <>
          {" "}
          <input
            disabled={this.state.action === "update"}
            style={{ color: "transparent", width: "110px" }}
            type="file"
            name="uploadFile"
            onChange={e => {
              this.uploadDocument(e);
            }}  required
          />{" "}
          {arr[arr.length - 1]}
        </>
      );
    } else {
      uploadoption = (
        <>
          <input
            style={{ color: "transparent", width: "110px" }}
            type="file"
            name="uploadFile"
            onChange={e => {
              this.uploadDocument(e);
            }}  required
          />
          {this.state.userDocObj.uploadPath}
        </>
      );
    }

    let page = (
      <form onSubmit={this.submitForm}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">Document Type:</span>
            <Dropdown
              value={this.state.userDocObj.documentTypeID}
              options={this.state.documentTypeDropdownList}
              onChange={e =>
                this.setState({
                  userDocObj: {
                    ...this.state.userDocObj,
                    documentTypeID: e.value
                  }
                })
              }
              required
              placeholder="Please Select"
            />
          </div>
          {/* <div className="p-col-12">
      <span className="boldLabel">File Name:</span>
      <InputText
        id="fileName"
        onChange={e => {
          this.updateProperty("fileName", e.target.value);
        }}
        value={this.state.userDocObj.fileName}
      />
    </div> */}

          <div className="p-col-12">
            <p className="boldLabel">Upload File</p>
            {uploadoption}
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Remarks:</span>
            <InputText
              id="documentRemarks"
              onChange={e => {
                this.updateProperty("documentRemarks", e.currentTarget.value);
              }}
              value={this.state.userDocObj.documentRemarks}
            />
          </div>
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button
            type="submit"
            label={this.state.action === "create" ? "Submit" : "Update"}
          />
        </div>
      </form>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = page;
    }

    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{this.state.formHeader}</h1>
            {display}
          </div>
        </div>
      </div>
    );
  }
}
