import React, { useEffect, useState } from "react";
import {
  RouteComponentProps,
  DropdownModel,
  CommonMethods,
} from "../resources/CommonMethods";
import { AttendanceService } from "../../service/AttendanceService";
import { Dropdown } from "primereact/dropdown";
import { SafeEntryLogs } from "./SafeEntryLogs";
import { ProgressSpinner } from "primereact/progressspinner";
import CustomError from "../resources/Error";

export function SafeEntryDashboard(props: RouteComponentProps<any>) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selected, setSelected] = useState<string>("");

  const [urlDropdown, setUrlDropdown] = useState<DropdownModel[]>(

    new Array<DropdownModel>()
  );
  useEffect(() => {
    let attService = new AttendanceService();
    let commonMethods = new CommonMethods();
    attService
      .getEntryExitUrl()
      .then((res) => {
        setUrlDropdown(res);
        setIsLoading(false);
      })
      .catch((err) => {
        let errorMsg = commonMethods.getErrorMessage(err);
        setErrorMessage(errorMsg);
        setIsError(true);
      });
  }, []);

  let tableDetails = <SafeEntryLogs {...props} />;
  let linkDisplay = (
    <div className="row pt-2">
      <div className=" col-12 col-lg-6">
        <p>
          If all your companies are at the same location, select the
          GROUPCOMPANY option.
        </p>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">Location</span>{" "}
            <span className="requiredRed">*</span>
            <Dropdown
              required
              options={urlDropdown}
              placeholder="Please Select"
              value={selected}
              onChange={(e) => {
                setSelected(e.value);
              }}
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">URL</span>
            {selected && (
              <p>
                {" "}
                <a href={"/entryexit" + selected}>
                  {" "}
                  {window.location.origin}/entryexit
                  {selected}
                </a>
              </p>
            )}
          </div>
          <div className="p-col-12">
            <p>
              Print this URL as a QR code for visitors to scan when they
              enter/exit your location.
            </p>
          </div>
        </div>
      </div>{" "}
    </div>
  );

  if (isLoading) {
    linkDisplay = <ProgressSpinner />;
  }
  if (isError) {
    linkDisplay = <CustomError message={errorMessage} />;
  }
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <h1 className="pb-2">Entry/Exit URL</h1>

            {linkDisplay}
          </div>
        </div>
      </div>
      {tableDetails}
    </>
  );
}
