import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { ClientCompanyService } from "../../service/ClientCompanyService";
import { CommonMethods } from "../resources/CommonMethods";
import { ToastStateContext } from "../resources/ToastContext";

let clientCompanyService = new ClientCompanyService();
let commonMethods = new CommonMethods();

export class ClientMinimalModel {
  public contactName: string = "";
  public contactEmail: string = "";
  public clientCompany: string = "";
  public title: string = "";
}

function ClientCompanyAndContactCreate(props: any) {
  const [clientModel, setClientModel] = useState(new ClientMinimalModel());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { SetError } = React.useContext(ToastStateContext);
  const [emailError, setEmailError] = useState<string>("");

  const titleSelectItems = [
    { label: "Mr", value: "Mr" },
    { label: "Mrs", value: "Mrs" },
    { label: "Miss", value: "Miss" },
    { label: "Ms", value: "Ms" }
  ];

  useEffect(() => {
  
    setIsLoading(false);
  }, []);


  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    setEmailError("");

    if (!commonMethods.validateEmail(clientModel.contactEmail)) {
      setEmailError("p-error");
      setIsLoading(false);
      SetError("Invalid email");
      return;
    } else {
      //Create
      clientCompanyService
        .createCompanyandClient(clientModel)
        .then((res) => {
          props.others.clientCallback(res.clientCompanyID);

          // clear model
          setClientModel(new ClientMinimalModel())
        })
        .catch(() => {
          props.others.clientCallback(null);
        });
      setIsLoading(false);
    }
  };

  const form = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">
              Client/Agency Company Name <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={clientModel.clientCompany}
              onChange={event =>
                setClientModel({
                  ...clientModel,
                  clientCompany: event.currentTarget.value
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Client/Agency Contact Name</span>
          </div>
          <div className="p-col-3">
            <Dropdown
              value={clientModel.title}
              options={titleSelectItems}
              placeholder="Please Select"
              onChange={e =>
                setClientModel({
                  ...clientModel,
                  title: e.value
                })
              }
            />
          </div>
          <div className="p-col-9">
            <InputText
              value={clientModel.contactName}
              placeholder="Name"
              onChange={event =>
                setClientModel({
                  ...clientModel,
                  contactName: event.currentTarget.value
                })
              }
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Email Address</span>
            <div hidden={emailError === "" ? true : false} className="my-1">
              <Message severity="error" text="Invalid Input" />
            </div>
            <InputText
              value={clientModel.contactEmail}
              onChange={event =>
                setClientModel({
                  ...clientModel,
                  contactEmail: event.currentTarget.value
                })
              }
            />
          </div>
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button type="submit" label="Create" />
        </div>
      </form>
    );
  };

  if (isLoading) {
    return <ProgressSpinner />;
  } else {
    return (
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="card">
            <h1 className="pb-2">Create Client/Agency</h1>
            {form()}
          </div>
        </div>
      </div>
    );
  }
}

export default ClientCompanyAndContactCreate;
