import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";

function HeaderCards(props: any) {
  const [clickableCard, setClickableCard] = useState("card");
  const [redirectPath, setRedirectPath] = useState("");
  const [redirectOut, setRedirectOut] = useState<boolean>(false);

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    if (
      props.redirect !== undefined &&
      props.redirect !== null &&
      props.redirect !== ""
    ) {
      if (myAbortController.signal.aborted) {
        return;
      }
      setClickableCard("card pointer");
      setRedirectPath(props.redirect);
    }

    return () => {
      myAbortController.abort();
    };
  }, []);

  return (
    <div
      className={clickableCard}
      onClick={() => {
        redirectPath !== "" && setRedirectOut(true);
      }}
      hidden={props.hidden}
    >
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      <div
        className="row text-center"
        data-tour-id={props.dataTourId}
        data-tour={props.dataTour}
      >
        {/* <div className="col-3 col-sm-3 d-flex align-items-center justify-content-center">
          <img src={props.imageUrl} alt="" />
        </div> */}
        {props.title ? (
          <>
            <div className="col-8 p-0 d-flex align-items-center justify-content-center">
              <span className="dashboardFont mr-3">{props.title} </span>
              <span>{props.content}</span>
            </div>

            {/* <div className="col-3 d-flex align-items-center justify-content-center"> */}
            <div className="col-4 p-0  d-flex align-items-center justify-content-center">
              <span className="dashboardFont">{props.number}</span>
            </div>
          </>
        ) : (
          <div className="col d-flex align-items-center justify-content-centerp-0">
            <span className="dashboardFont">{props.content}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeaderCards;
