import { HttpClient } from "./HttpClient";
export class ForgetPasswordService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

  async postEmail(data:any) {
    const res = await this.httpClient.post("/api/forgetpassword/forgetpassword", data);
    return res;
  }

  async validateLink(token: string) {
    return await this.httpClient.get("/api/forgetpassword/valid/" + token);
  }

  async newPassword(data:any, token:string) {
    return await this.httpClient.post("/api/forgetpassword/changepw/" + token, data);
  }
}
