import * as React from "react";
import { AccountManagementService } from "../../service/AccountManagementService";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Finance_AccountsModel } from "../resources/ExportClass";
import { CompanyService } from "../../service/CompanyService";
import Axios from "axios";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { CompanyObj } from "../company/Company";
import ReactTooltip from "react-tooltip";

interface AppState {
  isFetching: boolean;
  isEdited: boolean;

  companyEditing: boolean;
  bankEditing: boolean;

  companyDetails: CompanyObj;
  companyBankDetails: Finance_AccountsModel;

  companyDetailsInitial: CompanyObj;
  companyBankDetailsIntial: Finance_AccountsModel;

  swiftList: { label: string; value: string }[];

  isError: boolean;
  errorMsg: string;
}

export class CompanyDetails extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  thisDate: Date = new Date();
  financeSvc: AccountManagementService;
  companySvc: CompanyService;
  commonMethods: CommonMethods;
  industryList: { label: string; value: string }[];

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      isFetching: true,
      isEdited: false,

      companyEditing: true,
      bankEditing: true,

      companyDetails: new CompanyObj(),
      companyBankDetails: new Finance_AccountsModel(),

      companyDetailsInitial: new CompanyObj(),
      companyBankDetailsIntial: new Finance_AccountsModel(),
      swiftList: [],
      isError: false,
      errorMsg: "",
    };

    // SERVICE SET
    this.financeSvc = new AccountManagementService();
    this.companySvc = new CompanyService();
    this.commonMethods = new CommonMethods();
    // BINDING
    this.retrieveInformation = this.retrieveInformation.bind(this);
    this.saveInformation = this.saveInformation.bind(this);
    this.saveBankInfo = this.saveBankInfo.bind(this);
    this.industryList = this.commonMethods.getIndustryDropdownOptions();
  }

  componentDidMount() {
    if (this.props.others !== undefined && this.props.others === "onboarding") {
      this.commonMethods
        .getCompanyIDFromUserID()
        .then((res) => {
          this.retrieveInformation(res);
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.setState({
            isError: true,
            isFetching: false,
            errorMsg: errorMsg,
          });
          return errorMsg;
        });
    } else {
      let currentAccessingCompanyId =
        localStorage.getItem("currentAccessingCompany") || "";
      this.retrieveInformation(currentAccessingCompanyId);
    }
  }

  // API CALL
  retrieveInformation(companyID: string) {
    Axios.all([
      this.companySvc.getCompanyById(companyID),
      this.financeSvc.getCurrentCompanyBankAccount(companyID),
      this.commonMethods.getBankCodeDropdown(),
    ])
      .then(
        Axios.spread((first, second, third) => {
          if (typeof second === "object") {
            this.setState({
              companyDetails: first,
              companyDetailsInitial: first,
              companyBankDetails: second,
              companyBankDetailsIntial: second,
              swiftList: third,
              isFetching: false,
            });
          } else {
            this.setState({
              companyDetails: first,
              companyDetailsInitial: first,
              companyBankDetails: {
                ...this.state.companyBankDetails,
                companyID: first.companyID,
                accountStatus: "Active",
              },
              companyBankDetailsIntial: {
                ...this.state.companyBankDetails,
                companyID: first.companyID,
                accountStatus: "Active",
              },
              isFetching: false,
            });
          }
        })
      )
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isFetching: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  saveBankInfo(event: React.FormEvent) {
    event.preventDefault();
    this.setState({ isFetching: true }, () => {
      if (this.state.bankEditing === false) {
        let model = this.state.companyBankDetails;
        if (model.companyFinanceID === "") {
          delete model.companyFinanceID;
        }
        if (model.bankCode !== "") {
          var index = this.state.swiftList.findIndex(
            (obj) => obj.value === model.bankCode
          );
          model.bankName = this.state.swiftList[index].label;
        }
        delete model.updatedByID;
        this.financeSvc
          .updateBankAccount(model)
          .then((res) => {
            let msg = "Bank details has been updated";
            this.context.SetSuccess(msg);

            this.setState({
              isFetching: false,
              bankEditing: true,
              companyBankDetailsIntial: res,
            });
          })
          .catch((error) => {
            let errorMsg = this.commonMethods.getErrorMessage(error);

            this.setState({ isFetching: false });
            this.context.SetError(errorMsg);
            return errorMsg;
          });
      }
    });
  }

  saveInformation(event: React.FormEvent) {
    event.preventDefault();
    this.setState({ isFetching: true }, () => {
      if (this.state.companyEditing === false) {
        let model = this.state.companyDetails;
        this.companySvc
          .updateCompanyFinance(model)
          .then((res) => {
            let msg = "Details has been updated";
            this.context.SetSuccess(msg);

            this.setState({
              isFetching: false,
              companyEditing: true,
              companyDetailsInitial: res,
            });
          })
          .catch((error) => {
            let errorMsg = this.commonMethods.getErrorMessage(error);

            this.setState({ isFetching: false });
            this.context.SetError(errorMsg);
            return errorMsg;
          });
      }
    });
  }

  render() {
    const { companyEditing, bankEditing } = this.state;

    let displaycompanydetails = (
      <>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">
              {" "}
              Company Registration No. <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              id="companyRegNum"
              value={this.state.companyDetails.companyRegNum}
              disabled={companyEditing}
              onChange={(e: any) => {
                this.setState({
                  companyDetails: {
                    ...this.state.companyDetails,
                    companyRegNum: e.target.value,
                  },
                });
              }}
              maxLength={12}
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Pay Day Date <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              id="payDayDate"
              value={this.state.companyDetails.payDayDate}
              disabled={companyEditing}
              onChange={(e: any) => {
                this.setState({
                  companyDetails: {
                    ...this.state.companyDetails,
                    payDayDate: e.target.value,
                  },
                });
              }}
              required
            />
          </div>
        </div>
        <h5 className="pt-4">Statutory Information</h5>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">CPF Submission No. </span>
            <>
              <span data-tip="" data-for="CSNinfo">
                <a href="https://www.ifaq.gov.sg/uen/apps/fcd_faqmain.aspx?FAQ=32188">
                  <i className="fas fa-info-circle informationStyle"></i>
                </a>
              </span>
              <ReactTooltip
                id="CSNinfo"
                aria-haspopup="true"
                
                className="tooltipFont"
              >
                <p className="text-center mb-0">
                  The number you use to transact with CPF Board
                  <br />
                  Click for more info.{" "}
                </p>
              </ReactTooltip>
            </>
            <span style={{ color: "red" }}> *</span>
            <InputText
              id="cpfSubmissionNo"
              value={this.state.companyDetails.cpfSubmissionNo}
              disabled={companyEditing}
              onChange={(e: any) => {
                this.setState({
                  companyDetails: {
                    ...this.state.companyDetails,
                    cpfSubmissionNo: e.target.value,
                  },
                });
              }}
              placeholder="i.e. xxxxxxxxxx-PTE-01"
              required
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">IRAS Tax Reference No. </span>
            <>
              <span data-tip="" data-for="IRASinfo">
                <a href="https://www.ifaq.gov.sg/uen/apps/fcd_faqmain.aspx?FAQ=32369">
                  <i className="fas fa-info-circle informationStyle"></i>
                </a>
              </span>
              <ReactTooltip
                id="IRASinfo"
                aria-haspopup="true"
                
                className="tooltipFont"
              >
                <p className="text-center mb-0">
                  Please use the UEN as the Tax Reference Number.
                  <br /> If you do not have a UEN, please continue to use the
                  <br /> Tax Reference Number (eg A1234567Z) issued by IRAS.
                  <br />
                  Click for more info.
                </p>
              </ReactTooltip>
            </>
            <span style={{ color: "red" }}> *</span>
            <InputText
              id="irasTaxNo"
              value={this.state.companyDetails.irasTaxNo}
              disabled={companyEditing}
              onChange={(e: any) => {
                this.setState({
                  companyDetails: {
                    ...this.state.companyDetails,
                    irasTaxNo: e.target.value,
                  },
                });
              }}
              required
            />
          </div>
        </div>
      </>
    );

    let displaybankinfo = (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <span className="boldLabel">Bank</span>
          <Dropdown
            value={this.state.companyBankDetails.bankCode}
            options={this.state.swiftList}
            onChange={(e) => {
              this.setState({
                companyBankDetails: {
                  ...this.state.companyBankDetails,
                  bankCode: e.value,
                  bankName: this.state.swiftList[
                    this.state.swiftList.findIndex(
                      (obj) => obj.value === e.value
                    )
                  ].label,
                },
              });
            }}
            disabled={bankEditing}
            placeholder="Please Select"
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Account Name</span>
          <InputText
            id="accountHolderName"
            value={this.state.companyBankDetails.accountHolderName}
            disabled={bankEditing}
            onChange={(e: any) => {
              this.setState({
                companyBankDetails: {
                  ...this.state.companyBankDetails,
                  accountHolderName: e.target.value,
                },
              });
            }}
            maxLength={35}
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Account Type</span>
          <InputText
            id="bankName"
            value={this.state.companyBankDetails.accountType}
            disabled={bankEditing}
            onChange={(e: any) => {
              this.setState({
                companyBankDetails: {
                  ...this.state.companyBankDetails,
                  accountType: e.target.value,
                },
              });
            }}
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Bank Code</span>
          <InputText
            id="branchCode"
            value={this.state.companyBankDetails.bankCode}
            disabled={bankEditing}
            onChange={(e: any) => {
              this.setState({
                companyBankDetails: {
                  ...this.state.companyBankDetails,
                  bankCode: e.target.value,
                },
              });
            }}
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Account No.</span>
          <InputText
            id="accountNumber"
            value={this.state.companyBankDetails.accountNumber}
            disabled={bankEditing}
            onChange={(e: any) => {
              this.setState({
                companyBankDetails: {
                  ...this.state.companyBankDetails,
                  accountNumber: e.target.value,
                },
              });
            }}
          />
        </div>
      </div>
    );

    let page = (
      <>
        <form onSubmit={this.saveInformation}>
          <div className="card">
            <h1 className="pb-2">Company Finance Details</h1>
            <p>These are financial details required for our Payroll and Payslip Module.</p>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            <h5>
              Company Information
              <Button
                label="Edit"
                className="editButtonFloatRight"
                onClick={(event) => {
                  event.preventDefault();
                  this.setState({ companyEditing: false });
                }}
              />
            </h5>
            <div className="row p-justify-center">
              <div className="col-12 ">
                {displaycompanydetails}

                <div className="p-grid p-justify-center mt-2">
                  {!this.state.companyEditing ? (
                    <>
                      <Button
                        label="Save"
                        className="mr-2"
                        // onClick={this.saveInformation}
                      />
                      <Button
                        label="Cancel"
                        type=""
                        onClick={(event) => {
                          event.preventDefault();
                          this.setState({
                            companyEditing: true,

                            companyDetails: this.state.companyDetailsInitial,
                          });
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <h1 className="pb-2"> Company Bank Details</h1>
            <p>These are financial details required for our Payroll and Claims Module.</p>

            <h5 className="">
              Bank Information
              <Button
                label="Edit"
                type=""
                className="editButtonFloatRight"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ bankEditing: false });
                }}
              />
            </h5>
            <div className="row p-justify-center">
              <div className="col-12">
                {displaybankinfo}
                <div className="p-grid p-justify-center mt-2">
                  {!this.state.bankEditing ? (
                    <>
                      <Button
                        label={"Save"}
                        type="submit"
                        className="mr-2"
                        onClick={this.saveBankInfo}
                      />
                      <Button
                        label="Cancel"
                        type=""
                        onClick={(event) => {
                          event.preventDefault();
                          this.setState({
                            bankEditing: true,

                            companyBankDetails: this.state
                              .companyBankDetailsIntial,
                          });
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />; // or some growl/message that stays
    } else if (this.state.isFetching) {
      display = <ProgressSpinner />;
    } else {
      display = page;
    }

    return (
      <>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">{display}</div>
        </div>
      </>
    );
  }
}
