import { CommonMethods } from "../resources/CommonMethods";

export class RoomModel {
    public roomID: string = CommonMethods.EmptyGuid;
    public roomName: string = "";
    public roomDescription: string = "";
    public roomStatus: string = "";
    public roomOrder: number = 0;
  
    public createdTime: Date = new Date(); //needed?
}