import React, { useState } from "react";
import ViewClaims from "./ViewClaims";
import { ClaimViewType } from "./ClaimsModel";

export function EndorsingClaimsPage(props: any) {
  const [updateComponent, setUpdateComponent] = useState<boolean>(false);

  const callbackfunc = (refresh: boolean) => {
    setUpdateComponent(refresh);
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-10">
          <div
            className="card"
            data-tour-id="endorse-claim"
            data-tour="These are the claims waiting to be endorsed. View the claim in detail to approve or reject it."
          >
            <h1>Endorse Claims</h1>
            <ViewClaims
              others={ClaimViewType.Endorsing}
              callbackfunc={callbackfunc}
              {...props}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xl-10">
          <div
            className="card"
            data-tour-id="endorsed-claims"
            data-tour="These are the claims you've endorsed in the past. Use the buttons on the right to view claim details and submitted documents."
          >
            <h1>Endorsed History</h1>
            {updateComponent && (
              <ViewClaims others={ClaimViewType.EndorsingHistory} {...props} />
            )}
            {!updateComponent && (
              <ViewClaims others={ClaimViewType.EndorsingHistory} {...props} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
