import { HttpClient } from "./HttpClient";
export class JobTitleService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getJobTitles(companyid: string) {
    const res = await this.httpClient.get("/api/jobtitle/view/" + companyid);
    return res;
  }

  async getJobTitleById(id: string) {
    const res = await this.httpClient.get("/api/jobtitle/" + id);
    return res;
  }

  async createJobTitle(jobtitleObj: any) {
    const res = await this.httpClient.post("/api/jobtitle/create", jobtitleObj);
    return res;
  }

  async updateJobTitle(jobtitleObj: any) {
    const res = await this.httpClient.put("/api/jobtitle/update", jobtitleObj);
    return res;
  }

  async deleteJobtitle(jobtitleObj: any) {
    const res = await this.httpClient.delete(
      "/api/jobtitle/" + jobtitleObj.jobTitleID
    );
    return res;
  }
}
