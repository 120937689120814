import { HttpClient } from "./HttpClient";
import { convertObjToFormData } from "../components/resources/CommonMethodsServices";
export class AnnouncementService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async getUnreadAnnouncement() {
    const res = await this.httpClient.get("/api/announcement/unread");
    return res;
  }

  async getAllAnnouncement() {
    const res = await this.httpClient.get("/api/announcement/all");
    return res;
  }

  async getAllAnnouncementWithDocument() {
    const res = await this.httpClient.get("/api/announcement/document");
    return res;
  }

  async getAnnouncementById(announcementID: string) {
    const res = await this.httpClient.get(
      "/api/announcement/" + announcementID
    );
    return res;
  }

  async getReadListById(announcementID: string) {
    const res = await this.httpClient.get(
      "/api/announcement/readby/" + announcementID
    );
    return res;
  }
  async createAnnouncement(announcementObj: any) {
    const res = await this.httpClient.post(
      "/api/announcement/create",
      convertObjToFormData(announcementObj)
    );
    return res;
  }

  async updateAnnouncement(announcementObj: any) {
    const res = await this.httpClient.put(
      "/api/announcement/update",
      convertObjToFormData(announcementObj)
    );
    return res;
  }

  async deleteAnnouncement(id: string) {
    const res = await this.httpClient.delete("/api/announcement/" + id);
    return res;
  }

  async acknowledgeMsg(announcementID: string) {
    const res = await this.httpClient.get(
      `/api/announcement/acknowledge/${announcementID}`
    );
    return res;
  }
}
