import { HttpClient } from "./HttpClient";
export class TaskService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  // TaskSetting.tsx -> Retrieve from TaskModuleTable
  async getAllTaskModule() {
    const res = await this.httpClient.get("/api/task/getallmoduleaccess");
    return res;
  }

  // TaskSetting.tsx -> Retrieve all Distinct Task Access from TaskTable
  async getAllTaskAccess() {
    const res = await this.httpClient.get("/api/task/getalltask");
    return res;
  }

  // RoleTaskSetting.tsx
  async getAllPermittedTaskAccess(groupcompanyid: string) {
    const res = await this.httpClient.get(
      "/api/task/getalltaskbyplan/" + groupcompanyid
    );
    return res;
  }

  // TaskSetting.tsx -> Insert into TaskModuleTable
  async insertTaskModule(obj: any) {
    const res = await this.httpClient.post("/api/task/newassignment", obj);
    return res;
  }
  async deleteTaskModule(task: any) {
    const res = await this.httpClient.post("/api/task/delete", task);
    return res;
  }

  // Tasksetting.tsx - edit
  async updateTaskModule(obj: any) {
    const res = await this.httpClient.put("/api/task/update", obj);
    return res;
  }
}
