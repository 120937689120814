import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useRef, useState } from "react";
import "react-image-lightbox/style.css";
import { Redirect } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { AccountManagementService } from "../../service/AccountManagementService";
import { ClaimsService } from "../../service/ClaimsService";
import { CommonMethods, DropdownModel } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { CompanyDisplay } from "../resources/ExportClass";
import { ToastStateContext } from "../resources/ToastContext";
import { TaskList } from "../resources/ListOfTasks";
import {
  ClaimsDisplayModel, ClaimStatuses,
  ClaimViewType
} from "./ClaimsModel";

function ViewClaims(props: any) {
  const [claims, setClaims] = useState<Array<ClaimsDisplayModel>>(
    new Array<ClaimsDisplayModel>()
  );
  const [permClaims, setPermClaims] = useState<Array<ClaimsDisplayModel>>(
    new Array<ClaimsDisplayModel>()
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [globalFilter, setGlobalFilter] = useState<string>();
  const [claimTypeFilter, setClaimTypeFilter] = useState<DropdownModel[]>(
    new Array<DropdownModel>()
  );
  const [selectedClaims, setSelectedClaims] = useState<any[]>([]);
  const [startDateFilter, setStartDateFilter] = useState<Date>();
  const [endDateFilter, setEndDateFilter] = useState<Date>();
  const [bankDetails, setBankDetails] = useState<CompanyDisplay>(
    new CompanyDisplay()
  );
  // const [bulkExportData, setBulkExportData] = useState<any[]>([]);
  const claimsService = new ClaimsService();
  const [claimStatusDropdown, setClaimStatusDropdown] = useState<
    Array<DropdownModel>
  >([]);

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  let commonMethods = new CommonMethods();
  const [viewMyOwn, setViewMyOwn] = useState(false);

  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  // const [fileArray] = useState([]);
  // const [photoIndex, setPhotoIndex] = useState(0);
  // const [isOpen, setIsOpen] = useState(false);

  const [exportFileName, setExportFileName] = useState<string>(
    "Claims-" + commonMethods.displayDate_FileName(new Date())
  );

  const claimView: ClaimViewType = props.others;
  const [selectedClaimType, setSelectedClaimType] = useState(null);
  const [selectedClaimStatus, setSelectedClaimStatus] = useState(null);
  const [tooltipFilterString, setTooltipFilterString] = useState<string>("");

  let dtExport: DataTable | null = null;
  const dt = useRef<any>(null);

  useEffect(() => {
    loadPage();
  }, []);

  useEffect(() => {
    if (props.updateComponent !== undefined && props.updateComponent !== null) {
      loadPage();
    }
  }, [props]);

  const loadPage = () => {
    getClaimsData();
    getClaimTypesFilter();
    getClaimStatusesDropdownList();

    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";

    if (
      currentAccessingCompanyId !== "" &&
      commonMethods.isArrayPresentInArray(props.userProfile.taskList, [
        TaskList.payrollaccess,
      ])
    ) {
      getAllAccounts("company", currentAccessingCompanyId);
    }
  };

  const getAllAccounts = (type: string, id: string) => {
    new AccountManagementService()
      .getAllAccounts(type, id)
      .then((res) => {
        setBankDetails(res[0]);
      })
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));

        setIsError(true);
        setIsLoading(false);
      });
  };

  const getClaimTypesFilter = () => {
    commonMethods.getCostTypeDropdownFilter().then((res) => {
      setClaimTypeFilter(res);
    })
    .catch((err) => {
      setErrorMessage(commonMethods.getErrorMessage(err));
    });
  };

  const getClaimsData = () => {
    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";

    try {
      if (claimView === ClaimViewType.Approving) {
        formatExportFileName("Endorsed");
        claimsService
          .getClaimsAsApprover(currentAccessingCompanyId)
          .then((res) => {
            setClaims(res);
            setPermClaims(res);
            setTooltipFilterString("Filter by Endorsed Date");
            setIsLoading(false);
          });
      } else if (claimView === ClaimViewType.MyPending) {
        claimsService.viewMyPendingClaims().then((res) => {
          setClaims(res);
          setPermClaims(res);
          setTooltipFilterString("Filter by Submitted Date");
          setIsLoading(false);
          setViewMyOwn(true);
        });
      } else if (claimView === ClaimViewType.MyHistory) {
        claimsService.viewMyNotPendingClaims().then((res) => {
          setViewMyOwn(true);
          setClaims(res);
          setPermClaims(res);
          setTooltipFilterString("Filter by Updated Date");
          setIsLoading(false);
        });
      } else if (claimView === ClaimViewType.Endorsing) {
        claimsService.getClaimsAsEndorser().then((res) => {
          setClaims(res);
          setPermClaims(res);
          setTooltipFilterString("Filter by Submitted Date");
          setIsLoading(false);
        });
      } else if (claimView === ClaimViewType.EndorsingHistory) {
        claimsService.endorsedHistory().then((res) => {
          setClaims(res);
          setPermClaims(res);
          setTooltipFilterString("Havent do yet kek");
          setIsLoading(false);
        });
      } else if (claimView === ClaimViewType.ApprovingHistory) {
        formatExportFileName("Approved");
        claimsService
          .approvingHistory(currentAccessingCompanyId)
          .then((res) => {
            setClaims(res);
            setPermClaims(res);
            setTooltipFilterString("Filter by Approved Date");
            setIsLoading(false);
          });
      } else if (claimView === ClaimViewType.AllHistory) {
        formatExportFileName("All History");
        claimsService
          .getAllClaimsHistoryAsAdmin(currentAccessingCompanyId)
          .then((res) => {
            setClaims(res);
            setPermClaims(res);
            setTooltipFilterString("Filter by Updated Date");
            setIsLoading(false);
          });
      }
    } catch (err) {
      setErrorMessage(commonMethods.getErrorMessage(err));
      setIsError(true);
      setIsLoading(false);
    }
  };

  const formatExportFileName = (type: string) => {
    setExportFileName(
      "Claims_" +
        type +
        "_" +
        bankDetails.companyName.replace(/ /g, "") +
        commonMethods.displayDate_FileName(new Date())
    );
  };

  const getClaimStatusesDropdownList = () => {
    commonMethods
      .getClaimStatusDropdown()
      .then((res) => {
        setClaimStatusDropdown(res);
      })
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
      });
  };
  const editTemplate = (rowData: ClaimsDisplayModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Edit Claim Details"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning mr-1"
          onClick={() => {
            setRedirectOut(true);
            setRedirectPath("/claims/adminedit/" + rowData.claimID);
            // window.open("/claims/adminedit/" + rowData.claimID);
          }}
        />
      </div>
    );
  };
  const actionTemplate = (rowData: ClaimsDisplayModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="View Claim Details"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-eye"
          className="p-button-primary mr-1"
          onClick={() => {
            window.open("/claims/view/" + rowData.claimID, "_blank");
          }}
        />
      </div>
    );
  };

  const statusTemplate = (rowData: ClaimsDisplayModel) => {
    if (rowData.claimStatus === ClaimStatuses.Rejected) {
      return (
        <div className="text-danger boldLabel" data-tip="" data-for="rejected">
          <ReactTooltip place="top" id="rejected">
            Rejected by Supervisor/Finance.
          </ReactTooltip>
          {rowData.claimStatus}
        </div>
      );
    } else if (rowData.claimStatus === ClaimStatuses.Endorsed) {
      return (
        <div className="text-primary boldLabel" data-tip="" data-for="endorsed">
          <ReactTooltip place="top" id="endorsed">
            Endorsed by Supervisor, awaiting approval by Finance.
          </ReactTooltip>
          {rowData.claimStatus}
        </div>
      );
    } else if (rowData.claimStatus === ClaimStatuses.Approved) {
      return (
        <div className="text-success boldLabel" data-tip="" data-for="approved">
          <ReactTooltip place="top" id="approved">
            Approved by Finance, awaiting processing of payment.
          </ReactTooltip>
          {rowData.claimStatus}
        </div>
      );
    } else if (rowData.claimStatus === ClaimStatuses.Processed) {
      return (
        <div
          className="text-success boldLabel"
          data-tip=""
          data-for="processed"
        >
          <ReactTooltip place="top" id="processed">
            Processed by Finance, expect payment within 3-4 working days.
          </ReactTooltip>
          {rowData.claimStatus}
        </div>
      );
    } else if (rowData.claimStatus === ClaimStatuses.Pending) {
      return (
        <div className="text-info boldLabel" data-tip="" data-for="pending">
          <ReactTooltip place="top" id="pending">
            Pending endorsement by Supervisor.
          </ReactTooltip>
          {rowData.claimStatus}
        </div>
      );
    } else {
      return <div className="text-info boldLabel">{rowData.claimStatus}</div>;
    }
  };

  const formatDateDisplay = (date: Date) => {
    return commonMethods.displayDateTime(date);
  };

  const approvedDateTemplate = (rowData: ClaimsDisplayModel) => {
    if (
      claimView === ClaimViewType.MyPending ||
      claimView === ClaimViewType.Endorsing
    ) {
      return formatDateDisplay(rowData.createdDate);
    } else if (claimView === ClaimViewType.EndorsingHistory) {
      switch (rowData.claimStatus) {
        case ClaimStatuses.Endorsed:
          return formatDateDisplay(rowData.endorsedDate);
        case ClaimStatuses.Approved:
          return formatDateDisplay(rowData.approvedDate);
        case ClaimStatuses.Processed:
          return formatDateDisplay(rowData.processedDate);
        default:
          return formatDateDisplay(rowData.createdDate);
      }
    } else if (claimView === ClaimViewType.Approving) {
      return formatDateDisplay(rowData.endorsedDate);
    } else if (claimView === ClaimViewType.ApprovingHistory) {
      return formatDateDisplay(rowData.approvedDate);
    } else if (
      claimView === ClaimViewType.AllHistory ||
      claimView === ClaimViewType.MyHistory
    ) {
      return formatDateDisplay(rowData.lastUpdatedDate);
    } else {
      // shouldnt be here
      switch (rowData.claimStatus) {
        case ClaimStatuses.Cancelled:
          return formatDateDisplay(rowData.cancelledDate);
        case ClaimStatuses.Endorsed:
          return formatDateDisplay(rowData.endorsedDate);
        case ClaimStatuses.Approved:
          return formatDateDisplay(rowData.approvedDate);
        case ClaimStatuses.Processed:
          return formatDateDisplay(rowData.processedDate);
        default:
          return formatDateDisplay(rowData.createdDate);
      }
    }
  };

  const dateHeaderTemplate = () => {
    // My Claims History --> notPending ==> all type of dates
    // My Pending Claims [User applied] --> pending ==> createdDate only
    // Endorse Claims [supervisor received] --> endorsing ==> creadtedDate only
    // Endorsed History --> endorseHistory ==> endorsedDate, approvedDate, processedDate
    // Approve Claims --> approving ==> endorsedDate
    // Pending Processing --> approveHistory ==> approvedDate
    // All Claims History --> admin ==> creadtedDate

    if (
      claimView === ClaimViewType.MyPending ||
      claimView === ClaimViewType.Endorsing
    ) {
      return "Submitted On";
    } else if (
      claimView === ClaimViewType.EndorsingHistory ||
      claimView === ClaimViewType.Approving
    ) {
      return "Endorsed On";
    } else if (claimView === ClaimViewType.ApprovingHistory) {
      return "Approved On";
    } else if (
      claimView === ClaimViewType.AllHistory ||
      claimView === ClaimViewType.MyHistory
    ) {
      return "Updated On";
    }
  };

  const amountTemplate = (rowData: ClaimsDisplayModel) => {
    return (
      <>
        {rowData.currency +
          " " +
          (rowData.amount - rowData.taxAmount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
      </>
    );
  };

  const totalTemplate = (rowData: ClaimsDisplayModel) => {
    return (
      <>
        {rowData.currency +
          " " +
          rowData.amount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
      </>
    );
  };

  const taxAmountTemplate = (rowData: ClaimsDisplayModel) => {
    return (
      <>
        {rowData.currency +
          " " +
          rowData.taxAmount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
      </>
    );
  };

  const filterByDate = () => {
    // we use permClaims here because we want the full list. After some filters here and there, claims would have been set a few times and would not contain the full list.
    if (permClaims.length > 0) {
      let filteredClaims = permClaims.filter((claim: any) => {

        let dateToFilter = new Date();
        if ( claimView === ClaimViewType.MyPending || claimView === ClaimViewType.Endorsing ) {
          dateToFilter = claim.createdDate;
        } else if ( claimView === ClaimViewType.Approving ) {
          dateToFilter = claim.endorsedDate;
        } else if ( claimView === ClaimViewType.ApprovingHistory ) {
          dateToFilter = claim.approvedDate;
        } else if ( claimView === ClaimViewType.AllHistory || claimView === ClaimViewType.MyHistory ) {
          dateToFilter = claim.lastUpdatedDate;
        } else if ( claimView === ClaimViewType.EndorsingHistory ){
        }

        if (
          moment(dateToFilter).isBetween(
            moment(startDateFilter),
            moment(endDateFilter),
            "day",
            "[]"
          )
        ) {
          return claim;
        }
      });
      setClaims(filteredClaims);
    }
  };

  const updateClaimsAsProcessed = () => {
    let claimsToProcess: string[] = [];
    selectedClaims.forEach((element) => {
      claimsToProcess.push(element.claimID);
    });
    claimsService
      .batchProcessClaims(claimsToProcess)
      .then((res) => {
        SetSuccess(`Successfully processed ${claimsToProcess.length} claims`);
        if (
          props.callbackfunc !== undefined &&
          props.callbackfunc !== null &&
          props.callbackBool !== undefined
        ) {
          props.callbackfunc(!props.callbackBool);
        }
      })
      .catch((err) => {
        SetError(`Error processing ${claimsToProcess.length} claims`);
      });
  };

  const globalSearchBar = (
    <span className="p-input-icon-left mb-1 mr-1">
      <i className="pi pi-search" />
      <InputText
        placeholder="Global Search"
        onInput={(e) => setGlobalFilter(e.currentTarget.value)}
      />
    </span>
  );

  const claimTypeFilterBar = (
    <Dropdown
      showClear
      className="mb-1 mr-1"
      placeholder="Filter by Claim Type"
      value={selectedClaimType}
      options={claimTypeFilter}
      onChange={(e) => {
        setSelectedClaimType(e.value);
        onClaimTypeFilterChange(e);
      }}
    />
  );

  const onClaimTypeFilterChange = ((e: any) => {
    dt.current.filter(e.value, 'costTypeName', 'equals');
  });

  const claimStatusFilterBar = (
    <Dropdown
      showClear
      className="mb-1"
      placeholder="Filter by Claim Status"
      value={selectedClaimStatus}
      options={claimStatusDropdown}
      onChange={(e) => {
        setSelectedClaimStatus(e.value);
        onClaimStatusFilterChange(e);
      }}
    />
  );

  const onClaimStatusFilterChange = ((e:any) => {
    dt.current.filter(e.value, 'claimStatus', 'equals');
  });

  const dateStartFilter = (
    <Calendar
      className="mb-1 mr-1"
      placeholder="From Date"
      value={startDateFilter}
      dateFormat="dd/mm/yy"
      onChange={(e) => {
        e.value !== null && setStartDateFilter(new Date(e.value.toString()));
      }}
    />
  );

  const dateEndFilter = (
    <Calendar
      className="mb-1 mr-1"
      placeholder="To Date"
      value={endDateFilter}
      dateFormat="dd/mm/yy"
      onChange={(e) => {
        e.value !== null && setEndDateFilter(new Date(e.value.toString()));
      }}
    />
  );
  // const featureComingSoon = () => {
  //   SetInfo("Export as DBS bank formatted file - This feature is coming soon!");
  // };

  const exportAsExcel = () => {
    // if (selectedClaims.length <= 0) {
    //   SetWarn("Please select at least one claim");
    //   return false;
    // }
    if (dt !== null) {
      dt.current.exportCSV();
    }
    // else {
    //   SetWarn("Please select at least one claim");
    //   return false;
    // }
  };

  const header = (
    <>
      <div className="row">
        <div className="col">
          {globalSearchBar}
          {claimTypeFilterBar}
          {claimStatusFilterBar}
        </div>
        <div className="col text-md-right">
          {dateStartFilter}
          {dateEndFilter}
          <Button
            tooltip={tooltipFilterString}
            tooltipOptions={{ position: "top" }}
            label="Filter"
            onClick={() => {
              filterByDate();
            }}
            className="mr-1"
          />
          <Button
            label="Clear"
            onClick={() => {
              getClaimsData();
              setStartDateFilter(undefined);
              setEndDateFilter(undefined);
            }}
            className="mr-1 mt-1"
          />
          {(claimView === ClaimViewType.Approving ||
            claimView === ClaimViewType.AllHistory) && (
            <Button
              className="button-primary mr-1 mt-1"
              tooltip="Download all as excel."
              tooltipOptions={{ position: "top" }}
              label="Export"
              icon="pi pi-download"
              iconPos="left"
              onClick={() => {
                exportAsExcel();
              }}
            />
          )}

          {claimView === ClaimViewType.ApprovingHistory && (
            // <CSVLink
            //   onClick={() => {
            //     exportBulkClaims();
            //     getClaimsData();
            //   }}
            //   data={bulkExportData}
            //   filename={`${moment().format("DDMMYY")}-BulkClaimsExport.txt`}
            // >
            // <Button
            //   label="Export"
            //   className="mx-1"
            //   tooltip="Export"
            //   tooltipOptions={{ position: "top" }}
            //   onClick={() => setBulkExportData([])}
            // />
            <>
              <Button
                className="button-primary mt-1"
                tooltip="Download all as excel."
                tooltipOptions={{ position: "top" }}
                label="Export"
                icon="pi pi-download"
                iconPos="left"
                onClick={() => {
                  exportAsExcel();
                }}
              />{" "}
              {props.userProfile.taskList.includes(TaskList.approveclaimsaccess) &&
              <Button
                className="button-primary mt-1"
                tooltip="Mark selected as processed"
                tooltipOptions={{ position: "top" }}
                label="Process"
                onClick={() => {
                  updateClaimsAsProcessed();
                }}
              />
              }
            </>
          )}
        </div>
      </div>
      {/* <div className="row mb-2">
        <div className="col text-md-right">
          {claimView === ClaimViewType.ApprovingHistory ? (
            // <CSVLink
            //   onClick={() => {
            //     exportBulkClaims();
            //     getClaimsData();
            //   }}
            //   data={bulkExportData}
            //   filename={`${moment().format("DDMMYY")}-BulkClaimsExport.txt`}
            // >
            // <Button
            //   label="Export"
            //   className="mx-1"
            //   tooltip="Export"
            //   tooltipOptions={{ position: "top" }}
            //   onClick={() => setBulkExportData([])}
            // />
            <>
              <Button
                className="button-primary mx-1"
                tooltip="Download selected as excel."
                tooltipOptions={{ position: "top" }}
                label="Export"
                icon="pi pi-download"
                iconPos="left"
                onClick={() => {
                  exportAsExcel();
                }}
              />{" "}
              <Button
                className="button-primary"
                tooltip="Mark selected as processed"
                tooltipOptions={{ position: "top" }}
                label="Process"
                onClick={() => {
                  updateClaimsAsProcessed();
                }}
              />
            </>
          ) : // <Button
          //   label="Export"
          //   className="mx-1"
          //   tooltip="Export"
          //   tooltipOptions={{ position: "top" }}
          //   onClick={() => featureComingSoon()}
          // />
          //  </CSVLink>
          null}
        </div>
      </div> */}
    </>
  );

  const incompleteBankDetails = (rowData: ClaimsDisplayModel) => {
    if (
      rowData.bank_Number === null ||
      rowData.bank_PayeeName === null ||
      rowData.swiftCode === null
    ) {
      return (
        <>
          <span data-tip="" data-for="incompleteBankDetails">
            <i className="fas fa-exclamation exclamationStyle"></i>
          </span>
          <ReactTooltip
            id="incompleteBankDetails"
            aria-haspopup="true"
            className="tooltipFont"
          >
            <p className="text-center">
              User has not filled in their bank details.
            </p>
          </ReactTooltip>
        </>
      );
    }
  };

  // const exportBulkClaims = () => {
  //   try {
  //     if (
  //       bankDetails.accountHolderName === "" ||
  //       bankDetails.accountNumber === "" ||
  //       bankDetails.accountType === "" ||
  //       bankDetails.bankCode === null
  //     ) {
  //       SetError("Company bank details invalid");
  //       return false;
  //     }
  //     /**
  //      * UFF is a comma separated format(CSV),where comma character (,) is used as the delimiter. In case the comma delimiter is present as part of the field data, double-quote character (") will be used to quote the entire field. In case the double-quote character (") is present as part of the field data, two double-quote characters ("") will be used.
  //      */
  //     let totalTransactional = 0;
  //     const csvData = [];
  //     const csvHeader = [
  //       "HEADER", // H01 - [6]
  //       moment().format("DDMMYYYY"), // H02 - [8] DDMMYYYY
  //       bankDetails.companyRegNum, // H03 - [12] ORGANISATION ID
  //       bankDetails.accountHolderName, // H04 - [35] COMPANY NAME
  //     ];

  //     csvData.push(csvHeader);

  //     // hm changed to from payslipObjects to userList to allow checkbox selection
  //     if (selectedClaims !== undefined) {
  //       selectedClaims.forEach((object) => {
  //         const field = [
  //           "PAYMENT", // D01 [7] RECORD TYPE
  //           "BPY", // D02 [3] PRODUCT TYPE
  //           bankDetails.accountNumber.toString(), // D03 [35] ORIGINATING ACCOUNT NUMBER
  //           "SGD", // D04 [3] ORIGINATING ACCOUNT CURRENCY: SGD
  //           "", // D05 [35] CUSTOMER OR BATCH REF - AUTO ASSIGN IF NOT PROVIDED
  //           "SGD", // D06 [3] PAYMENT CURRENCY: SGD
  //           "", // D07 [5] BATCH ID: NULL
  //           moment().format("DDMMYYYY"), // D08 [8] PAYMENT DATE
  //           object.bank_PayeeName, // D11 [35] RECEIVING PARTY NAME
  //           object.bank_Number, // D16 [34] RECEIVING ACCOUNT NUMBER / IBAN / PROXY VALUE
  //           object.swiftCode, // D21 [11] BENEFICIARY BANK SWIFT BIC
  //           object.amount.toString(), // D28 [9] CLAIM AMOUNT
  //           "22", // D33 [2] TRANSACTION CODE - 22 (SALARY CREDIT)
  //           object.claimSerialNo, // D34 [35] PARTICULARS / BENEFICIARY OR PAYER REFERENCE (END TO END REF) - MONTH OF PAYMENT
  //           object.claimSerialNo, // D36 [140] PAYMENT DETAILS
  //           "SUPP", //D43 [4/5] PURPOSE OF PAYMENT - SUPP (Supplier Payment)
  //           "", // D45 [1] - DELIVERY MODE - (E) EMAIL
  //         ];
  //         totalTransactional += object.amount;
  //         csvData.push(field);
  //       });
  //     }
  //     const csvTrailer = [
  //       "TRAILER",
  //       selectedClaims.length.toString(),
  //       totalTransactional,
  //     ];
  //     csvData.push(csvTrailer);
  //     setBulkExportData(csvData);
  //     updateClaimsAsProcessed();
  //   } catch (error) {
  //     SetError("Incomplete claims data");
  //     return false;
  //   }
  // };

  // const displayCheckboxes = () => {
  //   if (claimView === ClaimViewType.ApprovingHistory) {
  //     return true;
  //   }
  //   return false;
  // };

  {claimView === ClaimViewType.ApprovingHistory && console.log(claims)};

  let page = (
    <div className="datatable-centerHeader datatable-centerContent">
      <DataTable
        value={claims}
        paginator={true}
        rows={15}
        autoLayout={true}
        alwaysShowPaginator={false}
        emptyMessage="No results found."
        globalFilter={globalFilter}
        selection={selectedClaims}
        onSelectionChange={(e) => setSelectedClaims(e.value)}
        ref={dt}
        exportFilename={exportFileName}
      >
        {claimView === ClaimViewType.ApprovingHistory && (
          <Column selectionMode="multiple" style={{ width: "2em" }} />
        )}
        <Column field="claimSerialNo" header="ID" sortable={true} />
        <Column field="costTypeName" header="Claim Type" filterElement={claimTypeFilterBar} />
        {/* For excel export -- start */}
        {claimView === ClaimViewType.ApprovingHistory && (
          <Column field="campaignTypeName" header="Campaign Type" />
        )}
        <Column field="receiptDate" header="Receipt Date" className="d-none" />
        <Column field="currency" header="Currency" className="d-none" />
        <Column field="companyName" header="Company" className="d-none" />
		<Column field="budgetingGroup" header="Budgeting Group" className="d-none" />
        {/* For excel export -- end */}

        <Column body={amountTemplate} header="Amount" />
        <Column field="taxAmount" body={taxAmountTemplate} header="Tax" />
        <Column field="amount" body={totalTemplate} header="Total" />
        <Column field="claimStatus" body={statusTemplate} header="Status" filterElement={claimStatusFilterBar} />
        <Column
          // field="approvedDate"
          body={approvedDateTemplate}
          header={dateHeaderTemplate()}
        />
        {/* For excel export */}
        <Column field="remarks" header="Remarks" className="d-none" />
        {(claimView === ClaimViewType.ApprovingHistory ||
          claimView === ClaimViewType.AllHistory) && (
          <Column
            field="approvedDate"
            header={"Approved On"}
            className="d-none"
          />
        )}
        {(claimView === ClaimViewType.ApprovingHistory ||
          claimView === ClaimViewType.AllHistory) && (
          <Column
            field="approvingRemarks"
            header="Approving Remarks"
            className="d-none"
          />
        )}
        {(claimView === ClaimViewType.Approving ||
          claimView === ClaimViewType.AllHistory) && (
          <Column
            field="endorsedDate"
            header={"Endorsed On"}
            className="d-none"
          />
        )}
        {(claimView === ClaimViewType.Approving ||
          claimView === ClaimViewType.AllHistory) && (
          <Column
            field="endorsedRemarks"
            header={"Endorsed Remarks"}
            className="d-none"
          />
        )}
        {claimView === ClaimViewType.ApprovingHistory && (
          <Column field="accountingCode" header={"Accounting Code"} />
        )}

        {claimView === ClaimViewType.AllHistory && (
          <Column
            field="accountingCode"
            header={"Accounting Code"}
            className="d-none"
          />
        )}
        {(claimView === ClaimViewType.ApprovingHistory ||
          claimView === ClaimViewType.AllHistory) && (
          <Column
            field="accountingCodeName"
            header={"Accounting Group"}
            className="d-none"
          />
        )}

        {claimView === ClaimViewType.ApprovingHistory ? (
          <Column
            // field="bank_Number"
            body={incompleteBankDetails}
            header=""
          />
        ) : null}
        {claimView === ClaimViewType.ApprovingHistory && (
          <Column
            field="paymentReference"
            className="d-none"
            header="Payment Reference"
          />
        )}
        {!viewMyOwn && <Column field="submittedByName" header="Submitted By" />}
        {claimView === ClaimViewType.ApprovingHistory && props.userProfile.taskList.includes("approveclaimsaccess") && (
          <Column body={editTemplate} header="Edit" />
        )}
        <Column body={actionTemplate} header="View" style={{ width: "8em" }} />
      </DataTable>
    </div>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <>
      {redirectOut ? <Redirect push to={redirectPath} /> : null}
      {header}
      {display}
    </>
  );
}

export default ViewClaims;
