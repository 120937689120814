import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { ClientCompanyService } from "../../service/ClientCompanyService";
import { ClientContactsService } from "../../service/ClientContactsService";
import { CommonMethods } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ClientContactsModel } from "../resources/ExportClass";
import { ToastStateContext } from "../resources/ToastContext";

let clientContactsService = new ClientContactsService();
let clientCompanyService = new ClientCompanyService();
let commonMethods = new CommonMethods();
function ClientContacts(props: any) {
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const { SetSuccess } = React.useContext(ToastStateContext);
  const [tableDataChanged, setTableDataChanged] = useState(false);
  // const [clientContactData, updateClientContactData] = useState(
  //   new ClientContactsModel()
  // );
  // const [clientCompanyData, setClientCompanyData] = useState(
  //   Array<ClientCompanyModel>()
  // );
  const [clientCompanyContacts, setClientCompanyContacts] = useState(
    Array<ClientContactsModel>()
  );

  const clientcompanyid = props.match.params.id;

  useEffect(() => {
    getListofClientContacts();
    // clientCompanyService
    //   .getClientCompanyByID(clientcompanyid)
    //   .then((res) => setClientCompanyData(res))
    //   .catch((err) => {
    //     let errorMsg = commonMethods.getErrorMessage(err);
    //     setIsError(true);
    //     setErrorMessage(errorMsg);
    //   });
  }, []);
  useEffect(() => {
    getListofClientContacts();
  }, [tableDataChanged]);

  const getListofClientContacts = () => {
    clientContactsService
      .getListofClientContacts(clientcompanyid)
      .then((res) => {
        setClientCompanyContacts(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsError(true);
        setErrorMessage(commonMethods.getErrorMessage(err));
      });
  };

  const updateClientContactStatus = async (rowData: ClientContactsModel) => {
    let clientContactsObj = new ClientContactsModel();
    clientContactsObj = rowData;
    clientContactsObj.status =
      clientContactsObj.status === "Active" ? "Deactivated" : "Active";

    clientContactsService.updateClientContactStatus(clientContactsObj);
  };

  const statusTemplate = (rowData: ClientContactsModel) => {
    return (
      <>
        <InputSwitch
          className="mt-2"
          tooltip={rowData.status === "Active" ? "Active" : "Deactivated"}
          tooltipOptions={{ position: "top" }}
          checked={rowData.status === "Active"}
          onChange={(e) => {
            updateClientContactStatus(rowData);
            SetSuccess(rowData.contactName + " is now " + rowData.status + ".");
          }}
        />
      </>
    );
  };

  const editTemplate = (rowData: ClientContactsModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Edit Contact Details"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            // updateClientContactData(rowData);
            setRedirectOut(true);

            setRedirectPath("update/" + rowData.clientContactsID);
          }}
        />
      </div>
    );
  };

  const addLeadTemplate = (rowData: ClientContactsModel) => {
    if (rowData.status === "Active") {
      return (
        <Button
          tooltip="Add Sales Leads"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-tag"
          className="p-button-danger mx-1"
          onClick={() => {
            setRedirectPath(
              "/sales/add/" +
                rowData.clientCompanyID +
                "/" +
                rowData.clientContactsID
            );
            setRedirectOut(true);
          }}
        />
      );
    }
    return "";
  };

  let page = (
    <div className="datatable-centerHeader datatable-centerContent">
      <DataTable
        value={clientCompanyContacts}
        paginator={true}
        rows={15}
        autoLayout={true}
        alwaysShowPaginator={false}
        emptyMessage="No results found."
      >
        <Column field="contactName" header="Name" />
        <Column field="designation" header="Job Title" />
        <Column field="contactNumber" header="Number" />
        <Column field="contactEmail" header="Email" />
        <Column body={statusTemplate} header="Status" />
        <Column body={editTemplate} header="Edit" />
        <Column body={addLeadTemplate} header="Add Sales Lead" />
      </DataTable>
    </div>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <div className="row">
      {redirectOut ? <Redirect push to={{ pathname: redirectPath }} /> : null}

      <div
        className="col-12 col-xl-8"
        data-tour-id="client-contact-details"
        data-tour="These are the people we have worked with for this client company."
      >
        <div className="card">
          <h1 className="pb-2">
            Contact Persons
            <span className="pl-3">
              <Button
                type="button"
                tooltip="Add Contact Person"
                tooltipOptions={{ position: "top" }}
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={() => {
                  setRedirectOut(true);
                  setRedirectPath(
                    "/sales/clientcontacts/create/" + clientcompanyid
                  );
                }}
              />
            </span>
          </h1>
          {display}
        </div>
      </div>
    </div>
  );
}

export default ClientContacts;
