import React from 'react'
import { CommentModel } from '../../Model/CommentModel'

export default function ReplyButton({ 
    item
}: {
    item: CommentModel
}) {

    return (
        <div className="comment-reply-button">
            <span
                className="comment-reply-button-span"
            >Reply</span>
        </div>
    )
}
