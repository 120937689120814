import React, { useState, useEffect } from "react";
import { RouteComponentProps, CommonMethods } from "../resources/CommonMethods";
import { Button } from "primereact/button";
import { Redirect } from "react-router";
import CostTypeService from "../../service/CostTypeService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { ToastStateContext } from "../resources/ToastContext";
import { ProgressSpinner } from "primereact/progressspinner";
import CustomError from "../resources/Error";
import { InputText } from "primereact/inputtext";

let costTypeService = new CostTypeService();
export class CostTypeModel {
  costTypeID: string = CommonMethods.EmptyGuid;
  costTypeName: string = "";
  costTypeDescription: string = "";
}

function CostType(props: RouteComponentProps<any>) {
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);
  const [toCreate, setToCreate] = useState(false);
  const [toUpdate, setToUpdate] = useState(false);
  const [tableDataChanged, setTableDataChanged] = useState(false);
  const [tableData, setTableData] = useState<CostTypeModel[]>([]);
  const [costTypeData, setCostTypeData] = useState<CostTypeModel>(
    new CostTypeModel()
  );
  const [dialogStr, setDialogStr] = useState<string>("");
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [dialogVisibility, setDialogVisibility] = useState(false);
  // const [dropdownData, setDropdownData] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    costTypeService
      .getAllCostTypesByGCID()
      .then(res => setTableData(res))
      .catch(err => setIsError(true));

    // commonMethods
    //   .getCostTypeDropdown()
    //   .then(res => setDropdownData(res))
    //   .catch(err => setIsError(true));

    setIsLoading(false);
  }, []);

  useEffect(() => {
    costTypeService
      .getAllCostTypesByGCID()
      .then(res => setTableData(res))
      .catch(err => setIsError(true));
    setIsLoading(false);
  }, [tableDataChanged]);

  const setDialog = (header: string, content: any) => {
    setConfirmStr(header);
    setDialogStr(content);
  };

  const actionTemplate = (rowData: CostTypeModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            setCostTypeData(rowData);
            setToUpdate(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setCostTypeData(rowData);

            setDialog(
              `Delete ${rowData.costTypeName}`,
              `Are you sure you want to delete this cost type?`
            );
            setDialogVisibility(true);
          }}
        />
      </div>
    );
  };

  let tableheader = (
    <div style={{ textAlign: "left" }}>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e: any) => setSearchValue(e.currentTarget.value)}
        />
      </span>
    </div>
  );

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message="An error has occurred." />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={tableData}
            paginator={true}
            rows={15}
            autoLayout={true}
            header={tableheader}
            globalFilter={searchValue}
            alwaysShowPaginator={false}
            emptyMessage="No results found."
          >
            <Column field="costTypeName" header="Name" />
            <Column field="costTypeDescription" header="Description" />
            <Column body={actionTemplate} header="Description" />
          </DataTable>
        </div>
      );
    }
  };

  return (
    <div className="row">
      {toCreate ? <Redirect push to="cost_type/create" /> : null}
      {toUpdate ? (
        <Redirect push to={"cost_type/update/" + costTypeData.costTypeID} />
      ) : null}

      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        //dismissableMask={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                if (confirmStr.includes("Delete")) {
                  costTypeService
                    .deleteCostTypeByID(costTypeData.costTypeID)
                    .then(() => {
                      setTableDataChanged(!tableDataChanged);
                      setDialogVisibility(false);
                      SetSuccess(
                        ` ${costTypeData.costTypeName} has been deleted.`
                      );
                    })
                    .catch(() => {
                      setDialogVisibility(false);
                      SetError(
                        `An error occurred when trying to delete ${costTypeData.costTypeName}`
                      );
                    });
                }
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>
      <div className="col-12 col-xl-10">
        <div className="card">
          <h1 className="pb-2">
            Cost Types
            <span className="pl-3">
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={() => {
                  setToCreate(true);
                }}
              />
            </span>
          </h1>
          <p>These are used to classify claims and operational costs. (E.g. Transport, Equipments, Office Rental, Utilities)</p>
          {display()}
        </div>
      </div>
    </div>
  );
}

export default CostType;
