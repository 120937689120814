import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import * as React from "react";
import { Redirect } from "react-router";
import { CompanyService } from "../../service/CompanyService";
import { DepartmentService } from "../../service/DepartmentService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError  from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import ReactTooltip from "react-tooltip";
import { AppService } from "../../service/AppService";

interface AppState {
  departmentObj: DepartmentModel;
  formState: string;
  allDepartments: any;
  redirectOut: boolean;
  redirectParam: string;
  redirectPath: string;
  formHeader: string;
  isLoading: boolean;
  isDeleteDialogVisible: boolean;
  deleteErrorMsg: string;
  statusSelected: string;
  // viewOptions: any;

  isError: boolean;
  errorMsg: string;

  searchValue: string;
  setupCreate: boolean;
}

export class DepartmentModel {
  public companyID: string = "";
  public departmentID: string = CommonMethods.EmptyGuid;
  public departmentName: string = "";
  public departmentStatus: string = "";
  public noJobTitle: boolean = false;
}

export class Department extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  departmentService: DepartmentService;
  companyService: CompanyService;
  commonMethods: CommonMethods;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      allDepartments: [],
      departmentObj: new DepartmentModel(),
      formState: "submit",
      redirectOut: false,
      redirectParam: "",
      redirectPath: "",
      formHeader: "",
      isLoading: false,
      isDeleteDialogVisible: false,
      deleteErrorMsg: "",
      statusSelected: "Active",
      // viewOptions: [],
      isError: false,
      errorMsg: "",

      searchValue: "",
      setupCreate: false,
    };

    this.departmentService = new DepartmentService();
    this.companyService = new CompanyService();
    this.commonMethods = new CommonMethods();
    this.actionButtons = this.actionButtons.bind(this);
    this.viewUserBtn = this.viewUserBtn.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.viewUsers = this.viewUsers.bind(this);
    this.redirectToUsers = this.redirectToUsers.bind(this);

    this.viewDeptName = this.viewDeptName.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.getDepartments("Active");
    });
  }

  getDepartments(status: string) {
    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";

    this.departmentService
      .getCompanyDepartments(currentAccessingCompanyId, status)
      .then((res) => {
        this.setState({ allDepartments: res });
      })
      .catch((err) => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({
          isError: true, // display error page
          isLoading: false,
          errorMsg: errorMsg,
        });
      });

    this.setState({
      // viewOptions: this.commonMethods.getViewStatusDropdownList(),
      isLoading: false,
    });
  }

  actionButtons(rowData: DepartmentModel) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning mx-1"
          onClick={(e) => {
            e.preventDefault();
            this.updateItem(rowData);
          }}
        />
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={(e) => {
            e.preventDefault();
            this.updateStatus(rowData);
          }}
        />
      </div>
    );
  }

  viewUserBtn(rowData: DepartmentModel) {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-users"
          className="p-button-info"
          onClick={(e) => {
            e.preventDefault();
            this.viewUsers(rowData);
          }}
          disabled={rowData.noJobTitle}
        />
      </div>
    );
  }

  addDepartment() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/department/create",
    });
  }

  updateItem(currDepartmentObj: DepartmentModel) {
    this.setState({
      redirectOut: true,
      redirectPath:
        "/admin/department/update/" + currDepartmentObj.departmentID,
    });
  }

  redirectToUsers() {
    this.setState({
      redirectOut: true,
      redirectPath:
        "/admin/employees/all/Department/" +
        this.state.departmentObj.departmentID,
    });
  }

  clearFields() {
    this.setState({
      departmentObj: {
        companyID: "",
        departmentID: "",
        departmentName: "",
        departmentStatus: "",
        noJobTitle: false,
      },
    });
    this.setState({ formState: "submit" });
  }

  viewDeptName(rowData: DepartmentModel) {
    let icon;
    if (rowData.noJobTitle) {
      icon = (
        <span
          data-tip=""
          data-for="exclamationmark"
          onClick={() =>
            this.setState({
              redirectPath: "/admin/job_title/create",
              redirectOut: true,
            })
          }
        >
          <i className="fas fa-exclamation exclamationStyle"></i>
          <ReactTooltip
            id="exclamationmark"
            aria-haspopup="true"
            className="exclamationmark"
          >
            <p className="text-center">No Job Titles in this department</p>
          </ReactTooltip>
        </span>
      );
    }

    return (
      <>
        {rowData.departmentName} {icon}
      </>
    );
  }

  viewUsers(selectedDepartmentObj: DepartmentModel) {
    this.setState({
      redirectOut: true,
      redirectPath:
        "/admin/employees/all/Department/" + selectedDepartmentObj.departmentID,
    });
  }

  updateStatus(rowData: DepartmentModel) {
    this.setState({
      isDeleteDialogVisible: true,
      departmentObj: rowData,
    });
  }

  confirmDelete(departmentObj: DepartmentModel) {
    let data = departmentObj;

    data.departmentStatus = "deleted";

    // } else if (data.departmentStatus === "Deactivated") {
    //   data.departmentStatus = "Active";
    // }

    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";
    departmentObj.companyID = currentAccessingCompanyId;

    this.setState({
      isDeleteDialogVisible: false,
    });
    this.departmentService
      .updateDepartmentStatus(departmentObj)
      .then((res) => {
        let msg = res.departmentName + " is now deleted.";
        this.context.SetSuccess(msg);
        this.getDepartments(this.state.statusSelected);
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        if (this.commonMethods.isWarning(error)) {
          this.context.SetError(errorMsg);
          // this.setState({
          //   isRedirectDialogVisible: true, // when department still has people inside, warning dialog will popup
          //   deleteErrorMsg: errorMsg,
          //   isDeleteDialogVisible: false
          // });
        } else {
          this.setState({
            isLoading: false,
          });
          this.context.SetError(errorMsg);
        }
      });
  }

  tableheader() {
    return (
      <div style={{ textAlign: "left" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            onInput={(e: any) => {
              this.setState({ searchValue: e.currentTarget.value });
            }}
          />
        </span>
      </div>
    );
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            search: this.state.redirectParam,
            pathname: this.state.redirectPath,
          }}
        />
      );
    }

    let nothingtodisplay;
    if (this.state.allDepartments.length === 0) {
      nothingtodisplay = "No results found.";
    }
    let form = (
      <>
        {/* <div className="p-grid">
          <div className="p-col-2 p-fluid">
            <span className="boldLabel">View </span>
            <Dropdown
              options={this.state.viewOptions}
              value={this.state.statusSelected}
              onChange={(e: any) => {
                this.setState({
                  statusSelected: e.target.value
                });
                this.getDepartments(e.target.value);
              }}
            />
          </div>
        </div> */}
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            emptyMessage="No results found."
            value={this.state.allDepartments}
            paginator={true}
            rows={15}
            autoLayout={true}
            alwaysShowPaginator={false}
            header={this.tableheader()}
            globalFilter={this.state.searchValue}
          >
            <Column body={this.viewDeptName} header="Department" />
            <Column
              field="departmentName"
              header="Department"
              className="d-none"
            />
            <Column
              body={this.viewUserBtn}
              header="Employee List"
              className="p-col-2"
            />
            <Column
              body={this.actionButtons}
              header="Action"
              className="p-col-3"
            />
          </DataTable>
        </div>
        {nothingtodisplay}
      </>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = form;
    }

    return (
      <div className="row">
        <div className="col-12 col-xl-10">
          <div className="card">
            <h1 className="pb-2">
              Department
              <span className="pl-3">
                <Button
                  type="button"
                  icon="pi pi-plus"
                  iconPos="right"
                  label="Add"
                  className="p-button-danger"
                  onClick={() => {
                    this.addDepartment();
                  }}
                />
              </span>
            </h1>
            {display}
            <Dialog
              className="popupDialog"
              header={"Delete " + this.state.departmentObj.departmentName}
              visible={this.state.isDeleteDialogVisible}
              modal={true}
              //dismissableMask={true}
              onHide={() => this.setState({ isDeleteDialogVisible: false })}
              footer={
                <div>
                  <Button
                    label="Yes"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={() => this.confirmDelete(this.state.departmentObj)}
                  />
                  <Button
                    label="No"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() =>
                      this.setState({ isDeleteDialogVisible: false })
                    }
                  />
                </div>
              }
            >
              Are you sure you want to delete this department?
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
export class DepartmentCreate extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  departmentService: DepartmentService;
  companyService: CompanyService;
  commonMethods: CommonMethods;
  appService: AppService;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      allDepartments: [],
      departmentObj: new DepartmentModel(),
      formState: "submit",
      redirectOut: false,
      redirectPath: "",
      redirectParam: "",
      formHeader: "",
      isLoading: false,
      isDeleteDialogVisible: false,
      deleteErrorMsg: "",
      statusSelected: "Active",
      // viewOptions: [],
      isError: false,
      errorMsg: "",

      searchValue: "",
      setupCreate: false,
    };

    this.departmentService = new DepartmentService();
    this.commonMethods = new CommonMethods();
    this.companyService = new CompanyService();
    this.appService = new AppService();
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.setupProgress();
    if (
      //  --- UPDATE---
      this.props.match.params !== {} &&
      this.props.match.params.id !== undefined
    ) {
      // const query = new URLSearchParams(this.props.location.search);
      // var actionfromQuery = query.get("action");

      this.setState({
        formHeader: "Update Department",
        formState: "update",
      });

      // get from db
      this.departmentService
        .getDepartmentById(this.props.match.params.id)
        .then((res) => {
          this.setState({
            departmentObj: res,
          });
        })
        .catch((err) => {
          let errorMsg = this.commonMethods.getErrorMessage(err);
          this.setState({
            isError: true, // go to error page because cannot get anything to display
            isLoading: false,
            errorMsg: errorMsg,
          });
        });
    } else {
      // create
      let currentAccessingCompanyId =
        localStorage.getItem("currentAccessingCompany") ||
        this.props.userProfile.companyID;

      this.setState({
        formHeader: "Create Department",
        formState: "create",
        departmentObj: {
          ...this.state.departmentObj,
          companyID: currentAccessingCompanyId,
        },
      });
    }
  }
  setupProgress() {
    this.appService
      .getAppTopBar()
      .then((res: any) => {
        if (!res.setup.includes("Department")) {
          this.setState({setupCreate: true});
        }
      })
      .catch ((err) => {
        this.context.SetError(this.commonMethods.getErrorMessage(err));
      })
  }

  backToView() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/department",
    });
  }

  submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // to prevent page from refreshing!

    if (this.state.formState === "update") {
      this.departmentService
        .updateDepartment(this.state.departmentObj)
        .then((res) => {
          let msg = res.departmentName + " has been updated.";
          this.context.SetSuccess(msg);
          this.backToView();
        })
        .catch((error) => {
          // error dialog
          let errorMsg = this.commonMethods.getErrorMessage(error);

          this.context.SetError(errorMsg);

          this.setState({
            isLoading: false,
          });
        });
    } else {
      this.departmentService
        .createDepartment(this.state.departmentObj)
        .then((res) => {
          // success dialog
          if (!this.state.setupCreate) {
            let msg = res.departmentName + " has been created.";
            this.context.SetSuccess(msg);
            this.backToView();
          } else {
            this.backToView();
            window.location.reload();
          }
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);

          this.context.SetError(errorMsg);

          this.setState({
            isLoading: false,
          });
        });
    }
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
          }}
        />
      );
    }

    let form = (
      <form onSubmit={this.submitForm}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">
              Department Name <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={this.state.departmentObj.departmentName}
              onChange={(e) =>
                this.setState({
                  departmentObj: {
                    ...this.state.departmentObj,
                    departmentName: e.currentTarget.value,
                  },
                })
              }
              required
            />
          </div>
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button type="submit" label="Submit" />
        </div>
      </form>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = form;
    }

    return (
      <>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <div className="card">
              <h1 className="pb-2">{this.state.formHeader}</h1>
              <div className="requiredRed">
                <p>* Required</p> 
              </div>
              {display}
            </div>
          </div>
        </div>
      </>
    );
  }
}
