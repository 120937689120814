import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { MultiDeptService } from "../../service/MultiDeptService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { TaskList } from "../resources/ListOfTasks";
import { CompanyDepartmentModel } from "../settings/MultiDeptSetting";
import { LeaveAppliedOneDeptView } from "./LeaveAppliedOneDeptView";

export function LeaveAppliedDeptView(props: RouteComponentProps) {
  const multiDeptService = new MultiDeptService();
  const commonMethods = new CommonMethods();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState<string>("");
  const [deptDetails, setDeptDetails] = useState<CompanyDepartmentModel[]>(
    new Array<CompanyDepartmentModel>()
  );
  const [hasCustomLeaveAccess] = useState<boolean>(
    props.userProfile.taskList.includes(TaskList.customleaveaccess)
  );

  useEffect(() => {
    if (hasCustomLeaveAccess) {
      getAppliedForCustomDepartments();
    } else {
      setIsLoading(false);

    }
  }, []);

  const getAppliedForCustomDepartments = () => {
    // get all the dept ids
    multiDeptService
      .getLeaveDepartmentIds()
      .then((res) => {
        setDeptDetails(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrMsg(commonMethods.getErrorMessage(err));
        setIsError(true);
      });
  };

  const multiDeptDisplay = () => {
    let displayArr: any[] = [];
    deptDetails.forEach((dept: CompanyDepartmentModel) => {
      let oneDept = (
        <div className="row" key={dept.departmentName}>
          <div className="col-12 col-xl-12">
            <div className="card">
              <h1 className="pb-2">
                Leave Applied - {dept.departmentName} [{dept.companyName}]
              </h1>
              <LeaveAppliedOneDeptView
                {...props}
                others={{
                  deptid: dept.departmentID,
                }}
              />
            </div>
          </div>
        </div>
      );
      displayArr.push(oneDept);
    });
    return displayArr;
  };

  let display;
  if (isError) {
    display = <CustomError message={errMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else if (hasCustomLeaveAccess && deptDetails.length > 0) {
    display = multiDeptDisplay();
  } else {
    display = (
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="card">
            <h1 className="pb-2">My Department - Leave Applied</h1>
            <LeaveAppliedOneDeptView
              {...props}
              others={{
                // deptUserBalance: departmentUserBalance,
                // deptUserIds: userIDs,
                mydept: true,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
  return <div>{display}</div>;
}
