import { HttpClient } from "./HttpClient";
export class AppService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

   // GET - All Bank Accounts wrt. CompanyID OR groupID
   async getAppTopBar() {
    const res = await this.httpClient.get("/api/app/topbar");
    return res;
  }

}