import React, { Component } from "react";
import { Redirect } from "react-router";
import { InputText } from "primereact/inputtext";
import { ContractModel } from "./Contract";
import { ContractService } from "../../service/ContractService";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { UserRankService } from "../../service/UserRankService";
import { JobTitleService } from "../../service/JobTitleService";
import { InputNumber } from 'primereact/inputnumber';
import { Button } from "primereact/button";

import { StatusService } from "../../service/StatusService";

import { UserService } from "../../service/UserService";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import NumberFormat from "react-number-format";
import { SelectButton } from "primereact/selectbutton";
import { Checkbox } from "primereact/checkbox";
import { UserModel } from "../user/User";

export interface ContractState {
  contractObject: ContractModel;
  headerStr: string;
  additionalStatus: any[];
  allStatusTypes: any[];
  redirectOut: boolean;
  redirectPath: string;
  redirectParam: string;

  dialogStr: string;
  visible: boolean;
  confirmStr: string;

  companyDropdownList: any[];
  departmentDropdownList: [];
  jobTitleDropdownList: [];
  userRankDropdownList: [];
  countryDropdownList: [];
  statusDropdownList: any[];
  currencyDropdownList: any[];
  workingDaysOptions: any[];

  isError: boolean;
  isLoading: boolean;
  errorMsg: string;

  isFromExistingUser: boolean;
  // contractID: string;
  me: UserModel;
}

export default class ViewContract extends Component<
  RouteComponentProps<any>,
  ContractState
> {
  contractService: ContractService;
  userService: UserService;
  commonMethods: CommonMethods;
  userRankService: UserRankService;
  jobTitleService: JobTitleService;
  statusService: StatusService;

  contractStatusDropdownList: any;
  contractTypeDropdownList: any;
  salaryPeriodDropdownList: any;

  allCompanies: any[];

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      contractObject: new ContractModel(),
      headerStr: "View Contract Details ",
      additionalStatus: [],
      redirectOut: false,
      redirectPath: "",
      redirectParam: "",
      dialogStr: "",
      visible: false,
      confirmStr: "",
      companyDropdownList: [],
      departmentDropdownList: [],
      jobTitleDropdownList: [],
      userRankDropdownList: [],
      countryDropdownList: [],
      statusDropdownList: [],
      isError: false,
      allStatusTypes: [],
      currencyDropdownList: [],
      workingDaysOptions: [],

      errorMsg: "",
      isLoading: true,
      isFromExistingUser: false,
	  me: new UserModel(),
    };
    this.commonMethods = new CommonMethods();
    this.contractService = new ContractService();
    this.userService = new UserService();
    this.userRankService = new UserRankService();
    this.jobTitleService = new JobTitleService();
    this.statusService = new StatusService();

    this.contractStatusDropdownList = [];
    this.contractTypeDropdownList = [];

    this.allCompanies = [];

    this.initiateDropDownLists = this.initiateDropDownLists.bind(this);
    this.showOtherLeaves = this.showOtherLeaves.bind(this);
    this.displayStatusTypeName = this.displayStatusTypeName.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params !== {} && this.props.match.params.id !== undefined) {
      this.setState({ isFromExistingUser: true }, () => {
        this.initiateDropDownLists();
      });

      this.statusService
        .getActiveAndDeductibleStatusTypes()
        .then((res) => {
          this.setState({ allStatusTypes: res });
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
          return errorMsg;
        });

      let contractID = this.props.match.params.id;
      this.contractService
        .getContractByID(contractID)
        .then((res) => {
			this.checkHRAccess(res);
          let data = new ContractModel();
          data = res;
          // data.jobStartDate = new Date(res.jobStartDate);
          // data.jobEndDate = new Date(res.jobEndDate);

          if (data.identificationNumber.length === 36) {
            // because original was set as a GUID for uniqueness
            data.identificationNumber = "";
          }

          this.setState(
            {
              contractObject: data,
            },
            () => {
              var jsonList = JSON.parse(this.state.contractObject.otherLeaves);
              if (jsonList !== null) {
                var values = Object.keys(jsonList).map((key) => {
                  return jsonList[key];
                });
                this.setState({
                  additionalStatus: values,
                });
              }
              this.displayJobTitleByCompanySelected(
                this.state.contractObject.departmentID
              );
              this.setState({
                isLoading: false,
              });
            }
          );
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
          return errorMsg;
        });
    }
  }

  checkHRAccess(userDetails: ContractModel){
	this.commonMethods
      .getDepartmentDropdownList(localStorage.getItem("currentAccessingCompany") || "")
      .then((res: any[]) => {
        let userDeptName = (res.find(dept => dept.value == userDetails.departmentID)).label;
		this.userService
			.getMyDisplay()
			.then((result: UserModel) => {
				if (result.departmentName == "Human Resource" && !(result.jobTitle == "HR Manager" || result.jobTitle == "Senior HR Manager") && userDeptName == "Human Resource" && userDetails.userID != result.userID){
					let errorMsg = "Cannot view HR personnel's contract";
					this.setState({
					isError: true,
					isLoading: false,
					errorMsg: errorMsg,
					});
					return errorMsg;
				}
			})
			.catch((error) => {
				let errorMsg = "Error retrieving personal information";
				this.setState({
				  isError: true,
				  isLoading: false,
				  errorMsg: errorMsg,
				});
				return errorMsg;
			  });
      })
      .catch((error) => {
        let errorMsg = "Error retrieving department list";
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  displayStatusTypeName(statusTypeID: string) {
    let leaveObject = this.state.allStatusTypes.filter(
      (element) => element.statusTypeID === statusTypeID
    );

    if (leaveObject.length > 0) {
      return leaveObject[0].statusTypeName;
    }
  }

  showOtherLeaves = () => {
    if (this.state.additionalStatus.length > 0) {
      let otherLeaves: any[] = [];
      let additionalStatus = this.state.additionalStatus[0];
      let keys = Object.keys(additionalStatus);

      for (var i = 0; i < Object.keys(additionalStatus).length; i++) {
        let currentStatus = keys[i];
        let currentStatusName = this.displayStatusTypeName(currentStatus);
        if (currentStatusName != "Annual Leave"){
          otherLeaves.push(
            <div className="p-col-12" key={keys[i]}>
              <span className="boldLabel">
                {this.displayStatusTypeName(keys[i])}
              </span>

              <InputNumber
                name={additionalStatus[i]}
                value={additionalStatus[keys[i]]}
                disabled={true}
                min={0}
                max={50}
                step={0.5}
                mode="decimal"
                showButtons
                minFractionDigits={1}
              />
            </div>
          );
        } else {
          otherLeaves.push(
            <>
              <div className="p-col-6">
                <span className="boldLabel">
                  1st Year Annual Leave Entitlement
                </span>
                <InputNumber
                  value={this.state.contractObject.yearlyAnnualLeave}
                  mode="decimal"
                  minFractionDigits={1}
                  required
                  disabled
                />
              </div>
              <div className="p-col-6" key={keys[i]}>
                <span className="boldLabel">
                  {this.displayStatusTypeName(keys[i])}
                </span>

                <InputNumber
                  name={keys[i]}
                  value={additionalStatus[keys[i]]}
                  mode="decimal"
                  minFractionDigits={1}
                  required
                  disabled
                />
              </div>
            </>
          );
        }
      }

      return otherLeaves;
    } else {
      return <>"No other Leaves"</>;
    }
  };

  initiateDropDownLists() {
    this.setState({
      workingDaysOptions: this.commonMethods.getWorkingDaysOptions(),
    });

    this.commonMethods
      .getCountryDropdown()
      .then((res) => {
        this.setState({ countryDropdownList: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });

    this.commonMethods
      .getCurrencyDropdown()
      .then((res) => {
        this.setState({ currencyDropdownList: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });

    if (this.state.isFromExistingUser) {
      this.contractStatusDropdownList = this.commonMethods.getContractAllStatusOptions();
    } else {
      this.contractStatusDropdownList = this.commonMethods.getFreshCreateContractStatusOption();
    }

    this.contractTypeDropdownList = this.commonMethods.getContractTypeOptions();
    this.salaryPeriodDropdownList = this.commonMethods.getSalaryPeriodOptions();

    this.userRankService
      .getUserRankDropdownList()
      .then((res) => {
        this.setState({ userRankDropdownList: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
    this.commonMethods
      .getDepartmentDropdownList(
        localStorage.getItem("currentAccessingCompany") || ""
      )
      .then((res) => {
        this.setState({ departmentDropdownList: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });

    this.statusService
      .getStatusTypeDropdown()
      .then((res) => {
        this.setState({ statusDropdownList: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  displayJobTitleByCompanySelected(departmentid: string) {
    this.commonMethods
      .getDeptJobTitleDropdown(departmentid)
      .then((res) => {
        this.setState({ jobTitleDropdownList: res });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  render() {
    let page = (
      <>
        <h5>Personal Particulars</h5>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="row">
              <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">First Name</span>
                <InputText
                  value={this.state.contractObject.firstName}
                  onChange={(e) =>
                    this.setState({
                      contractObject: {
                        ...this.state.contractObject,
                        firstName: e.currentTarget.value,
                      },
                    })
                  }
                  required
                  disabled
                />
              </div>
              <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">Last Name</span>
                <InputText
                  value={this.state.contractObject.lastName}
                  onChange={(e) =>
                    this.setState({
                      contractObject: {
                        ...this.state.contractObject,
                        lastName: e.currentTarget.value,
                      },
                    })
                  }
                  required
                  disabled
                />
              </div>{" "}
            </div>
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Full Name (as per NRIC) <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={this.state.contractObject.fullName}
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    fullName: e.currentTarget.value,
                  },
                })
              }
              required
              disabled
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Identification Number</span>
            <InputText
              value={this.state.contractObject.identificationNumber}
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    identificationNumber: e.currentTarget.value,
                  },
                })
              }
              required
              disabled
            />
          </div>
        </div>

        <h5 className="py-2">Contract Details </h5>

        <div className="p-grid p-fluid">
          <div className="p-col-12 p-lg-12">
            <span className="boldLabel">Status</span>

            <Dropdown
              value={this.state.contractObject.contractStatus}
              options={this.contractStatusDropdownList}
              onChange={(e) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    contractStatus: e.value,
                  },
                });
              }}
              required
              placeholder="Please Select"
              disabled
            />
          </div>
          {/* <div className="p-col-12 p-lg-2">
                <Button
                  className="mt-3"
                  label="Ok"
                  type="button"
                  onClick={this.changeStatusOnly}
                />
              </div> */}

          {/* <div className="p-col-12">
                <span className="boldLabel">Status</span>

                <Dropdown
                  value={this.state.contractObject.contractStatus}
                  options={this.contractStatusDropdownList}
                  onChange={e => {
                    this.setState({
                      contractObject: {
                        ...this.state.contractObject,
                        contractStatus: e.value
                      }
                    });
                  }}
                  required

                  placeholder="Please Select"
                />
              </div> */}
          <div className="p-col-12">
            <div className="row">
              <div className="col-12 col-lg-4 pt-2">
                <span className="boldLabel">Duration (months)</span>
                <InputNumber
                  value={this.state.contractObject.contractDuration}
                  onChange={(e) => {
                    this.setState({
                      contractObject: {
                        ...this.state.contractObject,
                        contractDuration: e.value,
                      },
                    });
                  }}
                  min={0}
                  max={100}
                  step={0.25}
                  disabled
                  mode="decimal"
                  showButtons
                  minFractionDigits={2}
                />
              </div>
              <div className="col-12 col-lg-4 pt-2">
                <span className="boldLabel">Start Date</span>
                <Calendar
                  dateFormat="dd/mm/yy"
                  value={
                    new Date(this.state.contractObject.jobStartDate.toString())
                  }
                  onChange={(e) => {
                    //   this.updateProperty("jobStartDate", e.value);
                  }}
                  showIcon={true}
                  required
                  disabled
                />
              </div>
              <div className="col-12 col-lg-4 pt-2">
                <span className="boldLabel">Contract Effective Date</span>
                <Calendar
                  dateFormat="dd/mm/yy"
                  readOnlyInput={true}
                  value={this.state.contractObject.contractEffectiveDate != null ?
                    new Date(this.state.contractObject.contractEffectiveDate.toString())
                  : undefined}
                  showIcon={true}
                  required
                  disabled
                />
              </div>

              {(this.state.contractObject.jobEndDate !== null && this.state.contractObject.accStatus == 'Deactivated') && (
                <div className="col-12 col-lg-6 pt-2">
                  <span className="boldLabel">End Date</span>
                  <Calendar
                    dateFormat="dd/mm/yy"
                    value={
                      new Date(this.state.contractObject.jobEndDate.toString())
                    }
                    onChange={(e) => {
                      //   this.updateProperty("jobStartDate", e.value);
                    }}
                    showIcon={true}
                    disabled
                  />
                </div>
              )}
            </div>
          </div>

          {/* <div className="p-col-12">
            <span className="boldLabel">Contract Type</span>
            <Dropdown
              value={this.state.contractObject.contractType}
              options={this.contractTypeDropdownList}
              onChange={e => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    contractType: e.value
                  }
                });
              }}
              required disabled
              placeholder="Please Select"
              disabled
            />
          </div> */}

          <div className="p-col-12">
            <span className="boldLabel">Department</span>
            <Dropdown
              value={this.state.contractObject.departmentID}
              options={this.state.departmentDropdownList}
              onChange={(e) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    departmentID: e.value,
                  },
                });
                this.displayJobTitleByCompanySelected(e.value);
              }}
              placeholder="Please Select"
              disabled
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Job Title</span>
            <Dropdown
              value={this.state.contractObject.jobTitleID}
              options={this.state.jobTitleDropdownList}
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    jobTitleID: e.value,
                  },
                })
              }
              required
              disabled
              placeholder="Please Select"
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">User Rank </span>
            <Dropdown
              value={this.state.contractObject.userRankID}
              options={this.state.userRankDropdownList}
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    userRankID: e.value,
                  },
                })
              }
              required
              disabled
              placeholder="Please Select"
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Work Location </span>
            <Dropdown
              value={this.state.contractObject.workLocation}
              options={this.state.countryDropdownList}
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    workLocation: e.value,
                  },
                })
              }
              required
              disabled
              placeholder="Please Select"
            />
          </div>
        </div>

        <h5 className="pt-4">Work Details</h5>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">Work Hours</span>
            <InputNumber
              value={this.state.contractObject.workHours}
              onChange={(e) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    workHours: e.value,
                  },
                });
              }}
              min={0}
              max={100}
              step={0.5}
              disabled
              mode="decimal"
              showButtons
              minFractionDigits={1}
            />

            {/* <div className="col-12 col-lg-6 pt-2">
                <span className="boldLabel">Weekly Work Days</span>
                <Spinner
                  value={this.state.contractObject.weeklyWorkDays}
                  onChange={e => {
                    this.setState({
                      contractObject: {
                        ...this.state.contractObject,
                        weeklyWorkDays: e.value
                      }
                    });
                  }}
                  min={0}
                  max={7}
                  step={0.5}
                  disabled
                />
              </div>{" "} */}
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Working Days</span>

            <SelectButton
              style={{ width:"70%", overflow: "auto" }}
              value={this.state.contractObject.workingDaysArr}
              options={this.state.workingDaysOptions}
              onChange={(e) => {
                this.setState(
                  {
                    contractObject: {
                      ...this.state.contractObject,
                      workingDaysArr: e.value,
                    },
                  },
                  () => {}
                );
              }}
              multiple
              disabled
            ></SelectButton>
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Probation Length (months)</span>
            <InputNumber
              value={this.state.contractObject.probationLength}
              onChange={(e) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    probationLength: e.value,
                  },
                });
              }}
              step={0.5}
              min={0}
              max={50}
              disabled
              mode="decimal"
              showButtons
              minFractionDigits={1}
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Notice Period (months)</span>
            <InputNumber
              value={this.state.contractObject.noticePeriod}
              onChange={(e) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    noticePeriod: e.value,
                  },
                });
              }}
              step={0.5}
              min={0}
              max={50}
              disabled
              mode="decimal"
              showButtons
              minFractionDigits={1}
            />
          </div>
        </div>

        <h5 className="pt-4">Salary Details</h5>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">Wage Type</span>
            <Dropdown
              value={this.state.contractObject.salaryPeriod}
              options={this.salaryPeriodDropdownList}
              onChange={(e) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    salaryPeriod: e.value,
                  },
                });
              }}
              required
              disabled
              placeholder="Please Select"
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Currency</span>

            <Dropdown
              value={this.state.contractObject.currency}
              options={this.state.currencyDropdownList}
              onChange={(e) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    currency: e.value,
                  },
                });
              }}
              placeholder="Please Select"
              required
              disabled
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Basic Salary</span>
            <NumberFormat
              className="p-inputtext p-disabled"
              value={this.state.contractObject.basicSalary}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
              onValueChange={(values) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    basicSalary: values.floatValue!,
                  },
                });
              }}
              disabled
            />
          </div>
          <div className="p-col-12 ">
            <span className="boldLabel">
              Job Grade <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={this.state.contractObject.jobGrade}
              disabled
            />
          </div>
          {/* <div className="p-col-12">
            <span className="boldLabel">Allowance</span>
            <NumberFormat
              className="p-inputtext p-disabled"
              value={this.state.contractObject.allowance}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
              onValueChange={(values) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    allowance: values.floatValue,
                  },
                });
              }}
              disabled
            />
          </div> */}
          <div className="p-col-12">
            <span className="boldLabel pr-3">Eligible for CPF</span>
            <Checkbox
              disabled
              checked={
                this.state.contractObject.cpfEligible === null
                  ? false
                  : this.state.contractObject.cpfEligible
              }
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    cpfEligible: e.checked,
                  },
                })
              }
            />
          </div>
          {/* <div className="p-col-12">
            <Checkbox
              checked={
                this.state.contractObject.cpfEligible === null
                  ? false
                  : this.state.contractObject.cpfEligible
              }
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    cpfEligible: e.checked,
                  },
                })
              }
              disabled
            />
            <span className="boldLabel">Eligible for CPF?</span>
          </div> */}
        </div>

        <h5 className="pt-4">Leave Details</h5>

        <div className="p-grid p-fluid">
          {/* <div className="p-col-12">
                <span className="boldLabel">Annual Leave</span>
                <Spinner
                  value={this.state.contractObject.yearlyAnnualLeave}
                  onChange={e => {
                    this.setState({
                      contractObject: {
                        ...this.state.contractObject,
                        yearlyAnnualLeave: e.value
                      }
                    });
                  }}
                  min={0}
                  max={50}
                  step={0.5}
                  disabled
                />
              </div>
              <div className="p-col-12">
                <span className="boldLabel">Outpatient Leave</span>
                <Spinner
                  value={this.state.contractObject.yearlyOutpatientLeave}
                  onChange={e => {
                    this.setState({
                      contractObject: {
                        ...this.state.contractObject,
                        yearlyOutpatientLeave: e.value
                      }
                    });
                  }}
                  min={0}
                  max={50}
                  step={0.5}
                  disabled
                />
              </div>
              <div className="p-col-12">
                <span className="boldLabel">Hospitalisation Leave</span>
                <Spinner
                  value={this.state.contractObject.yearlyHospitalisationLeave}
                  onChange={e => {
                    this.setState({
                      contractObject: {
                        ...this.state.contractObject,
                        yearlyHospitalisationLeave: e.value
                      }
                    });
                  }}
                  min={0}
                  max={50}
                  step={0.5}
                  disabled
                />
              </div> */}

          {this.showOtherLeaves()}
        </div>

        <h5 className="pt-4">Other Terms</h5>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">Confidentiality</span>
            <InputText
              value={
                this.state.contractObject.confidentiality === null
                  ? ""
                  : this.state.contractObject.confidentiality
              }
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    confidentiality: e.currentTarget.value,
                  },
                })
              }
              disabled
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Non-Compete</span>
            <InputText
              placeholder=""
              value={
                this.state.contractObject.nonCompete === null
                  ? ""
                  : this.state.contractObject.nonCompete
              }
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    nonCompete: e.currentTarget.value,
                  },
                })
              }
              disabled
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Damage Compensation</span>
            <InputText
              placeholder=""
              value={
                this.state.contractObject.damageCompensation === null
                  ? ""
                  : this.state.contractObject.damageCompensation
              }
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    damageCompensation: e.currentTarget.value,
                  },
                })
              }
              disabled
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Copyright</span>
            <InputText
              placeholder=""
              value={
                this.state.contractObject.copyright === null
                  ? ""
                  : this.state.contractObject.copyright
              }
              onChange={(e) =>
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    copyright: e.currentTarget.value,
                  },
                })
              }
              disabled
            />
          </div>
          {/* <div className="p-col-12">
            <span className="boldLabel">PayDay</span>
            <Spinner
              value={this.state.contractObject.payDay}
              onChange={(e) => {
                this.setState({
                  contractObject: {
                    ...this.state.contractObject,
                    payDay: e.value,
                  },
                });
              }}
              min={0}
              max={31}
              required
              disabled
            />
          </div> */}
          <div className="p-col-3"></div>
          <div className="p-col-6"></div>
        </div>
      </>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = page;
    }

    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
            search: this.state.redirectParam,
          }}
        />
      );
    }

    return (
      <>
        <Dialog
          className="popupDialog"
          visible={this.state.visible}
          header={this.state.confirmStr}
          modal={true}
          onHide={() => {
            this.props.history.goBack();
          }}
          footer={
            <div>
              <Button
                label="Okay"
                type="button"
                iconPos="left"
                icon="pi pi-check"
                className="p-button-success"
                onClick={() => {
                  this.props.history.goBack();
                }}
              />
            </div>
          }
        >
          {this.state.dialogStr}
        </Dialog>

        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <div className="card">
              <h1 className="pb-2">{this.state.headerStr}</h1>
              {display}
            </div>
          </div>
        </div>
      </>
    );
  }
}
