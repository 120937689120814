import React, { useState, useEffect, ReactNode } from "react";
import { Link } from "react-router-dom";

import { UserModel } from "./User";

import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";

import { UserService, UserDocumentService } from "../../service/UserService";

function EmailSignature(props: any) {
	let userService = new UserService();
	let userDocumentService = new UserDocumentService();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isError, setIsError] = useState<boolean>(false);
	const [errorMsg, setErrorMessage] = useState<string>("An unidentified error has occurred");

	const [userObj, setUserObj] = useState<UserModel>(new UserModel());
	const [mobileNumber, setMobileNumber] = useState<string>("");
	const [dialCode, setDialCode] = useState<string>("");
	const [displayMobile, setDisplayMobile] = useState(false);
	const [selectedLogo, setSelectedLogo] = useState("tslmg.png");

	const companyLogoDropdown = [
		{ label: "Eatbook", value: "eatbook.png" },
		{ label: "Must Share News", value: "msn.png" },
		{ label: "The Smart Local", value: "tsl.png" },
		{ label: "TSL Media Group", value: "tslmg.png" },
		{ label: "Telegram Co", value: "telegramco.png" },
		{ label: "Uchify", value: "uchify.png" },
		{ label: "Wiki.sg", value: "wiki.png" },
		{ label: "Zula", value: "zula.png" },
	];

	useEffect(() => {
		getUserDetails();
	}, []);

	const getUserDetails = () => {
		userService
			.getMyDisplay()
			.then((res: UserModel) => {
				setUserObj(res);
				const stringSplit = res.pP_MobileNumber.split(" ");
				if (stringSplit.length > 1) {
					setDialCode(stringSplit[0]);
					setMobileNumber(stringSplit[1]);
				} else {
					setDialCode("+65");
					setMobileNumber(res.pP_MobileNumber);
				}

				switch (res.companyName) {
					case "Eatbook Pte. Ltd":
						return "/emailsignature/company-eatbook.png";
					case "Zula Pte. Ltd.":
						return "/emailsignature/company-zula.png";
					case "MS News Pte. Ltd.":
						return "/emailsignature/company-msn.png";
					case "The Smart Local Thailand":
						return "/emailsignature/company-tslmg.png";
					case "The Smart Local Pte. Ltd.":
						return "/emailsignature/company-tslmg.png";
					default:
						return "/emailsignature/company-tslmg.png";
				}
			})
			.catch((err) => {
				setErrorMessage("Error retrieving user details");
				setIsError(true);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const userWebsite = () => {
		switch (userObj.companyName) {
			case "Eatbook Pte. Ltd":
				return "https://eatbook.sg";
				break;
			case "Zula Pte. Ltd.":
				return "https://zula.sg/";
				break;
			case "MS News Pte. Ltd.":
				return "https://mustsharenews.com/";
				break;
			default:
				return "https://tslmedia.sg";
				break;
		}
	};
	console.log("userObj");
	console.log(userObj);
	console.log("userObj email signature: " + userObj.emailSignatureDisplay);

	const numberSpacePadding = (obj: string) => {
		var res = "";
		var savei = 0;
		if (obj.length > 4) {
			for (let i = obj.length - 1; i > 3; i -= 4) {
				savei = i - 4;
				res = " " + obj.slice(i - 3, i + 1) + res;
			}
			res = obj.slice(0, savei + 1) + res;
			return res;
		} else {
			return obj;
		}
	};

	const renderMobileNumber = (): string => {
		if (mobileNumber) {
			let mobile = "";
			if (dialCode) {
				if (!dialCode.includes("+")) mobile += "+";
				mobile += dialCode + " ";
			}
			mobile += numberSpacePadding(mobileNumber);
			return mobile;
		}
		return "";
	};

	const generateLogo = (fileName: string) => (
		<table>
			<tr>
				<td className="p-0" width="140" height="30" align="center">
					<img
						width="140"
						src={`/emailsignature/company-${fileName}`}
						alt={fileName}
					/>
				</td>
			</tr>
		</table>
	);

	const companyLogoDropdownTemplate = (option: any) => generateLogo(option.value);

	return (
		<div className="row">
			<div className="col-12 col-xl-6">
				<div className="card">
					<h1>Email Signature</h1>

					<div className="m-2">
						<div className="mb-2 boldLabel">Instructions:</div>
						<p>
							1. Select the company that you represent from the drop-down below. (If unclear, please check
							with Brand Comms or your HOD)
						</p>
						<p>2. Tick / un-tick the checkbox if you wish to include or exclude your mobile number.</p>
						<p>3. Highlight and copy your signature</p>
						<p>
							4. Go to your Gmail's
							<a
								href="https://mail.google.com/mail/u/0/#settings/"
								target="_blank"
								style={{ textDecorationLine: "none", color: "rgb(66, 117, 170)" }}
							>
								{" "}
								settings{" "}
							</a>
							and paste your signature in the signature box
						</p>
						<p>5. Click "Save Changes"</p>

						<div className="p-grid">
							<div className="p-col-12 field">
								<div className="mb-2 boldLabel">Select Logo</div>
								<Dropdown
									value={selectedLogo}
									options={companyLogoDropdown}
									itemTemplate={companyLogoDropdownTemplate}
									onChange={(e) => {
										setSelectedLogo(e.value);
									}}
									placeholder="Select Logo"
									style={{
										width: 185,
									}}
								/>
							</div>

							<div className="p-col-12 field-checkbox">
								<Checkbox
									disabled={!mobileNumber}
									className="mr-2 pl-0"
									inputId="mobile"
									checked={displayMobile}
									onChange={(e) => {
										setDisplayMobile(e.checked);
									}}
								/>
								<label className="mb-0" htmlFor="mobile">
									Display Mobile Number?
								</label>
								{!isLoading && !mobileNumber && (
									<p className="text-danger text-sm">
										Please set your mobile number <Link to="/profile">here</Link>
									</p>
								)}
							</div>
						</div>
					</div>

					<hr style={{ height: 5, margin: "20px 0" }} />

					<div className="p-grid p-justify-left">
						<div className="p-col-12">
							<table style={{ width: "720px", maxWidth: "100%", border: "0" }}>
								<tbody>
									<tr>
										<td
											style={{
												textAlign: "center",
												margin: "0 0 0 0 ",
												padding: "0",
												width: "140px",
												verticalAlign: "top",
											}}
										>
											<div>
												<img
													style={{
														marginLeft: "auto",
														marginRight: "auto",
														marginBottom: "5px",
														objectFit: "cover",
													}}
													src={userObj.emailSignatureDisplay}
													alt=""
													width="100"
													height="100"
												/>
												{generateLogo(selectedLogo)}
											</div>
										</td>
										<td style={{ padding: "0 0 0 24px" }}>
											<p
												style={{
													color: "#000000",
													fontSize: "small",
													fontFamily: "Roboto, sans-serif",
													margin: "0 0 0 0",
												}}
											>
												<strong style={{ fontSize: "medium" }}>{userObj.fullName}</strong>
												<br />
												{userObj.jobTitle}
												<br />
												<a
													href="mailto:{email}"
													style={{
														color: "#4275AA",
														textDecoration: "none",
														fontSize: "x-small",
														fontFamily: "arial, helvetica, sans-serif",
													}}
												>
													{userObj.email}
												</a>
											</p>
											<p
												style={{
													margin: "0.5rem 0px 0px",
													fontSize: "x-small",
													fontFamily: "arial, helvetica, sans-serif",
												}}
											>
												<strong>Office:</strong>&nbsp;+65 6514 0510
												{displayMobile && (
													<>
														<br />
														<strong>Mobile:</strong>&nbsp;{renderMobileNumber()}
													</>
												)}
												<br />
												<strong>Address:&nbsp;</strong>219 Kallang Bahru, #04-00
												<br />Singapore 339348
												<br />
												<a
													href={userWebsite()}
													target="_blank"
													style={{ textDecorationLine: "none", color: "rgb(66, 117, 170)" }}
												>
													{userWebsite()}
												</a>
											</p>

											<p
												style={{
													color: "#000000",
													fontSize: "x-small",
													fontFamily: "Roboto, sans-serif",
													margin: "0.5rem 0 0 0",
												}}
											>
												<strong style={{ color: "#000000", fontFamily: "Roboto, sans-serif" }}>
													TSL Media Group
												</strong>
												<br />
												Brands:{" "}
												<a
													href="https://thesmartlocal.com"
													target="_blank"
													style={{ color: "#4275AA", textDecoration: "none" }}
												>
													TSL
												</a>{" "}
												|{" "}
												<a
													href="https://eatbook.sg"
													target="_blank"
													style={{ color: "#4275AA", textDecoration: "none" }}
												>
													EatBook
												</a>{" "}
												|{" "}
												<a
													href="https://zula.sg"
													target="_blank"
													style={{ color: "#4275AA", textDecoration: "none" }}
												>
													ZULA
												</a>{" "}
												|{" "}
												<a
													href="https://mustsharenews.com"
													target="_blank"
													style={{ color: "#4275AA", textDecoration: "none" }}
												>
													MS News
												</a>{" "}
												|{" "}
												<a
													href="https://uchify.com/"
													target="_blank"
													style={{ color: "#4275AA", textDecoration: "none" }}
												>
													Uchify
												</a>{" "}
												|{" "}
												<a
													href="https://wiki.sg/p/Main_Page"
													target="_blank"
													style={{ color: "#4275AA", textDecoration: "none" }}
												>
													Wiki.SG
												</a>{" "}
												|{" "}
												<a
													href="https://telegramco.sg/"
													target="_blank"
													style={{ color: "#4275AA", textDecoration: "none" }}
												>
													Telegram.Co
												</a>
												<br />
												Data Analytics:{" "}
												<a
													href="https://insightsrn.com"
													target="_blank"
													style={{ color: "#4275AA", textDecoration: "none" }}
												>
													InsightsRN
												</a>
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan={2}>
											<br />
											<table
												style={{
													border: 0,
													width: "100%",
													maxWidth: "720px",
												}}
											>
												<tr>
													<td align="center" >
														<img
															src="/emailsignature/award-all.png"
															alt="Award"
															width="100%"
														/>
													</td>
												</tr>
											</table>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

// export function UpdateClaimsPage(props: any) {
//   return (
//     <div className="card">
//       <CreateClaims submit="false" {...props}/>
//     </div>
//   );
// }

export default EmailSignature;
