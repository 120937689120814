import { HttpClient } from "./HttpClient";
import { CostTypeModel} from "../components/finance/CostType"
export class CostTypeService {
    httpClient: HttpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }
 
    
    async createCostType(costObj: CostTypeModel)
    {
        const res = await this.httpClient.post(
            "/api/costtype/create",
            costObj
        )
        return res

    }

    async getAllCostTypesByGCID()
    {
        const res = await this.httpClient.get(
            "/api/costtype/gcid")
        return res
    }

    async getCostTypeByID(id: string)
    {
        const res = await this.httpClient.get(
            "/api/costtype/id/" + id
        )
        return res;
    }

    async deleteCostTypeByID(id: string)
    {
        const res = await this.httpClient.delete(
            "/api/costtype/id/" + id
        )
        return res;
    }
    async updateCostType(costObj: CostTypeModel)
    {
        const res = await this.httpClient.put(
            "/api/costtype/update",
            costObj
        )
        return res

    }




}

export default CostTypeService;