import * as React from "react";
import { LoginService } from "../../service/LoginService";
import { ProgressSpinner } from "primereact/progressspinner";
import CustomError  from "../resources/Error";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { Redirect } from "react-router";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import { ToastStateContext } from "../resources/ToastContext";
import { Password } from "primereact/password";

interface AppState {
  redirectOut: boolean;
  redirectPath: string;
  isLoading: boolean;

  isError: boolean;
  errorMsg: string;

  passwordObj: PasswordModel;
}

export class PasswordModel {
  public userID: string = "";
  public currentPassword: string = "";
  public newPassword: string = "";
  public confirmPassword: string = "";
  public isAdminFunction: boolean = false;
}

export class PasswordSetting extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  loginService: LoginService;
  commonMethods: CommonMethods;
  newPw: string;
  confirmPw: string;
  currentPw: string;
  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      redirectOut: false,
      redirectPath: "",
      isLoading: false,
      isError: false,
      errorMsg: "",
      passwordObj: new PasswordModel(),
    };
    this.newPw = "";
    this.confirmPw = "";
    this.currentPw = "";
    this.loginService = new LoginService();
    this.commonMethods = new CommonMethods();
    this.submitForm = this.submitForm.bind(this);
    this.copyPassword = this.copyPassword.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    if (
      this.props.match.params !== {} &&
      this.props.match.params.id !== undefined
    ) {
      // from user admin view
      var userIDfromQuery = this.props.match.params.id;
      this.setState({
        passwordObj: {
          ...this.state.passwordObj,
          userID: userIDfromQuery,
          isAdminFunction: true,
        },
      });
    } else {
      // from user account view
      this.setState({
        passwordObj: {
          ...this.state.passwordObj,
          userID: this.props.userProfile.userID || "",
          isAdminFunction: false,
        },
      });
    }
  }

  pwValidation = (pw: string) => {
    //     Password must at least
    // be 8 characters long
    // contain one number
    // include both lower and upper case characters
    let regexpUpper = /[A-Z]/;
    let regexpLower = /[a-z]/;
    let number = /[0-9]/;
    if (
      regexpUpper.test(pw) &&
      number.test(pw) &&
      regexpLower.test(pw) &&
      pw.length >= 8 &&
      pw.length <= 128
    ) {
      return true;
    }
    return false;
  };

  copyPassword() {
    let copyText = this.state.passwordObj.newPassword;

    let textArea = document.createElement("textarea");
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }

  submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // to prevent page from refreshing!
    this.currentPw = "";
    this.newPw = "";
    this.confirmPw = "";
    this.setState({
      errorMsg: "",
    });

    if (
      this.state.passwordObj.newPassword ===
        this.state.passwordObj.confirmPassword &&
      this.pwValidation(this.state.passwordObj.newPassword)
    ) {
      this.loginService
        .changePassword(this.state.passwordObj)
        .then((res) => {
          this.setState({
            passwordObj: {
              ...this.state.passwordObj,
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            },
          });
          let msg = "Password successfully changed";
          this.context.SetSuccess(msg);
        })
        .catch((err) => {
          if (err.response.data.error.includes("Current Password")) {
            this.currentPw = "p-error";
            this.setState({
              errorMsg: err.response.data.error,
            });
          } else {
            this.setState({
              errorMsg: err.response.data.error,
            });
          }
        });
    } else {
      // error
      if (
        this.state.passwordObj.newPassword !==
        this.state.passwordObj.confirmPassword
      ) {
        this.context.SetError("Passwords do not match");
        this.newPw = "p-error";
        this.confirmPw = "p-error";
      } else if (!this.pwValidation(this.state.passwordObj.newPassword)) {
        this.context.SetError("Invalid Format");
        this.newPw = "p-error";
        this.confirmPw = "p-error";
      }
    }
  }

  goBack() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/employees",
    });
  }

  render() {
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
          }}
        />
      );
    }

    let errorMsg;
    if (this.state.errorMsg !== "") {
      errorMsg = (
        <Message
          className="p-col-6"
          severity="error"
          text={this.state.errorMsg}
        />
      );
    }

    let currentPwBox;
    let backButton;
    if (this.state.passwordObj.isAdminFunction) {
      currentPwBox = "";
      backButton = (
        <Button
          label="Back to Users"
          onClick={this.goBack}
          icon="pi pi-angle-left"
          iconPos="left"
          className="p-button-primary my-2"
        />
      );
    } else {
      currentPwBox = (
        <div className="p-col-12">
          <span className="boldLabel">Current Password:</span>
          <Password
            className={this.currentPw}
            id="currentPw"
            feedback={false}
            onChange={(e) =>
              this.setState({
                passwordObj: {
                  ...this.state.passwordObj,
                  currentPassword: e.currentTarget.value,
                },
              })
            }
            value={this.state.passwordObj.currentPassword}
            required
          />
        </div>
      );
    }
    let form = (
      <form onSubmit={this.submitForm}>
        <div className="p-grid p-fluid">
          <div className="p-col-12"></div>
          {currentPwBox}
          <div className="p-col-12 ">
            Password must at least <br></br>
            <ul>
              <li> be 8 characters long </li>
              <li> contain one number </li>
              <li> include both lower and upper case characters </li>
            </ul>
          </div>
          {errorMsg}
          <div className="p-col-12">
            <span className="boldLabel">New Password:</span>
            <div className="p-inputgroup">
              <Password
                className={this.newPw}
                id="newPw"
                onChange={(e) =>
                  this.setState({
                    passwordObj: {
                      ...this.state.passwordObj,
                      newPassword: e.currentTarget.value,
                    },
                  })
                }
                feedback={false}
                value={this.state.passwordObj.newPassword}
                required
              />
              <Button
                tooltip="Generate Random Password"
                tooltipOptions={{ position: "top" }}
                type="button"
                className=" p-button-warning"
                onClick={() => {
                  this.setState({
                    passwordObj: {
                      ...this.state.passwordObj,
                      newPassword: this.commonMethods.generatePassword(),
                    },
                  });
                }}
                icon="pi pi-replay"
              />
              <Button
                tooltip="Copy Password"
                tooltipOptions={{ position: "top" }}
                type="button"
                id="passwordGenerated"
                onClick={this.copyPassword}
                icon="pi pi-copy"
              />
            </div>
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Confirm Password:</span>
            <Password
              className={this.confirmPw}
              id="confirmPw"
              onChange={(e) =>
                this.setState({
                  passwordObj: {
                    ...this.state.passwordObj,
                    confirmPassword: e.currentTarget.value,
                  },
                })
              }
              feedback={false}
              value={this.state.passwordObj.confirmPassword}
              required
            />
          </div>
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button type="submit" label="Submit" />
        </div>
      </form>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />; // or some growl/message that stays
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = form;
    }
    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-4">
          {backButton}
          <div className="card">
            <h1 className="pb-2">Change Password</h1>

            {display}
          </div>
        </div>
      </div>
    );
  }
}
