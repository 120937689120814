import React, { Component } from "react";

import { ToastStateContext } from "../resources/ToastContext";
import { LeaveBalancePanel } from "./LeaveBalancePanel";
import { LeaveApplicationForm } from "./LeaveApplicationForm";
import { RouteComponentProps } from "../resources/CommonMethods";

type AppState = {};

export class StatusRecord extends Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-5">
          <div className="card">
            <LeaveApplicationForm
             {...this.props}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-5">
          <div className="card">
            <LeaveBalancePanel />
          </div>
        </div>
      </div>
    );
  }
}
