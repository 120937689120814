import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { ScoreIndicatorService } from "../../../service/ScoreIndicatorService";
import {
  CommonMethods,
  RouteComponentProps,
} from "../../resources/CommonMethods";
import { ToastStateContext } from "../../resources/ToastContext";
import CreateKPI from "./CreateIndicator";
import KPITable from "./KPITable";
import LinkPerformanceView from "../linkperformance/LinkPerformanceView";
import { UserIndicatorWithTargetModel, UserTargetModel } from "./KPIModels";
import ReactTooltip from "react-tooltip";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from 'primereact/inputnumber';

export default function MyKPIPage(props: RouteComponentProps<any>) {
  const sis = new ScoreIndicatorService();
  const commonMethods = new CommonMethods();

  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [refreshView, setRefreshView] = useState(false);
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [myKPI, setMyKPI] = useState<UserIndicatorWithTargetModel>(
    new UserIndicatorWithTargetModel()
  );
  const [linkGuideDialogVis, setLinkGuideDialogVis] = useState(false);

  const [customdialogVisibility, setCustomDialogVisibility] = useState(false);

  const [prorateButtonVisibility, setProrateButtonVisibility] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const [manualProrate, setManualProrate] = useState<boolean>(false);

  const [points, setPoints] = useState<number>(0);

  // kpi table is refreshed after a create/update/prorate target
  useEffect(() => {
    getData();
  }, [refreshView]);

  const getData = () => {
    sis.getMyKPIEntries().then((res) => {
      setMyKPI(res);
    });
  };

  const prorateTargetCalculations = () => {
    let selectedMonth = selectedDate.getMonth() + 1;
    let selectedYear = selectedDate.getFullYear();
    let targetModel = new UserTargetModel();
    targetModel.targetMonth = selectedMonth.toString();
    targetModel.targetYear = selectedYear.toString();
    targetModel.userID = props.userProfile.userID;

    if (!manualProrate) {
      sis
        .prorateTarget(targetModel)
        .then((res) => {
          getData();
          SetSuccess("Target has been prorated!");
          setProrateButtonVisibility(false);
        })
        .catch((err) => {
          let errMsg = commonMethods.getErrorMessage(err);
          SetError(errMsg);
        });
    } else {
      targetModel.targetPoints = points;
      sis
        .prorateTargetManual(targetModel)
        .then((res) => {
          getData();
          SetSuccess("Target has been prorated!");
          setProrateButtonVisibility(false);
        })
        .catch((err) => {
          let errMsg = commonMethods.getErrorMessage(err);
          SetError(errMsg);
        });
    }
  };

  console.log(myKPI);

  let prorateManual = (
    <div className="text-left p-col-12 p-fluid">
      <span className="boldLabel">Key in points</span>
      <span className="requiredRed">*</span>
      <InputNumber
        value={points}
        onChange={(e) => {
          setPoints(e.value);
        }}
        required
        mode="decimal"
        showButtons
        minFractionDigits={2}
      />
    </div>
  );

  return (
    <div>
      <div className="card">
        <h1
          className="pb-2"
          data-tour-id="create-my-kpi"
          data-tour="Add your completed assignments here to track your KPI"
        >
			My KPI
			<Button
				type="button"
				icon="pi pi-plus"
				iconPos="right"
				label="Add"
				tooltip="Add KPI"
				tooltipOptions={{ position: "top" }}
				className="p-button-danger mt-1 mr-1 ml-3"
				onClick={() => {
					setDialogVisibility(true);
				}}
			/>
			<Button
				type="button"
				icon="pi pi-plus"
				iconPos="right"
				label="Add Custom"
				tooltip="Add Custom KPI"
				tooltipOptions={{ position: "top" }}
				className="mx-1 mt-1"
				onClick={() => {
					setCustomDialogVisibility(true);
				}}
			/>
			<Button
				type="button"
				icon="pi pi-sliders-v"
				iconPos="right"
				label="Prorate Target"
				tooltip="Prorate your target KPI"
				tooltipOptions={{ position: "top" }}
				className="neuhr-ButtonColor mx-1 mt-1"
				onClick={() => {
					setProrateButtonVisibility(true);
					setSelectedDate(new Date());
					setPoints(0);
					setManualProrate(false);
				}}
			/>
			<span className="editButtonFloatRight">
				<Button
					type="button"
					icon="pi pi-info-circle"
					iconPos="right"
					label="Department Scoring Sheet"
					className="button-document mt-1 mx-1"
					onClick={() => {
						window.open("/indicators/scoring", "_blank");
					}}
				/>
			</span>
			<span className="editButtonFloatRight">
				<Button
					type="button"
					icon="pi pi-info-circle"
					iconPos="right"
					label="Link Guide"
					className="button-document mt-1 mx-1"
					onClick={() => {
						setLinkGuideDialogVis(true);
					}}
				/>
			</span>
        </h1>

        <KPITable
          {...props}
          others={{ update: getData, item: myKPI, hodview: false }}
        />
      </div>

      <Dialog
        header="Link Guide"
        visible={linkGuideDialogVis}
        modal={true}
        className="popupDialogKPI"
        //dismissableMask={true}
        onHide={() => setLinkGuideDialogVis(false)}
      >
        {" "}
        <div>
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <p className="text-center requiredRed">
                Only valid links will be sent to iNSIGHTSRN to retrieve
                performance. <br></br>Valid iNSIGHTSRN links are indicated with
                Y.
              </p>
              <h5> Link:</h5>
              <span className="boldLabel px-4">SP/BC articles</span>
              <ul>
                <li>
                  Must be a full link and start with https://www or http://www
                </li>
                <li>
                  Must be from these domains: "thesmartlocal.com", "eatbook.sg",
                  "zula.sg", "mustsharenews.com"
                </li>
              </ul>
              <span className="boldLabel px-4">
                Youtube, Instagram, Facebook Post etc.
              </span>
              <ul>
                <li>
                  Must be a full link and start with https://www or http://www
                </li>
                <li>
                  Must select a deliverable in order for performance to be
                  retrieved.
                </li>
                <li>
                  For facebook videos, must have /videos/ e.g.
                  https://www.facebook.com/TheSmartLocal/<b>videos</b>
                  /278001809689427
                </li>
              </ul>
              <span className="boldLabel px-4">Telegram Posts</span>
              <ul>
                <li>Must start with https://t.me</li>
                <li>Must be in this format: https://t.me/TSLMedia/504</li>
              </ul>
              <span className="boldLabel px-4">
                Others (Google doc/sheets, IRN report, etc.)
              </span>
              <ul>
                <li>
                  Place it under <b>Remarks</b> instead. These have no
                  performance.
                </li>
              </ul>
              <h5> Facebook Link:</h5>
              <ul>
                <li>
                  Fill this up <b>ONLY</b> if it is a SP/BC! (Means there should
                  be a Link)
                </li>
                <li>Must start with https://www.facebook.com</li>
              </ul>
            </div>
          </div>{" "}
        </div>
      </Dialog>

      <Dialog
        header="Add KPI"
        visible={dialogVisibility}
        modal={true}
        //dismissableMask={true}
        onHide={() => setDialogVisibility(false)}
        className="popupDialogKPI"
      >
        {dialogVisibility && (
          <CreateKPI
            isUpdate={false}
            refresh={refreshView}
            setRefreshView={setRefreshView}
            callback={setDialogVisibility}
            isCustomPointEntry={false}
            hodView={false}
            thisUser={CommonMethods.EmptyGuid}
          />
        )}
      </Dialog>

      <Dialog
        header="Add Custom KPI"
        visible={customdialogVisibility}
        modal={true}
        className="popupDialogKPI"
        //dismissableMask={true}
        onHide={() => setCustomDialogVisibility(false)}
      >
        {customdialogVisibility && (
          <CreateKPI
            isUpdate={false}
            refresh={refreshView}
            setRefreshView={setRefreshView}
            callback={setCustomDialogVisibility}
            isCustomPointEntry={true}
            hodView={false}
            thisUser={CommonMethods.EmptyGuid}
          />
        )}
      </Dialog>

      <Dialog
        contentStyle={{height: "200px"}}
        header="Prorate my target KPI"
        visible={prorateButtonVisibility}
        modal={true}
        className="popupDialogKPI"
        //dismissableMask={true}
        onHide={() => setProrateButtonVisibility(false)}
        footer={
          <div>
            <Button
              label="Submit"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              onClick={() => {
                prorateTargetCalculations();
              }}
            />
            <Button
              label="Cancel"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setProrateButtonVisibility(false);
              }}
            />
          </div>
        }
      >
        <div className="text-left text-md-center py-2">
          <div className="p-grid p-fluid">
            <div className="text-left p-col-12 p-fluid">
              <span className="boldLabel">Select Month</span>
              <span className="requiredRed">*</span>
              <Calendar
                yearNavigator
                yearRange="2020:2023"
                id="MonthCalendar"
                view="month"
                placeholder="Select Month"
                value={selectedDate}
                dateFormat="M-yy"
                readOnlyInput={true}
                onChange={(e) => {
                  setSelectedDate(new Date(e.value.toString()));
                }}
              />
            </div>

            <div className="text-left p-col-6">
              <RadioButton
                inputId="calculate"
                value={true}
                name="calculate"
                onChange={() => {
                  setManualProrate(false);
                }}
                checked={!manualProrate}
              />
              <label htmlFor="calculate" className="ml-1 mb-0">
                <p className="d-inline">Calculate for me!</p>
              </label>
              <span
                className="d-inline px-2"
                data-for="calculatett"
                data-tip=""
              >
                <i className="fas fa-info-circle informationStyleSmall"></i>
                <ReactTooltip
                  id="calculatett"
                  aria-haspopup="true"
                  className="tooltipFont"
                >
                  <p className="text-center mb-0">
                    Prorating your target KPI takes into account approved leaves
                    (Annual, Medical, Unpaid Leaves etc.) and public holidays for
                    that month.
                  </p>
                </ReactTooltip>
              </span>
            </div>

            <div className="text-left p-col-6">
              <RadioButton
                inputId="manual"
                value={true}
                name="manual"
                onChange={() => {
                  setManualProrate(true);
                }}
                checked={manualProrate}
              />
              <label htmlFor="manual" className="ml-1 mb-0">
                <p className="d-inline">Manual Input</p>
              </label>
              <span className="d-inline px-2" data-for="manualtt" data-tip="">
                <i className="fas fa-info-circle informationStyleSmall"></i>
                <ReactTooltip
                  id="manualtt"
                  aria-haspopup="true"
                  className="tooltipFont"
                >
                  <p className="text-center mb-0">
                    Calculate the target on your own. Leaves and PH will be fixed as 0.
                  </p>
                </ReactTooltip>
              </span>
            </div>

            {manualProrate && prorateManual}
          </div>
        </div>
      </Dialog>
      <div>
        <LinkPerformanceView {...props} />
      </div>
    </div>
  );
}
