import moment from "moment";
import { CommonMethods } from "./CommonMethods";

const processFieldsForFormData = <T, K extends keyof T>(obj: T, key: K) => {
  if (obj[key] instanceof Date) {
    // console.log("key:", key, " , value:", obj[key]);
    if (obj[key] !== null) {
      return moment(obj[key]).format(CommonMethods.DateForBackendStringFormat);
    }
    return null;
  }
  if (obj[key] === null) {
    // console.log("null" ,key)
  }
  return (obj as any)[key];
};

export const convertObjToFormData = <T>(obj: T) => {
  var form_data = new FormData();
  for (var key in obj) {
    const fieldValue = processFieldsForFormData(obj, key);
    form_data.append(key, fieldValue);
  }
  return form_data;
};
