import React, {
    ReactElement,
    MouseEvent,
    useMemo,
    useRef,
    useState,
    useEffect,
} from 'react';
import { CharacterMetadata, ContentBlock, ContentState, convertFromRaw, convertToRaw, EditorState, Modifier, SelectionState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
    MentionData, MentionPluginTheme,
} from '@draft-js-plugins/mention';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import { extractLinks } from '@draft-js-plugins/linkify';
import { useHistory } from 'react-router';
import MentionProfilePreview from '../ProfilePreview/MentionProfilePreview';
import Tippy from '@tippyjs/react';

export interface EntryComponentProps {
    className?: string;
    onMouseDown(event: MouseEvent): void;
    onMouseUp(event: MouseEvent): void;
    onMouseEnter(event: MouseEvent): void;
    role: string;
    id: string;
    'aria-selected'?: boolean | 'false' | 'true';
    theme?: MentionPluginTheme;
    mention: MentionData;
    isFocused: boolean;
    searchValue?: string;
}

export default function CommentDraftJsEditorReadOnly({
    rawEditorContentJsonStr,
    commentText,
}: {
    rawEditorContentJsonStr: string | null
    commentText?: string | null
}): ReactElement {
    const ref = useRef<Editor>(null);
    const [readMore, setReadMore] = useState(false)
    const charLimit = 600
    const [contentLength, setContentLength] = useState<number>(0)
    const [editorState, setEditorState] = useState(() => {
        if (rawEditorContentJsonStr) {
            let recreatedEditorState: EditorState = EditorState.createWithContent(convertFromRaw(JSON.parse(rawEditorContentJsonStr)))
            // let links = extractLinks(convertFromRaw(JSON.parse(rawEditorContentJsonStr)).getPlainText())
            // console.log('links', links)
            let lengthOfContent: number = convertFromRaw(JSON.parse(rawEditorContentJsonStr)).getPlainText().length
            setContentLength(lengthOfContent)
            // console.log("lengthOfContent", lengthOfContent)
            if (lengthOfContent > charLimit) {
                let { newEditorState } = truncate(recreatedEditorState, charLimit)
                // Decorators detect @mentions/links from text
                // Decorators are lost after truncation, need to retrieve decorators from initial recreatedEditorState
                // and set them on the truncatedEditorState
                let decorators = recreatedEditorState.getDecorator()
                let truncatedEditorState = EditorState.set(newEditorState, { decorator: decorators })
                return truncatedEditorState
            }
            return recreatedEditorState
        }
        if (commentText) {
            setContentLength(commentText.length)
            if (commentText.length > charLimit) {
                let truncatedPostText = commentText.slice(0, charLimit)
                let truncatedContentState = ContentState.createFromText(truncatedPostText)
                let truncatedEditorState = EditorState.createWithContent(truncatedContentState)
                return truncatedEditorState
            }
            let contentState = ContentState.createFromText(commentText)
            let editorStateFromPostText = EditorState.createWithContent(contentState)
            return editorStateFromPostText
        }
        return EditorState.createEmpty()
    });
    const [renderCount, setRenderCount] = useState(1)

    const { plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            mentionComponent: MentionComponent,
        });
        const linkifyPlugin = createLinkifyPlugin({
            rel: "noopener noreferrer",
            target: "_blank",
            // component: LinkifyComponent,
        });
        const emojiPlugin = createEmojiPlugin();
        const plugins = [mentionPlugin, linkifyPlugin, emojiPlugin];
        return { plugins };
    }, []);



    useEffect(() => {
        if (readMore && rawEditorContentJsonStr) {
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(rawEditorContentJsonStr))))
            return
        }
        if (readMore && commentText) {
            setEditorState(EditorState.createWithContent(ContentState.createFromText(commentText)))
            return
        }
    }, [commentText, rawEditorContentJsonStr, readMore])

    useEffect(() => {
        // apply changes after user edits comment
        // only runs after 1st render
        // if update editorstate function ran on first render, decorators are lost
        if (renderCount > 1 && rawEditorContentJsonStr) {
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(rawEditorContentJsonStr))))
        }
        setRenderCount(prev => prev + 1)
    }, [rawEditorContentJsonStr])

    function toggleReadMore() {
        setReadMore(true)
    }

    // function logEditorState(editorState: EditorState) {
    //     console.log('raw json of ContentState', convertToRaw(editorState.getCurrentContent()))
    //     console.log('EditorState Json:', editorState.toJS())
    // }

    function truncate(editorState: EditorState, charCount: number) {
        let newEditorState: EditorState = editorState;
        if (editorState && editorState.getCurrentContent) {
            const contentState = editorState.getCurrentContent();
            const blocks = contentState.getBlocksAsArray();
            let index = 0;
            let currentLength = 0;
            let isTruncated = false;
            const truncatedBlocks = [];
            let inlineStyleChars = 0;

            while (!isTruncated && blocks[index]) {
                let block: ContentBlock = blocks[index];
                const length = block.getLength();

                if (currentLength + length > charCount) {
                    isTruncated = true;

                    let text = block.getText()
                    var txt2 = text.slice(0, charLimit) + "..." + text.slice(charLimit)
                    // console.log('block text', txt2)
                    block = new ContentBlock(block.set('text', txt2))
                    inlineStyleChars = charCount - currentLength;
                    let characterList1 = block.getCharacterList().slice(0, inlineStyleChars);
                    // + 3 for '...'
                    let characterList2 = block.getCharacterList().slice(inlineStyleChars, inlineStyleChars + 3);
                    // remove entity (@mention) which removes mention styling from '...'
                    // TODO: fix not removing styling from linkify plugin
                    // possible solution: Extract links using extractLinks function from
                    // import { extractLinks } from '@draft-js-plugins/linkify';
                    // then render custom linkify component and add extracted links?
                    // match links by comparing array index
                    let plainCharList = characterList2.map(char => {
                        if (char) return CharacterMetadata.applyEntity(char, null)
                        return null
                    })
                    block = new ContentBlock(block.set('characterList', characterList1.concat(plainCharList)));
                    truncatedBlocks.push(block);
                } else {
                    truncatedBlocks.push(block);
                }
                currentLength += length + 1;
                index += 1;
            }
            if (isTruncated) {
                const state = ContentState.createFromBlockArray(truncatedBlocks);
                newEditorState = EditorState.createWithContent(state)
            }
            return { newEditorState, isTruncated };
        }
        return { newEditorState, isTruncated: false };
    };

    return (
        <div className="draftjs-readonly">
            <Editor
                // editorKey={'editor'}
                editorState={editorState}
                onChange={setEditorState}
                plugins={plugins}
                ref={ref}
                placeholder="What's happening?"
                readOnly={true}
            />
            {/* <button type="button" onClick={() => logEditorState(editorState)}>Log Editor State</button> */}
            {!readMore && contentLength > charLimit ? <div
                className="read-more-text"
                onClick={toggleReadMore}
            >
                Read more
            </div> : null}
        </div>
    );
}


function MentionComponent(props: any) {
    const history = useHistory();

    return (
        <Tippy
            content={<MentionProfilePreview mention={props.mention} />}
            interactive={true}
            // interactiveBorder={5}
            delay={150}
            appendTo={document.body}
            placement="auto"
            zIndex={9}
        >
            <span
                className={props.className + " mention"}
                onClick={() => {
                    // console.log('props', props)
                    history.push(`/publicprofile/${props.mention.userId}`, { userId: props.mention.userId });
                }}
            >
                {props.children}
            </span>
        </Tippy >
    )
}

