import moment from "moment";
import { HttpClient } from "./HttpClient";
import { SalesClientsModel, ClientContactsModel } from "../components/resources/ExportClass";
export class SalesService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getClientOnlyInfo(clientID: string) {
    const res = await this.httpClient.get("/api/sales/clientonly/"+clientID);
    return res;
  }

  async getAllSalesAndClients() {
    const res = await this.httpClient.get("/api/sales/all");
    return res;
  }

  async getAllSalesDetailsByClientCompany(clientcompanyID:string) {
    const res = await this.httpClient.get("/api/sales/salesdetails/" + clientcompanyID);
    return res;
  }

  async getAllSalesClientsByUserID(salesPersonID: string) {
    const res = await this.httpClient.get("/api/sales/user/" + salesPersonID);
    return res;
  }

  async filterSalesClientByDate(salesPersonID: string, meetingDateStart: Date, meetingDateEnd: Date) {
    const meetingDateStartString = moment(meetingDateStart).format("YYYY-MM-DD HH:mm:ss");
    const meetingDateEndString = moment(meetingDateEnd).format("YYYY-MM-DD HH:mm:ss");
    const res = await this.httpClient.get(`/api/sales/user/${salesPersonID}/${meetingDateStartString}/${meetingDateEndString}`);
    return res;
  }

  async getAllClientSales(clientID: string) {
    const res = await this.httpClient.get("/api/sales/client/" + clientID);
    return res;
  }

  async getAllClientByStatus(clientStatus: string) {
    const res = await this.httpClient.get("/api/sales/status/" + clientStatus);
    return res;
  }

  async insertNewSalesClient(newClient: any) {
    var form_data = new FormData();
    for (var key in newClient) {
      
      form_data.append(key, newClient[key]);
    }
    
    const res = await this.httpClient.post("/api/sales/newclient", form_data);
    return res;
  }

  async insertNewClientContact(newClient: ClientContactsModel) {
    const res = await this.httpClient.post("/api/sales/newcontact", newClient);
    return res;
  }

  async updateSalesClient(thisClient: SalesClientsModel) {
    const res = await this.httpClient.put("/api/sales/updateSales", thisClient);
    return res;
  }

  async updateClientcontact(thisClient: ClientContactsModel) {
    const res = await this.httpClient.put("/api/sales/updateContact", thisClient);
    return res;
  }
}
