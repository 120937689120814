import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import CampaignService from "../../service/CampaignService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";

let campaignService = new CampaignService();
let commonMethods = new CommonMethods();
function ClientCampaigns(props: RouteComponentProps<any>) {
  let params: { id?: string } = useParams();

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [clientCampaigns, setClientCampaigns] = useState<any[]>([]);

  useEffect(() => {
    setIsLoading(false);
    //get campaigns by clientcompanyID
    if (params.id !== undefined) {
      campaignService
        .getAllCampaignsByClientCompanyID(params.id)
        .then(res => {
          setClientCampaigns(res);
        })
        .catch(err => {
          let error = commonMethods.getErrorMessage(err);
          setErrorMessage(error)
          setIsError(true);
        });
    }
  }, []);

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMsg} />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={clientCampaigns}
            paginator={true}
            rows={15}
            autoLayout={true}
            alwaysShowPaginator={false}
            emptyMessage="No results found."
          >
            <Column header="ID" field="campaignSerialNo" />
            <Column header="Name" field="campaignName" />
            <Column header="Status" field="campaignStatus" />
          </DataTable>
        </div>
      );
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-xl-8" data-tour-id="client-campaigns"
        data-tour="These are campaigns done with this client.">
        <div className="card">
          <h1 className="pb-2">Campaigns</h1>

          {display()}
        </div>
      </div>
    </div>
  );
}

export default ClientCampaigns;
