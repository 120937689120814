import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { ProgressSpinner } from "primereact/progressspinner";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import { ContractService } from "../../service/ContractService";
import { UserService } from "../../service/UserService";
import {
  CommonMethods,
  RouteComponentProps,
  DropdownModel,
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import {
  ReportLeaveObjectModel,
  ReportsModel,
  ReportType,
  ProfileReportModel,
} from "./Reports";
import ReactTooltip from "react-tooltip";
import { InputSwitch } from "primereact/inputswitch";

function ContractReport(props: RouteComponentProps<any>) {
  const { SetError } = React.useContext(ToastStateContext);
  const commonMethods = new CommonMethods();
  const contractService = new ContractService();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const [reportType, setReportType] = useState<ReportType>({
    groupCompany: false,
    company: false,
  });
  const [companyDropdown, setCompanyDropdown] = useState<DropdownModel[]>([]);
  const [userDropdown, setUserDropdown] = useState<DropdownModel[]>([]);
  const [inactiveUserDropdown, setInactiveUserDropdown] = useState<
    DropdownModel[]
  >([]);
  const [switchButton, setSwitchButton] = useState(false);

  const [reportObj, setReportObj] = useState<ReportsModel>(new ReportsModel());
  const [reportData, setReportData] = useState<any[]>([]);
  const [exportFileName, setExportFileName] = useState<string>(
    "Employment_History_Report_" +
      commonMethods.displayDate_FileName(new Date()) +
      ".csv"
  );
  let datatable: DataTable | null = null;

  const contractHeaders = useState<any[]>([
    { label: "Full Name", key: "fullName" },
    { label: "Role", key: "roleName" },
    { label: "Company", key: "companyName" },
    { label: "Department", key: "departmentName" },
    { label: "Job Title", key: "jobTitle" },
    { label: "User's Rank", key: "userRankName" },
    { label: "Identification Number", key: "identificationNumber" },
    { label: "Contract Status", key: "contractStatus" },
    { label: "Contract Type", key: "contractType" },
    { label: "Start Date", key: "jobStartDate" },
    { label: "Contract Duration", key: "contractDuration" },
    { label: "Work Location", key: "workLocation" },
    { label: "Work Hours", key: "workHours" },
    { label: "Working Days", key: "workingDays" },
    { label: "No. of work days per week", key: "weeklyWorkDays" },
    { label: "Salary Period", key: "salaryPeriod" },
    { label: "Pay Day", key: "payDay" },

    { label: "Basic Salary", key: "basicSalary" },
    { label: "Allowance", key: "allowance" },
    { label: "Job Grade", key: "jobGrade" },

    { label: "Annual Leave Entitlement", key: "yearlyAnnualLeave" },
    { label: "Probation Length", key: "probationLength" },
    { label: "Notice Period", key: "noticePeriod" },
    { label: "Contract Created Date ", key: "createdTime" },
    { label: "Currency", key: "currency" },
    { label: "CPF Eligibility", key: "cpfEligible" },
    { label: "End Date", key: "jobEndDate" },
    { label: "Contract Effective Date", key: "contractEffectiveDate" },
  ]);

  useEffect(() => {
    getCompanyDropdownOptions();
  }, []);

  useEffect(() => {
    if (datatable !== null) {
      datatable.exportCSV();
    }
  }, [reportData]);

  useEffect(() => {
    if (reportObj.companyID !== CommonMethods.EmptyGuid) {
      setReportObj({
        ...reportObj,
        employeeListStrArr: [],
        inactiveEmployeeListStrArr: [],
      });
      setSwitchButton(false);
      commonMethods
        .getUserDropdownByCompany(reportObj.companyID)
        .then((res) => {
          setUserDropdown(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
      commonMethods
        .getInactiveUsersDropdownByCompany(reportObj.companyID)
        .then((res) => {
          setInactiveUserDropdown(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
    }
  }, [reportObj.companyID]);

  useEffect(() => {
    setUserDropdown([]);
    setInactiveUserDropdown([]);
    setReportObj({
      ...reportObj,
      employeeListStrArr: [],
      inactiveEmployeeListStrArr: [],
    });
    setSwitchButton(false);
    if (reportType.groupCompany) {
      setReportObj({
        ...reportObj,
        companyID: CommonMethods.EmptyGuid,
        employeeListStrArr: [],
        inactiveEmployeeListStrArr: [],
      });
      commonMethods
        .getUserDropdownForGroupCompany()
        .then((res) => {
          setUserDropdown(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
      commonMethods
        .getInactiveUserDropdownByGroupCompany()
        .then((res) => {
          setInactiveUserDropdown(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(commonMethods.getErrorMessage(err));
        });
    }
  }, [reportType]);

  const getCompanyDropdownOptions = () => {
    commonMethods
      .getCompanyDropdownListByGcid()
      .then((res) => {
        setCompanyDropdown(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
        setErrorMsg(commonMethods.getErrorMessage(err));
      });
  };

  const companyDropdownDisplay = () => {
    if (reportType.company) {
      return (
        <div className="p-col-12">
          <span className="boldLabel">Company</span>
          <Dropdown
            value={reportObj.companyID}
            options={companyDropdown}
            onChange={(e) => {
              setReportObj({
                ...reportObj,
                companyID: e.value,
              });
            }}
            placeholder="Please Select"
            required
          />
        </div>
      );
    }
  };

  function generateContractReport() {
    if (reportObj.employeeListStrArr.length === 0 && reportObj.inactiveEmployeeListStrArr.length === 0) {
      SetError("Please select employees");
      return;
    }
    contractService
      .getContractReportDetails(reportObj)
      .then((res: any) => {
        res.forEach((contract: any) => {
          if (contract.jobStartDate) {
            contract.jobStartDate = contract.jobStartDate.split("T")[0];
          }
          if (contract.createdTime) {
            contract.createdTime = contract.createdTime.split("T")[0];
          }
          if (contract.contractEffectiveDate) {
            contract.contractEffectiveDate =
              contract.contractEffectiveDate.split("T")[0];
          }
        });
        setReportData(res);
      })
      .catch((err: any) => {
        SetError("Unable to export Employment History Report");
      });

    //contract service here
  }

  const displayContractReportPart = () => {
    return (
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="card">
            <h5 className="pb-4">
              Employment/Contract History Report
              <span data-for="employmenthistory" data-tip="">
                {" "}
                <i className="fas fa-info-circle informationStyle"></i>
              </span>
            </h5>
            <ReactTooltip
              id="employmenthistory"
              aria-haspopup="true"
              className="tooltipFont"
            >
              <p className="text-center mb-0">
                Retrieve employee's active and past contract details
              </p>
            </ReactTooltip>
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <span className="boldLabel">
                  Type <span style={{ color: "red" }}>*</span>
                </span>
                <div className="p-grid p-fluid pt-1">
                  <div className="p-col-12 p-field-radiobutton">
                    <RadioButton
                      inputId="GroupCompanyContract"
                      value={true}
                      name="GroupCompany"
                      onChange={(e) => {
                        setReportType({
                          ...reportType,
                          groupCompany: true,
                          company: false,
                        });
                      }}
                      checked={reportType.groupCompany === true}
                    />
                    <label htmlFor="GroupCompanyContract" className="ml-1">
                      Group Company
                    </label>

                    <RadioButton
                      inputId="CompanyContract"
                      value={true}
                      className="ml-3"
                      name="Company"
                      onChange={(e) => {
                        setReportType({
                          ...reportType,
                          groupCompany: false,
                          company: true,
                        });
                      }}
                      checked={reportType.company === true}
                    />
                    <label htmlFor="CompanyContract" className="ml-1">
                      Company
                    </label>
                  </div>
                </div>
              </div>
              {companyDropdownDisplay()}
              <div className="p-col-12">
                <span className="boldLabel">Employees</span>
                <MultiSelect
                  value={reportObj.employeeListStrArr}
                  options={userDropdown}
                  onChange={(e) => {
                    setReportObj({
                      ...reportObj,
                      employeeListStrArr: e.value,
                    });
                  }}
                  filter={true}
                  placeholder="Please Select"
                  disabled={userDropdown.length === 0}
                />
              </div>
              <div className="p-col-12">
                <span className="boldLabel">Include deactivated employees?</span>
                <div className="field-checkbox">
                    <InputSwitch 
                      checked={switchButton} 
                      onChange={(e) => {
                        setSwitchButton(e.value)
                        setReportObj({
                          ...reportObj,
                          inactiveEmployeeListStrArr: new Array<string>(),
                        });
                      }}
                      disabled={userDropdown.length ===0}
                    />
                </div>
              </div>
              {switchButton &&
                <div className="p-col-12">
                  <span className="boldLabel">Deactivated Employees</span>
                  <MultiSelect
                    value={reportObj.inactiveEmployeeListStrArr}
                    options={inactiveUserDropdown}
                    onChange={(e) => {
                      setReportObj({
                        ...reportObj,
                        inactiveEmployeeListStrArr: e.value,
                      });
                    }}
                    filter={true}
                    placeholder="Please Select"
                    disabled={inactiveUserDropdown.length === 0}
                  />
                </div>
              }
            </div>
            <div className="p-col-12 pt-4">
              <div className="p-grid p-justify-center">
                <DataTable
                  style={{ display: "none" }}
                  value={reportData}
                  ref={(el) => {
                    datatable = el;
                  }}
                  exportFilename={exportFileName}
                >
                  {contractHeaders[0].map((col: any, i) => {
                    return (
                      <Column
                        key={col.key}
                        field={col.key}
                        header={col.label}
                      />
                    );
                  })}
                </DataTable>
                <Button
                  className="p-button"
                  label="Download Report"
                  onClick={(e) => {
                    e.preventDefault();
                    generateContractReport();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  let page = <>{displayContractReportPart()}</>;

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return <>{display}</>;
}

export default ContractReport;
