import React from "react";
import { Button } from "primereact/button";

function Features() {
  return (
    <div>
      <section
        className="fdb-block"
        data-block-type="call_to_action"
        data-id="2"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Features</h1>
              {/* <p className="h3">
                {" "}
                Comprehensive modules to cater to your needs
              </p> */}
            </div>
          </div>

          <div className="row text-sm-left text-center align-items-center ">
            <div className="col-12 col-md-7 text-center">
              <img
                alt=""
                className="img-fluid featuresimg"
                src="/assets/thumbnails/Payroll.png"
              />
            </div>

            <div className="col-12 col-md-5 m-md-auto">
              <h2>
                <strong> Payroll</strong>
              </h2>
              <p className="lead">
                Forget spreads and manual calcuations. NeuHR helps you calculate
                SDL, CPF within seconds. Monthly payrolls can be exported into
                itemized payslips for each employee and bank accepted formats to
                issue salaries on time.
              </p>
            </div>
          </div>

          <div className="row text-sm-left text-center align-items-center ">
            <div className="col-12 col-md-7 m-md-auto order-md-6 text-center">
              <img
                alt=""
                className="img-fluid featuresimg"
                src="/assets/thumbnails/Dashboard.png"
              />
            </div>

            <div className="col-12 col-md-5">
              <h2>
                <strong>
                  Company Overview & <br></br>Boss Dashboard
                </strong>
              </h2>
              <p className="lead">
                NeuHR has a noticeboard that gives you an overview of who's in
                and who's out of office at a glance. Time is precious. Get an
                effective overview of how all your companies are doing in
                various areas at a glance.
              </p>
            </div>
          </div>

          <div className="row text-sm-left text-center align-items-center ">
            <div className="col-12 col-md-7 text-center">
              <img
                alt=""
                className="img-fluid featuresimg"
                src="/assets/thumbnails/Permissioncontrol.png"
              />
            </div>

            <div className="col-12 col-md-5">
              <h2>
                <strong>Permissions Control</strong>
              </h2>
              <p className="lead">
                NeuHR allows you to customize roles with access to different
                modules. Hybrid roles with HR and finance rights can be created
                with our Task Based Access Controls.
              </p>
            </div>
          </div>

          <div className="row text-sm-left text-center align-items-center ">
            <div className="col-12 col-md-7 m-md-auto order-md-6 text-center">
              <img
                alt=""
                className="img-fluid featuresimg"
                src="/assets/thumbnails/Facilitiesbooking.png"
              />
            </div>

            <div className="col-12 col-md-5 m-md-auto">
              <h2>
                <strong>Facilities Booking</strong>
              </h2>
              <p className="lead">
                Manage your facilities bookings effectively. A clear selection
                and calendar view of facilities make booking simpler. Every
                employee can be responsible for their own bookings.
              </p>
            </div>
          </div>

          <div className="row text-sm-left text-center align-items-center ">
            <div className="col-12 col-md-7 text-center">
              <img
                alt=""
                className="img-fluid featuresimg"
                src="/assets/thumbnails/Campaign.png"
              />
            </div>

            <div className="col-12 col-md-5 m-md-auto">
              <h2>
                <strong>Campaigns</strong>
              </h2>
              <p className="lead">
                Campaigns and Deliverables can be created and assigned to
                employees. View your outstanding tasks, together with an
                organized department overview. Track your current and
                accumulated progress over the year.
              </p>
            </div>
          </div>

          <div className="row text-sm-left text-center align-items-center ">
            <div className="col-12 col-md-7 m-md-auto order-md-6">
              <img
                alt=""
                className="img-fluid featuresimg"
                src="/assets/thumbnails/KPI.png"
              />
            </div>

            <div className="col-12 col-md-5 m-md-auto">
              <h2>
                <strong>KPI</strong>
              </h2>
              <p className="lead">
                Employees earn points as they complete assigned tasks! Keep
                track of your employees' performance without them feeling
                micromanaged.
              </p>
            </div>
          </div>

          <div className="row text-sm-left text-center align-items-center py-5 ">
            <div className="row align-items-center  pt-2 pt-lg-5">
              <div className="col-12 col-md-4">
                <h2>Human Resource Hub</h2>
                <p className="lead">
                  Lock down Key Employment Terms, Personal Particulars,
                  Supporting Certificates all on one platform. Onboarding
                  employees has never been easier! Our customizable office
                  management has got you covered!
                </p>
              </div>

              <div className="col-12 col-md-4">
                <h2>Leave</h2>
                <p className="lead">
                  Apply Leave, Approve/Reject Leave, Monitor Leave Status, Apply
                  on behalf of your subordinates, An overview of your
                  department's Leave Balance, Auto deduction on national
                  holidays.
                </p>
              </div>

              <div className="col-12 col-md-4">
                {" "}
                <h2>Claims</h2>
                <p className="lead">
                  Submit claims with supporting documents. Our 2 level approval
                  structure ensures better claims management. NeuHR has
                  multi-currency support and calculates tax amounts for you when
                  you input your tax values.
                </p>
              </div>
            </div>

            <div className="row align-items-center pt-5 pt-lg-5">
              {/* <div className="col-12 col-md-4">
                <h2>Equipment Tracking</h2>
                <p className="lead">
                  Who is this equipment issued to? When does the warrenty
                  expire? Track these easily with our equipment module.
                </p>
              </div> */}
              <div className="col-12 col-md-4">
                <h2>Reports Generation</h2>
                <p className="lead">
                  Generate reports for leaves, claims and employee profiles for
                  your selected employees in seconds!
                </p>
              </div>
              <div className="col-12 col-md-4">
                <h2>Document Upload</h2>
                <p className="lead">
                  Upload documents tagged to an employee or Campaign for easier
                  reference and storage.
                </p>
              </div>
            </div>
          </div>

          <div className="row align-items-center text-center justify-content-center py-5 colouredbgroundedcard">
            <div className="col-12">
              <h1 className="text-center">Ready for NeuHR?</h1>
            </div>
            <p style={{ fontSize: "22px" }}>
              A comprehensive SaaS HR Solution for your SME.
            </p>

            <div className="col-12 py-3">
              <Button
                className="btn btn-primary btn-darker mx-1 my-1"
                onClick={() => (window.location.href = "/contactus")}
                label="Request Demo"
              />
              <Button
                className="btn btn-primary btn-login"
                onClick={() => (window.location.href = "/sme")}
                label="Try for free"
              />
            </div>
            <b>90 days free trial. No credit card required.</b>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Features;
