import React, { useState, useEffect } from "react";
import {
  RouteComponentProps,
  CommonMethods,
  DropdownModel
} from "../resources/CommonMethods";
import {
  LogisticsStatusTypeModel,
  NewLogisticsModel
} from "../resources/ExportClass";
import { LogisticsService } from "../../service/LogisticsService";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import NumberFormat from "react-number-format";
import { Calendar } from "primereact/calendar";
import { ToastStateContext } from "../resources/ToastContext";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import { Link } from "react-router-dom";

const commonMethods = new CommonMethods();
const logsService = new LogisticsService();
/**
 * Display the page to Update Logistics (aka Equipments)
 * @param props
 */
function LogisticsUpdate(props: RouteComponentProps<any>) {
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  const [logisticsObj, setLogisticsObj] = useState<NewLogisticsModel>(
    new NewLogisticsModel()
  );
  const [userList, setUserList] = useState<Array<DropdownModel>>([]);
  const [companyList, setCompanyList] = useState<Array<DropdownModel>>([]);
  const [departmentList, setDepartmentList] = useState<Array<DropdownModel>>([
    { label: "-- Company Wide --", value: "" }
  ]);
  const [documentTypeList, setDocumentTypeList] = useState<
    Array<DropdownModel>
  >([]);
  const [statusList, setStatusList] = useState<Array<LogisticsStatusTypeModel>>(
    []
  );

  // ComponentDidMount
  useEffect(() => {
    getUserListForDropdown();
    geCompanyListForDropdown();
    getStatusTypeForDropdown();
    getDocumentTypeList();

    getEquipmentObject();
  }, []);

  // Functions
  const getUserListForDropdown = () => {
    commonMethods.getUserDropdownForGroupCompany().then(res => {
      setUserList(res);
    });
  };

  const geCompanyListForDropdown = () => {
    commonMethods.getCompanyDropdownListByGcid().then(res => {
      setCompanyList(res);
    });
  };
  const getDepartmentListForCompanyForDropdown = (companyID: string) => {
    if (companyID !== "" || companyID !== null || companyID !== undefined) {
      commonMethods.getDepartmentDropdownList(companyID).then(res => {
        var temp: Array<DropdownModel> = [
          { label: "-- Company Wide --", value: "" }
        ];
        temp = temp.concat(res);
        setDepartmentList(temp);
      });
    }
  };

  const getStatusTypeForDropdown = () => {
    logsService.getAllLogisticsStatusType().then(res => {
      setStatusList(res);
    });
  };

  const getDocumentTypeList = () => {
    commonMethods.getDocumentTypeDropdown().then(res => {
      setDocumentTypeList(res);
    });
  };

  const getEquipmentObject = () => {
    logsService
      .getEquipmentByLogsId(props.match.params.logisticsID)
      .then((res: NewLogisticsModel) => {
        setLogisticsObj(res);
        setLoadingStatus(false);
        if (res.issuedToCompany !== null) {
          getDepartmentListForCompanyForDropdown(res.issuedToCompany);
        }
      });
  };

  const uploadDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e !== null &&
      e.currentTarget.files !== null &&
      e.currentTarget.files[0] !== undefined
    ) {
      if (e.currentTarget.files![0].size > 2000000) {
        SetError(
          "File too big! Has to be below 2MB. Please compress your file before uploading."
        );
      } else {
        setLogisticsObj({
          ...logisticsObj,
          invoice: e.currentTarget.files[0]
        });
      }
    }
  };

  const updateEquipment = () => {
    logsService
      .updateEquipment(logisticsObj)
      .then(res => {
        if (res.length === 0) {
          SetError("There is already an equipment with existing serial!");
        } else {
          SetSuccess("Equipment has been Updated!");
        }
      })
      .catch(err => {
        SetError("Equipment cannot be update!");
      });
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Link to="/equipment" style={{ color: "black" }}>
            <i className="pi pi-angle-left" />
            Back
          </Link>
        </div>
      </div>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <div className="row">
          <div className="col-12 col-xl-6">
            <div className="card">
              <h1>Update Equipment</h1>
              <form className="card-body" onSubmit={updateEquipment}>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <span className="boldLabel">Equipment Name</span>
                      <InputText
                        style={{ width: "100%" }}
                        value={logisticsObj.equipmentName}
                        placeholder="Sony NP-FW50 Battery"
                        onChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            equipmentName: e.currentTarget.value
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <span className="boldLabel">Serial Number</span>
                      <InputText
                        style={{ width: "100%" }}
                        value={logisticsObj.equipmentSerialNo}
                        placeholder="TSL-2020-0001"
                        onChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            equipmentSerialNo: e.currentTarget.value
                          })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <span className="boldLabel">Shared?</span>
                      <InputSwitch
                        checked={logisticsObj.sharedItem}
                        onChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            sharedItem: e.value,
                            issuedToUser: "",
                            issuedToCompany: ""
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <span className="boldLabel">Issued To</span>
                      {logisticsObj.sharedItem ? (
                        <>
                          <Dropdown
                            style={{ width: "100%" }}
                            value={logisticsObj.issuedToCompany}
                            placeholder="Select a company"
                            options={companyList}
                            onChange={e => {
                              setLogisticsObj({
                                ...logisticsObj,
                                issuedToCompany: e.value
                              });
                              getDepartmentListForCompanyForDropdown(e.value);
                            }}
                            filter={true}
                            filterBy="label,value"
                          />
                          <Dropdown
                            style={{ width: "100%" }}
                            value={logisticsObj.issuedToDepartment}
                            placeholder="Select a Department"
                            options={departmentList}
                            onChange={e =>
                              setLogisticsObj({
                                ...logisticsObj,
                                issuedToDepartment: e.value
                              })
                            }
                            filter={true}
                            filterBy="label,value"
                            className="mt-2"
                          />
                        </>
                      ) : (
                        <Dropdown
                          style={{ width: "100%" }}
                          value={logisticsObj.issuedToUser}
                          placeholder="Select an employee"
                          options={userList}
                          onChange={e =>
                            setLogisticsObj({
                              ...logisticsObj,
                              issuedToUser: e.value
                            })
                          }
                          filter={true}
                          filterBy="label,value"
                        />
                      )}
                    </div>

                    <div className="form-group">
                      <span className="boldLabel">Status</span>
                      <Dropdown
                        style={{ width: "100%" }}
                        value={
                          statusList[
                            statusList.findIndex(
                              (el: LogisticsStatusTypeModel) =>
                                logisticsObj.logisticsStatusTypeID ===
                                el.logisticsStatusTypeID
                            )
                          ]
                        }
                        placeholder="Status"
                        options={statusList}
                        optionLabel="logisticsStatusTypeName"
                        onChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            logisticsStatusTypeID: e.value.logisticsStatusTypeID
                          })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <span className="boldLabel">Remarks</span>
                      <InputText
                        style={{ width: "100%" }}
                        value={logisticsObj.remarks}
                        placeholder="Remarks"
                        onChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            remarks: e.currentTarget.value
                          })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <span className="boldLabel">Unit Price</span>
                      <NumberFormat
                        placeholder="$100.00"
                        className="p-inputtext"
                        style={{ width: "100%" }}
                        value={logisticsObj.purchasedUnitPrice}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        onValueChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            purchasedUnitPrice: Number(e.value)
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <span className="boldLabel">Product Brand</span>
                      <InputText
                        style={{ width: "100%" }}
                        value={logisticsObj.productBrand}
                        placeholder="Sony"
                        onChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            productBrand: e.currentTarget.value
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <span className="boldLabel">Model Number</span>
                      <InputText
                        style={{ width: "100%" }}
                        value={logisticsObj.productModelNumber}
                        placeholder="NP-FW50"
                        onChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            productModelNumber: e.currentTarget.value
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <span className="boldLabel">Category</span>
                      <InputText
                        style={{ width: "100%" }}
                        value={logisticsObj.productCategory}
                        placeholder="Battery"
                        onChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            productCategory: e.currentTarget.value
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <span className="boldLabel">Detail</span>
                      <InputText
                        style={{ width: "100%" }}
                        value={logisticsObj.productDetail}
                        placeholder="i.e. Product Serial Number"
                        onChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            productDetail: e.currentTarget.value
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <span className="boldLabel">Purchased Date</span>
                      <Calendar
                        style={{ width: "100%" }}
                        showButtonBar={true}
                        selectionMode="single"
                        todayButtonClassName="p-justify-center"
                        
                        value={new Date(logisticsObj.purchasedDate)}
                        onChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            purchasedDate: new Date(e.value.toString())
                          })
                        }
                        inline={false}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <span className="boldLabel">Warranty Expiry Date</span>
                      <Calendar
                        style={{ width: "100%" }}
                        showButtonBar={true}
                        selectionMode="single"
                        todayButtonClassName="p-justify-center"
                        
                        value={new Date(logisticsObj.warrantyExpiryDate)}
                        onChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            warrantyExpiryDate: new Date(e.value.toString())
                          })
                        }
                        inline={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <span className="boldLabel">Invoice Type</span>
                      <Dropdown
                        style={{ width: "100%" }}
                        value={logisticsObj.invoiceDocumentType}
                        placeholder="Select Document Type"
                        options={documentTypeList}
                        onChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            invoiceDocumentType: e.value
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <span className="boldLabel">Upload Invoice / Warranty</span>
                    <div className="form-group">
                      <input
                        style={{ color: "transparent", width: "110px" }}
                        type="file"
                        name="uploadFile"
                        onChange={e => {
                          uploadDocument(e);
                        }}
                      />
                      {logisticsObj.invoice
                        ? "Current File: " + logisticsObj.invoice.name
                        : ""}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <span className="boldLabel">Invoice Remarks</span>
                      <InputText
                        style={{ width: "100%" }}
                        value={logisticsObj.invoiceRemarks}
                        placeholder="Invoice Remarks"
                        onChange={e =>
                          setLogisticsObj({
                            ...logisticsObj,
                            invoiceRemarks: e.currentTarget.value
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 form-group">
                    <Button
                      label="Update"
                      onClick={e => {
                        e.preventDefault();
                        updateEquipment();
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default LogisticsUpdate;
