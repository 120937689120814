import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { DeliverableTypeService } from "../../service/DeliverableTypeService";
import {
  CommonMethods,
  RouteComponentProps,
  ActionTypes,
  ParamIDTypes,
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { DeliverableTypeModel } from "./DeliverableTypeModel";
import { TaskList } from "../resources/ListOfTasks";

function DeliverableTypeCreate(props: RouteComponentProps) {
  const deliverableTypeService = new DeliverableTypeService();
  const commonMethods = new CommonMethods();
  const { id } = useParams<ParamIDTypes>();
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const [deliverableTypeData, setDeliverableTypeData] = useState<
    DeliverableTypeModel
  >(new DeliverableTypeModel());
  const [action, setAction] = useState<string>(ActionTypes.create);

  const [isError, setisError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [toHome, setToHome] = useState(false);

  const [displayKPI, setDisplayKPI] = useState(false);

  useEffect(() => {
    if (id !== undefined) {
      deliverableTypeService
        .getDeliverableTypeByID(id)
        .then((res) => {
          setDeliverableTypeData(res);
        })
        .catch((err) => {
          setisError(true);
          setErrorMessage(commonMethods.getErrorMessage(err));
        });

      setAction(ActionTypes.update);
    }

    commonMethods
      .isGroupCompanyTSL(props.userProfile.groupCompanyID)
      .then((res) => {
        if (
          res &&
          props.userProfile.taskList.includes(TaskList.scoreindicatoraccess)
        ) {
          setDisplayKPI(true);
        }
        setIsLoading(false);
      })
      .catch(() => setDisplayKPI(false));
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (action === ActionTypes.create) {
      deliverableTypeService
        .createDeliverableType(deliverableTypeData)
        .then((res) => {
          SetSuccess("New Deliverable Type created!");
          setToHome(true);
        })
        .catch((err) => {
          // SetError("Could not create new deliverable type.");
          SetError(commonMethods.getErrorMessage(err));
        });
    } else if (action === ActionTypes.update) {
      deliverableTypeService
        .updateDeliverableType(deliverableTypeData)
        .then((res) => {
          SetSuccess("Deliverable Type updated!");
          setToHome(true);
        })
        .catch((err) => {
          // SetError("Could not update deliverable type.");
          SetError(commonMethods.getErrorMessage(err));
        });
    }
  };

  const displayKPIpart = () => {
    if (displayKPI) {
      return (
        <div className="p-col-12">
          <span className="boldLabel">Deliverable KPI Point:</span>
          <InputNumber
            value={deliverableTypeData.deliverablePoint}
            onChange={(e) => {
              if (!isNaN(Number(e.value))) {
                setDeliverableTypeData({
                  ...deliverableTypeData,
                  deliverablePoint: e.value,
                });
              }
            }}
            min={0}
            max={200}
            step={1}
            required
            showButtons
          />
        </div>
      );
    }
  };

  const form = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">
              Deliverable Type Name <span className="requiredRed">*</span>
            </span>
            <InputText
              value={deliverableTypeData.deliverableTypeName}
              onChange={(event) =>
                setDeliverableTypeData({
                  ...deliverableTypeData,
                  deliverableTypeName: event.currentTarget.value,
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Deliverable Type Description{" "}
              <span className="requiredRed">*</span>
            </span>
            <InputText
              value={deliverableTypeData.deliverableTypeDescription}
              onChange={(event) =>
                setDeliverableTypeData({
                  ...deliverableTypeData,
                  deliverableTypeDescription: event.currentTarget.value,
                })
              }
              required
            />
          </div>

          {displayKPIpart()}
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button type="submit" label={action} />
        </div>
      </form>
    );
  };

  let display;
  if (isLoading) {
    display = <ProgressSpinner />;
  } else if (isError) {
    display = <CustomError message={errorMsg} />;
  } else {
    display = form();
  }

  return (
    <div className="row">
      {toHome ? <Redirect push to="/admin/deliverable_type" /> : null}
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
        <div className="card">
          <h1 className="pb-2">{action} Deliverable Type</h1>
          <div className="requiredRed">
            <p>* Required</p>
          </div>
          {display}
        </div>
      </div>
    </div>
  );
}

export default DeliverableTypeCreate;
