import React, { useContext, useEffect, useRef } from 'react'
import CompanyFeedService from '../../Service/CompanyFeedService'
import { PostModel } from '../../Model/PostModel'
import Posts from './Posts'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ProgressSpinner } from 'primereact/progressspinner';
import { CompanyFeedContext } from '../../CompanyFeed'
import { ACTIONS } from '../../DispatchActions'

export default function PostsContainer({
    posts,
    page,
    hasMore,
    fromDashboard
}: {
    posts: PostModel[];
    page: number;
    hasMore: boolean;
    fromDashboard: boolean;
}) {
    const { dispatch } = useContext(CompanyFeedContext);
    const postsPerPage = 30;
    const isMounted = useRef<boolean>(true);
    const ider = document.getElementsByClassName("p-tabview-panel")[0]?.id;

    useEffect(() => {
        async function getPosts() {
            const companyFeedService = new CompanyFeedService()
            const result = await companyFeedService.getPosts(1)
            if (isMounted.current) {
                if (1 > result.totalPosts / postsPerPage) {
                    dispatch({ type: ACTIONS.OFF_HASMORE, payload: null })
                } else {
                    dispatch({ type: ACTIONS.ON_HASMORE, payload: null })
                }
                dispatch({ type: ACTIONS.LOAD_POSTS, payload: result.posts })
                dispatch({ type: ACTIONS.NEXT_PAGE, payload: null })
            }
        }
        getPosts()
        return () => {
            isMounted.current = false
        }
    }, [dispatch])

    // TODO: fix error: (Can't call setState (or forceUpdate) on an unmounted component)
    // appears occasionally after navigating to PublicProfile
    // unsure why, try making Promise cancellable?
    // axios cancel token?
    async function getPosts() {
        const companyFeedService = new CompanyFeedService()
        const result = await companyFeedService.getPosts(page)
        // console.log('getPosts', result)
        if (isMounted.current) {
            if (page > result.totalPosts / postsPerPage) {
                dispatch({ type: ACTIONS.OFF_HASMORE, payload: null })
            } else {
                dispatch({ type: ACTIONS.ON_HASMORE, payload: null })
            }
            dispatch({ type: ACTIONS.LOAD_POSTS, payload: result.posts })
            dispatch({ type: ACTIONS.NEXT_PAGE, payload: null })
        }
    }


    if (posts == null) {
        return null
    } else {
        return (
            <div>
                <div>

                    {
                    fromDashboard ? 
                    (ider && 
                        <InfiniteScroll
                        scrollableTarget={ider}
                        dataLength={posts.length}
                        next={getPosts}
                        hasMore={hasMore}
                        loader={<ProgressSpinner className="loader-centered loader-size" />}
                        endMessage={
                            <p className="textalign-center">
                                <b>You've reached the end!</b>
                            </p>
                        }>
                        <Posts
                            posts={posts}
                        />
                    </InfiniteScroll>
                    )
                    :
                    <InfiniteScroll
                        dataLength={posts.length}
                        next={getPosts}
                        hasMore={hasMore}
                        loader={<ProgressSpinner className="loader-centered loader-size" />}
                        endMessage={
                            <p className="textalign-center">
                                <b>You've reached the end!</b>
                            </p>
                        }>
                        <Posts
                            posts={posts}
                        />
                    </InfiniteScroll>
                    }
                </div>
            </div>
        )
    }
}



