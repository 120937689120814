import React from 'react'
import placeholder from '../../Assets/company-placeholder.jpg'

export default function CompanyLogo({ companyLogo }: { companyLogo: string }) {

    function handleError(e: React.SyntheticEvent<HTMLImageElement, Event>) {
        e.currentTarget.src = placeholder
    }

    return <img
        className="profile-companylogo"
        src={companyLogo ?? placeholder}
        alt=""
        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
    />
}