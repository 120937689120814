import React from 'react'
import { PostModel } from '../../Model/PostModel'
import SinglePost from '../SinglePost/SinglePost'

export default function TodayBirthdayPost({ post }: { post: PostModel | null }) {
    if (post == null) return null
    return (
        <SinglePost post={post} contextName="CompanyFeed" parentComponent="TodayBirthdayPost" isLoaded={true} />
    )
}
