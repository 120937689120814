import React from 'react'
import { toTitleCase } from '../../CompanyFeedFunctions'
import { PostModel, UserDetails } from '../../Model/PostModel'

export default function ProfileName({item}: {item: PostModel | UserDetails;}) {

    
    return (
        <div className="text-bold cursor-pointer hover-underline">
            {toTitleCase(item.firstName) + ' ' + toTitleCase(item.lastName)}
        </div>
    )
}

