import React from 'react'


export default function CloseFullScreenButton({ isFullscreenImage, setIsFullscreenImage }: {
    isFullscreenImage: boolean; setIsFullscreenImage: React.Dispatch<React.SetStateAction<boolean>>
}) {

    function closeFullscreen() {
        setIsFullscreenImage(false)
    }

    return (
        <span
            className={isFullscreenImage ? "material-icons md-30 fullscreen-close" : "display-none"}
            onClick={closeFullscreen}
        >close</span>
    )
}