import moment from "moment";
import { HttpClient } from "./HttpClient";
import { ClientCompanyModel } from "../components/client/AllClientCompanies";
import { convertObjToFormData } from "../components/resources/CommonMethodsServices";

export class ClientCompanyService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async getClientCompaniesIfNotSupplier() {
    const res = this.httpClient.get("/api/client/Notsupplier");
    return res;
  }

  async filterClientCompaniesByDate(
    meetingDateStart: Date,
    meetingDateEnd: Date
  ) {
    const meetingDateStartString = moment(meetingDateStart).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const meetingDateEndString = moment(meetingDateEnd).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const res = await this.httpClient.get(
      `/api/client/Notsupplier/${meetingDateStartString}/${meetingDateEndString}`
    );
    return res;
  }

  async getClientCompaniesIfIsSupplier() {
    const res = this.httpClient.get("/api/client/Issupplier");
    return res;
  }

  async createClientCompany(clientCompanyObj: any) {
    const res = this.httpClient.post(
      "/api/client/create",
      convertObjToFormData(clientCompanyObj)
    );
    return res;
  }

  async updateClientCompany(clientCompanyObj: any) {
    const res = this.httpClient.put(
      "/api/client/update",
      convertObjToFormData(clientCompanyObj)
    );
    return res;
  }

  async getClientCompanyByID(id: string) {
    const res = this.httpClient.get("/api/client/" + id);
    return res;
  }

  async deleteClientCompanyByID(id: string) {
    const res = this.httpClient.delete("/api/client/delete/" + id);
    return res;
  }

  async deactivateClientCompany(ID: string) {
    const res = this.httpClient.get("/api/client/deactivate/" + ID);
    return res;
  }
  async activateClientCompany(ID: string) {
    const res = this.httpClient.get("/api/client/activate/" + ID);
    return res;
  }

  async updateClientCompanyStatus(ClientCompanyObj: ClientCompanyModel) {
    const res = this.httpClient.put(
      "/api/client/updatestatus",
      ClientCompanyObj
    );
    return res;
  }

  async createCompanyandClient(obj: any) {
    const res = this.httpClient.post("/api/client/companyandclient", obj);
    return res;
  }
}
