import React, { useContext, useEffect, useState } from 'react'
import { getContext } from '../../CompanyFeedFunctions'
import { ACTIONS } from '../../DispatchActions'
import { CommentModel } from '../../Model/CommentModel'
import { PreviewProps, UserPreview } from '../../Model/PostModel'
import CompanyFeedService from '../../Service/CompanyFeedService'
import Comment from './Comment'
import ReplyInput from './ReplyInput'

export default function Comments({
    postId,
    postCommentIdentifier,
    comments,
    previewData,
    setPreviewData,
    previewProps,
    setPreviewProps,
    viewMoreComments,
    setViewMoreComments,
    viewMoreThreshold,
    setViewMoreThreshold,
    contextName,
    parentComponent,
}: {
    postId: string;
    postCommentIdentifier: string;
    comments: CommentModel[] | null;
    previewData: UserPreview | null
    setPreviewData: React.Dispatch<React.SetStateAction<UserPreview | null>>
    previewProps: PreviewProps
    setPreviewProps: React.Dispatch<React.SetStateAction<PreviewProps>>
    viewMoreComments: boolean
    setViewMoreComments: React.Dispatch<React.SetStateAction<boolean>>
    viewMoreThreshold: number
    setViewMoreThreshold: React.Dispatch<React.SetStateAction<number>>
    contextName: string
    parentComponent: string | undefined
}) {
    const { dispatch } = useContext(getContext(contextName));
    const [isEditMode, setIsEditMode] = useState(false)
    const [hoveringOverComment, setHoveringOverComment] = useState("")
    const [display, setDisplay] = useState("none")
    const showOnHover = {
        display: display
    };
    const [editCommentIdentifier, setEditCommentIdentifier] = useState('')
    const [replyCommentIdentifier, setReplyCommentIdentifier] = useState('')
    // CommentReplyCounter used to focus input, avoided using editorRef to focus as the input(CreateComment > CommentDraftJsEditor) is used by multiple components 
    // which would require passing editorRef through all components even if they didn't require editorRef.current.focus()
    const [commentReplyCounter, setCommentReplyCounter] = useState(0)
    const [renderedComments, setRenderedComments] = useState(comments)

    useEffect(() => {
        setRenderedComments(() => {
            if (comments) {
                if (comments.length <= viewMoreThreshold || viewMoreComments) return comments
                if (comments.length > viewMoreThreshold && !viewMoreComments) return comments.slice(0, viewMoreThreshold)
            }
            return comments
        })
    }, [comments, viewMoreThreshold, viewMoreComments])

    // if postCommentIdentifier changes, get comments
    useEffect(() => {
        async function LoadComments() {
            let companyFeedService = new CompanyFeedService();
            try {
                console.log('try getComments, postId: ', postId)
                const result = await companyFeedService.getComments(postId);
                if (parentComponent === "TodayBirthdayPost") dispatch({ type: ACTIONS.BIRTHDAY_POST.LOAD_COMMENTS, payload: { comments: result, postId } })
                else dispatch({ type: ACTIONS.LOAD_COMMENTS, payload: { comments: result, postId } })
                console.log('Loaded Comments', result)
            } catch (error) {
                console.log('getComments ERROR', error)
            }
        }
        LoadComments()
    }, [postCommentIdentifier, postId, dispatch, parentComponent])

    return (
        <div className="grid-container-comments">
            {renderedComments?.map((item, index) => {
                return (
                    <div key={item.commentId} className="grid-item-comments">
                        <Comment
                            comment={item}
                            previewData={previewData}
                            setPreviewData={setPreviewData}
                            previewProps={previewProps}
                            setPreviewProps={setPreviewProps}
                            setReplyCommentIdentifier={setReplyCommentIdentifier}
                            isEditMode={isEditMode}
                            setIsEditMode={setIsEditMode}
                            showOnHover={showOnHover}
                            hoveringOverComment={hoveringOverComment}
                            setHoveringOverComment={setHoveringOverComment}
                            setDisplay={setDisplay}
                            editCommentIdentifier={editCommentIdentifier}
                            setEditCommentIdentifier={setEditCommentIdentifier}
                            contextName={contextName}
                            setViewMoreThreshold={setViewMoreThreshold}
                            setCommentReplyCounter={setCommentReplyCounter}
                            parentComponent={parentComponent}
                        />
                        <div className="reply-comment-input">
                            <ReplyInput
                                comment={item}
                                replyCommentIdentifier={replyCommentIdentifier}
                                setViewMoreThreshold={setViewMoreThreshold}
                                commentReplyCounter={commentReplyCounter}
                                contextName={contextName}
                                parentComponent={parentComponent}
                            />
                        </div>
                    </div>
                )
            })}
            {(!viewMoreComments && comments != null && comments.length > viewMoreThreshold) ?
                <span className="view-more-comments" onClick={() => setViewMoreComments(true)}>View More Comments</span>
                : null}
        </div>
    )
}







