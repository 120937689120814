import { HttpClient } from "./HttpClient";
import { GroupCompanyModel } from "../components/groupcompany/GroupCompany";
import { CommonMethods } from "../components/resources/CommonMethods";
export class GroupCompanyService {
  httpClient: HttpClient;
  commonMethods: CommonMethods;
  constructor() {
    this.httpClient = new HttpClient();
    this.commonMethods = new CommonMethods();
  }

  async getGroupCompanies() {
    const res = await this.httpClient.get("/api/groupcompany");
    return res;
  }

  async getGroupCompanyById(id: string) {
    const res = await this.httpClient.get("/api/groupcompany/" + id);
    return res;
  }

  async createGroupCompany(groupCompanyObj: GroupCompanyModel) {
    const res = await this.httpClient.post(
      "/api/groupcompany/create",
      groupCompanyObj
    );
    return res;
  }

  async updateGroupCompany(groupCompanyObj: any) {
    const res = await this.httpClient.put(
      "/api/groupcompany/update",
      groupCompanyObj
    );
    return res;
  }

  async updateOrientationStatus(id: string) {
    const res = await this.httpClient.get(
      "/api/groupcompany/orientation/" + id
    );
    return res;
  }

  async getAllGroupCompanies() {
    const res = await this.httpClient.get("/api/groupcompany/all");
    return res;
  }

  async updatePlanOfGroupCompany(groupcompanyobj: GroupCompanyModel) {
    const res = await this.httpClient.put("/api/groupcompany/plan",groupcompanyobj);
    return res;
  }
}
