import Axios from "axios";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import * as React from "react";
import { Redirect } from "react-router";
import { AccountManagementService } from "../../service/AccountManagementService";
import { PayslipService } from "../../service/PayslipService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { CompanyDisplay, UserPayrollModel } from "../resources/ExportClass";
import { ToastStateContext } from "../resources/ToastContext";
import { PayrollTableModel } from "../resources/ExportClass";
import { PayrollSummary } from "./PayrollSummary";
import { PayrollTable } from "./PayrollTable";

export class PayrollModel {
  public userPayrollModelList: Array<UserPayrollModel> = new Array<
    UserPayrollModel
  >();
  public payrollPeriod: Date = new Date();
}
interface AppState {
  accounts: Array<CompanyDisplay>;
  companyAccount: CompanyDisplay;
  payslipObjects: Array<UserPayrollModel>;
  payrollModel: PayrollModel;

  swiftCodeList: { bankCode: string; swiftcode: string }[];

  isLoading: boolean;
  redirectOut: boolean;
  redirectPath: string;

  isError: boolean;
  errorMsg: string;

  isPremiumPlan: boolean;

  payrollID: string;
}

export class PayrollCurrent extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  payslipService: PayslipService;
  accMgmt: AccountManagementService;
  thisDate: Date = new Date();
  commonMethods: CommonMethods;

  static contextType = ToastStateContext;

  finance = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "SGD",
    minimumFractionDigits: 2,
  });

  dtExport: DataTable | null = null;
  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      accounts: [],
      companyAccount: new CompanyDisplay(),
      payslipObjects: [],
      payrollModel: new PayrollModel(),
      swiftCodeList: [],

      isLoading: true,
      redirectOut: false,
      redirectPath: "",

      isError: false,
      errorMsg: "",

      payrollID: this.props.match.params.id,

      isPremiumPlan: false,
    };

    // SERVICE SET
    this.payslipService = new PayslipService();
    this.accMgmt = new AccountManagementService();
    this.commonMethods = new CommonMethods();

    // BINDING
    this.getAllAccounts = this.getAllAccounts.bind(this);

    // BIND RENDER
    this.renderPayrollTable = this.renderPayrollTable.bind(this);
    this.markPayrollComplete = this.markPayrollComplete.bind(this);
  }

  componentDidMount() {
    this.initPage();
  }

  componentDidUpdate() {
    // Temp disabled for continuous checking & prevention of multiple calls
    // if (this.commonMethods.isPropsLoaded(this.props)) {
    //   this.updateAndNotify();
    // }
  }

  initPage() {
    this.getAllAccounts(
      "company",
      localStorage.getItem("currentAccessingCompany") || ""
    );
    this.commonMethods.hasPremiumPlanAccess().then((res) => {
      this.setState({ isPremiumPlan: res });
    });
  }

  // API CALL
  getAllAccounts(type: string, id: string) {
    this.accMgmt
      .getAllAccounts(type, id)
      .then((res) => {
        this.setState({ companyAccount: res[0] }, () => {
          this.setupCalls();
        });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  setupCalls() {
    Axios.all([
      this.commonMethods.getSwiftCode(),
      this.payslipService.getAllPayslipByPayrollID(this.state.payrollID),
    ])
      .then(
        Axios.spread((first: any, second: PayrollModel) => {
          this.setState({
            swiftCodeList: first,
            payrollModel: second,
            payslipObjects: second.userPayrollModelList,
            isLoading: false,
          });
        })
      )
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }
  // END API CALL

  // ------------------ DATATABLE DISPLAY METHODS ---------------- END
  calculateTotalNetPayable() {
    let total = 0;
    this.state.payslipObjects.forEach((obj, index) => {
      total += obj.netSalary;
    });
    return total;
  }
  calculateTotalEmployeeCPF() {
    let total = 0;
    this.state.payslipObjects.forEach((obj, index) => {
      total += obj.employeeCPFValue;
    });
    return total;
  }
  calculateTotalEmployerCPF() {
    let total = 0;
    this.state.payslipObjects.forEach((obj, index) => {
      total += obj.employerCPFValue;
    });
    return total;
  }
  calculateTotalSHG() {
    let total = 0;
    this.state.payslipObjects.forEach((obj, index) => {
      total += obj.shgValue;
    });
    return total;
  }
  calculateTotalSDL() {
    let total = 0;
    this.state.payslipObjects.forEach((obj, index) => {
      total += obj.sdf;
    });
    total = Math.floor(total);
    return total;
  }
  getSummary() {
    let others = {
      totalNetPay: this.calculateTotalNetPayable(),
      totalCPF:
        this.calculateTotalEmployerCPF() + this.calculateTotalEmployeeCPF(),
      totalSHG: this.calculateTotalSHG(),
      totalSDL: this.calculateTotalSDL(),
      isPremium: this.state.isPremiumPlan,
    };
    return others;
  }

  renderPayrollTable() {
    return (
      <PayrollTable
        {...this.props}
        others={{
          companyAccount: this.state.companyAccount,
          swiftCodeList: this.state.swiftCodeList,
          payrollModel: this.state.payrollModel,
          payslipObjects: this.state.payslipObjects,
          isPremiumPlan: this.state.isPremiumPlan,
		  completed: false,
        }}
      />
    );
  }

  markPayrollComplete() {
    const payroll = new PayrollTableModel();
    payroll.payrollID = this.state.payrollID;
    payroll.payrollStatus = "Completed";
    payroll.paymentDate = new Date(); //doesnt matter
    this.payslipService
      .updatePayrollStatus(payroll)
      .then((res) => {
        // redirect back to payrolls
        this.context.SetSuccess("Payroll is now completed");
        this.setState({
          redirectOut: true,
          redirectPath: "/finance/payroll",
        });
      })
      .catch((err) => {
        let errMsg = this.commonMethods.getErrorMessage(err);
        this.context.SetError(errMsg);
      });
  }
  // END RENDER FUNCTIONS
  render() {
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
          }}
        />
      );
    }

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />; // or some growl/message that stays
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = (
        <>
          <h5 className="p-col-12 text-center">
            {this.commonMethods.formatDateDisplayForPayrollPeriod(this.state.payrollModel.payrollPeriod)}

          </h5>
          <div className="row justify-content-around px-2">
            <div className="col-12 col-md-4  justify-content-center">
              Note: Click Add to include recently deactivated employees to
              current payroll <br></br>{" "}
            </div>

            <div className="col-12 col-md-4 pb-2 text-center">
              <span className="boldLabel">Company</span>
              <p>{this.state.companyAccount.companyName}</p>

              <span className="boldLabel">Account</span>
              <p>
                {this.state.companyAccount.bankName} -{" "}
                {this.state.companyAccount.accountNumber}
              </p>
              <span className="boldLabel">Pay Day</span>
              <p>
                {this.commonMethods.displayDate_Words(
                  this.state.payrollModel.payrollPeriod
                )}
              </p>
            </div>
            <div className="col-12 col-md-4  justify-content-center"></div>
          </div>
          <div className="row p-justify-center text-center">
            <this.renderPayrollTable />
            <Button
              tooltip="Marks Payroll as Completed"
              tooltipOptions={{ position: "top" }}
              label="Publish Payroll"
              className="p-button-success"
              onClick={this.markPayrollComplete}
            />
            <div className="p-col-12">
              <div className="col-12 col-lg-4 offset-lg-8 card p-0">
                <PayrollSummary {...this.props} others={this.getSummary()} />
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <h1 className="pb-2">
                Payroll{" "}
                <span className="pl-3">
                  <Button
                    label="Add employee"
                    tooltip="Add employee into payroll"
                    tooltipOptions={{ position: "right" }}
                    type="button"
                    icon="pi pi-plus"
                    onClick={(e) => {
                      this.setState({
                        redirectOut: true,
                        redirectPath:
                          "/finance/payroll/add_payslip/" +
                          this.state.payrollID,
                      });
                    }}
                    className="p-button-danger"
                  />
                </span>
              </h1>

              {display}
            </div>
          </div>
        </div>
      </>
    );
  }
}
