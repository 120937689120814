import React from 'react'
const placeholder = require('../../Assets/profileplaceholder.jpg')

export default function ProfilePicture({displayPicture}: {displayPicture: string | null;}) {

    function handleError(e: React.SyntheticEvent<HTMLImageElement, Event>) {
        e.currentTarget.src = placeholder
    }
    
    return (
        <div>
            <img
                className="p-avatar-circle cursor-pointer hover-greyedout"
                src={displayPicture ?? placeholder}
                alt=""
                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
            />
        </div>

    )
}