import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Redirect, useParams } from "react-router";
import ReactTooltip from "react-tooltip";
import CampaignService from "../../service/CampaignService";
import DeliverableService from "../../service/DeliverableService";
import {
  CommonMethods,
  RouteComponentProps,
  ActionTypes,
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { DeliverableModel } from "./DeliverablesInCampaign";
import { TaskList } from "../resources/ListOfTasks";
import { CampaignModel } from "../campaign/Campaign";

function DeliverableCreate(props: RouteComponentProps<any>) {
  let deliverableService = new DeliverableService();
  const campaignService = new CampaignService();
  let commonMethods = new CommonMethods();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [toHome, setToHome] = useState(false);
  const [toHomePath, setToHomePath] = useState<string>("");
  const [action, setAction] = useState(ActionTypes.create);
  const [deliverableObj, setDeliverableObj] = useState<DeliverableModel>(
    new DeliverableModel()
  );

  const [deliverableTypeDropdown, setDeliverableTypeDropdown] = useState<[]>(
    []
  );
  const [publisherDropdown, setPublisherDropdown] = useState<[]>([]);
  // const [currencyDropdown, setCurrenctyDropdown] = useState<any[]>([]);
  const [usersDropdown, setUsersDropdown] = useState<[]>([]);
  const [deliverableStatusDropdown, setDeliverableStatusDropdown] = useState<
    any[]
  >([]);
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const [displayDelValue, setDisplayDelValue] = useState<boolean>(false);
  const [isSalesFinanceView] = useState(
    props.userProfile.taskList.includes(TaskList.salesaccess) ||
      props.userProfile.taskList.includes(TaskList.financeaccess)
  );
  const [insightsrnaccess] = useState(
    props.userProfile.taskList.includes(TaskList.insightsrnaccess)
  );
  const [canPublish, setCanPublish] = useState<boolean>(false);
  // const [displayClosedDetails, setDisplayClosedDetails] = useState<boolean>(
  //   false
  // );

  const { campaignid, deliverableid } : any = useParams();

  useEffect(() => {
    if (campaignid === undefined) {
      setIsError(true);
      setIsLoading(false);
      setErrorMessage("Invalid Entry");
      return;
    }

    getDropdown();
    setToHomePath("/campaign/summary/" + campaignid);
    if (deliverableid !== undefined && deliverableid !== "") {
      // update
      deliverableService
        .getDeliverableByUrl(deliverableid)
        .then((res: DeliverableModel) => {
          if (res.deliverableLink !== "") {
            let linkArr = res.deliverableLink.split(";");
            res.deliverableLinkArr = linkArr;
          } else if (
            res.deliverableLink === null ||
            res.deliverableLink === ""
          ) {
            res.deliverableLinkArr = [];
          }
          setAction(ActionTypes.update);
          setDeliverableObj(res);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMessage(commonMethods.getErrorMessage(err));
        });
      campaignService
        .getCampaignByCampaignID(campaignid)
        .then((res) => {
          if (res.campaignTypeName === "Advertorial" && isSalesFinanceView) {
            setDisplayDelValue(true);
          }
          setIsLoading(false);
		  if (res.campaignProjectManagersIDStrArr.length != 0){
			setCanPublish(true);
		  }
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMessage(commonMethods.getErrorMessage(err));
        });
    } else {
      // create
      campaignService
        .getCampaignByCampaignID(campaignid)
        .then((res) => {
          setDeliverableObj({
            ...deliverableObj,
            campaignID: res.campaignID,
          });
          if (res.campaignTypeName === "Advertorial" && isSalesFinanceView) {
            setDisplayDelValue(true);
          }
          // setDeliverableObj(d => d.campaignID = res.campaignID);
          setIsLoading(false);
		  if (res.campaignProjectManagersIDStrArr.length != 0){
			setCanPublish(true);
		  }
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMessage(commonMethods.getErrorMessage(err));
        });
    }
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

	if (deliverableObj.deliverableStatus == "Published" && !canPublish) {
		const error = "Cannot publish a deliverable without a Project Manager assigned to the campaign"
		SetError(error);
		return;
	}
    if (action === ActionTypes.update) {
      deliverableService
        .updateDeliverable(deliverableObj)
        .then(() => {
          SetSuccess(deliverableObj.deliverableName + " has been updated.");
          setToHome(true);
        })
        .catch((err) => {
          const error = commonMethods.getErrorMessage(err);
          SetError(error);
        });
    } else if (action === ActionTypes.create) {
      deliverableService
        .createDeliverable(deliverableObj)
        .then(() => {
          SetSuccess(deliverableObj.deliverableName + " has been created.");
          setToHome(true);
        })
        .catch((err) => {
          const error = commonMethods.getErrorMessage(err);
          SetError(error);
        });
    }
  };

  const getDropdown = () => {
    commonMethods
      .getDeliverableTypeDropdown()
      .then((res) => {
        setDeliverableTypeDropdown(res);
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(commonMethods.getErrorMessage(err));
      });

    commonMethods
      .getPublisherDropdown()
      .then((res) => {
        setPublisherDropdown(res);
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(commonMethods.getErrorMessage(err));
      });

    commonMethods
      .getUserDropdownForGroupCompany()
      .then((res) => {
        setUsersDropdown(res);
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(commonMethods.getErrorMessage(err));
      });
      setDeliverableStatusDropdown(commonMethods.getDeliverableStatusDropdown());
  };

  // const updateDeliverableChipsArr = (e: {
  //   originalEvent: Event;
  //   value: any;
  // }) => {
  //   let stringified = e.value.join(";");
  //   setDeliverableObj({
  //     ...deliverableObj,
  //     deliverableLinkArr: e.value,
  //     deliverableLink: stringified,
  //   });
  // };

  let closedByDetails = (
    <>
      {/* <div className="p-col-12">
        <span className="boldLabel">Closed By</span>
        <Dropdown
          value={deliverableObj.closedBy}
          options={usersDropdown}
          onChange={(e) =>
            setDeliverableObj({
              ...deliverableObj,
              closedBy: e.value,
            })
          }
          placeholder="Please Select"
        />
      </div> */}
      <div className="p-col-12">
        <span className="boldLabel">Published Date</span>
        <Calendar
          dateFormat="dd/mm/yy"
          value={new Date(deliverableObj.closedDate)}
          onChange={(e) => {
            setDeliverableObj({
              ...deliverableObj,
              closedDate: new Date(e.value.toString()),
            });
          }}
          showButtonBar={true}
          showIcon={true}
          monthNavigator={true}
          yearNavigator={true}
          yearRange="1900:2030"
          // maxDate={this.state.maxDate}
          showTime={false}
          readOnlyInput={true}

          required
        />
      </div>
    </>
  );

  let deliverableValueDisplay;
  if (displayDelValue) {
    deliverableValueDisplay = (
      <div className="p-col-12">
        <span className="boldLabel">Deliverable Value</span>
        <NumberFormat
          className="p-inputtext"
          value={deliverableObj.deliverableValue}
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          onValueChange={(values) => {
            setDeliverableObj({
              ...deliverableObj,
              deliverableValue: values.floatValue!,
            });
          }}
        />
      </div>
    );
  }

  let irnPage = (
    <form onSubmit={handleSubmit}>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <span className="boldLabel">
            Deliverable Name <span style={{ color: "red" }}>*</span>{" "}
            <span className="text-info">* </span>
          </span>
          <InputText
            value={deliverableObj.deliverableName}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                deliverableName: e.currentTarget.value,
              })
            }
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel" data-tip="" data-for="gotoirn">
            Publisher <span style={{ color: "red" }}>*</span>{" "}
            <span className="text-info">* </span>
          </span>
          <ReactTooltip
            id="gotoirn"
            aria-haspopup="true"
            className="tooltipFont"
          >
            <p className="text-center">
              Our list of Publishers are from iNSIGHTSRN. To add, please head to
              https://insightsrn.com/
            </p>
          </ReactTooltip>
          <Dropdown
            filter={true}
            filterPlaceholder="Search Publisher"
            filterBy="label,value"
            value={deliverableObj.publisherID}
            options={publisherDropdown}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                publisherID: e.value,
              })
            }
            required
            placeholder="Please Select"
            scrollHeight="350px"
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Deliverable Type <span style={{ color: "red" }}>*</span>{" "}
            <span className="text-info">* </span>
          </span>
          <Dropdown
            value={deliverableObj.deliverableTypeID}
            options={deliverableTypeDropdown}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                deliverableTypeID: e.value,
              })
            }
            required
            placeholder="Please Select"
            scrollHeight="350px"
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Deliverable Status{" "}
			<i
				className="fas fa-info-circle informationStyle"
				data-tip=""
				data-for="statusInfo"
			></i>{" "}
			<ReactTooltip
				id="statusInfo"
				aria-haspopup="true"
				className="tooltipFont"
			>
				<p>Deliverable can only be published when a PMO has been assigned to the campaign</p>
			</ReactTooltip>
			<span style={{ color: "red" }}>*</span>
          </span>
          <Dropdown
            value={deliverableObj.deliverableStatus}
            options={deliverableStatusDropdown}
            onChange={(e) => {
              setDeliverableObj({
                ...deliverableObj,
                deliverableStatus: e.value,
              });
            }}
            placeholder="Please Select"
            required
          />
        </div>

        {deliverableObj.deliverableStatus === "Published"
          ? closedByDetails
          : ""}

        {action === ActionTypes.create ? (
          <div
            className="p-col-12"
            data-tour-id="deliverable-quantity"
            data-tour="If you want to duplicate this deliverable, you may increase the quantity."
          >
            <span className="boldLabel">
              Quantity <span style={{ color: "red" }}>*</span>
            </span>
            <InputNumber
              value={deliverableObj.quantity}
              onChange={(e) =>
                setDeliverableObj({
                  ...deliverableObj,
                  quantity: e.value,
                })
              }
              min={1}
              max={10}
              required
              showButtons
            />
          </div>
        ) : null}

        <div className="p-col-12">
          <span className="boldLabel">Members </span>
          <MultiSelect
            value={deliverableObj.deliverableMembersIDStrArr}
            options={usersDropdown}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                deliverableMembersIDStrArr: e.value,
              })
            }
            filter={true}
            placeholder="Please Select"
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Requirements</span>
          <InputText
            value={deliverableObj.requirements}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                requirements: e.currentTarget.value,
              })
            }
          />
        </div>

        {/* <div className="p-col-12">
          <span className="boldLabel">Remarks</span>
          <InputText
            value={deliverableObj.remarks}
            onChange={e =>
              setDeliverableObj({
                ...deliverableObj,
                remarks: e.currentTarget.value
              })
            }
          />
        </div> */}

        {/* <div className="p-col-12">
          <span className="boldLabel">Currency</span>

          <Dropdown
            value={deliverableObj.currency}
            options={currencyDropdown}
            onChange={e => {
              setDeliverableObj({
                ...deliverableObj,
                currency: e.value
              });
            }}
            placeholder="Select Currency"
            required
          />
        </div> */}
        {deliverableValueDisplay}

        {/* <div className="p-col-12">
          <span className="boldLabel">Publication Date</span>
          <Calendar
            dateFormat="dd/mm/yy"
            value={new Date(deliverableObj.publicationDate)}
            onChange={(e) => {
              setDeliverableObj({
                ...deliverableObj,
                publicationDate: new Date(e.value.toString()),
              });
            }}
            showButtonBar={true}
            showIcon={true}
            monthNavigator={true}
            yearNavigator={true}
            yearRange="1900:2030"
            // maxDate={this.state.maxDate}
            showTime={false}
            readOnlyInput={true}

          />
        </div> */}

        {/* <div className="p-col-12">
          <span className="boldLabel">Deliverable Link(s)</span>
          <span>(Enter to add another)</span>
          <Chips
            value={deliverableObj.deliverableLinkArr}
            onChange={updateDeliverableChipsArr}
          ></Chips>
        </div> */}

        <div className="p-col-12">
          <span className="boldLabel"> Link</span>{" "}
          <span className="text-info">* </span>
          <InputText
            value={deliverableObj.link}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                link: e.currentTarget.value,
              })
            }
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Facebook Link</span>{" "}
          <span className="text-info">* </span>
          <InputText
            value={deliverableObj.facebookLink}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                facebookLink: e.currentTarget.value,
              })
            }
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Report Order</span>{" "}
          <span className="text-info">* </span>
          <InputNumber
            value={deliverableObj.reportOrder}
            onChange={(e) => {
              setDeliverableObj({
                ...deliverableObj,
                reportOrder: e.value,
              });
            }}
            min={0}
            max={50}
            step={1}
            showButtons
          />
        </div>
      </div>

      <div className="p-grid p-justify-center pt-2">
        <Button type="submit" label={action} />
      </div>
    </form>
  );

  let normalPage = (
    <form onSubmit={handleSubmit}>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <span className="boldLabel">
            Deliverable Name <span style={{ color: "red" }}>*</span>{" "}
          </span>
          <InputText
            value={deliverableObj.deliverableName}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                deliverableName: e.currentTarget.value,
              })
            }
            required
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel" data-tip="" data-for="gotoirn">
            Publisher <span style={{ color: "red" }}>*</span>{" "}
          </span>
          <ReactTooltip
            id="gotoirn"
            aria-haspopup="true"
            className="tooltipFont"
          >
            <p className="text-center">
              Our list of Publishers are from iNSIGHTSRN. To add, please head to
              https://insightsrn.com/
            </p>
          </ReactTooltip>
          <Dropdown
            value={deliverableObj.publisherID}
            options={publisherDropdown}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                publisherID: e.value,
              })
            }
            required
            placeholder="Please Select"
            scrollHeight="350px"
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Deliverable Type <span style={{ color: "red" }}>*</span>{" "}
          </span>
          <Dropdown
            value={deliverableObj.deliverableTypeID}
            options={deliverableTypeDropdown}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                deliverableTypeID: e.value,
              })
            }
            required
            placeholder="Please Select"
            scrollHeight="350px"
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">
            Deliverable Status{" "}
			<i
				className="fas fa-info-circle informationStyle"
				data-tip=""
				data-for="statusInfo"
			></i>{" "}
			<ReactTooltip
				id="statusInfo"
				aria-haspopup="true"
				className="tooltipFont"
			>
				<p>Deliverable can only be published when a PMO has been assigned to the campaign</p>
			</ReactTooltip>
			<span style={{ color: "red" }}>*</span>
          </span>
          <Dropdown
            value={deliverableObj.deliverableStatus}
            options={deliverableStatusDropdown}
            onChange={(e) => {
              setDeliverableObj({
                ...deliverableObj,
                deliverableStatus: e.value,
              });
            }}
            placeholder="Please Select"
            required
          />
        </div>

        {deliverableObj.deliverableStatus === "Published"
          ? closedByDetails
          : ""}

        {action === ActionTypes.create ? (
          <div
            className="p-col-12"
            data-tour-id="deliverable-quantity"
            data-tour="If you want to duplicate this deliverable, you may increase the quantity."
          >
            <span className="boldLabel">
              Quantity <span style={{ color: "red" }}>*</span>
            </span>
            <InputNumber
              value={deliverableObj.quantity}
              onChange={(e) =>
                setDeliverableObj({
                  ...deliverableObj,
                  quantity: e.value,
                })
              }
              min={1}
              max={10}
              required
              showButtons
            />
          </div>
        ) : null}

        <div className="p-col-12">
          <span className="boldLabel">Members </span>
          <MultiSelect
            value={deliverableObj.deliverableMembersIDStrArr}
            options={usersDropdown}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                deliverableMembersIDStrArr: e.value,
              })
            }
            filter={true}
            placeholder="Please Select"
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Requirements</span>
          <InputText
            value={deliverableObj.requirements}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                requirements: e.currentTarget.value,
              })
            }
          />
        </div>

        {deliverableValueDisplay}

        {/* <div className="p-col-12">
          <span className="boldLabel">Publication Date</span>
          <Calendar
            dateFormat="dd/mm/yy"
            value={new Date(deliverableObj.publicationDate)}
            onChange={(e) => {
              setDeliverableObj({
                ...deliverableObj,
                publicationDate: new Date(e.value.toString()),
              });
            }}
            showButtonBar={true}
            showIcon={true}
            monthNavigator={true}
            yearNavigator={true}
            yearRange="1900:2030"
            // maxDate={this.state.maxDate}
            showTime={false}
            readOnlyInput={true}

          />
        </div> */}
        {/*
        <div className="p-col-12">
          <span className="boldLabel">Deliverable Link(s)</span>
          <span>(Enter to add another)</span>
          <Chips
            value={deliverableObj.deliverableLinkArr}
            onChange={updateDeliverableChipsArr}
          ></Chips>

        </div> */}

        <div className="p-col-12">
          <span className="boldLabel"> Link</span>{" "}
          <InputText
            value={deliverableObj.link}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                link: e.currentTarget.value,
              })
            }
          />
        </div>

        <div className="p-col-12">
          <span className="boldLabel">Facebook Link</span>{" "}
          <InputText
            value={deliverableObj.facebookLink}
            onChange={(e) =>
              setDeliverableObj({
                ...deliverableObj,
                facebookLink: e.currentTarget.value,
              })
            }
          />
        </div>
      </div>

      <div className="p-grid p-justify-center pt-2">
        <Button type="submit" label={action} />
      </div>
    </form>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    if (insightsrnaccess) {
      display = (
        <>
          <div className="pb-2">
            <span className="requiredRed">* Required</span>
            <span className="text-info px-2">
              * Used for iNSIGHTSRN Report (only if you need)
            </span>
          </div>
          <div className="row p-justify-center">
            <div className="col-12">{irnPage}</div>
          </div>
        </>
      );
    } else {
      display = (
        <>
          <div className="pb-2">
            <span className="requiredRed">* Required</span>
          </div>
          <div className="row p-justify-center">
            <div className="col-12">{normalPage}</div>
          </div>
        </>
      );
    }
  }

  return (
    <>
      {toHome ? <Redirect push to={toHomePath} /> : null}

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{action} Deliverable</h1>

            {display}
          </div>
        </div>
      </div>
    </>
  );
}

export default DeliverableCreate;
