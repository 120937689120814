import { Accordion, AccordionTab } from "primereact/accordion";
import React, { Component } from "react";
import { StatusRecordService } from "../../service/StatusRecordService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import { ToastStateContext } from "../resources/ToastContext";
import { ApproveOrRejectStatusRecord } from "./ApproveRejectLeave";
import ApproverHistory from "./ApproverHistory";


type ApprovalAppState = {
  isError: boolean;
  tableData: any[];
};

export class ApproveLeavePage extends Component<
  RouteComponentProps<any>,
  ApprovalAppState
> {
  static contextType = ToastStateContext;
  statusRecordService: StatusRecordService;
  commonMethods: CommonMethods;
  dt: any;
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      
      isError: false,
      tableData: []
    };
    this.statusRecordService = new StatusRecordService();
    this.commonMethods = new CommonMethods();

  }


  /*

  getting the history table data here so that it will update the table on reject/approval.

  tableData is sent to the ApproveHistory component as props.

  */

  componentDidMount() {
    this.getTableData()
   
  }


  getTableData = () => {
    if (this.isAdmin()) {
      this.getCompanyWideApprovingHistory();
    } else {
      this.getApprovingHistory();
    }
  }


  getApprovingHistory = () => {
    this.statusRecordService
      .getApproverHistory()
      .then(res => {
        this.setState({tableData: res})
      })
      .catch(err => {
        // const error = this.commonMethods.getErrorMessage(err);
        
        this.setState({isError: true})
      });
  }

  getCompanyWideApprovingHistory = () => {
    this.statusRecordService
    .getCompanyWideApproverHistory(localStorage.currentAccessingCompany)
      .then(res => {
      this.setState({tableData: res})
    })
    .catch(err => {
      // const error = this.commonMethods.getErrorMessage(err);
      this.setState({isError: true})
    });
  }

  isAdmin = () => {
    return (
      this.props.userProfile.taskList.includes("leaveadminaccess") &&
      this.props.location.pathname === "/admin/leave_admin"
    );
  };
  
  render() {


    return (
      <div className="row">
        <div className="col-12 col-xl-10">
          
          <ApproveOrRejectStatusRecord
            {...this.props}
            others={
              {
                tableDataCallback: this.getTableData
              }
            }
          />
        </div>
        <div className="col-12 col-xl-10">
          <div className="card" data-tour-id="leave-history" data-tour="Click to view your previous approval history.">
            <Accordion>
              <AccordionTab header="History">
                <ApproverHistory
                  {...this.props}
                  others={
                    {
                      tableData: this.state.tableData,
                      isError: this.state.isError
                    }
                  } />
              </AccordionTab>
            </Accordion>
          </div>
        </div>
      </div>
    );
  }
}


