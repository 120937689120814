import { HttpClient } from "./HttpClient";
import { StatusService } from "./StatusService";
import moment from "moment";
import { CommonMethods } from "../components/resources/CommonMethods";
import { StatusRecordDocumentObj } from "../components/statusrecord/LeaveApplicationForm";
import { convertObjToFormData } from "../components/resources/CommonMethodsServices";

export class StatusRecordService {
  httpClient: HttpClient;
  statusService: StatusService;
  commonMethods: CommonMethods;
  constructor() {
    this.httpClient = new HttpClient();
    this.statusService = new StatusService();
    this.commonMethods = new CommonMethods();
  }

  async getStatusRecords(status: string) {
    const res = await this.httpClient.get("/api/statusrecord");
    return res;
  }

  async getStatusRecordById(id: string) {
    const res = await this.httpClient.get(
      "/api/statusrecord/" + id + "/recordid"
    );
    return res;
  }

  async getStatusRecordsByUserId(id: string) {
    const res = await this.httpClient.get(
      "/api/statusrecord/history/userid/" + id
    );
    return res;
  }

  async getMyStatusRecords() {
    const res = await this.httpClient.get("/api/statusrecord/history/me");
    return res;
  }

  async getDepartmentLeaveCalendar() {
    const res = await this.httpClient.get(
      "/api/statusrecord/deptleavecalendar"
    );
    return res;
  }

  // async getActiveAndPendingStatusRecordByUserId(id: string) {
  //   const res = await this.httpClient.get(
  //     "/api/statusrecord/" + id + "/userid/active"
  //   );
  //   return res;
  // }

  async getAllPendingActiveLeaveToday(date: Date) {
    let utcDate = moment(date).format("YYYY-MM-DD");

    const res = await this.httpClient.get(
      `/api/statusrecord/allpendingactivetoday/${utcDate}`
    );
    return res;
  }

  async getCompanyWidePendingRecords(currentAccessingCompanyID: Date) {
    const res = await this.httpClient.get(
      "/api/statusrecord/CompanyWidePendingRecords/" + currentAccessingCompanyID
    );
    return res;
  }

  convertToLocalTime(res: StatusRecordDocumentObj[]) {
    res.forEach((statusRecord) => {
      statusRecord = this.commonMethods.updateProperty(
        "createdTime",
        this.commonMethods.convertDateToLocalTime(statusRecord.createdTime),
        statusRecord
      );
      statusRecord = this.commonMethods.updateProperty(
        "lastUpdated",
        this.commonMethods.convertDateToLocalTime(statusRecord.lastUpdated),
        statusRecord
      );
    });

    return res;
  }

  // Admin
  async getUnverifiedRecords(currentAccessingCompanyID: string) {
    let res: StatusRecordDocumentObj[];
    res = await this.httpClient.get(
      "/api/statusrecord/UnverifiedRecords/" + currentAccessingCompanyID
    );
    return this.convertToLocalTime(res);
  }

  async getCompanyWideApproverHistory(companyID: string) {
    let res: StatusRecordDocumentObj[];
    res = await this.httpClient.get(
      "/api/statusrecord/" + companyID + "/company/approver/history"
    );

    return this.convertToLocalTime(res);
  }

  async getStatusRecordByApprover() {
    let res: StatusRecordDocumentObj[];
    res = await this.httpClient.get("/api/statusrecord/approver");
    return this.convertToLocalTime(res);
  }

  async getApproverHistory() {
    let res: StatusRecordDocumentObj[];
    res = await this.httpClient.get("/api/statusrecord/approver/history");
    return this.convertToLocalTime(res);
  }

  async getOverlappingRecords(statusObj: any) {
    const res = await this.httpClient.post(
      "/api/statusrecord/overlapping",
      convertObjToFormData(statusObj)
    );

    return res;
  }

  async getNumberOfRecordsToday(userList: string[]) {
    const res = await this.httpClient.post(
      "/api/statusrecord/getNumberOfRecordsToday",
      userList
    );
    return res;
  }

  async createStatusRecord(statusObj: any) {
    const res = await this.httpClient.post(
      "/api/statusrecord/create",
      convertObjToFormData(statusObj)
    );

    return res;
  }

  async createStatusRecordMultiple(userStatusObj: any) {
	let converted = convertObjToFormData(userStatusObj);
	const res = await this.httpClient.post(
		"/api/statusrecord/createmultiple",
		convertObjToFormData(userStatusObj)
	);
	return res;
  }

  async bulkActionLeaveApplication(records: any[]) {
    const res = await this.httpClient.put(
      "/api/statusrecord/BulkAction",
      records
    );
    return res;
  }

  async cancelStatusRecord(statusObj: any) {
    let action = "cancel";
    const res = await this.httpClient.put(
      "/api/statusrecord/" + action + "/recordid/",
      statusObj
    );
    return res;
  }
  async adminCancelStatusRecord(statusObj: any) {
    let action = "adminCancel";
    const res = await this.httpClient.put(
      "/api/statusrecord/" + action + "/recordid/",
      statusObj
    );
    return res;
  }

  async updateStatusRecord(statusObj: any) {
    // var form_data = new FormData();
    // for (var key in statusRecordObj) {
    //   if (key === "startDate" || key === "endDate") {
    //     // let utcDate = statusRecordObj[key].toLocaleString();
    //     let utcDate = moment(statusRecordObj[key]).format(
    //       CommonMethods.DateForBackendStringFormat
    //     );
    //     form_data.append(key, utcDate);
    //   } else {
    //     form_data.append(key, statusRecordObj[key]);
    //   }
    // }
    const res = await this.httpClient.put(
      "/api/statusrecord/update",
      convertObjToFormData(statusObj)
    );
    return res;
  }
  async approveStatusRecord(statusObj: any) {
    let action = "approve";
    const res = await this.httpClient.put(
      "/api/statusrecord/" + action + "/recordid/",
      statusObj
    );
    return res;
  }
  async rejectStatusRecord(statusObj: any) {
    let action = "reject";
    const res = await this.httpClient.put(
      "/api/statusrecord/" + action + "/recordid/",
      statusObj
    );
    return res;
  }

  async getCompanyWideAttendance(dateToLoad: Date) {
    const res = await this.httpClient.get(
      "/api/statusrecord/companyWideAttendance/" + dateToLoad.toISOString()
    );
    return res;
  }

  //    ___  _          _               ___                       _
  //   / __|| |_  __ _ | |_  _  _  ___ | _ \ ___  _ __  ___  _ _ | |_  ___
  //   \__ \|  _|/ _` ||  _|| || |(_-< |   // -_)| '_ \/ _ \| '_||  _|(_-<
  //   |___/ \__|\__,_| \__| \_,_|/__/ |_|_\\___|| .__/\___/|_|   \__|/__/
  //                                             |_|

  async getLeaveReportDetails(statusObj: any) {
    const res = await this.httpClient.post(
      "/api/statusrecord/report",
      convertObjToFormData(statusObj)
    );

    return res;
  }
}

export class StatusDocumentService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async getStatusDocumentByStatusRecordID(id: string) {
    const res = await this.httpClient.get(
      "/api/statusdocument/" + id + "/recordid"
    );
    return res;
  }

  async verifyStatusDocument(documentObj: any) {
    const res = await this.httpClient.put(
      "/api/statusdocument/verify/" + documentObj.id,
      documentObj
    );
    return res;
  }

  async rejectStatusDocument(documentObj: any) {
    const res = await this.httpClient.put(
      "/api/statusdocument/reject/" + documentObj.id,
      documentObj
    );
    return res;
  }

  async bulkRejectStatusDocuments(documentObj: any) {
    const res = await this.httpClient.put(
      "/api/statusdocument/bulkreject/",
      documentObj
    );
    return res;
  }

  async bulkVerifyStatusDocuments(documentObj: any) {
    const res = await this.httpClient.put(
      "/api/statusdocument/bulkverify/",
      documentObj
    );
    return res;
  }
}
