import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { AccountingCodeService } from "../../service/AccountingCodeService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { AccountingCodeModel } from "./AccountingCodeModel";
import { InputText } from "primereact/inputtext";

function AccountingCodePage(props: RouteComponentProps<any>) {
  const commonMethods = new CommonMethods();
  const accountingCodeService = new AccountingCodeService();

  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );

  const [searchValue, setSearchValue] = useState<string>("");
  const [tableDataChanged, setTableDataChanged] = useState(false);
  const [tableData, setTableData] = useState<AccountingCodeModel[]>([]);
  const [accountingCodeData, setAccountingCodeData] = useState<
    AccountingCodeModel
  >(new AccountingCodeModel());

  const [dialogStr, setDialogStr] = useState<string>("");
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [dialogVisibility, setDialogVisibility] = useState(false);

  useEffect(() => {
    init();
  }, [tableDataChanged]);

  const init = () => {
    accountingCodeService
      .getAllAccountingCodesByGCID()
      .then((res) => setTableData(res))
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const setDialog = (header: string, content: any) => {
    setConfirmStr(header);
    setDialogStr(content);
  };

  const deleteAccountingCode = () => {
    accountingCodeService
      .deleteAccountingCode(accountingCodeData.accountingCodeID)
      .then(() => {
        setTableDataChanged(!tableDataChanged);
        setDialogVisibility(false);
        SetSuccess(
          ` ${accountingCodeData.accountingCodeName} has been deleted.`
        );
      })
      .catch((err) => {
        setDialogVisibility(false);
        // SetError(
        //   `An error occurred when trying to delete ${accountingCodeData.accountingCodeName}`
        // );
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  const actionTemplate = (rowData: AccountingCodeModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Edit Accounting Code"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            setAccountingCodeData(rowData);
            setRedirectOut(true);
            setRedirectPath(
              "/finance/accounting_code/update/" + rowData.accountingCodeID
            );
          }}
        />
        <Button
          tooltip="Delete Accounting Code"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setAccountingCodeData(rowData);

            setDialog(
              `Delete ${rowData.accountingCodeName}`,
              `Are you sure you want to delete this accounting code?`
            );
            setDialogVisibility(true);
          }}
        />
      </div>
    );
  };

  let tableheader = (
    <div style={{ textAlign: "left" }}>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e: any) => setSearchValue(e.currentTarget.value)}
        />
      </span>
    </div>
  );

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMsg} />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={tableData}
            paginator={true}
            rows={15}
            autoLayout={true}
            header={tableheader}
            globalFilter={searchValue}
            alwaysShowPaginator={false}
            emptyMessage="No results found."
          >
            <Column field="accountingCode" header="Code" />
            <Column field="accountingCodeName" header="Name" />
            <Column field="accountingCodeType" header="Type" />
            <Column field="accountingCodeDescription" header="Description" />
            <Column body={actionTemplate} header="Actions" className="p-col-2" />
          </DataTable>
        </div>
      );
    }
  };

  return (
    <div className="row">
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                deleteAccountingCode();
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>

      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">
            Accounting Codes
            <span className="pl-3">
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={() => {
                  setRedirectOut(true);
                  setRedirectPath("/finance/accounting_code/create");
                }}
              />
            </span>
          </h1>
          <p>
            These are to classify the claims expenses for accounting. (E.g.,
            Local Transport, International Transport, Overheads) You can tag
            them when approving claims.
          </p>
          {display()}
        </div>
      </div>
    </div>
  );
}

export default AccountingCodePage;
