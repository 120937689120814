import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { QuicklinksDashboardModel } from "./QuicklinksDashboardModel";
import { QuicklinksDashboardService } from "../../service/QuicklinksDashboardService";

export default function QuicklinksDashboard(props: RouteComponentProps<any>){
  const commonMethods = new CommonMethods();
  const quicklinksDashboardService = new QuicklinksDashboardService();
  
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
    
  const [searchValue, setSearchValue] = useState<string>("");
  const [tableDataChanged, setTableDataChanged] = useState(false);
  const [tableData, setTableData] = useState<QuicklinksDashboardModel[]>([]);
  const [linkData, setLinkData] = useState(new QuicklinksDashboardModel());

  const [dialogStr, setDialogStr] = useState<string>("");
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
  "An unidentified error has occurred"
  );

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [tableDataChanged]);

  // Initialise the data table
  const init = () => {
    quicklinksDashboardService
    .getAllLinks()
    .then((res) => setTableData(res))
    .catch((err)=> {
      setErrorMessage(commonMethods.getErrorMessage(err));
      setIsError(true);
    })
    .finally(() => setIsLoading(false));
  };

  /* Initialise header and content for popup dialogs (modal). 
  Should only be for delete action now */
  const setDialog = (header: string, content: string) => {
    setConfirmStr(header);
    setDialogStr(content);
  };

  // Delete method
  const deleteLink = () => {
    quicklinksDashboardService
      .deleteLinkByID(linkData.linkID)
      .then(() => {
        setTableDataChanged(!tableDataChanged);
        setDialogVisibility(false);
        SetSuccess(` ${linkData.linkTitle} has been deleted.`);
      })
      .catch((err) => {
        setDialogVisibility(false);
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  // Search bar
  let tableheader = (
    <div style={{ textAlign: "left" }}>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e: any) => setSearchValue(e.currentTarget.value)}
        />
      </span>
    </div>
  );

  const hyperLink = (rowData: QuicklinksDashboardModel) => {
    if (rowData.link) {
      return <a href={rowData.link} rel="noopener noreferrer" target="_blank">{rowData.linkTitle}</a>
    }
  }

  const actionTemplate = (rowData: QuicklinksDashboardModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Edit Link"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            setLinkData(rowData);
            setRedirectOut(true);
            setRedirectPath("/admin/quick_links/update/" + rowData.linkID);
          }}
        />
        <Button
          tooltip="Delete Link"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setLinkData(rowData);
            setDialog(
              `Delete ${rowData.linkTitle}`,
              `Are you sure you want to delete this link?`
            );
            setDialogVisibility(true);
          }}
        />
      </div>
    );
  };

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMessage} />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            id="quiclinks"
            value={tableData}
            paginator={true}
            rows={15}
            autoLayout={true}
            header={tableheader}
            globalFilter={searchValue}
            alwaysShowPaginator={false}
            emptyMessage="No results found."
            sortField="linkTitle"
            sortOrder={1}
          >
            <Column field="linkTitle" body={hyperLink} header="Link Title"/>
            {/* <Column field="fullName" header="Created By"/>
              Can uncomment if you want a "Created By column, backend and models are updated to show this"
            */}
            <Column body={actionTemplate} header="Actions" className="p-col-2" />
          </DataTable>
        </div>
      );
    }
  }

  return (
    <div className="row">
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

    <Dialog
      className="popupDialog"
      header={confirmStr}
      visible={dialogVisibility}
      modal={true}
      onHide={() => setDialogVisibility(false)}
      footer={
        <div>
          <Button
            label="Yes"
            type="button"
            iconPos="left"
            icon="pi pi-check"
            className="p-button-success"
            onClick={() => {
              deleteLink();
            }}
          />
          <Button
            label="No"
            type="button"
            iconPos="left"
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => {
              setDialogVisibility(false);
            }}
          />
        </div>
      }
    >
      {dialogStr}
    </Dialog>
    <div className="col-12 col-xl-12">
      <div className="card">
        <h1 className="pb-2">
          Quick Links
          <span className="pl-3">
            <Button
              type="button"
              icon="pi pi-plus"
              iconPos="right"
              label="Add"
              className="p-button-danger"
              onClick={() => {
                setRedirectOut(true);
                setRedirectPath("/admin/quick_links/create")
              }}
            />
          </span>
        </h1>
        <p>
          These links will show up on the Quick Links page
        </p>
        {display()}
      </div>
    </div>
  </div>
  );
}
