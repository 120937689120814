import _ from "lodash";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import DeliverableService from "../../service/DeliverableService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { DeliverableModel } from "./DeliverablesInCampaign";
import DeliverablesPerPerson from "./DeliverablesPerPerson";

export class UserDeliverableList {
  public deliverableList: Array<DeliverableModel> = new Array<
    DeliverableModel
  >();
  public userID: string = "";
  public userName: string = "";
  public colourCode: string = "";
  public points: number = 0;
}

function WorkDistributionOverview(props: RouteComponentProps<any>) {
  let deliverableService = new DeliverableService();
  let commonMethods = new CommonMethods();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const [arrToDisplay, setArrToDisplay] = useState();
  // const [cardsToDisplay, setCardsToDisplay] = useState();
  const [activeIndexArr, setActiveIndexArr] = useState<any>([]);
  const [userDelList, setUserDelList] = useState(
    new Array<UserDeliverableList>()
  );
  
  let colorArray: string[] = commonMethods.getColourArr();

  

  useEffect(() => {
    getDeliverablesForDepartment();
  }, []);

  const getDeliverablesForDepartment = () => {
    deliverableService
      .getAllDeliverablesFromUserDepartment()
      .then(res => {
        displayPerPerson(res);
        setUserDelList(res);
      })
      .catch(err => {
        let errorMsg = commonMethods.getErrorMessage(err);
        setIsError(true);
        setIsLoading(false);
        setErrorMsg(errorMsg);
      });
  };

  const heatMapColor = (cardNo: number, highestCount: number) => {
    let percentage = Math.floor((cardNo / highestCount) * 100);

    let r = 255;
    let g = Math.round((255 * (100 - percentage)) / 100);
    let b = Math.round((255 * (100 - percentage)) / 100);

    return "rgb(" + r + "," + g + "," + b + ")";
  };

  const getHighestCount = (userDelList: Array<UserDeliverableList>) => {
    const b = _.maxBy(userDelList, "points");
    if (b !== undefined) {
      return b.points;
    }
    return 0;
  };

  const displayPerPerson = (userDelList: Array<UserDeliverableList>) => {
    let highestCount = getHighestCount(userDelList);

    // rearrange according to points
    let orderedUserDelList = _.orderBy(userDelList, ["points"], ["desc"]);

    let arrOfDelPerPerson: any;
    arrOfDelPerPerson = [];
    let cardsPerPerson: any;
    cardsPerPerson = [];
    let colourCounter = 0;
    let maxLengthOfColors = colorArray.length;
    let cardForUser = <div className="card"> 0 </div>;

    //  -- Card --
    orderedUserDelList.forEach(user => {
      // colour for cards
      let col = heatMapColor(user.points, highestCount);

      cardForUser = (
        <span className="pr-2" key={user.userID}>
          <div
            className="card text-center d-inline-block"
            style={{ backgroundColor: col }}
          >
            <h2>{user.points}</h2> {user.userName}
          </div>
        </span>
      );
      cardsPerPerson.push(cardForUser);
    });

    // -- accordion --
    userDelList.forEach((user, index) => {
      // colour for accordion
      colourCounter++;
      if (index % maxLengthOfColors === 0) {
        colourCounter = 0;
      }
      user.colourCode = colorArray[colourCounter];

      // let header = user.userName + " (" + user.points + ")";
      let header = user.userName;
      let item = (
        <AccordionTab
          key={user.userID}
          header={header}
          headerStyle={{ backgroundColor: user.colourCode }}
          headerClassName="no-bg"
        >
          <DeliverablesPerPerson {...props} others={user} />
        </AccordionTab>
      );

      arrOfDelPerPerson.push(item);
    });

    setIsLoading(false);
    setArrToDisplay(arrOfDelPerPerson);
    // setCardsToDisplay(cardsPerPerson);
  };

  const openAllAccordionTabs = () => {
    // let str = "";
    if (activeIndexArr.length === 0) {
      // event.preventDefault();
      // str = _.range(userDelList.length).toString();
      setActiveIndexArr(_.range(userDelList.length));
    }
    else {
      setActiveIndexArr([])
    }

    // forceUpdate(1);
  };

  // useEffect(() => {
  //   let str = _.range(5).toString();
  //   let accordion = (
  //     <Accordion multiple activeIndex={str}>
  //       {arrToDisplay}
  //     </Accordion>
  //   );
  //   forceUpdate(1);
  //   setAccDisplay(accordion);
  // }, [activeIndexArr]);

  // useEffect(() => {
  //   let str = _.range(2).toString();
  //   let accordion = <Accordion multiple activeIndex={str}>{arrToDisplay}</Accordion>;
  //   setAccDisplay(accordion);
  // }, [arrToDisplay]);

  let accordion = (
    <Accordion multiple activeIndex={activeIndexArr} onTabChange={(e) => setActiveIndexArr(e.index)}>
      {arrToDisplay}
    </Accordion>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = (
      <div
        className="row"
        data-tour-id="deliverables-department-overview"
        data-tour="This is the list of outstanding deliverables for everyone in your department."
      >
        {/* <div className="col-12">
          <div className="pb-2">
            <h2>Current points allocation:</h2>
          </div>
          {cardsToDisplay}
        </div> */}

        
        <div className="col-12">
          <Button
            icon="pi pi-angle-double-down"
            onClick={e => openAllAccordionTabs()}
            label="All"
          ></Button>
          {accordion}
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">Department Overview</h1>

          {display}
        </div>
      </div>
    </div>
  );
}

export default WorkDistributionOverview;
