import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { ClaimsService } from "../../service/ClaimsService";
import { CommonMethods, DropdownModel, ParamIDTypes, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { ClaimsModel } from "./ClaimsModel";
import { DisplayClaim } from "./ViewClaimByID";

function AdminEditClaim(props: RouteComponentProps) {
  return (
    <div className="row ">
      <div className="col-12 col-xl-6">
        <DisplayClaim {...props} />
      </div>
      <div className="col-12 col-xl-6">
        <AdminEditClaimsPortion {...props} />
      </div>
    </div>
  );
}

function AdminEditClaimsPortion(props: RouteComponentProps) {
  const commonMethods = new CommonMethods();
  const claimsService = new ClaimsService();

  const { id } = useParams<ParamIDTypes>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("/finance/claims");
  const [errorMsg, setErrorMessage] = useState<string>("");
  const [accountingCodes, setAccountingCodes] = useState<DropdownModel[]>(
    new Array<DropdownModel>()
  );
  const [claimStatusDropdown, setClaimStatusDropdown] = useState<
    DropdownModel[]
  >(new Array<DropdownModel>());
  const [companyDropdown, setCompanyDropdown] = useState<DropdownModel[]>(
    new Array<DropdownModel>()
  );
  const [claim, setClaim] = useState<ClaimsModel>(new ClaimsModel());

  useEffect(() => {
    getDropdowns();
    getClaim();
  }, []);
  const getDropdowns = async () => {
    try {
      await commonMethods.getAccountingCodeDropdown().then((res) => {
        setAccountingCodes(res);
      });
      await commonMethods.getAdminSettableClaimStatus().then((res) => {
        setClaimStatusDropdown(res);
      });
      await commonMethods.getCompanyDropdownListByGcid().then((res) => {
        setCompanyDropdown(res);
      });
    } catch (err) {
      setIsError(true);
      setErrorMessage(commonMethods.getErrorMessage(err));
    }
  };
  const getClaim = () => {
    if (id) {
      claimsService.getClaimByID(id).then((res: ClaimsModel) => {
        setClaim(res);
        setIsLoading(false);
      });
    } else {
      setIsError(true);
      setErrorMessage("Invalid page");
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    claimsService
      .updateClaim(claim)
      .then((res) => {
        SetSuccess("Claim edited!");
        setRedirectOut(true);
        setRedirectPath("/finance/claims")
      })
      .catch((err) => {
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  let display;
  if (isLoading) {
    display = <ProgressSpinner />;
  } else if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (redirectOut) {
    return <Redirect push to={redirectPath} />;
  } else {
    display = (
      <form onSubmit={handleSubmit}>
        <div className="col-12 p-fluid p-2">
          <span className="boldLabel">Company </span>{" "}
          <span className="requiredRed">*</span>
          <Dropdown
            options={companyDropdown}
            placeholder="Please Select"
            filter={true}
            required
            scrollHeight="350px"
            value={claim.companyID}
            onChange={(e) => {
              setClaim({ ...claim, companyID: e.value });
            }}
          />
        </div>
        <div className="col-12 p-fluid p-2">
          <span className="boldLabel">Payment Reference </span>
          <InputText
            value={claim.paymentReference}
            onChange={(e) =>
              setClaim({
                ...claim,
                paymentReference: e.currentTarget.value,
              })
            }
          />
        </div>
        <div className="col-12 p-fluid p-2">
          <span className="boldLabel">Accounting Code </span>

          <Dropdown
            options={accountingCodes}
            placeholder="Please Select"
            filter={true}
            scrollHeight="350px"
            value={claim.accountingCodeID}
            onChange={(e) => {
              setClaim({ ...claim, accountingCodeID: e.value });
            }}
          />
        </div>
        <div className="col-12 p-fluid p-2">
          <span className="boldLabel">Status </span>{" "}
          <span className="requiredRed">*</span>
          <Dropdown
            options={claimStatusDropdown}
            placeholder="Please Select"
            required
            scrollHeight="350px"
            value={claim.claimStatus}
            onChange={(e) => {
              setClaim({
                ...claim,
                claimStatus: e.value,
              });
            }}
          />
        </div>
        <div className="col-12 p-fluid p-2">
          <span className="boldLabel">Approving Remarks </span>{" "}
          <span className="requiredRed">*</span>
          <InputTextarea
            rows={3}
            cols={30}
            value={claim.approvingRemarks}
            onChange={(e) =>
              setClaim({
                ...claim,
                approvingRemarks: e.currentTarget.value,
              })
            }
          />{" "}
        </div>

        <div className="p-grid p-justify-center pt-4">
          <Button type="submit" label={"Submit"} />
        </div>
      </form>
    );
  }
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <h1>Edit Claim</h1>
          {display}
        </div>
      </div>
    </div>
  );
}

export default AdminEditClaim;
