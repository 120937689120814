import moment from "moment";
import { CommonMethods } from "../resources/CommonMethods";

export enum TaxHeaderStatus {
  SUBMITTED = "SUBMITTED",
  NOTSUBMITTED = "NOT SUBMITTED",
  SUBMISSIONERR = "SUBMISSION ERROR",
}

export class TaxFilingHeader {
  public taxHeaderID: string = CommonMethods.EmptyGuid;
  public recordType: string = "";
  public source: string = "";
  public incomeYear: string = "";
  public paymentType: string = "";
  public organizationIDType: string = "";
  public organizationIDNo: string = "";
  public organizationName: string = "";
  public nameOfAuthorisedPerson: string = "";
  public designation: string = "";
  public authorizedPersonUserID: string = CommonMethods.EmptyGuid;
  public telephoneNumber: string = "";
  public emailAddressOfAuthorisedPerson: string = "";
  public fileType: string = "";
  public fileTypeName: string = "";
  public formType: string = "";
  public createdTime: Date = new Date();
  public companyID: string = "";
  public createdBy: string = CommonMethods.EmptyGuid;
  public status: string = "";
}

export class TaxBodyModel {
  public taxBodyID: string = "";
  public recordType: string = "";
  public fullName: string = "";
  public dateOfBirth: string = "";
  public nationality: string = "";
  public nationalityName: string = "";
  public gender: string = "";
  public employeeIDType: string = "";
  public employeeIDTypeName: string = "";
  public bankCode: string = "";
  public bankCodeName: string = "";

  public designation: string = "";

  public employeeIDNumber: string = "";
  public fromDate: string = "";
  public toDate: string = "";
  public dateOfCommencement: Date | null = null;
  public dateOfCessation: Date | null = null;

  public cessationProvisionsIndicator: string = "";
  public appendix8AIndicator: string = "";
  public formIR8SIndicator: string = "";

  public amount: number = 0;
  // deduction
  public mosqueBuildingFund: number = 0; // Edit - Deductions
  public donation: number = 0; // Edit - Deductions
  public cpf: number = 0; // Edit - Deductions
  public employeeCompulsoryContributionFundName: string = "";
  public lifeInsurancePremiums: number = 0; // Edit - Deductions
  // income
  public salary: number = 0;
  public bonus: number = 0;
  public bonusDateOfDeclaration: Date | null = null;
  public directorsFee: number = 0;
  public directorsFeeDateOfApproval: Date | null = null;
  public others: number = 0;
  public gratuity_NoticePay_Exgratiapayment: string = "";
  public gratuity_NoticePay_ExgratiapaymentAmount: number = 0;
  public grossCommission: number = 0;
  public grossCommissionPeriodFrom: Date | null = null;
  public grossCommissionPeriodTo: Date | null = null;
  public grossCommissionIndicator: string = "";
  public allowanceTransport: number = 0;
  public allowanceEntertainment: number = 0;
  public allowanceOther: number = 0;
  public pension: number = 0;

  public compensationForLossOfOfficeIndicator: string = "";
  public compensationForLossOfOfficeApprovalObtained: string = "";
  public compensationForLossOfOfficeDateOfApproval: Date | null = null;
  public compensationForLossOfOffice: number = 0;

  public retirement: number = 0;
  public retirementFrom1993: number = 0;
  public retirementFundName: string = "";

  public contributionsByEmployerToOutsideSg: number = 0;
  public excessVoluntaryCPFContributionByEmployer: number = 0;
  public gainsAndProfitFromShareOptionsFromS101b: number = 0;
  public gainsAndProfitFromShareOptionsFromS101g: number = 0;
  public valueOfBenefitsInKind: number = 0;
  public exemptIncomeIndicator: string = "";
  public exemptIncomeAmount: number = 0;
  public employmentIncomeTaxBorneByEmployerAmount: number = 0;
  public incomeTaxBorneByEmployeeAmount: number = 0;
  public employmentIncomeTaxBorneByEmployerIndicator: string = "";

  public createdTime: Date = new Date();
  public taxHeaderID: string = "";
  public userID: string = "";
  public incomeYear: string = "";
  public errorMsg: string = "";
}

export class TaxTrailerModel {
  public recordType: string = "";
  public noofRecords: string = "";
  public totalAmountOfPayment: string = "";
  public totalAmountOfSalary: string = "";
  public totalAmountOfBonus: string = "";
  public totalAmountOfDirectorsFee: string = "";
  public totalAmountOfOthers: string = "";
  public totalAmountOfExemptIncome: string = "";
  public totalAmountOfEmploymentIncomeTaxBorneByEmployer: string = "";
  public totalAmountOfIncomeTaxBorneByEmployee: string = "";
  public totalAmountOfDonation: string = "";
  public totalAmountOfCPF: string = "";
  public totalAmountOfInsurance: string = "";
  public totalAmountOfMBF: string = "";
}

export class TaxBodyCreationModel {
  public users: string[] = [];
  public taxheaderid: string = "";
}

export class TaxSubmissionResults {
  public taxSubmissionID: string = "";
  public taxHeader: TaxFilingHeader = new TaxFilingHeader();
  public submittedByName: string = "";

  public isSuccessfulContent: boolean = false;
  public transactionList: Array<TransactionVO> = new Array<TransactionVO>();
  public createdTime: Date = new Date();
}

export class TransactionVO {
  public taxSubmissionID: string = "";
  public taxTransactionID: string = "";
  public errors: Array<TaxSubmissionErrorModel> = [];
  public warnings: Array<TaxSubmissionErrorModel> = [];
  public formType: string = "";
  public msgError: string = "";
  public output: SubmissionOutputInformation = new SubmissionOutputInformation();
  public pass: string = "";
  public statusCode: string = "";
  public UserNames: Array<string> = [];
}

export class TaxSubmissionErrorModel {
  public recordType: string = "";
  public error: string = "";
  public recordIdentifier: string = "";
  public recordField: string = "";
  public fullName: string = "";
}

export class SubmissionOutputInformation {
  public submissionReferenceNumber: string = "";
  public organisationName: string = "";
  public organisationReferenceNumber: string = "";
  public basisYearAddOne: string = "";
  public fileType: string = "";
  public submissonDateTime: string = "";
  public userID: string = "";
  public noofRecords: string = "";
}

export class TaxFilingMethods {
  static getTaxFilingYearDropdown() {
    const currentYear = new Date().getFullYear().toString();
    const yearbefore1 = moment(new Date()).add(-1, "year").year().toString();
    const yearbefore2 = moment(new Date()).add(-2, "year").year().toString();
    const yearafter1 = moment(new Date()).add(1, "year").year().toString();

    const yearDropdown = [
      { label: yearbefore2, value: yearbefore2 },
      { label: yearbefore1, value: yearbefore1 },
      { label: currentYear, value: currentYear },
      { label: yearafter1, value: yearafter1 },
    ];
    return yearDropdown;
  }

  static getTaxFilingFormTypeDropdown() {
    const formDropdown = [{ label: "IR8A", value: "IR8A" }];
    return formDropdown;
  }

  static getTaxFilingFileTypeDropdown() {
    const formDropdown = [
      { label: "Original", value: "O" },
      // { label: "Amendment", value: "A" },
    ];
    return formDropdown;
  }

  static getOrganizationTypesDropdown() {
    const dropdown = [
      { label: "Business Registration number issued by ACRA", value: "7" },
      { label: "Local Company Registration number issued by ACRA", value: "8" },
      { label: "Tax Reference number assigned by IRAS", value: "A" },
      { label: "Income Tax Reference number assigned by IRAS", value: "I" },
      {
        label:
          "Unique Entity Number Others (e.g. Foreign Company Registration Number)",
        value: "U",
      },
    ];
    return dropdown;
  }

  static getGrossCommissionIndicators() {
    const dropdown = [
      { label: "None", value: "" },
      { label: "Monthly", value: "M" },
      { label: "Other Adhoc Payment", value: "O" },
      { label: "Both", value: "B" },
    ];
    return dropdown;
  }

  static getCompensationApproval() {
    const dropdown = [
      { label: "None", value: "" },
      { label: "Yes", value: "Y" },
      { label: "No", value: "N" },
    ];
    return dropdown;
  }

  static getExemptIncomeIndicator() {
    const dropdown = [
      { label: "None", value: "" },
      {
        label: "Tax Remission on Overseas Cost of Living Allowance (OCLA)",
        value: "1",
      },
      { label: "Seaman", value: "3" },
      { label: "Exemption", value: "4" },
      { label: "Overseas Pension Fund with Tax Concession", value: "5" },
      { label: "Income from Overseas Employment", value: "6" },
      {
        label:
          "Income from Overseas Employment and Overseas Pension Fund with Tax Concession",
        value: "7",
      },
    ];
    return dropdown;
  }

  static getIncomeTaxBorneByEmployer() {
    const dropdown = [
      { label: "None", value: "" },
      { label: "Tax fully borne by employer", value: "F" },
      { label: "Tax partially borne by employer", value: "P" },
      {
        label: "A fixed amount of income tax is borne by employee",
        value: "H",
      },
    ];
    return dropdown;
  }

  static cessationProvisionsIndicator() {
    const dropdown = [
      { label: "Yes", value: "Y" },
      { label: "No", value: "" },
    ];
    return dropdown;
  }


  static getStatusCodeDescription(statusCode: string) {
    switch (statusCode) {
      case "400":
        return "The request could not be processed by the server due to invalid inputs.";

      case "401":
        return "The requested service requires user authentication/authorisation.";

      case "200":
      case "204":
        return "Success";

      default:
        return "Error";
    }
  }
}
