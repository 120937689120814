import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { StatusService } from "../../service/StatusService";
import { CommonMethods, ParamIDTypes, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";

function LeaveBalancePanelHorizontal(props: RouteComponentProps | any) {
  let statusService = new StatusService();
  let commonMethods = new CommonMethods();
  const [leaveBalanceArray, setLeaveBalanceArray] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState<string>("An error has occurred");

  const [isLoading, setIsLoading] = useState(true);
  const [fullDisplayName, setFullDisplayName] = useState("");
  const [showOtherLeaves, setShowOtherLeaves] = useState(false);
  const [adminView, setAdminView] = useState<boolean>(false);
  const { id } = useParams<ParamIDTypes>();

  // let params: { id?: string } = useParams();
  const colorArray: string[] = [
    "4E5ED6",
    "3444BC",
    "6777ef",
    "8191FF",
    "9AAAFF",
    "B4C4FF",
  ];

  useEffect(() => {
    // admin
    if (id !== undefined) {
      setAdminView(true);
      setShowOtherLeaves(true);
      statusService
        .getLeaveBalanceByUserID_AdminView(id)
        .then((res) => {
          setIsLoading(false);
          setLeaveBalanceArray(res);
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError(true);
          setErrMsg(commonMethods.getErrorMessage(err));
        });
      getName();
    } else {
      statusService
        .getMyLeaveBalance()
        .then((res) => {
          setIsLoading(false);
          setLeaveBalanceArray(res);
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError(true);
          setErrMsg(commonMethods.getErrorMessage(err));
        });
    }
  }, []);

  const displayBalance = () => {
    if (isError) {
      return <CustomError message={errMsg} />;
    } else if (isLoading) {
      return <ProgressSpinner />;
    } else {
      let tempArray: any = [];
      let count: number = 0;

      leaveBalanceArray.forEach((element) => {
        tempArray.push(
          <div
            className="col-4 col-xl-3 col-lg-2 px-3 text-center p-justify-center"
            key={element.statusTypeName.trim("Leave")}
            hidden={showOtherLeaves === true ? false : !element.toDisplay}
          >
            <div>
              <svg
                className="bd-placeholder-img rounded-circle"
                width="60"
                height="60"
                preserveAspectRatio="xMidYMid slice"
                aria-label="Placeholder: 140x140"
                focusable="false"
              >
                <title>
                  Expires on: {commonMethods.displayDate(element.expiryDate)}
                </title>
                <rect
                  width="100%"
                  height="100%"
                  fill={`#${
                    colorArray[
                      count === colorArray.length ? (count = 0) : count++
                    ]
                  }`}
                ></rect>
                <text
                  x="50%"
                  y="50%"
                  fill="#FFFFFF"
                  textAnchor="middle"
                  dominantBaseline="central"
                >
                  {element.availableDays}
                </text>
              </svg>

              <p className="">
                <strong>
                  {element.statusTypeName.replace("Leave", "").trim()}
                </strong>
              </p>
            </div>
          </div>
        );
      });

      if (tempArray.length < 1) {
        return <div className="text-center p-col-12"> No Results. </div>;
      } else {
        return tempArray;
      }
    }
  };

  const getName = async () => {
    if (
      (props.others === "BalanceAndHistory" ||
        props.others === "StatusCredit") &&
      id !== undefined
    ) {
      let user = id;
      let fullName = await commonMethods.getFullDisplayNameFromProvidedUserID(
        user
      );
      setFullDisplayName("(" + fullName + ") - Admin View");
    }
  };

  const display = () => {
    return (
      <>
        <h1 className="pb-2">Leave Balance {fullDisplayName}</h1>

        <div className="row">{displayBalance()}</div>
        {!adminView && (
          <span
          className="pointer"
            style={{
              display: "inline-block",
              float: "right",
              color: "#6777EF",
              fontSize: "small",
            }}
            onClick={(event) => {
              event.preventDefault();
              setShowOtherLeaves(!showOtherLeaves);
            }}
          >
            {showOtherLeaves === false ? "Show more..." : "Show less..."}{" "}
          </span>
        )}
      </>
    );
  };

  return (
    <div className="row">
      <div
        className="col-12"
        data-tour-id="leave"
        data-tour="These are your remaining days of leave. Click Show More for more eligible leaves."
      >
        {display()}
      </div>
    </div>
  );
}

export default LeaveBalancePanelHorizontal;
