import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { ScoreIndicatorService } from "../../service/ScoreIndicatorService";
import {
  CommonMethods,
  DropdownModel,
  RouteComponentProps,
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { UserIndicatorModel } from "./kpipoints/KPIModels";
import { Link } from "react-router-dom";
import { ToastStateContext } from "../resources/ToastContext";

export default function KPIBossView(props: RouteComponentProps) {
  const sis = new ScoreIndicatorService();
  const commonMethods = new CommonMethods();
  const {SetError} = React.useContext(ToastStateContext);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMessage] = useState<string>("An error has occurred");
  const [isLoading, setIsLoading] = useState(true);
  const [groupCompanyKPIList, setGroupCompanyKPIList] = useState<
    Array<UserIndicatorModel>
  >(new Array<UserIndicatorModel>());
  const [companyList, setCompanyList] = useState<Array<DropdownModel>>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>(
    props.userProfile.companyID
  );
  const [dateFilter, setDateFilter] = useState<Date>(new Date());

  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    commonMethods
      .getCompanyDropdownListByGcid()
      .then((res) => {
        setCompanyList(res);
      })
      .catch(() => {
        SetError("Error loading list of companies");
      });
  }, []);

  useEffect(() => {
    let month = moment(dateFilter).month() + 1;
    let year = moment(dateFilter).year();

    sis
      .getCompanyKPIList(selectedCompany, month, year)
      .then((res) => {
        setGroupCompanyKPIList(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
      });
  }, [selectedCompany, dateFilter]);

  const pointsAchieved = (rowData: UserIndicatorModel) => {
    let per = (rowData.points / rowData.targetPoints) * 100;

    return (
      <span>
        {per.toFixed(2)}% ({rowData.points}/{rowData.targetPoints}){" "}
      </span>
    );
  };

  const header = () => {
    return (
      <div style={{ textAlign: "left" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            onInput={(e: any) => setSearchValue(e.currentTarget.value)}
          />
        </span>

        <Calendar
          showButtonBar
          todayButtonClassName="d-none"
          yearNavigator
          yearRange="2020:2023"
          id="MonthCalendar"
          view="month"
          className="mb-1 mx-1"
          placeholder="Select Month"
          value={dateFilter}
          dateFormat="M-yy"
          onChange={(e) => {
            if (e.value === null) {
              setDateFilter(new Date());
            } else {
              setDateFilter(new Date(e.value.toString()));
          }}}
        />

        <Dropdown
          className="mb-1"
          value={selectedCompany}
          options={companyList}
          onChange={(e) => setSelectedCompany(e.value)}
          placeholder="Please Select Company"
        />
        {/* <span className="px-4">
          No. of entries: {groupCompanyKPIList.length}
        </span> */}
      </div>
    );
  };

  // const displayCampaign = (rowData: any) => {
  //   if (rowData.campaignName !== null) {
  //     return rowData.campaignName;
  //   }
  //   return "-";
  // };

  // const displayDeliverable = (rowData: any) => {
  //   if (rowData.deliverableName !== null) {
  //     return rowData.deliverableName;
  //   }
  //   return "-";
  // };
  const headerTemplate = (data: UserIndicatorModel) => {
    // let totalPoints = 0;
    // let totalTargetPoints = 0;
    // groupCompanyKPIList.forEach((item) => {
    //   if (item.departmentName === data.departmentName) {
    //     totalPoints += item.points;
    //     totalTargetPoints += item.targetPoints;
    //   }
    // });

    return (
      <>
        <h5 className="neuhrTextColor textLeft d-inline pt-3">
          {data.departmentName}
        </h5>{" "}
        <Link to={"/indicators/stats/" + data.departmentID}>
          [View Department Stats]
        </Link>
      </>
    );
  };
  const footerTemplate = (data: UserIndicatorModel, index: any) => {
    let totalPoints = 0;
    let totalTargetPoints = 0;
    groupCompanyKPIList.forEach((item) => {
      if (item.departmentName === data.departmentName) {
        totalPoints += item.points;
        totalTargetPoints += item.targetPoints;
      }
    });

    return (
      <>
        <td colSpan={1}></td>
        <td
          key={data.departmentName + "_footerTotalLabel"}
          colSpan={1}
          className="neuhrTextColor text-center"
        >
          {" "}
          Dept Average: {((totalPoints / totalTargetPoints) * 100).toFixed(2)}%
        </td>
      </>
    );
  };

  let display = (
    <div className="datatable-centerHeader datatable-centerContent">
      <DataTable
        value={groupCompanyKPIList}
        globalFilter={searchValue}
        header={header()}
        autoLayout={true}
        alwaysShowPaginator={false}
        emptyMessage="No KPI found."
        rowGroupMode="subheader"
        sortField="departmentName"
        sortOrder={1}
        groupField="departmentName"
        rowGroupHeaderTemplate={headerTemplate}
        rowGroupFooterTemplate={footerTemplate}
      >
        <Column field="departmentName" header="Department" className="d-none" />
        <Column
          field="displayFullName"
          header="Employee"
          className="text-center"
        />
        <Column
          body={pointsAchieved}
          header="Achieved %"
          className="text-center"
        />
      </DataTable>
    </div>
  );

  let page;
  if (isError) {
    page = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    page = <ProgressSpinner />;
  } else {
    page = display;
  }

  return (
    <div className="row">
      <div className="col-12 col-lg-8">
        <div className="card">
          <h1 className="pb-2">General Overview of KPI across Companies</h1>
          {page}
        </div>
      </div>
    </div>
  );
}
