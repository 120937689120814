import * as React from "react";
import CustomError from "../resources/Error";
import { UserService } from "../../service/UserService";
import { ClaimsService } from "../../service/ClaimsService";

import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import { ProgressSpinner } from "primereact/progressspinner";
import { UserModel } from "./User";
import { Redirect } from "react-router";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ClaimsDisplayModel } from "../claims/ClaimsModel";
import {Checkbox } from "primereact/checkbox";

interface AppStateView {
    userObj: UserModel;
    claimObj: ClaimsDisplayModel[];
    totalWellnessAmount: number;
    totalDentalAmount: number;
    currency: string;

    redirectOut: boolean;
    redirectPath: string;

    isLoading: boolean;
    claimsIsLoading: boolean;

    isError: boolean;
    claimsIsError: boolean;

    errorMsg: string;
    claimsErrorMsg: string;

    isTSL: boolean;
}

export class UserView extends React.Component<
    RouteComponentProps<any>,
    AppStateView
> {
    userService: UserService;
    claimsService: ClaimsService;
    commonMethods: CommonMethods;
    nationalityList: any;

    constructor(props: RouteComponentProps<any>) {
        super(props);
        this.state = {
            userObj: new UserModel(),
            claimObj: [],
            totalWellnessAmount: 0,
            totalDentalAmount: 0,
            currency: "",
            redirectOut: false,
            redirectPath: "",
            isLoading: true,
            claimsIsLoading: true,

            isError: false,
            claimsIsError: false,

            errorMsg: "",
            claimsErrorMsg: "",

            isTSL: false,

        };
        this.userService = new UserService();
        this.claimsService = new ClaimsService();
        this.commonMethods = new CommonMethods();
        this.nationalityList = [];

        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        if (
            this.props.match.params !== {} &&
            this.props.match.params.id !== undefined
        ) {
            var userIDfromQuery = this.props.match.params.id;
            this.getUserFromDB(userIDfromQuery);
            this.getUserClaims(userIDfromQuery);

            this.commonMethods
                .isGroupCompanyTSL(this.props.userProfile.groupCompanyID)
                .then((res) => {
                    this.setState({ isTSL: res });
                })
                .catch(() => {
                    this.setState({ isTSL: false });
                });

        } else {
            // error page
        }
    }

    getUserFromDB(userID: string) {
        this.setState({ isLoading: true });
        this.userService
            .getUserByIdForDisplay(userID)
            .then(res => {
                this.setState({ userObj: res }, () =>
                // map legal status
                {
                    if (this.state.userObj.legalStatus === "SC") {
                        this.setState({
                            userObj: {
                                ...this.state.userObj,
                                legalStatus: "Singapore Citizen"
                            }
                        });
                    } else if (this.state.userObj.legalStatus === "PR") {
                        this.setState({
                            userObj: {
                                ...this.state.userObj,
                                legalStatus: "Permanent Resident"
                            }
                        });
                    } else if (this.state.userObj.legalStatus === "FR") {
                        this.setState({
                            userObj: {
                                ...this.state.userObj,
                                legalStatus: "Foreigner"
                            }
                        });
                    }
                }
                );

                // Convert date fields str to date for display
                this.convertDateFieldsForDisplay();

                // map nationality
                this.commonMethods.getNationalityDropdown().then(res => {
                    this.nationalityList = res;
                    for (let i in this.nationalityList) {
                        if (
                            this.state.userObj.nationality ===
                            this.nationalityList[i]["value"]
                        ) {
                            this.updateProperty(
                                "nationality",
                                this.nationalityList[i]["label"]
                            );
                            break;
                        }
                    }
                    this.setState({ isLoading: false });
                });
            })
            .catch(error => {
                let errorMsg = this.commonMethods.getErrorMessage(error);
                this.setState({
                    isError: true,
                    isLoading: false,
                    errorMsg: errorMsg
                });
                return errorMsg;
            });
    }

    getUserClaims(userID: string){
        this.setState({ claimsIsLoading: true});
        this.claimsService
            .getStaffBenefitClaimsByUserID(userID)
            .then((res: ClaimsDisplayModel[]) => {
                // we set currency as the first claim of this person. If the currency differs from the others, we reject it in the calculation
                let totalWellness = 0, totalDental = 0, claimCurrency = "";
                if (res.length != 0){
                    claimCurrency = res[0].currency;
                }
                for (let claim of res){
                    if (claim.currency == claimCurrency){
                        if (claim.campaignTypeName == "Wellness"){
                            totalWellness += claim.amount;
                        } else {
                            totalDental += claim.amount;
                        }
                    }
                }
                this.setState({
                    claimObj: res,
                    claimsIsLoading: false,
                    totalWellnessAmount: totalWellness,
                    totalDentalAmount: totalDental,
                    currency: claimCurrency,
                });
            })
            .catch(error => {
                let claimErrorMsg = this.commonMethods.getErrorMessage(error);
                this.setState({
                    claimsIsError: true,
                    claimsIsLoading: false,
                    claimsErrorMsg: claimErrorMsg
                });
                return claimErrorMsg;
            });
    }

    totalTemplate = (rowData: ClaimsDisplayModel) => {
        return (
            <>
                {rowData.currency + " " +
                rowData.amount.toLocaleString(undefined, {
                    minimumFractionDigits: 2, maximumFractionDigits: 2,
                })}
            </>
        );
    }

    dateTemplate = (rowData: ClaimsDisplayModel) => {
        return this.commonMethods.displayDate(rowData.receiptDate)
    }

    convertDateFieldsForDisplay() {
        if (!this.commonMethods.isDateNull(this.state.userObj.dateOfBirth)) {
            let dob = this.commonMethods.displayDate(
                this.state.userObj.dateOfBirth
            );
            this.updateProperty("dateOfBirth", dob);
        } else {
            this.updateProperty("dateOfBirth", "");
        }

        if (!this.commonMethods.isDateNull(this.state.userObj.prObtainedDate)) {
            let prdate = this.commonMethods.displayDate(
                this.state.userObj.prObtainedDate
            );
            this.updateProperty("prObtainedDate", prdate);
        } else {
            this.updateProperty("prObtainedDate", "");
        }

        if (
            !this.commonMethods.isDateNull(this.state.userObj.wP_WorkPermitExpiry)
        ) {
            let wpexpiry = this.commonMethods.displayDate(
                this.state.userObj.wP_WorkPermitExpiry
            );
            this.updateProperty("wP_WorkPermitExpiry", wpexpiry);
        } else {
            this.updateProperty("wP_WorkPermitExpiry", "");
        }
    }

    updateProperty(property: string, value: any) {
        let tempUserObj = this.state.userObj;
        (tempUserObj as any)[property] = value;
        this.setState({
            userObj: tempUserObj
        });
    }

    goBack() {
        this.setState({
            redirectOut: true,
            redirectPath: "/admin/employees"
        });
    }

    isEquipmentPart() {
        if (this.state.isTSL) {
            return (
                <>
                    <div className="p-col-4">
                        <strong>Equipment ID: </strong>
                    </div>
                    <div className="p-col-8">
                        {Buffer.from(
                            this.state.userObj.userID.replace(/-/g, ""),
                            "hex"
                        ).toString("base64")}
                    </div>
                </>
            );
        }
    }

    render() {
		console.log(this.state.userObj);
        let displayPrDateObtained;
        if (this.state.userObj.legalStatus === "Permanent Resident") {
            displayPrDateObtained = (
                <>
                    <div className="p-col-4">
                        <strong>PR Date Obtained: </strong>
                    </div>
                    <div className="p-col-8">
                        {this.state.userObj.prObtainedDate === null
                            ? "-"
                            : this.state.userObj.prObtainedDate.toString()}
                    </div>
                </>
            );
        } else {
            displayPrDateObtained = "";
        }

        let displayFRRemoteDetails;
        if (this.state.userObj.legalStatus === "Foreigner" && this.state.userObj.wP_WorkLocation === "Remote") {
            displayFRRemoteDetails = (
                <>
                    <div className="p-col-4">
                        <strong>Work Location: </strong>
                    </div>
                    <div className="p-col-8">{this.state.userObj.wP_WorkLocation}</div>
                    <div className="p-col-4">
                        <strong>Passport Number: </strong>
                    </div>
                    <div className="p-col-8">{this.state.userObj.wP_PassportNumber}</div>
                </>
            );
        } else {
            displayFRRemoteDetails = "";
        }

        let displayFRSGDetails;
        if (this.state.userObj.legalStatus === "Foreigner" && this.state.userObj.wP_WorkLocation === "SG") {
            displayFRSGDetails = (
                <>
                    <div className="p-col-4">
                        <strong>Work Location: </strong>
                    </div>
                    <div className="p-col-8">{this.state.userObj.wP_WorkLocation}</div>
                    <div className="p-col-4">
                        <strong>Work Pass Type: </strong>
                    </div>
                    <div className="p-col-8">{this.state.userObj.wP_WorkPass}</div>
                    <div className="p-col-4">
                        <strong>Work Pass Number: </strong>
                    </div>
                    <div className="p-col-8">{this.state.userObj.wP_DependantPass}</div>
                    <div className="p-col-4">
                        <strong>Work Pass Expiry Date: </strong>
                    </div>
                    <div className="p-col-8">{this.state.userObj.wP_WorkPermitExpiry}</div>
                </>
            )
        }

        let page;
        page = (
            <>
                <div className="p-grid p-justify-center pt-4">
                    <div className="imagecropper">
                        <img src={this.state.userObj.displayPicture} alt="displaypicture" />
                    </div>
                </div>

                <div className="p-col-12 py-2">
                    <h5> Employee Details</h5>
                </div>
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid">
                                <div className="p-col-4">
                                    <strong>First Name: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.firstName}</div>
                                <div className="p-col-4">
                                    <strong>Last Name: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.lastName}</div>
                                <div className="p-col-4">
                                    <strong>Full Name (as per NRIC): </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.fullName}</div>
                                <div className="p-col-4">
                                    <strong>Email: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.email}</div>
                                <div className="p-col-4">
                                    <strong>Company: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.companyName}</div>
                                <div className="p-col-4">
                                    <strong>Department: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.departmentName}
                                </div>
                                <div className="p-col-4">
                                    <strong>Job Title: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.jobTitle}</div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid p-fluid">
                                <div className="p-col-4">
                                    <strong>User Rank: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.userRankName}</div>
                                <div className="p-col-4">
                                    <strong>Role: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.roleName}</div>
                                <div className="p-col-4">
                                    <strong>Supervisors: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.approverList.map(
                                        (item: string, index: number) => {
                                            if (
                                                index ===
                                                this.state.userObj.approverList.length - 1
                                            ) {
                                                return item;
                                            } else {
                                                return item + ", ";
                                            }
                                        }
                                    )}
                                </div>
                                <div className="p-col-4">
                                    <strong>Subordinates: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.subordinateList.map(
                                        (item: string, index: number) => {
                                            if (
                                                index ===
                                                this.state.userObj.subordinateList.length - 1
                                            ) {
                                                return item;
                                            } else {
                                                return item + ", ";
                                            }
                                        }
                                    )}
                                </div>

                                <div className="p-col-4">
                                    <strong>Account Status: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.accStatus === "Active"
                                        ? "Active"
                                        : "Deactivated"}
                                </div>
                                {this.isEquipmentPart()}
								<div className="p-col-4">
                                    <strong>Budgeting Group: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.budgetingGroup}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{ height: 3, margin: 0 }} />

                <div className="p-col-12 py-2">
                    <h5> Personal Particulars</h5>
                </div>
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid">
                                <div className="p-col-4">
                                    <strong>Nationality: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.nationality}</div>
                                <div className="p-col-4">
                                    <strong>Citizenship: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.legalStatus}</div>
                                <div className="p-col-4">
                                    <strong>NRIC / FIN / ID: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.identificationNumber}</div>
                                {displayPrDateObtained}
                                {displayFRRemoteDetails}
                                {displayFRSGDetails}

                                <div className="p-col-4">
                                    <strong>Date of Birth: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.dateOfBirth === null
                                        ? "-"
                                        : this.state.userObj.dateOfBirth}
                                </div>
                                <div className="p-col-4">
                                    <strong>Gender: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.gender}</div>
                                <div className="p-col-4">
                                    <strong>Salutation: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.salutation}</div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid">
                                <div className="p-col-4">
                                    <strong>Marital Status: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.maritalStatus}
                                </div>
                                <div className="p-col-4">
                                    <strong>Race:</strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.race}</div>
                                <div className="p-col-4">
                                    <strong>Religion:</strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.religion}</div>
                                <div className="p-col-4">
                                    <strong>Personal Email: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.pP_PersonalEmail}
                                </div>
                                <div className="p-col-4">
                                    <strong>Residential Address: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.pP_ResidentialAddress}
                                </div>
                                <div className="p-col-4">
                                    <strong>Mobile Number: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.pP_MobileNumber}
                                </div>
                                <div className="p-col-4">
                                    <strong>Home Number: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.pP_HomeNumber}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr style={{ height: 3, margin: 0 }} />
                <div className="p-col-12 py-2">
                    <h5> Emergency Details</h5>
                </div>
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid p-fluid">
                                <div className="p-col-4">
                                    <strong>Name: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.emergency_Name}
                                </div>
                                <div className="p-col-4">
                                    <strong>Relationship: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.emergency_Relationship}
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid p-fluid">
                                <div className="p-col-4">
                                    <strong>Contact Number: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.emergency_Contact}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{ height: 3, margin: 0 }} />

                <div className="p-col-12 py-2">
                    <h5>Bank & Statutory Details</h5>
                </div>
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid">
                                <div className="p-col-4">
                                    <strong>Name of Bank: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.bank_Name}</div>
                                <div className="p-col-4">
                                    <strong>Account Type: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.bank_AccountType}
                                </div>
                                <div className="p-col-4">
                                    <strong>Account Name: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.bank_PayeeName}
                                </div>
                                <div className="p-col-4">
                                    <strong>Account Number: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.bank_Number}</div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid p-fluid">
                                <div className="p-col-4">
                                    <strong>SHG Contributions: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.shgEligible ? "Yes" : "No"}
                                </div>
                                <div className="p-col-4">
                                    <strong>SHG Group: </strong>
                                </div>
                                <div className="p-col-8">{this.state.userObj.shgGroup}</div>
                                <div className="p-col-4">
                                    <strong>Skills Development Fund: </strong>
                                </div>
                                <div className="p-col-8">
                                    {this.state.userObj.sdlEligible ? "Yes" : "No"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				<hr style={{ height: 3, margin: 0 }} />
				<div className="p-col-12 py-2">
					<h5>Public Profile Permission</h5>
				</div>
				<div className="p-grid p-justify-center">
					<div className="p-col-12 p-lg-8">
						<div className="p-grid p-fluid">
							<div className="p-col-12">
								<div className="field-checkbox">
									<Checkbox
										className="mr-2"
										disabled
										inputId="publicprofilepermission"
										checked={this.state.userObj.publicProfilePermission}
									/>
									<label className="mb-0"htmlFor="publicprofilepermission">
										I agree to have my information (Birthdays) displayed on the Company Feed and in my Profile
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
            </>
        );

        let claimsPage;
        claimsPage = (
            <>
                <div className="p-col-12">
                    <div className="p-grid p-fluid">
                        <div className="p-col-5"><strong>Total Wellness Claim Amount: </strong></div>
                        <div className="p-col-7">{this.state.claimObj ? (this.state.currency + " " + this.state.totalWellnessAmount) : ""}</div>
                        <div className="p-col-5"><strong>Total Dental Claim Amount: </strong></div>
                        <div className="p-col-7">{this.state.claimObj ? (this.state.currency + " " + this.state.totalDentalAmount) : ""}</div>
                    </div>
                </div>
                <DataTable
                    value={this.state.claimObj}
                    autoLayout={true}
                    emptyMessage="No claims found"
                >
                    <Column field="claimSerialNo" header="ID" />
                    <Column field="campaignTypeName" header="Claim Type" />
                    <Column field="receiptDate" body={this.dateTemplate}header="Date" />
                    <Column field="amount" body={this.totalTemplate} header="Amount" />
                    <Column field="claimStatus" header = "Status" />
                </DataTable>
            </>
        )

        let display;
        if (this.state.isError) {
            display = <CustomError message={this.state.errorMsg} />;
        } else if (this.state.isLoading) {
            display = <ProgressSpinner />;
        } else {
            display = page;
        }

        let claimsDisplay;
        if (this.state.claimsIsError) {
            claimsDisplay = <CustomError message={this.state.claimsErrorMsg} />;
        } else if (this.state.claimsIsLoading) {
            claimsDisplay = <ProgressSpinner />;
        } else {
            claimsDisplay = claimsPage;
        }

        if (this.state.redirectOut) {
            return (
                <Redirect
                    push
                    to={{
                        pathname: this.state.redirectPath
                    }}
                />
            );
        }

        return (
            <div>
                <Button
                    label="Back to Users"
                    onClick={this.goBack}
                    icon="pi pi-angle-left"
                    iconPos="left"
                    className="p-button-primary my-2"
                />
                <div className="row">
                    <div className="col-12 col-xl-7">
                        <div className="card">
                            <h1 className="pb-2"> Profile</h1>
                            {display}
                        </div>
                    </div>
                    <div className="col-12 col-xl-5">
                        <div className="card">
                            <h1 className="pb-2">Staff Benefits Claims</h1>
                            {claimsDisplay}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
