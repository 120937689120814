import * as React from 'react';
import {withRouter, RouteComponentProps } from 'react-router-dom';

interface AppProps {
    location: string;
}

interface AppState {}

class ScrollToTop extends React.Component<RouteComponentProps<any>, AppState> {
    componentDidUpdate(prevProps: any) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop);