import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { StatusService } from "../../service/StatusService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { TaskList } from "../resources/ListOfTasks";
import LeaveBalanceOneDeptView from "./LeaveBalanceOneDeptView";

export class StatusBalanceModelCondensed {
  public userID: string = "";
  public fullName: string = "";
  public fullNameDisplayed: string = "";
  public companyName: string = "";
  public departmentName: string = "";
  public userRankName: string = "";
  public statusName: string = "";
  public availableDays: number = 0;
}

export class StatusBalanceModelCondensedWithBF {
	public userID: string = "";
	public fullName: string = "";
	public fullNameDisplayed: string = "";
	public companyName: string = "";
	public departmentName: string = "";
	public userRankName: string = "";
	public statusName: string = "";
	public availableDays: number = 0;
	public isBroughtForward: boolean = false;
}


export class MultiDeptLeaveBalanceModel {
  public companyName: string = "";
  public departmentName: string = "";
  public statusBalanceModelCondensedList: Array<
    StatusBalanceModelCondensed
  > = new Array<StatusBalanceModelCondensed>();
  public userIDs: Array<string> = new Array<string>();
}

export class MultiDeptLeaveBalanceModelWithBF {
	public companyName: string = "";
	public departmentName: string = "";
	public statusBalanceModelCondensedListWithBF: Array<
	  StatusBalanceModelCondensedWithBF
	> = new Array<StatusBalanceModelCondensedWithBF>();
	public userIDs: Array<string> = new Array<string>();
}

export function LeaveBalanceDeptView(props: RouteComponentProps) {
  const statusService = new StatusService();
  const commonMethods = new CommonMethods();

  const [multiDepartmentUserBalance, setMultiDepartmentUserBalance] = useState<
    MultiDeptLeaveBalanceModelWithBF[]
  >(new Array<MultiDeptLeaveBalanceModelWithBF>());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState<string>("");
  const [hasCustomLeaveAccess] = useState<boolean>(
    props.userProfile.taskList.includes(TaskList.customleaveaccess)
  );

  useEffect(() => {
    if (hasCustomLeaveAccess) {
      getBalanceForCustomDepartment();
    } else {
      setIsLoading(false);
    }
  }, []);

  const getBalanceForCustomDepartment = () => {
    statusService
      .getMultipleDeptBalance()
      .then((res) => {
        setMultiDepartmentUserBalance(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrMsg(commonMethods.getErrorMessage(err));
        setIsError(true);
      });
  };

  const multiDeptDisplay = () => {
    let displayArr: any[] = [];
    multiDepartmentUserBalance.forEach((dept: MultiDeptLeaveBalanceModelWithBF) => {
      let oneDept = (
        <div className="row" key={dept.departmentName}>
          <div className="col-12 col-xl-12">
            <div className="card">
              <h1 className="pb-2">
                Leave Balance - {dept.departmentName} [{dept.companyName}]
              </h1>
              <LeaveBalanceOneDeptView
                {...props}
                others={{
                  deptUserBalance: dept.statusBalanceModelCondensedListWithBF,
                  deptUserIds: dept.userIDs,
                }}
              />
            </div>
          </div>
        </div>
      );
      displayArr.push(oneDept);
    });
    return displayArr;
  };

  let display;
  if (isError) {
    display = <CustomError message={errMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else if (hasCustomLeaveAccess && multiDepartmentUserBalance.length > 0) {
    display = multiDeptDisplay();
  } else {
    display = (
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="card">
            <h1 className="pb-2">My Department - Leave Balance</h1>
            <LeaveBalanceOneDeptView
              {...props}
              others={{
                // deptUserBalance: departmentUserBalance,
                // deptUserIds: userIDs,
                mydept: true,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
  return <div>{display}</div>;
}
