export const ACTIONS = {

    // SinglePostContainer specific actions 
    LOAD_POST: 'load-post',
    SET_POST_LOADED: 'set-post-loaded',

    // CompanyFeed specfic actions
    LOAD_POSTS: 'load-posts',
    ADD_POST: 'add-post',
    DELETE_POST: 'delete-post',
    NEXT_PAGE: 'next-page',
    ON_HASMORE: 'on-hasmore',
    OFF_HASMORE: 'off-hasmore',
    BIRTHDAY_POST: {
        LOAD_POST: 'birthdaypost/load-post',
        EDIT_POST: 'birthdaypost/edit-post',
        DELETE_POST: 'birthdaypost/delete-post',
        LOAD_COMMENTS: 'birthdaypost/load-comments',
        ADD_COMMENT: 'birthdaypost/add-comment',
        EDIT_COMMENT: 'birthdaypost/edit-comment',
        DELETE_COMMENT: 'birthdaypost/delete-comment',
        LOAD_CHILD_COMMENTS: 'birthdaypost/load-child-comments',
        ADD_CHILD_COMMENT: 'birthdaypost/add-child-comment',
        EDIT_CHILD_COMMENT: 'birthdaypost/edit-child-comment',
        DELETE_CHILD_COMMENT: 'birthdaypost/delete-child-comment',
        ADD_LIKE: 'birthdaypost/add-like',
        REMOVE_LIKE: 'birthdaypost/remove-like',
    },

    // shared actions, handled differently by different dispatches 
    EDIT_POST: 'edit-post',
    LOAD_COMMENTS: 'load-comments',
    ADD_COMMENT: 'add-comment',
    EDIT_COMMENT: 'edit-comment',
    DELETE_COMMENT: 'delete-comment',
    LOAD_CHILD_COMMENTS: 'load-child-comments',
    ADD_CHILD_COMMENT: 'add-child-comment',
    EDIT_CHILD_COMMENT: 'edit-child-comment',
    DELETE_CHILD_COMMENT: 'delete-child-comment',
    ADD_LIKE: 'add-like',
    REMOVE_LIKE: 'remove-like',
    SAVE_CURRENT_USER: 'save-current-user',
    SET_CURRENT_USER_COMPANYFEED_ADMIN: 'set-current-user-companyfeed-admin',
    SET_ALL_MENTION_SUGGESTIONS: 'set-all-mention-suggestions',
}