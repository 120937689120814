import { RouteComponentProps, useParams, Redirect } from "react-router";
import { RoomService } from "../../service/RoomService";
import { RoomModel } from "./RoomModel";
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ToastStateContext } from "../resources/ToastContext";
import CustomError  from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";
import { CommonMethods, ActionTypes, ParamIDTypes } from "../resources/CommonMethods";
import { InputNumber } from 'primereact/inputnumber';
import ReactTooltip from "react-tooltip";

function RoomCreate(props: RouteComponentProps) {
  const roomService = new RoomService();
  const commonMethods = new CommonMethods();
  const { id } = useParams<ParamIDTypes>();
  const { SetSuccess, SetError } = React.useContext(ToastStateContext)

  const [roomData, setRoomData] = useState<RoomModel>(new RoomModel());

  const [action, setAction] = useState<string>(ActionTypes.create);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [toHome, setToHome] = useState(false);


  useEffect(() => {
    if (id !== undefined) {
      roomService
        .getRoomById(id)
        .then((res) => {
            setRoomData(res);
            setIsLoading(false);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMessage(commonMethods.getErrorMessage(err));
        });
      setAction(ActionTypes.update);
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (action === ActionTypes.create) {
      roomService
        .createRoom(roomData)
        .then((res) => {
          SetSuccess("New Room created!");
          setToHome(true);
        })
        .catch((err) => {
          SetError(commonMethods.getErrorMessage(err));
        });
    } else if (action === ActionTypes.update) {
      roomService
        .updateRoom(roomData)
        .then((res) => {
          SetSuccess("Room updated!");
          setToHome(true);
        })
        .catch((err) => {
          SetError(commonMethods.getErrorMessage(err));
        });
    }
  };

  let page = (
    <form onSubmit={handleSubmit}>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <span className="boldLabel">
            Room Name <span style={{ color: "red" }}>*</span>
          </span>
          <InputText
            value={roomData.roomName}
            onChange={(event) =>
              setRoomData({
                ...roomData,
                roomName: event.currentTarget.value,
              })
            }
            required
          />
        </div>
        <div className="p-col-12">
          <span className="boldLabel">
            Room Description <span style={{ color: "red" }}>*</span>
          </span>
          <InputText
            value={roomData.roomDescription}
            onChange={(event) =>
              setRoomData({
                ...roomData,
                roomDescription: event.currentTarget.value,
              })
            }
            required
          />
        </div>
        <div className="p-col-12">
          <span className="boldLabel">
            Room Order{" "}
            <i
              className="fas fa-info-circle informationStyle"
              data-tip=""
              data-for="roomorder"
            ></i>{" "}
          </span>
          <ReactTooltip
            id="roomorder"
            aria-haspopup="true"
            className="tooltipFont"
          >
            <p>
              The display of rooms on the noticeboard are ordered by increasing
              room order no.
            </p>
          </ReactTooltip>
          <InputNumber
            value={roomData.roomOrder}
            onChange={(e) =>
              setRoomData({
                ...roomData,
                roomOrder: e.value,
              })
            }
            min={0}
            max={999}
            showButtons
          />
        </div>
      </div>

      <div className="p-grid p-justify-center pt-2">
        <Button type="submit" label={action} />
      </div>
    </form>
  );
  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <>
      {toHome ? <Redirect push to="/admin/room" /> : null}

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{action} Room</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {display}
          </div>
        </div>
      </div>
    </>
  );
}

export default RoomCreate;
