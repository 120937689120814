import _ from "lodash";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { ScoreIndicatorService } from "../../../service/ScoreIndicatorService";
import {
  CommonMethods,
  RouteComponentProps,
} from "../../resources/CommonMethods";
import CustomError from "../../resources/Error";
import KPITable from "../kpipoints/KPITable";
import { UserIndicatorWithTargetModel } from "../kpipoints/KPIModels";

function KPIDeptView(props: RouteComponentProps<any>) {
  const sis = new ScoreIndicatorService();
  let commonMethods = new CommonMethods();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const [arrToDisplay, setArrToDisplay] = useState();
  const [activeIndexArr, setActiveIndexArr] = useState<any>([]);
  const [deptUserIndicatorList, setDeptUserIndicatorList] = useState(
    new Array<UserIndicatorWithTargetModel>()
  );
  const colorArray: string[] = commonMethods.getColourArr();

  useEffect(() => {
    if (props.others !== null && props.others !== "") {
      getMultiDeptData();
    }
  }, [props.others]);

  const getMyDeptData = () => {
    sis
      .getMyDeptKPIEntries()
      .then((res) => {
        setDeptUserIndicatorList(res);
        displayPerPerson(res);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
        setErrorMsg(commonMethods.getErrorMessage(err));
      });
  };

  const getMultiDeptData = () => {
    sis
      .getDeptKPIEntries(props.others)
      .then((res) => {
        setDeptUserIndicatorList(res);
        displayPerPerson(res);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
        setErrorMsg(commonMethods.getErrorMessage(err));
      });
  };

  const displayPerPerson = (
    deptKPIList: Array<UserIndicatorWithTargetModel>
  ) => {
    let arrOfDelPerPerson: any;
    arrOfDelPerPerson = [];

    let colourCounter = 0;
    let maxLengthOfColors = colorArray.length;

    // -- accordion --
    deptKPIList.forEach((user, index) => {
      // colour for accordion
      colourCounter++;
      if (index % maxLengthOfColors === 0) {
        colourCounter = 0;
      }
      let col = colorArray[colourCounter];

      // let header = user.userName + " (" + user.points + ")";
      let header = user.fullName;
      let item = (
        <AccordionTab
          key={user.fullName}
          header={header}
          headerStyle={{ backgroundColor: col }}
          headerClassName="no-bg"
        >
          <KPITable
            {...props}
            others={{ item: user, update: getMyDeptData, hodview: true }}
          />
        </AccordionTab>
      );

      arrOfDelPerPerson.push(item);
    });

    setIsLoading(false);
    setArrToDisplay(arrOfDelPerPerson);
    // setCardsToDisplay(cardsPerPerson);
  };

  const openAllAccordionTabs = () => {
    if (activeIndexArr.length === 0) {
      setActiveIndexArr(_.range(deptUserIndicatorList.length));
    } else {
      setActiveIndexArr([]);
    }
  };

  let accordion = (
    <Accordion
      multiple
      activeIndex={activeIndexArr}
      onTabChange={(e) => setActiveIndexArr(e.index)}
    >
      {arrToDisplay}
    </Accordion>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = (
      <div className="col-12">
        <Button
          icon="pi pi-angle-double-down"
          onClick={(e) => openAllAccordionTabs()}
          label="All"
        ></Button>
        {accordion}
      </div>
    );
  }

  return (
    <div
      className="row"
      data-tour-id="deliverables-department-overview"
      data-tour="This is the list of outstanding deliverables for everyone in your department."
    >
      {display}
    </div>
  );
}

export default KPIDeptView;
