import _ from "lodash";
import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { SelectButton } from "primereact/selectbutton";
import React, { Component } from "react";
import { Redirect } from "react-router";
import { StatusRecordService } from "../../service/StatusRecordService";
import { StatusService } from "../../service/StatusService";
import { CommonDocumentMethods } from "../resources/CommonDocumentMethods";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";

interface dataObj {
  [key: string]: number | string;
}

type AppState = {
  statusTypesDropdown: any[];
  statusRecord: StatusRecordObj;
  statusTypeName: string;
  existingStatusArray: any[];
  titleString: string;
  buttonLabel: string;
  redirectOut: boolean;
  redirectPath: string;
  isLoading: boolean;
  isTSL: boolean;
  isError: boolean;
  errorMsg: string;
  requireGoogleForm_TimeOff: boolean;
};

export class StatusRecordObj {
  statusRecordID: string = CommonMethods.EmptyGuid;
  userID: string = CommonMethods.EmptyGuid;
  statusTypeID: string = CommonMethods.EmptyGuid;
  startDate: Date = new Date();
  endDate: Date = new Date();
  startDateDuration: string = "FULL DAY";
  endDateDuration: string = "FULL DAY";
  creditDebitValue: number = 0;
  remarks: string = "";
  duration: string = "";
  documentFile: File | any;
  documentFilePath: string | any;
  statusTypeName: string = "";
  broughtForward: boolean = false;
}

export class StatusRecordDisplayObj {
  statusRecordID: string = CommonMethods.EmptyGuid;
  userID: string = CommonMethods.EmptyGuid;
  statusTypeID: string = CommonMethods.EmptyGuid;
  statusTypeName: string = "";
  startDate: Date = new Date();
  endDate: Date = new Date();
  startDateDuration: string = "FULL DAY";
  endDateDuration: string = "FULL DAY";
  creditDebitValue: number = 0;
  remarks: string = "";
  duration: string = "";
  documentFile: File | any;
  documentFilePath: string | any;
  fullDisplayName: string = "";
  approvalStatus: string = "";
}

export class StatusRecordDocumentObj {
  statusRecordID: string = CommonMethods.EmptyGuid;
  userID: string = CommonMethods.EmptyGuid;
  statusTypeID: string = CommonMethods.EmptyGuid;
  startDate: Date = new Date();
  endDate: Date = new Date();
  startDateDuration: string = "FULL DAY";
  endDateDuration: string = "FULL DAY";
  creditDebitValue: number = 0;
  approvingRemarks: string = "";
  remarks: string = "";
  documentFile: File | any;
  documentFilePath: string | any;
  documentType: string = "";
  uploadDate: Date = new Date();
  uploadPath: string = "";
  verificationStatus: string = "";
  verifiedBy: string = "";
  verifiedDateTimeStamp: Date = new Date();
  verifiedRemarks: string = "";
  documentPath: string = "";

  approvalStatus: string = "";
  updatedBy: string = "";
  lastUpdated: Date = new Date();
  approvers: string = "";
  auditedBy: string = "";
  auditedDateTimestamp: string = "";
  auditedStatus: string = "";
  departmentName: string = "";
  duration: string = "";
  fullDisplayName: string = "";
  id: string = "";
  statusTypeSupportDoc: boolean = false;
  createdTime: Date = new Date();
}

export class LeaveApplicationForm extends Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;
  commonMethods: CommonMethods;
  statusRecordService: StatusRecordService;
  statusService: StatusService;

  // TSL leave status
  outOfOfficeID: string;
  timeOffID: string;
  overtimeID: string;
  medicalApptID: string;
  offinlieuID: string;

  durationSameDaySelection: dataObj[];
  durationDifferentDaySelectionForEndDate: dataObj[];
  durationDifferentDaySelectionForStartDate: dataObj[];
  arrayOfLeaveIDsThatRequireDocuments: string[];

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      statusTypesDropdown: [],
      statusRecord: new StatusRecordObj(),
      statusTypeName: "",
      existingStatusArray: [],
      titleString: "Leave Application",
      buttonLabel: "Apply",
      redirectOut: false,
      redirectPath: "",
      isLoading: true,
      isTSL: false,
      isError: false,
      errorMsg: "",
      requireGoogleForm_TimeOff: false,
    };
    this.statusRecordService = new StatusRecordService();
    this.commonMethods = new CommonMethods();
    this.statusService = new StatusService();

    this.outOfOfficeID = "NULL";
    this.overtimeID = "NULL";
    this.timeOffID = "NULL";
    this.medicalApptID = "NULL";
    this.offinlieuID = "NULL";

    this.arrayOfLeaveIDsThatRequireDocuments = [];
    this.durationSameDaySelection = this.commonMethods.getDurationOptions();
    this.durationDifferentDaySelectionForEndDate = [
      { label: "FULL", value: "FULL DAY" },
      { label: "AM", value: "AM" },
    ];
    this.durationDifferentDaySelectionForStartDate = [
      { label: "FULL", value: "FULL DAY" },
      { label: "PM", value: "PM" },
    ];

    this.submitForm = this.submitForm.bind(this);
    this.setDurations = this.setDurations.bind(this);

    this.onLeaveTypeDropdownChange = this.onLeaveTypeDropdownChange.bind(this);
    this.configureDropdownForBroughtForwardStart = this.configureDropdownForBroughtForwardStart.bind(this);
    this.configureDropdownForBroughtForwardEnd = this.configureDropdownForBroughtForwardEnd.bind(this);


    this.resetForm = this.resetForm.bind(this);
    this.isUpdatingApplication = this.isUpdatingApplication.bind(this);
    this.isTimeRelatedStatuses = this.isTimeRelatedStatuses.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.getTSLSpecialLeaveTypes = this.getTSLSpecialLeaveTypes.bind(this);
  }

  componentDidUpdate(prevProps: any) {
    if (
      this.props.others !== undefined &&
      this.props.others.refreshState != undefined &&
      this.props.others.refreshState !== prevProps.others.refreshState
    ) {
      this.loadPage();
    }
  }

  async componentDidMount() {
    this.commonMethods
      .isGroupCompanyTSL(this.props.userProfile.groupCompanyID || "")
      .then((res) => {
        this.setState({ isTSL: res }, () => {
          if (this.state.isTSL) {
            this.getTSLSpecialLeaveTypes();
          }
        });
      })
      .catch((err) => {
        this.setState({ isTSL: false });
      });

    //gets statusTypeIDs for those that required document uploads for approval
    this.statusService
      .getTypesThatRequireDocuments()
      .then((res) => {
        this.arrayOfLeaveIDsThatRequireDocuments = res;
      })
      .catch((err) => {
        this.arrayOfLeaveIDsThatRequireDocuments = [];
      });

    await this.loadPage();
  }

  async getTSLSpecialLeaveTypes() {
    // gets statusTypeID based on user's GCID
    // let statusNameStr = "Time-off,Medical Appointment,Out Of Office,Overtime,Off-In-Lieu (OIL)"; // omit unnecessary spaces
	let statusNameStr = "Time-off,Medical Appointment,Out Of Office,Off-In-Lieu (OIL)"; // omit unnecessary spaces
    this.statusService
      .getStatusIdByName(statusNameStr)
      .then((res) => {
        this.outOfOfficeID =
          _.find(res, ["statusTypeName", "Out Of Office"]).statusTypeID ??
          "NULL";
        this.timeOffID =
          _.find(res, ["statusTypeName", "Time-off"]).statusTypeID ?? "NULL";
        this.medicalApptID =
          _.find(res, ["statusTypeName", "Medical Appointment"]).statusTypeID ??
          "NULL";
        // this.overtimeID =
        //   _.find(res, ["statusTypeName", "Overtime"]).statusTypeID ??
        //   "NULL";
        this.offinlieuID =
          _.find(res, ["statusTypeName", "Off-In-Lieu (OIL)"]).statusTypeID ??
          "NULL";
      })
      .catch((error) => {
        this.setState({
          isError: true,
          errorMsg: this.commonMethods.getErrorMessage(error),
        });
      });
  }

  async loadPage() {
    let user;

    //     ___  ___  ___    _  _____  ___     _    _  _  ___      _    ___  ___  _  __   __   ___   _  _   ___  ___  _  _    _    _     ___
    //    / __|| _ \| __|  /_\|_   _|| __|   /_\  | \| ||   \    /_\  | _ \| _ \| | \ \ / /  / _ \ | \| | | _ )| __|| || |  /_\  | |   | __|
    //   | (__ |   /| _|  / _ \ | |  | _|   / _ \ | .` || |) |  / _ \ |  _/|  _/| |__\ V /  | (_) || .` | | _ \| _| | __ | / _ \ | |__ | _|
    //    \___||_|_\|___|/_/ \_\|_|  |___| /_/ \_\|_|\_||___/  /_/ \_\|_|  |_|  |____||_|    \___/ |_|\_| |___/|___||_||_|/_/ \_\|____||_|
    //
    if (
      this.props.match.path === "/status/apply" ||
      this.props.match.path === "/status/departmentview/apply/:userid"
    ) {
      if (this.props.match.path === "/status/apply") {
        this.statusService
          .getStatusTypeDropdown()
          .then((res) =>
            this.setState({ statusTypesDropdown: res }, () => {
              this.configureDropdownForBroughtForwardStart();
              this.setState(
                {
                  statusRecord: {
                    ...this.state.statusRecord,
                    statusTypeID: "",
                  },
                },
                () => {
                  this.setStatusTypeName();
                }
              );
            })
          )
          .catch((error) => {
            let errorMsg = this.commonMethods.getErrorMessage(error);
            this.setState({
              isError: true,
              isLoading: false,
              errorMsg: errorMsg,
            });
            return errorMsg;
          });
      } else if (
        this.props.match.path === "/status/departmentview/apply/:userid"
      ) {
        user = this.props.match.params.userid;
        let fullDisplayName = await this.commonMethods.getFullDisplayNameFromProvidedUserID(
          user
        );
        this.setState({
          titleString: `Leave Application (on behalf of ${fullDisplayName})`,
        });

        this.statusService
          .getStatusTypeDropdownForThisUser(user)
          .then((res) =>
            this.setState({ statusTypesDropdown: res }, () => {
              this.setState(
                {
                  statusRecord: {
                    ...this.state.statusRecord,
                    statusTypeID: "",
                  },
                },
                () => {
                  this.setStatusTypeName();
                }
              );
            })
          )
          .catch((error) => {
            let errorMsg = this.commonMethods.getErrorMessage(error);
            this.setState({
              isError: true,
              isLoading: false,
              errorMsg: errorMsg,
            });
            return errorMsg;
          });
      }

      this.setState(
        {
          statusRecord: {
            ...this.state.statusRecord,
            startDate: this.commonMethods.getTodayAsDate(),
            endDate: this.commonMethods.getEndOfTodayAsDate(),
            startDateDuration: "",
            endDateDuration: "",
            userID: user,
          },
        },
        () => {
          this.getOverlappingStatus();
        }
      );
    }

    //    _   _  ___  ___    _  _____  ___  _  _   ___    ___  _____  _  _____  _   _  ___
    //   | | | || _ \|   \  /_\|_   _||_ _|| \| | / __|  / __||_   _|/_\|_   _|| | | |/ __|
    //   | |_| ||  _/| |) |/ _ \ | |   | | | .` || (_ |  \__ \  | | / _ \ | |  | |_| |\__ \
    //    \___/ |_|  |___//_/ \_\|_|  |___||_|\_| \___|  |___/  |_|/_/ \_\|_|   \___/ |___/
    //
    else if (this.props.match.path === "/status/update/:id") {
      let recordId: string = this.props.match.params.id;

      this.setState({
        titleString: "Update Leave",
        buttonLabel: "Update",
      });

      this.statusRecordService
        .getStatusRecordById(recordId)
        .then((res) => {
          let returnedObj = res;
          this.setState(
            {
              statusRecord: {
                ...returnedObj,
                startDateDuration: returnedObj.duration.includes("-")
                  ? returnedObj.duration.split("-")[0].trim()
                  : returnedObj.duration,
                endDateDuration: returnedObj.duration.includes("-")
                  ? returnedObj.duration.split("-")[1].trim()
                  : returnedObj.duration,
                startDate: new Date(returnedObj.startDate.toString()),
                endDate: new Date(returnedObj.endDate.toString()),
                statusTypeID: returnedObj.statusTypeID,
                statusTypeName: returnedObj.broughtForward ? returnedObj.statusTypeName : returnedObj.statusTypeName + " (brought forward)",
                userID: returnedObj.userID,
                documentFilePath:
                  returnedObj.uploadPath !== null &&
                  returnedObj.uploadPath !== ""
                    ? "StatusDocument.jpg"
                    : undefined,
              },
            },
            () => {}
          );

          this.statusService
            .getStatusTypeDropdown()
            .then((response) =>
              this.setState({ statusTypesDropdown: response })
            )
            .then(() => {
              this.setStatusTypeName();
            })
            .catch((error) => {
              let errorMsg = this.commonMethods.getErrorMessage(error);
              this.setState({
                isError: true,
                isLoading: false,
                errorMsg: errorMsg,
              });
              return errorMsg;
            });
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
          return errorMsg;
        });
    }

    //      _    ___   __  __  ___  _  _      _    ___  ___  _  __   __    ___   _  _    ___  ___  _  _    _    _     ___
    //     /_\  |   \ |  \/  ||_ _|| \| |    /_\  | _ \| _ \| | \ \ / /   / _ \ | \| |  | _ )| __|| || |  /_\  | |   | __|
    //    / _ \ | |) || |\/| | | | | .` |   / _ \ |  _/|  _/| |__\ V /   | (_) || .` |  | _ \| _| | __ | / _ \ | |__ | _|
    //   /_/ \_\|___/ |_|  |_||___||_|\_|  /_/ \_\|_|  |_|  |____||_|     \___/ |_|\_|  |___/|___||_||_|/_/ \_\|____||_|
    //
    else if (this.props.match.path === "/admin/employees/credit_leave/:id") {
      user = this.props.match.params.id;
      let fullDisplayName = await this.commonMethods.getFullDisplayNameFromProvidedUserID(
        user
      );

      this.setState({
        titleString: `Leave Application (on behalf of ${fullDisplayName})`,
        buttonLabel: `Apply`,
      });

      this.setState(
        {
          statusRecord: {
            ...this.state.statusRecord,
            startDate: this.commonMethods.getTodayAsDate(),
            endDate: this.commonMethods.getTodayAsDate(),
            userID: user,
            startDateDuration: "",
            endDateDuration: "",
            remarks: "",
          },
        },
        () => {
          this.getOverlappingStatus();
        }
      );

      this.statusService
        .getStatusTypeDropdownAll()
        .then((res) =>
          this.setState({ statusTypesDropdown: res }, () => {
            this.setState(
              {
                statusRecord: {
                  ...this.state.statusRecord,
                  statusTypeID: "",
                },
              },
              () => {
                this.setStatusTypeName();
              }
            );
          })
        )
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
          return errorMsg;
        });
    }
    this.setState({ isLoading: false });

    // this.statusService
    //   .getDeductibleAndActiveStatusBalance(user)
    //   .then((res) => {
    //     let tempArray: dataObj[] = [];
    //     let tempObj: dataObj = {};
    //     res.forEach(
    //       (element: {
    //         statusTypeName: React.ReactText;
    //         availableDays: number;
    //       }) => {
    //         tempObj[element.statusTypeName] = element.availableDays;
    //       }
    //     );
    //     tempArray.push(tempObj);

    //     this.setState({ isLoading: false });
    //   })
    //   .catch((error) => {
    //     let errorMsg = this.commonMethods.getErrorMessage(error);
    //     this.setState({
    //       isError: true,
    //       isLoading: false,
    //       errorMsg: errorMsg,
    //     });
    //     return errorMsg;
    //   });
  }

  //    ___  _   _  _  _   ___  _____  ___  ___   _  _  ___
  //   | __|| | | || \| | / __||_   _||_ _|/ _ \ | \| |/ __|
  //   | _| | |_| || .` || (__   | |   | || (_) || .` |\__ \
  //   |_|   \___/ |_|\_| \___|  |_|  |___|\___/ |_|\_||___/
  //

  //TODO: might want to check for TSL only.
  isTimeRelatedStatuses = (statusTypeID: string) => {
    return (
      statusTypeID === this.outOfOfficeID ||
      statusTypeID === this.overtimeID ||
      statusTypeID === this.timeOffID ||
      statusTypeID === this.medicalApptID
    );
  };

  isUpdatingApplication = () => {
    return this.props.match.path === "/status/update/:id";
  };

  resetForm() {
    this.setState({
      statusRecord: {
        ...this.state.statusRecord,
        statusTypeID: "",
        startDate: this.commonMethods.getStartOfTodayAsDate(),
        endDate: this.commonMethods.getStartOfTodayAsDate(),
        startDateDuration: "FULL DAY",
        endDateDuration: "",
        remarks: "",
      },
    });
  }

  setStatusTypeName() {
    let label = "";

    try {
      label = this.state.statusTypesDropdown.find(
        (e) => e.value === this.state.statusRecord.statusTypeID
      ).label;
    } catch (error) {}
    this.setState({
      statusTypeName: label,
      statusRecord: {
        ...this.state.statusRecord,
        statusTypeName: label,
      },
    });
  }

  redirectBack() {
    if (this.props.match.path === "/admin/employees/credit_leave/:id") {
      // this.setState({
      //   redirectPath: "/admin/employees/credit_leave/" + statusRecordObj.userID,
      //   redirectOut: true,
      // });
      if (this.props.others !== undefined && this.props.others !== null) {
        this.props.others.refreshMethod(!this.props.others.refreshState);
      }
    } else if (
      this.props.match.path === "/status/departmentview/apply/:userid"
    ) {
      this.setState({
        redirectPath: "/status/departmentview",
        redirectOut: true,
      });
    } else {
      this.setState({
        redirectPath: "/status/summary/",
        redirectOut: true,
      });
    }
  }

  setDurations = (statusRecordObj: StatusRecordObj) => {
    if (this.isTimeRelatedStatuses(statusRecordObj.statusTypeID)) {
      return statusRecordObj;
    }
    if (
      this.commonMethods.isSameDate(
        statusRecordObj.startDate,
        statusRecordObj.endDate
      )
    ) {
      if (statusRecordObj.startDateDuration === "FULL DAY") {
        statusRecordObj.startDate = this.commonMethods.setStartOfDayOfDate(
          statusRecordObj.startDate
        );
        statusRecordObj.endDate = this.commonMethods.setEndOfDayOfDate(
          statusRecordObj.endDate
        );
        statusRecordObj.endDateDuration = "FULL DAY";
        statusRecordObj.duration = "FULL DAY";
      } else if (statusRecordObj.startDateDuration === "AM") {
        statusRecordObj.startDate = this.commonMethods.setStartOfDayOfDate(
          statusRecordObj.startDate
        );
        statusRecordObj.endDate = this.commonMethods.setDateAtTwoPM(
          statusRecordObj.endDate
        );
        statusRecordObj.endDateDuration = "AM";
        statusRecordObj.duration = "AM";
      } else if (statusRecordObj.startDateDuration === "PM") {
        statusRecordObj.startDate = this.commonMethods.setDateAtTwoPM(
          statusRecordObj.startDate
        );
        statusRecordObj.endDate = this.commonMethods.setEndOfDayOfDate(
          statusRecordObj.endDate
        );
        statusRecordObj.endDateDuration = "PM";
        statusRecordObj.duration = "PM";
      }
    } else {
      statusRecordObj.duration =
        statusRecordObj.startDateDuration +
        " - " +
        statusRecordObj.endDateDuration;

      if (
        statusRecordObj.startDateDuration === "FULL DAY" ||
        statusRecordObj.startDateDuration === "AM"
      ) {
        statusRecordObj.startDate = this.commonMethods.setStartOfDayOfDate(
          statusRecordObj.startDate
        );
      } else {
        statusRecordObj.startDate = this.commonMethods.setDateAtTwoPM(
          statusRecordObj.startDate
        );
      }

      if (
        statusRecordObj.startDateDuration === "FULL DAY" &&
        statusRecordObj.endDateDuration === "AM"
      ) {
        statusRecordObj.startDate = this.commonMethods.setStartOfDayOfDate(
          statusRecordObj.startDate
        );
        statusRecordObj.endDate = this.commonMethods.setDateAtTwoPM(
          statusRecordObj.endDate
        );
      } else if (
        statusRecordObj.startDateDuration === "PM" &&
        statusRecordObj.endDateDuration === "AM"
      ) {
        statusRecordObj.startDate = this.commonMethods.setDateAtTwoPM(
          statusRecordObj.startDate
        );
        statusRecordObj.endDate = this.commonMethods.setDateAtTwoPM(
          statusRecordObj.endDate
        );
      } else if (
        statusRecordObj.startDateDuration === "PM" &&
        statusRecordObj.endDateDuration === "FULL DAY"
      ) {
        statusRecordObj.startDate = this.commonMethods.setDateAtTwoPM(
          statusRecordObj.startDate
        );
        statusRecordObj.endDate = this.commonMethods.setEndOfDayOfDate(
          statusRecordObj.endDate
        );
      } else if (
        statusRecordObj.startDateDuration === "FULL DAY" &&
        statusRecordObj.endDateDuration === "FULL DAY"
      ) {
        statusRecordObj.startDate = this.commonMethods.setStartOfDayOfDate(
          statusRecordObj.startDate
        );
        statusRecordObj.endDate = this.commonMethods.setEndOfDayOfDate(
          statusRecordObj.endDate
        );
      }
    }
    return statusRecordObj;
  };

  // This method is to make it easier for <Dropdown> to correctly identify the brought over version
  configureDropdownForBroughtForwardStart(){
    var dropdownCopy = this.state.statusTypesDropdown;
    dropdownCopy.forEach(statusType => {
      if (statusType.label.includes("brought forward")){
        statusType.value += "x";
      }
    });
    this.setState({ statusTypesDropdown: dropdownCopy });
    console.log(this.state.statusTypesDropdown);
  }

  configureDropdownForBroughtForwardEnd(obj: StatusRecordObj){
    if (obj.statusTypeName.includes("brought forward")){
      obj.statusTypeID = obj.statusTypeID.slice(0, -1);
      obj.broughtForward = true;
    }
    console.log(obj);
  }


  onLeaveTypeDropdownChange(e: { originalEvent: Event; value: any }) {
    this.setState({
      requireGoogleForm_TimeOff: false,
    });
    if (this.isTimeRelatedStatuses(e.value)) {
      this.setState(
        {
          statusRecord: {
            ...this.state.statusRecord,
            statusTypeID: e.value,
            startDate: this.commonMethods.getTodayNowAsDate(),
            endDate: this.commonMethods.getTodayNowAsDate(),
            startDateDuration: "",
            endDateDuration: "",
          },
        },
        () => {
          this.getOverlappingStatus();
          this.setStatusTypeName();

          if (e.value === this.timeOffID) {
            this.setState({ requireGoogleForm_TimeOff: true });
          }
        }
      );
    } else {
      this.setState(
        {
          statusRecord: {
            ...this.state.statusRecord,
            statusTypeID: e.value,
            startDate: this.commonMethods.getTodayAsDate(),
            endDate: this.commonMethods.getTodayAsDate(),
            startDateDuration: "FULL DAY",
            endDateDuration: "FULL DAY",
          },
        },
        () => {
          this.setStartDatesSelected("date");
          this.setStatusTypeName();
        }
      );
    }
  }

  // hm added check
  hasInvalidFields(ObjToSubmit: StatusRecordObj) {
    if (this.isTimeRelatedStatuses(ObjToSubmit.statusTypeID)) {
      if (ObjToSubmit.startDate.getTime() === ObjToSubmit.endDate.getTime()) {
        this.context.SetError("Start Time cannot be the same as End Time.");
        return true;
      }

      if (ObjToSubmit.startDate > ObjToSubmit.endDate) {
        this.context.SetError("End Time cannot be before Start Time");
        return true;
      }
    }

    if (this.state.existingStatusArray.length > 0) {
      this.context.SetError(
        "There is another leave applied on your selected date range."
      );
      return true;
    }
    if (ObjToSubmit.remarks === "") {
      this.context.SetError("Remarks is required.");
      return true;
    }
  }

  setEndDateForTimeRelatedStatuses(ObjToSubmit: StatusRecordObj) {
    // if out of office/overtime:
    // - add day to end date
    // - make duration = ""
    if (this.isTimeRelatedStatuses(ObjToSubmit.statusTypeID)) {
      ObjToSubmit.duration = "";

      // the timing is inside ObjToSubmit.endDate
      ObjToSubmit.endDate.setDate(moment(ObjToSubmit.startDate).date());
      ObjToSubmit.endDate.setMonth(moment(ObjToSubmit.startDate).month());
      ObjToSubmit.endDate.setFullYear(moment(ObjToSubmit.startDate).year());
      // let result = moment(ObjToSubmit.endDate).utc().add(ObjToSubmit.endDate.getTimezoneOffset(), 'm')
      // ObjToSubmit.endDate = result.toDate();
    }

    return ObjToSubmit;
  }

  submitCreateMethod(ObjToSubmit: StatusRecordObj) {
    this.configureDropdownForBroughtForwardEnd(ObjToSubmit);
    this.statusRecordService
      .createStatusRecord(ObjToSubmit)
      .then((res) => {
        this.context.SetSuccess(
          this.state.statusTypeName + " has been applied!"
        );
        this.redirectBack();
      })
      .catch((err) => {
        const error = this.commonMethods.getErrorMessage(err);
        if (error !== null) {
          this.context.SetError(error);
        } else {
          this.resetForm();
          this.context.SetError("An Error has occurred!");
        }
      });
  }

  submitUpdateMethod(ObjToSubmit: StatusRecordObj) {
    this.statusRecordService
      .updateStatusRecord(ObjToSubmit)
      .then((res) => {
        this.context.SetSuccess(
          this.state.statusTypeName + " has been updated!"
        );
        this.redirectBack();
      })
      .catch((err) => {
        const error = this.commonMethods.getErrorMessage(err);
        if (error !== null) {
          this.context.SetError(error);
        } else {
          this.context.SetError("An Error has occurred!");
        }
      });
  }

  uploadImage(e: React.ChangeEvent<HTMLInputElement>) {
    if (
      e !== null &&
      e.currentTarget.files !== null &&
      e.currentTarget.files[0] !== undefined &&
      e.currentTarget.files[0] !== null
    ) {
      // if (e.currentTarget.files![0].size > 2000000) {
      //   this.context.SetError("File size too large. Has to be below 2MB.");
      // } else if (e.currentTarget.files![0].size <= 2000000) {
      //   this.setState({
      //     statusRecord: {
      //       ...this.state.statusRecord,
      //       documentFile: e.currentTarget.files![0],
      //       documentFilePath: e.currentTarget.files[0].name
      //     }
      //   });
      // }
      let currentTargetImage = e.currentTarget.files[0];
      this.setState(
        {
          statusRecord: {
            ...this.state.statusRecord,
            documentFilePath: e.currentTarget.files[0].name,
          },
        },
        () => {
          CommonDocumentMethods.uploadImageOrDocument(currentTargetImage).then((image) => {
            if (image.errorMsg !== "") {
              this.context.SetError(image.errorMsg);
            } else {
              this.setState({
                statusRecord: {
                  ...this.state.statusRecord,
                  documentFile: image.file,
                },
              });
            }
          });
        }
      );
    } else {
      this.setState({
        statusRecord: {
          ...this.state.statusRecord,
          documentFile: null,
          documentFilePath: "",
        },
      });
    }
  }

  submitForm(event: React.FormEvent) {
    event.preventDefault();
    this.setState({ isLoading: true });
    let ObjToSubmit = this.state.statusRecord;
    // if (this.hasInvalidFields(ObjToSubmit)) {
    //   this.setState({ isLoading: false });
    //   return;
    // }

    //     ___  ___  ___    _  _____  ___    ___  ___  ___  ___   ___  ___
    //    / __|| _ \| __|  /_\|_   _|| __|  | _ \| __|/ __|/ _ \ | _ \|   \
    //   | (__ |   /| _|  / _ \ | |  | _|   |   /| _|| (__| (_) ||   /| |) |
    //    \___||_|_\|___|/_/ \_\|_|  |___|  |_|_\|___|\___|\___/ |_|_\|___/
    //

    if (
      this.props.match.path === "/status/apply" ||
      this.props.match.path === "/admin/employees/credit_leave/:id" ||
      this.props.match.path === "/status/departmentview/apply/:userid"
    ) {
      ObjToSubmit = this.setDurations(ObjToSubmit);

      // ObjToSubmit = this.setEndDateForTimeRelatedStatuses(ObjToSubmit);
      // validation Form
      if (this.hasInvalidFields(ObjToSubmit)) {
        this.setState({ isLoading: false });
        return;
      }

      this.submitCreateMethod(ObjToSubmit);
      // if (this.props.others !== undefined && this.props.others !== null) {
      //   this.props.others.refreshMethod(!this.props.others.refreshState);
      // }
    }
    //    _   _  ___  ___    _  _____  ___    ___  ___  ___  ___   ___  ___
    //   | | | || _ \|   \  /_\|_   _|| __|  | _ \| __|/ __|/ _ \ | _ \|   \
    //   | |_| ||  _/| |) |/ _ \ | |  | _|   |   /| _|| (__| (_) ||   /| |) |
    //    \___/ |_|  |___//_/ \_\|_|  |___|  |_|_\|___|\___|\___/ |_|_\|___/
    //
    //id in params
    else if (this.isUpdatingApplication()) {
      this.submitUpdateMethod(ObjToSubmit);
      // if (this.props.others !== undefined && this.props.others !== null) {
      //   this.props.others.refreshMethod(!this.props.others.refreshState);
      // }
    }
  }

  getOverlappingStatus() {
    let obj = this.setEndDateForTimeRelatedStatuses(this.state.statusRecord);

    this.statusRecordService
      .getOverlappingRecords(obj)
      .then((res) => {
        this.setState({ existingStatusArray: res });
      })
      .catch((err) => {
        this.context.SetError(
          "Unable to get overlapping records for selected dates"
        );
      });
  }

  // Not for OOO and OT
  setStartDatesSelected(type: string) {
    if (type === "date") {
      this.setState(
        {
          statusRecord: {
            ...this.state.statusRecord,
            startDate: this.commonMethods.setStartOfDayOfDate(
              this.state.statusRecord.startDate
            ),
            endDate: this.commonMethods.setEndOfDayOfDate(
              this.state.statusRecord.startDate
            ),
            endDateDuration: "",
            startDateDuration: "FULL DAY",
          },
        },
        () => {
          this.getOverlappingStatus();
        }
      );
    } else if (type === "duration") {
      if (this.state.statusRecord.startDateDuration === "AM") {
        this.setState(
          {
            statusRecord: {
              ...this.state.statusRecord,
              startDate: this.commonMethods.setStartOfDayOfDate(
                this.state.statusRecord.startDate
              ),
              endDate: this.commonMethods.setDateAtTwoPM(
                this.state.statusRecord.startDate
              ),
              endDateDuration: "",
            },
          },
          () => {
            this.getOverlappingStatus();
          }
        );
      } else if (this.state.statusRecord.startDateDuration === "PM") {
        this.setState(
          {
            statusRecord: {
              ...this.state.statusRecord,
              startDate: this.commonMethods.setDateAtTwoPM(
                this.state.statusRecord.startDate
              ),
            },
          },
          () => {
            this.getOverlappingStatus();
          }
        );
      } else {
        this.setState(
          {
            statusRecord: {
              ...this.state.statusRecord,
              startDate: this.commonMethods.setStartOfDayOfDate(
                this.state.statusRecord.startDate
              ),
              startDateDuration: "FULL DAY",
            },
          },
          () => {
            this.getOverlappingStatus();
          }
        );
      }
    }
  }

  // Not for OOO and OT
  setEndDatesSelected(type: string) {
    if (type === "date") {
      let startduration = "FULL DAY";
      if (this.state.statusRecord.startDateDuration === "PM") {
        startduration = "PM";
      }
      this.setState(
        {
          statusRecord: {
            ...this.state.statusRecord,
            endDate: this.commonMethods.setEndOfDayOfDate(
              this.state.statusRecord.endDate
            ),
            startDateDuration: startduration,
          },
        },
        () => {
          this.getOverlappingStatus();
        }
      );
    } else if (type === "duration") {
      if (this.state.statusRecord.endDateDuration === "AM") {
        this.setState(
          {
            statusRecord: {
              ...this.state.statusRecord,
              endDate: this.commonMethods.setDateAtTwoPM(
                this.state.statusRecord.endDate
              ),
            },
          },
          () => {
            this.getOverlappingStatus();
          }
        );
      } else if (this.state.statusRecord.endDateDuration === "PM") {
        // it will never come here
      } else {
        this.setState(
          {
            statusRecord: {
              ...this.state.statusRecord,
              endDate: this.commonMethods.setEndOfDayOfDate(
                this.state.statusRecord.endDate
              ),
              endDateDuration: "FULL DAY",
            },
          },
          () => {
            this.getOverlappingStatus();
          }
        );
      }
    }
  }

  render() {
    // Msg string above apply button
    let msgStr;
    if (this.state.statusRecord.statusTypeID !== "") {
      this.setDurations(this.state.statusRecord);

      msgStr = (
        <p className="p-col-12 m-0">
          You are applying for
          <strong> {this.state.statusTypeName}</strong> <br></br>{" "}
          <strong>
            {this.commonMethods.convertDatesForLeaveFormDisplay(
              this.state.statusTypeName,
              this.state.statusRecord.startDate,
              this.state.statusRecord.endDate,
              this.state.statusRecord.duration
            )}{" "}
          </strong>
        </p>
      );
    }

    // End date time display according to leave type
    let endDateDisplay;
    let endDateDisplayHeader;

    if (this.isTimeRelatedStatuses(this.state.statusRecord.statusTypeID)) {
      endDateDisplayHeader = "End Time";

      endDateDisplay = (
        <Calendar
          id="EndTime"
          disabled={this.isUpdatingApplication()}
          readOnlyInput={true}
          value={this.state.statusRecord.endDate}
          onChange={(e) => {
            e.originalEvent.preventDefault();
            this.setState(
              {
                statusRecord: {
                  ...this.state.statusRecord,
                  endDate: new Date(e.value.toString()),
                },
              },
              () => {
                this.getOverlappingStatus();
              }
            );
          }}
          maxDate={this.commonMethods.setEndOfDayOfDate(
            this.state.statusRecord.startDate
          )}
          timeOnly={true}
          hourFormat="12"
          stepMinute={30}
          inline={false}
        />
      );
    } else {
      endDateDisplayHeader = `End Date`;
      endDateDisplay = (
        <Calendar
          id="EndDate"
          readOnlyInput={true}
          dateFormat="dd/mm/yy"
          disabled={
            this.isUpdatingApplication() ||
            this.state.statusRecord.statusTypeID === ""
          }
          value={this.state.statusRecord.endDate}
          inline={false}
          minDate={new Date(this.state.statusRecord.startDate.toString())}
          onChange={(e) => {
            e.value !== undefined
              ? this.setState(
                  {
                    statusRecord: {
                      ...this.state.statusRecord,
                      endDate: new Date(e.value.toString()),
                      endDateDuration: "FULL DAY",
                    },
                  },
                  () => {
                    this.setEndDatesSelected("date");
                  }
                )
              : this.setState({
                  statusRecord: {
                    ...this.state.statusRecord,
                    endDate: this.state.statusRecord.startDate,
                  },
                });
          }}
        />
      );
    }

    /***
     *      _____  ____   _                               _   __  _
     *     |_   _|/ ___| | |      ___  _ __    ___   ___ (_) / _|(_)  ___
     *       | |  \___ \ | |     / __|| '_ \  / _ \ / __|| || |_ | | / __|
     *       | |   ___) || |___  \__ \| |_) ||  __/| (__ | ||  _|| || (__
     *       |_|  |____/ |_____| |___/| .__/  \___| \___||_||_|  |_| \___|
     *                                |_|
     */

    // let googleformtext;
    // if (this.state.requireGoogleForm_TimeOff && this.state.isTSL) {
    //   googleformtext = (
    //     <div
    //       className="p-col-12 p-grid p-justify-center text-center text-danger"
    //       style={{ fontSize: "2em" }}
    //     >
    //       <b>
    //         You are also required to fill up this form{" "}
    //         <a
    //           href="https://forms.gle/GfFmskRwgKmu2TZ88"
    //           target="_blank"
    //           rel="noopener noreferrer"
    //         >
    //           <u>here</u>
    //         </a>
    //       </b>
    //     </div>
    //   );
    // }

    let offinlieuText;
    if (
      this.state.statusRecord.statusTypeID === this.offinlieuID &&
      this.state.isTSL
    ) {
      offinlieuText = (
        <div className="p-col-12 p-grid p-justify-center text-center text-danger">
          <p>
            You are to clear this OIL within 3 months from the date of your OT.
          </p>
        </div>
      );
    }

    let medicalApptText;
    if (
      this.state.statusRecord.statusTypeID === this.medicalApptID &&
      this.state.isTSL
    ) {
      medicalApptText = (
        <>
          <p className="text-danger">
            Medical appointment is applicable only for employees with
            pre-existing conditions and require a follow-up with a
            doctor/specialist. It is not applicable for aesthetic medical
            reasons such as dental braces check. A supporting document, such as
            a memo from the doctor, is required.
          </p>
          <p className="text-danger">
            If you are issued a MC, we will recognise that. Otherwise, this
            time-off is only valid for the duration of your appointment. (e.g.
            appointment at 10am, you will resume work after that).
          </p>
        </>
      );
    }

    let overtimeFormText = null;
    if (
      this.state.statusRecord.statusTypeID === this.overtimeID &&
      this.state.isTSL
    ) {
      overtimeFormText = (
        <div className="p-grid p-fluid">
          <div
            className="p-col-12 p-justify-center text-center text-danger"
            style={{ fontSize: "2em" }}
          >
            <b>
              You are required to fill up this form{" "}
              <a
                href="https://forms.gle/GfFmskRwgKmu2TZ88"
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>here</u>
              </a>
            </b>{" "}
          </div>
        </div>
      );
    }

    let leaveApplicationPart = (
      <>
        <div className="p-grid p-fluid">
          <div
            className="p-col-12 text-danger"
            style={{ margin: 0 }}
            hidden={
              !(this.state.existingStatusArray.length > 0) ||
              this.state.statusRecord.statusTypeID === ""
            }
          >
            <strong>
              {this.state.existingStatusArray.length > 0
                ? `${this.state.existingStatusArray[0].statusTypeName} already applied within your selected date range.`
                : ""}
            </strong>

            <div>
              <strong>
                {this.state.existingStatusArray.length > 0
                  ? `Remarks: ${this.state.existingStatusArray[0].remarks}`
                  : ""}
              </strong>
            </div>
          </div>
          <div
            className="p-col-12"
            data-tour-id="leave-duration"
            data-tour="Fill in your start and end dates and times here."
          >
            <div className="row">
              <div className="col-12 col-lg-12 col-xl-6 pt-2">
                <div>
                  <span className="boldLabel">
                    Start Date <span style={{ color: "red" }}>*</span>
                  </span>

                  <Calendar
                    id="StartDate"
                    readOnlyInput={true}
                    dateFormat="dd/mm/yy"
                    disabled={
                      this.isUpdatingApplication() ||
                      this.state.statusRecord.statusTypeID === ""
                    }
                    viewDate={this.state.statusRecord.startDate}
                    value={this.state.statusRecord.startDate}
                    showTime={this.isTimeRelatedStatuses(
                      this.state.statusRecord.statusTypeID
                    )}
                    showButtonBar={true}

                    hourFormat="12"
                    stepMinute={30}
                    inline={false}
                    maxDate={
                      this.state.statusRecord.statusTypeID === this.overtimeID
                        ? this.commonMethods.getEndOfTodayAsDate()
                        : new Date(new Date().getFullYear() + 1, 11, 31)
                    }
                    onChange={(e) => {
                      e.originalEvent.preventDefault();
                      if (e.value !== undefined) {
                        this.setState(
                          {
                            statusRecord: {
                              ...this.state.statusRecord,
                              startDate: new Date(e.value.toString()),
                              endDate: new Date(e.value.toString()),
                            },
                          },
                          () => {
                            this.getOverlappingStatus();
                          }
                        );
                      }
                    }}
                  />
                </div>
                <div
                  className="my-2"
                  hidden={this.isTimeRelatedStatuses(
                    this.state.statusRecord.statusTypeID
                  )}
                >
                  <SelectButton
                    disabled={
                      this.isUpdatingApplication() ||
                      this.state.statusRecord.statusTypeID === ""
                    }
					tooltip={"AM: 0000 - 1400\nPM: 1400 - 2359"}
					tooltipOptions={{ position: "bottom"}}
                    id="select-button"
                    value={this.state.statusRecord.startDateDuration}
                    options={
                      new Date(
                        this.state.statusRecord.startDate
                      ).toLocaleDateString() ===
                      new Date(
                        this.state.statusRecord.endDate
                      ).toLocaleDateString()
                        ? this.durationSameDaySelection
                        : this.durationDifferentDaySelectionForStartDate
                    }
                    onChange={(e) => {
                      e.value !== null &&
                        this.setState(
                          {
                            statusRecord: {
                              ...this.state.statusRecord,
                              startDateDuration: e.value,
                              // endDateDuration: ""
                            },
                          },
                          () => {
                            this.setStartDatesSelected("duration");
                          }
                        );
                    }}
                  />
                </div>
              </div>

              <div className="col-12 col-lg-12 col-xl-6 pt-2">
                <div>
                  <span className="boldLabel">
                    {endDateDisplayHeader}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  {endDateDisplay}
                </div>
                <div
                  className="my-2"
                  hidden={this.isTimeRelatedStatuses(
                    this.state.statusRecord.statusTypeID
                  )}
                >
                  <SelectButton
                    id="select-button"
                    disabled={
                      new Date(
                        this.state.statusRecord.startDate
                      ).toLocaleDateString() ===
                        new Date(
                          this.state.statusRecord.endDate
                        ).toLocaleDateString() || this.isUpdatingApplication()
                    }
					tooltip={"AM: 0000 - 1400\nPM: 1400 - 2359"}
					tooltipOptions={{ position: "bottom"}}
                    className=""
                    value={this.state.statusRecord.endDateDuration}
                    options={
                      new Date(
                        this.state.statusRecord.startDate
                      ).toLocaleDateString() ===
                      new Date(
                        this.state.statusRecord.endDate
                      ).toLocaleDateString()
                        ? this.durationSameDaySelection
                        : this.durationDifferentDaySelectionForEndDate
                    }
                    onChange={(e) => {
                      if (e.value !== null) {
                        this.setState(
                          {
                            statusRecord: {
                              ...this.state.statusRecord,
                              endDateDuration: e.value,
                            },
                          },
                          () => {
                            this.setEndDatesSelected("duration");
                            // this.getOverlappingStatus();
                          }
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-col-12">
            <div
              className="p-grid p-fluid"
              hidden={
                !this.arrayOfLeaveIDsThatRequireDocuments.includes(
                  this.state.statusRecord.statusTypeID
                )
              }
            >
              <div className="p-col-12">
                <p className="boldLabel">Document Upload</p>
                <p
                  className="text-danger"
                  hidden={
                    this.state.statusRecord.documentFilePath !== undefined
                  }
                >
                  <strong>
                    Supporting document is required for this application. You
                    can apply first and upload the document later. However, your
                    status will only be approved upon successful upload of the
                    document.
                  </strong>
                </p>
                {medicalApptText}

                <input
                  style={{ color: "transparent", width: "110px" }}
                  type="file"
                  name="statusDocument"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={this.uploadImage}
                />
                {this.state.statusRecord.documentFilePath}
              </div>
            </div>
          </div>
          <div
            className="p-col-12"
            data-tour-id="leave-remarks"
            data-tour="Fill in your leave reason here."
          >
            <div className="boldLabel">
              Remarks <span style={{ color: "red" }}>*</span>
            </div>
            <InputTextarea
              value={this.state.statusRecord.remarks}
              onChange={(e) =>
                this.setState({
                  statusRecord: {
                    ...this.state.statusRecord,
                    remarks: e.currentTarget.value,
                  },
                })
              }
              placeholder={
                this.state.statusRecord.statusTypeID === this.offinlieuID
                  ? "(date) - OT (length) for (reason)"
                  : ""
              }
              rows={2}
              cols={30}
              autoResize={false}
              required
              maxLength={140}
            />
          </div>
        </div>
        {/* {googleformtext} */}
        {offinlieuText}

        <div
          className="p-col-12 p-grid p-justify-center text-center"
          data-tour-id="message-string"
          data-tour="The sumary of your application should be shown. Please check if it is accurate."
        >
          {msgStr}
          <Button
            type="submit"
            label={this.state.buttonLabel}
            onClick={(e) => {
              this.submitForm(e);
            }}
          />
        </div>
      </>
    );
    let display;
    if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else {
      display = (
        <>
          <div className="p-grid p-fluid">
            <div
              className="p-col-12"
              data-tour-id="leave-type"
              data-tour="Select your leave type."
            >
              <span className="boldLabel">
                Leave Type <span style={{ color: "red" }}>*</span>
              </span>
              <Dropdown
                disabled={this.isUpdatingApplication()}
                placeholder="Please Select"
                value={this.state.statusRecord.statusTypeID}
                options={this.state.statusTypesDropdown}
                onChange={(e) => {
                  this.onLeaveTypeDropdownChange(e);
                }}
                required
                scrollHeight="350px"
              />
            </div>
          </div>
          {overtimeFormText !== null ? overtimeFormText : leaveApplicationPart}
        </>
      );
    }

    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
          }}
        />
      );
    }

    return (
      <div className="row">
        <div className="col-12">
          <h1 className="pb-2"> {this.state.titleString} </h1>
          <div className="requiredRed">
            <p>* Required</p>
          </div>
          {display}
        </div>
      </div>
    );
  }
}
