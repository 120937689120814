import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { CompanyFeedContext } from '../../../CompanyFeed'
import { ImageFile } from '../../../Model/PostModel'
import CompanyFeedService from '../../../Service/CompanyFeedService'
import DragAndDropUpload from './DragAndDropUpload'
import placeholder from '../../../Assets/profileplaceholder.jpg'
import { Card } from 'primereact/card'
import ProfilePicture from '../ProfilePicture'
import { useHistory } from 'react-router'
import Editor from '@draft-js-plugins/editor';
import PostDraftJsEditor from '../../DraftJsEditors/PostDraftJsEditor'
import { NotificationsService } from '../../../../../service/NotificationsService'
import { toTitleCase } from '../../../CompanyFeedFunctions'
import { ACTIONS } from '../../../DispatchActions'

export default function CreatePost() {
    const { state, dispatch } = useContext(CompanyFeedContext);
    const [isShowDialog, setIsShowDialog] = useState(false)
    const [isShowImagesDialog, setIsShowImagesDialog] = useState(false)
    const [images, setImages] = useState<ImageFile[] | null>(null)
    const [imagePathArray, setImagePathArray] = useState<string[] | null>(null)
    const imageInput = useRef<HTMLInputElement>(null)
    const history = useHistory();
    const [rawEditorContentJsonStr, setRawEditorContentJsonStr] = useState<string | null>(null)
    const [usersTaggedArr, setUsersTaggedArr] = useState<null | string[]>(null)
    const editorRef = useRef<Editor>(null);
    const [hasText, setHasText] = useState(false)

    useEffect(() => {
        if (imagePathArray == null && isShowImagesDialog === true) {
            setIsShowImagesDialog(false)
            setIsShowDialog(true)
        }
    }, [imagePathArray])

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        let formData = new FormData();
        if (images != null) {
            images.forEach((image) => {
                formData.append("MultipleUploads", image)
            })
        }
        if (rawEditorContentJsonStr) formData.append("RawEditorContentJsonStr", rawEditorContentJsonStr);
        if (usersTaggedArr) formData.append("UsersTagged", JSON.stringify(usersTaggedArr));

        const companyFeedService = new CompanyFeedService();
        companyFeedService.insertPost(formData)
            .then((res) => {
                let post = res.data
                post.isLikedByUser = false
                post.previouslyLikedByUser = false
                console.log('Post success: ', post)
                dispatch({ type: ACTIONS.ADD_POST, payload: post })
                // notifications
                if (usersTaggedArr) {
                    // don't notify sender if they tag themself
                    let usersToNotify = usersTaggedArr.filter((el) => (el !== state.currentUser.userId))
                    const notifSvc = new NotificationsService();
                    let notifObjArr = []
                    for (let i = 0; i < usersToNotify.length; i++) {
                        let notifObj = {
                            RecipientUserID: usersToNotify[i],
                            SenderUserID: state.currentUser.userId,
                            Url: `/companyfeed/post/${post.postId}`,
                            MsgHeader: "Tagged In Post",
                            Message: `${toTitleCase(state.currentUser.firstName) + " " + toTitleCase(state.currentUser.lastName)} has tagged you in a post`
                        }
                        notifObjArr.push(notifObj)
                        // console.log('notifObj create', notifObj)
                    }
                    notifSvc.createNotification(notifObjArr).then()
                }
            })
            .catch((err) => {
                console.log(err)
                alert("Post Error")
            });
        resetForm();
        setIsShowDialog(false)
    };

    const resetForm = () => {
        // setPostContent("");
        setImages(null)
        setImagePathArray(null)
        // setRows(3)
    };

    function showDialog() {
        setIsShowDialog(true)
    }

    function hideDialog() {
        setIsShowDialog(false)
        resetForm()
    }

    function hideImagesDialog() {
        setIsShowImagesDialog(false)
        setIsShowDialog(true)
    }

    function openImageInput() {
        if (imageInput.current) {
            imageInput.current.click()
        }
    }

    function addImages(e: React.ChangeEvent<HTMLInputElement>) {
        let addedImages = e.target.files
        if (addedImages?.length === 0) return
        const imageArray: ImageFile[] = []
        const imagePaths: string[] = []
        if (addedImages) {
            for (let i = 0; i < addedImages.length; i++) {
                const image: ImageFile = addedImages[i]
                image.objectURL = URL.createObjectURL(image)
                imageArray.push(image)
                imagePaths.push(image.objectURL);
            }
            setImages(prev => prev === null ? imageArray : [...prev, ...imageArray])
            setImagePathArray(prev => prev === null ? imagePaths : [...prev, ...imagePaths])
        }
    }

    function removeImage(path: string) {
        // TODO: is there a better way to handle images/imagePaths
        // Naming convention may be confusing
        let filteredArray: string[] = []
        if (imagePathArray) {
            for (let i = 0; i < imagePathArray.length; i++) {
                if (imagePathArray[i] !== path) {
                    filteredArray.push(imagePathArray[i]);
                }
            }
            setImagePathArray(filteredArray.length > 0 ? filteredArray : null)
        }
        let filteredImages: ImageFile[] = []
        if (images) {
            for (let i = 0; i < images.length; i++) {
                if (images[i].objectURL !== path) {
                    filteredImages.push(images[i])
                }
            }
            setImages(filteredImages.length > 0 ? filteredImages : null)
        }
        // close dialog if imagePathArray is empty after removing image in useeffect
        // console.log('filteredArray', filteredArray)
        // console.log('filteredImages', filteredImages)
    }

    function onShowDialog() {
        if (editorRef.current) editorRef.current.focus()
    }

    async function enterProfile(userId: string) {
        history.push(`/publicprofile/${userId}`, { userId }); // navigate to route
    }

    return (
        <div className="create-post-parent">
            <Card className="create-post-card posts-rounded-border each-post">
                <div className="flex-row">
                    <div onClick={() => enterProfile(state.currentUser.userId)}>
                        <ProfilePicture displayPicture={state.currentUser.displayPicture} />
                    </div>
                    <div onClick={showDialog} className="create-post-card-open position-relative hover-greyedout cursor-pointer">
                        <span className="create-post-card-text vertical-center">What's happening?</span>
                    </div>
                </div>
            </Card>
            <Dialog
                visible={isShowDialog}
                onHide={hideDialog}
                onShow={onShowDialog}
                className="rounded-border post-form-dialog"
                showHeader={true}
                blockScroll
                dismissableMask={true}
            >
                <form onSubmit={handleSubmit}>
                    <div className="flex-column post-form">
                        <div className="header-postform">
                            <h1 className="postform-header-title">New Post</h1>
                            <span
                                className="material-icons md-36 postform-cancel"
                                onClick={hideDialog}
                            >close</span>
                        </div>
                        <PostDraftJsEditor
                            setRawEditorContentJsonStr={setRawEditorContentJsonStr}
                            setUsersTaggedArr={setUsersTaggedArr}
                            editorRef={editorRef}
                            setHasText={setHasText}
                            contextName="CompanyFeed"
                        />
                        <div className="position-relative">
                            <DragAndDropUpload
                                images={images}
                                setImages={setImages}
                                imagePathArray={imagePathArray}
                                setImagePathArray={setImagePathArray}
                                setIsShowImagesDialog={setIsShowImagesDialog}
                                setIsShowDialog={setIsShowDialog}
                            />
                            <button type="button" className="image-upload-button add-image-button" onClick={openImageInput}>
                                <span className="material-icons material-icon-align">add</span>
                                <span className="image-upload-button-text">Add</span>
                            </button>
                            <input
                                type="file"
                                multiple
                                accept="image/*"
                                style={{ display: "none" }}
                                ref={imageInput}
                                onChange={(e) => addImages(e)}
                            />
                        </div>
                        <div className="postform-buttons">
                            <Button
                                type="submit"
                                label="Post"
                                disabled={(!images && !hasText)}
                                className="postform-submit"
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
            <Dialog
                visible={isShowImagesDialog}
                onHide={hideImagesDialog}
                className="rounded-border post-form-dialog post-form-image-dialog position-relative"
                showHeader={true}
                blockScroll
                dismissableMask={true}
            >
                <h1 className="textalign-center">Photos</h1>
                <ul className="images-dialog-ul-flex">
                    {imagePathArray?.map(item => {
                        return (
                            <li className="images-dialog-li" key={item}>
                                <img
                                    src={item}
                                    alt=""
                                    className="images-dialog-img"
                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => e.currentTarget.src = placeholder}
                                />
                                <span
                                    className="material-icons viewimages-remove"
                                    onClick={() => removeImage(item)}
                                >
                                    close
                                </span>
                            </li>
                        )
                    })}
                </ul>

                <span
                    className="material-icons image-dialog-back"
                    onClick={hideImagesDialog}
                >
                    keyboard_backspace
                </span>
            </Dialog>
        </div>
    )
}