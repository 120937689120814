import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { PayslipService } from "../../service/PayslipService";
import { ParamIDTypes, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { PayrollCurrent } from "./PayrollCurrent";
import { PayrollHistoryMonthDetails } from "./PayrollHistoryMonthDetails";

export function PayrollView(props: RouteComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(true);

  const [displayType, setDisplayType] = useState<string>("");
  const payslipService = new PayslipService();
  const { id } = useParams<ParamIDTypes>();
  useEffect(() => {
    // Get payroll
    payslipService
      .getPayrollStatus(id || "")
      .then((res) => {
        setDisplayType(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsError(true);
        
      });
  }, []);

  let display = <div></div>;
  if (isLoading) {
    display = (
      <div className="text-center">
        <ProgressSpinner />
      </div>
    );
  } else if (displayType === "Completed") {
    display = <PayrollHistoryMonthDetails {...props} />;
  } else if (displayType === "Incomplete") {
    display = <PayrollCurrent {...props} />;
  } else if (isError) {
    display = <CustomError message="Unable to get payroll" />;
  }

  return display;
}
