import { CommonMethods } from "../../resources/CommonMethods";

export class UserIndicatorModel {
  public userIndicatorID: string = CommonMethods.EmptyGuid;
  public indicatorID: string = CommonMethods.EmptyGuid;
  public deliverableID: string = CommonMethods.EmptyGuid;
  public userID: string = CommonMethods.EmptyGuid;
  public points: number = 0;
  public link: string = "";
  public facebookLink: string = "";
  public remarks: string = "";
  public modifier: number = 0;
  public customPoint: boolean = false;
  public campaignID: string = CommonMethods.EmptyGuid;
  public campaignName: string = "";
  public createdTime: Date = new Date();
  public validStatsLink: boolean = false;
  public linkStatsID: string = CommonMethods.EmptyGuid;

  // boss dashboard
  public targetPoints: number = 0;
  public departmentName: string = "";
  public departmentID: string = CommonMethods.EmptyGuid;
}

export class UserIndicatorMultiSelectModel {
  public userIndicatorID: string = CommonMethods.EmptyGuid;
  public indicatorID: string = CommonMethods.EmptyGuid;
  public indicatorIDList: Array<string> = new Array<string>();
  public deliverableID: string = CommonMethods.EmptyGuid;
  public userID: string = CommonMethods.EmptyGuid;
  public points: number = 0;
  public link: string = "";
  public facebookLink: string = "";
  public remarks: string = "";
  public modifier: number = 0;
  public customPoint: boolean = false;
  public campaignID: string = CommonMethods.EmptyGuid;
  public campaignName: string = "";
  public createdTime: Date = new Date();
  public validStatsLink: boolean = false;
  public linkStatsID: string = CommonMethods.EmptyGuid;

  // boss dashboard
  public targetPoints: number = 0;
  public departmentName: string = "";
  public departmentID: string = CommonMethods.EmptyGuid;
}

export class UserTargetModel {
  public userID: string = "";
  public targetMonth: string = "";
  public targetYear: string = "";
  public targetPoints: number = 0;
  public leaveDays: number = 0;
  public holidayDays: number = 0;
  public lastUpdated: Date = new Date();
  public lastUpdatedBy: string = CommonMethods.EmptyGuid;
}

export class UserIndicatorWithTargetModel {
  public originalTargetPoints: number = 160;
  public workDaysThisMonth: number = 31;
  public workDaysPast: number = 0;
  public userIndicatorList: Array<UserIndicatorModel> = new Array<
    UserIndicatorModel
  >();
  public fullName: string = "";
  public userID: string = "";
  public userTargetModel: UserTargetModel = new UserTargetModel();
}
