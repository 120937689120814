import React, { useEffect, useState } from "react";
import { RouteComponentProps, CommonMethods } from "../resources/CommonMethods";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/components/button/Button";
import { NavLink, Redirect } from "react-router-dom";
import CustomError  from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";

import CircularsDashboardService from "../../service/CircularsDashboardService";
import { CircularsDashboardModel } from "./CircularsDashboardModel";

export default function CircularsWidget(props: RouteComponentProps<any>) {
  const circularsDashboardService = new CircularsDashboardService();
  const commonMethods = new CommonMethods();

  const [tableData, setTableData] = useState<CircularsDashboardModel[]>([]);
  
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const [redirectOut, setRedirectOut] = useState<boolean>(false);
  // const [redirectPath, setRedirectPath] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
    );
  

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    circularsDashboardService
      // .getAllCirculars()
      .getAllCircularsForUserRank()
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const titleLabel = (rowData: CircularsDashboardModel) => {
    let content = <>{rowData.summary} {rowData.userRankName === "" || rowData.userRankName === null ? <></> :<span className="p-tag p-tag-rounded p-tag-neutral">{rowData.userRankName}</span>}</>;
    return (
      <NavLink
        to={"/circular/document/" + rowData.announcementID}
      >
        {content}
      </NavLink>
    );
  }

  const dateFormat = (rowData: CircularsDashboardModel) => {
    return commonMethods.displayDate(rowData.lastUpdatedDate);
  }

  // const viewDocument = (rowData: CircularsDashboardModel) => {
  //   return (
  //     <div style={{ display: "flex", justifyContent: "center" }}>
  //       <Button
  //         tooltip="View Document"
  //         tooltipOptions={{ position: "top" }}
  //         type="button"
  //         icon="pi pi-eye"
  //         className="p-button-primary mr-1"
  //         onClick={() => {
  //           setRedirectOut(true);
  //           setRedirectPath("/circular/document/" + rowData.announcementID)
  //         }}
  //       />
  //     </div>
  //   );
  // };

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMessage} />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={tableData}
            paginator={true}
            rows={10}
            autoLayout={true}
            alwaysShowPaginator={false}
            emptyMessage="No results found."
          >
            {/* <Column field="summary" header="Title" /> */}
            <Column body={titleLabel} header="Title" />
            <Column field="fullName" header="Posted By"/>
            <Column body={dateFormat} header="Posted On" />
            {/* <Column body={viewDocument} header="View" /> */}
          </DataTable>
        </div>
      );
    }
  }

  return (
    <div className="row">
      {/* {redirectOut ? <Redirect push to={redirectPath} /> : null} */}

      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">Important Circulars</h1>
          {display()}
        </div>
      </div>
    </div>
  );
}