import React, { useEffect, useState } from "react";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import CustomError from "../resources/Error";
import { QuicklinksDashboardModel } from "./QuicklinksDashboardModel";
import { QuicklinksDashboardService } from "../../service/QuicklinksDashboardService";

export default function QuickLinksWidget(props: RouteComponentProps<any>) {
  const commonMethods = new CommonMethods();
  const quicklinksDashboardService = new QuicklinksDashboardService();

  const [tableData, setTableData] = useState<QuicklinksDashboardModel[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
  "An unidentified error has occurred"
  );

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    sortWithCompanyStructure();
  },[tableData])

  // Initialise the data table
  const init = () => {
    quicklinksDashboardService
    .getAllLinks()
    .then((res) => {
      setTableData(res);
    })
    .catch((err)=> {
      setErrorMessage(commonMethods.getErrorMessage(err));
      setIsError(true);
    })
    .finally(() => setIsLoading(false));
  };

  const hyperLink = (rowData: QuicklinksDashboardModel) => {
    if (rowData.link) {
      return <a href={rowData.link} rel="noopener noreferrer" target="_blank">{rowData.linkTitle}</a>
    }
  }

  const sortWithCompanyStructure = () => {
    var companyStructure = {linkID: CommonMethods.EmptyGuid, linkTitle:"Company Structure", link: "/birdseye", fullName: "birdseye"};
    tableData.sort((a, b) => (a.linkTitle > b.linkTitle) ? 1 : -1);
    tableData.unshift(companyStructure);
  }

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMessage} />;
    } else {
      return (
        <DataTable
          value={tableData}
          paginator={true}
          rows={10}
          autoLayout={true}
          alwaysShowPaginator={false}
          className="text-left"
          emptyMessage="No results found."
        >
          <Column 
          field="linkTitle" 
          body={hyperLink} 
          header="Link"
          />
        </DataTable>  
      );
    }
  }

  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">Quick Links</h1>
          {display()}
        </div>
      </div>
    </div>
  );
}
