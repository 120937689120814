import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { RouteComponentProps } from "../../resources/CommonMethods";
import CustomError from "../../resources/Error";

export default function KPIProgressBar(props: RouteComponentProps) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPoints, setTotalPoints] = useState(0);
  const [target, setTarget] = useState(160);
  const [workDaysThisMonth, setWorkDaysThisMonth] = useState(0);
  const [workDaysPast, setWorkDaysPast] = useState(0);
  // const [monthToDisplay, setMonthToDisplay] = useState<string>(
  //   moment().format("MMMM")
  // );

  useEffect(() => {
    if (props.others === undefined) {
      setIsError(true);
    } else {
      if (props.others.userIndicatorList !== undefined) {
        let total = 0;
        props.others.userIndicatorList.map((element: any) => {
          total += element.points;
        });
        setTarget(props.others.userTargetModel.targetPoints);
        setWorkDaysThisMonth(props.others.workDaysThisMonth);
        setWorkDaysPast(props.others.workDaysPast);
        setTotalPoints(total);
        setIsLoading(false);

      }
    }
  }, [props.others]);

  const displayValue = () => {
    return (
      <React.Fragment>
        {workDaysPast + "/" + workDaysThisMonth} calendar working days this
        month
      </React.Fragment>
    );
  };

  let display = (
    <>
      <div className="text-left text-md-center py-2">
        <div className="row">
          <div className="col-12 col-md-6">
            <h2>Achieved: {totalPoints.toFixed(2)}</h2>
          </div>
          <div className="col-12 col-md-6">
            <h2 className="d-inline">Target: {target}</h2>
            <span className="d-inline px-2" data-for="kpitarget" data-tip="">
              <i className="fas fa-info-circle informationStyleSmall"></i>
              <ReactTooltip
                id="kpitarget"
                aria-haspopup="true"
                
                className="tooltipFont"
              >
                <p className="text-center mb-0">
                  This is calculated using the no. of working days this month
                  multiplied by no. of working hours a day.
                </p>
              </ReactTooltip>
            </span>
          </div>
        </div>
      </div>
      <div className="progressbarkpi pb-1">
        <ProgressBar
          value={parseFloat(
            (Math.round(totalPoints * 100) / target).toFixed(2)
          )}
          showValue={true}
        ></ProgressBar>
      </div>
      <div className="progressbardays">
        <ProgressBar
          value={parseFloat(
            ((workDaysPast * 100) / workDaysThisMonth).toFixed(2)
          )}
          displayValueTemplate={displayValue}
          showValue={true}
          unit={"days"}
        ></ProgressBar>
      </div>
    </>
  );

  if (isError) {
    return <CustomError message="An error has occurred" />;
  } else if (isLoading) {
    return <ProgressSpinner />;
  } else {
    return (
      <div
        // className="card"
        data-tour-id="my-kpi-target"
        data-tour="Here is your progress and target."
      >
        {display}
      </div>
    );
  }
}
