import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Redirect, useParams } from "react-router";
import { TaxFilingService } from "../../service/TaxFilingService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import {
  TaxBodyModel,
  TaxFilingHeader,
  TaxFilingMethods,
} from "./TaxFilingModel";
import ReactTooltip from "react-tooltip";
import moment from "moment";
interface TaxBodyParams {
  taxbodyid: string;
}

export default function TaxBodyEdit(props: RouteComponentProps) {
  const taxFilingService = new TaxFilingService();
  const [taxItem, setTaxItem] = useState<TaxBodyModel>(new TaxBodyModel());

  const commonMethods = new CommonMethods();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  // const [toHome, setToHome] = useState(false);
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const { taxbodyid } = useParams<TaxBodyParams>();
  const [header, setHeader] = useState<TaxFilingHeader>(new TaxFilingHeader());

  useEffect(() => {
    taxFilingService
      .getOneBodyDetails(taxbodyid)
      .then((res: TaxBodyModel) => {
        setTaxItem(res);
        setIsLoading(false);
        let taxheaderid = res.taxHeaderID;
        taxFilingService.getHeaderDetails(taxheaderid).then((result) => {
          setHeader(result);
        });
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(commonMethods.getErrorMessage(err));
      });
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    taxFilingService
      .updateOneBodyDetails(taxItem)
      .then(() => {
        SetSuccess(taxItem.fullName + " has been updated.");
        setRedirectPath("/finance/tax_filing/" + taxItem.taxHeaderID);
        setRedirectOut(true);
      })
      .catch((err) => {
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  const calculateValues = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    taxFilingService
      .calculateAndValidateOneBody(taxItem)
      .then((res) => {
        setTaxItem(res);
        SetSuccess("Calculated");
      })
      .catch((err) => {
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  let page = (
    <form onSubmit={handleSubmit}>
      <h5>Employee Details</h5>
      <div className="p-col-12">
        <p>Change employee details from their User Profile or Contract. Delete and re-add them into the selected employee list to update the changes.</p>
      </div>
      <div className="p-grid p-fluid">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Field</th>
              <th scope="col">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Employee's Tax Ref. Type (NRIC / FIN)
              </td>
              <td>
                <InputText value={taxItem.employeeIDTypeName} disabled />
              </td>
            </tr>
            <tr>
              <td>
                Employee's Tax Ref. No. (NRIC / FIN)
              </td>
              <td>
                <InputText value={taxItem.employeeIDNumber} disabled />
              </td>
            </tr>
            <tr>
              <td>Full Name of Employee as per NRIC / FIN</td>
              <td>
                <InputText value={taxItem.fullName} disabled />
              </td>
            </tr>
            <tr>
              <td>Date of Birth</td>
              <td>
                <Calendar
                  dateFormat="dd/mm/yy"
                  value={new Date(taxItem.dateOfBirth)}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Sex</td>
              <td>
                <InputText value={taxItem.gender} disabled />
              </td>
            </tr>
            <tr>
              <td>Nationality</td>
              <td>
                <InputText value={taxItem.nationalityName} disabled />
              </td>
            </tr>
            {/* <tr>
              <td>Residential Address</td>
              <td></td>
            </tr> */}
            <tr>
              <td>Designation</td>
              <td>
                <InputText value={taxItem.designation} disabled />
              </td>
            </tr>
            <tr>
              <td>Bank to which salary is credited</td>
              <td>
                <InputText value={taxItem.bankCodeName} disabled />
              </td>
            </tr>
            <tr>
              <td>
                Cessation Provisions Indicator{" "}
                <i
                  className="fas fa-info-circle informationStyle"
                  data-tip=""
                  data-for="cessationProvisionsIndicator"
                ></i>
                <ReactTooltip
                  class="tooltipWidth"
                  id="cessationProvisionsIndicator"
                  aria-haspopup="true"
                  className="tooltipFont"
                >
                  <p>
                    If "Yes" is selected, both the Date of Commencement and
                    Cessation cannot be blank, and the Date of Commencement must
                    be before 01/01/1969.
                  </p>
                </ReactTooltip>
              </td>
              <td>
                <Dropdown
                  value={taxItem.cessationProvisionsIndicator}
                  placeholder={"Please select"}
                  options={TaxFilingMethods.cessationProvisionsIndicator()}
                  onChange={(event) => {
                    setTaxItem({
                      ...taxItem,
                      cessationProvisionsIndicator: event.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                Date of Commencement{" "}
                <i
                  className="fas fa-info-circle informationStyle"
                  data-tip=""
                  data-for="dateOfCommencement"
                ></i>
                <ReactTooltip
                  class="tooltipWidth"
                  id="dateOfCommencement"
                  aria-haspopup="true"
                  className="tooltipFont"
                >
                  <p>
                    Date of Commencement must be earlier or equal to the Date of
                    Cessation
                  </p>
                </ReactTooltip>
              </td>
              <td>
                <Calendar
                  showButtonBar
                  todayButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  value={
                    taxItem.dateOfCommencement
                      ? new Date(taxItem.dateOfCommencement)
                      : undefined
                  }
                  readOnlyInput={true}
                  showIcon={true}
                  onChange={(e) => {
                    setTaxItem({
                      ...taxItem,
                      dateOfCommencement:
                        e.value == null ? null : new Date(e.value.toString()),
                    });
                  }}
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange={"1900:" + header.incomeYear}
                />
              </td>
            </tr>
            <tr>
              <td>
                Date of Cessation{" "}
                <i
                  className="fas fa-info-circle informationStyle"
                  data-tip=""
                  data-for="dateOfCessation"
                ></i>
                <ReactTooltip
                  class="tooltipWidth"
                  id="dateOfCessation"
                  aria-haspopup="true"
                  className="tooltipFont"
                >
                  <p>
                    Date Of Cessation must be within the Income Year in the
                    Header Record.
                  </p>
                </ReactTooltip>
              </td>
              <td>
                <Calendar
                  showButtonBar
                  todayButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  value={
                    taxItem.dateOfCessation
                      ? new Date(taxItem.dateOfCessation)
                      : undefined
                  }
                  readOnlyInput={true}
                  showIcon={true}
                  onChange={(e) => {
                    setTaxItem({
                      ...taxItem,
                      dateOfCessation:
                        e.value == null ? null : new Date(e.value.toString()),
                    });
                  }}
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange={"1900:" + header.incomeYear}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h5 className="pt-4 pb-2">Income Details</h5>
      <div className="p-grid p-fluid">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">IR8A form code</th>
              <th scope="col">Field</th>
              <th scope="col">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>
                Total Amount{" "}
                <i
                  className="fas fa-info-circle informationStyle"
                  data-tip=""
                  data-for="amount"
                ></i>
                <ReactTooltip
                  class="tooltipWidth"
                  id="amount"
                  aria-haspopup="true"
                  className="tooltipFont"
                >
                  <p>
                    Press "Calculate Amount and Others" button at the bottom of
                    the form to calculate the total for both fields.
                  </p>
                </ReactTooltip>
              </td>
              <td>
                <NumberFormat
                  required
                  disabled
                  className="p-inputtext p-disabled"
                  displayType={"input"}
                  value={taxItem.amount}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  maxLength={11}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      amount: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>a)</td>
              <td>
                Gross Salary, Fees, Leave Pay, Wages and Overtime Pay
                <span className="requiredRed">*</span>
              </td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.salary}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  maxLength={11}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      salary: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>b)</td>
              <td>Bonus</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.bonus}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  maxLength={11}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      bonus: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Date of declaration of bonus (Must be within income year)</td>
              <td>
                <Calendar
                  showButtonBar
                  todayButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  readOnlyInput={true}
                  showIcon={true}
                  value={
                    taxItem.bonusDateOfDeclaration === null
                      ? undefined
                      : new Date(taxItem.bonusDateOfDeclaration)
                  }
                  onChange={(e) => {
                    setTaxItem({
                      ...taxItem,
                      bonusDateOfDeclaration:
                        e.value == null ? null : new Date(e.value.toString()),
                    });
                  }}
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange={
                    "" +
                    (parseInt(header.incomeYear) - 2) +
                    ":" +
                    (parseInt(header.incomeYear) + 1)
                  }
                />
              </td>
            </tr>

            <tr>
              <td>c)</td>
              <td>Director's Fees</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.directorsFee}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  maxLength={11}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      directorsFee: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Date of Approval of Director's fee</td>
              <td>
                <Calendar
                  showButtonBar
                  todayButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  readOnlyInput={true}
                  showIcon={true}
                  value={
                    taxItem.directorsFeeDateOfApproval === null
                      ? undefined
                      : new Date(taxItem.directorsFeeDateOfApproval)
                  }
                  onChange={(e) => {
                    setTaxItem({
                      ...taxItem,
                      directorsFeeDateOfApproval:
                        e.value == null ? null : new Date(e.value.toString()),
                    });
                  }}
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange={
                    "" +
                    (parseInt(header.incomeYear) - 3) +
                    ":" +
                    (parseInt(header.incomeYear) + 1)
                  }
                />
              </td>
            </tr>

            <tr>
              <td>d)</td>
              <td>
                Others{" "}
                <i
                  className="fas fa-info-circle informationStyle"
                  data-tip=""
                  data-for="others"
                ></i>
                <ReactTooltip
                  class="tooltipWidth"
                  id="others"
                  aria-haspopup="true"
                  className="tooltipFont"
                >
                  <p>
                    Others is the sum of items d1 to d9 EXCEPT for the
                    following:{" "}
                  </p>
                  <p>d) 4ii. Compensation for loss of office amount.</p>
                  <p>d) 5. Retirement Benefits accrued up to 31/12/1992</p>
                </ReactTooltip>
              </td>
              <td>
                <NumberFormat
                  disabled
                  required
                  displayType={"input"}
                  className="p-inputtext p-disabled"
                  value={taxItem.others}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  maxLength={11}
                  // fixedDecimalScale={true}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      others: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>d) 1. </td>
              <td>Transport Allowance</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.allowanceTransport}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={14}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      allowanceTransport: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td></td>
              <td>Entertainment Allowance</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.allowanceEntertainment}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={14}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      allowanceEntertainment: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td> </td>
              <td>Other Allowance</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.allowanceOther}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={14}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      allowanceOther: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>d) 2.</td>
              <td>Gross Commission Amount</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.grossCommission}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={14}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      grossCommission: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td></td>
              <td>Gross Commission Indicator</td>
              <td>
                <Dropdown
                  value={taxItem.grossCommissionIndicator}
                  placeholder={"Please select"}
                  options={TaxFilingMethods.getGrossCommissionIndicators()}
                  onChange={(event) => {
                    setTaxItem({
                      ...taxItem,
                      grossCommissionIndicator: event.value,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td></td>
              <td>
                Gross Commission Period From{" "}
                <i
                  className="fas fa-info-circle informationStyle"
                  data-tip=""
                  data-for="grossCommissionFrom"
                ></i>
                <ReactTooltip
                  class="tooltipWidth"
                  id="grossCommissionFrom"
                  aria-haspopup="true"
                  className="tooltipFont"
                >
                  <p>
                    Gross Commission Period must be within the Income Year in
                    the Header Record
                  </p>
                </ReactTooltip>
              </td>
              <td>
                <Calendar
                  showButtonBar
                  todayButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  readOnlyInput={true}
                  showIcon={true}
                  value={
                    taxItem.grossCommissionPeriodFrom === null
                      ? undefined
                      : new Date(taxItem.grossCommissionPeriodFrom)
                  }
                  onChange={(e) => {
                    setTaxItem({
                      ...taxItem,
                      grossCommissionPeriodFrom:
                        e.value == null ? null : new Date(e.value.toString()),
                    });
                  }}
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange={
                    "" +
                    (parseInt(header.incomeYear) - 2) +
                    ":" +
                    (parseInt(header.incomeYear) + 1)
                  }
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                Gross Commission Period To{" "}
                <i
                  className="fas fa-info-circle informationStyle"
                  data-tip=""
                  data-for="grossCommissionTo"
                ></i>
                <ReactTooltip
                  class="tooltipWidth"
                  id="grossCommissionTo"
                  aria-haspopup="true"
                  className="tooltipFont"
                >
                  <p>
                    Gross Commission Period must be within the Income Year in
                    the Header Record
                  </p>
                </ReactTooltip>
              </td>
              <td>
                <Calendar
                  showButtonBar
                  todayButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  readOnlyInput={true}
                  showIcon={true}
                  value={
                    taxItem.grossCommissionPeriodTo === null
                      ? undefined
                      : new Date(taxItem.grossCommissionPeriodTo)
                  }
                  onChange={(e) => {
                    setTaxItem({
                      ...taxItem,
                      grossCommissionPeriodTo:
                        e.value == null ? null : new Date(e.value.toString()),
                    });
                  }}
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange={
                    "" +
                    (parseInt(header.incomeYear) - 2) +
                    ":" +
                    (parseInt(header.incomeYear) + 1)
                  }
                />
              </td>
            </tr>

            <tr>
              <td>d) 3.</td>
              <td>Pension</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.pension}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={14}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      pension: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>d) 4i.</td>
              <td>Gratuity/ Notice Pay/ Ex-gratia payment/ Others</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.gratuity_NoticePay_ExgratiapaymentAmount}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={14}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      gratuity_NoticePay_ExgratiapaymentAmount:
                        values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>d) 4ii.</td>
              <td>Compensation for loss of office amount</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.compensationForLossOfOffice}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={14}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      compensationForLossOfOffice: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Approval obtained from IRAS</td>
              <td>
                <Dropdown
                  value={taxItem.compensationForLossOfOfficeApprovalObtained}
                  placeholder={"Please select"}
                  options={TaxFilingMethods.getCompensationApproval()}
                  onChange={(event) => {
                    setTaxItem({
                      ...taxItem,
                      compensationForLossOfOfficeApprovalObtained: event.value,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td></td>
              <td>Date of approval</td>
              <td>
                <Calendar
                  showButtonBar
                  todayButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  showIcon={true}
                  readOnlyInput={true}
                  value={
                    taxItem.compensationForLossOfOfficeDateOfApproval === null
                      ? undefined
                      : new Date(
                          taxItem.compensationForLossOfOfficeDateOfApproval
                        )
                  }
                  onChange={(e) => {
                    setTaxItem({
                      ...taxItem,
                      compensationForLossOfOfficeDateOfApproval:
                        e.value == null ? null : new Date(e.value.toString()),
                    });
                  }}
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange="1900:2100"
                />
              </td>
            </tr>

            <tr>
              <td>d) 5.</td>
              <td>Retirement Benefits accrued up to 31/12/1992</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.retirement}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={14}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      retirement: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Retirement Benefits accrued from 1993</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.retirementFrom1993}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={14}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      retirementFrom1993: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Retirement Fund Name</td>
              <td>
                <InputText
                  value={taxItem.retirementFundName}
                  maxLength={60}
                  keyfilter={/^[\w ()+=,./?:{}~!@#$%*\-\[\]\\]+$/}
                  onChange={(event) =>
                    setTaxItem({
                      ...taxItem,
                      retirementFundName: event.currentTarget.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>d) 6.</td>
              <td>Overseas Employer Contribution</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.contributionsByEmployerToOutsideSg}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={14}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      contributionsByEmployerToOutsideSg:
                        values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>d) 7.</td>
              <td>Excess/Voluntary contribution to CPF by employer</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.excessVoluntaryCPFContributionByEmployer}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={14}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      excessVoluntaryCPFContributionByEmployer:
                        values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>d) 8i.</td>
              <td>Gains and profits from share option for sec.10(1)(b)</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.gainsAndProfitFromShareOptionsFromS101b}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={14}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      gainsAndProfitFromShareOptionsFromS101b:
                        values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>d) 8ii.</td>
              <td>Gains and profits from share option for sec.10(1)(g)</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.gainsAndProfitFromShareOptionsFromS101g}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  maxLength={11}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      gainsAndProfitFromShareOptionsFromS101g:
                        values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>d) 9.</td>
              <td>Value of benefits-in-kind</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.valueOfBenefitsInKind}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={14}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      valueOfBenefitsInKind: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>e)</td>
              <td>Exempt Income</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.exemptIncomeAmount}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  maxLength={11}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      exemptIncomeAmount: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td></td>
              <td>Exempt Indicator</td>
              <td className="taxBodyUpdateLongDropdown">
                <Dropdown
                  value={taxItem.exemptIncomeIndicator}
                  placeholder={"Please select"}
                  options={TaxFilingMethods.getExemptIncomeIndicator()}
                  onChange={(event) => {
                    setTaxItem({
                      ...taxItem,
                      exemptIncomeIndicator: event.value,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>f) </td>
              <td>
                Income Tax Borne By Employer Indicator{" "}
                <i
                  className="fas fa-info-circle informationStyle"
                  data-tip=""
                  data-for="incomeTaxEmployer_Employee"
                ></i>
                <ReactTooltip
                  class="tooltipWidth"
                  id="incomeTaxEmployer_Employee"
                  aria-haspopup="true"
                  className="tooltipFont"
                >
                  <p>
                    Tax fully borne by employer: Both f(i) and f(ii) MUST be 0.
                  </p>
                  <p>
                    Tax partially borne by employer: f(i) CANNOT be 0, f(ii)
                    MUST be 0.
                  </p>
                  <p>
                    A fixed amount of income tax is borne by employee: f(i) MUST
                    be 0, f(ii) CANNOT be 0.
                  </p>
                </ReactTooltip>
              </td>
              <td className="taxBodyUpdateLongDropdown">
                <Dropdown
                  value={taxItem.employmentIncomeTaxBorneByEmployerIndicator}
                  placeholder={"Please select"}
                  options={TaxFilingMethods.getIncomeTaxBorneByEmployer()}
                  onChange={(event) => {
                    setTaxItem({
                      ...taxItem,
                      employmentIncomeTaxBorneByEmployerIndicator: event.value,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>f) (i)</td>
              <td>
                Amount of employment income for which tax is borne by employer
              </td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  disabled={
                    taxItem.employmentIncomeTaxBorneByEmployerIndicator ==
                      "F" ||
                    taxItem.employmentIncomeTaxBorneByEmployerIndicator == "H"
                  }
                  className={
                    taxItem.employmentIncomeTaxBorneByEmployerIndicator ==
                      "F" ||
                    taxItem.employmentIncomeTaxBorneByEmployerIndicator == "H"
                      ? "p-inputtext p-disabled"
                      : "p-inputtext"
                  }
                  value={taxItem.employmentIncomeTaxBorneByEmployerAmount}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  maxLength={11}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      employmentIncomeTaxBorneByEmployerAmount:
                        values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>f) (ii)</td>
              <td>
                Fixed amount of income tax for which tax is borne by employee
              </td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  disabled={
                    taxItem.employmentIncomeTaxBorneByEmployerIndicator ==
                      "F" ||
                    taxItem.employmentIncomeTaxBorneByEmployerIndicator == "P"
                  }
                  className={
                    taxItem.employmentIncomeTaxBorneByEmployerIndicator ==
                      "F" ||
                    taxItem.employmentIncomeTaxBorneByEmployerIndicator == "P"
                      ? "p-inputtext p-disabled"
                      : "p-inputtext"
                  }
                  value={taxItem.incomeTaxBorneByEmployeeAmount}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  maxLength={11}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      incomeTaxBorneByEmployeeAmount: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h5 className="pt-4 pb-2">Deductions</h5>
      <div className="p-grid p-fluid">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Field</th>
              <th scope="col">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                EMPLOYEE'S COMPULSORY contribution to CPF / Designated Pension
                or Provident Fund
              </td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.cpf}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  maxLength={9}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      cpf: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                Name of Employee's Compulsory Designated Pension or Provident
                Fund
              </td>
              <td>
                <InputText
                  value={taxItem.employeeCompulsoryContributionFundName}
                  keyfilter={/^[\w ()+=,./?:{}~!@#$%*\-\[\]\\]+$/}
                  onChange={(event) =>
                    setTaxItem({
                      ...taxItem,
                      employeeCompulsoryContributionFundName:
                        event.currentTarget.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Donations</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.donation}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  maxLength={6}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      donation: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                Contributions deducted from salaries to Mosque Building Fund
              </td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.mosqueBuildingFund}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  maxLength={6}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      mosqueBuildingFund: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Life Insurance premiums deducted from salaries</td>
              <td>
                <NumberFormat
                  required
                  displayType={"input"}
                  className="p-inputtext"
                  value={taxItem.lifeInsurancePremiums}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  maxLength={6}
                  onValueChange={(values) => {
                    setTaxItem({
                      ...taxItem,
                      lifeInsurancePremiums: values.floatValue || 0,
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="text-center pt-3">
        Click Calculate to preview the recalculated Amount and Others fields and
        check for form validation errors.
      </p>

      <div className="p-grid p-justify-center pt-4">
        <span className="px-1">
          <Button
            // type="button"
            label={"Calculate"}
            tooltip="Preview the recalculated Amount and Others fields and check for form validation errors."
            tooltipOptions={{ position: "top" }}
            onClick={
              (e) => calculateValues(e)

              // var totalOthers =
              //   taxItem.allowanceTransport +
              //   taxItem.allowanceEntertainment +
              //   taxItem.allowanceOther +
              //   taxItem.grossCommission +
              //   taxItem.pension +
              //   taxItem.gratuity_NoticePay_ExgratiapaymentAmount +
              //   taxItem.retirementFrom1993 +
              //   taxItem.contributionsByEmployerToOutsideSg +
              //   taxItem.excessVoluntaryCPFContributionByEmployer +
              //   taxItem.gainsAndProfitFromShareOptionsFromS101b +
              //   taxItem.valueOfBenefitsInKind;
              // var totalAmount =
              //   taxItem.salary +
              //   taxItem.bonus +
              //   taxItem.directorsFee +
              //   totalOthers;
              // setTaxItem({
              //   ...taxItem,
              //   others: totalOthers,
              //   amount: totalAmount,
              // });
            }
          />
        </span>
        <span className="px-1">
          <Button type="submit" label={"Save"} />{" "}
        </span>
      </div>
    </form>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <>
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      <div className="row">
        <div className="col-12 col-md-12 col-lg-8 col-xl-8">
          <div className="card">
            <h1 className="pb-2">Tax Body Edit</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {display}
          </div>
        </div>
      </div>
    </>
  );
}
