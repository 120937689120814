import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useReducer, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { UserProfile } from '../../Model/PostModel';
import PublicProfileService from '../../Service/PublicProfileService';
import About from './About';
import CoverPicture from './CoverPicture';
import InterestsHobbies from './InterestsHobbies';
import ProfilePicture from './ProfilePicture';
import UserDescription from './UserDescription';
import UserInfo from './UserInfo';

class initialState {
    userProfile: UserProfile = new UserProfile();
}

export const PP_ACTIONS = {
    SAVE_USER_PROFILE: 'save-user-profile',
    ADD_ABOUT: 'add-about',
    EDIT_ABOUT: 'edit-about',
    ADD_INTERESTSHOBBIES: 'add-interestshobbies',
    EDIT_INTERESTSHOBBIES: 'edit-interestshobbies',
    ADD_TELEGRAM: 'add-telegram',
    EDIT_TELEGRAM: 'edit-telegram',
}

function reducer(state: initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
        case PP_ACTIONS.SAVE_USER_PROFILE:
            {
                const profile = action.payload
                return { ...state, userProfile: profile }
            }
        case PP_ACTIONS.ADD_ABOUT:
            {
                const about = action.payload
                console.log('added about', about)
                return { ...state, userProfile: { ...state.userProfile, aboutMe: about, hasCreatedPublicProfile: true } }
            }
        case PP_ACTIONS.EDIT_ABOUT:
            {
                const editedAbout = action.payload
                console.log('editedAbout', editedAbout)
                return { ...state, userProfile: { ...state.userProfile, aboutMe: editedAbout } }
            }
        case PP_ACTIONS.ADD_INTERESTSHOBBIES:
            {
                const addedInterestsHobbies = action.payload
                console.log('added InterestsHobbies', addedInterestsHobbies)
                return { ...state, userProfile: { ...state.userProfile, interestsHobbies: addedInterestsHobbies, hasCreatedPublicProfile: true } }
            }
        case PP_ACTIONS.EDIT_INTERESTSHOBBIES:
            {
                const editedInterestsHobbies = action.payload
                console.log('editedInterestsHobbies', editedInterestsHobbies)
                return { ...state, userProfile: { ...state.userProfile, interestsHobbies: editedInterestsHobbies } }
            }
        case PP_ACTIONS.ADD_TELEGRAM:
            {
                const addedTelegram = action.payload
                console.log('added addedTelegram', addedTelegram)
                return { ...state, userProfile: { ...state.userProfile, telegramUsername: addedTelegram, hasCreatedPublicProfile: true } }
            }
        case PP_ACTIONS.EDIT_TELEGRAM:
            {
                const editedTelegram = action.payload
                console.log('editedTelegram', editedTelegram)
                return { ...state, userProfile: { ...state.userProfile, telegramUsername: editedTelegram } }
            }
        default:
            throw new Error("reducer error");
    }
}

export const PublicProfileContext = React.createContext<{
    state: initialState
    dispatch: React.Dispatch<{
        type: any;
        payload: any;
    }>
}>({
    state: new initialState(),
    dispatch: () => undefined,
});

export default function PublicProfile(props: RouteComponentProps<any, {}, { userId: string }>) {
    const [state, dispatch] = useReducer(reducer, new initialState());
    const [hasLoaded, setHasLoaded] = useState(false)

    useEffect(() => {
        console.log('state', state)
    }, [state])

    useEffect(() => {
        async function getSelectedProfile(userId: string) {
            const publicProfileSvc = new PublicProfileService()
            const result = await publicProfileSvc.getUserProfile(userId)
            console.log('selectedProfileResults', result)
            dispatch({ type: PP_ACTIONS.SAVE_USER_PROFILE, payload: result.data })
            setHasLoaded(true)
        }
        // console.log(props)
        const selectedUserId = props.match.params.userId
        getSelectedProfile(selectedUserId)
    // }, [])
    }, [props.match.params.userId])

if (!hasLoaded) return <ProgressSpinner className="loader-centered loader-size" />

    return (
        <PublicProfileContext.Provider value={{ state, dispatch }}>
            <div className="profile-page-container">
                <div className="background-white">
                    <CoverPicture />
                    <div className="profile-page">
                        <div className="position-relative profilepic-userdescription-div">
                            <ProfilePicture />
                            <UserDescription />
                        </div>
                        <div><UserInfo /></div>
                    </div>
                </div>
                <About />
                <InterestsHobbies />
                <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet" />
            </div>
        </PublicProfileContext.Provider>
    )
}