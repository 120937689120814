import * as React from "react";
import { Route } from "react-router";
import AccountingCodeCreate from "../accountingcode/AccountingCodeCreate";
import AccountingCodePage from "../accountingcode/AccountingCodePage";
import { SafeEntryDashboard } from "../attendance/SafeEntryDashboard";
import CompanyBirdseyeView from "../birdseye/Birdseye";
import Campaign from "../campaign/Campaign";
import CampaignCreate from "../campaign/CampaignCreate";
import CampaignDeliverableView from "../campaign/CampaignDeliverablesView";
import CampaignDocumentForm from "../campaign/CampaignDocumentForm";
import CampaignDocumentTable from "../campaign/CampaignDocumentTable";
import CampaignInvoice from "../campaign/CampaignInvoice";
import CampaignInvoiceExport from "../campaign/CampaignInvoiceExport";
import CampaignType from "../campaign/CampaignType";
import CampaignTypeCreate from "../campaign/CampaignTypeCreate";
import ChronosOverview from "../chronos/ChronosOverview";
import EquipmentInOut from "../chronos/EquipmentInOut";
import { ApproveClaimsPage } from "../claims/ApproveClaims";
import CreateUpdateClaimsPage from "../claims/CreateUpdateClaimsPage";
import { EndorsingClaimsPage } from "../claims/EndorseClaims";
import ClaimsPage from "../claims/MyClaims";
import ViewClaimByID from "../claims/ViewClaimByID";
import AllClientCompanies from "../client/AllClientCompanies";
import ClientCompanyCreate from "../client/ClientCompanyCreate";
import ClientContactCreate from "../client/ClientContactCreate";
import ClientContactInCompany from "../client/ClientContactInCompany";
import { Company, CompanyCreate } from "../company/Company";
import Contract from "../contract/Contract";
import CreateContract from "../contract/CreateContract";
import ViewContract from "../contract/ViewContract";
import AdminDashboard from "../dashboard/AdminDashboard";
import BossDashboard from "../dashboard/BossDashboard";
import Dashboard from "../dashboard/Dashboard";
import { QuickLinks } from "../dashboard/QuickLinks";
import DeliverableCreate from "../deliverable/DeliverableCreate";
import DeliverablesOutstandingView from "../deliverable/DeliverablesOutstanding";
import DeliverableType from "../deliverable/DeliverableType";
import DeliverableTypeCreate from "../deliverable/DeliverableTypeCreate";
import WorkDistributionOverview from "../deliverable/DepartmentOverview";
import { Department, DepartmentCreate } from "../department/Department";
import EquipmentDetails from "../equipment/EquipmentDetails";
import LogisticsList from "../equipment/EquipmentList";
import LogisticsCreate from "../equipment/LogisticsCreate";
import LogisticsStatusCreate from "../equipment/LogisticsStatusCreate";
import LogisticsUpdate from "../equipment/LogisticsUpdate";
import WriteoffEquipment from "../equipment/WriteoffEquipment";
import { AllPayrolls } from "../finance/AllPayrolls";
import { CompanyDetails } from "../finance/CompanyDetails";
import CostType from "../finance/CostType";
import CostTypeCreate from "../finance/CostTypeCreate";
import { ExternalCost } from "../finance/ExternalCost";
import ExternalCostCreate from "../finance/ExternalCostCreate";
import { PayrollView } from "../finance/PayrollView";
import { GroupCompanyCreate } from "../groupcompany/GroupCompany";
import SignupForm from "../landingpages/ContactUs";
import Priceplans from "../landingpages/Priceplans";
import Noticeboard from "../noticeboard/noticeboard";
import { AnnouncementEditPage } from "../notifications/AnnouncementCreate";
import { CircularDocumentView } from "../circulars/CircularDocumentView";
import { AnnouncementPage } from "../notifications/AnnouncementPage";
import Onboarding from "../onboarding/Onboarding";
import PayItemType from "../payslip/PayItemType";
import PayItemTypeCreate from "../payslip/PayItemTypeCreate";
import { PayslipCreateSpecial } from "../payslip/PayslipCreateSpecial";
import { PayslipExport } from "../payslip/PayslipExport";
import { PayslipUpdate } from "../payslip/PayslipUpdate";
import { PayslipUserAll } from "../payslip/PayslipUserAll";
import Publishers from "../publisher/Publishers";
import Reports from "../reports/Reports";
import MyRoomBookings from "../room/MyRoomBookings";
import Room from "../room/Room";
import RoomBooking from "../room/RoomBooking";
import RoomCreate from "../room/RoomCreate";
import { ClientDetails } from "../sales/ClientDetails";
import { MySalesClients } from "../sales/MySalesClients";
import { SalesClientCreate } from "../sales/SalesClientCreate";
import ViewIndicators from "../scoreindicators/deptscoresheet/ViewAllDeptScoreIndicators";
import ViewCustomDeptScoreIndicators from "../scoreindicators/deptscoresheet/ViewCustomDeptScoreIndicators";
import KPIBossView from "../scoreindicators/KPIBossView";
import LinkPerformanceDeptView from "../scoreindicators/linkperformance/LinkPerformanceDeptView";
import MyKPIPage from "../scoreindicators/kpipoints/MyKPIPage";
import SupervisorView from "../scoreindicators/supervisorview/SupervisorView";
import { PasswordSetting } from "../settings/AccountSetting";
import {
  DocumentTypeSetting,
  DocumentTypeSettingCreate,
} from "../settings/DocumentTypeSetting";
import {
  HolidaySettings,
  HolidaySettingsCreate,
} from "../settings/HolidaySetting";
import {
  JobTitleSetting,
  JobTitleSettingCreate,
} from "../settings/JobTitleSetting";
import { Role, RoleCreate } from "../settings/Role";
import { RoleTaskSetting } from "../settings/RoleTaskSetting";
import { TaskModuleSetting } from "../settings/TaskSetting";
import { UserAccessSetting } from "../settings/UserAccessSetting";
import { Sitemap } from "../Sitemap";
import { ApproveLeavePage } from "../statusrecord/ApproveLeavePage";
import { AuditLeave } from "../statusrecord/AuditLeave";
import BalanceAndHistory from "../statusrecord/BalanceAndHistory";
import LeaveCreditApplicationCombined from "../statusrecord/LeaveCreditApplicationCombined";
import { LeaveOverviewDepts } from "../statusrecord/LeaveOverviewDepts";
import { StatusCalendarPage } from "../statusrecord/StatusCalendar";
import { StatusRecord } from "../statusrecord/StatusRecord";
import { StatusType, StatusTypeCreate } from "../statusrecord/StatusType";
import { StaffView } from "../user/StaffView";
import EmailSignature from "../user/EmailSignature";
import BusinessCardUser from "../user/BusinessCardUser";
import { User } from "../user/User";
import { UserCreate } from "../user/UserCreate";
import { UserDocument, UserDocumentCreate } from "../user/UserDocument";
import { UserUpdate } from "../user/UserUpdate";
import { UserView } from "../user/UserView";
import WeeklyDuties from "../weeklyduties/WeeklyDuties";
import WeeklyDutyCreate from "../weeklyduties/WeeklyDutyCreate";
import { CommonMethods } from "./CommonMethods";
import { UserProfileModel } from "./ExportClass";
import { MenuItem, MenuList } from "./MenuList";
import { Outerclass } from "./Outerclass";
import AdminEditClaim from "../claims/AdminEditClaim";
import PublisherCreate from "../publisher/PublisherCreate";
import IRNLinkStatsDashboard from "../scoreindicators/IRNLinkStatsDashboard";
import LinkPerformanceBossView from "../scoreindicators/LinkPerformanceBossView";
import TaxFiling from "../taxfiling/TaxFiling";
import TaxBodyEdit from "../taxfiling/TaxBodyEdit";
import QuicklinksDashboardCreate from "../quicklinks/QuicklinksDashboardCreate";
import CircularsDashboardCreate from "../circulars/CircularsDashboardCreate";
import { QCDashboard } from "../quicklinks/AdminQCDashboard";
import IrasSubmissionLoading from "../taxfiling/IrasSubmissionLoading";
import IrasSubmissionComplete from "../taxfiling/IrasSubmissionComplete";
import TaxFilingDashboard from "../taxfiling/TaxFilingDashboard";
import IrasSubmissionError from "../taxfiling/IrasSubmissionError";
import PayslipUpdatefunction from "../payslip/PayslipUpdateFunction";
import PayslipCreateSpecialFunctional from "../payslip/PayslipCreateSpecialFunctional";
import CompanyFeed from "../companyfeed/CompanyFeed";
import PublicProfile from "../companyfeed/Component/PublicProfile/PublicProfile";
import SinglePostContainer from "../companyfeed/Component/SinglePost/SinglePostContainer";

type MyContext = {
  profile: UserProfileModel;
};

const ProfileContext = React.createContext<MyContext>({
  profile: new UserProfileModel(),
});

export default class RouteProvider extends React.Component<any> {
  commonMethods: CommonMethods;
  _isMounted = false;

  constructor(props: any) {
    super(props);
    this.commonMethods = new CommonMethods();
  }

  readonly state = {
    profile: new UserProfileModel(),
    done: false,
  };

  componentDidMount() {
    this._isMounted = true;

    this.commonMethods.getContext().then((res: UserProfileModel) => {
      if (this._isMounted) {
        // localStorage.setItem("currentAccessingCompany", res.companyID);
        this.setState({ profile: res, done: true });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.state.done) {
      return (
        <ProfileContext.Provider
          value={{
            profile: this.state.profile,
          }}
        >
          <AppRoutes />
        </ProfileContext.Provider>
      );
    } else {
      return <></>;
    }
  }
}

const menuList = new MenuList().Menu;

const AppRoutes: React.FunctionComponent<{}> = () => {
  return (
    <ProfileContext.Consumer>
      {({ profile }) => (
        <>
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={Priceplans}
            menuModule={menuList.priceplans}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={SignupForm}
            menuModule={menuList.signupform}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={Noticeboard}
            menuModule={menuList.noticeboard}
          />
          {
            //    ___             _     _                        _
            //   |   \  __ _  ___| |_  | |__  ___  __ _  _ _  __| | ___
            //   | |) |/ _` |(_-<| ' \ | '_ \/ _ \/ _` || '_|/ _` |(_-<
            //   |___/ \__,_|/__/|_||_||_.__/\___/\__,_||_|  \__,_|/__/
            //
          }
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={BossDashboard}
            menuModule={menuList.bossdashboard}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={Dashboard}
            menuModule={menuList.dashboard}
          />
          {
            //    ___  _  _
            //   / __|(_)| |_  ___  _ __   __ _  _ __
            //   \__ \| ||  _|/ -_)| '  \ / _` || '_ \
            //   |___/|_| \__|\___||_|_|_|\__,_|| .__/
            //                                  |_|
          }
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={Sitemap}
            menuModule={menuList.sitemap}
          />
          {
            //     ___         _                        _  _
            //    / _ \  _ _  | |__  ___  __ _  _ _  __| |(_) _ _   __ _
            //   | (_) || ' \ | '_ \/ _ \/ _` || '_|/ _` || || ' \ / _` |
            //    \___/ |_||_||_.__/\___/\__,_||_|  \__,_||_||_||_|\__, |
            //                                                     |___/
          }
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={Onboarding}
            menuModule={menuList.onboarding}
          />
          {/***
           *      ___  _
           *     | __|(_) _ _   __ _  _ _   __  ___
           *     | _| | || ' \ / _` || ' \ / _|/ -_)
           *     |_|  |_||_||_|\__,_||_||_|\__|\___|
           *
           */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CompanyDetails}
            menuModule={menuList.financecompanydetails}
          />
          {/* <PrivateRoute
            userProfile={profile}
            component={PayrollCurrent}
            menuModule={menuList.payroll}
          /> */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={PayrollView}
            menuModule={menuList.payrollview}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={AllPayrolls}
            menuModule={menuList.payrollhistory}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            // component={PayslipUpdate}
            component={PayslipUpdatefunction}
            menuModule={menuList.payslipupdate}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={PayslipExport}
            menuModule={menuList.payslipexport}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            // component={PayslipCreateSpecial}
            component={PayslipCreateSpecialFunctional}
            menuModule={menuList.payrollmanual}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={PayItemType}
            menuModule={menuList.payitemtype}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={PayItemTypeCreate}
            menuModule={menuList.payitemtypecreate}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={PayItemTypeCreate}
            menuModule={menuList.payitemtypeupdate}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ExternalCost}
            menuModule={menuList.externalcost}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ExternalCostCreate}
            menuModule={menuList.externalcostcreate}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ExternalCostCreate}
            menuModule={menuList.externalcostupdate}
          />
          {/*  <PrivateRoute userProfile={profile} strictCheck={true}

            component={AllClientCompanies}
            menuModule={menuList.suppliercompany}
          /> */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ClientCompanyCreate}
            menuModule={menuList.createsuppliercompany}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ClientCompanyCreate}
            menuModule={menuList.updatesuppliercompany}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ApproveClaimsPage}
            menuModule={menuList.approveclaims}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={WriteoffEquipment}
            menuModule={menuList.equipmentwriteoff}
          />
          {/***
           *      _   _
           *     | | | | ___ ___  _ _
           *     | |_| |(_-</ -_)| '_|
           *      \___/ /__/\___||_|
           *
           */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={User}
            menuModule={menuList.user}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={User}
            menuModule={menuList.userstatus}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={UserCreate}
            menuModule={menuList.usercreate}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={UserCreate}
            menuModule={menuList.useronboarding}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={UserUpdate}
            menuModule={menuList.userupdate}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={UserView}
            menuModule={menuList.userview}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={UserAccessSetting}
            menuModule={menuList.useradminsetting}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={PasswordSetting}
            menuModule={menuList.changepassword}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={PasswordSetting}
            menuModule={menuList.changepasswordadmin}
          />
          {/***
           *       ___            _                   _
           *      / __| ___  _ _ | |_  _ _  __ _  __ | |_
           *     | (__ / _ \| ' \|  _|| '_|/ _` |/ _||  _|
           *      \___|\___/|_||_|\__||_|  \__,_|\__| \__|
           *
           */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={Contract}
            menuModule={menuList.contract}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ViewContract}
            menuModule={menuList.viewcontract}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CreateContract}
            menuModule={menuList.createnewcontract}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CreateContract}
            menuModule={menuList.createusercontract}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CreateContract}
            menuModule={menuList.updatecontract}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={Contract}
            menuModule={menuList.viewusercontract}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ViewContract}
            menuModule={menuList.viewusercontractfromcontract}
          />
          {/***
           *       ___
           *      / __| ___  _ __   _ __  __ _  _ _  _  _
           *     | (__ / _ \| '  \ | '_ \/ _` || ' \| || |
           *      \___|\___/|_|_|_|| .__/\__,_||_||_|\_, |
           *                       |_|               |__/
           */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={GroupCompanyCreate}
            menuModule={menuList.groupcompanyupdate}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={Company}
            menuModule={menuList.company}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CompanyCreate}
            menuModule={menuList.createcompany}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CompanyCreate}
            menuModule={menuList.updatecompany}
          />
          {/***
           *      ___                         _                     _
           *     |   \  ___  _ __  __ _  _ _ | |_  _ __   ___  _ _ | |_
           *     | |) |/ -_)| '_ \/ _` || '_||  _|| '  \ / -_)| ' \|  _|
           *     |___/ \___|| .__/\__,_||_|   \__||_|_|_|\___||_||_|\__|
           *                |_|
           */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={Department}
            menuModule={menuList.department}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={DepartmentCreate}
            menuModule={menuList.createdepartment}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={DepartmentCreate}
            menuModule={menuList.updatedepartment}
          />
          {/***
           *      ___       _    _    _
           *     / __| ___ | |_ | |_ (_) _ _   __ _  ___
           *     \__ \/ -_)|  _||  _|| || ' \ / _` |(_-<
           *     |___/\___| \__| \__||_||_||_|\__, |/__/
           *                                  |___/
           */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={HolidaySettings}
            menuModule={menuList.holiday}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={HolidaySettingsCreate}
            menuModule={menuList.createholiday}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={HolidaySettingsCreate}
            menuModule={menuList.updateholiday}
          />
          {/* //////////////////////////////////////// */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={DocumentTypeSetting}
            menuModule={menuList.documenttype}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={DocumentTypeSettingCreate}
            menuModule={menuList.createdocumenttype}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={DocumentTypeSettingCreate}
            menuModule={menuList.updatedocumenttype}
          />
          {/* ////////////////////////////////////////// */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={JobTitleSetting}
            menuModule={menuList.jobtitle}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={JobTitleSettingCreate}
            menuModule={menuList.createjobtitle}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={JobTitleSettingCreate}
            menuModule={menuList.updatejobtitle}
          />
          {/* ////////////////////////////////////////// */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={Role}
            menuModule={menuList.role}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={RoleCreate}
            menuModule={menuList.createrole}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={RoleCreate}
            menuModule={menuList.updaterole}
          />
          {/* //////////////////////////////////////// */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={WeeklyDuties}
            menuModule={menuList.weeklyduties}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={WeeklyDutyCreate}
            menuModule={menuList.createweeklyduties}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={WeeklyDutyCreate}
            menuModule={menuList.updateweeklyduties}
          />
          {/* ////////////////////////////////////////// */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={StatusType}
            menuModule={menuList.statustype}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={StatusTypeCreate}
            menuModule={menuList.createstatustype}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={StatusTypeCreate}
            menuModule={menuList.updatestatustype}
          />
          {/* ////////////////////////////////////////// */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={Room}
            menuModule={menuList.room}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={RoomCreate}
            menuModule={menuList.createroom}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={RoomCreate}
            menuModule={menuList.updateroom}
          />
          {/* ////////////////////////////////////////// */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={AnnouncementPage}
            menuModule={menuList.announcement}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={AnnouncementEditPage}
            menuModule={menuList.makeannouncement}
          />
          {/* ////////////////////////////////////////// */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CostType}
            menuModule={menuList.costtype}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CostTypeCreate}
            menuModule={menuList.createcosttype}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CostTypeCreate}
            menuModule={menuList.updatecosttype}
          />
          {/* ////////////////////////////////////////// */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CampaignType}
            menuModule={menuList.campaigntype}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CampaignTypeCreate}
            menuModule={menuList.createcampaigntype}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CampaignTypeCreate}
            menuModule={menuList.updatecampaigntype}
          />
          {/* ////////////////////////////////////////// */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={DeliverableType}
            menuModule={menuList.deliverabletype}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={DeliverableTypeCreate}
            menuModule={menuList.createdeliverabletype}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={DeliverableTypeCreate}
            menuModule={menuList.updatedeliverabletype}
          />
          {/* ////////////////////////////////////////// */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={Publishers}
            menuModule={menuList.publisher}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={PublisherCreate}
            menuModule={menuList.createpublisher}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={PublisherCreate}
            menuModule={menuList.updatepublisher}
          />
          {/* ////////////////////////////////////////// */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={QCDashboard}
            menuModule={menuList.quicklinksdashboard}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={CircularDocumentView}
            menuModule={menuList.viewcirculardocument}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={QuicklinksDashboardCreate}
            menuModule={menuList.createquicklinks}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={QuicklinksDashboardCreate}
            menuModule={menuList.updatequicklinks}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CircularsDashboardCreate}
            menuModule={menuList.createcirculars}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CircularsDashboardCreate}
            menuModule={menuList.updatecirculars}
          />
          {/***
           *      _   _                 ___
           *     | | | | ___ ___  _ _  |   \  ___  __
           *     | |_| |(_-</ -_)| '_| | |) |/ _ \/ _|
           *      \___/ /__/\___||_|   |___/ \___/\__|
           *
           */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={UserDocument}
            menuModule={menuList.userdocument}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={UserDocumentCreate}
            menuModule={menuList.createuserdocument}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={UserDocumentCreate}
            menuModule={menuList.updateuserdocument}
          />
          {/***
           *      _
           *     | |    ___  __ _ __ __ ___
           *     | |__ / -_)/ _` |\ V // -_)
           *     |____|\___|\__,_| \_/ \___|
           *
           */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={LeaveCreditApplicationCombined}
            menuModule={menuList.creditstatus}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={LeaveCreditApplicationCombined}
            menuModule={menuList.viewcreditstatus}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={ApproveLeavePage}
            menuModule={menuList.approveleave} // menu item
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={LeaveOverviewDepts}
            menuModule={menuList.departmentview} // menu item
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={StatusRecord}
            menuModule={menuList.applyleaveonbehalfsupervisor}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={BalanceAndHistory}
            menuModule={menuList.leavebalancehistory} // menu item
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={BalanceAndHistory}
            menuModule={menuList.viewuserleavebalancehistory}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={StatusRecord}
            menuModule={menuList.applyleave} // menu item
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={StatusRecord}
            menuModule={menuList.updateleave}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={AuditLeave}
            menuModule={menuList.adminaccess}
          />
          {/***
           *       ___        _                _
           *      / __| __ _ | | ___  _ _   __| | __ _  _ _
           *     | (__ / _` || |/ -_)| ' \ / _` |/ _` || '_|
           *      \___|\__,_||_|\___||_||_|\__,_|\__,_||_|
           *
           */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={StatusCalendarPage}
            menuModule={menuList.calendar}
          />
          {/***
           *      ___             __  _  _
           *     | _ \ _ _  ___  / _|(_)| | ___
           *     |  _/| '_|/ _ \|  _|| || |/ -_)
           *     |_|  |_|  \___/|_|  |_||_|\___|
           *
           */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={StaffView}
            menuModule={menuList.profile}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={PayslipUserAll}
            menuModule={menuList.payslip}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={EmailSignature}
            menuModule={menuList.emailsignature}

          />
		  <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={BusinessCardUser}
            menuModule={menuList.businesscarduser}
          />
          {
            //    _____           _
            //   |_   _|__ _  ___| |__
            //     | | / _` |(_-<| / /
            //     |_| \__,_|/__/|_\_\
            //
          }
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={TaskModuleSetting}
            menuModule={menuList.tasksettings}
          />
          {/***
           *      ___
           *     | _ \ ___  ___  _ __
           *     |   // _ \/ _ \| '  \
           *     |_|_\\___/\___/|_|_|_|
           *
           */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={RoomBooking}
            menuModule={menuList.roombooking}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={RoomBooking}
            menuModule={menuList.viewroombooking}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={MyRoomBookings}
            menuModule={menuList.viewmyroombookings}
          />
          {
            //    ___  _          _
            //   | _ )(_) _ _  __| | ___ ___  _  _  ___
            //   | _ \| || '_|/ _` |(_-</ -_)| || |/ -_)
            //   |___/|_||_|  \__,_|/__/\___| \_, |\___|
            //                                |__/
          }
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={CompanyBirdseyeView}
            menuModule={menuList.birdseye}
          />
          {
            //     ___  _        _
            //    / __|| | __ _ (_) _ __   ___
            //   | (__ | |/ _` || || '  \ (_-<
            //    \___||_|\__,_||_||_|_|_|/__/
            //
          }
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={CreateUpdateClaimsPage}
            menuModule={menuList.createclaims}
          />
          {/* <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={UpdateClaimsPage}
            menuModule={menuList.updateclaims}
          /> */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={ClaimsPage}
            menuModule={menuList.viewclaims}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ViewClaimByID}
            menuModule={menuList.viewthisclaim}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={EndorsingClaimsPage}
            menuModule={menuList.endorseclaims}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={AdminEditClaim}
            menuModule={menuList.admineditclaims}
          />
          {/*
        //              ___        _
        //             / __| __ _ | | ___  ___
        //             \__ \/ _` || |/ -_)(_-<
        //             |___/\__,_||_|\___|/__/
        //
        //
        */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={AllClientCompanies}
            menuModule={menuList.clientcompany} // menu item
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ClientCompanyCreate}
            menuModule={menuList.createclientcompany}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ClientCompanyCreate}
            menuModule={menuList.updateclientcompany}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ClientContactInCompany}
            menuModule={menuList.clientcontacts}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ClientContactCreate}
            menuModule={menuList.createclientcontacts}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ClientContactCreate}
            menuModule={menuList.updateclientcontacts}
          />
          {
            //    ___    _    _     ___  ___    ___  _     ___  ___  _  _  _____  ___
            //   / __|  /_\  | |   | __|/ __|  / __|| |   |_ _|| __|| \| ||_   _|/ __|
            //   \__ \ / _ \ | |__ | _| \__ \ | (__ | |__  | | | _| | .` |  | |  \__ \
            //   |___//_/ \_\|____||___||___/  \___||____||___||___||_|\_|  |_|  |___/
            //
          }
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={MySalesClients}
            menuModule={menuList.viewassignedclients} // menu item
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={SalesClientCreate}
            menuModule={menuList.createsalesclientbyclient}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={SalesClientCreate}
            menuModule={menuList.createsalesclient}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ClientDetails}
            menuModule={menuList.updateclient}
          />
          {/***
           *       ___                           _
           *      / __| __ _  _ __   _ __  __ _ (_) __ _  _ _
           *     | (__ / _` || '  \ | '_ \/ _` || |/ _` || ' \
           *      \___|\__,_||_|_|_|| .__/\__,_||_|\__, ||_||_|
           *                        |_|            |___/
           */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={Campaign}
            menuModule={menuList.campaigns} // menu item
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CampaignCreate}
            menuModule={menuList.createcampaign}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CampaignCreate}
            menuModule={menuList.updatecampaign}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={Campaign}
            menuModule={menuList.mycampaigns} // menu item
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CampaignDeliverableView}
            menuModule={menuList.campaignsummary}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CampaignDocumentTable}
            menuModule={menuList.campaigndocuments}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CampaignDocumentForm}
            menuModule={menuList.createcampaigndocuments}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CampaignDocumentForm}
            menuModule={menuList.updatecampaigndocuments}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CampaignInvoice}
            menuModule={menuList.campaigninvoice}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CampaignInvoiceExport}
            menuModule={menuList.campaigninvoiceexport}
          />
          {/*  <PrivateRoute userProfile={profile} strictCheck={true}

            component={PaymentTerms}
            menuModule={menuList.paymentterms}
          />
           <PrivateRoute userProfile={profile} strictCheck={true}

            component={PaymentTermsCreate}
            menuModule={menuList.paymenttermscreate}
          />
           <PrivateRoute userProfile={profile} strictCheck={true}

            component={PaymentTermsCreate}
            menuModule={menuList.paymenttermsupdate}
          /> */}
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={DeliverablesOutstandingView}
            menuModule={menuList.myoutstandingdeliverables} // menu item
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={WorkDistributionOverview}
            menuModule={menuList.workloaddistribution} // menu item
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={DeliverableCreate}
            menuModule={menuList.createdeliverable}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={DeliverableCreate}
            menuModule={menuList.updatedeliverable}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={RoleTaskSetting}
            menuModule={menuList.assigntaskrole}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={AdminDashboard}
            menuModule={menuList.admindashboard}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={Reports}
            menuModule={menuList.reports}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={QuickLinks}
            menuModule={menuList.quicklinks}
          />
          {
            //    ___              _                         _
            //   | __| __ _  _  _ (_) _ __  _ __   ___  _ _ | |_
            //   | _| / _` || || || || '_ \| '  \ / -_)| ' \|  _|
            //   |___|\__, | \_,_||_|| .__/|_|_|_|\___||_||_|\__|
            //           |_|         |_|
            //    aka Logistics
          }
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={LogisticsStatusCreate}
            menuModule={menuList.logsstatuscreate}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={LogisticsCreate}
            menuModule={menuList.logisticscreate}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={LogisticsList}
            menuModule={menuList.equipmentview}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={EquipmentDetails}
            menuModule={menuList.equipmentdetails}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={LogisticsUpdate}
            menuModule={menuList.logisticsupdate}
          />
          {
            //     ___  _  _  ___   ___   _  _   ___   ___
            //    / __|| || || _ \ / _ \ | \| | / _ \ / __|
            //   | (__ | __ ||   /| (_) || .` || (_) |\__ \
            //    \___||_||_||_|_\ \___/ |_|\_| \___/ |___/
            //
          }
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={ChronosOverview}
            menuModule={menuList.chronos}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={EquipmentInOut}
            menuModule={menuList.equipmentwithdraw}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={false}
            component={MyKPIPage} // menu item
            menuModule={menuList.userindicator}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={SupervisorView}
            menuModule={menuList.departmentuserindicator}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={ViewIndicators}
            menuModule={menuList.viewscoreindicator}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            // component={DepartmentIndicatorView}
            component={ViewCustomDeptScoreIndicators}
            menuModule={menuList.departmentscoreindicator}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={KPIBossView}
            menuModule={menuList.bossuserindicatorview}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={LinkPerformanceBossView}
            menuModule={menuList.bossdeptstatsview}
          />

          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={LinkPerformanceDeptView}
            menuModule={menuList.linkperformanceview}
          />

          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={IRNLinkStatsDashboard}
            menuModule={menuList.irnlinkstatsdashboard}
          />

          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={SafeEntryDashboard}
            menuModule={menuList.safeentrydashboard}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={AccountingCodeCreate}
            menuModule={menuList.accountingcodecreate}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={AccountingCodeCreate}
            menuModule={menuList.accountingcodeupdate}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={AccountingCodePage}
            menuModule={menuList.accountingcode}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={TaxFilingDashboard}
            menuModule={menuList.taxfilingdashboard}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={TaxFiling}
            menuModule={menuList.taxfiling}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={TaxBodyEdit}
            menuModule={menuList.taxfilingupdate}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={IrasSubmissionLoading}
            menuModule={menuList.irassubmissionloading}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={IrasSubmissionComplete}
            menuModule={menuList.irassubmissionsuccess}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={IrasSubmissionError}
            menuModule={menuList.irassubmissionerror}
          />

           {/* Company Feed */}
           <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={CompanyFeed}
            menuModule={menuList.companyfeed}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={SinglePostContainer}
            menuModule={menuList.companyfeedpost}
          />
          <PrivateRoute
            userProfile={profile}
            strictCheck={true}
            component={PublicProfile}
            menuModule={menuList.companyfeeduserprofile}
          />
        </>
      )}
    </ProfileContext.Consumer>
  );
};
interface PrivateRouteProps {
  userProfile: UserProfileModel;
  component: React.ReactType;
  menuModule: MenuItem;
  strictCheck: boolean;
}

function PrivateRoute(rest: PrivateRouteProps) {
  // REF: https://reacttraining.com/react-router/web/example/route-config
  return (
    <Route
      exact
      path={rest.menuModule.to}
      render={(routeProps) => {
        document.title = `TSLOS :: ${rest.menuModule.label}`;
        return <Outerclass routeProps={routeProps} {...rest} />;
      }}
    />
  );
}
