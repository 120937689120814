import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { PostModel, UserDetails } from '../../Model/PostModel'
import CompanyFeedService from '../../Service/CompanyFeedService'
import ProfileName from './ProfileName'
import ProfilePicture from './ProfilePicture'

export default function NumberOfLikes({ item }: { item: PostModel }) {
    const [isShowDialog, setIsShowDialog] = useState(false)
    const [likedUsers, setLikedUsers] = useState<UserDetails[] | null>(null)
    const history = useHistory();


    async function showUsers(likedArray: string[] | null) {
        const companyfeedSvc = new CompanyFeedService()
        if (likedArray) {
            console.log('sending over likedArray', likedArray)
            const results = await companyfeedSvc.getLikedUsers(likedArray)
            console.log(results)
            setLikedUsers(results)
            setIsShowDialog(true)
        }
    }

    function hideDialog() {
        setIsShowDialog(false)
    }

    const header = <div className="liked-users-header">Users Who Liked</div>

    function goToProfile(item: PostModel | UserDetails) {
        history.push(`/publicprofile/${item.userId}`, {userId: item.userId}); // navigate to route
    }

    if (item.usersWhoLikedArray && item.usersWhoLikedArray.length > 0) {
        return (
            <div>
                <div className="number-of-likes" onClick={() => showUsers(item.usersWhoLikedArray)}>
                    {item.usersWhoLikedArray.length}
                </div>
                <Dialog
                    header={header}
                    closable={true}
                    dismissableMask={true}
                    visible={isShowDialog}
                    footer={<Button label="Okay" icon="pi pi-check" onClick={hideDialog} autoFocus />}
                    onHide={() => hideDialog()}
                    className="rounded-border liked-users-dialog"
                >
                    {likedUsers?.map(user => {
                        return (
                            <div key={user.userId} className="flex-row each-liked-user">
                                <div onClick={() => goToProfile(user)}><ProfilePicture displayPicture={user.displayPicture}/></div>
                                <div onClick={() => goToProfile(user)} className="profile-name"><ProfileName item={user} /></div>
                            </div>
                        )
                    })}
                </Dialog>
            </div>

        )
    }
    return null
}
