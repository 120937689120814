import React, { useEffect, useState } from "react";
import _ from "lodash";
import { CommonMethods, DropdownModel, RouteComponentProps } from "../resources/CommonMethods";
import { PayslipService } from "../../service/PayslipService";
import { ToastStateContext } from "../resources/ToastContext";
import { PayItemCategory, PayItemMeta, PayItemModel, PayslipInfo, payslipmeta, PayslipModel, PayslipObjAndInfoModel, UpdateLog, BankNameModel } from "../resources/ExportClass";
import Axios from "axios";
import { Prompt, Redirect } from "react-router";
import CustomError from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import NumberFormat from "react-number-format";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";

function PayslipCreateSpecialFunctional(props: RouteComponentProps<any>) {
    // Declare Constants & States
    const { SetSuccess, SetError, SetWarn } = React.useContext(ToastStateContext);
    const payslipService = new PayslipService();
    const commonMethods = new CommonMethods();
    const logoCSS: React.CSSProperties = {
        // width: "70%",
        overflow: "hidden",
        textAlign: "center",
    };

    const [payrollID, setPayrollID] = useState<string>(props.match.params.id);
    const [payslipInfo, setPayslipInfo] = useState<PayslipInfo>(new PayslipInfo());
    const [payslipObj, setPayslipObj] = useState<PayslipModel>(new PayslipModel());
    const [updateLog, setUpdateLog] = useState<Array<UpdateLog>>(new Array<UpdateLog>());
    const [nationalityDropdownList, setNationalityDropdownList] = useState<Array<DropdownModel>>(new Array<DropdownModel>());
    const [bankNameList, setBankNameList] = useState<Array<BankNameModel>>(new Array<BankNameModel>());
    const [swiftList, setSwiftList] = useState<Array<DropdownModel>>(new Array<DropdownModel>());
    const [userDropdown, setUserDropdown] = useState<Array<DropdownModel>>(new Array<DropdownModel>());
    const [payItemDropdown, setPayItemDropdown] = useState<Array<DropdownModel>>(new Array<DropdownModel>());
    const [payItemDropdownFullObj, setPayItemDropdownFullObj] = useState<Array<PayItemModel>>(new Array<PayItemModel>());

    const [payItemListAfterFetch, setPayItemListAfterFetch] = useState<boolean>(false);
    const [payItemList, setPayItemList] = useState<Array<PayItemMeta>>(new Array<PayItemMeta>());

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");

    const [redirectOut, setRedirectOut] = useState<boolean>(false);
    const [redirectPath, setRedirectPath] = useState<string>("");

    const [preparedBy, setPreparedBy] = useState<string>("System");

    const legalStatusDropdownList = commonMethods.getLegalStatusDropdownList();


    // UseEffects
    useEffect(() => {
        setupCalls(props);
    }, []);

    useEffect(() => {
        if (!payItemListAfterFetch && (payslipObj.additionalWage.length > 0 || payslipObj.deductables.length > 0)) {
            console.log("uwu");
            setPayItemList(payslipObj.additionalWage.concat(payslipObj.deductables));
            setPayItemListAfterFetch(true); // To prevent infinite render loop
            setIsFetching(false);
        }
    }, [payslipObj]);


    // Functions
    const camelCase = (text: string) => {
        return text.substr(0, 1).toUpperCase() + text.substr(1);
    }

    const getIndex = (haystack: Array<payslipmeta>, needle: string) => {
        return haystack.map((o) => o.meta_key).indexOf(needle);
    }

    const formatMoneyDisplay = (amount: number) => {
        return (
            // NumberFormat doesn't render properly for number values(?) Renders properly for string values (Deductions)
            // <NumberFormat
            //   displayType="text"
            //   value={amount}
            //   thousandSeparator={true}
            //   defaultValue={amount}
            //   prefix={"$"}
            //   decimalScale={2}
            //   fixedDecimalScale={true}
            // />
            "$" + amount.toFixed(2)
        );
    }

    const setupCalls = (passedProps: RouteComponentProps<any>) => {
        Axios.all([
            commonMethods.getBankCodeDropdown(), // Bank Code
            commonMethods.getNationalityDropdown(), // country
            commonMethods.getBankNames(), // Bank Names + Short Names
        ])
            .then(
                Axios.spread((first, second, third) => {
                    setSwiftList(first);
                    setNationalityDropdownList(second);
                    setBankNameList(third)
                    setIsLoading(false);
                })
            )
            .catch((error) => {
                let msg = commonMethods.getErrorMessage(error);
                setIsError(true);
                setErrorMsg(msg);
                setIsLoading(false);
                return msg;
            });

        // user dropdown
        payslipService
            .getUsersNotInCurrentPayrollByCompany(payrollID)
            .then((res) => {
                setUserDropdown(res);
            });

        // Pay item dropdowns
        payslipService
            .getPayItemTypeDropdown()
            .then((res) => {
                setPayItemDropdown(res);
            })
            .catch((err) => {
                let msg = commonMethods.getErrorMessage(err);
                setErrorMsg(msg);
                setIsLoading(false);
                setIsError(true);
            });
        payslipService
            .getAllPayItemTypes()
            .then((res) => {
                setPayItemDropdownFullObj(res);
            })
            .catch((err) => {
                let msg = commonMethods.getErrorMessage(err);
                setErrorMsg(msg);
                setIsLoading(false);
                setIsError(true);
            });

        commonMethods
            .getFullDisplayNameFromProvidedUserID(props.userProfile.userID)
            .then((res) => {
                setPreparedBy(res);
            })
            .catch((err) => {
                let msg = commonMethods.getErrorMessage(err);
                setErrorMsg(msg);
                setIsLoading(false);
                setIsError(true);
            });
    }

    const getUserPayslipRequiredDetails = (userID: string) => {
        payslipService
            .getNewPayslipToUpdateByUserId(payrollID, userID)
            .then((res: PayslipObjAndInfoModel) => {
                setPayslipInfo(res.payslipInfo);
                setPayslipObj({ ...res.payslipModel, remarks: "" });
                setUpdateLog(res.updateLog);
                setIsLoading(false);
                setIsFetching(false);
            })
            .catch((err) => {
                let msg = commonMethods.getErrorMessage(err);
                setIsError(true);
                setErrorMsg(msg);
                setIsLoading(false);
            });
    }

    const addField = () => {
        setPayItemList([...payItemList, new PayItemMeta()]);
        setIsEdited(true);
    };

    const removeField = (index: number) => {
        payItemList.splice(index, 1);
    };

    const addNewPayItemID = (e: {
        originalEvent: Event;
        target: any;
        value: any;
    }) => {
        if (payItemList.length <= e.target.id) {
            let newItem = new PayItemMeta();
            newItem.meta_key = e.value;
            payItemList.push(newItem);
        } else {
            payItemList[e.target.id].meta_key = e.value;
        }
    };

    const addNewPayItemAmount = (amount: string, index: number) => {
        if (payItemList.length <= index) {
            let newItem = new PayItemMeta();
            newItem.meta_value = amount;
            payItemList.push(newItem);
        } else {
            payItemList[index].meta_value = amount;
        }
    };
    const splitAddandDeduct = () => {
        let add: Array<PayItemMeta> = new Array<PayItemMeta>();
        let deduct: Array<PayItemMeta> = new Array<PayItemMeta>();
        let error = false;

        if (payItemList.length > 0) {
            for (const payItem of payItemList) {
                if (payItem.meta_value === "0" || payItem.meta_key === "") {
                    SetError("Please fill up both Pay Item and Amount.");
                    error = true;
                    return { add, deduct, error };
                }
                const payItemFound = _.find(payItemDropdownFullObj, [
                    "payItemID",
                    payItem.meta_key,
                ]);

                if (payItemFound?.category === PayItemCategory.Add.toString()) {
                    add.push({
                        meta_key: payItemFound.payItemID,
                        meta_value: payItem.meta_value,
                    });
                } else if (payItemFound?.category === PayItemCategory.Deduct) {
                    deduct.push({
                        meta_key: payItemFound.payItemID,
                        meta_value: payItem.meta_value,
                    });
                }
            }
        }
        return { add, deduct, error };
    };
    const recalculate = () => {
        console.log("recalculate");
        const addAndDeductObj = splitAddandDeduct();
        if (!addAndDeductObj.error) {
            const thisPayslip: PayslipModel = payslipObj;
            thisPayslip.additionalWage = addAndDeductObj.add;
            thisPayslip.deductables = addAndDeductObj.deduct;

            payslipService.updatePayslipCalculations(
                thisPayslip,
                payslipInfo
            )
                .then((res: PayslipObjAndInfoModel) => {
                    setPayslipObj(res.payslipModel);
                    setPayslipInfo(res.payslipInfo);
                    setIsEdited(true);
                });
        }
    };

    const savePayslip = () => {
        if (payslipObj.remarks === "") {
            SetError("Remarks is required.");
            return;
        }

        if (isEdited) {
            payslipService
                .manualUpdatePayslip(payslipObj, payslipInfo)
                .then((res) => {
                    res.payslipModel.remarks = "";
                    setPayslipObj(res.payslipModel);
                    setUpdateLog(res.updateLog);
                    setIsEdited(false);

                    SetSuccess("Payslip saved! Ready for export.");
                })
                .catch((err) => {
                    let errMsg = commonMethods.getErrorMessage(err);
                    SetError(errMsg);
                });
        } else {
            SetWarn("Click Calculate before you save.");
        }
    }

    // Output display for finance
    if (redirectOut) {
        return (
            <Redirect
                push
                to={{
                    pathname: redirectPath,
                }}
            />
        );
    }

    console.log(swiftList);
    return (<>
        {/* <Button
          onClick={this.goBack}
          label="Back"
          className="p-button-secondary"
        /> */}
        <div className="row">
            <Prompt
                when={isEdited}
                message={"There are unsaved changes. Are you sure you want to leave?"}
            />
            <div className="col-12 col-xl-7">
                <div className="p-grid p-2"></div>
                <div className="card">
                    <h1 className="pb-2">Payslip Preview</h1>
                    {isError ? <CustomError message={errorMsg} /> : (// or some growl/message that stays
                        isLoading ? <ProgressSpinner /> : (
                            <div className="p-flex">
                                <div className="p-grid p-justify-center pt-3 pb-3 mb-3">
                                    <div className="col-12">
                                        {/* INFORMATION */}
                                        <div className="p-col-12 text-center mb-3">
                                            <div className="p-grid p-justify-center">
                                                {/* Company Information */}
                                                <div
                                                    className="p-col-12"
                                                    style={{ borderBottom: "2px solid #DFE9F2" }}
                                                >
                                                    <div className="p-grid p-fluid p-justify-between">
                                                        <div className="p-col-5 outerCenterWrap">
                                                            <div style={logoCSS}>
                                                                <img
                                                                    src={payslipInfo.payslipLogo}
                                                                    alt="company payslip logo"
                                                                    className="payslip-pic"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="p-col-4 p-col-align-end text-left">
                                                            <b>{payslipInfo.companyName}</b>

                                                            <p className="mb-0">
                                                                <span className="boldLabel">UEN:</span>
                                                                {payslipInfo.companyRegNum}{" "}
                                                            </p>
                                                            <p className="mb-0">{payslipInfo.companyAddress}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* // Company Information */}

                                                {/* Employee Information */}
                                                <div className="p-col-11 text-left p-justify-center">
                                                    <div className="p-grid p-fluid">
                                                        <div className="p-col-8">
                                                            <div className="p-col-12">
                                                                Employee Name
              <br />
                                                                <span className="font-weight-bold">{payslipInfo.fullName}</span>
                                                            </div>
                                                            <div className="p-col-12 p-grid pb-0 pt-0">
                                                                <div className="p-col-4">D.O.B:</div>
                                                                <div className="p-col-8 font-weight-bold">
                                                                    {commonMethods.displayDate_Words(payslipInfo.dateOfBirth)}
                                                                </div>
                                                            </div>
                                                            <div className="p-col-12 p-grid pb-0 pt-0">
                                                                <div className="p-col-4">Tax Ref/Fin No.:</div>
                                                                <div className="p-col-8 font-weight-bold">
                                                                    {payslipInfo.identificationNumber}
                                                                </div>
                                                            </div>
                                                            <div className="p-col-12 p-grid pb-0 pt-0">
                                                                <div className="p-col-4">Age</div>
                                                                <div className="p-col-8 font-weight-bold">
                                                                    {commonMethods.calculateAge(payslipInfo.dateOfBirth)}
                                                                </div>
                                                            </div>
                                                            <div className="p-col-12 p-grid pb-0 pt-0">
                                                                <div className="p-col-4">Currency</div>
                                                                <div className="p-col-8 font-weight-bold">
                                                                    {payslipObj.currency}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-4 p-col-align-center">
                                                            <b style={{ fontSize: "14pt" }}>Pay Advice For</b> <br />
                                                            <b>
                                                                {commonMethods.displayMonthYear_Full(
                                                                    payslipObj.paymentDate
                                                                )}
                                                            </b>
                                                            <div className="p-col-12">
                                                                A/C No.: {"    "}
                                                                {payslipInfo.bank_ShortName + payslipInfo.bank_Number}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* // Employee Information */}
                                            </div>
                                        </div>
                                        {/* // INFORMATION  */}
                                        {/* PAYSLIP DETAILS */}
                                        <div className="p-col-12 text-center border mb-3">
                                            <div className="p-grid p-fluid p-align-start">
                                                {/* Wage Earnings Information */}
                                                <div className="p-col-6 border-0">
                                                    <div className="p-grid p-fluid">
                                                        <div className="p-col-12 px-2 payslipSections">
                                                            <span className="font-weight-bold">Earnings</span>
                                                        </div>
                                                        <div className="p-col-6">
                                                            <b>Basic Pay</b>
                                                        </div>
                                                        <div className="p-col-6">
                                                            {formatMoneyDisplay(payslipObj.basicSalary)}
                                                        </div>
                                                        <div className="p-col-6">
                                                            <b>Work Days</b>
                                                        </div>
                                                        <div className="p-col-6">
                                                            {payslipObj.workDays}
                                                            {" day(s)"}
                                                        </div>
                                                        <div className="p-col-6">
                                                            <b>Unpaid Leaves</b>
                                                        </div>
                                                        <div className="p-col-6">
                                                            {payslipObj.unpaidLeaveDays}
                                                            {" day(s)"}
                                                        </div>
                                                        <div className="p-col-6">
                                                            <b>Ordinary Wage</b>
                                                        </div>
                                                        <div className="p-col-6 border-top">
                                                            {formatMoneyDisplay(payslipObj.ordinaryWage)}
                                                        </div>

                                                        <div className="p-col-12 px-2 payslipSections">
                                                            <span className="font-weight-bold">Additional Wages</span>
                                                        </div>
                                                        {payslipObj.additionalWageDisplay.map((value, index) => {
                                                            return (
                                                                <div className="p-col-12 p-grid" key={index}>
                                                                    <div className="p-col-6">
                                                                        <b>{camelCase(value.meta_key)}</b>
                                                                    </div>
                                                                    <div className="p-col-6">
                                                                        <NumberFormat
                                                                            displayType="text"
                                                                            value={value.meta_value}
                                                                            thousandSeparator={true}
                                                                            prefix={"$"}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                {/* // Wage Earnings Information */}

                                                {/* Wage Deduction Information */}
                                                <div className="p-col-6 border-0">
                                                    <div className="p-grid p-fluid">
                                                        <div className="p-col-12 px-2 payslipSections">
                                                            <span className="font-weight-bold">Deductions</span>
                                                        </div>
                                                        {getIndex(payslipObj.contributions, "Employee Contribution") > 0 ?
                                                            <div className="p-col-12 p-grid" key="employee_contribution">
                                                                <div className="p-col-6">
                                                                    <b>
                                                                        {
                                                                            payslipObj.contributions[
                                                                                getIndex(payslipObj.contributions, "Employee Contribution")
                                                                            ].meta_key
                                                                        }
                                                                    </b>
                                                                </div>
                                                                <div className="p-col-6">
                                                                    <NumberFormat
                                                                        displayType="text"
                                                                        value={
                                                                            payslipObj.contributions[
                                                                                getIndex(payslipObj.contributions, "Employee Contribution")
                                                                            ].meta_value
                                                                        }
                                                                        thousandSeparator={true}
                                                                        prefix={"$"}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            : <></>
                                                        }
                                                        {payslipObj.donation.map((obj, i) => {
                                                            return (
                                                                <div className="p-col-12 p-grid" key={i}>
                                                                    <div className="p-col-6">
                                                                        <b>{obj.meta_key}</b>
                                                                    </div>
                                                                    <div className="p-col-6">
                                                                        <NumberFormat
                                                                            displayType="text"
                                                                            value={obj.meta_value}
                                                                            thousandSeparator={true}
                                                                            prefix={"$"}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        {payslipObj.deductablesDisplay.map((value, index) => {
                                                            return (
                                                                <div className="p-col-12 p-grid" key={index}>
                                                                    <div className="p-col-6">
                                                                        <b>{camelCase(value.meta_key)}</b>
                                                                    </div>
                                                                    <div className="p-col-6">
                                                                        <NumberFormat
                                                                            displayType="text"
                                                                            value={value.meta_value}
                                                                            thousandSeparator={true}
                                                                            prefix={"$"}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                {/* // Wage Deduction Information */}

                                                {/* Gross and Net Pay */}
                                                <div className="p-col-12 border-0">
                                                    <div className="p-grid p-fluid">
                                                        <div className="p-col-3 payslipSections">
                                                            <b>Gross Pay</b>
                                                        </div>
                                                        <div className="p-col-3 border-top border-bottom">
                                                            {formatMoneyDisplay(payslipObj.grossSalary)}
                                                        </div>
                                                        <div className="p-col-3 payslipSections">
                                                            <b>Net Pay</b>
                                                        </div>
                                                        <div className="p-col-3 border-top border-bottom">
                                                            {formatMoneyDisplay(payslipObj.netSalary)}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* // Gross and Net Pay */}
                                            </div>
                                        </div>
                                        {/* // PAYSLIP DETAILS */}
                                        <div className="p-col-12 text-center border mb-3">
                                            <div className="row">
                                                <div className="col-12 col-xl-6">
                                                    <div className="col-12 text-left pb-2">
                                                        <span
                                                            className="pb-3 font-weight-bold"
                                                            style={{ color: "#4E79AD" }}
                                                        >
                                                            Employer Contributions
              </span>
                                                    </div>
                                                    <div className="row">
                                                        <div className="p-col-6">
                                                            <b>Employer CPF</b>
                                                        </div>
                                                        <div className="p-col-6">
                                                            {getIndex(
                                                                payslipObj.contributions,
                                                                "Employer Contribution"
                                                            ) > 0
                                                                ? formatMoneyDisplay(
                                                                    parseFloat(
                                                                        payslipObj.contributions[
                                                                            getIndex(
                                                                                payslipObj.contributions,
                                                                                "Employer Contribution"
                                                                            )
                                                                        ].meta_value
                                                                    )
                                                                )
                                                                : "-"}

                                                            {/* <NumberFormat
                  displayType="text"
                  value={
                    this.getIndex(
                      this.state.payslipObj.contributions,
                      "Employer Contribution"
                    ) > 0
                      ? this.state.payslipObj.contributions[
                          this.getIndex(
                            this.state.payslipObj.contributions,
                            "Employer Contribution"
                          )
                        ].meta_value
                      : "-"
                  }
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                /> */}
                                                        </div>

                                                        <div className="p-col-6">
                                                            <b>SDF (0.25%)</b>
                                                        </div>
                                                        <div className="p-col-6">
                                                            {formatMoneyDisplay(payslipObj.sdf)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-xl-6">
                                                    <div className="col-12 text-left pb-2"> &nbsp; </div>

                                                    <div className="row">
                                                        <div className="p-col-6 font-weight-bold">Pay Period</div>
                                                        <div className="p-col-6">
                                                            {commonMethods.formatDateDisplayPayPeriod(
                                                                payslipObj.payStartDate,
                                                                payslipObj.payEndDate
                                                            )}
                                                        </div>

                                                        <div className="p-col-6 font-weight-bold">Pay Day</div>
                                                        <div className="p-col-6">
                                                            {commonMethods.formatDatePayDay(
                                                                payslipObj.paymentDate
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* YTD (YearToDate) Earnings */}
                                        <div className="p-col-12 text-center border mb-3">
                                            <div className="row">
                                                <div className="col-12 col-xl-6">
                                                    <div className="col-12 text-left pb-2">
                                                        <span
                                                            className="pb-3 font-weight-bold"
                                                            style={{ color: "#4E79AD" }}
                                                        >
                                                            Year to Date Earnings
              </span>
                                                    </div>
                                                    <div className="row">
                                                        <div className="p-col-6">
                                                            <b>Total Salary</b>
                                                        </div>
                                                        {/* <div className="p-col-6">-</div> */}

                                                        <div className="p-col-6">
                                                            {formatMoneyDisplay(payslipObj.ytdPayout)}
                                                        </div>

                                                        {/* <div className="p-col-6">
                <b>Bonus Entitlement</b>
              </div>
              <div className="p-col-6">-</div> */}

                                                        {/* <div className="p-col-6">{ytdMonths} Month(s)</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* // YTD (YearToDate) Earnings */}
                                        <div className="p-col-3 p-col-offset-9 p-offset-9 text-center">
                                            <p className="mb-0">Prepared by</p>
                                            <p className="font-weight-bold">{preparedBy}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                    )}
                </div>
            </div>
            <div className="col-12 col-xl-5">
                <div className="p-grid p-2"></div>
                <div className="card">
                    <h1 className="pb-2">New Payslip</h1>
                    <div className="p-col-12">
                        <div className="p-grid p-fluid">
                            <h5 className="p-col-12">1. Select Employee</h5>
                            <div className="p-col-12">
                                <span className="boldLabel">Employees (not included in current payroll)</span>
                                <Dropdown
                                    value={payslipObj.userID}
                                    options={userDropdown}
                                    onChange={(e) => {
                                        setPayslipObj({ ...payslipObj, remarks: e.value });
                                        getUserPayslipRequiredDetails(e.value);
                                    }}
                                    placeholder="Please Select"
                                />
                            </div>

                            <h5 className="p-col-12 mt-2">2. Update Employee Information</h5>

                            <div className="p-col-6">
                                <span className="boldLabel">First Name</span>
                                <InputText
                                    value={payslipInfo.firstName}
                                    onChange={(e) => setPayslipInfo({ ...payslipInfo, firstName: e.currentTarget.value })}
                                />
                            </div>
                            <div className="p-col-6">
                                <span className="boldLabel">Last Name</span>
                                <InputText
                                    value={payslipInfo.lastName}
                                    onChange={(e) => setPayslipInfo({ ...payslipInfo, lastName: e.currentTarget.value })
                                    }
                                />
                            </div>
                            <div className="p-col-12">
                                <span className="boldLabel">Email</span>
                                <InputText
                                    value={payslipInfo.email}
                                    onChange={(e) => setPayslipInfo({ ...payslipInfo, email: e.currentTarget.value })
                                    }
                                />
                            </div>
                            <div className="p-col-12">
                                <span className="boldLabel">Date of Birth</span>{" "}
                                <span className="requiredRed">*</span>
                                <Calendar
                                    dateFormat="dd/mm/yy"
                                    value={new Date(payslipInfo.dateOfBirth)}
                                    onChange={(e) => { setPayslipInfo({ ...payslipInfo, dateOfBirth: Array.isArray(e) ? e[0].value : e.value }) }}
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange="1965:2030"
                                    showIcon={true}
                                    required
                                />
                            </div>
                            <div className="p-col-12">
                                <span className="boldLabel">NRIC / Fin Number</span>
                                <span className="requiredRed">*</span>
                                <InputText
                                    value={payslipInfo.identificationNumber}
                                    onChange={(e) => setPayslipInfo({ ...payslipInfo, identificationNumber: e.currentTarget.value })}
                                />
                            </div>

                            <div className="p-col-12">
                                <span className="boldLabel">
                                    Nationality <span style={{ color: "red" }}>*</span>
                                </span>
                                <Dropdown
                                    value={payslipInfo.nationality}
                                    options={nationalityDropdownList}
                                    onChange={(e) => { setPayslipInfo({ ...payslipInfo, nationality: e.value }) }}
                                    placeholder="Please Select"
                                    required
                                />
                            </div>

                            <div className="p-col-12">
                                <span className="boldLabel">
                                    Citizenship <span style={{ color: "red" }}>*</span>
                                </span>
                                <Dropdown
                                    value={payslipInfo.legalStatus}
                                    options={legalStatusDropdownList}
                                    onChange={(e) => setPayslipInfo({ ...payslipInfo, legalStatus: e.value })}
                                    placeholder="Please Select"
                                    required
                                />
                            </div>
                            {payslipInfo.legalStatus === "PR" ?
                                <div className="p-col-12">
                                    <span className="boldLabel">PR Date Obtained</span>
                                    <Calendar
                                        dateFormat="dd/mm/yy"
                                        value={new Date(payslipInfo.prObtainedDate)}
                                        onChange={(e) => setPayslipInfo({ ...payslipInfo, prObtainedDate: new Date(e.value.toString()) })}
                                        showButtonBar={true}
                                        showIcon={true}
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        yearRange="1900:2030"
                                        showTime={false}
                                        readOnlyInput={true}
                                    />
                                </div> : <></>}
                            <h5 className="p-col-12 mt-2">3. Update Bank Details</h5>

                            <div className="p-col-12">
                                <span className="boldLabel">Bank Name</span>
                                <Dropdown
                                    value={payslipInfo.bank_Code}
                                    options={swiftList}
                                    onChange={(e) => {
                                        setPayslipInfo({
                                            ...payslipInfo, 
                                            bank_Code: e.value,
                                            bank_Name: swiftList[
                                                swiftList.findIndex(
                                                    (obj) => obj.value === e.value
                                                )
                                            ].label,
                                            bank_ShortName: bankNameList[
                                                bankNameList.findIndex(
                                                    (obj) => obj.bankName === swiftList[
                                                        swiftList.findIndex(
                                                            (obj) => obj.value === e.value
                                                        )
                                                    ].label
                                                )
                                            ].bankShortName,
                                        })
                                    }}
                                    placeholder="Please Select"
                                />
                            </div>
                            <div className="p-col-12">
                                <span className="boldLabel">Account Type</span>
                                <InputText
                                    value={payslipInfo.bank_AccountType}
                                    onChange={(e) => setPayslipInfo({ ...payslipInfo, bank_AccountType: e.currentTarget.value })}
                                />
                            </div>
                            <div className="p-col-12">
                                <span className="boldLabel">Account Name</span>
                                <InputText
                                    value={payslipInfo.bank_PayeeName}
                                    onChange={(e) => setPayslipInfo({ ...payslipInfo, bank_PayeeName: e.currentTarget.value })}
                                />
                            </div>
                            <div className="p-col-12">
                                <span className="boldLabel">Account Number</span>
                                <span className="requiredRed">*</span>
                                <InputText
                                    value={payslipInfo.bank_Number}
                                    onChange={(e) => setPayslipInfo({ ...payslipInfo, bank_Number: e.currentTarget.value })}
                                />
                            </div>
                            {/* END Bank Details */}

                            <h5 className="p-col-12 mt-2">4. Update Payslip Details</h5>
                            {/* CPF , SHGG , SDL Contributions */}

                            <div className="p-col-12">
                                <span className="boldLabel pr-3">CPF Eligible</span>
                                <Checkbox
                                    onChange={(e) =>
                                        setPayslipInfo({
                                            ...payslipInfo,
                                            cpfEligible: e.checked,
                                            sdlEligible: e.checked
                                        })}
                                    checked={payslipInfo.cpfEligible}
                                    inputId="cpfEligible"
                                />
                            </div>

                            <div className="p-col-12">
                                <span className="boldLabel pr-3">SHG Eligible</span>
                                <Checkbox
                                    onChange={(e) =>
                                        setPayslipInfo({
                                            ...payslipInfo,
                                            shgEligible: e.checked,
                                            shgGroup:
                                                e.checked === false
                                                    ? ""
                                                    : payslipInfo.shgGroup
                                        })
                                    }
                                    checked={payslipInfo.shgEligible}
                                    inputId="shgEligible"
                                />
                            </div>
                            <div className="p-col-12">
                                <span className="boldLabel">
                                    Self Help Group <span style={{ color: "red" }}>*</span>
                                </span>
                                <Dropdown
                                    value={payslipInfo.shgGroup}
                                    options={commonMethods.getSHGDropdownOptions()}
                                    onChange={(e) => setPayslipInfo({ ...payslipInfo, shgGroup: e.value })}
                                    placeholder="Please Select"
                                    required
                                    disabled={!payslipInfo.shgEligible}
                                />
                            </div>
                            {/* END CPF , SHGG , SDL Contributions */}

                            {/* Contract Details */}
                            <div className="p-col-6">
                                <span className="boldLabel">Basic Pay</span>
                                <span className="requiredRed">*</span>

                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">$</span>
                                    <NumberFormat
                                        className="p-inputtext"
                                        value={payslipObj.basicSalary}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        onValueChange={(values) => { setPayslipObj({ ...payslipObj, basicSalary: values.floatValue! }) }}
                                    />
                                </div>
                            </div>

                            <div className="p-col-6">
                                <span className="boldLabel">
                                    Total Days worked (1 day = 8 hours)
                </span>
                                <span className="requiredRed">*</span>

                                <InputText
                                    value={payslipObj.workDays}
                                    keyfilter="pint"
                                    onChange={(e) => setPayslipObj({ ...payslipObj, workDays: Number.parseInt(e.currentTarget.value) })}
                                />
                            </div>
                            <div className="p-col-6">
                                <span className="boldLabel">Start Date</span>
                                <span className="requiredRed">*</span>
                                <Calendar
                                    dateFormat="dd/mm/yy"
                                    value={new Date(payslipObj.payStartDate)}
                                    onChange={(e) => setPayslipObj({ ...payslipObj, payStartDate: Array.isArray(e) ? e[0].value : e.value })}
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange="1965:2030"
                                    showIcon={true}
                                    required
                                />
                            </div>
                            <div className="p-col-6">
                                <span className="boldLabel">End Date</span>
                                <span className="requiredRed">*</span>
                                <Calendar
                                    dateFormat="dd/mm/yy"
                                    value={new Date(payslipObj.payEndDate)}
                                    onChange={(e) => setPayslipObj({ ...payslipObj, payEndDate: Array.isArray(e) ? e[0].value : e.value })}
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange="1965:2030"
                                    showIcon={true}
                                    required
                                />
                            </div>
                            <div className="p-col-12">
                                <span className="boldLabel">Payment Date</span>
                                <span className="requiredRed">*</span>
                                <Calendar
                                    dateFormat="dd/mm/yy"
                                    value={new Date(payslipObj.paymentDate)}
                                    onChange={(e) => setPayslipObj({ ...payslipObj, paymentDate: Array.isArray(e) ? e[0].value : e.value })}
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange="1965:2030"
                                    showIcon={true}
                                    required
                                />
                            </div>

                            {/* END Contract Details*/}
                            <h5 className="p-col-12">
                                1. Add Pay Items
            <Button
                                    className="p-button-info ml-2"
                                    onClick={addField}
                                    label="Add"
                                />
                            </h5>

                            {/* Additional Wages and Deductions */}
                            <div className="p-col-12 mb-3">
                                <div className="p-grid p-fluid">
                                    {payItemList.map((value, index) => {
                                        return (
                                            <div className="p-col-12 p-grid p-justify-center" key={index}>
                                                <div className="p-col-7">
                                                    <span className="boldLabel">Pay Item</span>

                                                    <Dropdown
                                                        options={payItemDropdown}
                                                        placeholder="Please Select"
                                                        id={index + ""}
                                                        value={payItemList[index].meta_key}
                                                        onChange={(e) => addNewPayItemID(e)}
                                                    />
                                                </div>

                                                <div className="p-col-4">
                                                    <span className="boldLabel">Amount</span>
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">$</span>
                                                        <NumberFormat
                                                            className="p-inputtext"
                                                            value={payItemList[index]?.meta_value}
                                                            thousandSeparator={true}
                                                            id={`${index}-amount`}
                                                            // prefix={"$"}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            onValueChange={(e) => {
                                                                addNewPayItemAmount(e.value, index);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="p-col-1 p-col-align-end">
                                                    <Button
                                                        icon="pi pi-times"
                                                        className="p-button-danger"
                                                        onClick={(e) => {
                                                            removeField(index);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="text-center pt-4">
                                    <span>
                                        Click to preview pay items on payslip with recalculated amounts.
              </span>
                                </div>
                                <div className="text-center pt-2">
                                    <Button
                                        className="p-button"
                                        onClick={recalculate}
                                        label="Calculate"
                                    />
                                </div>
                            </div>

                            <div className="p-col-12">
                                <h5>5. Save your changes</h5>

                                <div className="p-grid p-fluid">
                                    <div className="p-col-12">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <span className="boldLabel">Remarks</span>{" "}
                                                <span className="requiredRed">*</span>
                                                <InputTextarea
                                                    value={payslipObj.remarks}
                                                    onChange={(e: any) => {
                                                        setPayslipObj({ ...payslipObj, remarks: e.target.value });
                                                        setIsEdited(true);
                                                    }}
                                                    autoResize={true}
                                                    required
                                                />
                                            </div>{" "}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center py-2">
                                    <Button
                                        className="p-button-info ml-2"
                                        onClick={savePayslip}
                                        label="Save"
                                    />
                                    {/* <Button
                className="p-button-secondary"
                onClick={this.goToExport}
                label="Export"
                disabled={this.state.updateLog.length === 0 ? true : false}
              /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="p-grid p-justify-center pb-5">
            <div>
              <Button
                className="p-button-info"
                onClick={recalculateEarningsCpf}
                label="Calculate"
              />{" "}
            </div>
          </div> */}
                    {/* END User & Contract Required */}
                </div>
            </div>
        </div>
    </>);
}

export default PayslipCreateSpecialFunctional;