import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { TaxFilingService } from "../../service/TaxFilingService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import {
  SubmissionOutputInformation,
  TaxFilingMethods,
  TaxSubmissionErrorModel,
  TaxSubmissionResults,
} from "./TaxFilingModel";

export default function SubmissionDetails(props: RouteComponentProps) {
  const commonMethods = new CommonMethods();
  const taxFilingService = new TaxFilingService();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(
    "An unidentified error has occurred"
  );

  const [results, setResults] = useState<TaxSubmissionResults>(
    new TaxSubmissionResults()
  );

  useEffect(() => {
    const taxsubmissionid = props.others.id;
    if (taxsubmissionid !== "") {
      taxFilingService
        .getSubmissionDetails(taxsubmissionid)
        .then((res) => {
          setResults(res);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg("Error occured.");
        });
    }
  }, [props.others.id]);

  const displayEachSubmission = () => {
    let subArr: any[] = [];
    let outertable = (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Submission</th>
              <th scope="col">Details</th>
            </tr>
          </thead>
          <tbody>{subArr}</tbody>
        </table>
      </div>
    );

    results.transactionList.forEach((transaction, index) => {
      let oneSub = (
        <tr key={transaction.taxSubmissionID + "-" + index}>
          <td>{index + 1}</td>
          <td>
            <span className="boldLabel">Outcome</span>
            <p>
              {transaction.statusCode} -{" "}
              {TaxFilingMethods.getStatusCodeDescription(
                transaction.statusCode
              )}
            </p>
            <span className="boldLabel">Output</span>
            <p>{displayOutput(transaction.output)}</p>
            <span className="boldLabel">Form Type</span>
            <p> {transaction.formType} </p>
            {/* <span className="boldLabel">Success Output</span>
            <p> {transaction.msgError} </p> */}
            <span className="boldLabel">Error Message</span>
            <p> {transaction.msgError} </p>
            <span className="boldLabel">Errors</span>
            <p> {displayError(transaction.errors, "Errors")}</p>
            <span className="boldLabel">Warnings</span>
            <p> {displayError(transaction.warnings, "warnings")}</p>
          </td>
        </tr>
      );
      subArr.push(oneSub);
    });

    return outertable;
  };

  const header = () => {
    return (
      <div className="col-12">
        <div className="row justify-content-around">
          <div className="col-12 col-md-4"></div>
          <div className="col-12 col-md-4 text-center">
            <span className="boldLabel">Income Year</span>
            <p>{results.taxHeader.incomeYear}</p>
            <span className="boldLabel">Company</span>
            <p>{results.taxHeader.organizationName}</p>
            <span className="boldLabel">Status</span>

            {results.isSuccessfulContent ? (
              <p>Success </p>
            ) : (
              <p className="requiredRed">
                {" "}
                Unsuccessful. <br></br> Please submit again after rectifying the
                errors accordingly
              </p>
            )}

            <span className="boldLabel">Submitted By</span>
            <p>{results.submittedByName}</p>
            <span className="boldLabel">Submitted On</span>
            <p>{commonMethods.displayDateTime(results.createdTime)}</p>
          </div>
          <div className="col-12 col-md-4"></div>
        </div>
      </div>
    );
  };

  const displayOutput = (output: SubmissionOutputInformation) => {
    if (output === null) {
      return "";
    }
    return (
      <>
        Submission Reference No. : {output.submissionReferenceNumber}
        <br></br>
        Submission Date Time : {output.submissonDateTime}
        <br></br>
        Organization Name : {output.organisationName}
        <br></br>
        Organization Tax Ref No. : {output.organisationReferenceNumber}
        <br></br>
        Year of Assessment : {output.basisYearAddOne}
        <br></br>
        File Type : {output.fileType}
        <br></br>
        No. of Records : {output.noofRecords}
        <br></br>
        User ID : {output.userID}
        <br></br>
      </>
    );
  };
  const displayError = (errList: TaxSubmissionErrorModel[], type: string) => {
    let displayErrList: any[] = [];
    errList.forEach((err, index) => {
      let oneErr = (
        <>
          Record Identifier: {err.recordIdentifier} - {err.fullName}
          <br></br>
          Record Field: {err.recordField}
          <br></br>
          Error:{err.error}
          <br></br>
        </>
      );

      displayErrList.push(
        <span key={type + "-" + index}>
          {oneErr} <br></br>
        </span>
      );
    });

    return displayErrList;
  };

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = (
      <>
        {header()}

        {displayEachSubmission()}
      </>
    );
  }
  return display;
}
