import { HttpClient } from "../../service/HttpClient";
import { CampaignModel } from "../campaign/Campaign";
import { DeliverableModel } from "../deliverable/DeliverablesInCampaign";

export class IrnDeliverableModel {
  public name: string = "";
  public deliverable_type: string = "";
  public campaign: string = "";
  public publisher: string = "";
  public facebook_link: string = "";
  public order: number = 0;
  public link: string = "";
  public campaignId: string = "";
  public irnDelId: string = "";
  public deliverableId: string = "";
}
export class InsightsrnMethods {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

  // IRN
  async getDeliverableTypes() {
    const res = await this.httpClient.get("/api/insightsrn");
    return res;
  }

  async createCampaign(campaignObj: CampaignModel) {
    const res = await this.httpClient.post(
      "/api/insightsrn/campaign",
      campaignObj
    );
    return res;
  }

  // async updateCampaign(campaignObj: CampaignModel, irnCamId: string) {
  //   let data = {
  //     irnCamId: irnCamId,
  //     created_by: "",
  //     name: campaignObj.campaignName,
  //     objective: campaignObj.campaignObjective,
  //     password: null,
  //     client: campaignObj.clientCompanyName,
  //     publisher: campaignObj.publisherID,
  //     sales_person: campaignObj.salesPersonsIDStrArr[0] // only take the first person's
  //   };

  //   const res = await this.httpClient.put("/api/insightsrn/campaign", data);
  //   return res;
  // }

  async createDeliverable(deliverableObjArr: DeliverableModel[]) {
   
    // let dataArr: any[] = [];

    // for (let i = 0; i < deliverableObjArr.length; i++) {
    //   let deliverableObj = deliverableObjArr[i];

    //   let data: IrnDeliverableModel = {
    //     name: deliverableObj.deliverableName,
    //     deliverable_type: this.parseDeliverableTypes(
    //       deliverableObj.deliverableTypeName
    //     ),
    //     campaign: "", //backend set
    //     publisher: deliverableObj.publisherName,
    //     facebook_link: deliverableObj.facebookLink,
    //     order: deliverableObj.reportOrder,
    //     link: deliverableObj.link,
    //     campaignId: deliverableObj.campaignID,
    //     irnDelId: deliverableObj.irnDelId,
    //     deliverableId: deliverableObj.deliverableID
    //   };

    //   let result = this.isValidObj(data);
    //   if (result.error) {
    //     return {
    //       error: deliverableObj.deliverableSerialNo + " - " + result.errorMsg
    //     };
    //   }
    //   dataArr.push(data);
    // }

    const res = await this.httpClient.post(
      "/api/insightsrn/deliverable",
      deliverableObjArr
    );
    return res;
  }

  // async getPublishers() {
  //   const res = await this.httpClient.get("/api/insightsrn/publishers");
  //   return res;
  // }

  // async getAgents() {
  //   const res = await this.httpClient.get("/api/insightsrn/agents");
  //   return res;
  // }

  // async getClients() {
  //   const res = await this.httpClient.get("/api/insightsrn/clients");
  //   return res;
  // }

  // async getCompanies() {
  //   const res = await this.httpClient.get("/api/insightsrn/companies");
  //   return res;
  // }

  // parseDeliverableTypes(deliverableName: string) {
  //   switch (deliverableName) {
  //     case "Sponsored Post":
  //       return "sponsored_post";

  //     case "Branded Content":
  //       return "branded_content";

  //     case "Youtube Video":
  //       return "youtube_video";

  //     case "Instagram Post":
  //       return "instagram_post";

  //     case "Instagram Story":
  //       return "instagram_story";

  //     case "Telegram Post":
  //       return "telegram_post";

  //     case "Facebook Post":
  //       return "facebook_post";

  //     case "Facebook Video Basic":
  //       return "facebook_video";

  //     case "Facebook Video Concept":
  //       return "facebook_video";

  //     default:
  //       return "";
  //   }
  // }

  isValidObj(data: IrnDeliverableModel) {
    let error = true;
    let errorMsg = "";

    if (
      (data.facebook_link !== "" && data.facebook_link !== null) &&
      !(
        data.facebook_link.includes("http://www.facebook.com") ||
        data.facebook_link.includes("https://www.facebook.com")
      )
    ) {
      errorMsg =
        'Link placed here should be a full Facebook link! e.g. "https://www.facebook.com/..."';
      return { error, errorMsg };
    }

    if (
      data.deliverable_type !== "instagram_story" &&
      data.deliverable_type !== "telegram_post"
    ) {
      if (
        data.link === "" ||
        !(data.link.includes("http://www") || data.link.includes("https://www"))
      ) {
        errorMsg = 'Link placed here must be a full link e.g. "http://www..."';
        return { error, errorMsg };
      }
    }

    // IG Post - ok
    if (data.deliverable_type === "instagram_post") {
      if (
        data.link !== "" &&
        !(
          data.link.includes("http://www.instagram.com") ||
          data.link.includes("https://www.instagram.com")
        )
      ) {
        errorMsg =
          'Link placed here should be a full Instagram link! e.g. "https://www.instagram.com/..."';
        return { error, errorMsg };
      }
      if (this.isLinkEmpty(data).error) {
        return { error, errorMsg };
      }
    }

    // Youtube video - ok
    if (data.deliverable_type === "youtube_video") {
      if (
        data.link !== "" &&
        !(
          data.link.includes("http://www.youtube.com") ||
          data.link.includes("https://www.youtube.com")
        )
      ) {
        errorMsg =
          'Link placed here should be a full Youtube link! e.g. "https://www.youtube.com/..."';
        return { error, errorMsg };
      }
      if (this.isLinkEmpty(data).error) {
        return { error, errorMsg };
      }
    }

    // Telegram post - ok
    if (data.deliverable_type === "telegram_post") {
      if (
        data.link !== "" &&
        !(
          data.link.includes("http://t.me") ||
          data.link.includes("https://t.me")
        )
      ) {
        errorMsg =
          'Link placed here should be a full Telegram link! e.g. "https://t.me/TSLMedia/504"';
        return { error, errorMsg };
      }
      if (data.link !== "" && data.link.split("/").length !== 4) {
        errorMsg =
          "Link should follow this format: https://t.me/(channel name)/(post number)";
        return { error, errorMsg };
      }
      if (this.isLinkEmpty(data).error) {
        return { error, errorMsg };
      }
    }

    // Facebook - missing fb photo
    if (
      data.deliverable_type === "facebook_post" ||
      data.deliverable_type === "facebook_video"
    ) {
      if (data.facebook_link === "") {
        errorMsg = "Facebook link cannot be empty for this Deliverable Type";
        return { error, errorMsg };
      }
      if (this.isLinkEmpty(data).error) {
        return { error, errorMsg };
      }
    }

    if (data.deliverable_type === "facebook_video") {
      if (data.link !== "" && !data.link.includes("video")) {
        errorMsg =
          'Link placed here should be a full Facebook Video link! e.g. "https://www.facebook.com/TheSmartLocal/videos/278001809689427"';
        return { error, errorMsg };
      }
    }

    // IG Story, IG TV not done yet

    error = false;
    return { error, errorMsg };
  }

  isLinkEmpty(data: IrnDeliverableModel) {
    let error = true;
    let errorMsg = "";
    if (data.link === "") {
      errorMsg = "Link cannot be empty for this Deliverable Type";
      return { error, errorMsg };
    }
    error = false;
    return { error, errorMsg };
  }
}
