export class BusinessCardModel {
	public displayMobile: boolean = true;
	public bgColour: string = "177147";
	public nameTitleColour: string = "FFFFFF";
	public contactColour: string = "000000";
	public socialMedia: SocialMediaModel[] = [];
}

export class SocialMediaModel {
	public name: string;
	public url: string = "";
	public icon: string;

	constructor(name: string) {
		this.name = name;
		this.icon = name[0].toUpperCase() + name.substring(1);
	}
}
