import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { PublisherService } from "../../service/PublisherService";
import {
  ActionTypes, CommonMethods, 
  ParamIDTypes, 
  RouteComponentProps 
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { PublisherModel } from "./PublisherModel";
import { RadioButton } from "primereact/radiobutton";


function PublisherCreate(props: RouteComponentProps<any>) {
  const publisherService = new PublisherService();
  const commonMethods = new CommonMethods();
  const { id } = useParams<ParamIDTypes>();
  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const [publisherData, setPublisherData] = useState<
    PublisherModel
  >(new PublisherModel());
  const [action, setAction] = useState<string>(ActionTypes.create);

  const [isError, setisError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [toHome, setToHome] = useState(false);
  
  useEffect(() => {
    if (id !== undefined) {
      publisherService
        .getPublisherByID(id)
        .then((res) => {
          setPublisherData(res);
          setIsLoading(false);
      })
      .catch((err) => {
        setisError(true);
        setErrorMessage(commonMethods.getErrorMessage(err));
      });
    
    setAction(ActionTypes.update);
   } else {
      // create
      setIsLoading(false);
    }
  },[]);

  const handleSubmit = (event:any) => {
    event.preventDefault();
    if (action === ActionTypes.create) {
      publisherService
        .createPublisher(publisherData)
        .then((res) => {
          SetSuccess("New Publisher created!");
          setToHome(true);
        })
        .catch((err) => {
          SetError(commonMethods.getErrorMessage(err));
        });
    } else if (action === ActionTypes.update){
        publisherService
          .updatePublisher(publisherData)
          .then((res) => {
            SetSuccess("Publisher updated!");
            setToHome(true);
          })
          .catch((err) => {
            SetError(commonMethods.getErrorMessage(err));
          });
    }
  };

  const form = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="p-grid p-fluid">
        < div className="p-col-12">
            <span className="boldLabel">
              Publisher Name<span className="requiredRed"> *</span>
            </span>
            <InputText
              value={publisherData.publisherName}
              onChange={(event) =>
                setPublisherData({
                  ...publisherData,
                  publisherName: event.currentTarget.value,
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              KOL? <span className="requiredRed">*</span>
            </span>
            
            {/* <InputText
              value={publisherData.IsKOL}
              onChange={(event) =>
                setPublisherData({
                  ...publisherData,
                  IsKOL: event.currentTarget.value,
                })
              }
              required
            /> */}
            <div className="p-col-12 p-field-radiobutton">
              <RadioButton
                inputId='true'
                value={true}
                onChange={(event) => 
                  setPublisherData({
                    ...publisherData,
                    isKOL: event.value,
                  })
                }
                checked={publisherData.isKOL}
              />
              <label htmlFor='true' className="ml-1">
                True
              </label>
            </div>
            <div className="p-col-12 p-field-radiobutton">
              <RadioButton
                inputId='false'
                value={false}
                onChange={(event) => 
                  setPublisherData({
                    ...publisherData,
                    isKOL: event.value,
                  })
                }
                checked={!publisherData.isKOL}
              />
              <label htmlFor='false' className="ml-1">
                False
              </label>
            </div>
          </div>
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button type="submit" label={action} />
        </div>
      </form>
    );
  };
  
  let display;
  if (isLoading) {
    display = <ProgressSpinner />;
  } else if (isError) {
    display = <CustomError message={errorMsg} />;
  } else {
    display = form();
  }

  return (
    <div className="row">
      {toHome ? <Redirect push to="/admin/publishers" /> : null}
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
        <div className="card">
          <h1 className="pb-2">{action} Publishers</h1>
          <div className="requiredRed">
            <p>* Required</p>
          </div>
          {display}
        </div>
      </div>
    </div>
  );
}

export default PublisherCreate;
