import React, { useContext, useState } from 'react'
import CloseFullScreenButton from './CloseFullScreenButton'
import { PublicProfileContext } from './PublicProfile';
const placeholder = require('../../Assets/profileplaceholder.jpg')

export default function ProfilePicture() {
    const { state } = useContext(PublicProfileContext);
    const [isFullscreenImage, setIsFullscreenImage] = useState(false)

    function openImage() {
        setIsFullscreenImage(true)
    }

    function handleError(e: React.SyntheticEvent<HTMLImageElement, Event>) {
        e.currentTarget.src = placeholder
    }

    return (
        <div className={isFullscreenImage ? "fullscreen-background-displaypic" : ""}>
            <CloseFullScreenButton isFullscreenImage={isFullscreenImage} setIsFullscreenImage={setIsFullscreenImage} />
            <img
                className={isFullscreenImage ? "fullscreen-displaypic" : "profile-page-displaypic"}
                src={state.userProfile.displayPicture ?? placeholder}
                alt=""
                onClick={openImage}
                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleError(e)}
            />
        </div>
    )
}