import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import {
  CommonMethods,
  RouteComponentProps,
  DropdownModel,
} from "../resources/CommonMethods";
import { ToastStateContext } from "./ToastContext";

let commonMethods = new CommonMethods();

function CompanyDropdown(props: RouteComponentProps<any>) {
  const { SetError } = React.useContext(ToastStateContext);

  const [companyId, setCompanyId] = useState(
    localStorage.getItem("currentAccessingCompany") || ""
  );
  const [companyName, setCompanyName] = useState("");
  const [companyDropdown, setCompanyDropdown] = useState<DropdownModel[]>(
    new Array<DropdownModel>()
  );

  useEffect(() => {
    // get company dropdown according to user role
    commonMethods
      .getCompanyDropdownAccordingToUserRole()
      .then((res) => {
        setCompanyDropdown(res);
      })
      .catch((err) => {
        let errMsg = commonMethods.getErrorMessage(err);
        SetError(errMsg);
      });
  }, []);

  useEffect(() => {
    // get user's primary company as initial dropdown value
    const currentCompanyId = props.userProfile.companyID;
    if (companyDropdown !== undefined && currentCompanyId !== "") {
      var companyObj = companyDropdown.find((x: DropdownModel) => {
        return x.value === currentCompanyId;
      }) || { label: "", value: "" };

      setCompanyId(companyObj.value);
      setCompanyName(companyObj.label);
      localStorage.setItem(
        "currentAccessingCompanyName",
        companyObj.label || ""
      );
      props.others(currentCompanyId, companyObj.label);
    }
  }, [companyDropdown]);

  useEffect(() => {
    if (companyId !== null && companyId !== "") {
      localStorage.setItem("currentAccessingCompany", companyId);
      localStorage.setItem("currentAccessingCompanyName", companyName);
    }
  }, [companyId, companyName]);

  const displayCompany = () => {
    if (companyDropdown !== undefined && companyDropdown.length > 1) {
      return (
        <div className="p-grid">
          <div
            className="p-col-12 p-lg-3 pb-2"
            data-tour-id="admin-dashboard-company-selection"
            data-tour="Here are the companies you have admin access to."
          >
            <div className="boldLabel"> Now Viewing: </div>
            <Dropdown
              value={companyId}
              options={companyDropdown}
              onChange={(e) => {
                let item = companyDropdown.find(
                  (x: { label: string; value: string }) => x.value === e.value
                ) || { label: "", value: "" };

                if (window.confirm("You are changing to view: " + item.label)) {
                  setCompanyId(e.value);
                  setCompanyName(item.label);
                  props.others(e.value, item.label);
                  // window.location.reload();
                  //   window.location.replace("/");
                }
              }}
              placeholder="Please Select"
            />
          </div>
        </div>
      );
    }
  };
  return <>{displayCompany()}</>;
}

export default CompanyDropdown;
