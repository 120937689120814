import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import useForm from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import { ClaimsService } from "../../service/ClaimsService";
import { CommonMethods } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";

function ClaimsReport() {
  const commonMethods = new CommonMethods();
  const claimService = new ClaimsService();
  const { SetError } = React.useContext(ToastStateContext);

  const [claimsReport, setClaimsReport] = useState<any[]>([]);

  const [userDropdown, setUserDropdown] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  let dt: DataTable | null = null;

  const {
    register,
    handleSubmit,

    setValue,

  } = useForm();
  useEffect(() => {
    var companyid = localStorage.getItem("currentAccessingCompany") || "";
    commonMethods
      .getUserDropdownByCompany(companyid)
      .then(res => {
        setUserDropdown(res);
        setIsLoading(false);
      })
      .catch(err => {
        let errorMsg = commonMethods.getErrorMessage(err);
        setIsError(true);
        setIsLoading(false);
        setErrorMsg(errorMsg);
      });
  }, []);

  useEffect(() => {
    if ( dt !== null) {
      dt.exportCSV();
    }
  }, [claimsReport]);

  const onSubmit = (data: any) => {


    claimService
      .getClaimReport(data)
      .then(res => {
        setClaimsReport(res);
      })
      .catch(err => {
        SetError("Unable to Export Leave Report");
      });
  };

  const headers = [
    { label: "Claim Serial", key: "claimSerialNo" },
    { label: "Campaign Serial", key: "campaignSerialNo" },
    { label: "Campaign Name", key: "campaignName" },
    { label: "Claim Type", key: "costTypeName" },
    { label: "Company", key: "companyName" },

	{ label: "Department", key: "departmentName" },
	{ label: "Budgeting Group", key: "budgetingGroup" },

    { label: "Currency", key: "currency" },
    { label: "Amount", key: "amount" },
    { label: "Tax Amount", key: "taxAmount" },
    { label: "Receipt Date", key: "receiptDate" },
    { label: "Remarks", key: "remarks" },
    { label: "Receipt Path", key: "receiptPath" },
    { label: "Submitted By", key: "submittedByName" },
    { label: "Claim Status", key: "claimStatus" },
    { label: "Endorsed By", key: "endorsedByName" },
    { label: "Endorsed Remarks", key: "endorsedRemarks" },
    { label: "Endorsed Date", key: "endorsedDate" },
    { label: "Approved By", key: "approvedByName" },
    { label: "Approved Remarks", key: "approvingRemarks" },
    { label: "Approved Date", key: "approvedDate" },
    { label: "Payment Reference", key: "paymentReference" },
    { label: "Created Date", key: "createdDate" }
  ];

  let page = (
    <div className="row">
      <div className="col-12 col-xl-12">
        <div className="card">
          <h5 className="pb-4">Claims Report</h5>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <span className="boldLabel">Employees</span>
                <RHFInput
                  as={
                    <MultiSelect
                      options={userDropdown}
                      filter={true}
                      placeholder="Please Select"
                    />
                  }
                  register={register}
                  setValue={setValue}
                  name="employeeList"
                />
              </div>
              <div className="p-col-12">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <span className="boldLabel">Start Date</span>
                    <RHFInput
                      as={<Calendar dateFormat="dd/mm/yy" required></Calendar>}
                      register={register}
                      setValue={setValue}
                      name="startDate"
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <span className="boldLabel">End Date</span>
                    <RHFInput
                      as={<Calendar dateFormat="dd/mm/yy" required></Calendar>}
                      register={register}
                      setValue={setValue}
                      name="endDate"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center pt-4">
              <Button className="p-button" label="Download Report" />
            </div>
          </form>
          <div className="p-col-12">
            <div className="p-grid p-justify-center">
              <DataTable
                style={{ display: "none" }}
                value={claimsReport}
                ref={el => {
                  dt = el;
                }}
                exportFilename={
                  "Claims_Report_" + commonMethods.displayDate_FileName(new Date())+ ".csv"
                }
              >
                {headers.map((col, i) => {
                  return (
                    <Column key={col.key} field={col.key} header={col.label} />
                  );
                })}
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return <div>{display}</div>;
}

export default ClaimsReport;
