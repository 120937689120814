import { HttpClient } from "./HttpClient";
export class UserRankService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getUserRanks() {
    try {
      const res = await this.httpClient.get("/api/userrank");
      return res;
    } catch (err) {
      return err;
    }
  }

  async geUserRankById(id: string) {
    try {
      const res = await this.httpClient.get("/api/userrank/" + id);
      return res;
    } catch (err) {
      return err;
    }
  }

  async createUserRank(userrankObj: any) {
    var form_data = new FormData();

    for (var key in userrankObj) {
      form_data.append(key, userrankObj[key]);
    }
    try {
      const res = await this.httpClient.post(
        "/api/userrank/create",
        form_data
      );
      return res;
    } catch (err) {
      return err;
    }
  }

  async updateUserRank(userrankObj: any) {
    var form_data = new FormData();

    for (var key in userrankObj) {
      form_data.append(key, userrankObj[key]);
    }
    try {
      const res = await this.httpClient.put("/api/userrank/update", form_data);
      return res;
    } catch (err) {
      return err;
    }
  }

  async getUserRankDropdownList() {
    try {
      const res = await this.httpClient.get("/api/dropdown/userrank");
      return res;
    } catch (err) {
      return err;
    }
  }

  async deleteUserRank(id:string) {
    try {
      const res = await this.httpClient.delete("/api/userrank/" + id);
      return res;
    } catch (err) {
      return err;
    }
  }
}
