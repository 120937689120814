import { HttpClient } from "./HttpClient";
export class CampaignInvoiceService {
    httpClient: HttpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    async prefillInvoice(campaignID: string)
    {
        const res = await this.httpClient.get("/api/campaigninvoice/invoiceDetailsByCampaignID/" + campaignID);
        return res;
    }


    async logInvoiceDocument(campInvoiceObj: any)
    {
        var form_data = new FormData();
        for (var key in campInvoiceObj) {
          if (key === "receiptDate") {
            form_data.append(key, new Date(campInvoiceObj[key]).toISOString());
          } else {
            form_data.append(key, campInvoiceObj[key]);
          }
        }
        const res = await this.httpClient.post("/api/campaigninvoice/create", form_data);
        return res;
  }
  
  async getInvoicesByCampaignID(campaignURL: string)
  {
    const res = this.httpClient.get("/api/campaigninvoice/CampaignID/" + campaignURL);
    return res;
  }
 
    
  
}

export default CampaignInvoiceService;
