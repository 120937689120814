import { HttpClient } from "./HttpClient";
import {
  PayslipModel,
  PayslipInfo,
  PayslipObjAndInfoModel,
} from "../components/resources/ExportClass";
import { CommonMethods } from "../components/resources/CommonMethods";
import moment from "moment";
export class PayslipService {
  httpClient: HttpClient;
  commonMethods: CommonMethods;
  constructor() {
    this.httpClient = new HttpClient();
    this.commonMethods = new CommonMethods();
  }

  // GET

  // PayslipUpdate.tsx
  async getPayslipWithUpdateLogByPayslipId(id: string) {
    const res = await this.httpClient.get("/api/payslip/" + id);
    return res;
  }

  // PayslipUpdate.tsx
  async updatePayslip(payslipObj: object) {
    const res = await this.httpClient.put("/api/payslip/update", payslipObj);
    return res;
  }

  // PayslipUserAll.tsx
  async getAllPayslipByUserId(id: string) {
    // unused
    const res = await this.httpClient.get("/api/payslip/user/" + id + "/all");
    return res;
  }

  // PayslipUserAll.tsx
  async getAllPayslipsForUser() {
    const res = await this.httpClient.get("/api/payslip/user/all");
    return res;
  }

  // PayslipCreateSpecial.tsx
  async getNewPayslipToUpdateByUserId(payrollid: string, userid: string) {
    let res = await this.httpClient.get(
      "/api/payslip/" + payrollid + "/user/" + userid + "/new"
    );
    return res;

  }
  // PayslipCreateSpecial.tsx
  async getUsersNotInCurrentPayrollByCompany(id: string) {
    const res = await this.httpClient.get(
      "/api/payslip/dropdown/" + id + "/payrollexcluded"
    );
    return res;
  }

  // Payroll.tsx
  async getAllPayslipByPayrollID(payrollID: string) {
    const res = await this.httpClient.get("/api/payslip/payroll/" + payrollID);
    return res;
  }

  // Payroll.tsx
  async updatePayslipBulk(payslipObj: object) {
    const res = await this.httpClient.put(
      "/api/payslip/bulkupdate",
      payslipObj
    );
    return res;
  }

  // Payroll.tsx
  async removePayslips(payslips: any) {
    const res = await this.httpClient.post("/api/payslip/bulkremove", payslips);
    return res;
  }

//   async replacePayslips(payslips: any) {
// 	console.log('test');
// 	const res = await this.httpClient.post("/api/payslip/bulkreplace", payslips);
// 	return res;
//   }

  // PayslipExport.tsx
  async exportPayslip(object: any, fileName: string) {
    var form_data = new FormData();
    const base64 = object.replace("data:application/pdf;base64,", "");
    form_data.append("Base64", base64);
    form_data.append("contentType", "application/pdf");
    form_data.append("fileName", fileName);
    const res = await this.httpClient.post("/api/payslip/export", form_data);
    return res;
  }

  // PayslipCreateSpecial.tsx
  async updatePayslipCalculations(
    payslipObj: PayslipModel,
    payslipInfoModel: PayslipInfo
  ) {
    let payslipUserContactModel = new PayslipObjAndInfoModel();
    payslipUserContactModel.payslipInfo = payslipInfoModel;
    payslipUserContactModel.payslipModel = payslipObj;

    const res = await this.httpClient.put(
      "/api/payslip/updatecalculation",
      payslipUserContactModel
    );
    return res;
  }

  // PayslipCreateSpecial.tsx
  async manualUpdatePayslip(
    payslipObj: PayslipModel,
    payslipInfoModel: PayslipInfo
  ) {
    let payslipUserContactModel = new PayslipObjAndInfoModel();

    // format date
    let dob = moment(payslipInfoModel.dateOfBirth).format(
      CommonMethods.DateForBackendStringFormat
    );
    payslipInfoModel = this.commonMethods.updateProperty(
      "dateOfBirth",
      dob,
      payslipInfoModel
    );

    // payslipObj dates
    payslipObj = this.commonMethods.updateProperty(
      "payStartDate",
      moment(payslipObj.payStartDate).format(CommonMethods.DateForBackendStringFormat),
      payslipObj
    );
    payslipObj = this.commonMethods.updateProperty(
      "payEndDate",
      moment(payslipObj.payEndDate).format(CommonMethods.DateForBackendStringFormat),
      payslipObj
    );
    payslipObj = this.commonMethods.updateProperty(
      "paymentDate",
      moment(payslipObj.paymentDate).format(CommonMethods.DateForBackendStringFormat),
      payslipObj
    );

    payslipUserContactModel.payslipInfo = payslipInfoModel;
    payslipUserContactModel.payslipModel = payslipObj;

    const res = await this.httpClient.put(
      "/api/payslip/update/manual",
      payslipUserContactModel
    );
    return res;
  }

  // unused
  async getOvertimeBreakdown(userID: string, payslipDate: Date) {
    const res = await this.httpClient.get(
      "/api/payslip/overtime/" + userID + "/" + payslipDate
    );
    return res;
  }

  // payroll history
  // async getPayrollHistory(companyID: string, year: string) {
  //   const res = await this.httpClient.get(
  //     "/api/payslip/prhistory/" + companyID + "/" + year
  //   );
  //   return res;
  // }
  // payroll history

  // payroll history
  async getPayrollHistory(payrollID: string) {
    const res = await this.httpClient.get(
      "/api/payslip/payroll/history/" + payrollID
    );
    return res;
  }

  // PAYROLL

  async getPayrollHistoryYearDropdown(companyID: string) {
    const res = await this.httpClient.get(
      "/api/payroll/yeardropdown/" + companyID
    );
    return res;
  }

  async getPayrollStatus(payrollid: string) {
    const res = await this.httpClient.get("/api/payroll/status/" + payrollid);
    return res;
  }

  async updatePayrollStatus(payrollobj: any) {
    const res = await this.httpClient.put("/api/payroll/status", payrollobj);
    return res;
  }

  async getAllPayrollForCompany(companyID: string, year: string) {
    const res = await this.httpClient.get(
      "/api/payroll/" + companyID + "/" + year
    );
    return res;
  }

  async createPayroll(payroll: any) {
    var form_data = new FormData();

    for (var key in payroll) {
      if (key === "paymentDate" || key === "createdTime") {
        let utcDate = moment(payroll[key]).format(CommonMethods.DateForBackendStringFormat);
        form_data.append(key, utcDate);
      } else {
        form_data.append(key, payroll[key]);
      }
    }

    const res = await this.httpClient.post("/api/payroll", form_data);
    return res;
  }

  async deletePayroll(payrollid: string) {
    const res = await this.httpClient.delete("/api/payroll/" + payrollid);
    return res;
  }

  // PAYITEMTYPE
  async getPayItemTypeDropdown() {
    const res = await this.httpClient.get("/api/payitemtype/dropdown");
    return res;
  }

  async getAllPayItemTypes() {
    //gets from user's group company
    const res = await this.httpClient.get("/api/payitemtype");
    return res;
  }

  async getPayItemTypeById(payitemtypeid: string) {
    const res = await this.httpClient.get("/api/payitemtype/" + payitemtypeid);
    return res;
  }

  async updatePayItemType(payitemtypeobj: any) {
    const res = await this.httpClient.put("/api/payitemtype/", payitemtypeobj);
    return res;
  }

  async createPayItemType(payitemtypeobj: any) {
    const res = await this.httpClient.post("/api/payitemtype/", payitemtypeobj);
    return res;
  }

  async deletePayItemType(payitemtypeid: string) {
    const res = await this.httpClient.delete("/api/payitemtype/" + payitemtypeid);
    return res;
  }

  async getCPFTypesDropdownOptions() {
    const res = await this.httpClient.get("/api/payitemtype/cpftypes");
    return res;
  }

  async getPayItemCategoryDropdownOptions() {
    const res = await this.httpClient.get("/api/payitemtype/categories");
    return res;
  }

  async getIR8ADropdownOptions() {
    const res = await this.httpClient.get("/api/payitemtype/ir8a");
    return res;
  }

}
