import { HttpClient } from "./HttpClient";
import moment from "moment";
import { CommonMethods } from "../components/resources/CommonMethods";
import { convertObjToFormData } from "../components/resources/CommonMethodsServices";
export class UserService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getUserById(id: string) {
    const res = await this.httpClient.get("/api/user/" + id);
    return res;
  }

  async getUserByIdForDisplay(id: string) {
    // User view, staff view etc
    const res = await this.httpClient.get("/api/user/view/" + id);
    return res;
  }
  async getUserDisplayDetails() {
    const res = await this.httpClient.get("/api/user/displaydetails");
    return res;
  }
  async getMyDisplay() {
    // User view, staff view etc
    const res = await this.httpClient.get("/api/user/view/me");
    return res;
  }

  async getBusinessCardDetails(userid: string) {
	const res = await this.httpClient.get("/api/user/businesscard/" + userid);
	return res;
  }

  async getMyBusinessCardDetails(){
	const res = await this.httpClient.get("/api/user/businesscarduser");
	return res;
  }

  async userIDsForUsersInSameDept() {
    const res = await this.httpClient.get(
      "/api/user/UserIDsForUsersInSameDept"
    );
    return res;
  }

  async listOfUsersInSameDept() {
    const res = await this.httpClient.get("/api/user/ListOfUsersInSameDept");
    return res;
  }

  async userIDsForUsersInGroup() {
    const res = await this.httpClient.get("/api/user/UserIDsForUsersGroup");
    return res;
  }

  async getAllActiveUsersInCompany(companyID: string) {
    const res = await this.httpClient.get("/api/user/activeusers/" + companyID);
    return res;
  }

  async getUsersDropdown() {
    const res = await this.httpClient.get("/api/dropdown/user");
    return res;
  }

  async getUserDetailsFromContract(contractid: string) {
    // Create User
    const res = await this.httpClient.get(
      "/api/user/fromcontract/" + contractid
    );
    return res;
  }

  async getLookupIDFromUserIDService(lookupid: string) {
    const res = await this.httpClient.get("/api/user/" + lookupid);
    return res;
  }

  async getUsersWithIncompleteContract(companyid: string) {
    const res = await this.httpClient.get(
      "/api/user/incompleteContract/" + companyid
    );

    return res;
  }

  async getUsers(status: string, name: string, id: string, companyid: string) {
    let url;
    if (name === "") {
      url = "/api/user/userlist/" + status + "/" + companyid;
    } else {
      url =
        "/api/user/userlist/" +
        status +
        "/" +
        name +
        "/" +
        id +
        "/" +
        companyid;
    }

    const res = await this.httpClient.get(url);
    return res;
  }

  async getDummyInUsers(companyid: string){
    const res = await this.httpClient.get("/api/user/dummyinusers/" + companyid);
    return res;
  }

  async createUser(userObj: any) {
    var form_data = new FormData();

    for (var key in userObj) {
      form_data.append(key, userObj[key]);
    }

    const res = await this.httpClient.post("/api/user/create", form_data);
    return res;
  }

  async updateOnboardingUser(userObj: any) {
    var form_data = new FormData();

    for (var key in userObj) {
      if (
        key === "dateOfBirth" ||
        key === "wP_WorkPermitExpiry" ||
        key === "prObtainedDate"
      ) {
        let utcDate = moment(userObj[key]).format(CommonMethods.DateForBackendStringFormat);
        form_data.append(key, utcDate);
      } else {
        form_data.append(key, userObj[key]);
      }
    }

    const res = await this.httpClient.put(
      "/api/user/update/onboarding",
      form_data
    );
    return res;
  }

  async updateUser(userObj: any) {
    var form_data = new FormData();

    for (var key in userObj) {
      if (
        key === "dateOfBirth" ||
        key === "wP_WorkPermitExpiry" ||
        key === "prObtainedDate"
      ) {
        let utcDate = moment(userObj[key]).format(CommonMethods.DateForBackendStringFormat);
        form_data.append(key, utcDate);
      } else {
        form_data.append(key, userObj[key]);
      }
    }

    const res = await this.httpClient.put("/api/user/update", form_data);
    return res;
  }

  async staffUpdate(userObj: any) {
    var form_data = new FormData();

    for (var key in userObj) {
      if (
        key === "dateOfBirth" ||
        key === "wP_WorkPermitExpiry" ||
        key === "prObtainedDate"
      ) {
        let utcDate = moment(userObj[key]).format(CommonMethods.DateForBackendStringFormat);
        form_data.append(key, utcDate);
      } else {
        form_data.append(key, userObj[key]);
      }
    }

    const res = await this.httpClient.put("/api/user/update/staff", form_data);
    return res;
  }
  async updateUserStatus(userObj: any) {
    const res = await this.httpClient.post("/api/user/status",  convertObjToFormData(userObj));
    return res;
  }

  // 24/09/2020 temporary removed
  // async updateUserStatusBulk(userObj: any) {
  //   const res = await this.httpClient.post("/api/user/status/bulk", convertObjToFormData(userObj));
  //   return res;
  // }

  async getRolesByUserID(id: string) {
    const res = await this.httpClient.get("/api/user/roles/" + id);
    return res;
  }

  async getTaskListByUserID(id: string) {
    const res = await this.httpClient.get("/api/user/tasklist/" + id);
    return res;
  }

  // async isThisUserAnAdmin(userid: string) {
  //   try {
  //     const res = await this.httpClient.get("/api/user/roleIsAdmin/" + userid);
  //     return res;
  //   } catch (err) {
  //     return err;
  //   }
  // }

  async updateUserRoleSetting(obj: any) {
    const res = await this.httpClient.put("/api/user/update/roleList", obj);
    return res;
  }

  async getDetailsByUserID(id: string) {
    try {
      const res = await this.httpClient.get("/api/user/userdetails/" + id);
      return res;
    } catch (err) {
      return err;
    }
  }

  async getProfileReportDetails(reportObj: any) {
    const res = await this.httpClient.post("/api/user/report", reportObj);
    return res;
  }

  async importEmployees(userObj: any) {
    var form_data = new FormData();

    for (var key in userObj) {
      form_data.append(key, userObj[key]);
    }

    const res = await this.httpClient.post("/api/user/importusers", form_data);
    return res;
  }

  async downloadTemplate(userObj: any) {
    var form_data = new FormData();

    for (var key in userObj) {
      form_data.append(key, userObj[key]);
    }

    const res = await this.httpClient.post(
      "/api/user/downloadusertemplate",
      form_data
    );
    return res;
  }

  async signup(userObj: any) {
    const res = await this.httpClient.post("/api/signup/signup", userObj);
    return res;
  }

  async contactus(userObj: any) {
    const res = await this.httpClient.post("/api/signup/contactus", userObj);
    return res;
  }

  async deleteDefaultDummyEmployees() {
    const res = await this.httpClient.delete("/api/user/defaults");
    return res;
  }

  async generatePrintsForSelectedEmployees(userListObj: any) {
	const res = await this.httpClient.postModifiedResponse("/api/user/generatebusinesscard", userListObj);
	return res;
  }

}

export class UserDocumentService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getAllUserDocuments() {
    const res = await this.httpClient.get("/api/userdocument/");
    return res;
  }

  async getUserDocumentsByUser(id: string) {
    const res = await this.httpClient.get("/api/userdocument/user/" + id);
    return res;
  }

  async getMyUserDocuments() {
    const res = await this.httpClient.get("/api/userdocument/me" );
    return res;
  }
  async getUserDocumentByDocId(id: string) {
    const res = await this.httpClient.get("/api/userdocument/" + id);
    return res;
  }

  async createUserDocument(userDocObj: any) {
    var form_data = new FormData();

    for (var key in userDocObj) {
      form_data.append(key, userDocObj[key]);
    }

    const res = await this.httpClient.post(
      "/api/userdocument/create",
      form_data
    );
    return res;
  }

  async updateUserDocument(userDocObj: any) {
    var form_data = new FormData();

    for (var key in userDocObj) {
      form_data.append(key, userDocObj[key]);
    }

    const res = await this.httpClient.put(
      "/api/userdocument/update",
      form_data
    );
    return res;
  }

  async deleteUserDocument(userDocObj: any) {
    const res = await this.httpClient.delete(
      "/api/userdocument/" + userDocObj.userDocID
    );
    return res;
  }

}
