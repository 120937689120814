import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Message } from "primereact/message";
import { Messages } from "primereact/messages";
import { ProgressSpinner } from "primereact/progressspinner";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from 'primereact/inputnumber';
import React from "react";
import { Redirect } from "react-router-dom";
import { StatusService } from "../../service/StatusService";
import { UserService } from "../../service/UserService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { UserModel } from "../user/User";
import { LogisticsStatusTypeModel } from "../resources/ExportClass";

export class statusBalanceObj {
  id: string = "";
  statusTypeName: string = "";
  statusTypeId: string = "";
  statusType: string = "";
  userId: string = "";
  actionType: string = "";
  statusValue: number = 0;
  previousValue: number = 0;
  updatedBy: string = "";
  creditor: string = "";
  createdTime: Date = new Date();
  Remarks: string = "";
}

type AppStateCreate = {
  objList: Array<statusCreditDebitObj>;
  statusCreditDebit: statusCreditDebitObj;
  userDropdown: { label: string; value: string }[];
  statusTypesDropdown: { label: string; value: string }[];
  redirectOut: boolean;
  redirectPath: string;
  redirectParam: string;
  userList: Array<UserModel>;
  isBatchUpdate: boolean;
  userName: string;
  userheader: string;
  expiryDateActive: boolean;
  isError: boolean;
  errorMsg: string;
  isLoading: boolean;
  currentStatusTypeName: any;
};

class statusCreditDebitObj {
  userID: string = "";
  statusTypeID: string = "";
  actionType: string = "Credit";
  statusValue: number = 0;
  remarks: string = "";
  expiryDate: string | Date = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  ).toISOString();
  broughtForward: boolean = false;
}

class StatusCreditCreate extends React.Component<
  RouteComponentProps<any>,
  AppStateCreate
  > {
  static contextType = ToastStateContext;

  statusService: StatusService;
  userService: UserService;
  commonMethods: CommonMethods;
  messages: any;
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      objList: new Array<statusCreditDebitObj>(),
      redirectOut: false,
      redirectPath: "",
      redirectParam: "",
      statusCreditDebit: new statusCreditDebitObj(),
      userDropdown: [],
      statusTypesDropdown: [],
      userList: new Array<UserModel>(),
      isBatchUpdate: false,
      userName: "",
      userheader: "User",
      expiryDateActive: false,
      isLoading: true,
      isError: false,
      errorMsg: "",
      currentStatusTypeName: "",
    };
    this.statusService = new StatusService();
    this.userService = new UserService();
    this.submitForm = this.submitForm.bind(this);
    this.configureDropdownForBroughtForwardStart = this.configureDropdownForBroughtForwardStart.bind(this);
    this.configureDropdownForBroughtForwardEnd = this.configureDropdownForBroughtForwardEnd.bind(this);
    this.commonMethods = new CommonMethods();
  }

  componentDidMount() {
    // Batch
    if (this.props.location.search === "?batch") {
      if (this.commonMethods.checkLocState(this.props)) {
        var data: any = this.props.location.state;
        this.setState(
          {
            userList: data,
            isBatchUpdate: true
          },
          () => {
            this.updateUserNames();
          }
        );
      } else {
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: "Invalid entry for batch credit/debit"
        });
      }

      // Set UserIDs into statusCreditDebitObjs
    } else {
      if (this.props.match.params.id === undefined) {
        // this.userService
        //   .getUsersDropdown()
        //   .then(res => this.setState({ userDropdown: res }));
      } else {
        let paramsUserID = this.props.match.params.id;
        this.commonMethods
          .getFullDisplayNameFromProvidedUserID(paramsUserID)
          .then(res => {
            this.setState({
              userName: res,
              statusCreditDebit: {
                ...this.state.statusCreditDebit,
                userID: this.props.match.params.id
              }
            });
          })
          .catch(error => {
            let errorMsg = this.commonMethods.getErrorMessage(error);
            this.setState({
              isError: true,
              isLoading: false,
              errorMsg: errorMsg
            });
            return errorMsg;
          });

        // this.userService
        //   .getUsersDropdown()
        //   .then(res =>
        //     this.setState({
        //       userDropdown: res
        //     })
        //   )
        //   .catch(error => {
        //     let errorMsg = this.commonMethods.getErrorMessage(error);
        //     this.setState({
        //       isError: true,
        //       isLoading: false,
        //       errorMsg: errorMsg
        //     });
        //     return errorMsg;
        //   });
      }
    }

    this.statusService
      .getStatusTypeDropdownDeductible()
      .then(res =>
        this.setState({ statusTypesDropdown: res, isLoading: false }, () => {
          this.configureDropdownForBroughtForwardStart();
        })
      )
      .catch(error => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg
        });
        return errorMsg;
      });
  }

  configureDropdownForBroughtForwardStart(){
    var dropdownCopy = this.state.statusTypesDropdown;
    dropdownCopy.forEach(statusType => {
      if (statusType.label != null){
        if (statusType.label.includes("brought forward")){
          statusType.value += "x";
        }
      }
    });
    this.setState({ statusTypesDropdown: dropdownCopy });
  }

  configureDropdownForBroughtForwardEnd(obj: statusCreditDebitObj){
    if (this.state.currentStatusTypeName.includes("brought forward")){
      obj.statusTypeID = obj.statusTypeID.slice(0, -1);
      obj.broughtForward = true;
      console.log("configured statusCredit obj");
      console.log(obj);
    }
  }

  updateUserNames() {
    let usernamelist = "";
    if (this.state.userList !== undefined && this.state.userList.length > 0) {
      this.state.userList.forEach(item => {
        usernamelist += " " + item.firstName + " " + item.lastName + ",";
      });
      usernamelist = usernamelist.substring(0, usernamelist.length - 1);
      // userdropdown = <div>{usernamelist} </div>;

      this.setState({ userheader: "Users", userName: usernamelist });
    }
  }

  mapValuesToObjListForBatchUpdate(userModel: UserModel) {
    let newStatusObjModel = new statusCreditDebitObj();
    newStatusObjModel.userID = userModel.userID;
    newStatusObjModel.statusValue = this.state.statusCreditDebit.statusValue;
    newStatusObjModel.statusTypeID = this.state.statusCreditDebit.statusTypeID;
    newStatusObjModel.remarks = this.state.statusCreditDebit.remarks;
    newStatusObjModel.actionType = this.state.statusCreditDebit.actionType;
    return newStatusObjModel;
  }

  formMessage(
    actionType: string,
    leaveType: string,
    value: number,
    name: string
  ) {
    let actionTypeDone;
    if (actionType === "Credit") {
      actionTypeDone = "credited to ";
    } else if (actionType === "Debit") {
      actionTypeDone = "debited from ";
    } else if (actionType === "Allocate") {
      actionTypeDone = "allocated to ";
    }

    let returnStr =
      value + " " + leaveType + " has been " + actionTypeDone + name;
    return returnStr;
  }

  submitForm(event: React.FormEvent) {
    event.preventDefault();
    this.setState({ isLoading: true }, () => {
      if (this.state.statusCreditDebit.statusValue === 0) {
        this.context.SetError("Value cannot be 0");
        this.setState({ isLoading: false });
        return;
      }
      // map to list
      if (this.state.isBatchUpdate) {
        this.setState(
          {
            objList: this.state.userList.map(
              this.mapValuesToObjListForBatchUpdate,
              this
            )
          },
          () => {
            this.statusService
              .batchCreditStatus(this.state.objList)
              .then(res => {
                this.setState({ isLoading: false });
                this.context.SetSuccess(
                  this.formMessage(
                    this.state.statusCreditDebit.actionType,
                    this.state.statusTypesDropdown.find(
                      e => e.value === this.state.statusCreditDebit.statusTypeID
                    )!.label || "leave",
                    this.state.statusCreditDebit.statusValue,
                    this.state.userName
                  )
                );
              })
              .catch(err => {
                const error = this.commonMethods.getErrorMessage(err);
                this.context.SetError(error);
                this.setState({ isLoading: false });
              });
          }
        );
      } else {
        if (this.state.statusCreditDebit.actionType !== "Allocate") {
          let objToSubmit = this.state.statusCreditDebit;
          this.configureDropdownForBroughtForwardEnd(objToSubmit);
          this.statusService
            .creditStatus(objToSubmit)
            .then(res => {
              this.context.SetSuccess(
                this.formMessage(
                  this.state.statusCreditDebit.actionType,
                  this.state.statusTypesDropdown.find(
                    e => e.value === this.state.statusCreditDebit.statusTypeID
                  )!.label || "leave",
                  this.state.statusCreditDebit.statusValue,
                  this.state.userName
                )
              );
              this.setState({ isLoading: false });
              if (this.props.others !== undefined && this.props.others !== null) {
                this.props.others(true);
              }
            })
            .catch(err => {
              const error = this.commonMethods.getErrorMessage(err);
              this.context.SetError(error);
              this.setState({ isLoading: false });
            });
        } else {
          this.statusService
            .changeAllocatedDays(this.state.statusCreditDebit)
            .then(res => {
              this.setState({ isLoading: false });
              this.context.SetSuccess(
                this.formMessage(
                  this.state.statusCreditDebit.actionType,
                  this.state.statusTypesDropdown.find(
                    e => e.value === this.state.statusCreditDebit.statusTypeID
                  )!.label || "leave",
                  this.state.statusCreditDebit.statusValue,
                  this.state.userName
                )
              );
              if (this.props.others !== undefined && this.props.others !== null) {
                this.props.others(true);
              }
            })
            .catch(err => {
              const error = this.commonMethods.getErrorMessage(err);
              this.setState({ isLoading: false });
              this.context.SetError(error);
            });
        }
      }

    });

  }

  setExpiryDate() {
    this.statusService
      .getLeaveBalanceByUserID_StaffView(this.state.statusCreditDebit.userID)
      .then(res => {
        let expiry = res.find(
          (ele: { statusTypeID: string }) =>
            ele.statusTypeID === this.state.statusCreditDebit.statusTypeID
        );
        if (expiry !== undefined) {
          this.setState({
            statusCreditDebit: {
              ...this.state.statusCreditDebit,
              expiryDate: new Date(expiry.expiryDate).toISOString()
            },
            expiryDateActive: false
          });
        } else {
          this.setState({ expiryDateActive: true });
        }
      });
  }

  setCurrentStatusName(){
    let label, obj;
    try{
      obj = this.state.statusTypesDropdown.find(e => e.value === this.state.statusCreditDebit.statusTypeID);
      if (obj != null){
        label = obj.label;
        this.setState({
          currentStatusTypeName: label
        })
      }
    } catch (error){}
  }

  render() {
    let userdropdown = <div>{this.state.userName} </div>;
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
            search: this.state.redirectParam
          }}
        />
      );
    }

    let page = (
      <form onSubmit={this.submitForm}>
        <div className="p-justify-center">
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <Messages ref={el => (this.messages = el)} />
              <span className="boldLabel">{this.state.userheader}</span>

              {userdropdown}
              {/* <Dropdown
              value={this.state.statusCreditDebit.userID}
              options={this.state.userDropdown}
              onChange={e =>
                this.setState({
                  statusCreditDebit: {
                    ...this.state.statusCreditDebit,
                    userID: e.value
                  }
                })
              }
              required
              disabled
            /> */}
            </div>
            <div className="p-col-12">
              <span className="boldLabel">Status Type <span style={{color: "red"}}>*</span></span>
              <Dropdown
                value={this.state.statusCreditDebit.statusTypeID}
                options={this.state.statusTypesDropdown}
                onChange={e => {
                  this.setState({
                    statusCreditDebit: {
                      ...this.state.statusCreditDebit,
                      statusTypeID: e.value
                    },
                  }, () => {
                    this.setCurrentStatusName();
                  });
                  this.setExpiryDate();
                }}
                required
                placeholder="Please Select"
              />
            </div>
            <div className="p-col-12">
              <p className="boldLabel">Action Type <span style={{color: "red"}}>*</span></p>
              <div className="p-field-radiobutton">
                <RadioButton
                  inputId="rb1"
                  value="Credit"
                  name="actionType"
                  onChange={e =>
                    this.setState({
                      statusCreditDebit: {
                        ...this.state.statusCreditDebit,
                        actionType: e.value
                      }
                    })
                  }
                  checked={this.state.statusCreditDebit.actionType === "Credit"}
                />
                <label htmlFor="rb1" className="ml-1">
                  Credit
                </label>
                <RadioButton
                  inputId="rb2"
                  value="Debit"
                  name="actionType"
                  onChange={e =>
                    this.setState({
                      statusCreditDebit: {
                        ...this.state.statusCreditDebit,
                        actionType: e.value
                      }
                    })
                  }
                  checked={this.state.statusCreditDebit.actionType === "Debit"}
                  style={{ marginLeft: "1rem" }}
                />
                <label htmlFor="rb2" className="ml-1">
                  Debit
                </label>
                <RadioButton
                  inputId="rb3"
                  value="Allocate"
                  name="actionType"
                  onChange={e =>
                    this.setState({
                      statusCreditDebit: {
                        ...this.state.statusCreditDebit,
                        actionType: e.value
                      }
                    })
                  }
                  checked={this.state.statusCreditDebit.actionType === "Allocate"}
                  style={{ marginLeft: "1rem" }}
                />
                <label htmlFor="rb2" className="ml-1">
                  Allocate
                </label>
              </div>
            </div>
            <div
              className="p-col-12"
              hidden={!(this.state.statusCreditDebit.actionType === "Allocate")}
            >
              <Message
                severity="info"
                text="Selecting this option will only change the allocated days for the chosen leave type. It will not credit or debit the number of days."
              />
            </div>
            <div className="p-col-12">
              <span className="boldLabel">Value <span style={{color: "red"}}>*</span></span>

              <InputNumber
                value={this.state.statusCreditDebit.statusValue}
                onChange={e => {
                  if (!isNaN(Number(e.value))) {
                    this.setState({
                      statusCreditDebit: {
                        ...this.state.statusCreditDebit,
                        statusValue: e.value
                      }
                    });
                  }
                }}
                step={0.5}
                min={0}
                max={100}
                mode="decimal"
                showButtons
                minFractionDigits={1}
              />
            </div>
            {/* <div
              className="p-col-12"
              hidden={this.state.statusCreditDebit.actionType === "Allocate"}
            >
              <span className="boldLabel">Expiry Date</span>
              <div className="p-inputgroup">
                <Calendar
                  minDate={new Date()}
                  dateFormat="dd/mm/yy"
                  inline={false}
                  value={new Date(this.state.statusCreditDebit.expiryDate)}
                  onChange={e =>
                    this.setState({
                      statusCreditDebit: {
                        ...this.state.statusCreditDebit,
                        expiryDate: new Date(e.value.toString()).toISOString()
                      }
                    })
                  }
                  disabled={!this.state.expiryDateActive}
                />
                <Button
                  type="button"
                  icon="pi pi-pencil"
                  className="p-button-warning"
                  onClick={() => {
                    this.setState({ expiryDateActive: true });
                  }}
                />
              </div>
            </div> */}
            <div className="p-col-12">
              <span className="boldLabel">Remarks <span style={{color: "red"}}>*</span></span>
              <InputTextarea
                value={this.state.statusCreditDebit.remarks}
                onChange={e =>
                  this.setState({
                    statusCreditDebit: {
                      ...this.state.statusCreditDebit,
                      remarks: e.currentTarget.value
                    }
                  })
                }
                rows={5}
                cols={30}
                autoResize={true}
                required
              />
            </div>
          </div>
        </div>
        <div className="p-grid p-justify-center">
          <Button
            type="submit"
            label={
              this.state.statusCreditDebit.actionType.charAt(0).toUpperCase() +
              this.state.statusCreditDebit.actionType.slice(1)
            }
          />
        </div>
      </form>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = page;
    }

    return (
      <>
        <h1 className="pb-2">Credit/Debit of Status</h1>
        
          <div className="requiredRed"><p>* Required </p></div>
       
        {display}
      </>
    );
  }
}

export default StatusCreditCreate;
