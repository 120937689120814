import { HttpClient } from "./HttpClient";
import { CampaignTypeModel } from "../components/campaign/CampaignType";
export class CampaignTypeService {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  async createCampaignType(campaignObj: CampaignTypeModel) {
    const res = await this.httpClient.post(
      "/api/campaigntype/create",
      campaignObj
    );
    return res;
  }
  async getAllCampaignTypesByGCID() {
    const res = await this.httpClient.get("/api/campaigntype/gcid");
    return res;
  }
  async getCampaignTypeByID(id: string) {
    const res = await this.httpClient.get("/api/campaigntype/id/" + id);
    return res;
  }
  async deleteCampaignTypeByID(id: string) {
    const res = await this.httpClient.delete("/api/campaigntype/id/" + id);
    return res;
  }
  async updateCampaignType(campaignObj: CampaignTypeModel) {
    const res = await this.httpClient.post(
      "/api/campaigntype/update",
      campaignObj
    );
    return res;
  }
  async deactivateCampaignType(ID: string) {
    const res = this.httpClient.get("/api/campaigntype/deactivate/" + ID);
    return res;
  }
  async activateCampaignType(ID: string) {
    const res = this.httpClient.get("/api/campaigntype/activate/" + ID);
    return res;
  }
  async deleteCampaignType(ID: string) {
    const res = this.httpClient.get("/api/campaigntype/delete/" + ID);
    return res;
  }
  async updateCampaignStatus(campObj: CampaignTypeModel) {
    const res = this.httpClient.put("/api/campaigntype/updatestatus", campObj);
    return res;
  }
}

export default CampaignTypeService;
