import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputNumber } from 'primereact/inputnumber';
import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { StatusService } from "../../service/StatusService";
import { CommonMethods } from "../resources/CommonMethods";
import CustomError  from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import ReactTooltip from "react-tooltip";

type AppState = {
  status: string;
  submitType: string;
  redirectOut: boolean;
  redirectPath: string;
  redirectParam: string;
  statusTypes: statusTypeObj[];
  statusType: statusTypeObj;
  confirmDeleteVisible: boolean;
  isLoading: boolean;
  errorMsg: string;
  isError: boolean;
  dialogStr: string;
  dialogHeaderStr: string;
  visible: boolean;
  dialogIcon: string;
  dialogShowError: boolean;
  globalFilter: any;
  bringForwardSwitch: boolean;
};

export class statusTypeObj {
  statusTypeID: string = CommonMethods.EmptyGuid;
  statusTypeName: string = "";
  statusTypeDeductible: boolean = false;
  statusTypeSupportDoc: boolean = false;
  toDisplay: boolean = false;
  statusTypeStatus: string = "Active";
  createdTime: Date | Date[] = new Date();
  defaultValue: number = 0;
  bringForwardAmount: number = 0;
  bringFwdNumOfMonths: number = 0;
  incrementCount: number = 0;
  maximumAmount: number = 0;
}

export class StatusType extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  statusTypeService: StatusService;
  commonMethods: CommonMethods;
  status: { label: string; value: string }[];

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      status: "All",
      submitType: "Create",
      redirectOut: false,
      redirectPath: "",
      redirectParam: "",
      statusTypes: [],
      statusType: new statusTypeObj(),
      confirmDeleteVisible: false,
      isLoading: false,
      errorMsg: "",
      isError: false,
      dialogStr: "",
      dialogHeaderStr: "",
      visible: false,
      dialogIcon: "",
      dialogShowError: false,
      globalFilter: "",
      bringForwardSwitch: false,
    };
    this.statusTypeService = new StatusService();
    this.actionsTemplate = this.actionsTemplate.bind(this);
    this.dashboardDisplayTemplate = this.dashboardDisplayTemplate.bind(this);
    this.bringForwardTemplate = this.bringForwardTemplate.bind(this);
    this.supportDocsTemplate = this.supportDocsTemplate.bind(this);
    this.commonMethods = new CommonMethods();

    this.status = [
      { label: "Active", value: "Active" },
      { label: "Deactivated", value: "Deactivated" },
      { label: "Unappliable", value: "Unappliable" },
      { label: "All", value: "All" },
    ];
  }

  componentDidMount() {
    this.getStatusTypes(this.state.status);
  }

  getStatusTypes(status: string) {
    this.statusTypeService
      .getStatusTypes(status)
      .then((res) => {
        this.setState({ statusTypes: res });
      })
      .catch((err) => {
        let errorMsg = this.commonMethods.getErrorMessage(err);
        this.setState({
          isError: true, // display error page
          isLoading: false,
          errorMsg: errorMsg,
        });
      });
  }

  addStatusType() {
    this.setState({ redirectPath: "/admin/leave_types/create" });
    this.setState({ redirectOut: true });
  }

  updateItem(status: statusTypeObj) {
    this.setState({
      redirectPath: "/admin/leave_types/update/" + status.statusTypeID,
    });
    this.setState({ redirectOut: true });
  }

  deleteItem(status: statusTypeObj) {
    this.statusTypeService
      .tempDeleteStatusType(status.statusTypeID)
      .then(() => {
        let msg = status.statusTypeName + " has been deleted";
        this.context.SetSuccess(msg);

        this.getStatusTypes("Active");
        this.setState({ confirmDeleteVisible: false });
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);

        this.context.SetError(errorMsg);
        this.setState({
          isLoading: false,
        });
      });
  }

  confirmDeleteDialog(status: statusTypeObj) {
    this.setState({ confirmDeleteVisible: true, statusType: status });
  }
  // confirmDelete() {
  //   this.statusTypeService
  //     .deleteStatusType(this.state.statusType.statusTypeName)
  //     .then(res => {
  //       let msg = res.statusTypeName + " is now deleted.";
  //       this.context.SetSuccess(msg);
  //       this.getStatusTypes("Active");
  //     });
  //   this.setState({ confirmDeleteVisible: false });
  // }

  deductibleTemplate(rowData: statusTypeObj) {
    return (
      <div className="text-center">
        {rowData.statusTypeDeductible ? (
          <i className="pi pi-check" />
        ) : (
          <i className="pi pi-times" />
        )}
      </div>
    );
  }

  supportDocsTemplate(rowData: statusTypeObj) {
    return (
      <div className="text-center">
        {rowData.statusTypeSupportDoc ? (
          <i className="pi pi-check" />
        ) : (
          <i className="pi pi-times" />
        )}
      </div>
    );
  }

  dashboardDisplayTemplate(rowData: statusTypeObj) {
    return (
      <div className="text-center">
        {rowData.toDisplay ? (
          <i className="pi pi-check" />
        ) : (
          <i className="pi pi-times" />
        )}
      </div>
    );
  }

  bringForwardTemplate(rowData: statusTypeObj) {
    return (
      <div className="text-center">
        {rowData.bringForwardAmount == 0 
          ? (<i className="pi pi-times" />)
          : rowData.bringForwardAmount
        }
      </div>
    )
  }

  actionsTemplate(rowData: statusTypeObj) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning mx-1"
          onClick={() => {
            this.updateItem(rowData);
          }}
        />

        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            this.confirmDeleteDialog(rowData);
          }}
        />
      </div>
    );
  }
  render() {
    let displayHeader = (
      <div style={{ textAlign: "left" }}>
        <span className="p-input-icon-left mr-1 mb-1">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            onChange={(e) => {
              this.setState({ globalFilter: e.currentTarget.value });
            }}
          />
        </span>
        <Dropdown
          options={this.status}
          value={this.state.status}
          onChange={(e) => {
            this.setState({
              status: e.value,
            });
            this.getStatusTypes(e.value);
          }}
        />
      </div>
    );
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
            search: this.state.redirectParam,
          }}
        />
      );
    }

    let form = (
      <>
        <Dialog
          className="popupDialog"
          header={"Delete " + this.state.statusType.statusTypeName}
          visible={this.state.confirmDeleteVisible}
          modal={true}
          //dismissableMask={true}
          onHide={() => this.setState({ confirmDeleteVisible: false })}
          footer={
            <div>
              <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-success"
                onClick={() => this.deleteItem(this.state.statusType)}
              />
              <Button
                label="No"
                icon="pi pi-times"
                className="p-button-danger"
                onClick={() => this.setState({ confirmDeleteVisible: false })}
              />
            </div>
          }
        >
          Are you sure you want to delete this item?
        </Dialog>

        {/* <div className="p-grid">
          <div className="p-col">
            <Dropdown
              options={this.status}
              value={this.state.status}
              onChange={e => {
                this.setState({
                  status: e.value
                });
                this.getStatusTypes(e.value);
              }}
            />
          </div>
        </div> */}
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={this.state.statusTypes}
            autoLayout={true}
            emptyMessage="No results found."
            header={displayHeader}
            globalFilter={this.state.globalFilter}
            paginator={true}
            rows={15}
            alwaysShowPaginator={false}
          >
            <Column field="statusTypeName" header="Name" />
            <Column field="statusTypeStatus" header="Status" />

            <Column field="defaultValue" header="Default Value" />
            <Column body={this.deductibleTemplate} header="Deductible" />
            <Column body={this.supportDocsTemplate} header="Documents Required" />
            <Column
              body={this.dashboardDisplayTemplate}
              header="Show on dashboard"
            />
            <Column
              body={this.bringForwardTemplate}
              header="Bring Forward"
            />
            {/* <Column field="createdTime" header="Created On" /> */}
            <Column body={this.actionsTemplate} header="Actions" />
          </DataTable>
        </div>
      </>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />; // or some growl/message that stays
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = form;
    }

    return (
      <div className="row">
        <div className="col-12 col-xl-10">
          <div className="card">
            <h1>
              Leave Types
              <span className="pl-3 mb-4">
                <Button
                  type="button"
                  icon="pi pi-plus"
                  iconPos="right"
                  label="Add"
                  className="p-button-danger"
                  onClick={() => {
                    this.addStatusType();
                  }}
                />
              </span>
            </h1>
            <p>These are leaves that employees are able to apply for.</p>
            {display}
          </div>
        </div>
      </div>
    );
  }
}

export class StatusTypeCreate extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;

  statusTypeService: StatusService;
  statusTypeStatusList: { label: string; value: string }[];
  commonMethods: CommonMethods;
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      status: "Active",
      submitType: "Create",
      redirectOut: false,
      redirectPath: "",
      redirectParam: "",
      statusTypes: [],
      statusType: new statusTypeObj(),
      confirmDeleteVisible: false,
      isLoading: false,
      isError: false,
      errorMsg: "",
      dialogStr: "",
      dialogHeaderStr: "",
      visible: false,
      dialogIcon: "",
      dialogShowError: false,
      globalFilter: "",
      bringForwardSwitch: false,
    };
    this.statusTypeService = new StatusService();
    this.commonMethods = new CommonMethods();
    this.statusTypeStatusList = [
      { label: "Active", value: "Active" },
      { label: "Deactivated", value: "Deactivated" },
      { label: "Unappliable", value: "Unappliable" },
    ];
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    if (
      this.props.match.params !== {} &&
      this.props.match.params.id !== undefined
    ) {
      let idFromQuery = this.props.match.params.id;
      this.statusTypeService
        .getStatusTypeById(idFromQuery)
        .then((res) => {
          this.setState({ 
            statusType: res, 
            submitType: "Update", 
            bringForwardSwitch: res.bringForwardAmount
          });
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.setState({
            isError: true,
            isLoading: false,
            errorMsg: errorMsg,
          });
        });
    }
  }

  goBack() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/leave_types",
    });
  }

  submitForm(event: React.FormEvent) {
    event.preventDefault();
    if (!this.state.bringForwardSwitch){
      this.setState({
        statusType: {
          ...this.state.statusType,
          bringForwardAmount: 0,
          bringFwdNumOfMonths: 0
        }
      }, () => {
        if (this.state.submitType === "Create") {
          this.statusTypeService
            .createStatusType(this.state.statusType)
            .then((res) => {
              let msg = res.statusTypeName + " has been created.";
              this.context.SetSuccess(msg);
    
              this.goBack();
            })
            .catch((error) => {
              // error dialog
              let errorMsg = this.commonMethods.getErrorMessage(error);
    
              this.context.SetError(errorMsg);
    
              this.setState({
                isLoading: false,
              });
    
              return errorMsg;
            });
        } else if (this.state.submitType === "Update") {
          this.statusTypeService
            .updateStatusType(this.state.statusType)
            .then((res) => {
              let msg = res.statusTypeName + " has been updated.";
              this.context.SetSuccess(msg);
    
              this.goBack();
            })
            .catch((error) => {
              let errorMsg = this.commonMethods.getErrorMessage(error);
              this.context.SetError(errorMsg);
    
              this.setState({
                isLoading: false,
              });
            });
        }
      });
    } else {
      if (this.state.submitType === "Create") {
        this.statusTypeService
          .createStatusType(this.state.statusType)
          .then((res) => {
            let msg = res.statusTypeName + " has been created.";
            this.context.SetSuccess(msg);

            this.goBack();
          })
          .catch((error) => {
            // error dialog
            let errorMsg = this.commonMethods.getErrorMessage(error);

            this.context.SetError(errorMsg);

            this.setState({
              isLoading: false,
            });

            return errorMsg;
          });
      } else if (this.state.submitType === "Update") {
        this.statusTypeService
          .updateStatusType(this.state.statusType)
          .then((res) => {
            let msg = res.statusTypeName + " has been updated.";
            this.context.SetSuccess(msg);

            this.goBack();
          })
          .catch((error) => {
            let errorMsg = this.commonMethods.getErrorMessage(error);
            this.context.SetError(errorMsg);

            this.setState({
              isLoading: false,
            });
          });
      }
    }
  }

  render() {
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
            search: this.state.redirectParam,
          }}
        />
      );
    }

    let form = (
      <form onSubmit={this.submitForm}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">
              Status Name <span style={{ color: "red" }}>*</span>
            </span>
            <InputText
              value={this.state.statusType.statusTypeName}
              onChange={(e) =>
                this.setState({
                  statusType: {
                    ...this.state.statusType,
                    statusTypeName: e.currentTarget.value,
                  },
                })
              }
              required
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">
              Default Value{" "}
              <i
                className="fas fa-info-circle informationStyle"
                data-tip=""
                data-for="defaultvalue"
              ></i>{" "}
              <span style={{ color: "red" }}>*</span>
            </span>
            <ReactTooltip
              id="defaultvalue"
              aria-haspopup="true"
              
              className="tooltipFont"
            >
              <p className="text-center">
                Newly created contracts will have this default value.
              </p>
            </ReactTooltip>
            <InputNumber
              value={this.state.statusType.defaultValue}
              onChange={(e) => {
                if (!isNaN(Number(e.value))) {
                  this.setState({
                    statusType: {
                      ...this.state.statusType,
                      defaultValue: e.value,
                    },
                  });
                }
              }}
              min={0}
              max={200}
              step={0.5}
              required
              mode="decimal"
              showButtons
              minFractionDigits={1}
            />
          </div>

          <div className="p-col-12">
            <p className="boldLabel">
              Deductible{" "}
              <i
                className="fas fa-info-circle informationStyle"
                data-tip=""
                data-for="deductible"
              ></i>
            </p>
            <ReactTooltip
              id="deductible"
              aria-haspopup="true"
              
              className="tooltipFont"
            >
              <p className="text-center">
                Employees must be given credits in order to apply for this leave
                type.
              </p>
            </ReactTooltip>
            <InputSwitch
              checked={this.state.statusType.statusTypeDeductible}
              onChange={(e) =>
                this.setState({
                  statusType: {
                    ...this.state.statusType,
                    statusTypeDeductible: e.value,
                  },
                })
              }
            />
          </div>
          <div className="p-col-12">
            <p className="boldLabel">
              Document Required{" "}
              <i
                className="fas fa-info-circle informationStyle"
                data-tip=""
                data-for="documentrequired"
              ></i>
            </p>
            <ReactTooltip
              id="documentrequired"
              aria-haspopup="true"
              
              className="tooltipFont"
            >
              <p className="text-center">
                A document must be attached in order to be approved.
              </p>
            </ReactTooltip>
            <InputSwitch
              checked={this.state.statusType.statusTypeSupportDoc}
              onChange={(e) =>
                this.setState({
                  statusType: {
                    ...this.state.statusType,
                    statusTypeSupportDoc: e.value,
                  },
                })
              }
            />
          </div>
          <div className="p-col-12">
            <p className="boldLabel">Display on dashboard</p>
            <InputSwitch
              checked={this.state.statusType.toDisplay}
              onChange={(e) =>
                this.setState({
                  statusType: {
                    ...this.state.statusType,
                    toDisplay: e.value,
                  },
                })
              }
            />
          </div>
          <div className="p-col-12">
            <p className="boldLabel">Bring Forward</p>
            <InputSwitch
              checked={this.state.bringForwardSwitch}
              onChange={(e) =>
                this.setState({ bringForwardSwitch: e.value })
              }
            />
          </div>
          
          {this.state.bringForwardSwitch ? (
          <div className="p-col-12">
            <div className="pb-2">
              <span className="boldLabel">
                Bring Forward Amount{" "}
                <i
                  className="fas fa-info-circle informationStyle"
                  data-tip=""
                  data-for="bringforwardamount"
                ></i>{" "}
                <span style={{ color: "red" }}>*</span>
              </span>
              <ReactTooltip
                id="bringforwardamount"
                aria-haspopup="true"
                
                className="tooltipFont"
              >
                <p className="text-center">
                  Then number of days of this leave type that can be brought forward to the following user.
                </p>
              </ReactTooltip>
              <InputNumber
                value={this.state.statusType.bringForwardAmount}
                onChange={(e) => {
                  if (!isNaN(Number(e.value))) {
                    this.setState({
                      statusType: {
                        ...this.state.statusType,
                        bringForwardAmount: e.value,
                      },
                    });
                  }
                }}
                min={0}
                max={365}
                step={0.5}
                required
                mode="decimal"
                showButtons
                minFractionDigits={1}
              />
            </div>
            <div>
              <span className="boldLabel">
                Bring Forward No. Of Months{" "}
                <i
                  className="fas fa-info-circle informationStyle"
                  data-tip=""
                  data-for="bringforwardmonths"
                ></i>{" "}
                <span style={{ color: "red" }}>*</span>
              </span>
              <ReactTooltip
                id="bringforwardmonths"
                aria-haspopup="true"
                
                className="tooltipFont"
              >
                <p className="text-center">
                  Expiry date of this brought over leave = Reset Date + this no. of months.
                </p>
              </ReactTooltip>
              <InputNumber
                value={this.state.statusType.bringFwdNumOfMonths}
                onChange={(e) => {
                  if (!isNaN(Number(e.value))) {
                    this.setState({
                      statusType: {
                        ...this.state.statusType,
                        bringFwdNumOfMonths: e.value,
                      },
                    });
                  }
                }}
                min={0}
                max={12}
                step={0.5}
                required
                mode="decimal"
                showButtons
                minFractionDigits={1}
              />
            </div>
          </div>
          ) : ""
          }
          {/* <div className="p-col-12">
            <span className="boldLabel">
              Bring Forward Amount{" "}
              <i
                className="fas fa-info-circle informationStyle"
                data-tip=""
                data-for="bringforwardamount"
              ></i>{" "}
              <span style={{ color: "red" }}>*</span>
            </span>
            <ReactTooltip
              id="bringforwardamount"
              aria-haspopup="true"
              
              className="tooltipFont"
            >
              <p className="text-center">
                The number of days of this leave type that can be brought
                forward to the following year.
              </p>
            </ReactTooltip>
            <Spinner
              formatInput={false}
              decimalSeparator=""
              thousandSeparator=""
              value={this.state.statusType.bringForwardAmount}
              onChange={(e) => {
                if (!isNaN(Number(e.value))) {
                  this.setState({
                    statusType: {
                      ...this.state.statusType,
                      bringForwardAmount: e.value,
                    },
                  });
                }
              }}
              min={0}
              max={200}
              step={1}
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Bring Forward Maximum Number Of Months{" "}
              <span style={{ color: "red" }}>*</span>
            </span>
            <Spinner
              formatInput={false}
              decimalSeparator=""
              thousandSeparator=""
              value={this.state.statusType.bringFwdNumOfMonths}
              onChange={(e) => {
                if (!isNaN(Number(e.value))) {
                  this.setState({
                    statusType: {
                      ...this.state.statusType,
                      bringFwdNumOfMonths: e.value,
                    },
                  });
                }
              }}
              min={0}
              max={12}
              step={1}
              required
            />
          </div> */}
          <div className="p-col-12">
            <span className="boldLabel">
              Yearly Increment Count <span style={{ color: "red" }}>*</span>
            </span>
            <InputNumber
              value={this.state.statusType.incrementCount}
              onChange={(e) => {
                if (!isNaN(Number(e.value))) {
                  this.setState({
                    statusType: {
                      ...this.state.statusType,
                      incrementCount: e.value,
                    },
                  });
                }
              }}
              min={0}
              max={365}
              step={1}
              required
              mode="decimal"
              showButtons
              minFractionDigits={2}
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Maximum Amount{" "}
              <i
                className="fas fa-info-circle informationStyle"
                data-tip=""
                data-for="maxamount"
              ></i>{" "}
              <span style={{ color: "red" }}>*</span>
            </span>
            <ReactTooltip
              id="maxamount"
              aria-haspopup="true"
              
              className="tooltipFont"
            >
              <p>The maximum no. of days employees can apply for in a year.</p>
            </ReactTooltip>
            <InputNumber
              value={this.state.statusType.maximumAmount}
              onChange={(e) => {
                if (!isNaN(Number(e.value))) {
                  this.setState({
                    statusType: {
                      ...this.state.statusType,
                      maximumAmount: e.value,
                    },
                  });
                }
              }}
              min={0}
              max={365}
              step={1}
              required
              mode="decimal"
              showButtons
              minFractionDigits={2}
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Status{" "}
              <i
                className="fas fa-info-circle informationStyle"
                data-tip=""
                data-for="status"
              ></i>{" "}
              <span style={{ color: "red" }}>*</span>
            </span>
            <ReactTooltip
              id="status"
              aria-haspopup="true"
              
              className="tooltipFont"
            >
              <p>
                Active: Employees can apply for this leave. <br></br>
                Unappliable: Employees cannot apply for this leave. Only Admin
                can apply for them on their behalf.
              </p>
            </ReactTooltip>
            <Dropdown
              value={this.state.statusType.statusTypeStatus}
              options={this.statusTypeStatusList}
              onChange={(e) =>
                this.setState({
                  statusType: {
                    ...this.state.statusType,
                    statusTypeStatus: e.value,
                  },
                })
              }
              required
            />
          </div>
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button type="submit" label={this.state.submitType} />
        </div>
      </form>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />; // or some growl/message that stays
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = form;
    }

    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{this.state.submitType + " Status Type"}</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {display}
          </div>
        </div>
      </div>
    );
  }
}
