import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import { ScoreIndicatorService } from "../../../service/ScoreIndicatorService";
import {
  CommonMethods,
  RouteComponentProps
} from "../../resources/CommonMethods";
import CustomError from "../../resources/Error";
import { ToastStateContext } from "../../resources/ToastContext";
import { CompanyDepartmentModel } from "../../settings/MultiDeptSetting";
import { ScoreIndicatorModel } from "./ScoreIndicatorModel";

interface PropsOthers {
  departmentID: string;
  viewOnly: boolean;
  userRanks: any[];
  customView: boolean;
}

function OneDeptScoreIndicator(props: RouteComponentProps) {
  const commonMethods = new CommonMethods();
  const sis = new ScoreIndicatorService();

  const { SetSuccess, SetError } = React.useContext(ToastStateContext);
  const [userRanks, setUserRanks] = useState<any>([]);
  const [
    createCustomDialogVisibility,
    setCreateCustomDialogVisibility,
  ] = useState(false);
  const [
    updateCustomDialogVisibility,
    setUpdateCustomDialogVisibility,
  ] = useState(false);
  const [createDialogVisibility, setCreateDialogVisibility] = useState(false);
  const [updateDialogVisibility, setUpdateDialogVisibility] = useState(false);

  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [dialogHeader, setDialogHeader] = useState<string>("");
  const [dialogContents, setDialogContents] = useState<JSX.Element>(<></>);

  const [deptScoreIndicatorList, setDeptScoreIndicatorList] = useState<
    Array<ScoreIndicatorModel>
  >();

  const [deptCompanyModel, setDeptCompanyModel] = useState<
    CompanyDepartmentModel
  >(new CompanyDepartmentModel());
  const [scoreIndiModel, setScoreIndiModel] = useState<ScoreIndicatorModel>(
    new ScoreIndicatorModel()
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [viewOnly, setViewOnly] = useState<boolean>(true);
  // const [adminOnly] = useState<boolean>(
  //   props.userProfile.taskList.includes(TaskList.scoreindicatoraccess)
  // );

  const [searchValue, setSearchValue] = useState<string>("");
  const dt = useRef<any>(null);
  const [rankFilterValue, setRankFilterValue] = useState(null);
  const [customFilterValue, setCustomFilterValue] = useState(null);

  let otherProps: PropsOthers = props.others;

  let customDropdown = [
    { label: "Y", value: "true" },
    { label: "N", value: "false" },
  ];

  useEffect(() => {
    otherProps = props.others;
    if (otherProps.departmentID !== "") {
      getDeptScoring();
      setViewOnly(otherProps.viewOnly);
      setUserRanks(otherProps.userRanks);
    }
  }, [props.others]);

  const getDeptScoring = () => {
    sis
      .getDepartmentScoreIndicators(otherProps.departmentID)
      .then((res) => {
        setDeptScoreIndicatorList(res.scoreIndicatorList);
        const companyDept: CompanyDepartmentModel = res.companyDepartmentModel;
        setDeptCompanyModel(companyDept);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
        setIsLoading(false);
      });
  };

  const displayTitle = () => {
    if (otherProps.customView) {
      return (
        "KPI Scoring - " +
        deptCompanyModel.departmentName +
        " [" +
        deptCompanyModel.companyName +
        "]"
      );
    } else {
      return "KPI Scoring - " + deptCompanyModel.departmentName;
    }
  };

  const confirmDeleteDialog = (rowData: any) => {
    setDialogHeader("Confirm Delete");
    setDialogContents(
      <>
        <p>Are you sure you want to delete {rowData.indicatorName}?</p>
        <div className="row text-right">
          <div className="col">
            {/* <Button
              type="button"
              label="No"
              icon="pi pi-times"
              className="p-button-warning mx-1"
              onClick={e => {
                setDialogVisibility(false);
              }}
            /> */}
            <Button
              type="button"
              icon="pi pi-trash"
              label="Yes"
              className="p-button-danger"
              onClick={(e) => {
                e.preventDefault();
                setIsLoading(true);

                sis
                  .deleteScoreByID(rowData.indicatorID)
                  .then((res) => {
                    SetSuccess("KPI successfully deleted!");
                    setDialogVisibility(false);
                    setIsLoading(false);
                    // otherProps.callbackRefresh();
                    getDeptScoring();
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    setDialogVisibility(false);
                    SetError("Unable to delete KPI! KPI could be in use.");
                  });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const displayCustom = (rowData: any) => {
    if (rowData.customPoint) {
      return "Y";
    } else {
      return "N";
    }
  };

  const actionTemplate = (rowData: ScoreIndicatorModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning mx-1"
          onClick={(e) => {
            e.preventDefault();

            setScoreIndiModel(rowData);
            if (rowData.customPoint) {
              setUpdateCustomDialogVisibility(true);
            } else {
              setUpdateDialogVisibility(true);
            }
            setDialogHeader("Update");
          }}
        />
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={(e) => {
            e.preventDefault();
            confirmDeleteDialog(rowData);
            setDialogVisibility(true);
          }}
        />
      </div>
    );
  };

  const submitUpdateForm = (event: any) => {
    setIsLoading(true);
    setUpdateCustomDialogVisibility(false);
    setUpdateDialogVisibility(false);
    event.preventDefault();
    sis
      .updateScoreIndicator(scoreIndiModel)
      .then((res) => {
        SetSuccess("KPI successfully updated!");
        setIsLoading(false);

        // otherProps.callbackRefresh();
        getDeptScoring();
      })
      .catch((err) => {
        SetError("Error in updating KPI!");
      });
  };

  const submitForm = (event: any) => {
    setIsLoading(true);
    setCreateCustomDialogVisibility(false);
    setCreateDialogVisibility(false);

    event.preventDefault();
    sis
      .createScoreIndicator(scoreIndiModel)
      .then((res) => {
        SetSuccess("KPI successfully created!");
        setIsLoading(false);

        // otherProps.callbackRefresh();
        getDeptScoring();
      })
      .catch((err) => {
        SetError("Error in creating KPI!");
      });
  };

  const rankFilter = (
    <span>
      <Dropdown
        showClear
        className="mb-1 mr-1"
        value={rankFilterValue}
        options={userRanks}
        onChange={(e) => {
          setRankFilterValue(e.value);
          onRankChange(e);
        }}
        placeholder="Filter by Rank"
      />
    </span>
  );

  const customFilter = (
    <span>
      <Dropdown
        showClear
        value={customFilterValue}
        options={customDropdown}
        onChange={(e) => {
          setCustomFilterValue(e.value);
          onCustomChange(e);
        }}
        
        placeholder="Filter by Custom"
      />
    </span>
  );

  const onRankChange = ((e:any) => {
    let rankName = userRanks.filter((label:any) => label.value === e.value).map((item:any) => item.label)[0];
    dt.current.filter(rankName, 'userRankName', 'equals');
  });

  const onCustomChange = ((e: any) => {
    if (e.value === "true"){
      dt.current.filter(true, 'customPoint', 'equals');
    } else if (e.value === "false"){
      dt.current.filter(false, 'customPoint', 'equals');
    } else {
      dt.current.filter("", 'customPoint', 'in');
    }
  });

  let tableheader = (
    <div style={{ textAlign: "left" }}>
      <span className="p-input-icon-left mr-1 mb-1">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e: any) => setSearchValue(e.currentTarget.value)}
        />
      </span>
      {rankFilter}
      {customFilter}
    </div>
  );
  let createCustomForm = (
    <form onSubmit={submitForm}>
      <div className="p-grid justify-content-center">
        <div className="p-col-12 p-fluid">
          <span className="boldLabel">Name</span>
          <InputText
            value={scoreIndiModel.indicatorName}
            onChange={(e) =>
              setScoreIndiModel({
                ...scoreIndiModel,
                indicatorName: e.currentTarget.value,
              })
            }
            required
          />
        </div>
        <div className="p-col-12 p-fluid">
          <span className="boldLabel">Employee Type</span>
          <Dropdown
            placeholder="Please Select"
            options={userRanks}
            value={scoreIndiModel.userRankID}
            onChange={(e) => {
              setScoreIndiModel({
                ...scoreIndiModel,
                userRankID: e.value,
              });
            }}
            required
          />
        </div>

        <div className="p-col-12 p-fluid">
          <span className="boldLabel">
            Points{" "}
            <i
              className="fas fa-info-circle informationStyle"
              data-tip=""
              data-for="kpitarget"
            ></i>{" "}
          </span>

          <InputNumber
            step={0.01}
            disabled={scoreIndiModel.customPoint}
            value={0}
            mode="decimal"
            showButtons
            minFractionDigits={2}
          />

          <ReactTooltip
            id="kpitarget"
            aria-haspopup="true"
            className="tooltipFont"
          >
            <p className="text-center mb-0">
              This is 0 for Custom KPI as actual points will be calculated by
              employees themselves.
            </p>
          </ReactTooltip>
        </div>
      </div>
      <div className="p-grid p-justify-center pt-4">
        <Button
          label="Submit"
          type="submit"
          className="p-button-success"
          // onClick={() => {

          // }}
        />
      </div>
    </form>
  );

  let updateCustomForm = (
    <form onSubmit={submitUpdateForm}>
      <div className="p-grid justify-content-center">
        <div className="p-col-12 p-fluid">
          <span className="boldLabel">Name</span>
          <InputText
            placeholder="Task Name"
            value={scoreIndiModel.indicatorName}
            onChange={(e) => {
              setScoreIndiModel({
                ...scoreIndiModel,
                indicatorName: e.currentTarget.value,
              });
            }}
            required
          />
        </div>
        <div className="p-col-12 p-fluid">
          <span className="boldLabel">Employee Type</span>
          <Dropdown
            options={userRanks}
            placeholder="Please Select"
            value={scoreIndiModel.userRankID}
            onChange={(e) => {
              setScoreIndiModel({
                ...scoreIndiModel,
                userRankID: e.value,
              });
            }}
            disabled
          />
        </div>

        <div className="p-col-12 p-fluid" data-for="kpitarget" data-tip="">
          <span className="boldLabel">Points</span>

          <InputNumber
            step={0.01}
            disabled={scoreIndiModel.customPoint}
            value={0}
            mode="decimal"
            showButtons
            minFractionDigits={2}
          />

          <ReactTooltip
            id="kpitarget"
            aria-haspopup="true"
            className="tooltipFont"
          >
            <p className="text-center mb-0">
              This is 0 for Custom KPI as actual points will be calculated by
              employees themselves.
            </p>
          </ReactTooltip>
        </div>
      </div>
      <div className="p-grid p-justify-center pt-4">
        <Button label="Submit" type="submit" className="p-button-success" />
      </div>
    </form>
  );

  let createForm = (
    <form onSubmit={submitForm}>
      <div className="p-grid justify-content-center">
        <div className="p-col-12 p-fluid">
          <span className="boldLabel">Name</span>

          <InputText
            value={scoreIndiModel.indicatorName}
            onChange={(e) =>
              setScoreIndiModel({
                ...scoreIndiModel,
                indicatorName: e.currentTarget.value,
              })
            }
            required
          />
        </div>
        <div className="p-col-12 p-fluid">
          <span className="boldLabel">Employee Type</span>
          <Dropdown
            options={userRanks}
            placeholder="Please Select"
            value={scoreIndiModel.userRankID}
            onChange={(e) => {
              setScoreIndiModel({
                ...scoreIndiModel,
                userRankID: e.value,
              });
            }}
            required
          />
        </div>
        <div className="p-col-12 p-fluid">
          <span className="boldLabel">Points</span>
          <InputNumber
            step={0.01}
            value={scoreIndiModel.points}
            onChange={(e) => {
              setScoreIndiModel({ ...scoreIndiModel, points: e.value });
            }}
            mode="decimal"
            showButtons
            minFractionDigits={2}
          />
        </div>
      </div>
      <div className="p-grid p-justify-center pt-4">
        <Button label="Submit" type="submit" className="p-button-success" />
      </div>
    </form>
  );

  let updateForm = (
    <form onSubmit={submitUpdateForm}>
      <div className="p-grid justify-content-center">
        <div className="p-col-12 p-fluid">
          <span className="boldLabel">Name</span>
          <InputText
            value={scoreIndiModel.indicatorName}
            onChange={(e) => {
              setScoreIndiModel({
                ...scoreIndiModel,
                indicatorName: e.currentTarget.value,
              });
            }}
          />
        </div>
        <div className="p-col-12 p-fluid">
          <span className="boldLabel">Employee Type</span>
          <Dropdown
            options={userRanks}
            placeholder="Please Select"
            value={scoreIndiModel.userRankID}
            onChange={(e) => {
              setScoreIndiModel({
                ...scoreIndiModel,
                indicatorName: e.value,
              });
            }}
            disabled
          />
        </div>
        <div className="p-col-12 p-fluid">
          <span className="boldLabel">Points</span>
          <InputNumber
            step={0.01}
            value={scoreIndiModel.points}
            onChange={(e) => {
              setScoreIndiModel({ ...scoreIndiModel, points: e.value });
            }}
            mode="decimal"
            showButtons
            minFractionDigits={2}
          />
        </div>
      </div>
      <div className="p-grid p-justify-center pt-4">
        <Button label="Update" type="submit" className="p-button-success" />
      </div>
    </form>
  );

  let display;
  if (isLoading) {
    display = <ProgressSpinner />;
  } else if (isError) {
    display = <CustomError message={errorMsg} />;
  } else {
    display = (
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          ref={dt}
          value={deptScoreIndicatorList}
          rowGroupMode="rowspan"
          sortField="indicatorName"
          sortOrder={1}
          groupField="indicatorName"
          paginator={true}
          rows={viewOnly ? 25 : 15}
          autoLayout={true}
          alwaysShowPaginator={false}
          emptyMessage="No results found."
          header={tableheader}
          globalFilter={searchValue}
        >
          <Column field="indicatorName" header="Task" />
          <Column field="userRankName" header="Rank" filterElement={rankFilter} />
          <Column field="userRankID" header="Rank" className="d-none" />
          <Column field="customPoint" header="Point" className="d-none" filterElement={customFilter}/>
          <Column field="custom" body={displayCustom} header="Custom"/>
          <Column field="points" header="Points" className="text-center" />
          {/* {adminOnly && ( */}
          {!viewOnly && (
            <Column
              body={actionTemplate}
              header="Action"
              style={{ width: "8rem" }}
            />
          )}
          {/* )} */}
        </DataTable>
      </div>
    );
  }

  return (
    <div className="card">
      <h1 className="pb-2">
        {displayTitle()}
        {!viewOnly && (
          <span className="pl-3">
            <Button
              type="button"
              icon="pi pi-plus"
              iconPos="right"
              label="Add"
              className="p-button-danger"
              onClick={() => {
                setScoreIndiModel({
                  ...new ScoreIndicatorModel(),
                  departmentID: deptCompanyModel.departmentID,
                  customPoint: false,
                });
                setDialogContents(createForm);
                setCreateDialogVisibility(true);
                setDialogHeader(
                  "Create new KPI Point - " + deptCompanyModel.departmentName
                );
              }}
            />
          </span>
        )}
        {!viewOnly && (
          <span className="mx-1">
            <Button
              type="button"
              icon="pi pi-plus"
              iconPos="right"
              label="Add Custom"
              className="p-button-info mt-1"
              onClick={() => {
                setScoreIndiModel({
                  ...new ScoreIndicatorModel(),
                  departmentID: deptCompanyModel.departmentID,
                  customPoint: true,
                });
                // setDialogContents(createCustomForm);
                setCreateCustomDialogVisibility(true);
                setDialogHeader(
                  "Create new custom KPI - " + deptCompanyModel.departmentName
                );
              }}
            />
          </span>
        )}
      </h1>
      {display}
      <Dialog
        visible={dialogVisibility}
        header={dialogHeader}
        modal={true}
        onHide={() => {
          setDialogVisibility(false);
        }}
      >
        {dialogContents}
      </Dialog>

      <Dialog
        visible={createDialogVisibility}
        header={"Create KPI"}
        modal={true}
        onHide={() => {
          setCreateDialogVisibility(false);
        }}
      >
        {createForm}
      </Dialog>
      <Dialog
        visible={createCustomDialogVisibility}
        header={"Create Custom KPI"}
        modal={true}
        onHide={() => {
          setCreateCustomDialogVisibility(false);
        }}
      >
        {createCustomForm}
      </Dialog>
      <Dialog
        visible={updateDialogVisibility}
        header={dialogHeader}
        modal={true}
        onHide={() => {
          setUpdateDialogVisibility(false);
        }}
      >
        {updateForm}
      </Dialog>
      <Dialog
        visible={updateCustomDialogVisibility}
        header={dialogHeader}
        modal={true}
        onHide={() => {
          setUpdateCustomDialogVisibility(false);
        }}
      >
        {updateCustomForm}
      </Dialog>
    </div>
  );
}

export default OneDeptScoreIndicator;
