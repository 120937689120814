import React from "react";
import { Button } from "primereact/button";

function AboutUs() {
  return (
    <div>
      <section
        className="fdb-block pt-0"
        data-block-type="call_to_action"
        data-id="2"
      >
        <div className="container">
          <div className="row align-items-center pb-4">
            <div className="col-12 text-center">
              <h1>Specially designed for SMEs</h1>
              {/* <p className="h3">Specially designed for SMEs</p> */}
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row align-items-center pb-4">
            {/* <div className="col-12 text-center"> */}
            <img
              alt=""
              className="img-fluid"
              src="/assets/thumbnails/tsl-office-full.jpg"
            />
            {/* </div> */}
          </div>

          <div className="container  py-4">
            <div className="row  justify-content-center text-sm-left text-center">
              <div className="col-10">
                <h3>Our Story</h3>
                <p className="lead">
                  NeuHR was originally created for TheSmartLocal as an internal
                  office management system. After receiving many requests from
                  visitors to our office to use our system, we've decided to do
                  just that! <br></br>Now, any company will be able to use our
                  fully customisable office management system that automates
                  Payroll, HR, Claims, Leaves and other administrative work.
                </p>

                {/* <p className="lead">
                  NeuHR is specially designed for SMEs and our dedicated support
                  team will walk you through the whole process if needed.
                </p> */}
                <br></br>
              </div>
            </div>
            <div className="row align-items-center text-center justify-content-center py-5 colouredbgroundedcard ">
              <div className="col-12">
                <h2 className="text-center">
                  Start your automated journey with NeuHR
                </h2>
              </div>
              <p style={{ fontSize: "22px" }}>
                A comprehensive SaaS HR Solution for your SME.
              </p>
              <div className="col-12 py-3">
                <Button
                  className="btn btn-primary btn-darker mx-1 my-1"
                  onClick={() => (window.location.href = "/contactus")}
                  label="Request Demo"
                />
                <Button
                  className="btn btn-primary btn-login"
                  onClick={() => (window.location.href = "/sme")}
                  label="Try for free"
                />
              </div>
              <b>90 days free trial. No credit card required.</b>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
