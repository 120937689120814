import React, { useEffect, useState } from "react";
import ViewClaims from "./ViewClaims";
import { ClaimViewType } from "./ClaimsModel";

export function ApproveClaimsPage(props: any) {
  const [companyID, setCompanyID] = useState(
    localStorage.getItem("currentAccessingCompany") || ""
  );

  const [updateComponentAll, setUpdateComponentAll] = useState<boolean>(false);
  const [updateComponent, setUpdateComponent] = useState<boolean>(false);

  if (companyID === "") {
    setCompanyID(props.userProfile.companyID);
  }
  useEffect(() => {
    console.log(props);
  }, []);

  const callbackfunc = (refresh: boolean) => {
    setUpdateComponent(refresh);
  };

  const callbackfuncAll = (refresh: boolean) => {
    setUpdateComponentAll(refresh);
  };

  return (
    <>
      <div className="row">
        <div
          className="col-12 col-xl-12"
          data-tour-id="approve-claims"
          data-tour=" These are the claims endorsed by Supervisors waiting for approval. View the claim in detail to approve or reject it."
        >
          <div className="card">
            <h1>Approve Claims</h1>
            <p>
              These are the claims endorsed by Supervisors waiting for approval.
            </p>
            {
              <ViewClaims
                others={ClaimViewType.Approving}
                callbackfunc={callbackfunc}
                {...props}
              />
            }
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 col-xl-12"
          data-tour-id="process-claims"
          data-tour="These are approved claims waiting to be processed. Select the claims using the checkboxes then click export, a DBS bank formatted text file will be download for your submission."
        >
          <div className="card">
            <h1>Pending Processing</h1>
            <p>
              These are claims approved by Finance waiting to be processed for
              payment. Click Export to process them.
            </p>
            {updateComponentAll && (
              <ViewClaims
                others={ClaimViewType.ApprovingHistory}
                callbackfunc={callbackfuncAll}
                callbackBool={updateComponentAll}
                {...props}
              />
            )}
            {!updateComponentAll && (
              <ViewClaims
                others={ClaimViewType.ApprovingHistory}
                callbackfunc={callbackfuncAll}
                callbackBool={updateComponentAll}
                {...props}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 col-xl-12"
          data-tour-id="all-claims-history"
          data-tour="Here is the history of all the approved/rejected/processed claims for your reference."
        >
          <div className="card">
            <h1>All Claims History</h1>
            {(updateComponent || updateComponentAll) && (
              <ViewClaims others={ClaimViewType.AllHistory} {...props} />
            )}
            {!(updateComponent || updateComponentAll) && (
              <ViewClaims others={ClaimViewType.AllHistory} {...props} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
