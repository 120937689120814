import * as React from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Redirect, RouteComponentProps } from "react-router";
import { JobTitleService } from "../../service/JobTitleService";
import { InputText } from "primereact/inputtext";
import { CompanyService } from "../../service/CompanyService";
import { Dropdown } from "primereact/dropdown";
import { DepartmentService } from "../../service/DepartmentService";
import { Dialog } from "primereact/dialog";
import { CommonMethods } from "../resources/CommonMethods";
import { ProgressSpinner } from "primereact/progressspinner";
import  CustomError  from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { AppService } from "../../service/AppService";

interface AppProps {}

interface AppState {
  jobTitleList: [];
  jobTitleObj: JobTitleModel;
  redirectOut: boolean;
  redirectPath: string;

  isLoading: boolean;
  isDialogVisible: boolean;

  isRedirectDialogVisible: boolean;

  isError: boolean;
  errorMsg: string;
  dialogStr: string;

  searchValue: string;


}

export class JobTitleModel {
  public companyID: string = "";
  public departmentID: string = "";
  public jobTitle: string = "";
  public jobTitleID: string = CommonMethods.EmptyGuid;
  public companyName: string = "";
  public departmentName: string = "";
}

export class JobTitleSetting extends React.Component<AppProps, AppState> {
  static contextType = ToastStateContext;

  jobTitleService: JobTitleService;
  commonMethods: CommonMethods;

  constructor(props: AppProps) {
    super(props);
    this.state = {
      jobTitleList: [],
      jobTitleObj: new JobTitleModel(),
      redirectOut: false,
      redirectPath: "",
      isLoading: true,
      isDialogVisible: false,
      isRedirectDialogVisible: false,

      isError: false,
      errorMsg: "",
      dialogStr: "",
      searchValue: ""
    };
    this.jobTitleService = new JobTitleService();
    this.commonMethods = new CommonMethods();
    this.actionButton = this.actionButton.bind(this);
    this.employeeListButton = this.employeeListButton.bind(this);
  }
  componentDidMount() {
    this.getJobTitleList();
  }

  getJobTitleList() {
    let currentAccessingCompanyId =
      localStorage.getItem("currentAccessingCompany") || "";

    this.jobTitleService
      .getJobTitles(currentAccessingCompanyId)
      .then(res => {
        this.setState({ jobTitleList: res, isLoading: false });
      })
      .catch(error => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg
        });
        return errorMsg;
      });
  }
  actionButton(rowData: JobTitleModel) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning mx-1"
          onClick={() => {
            this.updateItem(rowData);
          }}
        />

        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            this.deleteItem(rowData);
          }}
        />
      </div>
    );
  }

  employeeListButton(rowData: JobTitleModel) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="button"
          icon="pi pi-users"
          className="p-button-info"
          onClick={() => {
            this.viewEmployees(rowData);
          }}
        />
      </div>
    );
  }

  viewEmployees(rowData: JobTitleModel) {
    this.setState({ jobTitleObj: rowData });
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/employees/all/JobTitle/" + rowData.jobTitleID
    });
  }

  addItem() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/job_title/create"
    });
  }

  updateItem(rowData: JobTitleModel) {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/job_title/update/" + rowData.jobTitleID
    });
  }

  deleteItem(rowData: JobTitleModel) {
    this.setState({
      isDialogVisible: true,
      jobTitleObj: rowData
    });
  }

  confirmDelete() {
    this.setState(
      {
        isLoading: true,
        isDialogVisible: false
      },
      () => {
        this.jobTitleService
          .deleteJobtitle(this.state.jobTitleObj)
          .then(res => {
            this.getJobTitleList();
            this.setState({ isLoading: false });

            let msg = this.state.jobTitleObj.jobTitle + " has been deleted.";
            this.context.SetSuccess(msg);
          })
          .catch(error => {
            let errorMsg = this.commonMethods.getErrorMessage(error);

            this.context.SetError(errorMsg);
            this.setState({
              isLoading: false
            });
          });
      }
    );
  }

  redirectToUsers() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/employees/all/JobTitle/" + this.state.jobTitleObj.jobTitleID
    });
  }

  tableheader() {
    return (
      <div style={{ textAlign: "left" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            onInput={(e: any) => {
              this.setState({ searchValue: e.currentTarget.value });
            }}
          />
        </span>
      </div>
    );
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectPath
          }}
          push
        />
      );
    }

    let form = (
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          rowGroupMode="rowspan"
          sortField="departmentName"
          sortOrder={1}
          groupField="departmentName"
          value={this.state.jobTitleList}
          autoLayout={true}
          emptyMessage="No results found."
          paginator={true}
          rows={15}
          alwaysShowPaginator={false}
          header={this.tableheader()}
          globalFilter={this.state.searchValue}
        >
          <Column className="p-col" field="jobTitle" header="Job Title" />
          <Column
            className="p-col"
            field="departmentName"
            header="Department"
          />

          <Column
            className="p-col-2"
            body={this.employeeListButton}
            header="Employee List"
          />
          <Column
            className="p-col-2"
            body={this.actionButton}
            header="Action"
          />
        </DataTable>
      </div>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = form;
    }

    return (
      <div className="row">
        <div className="col-12 col-xl-10">
          <div className="card">
            <h1 className="pb-2">
              Job Titles
              <span className="pl-3">
                <Button
                  type="button"
                  icon="pi pi-plus"
                  iconPos="right"
                  label="Add"
                  className="p-button-danger"
                  onClick={() => {
                    this.addItem();
                  }}
                />
              </span>
            </h1>

            {display}

            <Dialog
              className="popupDialog"
              header={"Delete " + this.state.jobTitleObj.jobTitle}
              visible={this.state.isDialogVisible}
              modal={true}
              //dismissableMask={true}
              onHide={() => this.setState({ isDialogVisible: false })}
              footer={
                <div>
                  <Button
                    label="Yes"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={() => this.confirmDelete()}
                  />
                  <Button
                    label="No"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => this.setState({ isDialogVisible: false })}
                  />
                </div>
              }
            >
              Are you sure you want to delete this job title?
            </Dialog>

            <Dialog
              className="popupDialog"
              header={"Unable to delete " + this.state.jobTitleObj.jobTitle}
              visible={this.state.isRedirectDialogVisible}
              modal={true}
              //dismissableMask={true}
              onHide={() => this.setState({ isRedirectDialogVisible: false })}
              footer={
                <div>
                  <Button
                    label="Proceed to Users"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={() => this.redirectToUsers()}
                  />
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() =>
                      this.setState({ isRedirectDialogVisible: false })
                    }
                  />
                </div>
              }
            >
              {" "}
              {this.state.dialogStr} You can view them on Employee List.
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

interface AppStateCreate {
  jobTitleList: [];
  jobTitleObj: JobTitleModel;

  companyDropdownList: [];
  departmentDropdownList: [];

  redirectOut: boolean;
  redirectPath: string;

  isLoading: boolean;
  action: string;
  formHeader: string;

  isError: boolean;
  errorMsg: string;
  setupCreate: boolean;
}

export class JobTitleSettingCreate extends React.Component<
  RouteComponentProps<any>,
  AppStateCreate
> {
  static contextType = ToastStateContext;

  jobTitleService: JobTitleService;
  companyService: CompanyService;
  departmentService: DepartmentService;
  commonMethods: CommonMethods;
  appService: AppService;
  taskid: string = "eaf585f7-7060-4af2-8d5a-8880edce40ba";

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      jobTitleList: [],
      jobTitleObj: new JobTitleModel(),
      companyDropdownList: [],
      departmentDropdownList: [],
      redirectOut: false,
      redirectPath: "",
      isLoading: true,
      action: "",
      formHeader: "",
      isError: false,
      errorMsg: "",
      setupCreate: false,
    };
    this.jobTitleService = new JobTitleService();
    this.companyService = new CompanyService();
    this.departmentService = new DepartmentService();
    this.submitForm = this.submitForm.bind(this);
    this.commonMethods = new CommonMethods();
    this.appService = new AppService();
  }

  componentDidMount() {
    this.setupProgress();
    let currentAccessingCompanyId = localStorage.getItem(
      "currentAccessingCompany"
    );
    let currentAccessingCompanyName = localStorage.getItem(
      "currentAccessingCompanyName"
    );
    if (
      this.props.match.params !== {} &&
      this.props.match.params.id !== undefined
    ) {
      var IDfromQuery = this.props.match.params.id;

      this.setState({
        action: "update",
        formHeader: "Update Job Title"
      });
      this.getJobTitlesFromDB(IDfromQuery);
    } else {
      this.setState(
        {
          formHeader: "Create Job Title",
          action: "create",
          isLoading: false,
          jobTitleObj: {
            ...this.state.jobTitleObj,
            companyID: currentAccessingCompanyId || "",
            companyName: currentAccessingCompanyName || ""
          }
        },
        () => {
          this.getDropdownLists();
        }
      );
    }
  }

  setupProgress() {
    this.appService
      .getAppTopBar()
      .then((res: any) => {
        if (!res.setup.includes("JobTitle")) {
          this.setState({setupCreate: true});
        }
      })
      .catch ((err) => {
        this.context.SetError(this.commonMethods.getErrorMessage(err));
      })
  }

  getJobTitlesFromDB(IDfromQuery: string) {
    this.jobTitleService
      .getJobTitleById(IDfromQuery)
      .then(res => {
        this.setState({ jobTitleObj: res, isLoading: false }, () => {
          // set companyName
          this.commonMethods
            .getCompanyDropdownListByGcid()
            .then(res => {
              this.setState({ companyDropdownList: res }, () => {
                let item = this.state.companyDropdownList.find(
                  (x: { label: string; value: string }) =>
                    x.value === this.state.jobTitleObj.companyID
                ) || { label: "", value: "" };
                this.setState({
                  jobTitleObj: {
                    ...this.state.jobTitleObj,
                    companyName: item.label || ""
                  }
                });
              });
            })
            .catch(error => {
              this.setState({
                isError: true,
                isLoading: false,
                errorMsg: error.response.data.error
              });
              return error.response.data.error;
            });
        });
      })
      .catch(error => {
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: error.response.data.error
        });
        return error.response.data.error;
      })
      .finally(() => {
        this.getDropdownLists();
      });
  }

  getDropdownLists() {
    this.commonMethods
      .getDepartmentDropdownList(this.state.jobTitleObj.companyID)
      .then(res => {
        this.setState({ departmentDropdownList: res });
      })
      .catch(error => {
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: error.response.data.error
        });
        return error.response.data.error;
      });
  }

  updateProperty(property: string, value: any) {
    let tempUserObj = this.state.jobTitleObj;
    (tempUserObj as any)[property] = value;
    this.setState({
      jobTitleObj: tempUserObj
    });
  }

  goBack() {
    this.setState({
      redirectOut: true,
      redirectPath: "/admin/job_title"
    });
  }

  submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // to prevent page from refreshing!
    this.setState({ isLoading: true });
    if (this.state.action === "create") {
      this.jobTitleService
        .createJobTitle(this.state.jobTitleObj)
        .then(res => {
          if (!this.state.setupCreate) {
            let msg = res.jobTitle + " has been created.";
            this.context.SetSuccess(msg);
            this.goBack();
          } else {
            this.goBack();
            window.location.reload();
          }
        })
        .catch(error => {
          let errorMsg = this.commonMethods.getErrorMessage(error);

          this.context.SetError(errorMsg);

          this.setState({
            isLoading: false
          });
        });
    } else if (this.state.action === "update") {
      this.jobTitleService
        .updateJobTitle(this.state.jobTitleObj)
        .then(res => {
          let msg = res.jobTitle + " has been updated.";
          this.context.SetSuccess(msg);
          this.goBack();
        })
        .catch(error => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.context.SetError(errorMsg);

          this.setState({
            isLoading: false
          });
        });
    }
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath
          }}
        />
      );
    }

    let form = (
      <form onSubmit={this.submitForm}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">Department <span style={{color: "red"}}>*</span></span>

            <Dropdown
              value={this.state.jobTitleObj.departmentID}
              options={this.state.departmentDropdownList}
              onChange={e => {
                this.updateProperty("departmentID", e.value);
              }}
              placeholder="Please Select"
              required
            />
          </div>

          <div className="p-col-12">
            <span className="boldLabel">Job Title <span style={{color: "red"}}>*</span></span>
            <InputText
              id="jobTitle"
              onChange={e => {
                this.updateProperty("jobTitle", e.currentTarget.value);
              }}
              value={this.state.jobTitleObj.jobTitle}
              required
            />
          </div>
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button
            type="submit"
            label={this.state.action === "create" ? "Submit" : "Update"}
          />
        </div>
      </form>
    );

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />;
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = form;
    }

    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{this.state.formHeader}</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {display}
          </div>
        </div>
      </div>
    );
  }
}
