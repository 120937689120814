import React from "react";
import { RouteComponentProps } from "../resources/CommonMethods";
import QuickLinksWidget from "../quicklinks/QuickLinksWidget";
import CircularsWidget from "../circulars/CircularsWidget";

export function QuickLinks(props: RouteComponentProps<any>) {
  return (
    <div className="row">
      
      <div className="col-12 col-lg-8">
        <CircularsWidget {...props} />
      </div>
      
      <div className="col-12 col-lg-4"> 
        {" "}
        <QuickLinksWidget {...props} /> 
      </div>
    </div>
  );
}
