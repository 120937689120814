import React, { useEffect, useState } from 'react'
import { PostModel, PreviewProps, UserPreview } from '../../Model/PostModel';
import EditPost from './Forms/EditPost';
import { checkElementAboveMiddleVH } from '../../CompanyFeedFunctions'
import Post from './Post';

export default function Posts({
    posts,
}: {
    posts: PostModel[];
}) {
    const [postToEdit, setPostToEdit] = useState<PostModel>(new PostModel())
    const [isEditPost, setIsEditPost] = useState(false)
    const [isShowEditDialog, setIsShowEditDialog] = useState(false)
    const [previewData, setPreviewData] = useState<UserPreview | null>(null)
    const [previewProps, setPreviewProps] = useState(new PreviewProps())
    const contextName = "CompanyFeed"

    useEffect(() => {
        if (previewProps.previewPosition) setPreviewProps(prevState => (
            {
                ...prevState,
                isElementAboveMiddleVH: checkElementAboveMiddleVH(previewProps.previewPosition)
            }
        ))
    }, [previewProps.previewPosition])

    return (
        <div>{posts?.map((post: PostModel, index: number) => (
            <div key={post.postId} className="each-post">
                <Post
                    post={post}
                    setPostToEdit={setPostToEdit}
                    setIsEditPost={setIsEditPost}
                    setIsShowEditDialog={setIsShowEditDialog}
                    previewData={previewData}
                    setPreviewData={setPreviewData}
                    previewProps={previewProps}
                    setPreviewProps={setPreviewProps}
                    contextName={contextName}
                />
            </div>))}
            {isEditPost ?
                <EditPost
                    setIsEditPost={setIsEditPost}
                    isShowEditDialog={isShowEditDialog}
                    setIsShowEditDialog={setIsShowEditDialog}
                    post={postToEdit}
                    contextName={contextName}
                />
                : null}
        </div>
    )
}













