import React from "react";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import { TaskService } from "../../service/TaskService";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { Redirect } from "react-router";
import { MenuList } from "../resources/MenuList";
import { Dropdown } from "primereact/dropdown";
import { ToastStateContext } from "../resources/ToastContext";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import PremiumPlanConversion from "./PremiumPlanConversion";
import { Dialog } from "primereact/dialog";

export class TaskModuleModel {
  public taskModuleID: string = "";
  public menuModule: string = "";
  public taskAccess: string = "";
  public taskConfigName: string = "";
  public defaultTask: boolean = true;
  public createdTime: Date = new Date();
  public addtoAdminRole: boolean = false;
}
interface AppState {
  currentTaskModules: Array<TaskModuleModel>;
  taskList: Array<TaskModuleModel>;
  newTaskModule: TaskModuleModel;
  newTaskAccess: boolean;

  redirectOut: boolean;
  redirectPath: string;

  dialogDeleteVisibility: boolean;
  dialogVisibility: boolean;

  selectedTask: TaskModuleModel;
  searchValue: string;
}

export class TaskModuleSetting extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  static contextType = ToastStateContext;
  taskService: TaskService;
  commonMethods: CommonMethods;
  menuList: MenuList;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      currentTaskModules: [],
      taskList: [],
      newTaskModule: new TaskModuleModel(),

      newTaskAccess: false,

      redirectOut: false,
      redirectPath: "",

      dialogDeleteVisibility: false,
      dialogVisibility: false,
      selectedTask: new TaskModuleModel(),
      searchValue: "",
    };

    this.taskService = new TaskService();
    this.commonMethods = new CommonMethods();

    this.menuList = new MenuList();

    this.getAllTasks = this.getAllTasks.bind(this);
    this.getAllTaskModule = this.getAllTaskModule.bind(this);
    this.renderTaskDropdownList = this.renderTaskDropdownList.bind(this);
    this.renderModuleDropdownList = this.renderModuleDropdownList.bind(this);
    this.saveNewTask = this.saveNewTask.bind(this);
    this.actionButtons = this.actionButtons.bind(this);
    this.deleteThisTaskModule = this.deleteThisTaskModule.bind(this);
  }

  componentDidMount() {
    this.getAllTasks();
    this.getAllTaskModule();
  }

  getAllTaskModule() {
    this.taskService.getAllTaskModule().then((res) => {
      this.setState({ currentTaskModules: res });
    });
  }

  getAllTasks() {
    this.taskService.getAllTaskAccess().then((res) => {
      this.setState({ taskList: res });
    });
  }

  renderTaskDropdownList() {
    const taskDropdown: { label: string; value: string }[] = [
      { label: "-- New Task Access --", value: "new" },
    ];
    this.state.taskList.forEach((item: TaskModuleModel) => {
      taskDropdown.push({ label: item.taskAccess, value: item.taskAccess });
    });
    taskDropdown.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));

    return (
      <Dropdown
        value={
          this.state.newTaskAccess ? "new" : this.state.newTaskModule.taskAccess
        }
        options={taskDropdown}
        filter={true}
        filterPlaceholder="Select TaskAccess"
        filterBy="label"
        onChange={(e) => {
          if (e.value === "new") {
            this.setState({ newTaskAccess: true });
          } else {
            this.setState({
              newTaskModule: {
                ...this.state.newTaskModule,
                taskAccess: e.value,
              },
              newTaskAccess: false,
            });
          }
        }}
        placeholder="Select a Task Access"
      />
    );
  }

  renderModuleDropdownList() {
    const moduleDropdown: { label: string; value: string }[] = [];

    Object.keys(this.menuList.Menu).forEach((key: any, index: any) => {
      if (!("items" in this.menuList.Menu[key])) {
        moduleDropdown.push({ label: key, value: key });
      }
    });
    moduleDropdown.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));

    return (
      <Dropdown
        value={this.state.newTaskModule.menuModule}
        options={moduleDropdown}
        filter={true}
        filterPlaceholder="Select Module"
        filterBy="label"
        onChange={(e) => {
          this.setState({
            newTaskModule: { ...this.state.newTaskModule, menuModule: e.value },
          });
        }}
        placeholder="Select Menu Module"
      />
    );
  }

  defaultTaskDisplay(rowData: TaskModuleModel) {
    if (rowData.defaultTask) {
      return <span>Free</span>;
    } else {
      return <span className="neuhrTextColor">Premium</span>;
    }
  }

  saveNewTask() {
    this.taskService
      .insertTaskModule(this.state.newTaskModule)
      .then((res) => {
        this.context.SetSuccess("Added");
      })
      .catch((err) => {
        let errMsg = this.commonMethods.getErrorMessage(err);
        this.context.SetError(errMsg);
      })
      .finally(() => this.getAllTaskModule());
  }
  submitUpdateForm() {
    this.taskService
      .updateTaskModule(this.state.selectedTask)
      .then((res) => {
        this.context.SetSuccess("Task updated");
      })
      .catch((err) => {
        let errMsg = this.commonMethods.getErrorMessage(err);
        this.context.SetError(errMsg);
      })
      .finally(() => {
        this.getAllTaskModule();
      });
  }
  actionButtons(rowData: TaskModuleModel) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          tooltip="Edit Name"
          onClick={() => {
            this.setState({
              dialogVisibility: true,
              selectedTask: rowData
            });
          }}
        /> */}
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          tooltip="Delete and remove from roles"
          onClick={() => {
            // this.deleteThisTaskModule(rowData);
            this.setState({
              dialogDeleteVisibility: true,
              selectedTask: rowData,
            });
          }}
        />
      </div>
    );
  }

  deleteThisTaskModule(thisTaskModule: TaskModuleModel) {
    this.taskService
      .deleteTaskModule(thisTaskModule)
      .then((res) => {
        this.setState({ dialogDeleteVisibility: false });
        this.context.SetSuccess("Deleted");
      })
      .catch((err) => {
        this.context.SetError("Err");
      })
      .finally(() => this.getAllTaskModule());
  }

  render() {
    // FOR REDIRECT
    if (this.state.redirectOut) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectPath,
          }}
          push
        />
      );
    }

    let tableheader = (
      <div style={{ textAlign: "left" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            onInput={(e: any) =>
              this.setState({ searchValue: e.currentTarget.value })
            }
          />
        </span>
      </div>
    );

    return (
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <h1 className="text-danger">
              {" "}
              Only people with techaccess can access
            </h1>
            This page is used for converting companies to premium plan, and for
            tech to create new permission access.
          </div>

          <div className="card">
            <h1 className="pb-2">Add New Task Access</h1>
            <div className="p-grid">
              <div className="p-lg-6 p-col-12">
                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <span className="requiredRed">
                      For any changes made, please do the same for Prod and Dev.
                      <br></br>Also update InsertionScript for any changes made
                      to any task access (Can generate script from db)
                    </span>
                  </div>

                  <div className="p-col-12">
                    <span className="boldLabel">Task Access shortcode</span>{" "}
                    <span className="requiredRed"> *</span>
                    <this.renderTaskDropdownList />
                  </div>

                  {this.state.newTaskAccess ? (
                    <div className="p-col-12">
                      <span className="boldLabel">
                        New: Task Access shortcode
                      </span>{" "}
                      <span className="requiredRed"> *</span>
                      <InputText
                        value={this.state.newTaskModule.taskAccess}
                        placeholder={
                          "Other: Task Access Name (i.e. taskaccess)"
                        }
                        onChange={(e) =>
                          this.setState({
                            newTaskModule: {
                              ...this.state.newTaskModule,
                              taskAccess: e.currentTarget.value,
                            },
                          })
                        }
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="p-col-12">
                    <span className="boldLabel">Task Configuration Name</span>{" "}
                    <span className="requiredRed"> *</span>
                    <InputText
                      value={this.state.newTaskModule.taskConfigName}
                      placeholder={"Task Configuration Name"}
                      tooltip="A brief explanation on the purpose of this task access &amp; what they are restricted or allowed access to"
                      onChange={(e) =>
                        this.setState({
                          newTaskModule: {
                            ...this.state.newTaskModule,
                            taskConfigName: e.currentTarget.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="p-col-12">
                    <span className="boldLabel">
                      Menu Module (from MenuList)
                    </span>{" "}
                    <span className="requiredRed"> *</span>
                    <this.renderModuleDropdownList />
                  </div>
                  <div className="p-col-12">
                    <span className="boldLabel">Task GUID (if copying from dev to prod)</span>
                    <InputText
                      value={this.state.newTaskModule.taskModuleID}
                      placeholder={"Task GUID"}
                      tooltip=""
                      onChange={(e) =>
                        this.setState({
                          newTaskModule: {
                            ...this.state.newTaskModule,
                            taskModuleID: e.currentTarget.value,
                          },
                        })
                      }
                    />
                  </div>

                  <div className="p-col-12">
                    <span className="boldLabel">
                      Free Plan Module (Unchecked means Premium Plan Module)
                    </span>
                    <p>
                      <Checkbox
                        onChange={(e) =>
                          this.setState({
                            newTaskModule: {
                              ...this.state.newTaskModule,
                              defaultTask: e.checked,
                            },
                          })
                        }
                        checked={this.state.newTaskModule.defaultTask}
                      ></Checkbox>
                    </p>
                  </div>

                  <div className="p-col-12">
                    <span className="boldLabel">
                      Add to all Administrator role for all Group Companies
                      (because Administrator role cannot be edited)
                    </span>
                    <p>
                      <Checkbox
                        onChange={(e) =>
                          this.setState({
                            newTaskModule: {
                              ...this.state.newTaskModule,
                              addtoAdminRole: e.checked,
                            },
                          })
                        }
                        checked={this.state.newTaskModule.addtoAdminRole}
                      ></Checkbox>
                    </p>
                  </div>
                </div>
                <Button label="Save" onClick={this.saveNewTask} />
              </div>
            </div>
          </div>

          <div className="card">
            <h1 className="pb-2">All Tasks</h1>
            <span className="datatable-centerHeader datatable-centerContent">
              <DataTable
                value={this.state.currentTaskModules}
                paginator={true}
                rows={15}
                // autoLayout={true}
                alwaysShowPaginator={false}
                emptyMessage="No Task Access or Modules"
                header={tableheader}
                globalFilter={this.state.searchValue}
                rowGroupMode="rowspan"
                sortField="taskAccess"
                sortOrder={1}
                groupField="taskAccess"
              >
                <Column
                  className="p-col-3"
                  field="taskConfigName"
                  header="Task Configuration Name"
                />
                <Column
                  className="p-col-3"
                  field="taskAccess"
                  header="Task Access"
                />
                <Column
                  className="p-col-3"
                  field="menuModule"
                  header="Menu Module"
                />
                <Column body={this.defaultTaskDisplay} header="Plan" />
                <Column body={this.actionButtons} />
              </DataTable>
            </span>
          </div>

          <PremiumPlanConversion />
        </div>

        <Dialog
          visible={this.state.dialogVisibility}
          header={"Edit Task"}
          modal={true}
          onHide={() => {
            this.setState({
              dialogVisibility: false,
            });
          }}
          footer={
            <div>
              <Button
                label="Submit"
                type="button"
                iconPos="left"
                icon="pi pi-check"
                className="p-button-success"
                onClick={() => {
                  this.submitUpdateForm();
                  this.setState({
                    dialogVisibility: false,
                  });
                }}
              />
              <Button
                label="Cancel"
                type="button"
                iconPos="left"
                icon="pi pi-times"
                className="p-button-danger"
                onClick={() => {
                  this.setState({
                    dialogVisibility: false,
                  });
                }}
              />
            </div>
          }
        >
          <form>
            <div className="p-grid justify-content-center">
              <div className="p-col-12 p-fluid">
                <span className="boldLabel">Task Config Name</span>

                <InputText
                  value={this.state.selectedTask.taskConfigName}
                  onChange={(e) =>
                    this.setState({
                      selectedTask: {
                        ...this.state.selectedTask,
                        taskConfigName: e.currentTarget.value,
                      },
                    })
                  }
                  required
                />
              </div>
              <div className="p-col-12 p-fluid">
                <span className="boldLabel">
                  Free Plan Module (Unchecked means Premium Plan Module)
                </span>
                <p>
                  <Checkbox
                    onChange={(e) =>
                      this.setState({
                        selectedTask: {
                          ...this.state.selectedTask,
                          defaultTask: e.checked,
                        },
                      })
                    }
                    checked={this.state.selectedTask.defaultTask}
                  ></Checkbox>
                </p>
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          visible={this.state.dialogDeleteVisibility}
          header={"Delete Task"}
          modal={true}
          onHide={() => {
            this.setState({
              dialogDeleteVisibility: false,
            });
          }}
          footer={
            <Button
              type="button"
              icon="pi pi-trash"
              label="Yes"
              className="p-button-danger"
              onClick={(e) => {
                e.preventDefault();
                this.deleteThisTaskModule(this.state.selectedTask);
              }}
            />
          }
        >
          <p>
            Are you sure you want to delete{" "}
            {this.state.selectedTask.taskConfigName}?
          </p>
        </Dialog>
      </div>
    );
  }
}
