import React from "react";
import ViewClaims from "./ViewClaims";
import { ClaimViewType } from "./ClaimsModel";

function ClaimsPage(props: any) {
 
  return (
  
        <>
          <div className="row">
            <div className="col-12 col-xl-10">
              <div
                className="card"
                data-tour-id="claim-pending"
                data-tour="These are the claims you submitted and are pending endorsement by your supervisor. You may view claim details and documents by using the buttons at the right."
              >
                <h1>My Pending Claims</h1>
                <ViewClaims others={ClaimViewType.MyPending} {...props} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-10">
              <div
                className="card"
                data-tour-id="claim-processed"
                data-tour="Here are all your past claims. Check back regularly to see the status of your endorsed/approved/processed claims "
              >
                <h1>My Claims History</h1>
                <ViewClaims others={ClaimViewType.MyHistory} {...props} />
              </div>
            </div>
          </div>
        </>
    
  );

}

export default ClaimsPage;
