import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { DeliverableModel } from "./DeliverablesInCampaign";
import { UserDeliverableList } from "./DepartmentOverview";
import { Redirect } from "react-router";

function DeliverablesPerPerson(props: RouteComponentProps<any>) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [userDelList, setUserDelList] = useState<UserDeliverableList>(
    new UserDeliverableList()
  );

  const [display, setDisplay] = useState(<></>);
  // const [header, setHeader] = useState("");
  // const [displayColour, setDisplayColour] = useState<string>("");

  useEffect(() => {
    if (
      props.others !== undefined &&
      props.others !== null &&
      props.others !== ""
    ) {
      setUserDelList(props.others);
      setIsLoading(false);
      // setDisplayColour(props.others.colourCode);
    } else {
      setIsError(true);
      setErrorMsg("No data found");
    }
  }, []);

  // useEffect(() => {
  //   let totalpoints = 0;
  //   if (userDelList.deliverableList.length > 0) {
  //     userDelList.deliverableList.forEach(del => {
  //       totalpoints += del.deliverablePoint;
  //     });
  //   }
  //   setHeader(userDelList.userName + " (" + totalpoints + ")");
  // }, [userDelList]);

  const goToCampaignSummary = (rowData: DeliverableModel) => {
    setRedirectPath("/campaign/summary/" + rowData.campaignID);
    setRedirectOut(true);
  };

  useEffect(() => {
    let table = (
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          value={userDelList.deliverableList}
          rowClassName={() => {
            return { pointer: "pointer" };
          }}
          paginator={true}
          rows={15}
          autoLayout={true}
          alwaysShowPaginator={false}
          emptyMessage="No deliverables found."
          onRowClick={event => {
            goToCampaignSummary(event.data);
          }}
          groupField="campaignName"
          sortField="campaignName"
          rowGroupMode="rowspan"
        >
          <Column field="deliverableTypeName" header="Deliverable Type" />

          <Column field="deliverableName" header="Deliverable Name" />
          <Column field="campaignName" header="Campaign Name" />
          <Column field="campaignSerialNo" header="Campaign Serial" />
          {/* <Column field="deliverablePoint" header="Points" /> */}
        </DataTable>
      </div>
    );
    setDisplay(table);
  }, [userDelList]);

  let pageDisplay;
  if (isError) {
    pageDisplay = <CustomError message={errorMsg} />; // or some growl/message that stays
  } else if (isLoading) {
    pageDisplay = <ProgressSpinner />;
  } else {
    pageDisplay = display;
  }

  return (
    <>
      {redirectOut ? <Redirect push to={redirectPath} /> : null}
      {pageDisplay}
    </>
    // <div className="col-12">

    //   <div className="card p-0">
    //     <Accordion style={{backgroundColor: displayColour}}>
    // <AccordionTab header={header} headerClassName="no-bg">{pageDisplay}</AccordionTab>
    //     </Accordion>
    //   </div>
    // </div>
  );
}

export default DeliverablesPerPerson;
