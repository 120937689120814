import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Row } from "primereact/row";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { StatusService } from "../../service/StatusService";
import { UserService } from "../../service/UserService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { MultiDeptLeaveBalanceModelWithBF } from "./LeaveBalanceDeptView";

interface dataObj {
  [key: string]: string | number;
}

function LeaveBalanceOneDeptView(props: RouteComponentProps) {
  let statusService = new StatusService();
  let commonMethods = new CommonMethods();
  let userService = new UserService();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errMsg, setErrMsg] = useState<string>("");

  const [userID, setUserID] = useState<string>("");
  const [userIDs, setUserIDs] = useState<string[]>(new Array<string>());
  const [statusTypeNames, setStatusTypeNames] = useState<string[]>([]);
  const [keyArray, setKeyArray] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [departmentUserBalance, setDepartmentUserBalance] = useState<any[]>([]);
  const [toApplyOnBehalf, setToApplyOnBehalf] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");

  let dt: DataTable | null = null;

  useEffect(() => {
    if (props.others !== undefined) {
      if (props.others?.mydept) {
        getStatusTypes();
        getBalanceForMyDepartment();
      } else {
        getStatusTypes().then(() => {
		  setDepartmentUserBalance(props.others.deptUserBalance);
          setUserIDs(props.others.deptUserIds);
          setIsLoading(false);
        });
      }
    }
  }, []);

  // once department's user balance is in, create an object for every user.
  useEffect(() => {
    createObjForEveryUser(userIDs);
  }, [departmentUserBalance, userIDs]);

  // gets all active and deductible status type names by GCID
  const getStatusTypes = async () => {
    await statusService
      .getAllStatusTypeNamesIncBF()
      .then((res) => {
        setStatusTypeNames(res);
        setKeyArray((keyArrayOriginal) => [...keyArrayOriginal, ...res]);
      })
      .catch((err) => {
        setErrMsg(commonMethods.getErrorMessage(err));
        setIsError(true);
      });
  };

  // my dept
  const getBalanceForMyDepartment = () => {
    statusService
      .getAllDepartmentBalance()
      .then((res: MultiDeptLeaveBalanceModelWithBF) => {
        setDepartmentUserBalance(res.statusBalanceModelCondensedListWithBF);
        setUserIDs(res.userIDs);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsError(true);
        setErrMsg(commonMethods.getErrorMessage(err));
      });
  };


  // get all balance by gcid
//   const getAllBalanceForGroup = () => {
//     statusService
//       .getGroupCompanyUsersBalance()
//       .then((res) => {
//         setDepartmentUserBalance(res);
//         setIsLoading(false);
//       })
//       .catch((err) => {
//         setErrMsg(commonMethods.getErrorMessage(err));
//         setIsError(true);
//       });
//   };

  // get a list of userIDs for the group
//   const getAllGroupUserIDs = () => {
//     userService
//       .userIDsForUsersInGroup()
//       .then((res) => {
//         setUserIDs(res);
//       })
//       .catch((err) => {
//         setIsError(true);
//         setErrMsg(commonMethods.getErrorMessage(err));
//       });
//   };

  // utility function to create a generic object for every user int he dept.
  function objConstructor(keyarray: string[]) {
    let object: dataObj = {};
    object["userID"] = "";

    keyarray.forEach((element) => {
      object[element] = 0;
    });
    return object;
  }

  function createObjForEveryUser(userArray: string[]) {
    for (let index = 0; index < userArray.length; index++) {
      let tempObj = objConstructor(keyArray);
      tempObj.userID = userArray[index];
      //   tempObj.fullName = userArray[index];
      //   tempObj.fullNameDisplayed = userArray[index];
      //   tempObj.companyName = userArray[index];
      //   tempObj.departmentName = userArray[index];
      //   tempObj.userRankName = userArray[index];

      departmentUserBalance.forEach((balanceObj) => {
        if (tempObj.userID === balanceObj.userID) {
          tempObj.fullName = balanceObj.fullName;
          tempObj.fullNameDisplayed = balanceObj.fullNameDisplayed;
          tempObj[balanceObj.statusName] = balanceObj.availableDays;
          tempObj.companyName = balanceObj.companyName;
          tempObj.departmentName = balanceObj.departmentName;
          tempObj.userRankName = balanceObj.userRankName;
        }
      });

      if (tempObj.userID !== tempObj.fullName) {
        setTableData((tableDataOriginal) => [...tableDataOriginal, tempObj]);
      }
    }
  }

  // using the status type names, create column headers
  const tableHeaders = () => {
    let headers = [];

    for (var i = 0; i < statusTypeNames.length; i++) {
      headers.push(
        <Column
          key={statusTypeNames[i]}
          header={statusTypeNames[i].replace("Leave", "")}
          colSpan={1}
        />
      );
    }
    return headers;
  };

  const headerGroup = () => {
    if (props.others?.admin) {
      return (
        <ColumnGroup>
          <Row>
            <Column header="Employee" rowSpan={2} />
            <Column header="Company" rowSpan={2} />
            <Column header="Department" rowSpan={2} />
            <Column header="FT/Intern" rowSpan={2} />
            <Column header="Leave Types" colSpan={statusTypeNames.length + 1} />
          </Row>
          <Row>
            {tableHeaders()}
            <Column header="Action" />
          </Row>
        </ColumnGroup>
      );
    } else {
      return (
        <ColumnGroup>
          <Row>
            <Column header="Employee" rowSpan={2} />
            <Column header="FT/Intern" rowSpan={2} />
            <Column header="Leave Types" colSpan={statusTypeNames.length + 1} />
          </Row>
          <Row>
            {tableHeaders()}
            <Column header="Action" />
          </Row>
        </ColumnGroup>
      );
    }
  };

  const actionTemplate = (rowData: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltipOptions={{ position: "top" }}
          tooltip={"Apply leave on behalf of " + rowData.fullName}
          icon="pi pi-calendar"
          onClick={() => {
            setUserID(rowData.userID);
            setToApplyOnBehalf(true);
          }}
        />
      </div>
    );
  };

  const globalSearchBar = (
    <>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e) => setGlobalFilter(e.currentTarget.value)}
        />
      </span>
    </>
  );

  const displayBalance = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errMsg} />;
    } else {
      return (
        <>
          <div className="row mb-2">
            <div className="col text-left">{globalSearchBar}</div>
            <div className="col text-right">
              <Button
                label="Export"
                onClick={() => {
                  if (dt !== null) {
                    dt.exportCSV();
                  }
                }}
              />
            </div>
          </div>
          <div className="datatable-centerHeader datatable-centerContent">
            <DataTable
              value={tableData}
              headerColumnGroup={headerGroup()}
              globalFilter={globalFilter}
              autoLayout={true}
              emptyMessage="No results found."
              paginator={true}
              rows={15}
              alwaysShowPaginator={false}
              ref={(el) => {
                dt = el;
              }}
              exportFilename={
                "StatusBalance_Report_" + commonMethods.displayDate_FileName(new Date())
              }
            >
              {initColumns()}
              <Column body={actionTemplate} />
            </DataTable>
          </div>
        </>
      );
    }
  };

  const initColumns = () => {
    let columns: any[] = [];
    if (props.others?.admin) {
      columns = [
        <Column key={"fullNameDisplayed"} field="fullNameDisplayed" />,
        <Column key={"companyName"} field="companyName" />,
        <Column key={"departmentName"} field="departmentName" />,
        <Column key={"userRankName"} field="userRankName" />,
      ];
    } else {
      columns = [
        <Column key={"fullName"} field="fullName" />,
        <Column key={"userRankName"} field="userRankName" />,
      ];
    }

    for (var i = 0; i < statusTypeNames.length; i++) {
      columns.push(
        <Column key={statusTypeNames[i]} field={statusTypeNames[i]} />
      );
    }
    return columns;
  };

  return (
    <div>
      {toApplyOnBehalf ? (
        <Redirect push to={`/status/departmentview/apply/${userID}`} />
      ) : null}
      {displayBalance()}
    </div>
  );
}
export default LeaveBalanceOneDeptView;
