import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { SalesService } from "../../service/SalesService";
import { CommonMethods } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { SalesClientsCompanyModel } from "../resources/ExportClass";

let salesService = new SalesService();
let commonMethods = new CommonMethods();

function SalesClientsDetails(props: any) {
  const [salesDetails, setSalesDetails] = useState(
    Array<SalesClientsCompanyModel>()
  );
  // Loading, Error, Redirect
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred."
  );

  let clientcompanyid = props.match.params.id;
  useEffect(() => {
    getSalesDetails();
  }, []);

  const getSalesDetails = () => {
    salesService
      .getAllSalesDetailsByClientCompany(clientcompanyid)
      .then(res => {
        setSalesDetails(res);
        setIsLoading(false);
      })
      .catch(err => {
        let errorMsg = commonMethods.getErrorMessage(err);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(errorMsg);
      });
  };

  const startDateTemplate = (rowData: SalesClientsCompanyModel) => {
    return commonMethods.displayDate(new Date(rowData.startDate));
  };
  const createdDateTemplate = (rowData: SalesClientsCompanyModel) => {
    return commonMethods.displayDate(new Date(rowData.createdTime));
  };

  

  let page = (
    <div className="datatable-centerHeader datatable-centerContent">
      <DataTable
        value={salesDetails}
        paginator={true}
        rows={15}
        autoLayout={true}
        alwaysShowPaginator={false}
        emptyMessage="No results found."
      >
        <Column field="salesPersonName" header="Sales Person" />
        <Column field="leadsStatus" header="Lead Status" />
        <Column field="contactName" header="Contact" />
        <Column body={startDateTemplate} header="Meeting Date" />
        <Column field="remarks" header="Remarks" />
        <Column body={createdDateTemplate} header="Created On" />
      </DataTable>
    </div>
  );
  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <div className="row">
      <div className="col-12 col-xl-8" data-tour-id="sales-leads"
        data-tour="View who has worked or is currently working with the client.">
        <div className="card">
          <h1 className="pb-2">
            Sales Details           
          </h1>
          {display}
        </div>
      </div>
    </div>
  );
}

export default SalesClientsDetails;
