import { CommonMethods } from "../resources/CommonMethods";

export class PublisherModel {
    public publisherID: string = CommonMethods.EmptyGuid;
    
    public publisherName: string = "";
    public isKOL: boolean = false;
    public publisherStatus: string = "";
    public createdTime: Date = new Date();
    public groupCompanyID: string = CommonMethods.EmptyGuid;
  }