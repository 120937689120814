import React, { useState, useEffect } from "react";
import { ClaimsService } from "../../service/ClaimsService";
import { Chart } from "primereact/chart";
import randomColor from "randomcolor";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import _ from "lodash";
import CustomError from "../resources/Error";
import { ProgressSpinner } from "primereact/progressspinner";
import NumberFormat from "react-number-format";

function ClaimsWidget(props: RouteComponentProps<any>) {
  const claimsService = new ClaimsService();
  const commonMethods = new CommonMethods();
  const [data, setData] = useState<any>();
  const [rawData, setRawData] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState("SGD");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>("");

  const [isNoResults, setNoResults] = useState<boolean>(true);

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    if (props.others !== undefined) {
      if (myAbortController.signal.aborted) {
        return;
      }
      setSelectedCurrency(props.others);
    }

    return () => {
      myAbortController.abort();
    };
  }, [props.others]);

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();
    getPieChartData(myAbortController.signal.aborted);

    return () => {
      myAbortController.abort();
    };
  }, []);

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    getPieChartData(myAbortController.signal.aborted);

    return () => {
      myAbortController.abort();
    };
  }, [selectedCurrency]);

  const getPieChartData = (abortSignal: boolean) => {
    claimsService
      .getClaimDataWidget(selectedCurrency)
      .then((res) => {
        if (abortSignal) {
          return;
        }
        if (res.length !== 0) {
          setNoResults(false);
          setRawData(res);
          setData({
            labels: res.map((ele: { label: any }) => ele.label),
            datasets: [
              {
                data: res.map((ele: { value: any }) => ele.value),
                backgroundColor: randomColor({
                  count: res.length,
                  luminosity: "light",
                  hue: "random",
                }),
              },
            ],
          });
        }

        setIsLoading(false);
      })
      .catch((err) => {
        if (abortSignal) {
          return;
        }
        let errorMsg = commonMethods.getErrorMessage(err);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(errorMsg);
      });
  };
  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else if (isNoResults) {
    display = <div className="text-center">Nothing to display</div>;
  } else {
    display = (
      <>
        <div className="row">
          <div className="col text-center">
            <span className="boldLabel">
              Total:{" "}
              <NumberFormat
                displayType={"text"}
                value={_.sumBy(rawData, (ele: any) => {
                  return Number.parseFloat(ele.value);
                })}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                suffix={selectedCurrency}
              />
              {/* {_.sumBy(rawData, (ele: any) => {
            return Number.parseFloat(ele.value);
          }).toFixed(2)}{" "}
          {selectedCurrency} */}
            </span>
          </div>
        </div>
        <div className="py-2">
          <Chart type="pie" data={data} />
        </div>
      </>
    );
  }

  return <div>{display}</div>;
}

export default ClaimsWidget;
