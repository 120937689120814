import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import DeliverableTypeService from "../../service/DeliverableTypeService";
import { DeliverableTypeModel } from "./DeliverableTypeModel";
import {
  RouteComponentProps,
  StatusTypes,
  CommonMethods,
} from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { InputText } from "primereact/inputtext";


function DeliverableType(props: RouteComponentProps) {
  const commonMethods = new CommonMethods();
  const deliverableTypeService = new DeliverableTypeService();

  const { SetSuccess, SetError } = React.useContext(ToastStateContext);

  const [redirectOut, setRedirectOut] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");

  const [searchValue, setSearchValue] = useState<string>("");
  const [tableDataChanged, setTableDataChanged] = useState(false);
  const [tableData, setTableData] = useState<DeliverableTypeModel[]>([]);
  const [deliverableTypeData, setDeliverableTypeData] = useState<
    DeliverableTypeModel
  >(new DeliverableTypeModel());
  const [dialogStr, setDialogStr] = useState<string>("");
  const [confirmStr, setConfirmStr] = useState<string>("");
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [tableDataChanged]);

  const init = () => {
    deliverableTypeService
      .getAllDeliverableTypesByGCID()
      .then((res) => setTableData(res))
      .catch((err) => {
        setErrorMessage(commonMethods.getErrorMessage(err));
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const setDialog = (header: string, content: any) => {
    setConfirmStr(header);
    setDialogStr(content);
  };

  const deleteDeliverableType = () => {
    deliverableTypeService
      .deleteDeliverableType(deliverableTypeData.deliverableTypeID)
      .then(() => {
        setTableDataChanged(!tableDataChanged);
        setDialogVisibility(false);
        SetSuccess(
          ` ${deliverableTypeData.deliverableTypeName} has been deleted.`
        );
      })
      .catch((err) => {
        setDialogVisibility(false);
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  const updateDeliverableTypeStatus = (
    rowData: DeliverableTypeModel,
    status: string
  ) => {
    rowData.deliverableTypeStatus = status;
    deliverableTypeService
      .updateDeliverableTypeStatus(rowData)
      .then(() => {
        SetSuccess(
          rowData.deliverableTypeName +
            " is now " +
            rowData.deliverableTypeStatus +
            "."
        );
      })
      .catch((err) => {
        SetError(commonMethods.getErrorMessage(err));
      });
  };

  const statusTemplate = (rowData: DeliverableTypeModel) => {
    return (
      <InputSwitch
        className="mt-2"
        tooltip={
          rowData.deliverableTypeStatus === StatusTypes.active
            ? StatusTypes.active
            : StatusTypes.deactivated
        }
        tooltipOptions={{ position: "top" }}
        checked={rowData.deliverableTypeStatus === StatusTypes.active}
        onChange={(e) => {
          updateDeliverableTypeStatus(
            rowData,
            e.value ? StatusTypes.active : StatusTypes.deactivated
          );
        }}
      />
    );
  };

  const actionTemplate = (rowData: DeliverableTypeModel) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tooltip="Edit Deliverable Type"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            setDeliverableTypeData(rowData);
            setRedirectOut(true);
            setRedirectPath(
              "/admin/deliverable_type/update/" + rowData.deliverableTypeID
            );
          }}
        />
        <Button
          tooltip="Delete Deliverable Type"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          className="p-button-danger mx-1"
          onClick={() => {
            setDeliverableTypeData(rowData);

            setDialog(
              `Delete ${rowData.deliverableTypeName}`,
              `Are you sure you want to delete this deliverable type?`
            );
            setDialogVisibility(true);
          }}
        />
      </div>
    );
  };

  let tableheader = (
    <div style={{ textAlign: "left" }}>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          onInput={(e: any) => setSearchValue(e.currentTarget.value)}
        />
      </span>
    </div>
  );

  const display = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    } else if (isError) {
      return <CustomError message={errorMsg} />;
    } else {
      return (
        <div className="datatable-centerHeader datatable-centerContent">
          <DataTable
            value={tableData}
            paginator={true}
            rows={15}
            autoLayout={true}
            header={tableheader}
            globalFilter={searchValue}
            alwaysShowPaginator={false}
            emptyMessage="No results found."
            rowGroupMode="rowspan"
            groupField="companyName"
            sortField="companyName"
            sortOrder={1}
          >
            <Column field="deliverableTypeName" header="Name" />
            <Column field="deliverableTypeDescription" header="Description" />
            {/* <Column field="deliverablePoint" header="Point" /> */}
            <Column
              field="deliverableTypeStatus"
              header="Status"
              className="p-col-2"
            />
            <Column body={statusTemplate} className="p-col-0" />
            <Column body={actionTemplate} header="Actions" className="p-col-2" />
          </DataTable>
        </div>
      );
    }
  };

  return (
    <div className="row">
      {redirectOut ? <Redirect push to={redirectPath} /> : null}

      <Dialog
        className="popupDialog"
        header={confirmStr}
        visible={dialogVisibility}
        modal={true}
        //dismissableMask={true}
        onHide={() => setDialogVisibility(false)}
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => {
                deleteDeliverableType();
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                setDialogVisibility(false);
              }}
            />
          </div>
        }
      >
        {dialogStr}
      </Dialog>
      <div className="col-12 col-xl-12">
        <div className="card">
          <h1 className="pb-2">
            Deliverable Types
            <span className="pl-3">
              <Button
                type="button"
                icon="pi pi-plus"
                iconPos="right"
                label="Add"
                className="p-button-danger"
                onClick={() => {
                  setRedirectOut(true);
                  setRedirectPath("/admin/deliverable_type/create");
                }}
              />
            </span>
          </h1>
          <p>
            These are to classify the deliverables created. (E.g., YouTube
            Video, Facebook Post)
          </p>
          {display()}
        </div>
      </div>
    </div>
  );
}

export default DeliverableType;
