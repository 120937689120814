import { Context } from "react"
import { CompanyFeedContext } from "./CompanyFeed"
import { SinglePostContext } from "./Component/SinglePost/SinglePostContainer"

export function nameFirstLetter(name: string) {
    const firstLetter = name.charAt(0)
    return firstLetter
}

export function checkElementAboveMiddleVH(position: DOMRect | null) {
    if (position && position.bottom <= (window.innerHeight / 2)) return true
    return false
}

export function toTitleCase(str: string | null | undefined) {
    if (str == null) return ""
    return str.replace(/\b\w+('\w{1})?/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function getContext(contextName: string) {
    let context: Context<{state: any; dispatch: any}> = CompanyFeedContext
    if (contextName === "SinglePost") context = SinglePostContext
    return context
}

