import Axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { Row } from "primereact/row";
import * as React from "react";
import NumberFormat from "react-number-format";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { AccountManagementService } from "../../service/AccountManagementService";
import { PayslipService } from "../../service/PayslipService";
import { PayslipExport } from "../payslip/PayslipExport";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import {
  CompanyDisplay,
  PayslipModel,
  UserPayrollModel
} from "../resources/ExportClass";
import { ToastStateContext } from "../resources/ToastContext";
import { PayrollModel } from "./PayrollCurrent";

interface AppState {
  accounts: Array<CompanyDisplay>;
  companyAccount: CompanyDisplay;
  payslipObjects: Array<UserPayrollModel>;
  payrollModel: PayrollModel;

  exportable: object[];

  swiftCodeList: { bankCode: string; swiftcode: string }[];

  isLoading: boolean;
  globalFilter: string;
  redirectOut: boolean;
  redirectPath: string;

  isError: boolean;
  errorMsg: string;

  selectedPayslipObjects: Array<UserPayrollModel>;
  selectedPayslipObjectsBackup: Array<UserPayrollModel>;
  isPremiumPlan: boolean;
  completed: boolean;

  isExportDialogShowing: boolean;
  isCancelExportProcessDialog: boolean;
  isProcessingDialog: boolean;

  progressBarValue: number;
  totalExportCount: number;
  payslipToExport: UserPayrollModel | null;
  forceTerminateExportProcess: boolean;
  isExportCompleted: boolean;
  isExportError: boolean;

  payrollID: string;
}

export class PayrollTable extends React.Component<
  RouteComponentProps<any>,
  AppState
> {
  payslipService: PayslipService;
  accMgmt: AccountManagementService;
  thisDate: Date = new Date();
  commonMethods: CommonMethods;

  static contextType = ToastStateContext;
  currentAccessingCompanyId =
    localStorage.getItem("currentAccessingCompany") || "";

  finance = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "SGD",
    minimumFractionDigits: 2,
  });

  dtExport: DataTable | null = null;
  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      accounts: [],
      companyAccount: new CompanyDisplay(),
      payslipObjects: new Array<UserPayrollModel>(),
      payrollModel: new PayrollModel(),
      exportable: [],
      swiftCodeList: [],

      isLoading: true,
      globalFilter: "",
      redirectOut: false,
      redirectPath: "",

      isError: false,
      errorMsg: "",

      selectedPayslipObjects: new Array<UserPayrollModel>(),
      selectedPayslipObjectsBackup: new Array<UserPayrollModel>(),

      isPremiumPlan: false,
	  completed: false,

      isExportDialogShowing: false,
      isCancelExportProcessDialog: false,
      isProcessingDialog: false,
      progressBarValue: 0,
      totalExportCount: 0,
      payslipToExport: new UserPayrollModel(),
      forceTerminateExportProcess: false,
      isExportCompleted: false,
      isExportError: false,

      payrollID: this.props.match.params.id,
    };

    // SERVICE SET
    this.payslipService = new PayslipService();
    this.accMgmt = new AccountManagementService();
    this.commonMethods = new CommonMethods();

    // BINDING

    // BIND RENDER
    this.renderPayrollTable = this.renderPayrollTable.bind(this);
    this.getSwiftBICCode = this.getSwiftBICCode.bind(this);

    this.formatDateCSV = this.formatDateCSV.bind(this);
    this.getPayerReference = this.getPayerReference.bind(this);
    this.exportPayroll_CSV = this.exportPayroll_CSV.bind(this);
    this.bulkUpdate = this.bulkUpdate.bind(this);
    this.bulkGeneratePDF = this.bulkGeneratePDF.bind(this);
    this.displayFirstLastName = this.displayFirstLastName.bind(this);
    this.goToEditPage = this.goToEditPage.bind(this);
    this.viewPDF = this.viewPDF.bind(this);
    this.generatePdf_CompletedCallback = this.generatePdf_CompletedCallback.bind(
      this
    );
  }

  componentDidMount() {
    if (this.commonMethods.isPropsLoaded(this.props)) {
      this.setState({
        companyAccount: this.props.others.companyAccount,
        swiftCodeList: this.props.others.swiftCodeList,
        payrollModel: this.props.others.payrollModel,
        payslipObjects: this.props.others.payslipObjects,
        isPremiumPlan: this.props.others.isPremiumPlan,
        isLoading: false,
		completed: this.props.others.completed
      });
    }
  }

  componentDidUpdate() {
    // Temp disabled for continuous checking & prevention of multiple calls
    // if (this.commonMethods.isPropsLoaded(this.props)) {
    //   this.updateAndNotify();
    // }
  }

  setupCalls() {
    Axios.all([
      this.commonMethods.getSwiftCode(),
      this.payslipService.getAllPayslipByPayrollID(this.state.payrollID),
    ])
      .then(
        Axios.spread((first: any, second: PayrollModel) => {
          this.setState(
            {
              swiftCodeList: first,
              payrollModel: second,
              payslipObjects: second.userPayrollModelList,
              isLoading: false,
            },
            () => {
              if (this.state.selectedPayslipObjectsBackup.length > 0) {
                this.setState({
                  selectedPayslipObjects: this.state
                    .selectedPayslipObjectsBackup,
                });
              } else {
                this.setState({
                  selectedPayslipObjects: new Array<UserPayrollModel>(),
                });
              }
            }
          );
        })
      )
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.setState({
          isError: true,
          isLoading: false,
          errorMsg: errorMsg,
        });
        return errorMsg;
      });
  }

  // USABLE FUNCTIONS
  formatDateCSV(date: Date) {
    return date
      .toLocaleDateString("en-SG", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "");
  }
  formatDateSQL(date: Date) {
    //  DDMMYYYY from YYYY-MM-DDT00:00:00
    var newDate = date.toString().slice(0, date.toString().indexOf("T"));
    var paymentDate = newDate.split("-");
    return paymentDate[2] + paymentDate[1] + paymentDate[0];
  }

  getPayerReference(date: Date) {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return `${months[date.getMonth()]}  ${date.getFullYear().toString()}`;
  }

  isBankInfoPresent(rowData: any, column: any) {
    if (rowData[column.field] === null) {
      return (
        <>
          <span data-tip="" data-for="employeeDetails">
            <i className="fas fa-exclamation exclamationStyle"></i>
          </span>
          <ReactTooltip
            id="employeeDetails"
            aria-haspopup="true"
            className="tooltipFont"
            place="bottom"
          >
            <p className="text-center">
              Employee has yet to fill their bank account details
            </p>
          </ReactTooltip>
        </>
      );
    } else {
      return <></>;
    }
  }

  displayFirstLastName(rowData: UserPayrollModel) {
    return (
      <div>
        {rowData.firstName} {rowData.lastName}
      </div>
    );
  }

  goToEditPage(rowData: PayslipModel) {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-warning"
        tooltip="Edit Payslip"
        tooltipOptions={{ position: "top" }}
        onClick={() => {
          this.editPayslip(rowData);
        }}
      />
    );
  }

  checkProcessed(rowData: UserPayrollModel) {
    if (rowData.versionNumber === 0) {
      return (
        <i
          className="pi pi-exclamation-triangle"
          id={rowData.contractID}
          data-tip=""
          data-for="notprocessed"
        >
          {" "}
          <ReactTooltip
            id="notprocessed"
            aria-haspopup="true"
            className="tooltipFont"
          >
            <p className="text-center">Requires processing</p>
          </ReactTooltip>{" "}
        </i>
      );
    } else {
      return rowData.versionNumber;
    }
  }

  editPayslip(rowData: PayslipModel) {
    this.setState({
      redirectOut: true,
      redirectPath: `/finance/payroll/payslip/${rowData.payslipID}/update`,
    });
  }

  getSwiftBICCode(bankCode: string) {
    var swift = this.state.swiftCodeList[
      this.state.swiftCodeList.findIndex((obj) => obj.bankCode === bankCode)
    ].swiftcode;
    return swift;
  }

  viewPDF(rowData: UserPayrollModel) {
    return (
      <Button
        type="button"
        icon="pi pi-external-link"
        className="button-document"
        onClick={() => {
          window.open(
            rowData.uploadPath,
            `View ${rowData.firstName} ${rowData.lastName} Payslip`,
            "width=560,height=900,toolbar=0,menubar=0,location=0"
          );
        }}
        disabled={rowData.uploadPath !== null ? false : true}
      />
    );
  }
  padSpaces(item: string | number, maxLength: number) {
    if (typeof item === "number") {
      item = item.toString();
    }
    var numberToPad = maxLength - item.length;
    return item + " ".repeat(numberToPad);
  }

  // END USABLE FUNCTIONS

  // EXPORT FUNCTION

  exportPayroll_CSV() {
    try {
      if (!this.state.isPremiumPlan) {
        this.context.SetError("This action requires premium access.");
        return false;
      }
      if (
        this.state.companyAccount.accountHolderName === "" ||
        this.state.companyAccount.accountNumber === "" ||
        this.state.companyAccount.accountType === "" ||
        this.state.companyAccount.bankCode === 0 ||
        this.state.companyAccount.bankCode === null
      ) {
        this.context.SetWarn(
          "Company Bank Details are invalid. You may update them at Finance Details from the Admin Dashboard."
        );
        return false;
      }
      if (this.state.swiftCodeList.length === 0) {
        this.context.SetError("Error with swiftcode.");
        return false;
      }
      if (this.state.selectedPayslipObjects.length === 0) {
        this.context.SetWarn("Please select at least one payslip.");
        return false;
      }
      /**
       * UFF is a comma separated format(CSV),where comma character (,) is used as the delimiter.
       * In case the comma delimiter is present as part of the field data,
       * double-quote character (") will be used to quote the entire field.
       * In case the double-quote character (") is present as part of the field data,
       * two double-quote characters ("") will be used.
       */
      let canExport = true;
      let totalTransactional = 0;
      const csvData = [];
      const csvHeader = [
        "HEADER", // H01 - [6]
        this.formatDateCSV(this.thisDate), // H02 - [8] DDMMYYYY
        this.state.companyAccount.companyRegNum, // H03 - [12] ORGANISATION ID
        this.state.companyAccount.accountHolderName, // H04 - [35] COMPANY NAME
      ];

      csvData.push(csvHeader);

      for (var employee of this.state.selectedPayslipObjects) {
        if (employee.versionNumber !== 0 || employee.bank_Code === null) {
          const field = [
            "PAYMENT", // D01 [7] RECORD TYPE
            "SAL", // D02 [3] PRODUCT TYPE
            employee.currency, // D03 [35] ORIGINATING ACCOUNT CURRENCY
            this.state.companyAccount.country, // D04 [3] ORIGINATING ACCOUNT CURRENCY: SGD
            `${employee.userRankName} Payment`, // D05 [35] CUSTOMER OR BATCH REF - AUTO ASSIGN IF NOT PROVIDED [FT Payment / Intern Payment]
            "SGD", // D06 [3] PAYMENT CURRENCY: SGD
            "", // D07 [5] BATCH ID: NULL
            this.formatDateSQL(employee.paymentDate), // D08 [8] PAYMENT DATE
            employee.bank_PayeeName, // D11 [35] RECEIVING PARTY NAME
            employee.bank_Number.toString(), // D16 [34] RECEIVING ACCOUNT NUMBER / IBAN / PROXY VALUE
            this.getSwiftBICCode(employee.bank_Code), // D21 [11] BENEFICIARY BANK SWIFT BIC
            employee.netSalary.toString(), // D28 [9] NET SALARY
            "22", // D33 [2] TRANSACTION CODE - 22 (SALARY CREDIT)
            this.getPayerReference(this.thisDate), // D34 [35] PARTICULARS / BENEFICIARY OR PAYER REFERENCE (END TO END REF) - MONTH + YEAR OF PAYMENT
            "", // D36 [140] PAYMENT DETAILS
            "SALA", //D43 [4/5] PURPOSE OF PAYMENT - SALA
            "", // D45 [1] - DELIVERY MODE - (E) EMAIL
          ];
          totalTransactional += employee.netSalary;
          csvData.push(field);
        } else {
          canExport = false;
          break;
        }
      }
      // this.state.selectedPayslipObjects.forEach((employee, index) => {
      //   if (employee.versionNumber !== 0 || employee.bank_Code === null) {
      //     const field = [
      //       "PAYMENT", // D01 [7] RECORD TYPE
      //       "SAL", // D02 [3] PRODUCT TYPE
      //       employee.currency, // D03 [35] ORIGINATING ACCOUNT CURRENCY
      //       this.state.companyAccount.country, // D04 [3] ORIGINATING ACCOUNT CURRENCY: SGD
      //       `${employee.userRankName} Payment`, // D05 [35] CUSTOMER OR BATCH REF - AUTO ASSIGN IF NOT PROVIDED [FT Payment / Intern Payment]
      //       "SGD", // D06 [3] PAYMENT CURRENCY: SGD
      //       "", // D07 [5] BATCH ID: NULL
      //       this.formatDateSQL(employee.paymentDate), // D08 [8] PAYMENT DATE
      //       employee.bank_PayeeName, // D11 [35] RECEIVING PARTY NAME
      //       employee.bank_Number.toString(), // D16 [34] RECEIVING ACCOUNT NUMBER / IBAN / PROXY VALUE
      //       this.getSwiftBICCode(employee.bank_Code), // D21 [11] BENEFICIARY BANK SWIFT BIC
      //       employee.netSalary.toString(), // D28 [9] NET SALARY
      //       "22", // D33 [2] TRANSACTION CODE - 22 (SALARY CREDIT)
      //       this.getPayerReference(this.thisDate), // D34 [35] PARTICULARS / BENEFICIARY OR PAYER REFERENCE (END TO END REF) - MONTH + YEAR OF PAYMENT
      //       "", // D36 [140] PAYMENT DETAILS
      //       "SALA", //D43 [4/5] PURPOSE OF PAYMENT - SALA
      //       "", // D45 [1] - DELIVERY MODE - (E) EMAIL
      //     ];
      //     totalTransactional += employee.netSalary;
      //     csvData.push(field);
      //   } else {
      //     if (canExport === true) {
      //       canExport = false;
      //     }
      //   }
      // });

      const csvTrailer = [
        "TRAILER",
        this.state.selectedPayslipObjects.length.toString(),
        totalTransactional,
      ];

      csvData.push(csvTrailer);

      if (canExport) {
        this.setState({ exportable: csvData });
        this.context.SetSuccess("CSV exported");
      } else {
        this.context.SetError("There are unprocessed payslips.");
        return false;
      }
    } catch (error) {
      this.context.SetError("Incomplete User(s) Data");
      return false;
    }
  }

  exportAsExcel() {
    if (this.state.selectedPayslipObjects.length <= 0) {
      this.context.SetWarn("Please select at least one payslip");
      return false;
    }
    if (this.dtExport !== null) {
      this.dtExport.exportCSV();
    } else {
      this.context.SetWarn("Please select at least one payslip");
      return false;
    }
  }
  bulkUpdate() {
    if (this.state.selectedPayslipObjects.length <= 0) {
      this.context.SetWarn("Please select at least one payslip");
      return false;
    }
    this.setState({ isProcessingDialog: true }, () => {
      this.payslipService
        .updatePayslipBulk(this.state.selectedPayslipObjects)
        .then((res) => {
			if (!this.state.completed){
				console.log("payroll is incomplete, running setup calls method");
				this.setupCalls();
			} else {
				console.log("payroll is complete, NOT running setup calls method");
			}
          this.context.SetSuccess(
            this.state.selectedPayslipObjects.length + " payslips processed."
          );
        })
        .catch((error) => {
          let errorMsg = this.commonMethods.getErrorMessage(error);
          this.context.SetError(errorMsg);

          return errorMsg;
        })
        .finally(() => {
          this.setState({ isProcessingDialog: false });
        });
    });
  }

  bulkGeneratePDF() {
    if (this.state.selectedPayslipObjects.length === 0) {
      this.context.SetWarn("Please select at least one payslip");
      return;
    }
    for (var i = 0; i < this.state.selectedPayslipObjects.length; i++) {
      if (this.state.selectedPayslipObjects[i].versionNumber === 0) {
        this.context.SetWarn("Only processed payslips can be exported");
        return;
      }
    }
    this.setState(
      {
        isExportCompleted: false,
        isExportDialogShowing: true,
        forceTerminateExportProcess: false,
        totalExportCount: this.state.selectedPayslipObjects.length,
        isExportError: false,
        payslipToExport: null,
      },
      () => {
        this.generateOnePDF(this.state.selectedPayslipObjects[0]);
      }
    );
  }

  generatePdf_CompletedCallback(result: boolean, payslipIDcompleted: string) {
    if (result && payslipIDcompleted !== "") {
      // remove the completed item
      let indexOfCompleted = this.state.selectedPayslipObjects.findIndex(
        (item) => item.payslipID === payslipIDcompleted
      );
      this.state.selectedPayslipObjects.splice(indexOfCompleted, 1);

      this.setState({
        progressBarValue: Math.round(
          ((this.state.totalExportCount -
            this.state.selectedPayslipObjects.length) /
            this.state.totalExportCount) *
            100
        ),
      });

      // Terminated by user closing the dialog
      if (this.state.forceTerminateExportProcess) {
        this.setState({
          isExportDialogShowing: false,
          selectedPayslipObjects: new Array<UserPayrollModel>(),
        });
        this.context.SetError("Payslips generation has been terminated.");
        return;
      }
      // List empty = completed.
      if (this.state.selectedPayslipObjects.length === 0) {
        this.setState({ isExportCompleted: true, payslipToExport: null });
		if (!this.state.completed){
			console.log("payroll is incomplete, running setup calls method");
			this.setupCalls();
		} else {
			console.log("payroll is complete, NOT running setup calls method");
		}
        return;
      }
      // Process next on the list
      if (this.state.selectedPayslipObjects.length > 0) {
        this.generateOnePDF(this.state.selectedPayslipObjects[0]);
        return;
      }
    } else {
      this.setState({ isExportError: true });
      this.context.SetError("Error occured while generating payslips");
    }
  }

  generateOnePDF(payslip: UserPayrollModel) {
    if (payslip !== null) {
      this.setState({ payslipToExport: payslip });
    }
  }

  displayValue = () => {
    return (
      <React.Fragment>
        {this.state.totalExportCount -
          this.state.selectedPayslipObjects.length +
          "/" +
          this.state.totalExportCount}{" "}
        processed
      </React.Fragment>
    );
  };

  bulkRemovePayslips() {
    // remove only if it is a deactivated acc.
    this.payslipService
      .removePayslips(this.state.selectedPayslipObjects)
      .then((res) => {
        this.context.SetSuccess("Payslips removed!");
      })
      .catch((error) => {
        let errorMsg = this.commonMethods.getErrorMessage(error);
        this.context.SetError(errorMsg);
        return errorMsg;
      });
  }

//   bulkReplacePayslips() {
// 	this.payslipService
// 		.replacePayslips(this.state.selectedPayslipObjects)
// 		.then((res) => {
// 			this.context.SetSuccess("Payslips removed!");
// 		  })
// 		.catch((error) => {

// 		let errorMsg = this.commonMethods.getErrorMessage(error);
// 		this.context.SetError(errorMsg);
// 		return errorMsg;
// 		});
//   }
  // END EXPORT FUNCTION

  // RENDER FUNCTIONS

  // ------------------ FOOTER METHODS ------------------ START
  displayTotalNetPayable() {
    let total = this.calculateTotalNetPayable() || 0;
    return (
      <NumberFormat
        displayType="text"
        value={total}
        thousandSeparator={true}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    );
  }
  calculateTotalNetPayable() {
    let total = 0;
    this.state.payslipObjects.forEach((obj) => {
      total += obj.netSalary;
    });
    return total;
  }
  displayTotalAdjustments() {
    let total = this.calculateTotalAdjustmentPayable() || 0;
    return (
      <NumberFormat
        displayType="text"
        value={total}
        thousandSeparator={true}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    );
  }
  calculateTotalAdjustmentPayable() {
    let total = 0;
    this.state.payslipObjects.forEach((obj, index) => {
      total += obj.adjustmentValue;
    });
    return total;
  }
  displayTotalEmployeeCPF() {
    let total = this.calculateTotalEmployeeCPF() || 0;
    return (
      <NumberFormat
        displayType="text"
        value={total}
        thousandSeparator={true}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    );
  }
  calculateTotalEmployeeCPF() {
    let total = 0;
    this.state.payslipObjects.forEach((obj, index) => {
      total += obj.employeeCPFValue;
    });
    return total;
  }
  displayTotalBasicPay() {
    let total = this.calculateTotalBasicPay() || 0;
    return (
      <NumberFormat
        displayType="text"
        value={total}
        thousandSeparator={true}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    );
  }
  calculateTotalBasicPay() {
    let total = 0;
    this.state.payslipObjects.forEach((obj, index) => {
      total += obj.ordinaryWage;
    });
    return total;
  }
  displayTotalEmployerCPF() {
    let total = this.calculateTotalEmployerCPF();
    return (
      <NumberFormat
        displayType="text"
        value={total}
        thousandSeparator={true}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    );
  }
  calculateTotalEmployerCPF() {
    let total = 0;
    this.state.payslipObjects.forEach((obj, index) => {
      total += obj.employerCPFValue;
    });
    return total;
  }
  displayTotalSHG() {
    let total = this.calculateTotalSHG() || 0;
    return (
      <NumberFormat
        displayType="text"
        value={total}
        thousandSeparator={true}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    );
  }
  calculateTotalSHG() {
    let total = 0;
    this.state.payslipObjects.forEach((obj, index) => {
      total += obj.shgValue;
    });
    return total;
  }
  calculateTotalSDL() {
    let total = 0;
    this.state.payslipObjects.forEach((obj, index) => {
      total += obj.sdf;
    });
    total = Math.floor(total);
    return total;
  }
  displayTotalSDL() {
    let total = this.calculateTotalSDL() || 0;
    return (
      <NumberFormat
        displayType="text"
        value={total}
        thousandSeparator={true}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    );
  }
  // ------------------ FOOTER METHODS ------------------ END

  // ------------------ DATATABLE DISPLAY METHODS ---------------- START
  formatMoneyValue(rowData: any, column: any) {
    return (
      <NumberFormat
        displayType="text"
        value={rowData[column.field]}
        thousandSeparator={true}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    );
  }
  // ------------------ DATATABLE DISPLAY METHODS ---------------- END

  getSummary() {
    let others = {
      totalNetPay: this.calculateTotalNetPayable(),
      totalCPF:
        this.calculateTotalEmployerCPF() + this.calculateTotalEmployeeCPF(),
      totalSHG: this.calculateTotalSHG(),
      totalSDL: this.calculateTotalSDL(),
      isPremium: this.state.isPremiumPlan,
    };
    return others;
  }

  renderPayrollTable() {
    const commMethods = new CommonMethods();
    // var filename =
    //   "PAYROLL_" +
    //   this.state.companyAccount.companyName.replace(/\s/g, "") +
    //   "_" +
    //   this.formatDateCSV(this.thisDate) +
    //   ".txt";

    let deleteEmployeeButton = (
      <span className="px-1">
        <Button
          label="Remove employees"
          tooltip="Remove employees from current payroll"
          tooltipOptions={{ position: "top" }}
          type="button"
          icon="pi pi-trash"
          onClick={(e) => {
            this.bulkRemovePayslips();
          }}
          className="p-button-danger"
        />
      </span>
    );

	// let replaceEmployeeButton = (
	// 	<span className="px-1">
	// 	  <Button
	// 		label="Replace employees"
	// 		tooltip="Remove employees from current payroll"
	// 		tooltipOptions={{ position: "top" }}
	// 		type="button"
	// 		icon="pi pi-info"
	// 		onClick={(e) => {
	// 		  this.bulkReplacePayslips();
	// 		}}
	// 		className="p-button-danger"
	// 	  />
	// 	</span>
	//   );
    var header = (
      <div style={{ textAlign: "left" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            onInput={(e) =>
              this.setState({ globalFilter: e.currentTarget.value })
            }
          />
        </span>
        {this.state.selectedPayslipObjects.length > 0 && deleteEmployeeButton}
		{/* {this.state.selectedPayslipObjects.length > 0 && replaceEmployeeButton} */}

      </div>
    );

    let footerGroup = (
      <ColumnGroup>
        <Row>
          <Column footer="Payslips selected:" colSpan={2} />
          <Column
            footer={`${this.state.selectedPayslipObjects.length} / ${this.state.payslipObjects.length}`}
            colSpan={1}
          />
          <Column colSpan={2} />
          <Column footer="Total:" colSpan={2} />
          <Column
            footer={this.displayTotalBasicPay()}
            footerStyle={{ backgroundColor: "#eee" }}
          />
          <Column
            footer={this.displayTotalEmployeeCPF()}
            footerStyle={{ backgroundColor: "#eee" }}
          />
          <Column
            footer={this.displayTotalEmployerCPF()}
            footerStyle={{ backgroundColor: "#eee" }}
          />
          <Column
            footer={this.displayTotalSHG()}
            footerStyle={{ backgroundColor: "#eee" }}
          />
          <Column
            footer={this.displayTotalSDL()}
            footerStyle={{ backgroundColor: "#eee" }}
          />
          <Column
            footer={this.displayTotalAdjustments()}
            footerStyle={{ backgroundColor: "#eee" }}
          />

          <Column
            footer={this.displayTotalNetPayable()}
            footerStyle={{ backgroundColor: "#eee" }}
          />
          <Column colSpan={3} />
        </Row>
      </ColumnGroup>
    );

    function formatBirthdate(rowData: UserPayrollModel) {
      return commMethods.displayDate_Short(rowData.dateOfBirth);
    }

    const { companyAccount: thisAccount } = this.state;

    return (
      <div className="p-col-12">
        <div
          className="p-col-12"
          data-tour-id="employee-payslip"
          data-tour="Payslips have to be processed or updated before they can be generated into PDFs. The generated payslip PDFs on the rightmost column will be what employees see under the Personal tab."
        >
          <div className="datatable-centerHeader datatable-centerContent">
            <DataTable
              emptyMessage="No results found."
              value={this.state.payslipObjects}
              footerColumnGroup={footerGroup}
              autoLayout={true}
              selection={this.state.selectedPayslipObjects}
              onSelectionChange={(e) => {
                this.setState({ selectedPayslipObjects: e.value });
              }}
              header={header}
              globalFilter={this.state.globalFilter}
              ref={(e) => (this.dtExport = e)}
              exportFilename={"Payroll_" +
                this.state.companyAccount.companyName.replace(/ /g, "") +"_" +
                this.commonMethods.displayDate_FileName(new Date())
              }
            >
              <Column selectionMode="multiple" style={{ width: "2em" }} />
              <Column
                field={"bank_Code"}
                body={this.isBankInfoPresent}
                style={{ width: "1em", verticalAlign: "middle" }}
              />

              <Column field={"departmentName"} header="Department" />

              <Column header="Job Title" field="jobTitle" />
              <Column
                field={"fullName"}
                // body={this.displayFirstLastName}
                header="Employee Name"
              />
              <Column header="NRIC / FIN" field="identificationNumber" />
              <Column
                header="D.O.B."
                field="dateOfBirth"
                body={formatBirthdate}
              />
              <Column
                header="Basic"
                body={this.formatMoneyValue}
                field="ordinaryWage"
                headerStyle={{ backgroundColor: "#eee" }}
              />
              <Column
                header="Employee CPF"
                body={this.formatMoneyValue}
                field="employeeCPFValue"
                headerStyle={{ backgroundColor: "#eee" }}
              />
              <Column
                header="Employer CPF"
                body={this.formatMoneyValue}
                field={"employerCPFValue"}
                headerStyle={{ backgroundColor: "#eee" }}
              />
              <Column
                header="SHG donations"
                body={this.formatMoneyValue}
                field={"shgValue"}
                headerStyle={{ backgroundColor: "#eee" }}
              />
              <Column
                header="SDL"
                body={this.formatMoneyValue}
                field={"sdf"}
                headerStyle={{ backgroundColor: "#eee" }}
              />
              <Column
                header="Adjustments"
                field="adjustmentValue"
                body={this.formatMoneyValue}
                headerStyle={{ backgroundColor: "#eee" }}
              />
              <Column
                header="Net Salary"
                field="netSalary"
                body={this.formatMoneyValue}
                headerStyle={{ backgroundColor: "#eee" }}
              />

              <Column
                body={this.checkProcessed}
                header="Ver"
                // field="versionNumber"
                style={{ width: "3em" }}
              />
              <Column
                body={this.goToEditPage}
                header="Edit"
                style={{ width: "3em" }}
              />
              <Column
                body={this.viewPDF}
                // field="uploadPath"
                header="PDF"
                style={{ width: "3em" }}
              />
            </DataTable>
          </div>
          <p>
            If there are some users not reflected here, please check that their
            contract last day is greater than today
          </p>
        </div>
        <div className="p-col-12 text-center">
          {(thisAccount.accountHolderName === "" ||
            thisAccount.accountNumber === "" ||
            thisAccount.accountType === "" ||
            thisAccount.bankCode === null) && (
            <>
              <span data-tip="" data-for="exclamationmarkCompanyDetails">
                <i
                  className="fas fa-exclamation exclamationStyle mx-2"
                  style={{ float: "none" }}
                ></i>
              </span>

              <ReactTooltip
                id="exclamationmarkCompanyDetails"
                aria-haspopup="true"
                className="tooltipFont"
              >
                <p className="text-center">
                  Company Finance Details are not yet filled up
                </p>
              </ReactTooltip>
            </>
          )}
          <span className="px-1">
            <Button
              tooltip="Updates selected payslips to version 1"
              tooltipOptions={{ position: "top" }}
              className="p-button-warning"
              icon="pi pi-check"
              iconPos="left"
              label="Process Payslips"
              onClick={this.bulkUpdate}
            />
          </span>
          <span className="px-1">
            <Button
              tooltip="Generates individual PDFs for selected payslips."
              tooltipOptions={{ position: "top" }}
              className="button-document"
              label="Generate PDF"
              icon="pi pi-file-o"
              iconPos="left"
              onClick={() => {
                this.bulkGeneratePDF();
              }}
            />
          </span>
          <span className="px-1">
            <Button
              className="button-primary"
              tooltip="Download selected payslips as excel."
              tooltipOptions={{ position: "top" }}
              label="Download as Excel"
              icon="pi pi-download"
              iconPos="left"
              onClick={() => {
                this.exportAsExcel();
              }}
            />
          </span>
          {/* <span className="px-1">
            <CSVLink
              onClick={this.exportPayroll_CSV}
              data={this.state.exportable}
              filename={filename}
            >
              <Button
                className="p-button-secondary"
                label="Export Bank CSV"        icon="pi pi-download"
                iconPos="left"
                onClick={(e) => e.preventDefault}
                tooltip="Export selected payslips as CSV. <br> Recommended for DBS Ideal"
                tooltipOptions={{ position: "top" }}
                disabled={
                  // thisAccount.accountHolderName === "" ||
                  // thisAccount.accountNumber === "" ||
                  // thisAccount.accountType === "" ||
                  // thisAccount.bankCode === null ||
                  !this.state.isPremiumPlan
                }
              />
            </CSVLink>
          </span> */}
          {!this.state.isPremiumPlan && (
            <div className="pb-2 text-danger">
              Some functions on this page requires Premium access. To unlock,
              click{" "}
              <Link to="/priceplans" target="_blank">
                here
              </Link>
              .
            </div>
          )}
        </div>
        {/* <div className="p-col-12">
          <div className="row">
            <div className="col-12 col-lg-4 offset-lg-8 card p-0">
              <PayrollSummary {...this.props} others={this.getSummary()} />
            </div>
          </div>
        </div> */}
      </div>
    );
  }
  // END RENDER FUNCTIONS
  render() {
	console.log(this.state.completed);
    if (this.state.redirectOut) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectPath,
          }}
        />
      );
    }

    let display;
    if (this.state.isError) {
      display = <CustomError message={this.state.errorMsg} />; // or some growl/message that stays
    } else if (this.state.isLoading) {
      display = <ProgressSpinner />;
    } else {
      display = <this.renderPayrollTable />;
    }

    let generating = (
      <div>
        <p>
          Please do not navigate away from this page while payslips are being
          generated.
        </p>
        <ProgressBar
          value={this.state.progressBarValue}
          displayValueTemplate={this.displayValue}
        />

        <div className="text-center pt-2">
          <p>
            Now generating:{" "}
            {this.state.payslipToExport?.firstName +
              " " +
              this.state.payslipToExport?.lastName}
          </p>
        </div>
        <div style={{ position: "absolute", left: "-1000px", top: 0 }}>
          <PayslipExport
            {...this.props}
            others={{
              payslipID: this.state.payslipToExport?.payslipID || "",
              completedCallback: this.generatePdf_CompletedCallback,
            }}
          />{" "}
        </div>
      </div>
    );
    let completed = (
      <div>
        <p className="text-success">
          All selected payslips has been generated.{" "}
        </p>{" "}
        <ProgressBar
          value={this.state.progressBarValue}
          displayValueTemplate={this.displayValue}
        />
      </div>
    );
    let exporterror = (
      <div>
        <p className="text-danger">An error has occured.</p>
        <ProgressBar
          value={this.state.progressBarValue}
          displayValueTemplate={this.displayValue}
        />
        <div className="text-center pt-2">
          <p>
            Error at payslip :{" "}
            {this.state.payslipToExport?.firstName +
              " " +
              this.state.payslipToExport?.lastName}
          </p>
        </div>
      </div>
    );

    let dialogDisplay = generating;
    if (this.state.isExportError) {
      dialogDisplay = exporterror;
    } else if (this.state.isExportCompleted) {
      dialogDisplay = completed;
    }
    // else if (this.state.isExportDialogShowing) {
    //   dialogDisplay = generating;
    // }

    let cancelWhileExportingDialog = (
      <Dialog
        visible={this.state.isCancelExportProcessDialog}
        modal={true}
        header={"Cancel"}
        closeOnEscape={false}
        closable={false}
        onHide={() =>
          this.setState({
            isCancelExportProcessDialog: false,
          })
        }
        footer={
          <div>
            <Button
              label="Yes"
              type="button"
              iconPos="left"
              icon="pi pi-check"
              className="p-button-danger"
              onClick={() => {
                this.setState({
                  isExportDialogShowing: false,
                  isCancelExportProcessDialog: false,
                  forceTerminateExportProcess: true,
                });
              }}
            />
            <Button
              label="No"
              type="button"
              iconPos="left"
              icon="pi pi-times"
              className="p-button-success"
              onClick={() => {
                this.setState({
                  isCancelExportProcessDialog: false,
                });
              }}
            />
          </div>
        }
      >
        <p>This will terminate the current process. Proceed to cancel?</p>
      </Dialog>
    );

    let generatingDialog = (
      <Dialog
        visible={this.state.isExportDialogShowing}
        modal={true}
        header={"Generating Payslips"}
        closeOnEscape={false}
        onHide={() => {
          if (
            this.state.isExportCompleted ||
            this.state.forceTerminateExportProcess
          ) {
            this.setState({
              isExportDialogShowing: false,
              isExportError: false,
              progressBarValue: 0,
            });
          } else {
            this.setState({ isCancelExportProcessDialog: true });
          }
        }}
      >
        {dialogDisplay}
      </Dialog>
    );

    let processingDialog = (
      <Dialog
        visible={this.state.isProcessingDialog}
        modal={true}
        header={"Processing Payslips"}
        onHide={() => {
          this.setState({ isProcessingDialog: false });
        }}
      >
        <p>Processing...</p>
      </Dialog>
    );
    return (
      <>
        {/* <div className="row"> */}
        <div className="p-col-12">
          {cancelWhileExportingDialog}
          {generatingDialog}
          {processingDialog}
          {display}
        </div>
        {/* </div> */}
      </>
    );
  }
}
