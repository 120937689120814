import React, { useState, useEffect } from "react";
import {
  RouteComponentProps,
  CommonMethods,
  ActionTypes,
  ParamIDTypes,
} from "../resources/CommonMethods";
import { Button } from "primereact/button";
import { CampaignTypeModel } from "./CampaignType";
import { InputText } from "primereact/inputtext";
import { CampaignTypeService } from "../../service/CampaignTypeService";
import { ProgressSpinner } from "primereact/progressspinner";
import CustomError from "../resources/Error";
import { ToastStateContext } from "../resources/ToastContext";
import { Redirect, useParams } from "react-router";

function CampaignTypeCreate(props: RouteComponentProps) {
  const { id } = useParams<ParamIDTypes>();
  const campaignTypeService = new CampaignTypeService();

  const commonMethods = new CommonMethods();
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);

  const [campaignTypeData, setCampaignTypeData] = useState<CampaignTypeModel>(
    new CampaignTypeModel()
  );
  const [action, setAction] = useState<string>(ActionTypes.create);
  const [isError, setisError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [toHome, setToHome] = useState(false);

  useEffect(() => {
    if (id !== undefined) {
      campaignTypeService
        .getCampaignTypeByID(id)
        .then((res) => {
          setIsLoading(false);
          setCampaignTypeData(res);
        })
        .catch((err) => {
          setErrorMessage(commonMethods.getErrorMessage(err));
          setisError(true);
        });

      setAction(ActionTypes.update);
    }
    setIsLoading(false);
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (action === ActionTypes.create) {
      campaignTypeService
        .createCampaignType(campaignTypeData)
        .then((res) => {
          SetSuccess("New Campaign Type created!");
          setToHome(true);
        })
        .catch((err) => {
          SetError("Could not create new campaign type.");
        });
    } else if (action === ActionTypes.update) {
      campaignTypeService
        .updateCampaignType(campaignTypeData)
        .then((res) => {
          SetSuccess("Campaign Type updated!");
          setToHome(true);
        })
        .catch((err) => {
          SetError("Could not update campaign type.");
        });
    }
  };

  const form = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">
              Campaign Type Name <span className="requiredRed">*</span>
            </span>
            <InputText
              value={campaignTypeData.campaignTypeName}
              onChange={(event) =>
                setCampaignTypeData({
                  ...campaignTypeData,
                  campaignTypeName: event.currentTarget.value,
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Campaign Type Description <span className="requiredRed">*</span>
            </span>
            <InputText
              value={campaignTypeData.campaignTypeDescription}
              onChange={(event) =>
                setCampaignTypeData({
                  ...campaignTypeData,
                  campaignTypeDescription: event.currentTarget.value,
                })
              }
              required
            />
          </div>
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button type="submit" label={action} />
        </div>
      </form>
    );
  };

  if (isLoading) {
    return <ProgressSpinner />;
  } else if (isError) {
    return <CustomError message={errorMsg} />;
  } else {
    return (
      <div className="row">
        {toHome ? <Redirect push to="/admin/campaign_type" /> : null}
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <h1 className="pb-2">{action} Campaign Type</h1>
            <div className="requiredRed">
              <p>* Required</p>
            </div>
            {form()}
          </div>
        </div>
      </div>
    );
  }
}

export default CampaignTypeCreate;
