import { HttpClient } from "./HttpClient";
export class AccountManagementService {
  httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

   // GET - All Bank Accounts wrt. CompanyID OR groupID
   async getCurrentCompanyBankAccount(companyID:string) {
    const res = await this.httpClient.get("/api/finance/accounts/company/"+companyID+"/details");
    return res;
  }

  // GET - All Bank Accounts wrt. CompanyID OR groupID
  async getAllAccounts(Type:string , ID:string) {
    const res = await this.httpClient.get("/api/finance/accounts/"+Type+"/"+ID);
    return res;
  }

  // GET - Bank Accounts wrt companyfinanceID -- unused
  async getAccountByCFID(companyfinanceID:string) {
    const res = await this.httpClient.get("/api/finance/accounts/cfid/"+companyfinanceID);
    return res;
  }

  // POST - Add New Account -- unused: newly inserted in update bank acc
  async postNewAccount(object:object) {
    const res = await this.httpClient.post("/api/finance/accounts/new", object);
    return res;
  }

  // PUT - Update Bank Account
  async updateBankAccount(object:object) {
    const res = await this.httpClient.put("/api/finance/accounts/update", object);
    return res;
  }

  // PUT - Update CPF/IRAS Details
  async updateGovtDetails(object:object) {
    const res = await this.httpClient.put("/api/finance/cpfiras/update", object);
    return res;
  }

  // PUT - Delete Selected Account -- unused
  async removeSelectedAccount(object:object) {
    const res = await this.httpClient.put("/api/finance/accounts/remove", object);
    return res;
  }
}
