import { RouteComponentProps } from "../resources/CommonMethods";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";

export function PayrollSummary(props: RouteComponentProps) {
  useEffect(() => {
    if (props.others != null) {
      setTotalNetPay(props.others.totalNetPay);
      setTotalCPF(props.others.totalCPF);
      setTotalSHG(props.others.totalSHG);
      setTotalSDL(props.others.totalSDL);
      if (!props.others.isPremium) {
        setTableClass("row premium");
      } else {
          setTableClass("row")
      }
    }
  }, [props.others]);

  const [totalNetPay, setTotalNetPay] = useState<number>(0);
  const [totalCPF, setTotalCPF] = useState<number>(0);
  const [totalSHG, setTotalSHG] = useState<number>(0);
  const [totalSDL, setTotalSDL] = useState<number>(0);
  const [tableClass, setTableClass] = useState<string>("row");

  return (
    <div>
      <div className="card-header font-weight-bold text-left">
       Summary
      </div>
      <div className="card-body p-0">
        <div className="container p-align-center m-0">
          <div className={tableClass}>
            <div className="col-12 border-bottom px-3 py-2">
              <div className="row">
                <div className="col text-left">Total Net Pay</div>
                <div className="col text-right">
                  <NumberFormat
                    displayType="text"
                    value={totalNetPay}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 border-bottom px-3 py-2">
              <div className="row">
                <div className="col text-left">Total CPF Payment</div>
                <div className="col text-right">
                  <NumberFormat
                    displayType="text"
                    value={totalCPF}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-12 border-bottom px-3 py-2">
              <div className="row">
                <div className="col text-left">Total SHGs Payment</div>
                <div className="col text-right">
                  <NumberFormat
                    displayType="text"
                    value={totalSHG}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </div>
              </div>
            </div> */}
            <div className="col-12 border-bottom px-3 py-2">
              <div className="row">
                <div className="col text-left">Total SDL Payment</div>
                <div className="col text-right">
                  <NumberFormat
                    displayType="text"
                    value={totalSDL}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 border-bottom px-3 py-2 card-header">
              <div className="row ">
                <div className="col text-left boldLabel">Total Payable Out</div>
                <div className="col text-right boldLabel">
                  <NumberFormat
                    displayType="text"
                    value={totalSDL+totalCPF+totalNetPay+totalSHG}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}
