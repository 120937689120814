import React, { useContext } from 'react'
import { toTitleCase } from '../../CompanyFeedFunctions';
import { PublicProfileContext } from './PublicProfile';

export default function UserDescription() {
    const { state } = useContext(PublicProfileContext);

    return (
        <div className="profile-user-description">
            <div className="text-bold profile-page-name">
                {toTitleCase(state.userProfile.firstName) + ' ' + toTitleCase(state.userProfile.lastName)}
            </div>
            <div className="profile-page-jobtitle">
                {state.userProfile.jobTitle}
            </div>
        </div>
    )
}
