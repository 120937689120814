import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { ClientCompanyService } from "../../service/ClientCompanyService";
import { ClientContactsService } from "../../service/ClientContactsService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import { ClientContactsModel } from "../resources/ExportClass";
import { ToastStateContext } from "../resources/ToastContext";

let clientCompanyService = new ClientCompanyService();
let clientContactsService = new ClientContactsService();
let commonMethods = new CommonMethods();

function ClientContactCreate(props: RouteComponentProps<any>) {
  const [clientContactsData, setClientContactsData] = useState(
    new ClientContactsModel()
  );
  const [clientCompanyName, setClientCompanyName] = useState("");
  const [buttonLabel, setButtonLabel] = useState<string>("Create");
  const [toHome, setToHome] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { SetSuccess } = React.useContext(ToastStateContext);
  const { SetError } = React.useContext(ToastStateContext);
  const [emailError, setEmailError] = useState<string>("");
  const [errorMsg, setErrorMessage] = useState<string>(
    "An unidentified error has occurred"
  );
  const [cardsize, setCardSize] = useState<string>(
    "col-12 col-md-6 col-lg-6 col-xl-6"
  );
  const titleSelectItems = [
    { label: "Mr", value: "Mr" },
    { label: "Mrs", value: "Mrs" },
    { label: "Miss", value: "Miss" },
    { label: "Ms", value: "Ms" }
  ];

  const [clientcompanyid, setClientCompanyId] = useState<string>(
    props.match.params.companyid
  );

  let clientcontactid = props.match.params.contactid;

  useEffect(() => {
  
    if (clientcontactid !== undefined) {
      clientContactsService
        .getClientContactByID(clientcontactid)
        .then((res: any) => {
          setClientContactsData(res);
        })
        .catch(err => {
          let error = commonMethods.getErrorMessage(err);
          setErrorMessage(error);
          setIsError(true);
        });
      setButtonLabel("Update");
    } else if (clientcompanyid !== undefined) {
      clientCompanyService
        .getClientCompanyByID(clientcompanyid)
        .then((res: any) => setClientCompanyName(res.clientCompany))
        .catch(err => {
          let error = commonMethods.getErrorMessage(err);
          setErrorMessage(error);
          setIsError(true);
        });
    } else {
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (
      props.others !== undefined && props.others.clientCompanyID !== CommonMethods.EmptyGuid
    ) {
      setIsError(false);
      setClientCompanyId(props.others.clientCompanyID);
      clientCompanyService
        .getClientCompanyByID(props.others.clientCompanyID)
        .then((res: any) => {
          setClientCompanyName(res.clientCompany);
        })
        .catch(err => {
          let error = commonMethods.getErrorMessage(err);
          setErrorMessage(error);
          setIsError(true);
        });

      setCardSize("col-12 col-xl-12");
    }
  }, [props.others]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setEmailError("");

    if (!commonMethods.validateEmail(clientContactsData.contactEmail)) {
      setEmailError("p-error");
      SetError("Invalid email");
      return;
    } else {
      if (buttonLabel === "Create") {
        let obj = clientContactsData;
        obj.clientCompanyID = clientcompanyid;
        clientContactsService
          .createClientContact(obj)
          .then(res => {
            if (props.others !== undefined) {
              props.others.clientContactCallback(true, clientcompanyid);
            } else {
              SetSuccess(
                "New contact (" + clientContactsData.contactName + ") created!"
              );
              setToHome(true);
            }
          })
          .catch(err => {
            SetError("Could not create client contact.");
          });
      } else if (buttonLabel === "Update") {
        let obj = clientContactsData;
        clientContactsService
          .updateClientContact(obj)
          .then(res => {
            SetSuccess(`${res.contactName} has been updated`);
            setToHome(true);
          })
          .catch(err => {
            SetError("Could not update client contact.");
          });
      }
    }
  };

  const form = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <span className="boldLabel">
              Contact Name <span style={{ color: "red" }}>*</span>
            </span>
          </div>
          <div className="p-col-3">
            <Dropdown
              value={clientContactsData.title}
              options={titleSelectItems}
              placeholder="Please Select"
              onChange={e =>
                setClientContactsData({
                  ...clientContactsData!,
                  title: e.value
                })
              }
              required
            />
          </div>
          <div className="p-col-9">
            <InputText
              value={clientContactsData!.contactName}
              placeholder="Name"
              onChange={event =>
                setClientContactsData({
                  ...clientContactsData!,
                  contactName: event.currentTarget.value
                })
              }
              required
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Contact Number</span>
            <InputText
              value={clientContactsData!.contactNumber}
              onChange={event =>
                setClientContactsData({
                  ...clientContactsData!,
                  contactNumber: event.currentTarget.value
                })
              }
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">
              Email Address <span style={{ color: "red" }}>*</span>
            </span>
            <div hidden={emailError === "" ? true : false} className="my-1">
              <Message severity="error" text="Invalid Input" />
            </div>
            <InputText
              value={clientContactsData!.contactEmail}
              onChange={event =>
                setClientContactsData({
                  ...clientContactsData!,
                  contactEmail: event.currentTarget.value
                })
              }
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Job Title</span>
            <InputText
              value={clientContactsData!.designation}
              // placeholder="Business Development"
              onChange={event =>
                setClientContactsData({
                  ...clientContactsData!,
                  designation: event.currentTarget.value
                })
              }
            />
          </div>
          <div className="p-col-12">
            <span className="boldLabel">Remarks</span>
            <InputText
              value={clientContactsData.remarks}
              placeholder=""
              onChange={event =>
                setClientContactsData({
                  ...clientContactsData!,
                  remarks: event.currentTarget.value
                })
              }
            />
          </div>
        </div>

        <div className="p-grid p-justify-center pt-2">
          <Button type="submit" label={buttonLabel} />
        </div>
      </form>
    );
  };

  if (isLoading) {
    return <ProgressSpinner />;
  } else if (isError) {
    return <CustomError message={errorMsg} />;
  } else {
    let header;
    header =
      buttonLabel === "Create"
        ? "Create Contact for " + clientCompanyName
        : "Update Contact Details of " + clientContactsData.contactName;

    return (
      <div className="row">
        {toHome ? (
          <Redirect
            push
            to={{
              pathname:
                "/sales/clientcontacts/" + clientContactsData.clientCompanyID
            }}
          />
        ) : null}
        <div className={cardsize}>
          <div className="card">
            <h1 className="pb-2">{header}</h1>
            {form()}
          </div>
        </div>
      </div>
    );
  }
}

export default ClientContactCreate;
