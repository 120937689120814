import { Chart } from "primereact/chart";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { ExternalCostService } from "../../service/ExternalCostService";
import { PieChartModel } from "../resources/ExportClass";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import NumberFormat from "react-number-format";

function ExternalCostWidget(props: RouteComponentProps<any>) {
  const TotalExternalCostPerMonthModel = {
    costTypeName: "",
    totalExternalCostAmount: 0,
  };

  const commonMethods = new CommonMethods();
  const externalCostsSvc = new ExternalCostService();

  const [externalCosts, setExternalCosts] = useState([
    { ...TotalExternalCostPerMonthModel },
  ]);
  const [externalCostsData, setExternalCostsData] = useState({});
  const [totalAmount, setTotalAmount] = useState<number>();

  const [selectedCurrency, setSelectedCurrency] = useState("SGD");

  // Loading, Error, Redirect
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>("");

  const [isNoResults, setNoResults] = useState<boolean>(true);

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    if (props.others !== undefined) {
      if (myAbortController.signal.aborted) {
        return;
      }
      setSelectedCurrency(props.others);
    }

    return () => {
      myAbortController.abort();
    };
  }, [props.others]);

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    externalCostsSvc
      .getPieChartCostByCurrency(
        props.userProfile.groupCompanyID,
        selectedCurrency
      )
      .then((res) => {
        if (myAbortController.signal.aborted) {
          return;
        }
        if (res.length !== 0) {
          setNoResults(false);
          setExternalCosts(res);
        }
      })
      .catch((err) => {
        if (myAbortController.signal.aborted) {
          return;
        }
        let errorMsg = commonMethods.getErrorMessage(err);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(errorMsg);
      });

    return () => {
      myAbortController.abort();
    };
  }, [selectedCurrency]);

  // Get Pie Chart For Operational Cost For Group Company
  useEffect(() => {
    const myAbortController: AbortController = new AbortController();

    var labels: Array<string> = [];
    var data: Array<number> = [];
    let totalCost = 0;

    externalCosts.forEach((costType) => {
      labels.push(costType.costTypeName);
      data.push(costType.totalExternalCostAmount);
      totalCost += costType.totalExternalCostAmount;
    });

    var final: PieChartModel = new PieChartModel();
    final.labels = labels;
    final.datasets[0].data = data;

    if (myAbortController.signal.aborted) {
      return;
    }
    setExternalCostsData(final);
    setTotalAmount(totalCost);
    setIsLoading(false);

    return () => {
      myAbortController.abort();
    };
  }, [externalCosts]);

  let display;
  if (isLoading) {
    display = <ProgressSpinner />;
  } else if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isNoResults) {
    display = <div className="text-center">Nothing to display</div>;
  } else {
    display = (
      <>
        <div className="row">
          <div className="col text-center">
            <span className="boldLabel">
              Total:{" "}
              <NumberFormat
                displayType={"text"}
                value={totalAmount}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                suffix={selectedCurrency}
              />
              {/* Total: {Number.parseFloat(totalAmount).toFixed(2)}{" "} */}
            </span>
          </div>
        </div>
        <div className="py-2">
          <Chart type="pie" data={externalCostsData} />
        </div>
      </>
    );
  }
  return <div>{display}</div>;
}

export default ExternalCostWidget;
