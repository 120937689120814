import randomColor from "randomcolor";
import moment from "moment";
import { CommonMethods } from "./CommonMethods";

function getIndex(haystack: Array<payslipmeta>, needle: string) {
  return haystack.map((o) => o.meta_key).indexOf(needle);
}

export class UserProfileModel {
  public userID: string = "";
  public companyID: string = "";
  public groupCompanyID: string = "";
  public taskList: Array<string> = [];
  public listOfCompanyIds: Array<string> = [];
}

//    ___   _ __   __ ___  _     ___  ___
//   | _ \ /_\\ \ / // __|| |   |_ _|| _ \
//   |  _// _ \\ V / \__ \| |__  | | |  _/
//   |_| /_/ \_\|_|  |___/|____||___||_|
//     ___  _       _    ___  ___
//    / __|| |     /_\  / __|/ __|
//   | (__ | |__  / _ \ \__ \\__ \
//    \___||____|/_/ \_\|___/|___/
//

export class PayslipModel {
  public payrollID: string = "";
  public payslipID: string = "";
  public userID: string = "";
  public contractID: string = "";
  public currency: string = "";
  public basicSalary: number = 0;
  public workDays: number = 0;
  public ordinaryWage: number = 0;
  public overtimeWage: Array<OvertimeLog> = new Array<OvertimeLog>();
  public additionalWage: Array<PayItemMeta> = new Array<PayItemMeta>();
  public additionalWageDisplay: Array<payslipmeta> = new Array<payslipmeta>();
  public contributions: Array<payslipmeta> = new Array<payslipmeta>();
  public donation: Array<payslipmeta> = new Array<payslipmeta>();
  public unpaidLeaveDays: number = 0;
  public ytdPayout: number = 0;
  public ytdMonths: number = 0;
  public deductables: Array<PayItemMeta> = new Array<PayItemMeta>();
  public deductablesDisplay: Array<payslipmeta> = new Array<payslipmeta>();

  public sdf: number = 0;
  public grossSalary: number = 0;
  public netSalary: number = 0;
  public payStartDate: Date = new Date();
  public payEndDate: Date = new Date();
  public paymentDate: Date = new Date();
  public remarks: string = "";
  public payslipStatus: string = "";
  public versionNumber: number = 0;
  public createdTime: Date = new Date();
}
export class payslipmeta {
  [key: string]: string;
  public meta_key: string = "";
  public meta_value: string = "";
}
export class OvertimeLog {
  public startDate: Date = new Date();
  public endDate: Date = new Date();
  public lastUpdated: Date = new Date();
  public hoursClocked: number = 0;
  public wageType: string = "";
}
export class PayslipInfo {
  public userID: string = "";
  public firstName: string = "";
  public fullName: string = "";
  public lastName: string = "";
  public email: string = "";
  public dateOfBirth: Date = new Date();
  public bank_Number: string = "";
  public identificationNumber: string = "";

  public bank_Name: string = "";
  public bank_ShortName: string = "";
  public bank_Code: string = "";
  // public bank_Number: string = "";
  public bank_AccountType: string = "";
  public bank_PayeeName: string = "";

  public shgEligible: boolean = true;
  public shgGroup: string = "";
  public sdlEligible: boolean = true;
  public cpfEligible: boolean = true;

  public companyID: string = "";
  public companyName: string = "";

  public payslipLogo: string = "";
  public companyRegNum: string = "";
  public companyAddress: string = "";

  public nationality: string = "";
  public legalStatus: string = "";
  public prObtainedDate: Date = new Date();
}

export class PayslipObjAndInfoModel {
  public payslipModel: PayslipModel = new PayslipModel();
  public payslipInfo: PayslipInfo = new PayslipInfo();
  public updateLog: Array<UpdateLog> = new Array<UpdateLog>();
}

// export class PayslipView {
//   constructor(obj: PayslipModel) {
//     this.userID = obj.userID;
//     this.currency = obj.currency;
//     this.basicSalary = "$ " + obj.basicSalary.toString();
//     this.workDays = obj.workDays.toString() + " day(s)";
//     this.ordinaryWage = "$ " + obj.ordinaryWage.toFixed(2);
//     this.overtimeWage = obj.overtimeWage;
//     this.additionalWage = obj.additionalWage;
//     this.contributions = obj.contributions;
//     this.donation = obj.donation;
//     this.unpaidLeaveDays = obj.unpaidLeaveDays.toString() + " day(s)";
//     this.ytdMonths = obj.ytdMonths.toString();
//     this.deductables = obj.deductables;
//     this.grossSalary = "$ " + obj.grossSalary.toFixed(2);
//     this.netSalary = "$ " + obj.netSalary.toFixed(2);
//     this.payStartDate = moment(obj.payStartDate).format("D MM");
//     this.payEndDate = moment(obj.payEndDate).format("D MM");
//     this.paymentDate = moment(obj.paymentDate).format("D MM");

//     this.employerCPF =
//       obj.contributions.length > 0
//         ? obj.contributions[
//             getIndex(obj.contributions, "Employer Contribution")
//           ].meta_value
//         : "";
//     this.sdf =
//       "$ " + (obj.basicSalary * 0.0025 > 2 ? obj.basicSalary * 0.0025 : 2);
//   }

//   public userID: string = "";
//   public companyName: string = "";
//   public employeeFullName: string = "";
//   public employeeAccNumber: string = "";
//   public dob: string = "";
//   public age: string = "";
//   public taxRef: string = ""; // NRIC/FIN NO
//   public currency: string = "";

//   public basicSalary: string = "";
//   public workDays: string = "";
//   public ordinaryWage: string = "";

//   public overtimeWage: Array<OvertimeLog> = new Array<OvertimeLog>();
//   public additionalWage: Array<PayItemMeta> = new Array<PayItemMeta>();
//   public contributions: Array<payslipmeta> = new Array<payslipmeta>();
//   public donation: Array<payslipmeta> = new Array<payslipmeta>();
//   public unpaidLeaveDays: string = "";
//   public ytdMonths: string = "";
//   public deductables: Array<PayItemMeta> = new Array<PayItemMeta>();
//   public grossSalary: string = "";
//   public netSalary: string = "";
//   public payStartDate: string = "";
//   public payEndDate: string = "";
//   public paymentDate: string = "";

//   public employerCPF: string = "";
//   public sdf: string = "";
// }

export class UpdateLog {
  public updateDate: string = "";
  public updateUserID: string = "";
  public updateFullName: string = "";
  public versionNumber: number = 0;
  public remarks: string = "";
}

export class UserPayslipModel {
  public userID: string = "";
  public firstName: string = "";
  public lastName: string = "";
  public email: string = "";
  public displayPicture: string = "";
  public displayPictureFile: string = "";
  public dateOfBirth: Date = new Date();
  public nationality: string = "";
  public maritalStatus: string = "";
  public identificationNumber: string = "";
  public jobStartDate: Date = new Date();
  public contractDuration: number = 0;
  public gender: string = "";
  public race: string = "";
  public religion: string = "";
  public prObtainedDate: Date = new Date();
  public legalStatus: string = "";
  public wp_WorkPass: string = "";
  public wp_DependantPass: string = "";
  public wp_WorkPermitExpiry: Date = new Date();
  public bank_Name: string = "";
  public bank_Code: string = "";
  public bank_Number: string = "";
  public bank_AccountType: string = "";
  public bank_PayeeName: string = "";
  public shgEligible: string = "";
  public shgGroup: string = "";

  public payslipID: string = "";
  public contractID: string = "";
  public currency: string = "";
  public basicSalary: number = 0;
  public workDays: number = 0;
  public overtimeWage: Array<OvertimeLog> = new Array<OvertimeLog>();
  public additionalWage: Array<payslipmeta> = new Array<payslipmeta>();
  public contributions: Array<payslipmeta> = new Array<payslipmeta>();
  public donation: Array<payslipmeta> = new Array<payslipmeta>();
  public deductables: Array<payslipmeta> = new Array<payslipmeta>();
  public sdf: number = 0;
  public onpaidLeaveDays: number = 0;
  public ytdMonths: number = 0;
  public grossSalary: number = 0;
  public netSalary: number = 0;
  public payStartDate: Date = new Date();
  public payEndDate: Date = new Date();
  public paymentDate: Date = new Date();
  public payslipStatus: string = "";
  public versionNumber: number = 0;

  public uploadPath: string = "";
}

export class UserPayrollModel {
  public bank_AccountType: string = "";
  public bank_Name: string = "";
  public bank_Code: string = "";
  public bank_Number: string = "";
  public bank_PayeeName: string = "";
  public currency: string = "";
  public firstName: string = "";
  public lastName: string = "";
  public identificationNumber: string = "";
  public dateOfBirth: Date = new Date();
  public jobTitle: string = "";
  public netSalary: number = 0;
  public paymentDate: Date = new Date();
  public payslipID: string = "";
  public remarks: string = "";
  public userID: string = "";
  public versionNumber: number = 0;

  public workDays: number = 0;
  public basicSalary: number = 0;
  public ordinaryWage: number = 0;
  public grossSalary: number = 0;
  public unpaidLeaveDays: number = 0;
  public additionalWage: Array<payslipmeta> = new Array<payslipmeta>();
  public deductables: Array<payslipmeta> = new Array<payslipmeta>();
  public donation: Array<payslipmeta> = new Array<payslipmeta>();
  public contributions: Array<payslipmeta> = new Array<payslipmeta>();
  public sdf: number = 0;
  public contractID: string = "";
  public overtimeWage: Array<OvertimeLog> = new Array<OvertimeLog>();
  public ytdPayout: number = 0;
  public ytdMonths: number = 0;
  public payStartDate: Date = new Date();
  public payEndDate: Date = new Date();
  public payslipStatus: string = "";
  public uploadPath: string = "";

  public userRankName: string = "";
  public shgValue: number = 0;
  public adjustmentValue = 0;
  public employeeCPFValue = 0;
  public employerCPFValue = 0;
}

export class BankNameModel {
  public bankName: string = "";
  public bankShortName: string = "";
}

//     ___  ___   __  __  ___   _    _  _ __   __
//    / __|/ _ \ |  \/  || _ \ /_\  | \| |\ \ / /
//   | (__| (_) || |\/| ||  _// _ \ | .` | \ V /
//    \___|\___/ |_|  |_||_| /_/ \_\|_|\_|  |_|
//    ___   ___  _____  _    ___  _     ___
//   |   \ | __||_   _|/_\  |_ _|| |   / __|
//   | |) || _|   | | / _ \  | | | |__ \__ \
//   |___/ |___|  |_|/_/ \_\|___||____||___/
//

export class CompanyDisplay {
  // Maps CompanyFinanceDisplayModel (C#)
  companyFinanceID: string = "";
  companyID: string = "";
  groupCompanyID: string = "";
  companyRegNum: string = "";
  cpfSubmissionNo: string = "";
  irasTaxNo: string = "";
  companyName: string = "";
  country: string = "";
  accountHolderName: string = "";
  bankName: string = "";
  bankCode: number | null = null;
  accountNumber: string = "";
  accountType: string = "";
  accountStatus: string = "";
  mainAccount: boolean = false;
  payslipLogo: string = "";
  companyAddress: string = "";
}

export class BankAccountDetails {
  CompanyFinanceID: string = "";
  CompanyID: string = "";
  BankName: string = "";
  AccountNumber: number = 0;
  AccountType: string = "";
  AccountHolderName: string = "";
  AccountStatus: string = "";
  MainAccount: boolean = false;
}

export class Finance_CompanyModel {
  // Maps CompanyModel (C#)
  companyAddress: string = "";
  companyID: string = "";
  companyLogo: string = "";
  companyLogoFile: string = "";
  companyName: string = "";
  companyRegNum: string = "";
  companyStatus: string = "";
  companyTSLOSlink: string = "";
  country: string = "";
  createdTime: string = "";
  groupCompanyID: string = "";
  gstRegNum: string = "";
  industry: string = "";
  isParentCompany: boolean = false;
  payslipLogo: string = "";
  payslipLogoFile: string = "";
  timezone: string = "";
  cpfSubmissionNo: string = "";
  irasTaxNo: string = "";
  phoneNumber: string = "";
}

export class Finance_AccountsModel {
  // Maps CompanyFinanceModel (C#)
  companyFinanceID?: string = "";
  companyID: string = "";
  bankName: string = "";
  bankCode: string = "";
  accountNumber: string = "";
  accountType: string = "";
  accountHolderName: string = "";
  accountStatus: string = "";
  createdTime: Date = new Date();
  updatedByID?: string = "";
}

export class PayrollHistory {
  public id: string = "";
  public userID: string = "";
  public payslipID: string = "";
  public generatedDate: Date = new Date();
  public generatedBy: string = "";
  public uploadPath: string = "";
  public generatedForMonth: string = "";
  public versionNo: number = 0;
  public paymentDate: Date = new Date();
}

export class PayrollHistoryMonth {
  public month: number = 0;
  public totalNetSalary: number = 0;
  public totalCPFContributions: number = 0;
  public totalDonation: number = 0;
  public totalSDL: number = 0;
}

export class PayrollTableModel {
  public payrollID: string = CommonMethods.EmptyGuid;
  public paymentDate: any = null;
  public companyID: string = CommonMethods.EmptyGuid;
  public payrollStatus: string = "";
  public createdTime: Date = new Date();
}


// PDF Data Format
export class DataPDF {
  public contentType: string = "";
  public base64: string = "";
  public fileName: string = "test.txt";
  public file: File | any;
}

export class PayItemModel {
  public payItemID: string = CommonMethods.EmptyGuid; 
  public payItemName: string = "";
  public cpfType: string = "";
  public category: string = "";
  public iR8ACode: string = "";
  public createdTime: Date = new Date();
  public payItemStatus: string = "";
  public groupCompanyID: string = CommonMethods.EmptyGuid; 
  public isCPFPayable: boolean = false;
}

export class PayItemMeta {
  public meta_key: string = "";
  public meta_value: string = "0";
}

export enum PayItemCategory {
  Add = "ADD",
  Deduct = "DEC",
}
// CommonMethods.EmptyGuid

//    ___    _    _     ___  ___    ___  _     ___  ___  _  _  _____  ___
//   / __|  /_\  | |   | __|/ __|  / __|| |   |_ _|| __|| \| ||_   _|/ __|
//   \__ \ / _ \ | |__ | _| \__ \ | (__ | |__  | | | _| | .` |  | |  \__ \
//   |___//_/ \_\|____||___||___/  \___||____||___||___||_|\_|  |_|  |___/
//

export class SalesClientsModel {
  public id: string = "";
  public salesPersonID: string = "";
  public clientContactsID: string = "";
  public leadsStatus: string = "";
  public startDate: Date | string = new Date().toISOString();
  // public endDate: Date | string = new Date().toISOString();
  public remarks: string = "";
  public createdTime: Date = new Date();
}

export class SalesClientsFullModel {
  public id: string = "";
  public salesPersonID: string = "";
  public clientContactsID: string = "";
  public leadsStatus: string = "";
  public startDate: Date = new Date();
  // public endDate: Date = new Date();

  public clientCompanyID: string = "";
  public contactName: string = "";
  public contactNumber: number = 0;
  public contactEmail: string = "";
  public designation: string = "";
  public title: string = "";

  public firstName: string = "";
  public lastName: string = "";
  public email: string = "";
}

export class SalesClientsCompanyModel {
  public id: string = "";
  public salesPersonID: string = "";
  public clientContactsID: string = "";
  public leadsStatus: string = "";
  public startDate: Date = new Date();
  // public endDate: Date = new Date();
  public createdTime: Date | string = new Date();

  public clientCompanyID: string = "";
  public clientCompany: string = "";
  public clientLogoImagePath: string = "";
  public clientCompanyUEN: string = "";
  public clientEmail: string = "";
  public clientAddress: string = "";
  public lastUpdated: Date = new Date();
  public lastUpdatedBy: string = "";
  public isSupplier: boolean = false;
  public clientStatus: string = "";
  public industry: string = "";

  public contactName: string = "";
  public contactNumber: number = 0;
  public contactEmail: string = "";
  public designation: string = "";
  public title: string = "";

  public firstName: string = "";
  public lastName: string = "";
  public email: string = "";

  public salesPersonName: string = "";
}

export class ClientContactsModel {
  public clientContactsID: string = CommonMethods.EmptyGuid;
  public clientCompanyID: string = CommonMethods.EmptyGuid;
  public contactName: string = "";
  public contactNumber: string = "";
  public contactEmail: string = "";
  public designation: string = "";
  public title: string = "";
  public remarks: string = "";
  public createdTime: Date = new Date();
  public status: string = "";
}

//     ___  _  _    _    ___  _____  ___
//    / __|| || |  /_\  | _ \|_   _|/ __|
//   | (__ | __ | / _ \ |   /  | |  \__ \
//    \___||_||_|/_/ \_\|_|_\  |_|  |___/
//

export class PieChartModel {
  public labels: Array<string> = [];
  public datasets: Array<PieChartDatasets> = [
    {
      data: [],
      backgroundColor: randomColor({
        count: 100,
        luminosity: "light",
        hue: "random",
      }),
      // hoverBackgroundcolor: ["#FF6384", "#36A2EB", "#FFCE56"]
    },
  ];
}
export class PieChartDatasets {
  public data: Array<number> = [];
  public backgroundColor: Array<string> = [];
  // public hoverBackgroundcolor: Array<string> = [];
}

export class BarChartModel {
  public labels: Array<string> = [];
  public datasets: Array<BarChartDatasets> = [
    {
      label: "",
      data: [],
      backgroundColor: "#42A5F5",
    },
  ];
}
export class BarChartDatasets {
  public label: string = ""; // e.g My First Dataset
  public data: Array<number> = [];
  public backgroundColor: string = "";
}

export class StackBarChartModel {
  public labels: Array<string> = [];
  public datasets: Array<StackBarChartDatasets> = [
    {
      label: "",
      type: "bar",
      data: [],
      backgroundColor: "#42A5F5",
    },
  ];
}
export class StackBarChartDatasets {
  constructor(label: string, backgroundColor: string) {
    this.label = label;
    this.backgroundColor = backgroundColor;
  }
  public type: string = "bar";
  public label: string = ""; // e.g My First Dataset
  public data: any[] = [];
  public backgroundColor: string = "";
}

//    _                _      _    _           __  __          _       _
//   | |    ___  __ _ (_) ___| |_ (_) __  ___ |  \/  | ___  __| | ___ | | ___
//   | |__ / _ \/ _` || |(_-<|  _|| |/ _|(_-< | |\/| |/ _ \/ _` |/ -_)| |(_-<
//   |____|\___/\__, ||_|/__/ \__||_|\__|/__/ |_|  |_|\___/\__,_|\___||_|/__/
//              |___/

export class LogisticsStatusTypeModel {
  public logisticsStatusTypeID: string = "";
  public logisticsStatusTypeName: string = "";
}

export class LogisticsModel {
  public logisticsID: string = "";
  public equipmentName: string = "";
  public equipmentSerialNo: string = "";
  public issuedDate: Date = new Date();
  public issuedToCompany: string = "";
  public issuedToDepartment: string = "";
  public issuedToUser: string = "";
  public sharedItem: boolean = false;
  public logisticsStatusTypeID: string = "";
  public remarks: string = "";
  public active: boolean = true;
  public purchasedUnitPrice: number = 0;
  public purchasedDate: Date = new Date();
  public warrantyExpiryDate: Date = new Date();
  public productBrand: string = "";
  public productModelNumber: string = "";
  public productCategory: string = "";
  public productDetail: string = "";
  public accountedBy: string = "";
  public accountedDate: Date = new Date();

  public accountedByName: string = "";
  public issuedToName: string = "";
}

export class LogisticsDocumentModel {
  public logisticsDocID: string = "";
  public logisticsID: string = "";
  public uploadPath: string = "";
  public uploadDate: Date = new Date();
  public documentTypeID: string = "";
  public remarks: string = "";
}

export class LogisticsUpdateLogModel {
  public logisticsLogID: string = "";
  public logisticsID: string = "";
  public actionType: string = "";
  public updatedContent: string = "";
  public updatedOn: Date = new Date();
  public updatedBy: string = "";
}

export class NewLogisticsModel {
  public logisticsID: string = "";
  public equipmentName: string = "";
  public equipmentSerialNo: string = "";
  public issuedDate: Date | string = new Date().toISOString();
  public issuedToCompany: null | string = null;
  public issuedToDepartment: null | string = null;
  public issuedToUser: null | string = null;
  public sharedItem: boolean = false;
  public logisticsStatusTypeID: string = "";
  public remarks: string = "";
  public active: boolean = true;
  public purchasedUnitPrice: number = 0;
  public purchasedDate: Date | string = new Date().toISOString();
  public warrantyExpiryDate: Date | string = new Date().toISOString();
  public productBrand: string = "";
  public productModelNumber: string = "";
  public productCategory: string = "";
  public productDetail: string = "";
  public accountedBy: string = "";
  public accountedDate: Date | string = new Date().toISOString();

  public invoice: File | any = null;
  public invoiceDocumentType: string = "";
  public invoiceRemarks: string = "";
}

//     ___  _  _  ___   ___   _  _   ___   ___
//    / __|| || || _ \ / _ \ | \| | / _ \ / __|
//   | (__ | __ ||   /| (_) || .` || (_) |\__ \
//    \___||_||_||_|_\ \___/ |_|\_| \___/ |___/
//

export class EquipmentsModel {
  public logisticsID: string = "";
  public equipmentName: string = "";
  public equipmentSerialNo: string = "";
  public issuedDate: Date = new Date();
  public issuedToCompany: string = "";
  public sharedItem: boolean = false;
  public logisticsStatusTypeID: string = "";
  public active: boolean = true;
  public productBrand: string = "";
  public productModelNumber: string = "";
  public productCategory: string = "";
  public productDetail: string = "";

  public companyID: string = "";
  public companyName: string = "";

  public equipLoanID: string = "";
  public userID: string = "";
  public startDate: Date = new Date();
  public endDate: Date = new Date();
  public inOut: string = "";
}

export class CompanyEquipmentsModel {
  public companyID: string = "";
  public equipments: Array<EquipmentsModel> = [];
}

export class EquipmentForecastingModel {
  public equipForecastID: string = "";
  public userID: string = "";
  public logisticsID: string = "";
  public startDate: Date = new Date();
  public startTime: string = "";
  public endDate: Date = new Date();
  public endTime: string = "";
  public campaignID: string = "";
  public deliverableID: string = "";
}
export class SingleForecastingModel {
  public equipForecastID: string = "";
  public userID: string = "";
  public logisticsIDList: Array<string> = [];
  public startDate: string | Date = "";
  public startTime: string = "";
  public endDate: string | Date = "";
  public endTime: string = "";
  public campaignID: string = "";
  public deliverableID: string = "";
  public equipmentAvailable: boolean = true;
}
export class EquipmentLoanModel {
  public equipLoanID: string = "";
  public equipForecastID: string = "";
  public userID: string = "";
  public logisticsID: string = "";
  public transDateTime: Date = new Date();
  public transType: string = "";
}
export class MultipleEquipmentLoanModel {
  public equipLoanID: string = "";
  public equipForecastID: string = "";
  public userID: string = "";
  public logisticsIDList: Array<EquipmentsModel> = [];
  // public logisticsIDList:Array<string> = [];
  public transDateTime: Date = new Date();
  public transType: string = "";
}

//    ___ ___ _____ _____ ___ _  _  ___ ___
//   / __| __|_   _|_   _|_ _| \| |/ __/ __|
//   \__ \ _|  | |   | |  | || .` | (_ \__ \
//   |___/___| |_|   |_| |___|_|\_|\___|___/
//

export class NoticeboardSettingModel {
  public settingID: string = "";
  public userID: string = "";
  public settingKey: string = "";
  public settingValue: Array<string> = [];
}

